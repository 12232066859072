import React from 'react';
import { HelpTrainingEntity } from '../../models';

export interface CmsHelpTrainingContextType {
  fetchData: () => Promise<void>;
  helpTraining: HelpTrainingEntity | null;
  canEdit: boolean;
  isLoading: boolean;
}

export const CmsHelpTrainingContext = React.createContext<CmsHelpTrainingContextType>({} as CmsHelpTrainingContextType);
