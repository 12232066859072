import { array, date, mixed, number, object, ref, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorGeneralConditionEntity } from '../../models';
import { EntityFormSchema, GeneralConditionType, LanguageCode } from '../../types';
import { isDateValid } from '../../utils/helper';

export const VendorGeneralConditionSchema = (): EntityFormSchema<VendorGeneralConditionEntity> => ({
  schema: object().shape(
    {
      type: mixed<GeneralConditionType>().oneOf(Object.values(GeneralConditionType)).required(),
      vendorBuyingAgreementId: number().typeError('common:error.fieldRequired').required(),
      description_En: string().when('languages', {
        is: (val: LanguageCode[] | undefined) => val?.includes(LanguageCode.en),
        then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
        otherwise: (schema) => schema.nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      }),
      description_Fr: string().when('languages', {
        is: (val: LanguageCode[] | undefined) => val?.includes(LanguageCode.fr),
        then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
        otherwise: (schema) => schema.nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      }),
      summary_En: string().when('type', {
        is: GeneralConditionType.Freight,
        then: (schema) => schema.nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
        otherwise: (schema) => schema.required().max(MAX_LONG_TEXT_LENGTH),
      }),
      summary_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      freightPolicy_En: string().nullable().notRequired().max(195),
      freightPolicy_Fr: string().nullable().notRequired().max(195),
      pickupAllowance_En: string().nullable().notRequired().max(20),
      pickupAllowance_Fr: string().nullable().notRequired().max(20),
      prepaidAmount_En: string().nullable().notRequired().max(20),
      prepaidAmount_Fr: string().nullable().notRequired().max(20),
      fobPoint_En: string().nullable().notRequired().max(20),
      fobPoint_Fr: string().nullable().notRequired().max(20),
      vendorNotes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .required()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      files: array(object({ languages: array().required().min(1) }).notRequired()),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
