import { buyingAgreement as base } from './buyingAgreement';
import { buyingAgreementBuyOpportunity } from './buyingAgreementBuyOpportunity';
import { buyingAgreementGeneralCondition } from './buyingAgreementGeneralCondition';
import { buyingAgreementGrowth } from './buyingAgreementGrowth';
import { buyingAgreementRebateCategory } from './buyingAgreementRebateCategory';
import { buyingAgreementTier } from './buyingAgreementTier';

export const buyingAgreement = {
  ...base,
  ...buyingAgreementTier,
  ...buyingAgreementGrowth,
  ...buyingAgreementRebateCategory,
  ...buyingAgreementBuyOpportunity,
  ...buyingAgreementGeneralCondition,
};
