import React from 'react';
import { VendorBuyingAgreementBuyOpportunityEntity } from '../../models/Vendor/VendorBuyingAgreementBuyOpportunityEntity';

interface VendorBuyingAgreementBuyOpportunityDetailContextType {
  fetchData: () => Promise<void>;
  vendorId: number;
  readOnly: boolean;
  buyOpportunity: VendorBuyingAgreementBuyOpportunityEntity;
  save: (
    entity: VendorBuyingAgreementBuyOpportunityEntity,
  ) => Promise<VendorBuyingAgreementBuyOpportunityEntity | null>;
}

export const VendorBuyingAgreementBuyOpportunityDetailContext =
  React.createContext<VendorBuyingAgreementBuyOpportunityDetailContextType>(
    {} as VendorBuyingAgreementBuyOpportunityDetailContextType,
  );
