import { AddRounded } from '@mui/icons-material';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { VendorDetailContext } from '../../../contexts';
import { useApi, useAuth, useGlobalEdit, useInternationalization } from '../../../hooks';
import { VendorTierGrowthEntity } from '../../../models';
import { VendorTierGrowthSchema } from '../../../schemas';
import { PermissionKey, SearchVendorTiersGrowthsFilter, Styles } from '../../../types';
import { Container } from '../../Container';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { ArchiveMenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { VendorTierGrowthList } from './VendorTierGrowthList';
import { VendorTiersGrowthsDrawer } from './VendorTiersGrowthsDrawer';

const style: Styles = {
  container: {
    py: 4,
  },
  header: {
    pb: 3,
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
    mb: 2,
  },
};

export const VendorTiersGrowths = () => {
  const { hasPermissions } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditingOrAddingGrowth, setIsEditingOrAddingGrowth] = useState(false);
  const [tierOrGrowth, setTierOrGrowth] = useState<VendorTierGrowthEntity>(new VendorTierGrowthEntity());
  const { vendorId, vendor } = useContext(VendorDetailContext);
  const [filter, setFilter] = useState<SearchVendorTiersGrowthsFilter>({ ...MAX_PAGINATION_FILTER });
  const {
    data: tiers,
    isLoading: isTierLoading,
    refresh: refreshTiers,
  } = useApi(vendorApi.getAllTier, null, vendorId, filter);
  const {
    data: growths,
    isLoading: isGrowthsLoading,
    refresh: refreshGrowths,
  } = useApi(vendorApi.getAllGrowth, null, vendorId, filter);
  const { call: createOrUpdateTier } = useApi(tierOrGrowth.id ? vendorApi.updateTier : vendorApi.createTier, {
    successKey: 'common:success.save',
  });
  const { call: createOrUpdateGrowth } = useApi(tierOrGrowth.id ? vendorApi.updateGrowth : vendorApi.createGrowth, {
    successKey: 'common:success.save',
  });
  const { call: archiveGrowth } = useApi(vendorApi.archiveGrowth, { successKey: 'common:success.action' });
  const { call: archiveTier } = useApi(vendorApi.archiveTier, { successKey: 'common:success.action' });

  const onArchiveConfirm =
    (isGrowthSelected: boolean) => async (isArchived: boolean, entity: VendorTierGrowthEntity | null) => {
      if (entity) {
        const apiCall = isGrowthSelected ? archiveGrowth : archiveTier;
        const refresh = isGrowthSelected ? refreshGrowths : refreshTiers;
        await apiCall(vendorId, [entity.id], isArchived);
        refresh();
      }
    };

  const saveDrawer = async (entity: VendorTierGrowthEntity) => {
    const savedTierOrGrowth = isEditingOrAddingGrowth
      ? await createOrUpdateGrowth(vendorId, entity)
      : await createOrUpdateTier(vendorId, entity);
    if (savedTierOrGrowth) {
      setTierOrGrowth(new VendorTierGrowthEntity());
    }
    return savedTierOrGrowth;
  };

  const cancelDrawer = () => {
    setIsDrawerOpen(false);
    setIsEditingOrAddingGrowth(false);
    setTierOrGrowth(new VendorTierGrowthEntity());
  };

  const confirmDrawer = () => {
    isEditingOrAddingGrowth ? refreshGrowths() : refreshTiers();
    setIsEditingOrAddingGrowth(false);
    setIsDrawerOpen(false);
  };

  const renderActionsMenuItems = (entity: VendorTierGrowthEntity, onClick: () => void, isGrowthSelected: boolean) => [
    <MenuItem
      id="edit"
      key="edit"
      onClick={() => {
        setIsEditingOrAddingGrowth(isGrowthSelected);
        setTierOrGrowth(entity);
        setIsDrawerOpen(true);
        onClick();
      }}
    >
      {t('common:edit')}
    </MenuItem>,
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={getTranslation(entity, 'name')}
      onClick={onClick}
      onArchiveConfirm={onArchiveConfirm(isGrowthSelected)}
      actionSuffix={t('vendor:rebateCategory.tierAndGrowth.actions.suffix.tier')}
    />,
  ];

  return (
    <Container isHighlighted sx={style.container}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={style.header}>
        <Typography variant="h2">{t('vendor:rebateCategory.tierAndGrowth.title')}</Typography>
        <HasPermissions keys={PermissionKey.VendorEditRebates}>
          <Button
            variant="contained"
            startIcon={<AddRounded />}
            onClick={() => setIsDrawerOpen(true)}
            disabled={!vendor || vendor.isArchived || globalEditing}
          >
            {t('vendor:rebateCategory.tierAndGrowth.actions.add')}
          </Button>
        </HasPermissions>
      </Stack>
      <Box sx={style.selectSmallContainer}>
        <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />
      </Box>
      <VendorTierGrowthList
        isLoading={isTierLoading || isGrowthsLoading}
        tiers={tiers?.data ?? []}
        growths={growths?.data ?? []}
        actionMenuItems={hasPermissions(PermissionKey.VendorEditRebates) ? renderActionsMenuItems : undefined}
      />
      <VendorTiersGrowthsDrawer
        open={isDrawerOpen}
        entity={tierOrGrowth}
        schema={VendorTierGrowthSchema(isEditingOrAddingGrowth)}
        onSave={saveDrawer}
        onConfirm={confirmDrawer}
        onCancel={cancelDrawer}
        context={{ setIsEditingOrAddingGrowth, isEditingOrAddingGrowth, tierOrGrowth, setTierOrGrowth }}
      />
    </Container>
  );
};
