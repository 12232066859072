import { ErrorBoundary, RequireAuth } from '../components';
import { LayoutAdminUser } from '../components/Layout';
import {
  Boards,
  BuyingAgreementBuyOpportunities,
  BuyingAgreementBuyOpportunity,
  BuyingAgreementBuyOpportunityDetails,
  BuyingAgreementBuyOpportunityDocuments,
  BuyingAgreementBuyOpportunityPermissions,
  BuyingAgreementDocuments,
  BuyingAgreementGeneralConditions,
  BuyingAgreementRebateCategories,
  BuyingAgreementRebateCategory,
  BuyingAgreementRebateCategoryPermissions,
  BuyingAgreementRebateCategoryRebates,
  BuyingAgreementRebateCategoryTiersGrowths,
  BuyingAgreements,
  BuyOpportunities,
  BuyOpportunity,
  BuyOpportunityDetails,
  BuyOpportunityDocuments,
  BuyOpportunityPermissions,
  ChangeRequests,
  Cms,
  CmsAdDetails,
  CmsAds,
  CmsHelpTrainingDetails,
  CmsHelpTrainings,
  CmsPublicationDetailContent,
  CmsPublicationDetails,
  CmsPublications,
  CmsPublicationTargeting,
  CmsStaticPageDetailContent,
  CmsStaticPageDetails,
  CmsStaticPages,
  CommitmentEvent,
  CommitmentEventCategories,
  CommitmentEventCommitments,
  CommitmentEvents,
  CommitmentLayout,
  Dashboard,
  Departments,
  FailedAuthentication,
  Financials,
  ForestProduct,
  ForestProductGroupDetails,
  ForestProductGroups,
  ForestProductPriceUpdateDetails,
  ForestProductPriceUpdatePrices,
  ForestProductPriceUpdates,
  ForestProducts,
  HelpDetail,
  HelpLayout,
  InternalUser,
  InternalUserPermissions,
  InternalUserRoles,
  InternalUsers,
  InvalidAccess,
  Member,
  MemberChangeRequests,
  MemberCommitmentDocuments,
  MemberContacts,
  MemberGroup,
  MemberGroups,
  MemberMemberGroups,
  MemberMemberUsers,
  Members,
  MembershipTypes,
  MemberUser,
  MemberUserPermissions,
  MemberUserRoles,
  MemberUsers,
  ProductCategories,
  ProfileLayout,
  RebateCategoriesReports,
  RebateCategory,
  RebateCategoryPermissions,
  RebateCategoryProgram,
  RebateCategoryProgramPermissions,
  RebateCategoryProgramRebates,
  RebateCategoryPrograms,
  RebateCategoryProgramTiersAndGrowths,
  RebateCategoryRebates,
  RebateCategoryTiersAndGrowths,
  RebateProgramsReports,
  RebateTypes,
  Regions,
  Reports,
  Roles,
  Search,
  SettingsLayout,
  TBMContactInternalContactDetail,
  TBMContactInternalContacts,
  TBMContactOfficeDetail,
  TBMContactOfficeInternalContacts,
  TBMContactOffices,
  TBMContacts,
  BuyingAgreementsReports,
  Users,
  Vendor,
  VendorBlocks,
  VendorBuyingAgreement,
  VendorChangeRequests,
  VendorContacts,
  VendorDesignations,
  VendorDocuments,
  VendorGeneralConditions,
  VendorLocations,
  Vendors,
  VendorTiersAndGrowths,
  VendorUser,
  VendorUserPermissions,
  VendorUserRoles,
  VendorUsers,
  VendorVendorUsers,
  RebateCategories,
  MissingTranslationsReports,
  ActivityLogs,
  VendorProductCategoriesReports,
  HoldbackReports,
} from '../pages/Admin';
import { UserType } from '../types';

export const AdminRoutes = {
  path: '/admin',
  errorElement: <ErrorBoundary />,
  element: (
    <RequireAuth userType={UserType.Internal}>
      <LayoutAdminUser />
    </RequireAuth>
  ),
  Dashboard: {
    path: 'dashboard',
    element: <Dashboard />,
  },
  User: {
    path: 'users',
    element: <Users />,
    InternalUser: {
      path: 'internal',
      element: <InternalUsers />,
      Detail: {
        path: ':userId',
        element: <InternalUser />,
        Permissions: {
          path: 'permissions',
          element: <InternalUserPermissions />,
        },
      },
    },
    MemberUser: {
      path: 'member',
      element: <MemberUsers />,
      Detail: {
        path: ':userId',
        element: <MemberUser />,
        Permissions: {
          path: 'permissions',
          element: <MemberUserPermissions />,
        },
      },
    },
    VendorUser: {
      path: 'vendor',
      element: <VendorUsers />,
      Detail: {
        path: ':userId',
        element: <VendorUser />,
        Permissions: {
          path: 'permissions',
          element: <VendorUserPermissions />,
        },
      },
    },
  },
  Region: {
    path: 'categorization/regions',
    element: <Regions />,
  },
  MembershipType: {
    path: 'categorization/membership-types',
    element: <MembershipTypes />,
  },
  VendorDesignation: {
    path: 'categorization/vendor-types',
    element: <VendorDesignations />,
  },
  RebateType: {
    path: 'categorization/rebate-types',
    element: <RebateTypes />,
  },
  ProductCategory: {
    path: 'categorization/product-categories',
    element: <ProductCategories />,
  },
  Board: {
    path: 'categorization/boards',
    element: <Boards />,
  },
  Department: {
    path: 'categorization/departments',
    element: <Departments />,
  },
  Member: {
    path: 'members',
    element: <Members />,
    Detail: {
      path: ':memberId',
      element: <Member />,
      Contacts: {
        path: 'contacts',
        element: <MemberContacts />,
      },
      MemberGroups: {
        path: 'member-groups',
        element: <MemberMemberGroups />,
      },
      MemberUsers: {
        path: 'users',
        element: <MemberMemberUsers />,
      },
      MemberCommitmentDocuments: {
        path: 'commitment-documents',
        element: <MemberCommitmentDocuments />,
      },
    },
  },
  Role: {
    path: 'roles',
    element: <Roles />,
    InternalUsers: {
      path: 'internal',
      element: <InternalUserRoles />,
    },
    MemberUsers: {
      path: 'member',
      element: <MemberUserRoles />,
    },
    VendorUsers: {
      path: 'vendor',
      element: <VendorUserRoles />,
    },
  },
  MemberGroups: {
    path: 'member-groups',
    element: <MemberGroups />,
    Detail: {
      path: ':memberGroupId',
      element: <MemberGroup />,
    },
  },
  Vendor: {
    path: 'vendors',
    element: <Vendors />,
    Detail: {
      path: ':vendorId',
      element: <Vendor />,
      Financials: {
        path: 'financials',
        element: <Financials />,
      },
      BuyingAgreements: {
        path: 'buying-agreements',
        element: <BuyingAgreements />,
      },
      RebateCategories: {
        path: 'rebate-categories',
        element: <RebateCategories />,
      },
      VendorUsers: {
        path: 'users',
        element: <VendorVendorUsers />,
      },
      TiersAndGrowths: {
        path: 'tiers-growths',
        element: <VendorTiersAndGrowths />,
      },
      Blocks: {
        path: 'blocks',
        element: <VendorBlocks />,
      },
      Contacts: {
        path: 'contacts',
        element: <VendorContacts />,
      },
      Locations: {
        path: 'locations',
        element: <VendorLocations />,
      },
      Documents: {
        path: 'documents',
        element: <VendorDocuments />,
      },
      GeneralConditions: {
        path: 'general-conditions',
        element: <VendorGeneralConditions />,
      },
      BuyOpportunities: {
        path: 'buy-opportunities',
        element: <BuyOpportunities />,
      },
    },
    BuyingAgreement: {
      path: ':vendorId/buying-agreements/:vendorBuyingAgreementId',
      element: <VendorBuyingAgreement />,
      Documents: {
        path: 'documents',
        element: <BuyingAgreementDocuments />,
      },
      BuyOpportunities: {
        path: 'buy-opportunities',
        element: <BuyingAgreementBuyOpportunities />,
      },
      GeneralConditions: {
        path: 'general-conditions',
        element: <BuyingAgreementGeneralConditions />,
      },
      RebateCategories: {
        path: 'rebate-categories',
        element: <BuyingAgreementRebateCategories />,
      },
    },
    BuyingAgreementBuyOpportunity: {
      path: ':vendorId/buying-agreements/:vendorBuyingAgreementId/buy-opportunities/:buyOpportunityId',
      element: <BuyingAgreementBuyOpportunity />,
      Permissions: {
        path: 'permissions',
        element: <BuyingAgreementBuyOpportunityPermissions />,
      },
      Documents: {
        path: 'documents',
        element: <BuyingAgreementBuyOpportunityDocuments />,
      },
      Details: {
        path: 'details',
        element: <BuyingAgreementBuyOpportunityDetails />,
      },
    },
    BuyingAgreementRebateCategory: {
      path: ':vendorId/buying-agreements/:vendorBuyingAgreementId/rebate-categories/:vendorRebateCategoryId',
      element: <BuyingAgreementRebateCategory />,
      Permissions: {
        path: 'permissions',
        element: <BuyingAgreementRebateCategoryPermissions />,
      },
      TiersAndGrowths: {
        path: 'tiers-growths',
        element: <BuyingAgreementRebateCategoryTiersGrowths />,
      },
      Rebates: {
        path: 'rebates',
        element: <BuyingAgreementRebateCategoryRebates />,
      },
    },
    BuyOpportunity: {
      path: ':vendorId/buy-opportunity/:vendorBuyOpportunityId',
      element: <BuyOpportunity />,
      Permissions: {
        path: 'permissions',
        element: <BuyOpportunityPermissions />,
      },
      Documents: {
        path: 'documents',
        element: <BuyOpportunityDocuments />,
      },
      Details: {
        path: 'details',
        element: <BuyOpportunityDetails />,
      },
    },
    RebateCategory: {
      path: ':vendorId/rebate-categories/:vendorRebateCategoryId',
      element: <RebateCategory />,
      Permissions: {
        path: 'permissions',
        element: <RebateCategoryPermissions />,
      },
      TiersAndGrowths: {
        path: 'tiers-growths',
        element: <RebateCategoryTiersAndGrowths />,
      },
      Programs: {
        path: 'programs',
        element: <RebateCategoryPrograms />,
      },
      Rebates: {
        path: 'rebates',
        element: <RebateCategoryRebates />,
      },
    },
    RebateCategoryProgram: {
      path: ':vendorId/rebate-categories/:vendorRebateCategoryId/programs/:rebateProgramId',
      element: <RebateCategoryProgram />,
      Permissions: {
        path: 'permissions',
        element: <RebateCategoryProgramPermissions />,
      },
      TiersAndGrowths: {
        path: 'tier-growths',
        element: <RebateCategoryProgramTiersAndGrowths />,
      },
      Rebates: {
        path: 'rebates',
        element: <RebateCategoryProgramRebates />,
      },
    },
  },
  ChangeRequests: {
    path: 'change-requests',
    element: <ChangeRequests />,
    Vendor: {
      path: 'vendor',
      element: <VendorChangeRequests />,
    },
    Member: {
      path: 'member',
      element: <MemberChangeRequests />,
    },
  },
  Profile: {
    path: 'profile',
    element: <ProfileLayout />,
  },
  Cms: {
    path: 'content-publications',
    element: <Cms />,
    Ads: {
      path: 'ads',
      element: <CmsAds />,
    },
    Publications: {
      path: 'publications',
      element: <CmsPublications />,
    },
    HelpTrainings: {
      path: 'helpTrainings',
      element: <CmsHelpTrainings />,
    },
    StaticPages: {
      path: 'static-pages',
      element: <CmsStaticPages />,
    },
  },
  AdDetails: {
    path: 'content-publications/ads/:adId',
    element: <CmsAdDetails />,
  },
  PublicationDetails: {
    path: 'content-publications/publications/:publicationId',
    element: <CmsPublicationDetails />,
    Content: {
      path: 'content',
      element: <CmsPublicationDetailContent />,
    },
    Targeting: {
      path: 'targeting',
      element: <CmsPublicationTargeting />,
    },
  },
  StaticPageDetails: {
    path: 'content-publications/static-pages/:staticPageId',
    element: <CmsStaticPageDetails />,
    Content: {
      path: 'content',
      element: <CmsStaticPageDetailContent />,
    },
  },
  HelpTrainingDetails: {
    path: 'content-publications/helpTrainings/:helpTrainingId',
    element: <CmsHelpTrainingDetails />,
  },
  TBMContacts: {
    path: 'tbm-contacts',
    element: <TBMContacts />,
    InternalContacts: {
      path: 'contacts',
      element: <TBMContactInternalContacts />,
    },
    Offices: {
      path: 'offices',
      element: <TBMContactOffices />,
    },
  },
  OfficeDetails: {
    path: 'tbm-contacts/offices/:officeId',
    element: <TBMContactOfficeDetail />,
    InternalContacts: {
      path: 'contacts',
      element: <TBMContactOfficeInternalContacts />,
    },
  },
  InternalContactDetails: {
    path: 'tbm-contacts/contacts/:contactId',
    element: <TBMContactInternalContactDetail />,
  },
  Help: {
    path: 'help',
    element: <HelpLayout />,
    Detail: {
      path: ':helpTrainingId',
      element: <HelpDetail />,
    },
  },
  Settings: {
    path: 'settings',
    element: <SettingsLayout />,
  },
  Cci: {
    path: 'cci',
    element: <CommitmentLayout />,
    CommitmentEvents: {
      path: 'events',
      element: <CommitmentEvents />,
    },
  },
  CommitmentEventDetails: {
    path: 'cci/events/:eventId',
    element: <CommitmentEvent />,
    Category: {
      path: 'categories',
      element: <CommitmentEventCategories />,
    },
    Commitments: {
      path: 'commitments',
      element: <CommitmentEventCommitments />,
    },
  },
  ForestProducts: {
    path: 'forest-products',
    element: <ForestProduct />,
    Groups: {
      path: 'product-groups',
      element: <ForestProductGroups />,
    },
    PriceUpdates: {
      path: 'price-updates',
      element: <ForestProductPriceUpdates />,
    },
  },
  ForestProductGroupDetail: {
    path: 'product-groups/:productGroupId',
    element: <ForestProductGroupDetails />,
    Products: {
      path: 'products',
      element: <ForestProducts />,
    },
  },
  ForestProductPriceUpdateDetail: {
    path: 'price-updates/:priceUpdateId',
    element: <ForestProductPriceUpdateDetails />,
    Groups: {
      path: ':productGroupId',
      element: <ForestProductPriceUpdatePrices />,
    },
  },
  Reports: {
    path: 'reports',
    element: <Reports />,
    FailedAuthentication: {
      path: 'failed-authentication',
      element: <FailedAuthentication />,
    },
    InvalidAccess: {
      path: 'invalid-access',
      element: <InvalidAccess />,
    },
    UnapprovedBuyingAgreements: {
      path: 'buying-agreements',
      element: <BuyingAgreementsReports />,
    },
    RebateCategories: {
      path: 'rebate-categories',
      element: <RebateCategoriesReports />,
    },
    RebatePrograms: {
      path: 'rebate-programs',
      element: <RebateProgramsReports />,
    },
    MissingTranslations: {
      path: 'missing-translations',
      element: <MissingTranslationsReports />,
    },
    ActivityLogs: {
      path: 'activity-logs',
      element: <ActivityLogs />,
    },
    VendorProductCategories: {
      path: 'product-categories',
      element: <VendorProductCategoriesReports />,
    },
    Holdbacks: {
      path: 'holdbacks',
      element: <HoldbackReports />,
    },
  },
  Search: {
    path: 'search',
    element: <Search />,
  },
};
