import DownloadIcon from '@mui/icons-material/Download';
import ModeCommentRounded from '@mui/icons-material/ModeCommentRounded';
import { Box, Link, SelectChangeEvent, Stack } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { EntityPeriod } from '../';
import { vendor as vendorApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER, MAX_PAGINATION_FILTER } from '../../constants';
import { BuyGuideVendorDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { BuyGuideVendorRebateProgramDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorRebateProgramDetailContext';
import { useApi, useEnumList, useInternationalization } from '../../hooks';
import { VendorDocumentEntity } from '../../models';
import {
  DocumentType,
  FilterPeriod,
  LanguageCode,
  OrderBy,
  PermissionKey,
  Styles,
  VendorDocumentFilter,
} from '../../types';
import { areArrayEquals, downloadBlob, formatLongDate } from '../../utils/helper';
import { FilterContainer } from '../Container';
import { PeriodFilterSelect } from '../Filter/PeriodFilterSelect';
import { MultiSelect, Select } from '../Form';
import { LayoutPage } from '../Layout';
import { Table, TableColumn } from '../Table';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectRebateCategory: {
    width: {
      xs: '100%',
      sm: '250px',
    },
  },
};

const defaultFilter: VendorDocumentFilter = {
  period: FilterPeriod.All,
  isArchived: false,
  type: undefined,
  rebateCategoryIds: [],
  ...DEFAULT_PAGINATION_FILTER,
};

export const BuyGuideVendorDocumentList = () => {
  const buyGuideVendorDetailContext = useContext(BuyGuideVendorDetailContext);
  const buyGuideVendorRebateProgramDetailContext = useContext(BuyGuideVendorRebateProgramDetailContext);
  const isInProgram = !!buyGuideVendorRebateProgramDetailContext.vendorId;
  const vendorId = buyGuideVendorDetailContext?.vendorId ?? buyGuideVendorRebateProgramDetailContext?.vendorId;

  const [filter, setFilter] = useState<VendorDocumentFilter>({
    ...defaultFilter,
    rebateProgramId: buyGuideVendorRebateProgramDetailContext?.rebateProgramId,
  });
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { call: download } = useApi(vendorApi.getDocumentFile, null);
  const { data: rebateCategories } = useApi(
    vendorApi.getDocumentRebateCategories,
    null,
    vendorId,
    MAX_PAGINATION_FILTER,
  );

  const { data, isLoading, refresh } = useApi(vendorApi.getAllDocuments, { skipFetch: !vendorId }, vendorId, filter);

  const typeOptions = useEnumList(DocumentType, 'buyGuide:documents.type', true);

  const rebateCategoriesOptions = useMemo(
    () =>
      rebateCategories?.data.map((r) => ({
        value: r.id,
        label: getTranslation(r, 'name'),
      })) ?? [],
    [getTranslation, rebateCategories],
  );

  const downloadFile = async (entity: VendorDocumentEntity) => {
    if (entity.id && entity.file) {
      const fileData = await download(vendorId, entity.id);
      if (fileData) {
        downloadBlob(entity.file.name, fileData);
      }
    }
  };

  useEffect(() => {
    refresh();
  }, [currentLanguage, refresh]);

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onRebateCategoriesBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.rebateCategoryIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, rebateCategoryIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      type: (event.target.value as DocumentType) || undefined,
      pageNumber: 1,
    }));
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const renderFilter = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={typeOptions}
          value={filter.type ?? ''}
          onChange={onTypeChange}
          label={t('buyGuide:documents.search.type')}
        />
      </Box>
      <PeriodFilterSelect filter={filter} setFilter={setFilter} />
      {!isInProgram && (
        <Box sx={style.selectRebateCategory}>
          <MultiSelect
            displayEmpty
            options={rebateCategoriesOptions}
            values={filter.rebateCategoryIds ?? []}
            onConfirm={onRebateCategoriesBlur}
            noValueText={t('common:filter.all', { context: 'female' })}
            label={t('buyGuide:documents.search.rebateCategories')}
          />
        </Box>
      )}
    </FilterContainer>
  );

  const renderPeriod = (entity: VendorDocumentEntity) => (
    <EntityPeriod
      period={entity.period}
      translationContext={entity.type === DocumentType.PriceList ? 'female' : 'male'}
    />
  );

  const renderType = (entity: VendorDocumentEntity) => t(`buyGuide:documents.type.${entity.type}`);

  const renderFileName = (entity: VendorDocumentEntity) => (
    <Stack>
      <Link variant="tableRow" whiteSpace="pre-wrap" onClick={() => downloadFile(entity)}>{`${
        entity.file?.name ?? ''
      }`}</Link>
      <Box>{getTranslation(entity, 'description')}</Box>
    </Stack>
  );

  const renderLastModified = (entity: VendorDocumentEntity) => formatLongDate(entity.modifiedDate);

  const renderTable = () =>
    data && (
      <Table
        data={data}
        translationNamespace="buyGuide:documents"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        hidePending
      >
        <TableColumn type="custom" width="15%" id="period" sortable render={renderPeriod} />
        <TableColumn type="custom" width="10%" id="type" sortable render={renderType} />
        <TableColumn type="custom" width="35%" id="filename" sortable render={renderFileName} />
        <TableColumn
          type="custom"
          id="effectiveDate"
          width="15%"
          sortable
          render={(entity: VendorDocumentEntity) => formatLongDate(entity.effectiveDate)}
        />
        <TableColumn
          type="custom"
          id="expiryDate"
          width="15%"
          sortable
          render={(entity: VendorDocumentEntity) => formatLongDate(entity.expiryDate)}
        />
        <TableColumn type="custom" width="10%" id="modifiedDate" sortable render={renderLastModified} />
        <TableColumn
          type="icon"
          tooltip
          sortable
          id={currentLanguage === LanguageCode.en ? 'memberNotes_En' : 'memberNotes_Fr'}
          align="center"
          render={(entity: VendorDocumentEntity) => getTranslation(entity, 'memberNotes')}
          iconComponent={ModeCommentRounded}
        />
        <TableColumn
          type="button"
          tooltip
          id="download"
          align="center"
          render={() => t('common:download')}
          iconComponent={DownloadIcon}
          onClick={(entity: VendorDocumentEntity) => downloadFile(entity)}
        />
      </Table>
    );

  return (
    <>
      <LayoutPage
        title={t('buyGuide:documents.title')}
        display={'Tab'}
        permissions={{ keys: PermissionKey.VendorViewFiles }}
      >
        {renderFilter()}
        {renderTable()}
      </LayoutPage>
    </>
  );
};
