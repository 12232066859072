import { Stack, Typography } from '@mui/material';
import { vendor as vendorApi, member as memberApi, memberGroup as memberGroupApi } from '../../../api';
import { useInternationalization } from '../../../hooks';
import { MemberEntity, MemberGroupEntity } from '../../../models';
import { Styles } from '../../../types';
import { Container } from '../../Container';
import { VendorBlockList } from './VendorBlockList';

const style: Styles = {
  container: {
    py: 4,
  },
};

export const VendorBlocks = () => {
  const { t } = useInternationalization();

  return (
    <Container isHighlighted sx={style.container}>
      <Stack spacing={4}>
        <Typography variant="h2">{t('vendor:blocks.title')}</Typography>
        <VendorBlockList
          title={t('vendor:blocks.sections.memberGroups')}
          getCall={vendorApi.getBlockedMemberGroups}
          searchCall={memberGroupApi.getSuggestions}
          blockCall={vendorApi.blockMemberGroup}
          unblockCall={vendorApi.unblockMemberGroup}
          renderOptionLabel={(item: MemberGroupEntity) => item.name}
          noOptionsText={t('memberGroup:search.notFound')}
          translationNamespace="vendor:blocks.memberGroups"
        />
        <VendorBlockList
          title={t('vendor:blocks.sections.members')}
          getCall={vendorApi.getBlockedMembers}
          searchCall={memberApi.getSuggestions}
          blockCall={vendorApi.blockMember}
          unblockCall={vendorApi.unblockMember}
          renderOptionLabel={(item: MemberEntity) => `${item.fullNumber} - ${item.name}`}
          noOptionsText={t('member:search.notFound')}
          translationNamespace="vendor:blocks.members"
        />
      </Stack>
    </Container>
  );
};
