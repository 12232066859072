import {
  AccountCircleRounded,
  LanguageRounded,
  LiveHelpRounded,
  LogoutRounded,
  SettingsRounded,
} from '@mui/icons-material';
import { generatePath } from 'react-router-dom';
import { NavigationMenuDivider, NavigationMenuItem, NavigationUserTypeMenu } from '.';
import { useInternationalization } from '../../hooks';
import useAuth from '../../hooks/useAuth';
import { routes } from '../../routes';
import { PermissionKey } from '../../types';
import { HasPermissions } from '../Permission';

export const NavigationUserMenuItems = () => {
  const { t, i18n, otherLanguage } = useInternationalization();
  const { logout, isMemberUser, isVendorUser, user, isInternalUser } = useAuth();

  const changeLanguage = () => {
    i18n.changeLanguage(otherLanguage);
  };

  const logoutUser = () => {
    user && logout(user);
  };

  return (
    <>
      <NavigationUserTypeMenu />
      <NavigationMenuDivider />
      <NavigationMenuItem
        title={t('user:profile.menuItemTitle')}
        icon={<AccountCircleRounded />}
        href={generatePath(
          isMemberUser
            ? routes.Member.Profile.path
            : isVendorUser
            ? routes.Vendor.Profile.path
            : routes.Admin.Profile.path,
        )}
      />
      <NavigationMenuItem
        title={t(`common:otherLanguage.${otherLanguage}`)}
        icon={<LanguageRounded />}
        onClick={changeLanguage}
      />
      <NavigationMenuItem
        title={t('navigation:sections.help')}
        icon={<LiveHelpRounded />}
        href={isMemberUser ? routes.Member.Help.path : isVendorUser ? routes.Vendor.Help.path : routes.Admin.Help.path}
        matchPath={isMemberUser ? '/member/help/*' : isVendorUser ? '/vendor/help/*' : '/admin/help/*'}
      />
      {isInternalUser && (
        <HasPermissions keys={[PermissionKey.AdminSettingsMailChimp, PermissionKey.AdminSettingsSearch]} any>
          <NavigationMenuItem
            title={t('navigation:sections.settings')}
            icon={<SettingsRounded />}
            href={routes.Admin.Settings.path}
            matchPath="/admin/settings"
          />
        </HasPermissions>
      )}

      <NavigationMenuItem title={t('auth:logout')} icon={<LogoutRounded />} onClick={logoutUser} />
    </>
  );
};
