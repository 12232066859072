import { StaticField } from '../Form';
import { useEntityFormContext, useInternationalization } from '../../hooks';
import { ProfileEntity } from '../../models';

export const TwoFactorAuthenticationForm = () => {
  const { t } = useInternationalization();
  const { watch } = useEntityFormContext<ProfileEntity>();
  const phoneNumber = watch('verificationPhoneNumber');

  return (
    <StaticField
      label={t('user:profile.twoFactorAuthentication.phoneNumber')}
      hideLabel={!phoneNumber}
      value={
        phoneNumber
          ? t('user:profile.twoFactorAuthentication.phoneNumberPlaceholder', { phoneNumber })
          : t('user:profile.twoFactorAuthentication.phoneNumberEmptyPlaceholder')
      }
    />
  );
};
