import { Box, Grid } from '@mui/material';
import { cms as cmsApi } from '../../../api';
import { vendor as vendorApi } from '../../../api/vendor';
import { useApi, useAuth, useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { PublicationEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { PublicationUrgency, Styles } from '../../../types';
import {
  ControlledAutocomplete,
  ControlledCheckbox,
  ControlledInput,
  ControlledSelect,
  ControlledTranslatedInput,
} from '../../Form';
import { PeriodForm } from '../../PeriodForm';
import { TargetedMembershipForm } from '../../TargetedMembership';
import { NewReleasesRounded } from '@mui/icons-material';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
  notesHelperText: {
    mt: 1,
  },
  sectionHeader: {
    borderTop: 'solid 1px ' + palette.grey[300],
    pt: 3,
  },
};

export const CmsPublicationForm = () => {
  const { t, getTranslation } = useInternationalization();
  const { isInternalUser } = useAuth();
  const { control, variant, readOnly, watch, getValues } = useEntityFormContext<PublicationEntity>();
  const urgencyOptions = useEnumList(
    PublicationUrgency,
    'cms:publications.form.urgency',
    undefined,
    undefined,
    false,
    undefined,
    palette.PublicationUrgency,
  );
  const { data: publicationCategories } = useApi(cmsApi.getPublicationCategories, { callImmediately: true });

  const vendorWatch = watch('vendor');

  const categoriesOptions = publicationCategories?.map((c) => ({
    value: c.id,
    label: getTranslation(c, 'name'),
    groupName: getTranslation(c, 'groupName'),
  }));

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        {!readOnly && (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              variant="standard"
              label={t('cms:publications.form.name')}
              name="name"
              control={control}
              required
            />
          </Grid>
        )}
        <Grid item xs={variant === 'drawer' ? 12 : 4}>
          <ControlledSelect
            label={t('cms:publications.form.category')}
            name="publicationCategoryId"
            control={control}
            options={categoriesOptions}
            renderValue={(id) => {
              const option = publicationCategories?.find((c) => c.id === id) ?? getValues().category;
              return option ? `${getTranslation(option, 'groupName')} - ${getTranslation(option, 'name')}` : '';
            }}
            required
          />
        </Grid>
        <PeriodForm
          xs={variant === 'drawer' ? 6 : 4}
          sm={variant === 'drawer' ? 6 : 3}
          md={variant === 'drawer' ? 6 : 2}
        />
        {isInternalUser && (
          <Grid item xs={variant === 'drawer' ? 12 : 2}>
            <ControlledSelect
              label={t('cms:publications.form.urgency.title')}
              name="urgency"
              control={control}
              options={urgencyOptions}
              required
            />
          </Grid>
        )}
        {isInternalUser && (
          <Grid item xs={variant === 'drawer' ? 12 : 4}>
            <ControlledAutocomplete
              label={t('cms:publications.form.relatedVendor')}
              name="vendorId"
              control={control}
              value={
                vendorWatch ? `${vendorWatch.fullNumber ? vendorWatch.fullNumber + ' - ' : ''}${vendorWatch.name}` : ''
              }
              apiFunction={vendorApi.getSuggestions}
              placeholder={t('cms:publications.form.relatedVendorPlaceholder')}
              getOptionValue={(vendor) => vendor.id}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
              }}
            />
          </Grid>
        )}
        <Grid item xs={variant === 'drawer' ? 12 : 4}>
          <ControlledInput label={t('cms:publications.form.associatedLink')} name="associatedLink" control={control} />
        </Grid>
        {variant !== 'drawer' && <Grid item xs={4} />}
        {isInternalUser && (
          <Grid item xs={variant === 'drawer' ? 12 : 2}>
            <ControlledCheckbox
              label={t('cms:publications.form.isMandatory')}
              name="isMandatory"
              icon={NewReleasesRounded}
              control={control}
            />
          </Grid>
        )}
        {isInternalUser && (
          <Grid item xs={variant === 'drawer' ? 12 : 2}>
            <ControlledCheckbox
              label={t('cms:publications.form.appearsOnCalendar')}
              name="appearsOnCalendar"
              control={control}
            />
          </Grid>
        )}
      </Grid>
      {variant === 'drawer' && <TargetedMembershipForm />}
    </Box>
  );
};
