import { ModeCommentRounded } from '@mui/icons-material';
import { Box, MenuItem, SelectChangeEvent, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { categorization as categorizationApi } from '../../api';
import { CategorizationContext } from '../../contexts';
import { useApi, useInternationalization, useAuth } from '../../hooks';
import { Select } from '../Form';
import { CategorizationEntity } from '../../models';
import { ArchiveFilter, CategorizationSortOrder, Styles } from '../../types';
import { ArchiveFilterSelect } from '../Filter/ArchiveFilterSelect';
import { ArchiveMenuItem } from '../Menu';
import { Table, TableColumn, TableReorderEvent } from '../Table';
import { CategorizationEditDrawer } from './CategorizationEditDrawer';
import { PermissionKey } from '../../types';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
};

export const CategorizationListView = () => {
  const { t, getTranslation } = useInternationalization();
  const { type, data, setData, translationContext, isLoading, fetchData, isFieldVisible } =
    useContext(CategorizationContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const [filter, setFilter] = useState<ArchiveFilter>({});
  const [editingEntity, setEditingEntity] = useState<CategorizationEntity | null>(null);
  const updateSorting = useApi(categorizationApi.updateSorting, null);
  const archiveApi = useApi(categorizationApi.archive, { successKey: 'common:success.action' });
  const reorderApi = useApi(categorizationApi.updateDisplayOrder, null);
  const { hasPermissions } = useAuth();

  const toggleEditDrawer = (value: CategorizationEntity | null) => () => setEditingEntity(value);

  const onSortOrderChange = async (e: SelectChangeEvent<unknown>) => {
    const selectedSortOrder = e.target.value as CategorizationSortOrder;
    setData((prev) => (prev ? { ...prev, sortOrder: selectedSortOrder } : null));
    const results = await updateSorting.call(type, { sortOrder: selectedSortOrder });
    setData(results);
  };

  const renderSelectValue = (value: unknown) => {
    const order = value as CategorizationSortOrder;
    switch (order) {
      case CategorizationSortOrder.NAME:
        return t('categorization:sortOrder.name');
      case CategorizationSortOrder.DISPLAYORDER:
        return t('categorization:sortOrder.displayOrder');
    }
  };

  const onArchiveConfirm = async (isArchived: boolean, category: CategorizationEntity | null) => {
    if (category) {
      await archiveApi.call(type, isArchived, [category.id]);
      fetchData();
    }
  };

  const actionsMenuItems = (category: CategorizationEntity, onClick: () => void) => [
    <ArchiveMenuItem
      key="archive"
      entity={category}
      name={getTranslation(category, 'name')}
      onClick={onClick}
      onArchiveConfirm={onArchiveConfirm}
      actionSuffix={t(`categorization:${type}.actionSuffix`)}
    />,
    ...(!category.isArchived
      ? [
          <MenuItem
            id="edit"
            key="edit"
            onClick={() => {
              onClick();
              toggleEditDrawer(category)();
            }}
          >
            {t('common:edit')}
          </MenuItem>,
        ]
      : []),
  ];

  const filteredData = useMemo(
    () =>
      data?.values.filter((category) => filter.isArchived === undefined || filter.isArchived === category.isArchived) ??
      [],
    [data, filter.isArchived],
  );

  const onTableReorder = async (e: TableReorderEvent) => {
    if (!data) {
      return;
    }

    // Retrieve indexes from unfiltered data, using zero if not present
    const fromIndex = Math.max(data.values.indexOf(filteredData[e.fromIndex]), 0);
    const toIndex = Math.max(data.values.indexOf(filteredData[e.toIndex]), 0);
    // Reorder data and set temporary data while waiting for API results
    const reorderedData = [...data.values];
    reorderedData.splice(toIndex, 0, reorderedData.splice(fromIndex, 1)[0]);
    setData((prev) => (prev ? { ...prev, values: reorderedData } : null));
    // Send new order to API
    const results = await reorderApi.call(
      type,
      reorderedData.map((category, index) => ({ id: category.id, displayOrder: index })),
    );
    setData(results);
  };

  return (
    <Stack>
      <Box sx={style.selectSmallContainer}>
        <Select
          value={data?.sortOrder ?? ''}
          displayEmpty
          onChange={onSortOrderChange}
          renderValue={renderSelectValue}
          label={t('categorization:sortOrder.title')}
          options={[
            { label: t('categorization:sortOrder.name'), value: CategorizationSortOrder.NAME },
            { label: t('categorization:sortOrder.displayOrder'), value: CategorizationSortOrder.DISPLAYORDER },
          ]}
        />
      </Box>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext={translationContext} />
      <Table
        actionMenuItems={ hasPermissions(PermissionKey.AdminEditCategorization) ? actionsMenuItems: undefined }
        data={filteredData}
        onReorder={onTableReorder}
        reorderable={data?.sortOrder === CategorizationSortOrder.DISPLAYORDER}
        translationNamespace="categorization"
        isLoading={isLoading}
      >
        {matches && isFieldVisible('abbreviation') && <TableColumn type="property" id="abbreviation_En" width="15%" />}
        <TableColumn type="property" id="name_En" width={matches && isFieldVisible('abbreviation') ? '30%' : '47.5%'} />
        {matches && isFieldVisible('abbreviation') && <TableColumn type="property" id="abbreviation_Fr" width="15%" />}
        <TableColumn type="property" id="name_Fr" width={matches && isFieldVisible('abbreviation') ? '30%' : '47.5%'} />
        <TableColumn type="icon" tooltip width="5%" id="note" align="right" iconComponent={ModeCommentRounded} />
      </Table>
      <CategorizationEditDrawer open={!!editingEntity} onClose={toggleEditDrawer(null)} entity={editingEntity} />
    </Stack>
  );
};
