import { SvgIconComponent } from '@mui/icons-material';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  Stack,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { createElement } from 'react';
import { palette } from '../../../styles/palette';
import { Styles } from '../../../types';
import { Container } from './Container';
import React from 'react';

const style: Styles = {
  stackedContainer: {
    alignItems: 'flex-start',
  },
  stackedCheckbox: {
    pl: 0,
  },
  icon: {
    fontSize: 'small',
    htmlColor: palette.grey[500],
  },
};

interface CheckboxProps extends Omit<MuiCheckboxProps, 'variant' | 'icon'> {
  label?: string | JSX.Element;
  variant?: 'inline' | 'stacked';
  hideLabel?: boolean;
  icon?: SvgIconComponent;
}

export const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ hideLabel, label, icon, variant = 'inline', ...props }, ref) =>
    variant === 'inline' ? (
      <FormControlLabel
        control={<MuiCheckbox {...props} ref={ref} />}
        label={
          <Stack direction="row" spacing={1}>
            <Typography display="block" variant="label">
              {label}
            </Typography>
            {icon && createElement(icon, style.icon as SvgIconProps)}
          </Stack>
        }
      />
    ) : (
      <Container label={label} hideLabel={hideLabel} sx={style.stackedContainer} icon={icon}>
        <MuiCheckbox {...props} ref={ref} sx={style.stackedCheckbox} />
      </Container>
    ),
);
