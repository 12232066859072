import { LocalActivity } from '@mui/icons-material';
import {
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { cci as cciApi, member as memberApi } from '../../../api';
import { DOLLAR_NO_DECIMAL, MAX_PAGINATION_FILTER } from '../../../constants';
import { CommitmentEventDetailContext } from '../../../contexts';
import {
  useApi,
  useAuth,
  useEntityFormContext,
  useEntityFormFieldArray,
  useInternationalization,
} from '../../../hooks';
import { MemberCommitmentEntity, MemberCommitmentVendorEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { LanguageCode, RebateTypeAndUnitType, Styles } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { ControlledAutocomplete, ControlledCheckbox, ControlledInput, ControlledSelect, StaticField } from '../../Form';

const styles: Styles = {
  tableReadOnly: {
    '& .MuiTableRow-root .MuiTableCell-root': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
};

const defaultFilter = { ...MAX_PAGINATION_FILTER, isArchived: false, isCommitted: false };

export const MemberCommitmentForm = () => {
  const { t, currentLanguage, getTranslation } = useInternationalization();
  const { isInternalUser } = useAuth();
  const { control, readOnly, watch, setValue, reset, getValues } = useEntityFormContext<MemberCommitmentEntity>();
  const vendors = useEntityFormFieldArray(control, 'vendors');
  const { eventId } = useContext(CommitmentEventDetailContext);
  const {
    data: memberCommitments,
    call: fetchMemberCommitments,
    isLoading: loadingMemberCommitments,
  } = useApi(cciApi.getAllMemberCommitments, null);
  const isEditing = !!getValues().id;

  const commitmentEventCategoryId = watch('commitmentEventCategoryId');
  const commitmentEventCategory = watch('commitmentEventCategory');
  const memberId = watch('memberId');
  const member = watch('member');

  const matchingMemberCommitment = memberCommitments?.data?.find(
    (x) => x.commitmentEventCategoryId === commitmentEventCategoryId,
  );

  const { data: categories } = useApi(
    cciApi.getAllCommitmentEventCategories,
    { skipFetch: !isInternalUser },
    eventId,
    defaultFilter,
    memberId ?? null,
  );

  const willSupportOptions = [
    { label: '', value: '' },
    { label: t('common:boolean.false'), value: false },
    { label: t('common:boolean.true'), value: true },
  ];

  const categoryOptions = useMemo(
    () => categories?.data.map((category) => ({ value: category.id, label: getTranslation(category, 'name') })) ?? [],
    [getTranslation, categories],
  );

  const moneyFormatter = useMemo(
    () =>
      numberFormatter(
        currentLanguage,
        t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Dollar}`),
        DOLLAR_NO_DECIMAL,
        currentLanguage !== LanguageCode.en,
      ),
    [currentLanguage, t],
  );

  useEffect(() => {
    if (isInternalUser && !matchingMemberCommitment && !loadingMemberCommitments) {
      const category = categories?.data.find((c) => c.id === commitmentEventCategoryId);
      if (category) {
        category?.vendors &&
          setValue(
            'vendors',
            category.vendors.map(
              (v) =>
                new MemberCommitmentVendorEntity({
                  vendor: v.vendor,
                  vendorId: v.vendorId,
                  isPreferred: v.isPreferred,
                  isCurrent: false,
                  willSupport: false,
                }),
            ),
          );
      } else {
        setValue('vendors', []);
      }
    }
  }, [
    loadingMemberCommitments,
    categories?.data,
    commitmentEventCategoryId,
    isInternalUser,
    matchingMemberCommitment,
    setValue,
  ]);

  useEffect(() => {
    if (!memberId) {
      setValue('commitmentEventCategoryId', -1);
      setValue('vendors', []);
      setValue('volume', undefined);
    }
  }, [memberId, setValue]);

  useEffect(() => {
    if (isInternalUser && commitmentEventCategoryId && memberId) {
      fetchMemberCommitments(eventId, { memberId, ...MAX_PAGINATION_FILTER });
    }
  }, [commitmentEventCategoryId, memberId, eventId, fetchMemberCommitments, isInternalUser]);

  useEffect(() => {
    if (matchingMemberCommitment) {
      reset({ ...matchingMemberCommitment, vendors: matchingMemberCommitment.vendors.map((x) => ({ ...x })) });
    }
  }, [matchingMemberCommitment, reset]);

  return (
    <Grid container spacing={2}>
      {isInternalUser && (
        <>
          <Grid item xs={12}>
            {isEditing ? (
              <StaticField
                value={`${member.fullNumber ? member.fullNumber + ' - ' : ''}${member.name}`}
                label={t('cci:eventCommitments.form.member')}
              ></StaticField>
            ) : (
              <ControlledAutocomplete
                minSearchTextLength={1}
                name="memberId"
                noOptionsText={t('member:search.notFound')}
                apiFunction={memberApi.getSuggestions}
                label={t('member:search.title')}
                placeholder={t('member:search.placeholder')}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
                }}
                getOptionValue={(option) => option.id}
                control={control}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {isEditing ? (
              <StaticField
                value={getTranslation(commitmentEventCategory, 'name')}
                label={t('cci:eventCommitments.form.category')}
              ></StaticField>
            ) : (
              <ControlledSelect
                name="commitmentEventCategoryId"
                control={control}
                options={categoryOptions}
                label={t('cci:eventCommitments.form.category')}
                placeholder={t('cci:eventCommitments.form.categoryPlaceholder')}
              />
            )}
          </Grid>
        </>
      )}
      {isInternalUser && !!matchingMemberCommitment && (
        <Grid item xs={12}>
          <Alert severity="warning">{t('cci:memberCommitments.memberCategoryCombinationAlreadyExistsWarning')}</Alert>
        </Grid>
      )}
      <Grid item xs={readOnly ? 9 : 12}>
        <TableContainer>
          <Table sx={readOnly ? styles.tableReadOnly : undefined}>
            <TableHead>
              <TableRow>
                <TableCell width="60%">{t('cci:memberCommitments.form.vendor')}</TableCell>
                <TableCell width="20%">{t('cci:memberCommitments.form.isCurrent')}</TableCell>
                <TableCell width="20%">
                  {t('cci:memberCommitments.form.willSupport')}
                  {!readOnly ? '*' : ''}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendors.fields.map((item: MemberCommitmentVendorEntity, index) => (
                <TableRow key={item.vendor?.id}>
                  <TableCell>
                    <Typography>
                      {item.vendor?.name} {item.isPreferred && <LocalActivity htmlColor={palette.grey[300]} />}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <ControlledCheckbox hideLabel label="" name={`vendors.${index}.isCurrent`} control={control} />
                  </TableCell>
                  <TableCell>
                    <ControlledSelect
                      hideLabel
                      label={t('cci:memberCommitments.form.willSupport')}
                      name={`vendors.${index}.willSupport`}
                      control={control}
                      options={willSupportOptions}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={readOnly ? 3 : 12}>
        <ControlledInput
          label={t('cci:memberCommitments.form.volume')}
          name="volume"
          control={control}
          formatter={moneyFormatter}
          orientation="horizontal"
          required
        />
      </Grid>
    </Grid>
  );
};
