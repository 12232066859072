import { Box, lighten } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { Styles } from '../../../types';
import { Attachments } from '../Base/Attachments/Attachments';

const style: Styles = {
  dirty: {
    backgroundColor: lighten(palette.secondary.orange, 0.85),
    border: '1px solid ' + palette.secondary.orange,
    borderRadius: 3,
    padding: 2,
  },
};

interface ControlledAttachmentProps
  extends Omit<React.ComponentProps<typeof Attachments>, 'onChange' | 'files' | 'maxCount'> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  hideLabel?: boolean;
  required?: boolean;
}

export const ControlledAttachment = ({ name, control, required, label, ...props }: ControlledAttachmentProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => {
        const onFileChange = (files: File[]) => {
          onChange(files[0] ?? null);
          onBlur();
        };

        const hasFileChanged = () => {
          const original = formState.defaultValues[name] as FileEntity;
          const current = value as FileEntity;
          return original?.id !== current?.id && !control._options.context?.readOnly;
        };

        return (
          <Box sx={control._options.context?.variant === 'changeRequest' && hasFileChanged() ? style.dirty : undefined}>
            <Attachments
              {...props}
              onChange={onFileChange}
              maxCount={1}
              files={value ? [value] : []}
              readOnly={control._options.context?.readOnly || props.readOnly}
              error={!!error}
              helperText={error?.message && t(error?.message, { name: label })}
              hideLabel={props.hideLabel}
              label={required ? `${label} *` : label}
            />
          </Box>
        );
      }}
    />
  );
};
