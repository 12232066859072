import { Chip, Grid, List, Paper, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { search as searchApi } from '../../api';
import { SearchContext } from '../../contexts';
import { useApi, useAuth, useEnumList, useInternationalization } from '../../hooks';
import { SearchCategory, SearchPeriod, SearchSort, Styles } from '../../types';
import { Select } from '../Form';
import { LayoutPage } from '../Layout';
import { Pagination } from '../Pagination';
import { SearchResult } from './SearchResult';
import { searchCategoriesPermissions } from '../../utils/searchUtils';
import { Loading } from '../Loading';
import { PAGINATION_ROWS_PER_PAGE_OPTIONS } from '../../constants';

const styles: Styles = {
  select: {
    width: { xs: '40vw', md: 220 },
  },
};

export const Search = () => {
  const { hasPermissions, userType } = useAuth();
  const { t } = useInternationalization();
  const { searchRequest, setSearchRequest, setSearchHasUnmount } = useContext(SearchContext);
  const { data, isLoading } = useApi(searchApi.search, { skipFetch: !searchRequest.query }, searchRequest);

  const searchCategoryOptions = useEnumList(SearchCategory, 'navigation:search.searchCategory', true, undefined, false);
  const searchPeriodOptions = useEnumList(SearchPeriod, 'navigation:search.searchPeriod', true, 'female', false);
  const searchSortOptions = useEnumList(SearchSort, 'navigation:search.searchSort', false, undefined, false);

  const onSearchPeriodChange = (event: SelectChangeEvent<SearchPeriod>) =>
    setSearchRequest((prev) => ({
      ...prev,
      period: event.target.value ? (event.target.value as SearchPeriod) : undefined,
      pageNumber: 1,
    }));

  const onSearchSortChange = (event: SelectChangeEvent<SearchSort>) =>
    setSearchRequest((prev) => ({ ...prev, sort: event.target.value as SearchSort, pageNumber: 1 }));

  const onSearchCategoryChange = (category: SearchCategory) => () =>
    setSearchRequest((prev) => ({ ...prev, categories: !category ? undefined : [category], pageNumber: 1 }));

  const searchCategoryOptionsFiltered = useMemo(
    () =>
      searchCategoryOptions.filter(
        (c) => c.value.toString() === '' || hasPermissions(searchCategoriesPermissions[userType][c.value], true),
      ),
    [hasPermissions, searchCategoryOptions, userType],
  );

  useEffect(() => {
    setSearchHasUnmount(false);

    return () => {
      setSearchHasUnmount(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutPage title={t('navigation:search.pageTitle', { query: searchRequest.query })}>
      <Stack spacing={3}>
        <Grid container spacing={2}>
          <Grid item>
            <Select
              displayEmpty
              sx={styles.select}
              options={searchPeriodOptions}
              value={searchRequest.period ?? ''}
              onChange={onSearchPeriodChange}
              label={t('navigation:search.period')}
            />
          </Grid>
          <Grid item>
            <Select
              displayEmpty
              sx={styles.select}
              options={searchSortOptions}
              value={searchRequest.sort ?? ''}
              onChange={onSearchSortChange}
              label={t('navigation:search.sort')}
            />
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography align="center" variant="label" color="grey.600">
            {t('common:show')}
          </Typography>
          <Stack spacing={0.7} direction="row" flexWrap="wrap">
            {searchCategoryOptionsFiltered.map((option) => (
              <Chip
                key={option.value}
                label={option.label}
                onClick={onSearchCategoryChange(option.value)}
                color={
                  (!option.value && !searchRequest.categories) || searchRequest.categories?.includes(option.value)
                    ? 'primary'
                    : 'secondary'
                }
              />
            ))}
          </Stack>
        </Stack>
        {isLoading ? (
          <Loading />
        ) : (
          <Typography variant="h6">{t('common:table.results', { count: data?.totalCount ?? 0 })}</Typography>
        )}
        {!!data && !!data.data.length && (
          <Paper>
            <List>
              {data.data.map((item) => (
                <SearchResult key={item.id} item={item} />
              ))}
            </List>
          </Paper>
        )}
        {!!data && !!searchRequest.pageNumber && !!searchRequest.pageSize && (
          <Pagination
            count={data.totalCount}
            page={searchRequest.pageNumber - 1}
            onPageChange={(_, page) => {
              searchRequest.pageSize && setSearchRequest((prev) => ({ ...prev, pageNumber: page + 1 }));
            }}
            rowsPerPage={searchRequest.pageSize}
            rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
            onRowsPerPageChange={(e) =>
              searchRequest.pageSize &&
              setSearchRequest((prev) => ({ ...prev, pageNumber: 1, pageSize: parseInt(e.target.value) }))
            }
          />
        )}
      </Stack>
    </LayoutPage>
  );
};
