import { mixed, object, string } from 'yup';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorLocationEntity } from '../../models';
import { Country, EntityFormSchema, Province } from '../../types';
import { AddressSchema, EmailAddressSchema, PhoneNumberSchema } from '../Contact';

export const VendorLocationSchema = (): EntityFormSchema<VendorLocationEntity> => ({
  schema: object({
    description_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
    description_Fr: string().max(MAX_SHORT_TEXT_LENGTH),
    ...PhoneNumberSchema(),
    address: AddressSchema(true).schema.concat(
      object({
        country: mixed<Country>().oneOf(Object.values(Country)).required(),
        street: string().required().max(MAX_SHORT_TEXT_LENGTH),
        city: string().required().max(MAX_SHORT_TEXT_LENGTH),
        province: mixed<Province>().oneOf(Object.values(Province)).required(),
      }),
    ),
  })
    .concat(PhoneNumberSchema().schema)
    .concat(EmailAddressSchema().schema),
  errorCodeMap: {},
});
