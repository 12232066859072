import { PermissionCategory, PermissionKey } from '../../types';

export class PermissionEntity {
  id!: PermissionKey;
  name_Fr: string;
  name_En: string;
  description_Fr: string;
  description_En: string;
  category!: PermissionCategory;

  constructor(entity?: PermissionEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.name_Fr = '';
      this.name_En = '';
      this.description_Fr = '';
      this.description_En = '';
    }
  }
}
