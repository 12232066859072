import { Box, SelectChangeEvent, Stack } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberGroupContext } from '../../contexts';
import { useEnumList } from '../../hooks';
import { MemberGroupFilter, MemberGroupType, Styles } from '../../types';
import { ArchiveFilterSelect } from '../Filter/ArchiveFilterSelect';
import { Select } from '../Form';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '250px',
    },
  },
};

export const MemberGroupListSearch = () => {
  const { filter, setFilter } = useContext(MemberGroupContext);
  const { t } = useTranslation();
  const memberGroupTypeOptions = useEnumList(MemberGroupType, 'memberGroup:type', true, 'male', false);

  const onTypeChange = (event: SelectChangeEvent<unknown>, propertyName: keyof MemberGroupFilter) => {
    const value = !event.target.value ? undefined : event.target.value;
    setFilter((prev) => ({ ...prev, [propertyName]: value, pageNumber: 1 }));
  };

  return (
    <Stack>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={memberGroupTypeOptions}
          value={filter.type ?? ''}
          onChange={(e) => onTypeChange(e, 'type')}
          label={t('memberGroup:search.type')}
        />
      </Box>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />
    </Stack>
  );
};
