import { MemberGroupEntity } from '../Member';
import { BuyOpportunityEntity } from './BuyOpportunityEntity';
import { VendorEntity } from './VendorEntity';

export class VendorBuyOpportunityEntity extends BuyOpportunityEntity {
  vendorId: number | null;
  vendor: VendorEntity;
  participatingDCIds: number[];
  participatingDCs: MemberGroupEntity[];
  latestChange?: Date;

  constructor(entity?: VendorBuyOpportunityEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.vendorId = entity.vendor?.id;
      this.vendorBuyingAgreementId = entity.vendorBuyingAgreement?.id;
      this.participatingDCIds = entity.participatingDCs?.map((p) => p.id);
    } else {
      this.vendorId = null;
      this.participatingDCIds = [];
      this.participatingDCs = [];
    }
  }
}
