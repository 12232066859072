import { AddRounded, CheckRounded, PriorityHighRounded } from '@mui/icons-material';
import { Box, Button, Link, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { AdEntity } from '../../../models';
import { routes } from '../../../routes';
import { AdSchema } from '../../../schemas';
import {
  AdFilter,
  AdType,
  ContentFilter,
  ContentStatus,
  LanguageCode,
  OrderBy,
  PermissionKey,
  Styles,
  TranslationLanguage,
} from '../../../types';
import { formatShortDate } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { EditDrawer } from '../../EditDrawer';
import { Autocomplete, Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { CmsContentStatus } from '../CmsContentStatus';
import { CmsAdForm } from './CmsAdForm';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
    my: 3,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
};

const defaultFilter: AdFilter = {
  adName: '',
  type: undefined,
  status: undefined,
  filter: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

export const CmsAdList = () => {
  const { t } = useInternationalization();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const ad = useMemo(() => new AdEntity(), []);
  const [adType, setAdType] = useState(AdType.Image);
  const [filter, setFilter] = useState(defaultFilter);
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const filterOptions = useEnumList(ContentFilter, 'cms:showFilter', false, undefined, false);
  const statusOptions = useEnumList(ContentStatus, 'cms:status', true);
  const typeOptions = useEnumList(AdType, 'cms:ads.table.typeValue', true);
  const { call: create } = useApi(cmsApi.createAd, null);
  const { call: upload } = useApi(cmsApi.uploadAdFile, { successKey: 'common:success.action' });
  const { data: ads, isLoading, refresh } = useApi(cmsApi.getAllAds, null, filter);
  usePageTitle('cms:ads.title');

  const saveDrawer = async (entity: AdEntity) => {
    const createdEntity = await create(entity);
    if (createdEntity && createdEntity.id) {
      [TranslationLanguage.En, TranslationLanguage.Fr].forEach(async (language) => {
        const file = entity[`file_${language}`];
        if (file) {
          createdEntity[`file_${language}`] = file;
          enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
          await upload(createdEntity.id, language.toLocaleLowerCase() as LanguageCode, file as unknown as File);
        }
      });
    } else {
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    await refresh();
    return createdEntity;
  };

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({
      ...prev,
      ...(checked ? { ...defaultFilter, filter: ContentFilter.Upcoming } : defaultFilter),
    }));
    setIsAdvancedMode(checked);
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const clearFilter = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter, filter: ContentFilter.Upcoming }));
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, filter: event.target.value as ContentFilter, pageNumber: 1 }));
  };

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, type: (event.target.value as AdType) || undefined, pageNumber: 1 }));
  };

  const onStatusChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, status: (event.target.value as ContentStatus) || undefined, pageNumber: 1 }));
  };

  const getDetailPath = (item: AdEntity) => generatePath(routes.Admin.AdDetails.path, { adId: item.id });

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.CmsAdEdit}>
      <Button variant="contained" startIcon={<AddRounded />} onClick={() => setIsAddOpen(true)}>
        {t('cms:ads.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderActionsMenuItems = (item: AdEntity) => {
    return [
      <MenuItem id="view" key="view" onClick={() => navigate(getDetailPath(item))}>
        {t('common:view')}
      </MenuItem>,
    ];
  };

  const renderAdLink = (id: number, text: string | undefined) =>
    !!text && (
      <Link underline="always" href={generatePath(routes.Admin.AdDetails.path, { adId: id })}>
        {text}
      </Link>
    );

  const renderTextSearch = () => (
    <Autocomplete
      value={filter.adName}
      sx={style.textContainer}
      noOptionsText={t('cms:ads.search.notFound')}
      apiFunction={cmsApi.getAdSuggestions}
      label={t('cms:ads.search.title')}
      placeholder={t('cms:ads.search.placeholder')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      onApplySearch={(searchText) => setFilter((prev) => ({ ...prev, adName: searchText ?? undefined, pageNumber: 1 }))}
      onSelectItem={(item) => item && navigate(getDetailPath(item))}
    />
  );
  const renderAdvancedSearch = () => (
    <FilterContainer onClear={clearFilter} my={3}>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={filterOptions}
          value={filter.filter ?? ''}
          onChange={onFilterChange}
          label={t('cms:ads.search.show')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={typeOptions}
          value={filter.type ?? ''}
          onChange={onTypeChange}
          label={t('cms:ads.search.type')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={statusOptions}
          value={filter.status ?? ''}
          onChange={onStatusChange}
          label={t('cms:ads.search.status')}
        />
      </Box>
    </FilterContainer>
  );

  const renderTable = () => (
    <Table
      data={ads ?? []}
      translationNamespace="cms:ads"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={renderActionsMenuItems}
    >
      <TableColumn
        type="custom"
        sortable
        id="status"
        render={(item: AdEntity) => <CmsContentStatus status={item.status} />}
      />
      <TableColumn
        type="custom"
        sortable
        width="15%"
        id="name"
        render={(item: AdEntity) => renderAdLink(item.id, item.name)}
      />
      <TableColumn
        type="custom"
        width="10%"
        id="type"
        render={(item: AdEntity) => t(`cms:ads.table.typeValue.${item.type}`)}
      />
      <TableColumn
        type="custom"
        sortable
        width="15%"
        id="effectiveDate"
        render={({ effectiveDate }: AdEntity) => formatShortDate(effectiveDate)}
      />
      <TableColumn
        type="custom"
        sortable
        width="15%"
        id="expiryDate"
        render={({ expiryDate }: AdEntity) => formatShortDate(expiryDate)}
      />
      <TableColumn type="custom" sortable width="15%" id="submittedBy" render={(item: AdEntity) => item.modifiedBy} />
      <TableColumn type="icon" sortable id="hasPriority" iconComponent={PriorityHighRounded} />
      <TableColumn type="icon" sortable align="center" id="isActive" iconComponent={CheckRounded} />
    </Table>
  );

  return (
    <LayoutPage
      display="Tab"
      title={t('cms:ads.title')}
      rightTitle={renderAddButton()}
      permissions={{
        keys: PermissionKey.CmsAdView,
      }}
    >
      <EditDrawer
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <AddRounded />
            <Typography variant="drawerTitle">{t('cms:ads.actions.add')}</Typography>
          </Stack>
        }
        open={isAddOpen}
        redirectPath={getDetailPath}
        entity={ad}
        schema={AdSchema(adType)}
        redirectLabel={t('cms:ads.actions.saveOpen')}
        onSave={saveDrawer}
        onConfirm={confirmDrawer}
        onCancel={cancelDrawer}
      >
        <CmsAdForm setAdType={setAdType} />
      </EditDrawer>
      <Box sx={style.filterBox}>
        <Typography variant="searchTitle" color="primary.dark">
          {t('cms:ads.search.title')}
        </Typography>
        <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
        <Typography variant="searchTitle" color="grey.600">
          {t('cms:ads.search.filterList')}
        </Typography>
      </Box>
      {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
      {renderTable()}
    </LayoutPage>
  );
};
