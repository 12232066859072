import { SvgIconComponent } from '@mui/icons-material';
import { Link, Typography, TypographyProps } from '@mui/material';
import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Styles } from '../../../types';
import { Container } from './Container';
import { palette } from '../../../styles/palette';

export type LinkType = 'email' | 'phone' | 'url';

const style: Styles = {
  typography: {
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    maxWidth: '65ch',
  },
  link: {
    textDecoration: 'underline',
    color: palette.primary.deep,
  },
};

interface StaticFieldProps {
  label?: string;
  value?: string | number | ReactNode;
  hideLabel?: boolean;
  sx?: Styles[0];
  variant?: TypographyProps['variant'];
  icon?: SvgIconComponent | JSX.Element;
  linkType?: LinkType;
  rawRender?: boolean;
}

export const StaticField = ({
  label,
  icon,
  hideLabel,
  sx,
  variant,
  linkType,
  rawRender,
  ...props
}: StaticFieldProps) => {
  const { t } = useTranslation();

  const getLinkType = () => {
    let hrefType = '';
    let target: HTMLAttributeAnchorTarget | undefined = undefined;
    switch (linkType) {
      case 'email':
        hrefType = 'mailto:';
        break;
      case 'phone':
        hrefType = 'tel:';
        break;
      case 'url':
        hrefType =
          props.value &&
          typeof props.value === 'string' &&
          (props.value.includes('https://') || props.value.includes('http://'))
            ? ''
            : '//';
        target = '_blank';
        break;

      default:
        break;
    }

    return (
      <Link
        variant={variant || 'body1'}
        component="a"
        sx={{ ...style.typography, ...style.link }}
        href={`${hrefType}${props.value}`}
        target={target}
      >
        {props.value}
      </Link>
    );
  };

  return (
    <Container label={label} icon={icon} hideLabel={hideLabel} sx={sx}>
      {props.value || props.value === false || props.value === 0 ? (
        linkType ? (
          getLinkType()
        ) : !rawRender ? (
          <Typography variant={variant || 'body1'} sx={style.typography}>
            {props.value}
          </Typography>
        ) : (
          props.value
        )
      ) : (
        <Typography variant="valuePlaceholder" sx={style.typography}>
          {t('common:notSpecified')}
        </Typography>
      )}
    </Container>
  );
};
