import { AddRounded, EditRounded, ModeCommentRounded } from '@mui/icons-material';
import { Box, Button, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { EditDrawer, EntityPeriod, LimitCommaValues } from '../..';
import { useAuth, useEnumList, useGlobalEdit, useInternationalization } from '../../../hooks';
import { VendorDocumentEntity } from '../../../models';
import { VendorDocumentSchema } from '../../../schemas/Vendor';
import {
  BaseComponentListProps,
  DocumentType,
  OrderBy,
  PermissionKey,
  Styles,
  VendorDocumentFilter,
} from '../../../types';
import { downloadBlob, formatLongDate, formatShortDate } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { PeriodFilterSelect } from '../../Filter/PeriodFilterSelect';
import { Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { VendorDocumentForm } from './VendorDocumentForm';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
};

interface VendorDocumentsListProps
  extends BaseComponentListProps<VendorDocumentEntity, VendorDocumentFilter>,
    React.ComponentProps<typeof VendorDocumentForm> {
  onFileDownload: (entity: VendorDocumentEntity) => Promise<Blob | null>;
  defaultFilter: VendorDocumentFilter;
}

export const VendorDocumentsList = ({
  addButtonProps,
  tableProps,
  showSearch,
  onSave,
  fetchApi,
  filter,
  setFilter,
  layout = 'Tab',
  isChangeRequest,
  onFileDownload,
  defaultFilter,
  rebateCategories,
}: VendorDocumentsListProps) => {
  const { isInternalUser } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();

  const { data, isLoading, refresh } = fetchApi;
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [vendorDocument, setVendorDocument] = useState<VendorDocumentEntity>(new VendorDocumentEntity());

  const typeOptions = useEnumList(DocumentType, 'vendor:document.type', true);

  const downloadFile = async (entity: VendorDocumentEntity) => {
    if (entity.id && entity.file) {
      const fileData = await onFileDownload(entity);
      if (fileData) {
        downloadBlob(entity.file.name, fileData);
      }
    }
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      type: (event.target.value as DocumentType) || undefined,
      pageNumber: 1,
    }));
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const renderSearch = () => (
    <Stack mb={!isInternalUser ? 2 : 0}>
      <FilterContainer onClear={onClear}>
        <PeriodFilterSelect filter={filter} setFilter={setFilter} />
        <Box sx={style.selectSmallContainer}>
          <Select
            displayEmpty
            options={typeOptions}
            value={filter.type ?? ''}
            onChange={onTypeChange}
            label={t('vendor:document.search.type')}
          />
        </Box>
      </FilterContainer>
      {isInternalUser && <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />}
    </Stack>
  );

  const renderActionsMenuItems = (entity: VendorDocumentEntity, onClick: () => void) => [
    <MenuItem
      id="download"
      key="download"
      onClick={() => {
        downloadFile(entity);
        onClick();
      }}
    >
      {t('common:download')}
    </MenuItem>,
    ...(!entity.isArchived
      ? [
          <MenuItem
            id="edit"
            key="edit"
            permissions={{ keys: PermissionKey.VendorEditFiles }}
            onClick={() => {
              setVendorDocument(entity);
              setIsAddOpen(true);
              onClick();
            }}
          >
            {t('common:edit')}
          </MenuItem>,
        ]
      : []
    ).concat(tableProps?.actionMenuItems ? tableProps?.actionMenuItems(entity, onClick) : []),
  ];

  const renderPeriod = (entity: VendorDocumentEntity) => (
    <EntityPeriod
      period={entity.period}
      translationContext={entity.type === DocumentType.PriceList ? 'female' : 'male'}
    />
  );

  const renderType = (entity: VendorDocumentEntity) => t(`vendor:document.type.${entity.type}`);

  const renderDescription = (entity: VendorDocumentEntity) => getTranslation(entity, 'description');

  const renderFileName = (entity: VendorDocumentEntity) => entity.file?.name ?? '';

  const renderLanguages = (entity: VendorDocumentEntity) =>
    entity.languages.map((l) => t(`common:language.${l}`)).join(', ');

  const renderRebateCategories = (entity: VendorDocumentEntity) => (
    <LimitCommaValues
      value={entity.rebateCategories.map((rebateCategory) => getTranslation(rebateCategory, 'name')).join(', ')}
      translationContext="female"
      defaultToAll
      maxLength={50}
    />
  );

  const renderLastModified = (entity: VendorDocumentEntity) =>
    t('common:entity.modifiedDateShort', {
      date: formatShortDate(entity.modifiedDate),
      name: entity.modifiedBy,
    });

  const renderNotes = (entity: VendorDocumentEntity) => {
    let notes = '';
    if (isInternalUser) {
      notes = entity.notes;
    } else {
      if (entity.vendorNotes) {
        notes += `${t('vendor:document.form.comments')}: ${entity.vendorNotes}\n`;
      }
      if (getTranslation(entity, 'memberNotes')) {
        notes += `${t('vendor:document.form.memberNotes')}: ${getTranslation(entity, 'memberNotes')}`;
      }
    }
    return notes;
  };

  const renderTable = () =>
    data && (
      <Table
        {...tableProps}
        data={data}
        translationNamespace="vendor:document"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
      >
        <TableColumn type="custom" width="10%" id="period" sortable render={renderPeriod} />
        <TableColumn type="custom" width="10%" id="type" sortable render={renderType} />
        <TableColumn type="custom" width="10%" id="description" sortable render={renderDescription} />
        <TableColumn type="custom" width="10%" id="filename" render={renderFileName} />
        <TableColumn type="custom" width="10%" id="languages" render={renderLanguages} />
        <TableColumn
          type="custom"
          id="effectiveDate"
          width="10%"
          sortable
          render={(entity: VendorDocumentEntity) => formatLongDate(entity.effectiveDate)}
        />
        <TableColumn
          type="custom"
          id="expiryDate"
          width="10%"
          sortable
          render={(entity: VendorDocumentEntity) => formatLongDate(entity.expiryDate)}
        />
        <TableColumn type="custom" width="10%" id="rebateCategories" render={renderRebateCategories} />
        <TableColumn type="custom" width="10%" id="modifiedDate" sortable render={renderLastModified} />
        <TableColumn
          type="icon"
          tooltip
          id="notes"
          align="center"
          render={renderNotes}
          iconComponent={ModeCommentRounded}
        />
      </Table>
    );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.VendorEditFiles}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => {
          setVendorDocument(new VendorDocumentEntity());
          setIsAddOpen(true);
        }}
        {...addButtonProps}
        disabled={addButtonProps?.disabled || globalEditing}
      >
        {t('vendor:document.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {vendorDocument.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(vendorDocument.id ? 'vendor:document.actions.edit' : 'vendor:document.actions.add')}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={vendorDocument}
      schema={VendorDocumentSchema()}
      onSave={onSave}
      onConfirm={() => {
        refresh();
        setIsAddOpen(false);
      }}
      onCancel={() => setIsAddOpen(false)}
      isChangeRequest={isChangeRequest}
    >
      <VendorDocumentForm rebateCategories={rebateCategories} />
    </EditDrawer>
  );

  return (
    <>
      <LayoutPage
        title={t('vendor:document.title')}
        rightTitle={renderAddButton()}
        display={layout}
        permissions={{ keys: PermissionKey.VendorViewFiles }}
      >
        {showSearch && renderSearch()}
        {renderTable()}
        {renderDrawer()}
      </LayoutPage>
    </>
  );
};
