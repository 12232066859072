import { TargetedMembershipMemberEntity, TargetedMembershipMemberGroupEntity } from '.';
import { CategorizationEntity, EditableEntity } from '..';

export class TargetedMembershipEntity extends EditableEntity {
  memberGroups: TargetedMembershipMemberGroupEntity[];
  members: TargetedMembershipMemberEntity[];
  membershipTypeIds: number[];
  membershipTypes: CategorizationEntity[];
  regionIds: number[];
  regions: CategorizationEntity[];
  isBasedOnMemberCriteria: boolean;

  constructor(entity?: TargetedMembershipEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.membershipTypeIds = [];
      this.regionIds = [];
      this.members = [];
      this.memberGroups = [];
      this.isBasedOnMemberCriteria = true;
    }
  }
}
