import { useTranslation } from 'react-i18next';
import { PermissionKey } from '../../../types';
import { LayoutPage } from '../../Layout';
import { InvoicingInformationAdmin } from './InvoicingInformationAdmin';
import { PaymentScheduleAdmin } from './PaymentScheduleAdmin';
import { TaxInformationAdmin } from './TaxInformationAdmin';

export const VendorFinancialsAdmin = () => {
  const { t } = useTranslation();

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewFinancial }}
      title={t('vendor:sections.financials')}
      display="Tab"
    >
      <TaxInformationAdmin />
      <InvoicingInformationAdmin />
      <PaymentScheduleAdmin />
    </LayoutPage>
  );
};
