import { PlayArrowRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  YOUTUBE_EMBED_URL,
  YOUTUBE_THUMBNAIL_URL,
  YOUTUBE_THUMBNAIL_URL_MAX_RESOLUTION_PATH,
  YOUTUBE_URL_REGEX,
} from '../../../constants';
import { palette } from '../../../styles/palette';
import { Styles } from '../../../types';

const styles: Styles = {
  container: {
    width: '100%',
    minHeight: '100%',
    position: 'relative',
    margin: 0,
    cursor: 'pointer',
  },
  playButton: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    fontSize: 56,
    color: palette.white,
    background: palette.primary.main,
    borderRadius: 33,
  },
  video: {
    display: 'block',
    height: 0,
    margin: 'auto',
    overflow: 'hidden',
    padding: '0% 0% 56.25%',
    position: 'relative',
    width: '100%',
    '& iframe': {
      border: 0,
      bottom: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
};

interface Props {
  isVideoPlaying: boolean;
  videoUrl: string;
  onThumbnailClick?: () => void;
}

export const CmsAdViewVideo = ({ videoUrl, isVideoPlaying, onThumbnailClick }: Props) => {
  const youTubeVideoId = videoUrl.match(YOUTUBE_URL_REGEX)?.[1];

  const getVideoThumb = () => `${YOUTUBE_THUMBNAIL_URL}${youTubeVideoId}${YOUTUBE_THUMBNAIL_URL_MAX_RESOLUTION_PATH}`;

  return (
    <Box mt={2} sx={styles.container} onClick={onThumbnailClick}>
      {!isVideoPlaying ? (
        <>
          <img src={getVideoThumb()} />
          <PlayArrowRounded sx={styles.playButton} fontSize="large" />
        </>
      ) : (
        <Box sx={styles.video}>
          <iframe id="player" src={`${YOUTUBE_EMBED_URL}${youTubeVideoId}?autoplay=1`} />
        </Box>
      )}
    </Box>
  );
};
