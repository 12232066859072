import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext } from '../../../hooks';
import { OfficeEntity } from '../../../models';
import { ControlledTranslatedInput } from '../../Form';

export const OfficeHeader = () => {
  const { t } = useTranslation();
  const { control, readOnly } = useEntityFormContext<OfficeEntity>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ControlledTranslatedInput
          maxLength={MAX_SHORT_TEXT_LENGTH}
          label={t('contact:office.form.name')}
          name="description"
          isFrRequired
          variant="standard"
          control={control}
          hideLabel={readOnly}
          required
        />
      </Grid>
    </Grid>
  );
};
