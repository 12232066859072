import axios from 'axios';
import { VendorDocumentEntity, VendorEntityChangeRequestEntity, VendorRebateCategoryEntity } from '../../models';
import { ChangeRequestTrigger, PaginationEntity, VendorDocumentFilter, VendorRebateCategoryFilter } from '../../types';

export const document = {
  getAllDocuments: async (
    vendorId: number,
    documentFilter: VendorDocumentFilter,
  ): Promise<PaginationEntity<VendorDocumentEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/documents/search`, documentFilter);
    return { ...data, data: data.data.map((v: VendorDocumentEntity) => new VendorDocumentEntity(v)) };
  },
  createDocument: async (vendorId: number, entity: VendorDocumentEntity): Promise<VendorDocumentEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/documents`, entity);
    return new VendorDocumentEntity(data);
  },
  updateDocument: async (vendorId: number, entity: VendorDocumentEntity): Promise<VendorDocumentEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/documents/${entity.id}`, entity);
    return new VendorDocumentEntity(data);
  },
  getDocumentFile: async (vendorId: number, vendorDocumentId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/documents/${vendorDocumentId}/file`, {
      responseType: 'blob',
    });
    return data;
  },
  uploadDocumentFile: async (vendorId: number, vendorDocumentId: number, file: File): Promise<VendorDocumentEntity> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.put(`api/vendor/${vendorId}/documents/${vendorDocumentId}/file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new VendorDocumentEntity(data);
  },
  archiveDocument: async (vendorId: number, ids: number[], isArchived: boolean): Promise<VendorDocumentEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/documents/archive`, { isArchived, ids });
    return data.map((v: VendorDocumentEntity) => new VendorDocumentEntity(v));
  },
  getCurrentDocuments: async (
    documentFilter: VendorDocumentFilter,
  ): Promise<PaginationEntity<VendorDocumentEntity>> => {
    const { data } = await axios.post(`api/vendor/documents/search`, documentFilter);
    return { ...data, data: data.data.map((v: VendorDocumentEntity) => new VendorDocumentEntity(v)) };
  },
  getDocumentFileChangeRequest: async (changeRequestId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/vendorChangeRequests/documents/${changeRequestId}/file`, {
      responseType: 'blob',
    });
    return data;
  },
  createDocumentChangeRequest: async (entity: VendorDocumentEntity): Promise<VendorDocumentEntity> => {
    const { data } = await axios.post(`api/vendor/documents`, entity);
    return new VendorDocumentEntity(data);
  },
  updateDocumentChangeRequest: async (entity: VendorDocumentEntity): Promise<VendorDocumentEntity> => {
    const { data } = await axios.put(`api/vendor/documents/${entity.id}`, entity);
    return new VendorDocumentEntity(data);
  },
  uploadDocumentFileChangeRequest: async (changeRequestId: number, file: File): Promise<VendorDocumentEntity> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.put(`api/vendor/documents/${changeRequestId}/file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new VendorDocumentEntity(data);
  },
  removeDocumentChangeRequest: async (vendorDocumentId: number): Promise<VendorDocumentEntity> => {
    const { data } = await axios.delete(`api/vendor/documents/${vendorDocumentId}`);
    return new VendorDocumentEntity(data);
  },
  approvedOrRejectDocumentRequest: async (
    id: number,
    trigger: ChangeRequestTrigger,
    entity: VendorDocumentEntity,
  ): Promise<VendorDocumentEntity> => {
    const { data } = await axios.post(`api/vendorChangeRequests/documents/${id}/${trigger}`, entity);
    return new VendorDocumentEntity(data);
  },
  getDocumentChangeRequest: async (id: number): Promise<VendorEntityChangeRequestEntity<VendorDocumentEntity>> => {
    const { data } = await axios.get(`api/vendorChangeRequests/documents/${id}`);
    return new VendorEntityChangeRequestEntity(data);
  },
  getDocumentRebateCategories: async (
    id: number,
    rebateCategoryFilter: VendorRebateCategoryFilter,
  ): Promise<PaginationEntity<VendorRebateCategoryEntity>> => {
    const { data } = await axios.post(`api/vendor/${id}/documents/rebateCategories/search`, rebateCategoryFilter);
    return { ...data, data: data.data.map((v: VendorRebateCategoryEntity) => new VendorRebateCategoryEntity(v)) };
  },
};
