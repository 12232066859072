import axios from 'axios';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { ProfileEntity } from '../models';
import {
  CodeVerificationRequest,
  PasswordInitialization,
  PasswordReinitialization,
  PasswordReinitializationRequest,
  User,
  UserTemporaryPassword,
  PhoneNumberInitializationRequest,
  PhoneNumberVerificationRequest,
} from '../types';

export const auth = {
  post: async (username: string, password: string): Promise<[number, User | UserTemporaryPassword]> => {
    const { data, status } = await axios.post('auth/login', { username, password }, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
    return [status, { ...data, profile: new ProfileEntity(data.profile), accessToken: data.token, username }];
  },
  postWithRefreshToken: async (username: string, refreshToken: string): Promise<User> => {
    const { data } = await axios.post('auth/token', { refreshToken, userId: username }, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
    return { ...data, profile: new ProfileEntity(data.profile), accessToken: data.token };
  },
  logout: async (refreshToken: string): Promise<void> => {
    await axios.post('auth/logout', { refreshToken }, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
  },
  forgotPassword: async (request: PasswordReinitializationRequest): Promise<void> => {
    await axios.post('auth/resetuserpassword', request, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
  },
  resetPassword: async (request: PasswordReinitialization): Promise<void> => {
    await axios.post('auth/confirmForgotPassword', request, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
  },
  setPassword: async (request: PasswordInitialization): Promise<User> => {
    const { data } = await axios.post('auth/setPassword', request, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
    return { ...data, profile: new ProfileEntity(data.profile), accessToken: data.token };
  },
  verifyCode: async (request: CodeVerificationRequest): Promise<User> => {
    const { data } = await axios.post('auth/verifycode', request, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
    return { ...data, profile: new ProfileEntity(data.profile), accessToken: data.token };
  },
  setVerificationPhoneNumber: async (verificationPhoneNumber: PhoneNumberInitializationRequest): Promise<void> => {
    await axios.post('auth/setverificationphonenumber', verificationPhoneNumber);
  },
  verifyPhoneNumber: async (verifyPhoneNumber: PhoneNumberVerificationRequest): Promise<void> => {
    await axios.post('auth/verifyphonenumber', verifyPhoneNumber);
  },
  resetPhoneNumber: async (): Promise<void> => {
    await axios.post('auth/resetphonenumber');
  },
};
