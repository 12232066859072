import { CategorizationEntity } from '../Categorization';
import { MemberEntity } from '../Member';
import { VendorEntity } from '../Vendor';
import { CommitmentEventCategoryEntity } from './CommitmentEventCategoryEntity';
import { CommitmentEventEntity } from './CommitmentEventEntity';

export class MemberCommitmentReportDetailEntity {
  id: number;
  event?: CommitmentEventEntity;
  category?: CommitmentEventCategoryEntity;
  member?: MemberEntity;
  regions?: CategorizationEntity[];
  memberCommitmentId?: number;
  vendor?: VendorEntity;
  volume?: number;
  isCurrent?: number;
  willSupport?: number;
  createdDate: Date;
  createdBy: string;
  modifiedDate: Date;
  modifiedBy: string;

  constructor(entity?: MemberCommitmentReportDetailEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
      this.member = entity.member && new MemberEntity(entity.member);
      this.vendor = entity.vendor && new VendorEntity(entity.vendor);
    }
    this.id = 1;
  }
}
