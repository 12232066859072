import { AddRounded, EditRounded, ModeCommentRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { vendor as vendorApi } from '../../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../../constants';
import { VendorBuyingAgreementContext } from '../../../../contexts/Vendor';
import { useAuth, useGlobalEdit, useInternationalization } from '../../../../hooks';
import { useApi } from '../../../../hooks/useApi';
import { FileEntity, VendorBuyingAgreementDocumentEntity } from '../../../../models';
import { VendorBuyingAgreementDocumentSchema } from '../../../../schemas';
import { OrderBy, PaginationFilter, PermissionKey } from '../../../../types';
import { downloadBlob, formatShortDate } from '../../../../utils/helper';
import { EditDrawer } from '../../../EditDrawer';
import { EntityModifiedDate } from '../../../EntityModifiedDate';
import { LayoutPage } from '../../../Layout';
import { DeleteMenuItem, MenuItem } from '../../../Menu';
import { HasPermissions } from '../../../Permission';
import { Table, TableColumn } from '../../../Table';
import { VendorBuyingAgreementDocumentForm } from './VendorBuyingAgreementDocumentForm';

export const VendorBuyingAgreementDocumentsList = () => {
  const { t } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { user, isInternalUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { vendorBuyingAgreement, readOnly, refresh: refreshBuyingAgreement } = useContext(VendorBuyingAgreementContext);
  const [document, setDocument] = useState<VendorBuyingAgreementDocumentEntity>(
    new VendorBuyingAgreementDocumentEntity(),
  );
  const [filter, setFilter] = useState<PaginationFilter>(DEFAULT_PAGINATION_FILTER);

  const { data, isLoading, refresh } = useApi(
    vendorApi.getAllBuyingAgreementFiles,
    null,
    vendorBuyingAgreement.vendorId,
    vendorBuyingAgreement.id,
    filter,
  );
  const { call: getFile } = useApi(vendorApi.getBuyingAgreementFile, null);
  const { call: removeFile } = useApi(vendorApi.deleteBuyingAgreementFiles, { successKey: 'common:success.save' });
  const { call: addFile } = useApi(vendorApi.addBuyingAgreementFiles, { successKey: 'common:success.save' });
  const { call: updateFile } = useApi(vendorApi.updateBuyingAgreementFile, { successKey: 'common:success.save' });

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const downloadFile = async (file: FileEntity) => {
    if (file.id) {
      const fileData = await getFile(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, file.id);
      if (fileData) {
        downloadBlob(file.name, fileData);
      }
    }
  };

  const onSave = async (entity: VendorBuyingAgreementDocumentEntity) => {
    if (!entity.file?.id) {
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
      await addFile(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, [entity]);
    } else {
      await updateFile(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, entity);
    }
    setDocument(new VendorBuyingAgreementDocumentEntity());
    await refresh();
    await refreshBuyingAgreement();
    return entity;
  };

  const onDeleteConfirm = async (entity: VendorBuyingAgreementDocumentEntity) => {
    if (entity.file?.id) {
      await removeFile(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, [entity.file.id]);
      await refresh();
      await refreshBuyingAgreement();
    }
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
    setDocument(new VendorBuyingAgreementDocumentEntity());
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const renderActionsMenuItems = (entity: VendorBuyingAgreementDocumentEntity, onClick: () => void) => {
    const menuItems = [
      <MenuItem
        id="download"
        key="download"
        onClick={() => {
          entity.file && downloadFile(entity.file);
          onClick();
        }}
      >
        {t('common:download')}
      </MenuItem>,
    ];
    if (!readOnly) {
      menuItems.push(
        <MenuItem
          id="edit"
          key="edit"
          permissions={{ keys: PermissionKey.VendorEditVBAs }}
          onClick={() => {
            setDocument(entity);
            setIsAddOpen(true);
            onClick();
          }}
        >
          {t('common:edit')}
        </MenuItem>,
      );

      if (entity.createdByUserType == user?.profile.userType) {
        menuItems.push(
          <DeleteMenuItem
            key="delete"
            permissions={{ keys: PermissionKey.VendorEditVBAs }}
            actionSuffix={t('vendor:buyingAgreements.documents.actions.suffix')}
            name={entity.description ?? t(`vendor:buyingAgreements.documents.type.${entity.type}`)}
            entity={entity}
            onDeleteConfirm={onDeleteConfirm}
            onClick={onClick}
          />,
        );
      }
    }
    return menuItems;
  };

  const renderAddButton = () =>
    !readOnly || (isInternalUser && vendorBuyingAgreement.isApproveOrReject) ? (
      <HasPermissions keys={PermissionKey.VendorEditVBAs}>
        <Button
          variant="contained"
          startIcon={<AddRounded />}
          onClick={() => setIsAddOpen(true)}
          disabled={globalEditing}
        >
          {t('vendor:buyingAgreements.documents.actions.add')}
        </Button>
      </HasPermissions>
    ) : undefined;

  const renderType = (entity: VendorBuyingAgreementDocumentEntity) =>
    t(`vendor:buyingAgreements.documents.type.${entity.type}`);

  const renderLastModified = (entity: VendorBuyingAgreementDocumentEntity) =>
    t('common:entity.modifiedDateShort', {
      date: formatShortDate(entity.modifiedDate),
      name: entity.modifiedBy,
    });

  const renderNotes = (entity: VendorBuyingAgreementDocumentEntity) => {
    let notes = '';
    if (isInternalUser && entity.notes) {
      notes = `${t('vendor:buyingAgreements.documents.form.notes')}: ${entity.notes}\n`;
    }
    if (entity.vendorNotes) {
      if (isInternalUser) {
        notes += `${t('vendor:buyingAgreements.documents.form.vendorNotes')}: `;
      }
      notes += `${entity.vendorNotes}\n`;
    }
    return notes;
  };

  const renderFilename = (entity: VendorBuyingAgreementDocumentEntity) => entity.file?.name ?? '';

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="vendor:buyingAgreements.documents"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
    >
      <TableColumn type="custom" width="20%" id="type" render={renderType} />
      <TableColumn type="custom" width="30%" id="filename" render={renderFilename} />
      <TableColumn type="property" width="30%" id="description" sortable />
      <TableColumn type="custom" width="20%" id="lastUpdate" render={renderLastModified} />
      <TableColumn type="icon" tooltip id="notes" render={renderNotes} iconComponent={ModeCommentRounded} />
    </Table>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {document.file?.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(
              document.file?.id
                ? 'vendor:buyingAgreements.documents.actions.edit'
                : 'vendor:buyingAgreements.documents.actions.add',
            )}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={document}
      schema={VendorBuyingAgreementDocumentSchema()}
      onSave={onSave}
      onConfirm={confirmDrawer}
      onCancel={cancelDrawer}
    >
      <Stack spacing={4}>
        <EntityModifiedDate entity={document} />
        <VendorBuyingAgreementDocumentForm />
      </Stack>
    </EditDrawer>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewVBAs }}
      title={t('vendor:sections.vendorDocuments')}
      display="Tab"
      rightTitle={renderAddButton()}
    >
      <Stack spacing={4}>
        {renderTable()}
        {renderDrawer()}
      </Stack>
    </LayoutPage>
  );
};
