import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { CmsHelpTrainingContext } from '../../../contexts/Cms';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../hooks';
import { HelpTrainingEntity } from '../../../models';
import { routes } from '../../../routes';
import { HelpTrainingSchema } from '../../../schemas';
import { ContentStatus, ContentTriggers, PermissionKey, Styles } from '../../../types';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { HasPermissions } from '../../Permission';
import { CmsChangeLogs } from '../ChangeLog/CmsChangeLogs';
import { CmsWorkflow } from '../CmsWorkflow';
import { CmsHelpTrainingContent } from './CmsHelpTrainingContent';
import { CmsHelpTrainingForm } from './CmsHelpTrainingForm';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const CmsHelpTrainingDetail = () => {
  const { t, getTranslatablePropertyKey, getTranslation } = useInternationalization();
  const { hasPermissions } = useAuth();
  const params = useParams();
  const helpTrainingId = Number(params.helpTrainingId);
  const { data, refresh, isLoading } = useApi(cmsApi.getHelpTraining, null, helpTrainingId);
  const { call: update } = useApi(cmsApi.updateHelpTraining, null);
  const { call: trigger, isLoading: isLoadingTrigger } = useApi(cmsApi.updateHelpTrainingWorkflow, null);
  usePageTitle(data ? getTranslation(data, 'name') : '');

  const canEdit =
    (data?.status === ContentStatus.InProgress && hasPermissions(PermissionKey.CmsHelpEdit)) ||
    ((data?.status === ContentStatus.Submitted || data?.status === ContentStatus.Approved) &&
      hasPermissions(PermissionKey.CmsHelpApprove));

  const save = async (entity: HelpTrainingEntity) => {
    const updatedEntity = await update(entity);
    await refresh();
    return updatedEntity;
  };

  const onUpdateWorkflowConfirm = (newTrigger: ContentTriggers) => async () => {
    await trigger(helpTrainingId, newTrigger);
    refresh();
  };

  const renderWorkflow = () => {
    if (
      !data ||
      data?.status == ContentStatus.Rejected ||
      !hasPermissions([PermissionKey.CmsHelpEdit, PermissionKey.CmsHelpApprove], true)
    ) {
      return undefined;
    }

    return (
      <CmsWorkflow
        isLoading={isLoadingTrigger}
        onTrigger={onUpdateWorkflowConfirm}
        status={data.status}
        entityName={getTranslation(data, 'name')}
        approvePermission={PermissionKey.CmsHelpApprove}
        editPermission={PermissionKey.CmsHelpEdit}
        entitySuffix={t('cms:helpTrainings.actions.suffix')}
      />
    );
  };

  return (
    <CmsHelpTrainingContext.Provider value={{ helpTraining: data, canEdit, isLoading: isLoading, fetchData: refresh }}>
      <Container>
        {isLoading && <CircularProgress size={20} sx={style.loading} />}
        <HasPermissions keys={[PermissionKey.CmsHelpView, PermissionKey.CmsHelpEdit]} any={true}>
          {data && (
            <EditDetails
              titleLabel={t(`cms:helpTrainings.form.name`)}
              title={getTranslatablePropertyKey(data, 'name')}
              entity={data}
              schema={HelpTrainingSchema()}
              alwaysOpen
              onSave={save}
              onConfirm={() => refresh()}
              hideHeaderWhenEditing
              readOnly={!canEdit}
              breadcrumbs={[
                { title: t('cms:helpTrainings.title'), href: routes.Admin.Cms.HelpTrainings.path },
                { title: getTranslation(data, 'name') },
              ]}
              extraActions={[
                <CmsChangeLogs
                  key="changeLogs"
                  cmsEntityId={data.id}
                  fetchApi={cmsApi.getHelpTrainingChangeLogs}
                  addCommentApi={cmsApi.addHelpTrainingComment}
                  title={t('changeLog:helpTrainingTitle')}
                />,
              ]}
              workflow={renderWorkflow()}
            >
              <CmsHelpTrainingForm />
            </EditDetails>
          )}
        </HasPermissions>
      </Container>
      <CmsHelpTrainingContent />
    </CmsHelpTrainingContext.Provider>
  );
};
