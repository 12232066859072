import { Chip, Stack } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Styles } from '../../../types';
import { MultiSelect } from '../Base/MultiSelect';
import { StaticField } from '../Base/StaticField';

const style: Styles = {
  wrapStack: { flexWrap: 'wrap', gap: 1 },
};

interface ControlledMultiSelectProps extends Omit<React.ComponentProps<typeof MultiSelect>, 'onConfirm'> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
}

export const ControlledMultiSelect = ({
  name,
  control,
  label,
  readOnly,
  required,
  defaultToAll,
  translationContext = 'male',
  ...props
}: ControlledMultiSelectProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, fieldState: { error, isDirty } }) => {
        const selectedOptions = props.options.filter((o) => value?.includes(o.value));

        const onValuesChange = (values: (string | number)[]) => {
          onChange(values);
          onBlur();
        };

        const renderStaticValue = () => {
          if (selectedOptions.length) {
            if (props.mode === 'autocomplete') {
              return (
                <Stack direction="row" sx={style.wrapStack}>
                  {selectedOptions.map((o) => (
                    <Chip key={o.value} variant="filled" label={o.label} />
                  ))}
                </Stack>
              );
            }

            return selectedOptions.map((o) => o.label).join(', ');
          } else if (defaultToAll) {
            return t('common:filter.all', { context: translationContext });
          }

          return null;
        };

        return control._options.context?.readOnly || readOnly ? (
          <StaticField
            rawRender={!!selectedOptions.length && props.mode === 'autocomplete'}
            label={label}
            value={renderStaticValue()}
          />
        ) : (
          <MultiSelect
            {...props}
            defaultToAll={defaultToAll}
            className={control._options.context?.variant === 'changeRequest' && isDirty ? 'dirty' : undefined}
            name={name}
            label={required ? `${label} *` : label}
            id={name}
            onValuesChange={onValuesChange}
            onConfirm={onValuesChange}
            values={value ?? []}
            error={!!error}
            translationContext={translationContext}
            helperText={error?.message && t(error?.message, { name: label })}
          />
        );
      }}
    />
  );
};
