import { AddRounded, ArchiveRounded, ModeCommentRounded, UnarchiveRounded } from '@mui/icons-material';
import { Button, Link, MenuItem, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { forestProduct as forestProductApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { ForestProductGroupEntity } from '../../../models';
import { ForestProductGroupFilter, OrderBy, PermissionKey } from '../../../types';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { routes } from '../../../routes';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { ForestProductGroupSchema } from '../../../schemas';
import { EditDrawer } from '../../EditDrawer';
import { ForestProductGroupForm } from './ForestProductGroupForm';

const defaultFilter: ForestProductGroupFilter = {
  isArchived: false,
  ...DEFAULT_PAGINATION_FILTER,
};

export const ForestProductGroupList = () => {
  const navigate = useNavigate();
  const { t, getTranslation } = useInternationalization();
  const productGroup = useMemo(() => new ForestProductGroupEntity(), []);
  const [filter, setFilter] = useState(defaultFilter);
  const [isAddOpen, setIsAddOpen] = useState(false);

  const { data, isLoading, refresh } = useApi(forestProductApi.getAllForestProductGroups, null, filter);

  const { call: create } = useApi(forestProductApi.createForestProductGroup, { successKey: 'common:success.save' });
  const archiveApi = useApi(forestProductApi.archiveForestProductGroup, { successKey: 'common:success.action' });

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: ForestProductGroupEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: ForestProductGroupEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onSave = async (entity: ForestProductGroupEntity) => {
    return await create(entity);
  };

  const onArchiveConfirm = async (isArchived: boolean, groups: ForestProductGroupEntity[]) => {
    await archiveApi.call(
      groups.map((i) => i.id),
      isArchived,
    );
    refresh();
  };

  const renderFilter = () => <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext={'male'} />;

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const getProductGroupDetailPath = (productGroupId: number) =>
    generatePath(routes.Admin.ForestProductGroupDetail.path, { productGroupId });

  const renderActionsMenuItems = (entity: ForestProductGroupEntity, onClick: () => void) => [
    <MenuItem id="view" key="view" onClick={() => navigate(getProductGroupDetailPath(entity.id))}>
      {t('common:view')}
    </MenuItem>,
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={getTranslation(entity, 'name')}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('forestProduct:groups.actions.suffix')}
      permissions={{ keys: PermissionKey.CommodityGroupManage }}
    />,
  ];

  const renderName = (entity: ForestProductGroupEntity, name: string) => (
    <Link underline="always" href={getProductGroupDetailPath(entity.id)}>
      {name}
    </Link>
  );

  const renderTable = () =>
    data && (
      <Table
        data={data}
        translationNamespace="forestProduct:groups"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={renderActionsMenuItems}
        bulkActions={bulkActions}
        renderBulkSelection={(items) =>
          items.length === 1 ? getTranslation(items[0], 'name') : t('forestProduct:groups.title')
        }
      >
        <TableColumn
          type="custom"
          width="40%"
          id="name_En"
          sortable
          render={(entity: ForestProductGroupEntity) => renderName(entity, entity.name_En)}
        />
        <TableColumn
          type="custom"
          width="40%"
          id="name_Fr"
          sortable
          render={(entity: ForestProductGroupEntity) => renderName(entity, entity.name_Fr)}
        />
        <TableColumn
          type="custom"
          width="20%"
          id="productsCount"
          sortable
          align="center"
          render={(entity: ForestProductGroupEntity) => entity.productsCount.toString()}
        />
        <TableColumn type="icon" tooltip id="notes" align="center" iconComponent={ModeCommentRounded} />
      </Table>
    );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.CommodityGroupManage}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => {
          setIsAddOpen(true);
        }}
      >
        {t('forestProduct:groups.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('forestProduct:groups.actions.add')}</Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={productGroup}
      schema={ForestProductGroupSchema()}
      onSave={async (entity) => {
        const createdEntity = await onSave(entity);
        await refresh();
        return createdEntity;
      }}
      onConfirm={async () => {
        await refresh();
        setIsAddOpen(false);
      }}
      onCancel={() => setIsAddOpen(false)}
    >
      <ForestProductGroupForm />
    </EditDrawer>
  );

  return (
    <LayoutPage title={t('forestProduct:groups.title')} rightTitle={renderAddButton()} display={'Tab'}>
      {renderFilter()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
