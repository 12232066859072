import axios from 'axios';
import { VendorChangeRequestEntity } from '../../models';
import { PaginationEntity, VendorChangeRequestFilter } from '../../types';

export const changeRequest = {
  getAllChangeRequests: async (
    changeRequestFilter: VendorChangeRequestFilter,
  ): Promise<PaginationEntity<VendorChangeRequestEntity>> => {
    const { data } = await axios.post(`api/vendorChangeRequests/search`, changeRequestFilter);
    return { ...data, data: data.data.map((v: VendorChangeRequestEntity) => new VendorChangeRequestEntity(v)) };
  },
};
