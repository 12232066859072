import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../Base/Input';
import { LinkType, StaticField } from '../Base/StaticField';

interface ControlledInputProps extends React.ComponentProps<typeof Input> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  label?: string;
  staticValue?: string;
  required?: boolean;
  readOnly?: boolean;
  contentBesideTitle?: JSX.Element;
  titleStacked?: boolean;
  linkType?: LinkType;
}

export const ControlledInput = ({
  name,
  control,
  formatter,
  hideLabel,
  label,
  staticValue,
  required,
  readOnly,
  contentBesideTitle,
  titleStacked,
  linkType,
  ...props
}: ControlledInputProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, fieldState: { error, isDirty } }) =>
        control._options.context?.readOnly || readOnly ? (
          <StaticField
            label={label}
            hideLabel={hideLabel}
            variant={props.variant === 'standard' ? 'h1' : undefined}
            value={staticValue ? staticValue : formatter ? formatter(value ?? '').formatted.join('') : value ?? ''}
            linkType={linkType}
          />
        ) : (
          <Input
            {...props}
            onBlur={(e) => {
              onBlur();
              props.onBlur && props.onBlur(e);
            }}
            name={name}
            label={required ? `${label} *` : label}
            hideLabel={hideLabel}
            id={name}
            value={value}
            onChange={onChange}
            formatter={formatter}
            error={!!error}
            helperText={error?.message && t(error?.message, { name: label })}
            contentBesideTitle={contentBesideTitle}
            titleStacked={titleStacked}
            className={control._options.context?.variant === 'changeRequest' && isDirty ? 'dirty' : undefined}
          />
        )
      }
    />
  );
};
