import { object, array } from 'yup';
import { UserPermissionsEntity } from '../../models';
import { EntityFormSchema } from '../../types';

export const UserPermissionsSchema = (): EntityFormSchema<UserPermissionsEntity> => ({
  schema: object({
    assignedPermissions: array().required(),
  }),
  errorCodeMap: {},
});
