import { ContentStatus, LanguageCode } from '../../types';
import { PeriodEntity } from '../PeriodEntity';
import { TranslatedFileEntity } from '../TranslatedFileEntity';
import { ContentPageEntity } from './ContentPageEntity';

export class ContentEntity extends PeriodEntity {
  status: ContentStatus;
  name_En: string;
  name_Fr: string;
  isActive?: boolean;
  associatedLink?: string;
  pages: ContentPageEntity[];
  files: TranslatedFileEntity[];

  constructor(entity?: ContentEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.status = ContentStatus.InProgress;
      this.name_En = '';
      this.name_Fr = '';
      this.associatedLink = '';
      this.pages = [];
      this.files = [];
    }
  }

  static createFromFile(file: TranslatedFileEntity) {
    const newDocument = new TranslatedFileEntity();
    newDocument.file = file.file;
    newDocument.languages = file.languages;
    return newDocument;
  }

  static toFormData(entities: TranslatedFileEntity[], key: string) {
    const formData = new FormData();
    entities.forEach((entity, index) => {
      formData.append(`${key}[${index}].File`, entity.file ?? '');
      entity.languages.forEach((language, i) => {
        formData.append(`${key}[${index}].Languages[${i}]`, language);
      });
    });
    return formData;
  }

  getFilesDiff(entity: ContentEntity) {
    const filesToDelete = this.files?.filter((existingFile) => {
      const replacedFile = entity.files?.find((newFile) => newFile.file?.name === existingFile.file?.name);
      return !replacedFile || replacedFile.file?.id !== existingFile.file?.id;
    });

    const languageModified = entity.files
      ?.filter((newFile) => {
        return this.files?.find(
          (existingFile) =>
            existingFile.file?.id === newFile.file?.id &&
            (!existingFile.languages.every((lang) => newFile.languages.includes(lang)) ||
              existingFile.languages.length !== newFile.languages.length),
        );
      })
      .map((f) => (!f.languages.length ? { ...f, languages: [LanguageCode.en] } : f));

    return {
      added:
        entity.files
          ?.filter((f) => !f.file?.id)
          .map((f) => (!f.languages.length ? { ...f, languages: [LanguageCode.en] } : f)) ?? [],
      removed: filesToDelete.map((f) => f.file?.id ?? 0) ?? [],
      languageModified: languageModified.length
        ? [
            ...entity.files.filter((f) => !!f.file?.id && !languageModified.find((x) => x.file?.id === f.file?.id)),
            ...languageModified,
          ].map((f) => ({ id: f.file?.id, languages: f.languages }))
        : [],
    };
  }
}
