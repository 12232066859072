import { DownloadRounded } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LoadingButton } from '@mui/lab';
import Menu from '@mui/material/Menu';
import { MouseEvent, useState } from 'react';
import { useInternationalization } from '../hooks';
import theme from '../styles/theme';
import { ExportType } from '../types';
import { MenuItem } from './Menu';

interface ExportButtonProps {
  displayMenu: boolean;
  defaultExportType: ExportType;
  onExportClick: (exportType: ExportType) => Promise<void>;
}

export function ExportButton({ displayMenu, defaultExportType, onExportClick }: ExportButtonProps) {
  const { t } = useInternationalization();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (displayMenu) {
      setAnchorEl(event.currentTarget);
    } else {
      handleExport(defaultExportType);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (exportType: ExportType) => {
    setIsLoading(true);
    onExportClick(exportType).finally(() => {
      setIsLoading(false);
    });
    handleClose();
  };

  return (
    <div>
      <LoadingButton
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        loading={isLoading}
        startIcon={<DownloadRounded />}
        endIcon={displayMenu ? <ArrowDropDownIcon /> : undefined}
      >
        {t('common:export')}
      </LoadingButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.MenuListProps}
        PaperProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleExport(ExportType.CSV)} disableRipple>
          {t('common:exportType.csv')}
        </MenuItem>
        <MenuItem onClick={() => handleExport(ExportType.PDF)} disableRipple>
          {t('common:exportType.pdf')}
        </MenuItem>
      </Menu>
    </div>
  );
}
