import { Box, Button, CircularProgress, Grid, Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { cms as cmsApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { useApi, useInternationalization } from '../../hooks';
import { palette } from '../../styles/palette';
import { EventFilter, EventType, LanguageCode, Styles } from '../../types';
import { formatShortDate } from '../../utils/helper';
import { routes } from '../../routes';
import format from 'date-fns/format';
import { enCA, frCA } from 'date-fns/locale';
import differenceInDays from 'date-fns/differenceInDays';
import { StoreMallDirectoryRounded } from '@mui/icons-material';

const styles: Styles = {
  container: {
    marginBottom: 1,
  },
  expiryDate: {
    padding: 1,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    background: palette.white,
    borderLeft: `1px solid ${palette.grey[300]}`,
    borderBottom: `1px solid ${palette.grey[300]}`,
    borderTop: `1px solid ${palette.grey[300]}`,
    color: palette.black,
    'p:first-of-type': {
      paddingTop: 1,
      fontWeight: 700,
      fontSize: 17,
    },
    'p:last-of-type': {
      fontWeight: 600,
      paddingBottom: 1,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventBody: {
    borderRight: `1px solid ${palette.grey[300]}`,
    borderBottom: `1px solid ${palette.grey[300]}`,
    borderTop: `1px solid ${palette.grey[300]}`,
    borderTopRightRadius: 8,
    padding: '8px 0px',
    borderBottomRightRadius: 8,
    background: palette.white,
    display: 'flex',
    'div:last-of-type': {
      padding: '4px 8px 4px 0',
    },
  },
  divider: {
    padding: '4px 16px 4px 0',
    borderLeft: `1px solid ${palette.grey[300]}`,
  },
  daysBeforeEvent: {
    fontWeight: 600,
    fontSize: 12,
    color: palette.grey[500],
  },
  dateLessThan7Days: {
    background: palette.primary.main,
    '>p': {
      color: palette.white,
    },
  },
  eventBodyLessThan7Days: {
    background: palette.primary.lightMain,
  },
  viewCalendar: {
    mt: 1,
    mb: 2,
  },
  vendorName: {
    fontSize: 12,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  vendorIcon: {
    color: palette.grey[500],
    fontSize: 14,
  },
};

interface BuyGuideEventsProps {
  favouriteVendorsOnly?: boolean;
}

const defaultFilter: EventFilter = {
  effectiveDate: formatShortDate(new Date()),
  types: [EventType.Booking, EventType.PoolBuy, EventType.SpecialBuy],
  orderBy: [{ columnName: 'expiryDate', direction: 'asc' }],
  ...DEFAULT_PAGINATION_FILTER,
  pageSize: 5,
};

export const BuyGuideEvents = ({ favouriteVendorsOnly }: BuyGuideEventsProps) => {
  const { getTranslation, currentLanguage, t } = useInternationalization();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<EventFilter>({ ...defaultFilter, favouriteVendorsOnly });
  const { data, isLoading } = useApi(cmsApi.getAllEvents, null, filter);

  useEffect(() => {
    setFilter((prev) => ({ ...prev, favouriteVendorsOnly }));
  }, [favouriteVendorsOnly, setFilter]);

  if (!data?.totalCount) {
    return null;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h2" mb={3}>
        {t('buyGuide:events.title')}
      </Typography>
      {data?.data.map((event) => {
        if (!event.expiryDate) {
          return null;
        }
        const daysLeft = differenceInDays(new Date(event.expiryDate), new Date());
        return (
          <Grid
            container
            sx={styles.container}
            key={event.id}
            component={Link}
            href={generatePath(routes.Member.BuyGuideBuyOpportunityDetail.path, {
              vendorId: event.vendorId,
              vendorBuyOpportunityId: event.id,
            })}
          >
            <Grid item sx={[styles.expiryDate, ...(daysLeft <= 7 ? [styles.dateLessThan7Days] : [])]} xs={2}>
              <Typography>{format(new Date(event.expiryDate), 'dd')}</Typography>
              <Typography>
                {format(new Date(event.expiryDate), 'LLL', {
                  locale: currentLanguage === LanguageCode.fr ? frCA : enCA,
                }).toUpperCase()}
              </Typography>
            </Grid>
            <Grid item sx={[styles.eventBody, ...(daysLeft <= 7 ? [styles.eventBodyLessThan7Days] : [])]} xs={10}>
              <Box sx={styles.divider} />
              <Box>
                <Typography sx={styles.daysBeforeEvent}>
                  {t('buyGuide:events.daysLeft', { count: daysLeft })}
                </Typography>
                {event.vendorName && (
                  <Stack direction="row" spacing={0.25} alignItems="center">
                    <StoreMallDirectoryRounded sx={styles.vendorIcon} />
                    <Typography sx={styles.vendorName} variant="label">
                      {event.vendorName}
                    </Typography>
                  </Stack>
                )}
                <Typography sx={styles.eventName} fontWeight={600}>
                  {getTranslation(event, 'name')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        );
      })}
      <Button
        variant="outlined"
        sx={styles.viewCalendar}
        size="small"
        onClick={() => navigate(generatePath(routes.Member.Events.path))}
      >
        {t('buyGuide:events.viewCalendar')}
      </Button>
    </Box>
  );
};
