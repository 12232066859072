import { report as reportsApi } from '../../../api';
import { VendorDataReportList } from '../../../components/Report';

export function RebateCategoriesReports() {
  return (
    <VendorDataReportList
      dataApi={reportsApi.getAllRebateCategoriesWithoutPrograms}
      exportApi={reportsApi.exportRebateCategoryWithoutPrograms}
      reportType="RebateCategory"
    />
  );
}
