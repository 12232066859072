import { ContentEntity } from '../../models';
import { TranslatedFileEntity } from '../../models/TranslatedFileEntity';
import { downloadBlob } from '../../utils/helper';
import { useApi, useInternationalization } from '../../hooks';
import { Stack, Typography } from '@mui/material';
import { Styles } from '../../types';
import { palette } from '../../styles/palette';
import { AttachFileRounded } from '@mui/icons-material';
import { formatBytes } from '../../utils/file';

const style: Styles = {
  file: {
    background: palette.white,
    borderRadius: '8px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.grey[300],
    p: 1,
    pr: 2,
    cursor: 'pointer',
  },
  fileName: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  fileSize: {
    fontSize: '12px',
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const defaultDownloadApi = (contentEntityId: number, fileId: number) => undefined as any;

interface CmsContentAttachmentsViewProps {
  contentEntity: ContentEntity;
  downloadApi?: (contentEntityId: number, fileId: number) => Promise<Blob>;
}

export const CmsContentAttachmentsView = ({ contentEntity, downloadApi }: CmsContentAttachmentsViewProps) => {
  const { currentLanguage } = useInternationalization();
  const { call: download } = useApi(downloadApi ?? defaultDownloadApi, null);

  const downloadFile = async (file: TranslatedFileEntity) => {
    if (contentEntity && file.file?.id && downloadApi) {
      const fileData = await download(contentEntity.id, file.file.id);
      if (fileData) {
        downloadBlob(file.file.name, fileData as Blob);
      }
    }
  };

  const filteredFiles = contentEntity.files.filter((f) => f.languages.includes(currentLanguage));

  const renderFile = (file: TranslatedFileEntity) => (
    <Stack sx={style.file} direction="row" onClick={() => downloadFile(file)} spacing={1} alignItems="center">
      <AttachFileRounded htmlColor={palette.primary.main} />
      <Stack>
        <Typography sx={style.fileName}>{file.file?.name}</Typography>
        <Typography sx={style.fileSize}>{formatBytes(file.file?.size)}</Typography>
      </Stack>
    </Stack>
  );

  if (!filteredFiles.length) {
    return null;
  }

  return (
    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={1}>
      {filteredFiles.map((file) => renderFile(file))}
    </Stack>
  );
};
