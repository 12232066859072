import { CheckRounded } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useAuth, useInternationalization } from '../../../hooks';
import { VendorTierGrowthEntity, VendorTierGrowthValueEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { LanguageCode, RebateTypeAndUnitType, Styles } from '../../../types';
import { formatMoney } from '../../../utils/helper';
import { CardList, CardListElement, CardListRow } from '../../Card';

const style: Styles = {
  growthsContainer: {
    marginTop: 2,
  },
  checkIcon: {
    marginRight: 1.5,
    width: 20,
    height: 15,
  },
  dataRow: {
    justifyContent: 'left',
  },
};

interface VendorTierGrowthListProps<T extends VendorTierGrowthEntity> {
  tiers: T[] | null;
  growths: T[] | null;
  actionMenuItems?: (entity: T, onClick: () => void, isGrowthSelected: boolean) => JSX.Element[];
  isLoading?: boolean;
}

export const VendorTierGrowthList = <T extends VendorTierGrowthEntity>({
  tiers,
  growths,
  actionMenuItems,
  isLoading,
}: VendorTierGrowthListProps<T>) => {
  const { t, currentLanguage, getTranslation } = useInternationalization();
  const { isMemberUser, isInternalUser } = useAuth();

  const renderRebateValue = (item: VendorTierGrowthEntity, value: VendorTierGrowthValueEntity) => {
    if (value.rebateValue != null) {
      if (item.rebateUnit === RebateTypeAndUnitType.Percent) {
        return `${value.rebateValue}%`;
      }
      if (item.rebateUnit === RebateTypeAndUnitType.Dollar) {
        return formatMoney(value.rebateValue);
      }
      if (item.rebateUnit === RebateTypeAndUnitType.Custom) {
        return `${value.rebateValue} ${getTranslation(item, 'rebateCustomUnit')}`;
      }
    }
    return '';
  };

  const renderValueWithUnitValue = (
    item: VendorTierGrowthEntity,
    value: VendorTierGrowthValueEntity,
    unitKey: keyof VendorTierGrowthEntity,
    valueKey: keyof VendorTierGrowthValueEntity,
  ) => {
    if (value[valueKey] != null) {
      if (item[unitKey] === RebateTypeAndUnitType.Percent) {
        return `${value[valueKey]}%`;
      }
      if (item[unitKey] === RebateTypeAndUnitType.Dollar) {
        return formatMoney(value[valueKey]);
      }
      if (item[unitKey] === RebateTypeAndUnitType.Custom) {
        return `${value[valueKey]} ${getTranslation(item, 'customUnit')}`;
      }
    }
    return '';
  };

  return (
    <Box>
      <CardList
        data={tiers ?? []}
        isLoading={isLoading}
        translationNamespace="vendor"
        hideResultCount
        hideZeroResultCount
        actionMenuItems={actionMenuItems ? (item, onClick) => actionMenuItems(item, onClick, false) : undefined}
      >
        <CardListRow>
          <CardListElement type="title" id="name" translated hideLabel />
          <CardListElement
            type="custom"
            render={() => (
              <Typography variant="tag">{t('vendor:rebateCategory.tierAndGrowth.tierCardType')}</Typography>
            )}
            id={'tierCardType'}
            hideLabel
          />
        </CardListRow>
        <CardListRow sx={style.dataRow} alignItems="top">
          <CardListElement
            type="custom"
            id="values.rebateValue"
            hideLabel
            render={(item: VendorTierGrowthEntity) => (
              <Stack direction="column">
                <Typography display="block" variant="label">
                  {t('vendor:rebateCategory.tierAndGrowth.rebateValue')}
                </Typography>
                {item.values.map((value) => (
                  <Typography display="block" variant="body1">
                    {renderRebateValue(item, value)}
                  </Typography>
                ))}
              </Stack>
            )}
          />
          <CardListElement
            hideLabel
            type="custom"
            id="values.from"
            render={(item: VendorTierGrowthEntity) => (
              <Stack direction="column">
                <Typography display="block" variant="label">
                  {t('vendor:rebateCategory.tierAndGrowth.from')}
                </Typography>
                {item.values.map((value) => (
                  <Typography display="block" variant="body1">
                    {renderValueWithUnitValue(item, value, 'unit', 'from') || <>&nbsp;</>}
                  </Typography>
                ))}
              </Stack>
            )}
          />
          <CardListElement
            hideLabel
            type="custom"
            id="values.to"
            render={(item: VendorTierGrowthEntity) => (
              <Stack direction="column">
                <Typography display="block" variant="label">
                  {t('vendor:rebateCategory.tierAndGrowth.to')}
                </Typography>
                {item.values.map((value) => (
                  <Typography display="block" variant="body1">
                    {renderValueWithUnitValue(item, value, 'unit', 'to') || <>&nbsp;</>}
                  </Typography>
                ))}
              </Stack>
            )}
          />
        </CardListRow>
        <CardListRow noUnderline>
          <CardListElement
            type="custom"
            id={'isRetroactiveToDollar'}
            hideLabel
            hideIf={(item: VendorTierGrowthEntity) => !item.isRetroactiveToDollar}
            render={() => (
              <Typography variant="label" alignItems={'center'} display={'flex'}>
                <CheckRounded htmlColor={palette.grey[400]} sx={style.checkIcon} />
                {t('vendor:rebateCategory.tierAndGrowth.isRetroactiveToDollar')}
              </Typography>
            )}
          />
        </CardListRow>
        <CardListRow>
          <CardListElement
            type="property"
            label={t(`vendor:rebateCategory.tierAndGrowth.${isMemberUser ? 'memberUserNotes' : 'memberNotes'}`)}
            id={currentLanguage === LanguageCode.en ? 'memberNotes_En' : 'memberNotes_Fr'}
          />
          {isInternalUser && (
            <CardListElement type="property" label={t('vendor:rebateCategory.tierAndGrowth.notes')} id="notes" />
          )}
        </CardListRow>
      </CardList>
      <Box sx={style.growthsContainer}>
        <CardList
          isLoading={isLoading}
          data={growths ?? []}
          translationNamespace="vendor"
          hideResultCount
          hideZeroResultCount={!!(growths?.length || tiers?.length)}
          actionMenuItems={actionMenuItems ? (item, onClick) => actionMenuItems(item, onClick, true) : undefined}
        >
          <CardListRow sx={style.cardTitle}>
            <CardListElement type="title" id="name" translated hideLabel />
            <CardListElement
              type="custom"
              render={() => (
                <Typography variant="tag">{t('vendor:rebateCategory.tierAndGrowth.growthCardType')}</Typography>
              )}
              id="growthCardType"
              hideLabel
            />
          </CardListRow>
          <CardListRow sx={style.dataRow} alignItems="top">
            <CardListElement
              type="custom"
              id="values.rebateValue"
              hideLabel
              render={(item: VendorTierGrowthEntity) => (
                <Stack direction="column">
                  <Typography display="block" variant="label">
                    {t('vendor:rebateCategory.tierAndGrowth.rebateValue')}
                  </Typography>
                  {item.values.map((value) => (
                    <Typography display="block" variant="body1">
                      {renderRebateValue(item, value)}
                    </Typography>
                  ))}
                </Stack>
              )}
            />
            <CardListElement
              hideLabel
              type="custom"
              id="values.baselineYear"
              render={(item: VendorTierGrowthEntity) => (
                <Stack direction="column">
                  <Typography display="block" variant="label">
                    {t('vendor:rebateCategory.tierAndGrowth.baselineYear')}
                  </Typography>
                  {item.values.map((value) => (
                    <Typography display="block" variant="body1">
                      {value.baselineYear}
                    </Typography>
                  ))}
                </Stack>
              )}
            />
            <CardListElement
              hideLabel
              type="custom"
              id="values.baselineVolume"
              render={(item: VendorTierGrowthEntity) => (
                <Stack direction="column">
                  <Typography display="block" variant="label">
                    {t('vendor:rebateCategory.tierAndGrowth.baselineVolume')}
                  </Typography>
                  {item.values.map((value) => (
                    <Typography display="block" variant="body1">
                      {value.baselineVolume || <>&nbsp;</>}
                    </Typography>
                  ))}
                </Stack>
              )}
            />
            <CardListElement
              hideLabel
              type="custom"
              id="values.growth"
              render={(item: VendorTierGrowthEntity) => (
                <Stack direction="column">
                  <Typography display="block" variant="label">
                    {t('vendor:rebateCategory.tierAndGrowth.growth')}
                  </Typography>
                  {item.values.map((value) => (
                    <Typography display="block" variant="body1">
                      {renderValueWithUnitValue(item, value, 'unit', 'growth')}
                    </Typography>
                  ))}
                </Stack>
              )}
            />
          </CardListRow>
          <CardListRow>
            <CardListElement
              type="property"
              label={t(`vendor:rebateCategory.tierAndGrowth.${isMemberUser ? 'memberUserNotes' : 'memberNotes'}`)}
              id={currentLanguage === LanguageCode.en ? 'memberNotes_En' : 'memberNotes_Fr'}
            />
            {isInternalUser && (
              <CardListElement type="property" label={t('vendor:rebateCategory.tierAndGrowth.notes')} id="notes" />
            )}
          </CardListRow>
        </CardList>
      </Box>
    </Box>
  );
};
