/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { useEnumList, useInternationalization } from '../../hooks';
import { LayoutPage } from '../Layout';
import { ForestProductIndexPrice, LanguageCode } from '../../types';
import {
  TRADING_DASHBOARD_INFOGRAM_ID_EN,
  TRADING_DASHBOARD_INFOGRAM_ID_FR,
  TRADING_DASHBOARD_INFOGRAM_ID_VANCOUVER,
} from '../../constants';

export const ForestProductTradingDashboard = () => {
  const { t, currentLanguage } = useInternationalization();
  const [indexPrice, setIndexPrice] = useState(ForestProductIndexPrice.Toronto);
  const indexPriceOptions = useEnumList(ForestProductIndexPrice, 'forestProduct:forestProductIndexPrice');
  const containerRef = useRef<HTMLDivElement>(null);
  const windowInfogramKey = 'InfogramEmbeds';
  const scriptId = 'infogram-async';
  const scriptSrc = 'https://infogram.com/js/dist/embed-loader-min.js';
  const id = useMemo(
    () =>
      indexPrice === ForestProductIndexPrice.Vancouver
        ? TRADING_DASHBOARD_INFOGRAM_ID_VANCOUVER
        : currentLanguage === LanguageCode.en
        ? TRADING_DASHBOARD_INFOGRAM_ID_EN
        : TRADING_DASHBOARD_INFOGRAM_ID_FR,
    [currentLanguage, indexPrice],
  );

  const loadDashboard = () => {
    const existingScript = document.getElementById(scriptId);

    if ((window as any)[windowInfogramKey] && (window as any)[windowInfogramKey].initialized) {
      (window as any)[windowInfogramKey].process && (window as any)[windowInfogramKey].process();
    } else if (!existingScript) {
      const script = document.createElement('script');
      script.async = true;
      script.id = scriptId;
      script.src = scriptSrc;
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode?.insertBefore(script, firstScript);
    }
  };

  useEffect(() => {
    const script = document.getElementById(scriptId);
    if (script && (window as any)[windowInfogramKey].initialized) {
      (window as any)[windowInfogramKey].initialized = false;
      script.remove();
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    }
    loadDashboard();
  }, [id]);

  return (
    <LayoutPage display="Tab">
      <Stack direction="row" spacing={1} mb={2} alignItems="center" flexWrap="wrap">
        <Box>
          <Typography variant="label">{t('forestProduct:tradingDashboard.indexPrice')}</Typography>
        </Box>
        {indexPriceOptions.map((option) => (
          <Chip
            label={option.label}
            onClick={() => setIndexPrice(option.value)}
            color={option.value === indexPrice ? 'primary' : 'secondary'}
          />
        ))}
      </Stack>
      <div
        ref={containerRef}
        className="infogram-embed"
        data-id={id}
        data-type="interactive"
        data-title={t('forestProduct:tradingDashboard.title')}
      />
    </LayoutPage>
  );
};
