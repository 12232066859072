import { Box, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { categorization as categorizationApi, cci as cciApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER, DOLLAR_NO_DECIMAL, MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { MemberCommitmentReportDetailEntity } from '../../../models/Cci';
import {
  CommitmentEventFilter,
  ExportType,
  LanguageCode,
  MemberCommitmentReportFilter,
  MemberCommitmentReportGrouping,
  OrderBy,
  PermissionKey,
  RebateTypeAndUnitType,
  Styles,
} from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { downloadBlob } from '../../../utils/helper';
import { ExportButton } from '../../ExportButton';
import { MultiSelect } from '../../Form';
import { LayoutPage } from '../../Layout';
import { Table, TableColumn } from '../../Table';

const styles: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      md: '220px',
    },
  },
  selectLargeContainer: {
    width: '100%',
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
};

const defaultFilter: MemberCommitmentReportFilter = {
  grouping: MemberCommitmentReportGrouping.Vendor,
  ...DEFAULT_PAGINATION_FILTER,
};

const commitmentEventDefaultFilter: CommitmentEventFilter = {
  isArchived: false,
  ...MAX_PAGINATION_FILTER,
};

export const VendorCommitments = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const [filter, setFilter] = useState(defaultFilter);
  const { data, isLoading } = useApi(cciApi.getAllMemberCommitmentReportDetails, null, filter);
  const { data: regions, isLoading: isRegionsLoading } = useApi(categorizationApi.getAll, null, 'region');
  const { call: downloadExport } = useApi(cciApi.exportMemberCommitments, {
    successKey: 'common:success.action',
  });
  const { data: events, isLoading: isEventsLoading } = useApi(
    cciApi.getAllCommitmentEvents,
    null,
    commitmentEventDefaultFilter,
  );
  const { data: categories, isLoading: isCategoriesLoading } = useApi(
    cciApi.getCommitmentCategorySuggestions,
    null,
    '',
  );

  const categoryOptions = useMemo(
    () => categories?.data.map((category) => ({ value: category.id, label: getTranslation(category, 'name') })) ?? [],
    [getTranslation, categories],
  );

  const eventsOptions = useMemo(
    () => events?.data.map((event) => ({ value: event.id, label: event.year.toString() })) ?? [],
    [events],
  );

  const regionOptions = useMemo(
    () => regions?.values?.map((region) => ({ value: region.id, label: getTranslation(region, 'abbreviation') })) ?? [],
    [getTranslation, regions?.values],
  );

  const onExportClick = async (exportType: ExportType = ExportType.PDF) => {
    const fileData = await downloadExport(exportType);
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
    }
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onRegionsChange = (values: number[]) => setFilter((prev) => ({ ...prev, regionIds: values }));

  const onEventsChange = (values: number[]) => setFilter((prev) => ({ ...prev, commitmentEventIds: values }));

  const onCategoriesChange = (values: number[]) => setFilter((prev) => ({ ...prev, commitmentCategoryIds: values }));

  const moneyFormatter = useMemo(
    () =>
      numberFormatter(
        currentLanguage,
        t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Dollar}`),
        DOLLAR_NO_DECIMAL,
        currentLanguage !== LanguageCode.en,
      ),
    [currentLanguage, t],
  );

  const renderSearch = () => (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={{ xs: 1, md: 2 }}
      mb={2}
      alignItems={{ xs: 'inherit', md: 'flex-end' }}
    >
      <Box sx={styles.selectSmallContainer}>
        <MultiSelect
          onConfirm={onEventsChange}
          options={eventsOptions}
          label={t('cci:memberCommitmentsHistory.filter.years')}
        />
      </Box>
      <Box sx={styles.selectSmallContainer}>
        <MultiSelect
          onConfirm={onCategoriesChange}
          options={categoryOptions}
          label={t('cci:memberCommitmentsHistory.filter.categories')}
        />
      </Box>
      <Box sx={styles.selectSmallContainer}>
        <MultiSelect
          onConfirm={onRegionsChange}
          options={regionOptions}
          label={t('cci:eventCommitments.filter.regions')}
        />
      </Box>
    </Stack>
  );

  const renderPageActions = () => (
    <ExportButton displayMenu={false} defaultExportType={ExportType.PDF} onExportClick={onExportClick} />
  );

  return (
    <LayoutPage
      display="Tab"
      permissions={{ keys: PermissionKey.CciViewCommitments }}
      title={t('cci:memberCommitmentsHistory.title')}
      rightTitle={renderPageActions()}
    >
      {renderSearch()}
      <Table
        data={data ?? []}
        translationNamespace="cci:memberCommitmentsHistory"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading || isEventsLoading || isCategoriesLoading || isRegionsLoading}
      >
        <TableColumn
          type="custom"
          id="year"
          render={(item: MemberCommitmentReportDetailEntity) => item?.event?.year?.toString() ?? ''}
          sortable
        />
        <TableColumn
          type="custom"
          id="category"
          render={(item: MemberCommitmentReportDetailEntity) =>
            item?.category ? getTranslation(item.category, 'name') : ''
          }
          sortable
        />
        <TableColumn
          type="custom"
          id="regions"
          render={(item: MemberCommitmentReportDetailEntity) =>
            item?.regions?.length
              ? item?.regions?.map((r) => getTranslation(r, 'abbreviation')).join(', ') ?? ''
              : t('common:filter.all', { context: 'female' })
          }
          sortable
        />
        <TableColumn
          type="custom"
          id="isCurrent"
          render={(item: MemberCommitmentReportDetailEntity) => item?.isCurrent?.toString() ?? ''}
          sortable
        />
        <TableColumn
          type="custom"
          id="willSupport"
          render={(item: MemberCommitmentReportDetailEntity) => item?.willSupport?.toString() ?? ''}
          sortable
        />
        <TableColumn
          type="custom"
          id="volume"
          render={(item: MemberCommitmentReportDetailEntity) =>
            item?.volume != null ? moneyFormatter(item?.volume).formatted.join('') : '-'
          }
          sortable
        />
      </Table>
    </LayoutPage>
  );
};
