import { Box, Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import { MAX_LONG_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { RebateCategoryEntity } from '../../../models';
import { ControlledInput } from '../../Form';

export const VendorRebateCategoryPermissionsNoteForm = () => {
  const { t } = useInternationalization();
  const { control, readOnly } = useEntityFormContext<RebateCategoryEntity>();

  return (
    <Stack spacing={1}>
      <ControlledInput
        rows={6}
        maxLength={MAX_LONG_TEXT_LENGTH}
        multiline
        label={t('vendor:rebateCategory.form.notes')}
        name="permissionNotes"
        control={control}
      />
      <Box>{!readOnly && <Trans i18nKey="common:formHelperText.notes" />}</Box>
    </Stack>
  );
};
