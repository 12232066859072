import { useOutlet } from 'react-router-dom';
import { useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';
import { PermissionKey } from '../../types';

export const CmsDetailVendor = () => {
  const { t } = useInternationalization();
  const outlet = useOutlet();

  const tabs = [
    <LinkTab
      label={t('cms:sections.publications')}
      value={routes.Vendor.Cms.Publications.path}
      permissions={{ keys: PermissionKey.CmsNewsView }}
    />,
  ];
  return <LayoutPage display="Page" tabs={tabs} title={t('cms:title')} outlet={outlet}></LayoutPage>;
};
