import { useContext } from 'react';
import { vendor as vendorApi } from '../../../../api';
import { VendorBuyingAgreementBuyOpportunityDetailContext } from '../../../../contexts/Vendor';
import { useApi, useInternationalization } from '../../../../hooks';
import { FileEntity } from '../../../../models';
import { VendorBuyingAgreementBuyOpportunitySchema } from '../../../../schemas';
import { downloadBlob } from '../../../../utils/helper';
import { EditCard } from '../../../Card';
import { Container } from '../../../Container';
import { VendorBuyOpportunityDocumentForm } from '../../BuyOpportunities/VendorBuyOpportunityDocumentForm';

export const VendorBuyingAgreementBuyOpportunityDocuments = () => {
  const { t } = useInternationalization();
  const { vendorId, buyOpportunity, save, fetchData, readOnly } = useContext(
    VendorBuyingAgreementBuyOpportunityDetailContext,
  );
  const { call: getFile } = useApi(vendorApi.getBuyingAgreementBuyOpportunityFile, null);

  const downloadFile = async (file: FileEntity) => {
    if (file.id && buyOpportunity.vendorBuyingAgreementId) {
      const fileData = await getFile(vendorId, buyOpportunity.vendorBuyingAgreementId, buyOpportunity.id, file.id);
      if (fileData) {
        downloadBlob(file.name, fileData);
      }
    }
  };

  return (
    <Container isHighlighted>
      <EditCard
        title={`${t('vendor:buyOpportunities.sections.files')} (${buyOpportunity.filesCount || 0})`}
        entity={buyOpportunity}
        readOnly={readOnly}
        schema={VendorBuyingAgreementBuyOpportunitySchema()}
        onSave={save}
        onConfirm={fetchData}
      >
        <VendorBuyOpportunityDocumentForm downloadFile={downloadFile} />
      </EditCard>
    </Container>
  );
};
