import { Button, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { cci as cciApi } from '../../../api';
import { useApi, useInternationalization } from '../../../hooks';
import { CommitmentEventCategoryEntity } from '../../../models';
import { PaginationEntity, Styles } from '../../../types';
import { Autocomplete } from '../../Form';

const style: Styles = {
  addButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
    justifyContent: 'flex-start',
  },
  inputContainer: {
    maxWidth: { xs: '100%', lg: '60%', xl: '50%' },
    minWidth: { xs: '100%', lg: '60%', xl: '50%' },
    '.MuiFormControl-root': {
      marginTop: 0,
    },
  },
};

interface CommitmentEventCategorySelectProps {
  currentCategories: CommitmentEventCategoryEntity[] | null;
  eventId: number;
  onCreateCategory: (name: string) => void;
  onAddExistingCategory: (item: CommitmentEventCategoryEntity) => void;
}

export const CommitmentEventCategorySelect = ({
  currentCategories,
  eventId,
  onCreateCategory,
  onAddExistingCategory,
}: CommitmentEventCategorySelectProps) => {
  const { t } = useInternationalization();
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<CommitmentEventCategoryEntity | null>(null);
  const { call: fetchCategory } = useApi(cciApi.getCommitmentEventCategorySuggestions, { callImmediately: false });

  const onSearchCategory = useCallback(
    async (name: string) => {
      const categories = await fetchCategory(name, eventId);
      return categories as PaginationEntity<CommitmentEventCategoryEntity>;
    },
    [eventId, fetchCategory],
  );

  const onAdd = async () => {
    if (selectedItem && canAddCategory(selectedItem)) {
      onAddExistingCategory?.(selectedItem);
      setSelectedItem(null);
      setSearchText('');
    }
  };

  const canAddCategory = (item: CommitmentEventCategoryEntity) => {
    return currentCategories?.every((category) => category.id !== item.id);
  };

  const createCategory = () => {
    onCreateCategory && onCreateCategory(searchText);
    setSelectedItem(null);
    setSearchText('');
  };

  const renderExtraOption = () =>
    !selectedItem && searchText ? (
      <li key="extra">
        <Button fullWidth sx={style.addButton} variant="text" onClick={createCategory}>
          {t('cci:eventCategories.actions.createAndAdd', { name: searchText })}
        </Button>
      </li>
    ) : undefined;

  return (
    <Stack direction="row" spacing={2} alignItems="flex-end" sx={style.inputContainer}>
      <Autocomplete
        minSearchTextLength={1}
        value={searchText}
        onValueChange={(value) => setSearchText(value)}
        apiFunction={onSearchCategory}
        disableOption={(option) => !canAddCategory(option)}
        languageOptionKey="name"
        extraOption={renderExtraOption()}
        groupBy={(option) => option.name_En}
        renderGroup={(params) => params.children}
        onApplySearch={(text) => {
          setSearchText(text);
          if (!text) {
            setSelectedItem(null);
          }
        }}
        onSelectItem={setSelectedItem}
      />
      <Button disabled={!selectedItem} variant="contained" onClick={onAdd}>
        {t('common:add')}
      </Button>
    </Stack>
  );
};
