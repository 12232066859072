import { CheckRounded, LocationOnRounded, SyncProblemRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { MemberForm } from '.';
import { ArchiveBanner, EditDetails } from '..';
import { member as memberApi } from '../../api';
import { MemberDetailContext } from '../../contexts';
import { useApi, useAuth, useConfirmationModal, usePageTitle } from '../../hooks';
import { MemberEntity } from '../../models';
import { routes } from '../../routes';
import { MemberSchema } from '../../schemas';
import { palette } from '../../styles/palette';
import { PermissionKey, Styles } from '../../types';
import { formatShortDate } from '../../utils/helper';
import { Container } from '../Container';
import { LayoutPage } from '../Layout';
import { ArchiveMenuItem } from '../Menu';
import { LinkTab } from '../Tab';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const MemberDetail = () => {
  const { t } = useTranslation();
  const { isInternalUser, isMemberUser, user, hasPermissions } = useAuth();
  const outlet = useOutlet();
  const params = useParams();
  const memberId = Number(params.memberId);
  const { data, isLoading, refresh } = useApi(memberApi.get, null, memberId);
  const { call } = useApi(isInternalUser ? memberApi.update : memberApi.updateMemberChangeRequest, {
    successKey: 'common:success.save',
  });
  const { call: confirmGeneralInformation } = useApi(memberApi.confirm, { successKey: 'common:success.save' });
  const { openModal } = useConfirmationModal();

  const archiveApi = useApi(memberApi.archive, { successKey: 'common:success.action' });
  usePageTitle(data?.name ?? '');

  const onArchiveConfirm = async (isArchived: boolean, entity: MemberEntity) => {
    await archiveApi.call([entity.id], isArchived);
    refresh();
  };

  const shouldRenderChangeRequest = () =>
    data?.isPending &&
    (isInternalUser || data?.isMyLocation || (isMemberUser && hasPermissions([PermissionKey.MemberEditMembers], true)));

  const onConfirmGeneralInformation = () => {
    openModal({
      confirmText: t('common:action.confirmButton', { action: t('common:confirm') }),
      title: t('member:confirmGeneralInformationTitle'),
      description: t('member:confirmGeneralInformationDescription'),
      onConfirm: async () => {
        await confirmGeneralInformation(memberId);
        refresh();
      },
    });
  };

  const renderActionMenuItems = (onClick: () => void) =>
    data && isInternalUser
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={data.name}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('member:action.suffix')}
          />,
        ]
      : [];

  const renderExtraActions = () => [
    ...(shouldRenderChangeRequest()
      ? [
          <Tooltip key="pending" title={t('common:changeRequest.pending')}>
            <SyncProblemRounded htmlColor={palette.primary.deep} />
          </Tooltip>,
        ]
      : []),
    ...(data?.isMyLocation
      ? [
          <LocationOnRounded
            key="location"
            htmlColor={user?.memberId == memberId ? palette.primary.main : palette.grey[500]}
          />,
        ]
      : []),
  ];

  const tabs = [
    ...(isInternalUser
      ? [
          <LinkTab
            label={t('member:sections.memberGroups')}
            value={generatePath(routes.Admin.Member.Detail.MemberGroups.path, { memberId })}
          />,
        ]
      : []),
    <LinkTab
      label={t('member:sections.contacts')}
      value={generatePath(
        isInternalUser
          ? routes.Admin.Member.Detail.Contacts.path
          : isMemberUser
          ? routes.Member.Member.Detail.Contacts.path
          : routes.Vendor.Member.Detail.Contacts.path,
        { memberId },
      )}
      permissions={{ keys: PermissionKey.MemberViewContacts }}
    />,
    ...(isInternalUser || data?.isMyLocation
      ? [
          <LinkTab
            permissions={{
              keys: isMemberUser ? PermissionKey.MemberViewMemberUsers : PermissionKey.AdminManageMemberUsers,
            }}
            label={t('member:sections.users')}
            value={generatePath(
              isInternalUser
                ? routes.Admin.Member.Detail.MemberUsers.path
                : routes.Member.Member.Detail.MemberUsers.path,
              { memberId },
            )}
          />,
        ]
      : []),
    ...(isInternalUser
      ? [
          <LinkTab
            permissions={{
              keys: [PermissionKey.MemberCommitmentsView, PermissionKey.MemberCommitmentsEdit],
              any: true,
            }}
            label={t('member:sections.commitments')}
            value={generatePath(routes.Admin.Member.Detail.MemberCommitmentDocuments.path, { memberId })}
          />,
        ]
      : []),
  ];

  const renderWorkflow = () =>
    !data?.canConfirm ? undefined : (
      <Box>
        <Button size="small" variant="contained" onClick={onConfirmGeneralInformation} startIcon={<CheckRounded />}>
          {t('common:confirm')}
        </Button>
      </Box>
    );

  return (
    <MemberDetailContext.Provider value={{ member: data, memberId }}>
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
        <ArchiveBanner
          permissions={{ keys: PermissionKey.MemberEditMembers }}
          entity={data}
          onUnarchive={() => data && onArchiveConfirm(false, data)}
        />
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              workflow={renderWorkflow()}
              alwaysOpen={!outlet}
              permissions={{ keys: PermissionKey.MemberEditMembers }}
              titleLabel={t(`member:form.name`)}
              title="name"
              disabled={data.isArchived}
              readOnly={data.isPending || (!isInternalUser && !data.isMyLocation)}
              entity={data}
              schema={MemberSchema()}
              onSave={call}
              extraActions={data.isMyLocation || isInternalUser ? renderExtraActions() : []}
              actionMenuItems={renderActionMenuItems}
              onConfirm={() => refresh()}
              renderModifiedDate={
                isMemberUser
                  ? (e) => (
                      <Typography variant="modifiedDate">
                        {e.isMyLocation &&
                          e.modifiedBy &&
                          e.modifiedDate &&
                          t('common:entity.modifiedDate', {
                            date: formatShortDate(e.modifiedDate),
                            name: e.modifiedBy,
                          })}
                      </Typography>
                    )
                  : undefined
              }
              breadcrumbs={[
                {
                  title: t('member:title'),
                  href: isInternalUser
                    ? routes.Admin.Member.path
                    : isMemberUser
                    ? routes.Member.Member.path
                    : routes.Vendor.Member.path,
                },
                { title: data?.name },
              ]}
            >
              <MemberForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </MemberDetailContext.Provider>
  );
};
