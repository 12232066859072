import { AddRounded, EditRounded, VisibilityRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { EditDrawer } from '../..';
import { useAuth, useGlobalEdit, useInternationalization } from '../../../hooks';
import { VendorLocationEntity } from '../../../models';
import { VendorLocationSchema } from '../../../schemas/Vendor';
import { BaseComponentListProps, OrderBy, PermissionKey, VendorLocationFilter } from '../../../types';
import { formatAddress, formatShortDate } from '../../../utils/helper';
import { EmailAddressList, PhoneNumberList } from '../../Contact';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { VendorLocationForm } from './VendorLocationForm';

export const VendorLocationsList = ({
  addButtonProps,
  tableProps,
  showSearch,
  onSave,
  fetchApi,
  filter,
  setFilter,
  layout = 'Tab',
  isChangeRequest,
}: BaseComponentListProps<VendorLocationEntity, VendorLocationFilter>) => {
  const { hasPermissions } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { data, isLoading, refresh } = fetchApi;
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [vendorLocation, setVendorLocation] = useState<VendorLocationEntity>(new VendorLocationEntity());

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderSearch = () => <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />;

  const renderBaseActionsMenuItems = (entity: VendorLocationEntity, onClick: () => void) => [
    ...(!entity.isArchived
      ? [
          <MenuItem
            id="edit"
            key="edit"
            onClick={() => {
              setVendorLocation(entity);
              setIsAddOpen(true);
              onClick();
            }}
          >
            {t('common:edit')}
          </MenuItem>,
        ]
      : []
    ).concat(tableProps?.actionMenuItems ? tableProps?.actionMenuItems(entity, onClick) : []),
  ];

  const renderGeneralContacts = (entity: VendorLocationEntity) => (
    <Stack spacing={1}>
      <PhoneNumberList data={entity.phoneNumbers} />
      <EmailAddressList data={entity.emailAddresses} />
    </Stack>
  );

  const renderAddress = (entity: VendorLocationEntity) => (
    <Typography variant="inherit" whiteSpace="pre-wrap">
      {formatAddress(entity.address)}
    </Typography>
  );

  const renderLastModified = (entity: VendorLocationEntity) =>
    t('common:entity.modifiedDateShort', {
      date: formatShortDate(entity.modifiedDate),
      name: entity.modifiedBy,
    });

  const renderTable = () =>
    data && (
      <Table
        {...tableProps}
        data={data}
        translationNamespace="vendor:location"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={
          hasPermissions(PermissionKey.VendorEditContacts) && !globalEditing ? renderBaseActionsMenuItems : undefined
        }
      >
        <TableColumn
          type="custom"
          width="25%"
          id="description"
          sortable
          render={(entity: VendorLocationEntity) => getTranslation(entity, 'description')}
        />
        <TableColumn type="custom" width="25%" id="address" render={renderAddress} />
        <TableColumn type="custom" width="25%" id="generalContacts" render={renderGeneralContacts} />
        <TableColumn type="custom" width="25%" id="modifiedDate" render={renderLastModified} />
        <TableColumn type="icon" id="isVisibleToMember" sortable align="center" iconComponent={VisibilityRounded} />
      </Table>
    );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.VendorEditContacts}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => {
          setVendorLocation(new VendorLocationEntity());
          setIsAddOpen(true);
        }}
        {...addButtonProps}
        disabled={addButtonProps?.disabled || globalEditing}
      >
        {t('vendor:location.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {vendorLocation.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(vendorLocation.id ? 'vendor:location.actions.edit' : 'vendor:location.actions.add')}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={vendorLocation}
      schema={VendorLocationSchema()}
      onSave={async (entity) => {
        const createdEntity = await onSave(entity);
        await refresh();
        return createdEntity;
      }}
      onConfirm={() => {
        refresh();
        setIsAddOpen(false);
      }}
      onCancel={() => setIsAddOpen(false)}
      isChangeRequest={isChangeRequest}
    >
      <VendorLocationForm />
    </EditDrawer>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewContacts }}
      title={t('vendor:location.title')}
      rightTitle={renderAddButton()}
      display={layout}
    >
      {showSearch && renderSearch()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
