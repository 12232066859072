import { AddRounded, DeleteRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEntityFormContext, useEntityFormFieldArray, useInternationalization } from '../../hooks';
import { PhoneNumberEntity } from '../../models';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';
import { phoneNumberFormatter } from '../../utils/formatters';
import { ControlledInput, ControlledTranslatedInput } from '../Form';

const style: Styles = {
  tableCell: {
    px: 1.25,
    py: 0.5,
    verticalAlign: 'top',
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  tableRow: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${palette.grey[300]}`,
      pb: '10px !important',
    },
  },
  tableButton: {
    mt: 3.5,
  },
  buttonBox: {
    mt: 1,
  },
  tableCellSingleLanguage: {
    pt: '35px !important',
  },
};

interface PhoneNumberListFormProps {
  disableTranslation?: boolean;
}

export const PhoneNumberListForm = ({ disableTranslation }: PhoneNumberListFormProps) => {
  const { t } = useInternationalization();
  const { control, readOnly } = useEntityFormContext();
  const phoneNumbers = useEntityFormFieldArray(control, 'phoneNumbers');

  return (
    <>
      <TableContainer>
        <Table sx={style.table}>
          <TableHead>
            <TableRow>
              <TableCell width="35%">{t('common:contact.phoneNumber.number')}</TableCell>
              <TableCell width="20%">{t('common:contact.phoneNumber.extension')}</TableCell>
              <TableCell width="45%">{t('common:contact.phoneNumber.description')}</TableCell>
              {!readOnly && <TableCell variant="head"></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {phoneNumbers.fields.map(
              (item: Record<string, string>, index) =>
                !item.isDeleted && (
                  <TableRow key={item.id} sx={style.tableRow}>
                    <TableCell
                      sx={[style.tableCell, disableTranslation || readOnly ? null : style.tableCellSingleLanguage]}
                    >
                      <ControlledInput
                        hideLabel
                        label={t('common:contact.phoneNumber.number')}
                        formatter={phoneNumberFormatter}
                        name={`phoneNumbers.${index}.number`}
                        control={control}
                        linkType="phone"
                      />
                    </TableCell>
                    <TableCell
                      sx={[style.tableCell, disableTranslation || readOnly ? null : style.tableCellSingleLanguage]}
                    >
                      <ControlledInput
                        hideLabel
                        label={t('common:contact.phoneNumber.extension')}
                        name={`phoneNumbers.${index}.extension`}
                        control={control}
                      />
                    </TableCell>
                    <TableCell sx={style.tableCell}>
                      {disableTranslation ? (
                        <ControlledInput
                          hideLabel
                          label={t('common:contact.phoneNumber.description')}
                          name={`phoneNumbers.${index}.description`}
                          control={control}
                        />
                      ) : (
                        <ControlledTranslatedInput
                          hideLabel
                          label={t('common:contact.phoneNumber.description')}
                          name={`phoneNumbers.${index}.description`}
                          control={control}
                        />
                      )}
                    </TableCell>
                    {!readOnly && (
                      <TableCell sx={style.tableCell}>
                        <IconButton
                          onClick={() => phoneNumbers.remove(index)}
                          sx={disableTranslation ? undefined : style.tableButton}
                        >
                          <DeleteRounded />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ),
            )}
            {!phoneNumbers.fields.length && (
              <TableRow>
                <TableCell sx={style.tableCell}>
                  <Typography variant="valuePlaceholder">{t('common:notSpecified')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!readOnly && (
        <Box sx={style.buttonBox}>
          <Button
            variant="contained"
            size="small"
            startIcon={<AddRounded />}
            onClick={() => phoneNumbers.append(new PhoneNumberEntity())}
          >
            {t('common:add')}
          </Button>
        </Box>
      )}
    </>
  );
};
