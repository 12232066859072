import { BlockRounded, CheckRounded, EditRounded, VerifiedUserRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../styles/palette';
import { Styles, VendorRebateCategoryProgramStatus } from '../../../types';

const style: Styles = {
  approved: {
    fontWeight: 600,
    fontSize: 14,
    color: palette.success.main,
  },
  other: {
    color: palette.grey[500],
  },
};

interface VendorRebateCategoryProgramStatusDisplayProps {
  status: VendorRebateCategoryProgramStatus;
}

export const VendorRebateCategoryProgramStatusDisplay = ({ status }: VendorRebateCategoryProgramStatusDisplayProps) => {
  const { t } = useTranslation();

  const renderIcon = () => {
    switch (status) {
      case VendorRebateCategoryProgramStatus.InProgress:
        return <EditRounded htmlColor={palette.grey[500]} />;
      case VendorRebateCategoryProgramStatus.Approved:
        return <CheckRounded htmlColor={palette.success.main} />;
      case VendorRebateCategoryProgramStatus.Verified:
        return <VerifiedUserRounded htmlColor={palette.success.main} />;
      case VendorRebateCategoryProgramStatus.Rejected:
        return <BlockRounded htmlColor={palette.grey[500]} />;
      default:
        return null;
    }
  };
  return (
    <Typography
      variant="status"
      sx={
        status === VendorRebateCategoryProgramStatus.Approved || status === VendorRebateCategoryProgramStatus.Verified
          ? style.approved
          : style.other
      }
    >
      {t(`vendor:rebateCategory.programs.status.${status}`)}
      {renderIcon()}
    </Typography>
  );
};
