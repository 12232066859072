import { Country, Province } from '../../types';

export class AddressEntity {
  id: number;
  street: string | null;
  city: string | null;
  province: Province | null;
  postalCode: string | null;
  country: Country | null;

  constructor(entity?: AddressEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.street = null;
      this.city = null;
      this.province = null;
      this.postalCode = null;
      this.country = null;
    }
  }
}
