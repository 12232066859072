import { number, object, string } from 'yup';
import { MAX_INTEGER, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorRebateCategoryProgramRebateEntity } from '../../models';
import { EntityFormSchema, RebateTypeAndUnitType } from '../../types';

export const VendorRebateCategoryProgramRebateSchema = (
  entityPart: 'calculationMethod' | 'paymentTerms',
): EntityFormSchema<VendorRebateCategoryProgramRebateEntity> => ({
  schema: object({
    ...(entityPart === 'paymentTerms'
      ? {
          termNote_En: string().when('isTermNote', {
            is: true,
            then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
            otherwise: (schema) => schema.nullable().notRequired(),
          }),
          termNote_Fr: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
          termValue: number().when('isTermNote', {
            is: false,
            then: (schema) => schema.required().min(0).max(MAX_INTEGER),
            otherwise: (schema) =>
              schema
                .transform(() => {
                  return undefined;
                })
                .nullable()
                .notRequired(),
          }),
          unit: string().when('isTermNote', {
            is: false,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable().notRequired(),
          }),
          customUnit_En: string().when('unit', {
            is: RebateTypeAndUnitType.Custom,
            then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
            otherwise: (schema) => schema.nullable().notRequired(),
          }),
          customUnit_Fr: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
        }
      : {
          calculationMethodId: string().required(),
        }),
  }),
  errorCodeMap: {},
});
