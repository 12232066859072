export class SettingEntity {
  id: number;
  isMailSyncEnabled: boolean;

  constructor(entity?: SettingEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.isMailSyncEnabled = true;
    }
  }
}
