import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { CategorizationContext } from '../../contexts';
import { useEntityFormContext } from '../../hooks';
import { CategorizationEntity } from '../../models';
import { EntityModifiedDate } from '../EntityModifiedDate';
import { ControlledInput } from '../Form';

export const CategorizationForm = () => {
  const { t } = useTranslation();
  const { control, getValues } = useEntityFormContext<CategorizationEntity>();
  const { isFieldVisible } = useContext(CategorizationContext);

  return (
    <Stack spacing={4}>
      <EntityModifiedDate entity={getValues()} />
      <Stack spacing={8}>
        <Stack spacing={3}>
          <ControlledInput
            maxLength={MAX_SHORT_TEXT_LENGTH}
            label={t('categorization:table.name_En')}
            name="name_En"
            variant="standard"
            control={control}
            required
          />
          {isFieldVisible('abbreviation') && (
            <ControlledInput
              maxLength={MAX_SHORT_TEXT_LENGTH}
              label={t('categorization:table.abbreviation_En')}
              name="abbreviation_En"
              control={control}
              required
            />
          )}
          {isFieldVisible('description') && (
            <ControlledInput
              maxLength={MAX_SHORT_TEXT_LENGTH}
              rows={3}
              multiline
              label={t('categorization:table.description_En')}
              name="description_En"
              control={control}
            />
          )}
          {isFieldVisible('searchKeywords') && (
            <Stack spacing={1}>
              <ControlledInput
                maxLength={MAX_LONG_TEXT_LENGTH}
                label={t('categorization:table.searchKeywords_En')}
                name="searchKeywords_En"
                control={control}
              />
              <Typography variant="h4">{t('common:formHelperText.keywords')}</Typography>
            </Stack>
          )}
        </Stack>
        <Stack spacing={3}>
          <ControlledInput
            maxLength={MAX_SHORT_TEXT_LENGTH}
            label={t('categorization:table.name_Fr')}
            name="name_Fr"
            variant="standard"
            control={control}
            required
          />
          {isFieldVisible('abbreviation') && (
            <ControlledInput
              maxLength={MAX_SHORT_TEXT_LENGTH}
              label={t('categorization:table.abbreviation_Fr')}
              name="abbreviation_Fr"
              control={control}
              required
            />
          )}
          {isFieldVisible('description') && (
            <ControlledInput
              maxLength={MAX_SHORT_TEXT_LENGTH}
              rows={3}
              multiline
              label={t('categorization:table.description_Fr')}
              name="description_Fr"
              control={control}
            />
          )}
          {isFieldVisible('searchKeywords') && (
            <Stack spacing={1}>
              <ControlledInput
                maxLength={MAX_LONG_TEXT_LENGTH}
                label={t('categorization:table.searchKeywords_Fr')}
                name="searchKeywords_Fr"
                control={control}
              />
              <Typography variant="h4">{t('common:formHelperText.keywords')}</Typography>
            </Stack>
          )}
          <Typography variant="drawerContentSubtitle">{t('categorization:form.noteTitle')}</Typography>
          <ControlledInput
            rows={5}
            maxLength={MAX_LONG_TEXT_LENGTH}
            multiline
            label={t('categorization:form.noteField')}
            name="note"
            control={control}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
