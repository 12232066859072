import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext } from '../../../hooks';
import { BuyOpportunityEntity } from '../../../models/Vendor/BuyOpportunityEntity';
import { ControlledTranslatedInput } from '../../Form';

export const VendorBuyOpportunityHeader = () => {
  const { t } = useTranslation();
  const { control, readOnly } = useEntityFormContext<BuyOpportunityEntity>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ControlledTranslatedInput
          maxLength={MAX_SHORT_TEXT_LENGTH}
          label={t('vendor:buyOpportunities.form.name')}
          name="name"
          variant="standard"
          control={control}
          hideLabel={readOnly}
          required
        />
      </Grid>
    </Grid>
  );
};
