import { Grid } from '@mui/material';
import { ReactElement } from 'react';
import { useAuth, useInternationalization, usePageTitle } from '../../hooks';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';
import { LayoutPage } from '../Layout';
import { WelcomeMessage, WidgetProps } from './Widgets';

const styles: Styles = {
  topSection: {
    borderBottom: `solid 1px ${palette.grey[300]}`,
    paddingBottom: 2,
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' },
    gridGap: '16px',
  },
};

export interface DashboardProps {
  children?: (ReactElement<WidgetProps> | false)[] | ReactElement<WidgetProps> | false;
  title?: string;
}

export const Dashboard = ({ children, title }: DashboardProps) => {
  const { t } = useInternationalization();
  const { hasPermissions } = useAuth();
  usePageTitle('dashboard:title');
  const topWidgets: ReactElement<WidgetProps>[] = [];
  const centerWidgets: ReactElement<WidgetProps>[] = [];
  const rightWidgets: ReactElement<WidgetProps>[] = [];

  const availableWidgets = (Array.isArray(children) ? children : [children]).filter((widget) => {
    const isAvailable =
      !!widget &&
      (!widget.props.permissions ||
        !widget.props.permissions.keys ||
        hasPermissions(widget.props.permissions.keys, widget.props.permissions.any));
    if (isAvailable) {
      switch (widget.props.placement) {
        case 'top':
          topWidgets.push(widget);
          break;
        case 'center':
          centerWidgets.push(widget);
          break;
        case 'right':
          rightWidgets.push(widget);
          break;
        default:
          break;
      }
    }

    return isAvailable;
  });

  const renderWidgets = () => (
    <Grid container spacing={2}>
      {!!topWidgets.length && (
        <Grid item xs={12} sx={styles.topSection}>
          {topWidgets}
        </Grid>
      )}
      {!!centerWidgets.length && (
        <Grid item xs={12} xl={rightWidgets.length ? 9 : 12} lg={rightWidgets.length ? 8 : 12}>
          {centerWidgets}
        </Grid>
      )}
      {!!rightWidgets.length && (
        <Grid item xs={12} xl={3} lg={4}>
          {rightWidgets}
        </Grid>
      )}
    </Grid>
  );

  return (
    <LayoutPage title={title ?? t('dashboard:title')}>
      {availableWidgets.length ? renderWidgets() : <WelcomeMessage />}
    </LayoutPage>
  );
};
