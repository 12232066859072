import { vendor as vendorApi } from '../../../api';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { OrderBy, Styles, VendorBuyingAgreementFilter, VendorBuyingAgreementStatus } from '../../../types';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { routes } from '../../../routes';
import { WidgetProps } from './Widget';
import { Table, TableColumn } from '../../Table';
import { VendorBuyingAgreementEntity } from '../../../models';
import { useState } from 'react';
import { Box, Chip, Link, Stack, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { formatShortDate } from '../../../utils/helper';
import { ArrowForward } from '@mui/icons-material';
import { palette } from '../../../styles/palette';

const styles: Styles = {
  name: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  nameText: {
    marginLeft: 1,
    marginTop: 0.5,
    fontWeight: 600,
    fontSize: 14,
  },
  filterContainer: {
    py: 2,
  },
};

const defaultFilter: VendorBuyingAgreementFilter = {
  status: [VendorBuyingAgreementStatus.InProgressTBM, VendorBuyingAgreementStatus.InProgressVendor],
  ...DEFAULT_PAGINATION_FILTER,
};

export const InProgressVBAs: (props: WidgetProps) => JSX.Element = () => {
  const { t } = useInternationalization();
  const { user } = useAuth();
  const [filter, setFilter] = useState({ ...defaultFilter, responsibleBuyerId: user?.profile.id });
  const navigate = useNavigate();
  const { data, isLoading } = useApi(vendorApi.getAllBuyingAgreements, null, filter);

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderVBALink = (vendorBuyingAgreementEntity: VendorBuyingAgreementEntity) =>
    !!vendorBuyingAgreementEntity.name && (
      <Box sx={styles.name}>
        {vendorBuyingAgreementEntity.responsibleBuyerId === user?.profile.id ? (
          <StarRateRoundedIcon htmlColor={palette.primary.deep} />
        ) : (
          <StarBorderRoundedIcon htmlColor={palette.primary.deep} />
        )}
        <Link
          underline="always"
          href={generatePath(routes.Admin.Vendor.BuyingAgreement.path, {
            vendorId: vendorBuyingAgreementEntity.vendorId,
            vendorBuyingAgreementId: vendorBuyingAgreementEntity.id,
          })}
          sx={styles.nameText}
        >
          {vendorBuyingAgreementEntity.name}
        </Link>
      </Box>
    );

  return (
    <Box mt={2}>
      <Typography mb={1} variant="h2">
        {t('dashboard:inProgressVBAs.title')}
      </Typography>
      <Stack sx={styles.filterContainer} direction="row" spacing={2} alignItems="center">
        <Typography align="center" variant="label" color="grey.600">
          {t('common:show')}
        </Typography>
        <Stack spacing={0.7} direction="row">
          <Chip
            key={'myVBAs'}
            label={t('dashboard:inProgressVBAs.filter.myVBAs')}
            onClick={() => setFilter((prev) => ({ ...prev, responsibleBuyerId: user?.profile.id, pageNumber: 1 }))}
            color={filter.responsibleBuyerId ? 'primary' : 'secondary'}
          />
          <Chip
            key={'all'}
            label={t('common:filter.all', { context: 'male' })}
            onClick={() => setFilter((prev) => ({ ...prev, responsibleBuyerId: undefined, pageNumber: 1 }))}
            color={!filter.responsibleBuyerId ? 'primary' : 'secondary'}
          />
        </Stack>
      </Stack>
      <Table
        data={data ?? []}
        isLoading={isLoading}
        translationNamespace="dashboard:inProgressVBAs"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
      >
        <TableColumn
          type="custom"
          width="20%"
          sortable
          id="name"
          render={(item: VendorBuyingAgreementEntity) => renderVBALink(item)}
        />
        <TableColumn
          type="custom"
          width="10%"
          sortable
          id="type"
          render={({ type }: VendorBuyingAgreementEntity) => t(`vendor:buyingAgreements.type.${type}`)}
        />
        <TableColumn
          type="custom"
          width="20%"
          sortable
          id="vendor"
          render={({ vendor }: VendorBuyingAgreementEntity) => vendor.name}
        />
        <TableColumn type="property" width="20%" sortable id="responsibleBuyer" />
        <TableColumn
          type="custom"
          width="20%"
          sortable
          id="status"
          render={({ status }: VendorBuyingAgreementEntity) =>
            status === VendorBuyingAgreementStatus.InProgressTBM
              ? t('dashboard:inProgressVBAs.table.timbermart')
              : t('dashboard:inProgressVBAs.table.vendor')
          }
        />
        <TableColumn
          type="custom"
          width="10%"
          sortable
          id="modifiedDate"
          render={({ modifiedDate }: VendorBuyingAgreementEntity) =>
            modifiedDate ? formatShortDate(modifiedDate) : ''
          }
        />
        <TableColumn
          type="button"
          id="navigate"
          align="center"
          iconProps={() => ({
            fontSize: 'medium',
            htmlColor: palette.primary.deep,
          })}
          onClick={(item: VendorBuyingAgreementEntity) =>
            navigate(
              generatePath(routes.Admin.Vendor.BuyingAgreement.path, {
                vendorId: item.vendorId,
                vendorBuyingAgreementId: item.id,
              }),
            )
          }
          iconComponent={ArrowForward}
          render={() => true}
          tooltip={false}
        />
      </Table>
    </Box>
  );
};
