import { AddRounded, ModeCommentRounded } from '@mui/icons-material';
import { Box, Button, Link, MenuItem, Stack, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { EditDrawer, EntityPeriod, LimitCommaValues } from '../../..';
import { vendor as vendorApi } from '../../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../../constants';
import { VendorBuyingAgreementContext } from '../../../../contexts/Vendor';
import { useApi, useAuth, useGlobalEdit, useInternationalization } from '../../../../hooks';
import { RebateCategoryEntity, VendorBuyingAgreementRebateCategoryEntity } from '../../../../models';
import { routes } from '../../../../routes';
import { VendorBuyingAgreementRebateCategorySchema } from '../../../../schemas';
import { FilterPeriod, OrderBy, PermissionKey, Styles, VendorRebateCategoryFilter } from '../../../../types';
import { formatShortDate } from '../../../../utils/helper';
import { PeriodFilterSelect } from '../../../Filter/PeriodFilterSelect';
import { LayoutPage } from '../../../Layout';
import { DeleteMenuItem, DuplicateMenuItem } from '../../../Menu';
import { HasPermissions } from '../../../Permission';
import { Table, TableColumn } from '../../../Table';
import { VendorRebateCategoryDuplicateDrawer } from '../../RebateCategories';
import { VendorRebateCategoryForm } from '../../RebateCategories/VendorRebateCategoryForm';
import { VendorRebateCategoryHeader } from '../../RebateCategories/VendorRebateCategoryHeader';

const style: Styles = {
  selectSmallContainer: {
    pb: 2,
  },
};
const defaultAdvancedSearchFilter = {
  period: FilterPeriod.All,
  ...DEFAULT_PAGINATION_FILTER,
};

export const VendorBuyingAgreementRebateCategoriesList = () => {
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { isInternalUser } = useAuth();
  const navigate = useNavigate();
  const {
    vendorBuyingAgreement,
    readOnly,
  } = useContext(VendorBuyingAgreementContext);
  const { vendorId, id } = vendorBuyingAgreement;
  const [filter, setFilter] = useState<VendorRebateCategoryFilter>(defaultAdvancedSearchFilter);
  const rebateCategory = useMemo(() => new VendorBuyingAgreementRebateCategoryEntity(), []);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState<RebateCategoryEntity | null>(null);
  const { data, isLoading, refresh } = useApi(
    vendorApi.getAllBuyingAgreementRebateCategories,
    null,
    vendorId,
    id,
    filter,
  );
  const createApi = useApi(vendorApi.createBuyingAgreementRebateCategory, { successKey: 'common:success.save' });
  const removeApi = useApi(vendorApi.deleteBuyingAgreementRebateCategory, { successKey: 'common:success.save' });

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const saveDrawer = async (entity: VendorBuyingAgreementRebateCategoryEntity) => {
    const createdEntity = await createApi.call(vendorId, id, entity);
    await refresh();
    return createdEntity;
  };

  const onRemoveConfirm = async (entity: VendorBuyingAgreementRebateCategoryEntity) => {
    await removeApi.call(vendorId, id, entity.id);
    refresh();
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const getDetailPath = (entity: VendorBuyingAgreementRebateCategoryEntity) =>
    generatePath(
      isInternalUser
        ? routes.Admin.Vendor.BuyingAgreementRebateCategory.path
        : routes.Vendor.BuyingAgreementRebateCategory.path,
      { vendorId, vendorBuyingAgreementId: id, vendorRebateCategoryId: entity.id },
    );

  const renderSearch = () => (
    <Box sx={style.selectSmallContainer}>
      <PeriodFilterSelect filter={filter} setFilter={setFilter} />
    </Box>
  );

  const renderRebateCategoryLink = (entity: VendorBuyingAgreementRebateCategoryEntity) => (
    <Link underline="hover" href={getDetailPath(entity)}>
      {getTranslation(entity, 'name')}
    </Link>
  );

  const renderVendorDesignations = (entity: VendorBuyingAgreementRebateCategoryEntity) => (
    <LimitCommaValues
      value={entity.vendorDesignations
        ?.map((vendorDesignation) => getTranslation(vendorDesignation, 'name'))
        .join(', ')}
      maxLength={50}
    />
  );

  const renderFilterPeriod = (filterPeriod?: FilterPeriod) => (
    <EntityPeriod period={filterPeriod} translationContext="female" />
  );

  const renderActionsMenuItems = (entity: VendorBuyingAgreementRebateCategoryEntity, onClick: () => void) => {
    const menuItems = [
      <MenuItem id="view" key="view" onClick={() => navigate(getDetailPath(entity))}>
        {t('common:view')}
      </MenuItem>,
    ];
    if (!readOnly) {
      menuItems.push(
        <DuplicateMenuItem
          permissions={{ keys: PermissionKey.VendorEditVBAs }}
          entity={vendorBuyingAgreement}
          key="duplicate"
          onClick={() => {
            onClick();
            setDuplicateOpen(entity);
          }}
        />,
        <DeleteMenuItem
          key="delete"
          permissions={{ keys: PermissionKey.VendorEditVBAs }}
          actionSuffix={t('vendor:rebateCategory.actions.suffix')}
          name={getTranslation(entity, 'name')}
          entity={entity}
          onDeleteConfirm={onRemoveConfirm}
          onClick={onClick}
        />,
      );
    }

    return menuItems;
  };

  const renderAddButton = () =>
    !readOnly ? (
      <HasPermissions keys={PermissionKey.VendorEditVBAs}>
        <Button
          variant="contained"
          startIcon={<AddRounded />}
          onClick={() => setIsAddOpen(true)}
          disabled={globalEditing}
        >
          {t('vendor:rebateCategory.actions.add')}
        </Button>
      </HasPermissions>
    ) : undefined;

  const renderNotes = (entity: VendorBuyingAgreementRebateCategoryEntity) => {
    let notes = '';
    if (isInternalUser && entity.notes) {
      notes = `${t('vendor:rebateCategory.form.notes')}: ${entity.notes}\n`;
    }
    if (getTranslation(entity, 'memberNotes')) {
      notes += `${t('vendor:rebateCategory.form.memberNotes')}: ${getTranslation(entity, 'memberNotes')}`;
    }
    return notes;
  };

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="vendor:rebateCategory"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
    >
      <TableColumn
        type="custom"
        sortable
        width="15%"
        id="period"
        render={(entity: VendorBuyingAgreementRebateCategoryEntity) => renderFilterPeriod(entity.period)}
      />
      <TableColumn type="custom" sortable width="30%" id="name" render={renderRebateCategoryLink} />
      <TableColumn type="custom" width="25%" id="vendorDesignations" render={renderVendorDesignations} />
      <TableColumn
        type="custom"
        sortable
        width="10%"
        id="effectiveDate"
        align="center"
        render={({ effectiveDate }: VendorBuyingAgreementRebateCategoryEntity) => formatShortDate(effectiveDate)}
      />
      <TableColumn
        type="custom"
        sortable
        width="10%"
        id="expiryDate"
        align="center"
        render={({ expiryDate }: VendorBuyingAgreementRebateCategoryEntity) => formatShortDate(expiryDate)}
      />
      <TableColumn
        type="icon"
        tooltip
        width={100}
        id="notes"
        align="center"
        render={renderNotes}
        iconComponent={ModeCommentRounded}
      />
    </Table>
  );

  const renderEditDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('vendor:rebateCategory.actions.add')}</Typography>
        </Stack>
      }
      open={isAddOpen}
      redirectPath={(entity) => getDetailPath(entity)}
      entity={rebateCategory}
      schema={VendorBuyingAgreementRebateCategorySchema()}
      redirectLabel={t('vendor:rebateCategory.actions.saveOpen')}
      onSave={saveDrawer}
      onConfirm={confirmDrawer}
      onCancel={cancelDrawer}
    >
      <VendorRebateCategoryHeader />
      <VendorRebateCategoryForm />
    </EditDrawer>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewVBAs }}
      title={t('vendor:rebateCategory.title')}
      display="Tab"
      rightTitle={renderAddButton()}
    >
      {renderSearch()}
      {renderTable()}
      {renderEditDrawer()}
      <VendorRebateCategoryDuplicateDrawer
        rebateCategory={duplicateOpen}
        onClose={() => setDuplicateOpen(null)}
        refresh={refresh}
      />
    </LayoutPage>
  );
};
