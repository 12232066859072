import {
  VendorBuyingAgreementEntity,
  VendorRebateCategoryRebateEntity,
  VendorRebateCategoryRebateSummaryEntity,
} from '.';
import { CategorizationEntity, PeriodEntity, TargetedMembershipEntity } from '..';

export class RebateCategoryEntity extends PeriodEntity {
  name_Fr: string;
  name_En: string;
  responsibleBuyer: string;
  responsibleBuyerId: number | null;
  notes: string;
  isArchived: boolean;
  vendorDesignations: CategorizationEntity[];
  vendorDesignationIds: number[];
  productCategoryIds: number[];
  memberNotes_Fr: string;
  memberNotes_En: string;
  tiersCount: number;
  growthsCount: number;
  tierIds: number[];
  growthIds: number[];
  rebate: VendorRebateCategoryRebateEntity;
  targetedMembership: TargetedMembershipEntity | null;
  permissionNotes: string;
  receivableRebatesSummary: VendorRebateCategoryRebateSummaryEntity | null;
  vendorBuyingAgreement: VendorBuyingAgreementEntity;
  vendorBuyingAgreementId: number | null;

  constructor(entity?: RebateCategoryEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.vendorDesignationIds = entity.vendorDesignations?.map((m) => m.id);
    } else {
      this.name_Fr = '';
      this.name_En = '';
      this.responsibleBuyer = '';
      this.responsibleBuyerId = null;
      this.notes = '';
      this.isArchived = false;
      this.vendorDesignations = [];
      this.vendorDesignationIds = [];
      this.productCategoryIds = [];
      this.memberNotes_Fr = '';
      this.memberNotes_En = '';
      this.targetedMembership = new TargetedMembershipEntity();
      this.permissionNotes = '';
      this.vendorBuyingAgreement = new VendorBuyingAgreementEntity();
      this.vendorBuyingAgreementId = null;
      this.effectiveDate = new Date(new Date().getFullYear(), 0, 1);
      this.expiryDate = new Date(new Date().getFullYear(), 11, 31);
    }
  }
}
