import { useOutlet } from 'react-router-dom';
import { useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { PermissionKey } from '../../types';
import { LayoutPage } from '../Layout';
import { RequirePermission } from '../Permission';
import { LinkTab } from '../Tab';

export const RoleLayout = () => {
  const { t } = useInternationalization();
  const outlet = useOutlet();

  const tabs = [
    <LinkTab label={t('user:role.sections.InternalUser')} value={routes.Admin.Role.InternalUsers.path} />,
    <LinkTab label={t('user:role.sections.MemberUser')} value={routes.Admin.Role.MemberUsers.path} />,
    <LinkTab label={t('user:role.sections.VendorUser')} value={routes.Admin.Role.VendorUsers.path} />,
  ];

  return (
    <RequirePermission keys={PermissionKey.AdminEditPermissions}>
      <LayoutPage title={t('user:role.title')} tabs={tabs} outlet={outlet} />
    </RequirePermission>
  );
};
