import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { useState } from 'react';
import { logging as loggingApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { ActivityLogEntity } from '../../../models';
import { ActivityLogEntityTypeGroup, ActivityLogFilter, OrderBy, PermissionKey, Styles } from '../../../types';
import { formatDateTime, formatShortDate, isDateValid } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { DatePicker, Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { Table, TableColumn } from '../../Table';
import { getTranslation } from '../../../utils/translation';
import { subMonths } from 'date-fns';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectLargeContainer: {
    width: {
      xs: '100%',
      sm: '300px',
    },
  },
};

const defaultFilter: ActivityLogFilter = {
  effectiveDate: formatShortDate(subMonths(new Date(), 1)),
  expiryDate: formatShortDate(new Date()),
  entityGroupType: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

export const ActivityLogList = () => {
  const { t, timeZoneName } = useInternationalization();
  const [filter, setFilter] = useState(defaultFilter);
  const { data, isLoading } = useApi(loggingApi.getAllActivityLogs, null, filter);

  usePageTitle('report:activityLogs.title');

  const entityGroupTypesOptions = useEnumList(ActivityLogEntityTypeGroup, 'report:activityLogs.entityTypeGroup', true);

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onEffectiveDateChange = (date: Date | null) => {
    if (date && isDateValid(date)) {
      setFilter((prev) => ({ ...prev, effectiveDate: formatShortDate(date), pageNumber: 1 }));
    }
    if (!date) {
      setFilter((prev) => ({ ...prev, effectiveDate: undefined, pageNumber: 1 }));
    }
  };

  const onExpiryDateChange = (date: Date | null) => {
    if (date && isDateValid(date)) {
      setFilter((prev) => ({ ...prev, expiryDate: formatShortDate(date), pageNumber: 1 }));
    }
    if (!date) {
      setFilter((prev) => ({ ...prev, expiryDate: undefined, pageNumber: 1 }));
    }
  };

  const onEntityGroupTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, entityGroupType: event.target.value || undefined, pageNumber: 1 }));
  };

  const onClear = () => {
    setFilter((prev) => ({
      ...prev,
      ...defaultFilter,
    }));
  };

  const formatDate = (date?: string) => (date ? `${date}T00:00:00` : null);

  const renderSearch = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <Box sx={style.selectSmallContainer}>
        <DatePicker
          value={formatDate(filter.effectiveDate)}
          label={t('report:activityLogs.filter.effectiveDate')}
          onChange={onEffectiveDateChange}
          clearable
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <DatePicker
          value={formatDate(filter.expiryDate)}
          label={t('report:activityLogs.filter.expiryDate')}
          onChange={onExpiryDateChange}
          clearable
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          value={filter.entityGroupType ?? ''}
          label={t('report:activityLogs.filter.entityGroupType')}
          onChange={onEntityGroupTypeChange}
          options={entityGroupTypesOptions}
        />
      </Box>
    </FilterContainer>
  );

  const renderEntityName = (entity: ActivityLogEntity) => (
    <Typography>{getTranslation(entity, 'entityName')}</Typography>
  );

  const renderFieldsName = (entity: ActivityLogEntity) => (
    <Typography>{entity.fields.map((f) => f.fieldName).join(', ')}</Typography>
  );

  const renderEntityTypeGroup = (entity: ActivityLogEntity) => (
    <Typography>{t(`report:activityLogs.entityTypeGroup.${entity.entityTypeGroup}`)}</Typography>
  );

  const renderEntityType = (entity: ActivityLogEntity) => (
    <Typography>{t(`report:activityLogs.entityType.${entity.entityType}`)}</Typography>
  );

  const renderEntityAction = (entity: ActivityLogEntity) => (
    <Typography>{t(`report:activityLogs.entityAction.${entity.entityAction}`)}</Typography>
  );

  const renderLogDate = (entity: ActivityLogEntity) => formatDateTime(entity.actionDate, timeZoneName);

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="report:activityLogs"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
    >
      <TableColumn type="custom" width="12%" id="entityTypeGroup" sortable render={renderEntityTypeGroup} />
      <TableColumn type="custom" width="13%" id="entityName" sortable render={renderEntityName} />
      <TableColumn type="custom" width="15%" id="entityType" sortable render={renderEntityType} />
      <TableColumn type="custom" width="25%" id="fields" render={renderFieldsName} />
      <TableColumn type="custom" width="10%" id="entityAction" sortable render={renderEntityAction} />
      <TableColumn type="property" width="15%" id="user" sortable />
      <TableColumn type="custom" width="10%" id="actionDate" sortable render={renderLogDate} />
    </Table>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.ReportActivityLog }}
      display="Tab"
      title={t(`report:activityLogs.title`)}
    >
      {renderSearch()}
      {renderTable()}
    </LayoutPage>
  );
};
