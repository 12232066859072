import { Grid } from '@mui/material';
import { useEntityFormContext, useInternationalization } from '../../hooks';
import { SettingEntity } from '../../models';
import { ControlledCheckbox } from '../Form';

export const SettingMailSyncForm = () => {
  const { t } = useInternationalization();
  const { control } = useEntityFormContext<SettingEntity>();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mt={1.5}>
        <ControlledCheckbox label={t('setting:mailSync.label')} name="isMailSyncEnabled" control={control} />
      </Grid>
    </Grid>
  );
};
