import { EditRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Collapse, Divider, Grid, IconButtonProps, MenuItemProps, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityFormProvider } from '../contexts';
import { useEntityForm, useGlobalEdit } from '../hooks';
import { EditableEntity } from '../models';
import { palette } from '../styles/palette';
import { Breadcrumb, EntityFormSchema, PermissionProps, Styles } from '../types';
import { Breadcrumbs } from './Breadcrumbs';
import { EntityModifiedDate } from './EntityModifiedDate';
import { ControlledInput } from './Form';
import { ActionMenu } from './Menu';
import { HasPermissions } from './Permission';

const style: Styles = {
  box: {
    mb: 0,
  },
  header: {
    mb: 2,
    pb: 1,
  },
  actionBox: {
    backgroundColor: palette.primary.dark,
    width: { xs: '100%', md: 'auto' },
  },
  actionButton: {
    color: palette.primary.deep,
  },
  showMoreButton: {
    px: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
      textDecoration: 'underline',
    },
  },
  collapse: {
    position: 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '30px',
      backgroundImage: 'linear-gradient(to bottom, #00000000, #FFFFFFFF)',
    },
  },
  expanded: {
    mb: 2,
  },
  editTitleBox: {
    py: 1,
    px: {
      xs: 2,
      md: 3,
    },
    color: palette.white,
    backgroundColor: palette.primary.dark,
    borderTopLeftRadius: '16px',
    mb: 3,
  },
  actions: {
    width: { xs: '100%', md: 'auto' },
  },
};

interface EditDetailsProps<T extends EditableEntity> extends PropsWithChildren {
  entity: T;
  schema: EntityFormSchema<T>;
  onSave?: (entity: T) => Promise<T | null>;
  onConfirm?: () => void;
  disabled?: boolean;
  actionMenuItems?: (onClick: () => void) => React.ReactElement<MenuItemProps>[];
  title: (keyof T & string) | string;
  titleLabel: string;
  header?: ReactNode;
  breadcrumbs?: Breadcrumb[];
  alwaysOpen?: boolean;
  hideHeaderWhenEditing?: boolean;
  permissions?: PermissionProps;
  extraActions?: React.ReactElement<IconButtonProps>[];
  workflow?: React.ReactElement;
  readOnly?: boolean;
  renderModifiedDate?: (entity: T) => React.ReactElement;
}

export const EditDetails = <T extends EditableEntity>({
  disabled,
  entity,
  schema,
  onSave,
  onConfirm,
  title,
  children,
  actionMenuItems,
  titleLabel,
  breadcrumbs,
  alwaysOpen,
  header,
  hideHeaderWhenEditing,
  permissions,
  extraActions,
  workflow,
  readOnly,
  renderModifiedDate,
}: EditDetailsProps<T>) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const { globalEditing, setGlobalEditing } = useGlobalEdit();
  const [isOpen, setIsOpen] = useState(alwaysOpen || false);
  const { handleSubmit, reset, isSubmitDisabled, form, control, handleError } = useEntityForm(entity, schema, {
    readOnly: readOnly || !editing || !onSave,
    variant: 'details',
  });
  const [isSaving, setIsSaving] = useState(false);

  const onEditClick = () => {
    setEditing(!editing);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const onCollapseClick = () => {
    setIsOpen(!isOpen);
    if (editing) {
      onCancelClick();
    }
  };

  const onCancelClick = () => {
    reset(entity);
    setEditing(false);
  };

  const onSubmitSuccess = async (data: T) => {
    if (onSave && !isSaving) {
      try {
        setIsSaving(true);
        const saveResult = await onSave(data);
        if (saveResult) {
          setEditing(false);
          onConfirm && onConfirm();
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const hasActionMenuItems = () => {
    const actionsFiltered = actionMenuItems ? actionMenuItems(() => undefined)?.filter((a) => !!a) : null;
    return actionsFiltered && actionsFiltered.length > 0;
  };

  useEffect(() => {
    setGlobalEditing(editing);
    if ((disabled || readOnly) && editing) {
      setEditing(false);
    }
  }, [disabled, readOnly, editing, setGlobalEditing]);

  const hideHeader = useMemo(() => hideHeaderWhenEditing && editing, [editing, hideHeaderWhenEditing]);

  return (
    <>
      {!editing && (
        <Stack
          direction={{
            xs: entity.modifiedDate && entity.modifiedBy ? 'column-reverse' : 'column',
            md: entity.modifiedDate && entity.modifiedBy ? 'row-reverse' : 'row',
          }}
          justifyContent={entity.modifiedDate && entity.modifiedBy ? 'space-between' : 'flex-start'}
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          {renderModifiedDate ? renderModifiedDate(entity) : <EntityModifiedDate entity={entity} />}
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        </Stack>
      )}
      {workflow && !editing && (
        <Stack spacing={1.3} mt={1} mb={2}>
          {workflow}
          <Divider />
        </Stack>
      )}
      <Box sx={style.box}>
        <EntityFormProvider {...form}>
          <form id="edit-vendor-form" onSubmit={handleSubmit(onSubmitSuccess)}>
            {editing && (
              <Grid container sx={style.editTitleBox} rowGap={1}>
                <Grid item xs={12} md={8} lg={9} display="flex">
                  <Stack spacing={1} direction="row" alignItems="center">
                    <EditRounded />
                    <Typography variant="drawerTitle">
                      {t('common:editing', { name: entity[title as keyof T] ? entity[title as keyof T] : title })}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Stack spacing={1} direction="row" sx={style.actionBox} justifyContent="flex-end">
                    <Button onClick={onCancelClick} variant="outlined" color="secondary" size="small">
                      {t('common:cancel')}
                    </Button>
                    <LoadingButton
                      loading={isSaving}
                      disabled={isSubmitDisabled}
                      variant="contained"
                      size="small"
                      type="submit"
                    >
                      {t('common:saveAndClose')}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            )}
            <Box>
              <Stack
                sx={style.header}
                spacing={{ xs: 0, md: 3 }}
                direction={{ xs: 'column-reverse', md: 'row' }}
                alignItems="flex-start"
                justifyContent="space-between"
              >
                {!hideHeader
                  ? header || (
                      <ControlledInput
                        variant="standard"
                        label={titleLabel}
                        name={title}
                        control={control}
                        required
                        hideLabel={!editing}
                      />
                    )
                  : null}
                {!globalEditing && (
                  <HasPermissions {...permissions}>
                    <Stack
                      direction="row"
                      spacing={0.5}
                      alignItems="center"
                      justifyContent="flex-end"
                      sx={style.actions}
                    >
                      {!readOnly && onSave && (
                        <Button
                          disabled={editing || disabled}
                          variant="text"
                          sx={style.actionButton}
                          startIcon={<EditRounded />}
                          onClick={onEditClick}
                        >
                          {t('common:edit')}
                        </Button>
                      )}
                      {extraActions}
                      {actionMenuItems && hasActionMenuItems() && <ActionMenu menuItems={actionMenuItems} />}
                    </Stack>
                  </HasPermissions>
                )}
              </Stack>
              {children && (
                <>
                  <Collapse sx={!isOpen ? style.collapse : style.expanded} collapsedSize="70px" in={isOpen}>
                    <Box>{children}</Box>
                  </Collapse>
                  {!editing && !alwaysOpen && (
                    <Box>
                      <Button
                        size="small"
                        variant="text"
                        sx={[style.actionButton, style.showMoreButton]}
                        onClick={onCollapseClick}
                      >
                        {isOpen ? t('common:showLess') : t('common:showMore')}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </form>
        </EntityFormProvider>
      </Box>
    </>
  );
};
