import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Attachments } from '../Base/Attachments/Attachments';

interface ControlledAttachmentsProps extends Omit<React.ComponentProps<typeof Attachments>, 'onChange' | 'files'> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  hideLabel?: boolean;
}

export const ControlledAttachments = ({ name, control, ...props }: ControlledAttachmentsProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
        const onFileChange = (files: File[]) => {
          onChange(files);
          onBlur();
        };

        return (
          <Attachments
            {...props}
            onChange={onFileChange}
            files={value}
            readOnly={control._options.context?.readOnly}
            error={!!error}
            helperText={error?.message && t(error?.message, { name: props.label })}
            hideLabel
          />
        );
      }}
    />
  );
};
