import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { member as memberApi, memberGroup as memberGroupApi } from '../../api';
import { MemberDetailContext } from '../../contexts';
import { useApi } from '../../hooks';
import { MemberGroupEntity, MemberPermissionEntity } from '../../models';
import { SelectOption, Styles } from '../../types';
import { Container } from '../Container';
import { Select } from '../Form';
import { MemberPermissionsList } from '../MemberPermissions';

const style: Styles = {
  container: {
    pt: 4,
    pb: 4,
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
};

export const MemberMemberGroups = () => {
  const { t } = useTranslation();
  const { member } = useContext(MemberDetailContext);
  const { data, refresh } = useApi(memberApi.getMemberGroups, { skipFetch: true }, member?.id);
  const { call } = useApi(memberApi.updateMemberGroup, { successKey: 'common:success.save' });
  const [isIncludeFilter, setIncludeFilter] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    refresh();
  }, [member, refresh]);

  const getPermissionOptions = (): SelectOption<string>[] => [
    { label: t('common:filter.all', { context: 'female' }), value: '' },
    { label: t('common:permission.include'), value: 'true' },
    { label: t('common:permission.exclude'), value: 'false' },
  ];

  const onPermissionChange = (event: SelectChangeEvent<unknown>) => {
    const value = !event.target.value ? undefined : event.target.value === 'true';
    setIncludeFilter(value);
  };

  const onAddPermission = (memberGroup: MemberGroupEntity) => {
    onUpdate(0, { ...new MemberPermissionEntity(), memberGroupId: memberGroup.id, isIncluded: true });
  };

  const onUpdate = async (_: number, permission: MemberPermissionEntity) => {
    if (member) {
      await call(member?.id, permission.memberGroupId, permission.isIncluded);
      refresh();
    }
  };

  const getFilterData = () =>
    data?.filter((d) => (isIncludeFilter === undefined ? true : d.isIncluded === isIncludeFilter)) ?? [];

  return (
    <Container isHighlighted sx={style.container}>
      <Stack spacing={2}>
        <Typography variant="h2">{t('member:sections.memberGroups')}</Typography>
        <Stack spacing={3}>
          <Typography variant="searchTitle">{t('memberGroup:filterList')}</Typography>
          <Box sx={style.selectSmallContainer}>
            <Select
              displayEmpty
              value={isIncludeFilter ?? ''}
              options={getPermissionOptions()}
              onChange={onPermissionChange}
              label={t('memberGroup:form.permission')}
            />
          </Box>
        </Stack>
        <MemberPermissionsList
          permissions={getFilterData()}
          isIncludedProperty="isIncluded"
          apiCall={memberGroupApi.getSuggestions}
          renderKeyValue={(p: MemberPermissionEntity) => ({ label: p.memberGroupName ?? '', value: p.memberGroupId })}
          renderOptionLabel={(item: MemberGroupEntity) => item.name}
          nameColumn={t('member:sections.memberGroups')}
          isIncludedColumn={t('memberGroup:form.permission')}
          onUpdate={onUpdate}
          onAdd={onAddPermission}
          readOnly={member?.isArchived}
        />
      </Stack>
    </Container>
  );
};
