import { vendor as vendorApi } from '../../../api';
import { useApi, useInternationalization } from '../../../hooks';
import { BuyGuideFilter, OrderBy, Styles } from '../../../types';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { routes } from '../../../routes';
import { WidgetProps } from './Widget';
import { Table, TableColumn } from '../../Table';
import { VendorEntity } from '../../../models';
import { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { formatShortDate } from '../../../utils/helper';
import { ArrowForward } from '@mui/icons-material';
import { palette } from '../../../styles/palette';

const styles: Styles = {
  name: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  nameText: {
    marginLeft: 1,
    marginTop: 0.5,
    fontWeight: 600,
    fontSize: 14,
  },
};

const defaultFilter: BuyGuideFilter = {
  isFavourite: true,
  orderBy: [{columnName: "latestChange", direction: "desc" }],
  ...DEFAULT_PAGINATION_FILTER,
};

export const FavouriteVendors: (props: WidgetProps) => JSX.Element = () => {
  const { t } = useInternationalization();
  const [filter, setFilter] = useState(defaultFilter);
  const navigate = useNavigate();
  const { data, isLoading } = useApi(vendorApi.getAll, null, filter);

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderVendorLink = (id: number, text: string | undefined) =>
    !!text && (
      <Box sx={styles.name}>
        <StarRateRoundedIcon htmlColor={palette.primary.deep} />
        <Link
          underline="always"
          href={generatePath(routes.Member.BuyGuideDetail.path, {
            vendorId: id,
          })}
          sx={styles.nameText}
        >
          {text}
        </Link>
      </Box>
    );

  return (
    <Box mt={2}>
      <Typography mb={1} variant="h2">
        {t('dashboard:favouriteVendors.title')}
      </Typography>
      <Table
        data={data ?? []}
        isLoading={isLoading}
        translationNamespace="dashboard:favouriteVendors"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
      >
        <TableColumn
          type="custom"
          width="90%"
          sortable
          id="name"
          render={({ id, name }: VendorEntity) => renderVendorLink(id, name)}
        />
        <TableColumn
          type="custom"
          width="10%"
          sortable
          id="latestChange"
          render={({ latestChange }: VendorEntity) => (latestChange ? formatShortDate(latestChange) : '')}
        />
        <TableColumn
          type="button"
          id="navigate"
          align="center"
          iconProps={() => ({
            fontSize: 'medium',
            htmlColor: palette.primary.deep,
          })}
          onClick={(item: VendorEntity) =>
            navigate(
              generatePath(routes.Member.BuyGuideDetail.path, {
                vendorId: item.id,
              }),
            )
          }
          iconComponent={ArrowForward}
          tooltip={false}
        />
      </Table>
    </Box>
  );
};
