import { SvgIconComponent } from '@mui/icons-material';
import { ReactElement } from 'react';
import { Styles } from '../../types';

interface PropertyCardListElementProps<T> {
  type: 'property';
  label?: string;
  id: keyof T;
}

interface TitleCardListElementProps<T> {
  type: 'title';
  id: keyof T | string;
  translated?: boolean;
}

interface IconCardListElementProps<T> {
  type: 'icon';
  id: keyof T;
  tooltip?: boolean;
  iconComponent: ((value: T[keyof T]) => SvgIconComponent | null) | SvgIconComponent;
  render?: (item: T) => string;
}

interface CustomCardListElementProps<T> {
  type: 'custom';
  id: string;
  render: (item: T) => ReactElement | string | false;
  rawRender?: boolean;
}

export type CardListElementProps<T> = (
  | PropertyCardListElementProps<T>
  | TitleCardListElementProps<T>
  | CustomCardListElementProps<T>
  | IconCardListElementProps<T>
) & {
  decorator?: boolean;
  hideLabel?: boolean;
  sortable?: boolean;
  hideIf?: (item: T) => boolean;
  sx?: Styles[0];
  align?: 'left' | 'right';
};

// eslint-disable-next-line no-empty-pattern
export const CardListElement = <T,>({}: CardListElementProps<T>) => <></>;
