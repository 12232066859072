import { array, date, number, object, ref, string } from 'yup';
import { MAX_FILE_SIZE_MB, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorBuyOpportunityEntity } from '../../models/Vendor/VendorBuyOpportunityEntity';
import { EntityFormSchema, OpportunityType } from '../../types';
import { mbToBytes } from '../../utils/file';
import { isDateValid } from '../../utils/helper';

export const VendorBuyOpportunitySchema = (isDetails?: boolean): EntityFormSchema<VendorBuyOpportunityEntity> => ({
  schema: object().shape(
    {
      name_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
      name_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      opportunityType: string().required().oneOf(Object.values(OpportunityType)),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .required()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      vendorBuyingAgreementId: number()
        .notRequired()
        .nullable()
        .when('opportunity', {
          is: (value: OpportunityType) => value === OpportunityType.Booking || value === OpportunityType.SpecialBuy,
          then: number().typeError('common:error.fieldRequired').required(),
        }),
      responsibleBuyerId: number().typeError('common:error.fieldRequired').required(),
      files: array()
        .max(VendorBuyOpportunityEntity.MAX_FILES_COUNT)
        .test('max-size', 'common:error.arrayMaxItemSize', (files) =>
          files ? files.every((f) => f.size < mbToBytes(MAX_FILE_SIZE_MB)) : true,
        ),
      shippingDate_En: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      shippingDate_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      foid_En: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      foid_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      terms_En: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      terms_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      paymentTermDiscount_En: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      paymentTermDiscount_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      minimumOrder_En: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      minimumOrder_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      minimumOrderQuantity_En: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      minimumOrderQuantity_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      minimumFreightPrepaid_En: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      minimumFreightPrepaid_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      participatingDCs: array().notRequired(),
      description_En: isDetails
        ? string().required().max(MAX_LONG_TEXT_LENGTH)
        : string().notRequired().max(MAX_LONG_TEXT_LENGTH),
      description_Fr: string().notRequired().max(MAX_LONG_TEXT_LENGTH),
      vendorNotes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
