import axios from 'axios';
import { MAX_PAGINATION_FILTER } from '../../constants';
import { CommitmentEventCategoryEntity, CommitmentEventEntity } from '../../models';
import { CommitmentEventCategoryFilter, PaginationEntity } from '../../types';

export const CommitmentEventCategory = {
  getAllCommitmentEventCategories: async (
    commitmentEventId: number,
    commitmentEventCategoryFilter: CommitmentEventCategoryFilter,
    memberId: number | null = null,
  ): Promise<PaginationEntity<CommitmentEventCategoryEntity>> => {
    const { data } = await axios.post(`api/commitmentEvent/${commitmentEventId}/categories/search`, {
      memberId,
      ...commitmentEventCategoryFilter,
    });
    return { ...data, data: data.data.map((d: CommitmentEventCategoryEntity) => new CommitmentEventCategoryEntity(d)) };
  },
  createCommitmentEventCategory: async (
    commitmentEventId: number,
    entity: CommitmentEventCategoryEntity,
  ): Promise<CommitmentEventCategoryEntity> => {
    const { data } = await axios.post(`api/commitmentEvent/${commitmentEventId}/categories`, entity);
    return new CommitmentEventCategoryEntity(data);
  },
  archiveCommitmentEventCategories: async (
    commitmentEventId: number,
    isArchived: boolean,
    ids: number[],
  ): Promise<CommitmentEventCategoryEntity[]> => {
    const { data } = await axios.put(`api/commitmentEvent/${commitmentEventId}/categories/archive`, {
      isArchived,
      ids,
    });
    return data;
  },
  getCommitmentEventCategory: async (
    commitmentEventId: number,
    categoryId: number,
  ): Promise<CommitmentEventCategoryEntity> => {
    const { data } = await axios.get<CommitmentEventCategoryEntity>(
      `api/commitmentEvent/${commitmentEventId}/categories/${categoryId}`,
    );
    return new CommitmentEventCategoryEntity(data);
  },
  updateCommitmentEventCategory: async (
    commitmentEventId: number,
    entity: CommitmentEventCategoryEntity,
  ): Promise<CommitmentEventCategoryEntity> => {
    const { data } = await axios.put(`api/commitmentEvent/${commitmentEventId}/categories/${entity.id}`, entity);
    return new CommitmentEventCategoryEntity(data);
  },
  addCommitmentEventCategory: async (commitmentEventId: number, categoryId: number): Promise<CommitmentEventEntity> => {
    const { data } = await axios.post(`api/commitmentEvent/${commitmentEventId}/category/${categoryId}`);
    return new CommitmentEventEntity(data);
  },
  getCommitmentEventCategorySuggestions: async (
    name: string,
    commitmentEventId: number,
  ): Promise<PaginationEntity<CommitmentEventCategoryEntity>> => {
    const { data } = await axios.post(`api/commitmentEvent/${commitmentEventId}/categories/suggestions`, {
      name,
      distinctBy: 'name',
      pageSize: 10,
      pageNumber: 1,
    });
    return { ...data, data: data.data.map((v: CommitmentEventCategoryEntity) => new CommitmentEventCategoryEntity(v)) };
  },
  getCommitmentCategorySuggestions: async (name: string): Promise<PaginationEntity<CommitmentEventCategoryEntity>> => {
    const { data } = await axios.post(`api/commitmentEvent/categories/search`, {
      name,
      distinctBy: 'name',
      ...MAX_PAGINATION_FILTER,
    });
    return { ...data, data: data.data.map((v: CommitmentEventCategoryEntity) => new CommitmentEventCategoryEntity(v)) };
  },
};
