import axios from 'axios';
import {
  VendorRebateCategoryEntity,
  VendorRebateCategoryRebateEntity,
  VendorRebateCategoryValueEntity,
} from '../../models';
import {
  PaginationEntity,
  PaginationFilter,
  RebateCategoryDuplicate,
  VendorRebateCategoryFilter,
  VendorRebateCategoryValue,
} from '../../types';

export const rebate = {
  getAllRebateCategories: async (
    vendorId: number,
    rebateCategoryFilter: VendorRebateCategoryFilter,
  ): Promise<PaginationEntity<VendorRebateCategoryEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/rebatecategories/search`, rebateCategoryFilter);
    return { ...data, data: data.data.map((v: VendorRebateCategoryEntity) => new VendorRebateCategoryEntity(v)) };
  },
  getRebateCategorySuggestions: async (
    name: string,
    vendorId: number,
  ): Promise<PaginationEntity<VendorRebateCategoryEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/rebatecategories/search`, {
      name,
      distinctBy: 'name',
      pageSize: 10,
      pageNumber: 1,
    });
    return { ...data, data: data.data.map((v: VendorRebateCategoryEntity) => new VendorRebateCategoryEntity(v)) };
  },
  getCurrentRebateCategories: async (
    rebateCategoryFilter: PaginationFilter,
  ): Promise<PaginationEntity<VendorRebateCategoryEntity>> => {
    const { data } = await axios.post(`api/vendor/rebatecategories/search`, rebateCategoryFilter);
    return { ...data, data: data.data.map((v: VendorRebateCategoryEntity) => new VendorRebateCategoryEntity(v)) };
  },
  createRebateCategory: async (
    vendorId: number,
    entity: VendorRebateCategoryEntity,
  ): Promise<VendorRebateCategoryEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/rebatecategories`, entity);
    return new VendorRebateCategoryEntity(data);
  },
  archiveRebateCategory: async (
    vendorId: number,
    ids: number[],
    isArchived: boolean,
  ): Promise<VendorRebateCategoryEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/rebatecategories/archive`, { isArchived, ids });
    return data.map((v: VendorRebateCategoryEntity) => new VendorRebateCategoryEntity(v));
  },
  duplicateRebateCategory: async (duplicate: RebateCategoryDuplicate): Promise<VendorRebateCategoryEntity> => {
    const { data } = await axios.put(
      `api/vendor/${duplicate.vendorId}/rebatecategories/${duplicate.rebateCategoryId}/duplicate`,
      duplicate,
    );
    return new VendorRebateCategoryEntity(data);
  },
  getRebateCategory: async (vendorId: number, rebateCategoryId: number): Promise<VendorRebateCategoryEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}`);
    return new VendorRebateCategoryEntity(data);
  },
  updateRebateCategory: async (
    vendorId: number,
    entity: VendorRebateCategoryEntity,
  ): Promise<VendorRebateCategoryEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/rebatecategories/${entity.id}`, entity);
    return new VendorRebateCategoryEntity(data);
  },
  getRebateCategoryValues: async (type: VendorRebateCategoryValue): Promise<VendorRebateCategoryValueEntity[]> => {
    const { data } = await axios.get(`api/rebate/${type}`);
    return data.map((v: VendorRebateCategoryValueEntity) => new VendorRebateCategoryValueEntity(v));
  },
  getRebateCategoryRebate: async (
    vendorId: number,
    rebateCategoryId: number,
  ): Promise<VendorRebateCategoryRebateEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/rebates`);
    return new VendorRebateCategoryRebateEntity(data);
  },
  updateRebateCategoryRebate: async (
    vendorId: number,
    rebateCategoryId: number,
    entity: VendorRebateCategoryRebateEntity,
  ): Promise<VendorRebateCategoryRebateEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/rebates`, entity);
    return new VendorRebateCategoryRebateEntity(data);
  },
};
