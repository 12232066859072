import React from 'react';
import { VendorBuyOpportunityEntity } from '../../models';

interface BuyGuideVendorBuyOpportunityDetailContextType {
  vendorId: number;
  buyOpportunityId: number;
  buyOpportunity: VendorBuyOpportunityEntity | null;
}

export const BuyGuideVendorBuyOpportunityDetailContext =
  React.createContext<BuyGuideVendorBuyOpportunityDetailContextType>(
    {} as BuyGuideVendorBuyOpportunityDetailContextType,
  );
