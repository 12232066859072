import { DragHandleRounded, MoreVertRounded, SvgIconComponent } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Menu,
  MenuItemProps,
  Stack,
  SvgIconProps,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useInternationalization } from '../../hooks';
import { palette } from '../../styles/palette';
import theme from '../../styles/theme';
import { Archivable, BulkAction, Entity, PaginationEntity, PaginationFilter, Styles } from '../../types';
import { scrollToTop } from '../../utils/helper';
import { BulkActions } from '../BulkActions';
import { Pagination } from '../Pagination';
import { CardListElementProps } from './CardListElement';
import { CardListRowProps } from './CardListRow';
import { PAGINATION_ROWS_PER_PAGE_OPTIONS } from '../../constants';

const style: Styles = {
  archivedElement: {
    backgroundColor: `${palette.grey[50]} !important`,
    '& .MuiCardListCell-root': {
      color: palette.grey[400],
    },
    '& .MuiTypography-h2, & .MuiTypography-label, & .MuiTypography-body1': {
      color: `${palette.grey[400]} !important`,
    },
  },
  draggingElement: {
    display: 'table',
    backgroundColor: palette.white,
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderColor: palette.secondary.dark,
  },
  body: {
    transition: 'opacity 0ms',
    '& tr': {
      backgroundColor: palette.white,
    },
  },
  loading: {
    my: 2,
  },
  bodyLoading: {
    transition: 'opacity 1s ease-out',
    opacity: 0.5,
  },
  cellIcon: {
    fontSize: 'small',
    htmlColor: palette.primary.deep,
  },
  cardRow: {
    borderBottom: '1px solid ' + palette.grey[300],
    pb: 2,
    mb: 2,
    '&:last-child': {
      borderBottom: 'none',
      pb: 0,
      mb: 0,
    },
  },
  noRowUnderline: {
    borderBottom: 0,
    paddingBottom: 0,
  },
  cardElement: {
    width: { xs: '45%', md: 'auto' },
  },
  cardElementSingleElement: {
    width: undefined,
  },
  cardElementFirstRow: {
    width: undefined,
  },
  cardElementUtility: {
    width: undefined,
    flex: 0,
  },
  menuAndDecorators: {
    width: { xs: '100%', md: 'auto' },
  },
  modifiedDate: {
    width: '100%',
    textAlign: 'right',
  },
  title: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@supports (-webkit-line-clamp: 2) or (line-clamp: 2)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      display: '-webkit-box',
      lineClamp: '2',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
  },
};

export interface CardListReorderEvent {
  fromIndex: number;
  toIndex: number;
}

export interface CardListProps<T extends Entity & Archivable> {
  actionMenuItems?: (
    item: T,
    onClick: () => void,
  ) => React.ReactElement<MenuItemProps>[] | React.ReactElement<MenuItemProps>;
  children?: (ReactElement<CardListRowProps<T>> | false)[] | ReactElement<CardListRowProps<T>>;
  data: Array<T> | PaginationEntity<T>;
  isLoading?: boolean;
  reorderable?: boolean;
  onReorder?: (e: CardListReorderEvent) => void;
  translationData?: Record<string, string>;
  translationNamespace: string;
  collapsible?: boolean;
  paginationFilter?: PaginationFilter;
  onPaginationChange?: (page: number, pageSize: number) => void;
  bulkActions?: BulkAction<T>[];
  bulkActionSuffix?: string;
  renderBulkSelection?: (items: T[]) => string;
  href?: (item: T) => string;
  hideResultCount?: boolean;
  hideZeroResultCount?: boolean;
  linkOnlyInTitle?: boolean;
  modifiedDate?: (item: T) => string | false;
}

export const CardList = <T extends Entity & Archivable>({
  actionMenuItems,
  children,
  data,
  hideResultCount,
  hideZeroResultCount,
  linkOnlyInTitle,
  href,
  isLoading,
  onReorder,
  reorderable,
  translationData,
  translationNamespace,
  collapsible,
  onPaginationChange,
  paginationFilter,
  bulkActions,
  bulkActionSuffix,
  renderBulkSelection,
}: CardListProps<T>) => {
  const { t, getTranslation } = useInternationalization();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuItem, setMenuItem] = useState<T | null>(null);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  useEffect(() => {
    scrollToTop();
  }, [paginationFilter]);

  useEffect(() => {
    setSelectedItems([]);
  }, [data]);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>, item: T) => {
    setAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!children) {
    return null;
  }

  const rows = Array.isArray(children)
    ? children.filter((x) => x).map((x) => (x as ReactElement<CardListRowProps<T>>).props)
    : [children.props];

  const handleOnDragEnd = (result: DropResult) =>
    result.destination && onReorder && onReorder({ fromIndex: result.source.index, toIndex: result.destination.index });

  const renderCardListCell = (item: T, element: CardListElementProps<T>) => {
    switch (element.type) {
      case 'custom': {
        const value = element.render(item);
        if (!value) {
          return null;
        }

        return (
          <Stack direction="column" sx={{ textAlign: element.align }}>
            {!element.hideLabel && (
              <Typography display="block" variant="label">
                {t(`${translationNamespace}:card.${new String(element.id)}`, translationData)}
              </Typography>
            )}
            {!element.rawRender ? (
              <Typography component="span" display="block" variant="body1">
                {value}
              </Typography>
            ) : (
              value
            )}
          </Stack>
        );
      }
      case 'title':
        return (
          <Typography variant="h2" sx={style.title}>
            {element.translated
              ? // Unable to use TranslatedStrings type for TitleCardListElementProps
                // since it always could be keyof T as well, therefore:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                new String(getTranslation(item as any, element.id as any) ?? '')
              : new String(item[element.id as keyof T] ?? '')}
          </Typography>
        );
      case 'property': {
        const value = item[element.id] ?? '';
        if (!value) {
          return null;
        }

        return (
          <Stack direction="column">
            {!element.hideLabel && (
              <Typography display="block" variant="label">
                {element.label
                  ? element.label
                  : t(`${translationNamespace}:card.${new String(element.id)}`, translationData)}
              </Typography>
            )}
            <Typography display="block" variant="body1">
              {new String(value)}
            </Typography>
          </Stack>
        );
      }
      case 'icon': {
        const iconTooltipText = (element.render ? element.render(item) : item[element.id]) as string;
        let icon: SvgIconComponent | null = null;
        if (typeof element.iconComponent === 'function') {
          icon = (element.iconComponent as CallableFunction)(iconTooltipText);
        } else if (iconTooltipText) {
          icon = element.iconComponent;
        }

        return (
          icon && (
            <Tooltip
              arrow
              placement="top"
              title={element.tooltip ? <Typography variant="tooltip">{iconTooltipText}</Typography> : ''}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                {React.createElement(icon, style.cellIcon as SvgIconProps)}
                {!element.hideLabel && (
                  <Typography>
                    {t(`${translationNamespace}:card.${new String(element.id)}`, translationData)}
                  </Typography>
                )}
              </Stack>
            </Tooltip>
          )
        );
      }
      default:
        return '';
    }
  };

  const renderCardListElements = (item: T, elements: CardListElementProps<T>[], firstRow?: boolean) =>
    elements.map((element, i) => {
      const result = renderCardListCell(item, element);
      if (!result) {
        return null;
      }

      return (
        <Box
          key={element.id.toString() + i.toString()}
          sx={{
            ...style.cardElement,
            ...(firstRow ? style.cardElementFirstRow : {}),
            ...(elements.length === 1 ? style.cardElementSingleElement : {}),
          }}
        >
          {result}
        </Box>
      );
    });

  const renderRow = (item: T, index: number) => (
    <Draggable draggableId={item.id.toString()} index={index} isDragDisabled={!reorderable} key={item.id}>
      {(provided, snapshot) => (
        <>
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              ...(snapshot.isDragging ? style.draggingElement : {}),
              ...(item.isArchived ? style.archivedElement : {}),
            }}
            component={!linkOnlyInTitle && href ? Link : 'div'}
            href={href && href(item)}
          >
            <CardContent>
              {rows.map((row, i) => {
                if (!row.children) {
                  return;
                }
                const elements = Array.isArray(row.children)
                  ? row.children.filter((x) => x).map((x) => (x as ReactElement<CardListElementProps<T>>).props)
                  : [row.children.props];

                const filteredElements = elements.filter((element) => !element.hideIf || !element.hideIf(item));

                if (!filteredElements.length) {
                  return;
                }

                return (
                  <Stack
                    key={i}
                    sx={
                      row.noUnderline || i === 0
                        ? { ...style.cardRow, ...row.sx, ...style.noRowUnderline }
                        : { ...style.cardRow, ...row.sx }
                    }
                    flexWrap={i === 0 ? undefined : { xs: 'wrap', md: undefined }}
                    direction={i === 0 ? { xs: 'column-reverse', md: 'row' } : 'row'}
                    justifyContent={i === 0 ? 'space-between' : row.justifyContent ?? 'flex-start'}
                    alignItems={i === 0 ? 'flex-start' : row.alignItems ?? 'flex-start'}
                    gap={i === 0 ? 0 : 2}
                  >
                    {i === 0 ? (
                      <>
                        {reorderable && (
                          <Box {...provided.dragHandleProps} sx={{ ...style.cardElement, ...style.cardElementUtility }}>
                            <DragHandleRounded htmlColor={palette.secondary.dark} />
                          </Box>
                        )}
                        {bulkActions && (
                          <Box sx={{ ...style.cardElement, ...style.cardElementUtility }}>
                            <Checkbox
                              size="small"
                              checked={selectedItems?.includes(item)}
                              onChange={onRowCheck(item)}
                            />
                          </Box>
                        )}
                        <Stack direction="row" flexWrap="wrap" gap={1} alignItems="center">
                          <Box
                            component={linkOnlyInTitle && href ? Link : 'div'}
                            href={href && linkOnlyInTitle ? href(item) : undefined}
                            underline={linkOnlyInTitle ? 'hover' : undefined}
                          >
                            {renderCardListElements(
                              item,
                              filteredElements.filter((element) => element.type === 'title'),
                              true,
                            )}
                          </Box>
                          <Stack direction="row" spacing={1}>
                            {renderCardListElements(
                              item,
                              filteredElements.filter((element) => element.type !== 'title' && !element.decorator),
                              true,
                            )}
                          </Stack>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="flex-end"
                          sx={style.menuAndDecorators}
                        >
                          <Stack direction="row" spacing={1} alignItems="center">
                            {renderCardListElements(
                              item,
                              filteredElements.filter((element) => element.decorator),
                            )}
                          </Stack>
                          {(actionMenuItems || collapsible) && (
                            <Box sx={{ ...style.cardElement, ...style.cardElementUtility }}>
                              <Stack direction="row" spacing={1}>
                                {actionMenuItems && (
                                  <IconButton
                                    id="action-menu"
                                    disabled={isLoading}
                                    onClick={(e) => {
                                      handleMenuOpen(e, item);
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                  >
                                    <MoreVertRounded htmlColor={palette.secondary.dark} />
                                  </IconButton>
                                )}
                              </Stack>
                            </Box>
                          )}
                        </Stack>
                      </>
                    ) : (
                      renderCardListElements(item, filteredElements)
                    )}
                  </Stack>
                );
              })}
            </CardContent>
          </Card>
        </>
      )}
    </Draggable>
  );

  const renderActionMenuItems = () => {
    if (actionMenuItems && menuItem) {
      return actionMenuItems(menuItem, handleMenuClose);
    }
  };

  const dataArray = Array.isArray(data) ? data : data.data;

  const onHeaderCheck = () => {
    if (selectedItems?.length === 0) {
      return setSelectedItems(dataArray);
    }
    return setSelectedItems([]);
  };

  const onRowCheck = (item: T) => () => {
    if (selectedItems?.includes(item)) {
      return setSelectedItems(selectedItems.filter((s) => s !== item));
    }
    return setSelectedItems([...(selectedItems ?? []), item]);
  };

  return (
    <>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        {isLoading ? (
          <CircularProgress size={22} sx={style.loading} />
        ) : (
          <Stack direction="row" alignItems="center">
            {bulkActions && (
              <Checkbox
                size="small"
                onChange={onHeaderCheck}
                indeterminate={selectedItems && selectedItems.length > 0 && selectedItems.length !== dataArray.length}
                checked={selectedItems && selectedItems.length > 0 && selectedItems?.length === dataArray.length}
              />
            )}
            {!hideZeroResultCount &&
              (!hideResultCount || (Array.isArray(data) ? data.length : data.totalCount) === 0) && (
                <Typography variant="h6">
                  {t('common:table.results', { count: Array.isArray(data) ? data.length : data.totalCount })}
                </Typography>
              )}
          </Stack>
        )}
        {bulkActions && (
          <BulkActions
            bulkActions={bulkActions}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            renderSelection={renderBulkSelection}
            bulkActionSuffix={bulkActionSuffix}
            disabled={isLoading}
          />
        )}
      </Stack>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => {
            const rows = Array.isArray(data) ? data : data.data;
            return (
              <Stack
                direction="column"
                spacing={2}
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  ...style.body,
                  ...(isLoading ? style.bodyLoading : {}),
                }}
              >
                {rows.map((item, index) => renderRow(item, index))}
                {provided.placeholder}
              </Stack>
            );
          }}
        </Droppable>
      </DragDropContext>
      {!Array.isArray(data) &&
        paginationFilter &&
        paginationFilter.pageNumber &&
        paginationFilter.pageSize &&
        onPaginationChange && (
          <Box width="100%" display="flex" justifyContent="end">
            <Pagination
              count={data.totalCount}
              page={paginationFilter.pageNumber - 1}
              onPageChange={(_, page) =>
                paginationFilter.pageSize && onPaginationChange(page + 1, paginationFilter.pageSize)
              }
              rowsPerPage={paginationFilter.pageSize}
              rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
              onRowsPerPageChange={(e) =>
                paginationFilter.pageNumber && onPaginationChange(paginationFilter.pageNumber, parseInt(e.target.value))
              }
            />
          </Box>
        )}
      <Menu
        id="table-menu"
        MenuListProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.MenuListProps}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        PaperProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps}
      >
        {renderActionMenuItems()}
      </Menu>
    </>
  );
};
