import axios from 'axios';
import { AuthenticationLogEntity } from '../../models';
import { AuthenticationLogFilter, ExportType, PaginationEntity } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const AuthenticationLog = {
  getAllAuthenticationLogs: async (
    authenticationLogFilter: AuthenticationLogFilter,
  ): Promise<PaginationEntity<AuthenticationLogEntity>> => {
    const { data } = await axios.post('api/authenticationLog/search', authenticationLogFilter);
    return { ...data, data: data.data.map((d: AuthenticationLogEntity) => new AuthenticationLogEntity(d)) };
  },
  exportAuthenticationLogs: async (
    authenticationLogFilter: AuthenticationLogFilter,
    exportType: ExportType,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post(`api/authenticationLog/export?type=${exportType}`, authenticationLogFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
