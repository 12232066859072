import { CheckRounded, ModeCommentRounded } from '@mui/icons-material';
import { Box, Link, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { role as roleApi, user as userApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { MemberDetailContext } from '../../contexts';
import { useApi, useAuth, useInternationalization } from '../../hooks';
import { UserEntity } from '../../models';
import { routes } from '../../routes';
import { OrderBy, PermissionKey, SelectOption, Styles, TranslationContext, UserFilter, UserType } from '../../types';
import { areArrayEquals, formatDateTime } from '../../utils/helper';
import { Container, FilterContainer } from '../Container';
import { Autocomplete, MultiSelect, Select } from '../Form';
import { Table, TableColumn } from '../Table';

const style: Styles = {
  container: {
    pt: 4,
    pb: 4,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
};

const defaultTextSearchFilter = { userFirstNameOrLastName: undefined };
const defaultAdvancedSearchFilter = {
  roleIds: undefined,
  isAllowedAccess: undefined,
};

export const MemberMemberUsers = () => {
  const { isInternalUser, hasPermissions } = useAuth();
  const { t, getTranslation, timeZoneName } = useInternationalization();
  const navigate = useNavigate();
  const { member } = useContext(MemberDetailContext);
  const { data: roles } = useApi(roleApi.getAll, null, UserType.Member);
  const [filter, setFilter] = useState<UserFilter>(DEFAULT_PAGINATION_FILTER);
  const apiFilter = useMemo<UserFilter>(
    () => ({ ...filter, memberIds: [member?.id ?? 0], isArchived: false }),
    [filter, member],
  );
  const { data, isLoading } = useApi(userApi.getAll, { skipFetch: !member }, UserType.Member, apiFilter);
  const [isAdvancedMode, setIsAdvancedMode] = useState(!!filter.roleIds || !!filter.isAllowedAccess);

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...(checked ? defaultTextSearchFilter : defaultAdvancedSearchFilter) }));
    setIsAdvancedMode(checked);
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const onBooleanChange = (event: SelectChangeEvent<unknown>, propertyName: keyof UserFilter) => {
    const value = !event.target.value ? undefined : event.target.value === 'true';
    setFilter((prev) => ({ ...prev, [propertyName]: value, pageNumber: 1 }));
  };

  const onRoleBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.roleIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, roleIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const getUserDetailPath = (userId: number) =>
    isInternalUser
      ? generatePath(routes.Admin.User.MemberUser.Detail.path, { userId })
      : generatePath(routes.Member.User.Detail.path, { userId });

  const getBooleanOptions = (context: TranslationContext = 'male'): SelectOption<string>[] => [
    { label: t('common:filter.all', { context }), value: '' },
    { label: t('common:boolean.true'), value: 'true' },
    { label: t('common:boolean.false'), value: 'false' },
  ];

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear}>
      <Box sx={style.selectSmallContainer}>
        <MultiSelect
          displayEmpty
          values={filter.roleIds ?? []}
          onConfirm={onRoleBlur}
          options={roles?.map((role) => ({ value: role.id, label: getTranslation(role, 'name') })) ?? []}
          label={t('user:search.role')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={getBooleanOptions()}
          value={filter.isAllowedAccess ?? ''}
          onChange={(e) => onBooleanChange(e, 'isAllowedAccess')}
          label={t('user:search.isAllowed')}
        />
      </Box>
    </FilterContainer>
  );

  const renderTextSearch = () => (
    <Autocomplete
      value={filter.userFirstNameOrLastName}
      sx={style.textContainer}
      noOptionsText={t('user:search.notFound')}
      apiFunction={(searchText: string, userFilter: UserFilter) =>
        userApi.getSuggestions(searchText, UserType.Member, userFilter)
      }
      apiExtraParams={[{ memberIds: [member?.id ?? 0] }]}
      label={t('user:search.title')}
      placeholder={t('user:search.placeholder')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.firstName} ${option.lastName}`)}
      onApplySearch={(searchText) =>
        setFilter((prev) => ({ ...prev, userFirstNameOrLastName: searchText ?? undefined, pageNumber: 1 }))
      }
      onSelectItem={(item) => {
        if (item) {
          if (!hasPermissions(PermissionKey.AdminManageMemberUsers)) {
            setFilter((prev) => ({ ...prev, userFirstNameOrLastName: item.fullName, pageNumber: 1 }));
          } else {
            navigate(getUserDetailPath(item.id));
          }
        }
      }}
    />
  );

  const renderUserLink = (id: number, text: string) =>
    !hasPermissions(PermissionKey.AdminManageMemberUsers) ? (
      text
    ) : (
      <Link underline="always" href={getUserDetailPath(id)}>
        {text}
      </Link>
    );

  return (
    <Container isHighlighted sx={style.container}>
      <Stack spacing={4}>
        <Typography variant="h2">{t('member:sections.users')}</Typography>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark">
            {t('user:search.title')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('user:search.filterList')}
          </Typography>
        </Box>
        {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
        <Table
          data={data ?? []}
          translationNamespace="user"
          paginationFilter={filter}
          onPaginationChange={onPaginationChange}
          sortColumns={filter.orderBy}
          onSortChange={onSortChange}
          isLoading={isLoading}
        >
          <TableColumn
            type="custom"
            sortable
            width="20%"
            id="lastName"
            render={({ id, lastName }: UserEntity) => renderUserLink(id, lastName)}
          />
          <TableColumn
            type="custom"
            sortable
            width="20%"
            id="firstName"
            render={({ id, firstName }: UserEntity) => renderUserLink(id, firstName)}
          />
          <TableColumn
            type="custom"
            sortable
            width="20%"
            id="role"
            render={({ role }: UserEntity) => getTranslation(role, 'name')}
          />
          <TableColumn
            type="custom"
            sortable
            width="15%"
            id="lastLogin"
            align="center"
            render={({ lastLogin }: UserEntity) => formatDateTime(lastLogin, timeZoneName)}
          />
          <TableColumn
            type="custom"
            sortable
            width="15%"
            id="lastVerifiedDate"
            align="center"
            render={({ lastVerifiedDate }: UserEntity) => formatDateTime(lastVerifiedDate, timeZoneName)}
          />
          <TableColumn type="icon" tooltip width="5%" id="notes" align="center" iconComponent={ModeCommentRounded} />
          <TableColumn
            type="icon"
            sortable
            width="5%"
            id="isAllowedAccess"
            iconComponent={CheckRounded}
            align="center"
          />
        </Table>
      </Stack>
    </Container>
  );
};
