import { Box, CircularProgress } from '@mui/material';
import { Styles } from '../types';

const style: Styles = {
  loading: {
    display: 'flex',
    justifyContent: 'center',
    pt: 3,
  },
};

export const Loading = () => (
  <Box sx={style.loading}>
    <CircularProgress size={30} />
  </Box>
);
