import { AppBar, Container, Stack, Toolbar } from '@mui/material';
import { PropsWithChildren, useRef } from 'react';
import {
  NavigationBranding,
  NavigationMobileSideMenu,
  NavigationSearchBar,
  NavigationSideMenu,
  NavigationUserMenu,
} from '.';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';

const style: Styles = {
  appBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  container: {
    boxShadow: `0 4px 8px ${palette.shadow}`,
    px: { xs: 1, md: 3 },
  },
  toolbar: {
    justifyContent: 'space-between',
    height: '2rem',
  },
};

export const NavigationMenu = ({ children }: PropsWithChildren) => {
  const appBarRef = useRef<HTMLDivElement | null>(null);

  return (
    <AppBar ref={appBarRef} position="fixed" sx={style.appBar}>
      <Container maxWidth={false} sx={style.container}>
        <Toolbar disableGutters role="menu" sx={style.toolbar}>
          <Stack direction="row">
            <NavigationMobileSideMenu>{children}</NavigationMobileSideMenu>
            <NavigationBranding showUserType={{ mobile: false, desktop: true }} />
          </Stack>
          <NavigationSearchBar />
          <NavigationUserMenu />
          <NavigationSideMenu anchorElRef={appBarRef}>{children}</NavigationSideMenu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
