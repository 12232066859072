import { ContentStatus, HelpTrainingType } from '../../types';
import { ContentEntity } from './ContentEntity';

export class HelpTrainingEntity extends ContentEntity {
  searchKeywords_En: string;
  searchKeywords_Fr: string;
  type: HelpTrainingType;
  isVisibleToAdmins: boolean;
  isVisibleToVendors: boolean;
  isVisibleToMembers: boolean;

  constructor(entity?: HelpTrainingEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.status = ContentStatus.InProgress;
      this.name_En = '';
      this.name_Fr = '';
      this.searchKeywords_En = '';
      this.searchKeywords_Fr = '';
      this.type = HelpTrainingType.Help;
      this.isVisibleToMembers = true;
      this.isVisibleToVendors = true;
      this.pages = [];
      this.files = [];
    }
    this.expiryDate = null;
  }
}
