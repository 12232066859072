import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { getDashboardPath, routes } from '../../routes';

export function RequireTemporaryAuth({ children }: PropsWithChildren) {
  const { userTemporaryPassword, userVerifyCode, user } = useAuth();

  if (userTemporaryPassword) {
    return <>{children}</>;
  } else if (userVerifyCode) {
    return <>{children}</>;
  } else if (user?.requiresVerificationPhoneNumber) {
    return <Navigate to={routes.SetVerificationPhoneNumber.path} replace />;
  } else if (!user) {
    return <Navigate to={routes.Login.path} replace />;
  }

  return <Navigate to={getDashboardPath(user.profile.userType)} replace />;
}
