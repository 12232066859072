/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchRounded } from '@mui/icons-material';
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  CircularProgress,
  InputAdornment,
  Autocomplete as MuiAutocomplete,
} from '@mui/material';
import { FocusEventHandler, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '..';
import { useApi, useDebounce, useEnumList } from '../../../hooks';
import { palette } from '../../../styles/palette';
import { PaginationEntity, Styles, TranslationLanguage, WithoutLanguageSuffix } from '../../../types';

const style: Styles = {
  circularProgress: {
    display: 'flex',
    marginRight: '5px',
  },
};

export interface AutocompleteProps<T, Args extends any[]> {
  apiFunction: (searchText: string, ...args: Args) => Promise<PaginationEntity<T>>;
  apiExtraParams?: Partial<Args>;
  getOptionLabel?: (option: string | T) => string;
  label?: string;
  onApplySearch?: (value: string) => void;
  onSelectItem?: (value: T | null) => void;
  placeholder?: string;
  sx?: Styles[0];
  value?: string;
  onValueChange?: (value: string) => void;
  noOptionsText?: string;
  disableOption?: (item: T) => boolean;
  name?: string;
  error?: boolean;
  helperText?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  hideLabel?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  minSearchTextLength?: number;
  groupBy?: (option: T) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode;
  languageOptionKey?: WithoutLanguageSuffix<keyof T>;
  extraOption?: ReactNode;
}

export const Autocomplete = <T, Args extends any[]>({
  apiExtraParams,
  apiFunction,
  disableOption,
  error,
  getOptionLabel,
  helperText,
  label,
  name,
  noOptionsText,
  onApplySearch,
  onSelectItem,
  placeholder,
  sx,
  value,
  onValueChange,
  onBlur,
  hideLabel,
  renderInput,
  minSearchTextLength = 3,
  groupBy,
  renderGroup,
  languageOptionKey,
  extraOption,
}: AutocompleteProps<T, Args>) => {
  const { t } = useTranslation();
  const languageOptions = useEnumList(TranslationLanguage, 'common:translationLanguage');
  const [searchText, setSearchText] = useState<string>(value || '');
  const [internalError, setInternalError] = useState<string | null>(null);

  useEffect(() => {
    setSearchText(value || '');
  }, [value]);

  const debounceSearchText = useDebounce(searchText, 1000);

  const apiParams = useMemo(() => {
    if (debounceSearchText && debounceSearchText.trim().length >= minSearchTextLength) {
      return apiExtraParams ? [debounceSearchText, ...apiExtraParams] : [debounceSearchText];
    }
    return apiExtraParams ? [undefined, ...apiExtraParams] : [undefined];
  }, [debounceSearchText, apiExtraParams, minSearchTextLength]);

  const api = useApi(apiFunction, null, ...(apiParams as Args));

  const options = (extraOption && debounceSearchText ? [{} as T] : []).concat(api.data?.data ?? []);

  const handleOnChange = (value: string | T | null) => {
    if (value === null) {
      onApplySearch && onApplySearch('');
      setInternalError(null);
      onSelectItem && onSelectItem(value);
    } else if (typeof value === 'string') {
      if (value && value.trim().length < minSearchTextLength && value.trim().length >= 1) {
        setInternalError(t('common:error.minLength', { length: minSearchTextLength }));
      } else {
        onApplySearch && onApplySearch(value);
        setInternalError(null);
      }
    } else {
      setInternalError(null);
      onSelectItem && value && onSelectItem(value);
    }
  };

  const renderOption = (option: T | string) => {
    if (getOptionLabel) {
      return getOptionLabel(option);
    } else if (languageOptionKey) {
      return typeof option === 'object' && !!Object.keys(option as object).length
        ? languageOptions
            .map(
              (l) => `${l.label} : ${(option as any)[`${languageOptionKey}_${l.value}`] || t('common:notSpecified')}`,
            )
            .join(' | ')
        : searchText;
    }

    return `${option}`;
  };

  return (
    <MuiAutocomplete
      fullWidth
      disableClearable={!searchText}
      blurOnSelect
      clearOnBlur={false}
      freeSolo
      getOptionLabel={renderOption}
      inputValue={searchText}
      noOptionsText={noOptionsText}
      getOptionDisabled={(option) => (disableOption ? disableOption(option) : false)}
      onChange={(_, v) => handleOnChange(v)}
      onInputChange={(_, value, reason) => {
        (!renderInput || reason !== 'reset') && setSearchText(value);
        onValueChange && onValueChange(value);
        if (reason === 'clear') {
          handleOnChange(null);
        } else if (reason === 'input' && !value) {
          handleOnChange(value);
        }
      }}
      groupBy={groupBy}
      renderGroup={(params) => {
        if (!renderGroup && !extraOption) {
          return undefined;
        } else if (Number(params.key) == 0 && extraOption) {
          return extraOption;
        }

        return renderGroup?.(params);
      }}
      filterOptions={(options) => options}
      options={options}
      sx={sx}
      renderInput={(params) =>
        renderInput ? (
          renderInput(params)
        ) : (
          <Input
            {...params}
            hideLabel={hideLabel}
            onBlur={onBlur}
            name={name}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchRounded htmlColor={palette.secondary.dark} />,
              endAdornment: (
                <InputAdornment position="end">
                  {api.isLoading && <CircularProgress sx={style.circularProgress} size={25} />}
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            error={!!internalError || error}
            helperText={internalError || helperText}
            label={label}
            placeholder={placeholder}
          />
        )
      }
    />
  );
};
