import { InfoOutlined } from '@mui/icons-material';
import { FormControlLabel, FormHelperText, Radio, RadioGroup, RadioGroupProps, Stack, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { palette } from '../../../styles/palette';
import { SelectOption, Styles } from '../../../types';
import { Container } from './Container';

const style: Styles = {
  labelWithDescription: {
    marginRight: '5px',
  },
  tooltipDescription: {
    marginRight: '20px',
  },
};

interface RadioSelectProps<T> extends RadioGroupProps {
  label?: string;
  options?: SelectOption<T>[];
  error?: boolean;
  helperText?: ReactNode;
  hideLabel?: boolean;
}

export const RadioSelect = <T extends string | number | boolean>({
  label,
  options,
  children,
  helperText,
  error,
  hideLabel,
  ...props
}: RadioSelectProps<T>) => (
  <Container label={label} hideLabel={hideLabel}>
    <RadioGroup {...props}>
      {options?.map((o, i) => (
        <Stack key={i.toString()} direction="row" alignItems="center">
          <FormControlLabel
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={o.value as any}
            control={<Radio />}
            label={o.label}
            disabled={o.disabled}
            sx={o.description ? style.labelWithDescription : {}}
          />
          {!!o.description && (
            <Tooltip title={o.description} sx={style.tooltipDescription} placement="top">
              <InfoOutlined htmlColor={palette.grey[500]} />
            </Tooltip>
          )}
        </Stack>
      )) ?? children}
    </RadioGroup>
    {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
  </Container>
);
