import { AddRounded, EditRounded } from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { EmailAddressList, PhoneNumberList } from '..';
import { EditDrawer, ExportButton, LimitCommaValues, Loading } from '../..';
import { categorization as categorizationApi, contact as contactApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER, MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useAuth, useDebounce, useInternationalization } from '../../../hooks';
import { InternalContactEntity } from '../../../models';
import { routes } from '../../../routes';
import { InternalContactSchema } from '../../../schemas/Contact';
import {
  BaseComponentListProps,
  ExportType,
  InternalContactFilter,
  OrderBy,
  PermissionKey,
  Styles,
} from '../../../types';
import { areArrayEquals, downloadBlob } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { Input, MultiSelect, Switch } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { InternalContactForm } from './InternalContactForm';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '240px',
    },
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
  switch: {
    marginBottom: '-4px',
    mr: -3,
  },
};

const defaultTextSearchFilter: InternalContactFilter = {
  name: '',
  ...DEFAULT_PAGINATION_FILTER,
};
const defaultAdvancedSearchFilter: InternalContactFilter = {
  officeIds: [],
  boardIds: [],
  departmentIds: [],
  ...DEFAULT_PAGINATION_FILTER,
};

export const InternalContactsList = ({
  addButtonProps,
  tableProps,
  showSearch,
  onSave,
  layout = 'Tab',
  isChangeRequest,
  hidePending,
  readOnly,
  ...props
}: Omit<BaseComponentListProps<InternalContactEntity, InternalContactFilter>, 'fetchApi' | 'filter' | 'setFilter'> &
  Partial<
    Pick<BaseComponentListProps<InternalContactEntity, InternalContactFilter>, 'fetchApi' | 'filter' | 'setFilter'>
  >) => {
  const navigate = useNavigate();
  const { isInternalUser, isMemberUser } = useAuth();
  const { t, getTranslation } = useInternationalization();

  const stateFilter = useState<InternalContactFilter>({ isArchived: false, ...defaultTextSearchFilter });
  const [filter, setFilter] = props.filter && props.setFilter ? [props.filter, props.setFilter] : stateFilter;
  const debounceTextFilter = useDebounce(filter, 750);

  const fetchApi = useApi(contactApi.getAllContacts, { callImmediately: false, skipFetch: true }, filter);
  const { data, call, isLoading } = props.fetchApi ?? fetchApi;

  useEffect(() => {
    call({
      name: debounceTextFilter.name,
      departmentIds: filter.departmentIds,
      officeIds: filter.officeIds,
      boardIds: filter.boardIds,
      orderBy: filter.orderBy,
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
      isArchived: filter.isArchived,
    });
  }, [
    call,
    debounceTextFilter.name,
    filter.departmentIds,
    filter.officeIds,
    filter.boardIds,
    filter.isArchived,
    filter.orderBy,
    filter.pageNumber,
    filter.pageSize,
  ]);

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [internalContact, setInternalContact] = useState<InternalContactEntity>(new InternalContactEntity());
  const [isAdvancedMode, setIsAdvancedMode] = useState(!!filter.officeIds || !!filter.departmentIds);
  const { data: boards, isLoading: isLoadingBoards } = useApi(categorizationApi.getAll, null, 'board', false);
  const { data: departments, isLoading: isLoadingDepartments } = useApi(
    categorizationApi.getAll,
    null,
    'department',
    false,
  );
  const { data: offices, isLoading: isLoadingOffices } = useApi(contactApi.getAllOffices, null, MAX_PAGINATION_FILTER);

  const { call: downloadExport } = useApi(contactApi.exportEmployees, null);

  const getInternalContactDetailPath = (contactId: number) =>
    generatePath(
      isInternalUser
        ? routes.Admin.InternalContactDetails.path
        : isMemberUser
        ? routes.Member.InternalContactDetails.path
        : routes.Vendor.InternalContactDetails.path,
      { contactId },
    );

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...(checked ? defaultTextSearchFilter : defaultAdvancedSearchFilter) }));
    setIsAdvancedMode(checked);
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onOfficeIdsBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.officeIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, officeIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const onBoardIdsBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.boardIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, boardIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const onDepartmentIdsBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.departmentIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, departmentIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const exportEmployees = async (exportType: ExportType = ExportType.CSV) => {
    const fileData = await downloadExport(filter, exportType);
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.csv', fileData.data);
    }
  };

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear}>
      <Box sx={style.selectSmallContainer}>
        <MultiSelect
          label={t('contact:internalContact.search.officeIds')}
          options={offices?.data.map((d) => ({ label: getTranslation(d, 'description'), value: d.id })) ?? []}
          onConfirm={onOfficeIdsBlur}
          values={filter.officeIds || []}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <MultiSelect
          label={t('contact:internalContact.search.boardIds')}
          options={boards?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
          onConfirm={onBoardIdsBlur}
          values={filter.boardIds || []}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <MultiSelect
          label={t('contact:internalContact.search.departmentIds')}
          options={departments?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
          onConfirm={onDepartmentIdsBlur}
          values={filter.departmentIds || []}
        />
      </Box>
    </FilterContainer>
  );

  const renderTextSearch = () => (
    <Input
      sx={style.textContainer}
      value={filter.name ?? ''}
      label={t('contact:internalContact.search.name')}
      onChange={(event) => setFilter((prev) => ({ ...prev, name: event.target.value ?? undefined, pageNumber: 1 }))}
      placeholder={t('contact:internalContact.search.placeholder')}
    />
  );

  const renderSearch = () =>
    isLoadingBoards || isLoadingDepartments || isLoadingOffices ? (
      <Loading />
    ) : (
      <Stack spacing={3} mb={!isInternalUser ? 2 : 0}>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark">
            {t('contact:internalContact.search.title')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('contact:internalContact.search.filterList')}
          </Typography>
        </Box>
        <Stack>
          {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
          {isInternalUser && <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />}
        </Stack>
      </Stack>
    );

  const renderBaseActionsMenuItems = (entity: InternalContactEntity, onClick: () => void) =>
    [
      <MenuItem id="view" key="view" onClick={() => navigate(getInternalContactDetailPath(entity.id))}>
        {t('common:view')}
      </MenuItem>,
    ].concat(tableProps?.actionMenuItems ? tableProps?.actionMenuItems(entity, onClick) : []);

  const renderName = (entity: InternalContactEntity) => (
    <Link underline="always" href={getInternalContactDetailPath(entity.id)}>
      {entity.fullName}
    </Link>
  );

  const renderRole = (entity: InternalContactEntity) => getTranslation(entity, 'role');

  const renderOffice = (entity: InternalContactEntity) =>
    entity.office ? getTranslation(entity.office, 'description') : '';

  const renderBoards = (entity: InternalContactEntity) => (
    <LimitCommaValues value={entity.boards.map((d) => getTranslation(d, 'name')).join(', ')} maxLength={50} />
  );

  const renderDepartments = (entity: InternalContactEntity) => (
    <LimitCommaValues value={entity.departments.map((d) => getTranslation(d, 'name')).join(', ')} maxLength={50} />
  );

  const renderPhoneNumbers = (entity: InternalContactEntity) => <PhoneNumberList data={entity.phoneNumbers} />;

  const renderEmailAddresses = (entity: InternalContactEntity) => <EmailAddressList data={entity.emailAddresses} />;

  const renderTable = () =>
    data && (
      <Table
        {...tableProps}
        data={data}
        translationNamespace="contact:internalContact"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={renderBaseActionsMenuItems}
        hidePending={hidePending}
      >
        <TableColumn type="custom" width="20%" id="name" sortable render={renderName} />
        <TableColumn type="custom" width="10%" id="role" sortable render={renderRole} />
        <TableColumn type="custom" width="10%" id="office" sortable render={renderOffice} />
        <TableColumn type="custom" width="15%" id="boards" render={renderBoards} />
        <TableColumn type="custom" width="15%" id="departments" render={renderDepartments} />
        <TableColumn type="custom" width="15%" id="phoneNumbers" render={renderPhoneNumbers} />
        <TableColumn type="custom" width="15%" id="emailAddresses" render={renderEmailAddresses} />
      </Table>
    );

  const renderPageActions = () => (
    <Stack direction="row" spacing={1}>
      {!readOnly && (
        <HasPermissions keys={PermissionKey.AdminManageContacts}>
          <Button
            variant="contained"
            startIcon={<AddRounded />}
            onClick={() => {
              setInternalContact(new InternalContactEntity());
              setIsAddOpen(true);
            }}
            {...addButtonProps}
          >
            {t('contact:internalContact.actions.add')}
          </Button>
        </HasPermissions>
      )}
      <ExportButton displayMenu defaultExportType={ExportType.CSV} onExportClick={exportEmployees} />
    </Stack>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {internalContact.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(internalContact.id ? 'contact:internalContact.actions.edit' : 'contact:internalContact.actions.add')}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={internalContact}
      schema={InternalContactSchema()}
      onSave={async (entity) => {
        const createdEntity = await onSave(entity);
        await call(filter);
        return createdEntity;
      }}
      onConfirm={() => {
        call(filter);
        setIsAddOpen(false);
      }}
      onCancel={() => setIsAddOpen(false)}
      isChangeRequest={isChangeRequest}
    >
      <InternalContactForm />
    </EditDrawer>
  );

  return (
    <LayoutPage title={t('contact:internalContact.title')} rightTitle={renderPageActions()} display={layout}>
      {showSearch && renderSearch()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
