import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { vendor as vendorApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { VendorDetailContext } from '../../../contexts';
import { useApi } from '../../../hooks';
import { VendorBuyingAgreementDocumentEntity, VendorBuyingAgreementEntity } from '../../../models';
import { routes } from '../../../routes';
import { FilterPeriod, PermissionKey, VendorBuyingAgreementFilter } from '../../../types';
import { ArchiveMenuItem, MenuItem } from '../../Menu';
import { VendorBuyingAgreementsList } from './VendorBuyingAgreementsList';

const defaultFilter = {
  period: FilterPeriod.All,
  isArchived: false,
};

export const VendorBuyingAgreementsListAdmin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { vendor, vendorId } = useContext(VendorDetailContext);
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState<VendorBuyingAgreementFilter>({ ...MAX_PAGINATION_FILTER, ...defaultFilter });
  const getAllBuyingAgreements = useApi(vendorApi.getAllVendorBuyingAgreements, null, vendorId, filter);
  const { call: create } = useApi(vendorApi.createBuyingAgreement, null);
  const { call: upload } = useApi(vendorApi.addBuyingAgreementFiles, { successKey: 'common:success.save' });
  const archiveApi = useApi(vendorApi.archiveBuyingAgreement, { successKey: 'common:success.action' });
  const { call: duplicate } = useApi(vendorApi.duplicateBuyingAgreement, { successKey: 'common:success.action' });

  const onSave = async (entity: VendorBuyingAgreementEntity) => {
    entity.isPendingCreation = !entity.id && (entity.rawFiles?.length ?? 0) > 0;
    const savedBuyingAgreement = await (entity.isDuplicate
      ? duplicate(entity.vendorId, entity.id, entity)
      : create(vendorId, entity));

    if (savedBuyingAgreement && entity.rawFiles?.length) {
      savedBuyingAgreement.files = entity.rawFiles.map((f) => VendorBuyingAgreementDocumentEntity.createFromFile(f));
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
      await upload(vendorId, savedBuyingAgreement?.id || 0, savedBuyingAgreement.files);
    }
    return savedBuyingAgreement;
  };

  const onArchiveConfirm = async (isArchived: boolean, entity: VendorBuyingAgreementEntity | null) => {
    if (entity) {
      await archiveApi.call(vendorId, [entity.id], isArchived);
      getAllBuyingAgreements.refresh();
    }
  };

  const renderActionsMenuItems = (entity: VendorBuyingAgreementEntity, onClick: () => void) => [
    <ArchiveMenuItem
      permissions={{ keys: PermissionKey.VendorEditVBAs }}
      key="archive"
      entity={entity}
      name={entity.name}
      onClick={onClick}
      onArchiveConfirm={onArchiveConfirm}
      actionSuffix={t('vendor:buyingAgreements.actions.suffix')}
      disabled={!vendor || vendor.isArchived}
    />,
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(
          generatePath(routes.Admin.Vendor.BuyingAgreement.path, {
            vendorId: vendorId,
            vendorBuyingAgreementId: entity.id,
          }),
        );
      }}
    >
      {t('common:view')}
    </MenuItem>,
  ];

  const getRedirectPath = (entity: VendorBuyingAgreementEntity) =>
    generatePath(routes.Admin.Vendor.BuyingAgreement.path, {
      vendorId: vendorId,
      vendorBuyingAgreementId: entity.id,
    });

  return (
    <VendorBuyingAgreementsList
      layout="Tab"
      showSearch
      addButtonProps={{
        disabled: !vendor || vendor.isArchived,
      }}
      filter={filter}
      setFilter={setFilter}
      fetchApi={getAllBuyingAgreements}
      onSave={onSave}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
      }}
      showAddButton={true}
      redirectPath={getRedirectPath}
    />
  );
};
