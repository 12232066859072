import { AddRounded, DownloadRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet, useSearchParams } from 'react-router-dom';
import { MemberForm, MemberListSearch, MemberListView } from '.';
import { member as memberApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { MemberContext } from '../../contexts';
import { useApi } from '../../hooks';
import { MemberEntity } from '../../models';
import { routes } from '../../routes';
import { MemberSchema } from '../../schemas';
import { MemberFilter, PermissionKey, Styles } from '../../types';
import { EditDrawer } from '../EditDrawer';
import { LayoutPage } from '../Layout';
import { HasPermissions } from '../Permission';
import { LinkTab } from '../Tab';
import { downloadBlob } from '../../utils/helper';

const styles: Styles = {
  subTitleWrapperContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    alignItems: { xs: 'start', md: 'center' },
    pb: 4,
  },
};

export const MemberLayout = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<MemberFilter>({
    isArchived: false,
    mailSyncErrorOnly: !!searchParams.get('mailSyncErrorOnly'),
    ...DEFAULT_PAGINATION_FILTER,
  });
  const { refresh, isLoading, data, setData } = useApi(memberApi.getAll, { skipFetch: !!outlet }, filter);
  const { call: download } = useApi(memberApi.exportAllMembersContacts, null);
  const { call } = useApi(memberApi.create, { successKey: 'common:success.save' });
  const [member, setMember] = useState(new MemberEntity());

  useEffect(() => {
    setMember(new MemberEntity());
  }, [isAddOpen]);

  useEffect(() => {
    if (!filter.mailSyncErrorOnly) {
      setSearchParams(undefined);
    }
  }, [filter.mailSyncErrorOnly, setSearchParams]);

  if (outlet) {
    return outlet;
  }

  const onDownload = async () => {
    const fileData = await download();
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.csv', fileData.data);
    }
  };

  const tabs = [
    <LinkTab label={t('navigation:sections.members')} value={routes.Admin.Member.path} />,
    <LinkTab
      label={t('navigation:sections.memberGroups')}
      value={routes.Admin.MemberGroups.path}
      permissions={{
        keys: [
          PermissionKey.AdminManageDCGroups,
          PermissionKey.AdminManageGeneralGroups,
          PermissionKey.AdminManageOwnerGroups,
        ],
        any: true,
      }}
    />,
  ];

  return (
    <MemberContext.Provider value={{ data, setData, isLoading, fetchData: refresh, filter, setFilter }}>
      <LayoutPage title={t('member:title')} tabs={tabs}>
        <>
          <Box sx={styles.subTitleWrapperContainer}>
            <Typography display="inline" variant="h2">
              {t('navigation:sections.members')}
            </Typography>
            <Box
              width={{ xs: '100%', md: 'auto' }}
              alignItems={{ xs: 'end', md: 'unset' }}
              display={{ xs: 'flex', md: 'unset' }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
                <HasPermissions keys={PermissionKey.MemberEditMembers}>
                  <Button startIcon={<AddRounded />} onClick={() => setIsAddOpen(true)} variant="contained">
                    {t('member:add')}
                  </Button>
                </HasPermissions>
                <HasPermissions keys={PermissionKey.MemberViewContacts}>
                  <Button startIcon={<DownloadRounded />} onClick={onDownload} variant="outlined">
                    {t('member:export')}
                  </Button>
                </HasPermissions>
              </Stack>
            </Box>
          </Box>
          <MemberListSearch />
          <MemberListView />
          <EditDrawer
            title={
              <Stack direction="row" spacing={1} alignItems="center">
                <AddRounded />
                <Typography variant="drawerTitle">{t(`member:add`)}</Typography>
              </Stack>
            }
            open={isAddOpen}
            entity={member}
            schema={MemberSchema()}
            redirectPath={(entity) => generatePath(routes.Admin.Member.Detail.path, { memberId: entity.id })}
            redirectLabel={t('member:saveOpenMember')}
            onSave={call}
            onConfirm={() => {
              refresh();
              setIsAddOpen(false);
            }}
            onCancel={() => setIsAddOpen(false)}
          >
            <MemberForm />
          </EditDrawer>
        </>
      </LayoutPage>
    </MemberContext.Provider>
  );
};
