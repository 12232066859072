import {
  ArrowUpwardRounded,
  NewspaperRounded,
  PriorityHighRounded,
  StarRounded,
  StoreMallDirectoryRounded,
} from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { routes } from '../../../routes';
import { palette } from '../../../styles/palette';
import { ContentFilter, PublicationFilter, Styles } from '../../../types';
import { formatLongDate } from '../../../utils/helper';
import { WidgetProps } from './Widget';

const styles: Styles = {
  container: {
    background: palette.white,
    borderRadius: 4,
    padding: 2,
    paddingTop: 3,
    overflowY: 'auto',
    maxHeight: '454px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  },
  vendorName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  viewAll: {
    mt: 2,
  },
  newsItem: {
    cursor: 'pointer',
    padding: '20px 4px 16px 8px',
    borderBottom: `1px solid ${palette.grey[300]}`,
    ':hover': {
      background: palette.grey[50],
    },
  },
};

interface NewsProps extends WidgetProps {
  isMandatoryOrFavouriteVendor?: boolean;
  isMandatoryOrFavouriteVendorOrNoVendor?: boolean;
}

const defaultFilter: PublicationFilter = {
  filter: ContentFilter.Active,
  ...DEFAULT_PAGINATION_FILTER,
  pageSize: 20,
};

export const News = ({ isMandatoryOrFavouriteVendor, isMandatoryOrFavouriteVendorOrNoVendor }: NewsProps) => {
  const { t, getTranslation } = useInternationalization();
  const navigate = useNavigate();
  const [filter] = useState({
    ...defaultFilter,
    isMandatoryOrFavouriteVendor,
    isMandatoryOrFavouriteVendorOrNoVendor,
  });
  const { data, isLoading } = useApi(cmsApi.getAllPublications, null, filter);

  return (
    <Box sx={styles.container}>
      <Typography mb={2} variant="h2">
        {t('dashboard:news.title')}
      </Typography>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box sx={styles.newsList}>
          {!isLoading && !data?.totalCount && (
            <Box display="flex" justifyItems="center" alignItems="center" flexDirection="column">
              <NewspaperRounded htmlColor={palette.grey[400]} fontSize="large" />
              <Typography mb={2}>{t('dashboard:news.noNews')}</Typography>
            </Box>
          )}
          {isLoading && <CircularProgress />}
          {data?.data.map((item) => (
            <Box
              key={item.id}
              sx={styles.newsItem}
              onClick={() => navigate(generatePath(routes.Member.PublicationDetails.path, { publicationId: item.id }))}
            >
              <Stack direction="row" flexWrap="wrap" spacing={0.5} alignItems="center">
                <Typography variant="label">{formatLongDate(item.effectiveDate)}</Typography>
                {item.urgency === 'Red' && (
                  <PriorityHighRounded htmlColor={palette.PublicationUrgency.Red} fontSize="small" />
                )}
                {item.urgency === 'Yellow' && (
                  <ArrowUpwardRounded htmlColor={palette.PublicationUrgency.Yellow} fontSize="small" />
                )}
                {item.vendor && (
                  <Stack direction="row" spacing={0.25}>
                    {item.vendor.isFavourite ? (
                      <StarRounded htmlColor={palette.primary.main} fontSize="small" />
                    ) : (
                      <StoreMallDirectoryRounded htmlColor={palette.grey[500]} fontSize="small" />
                    )}
                    <Typography
                      sx={styles.vendorName}
                      variant="label"
                      color={item.vendor.isFavourite ? palette.primary.main : undefined}
                    >
                      {item.vendor.name}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Box mt={1}>
                <Typography fontWeight={600} sx={styles.newsName}>
                  {getTranslation(item, 'name')}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            sx={styles.viewAll}
            size="small"
            variant="text"
            onClick={() => navigate(generatePath(routes.Member.Cms.Publications.path))}
          >
            {t('dashboard:news.viewAll')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
