import { AddRounded } from '@mui/icons-material';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { EditDrawer } from '../..';
import { vendor as vendorApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { VendorDetailContext } from '../../../contexts/Vendor/VendorDetailContext';
import { useApi, useEnumList, useGlobalEdit, useInternationalization } from '../../../hooks';
import { VendorBuyOpportunityEntity } from '../../../models/Vendor/VendorBuyOpportunityEntity';
import { routes } from '../../../routes';
import { VendorBuyOpportunitySchema } from '../../../schemas';
import { FilterPeriod, OpportunityType, PermissionKey, Styles, VendorBuyOpportunityFilter } from '../../../types';
import { FilterContainer } from '../../Container';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { PeriodFilterSelect } from '../../Filter/PeriodFilterSelect';
import { Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { VendorBuyOpportunitiesCardList } from './VendorBuyOpportunitiesCardList';
import { VendorBuyOpportunityForm } from './VendorBuyOpportunityForm';
import { VendorBuyOpportunityHeader } from './VendorBuyOpportunityHeader';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
};
const defaultFilter = {
  period: FilterPeriod.All,
  opportunity: OpportunityType.All,
  isArchived: false,
};

export const VendorBuyOpportunities = () => {
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const navigate = useNavigate();
  const { vendor, vendorId } = useContext(VendorDetailContext);
  const [filter, setFilter] = useState<VendorBuyOpportunityFilter>({ ...MAX_PAGINATION_FILTER, ...defaultFilter });
  const { data, isLoading, refresh } = useApi(vendorApi.getAllBuyOpportunities, null, vendorId, filter);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const vendorBuyOpportunity = useMemo(() => new VendorBuyOpportunityEntity(), []);
  const { call: create } = useApi(vendorApi.createBuyOpportunity, null);
  const archiveApi = useApi(vendorApi.archiveBuyOpportunity, { successKey: 'common:success.action' });
  const opportunityOptions = useEnumList(OpportunityType, 'vendor:buyOpportunities.opportunity');

  const clearFilter = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const saveDrawer = async (entity: VendorBuyOpportunityEntity) => {
    const result = await create(vendorId, entity);
    refresh();
    return result;
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const onArchiveConfirm = async (isArchived: boolean, entity: VendorBuyOpportunityEntity | null) => {
    if (entity) {
      await archiveApi.call(vendorId, [entity.id], isArchived);
      refresh();
    }
  };

  const renderActionsMenuItems = (entity: VendorBuyOpportunityEntity, onClick: () => void) => [
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={getTranslation(entity, 'name')}
      onClick={onClick}
      onArchiveConfirm={onArchiveConfirm}
      actionSuffix={t('vendor:buyOpportunities.actions.suffix')}
      disabled={!vendor || vendor.isArchived}
      permissions={{ keys: PermissionKey.VendorEditBuys }}
    />,
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(
          generatePath(routes.Admin.Vendor.BuyOpportunity.path, {
            vendorId: vendorId,
            vendorBuyOpportunityId: entity.id,
          }),
        );
      }}
    >
      {t('common:view')}
    </MenuItem>,
  ];

  const renderList = () => (
    <VendorBuyOpportunitiesCardList
      data={data ?? []}
      isLoading={isLoading}
      translationNamespace="vendor"
      hideResultCount
      actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
      href={(entity) =>
        generatePath(routes.Admin.Vendor.BuyOpportunity.path, {
          vendorId: vendorId,
          vendorBuyOpportunityId: entity.id,
        })
      }
    />
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('vendor:buyOpportunities.actions.add')}</Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={vendorBuyOpportunity}
      schema={VendorBuyOpportunitySchema()}
      redirectPath={(entity) =>
        generatePath(routes.Admin.Vendor.BuyOpportunity.path, {
          vendorId: vendorId,
          vendorBuyOpportunityId: entity.id,
        })
      }
      redirectLabel={t('vendor:buyOpportunities.actions.saveOpen')}
      onSave={saveDrawer}
      onConfirm={confirmDrawer}
      onCancel={cancelDrawer}
    >
      <VendorBuyOpportunityHeader />
      <VendorBuyOpportunityForm />
    </EditDrawer>
  );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.VendorEditBuys}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => setIsAddOpen(true)}
        disabled={!vendor || vendor.isArchived || globalEditing}
      >
        {t('vendor:buyOpportunities.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderSearch = () => (
    <Stack>
      <FilterContainer onClear={clearFilter}>
        <PeriodFilterSelect filter={filter} setFilter={setFilter} />
        <Box sx={style.selectSmallContainer}>
          <Select
            displayEmpty
            value={filter.opportunity}
            options={opportunityOptions}
            onChange={(event) => {
              setFilter((prev) => ({ ...prev, opportunity: event.target.value as OpportunityType }));
            }}
            label={t('vendor:buyOpportunities.filter.opportunity')}
          />
        </Box>
      </FilterContainer>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="female" />
    </Stack>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewBuys }}
      title={t('vendor:buyOpportunities.title')}
      display="Tab"
      rightTitle={renderAddButton()}
    >
      {renderSearch()}
      {renderList()}
      {renderDrawer()}
    </LayoutPage>
  );
};
