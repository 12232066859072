import { Typography } from '@mui/material';
import { useAuth, useInternationalization } from '../../hooks';
import { Styles } from '../../types';
import { TargetedMembershipCategorizationForm } from './TargetedMembershipCategorizationForm';
import { TargetedMembershipMemberForm } from './TargetedMembershipMemberForm';
import { TargetedMembershipMemberGroupForm } from './TargetedMembershipMemberGroupForm';

const style: Styles = {
  header: {
    py: 3,
  },
};

export const TargetedMembershipForm = () => {
  const { t } = useInternationalization();
  const { isInternalUser } = useAuth();

  return (
    <>
      <Typography variant="h3" sx={style.header}>
        {t('vendor:targetedMembership.title')}
      </Typography>
      <TargetedMembershipCategorizationForm />
      {isInternalUser && (
        <>
          <TargetedMembershipMemberGroupForm />
          <TargetedMembershipMemberForm />
        </>
      )}
    </>
  );
};
