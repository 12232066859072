import { RebateValueType } from "../../types";

export class VendorRebateCategoryValueEntity {
  id: number;
  value_Fr: string;
  value_En: string;
  description_Fr: string;
  description_En: string;
  isCustom: boolean;
  rebateValueTypes?: RebateValueType[];

  constructor(entity?: VendorRebateCategoryValueEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.value_Fr = '';
      this.value_En = '';
      this.description_Fr = '';
      this.description_En = '';
      this.isCustom = false;
    }
  }
}
