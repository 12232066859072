import axios from 'axios';
import { ChangeLogEntity, VendorBuyingAgreementDocumentEntity, VendorBuyingAgreementEntity } from '../../../models';
import {
  ChangeLogFilter,
  PaginationEntity,
  PaginationFilter,
  VendorBuyingAgreementFilter,
  VendorBuyingAgreementTriggers,
} from '../../../types';
import { getContentDispositionFileName } from '../../../utils/file';

export const buyingAgreement = {
  getAllBuyingAgreements: async (
    buyingAgreementFilter: VendorBuyingAgreementFilter,
  ): Promise<PaginationEntity<VendorBuyingAgreementEntity>> => {
    const { data } = await axios.post(`api/vendorbuyingagreements/search`, buyingAgreementFilter);
    return { ...data, data: data.data.map((v: VendorBuyingAgreementEntity) => new VendorBuyingAgreementEntity(v)) };
  },
  getAllVendorBuyingAgreements: async (
    vendorId: number,
    buyingAgreementFilter: VendorBuyingAgreementFilter,
  ): Promise<PaginationEntity<VendorBuyingAgreementEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/buyingagreements/search`, buyingAgreementFilter);
    return { ...data, data: data.data.map((v: VendorBuyingAgreementEntity) => new VendorBuyingAgreementEntity(v)) };
  },
  createBuyingAgreement: async (
    vendorId: number,
    entity: VendorBuyingAgreementEntity,
  ): Promise<VendorBuyingAgreementEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/buyingagreements`, entity);
    return new VendorBuyingAgreementEntity(data);
  },
  getBuyingAgreement: async (vendorId: number, buyingAgreementId: number): Promise<VendorBuyingAgreementEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}`);
    return new VendorBuyingAgreementEntity(data);
  },
  archiveBuyingAgreement: async (
    vendorId: number,
    ids: number[],
    isArchived: boolean,
  ): Promise<VendorBuyingAgreementEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/buyingagreements/archive`, { isArchived, ids });
    return data.map((v: VendorBuyingAgreementEntity) => new VendorBuyingAgreementEntity(v));
  },
  updateBuyingAgreement: async (
    vendorId: number,
    entity: VendorBuyingAgreementEntity,
  ): Promise<VendorBuyingAgreementEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/buyingagreements/${entity.id}`, entity);
    return new VendorBuyingAgreementEntity(data);
  },
  deleteBuyingAgreementFiles: async (vendorId: number, buyingAgreementId: number, ids: number[]): Promise<void> => {
    const fileIds = ids.map((id) => `fileIds=${id}`).join('&');
    await axios.delete(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/files?${fileIds}`);
  },
  addBuyingAgreementFiles: async (
    vendorId: number,
    vendorBuyingAgreementId: number,
    files: VendorBuyingAgreementDocumentEntity[],
  ): Promise<VendorBuyingAgreementEntity> => {
    const formData = VendorBuyingAgreementDocumentEntity.toFormData(files, 'files');
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${vendorBuyingAgreementId}/files`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    return new VendorBuyingAgreementEntity(data);
  },

  updateBuyingAgreementFile: async (
    vendorId: number,
    vendorBuyingAgreementId: number,
    file: VendorBuyingAgreementDocumentEntity,
  ): Promise<VendorBuyingAgreementDocumentEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${vendorBuyingAgreementId}/files/${file.id}`,
      file,
    );
    return new VendorBuyingAgreementDocumentEntity(data);
  },
  getBuyingAgreementFile: async (vendorId: number, buyingAgreementId: number, fileId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/files/${fileId}`, {
      responseType: 'blob',
    });
    return data;
  },
  getCurrentBuyingAgreements: async (
    buyingAgreementFilter: VendorBuyingAgreementFilter,
  ): Promise<PaginationEntity<VendorBuyingAgreementEntity>> => {
    const { data } = await axios.post(`api/vendor/buyingagreements/search`, buyingAgreementFilter);
    return { ...data, data: data.data.map((v: VendorBuyingAgreementEntity) => new VendorBuyingAgreementEntity(v)) };
  },
  updateWorkflowBuyingAgreement: async (
    vendorId: number,
    buyingAgreementId: number,
    trigger: VendorBuyingAgreementTriggers,
  ): Promise<VendorBuyingAgreementEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/${trigger}`);
    return new VendorBuyingAgreementEntity(data);
  },
  submitBuyingAgreement: async (buyingAgreementId: number): Promise<VendorBuyingAgreementEntity> => {
    const { data } = await axios.post(`api/vendor/buyingagreements/${buyingAgreementId}/submit`);
    return new VendorBuyingAgreementEntity(data);
  },
  getAllBuyingAgreementFiles: async (
    vendorId: number,
    buyingAgreementId: number,
    paginationFilter: PaginationFilter,
  ): Promise<PaginationEntity<VendorBuyingAgreementDocumentEntity>> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/files/search`,
      paginationFilter,
    );
    return {
      ...data,
      data: data.data.map((v: VendorBuyingAgreementDocumentEntity) => new VendorBuyingAgreementDocumentEntity(v)),
    };
  },
  getAllBuyingAgreementChangeLogs: async (
    vendorId: number,
    buyingAgreementId: number,
    filter: ChangeLogFilter,
  ): Promise<PaginationEntity<ChangeLogEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/changeLog/search`, {
      ...filter,
    });
    return { ...data, data: data.data.map((v: ChangeLogEntity) => new ChangeLogEntity(v)) };
  },
  addBuyingAgreementComment: async (
    vendorId: number,
    buyingAgreementId: number,
    description: string,
  ): Promise<ChangeLogEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/comment`, {
      description,
    });
    return new ChangeLogEntity(data);
  },
  transferBuyingAgreement: async (vendorId: number, buyingAgreementId: number): Promise<void> => {
    await axios.post(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/transfer`);
  },
  exportBuyingAgreement: async (
    vendorId: number,
    buyingAgreementId: number,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/report`, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  duplicateBuyingAgreement: async (
    vendorId: number,
    buyingAgreementId: number,
    entity: VendorBuyingAgreementEntity,
  ): Promise<VendorBuyingAgreementEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/duplicate`, entity);
    return new VendorBuyingAgreementEntity(data);
  },
};
