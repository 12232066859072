import { Chip, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useEnumList, useInternationalization } from '../../../hooks';
import { ChangeLogEntity } from '../../../models';
import { ChangeLogFilter, ChangeLogType, PaginationEntity, Styles } from '../../../types';
import { ChangeLogs } from '../../ChangeLog';
import { CmsChangeLogItem } from './CmsChangeLogItem';

const style: Styles = {
  filterContainer: {
    px: 2.5,
    py: 1,
    overflowX: 'auto',
  },
};

const defaultFilter: ChangeLogFilter = {
  changeLogType: ChangeLogType.All,
  ...MAX_PAGINATION_FILTER,
};

interface CmsChangeLogsProps {
  cmsEntityId: number;
  title: string;
  fetchApi: (cmsEntityId: number, filter: ChangeLogFilter) => Promise<PaginationEntity<ChangeLogEntity>>;
  addCommentApi: (cmsEntityId: number, description: string) => Promise<ChangeLogEntity>;
}

export const CmsChangeLogs = ({ cmsEntityId, addCommentApi, fetchApi, title }: CmsChangeLogsProps) => {
  const { t } = useInternationalization();
  const [filter, setFilter] = useState(defaultFilter);
  const changeLogTypeOptions = useEnumList(ChangeLogType, 'changeLog:type', false, undefined, false);
  const { data, isLoading, refresh } = useApi(
    fetchApi,
    { callImmediately: false, skipFetch: true },
    cmsEntityId,
    filter,
  );

  const { call: addComment } = useApi(addCommentApi, { successKey: 'common:success.action' });

  const onTypeSelect = (value: ChangeLogType) => () => setFilter((prev) => ({ ...prev, changeLogType: value }));

  const onCommentAdded = async (text: string) => {
    await addComment(cmsEntityId, text);
    refresh();
  };

  const renderFilterSection = () => (
    <Stack sx={style.filterContainer} direction="row" spacing={2} alignItems="center">
      <Typography align="center" variant="label" color="grey.600">
        {t('common:show')}
      </Typography>
      <Stack spacing={0.7} direction="row">
        {changeLogTypeOptions.map((option) => (
          <Chip
            key={option.value}
            label={option.label}
            onClick={onTypeSelect(option.value)}
            color={option.value === filter.changeLogType ? 'primary' : 'secondary'}
          />
        ))}
      </Stack>
    </Stack>
  );

  return (
    <ChangeLogs
      title={title}
      data={data}
      isLoading={isLoading}
      refresh={refresh}
      renderFilter={renderFilterSection}
      renderItem={(item) => <CmsChangeLogItem key={item.id} item={item} />}
      onCommentAdded={onCommentAdded}
    />
  );
};
