import React from 'react';
import { VendorBuyOpportunityEntity, VendorEntity } from '../../models';

interface VendorBuyOpportunityDetailContextType {
  fetchData: () => Promise<void>;
  isLoading: boolean;
  vendorId: number;
  vendor: VendorEntity | null;
  vendorBuyOpportunity: VendorBuyOpportunityEntity | null;
  vendorBuyOpportunityId: number;
  save: (entity: VendorBuyOpportunityEntity) => Promise<VendorBuyOpportunityEntity | null>;
  readOnly: boolean;
}

export const VendorBuyOpportunityDetailContext = React.createContext<VendorBuyOpportunityDetailContextType>(
  {} as VendorBuyOpportunityDetailContextType,
);
