import { Box } from '@mui/material';
import { EmailAddressEntity } from '../../models';
import { StaticField } from '../Form';
import { useInternationalization } from '../../hooks';

interface EmailAddressListProps {
  data: EmailAddressEntity[];
}

export const EmailAddressList = ({ data }: EmailAddressListProps) => {
  const { getTranslation } = useInternationalization();
  return (
    <>
      {data.map((emailAddress) => (
        <Box component="div" key={emailAddress.id}>
          {getTranslation(emailAddress, 'description') ? getTranslation(emailAddress, 'description') + ': ' : ''}
          <StaticField value={`${emailAddress.email}`} linkType="email" variant="body2" />
        </Box>
      ))}
    </>
  );
};
