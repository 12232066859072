import { object, string } from 'yup';
import { MemberGroupEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema } from '../../types';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';

export const MemberGroupSchema = (): EntityFormSchema<MemberGroupEntity> => ({
  schema: object({
    name: string().required().max(MAX_SHORT_TEXT_LENGTH),
  }),
  errorCodeMap: {
    [ApiErrorCodes.MEMBER_GROUP_NAME_NOT_UNIQUE]: { key: 'name', message: 'common:error.fieldUnique' },
  },
});
