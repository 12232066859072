import { CircularProgress } from '@mui/material';
import { generatePath, Navigate, useOutlet, useParams } from 'react-router-dom';
import { EditDetails } from '../../..';
import { vendor as vendorApi } from '../../../../api';
import { VendorBuyingAgreementRebateCategoryDetailContext } from '../../../../contexts/Vendor';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../../hooks';
import { VendorBuyingAgreementRebateCategoryEntity } from '../../../../models';
import { routes } from '../../../../routes';
import { VendorBuyingAgreementRebateCategorySchema } from '../../../../schemas';
import { PermissionKey, Styles } from '../../../../types';
import { Container } from '../../../Container';
import { RequirePermission } from '../../../Permission';
import { LinkTab } from '../../../Tab';
import { VendorRebateCategoryForm } from '../../RebateCategories/VendorRebateCategoryForm';
import { VendorRebateCategoryHeader } from '../../RebateCategories/VendorRebateCategoryHeader';
import { LayoutPage } from '../../../Layout';
import { useState } from 'react';
import { DuplicateMenuItem } from '../../../Menu';
import { VendorRebateCategoryDuplicateDrawer } from '../../RebateCategories';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const VendorBuyingAgreementRebateCategoryDetail = () => {
  const { t, getTranslation } = useInternationalization();
  const outlet = useOutlet();
  const params = useParams();
  const { isInternalUser, user, hasPermissions } = useAuth();
  const [duplicateOpen, setDuplicateOpen] = useState<VendorBuyingAgreementRebateCategoryEntity | null>(null);
  const vendorId = Number(user?.profile.vendor?.id ?? params.vendorId);
  const { data: vendor } = useApi(vendorApi.get, { skipFetch: !isInternalUser }, vendorId);
  const vendorBuyingAgreementId = Number(params.vendorBuyingAgreementId);
  const vendorRebateCategoryId = Number(params.vendorRebateCategoryId);
  const { data: buyingAgreement } = useApi(vendorApi.getBuyingAgreement, null, vendorId, vendorBuyingAgreementId);
  const { data, isLoading, refresh } = useApi(
    vendorApi.getBuyingAgreementRebateCategory,
    null,
    vendorId,
    vendorBuyingAgreementId,
    vendorRebateCategoryId,
  );
  usePageTitle(data ? getTranslation(data, 'name') : '');

  const { call: update } = useApi(vendorApi.updateBuyingAgreementRebateCategory, {
    successKey: 'common:success.save',
  });

  const currentPath = isInternalUser
    ? routes.Admin.Vendor.BuyingAgreementRebateCategory
    : routes.Vendor.BuyingAgreementRebateCategory;

  const readOnly =
    !buyingAgreement?.isChildEditable(user?.profile.userType) || !hasPermissions(PermissionKey.VendorEditVBAs);

  const renderActionMenuItems = (onClick: () => void) =>
    data && !readOnly
      ? [
          <DuplicateMenuItem
            key="duplicate"
            permissions={{ keys: PermissionKey.VendorEditVBAs }}
            entity={data}
            onClick={() => {
              onClick();
              setDuplicateOpen(data);
            }}
          />,
        ]
      : [];

  const getGeneratedPath = (path: string) =>
    generatePath(path, { vendorId, vendorBuyingAgreementId, vendorRebateCategoryId });

  const saveRebateCategory = async (entity: VendorBuyingAgreementRebateCategoryEntity) => {
    return await update(vendorId, vendorBuyingAgreementId, entity);
  };

  const tabs = [
    <LinkTab
      label={t('vendor:rebateCategory.sections.permissions')}
      value={getGeneratedPath(currentPath.Permissions.path)}
    />,
    <LinkTab
      label={t('vendor:rebateCategory.sections.rebates')}
      value={getGeneratedPath(currentPath.Rebates.path)}
      permissions={isInternalUser ? { keys: PermissionKey.VendorViewRebates } : undefined}
    />,
    <LinkTab
      label={t('vendor:rebateCategory.sections.tierAndGrowth')}
      value={getGeneratedPath(currentPath.TiersAndGrowths.path)}
      permissions={isInternalUser ? { keys: PermissionKey.VendorViewRebates } : undefined}
    />,
  ];

  if (isLoading || !data) {
    return (
      <Container>
        <CircularProgress size={20} sx={style.loading} />
      </Container>
    );
  }

  if (!outlet) {
    return <Navigate replace to={getGeneratedPath(currentPath.Permissions.path)} />;
  }

  return (
    <RequirePermission keys={PermissionKey.VendorViewVBAs}>
      <VendorBuyingAgreementRebateCategoryDetailContext.Provider
        value={{
          vendorId,
          vendorBuyingAgreementId,
          readOnly,
          rebateCategory: data,
          save: saveRebateCategory,
          fetchData: refresh,
        }}
      >
        <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
          <Container>
            <EditDetails
              titleLabel={t(`vendor:form.name`)}
              title={'name_En'}
              header={<VendorRebateCategoryHeader />}
              readOnly={readOnly}
              entity={data}
              schema={VendorBuyingAgreementRebateCategorySchema()}
              onSave={saveRebateCategory}
              actionMenuItems={renderActionMenuItems}
              onConfirm={refresh}
              breadcrumbs={
                isInternalUser
                  ? [
                      { title: t('vendor:title'), href: routes.Admin.Vendor.path },
                      { title: vendor?.name, href: generatePath(routes.Admin.Vendor.Detail.path, { vendorId }) },
                      {
                        title: buyingAgreement?.name,
                        href: generatePath(routes.Admin.Vendor.BuyingAgreement.RebateCategories.path, {
                          vendorId,
                          vendorBuyingAgreementId,
                        }),
                      },
                      { title: data ? getTranslation(data, 'name') : undefined },
                    ]
                  : [
                      { title: t('vendor:buyingAgreements.title'), href: routes.Vendor.BuyingAgreements.path },
                      {
                        title: buyingAgreement?.name,
                        href: generatePath(routes.Vendor.BuyingAgreement.RebateCategories.path, {
                          vendorBuyingAgreementId,
                        }),
                      },
                      { title: data ? getTranslation(data, 'name') : undefined },
                    ]
              }
            >
              <VendorRebateCategoryForm />
            </EditDetails>
          </Container>
          <VendorRebateCategoryDuplicateDrawer rebateCategory={duplicateOpen} onClose={() => setDuplicateOpen(null)} />
        </LayoutPage>
      </VendorBuyingAgreementRebateCategoryDetailContext.Provider>
    </RequirePermission>
  );
};
