import { MenuItem } from '@mui/material';
import { useContext } from 'react';
import { user as userApi } from '../../api';
import { UserDetailContext } from '../../contexts';
import { useApi, useInternationalization } from '../../hooks';
import { UserSchema } from '../../schemas';
import { UserType } from '../../types';
import { EditCard } from '../Card';
import { StaticField } from '../Form';
import { Loading } from '../Loading';

interface UserTwoFactorAuthenticationProps {
  type: UserType;
}

export const UserTwoFactorAuthentication = ({ type }: UserTwoFactorAuthenticationProps) => {
  const { t } = useInternationalization();
  const { userId, user, fetchData } = useContext(UserDetailContext);

  // User Permissions
  const { call: resetPhoneNumber } = useApi(userApi.resetPhoneNumber, null);

  if (type == UserType.Member || type == UserType.Vendor) {
    return null;
  }

  const menuItems = (onClick: () => void) =>
    user
      ? [
          <MenuItem
            key="resend2fa"
            onClick={async () => {
              onClick();
              await resetPhoneNumber(userId, type);
              await fetchData();
            }}
          >
            {t('user:profile.twoFactorAuthentication.reset')}
          </MenuItem>,
        ]
      : [];

  if (!user) {
    return <Loading />;
  }

  return (
    <EditCard
      title={t('user:profile.twoFactorAuthentication.title')}
      entity={user}
      schema={UserSchema(type)}
      actionMenuItems={menuItems}
      readOnly
    >
      <StaticField
        label={t('user:profile.twoFactorAuthentication.phoneNumber')}
        hideLabel={!user?.verificationPhoneNumber}
        value={
          user?.verificationPhoneNumber
            ? t('user:profile.twoFactorAuthentication.phoneNumberPlaceholder', {
                phoneNumber: user?.verificationPhoneNumber,
              })
            : t('user:profile.twoFactorAuthentication.phoneNumberEmptyPlaceholder')
        }
      />
    </EditCard>
  );
};
