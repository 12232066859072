import { useInternationalization } from '../../../hooks';
import { ChangeLogEntity } from '../../../models';
import { ChangeLogEntityType } from '../../../types';
import { ChangeLogItem } from '../../ChangeLog';

interface BuyGuideVendorChangeLogItemProps {
  item: ChangeLogEntity;
}

export const BuyGuideVendorChangeLogItem = ({ item }: BuyGuideVendorChangeLogItemProps) => {
  const { t, getTranslation } = useInternationalization();

  const renderValue = () => {
    switch (item.entityType) {
      case ChangeLogEntityType.RebateProgram:
      case ChangeLogEntityType.Document:
      case ChangeLogEntityType.GeneralConditions:
      case ChangeLogEntityType.Contact:
      case ChangeLogEntityType.Location:
      case ChangeLogEntityType.BuyOpportunity:
        return getTranslation(item, 'entityName');
      default:
        break;
    }
    return '';
  };

  return (
    <ChangeLogItem
      item={item}
      renderValue={(item) =>
        t(`changeLog:buyGuideEntityType.${item.entityType}`, {
          action: item.changeLogActionType,
          fullName: item.loggedBy,
          description: item.description,
          value: renderValue(),
        })
      }
    />
  );
};
