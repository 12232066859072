import { Grid } from '@mui/material';
import { useAuth, useEntityFormContext, useInternationalization } from '../../../hooks';
import { ControlledCheckbox, ControlledInput } from '../../Form';

export const InvoicingInformationForm = () => {
  const { isInternalUser } = useAuth();
  const { t } = useInternationalization();
  const { control, variant } = useEntityFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={3}>
        <ControlledCheckbox
          label={t('vendor:financials.form.centralBilling')}
          name="centralBilling"
          control={control}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <ControlledCheckbox label={t('vendor:financials.form.directBilling')} name="directBilling" control={control} />
      </Grid>
      <Grid item xs={6} md={3}>
        <ControlledCheckbox label={t('vendor:financials.form.ediCapability')} name="ediCapability" control={control} />
      </Grid>
      <Grid item xs={6} md={3}>
        <ControlledInput label={t('vendor:financials.form.ediSystem')} name="ediSystem" control={control} />
      </Grid>
      <Grid item xs={variant === 'changeRequest' ? 12 : 6}>
        <ControlledInput
          label={isInternalUser ? t('vendor:financials.form.vendorNotes') : t('vendor:financials.form.comments')}
          name="vendorNotes"
          control={control}
          multiline
          rows={3}
        />
      </Grid>
      <Grid item xs={6}>
        {isInternalUser && variant !== 'changeRequest' && (
          <ControlledInput
            label={t('vendor:financials.form.notes')}
            name="notes"
            control={control}
            multiline
            rows={3}
          />
        )}
      </Grid>
    </Grid>
  );
};
