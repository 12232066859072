import axios from 'axios';
import { AssignedPermissionEntity, UserEntity } from '../models';
import { ExportType, PaginationEntity, PermissionKey, UserFilter, UserType } from '../types';
import { getContentDispositionFileName } from '../utils/file';

export const user = {
  getAll: async (type: UserType, userFilter: UserFilter): Promise<PaginationEntity<UserEntity>> => {
    const { data } = await axios.post(`api/${type}/search`, userFilter);
    return { ...data, data: data.data.map((d: UserEntity) => new UserEntity(d)) };
  },
  getSuggestions: async (
    userFirstNameOrLastName: string,
    type: UserType,
    userFilter?: UserFilter,
  ): Promise<PaginationEntity<UserEntity>> => {
    const { data } = await axios.post(`api/${type}/search`, {
      userFirstNameOrLastName,
      pageSize: 10,
      pageNumber: 1,
      ...userFilter,
    });
    return { ...data, data: data.data.map((d: UserEntity) => new UserEntity(d)) };
  },
  archive: async (ids: number[], isArchived: boolean, type: UserType): Promise<UserEntity[]> => {
    const { data } = await axios.put(`api/${type}/archive`, { isArchived, ids });
    return data.map((v: UserEntity) => new UserEntity(v));
  },
  allowAccess: async (ids: number[], isAllowedAccess: boolean, type: UserType): Promise<UserEntity[]> => {
    const { data } = await axios.put(`api/${type}/allowaccess`, { isAllowedAccess, ids });
    return data.map((v: UserEntity) => new UserEntity(v));
  },
  resetPassword: async (id: number, type: UserType): Promise<boolean> => {
    await axios.put(`api/${type}/${id}/resetpassword`);
    return true;
  },
  resetPhoneNumber: async (id: number, type: UserType): Promise<boolean> => {
    await axios.put(`api/${type}/${id}/resetPhoneNumber`);
    return true;
  },
  create: async (entity: UserEntity, type: UserType): Promise<UserEntity> => {
    const { data } = await axios.post(`api/${type}`, entity);
    return new UserEntity(data);
  },
  get: async (userId: number, type: UserType): Promise<UserEntity> => {
    const { data } = await axios.get(`api/${type}/${userId}`);
    return new UserEntity(data);
  },
  update: async (entity: UserEntity, type: UserType): Promise<UserEntity> => {
    const { data } = await axios.put(`api/${type}/${entity.id}`, entity);
    return new UserEntity(data);
  },
  getPermissions: async (userId: number, type: UserType): Promise<AssignedPermissionEntity[]> => {
    const { data } = await axios.get<AssignedPermissionEntity[]>(`api/${type}/${userId}/permissions`);
    return data.map((d) => new AssignedPermissionEntity(d));
  },
  updatePermissions: async (
    userId: number,
    type: UserType,
    permissions: PermissionKey[],
    excludedPermissions: PermissionKey[],
  ): Promise<AssignedPermissionEntity[]> => {
    const { data } = await axios.put<AssignedPermissionEntity[]>(`api/${type}/${userId}/permissions`, {
      permissionKeys: permissions,
      excludedPermissionKeys: excludedPermissions,
    });
    return data.map((d) => new AssignedPermissionEntity(d));
  },
  verify: async (ids: number[], type: UserType): Promise<UserEntity[]> => {
    const { data } = await axios.put(`api/${type}/verify`, { ids });
    return data.map((v: UserEntity) => new UserEntity(v));
  },
  export: async (
    type: UserType,
    userFilter: UserFilter,
    exportType: ExportType,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post(`api/${type}/export?type=${exportType}`, userFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
