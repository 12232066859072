import { Box, Grid } from '@mui/material';
import { MAX_LONG_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { ForestProductPriceUpdateEntity } from '../../../models';
import { Styles } from '../../../types';
import { ControlledDatePicker, ControlledInput } from '../../Form';
import { useEffect } from 'react';

const style: Styles = {
  container: {
    pb: 4,
  },
};

export const ForestProductPriceUpdateForm = () => {
  const { t } = useInternationalization();
  const { control, variant, setValue } = useEntityFormContext<ForestProductPriceUpdateEntity>();

  useEffect(() => {
    if (variant === 'drawer') {
      setValue('date', new Date(), { shouldDirty: true });
    }
  }, [setValue, variant]);

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ControlledDatePicker
            label={t('forestProduct:priceUpdates.form.date')}
            name="date"
            control={control}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            label={t('forestProduct:priceUpdates.form.notes')}
            name="notes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
