import { AttachmentRounded, ModeCommentRounded } from '@mui/icons-material';
import { Box, Button, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { StaticDrawer } from '..';
import { vendor as vendorApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { BuyGuideVendorDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { useApi, useEnumList, useInternationalization } from '../../hooks';
import { GeneralConditionEntity, TranslatedFileEntity, VendorGeneralConditionEntity } from '../../models';
import {
  FilterPeriod,
  GeneralConditionType,
  LanguageCode,
  OrderBy,
  PermissionKey,
  Styles,
  VendorGeneralConditionFilter,
} from '../../types';
import { downloadBlob, formatLongDate, formatShortDate } from '../../utils/helper';
import { FilterContainer } from '../Container';
import { EntityPeriod } from '../EntityPeriod';
import { PeriodFilterSelect } from '../Filter/PeriodFilterSelect';
import { Select, TranslatedAttachments } from '../Form';
import { LayoutPage } from '../Layout';
import { Table, TableColumn } from '../Table';

const style: Styles = {
  container: {
    pt: 4,
    pb: 4,
  },
  selectLargeContainer: {
    width: {
      xs: '100%',
      sm: '300px',
    },
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  attachmentButton: {
    py: '0 !important',
  },
};

const defaultFilter: VendorGeneralConditionFilter = {
  type: undefined,
  period: FilterPeriod.Current,
  isArchived: false,
  ...DEFAULT_PAGINATION_FILTER,
};

export const BuyGuideVendorGeneralConditionList = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { vendor } = useContext(BuyGuideVendorDetailContext);
  const [filter, setFilter] = useState<VendorGeneralConditionFilter>(defaultFilter);
  const { data, isLoading } = useApi(vendorApi.getAllGeneralConditions, { skipFetch: !vendor }, vendor?.id, filter);
  const typeOptions = useEnumList(GeneralConditionType, 'buyGuide:generalConditions.type', true);
  const [selectedEntity, setSelectedEntity] = useState<GeneralConditionEntity | null>(null);
  const { call: download } = useApi(vendorApi.getGeneralConditionFile, null);

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      type: (event.target.value as GeneralConditionType) || undefined,
      pageNumber: 1,
    }));
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const downloadFile = async (file: TranslatedFileEntity) => {
    if (vendor && selectedEntity && file.file?.id) {
      const fileData = await download(vendor.id, selectedEntity.id, file.file.id);
      if (fileData) {
        downloadBlob(file.file.name, fileData);
      }
    }
  };

  const renderFilter = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <PeriodFilterSelect filter={filter} setFilter={setFilter} />
      <Box sx={style.selectLargeContainer}>
        <Select
          displayEmpty
          options={typeOptions}
          value={filter.type ?? ''}
          onChange={onTypeChange}
          label={t('buyGuide:generalConditions.search.type')}
        />
      </Box>
    </FilterContainer>
  );

  const renderType = (entity: VendorGeneralConditionEntity) => t(`buyGuide:generalConditions.type.${entity.type}`);

  const renderSummary = (entity: VendorGeneralConditionEntity) => (
    <Stack alignItems="flex-start">
      <Typography variant="body1">
        {entity.type === GeneralConditionType.Freight
          ? [
              getTranslation(entity, 'freightPolicy'),
              getTranslation(entity, 'pickupAllowance')
                ? `${t('buyGuide:generalConditions.form.pickupAllowance')}: ${getTranslation(
                    entity,
                    'pickupAllowance',
                  )}`
                : null,
              getTranslation(entity, 'prepaidAmount')
                ? `${t('buyGuide:generalConditions.form.prepaidAmount')}: ${getTranslation(entity, 'prepaidAmount')}`
                : null,
              getTranslation(entity, 'fobPoint')
                ? `${t('buyGuide:generalConditions.form.fobPoint')}: ${getTranslation(entity, 'fobPoint')}`
                : null,
            ]
              .filter((x) => x)
              .join(', ')
          : getTranslation(entity, 'summary')}
      </Typography>
      {entity.files?.length > 0 && (
        <Button
          startIcon={<AttachmentRounded />}
          size="small"
          variant="text"
          sx={style.attachmentButton}
          onClick={() => setSelectedEntity(entity)}
        >
          {t('buyGuide:generalConditions.attachmentsWithNumber', { count: entity.files?.length ?? 0 })}
        </Button>
      )}
    </Stack>
  );

  const renderLastModified = (entity: VendorGeneralConditionEntity) => formatShortDate(entity.modifiedDate);

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewConditions }}
      title={t('buyGuide:generalConditions.title')}
      display="Tab"
    >
      {renderFilter()}
      <Table
        data={data ?? []}
        translationNamespace="buyGuide:generalConditions"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
      >
        <TableColumn
          type="custom"
          width="15%"
          id="period"
          sortable
          render={(entity: VendorGeneralConditionEntity) => (
            <EntityPeriod period={entity.period} translationContext="female" />
          )}
        />
        <TableColumn type="custom" width="15%" id="type" sortable render={renderType} />
        <TableColumn type="custom" width="40%" id="summary" render={renderSummary} />
        <TableColumn
          type="custom"
          id="effectiveDate"
          width="15%"
          sortable
          render={(entity: VendorGeneralConditionEntity) => formatLongDate(entity.effectiveDate)}
        />
        <TableColumn
          type="custom"
          id="expiryDate"
          width="15%"
          sortable
          render={(entity: VendorGeneralConditionEntity) => formatLongDate(entity.expiryDate)}
        />
        <TableColumn type="custom" width="10%" id="modifiedDate" sortable render={renderLastModified} />
        <TableColumn
          type="icon"
          tooltip
          sortable
          id={currentLanguage === LanguageCode.en ? 'memberNotes_En' : 'memberNotes_Fr'}
          align="center"
          render={(entity: VendorGeneralConditionEntity) => getTranslation(entity, 'memberNotes')}
          iconComponent={ModeCommentRounded}
        />
      </Table>
      <StaticDrawer
        title={t('buyGuide:generalConditions.attachments')}
        open={!!selectedEntity}
        footer={
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button variant="contained" size="small" onClick={() => setSelectedEntity(null)}>
              {t('common:close')}
            </Button>
          </Box>
        }
        onClose={() => setSelectedEntity(null)}
      >
        <TranslatedAttachments files={selectedEntity?.files ?? []} label={''} onFileClick={downloadFile} readOnly />
      </StaticDrawer>
    </LayoutPage>
  );
};
