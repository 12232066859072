import { useContext } from 'react';
import { ConfirmationModalContext, ConfirmationModalProps } from '../contexts';

export function useConfirmationModal() {
  const { setModalProps, modalRef } = useContext(ConfirmationModalContext);

  const openModal = (props: Omit<ConfirmationModalProps, 'open'>) => {
    setModalProps({ open: true, ...props });
  };

  return { openModal, modalRef };
}
