import { SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutlet } from 'react-router-dom';
import { MemberListSearch, MemberListView } from '.';
import { member as memberApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { MemberContext } from '../../contexts';
import { useApi } from '../../hooks';
import { MemberFilter } from '../../types';
import { LayoutPage } from '../Layout';

export const MemberListVendor = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const [filter, setInternalFilter] = useState<MemberFilter>({
    isArchived: false,
    ...DEFAULT_PAGINATION_FILTER,
  });
  const { refresh, isLoading, data, setData } = useApi(memberApi.getAll, { skipFetch: !!outlet }, filter);

  const setFilter = useCallback(
    (value: SetStateAction<MemberFilter>) => {
      setInternalFilter(value);
    },
    [setInternalFilter],
  );

  if (outlet) {
    return outlet;
  }

  return (
    <MemberContext.Provider value={{ data, setData, isLoading, fetchData: refresh, filter, setFilter }}>
      <LayoutPage title={t('member:title')}>
        <MemberListSearch />
        <MemberListView />
      </LayoutPage>
    </MemberContext.Provider>
  );
};
