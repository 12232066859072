import { EventType, PublicationUrgency } from '../../types';
import { PeriodEntity } from '../PeriodEntity';

export class EventEntity extends PeriodEntity {
  vendorId: number | null;
  vendorName: string | null;
  name_En: string;
  name_Fr: string;
  urgency?: PublicationUrgency;
  type: EventType;

  constructor(entity?: EventEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.vendorId = null;
      this.vendorName = null;
      this.type = EventType.Publication;
      this.name_En = '';
      this.name_Fr = '';
    }
  }
}
