import { useOutlet } from 'react-router-dom';
import { useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';

export const CmsDetailMember = () => {
  const { t } = useInternationalization();
  const outlet = useOutlet();

  const tabs = [<LinkTab label={t('cms:sections.publications')} value={routes.Member.Cms.Publications.path} />];
  return <LayoutPage display="Page" tabs={tabs} title={t('cms:title')} outlet={outlet}></LayoutPage>;
};
