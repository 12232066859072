import { CheckRounded, ExpandMore, LocalActivity, MoreVertRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Menu, Typography } from '@mui/material';
import { MouseEvent, useContext, useState } from 'react';
import { cci as cciApi } from '../../../api';
import { CommitmentEventDetailContext } from '../../../contexts';
import { useApi, useAuth, useGlobalEdit, useInternationalization } from '../../../hooks';
import { CategorizationEntity, CommitmentEventCategoryEntity } from '../../../models';
import { CommitmentEventCategoryVendorEntity } from '../../../models/Cci';
import { palette } from '../../../styles/palette';
import theme from '../../../styles/theme';
import { LanguageCode, PermissionKey, Styles } from '../../../types';
import { percentageFormatter } from '../../../utils/formatters';
import { formatMoney } from '../../../utils/helper';
import { ArchiveMenuItem, MenuItem } from '../../Menu';
import { Table, TableColumn } from '../../Table';

const styles: Styles = {
  accordion: {
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '16px !important',
    border: 0,
    ':before': {
      display: 'none',
    },
  },
  archived: {
    background: palette.grey[50],
    '*': {
      color: palette.grey[400],
    },
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
  },
  commitmentRequiredTag: {
    color: palette.secondary.red,
    background: 'rgba(171, 18, 48, 0.15)',
    px: 1,
    borderRadius: 1,
    fontWeight: 600,
  },
  menu: {
    '& li': {
      whiteSpace: 'nowrap',
    },
  },
  accordionsContainer: {
    '>div:not(div:last-of-type)': {
      mb: 1,
    },
  },
};

interface CommitmentEventCategoriesAccordionProps {
  categories: CommitmentEventCategoryEntity[];
  isCommitted: boolean;
  refresh: () => Promise<void>;
  onEditClick: (category: CommitmentEventCategoryEntity) => void;
}

export const CommitmentEventCategoriesAccordion = ({
  isCommitted,
  refresh,
  onEditClick,
  ...props
}: CommitmentEventCategoriesAccordionProps) => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { hasPermissions } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { eventId, readonly } = useContext(CommitmentEventDetailContext);
  const [menuItem, setMenuItem] = useState<CommitmentEventCategoryEntity | null>(null);
  const archiveApi = useApi(cciApi.archiveCommitmentEventCategories, { successKey: 'common:success.action' });
  const categories = props.categories.filter((category) => category.isCommitted === isCommitted);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>, item: CommitmentEventCategoryEntity) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const onArchiveConfirm = async (isArchived: boolean, entity: CommitmentEventCategoryEntity) => {
    await archiveApi.call(eventId, isArchived, [entity.id]);
    refresh();
  };

  const renderActionMenuItems = () =>
    menuItem && hasPermissions(PermissionKey.CciManageEvents)
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={menuItem}
            name={getTranslation(menuItem, 'name')}
            onClick={handleMenuClose}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('cci:events.actions.suffix')}
          />,
          ...(!menuItem.isArchived
            ? [
                <MenuItem
                  id="edit"
                  key="edit"
                  disabled={menuItem.isArchived}
                  onClick={() => {
                    handleMenuClose();
                    onEditClick(menuItem);
                  }}
                >
                  {t('common:edit')}
                </MenuItem>,
              ]
            : []),
        ]
      : [];

  const renderRegions = (regions: CategorizationEntity[]) =>
    regions.length
      ? regions.map((r) => getTranslation(r, 'abbreviation')).join(', ')
      : t('common:filter.all', { context: 'female' });

  const renderVendor = (entity: CommitmentEventCategoryVendorEntity) => (
    <Typography>
      {entity.vendor?.name}
      {entity.isPreferred && <LocalActivity htmlColor={palette.grey[300]} />}
    </Typography>
  );

  const renderTable = (categories: CommitmentEventCategoryEntity) => (
    <Table data={categories.vendors ?? []} translationNamespace="cci:eventCategories" hideTotal>
      <TableColumn
        type="custom"
        width="50%"
        id="vendor"
        render={(entity: CommitmentEventCategoryVendorEntity) => renderVendor(entity)}
      />
      <TableColumn
        type="custom"
        width="20%"
        id="regions"
        render={(entity: CommitmentEventCategoryVendorEntity) => renderRegions(entity.regions)}
      />
      <TableColumn
        type="custom"
        width="10%"
        id="current"
        render={(entity: CommitmentEventCategoryVendorEntity) => entity.isCurrent?.toString() ?? '-'}
      />
      <TableColumn
        type="custom"
        width="10%"
        id="support"
        render={(entity: CommitmentEventCategoryVendorEntity) => entity.willSupport?.toString() ?? '-'}
      />
      <TableColumn
        type="custom"
        width="10%"
        id="volume"
        render={(entity: CommitmentEventCategoryVendorEntity) => (entity.volume ? formatMoney(entity.volume) : '-')}
      />
    </Table>
  );

  return (
    <>
      <Typography variant="h3" mb={2}>
        {t(`cci:eventCategories.card.${isCommitted ? 'committed' : 'commitmentRequired'}`, {
          count: categories.length,
        })}
      </Typography>
      <Box mb={3} sx={styles.accordionsContainer}>
        {categories.map((category) => (
          <Accordion sx={[styles.accordion, ...(category.isArchived ? [styles.archived] : [])]} key={category.id}>
            <AccordionSummary expandIcon={<ExpandMore htmlColor={palette.primary.deep} />} sx={styles.accordionSummary}>
              <Grid container>
                <Grid item xs={7} sm={8} md={10} alignItems="center" display="flex" flexWrap="wrap">
                  <Typography variant="h3" ml={1}>
                    {getTranslation(category, 'name')}
                  </Typography>
                  {isCommitted ? (
                    <CheckRounded htmlColor={palette.grey[500]} />
                  ) : (
                    <Typography ml={1} sx={styles.commitmentRequiredTag}>
                      {t('cci:eventCategories.card.commitmentRequiredTag')}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={5} sm={4} md={2} alignItems="center" display="flex" justifyContent="flex-end">
                  <Box>
                    <Typography textAlign="center" variant="label" mr={2}>
                      {t('cci:eventCategories.card.yoy')}
                    </Typography>
                    <Typography textAlign="center" fontWeight={700}>
                      {category.yearOverYear
                        ? percentageFormatter(currentLanguage)((category.yearOverYear * 100).toFixed(3)).formatted.join(
                            currentLanguage === LanguageCode.fr ? ' ' : '',
                          )
                        : ''}
                    </Typography>
                  </Box>
                  {!readonly && !globalEditing && hasPermissions(PermissionKey.CciManageEvents) && (
                    <IconButton id="action-menu" onClick={(e) => handleMenuOpen(e, category)}>
                      <MoreVertRounded htmlColor={palette.secondary.dark} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>{renderTable(category)}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Menu
        id="table-menu"
        MenuListProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.MenuListProps}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        PaperProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps}
        sx={styles.menu}
      >
        {renderActionMenuItems()}
      </Menu>
    </>
  );
};
