import { MenuItem, MenuItemProps } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useConfirmationModal } from '../../hooks';
import { AllowedAccess, Entity } from '../../types';

interface AllowAccessMenuItemProps<T extends Entity & AllowedAccess> extends MenuItemProps {
  entity: T;
  name: string;
  onAllowAccessConfirm: (isAllowedAccess: boolean, entity: T) => void;
  onClick: () => void;
  actionSuffix: string;
}

export const AllowAccessMenuItem = <T extends Entity & AllowedAccess>({
  entity,
  name,
  onAllowAccessConfirm,
  onClick,
  actionSuffix,
  ...props
}: AllowAccessMenuItemProps<T>) => {
  const { openModal } = useConfirmationModal();
  const { t } = useTranslation();

  const onAllowAccesClick = () => {
    openModal({
      confirmText: t('common:action.confirmButton', { action: t('common:action.allowAccess') }),
      title: t('common:action.confirmTitle', {
        name: actionSuffix,
        action: t('common:action.allowAccess'),
        count: 1,
      }),
      description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name }} />,
      onConfirm: () => onAllowAccessConfirm(true, entity),
    });
    onClick && onClick();
  };

  const onBlockAccessClick = () => {
    openModal({
      confirmText: t('common:action.confirmButton', { action: t('common:action.blockAccess') }),
      title: t('common:action.confirmTitle', {
        name: actionSuffix,
        action: t('common:action.blockAccess'),
        count: 1,
      }),
      description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name }} />,
      onConfirm: () => onAllowAccessConfirm(false, entity),
    });
    onClick && onClick();
  };

  if (!entity.isAllowedAccess) {
    return (
      <MenuItem {...props} id="allowAccess" key="allowAccess" onClick={onAllowAccesClick}>
        {t('common:action.allowAccess')}
      </MenuItem>
    );
  }

  return (
    <MenuItem {...props} id="blockAccess" key="blockAccess" onClick={onBlockAccessClick}>
      {t('common:action.blockAccess')}
    </MenuItem>
  );
};
