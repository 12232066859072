import { SyncDisabledRounded } from '@mui/icons-material';
import { createSearchParams } from 'react-router-dom';
import { member as memberApi } from '../../../api';
import { MIN_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { routes } from '../../../routes';
import { MemberFilter } from '../../../types';
import { CounterWidget } from './CounterWidget';
import { WidgetProps } from './Widget';

const filter: MemberFilter = {
  ...MIN_PAGINATION_FILTER,
  mailSyncErrorOnly: true,
};

export const FailedMailSyncMembers = (props: WidgetProps) => {
  const { t } = useInternationalization();
  const { data, isLoading } = useApi(memberApi.getAll, null, filter);

  return (
    <CounterWidget
      {...props}
      isLoading={isLoading}
      count={data?.totalCount}
      title={t('dashboard:failedMailSyncMembers:title')}
      icon={SyncDisabledRounded}
      link={`${routes.Admin.Member.path}?${createSearchParams({ mailSyncErrorOnly: 'true' }).toString()}`}
    />
  );
};
