import { array, number, object, string } from 'yup';
import { MAX_INTEGER, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH, MAX_URL_LENGTH } from '../../constants';
import { MemberEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema } from '../../types';
import { AddressSchema, EmailAddressSchema, PhoneNumberSchema } from '../Contact';

export const MemberSchema = (): EntityFormSchema<MemberEntity> => ({
  schema: object({
    name: string().required().max(MAX_SHORT_TEXT_LENGTH),
    number: number().integer().min(1).max(MAX_INTEGER),
    location: number().integer().min(0).max(MAX_INTEGER),
    website: string().notRequired().nullable().max(MAX_URL_LENGTH),
    regionIds: array().min(1).required(),
    membershipTypeId: number().required(),
    address: AddressSchema().schema,
    notes: string().notRequired().max(MAX_LONG_TEXT_LENGTH),
  })
    .concat(PhoneNumberSchema().schema)
    .concat(EmailAddressSchema().schema),
  errorCodeMap: {
    [ApiErrorCodes.MEMBER_NAME_NOT_UNIQUE]: { key: 'name', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.MEMBER_NUMBER_LOCATION_NOT_UNIQUE]: { key: 'location', message: 'common:error.fieldUnique' },
  },
});
