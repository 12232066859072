import { UserType } from '../../types';
import { AssignedPermissionEntity } from '../Permission';

export class RoleEntity {
  id: number;
  description: string;
  name_En: string;
  name_Fr: string;
  userType?: UserType;
  assignedPermissions?: AssignedPermissionEntity[] | null;

  constructor(entity?: RoleEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
      this.userType = entity.userType;
    } else {
      this.id = 0;
      this.description = '';
      this.name_En = '';
      this.name_Fr = '';
    }
  }
}
