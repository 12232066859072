import { useTranslation } from 'react-i18next';
import { useSettings } from '../../hooks';
import { Archivable, Entity, Styles } from '../../types';
import { MenuItem, MenuItemProps } from './MenuItem';

const style: Styles = {
  largeMenuItem: {
    width: 250,
  },
};

interface MailSyncMenuItemProps<T extends Entity & Archivable> extends MenuItemProps {
  onMailSync: (entity: T) => Promise<void>;
  onClick: () => void;
  entity: T;
  shouldDelete: boolean;
}

export const MailSyncMenuItem = <T extends Entity & Archivable>({
  onClick,
  onMailSync,
  entity,
  shouldDelete,
  ...props
}: MailSyncMenuItemProps<T>) => {
  const { t } = useTranslation();
  const { settings } = useSettings();

  const onMailSyncClick = async () => {
    onClick && onClick();
    await onMailSync(entity);
  };

  return (
    <MenuItem
      {...props}
      disabled={!settings?.isMailSyncEnabled}
      sx={style.largeMenuItem}
      id="mailSync"
      key="mailSync"
      onClick={onMailSyncClick}
    >
      {shouldDelete ? t('common:action.removeFromMailSync') : t('common:action.mailSync')}
    </MenuItem>
  );
};
