import { Grid, RegularBreakpoints } from '@mui/material';
import { useEffect } from 'react';
import { useEntityFormContext, useInternationalization } from '../hooks';
import { PeriodEntity } from '../models';
import { ControlledDatePicker } from './Form';

interface PeriodFormProps extends RegularBreakpoints {
  optionalExpiryDate?: boolean;
  showExpiryDate?: boolean;
  effectiveDateLabel?: string;
  expiryDateLabel?: string;  
  readOnly?: boolean;
}

export function PeriodForm({
  optionalExpiryDate = false,
  showExpiryDate = true,
  effectiveDateLabel,
  expiryDateLabel,
  readOnly,
  ...props
}: PeriodFormProps) {

  const { t } = useInternationalization();

  const { getValues, control, trigger, watch } = useEntityFormContext<PeriodEntity>();

  const effectiveDateWatch = watch('effectiveDate');
  const expiryDateWatch = watch('expiryDate');

  useEffect(() => {
    if (effectiveDateWatch && expiryDateWatch) {
      trigger('effectiveDate');
      trigger('expiryDate');
    }
  }, [trigger, effectiveDateWatch, expiryDateWatch]);

  return (
    <>
      <Grid item {...props}>
        <ControlledDatePicker
          label={effectiveDateLabel ?? t('vendor:period.effectiveDate')}
          readOnly={readOnly}
          name="effectiveDate"
          control={control}
          required
        />
      </Grid>
      {showExpiryDate && (
        <Grid item {...props}>
          <ControlledDatePicker
            label={expiryDateLabel ?? t('vendor:period.expiryDate')}
            readOnly={readOnly}
            name="expiryDate"
            control={control}
            minDate={getValues().effectiveDate || undefined}
            required={!optionalExpiryDate}
            clearable
          />
        </Grid>
      )}
    </>
  );
}
