export const compareAsc =
  <T>(key: keyof T) =>
  (a: T, b: T) => {
    if (a[key] === b[key]) {
      return 0;
    }
    if (a[key] === null) {
      return 1;
    }
    if (b[key] === null) {
      return -1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 1;
  };
