import { boolean, date, mixed, object, string } from 'yup';
import { HelpTrainingEntity } from '../../models';
import { EntityFormSchema, HelpTrainingType } from '../../types';
import { MAX_SHORT_TEXT_LENGTH, MAX_LONG_TEXT_LENGTH } from '../../constants';

export const HelpTrainingSchema = (): EntityFormSchema<HelpTrainingEntity> => ({
  schema: object().shape(
    {
      name_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
      name_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      searchKeywords_En: string().notRequired().max(MAX_LONG_TEXT_LENGTH),
      searchKeywords_Fr: string().notRequired().max(MAX_LONG_TEXT_LENGTH),
      effectiveDate: date().required(),
      type: mixed<HelpTrainingType>().oneOf(Object.values(HelpTrainingType)).required(),
      isVisibleToAdmins: boolean().notRequired(),
      isVisibleToVendors: boolean().notRequired(),
      isVisibleToMembers: boolean().notRequired(),
    },
    [],
  ),
  errorCodeMap: {},
});
