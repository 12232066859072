import { ArchiveRounded, UnarchiveRounded } from '@mui/icons-material';
import { useState } from 'react';
import { contact as contactApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { OfficeEntity } from '../../../models';
import { OfficeFilter, PermissionKey } from '../../../types';
import { ArchiveMenuItem } from '../../Menu';
import { OfficeList } from './OfficeList';

export const OfficeListAdmin = () => {
  const { t, getTranslation } = useInternationalization();

  const [filter, setFilter] = useState<OfficeFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });
  const getAllContactsApi = useApi(contactApi.getAllOffices, { callImmediately: false, skipFetch: true }, filter);
  const { call: create } = useApi(contactApi.createOffice, { successKey: 'common:success.save' });
  const archiveApi = useApi(contactApi.archiveOffice, { successKey: 'common:success.action' });

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: OfficeEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: OfficeEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onSave = async (entity: OfficeEntity) => {
    return await create(entity);
  };

  const onArchiveConfirm = async (isArchived: boolean, contacts: OfficeEntity[]) => {
    await archiveApi.call(
      contacts.map((i) => i.id),
      isArchived,
    );
    getAllContactsApi.call(filter);
  };

  const renderActionsMenuItems = (entity: OfficeEntity, onClick: () => void) => [
    <ArchiveMenuItem
      permissions={{ keys: PermissionKey.AdminManageContacts }}
      key="archive"
      entity={entity}
      name={getTranslation(entity, 'description')}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('contact:office.actions.suffix')}
    />,
  ];

  return (
    <OfficeList
      filter={filter}
      setFilter={setFilter}
      fetchApi={getAllContactsApi}
      onSave={onSave}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
        bulkActions: bulkActions,
        bulkActionSuffix: t('contact:office.actions.suffix'),
        bulkPermissions: { keys: PermissionKey.AdminManageContacts },
        renderBulkSelection: (items) =>
          items.length === 1 ? getTranslation(items[0], 'description') : t(`contact:office.title`),
      }}
    />
  );
};
