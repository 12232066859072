import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

interface InternationalizationContextType {
  timeZoneName?: string;
  setTimeZoneName: Dispatch<SetStateAction<string | undefined>>;
}

export const InternationalizationContext = React.createContext<InternationalizationContextType>(
  {} as InternationalizationContextType,
);

export const InternationalizationProvider = ({ children }: PropsWithChildren) => {
  const [timeZoneName, setTimeZoneName] = useState<string | undefined>(undefined);

  return (
    <InternationalizationContext.Provider value={{ timeZoneName, setTimeZoneName }}>
      {children}
    </InternationalizationContext.Provider>
  );
};
