import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const style = {
  externalLink: {
    width: 'fit-content',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LinkBehaviour = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  ({ href, target, ...other }, ref) => {
    if (target === '_blank') {
      // for external links
      return <a style={style.externalLink} target={target} href={href as string} {...other} />;
    }
    return <RouterLink ref={ref} to={href} {...other} />;
  },
);
