import { MemberCommitmentDocumentType } from '../../types';
import { FileEntity } from '../FileEntity';
import { PeriodEntity } from '../PeriodEntity';

export class MemberCommitmentDocumentEntity extends PeriodEntity {
  memberId: number;
  description: string;
  fileId: number;
  file: FileEntity | null;
  type: MemberCommitmentDocumentType;
  isArchived: boolean;
  isPending: boolean;
  notes: string;
  memberNotes: string;

  constructor(entity?: MemberCommitmentDocumentEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.memberId = 0;
      this.description = '';
      this.fileId = 0;
      this.file = null;
      this.type = MemberCommitmentDocumentType.ForestProducts;
      this.notes = '';
      this.memberNotes = '';
      this.isArchived = false;
    }
  }
}
