import { ContentBlockType } from '../../types';
import { FileEntity } from '../FileEntity';
import { PeriodEntity } from '../PeriodEntity';

export class ContentBlockEntity extends PeriodEntity {
  type: ContentBlockType;
  content_En: string;
  content_Fr: string;
  file_En?: FileEntity;
  file_Fr?: FileEntity;
  order: number;

  constructor(entity?: ContentBlockEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.type = ContentBlockType.HTML;
      this.content_En = '';
      this.content_Fr = '';
      this.order = 0;
    }
  }
}
