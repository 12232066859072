import { EditableEntity } from '../EditableEntity';
import { MemberEntity } from '../Member';
import { CommitmentEventCategoryEntity } from './CommitmentEventCategoryEntity';
import { MemberCommitmentVendorEntity } from './MemberCommitmentVendorEntity';

export class MemberCommitmentEntity extends EditableEntity {
  commitmentEventCategory: CommitmentEventCategoryEntity;
  commitmentEventCategoryId: number;
  memberId: number;
  member: MemberEntity;
  vendors: MemberCommitmentVendorEntity[];
  volume?: number;

  constructor(entity?: MemberCommitmentEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.commitmentEventCategoryId = this.commitmentEventCategory?.id;
      this.memberId = this.member?.id;
      this.vendors = this.vendors?.map((mcv: MemberCommitmentVendorEntity) => new MemberCommitmentVendorEntity(mcv));
    } else {
      this.vendors = [];
    }
  }
}
