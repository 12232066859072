import { boolean, date, mixed, object, ref, string } from 'yup';
import { MAX_FILE_SIZE_MB, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { AdEntity } from '../../models';
import { AdType, EntityFormSchema } from '../../types';
import { mbToBytes } from '../../utils/file';
import { isDateValid } from '../../utils/helper';
import { isImgExtension } from '../../utils/schemas';

export const AdSchema = (type: AdType): EntityFormSchema<AdEntity> => ({
  schema: object().shape(
    {
      name: string().required().max(MAX_SHORT_TEXT_LENGTH),
      type: string().required(),
      file_En: (type === AdType.Image ? mixed().required() : mixed().nullable().notRequired())
        .test('max-size', 'common:error.arrayMaxItemSize', (file) =>
          file ? file.size < mbToBytes(MAX_FILE_SIZE_MB) : true,
        )
        .test('file-type', 'common:error.onlyImgExtension', isImgExtension),
      file_Fr: mixed()
        .test('max-size', 'common:error.arrayMaxItemSize', (file) =>
          file ? file.size < mbToBytes(MAX_FILE_SIZE_MB) : true,
        )
        .test('file-type', 'common:error.onlyImgExtension', isImgExtension),
      videoUrl_En: (type === AdType.Video ? string().required() : string().nullable().notRequired()).max(
        MAX_SHORT_TEXT_LENGTH,
      ),
      videoUrl_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      destinationUrl_En: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      destinationUrl_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .required()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      hasPriority: boolean().required(),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
