import React, { Dispatch, SetStateAction } from 'react';
import { MemberGroupEntity } from '../../models';
import { MemberGroupFilter, PaginationEntity } from '../../types';

interface MemberGroupContextType {
  data: PaginationEntity<MemberGroupEntity> | null;
  setData: Dispatch<SetStateAction<PaginationEntity<MemberGroupEntity> | null>>;
  fetchData: () => Promise<void>;
  filter: MemberGroupFilter;
  setFilter: Dispatch<SetStateAction<MemberGroupFilter>>;
  isLoading: boolean;
}

export const MemberGroupContext = React.createContext<MemberGroupContextType>({} as MemberGroupContextType);
