import { useContext, useEffect, useMemo } from 'react';
import { member as memberApi } from '../../api';
import { EntityFormProvider, MemberChangeRequestContext } from '../../contexts';
import { useApi, useEntityForm } from '../../hooks';
import { AddressEntity, MemberEntity } from '../../models';
import { MemberChangeRequestSchema } from '../../schemas';
import { ChangeRequestTrigger } from '../../types';
import { padChangeRequestArray, unpadChangeRequestArray } from '../../utils/changeRequests';
import { EditDrawerChangeRequest } from '../EditDrawerChangeRequest';
import { MemberForm } from '../Member';
import { ChangeRequestDrawerHeader } from './ChangeRequestDrawerHeader';

export const MemberGeneralInformationChangeRequest = () => {
  const { changeRequest: memberChangeRequest, isAfter } = useContext(MemberChangeRequestContext);
  const { data, isLoading } = useApi(memberApi.getGeneralInformationChangeRequest, null, memberChangeRequest.id);
  const { call: update } = useApi(memberApi.approvedOrRejectGeneralInformationRequest, {
    successKey: 'common:success.save',
  });
  const entity = useMemo(() => new MemberEntity(), []);
  const paddedData = useMemo(() => {
    if (data?.entity && !data?.entity.address) {
      data.entity.address = new AddressEntity();
    }
    return padChangeRequestArray(data, 'emailAddresses', 'phoneNumbers');
  }, [data]);

  const { handleSubmit, reset, setValues, isSubmitDisabled, form, handleError } = useEntityForm<MemberEntity>(
    paddedData?.entity || entity,
    MemberChangeRequestSchema(),
    {
      variant: 'changeRequest',
      readOnly: !isAfter,
    },
  );

  useEffect(() => {
    if (paddedData) {
      if (isAfter) {
        setValues(paddedData?.changeRequest || entity);
      } else {
        reset();
      }
    }
  }, [isAfter, setValues, reset, entity, paddedData]);

  const saveDrawer = (e: MemberEntity, trigger: ChangeRequestTrigger) => {
    return update(memberChangeRequest.id, trigger, unpadChangeRequestArray(e, 'emailAddresses', 'phoneNumbers'));
  };

  return (
    <EntityFormProvider {...form} isLoading={isLoading || !data}>
      <EditDrawerChangeRequest
        open={true}
        entity={entity}
        handleSubmit={handleSubmit}
        handleError={handleError}
        isSubmitDisabled={isSubmitDisabled}
        reset={reset}
        onSave={saveDrawer}
      >
        <ChangeRequestDrawerHeader />
        <MemberForm />
      </EditDrawerChangeRequest>
    </EntityFormProvider>
  );
};
