import { useMemo } from 'react';
import { useApi, useInternationalization } from '../../hooks';
import { RoleEntity } from '../../models';
import { RoleSchema } from '../../schemas';
import { EditDrawer } from '../EditDrawer';
import { role as roleApi } from '../../api';
import { RoleForm } from './RoleForm';
import { PermissionState } from '../../types';

interface RoleEditDrawerProps {
  entity: RoleEntity;
  onClose: () => void;
}

export const RoleEditDrawer = ({ onClose, entity }: RoleEditDrawerProps) => {
  const { getTranslation } = useInternationalization();
  const updatePermissions = useApi(roleApi.updatePermissions, { successKey: 'common:success.action' });
  const { data: rolePermissions } = useApi(roleApi.getPermissions, null, entity.id);
  const role = useMemo(() => ({ ...entity, assignedPermissions: rolePermissions }), [entity, rolePermissions]);

  const onSave = async (entity: RoleEntity) => {
    if (entity.assignedPermissions) {
      const result = await updatePermissions.call(
        entity.id,
        entity.assignedPermissions.filter((p) => p.state === PermissionState.Included).map((p) => p.permission.id),
      );
      return { ...entity, assignedPermissions: result };
    }
    return null;
  };

  return (
    <EditDrawer
      open={true}
      entity={role}
      schema={RoleSchema()}
      title={getTranslation(entity, 'name')}
      onConfirm={onClose}
      onCancel={onClose}
      onSave={onSave}
      size="large"
    >
      <RoleForm />
    </EditDrawer>
  );
};
