import { CloseRounded, QuestionAnswerRounded } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, List, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useInternationalization } from '../../hooks';
import { ChangeLogEntity } from '../../models';
import { palette } from '../../styles/palette';
import { ChangeLogType, PaginationEntity, Styles } from '../../types';
import { ChangeLogComment } from '../ChangeLog';
import { Drawer } from '../Drawer';
import { Loading } from '../Loading';

const style: Styles = {
  container: {
    minWidth: {
      xs: '100%',
      md: 800,
    },
    maxWidth: {
      xs: '100%',
      md: '50vw',
    },
    backgroundColor: palette.white,
    borderTopLeftRadius: '12px',
    overflow: 'hidden',
    outline: 'none',
    flex: 1,
    marginTop: 1,
    spacing: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  titleBox: {
    color: palette.white,
    minHeight: 47,
    display: 'flex',
    px: 1.2,
    backgroundColor: palette.primary.dark,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flex: 1,
  },
  paperDrawer: {
    backgroundColor: 'transparent',
    width: {
      xs: '100%',
      md: 'inherit',
    },
  },
  closeButton: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  listContainer: {
    flex: 1,
    overflow: 'auto',
    px: 2.5,
    textAlign: 'center',
  },
};

interface ChangeLogsProps {
  title: string;
  data: PaginationEntity<ChangeLogEntity> | null;
  isLoading: boolean;
  refresh: () => void;
  renderFilter: () => React.ReactElement;
  onCommentAdded?: (text: string) => Promise<void>;
  renderItem: (item: ChangeLogEntity) => React.ReactElement;
}

export const ChangeLogs = ({
  title,
  data,
  isLoading,
  refresh,
  renderFilter,
  onCommentAdded,
  renderItem,
}: ChangeLogsProps) => {
  const { t } = useInternationalization();
  const [isChangeLogOpen, setChangeLogOpen] = useState(false);

  useEffect(() => {
    if (isChangeLogOpen) {
      refresh();
    }
  }, [isChangeLogOpen, refresh]);

  const toggleChangeLogOpen = (isOpen: boolean) => () => {
    setChangeLogOpen(isOpen);
  };

  const renderList = () => {
    if (isLoading || !data) {
      return (
        <Box sx={style.listContainer}>
          <Loading />
        </Box>
      );
    }
    if (data.data.length == 0) {
      return (
        <Stack spacing={1} sx={style.listContainer} pt={3}>
          <Typography variant="h3">{t('changeLog:emptyTitle')}</Typography>
          <Typography variant="h4">{t('changeLog:emptySubtitle')}</Typography>
        </Stack>
      );
    }
    return (
      <List sx={style.listContainer}>
        {data.data.map((item, index) => {
          const nextItem = data.data[index + 1];
          const hideDivider =
            !nextItem ||
            (item.changeLogType == ChangeLogType.Comment && nextItem.changeLogType == ChangeLogType.Comment);

          return (
            <Stack key={item.id + index.toString()}>
              {renderItem(item)}
              {!hideDivider && <Divider />}
            </Stack>
          );
        })}
      </List>
    );
  };

  return (
    <>
      <IconButton title={title} onClick={toggleChangeLogOpen(true)}>
        <QuestionAnswerRounded htmlColor={palette.primary.deep} />
      </IconButton>
      <Drawer
        PaperProps={{ sx: style.paperDrawer }}
        anchor="right"
        open={isChangeLogOpen}
        onClose={toggleChangeLogOpen(false)}
      >
        <Box sx={style.container}>
          <Box sx={style.titleBox}>
            <Stack direction="row" spacing={1} alignItems="center">
              <QuestionAnswerRounded htmlColor={palette.white} />
              <Typography variant="drawerTitle">{title}</Typography>
            </Stack>
            <Button
              variant="text"
              sx={style.closeButton}
              onClick={toggleChangeLogOpen(false)}
              startIcon={<CloseRounded htmlColor={palette.white} />}
            >
              <Typography variant="label" color={palette.white}>
                {t('common:close')}
              </Typography>
            </Button>
          </Box>
          <Box sx={style.contentBox}>
            {renderFilter()}
            <Divider />
            {renderList()}
            {onCommentAdded && (
              <>
                <Divider />
                <ChangeLogComment onCommentAdded={onCommentAdded} isLoading={isLoading} />
              </>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
