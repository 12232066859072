import {
  ArchiveRounded,
  CampaignRounded,
  CheckCircleRounded,
  LocationOnRounded,
  ModeCommentRounded,
  SyncProblemRounded,
  UnarchiveRounded,
  UnpublishedRounded,
} from '@mui/icons-material';
import { Link } from '@mui/material';
import { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { member as memberApi } from '../../api';
import { MemberContext } from '../../contexts';
import { useApi, useAuth, useInternationalization } from '../../hooks';
import { MemberEntity } from '../../models';
import { routes } from '../../routes';
import { palette } from '../../styles/palette';
import { OrderBy, PermissionKey, Styles } from '../../types';
import { LimitCommaValues } from '../LimitCommaValues';
import { ArchiveMenuItem, MenuItem } from '../Menu';
import { Table, TableColumn } from '../Table';

const style: Styles = {
  largeMenuItem: {
    width: 250,
  },
};

export const MemberListView = () => {
  const { t, getTranslation } = useInternationalization();
  const { isInternalUser, isMemberUser, setMemberId, user, hasPermissions } = useAuth();
  const navigate = useNavigate();
  const { data, isLoading, filter, setFilter, fetchData } = useContext(MemberContext);
  const archiveApi = useApi(memberApi.archive, { successKey: 'common:success.action' });
  const activeApi = useApi(memberApi.active, { successKey: 'common:success.action' });

  const bulkActions = [
    {
      icon: <CheckCircleRounded />,
      label: t('common:action.markActive'),
      onConfirm: (items: MemberEntity[]) => onActive(true, items),
    },
    {
      icon: <UnpublishedRounded />,
      label: t('common:action.markInactive'),
      onConfirm: (items: MemberEntity[]) => onActive(false, items),
    },
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: MemberEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: MemberEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onArchiveConfirm = async (isArchived: boolean, members: MemberEntity[]) => {
    await archiveApi.call(
      members.map((i) => i.id),
      isArchived,
    );
    fetchData();
  };

  const onActive = async (isActive: boolean, members: MemberEntity[]) => {
    await activeApi.call(
      members.map((i) => i.id),
      isActive,
    );
    await fetchData();
  };

  const getDetailsPath = (memberId: number) => {
    const basePath = isInternalUser
      ? routes.Admin.Member.Detail.path
      : isMemberUser
      ? routes.Member.Member.Detail.path
      : routes.Vendor.Member.Detail.path;
    return generatePath(basePath, { memberId });
  };

  const renderActionsMenuItems = (member: MemberEntity, onClick: () => void) => [
    ...(isMemberUser && member.isMyLocation
      ? [
          <MenuItem
            key="select"
            onClick={() => {
              setMemberId(member.id);
              onClick();
            }}
            disabled={user?.memberId == member.id}
            sx={style.largeMenuItem}
          >
            {t('common:action.select')}
          </MenuItem>,
        ]
      : []),
    ...(isInternalUser && !member.isPending
      ? [
          <MenuItem
            id={member.isActive ? 'inactive' : 'active'}
            key="active"
            onClick={() => {
              onActive(!member.isActive, [member]);
              onClick();
            }}
            sx={style.largeMenuItem}
            permissions={{ keys: PermissionKey.MemberEditMembers }}
          >
            {member.isActive ? t('common:action.markInactive') : t('common:action.markActive')}
          </MenuItem>,
          <ArchiveMenuItem
            key="archive"
            entity={member}
            name={member.name}
            onClick={onClick}
            onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
            actionSuffix={t('member:action.suffix')}
            permissions={{ keys: PermissionKey.MemberEditMembers }}
          />,
        ]
      : []),
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(getDetailsPath(member.id));
      }}
    >
      {t('common:view')}
    </MenuItem>,
  ];

  const renderMemberLink = (id: number, text: string) => (
    <Link underline="always" href={getDetailsPath(id)}>
      {text}
    </Link>
  );

  const renderMembershipType = (item: MemberEntity) =>
    item?.membershipType ? getTranslation(item.membershipType, 'name') : '';

  const renderRegions = (item: MemberEntity) => (
    <LimitCommaValues value={item.regions.map((region) => getTranslation(region, 'name')).join(', ')} maxLength={50} />
  );

  return (
    <Table
      actionMenuItems={renderActionsMenuItems}
      data={data ?? []}
      translationNamespace="member"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      bulkActions={isInternalUser ? bulkActions : undefined}
      bulkActionSuffix={t('member:action.suffix')}
      bulkPermissions={{ keys: PermissionKey.MemberEditMembers }}
      renderBulkSelection={(items) => (items.length === 1 ? items[0].name : t('navigation:sections.members'))}
      isLoading={isLoading}
      hidePending
    >
      <TableColumn
        type="custom"
        width="10%"
        sortable
        id="number"
        render={({ id, fullNumber }: MemberEntity) => renderMemberLink(id, fullNumber)}
      />
      <TableColumn
        type="custom"
        sortable
        id="name"
        width="50%"
        render={({ id, name }: MemberEntity) => renderMemberLink(id, name)}
      />
      <TableColumn type="custom" width="20%" id="membershipType" render={renderMembershipType} />
      <TableColumn type="custom" width="20%" id="regionIds" render={renderRegions} />
      {isInternalUser && (
        <TableColumn type="icon" sortable id="isMarketing" iconComponent={CampaignRounded} align="center" />
      )}
      {isInternalUser && (
        <TableColumn type="icon" sortable id="isActive" iconComponent={CheckCircleRounded} align="center" />
      )}
      {isInternalUser && (
        <TableColumn type="icon" tooltip id="notes" align="center" iconComponent={ModeCommentRounded} />
      )}
      {isMemberUser && (
        <TableColumn
          type="icon"
          sortable
          id="isMyLocation"
          iconProps={(item: MemberEntity) => ({
            htmlColor: user?.memberId == item.id ? palette.primary.main : palette.grey[500],
          })}
          iconComponent={LocationOnRounded}
        />
      )}
      {(isMemberUser || isInternalUser) && (
        <TableColumn
          type="icon"
          id="isPending"
          tooltip
          iconProps={(item: MemberEntity) => ({
            htmlColor: palette.primary.deep,
            visibility:
              item.isMyLocation ||
              isInternalUser ||
              hasPermissions([PermissionKey.MemberEditContacts, PermissionKey.MemberEditMembers], true)
                ? 'visible'
                : 'hidden',
          })}
          iconComponent={SyncProblemRounded}
          render={(item: MemberEntity) => (item.isPending ? t('common:changeRequest.pending') : '')}
        />
      )}
    </Table>
  );
};
