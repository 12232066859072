import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';
import { ControlledInput } from '../components/Form';
import { LayoutPublic } from '../components/Layout';
import { useInternationalization, usePageTitle } from '../hooks';
import useAuth from '../hooks/useAuth';
import { routes } from '../routes';
import { palette } from '../styles/palette';
import { ApiErrorCodes, ApiException, Authentication, Styles } from '../types';

const style: Styles = {
  button: {
    width: '100%',
  },
  forgotPassword: {
    fontSize: '16px',
    fontWeight: 600,
    color: palette.primary.deep,
  },
  termsAndConditions: {
    fontSize: '14px',
  },
  termsAndConditionsLink: {
    fontWeight: 600,
    color: palette.primary.deep,
  },
  disclaimer: {
    maxWidth: {
      xs: '100%',
      md: '65%',
      xl: '45%',
    },
    p: 3,
    textAlign: 'center',
    color: palette.grey[500],
  },
};

export function Login() {
  const { t } = useInternationalization();
  usePageTitle('auth:login.title');
  const { login } = useAuth();
  const [errorKey, setErrorKey] = useState('');

  const validationLogin = Yup.object({
    username: Yup.string().required(t('common:error.fieldRequired', { name: t('auth:login.username') })),
    password: Yup.string().required(t('common:error.fieldRequired', { name: t('auth:login.password') })),
  });

  const { handleSubmit, control, formState, resetField } = useForm<Authentication>({
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(validationLogin),
  });

  const onLoginClick = async (auth: Authentication) => {
    try {
      setErrorKey('');
      await login(auth);
    } catch (error) {
      const errorCode = (error as ApiException).code;
      if (errorCode === ApiErrorCodes.MEMBERS_INACTIVE_OR_ARCHIVED) {
        setErrorKey('auth:login.membersInactiveOrArchived');
      } else if (errorCode === ApiErrorCodes.USER_UNVERIFIED) {
        setErrorKey('auth:login.unverified');
      } else {
        setErrorKey('auth:login.invalid');
      }
      resetField('password');
    }
  };

  return (
    <LayoutPublic
      title={t('auth:login.title')}
      footer={<Typography sx={style.disclaimer}>{t('auth:disclaimer')}</Typography>}
    >
      <form id="login-form" onSubmit={handleSubmit(onLoginClick)}>
        <Stack spacing={2}>
          <ControlledInput label={t('auth:login.username')} name="username" control={control} />
          <ControlledInput label={t('auth:login.password')} type="password" name="password" control={control} />
          <Link href={routes.ForgotPassword.path} sx={style.forgotPassword}>
            {t('auth:login.forgotPassword')}
          </Link>
          <Typography variant="body1" sx={style.termsAndConditions}>
            <Trans i18nKey="auth:login.acceptTermsAndConditions">
              <Link href={routes.TermsAndConditions.path} sx={style.termsAndConditionsLink} />
            </Trans>
          </Typography>
          <LoadingButton sx={style.button} loading={formState.isSubmitting} type="submit" variant="contained">
            {t('auth:login.action')}
          </LoadingButton>
          {!!errorKey && <Typography variant="inputError">{t(errorKey)}</Typography>}
        </Stack>
      </form>
    </LayoutPublic>
  );
}
