import { FilterPeriod } from '../types';
import { EditableEntity } from './EditableEntity';

export class PeriodEntity extends EditableEntity {
  effectiveDate: Date | null;
  expiryDate: Date | null;
  period: FilterPeriod;

  constructor(entity?: PeriodEntity) {
    super(entity);
    if (entity) {
      this.effectiveDate = entity.effectiveDate;
      this.expiryDate = entity.expiryDate;
    } else {
      this.effectiveDate = new Date();
      this.expiryDate = new Date();
    }
  }
}
