import { boolean, date, number, object, ref, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH } from '../../constants';
import { CommitmentEventEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema } from '../../types';
import { isDateValid } from '../../utils/helper';

export const CommitmentEventSchema = (): EntityFormSchema<CommitmentEventEntity> => ({
  schema: object().shape(
    {
      year: number().required().min(1900),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .required()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      isVisibleToVendor: boolean().notRequired(),
      notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {
    [ApiErrorCodes.COMMITMENT_EVENT_YEAR_NOT_UNIQUE]: { key: 'year', message: 'common:error.fieldUnique' },
  },
});
