export const Timbermart = () => (
  <svg width="115" height="14" viewBox="0 0 115 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.95179 13.6181H0.667022C0.298324 13.6181 0 13.3213 0 12.9526V5.56033C0 5.38439 0.0703734 5.21611 0.194293 5.09066L4.8864 0.363369C5.01032 0.237919 5.1786 0.166016 5.3576 0.166016C5.53506 0.166016 5.70335 0.23639 5.82879 0.360309L10.4215 4.94226C10.5469 5.06771 10.6158 5.23753 10.6158 5.41499V12.9526C10.6158 13.3213 10.3174 13.6181 9.95026 13.6181"
      fill="#AB1230"
    />
    <path
      d="M15.2606 4.4305L11.0136 0.194293C10.8882 0.068844 10.7184 0 10.5424 0H10.5409C10.365 0 10.1952 0.0703739 10.0697 0.197353L8.61328 1.62319L9.57098 2.59924L9.58016 2.59007L11.5889 4.60184C11.7143 4.72729 11.7847 4.89711 11.7847 5.12506V12.2879V12.5832V12.6413V12.652L11.7878 13.2043V13.6189H14.7894C15.1565 13.6189 15.4548 13.3221 15.4548 12.9534V4.90323C15.4548 4.72576 15.3845 4.55595 15.2606 4.43203"
      fill="#AB1230"
    />
    <path
      d="M20.0854 4.4305L15.8385 0.194293C15.713 0.068844 15.5448 0 15.3688 0H15.3658C15.1898 0 15.02 0.0703739 14.8946 0.197353L13.6875 1.37994L14.509 2.19536L14.7018 2.38812L16.4137 4.10769C16.5392 4.23314 16.6095 4.40296 16.6095 4.63091V12.2879V12.5832V12.6413V12.652V13.2043L16.6111 13.6189H19.6127C19.9798 13.6189 20.2782 13.3221 20.2782 12.9534V4.90323C20.2782 4.72576 20.2078 4.55595 20.0823 4.43203"
      fill="#AB1230"
    />
    <path
      d="M30.4649 13.6118V5.97241L32.7433 6.07293V2.4375H24.1992V6.07293L26.4776 5.97241V13.6118H30.4649Z"
      fill="#005EB8"
    />
    <path d="M37.3741 13.6118V2.4375H33.3198V13.6118H37.3741Z" fill="#005EB8" />
    <path
      d="M42.4331 13.6118L42.986 8.3681C43.0028 8.15031 43.0028 7.71473 43.0028 7.49694H43.12C43.1368 7.96603 43.2205 8.31784 43.3378 8.71992L44.8288 13.6118H47.2078L48.8663 8.43511C48.9334 8.21732 49.0339 7.78174 49.0841 7.49694H49.1511C49.1511 7.96603 49.1846 8.21732 49.2182 8.60264L49.7207 13.6118H53.6242L51.7981 2.4375H47.6601L46.3534 7.0111C46.2193 7.48019 46.1523 7.81525 46.1356 8.30109H46.0351C46.0183 8.15031 45.9513 7.59746 45.7838 7.0111L44.477 2.4375H40.3055L38.4962 13.6118H42.4331Z"
      fill="#005EB8"
    />
    <path
      d="M54.7534 13.6118H60.4327C61.2201 13.6118 62.3593 13.5113 63.2974 12.7574C64.0681 12.1376 64.3529 11.3669 64.3529 10.4957C64.3529 9.6916 64.0848 9.08849 63.532 8.55239C63.1802 8.21732 62.8116 7.99953 62.3593 7.88226L62.376 7.78174C62.7781 7.63096 63.1299 7.36291 63.3477 7.09486C63.7665 6.57552 63.8168 5.97241 63.8168 5.55358C63.8168 4.2971 63.2304 3.62697 62.9624 3.37568C62.0745 2.53802 60.5834 2.4375 59.6285 2.4375H54.7534V13.6118ZM58.5061 5.118H59.1427C59.2934 5.118 59.662 5.13475 59.8798 5.30228C59.9803 5.38605 60.1311 5.57033 60.1311 5.88864C60.1311 6.1902 60.0138 6.37448 59.9133 6.475C59.729 6.65928 59.3772 6.7263 59.1427 6.7263H58.5061V5.118ZM58.5061 9.07173H59.3772C59.595 9.07173 59.9971 9.08848 60.2651 9.30628C60.4494 9.45705 60.5667 9.6916 60.5667 9.9764C60.5667 10.345 60.3824 10.5963 60.1814 10.7303C59.9133 10.8978 59.5615 10.9146 59.327 10.9146H58.5061V9.07173Z"
      fill="#005EB8"
    />
    <path
      d="M73.0604 13.6118V10.546H69.4753V9.47381H72.6416V6.54201H69.4753V5.50332H72.9599V2.4375H65.421V13.6118H73.0604Z"
      fill="#005EB8"
    />
    <path
      d="M78.0792 13.6118V10.0267L78.1462 9.99315C78.1964 10.1774 78.2467 10.2277 78.297 10.345L79.9053 13.6118H84.5794L82.0664 9.84237C81.9156 9.60783 81.6978 9.4403 81.4801 9.32303V9.22251C82.1334 9.08849 82.6863 8.75342 83.0213 8.3346C83.3397 7.94927 83.6412 7.32941 83.6412 6.34098C83.6412 5.01748 83.1554 4.16307 82.5858 3.59346C81.5638 2.57153 80.3409 2.4375 79.1681 2.4375H74.1254V13.6118H78.0792ZM78.0792 5.50332H78.7828C79.1179 5.50332 79.3357 5.6541 79.4697 5.80488C79.6205 5.97241 79.6875 6.2237 79.6875 6.475C79.6875 6.82681 79.5367 7.04461 79.4027 7.16188C79.1514 7.37967 78.7828 7.41317 78.5483 7.41317H78.0792V5.50332Z"
      fill="#005EB8"
    />
    <path
      d="M89.4554 13.6118V9.80887C89.4554 9.30628 89.3213 8.35135 89.2041 7.74824H89.3213C89.5391 8.21732 89.8407 8.80368 90.092 9.20576L92.873 13.6118H96.7597V2.4375H92.873V5.98916C92.873 6.81006 93.0573 7.93252 93.2248 8.63615H93.1076C92.739 7.76499 92.4709 7.32941 92.0521 6.65928L89.4554 2.4375H85.5854V13.6118H89.4554Z"
      fill="#AB1230"
    />
    <path
      d="M105.97 13.6118V10.546H102.385V9.47381H105.551V6.54201H102.385V5.50332H105.87V2.4375H98.3309V13.6118H105.97Z"
      fill="#AB1230"
    />
    <path
      d="M112.721 13.6118V5.97241L115 6.07293V2.4375H106.455V6.07293L108.734 5.97241V13.6118H112.721Z"
      fill="#AB1230"
    />
  </svg>
);
