import { useTranslation } from 'react-i18next';
import { categorization as categorizationApi } from '../../api';
import { CategorizationContext } from '../../contexts';
import { useApi, usePageTitle } from '../../hooks';
import { CategorizationEntity } from '../../models';
import { routes } from '../../routes';
import {
  CategorizationType,
  PermissionKey,
  PermissionProps,
  TranslationContext,
  WithoutLanguageSuffix,
} from '../../types';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';
import { CategorizationHeader } from './CategorizationHeader';
import { CategorizationListView } from './CategorizationListView';
import { RequirePermission } from '../Permission';

interface CategorizationLayoutProps {
  type: CategorizationType;
  translationContext: TranslationContext;
  omitFields?: WithoutLanguageSuffix<keyof CategorizationEntity>[];
  permissions?: PermissionProps;
}

export const CategorizationLayout = ({
  type,
  translationContext,
  omitFields,
  permissions,
}: CategorizationLayoutProps) => {
  const { t } = useTranslation();
  const { refresh, isLoading, data, setData } = useApi(categorizationApi.getAll, null, type);
  usePageTitle(`categorization:${type}.title`);

  const tabs = [
    <LinkTab label={t('categorization:sections.region')} value={routes.Admin.Region.path} />,
    <LinkTab label={t('categorization:sections.membershipType')} value={routes.Admin.MembershipType.path} />,
    <LinkTab label={t('categorization:sections.vendorDesignation')} value={routes.Admin.VendorDesignation.path} />,
    <LinkTab label={t('categorization:sections.rebateType')} value={routes.Admin.RebateType.path} />,
    <LinkTab label={t('categorization:sections.productCategory')} value={routes.Admin.ProductCategory.path} />,
    <LinkTab
      label={t('categorization:sections.board')}
      value={routes.Admin.Board.path}
      permissions={{ keys: PermissionKey.AdminManageContacts }}
    />,
    <LinkTab
      label={t('categorization:sections.department')}
      value={routes.Admin.Department.path}
      permissions={{ keys: PermissionKey.AdminManageContacts }}
    />,
  ];

  return (
    <RequirePermission keys={[PermissionKey.AdminViewCategorization]}>
      <CategorizationContext.Provider
        value={{
          type,
          data,
          setData,
          isLoading,
          fetchData: refresh,
          translationContext,
          omitFields,
          isFieldVisible: (fieldName) => !omitFields?.includes(fieldName),
        }}
      >
        <LayoutPage title={t('categorization:title')} tabs={tabs} permissions={permissions}>
          <CategorizationHeader />
          <CategorizationListView />
        </LayoutPage>
      </CategorizationContext.Provider>
    </RequirePermission>
  );
};
