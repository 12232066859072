import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { cms as cmsApi } from '../../../api';
import { YOUTUBE_THUMBNAIL_URL, YOUTUBE_THUMBNAIL_URL_DEFAULT_PATH, YOUTUBE_URL_REGEX } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { AdEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { AdType, LanguageCode, Styles } from '../../../types';
import { toExternalLink } from '../../../utils/helper';
import { CmsAdViewVideo } from './CmsAdViewVideo';

const styles: Styles = {
  container: {
    '.carousel .slide': {
      margin: 'auto',
    },
    '.carousel .slide img': {
      width: 'auto',
      maxWidth: '100%',
    },
    '.slider-wrapper': {
      background: palette.grey[600],
    },
  },
  selectedThumb: {
    border: `2px solid ${palette.primary.deep}`,
    maxHeight: '32px',
  },
  thumb: {
    cursor: 'pointer',
    '> iframe': {
      pointerEvents: 'none',
    },
  },
  navigationButton: {
    padding: 1,
    minWidth: 'inherit',
  },
};

interface AdElementType {
  type: AdType;
  element: JSX.Element;
  src: string;
}

interface CmsAdViewProps {
  ads: AdEntity[];
  isLoading?: boolean;
  emptyPlaceholder?: JSX.Element;
}

export const CmsAdView = ({ ads, isLoading, emptyPlaceholder }: CmsAdViewProps) => {
  const { currentLanguage } = useInternationalization();
  const { call } = useApi(cmsApi.getAdFile, null);
  const [selectedItem, setSeletedItem] = useState(0);
  const [elements, setElements] = useState<AdElementType[] | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isAutoPlayDisabled, setIsAutoPlayDisabled] = useState(false);

  const customRenderThumb = (url: string) => {
    const youTubeVideoId = url.match(YOUTUBE_URL_REGEX)?.[1];
    return (
      <img
        src={`${YOUTUBE_THUMBNAIL_URL}${youTubeVideoId}${YOUTUBE_THUMBNAIL_URL_DEFAULT_PATH}`}
        height={32}
        width={65}
      />
    );
  };

  useEffect(() => {
    const getElement = async () => {
      const result: AdElementType[] = [];
      if (ads) {
        for await (const item of ads) {
          const file = currentLanguage === LanguageCode.fr ? item.file_Fr : item.file_En;
          const videoUrl = currentLanguage === LanguageCode.fr ? item.videoUrl_Fr : item.videoUrl_En;
          const destinationUrl = currentLanguage === LanguageCode.fr ? item.destinationUrl_Fr : item.destinationUrl_En;

          switch (item.type) {
            case AdType.Image:
              if (file) {
                const image = await call(item.id, currentLanguage);
                if (image) {
                  result.push({
                    type: item.type,
                    src: URL.createObjectURL(image),
                    element: destinationUrl ? (
                      <Link href={toExternalLink(destinationUrl)} target="_blank" display="inline-block" key={item.id}>
                        <img src={URL.createObjectURL(image)} />
                      </Link>
                    ) : (
                      <img src={URL.createObjectURL(image)} key={item.id} />
                    ),
                  });
                }
              }
              break;
            case AdType.Video:
              if (videoUrl) {
                result.push({
                  type: item.type,
                  src: videoUrl,
                  element: (
                    <CmsAdViewVideo
                      key={item.id}
                      videoUrl={videoUrl}
                      onThumbnailClick={onAdVideoThumbnailClick}
                      isVideoPlaying={isVideoPlaying}
                    />
                  ),
                });
              }
              break;

            default:
              break;
          }
        }
        setElements(result);
      }
    };
    getElement();
  }, [call, currentLanguage, ads, isVideoPlaying]);

  const onAdVideoThumbnailClick = () => {
    setIsVideoPlaying(true);
    setIsAutoPlayDisabled(true);
  };

  const resetCarousel = () => {
    setIsVideoPlaying(false);
    setIsAutoPlayDisabled(false);
  };

  const onThumbClick = (index: number) => {
    setSeletedItem(index);
  };

  const onPrevClick = () => {
    if (elements) {
      const index = selectedItem <= 0 ? elements.length - 1 : selectedItem - 1;
      setSeletedItem(index);
    }
  };

  const onNextClick = () => {
    if (elements) {
      const index = selectedItem + 1 >= elements.length ? 0 : selectedItem + 1;
      setSeletedItem(index);
    }
  };

  useEffect(() => {
    // When items change, set index to last available item if overflowed
    if (elements && selectedItem >= elements.length && selectedItem !== 0) {
      setSeletedItem(Math.max(elements.length - 1, 0));
    }
  }, [elements, selectedItem]);

  useEffect(() => resetCarousel(), [selectedItem]);

  if (isLoading || !elements) {
    return <CircularProgress />;
  }

  if (emptyPlaceholder && !elements.length) {
    return emptyPlaceholder;
  }

  return (
    <Box sx={styles.container}>
      <Carousel
        showArrows={false}
        showIndicators={false}
        autoPlay={!isAutoPlayDisabled}
        interval={20000}
        showThumbs={false}
        showStatus={false}
        selectedItem={selectedItem}
        onChange={(index) => setSeletedItem(index)}
        infiniteLoop
      >
        {elements.map((x) => x.element)}
      </Carousel>
      {!!elements.length && (
        <Grid container display="flex" justifyContent="flex-end" mt={1}>
          {elements.length > 1 && (
            <Grid item xs={3}>
              <Button sx={styles.navigationButton} size="small" variant="text" onClick={onPrevClick}>
                <ChevronLeftRounded htmlColor={palette.primary.deep} />
              </Button>
              <Button sx={styles.navigationButton} size="small" variant="text" onClick={onNextClick}>
                <ChevronRightRounded htmlColor={palette.primary.deep} />
              </Button>
            </Grid>
          )}
          <Grid item display="flex" xs={9} justifyContent="flex-end" flexWrap="wrap">
            {elements.map((x, index) => (
              <Box
                key={index}
                onClick={() => onThumbClick(index)}
                ml={2}
                sx={[styles.thumb, ...(index === selectedItem ? [styles.selectedThumb] : [])]}
              >
                {x.type === AdType.Image && <img src={x.src} height={32} />}
                {x.type === AdType.Video && customRenderThumb(x.src)}
              </Box>
            ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
