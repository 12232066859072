import { number } from 'yup';
import { VendorBuyingAgreementGeneralConditionEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { VendorGeneralConditionSchema } from './VendorGeneralConditionSchema';

export const VendorBuyingAgreementGeneralConditionSchema =
  (): EntityFormSchema<VendorBuyingAgreementGeneralConditionEntity> => ({
    schema: VendorGeneralConditionSchema().schema.shape({
      vendorBuyingAgreementId: number().nullable().notRequired(),
    }),
    errorCodeMap: {},
  });
