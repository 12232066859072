import { array, number, string } from 'yup';
import { VendorBuyingAgreementBuyOpportunityEntity } from '../../models/Vendor/VendorBuyingAgreementBuyOpportunityEntity';
import { EntityFormSchema, OpportunityType } from '../../types';
import { VendorBuyOpportunitySchema } from './VendorBuyOpportunitySchema';

export const VendorBuyingAgreementBuyOpportunitySchema =
  (): EntityFormSchema<VendorBuyingAgreementBuyOpportunityEntity> => ({
    schema: VendorBuyOpportunitySchema().schema.shape({
      vendorBuyingAgreementId: number().nullable().notRequired(),
      opportunityType: string().required().oneOf([OpportunityType.SpecialBuy, OpportunityType.Booking]),
      participatingDCs: array().nullable().notRequired(),
    }),
    errorCodeMap: {},
  });
