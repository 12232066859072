import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { enCA, frCA } from 'date-fns/locale';
import { forestProduct as forestProductApi } from '../../../api';
import { DOLLAR_MAX_DECIMALS } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { ForestProductPriceUpdateEntity, ForestProductPriceUpdateHistoryEntity } from '../../../models';
import { LanguageCode, RebateTypeAndUnitType, Styles } from '../../../types';
import { formatMonth, formatShortDate } from '../../../utils/helper';
import { palette } from '../../../styles/palette';
import { numberFormatter } from '../../../utils/formatters';
import { Table, TableColumn } from '../../Table';
import { Loading } from '../../Loading';

const styles: Styles = {
  chartContainer: {
    height: 235,
  },
};

interface ForestProductPriceHistoryChartProps {
  priceUpdate: ForestProductPriceUpdateEntity;
  forestProductId: number;
  forestProductTradingCityId: number;
}

export const ForestProductPriceHistoryChart = ({
  priceUpdate,
  forestProductId,
  forestProductTradingCityId,
}: ForestProductPriceHistoryChartProps) => {
  const { t, currentLanguage } = useInternationalization();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { data, isLoading } = useApi(
    forestProductApi.getForestProductPriceUpdateHistory,
    null,
    forestProductId,
    forestProductTradingCityId,
  );

  const currentYear = useMemo(() => priceUpdate.date?.getFullYear(), [priceUpdate]);
  const previousYear = useMemo(() => priceUpdate.date?.getFullYear() - 1, [priceUpdate]);
  const datasets = useMemo(
    () => [
      {
        label: currentYear?.toString(),
        data: data?.map((item) => ({ x: item.date, y: item.price })),
        yAxisID: 'y',
        xAxisID: 'current',
        backgroundColor: palette.primary.main,
        borderColor: palette.primary.main,
        fill: false,
      },
      {
        label: previousYear?.toString(),
        data: data?.map((item) => ({ x: item.date, y: item.price })),
        yAxisID: 'y',
        xAxisID: 'previous',
        backgroundColor: palette.secondary.lightest + 'CC',
        borderColor: palette.secondary.lightest,
        fill: 'origin',
      },
    ],
    [data, currentYear, previousYear],
  );

  const moneyFormatter = useMemo(
    () =>
      numberFormatter(
        currentLanguage,
        t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Dollar}`),
        DOLLAR_MAX_DECIMALS,
        currentLanguage !== LanguageCode.en,
        true,
      ),
    [currentLanguage, t],
  );

  return isDesktop ? (
    <Box sx={styles.chartContainer}>
      {isLoading ? (
        <Loading />
      ) : (
        <Line
          data={{
            datasets,
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            plugins: {
              legend: {
                position: 'bottom',
                onClick: (e) => e.native?.stopPropagation(),
                labels: {
                  generateLabels: (chart) => {
                    const datasets = chart.data.datasets;
                    const options = chart.legend?.options;
                    if (options) {
                      const {
                        labels: { pointStyle, textAlign, color },
                      } = options;

                      return chart.getSortedVisibleDatasetMetas().map((meta) => {
                        const style = meta.controller.getStyle(0, false);

                        const isPrevious = datasets[meta.index].label === previousYear?.toString();

                        return {
                          text: isPrevious
                            ? t('forestProduct:priceHistory.previousYear')
                            : t('forestProduct:priceHistory.currentYear'),
                          borderRadius: 0,
                          datasetIndex: meta.index,
                          fillStyle: style.backgroundColor,
                          fontColor: color,
                          hidden: !meta.visible,
                          lineCap: style.borderCapStyle,
                          lineDash: style.borderDash,
                          lineDashOffset: style.borderDashOffset,
                          lineJoin: style.borderJoinStyle,
                          lineWidth: isPrevious ? 0 : 8,
                          pointStyle: pointStyle || style.pointStyle,
                          rotation: style.rotation,
                          strokeStyle: isPrevious ? style.borderColor : palette.white,
                          textAlign: textAlign || style.textAlign,
                        };
                      }, this);
                    } else {
                      return [];
                    }
                  },
                },
              },
              tooltip: {
                mode: 'nearest',
                intersect: false,
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += currentLanguage === LanguageCode.fr ? ' : ' : ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += moneyFormatter(context.parsed.y).formatted.join('');
                    }
                    return label;
                  },
                },
              },
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                  callback: (value) => moneyFormatter(value).formatted.join(''),
                },
              },
              current: {
                min: `${currentYear}-01-01`,
                max: `${currentYear}-12-31`,
                type: 'time',
                adapters: { date: { locale: currentLanguage === LanguageCode.fr ? frCA : enCA } },
                time: {
                  unit: 'month',
                  displayFormats: {
                    month: 'MMM',
                  },
                  tooltipFormat: 'yyyy-MM-dd',
                },
                ticks: {
                  callback: (value) => formatMonth(new Date(value)),
                },
              },
              previous: {
                min: `${previousYear}-01-01`,
                max: `${previousYear}-12-31`,
                display: false,
                type: 'time',
                adapters: { date: { locale: currentLanguage === LanguageCode.fr ? frCA : enCA } },
                time: {
                  unit: 'month',
                  tooltipFormat: 'yyyy-MM-dd',
                },
              },
            },
          }}
        />
      )}
    </Box>
  ) : (
    <Table
      data={data?.filter((item) => item.price !== null) ?? []}
      translationNamespace={'forestProduct:priceHistory'}
      isLoading={isLoading}
    >
      <TableColumn
        type="custom"
        id="date"
        render={(item: ForestProductPriceUpdateHistoryEntity) => formatShortDate(item.date)}
      />
      <TableColumn
        type="custom"
        id="price"
        align="right"
        render={(item: ForestProductPriceUpdateHistoryEntity) => moneyFormatter(item.price).formatted.join('')}
      />
    </Table>
  );
};
