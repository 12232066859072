import { WarningRounded } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Loading } from '../..';
import { DOLLAR_MAX_DECIMALS, PERCENT_MAX_DECIMALS } from '../../../constants';
import { useAuth, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryRebateSummaryEntity, VendorRebateCategoryRebateSummaryGroupEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { LanguageCode, PermissionKey, RebateTypeAndUnitType, Styles } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { StaticField } from '../../Form';

const style: Styles = {
  staticField: {
    flex: undefined,
  },
  infoIcon: {
    fontSize: 15,
  },
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
};

interface VendorRebateCategoryRebateSummaryProps {
  summary: VendorRebateCategoryRebateSummaryEntity | null;
  foid?: VendorRebateCategoryRebateSummaryGroupEntity | null;
}

export const VendorRebateCategoryRebateSummary = (props: VendorRebateCategoryRebateSummaryProps) => {
  const { summary } = props;
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { isMemberUser, hasPermissions } = useAuth();

  const foid = props.foid ?? summary?.foid;

  if (!summary && !foid) {
    return <Loading />;
  }

  const renderGroupSummary = (group: VendorRebateCategoryRebateSummaryGroupEntity, forTooltip?: boolean) => {
    let hasAdditionalLongTextNote = false;
    const elements = group.details?.map((detail) => {
      let displayedValue: string | null = null;
      if (detail.value) {
        const formatter = numberFormatter(
          currentLanguage,
          detail.unit !== RebateTypeAndUnitType.Custom
            ? t(`common:rebateTypeAndUnitType.${detail.unit}`)
            : getTranslation(detail, 'customUnit'),
          detail.unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
          detail.unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
        );
        displayedValue = formatter(detail.value).formatted.join('');
      } else {
        const textValue = getTranslation(detail, 'textValue');
        if (textValue) {
          if (textValue.length > 3 && !forTooltip) {
            hasAdditionalLongTextNote = true;
          } else {
            displayedValue = textValue;
          }
        }
      }
      const rebateTypeAbbreviation = getTranslation(detail, 'rebateTypeAbbreviation');
      if (rebateTypeAbbreviation && displayedValue) {
        displayedValue += ` ${rebateTypeAbbreviation}`;
      }
      return displayedValue;
    });
    if (hasAdditionalLongTextNote) {
      elements.push('*');
    }
    return elements.filter((e) => e != null).join(' + ');
  };

  const renderPercentage = (value: number | undefined | null) => {
    if (!value) {
      return '';
    }
    const formatter = numberFormatter(
      currentLanguage,
      t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Percent}`),
      PERCENT_MAX_DECIMALS,
      true,
    );
    return formatter(value).formatted.join('');
  };

  return (
    <Stack
      display="inline-flex"
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="flex-start"
      alignItems="top"
      spacing={2}
      sx={style.stack}
    >
      {foid && (isMemberUser ? hasPermissions(PermissionKey.VendorViewRebateFOID) : true) && (
        <StaticField
          sx={style.staticField}
          label={getTranslation(foid, 'title')}
          value={renderGroupSummary(foid)}
          icon={
            <Tooltip
              title={
                <Typography variant="tooltip" whiteSpace="pre-wrap">{`${getTranslation(
                  foid,
                  'description',
                )}\n${renderGroupSummary(foid, true)}`}</Typography>
              }
              placement="top"
              arrow
            >
              <InfoOutlinedIcon sx={style.infoIcon} htmlColor={palette.grey[500]} />
            </Tooltip>
          }
        />
      )}

      {((!isMemberUser && summary) ||
        (isMemberUser
          ? hasPermissions(
              [
                PermissionKey.VendorViewRebateMP,
                PermissionKey.VendorViewRebateQP,
                PermissionKey.VendorViewRebateSP,
                PermissionKey.VendorViewRebateAP,
              ],
              true,
            )
          : hasPermissions(PermissionKey.VendorViewRebates))) &&
        summary?.groups?.map((group) => (
          <StaticField
            sx={style.staticField}
            key={group.receivableFrequencyId}
            label={getTranslation(group, 'title')}
            value={renderGroupSummary(group)}
            icon={
              <Tooltip
                title={
                  <Typography variant="tooltip" whiteSpace="pre-wrap">{`${getTranslation(
                    group,
                    'description',
                  )}\n${renderGroupSummary(group, true)}`}</Typography>
                }
                placement="top"
                arrow
              >
                <InfoOutlinedIcon sx={style.infoIcon} htmlColor={palette.grey[500]} />
              </Tooltip>
            }
          />
        ))}
      {((!isMemberUser && summary) || hasPermissions(PermissionKey.VendorViewPrograms)) && (
        <StaticField
          sx={style.staticField}
          label={t('vendor:rebateCategory.form.completeDiscount')}
          value={renderPercentage(summary?.completeDiscount)}
          icon={
            <Tooltip title={t('vendor:rebateCategory.tooltip.completeDiscount')} placement="top" arrow>
              <InfoOutlinedIcon sx={style.infoIcon} htmlColor={palette.grey[500]} />
            </Tooltip>
          }
        />
      )}
      {((!isMemberUser && summary) || hasPermissions(PermissionKey.VendorViewPrograms)) && (
        <StaticField
          sx={style.staticField}
          label={t('vendor:rebateCategory.form.totalRebates')}
          value={renderPercentage(summary?.totalRebates)}
          icon={
            <Tooltip title={t('vendor:rebateCategory.tooltip.totalRebates')} placement="top" arrow>
              <InfoOutlinedIcon sx={style.infoIcon} htmlColor={palette.grey[500]} />
            </Tooltip>
          }
        />
      )}
      {summary?.hasNonPercentageValues && (
        <Tooltip title={t('vendor:rebateCategory.form.hasNonPercentageValuesNote')}>
          <WarningRounded color="error" />
        </Tooltip>
      )}
    </Stack>
  );
};
