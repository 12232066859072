import { PermissionState } from '../../types';
import { PermissionEntity } from './PermissionEntity';

export class AssignedPermissionEntity {
  permission!: PermissionEntity;
  state: PermissionState;
  defaultState?: PermissionState;

  constructor(entity?: AssignedPermissionEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
      this.permission = this.permission && new PermissionEntity(this.permission);
    } else {
      this.state = PermissionState.Available;
    }
  }
}
