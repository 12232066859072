import { Box, CircularProgress, Stack } from '@mui/material';
import { useContext } from 'react';
import { VendorBuyOpportunityDetailContext } from '../../../contexts/Vendor/VendorBuyOpportunityDetailContext';
import { useInternationalization } from '../../../hooks';
import { VendorBuyOpportunitySchema } from '../../../schemas';
import { Styles } from '../../../types';
import { EditCard } from '../../Card';
import { Container } from '../../Container';
import {
  TargetedMembershipCategorizationForm,
  TargetedMembershipMemberForm,
  TargetedMembershipMemberGroupForm,
} from '../../TargetedMembership';

const style: Styles = {
  loading: {
    mx: 2,
    mt: 3,
  },
  highlightContainer: {
    mb: 5,
  },
};

export const VendorBuyOpportunityPermissions = () => {
  const { t } = useInternationalization();
  const { vendorBuyOpportunity, save, fetchData, isLoading, readOnly } = useContext(VendorBuyOpportunityDetailContext);

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        {isLoading && !vendorBuyOpportunity && <CircularProgress size={20} sx={style.loading} />}
        {vendorBuyOpportunity && (
          <Box>
            <EditCard
              readOnly={readOnly}
              title={t('vendor:targetedMembership.title')}
              entity={vendorBuyOpportunity}
              disabled={vendorBuyOpportunity.isArchived}
              schema={VendorBuyOpportunitySchema()}
              onSave={save}
              onConfirm={fetchData}
            >
              <TargetedMembershipCategorizationForm />
            </EditCard>
            <EditCard
              readOnly={readOnly}
              title={t('vendor:targetedMembership.form.memberGroupTitle')}
              entity={vendorBuyOpportunity}
              disabled={vendorBuyOpportunity.isArchived}
              schema={VendorBuyOpportunitySchema()}
              onSave={save}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberGroupForm hideTitle />
            </EditCard>
            <EditCard
              readOnly={readOnly}
              title={t('vendor:targetedMembership.form.memberTitle')}
              entity={vendorBuyOpportunity}
              disabled={vendorBuyOpportunity.isArchived}
              schema={VendorBuyOpportunitySchema()}
              onSave={save}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberForm hideTitle />
            </EditCard>
          </Box>
        )}
      </Stack>
    </Container>
  );
};
