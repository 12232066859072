import { Stack } from '@mui/material';
import { useContext } from 'react';
import { VendorBuyingAgreementBuyOpportunityDetailContext } from '../../../../contexts/Vendor';
import { useInternationalization } from '../../../../hooks';
import { VendorBuyingAgreementBuyOpportunitySchema } from '../../../../schemas';
import { EditCard } from '../../../Card';
import { Container } from '../../../Container';
import { VendorBuyingAgreementBuyOpportunityDetailsForm } from './VendorBuyingAgreementBuyOpportunityDetailsForm';

export const VendorBuyingAgreementBuyOpportunityDetails = () => {
  const { t } = useInternationalization();
  const { buyOpportunity, save, fetchData, readOnly } = useContext(VendorBuyingAgreementBuyOpportunityDetailContext);

  return (
    <Container isHighlighted>
      <Stack spacing={2}>
        <EditCard
          title={t('vendor:buyOpportunities.sections.details')}
          entity={buyOpportunity}
          readOnly={readOnly}
          schema={VendorBuyingAgreementBuyOpportunitySchema()}
          onSave={save}
          onConfirm={fetchData}
        >
          <VendorBuyingAgreementBuyOpportunityDetailsForm />
        </EditCard>
      </Stack>
    </Container>
  );
};
