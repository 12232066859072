import { object, array } from 'yup';
import { RoleEntity } from '../../models';
import { EntityFormSchema } from '../../types';

export const RoleSchema = (): EntityFormSchema<RoleEntity> => ({
  schema: object({
    assignedPermissions: array().required(),
  }),
  errorCodeMap: {},
});
