import { EditableEntity } from './EditableEntity';

export class PendingEntity extends EditableEntity {
  isPending?: boolean;

  constructor(entity?: PendingEntity) {
    super(entity);
    if (entity) {
      this.isPending = entity.isPending;
    } else {
      this.isPending = false;
    }
  }
}
