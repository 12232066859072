import { array, date, number, object, ref, string } from 'yup';
import { MAX_INTEGER, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorRebateCategoryProgramEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { isDateValid } from '../../utils/helper';

export const VendorRebateCategoryProgramSchema = (): EntityFormSchema<VendorRebateCategoryProgramEntity> => ({
  schema: object().shape(
    {
      number: number().required().max(MAX_INTEGER),
      name_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
      name_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      vendorDesignationIds: array().notRequired(),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .required()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      memberNotes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    },
    [['expiryDate', 'effectiveDate']],
  ),
});
