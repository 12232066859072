import { ContentStatus, ContentTriggers, PermissionKey } from '../../types';
import { useAuth, useConfirmationModal, useGlobalEdit, useInternationalization } from '../../hooks';
import { Button, Stack, Typography } from '@mui/material';
import { HasPermissions } from '../Permission';
import { BlockRounded, CheckRounded, ReplayRounded, SendRounded, UndoRounded } from '@mui/icons-material';
import { palette } from '../../styles/palette';
import { Trans } from 'react-i18next';

interface CmsWorkflowProps {
  status: ContentStatus;
  isLoading: boolean;
  entityName: string;
  onTrigger: (trigger: ContentTriggers) => () => void;
  editPermission: PermissionKey;
  approvePermission: PermissionKey;
  entitySuffix: string;
}

export const CmsWorkflow = ({
  status,
  isLoading,
  onTrigger,
  entityName,
  editPermission,
  approvePermission,
  entitySuffix,
}: CmsWorkflowProps) => {
  const { t } = useInternationalization();
  const { hasPermissions } = useAuth();
  const { openModal } = useConfirmationModal();

  const { globalEditing } = useGlobalEdit();

  const onApproveRejectWorkflow = (newTrigger: ContentTriggers.Approve | ContentTriggers.Reject) => () => {
    const action = t(`cms:workflow.${newTrigger.toLowerCase()}`);
    openModal({
      confirmText: t('common:action.confirmButton', { action }),
      title: t('common:action.confirmTitle', { name: entitySuffix, action, count: 1 }),
      description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name: entityName }} />,
      onConfirm: onTrigger(newTrigger),
    });
  };

  if (status == ContentStatus.Rejected || !hasPermissions([editPermission, approvePermission], true)) {
    return null;
  }

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="stretch">
      <Stack spacing={1} direction="row" alignItems="center">
        {status == ContentStatus.InProgress && (
          <>
            <HasPermissions keys={[editPermission]} any>
              <Button
                size="small"
                variant="contained"
                disabled={isLoading || globalEditing}
                onClick={onTrigger(ContentTriggers.Submit)}
                startIcon={<SendRounded />}
              >
                {t('cms:workflow.submit')}
              </Button>
            </HasPermissions>
            <HasPermissions keys={[approvePermission]}>
              <Button
                size="small"
                disabled={isLoading || globalEditing}
                variant="outlined"
                onClick={onApproveRejectWorkflow(ContentTriggers.Reject)}
                startIcon={<BlockRounded />}
              >
                {t('cms:workflow.reject')}
              </Button>
            </HasPermissions>
          </>
        )}
        {status == ContentStatus.Submitted && (
          <>
            <HasPermissions keys={[approvePermission]}>
              <Button
                size="small"
                disabled={isLoading || globalEditing}
                variant="contained"
                onClick={onApproveRejectWorkflow(ContentTriggers.Approve)}
                startIcon={<CheckRounded />}
              >
                {t('cms:workflow.approve')}
              </Button>
            </HasPermissions>
            <HasPermissions keys={[approvePermission]}>
              <Button
                size="small"
                disabled={isLoading || globalEditing}
                variant="outlined"
                onClick={onApproveRejectWorkflow(ContentTriggers.Reject)}
                startIcon={<BlockRounded />}
              >
                {t('cms:workflow.reject')}
              </Button>
            </HasPermissions>
          </>
        )}
        {status == ContentStatus.Approved && (
          <HasPermissions keys={[approvePermission]}>
            <Button
              size="small"
              variant="text"
              disabled={isLoading || globalEditing}
              onClick={onTrigger(ContentTriggers.Unpublish)}
              startIcon={<UndoRounded htmlColor={palette.primary.deep} />}
            >
              <Typography variant="inherit" color={palette.primary.deep}>
                {t('cms:workflow.unpublish')}
              </Typography>
            </Button>
          </HasPermissions>
        )}
      </Stack>
      {status == ContentStatus.Submitted && (
        <HasPermissions keys={[editPermission, approvePermission]} any={true}>
          <Button
            size="small"
            disabled={isLoading || globalEditing}
            variant="text"
            onClick={onTrigger(ContentTriggers.RequestChanges)}
            startIcon={<ReplayRounded htmlColor={palette.primary.deep} />}
          >
            <Typography variant="inherit" color={palette.primary.deep}>
              {t('cms:workflow.requestChanges')}
            </Typography>
          </Button>
        </HasPermissions>
      )}
    </Stack>
  );
};
