import { AdType, ContentStatus } from '../../types';
import { FileEntity } from '../FileEntity';
import { PeriodEntity } from '../PeriodEntity';
import { TargetedMembershipEntity } from '../TargetedMembership';

export class AdEntity extends PeriodEntity {
  status: ContentStatus;
  name: string;
  type: AdType;
  hasPriority: boolean;
  file_En?: FileEntity;
  file_Fr?: FileEntity;
  videoUrl_En?: string;
  videoUrl_Fr?: string;
  destinationUrl_En?: string;
  destinationUrl_Fr?: string;
  isActive?: boolean;
  targetedMembership?: TargetedMembershipEntity;

  constructor(entity?: AdEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.status = ContentStatus.InProgress;
      this.name = '';
      this.type = AdType.Image;
      this.hasPriority = false;
      this.videoUrl_En = '';
      this.videoUrl_Fr = '';
      this.destinationUrl_En = '';
      this.destinationUrl_Fr = '';
      this.targetedMembership = new TargetedMembershipEntity();
    }
  }
}
