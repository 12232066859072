import { Trans, useTranslation } from 'react-i18next';
import { useConfirmationModal } from '../../hooks';
import { Archivable, Entity } from '../../types';
import { MenuItem, MenuItemProps } from './MenuItem';

interface ArchiveMenuItemProps<T extends Entity & Archivable> extends MenuItemProps {
  entity: T;
  name: string;
  onArchiveConfirm: (isArchived: boolean, entity: T) => void;
  onClick: () => void;
  actionSuffix: string;
}

export const ArchiveMenuItem = <T extends Entity & Archivable>({
  entity,
  name,
  onArchiveConfirm,
  onClick,
  actionSuffix,
  ...props
}: ArchiveMenuItemProps<T>) => {
  const { openModal } = useConfirmationModal();
  const { t } = useTranslation();

  const onArchiveClick = () => {
    openModal({
      confirmText: t('common:action.confirmButton', { action: t('common:action.archive') }),
      title: t('common:action.confirmTitle', {
        name: actionSuffix,
        action: t('common:action.archive'),
        count: 1,
      }),
      description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name }} />,
      onConfirm: () => onArchiveConfirm(true, entity),
    });
    onClick && onClick();
  };

  const onUnarchiveClick = () => {
    onArchiveConfirm(false, entity);
    onClick && onClick();
  };

  if (!entity.isArchived) {
    return (
      <MenuItem {...props} id="archive" key="archive" onClick={onArchiveClick}>
        {t('common:action.archive')}
      </MenuItem>
    );
  }

  return (
    <MenuItem {...props} id="unarchive" key="unarchive" onClick={onUnarchiveClick}>
      {t('common:action.unarchive')}
    </MenuItem>
  );
};
