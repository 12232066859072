import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { Box, Chip, Grid, Link, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { categorization, categorization as categorizationApi, vendor as vendorApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { ConfidentialAgreementContext } from '../../contexts/ConfidentialAgreementContext';
import { useApi, useAuth, useInternationalization } from '../../hooks';
import { VendorEntity } from '../../models';
import { routes } from '../../routes';
import { palette } from '../../styles/palette';
import { BuyGuideFilter, BuyGuideSearchBy, OrderBy, PermissionKey, Styles } from '../../types';
import { areArrayEquals, formatShortDate } from '../../utils/helper';
import { News } from '../Dashboard/Widgets';
import { Autocomplete, MultiSelect } from '../Form';
import { LayoutPage } from '../Layout';
import { RequirePermission } from '../Permission';
import { Table, TableColumn } from '../Table';
import { BuyGuideEvents } from './BuyGuideEvents';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      md: '220px',
    },
  },
  selectLargeContainer: {
    width: '100%',
  },
  textContainer: {
    width: '100%',
  },
  favouriteIcon: {
    '&:hover': {
      color: palette.primary.deep,
    },
  },
};

const defaultFilter: BuyGuideFilter = {
  vendorName: undefined,
  productCategoryNameOrKeyword: undefined,
  designations: [],
  isFavourite: false,
  ...DEFAULT_PAGINATION_FILTER,
};

export const BuyGuideList = () => {
  const { getTranslation } = useInternationalization();
  const { confidentialAgreementButton } = useContext(ConfidentialAgreementContext);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useInternationalization();
  const designations = useApi(categorizationApi.getAll, null, 'vendorDesignation', false);
  const favouriteCall = useApi(vendorApi.updateFavourites, null);
  const [searchBy, setSearchBy] = useState(BuyGuideSearchBy.Vendor);
  const [filter, setFilter] = useState<BuyGuideFilter>(defaultFilter);
  const { data, isLoading, refresh } = useApi(vendorApi.getAll, null, filter);

  // Refresh list if chosen Member is changed
  useEffect(() => {
    refresh();
  }, [user?.memberId, refresh]);

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onDesignationBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.designations ?? [], selected)) {
      setFilter((prev) => ({ ...prev, designations: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const onSearchByClick = (value: BuyGuideSearchBy) => {
    setSearchBy(value);
    setFilter((prev) => ({ ...defaultFilter, isFavourite: prev.isFavourite }));
  };

  const onFavoriteClick = async (entity: VendorEntity) => {
    await favouriteCall.call([entity.id], !entity.isFavourite);
    refresh();
  };

  const renderVendorSearch = () => (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 2 }} mb={2}>
      <Box sx={style.selectLargeContainer}>
        <Autocomplete
          value={filter.vendorName}
          sx={style.textContainer}
          noOptionsText={t('buyGuide:search.notFound')}
          apiFunction={vendorApi.getMemberSuggestions}
          label={t('buyGuide:search.vendorName')}
          placeholder={t('buyGuide:search.vendorPlaceholder')}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option.name;
          }}
          onApplySearch={(searchText) =>
            setFilter((prev) => ({ ...prev, vendorName: searchText ?? undefined, pageNumber: 1 }))
          }
          onSelectItem={(item) =>
            item && navigate(generatePath(routes.Member.BuyGuideDetail.path, { vendorId: item.id }))
          }
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <MultiSelect
          label={t('buyGuide:search.designations')}
          options={designations.data?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
          onConfirm={onDesignationBlur}
          values={filter.designations || []}
        />
      </Box>
    </Stack>
  );

  const renderProductCategorySearch = () => (
    <Box sx={style.selectLargeContainer} mb={2}>
      <Autocomplete
        value={filter.productCategoryNameOrKeyword}
        sx={style.textContainer}
        noOptionsText={t('buyGuide:search.notFound')}
        apiFunction={categorization.getProductCategoriesSuggestions}
        label={t('buyGuide:search.productCategoryName')}
        placeholder={t('buyGuide:search.productCategoryPlaceholder')}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return getTranslation(option, 'name');
        }}
        onApplySearch={(searchText) =>
          setFilter((prev) => ({ ...prev, productCategoryNameOrKeyword: searchText ?? undefined, pageNumber: 1 }))
        }
        onSelectItem={(item) =>
          item &&
          setFilter((prev) => ({
            ...prev,
            productCategoryNameOrKeyword: getTranslation(item, 'name') ?? undefined,
            pageNumber: 1,
          }))
        }
      />
    </Box>
  );

  const renderSearch = () => (
    <>
      <Stack direction="row" spacing={1} mb={2} alignItems="center" flexWrap="wrap">
        <Box>
          <Typography variant="label">{t('common:searchBy')}</Typography>
        </Box>
        <Box>
          <Chip
            label={t('buyGuide:search.vendors')}
            onClick={() => onSearchByClick(BuyGuideSearchBy.Vendor)}
            color={searchBy === BuyGuideSearchBy.Vendor ? 'primary' : 'secondary'}
          />
        </Box>
        <Box>
          <Chip
            label={t('buyGuide:search.productCategories')}
            onClick={() => onSearchByClick(BuyGuideSearchBy.ProductCategories)}
            color={searchBy === BuyGuideSearchBy.ProductCategories ? 'primary' : 'secondary'}
          />
        </Box>
      </Stack>
      {searchBy === BuyGuideSearchBy.Vendor ? renderVendorSearch() : renderProductCategorySearch()}
      <Stack direction="row" spacing={1} mb={2} alignItems="center">
        <Box>
          <Typography variant="label">{t('common:show')}</Typography>
        </Box>
        <Box>
          <Chip
            label={t('common:filter.all', { context: 'male' })}
            onClick={() => setFilter((prev) => ({ ...prev, isFavourite: undefined }))}
            color={!filter.isFavourite ? 'primary' : 'secondary'}
          />
        </Box>
        <Box>
          <Chip
            label={t('buyGuide:search.favourites')}
            onClick={() => setFilter((prev) => ({ ...prev, isFavourite: true }))}
            color={filter.isFavourite ? 'primary' : 'secondary'}
          />
        </Box>
      </Stack>
    </>
  );

  const renderVendorLink = (id: number, text: string | undefined) =>
    !!text && (
      <Link underline="always" href={generatePath(routes.Member.BuyGuideDetail.path, { vendorId: id })}>
        {text}
      </Link>
    );

  const renderType = (entity: VendorEntity) =>
    entity.vendorDesignations.map((vd) => getTranslation(vd, 'name')).join(', ');

  return (
    <RequirePermission keys={[PermissionKey.VendorViewBuyGuide]}>
      <LayoutPage title={t('buyGuide:title')} rightTitle={confidentialAgreementButton}>
        <Grid container spacing={2}>
          <Grid item xl={9} lg={8} xs={12}>
            <Typography variant="h2" mb={2}>
              {t('buyGuide:sections.vendors')}
            </Typography>
            {renderSearch()}
            <Table
              data={data ?? []}
              translationNamespace="buyGuide"
              paginationFilter={filter}
              onPaginationChange={onPaginationChange}
              sortColumns={filter.orderBy}
              onSortChange={onSortChange}
              isLoading={isLoading}
            >
              <TableColumn
                type="button"
                id="isFavourite"
                align="center"
                iconProps={(item) => ({
                  sx: style.favouriteIcon,
                  htmlColor: item.isFavourite ? palette.primary.deep : undefined,
                })}
                onClick={(item: VendorEntity) => onFavoriteClick(item)}
                iconComponent={(isFavourite) => (isFavourite ? StarRateRoundedIcon : StarBorderRoundedIcon)}
                render={(item: VendorEntity) => !!item.isFavourite}
              />
              <TableColumn
                type="custom"
                sortable
                id="name"
                width="25%"
                render={({ id, name }: VendorEntity) => renderVendorLink(id, name)}
              />
              <TableColumn type="custom" width="55%" id="type" render={renderType} />
              <TableColumn
                type="custom"
                id="latestChange"
                sortable
                width="15%"
                render={({ latestChange }: VendorEntity) => !!latestChange && formatShortDate(latestChange)}
              />
              <TableColumn type="icon" id="cciPreferred" width="5%" iconComponent={LocalActivityIcon} />
            </Table>
          </Grid>
          <Grid item xl={3} lg={4} xs={12}>
            <BuyGuideEvents favouriteVendorsOnly={filter.isFavourite} />
            <News placement="right" isMandatoryOrFavouriteVendor />
          </Grid>
        </Grid>
      </LayoutPage>
    </RequirePermission>
  );
};
