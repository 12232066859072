import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Navigate, generatePath, useOutlet, useParams } from 'react-router-dom';
import { ArchiveBanner, EditDetails } from '../..';
import { vendor as vendorApi } from '../../../api';
import { VendorBuyOpportunityDetailContext } from '../../../contexts/Vendor/VendorBuyOpportunityDetailContext';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../hooks';
import { VendorBuyOpportunityEntity } from '../../../models';
import { routes } from '../../../routes';
import { VendorBuyOpportunitySchema } from '../../../schemas';
import { LanguageCode, PermissionKey, Styles } from '../../../types';
import { Container } from '../../Container';
import { ArchiveMenuItem } from '../../Menu';
import { RequirePermission } from '../../Permission';
import { LinkTab } from '../../Tab';
import { VendorBuyOpportunityForm } from './VendorBuyOpportunityForm';
import { VendorBuyOpportunityHeader } from './VendorBuyOpportunityHeader';
import { LayoutPage } from '../../Layout';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const VendorBuyOpportunityDetail = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { hasPermissions } = useAuth();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const outlet = useOutlet();
  const vendorId = Number(params.vendorId);
  const vendorBuyOpportunityId = Number(params.vendorBuyOpportunityId);
  const { data: vendor } = useApi(vendorApi.get, null, vendorId);
  const { data, isLoading, refresh } = useApi(vendorApi.getBuyOpportunity, null, vendorId, vendorBuyOpportunityId);
  const { call: update } = useApi(vendorApi.updateBuyOpportunity, null);
  const { call: upload } = useApi(vendorApi.uploadBuyOpportunityFiles, null);
  const { call: deleteFiles } = useApi(vendorApi.deleteBuyOpportunityFiles, null);
  usePageTitle(data ? getTranslation(data, 'name') : '');

  const saveBuyOpportunity = async (entity: VendorBuyOpportunityEntity) => {
    let savedBuyOpportunity = await update(vendorId, entity);
    if (savedBuyOpportunity && data) {
      const filesDiff = data.getFilesDiff(entity);
      if (filesDiff.removed.length) {
        savedBuyOpportunity = await deleteFiles(vendorId, vendorBuyOpportunityId, filesDiff.removed);
      }
      if (filesDiff.added.length) {
        enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
        savedBuyOpportunity = await upload(vendorId, vendorBuyOpportunityId, filesDiff.added);
      }
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    return savedBuyOpportunity;
  };

  const archiveApi = useApi(vendorApi.archiveBuyOpportunity, { successKey: 'common:success.action' });

  const onArchiveConfirm = async (isArchived: boolean, entity: VendorBuyOpportunityEntity) => {
    await archiveApi.call(vendorId, [entity.id], isArchived);
    refresh();
  };

  const renderActionMenuItems = (onClick: () => void) =>
    data
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={getTranslation(data, 'name')}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('vendor:action.suffix')}
            disabled={!vendor || vendor.isArchived}
            permissions={{ keys: PermissionKey.VendorEditBuys }}
          />,
        ]
      : [];

  const tabs = [
    <LinkTab
      label={t('vendor:buyOpportunities.sections.permissions')}
      value={generatePath(routes.Admin.Vendor.BuyOpportunity.Permissions.path, {
        vendorId,
        vendorBuyOpportunityId,
      })}
    />,
    <LinkTab
      label={t('vendor:buyOpportunities.sections.documents')}
      value={generatePath(routes.Admin.Vendor.BuyOpportunity.Documents.path, {
        vendorId,
        vendorBuyOpportunityId,
      })}
    />,
    <LinkTab
      label={t('vendor:buyOpportunities.sections.details')}
      value={generatePath(routes.Admin.Vendor.BuyOpportunity.Details.path, {
        vendorId,
        vendorBuyOpportunityId,
      })}
    />,
  ];

  if (!outlet) {
    return (
      <Navigate
        replace
        to={generatePath(routes.Admin.Vendor.BuyOpportunity.Permissions.path, { vendorId, vendorBuyOpportunityId })}
      />
    );
  }

  return (
    <RequirePermission keys={PermissionKey.VendorViewBuys}>
      <VendorBuyOpportunityDetailContext.Provider
        value={{
          vendorId,
          vendorBuyOpportunityId,
          vendor,
          vendorBuyOpportunity: data,
          save: saveBuyOpportunity,
          fetchData: refresh,
          isLoading,
          readOnly: !hasPermissions(PermissionKey.VendorEditBuys),
        }}
      >
        <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
          <ArchiveBanner entity={data} onUnarchive={() => data && onArchiveConfirm(false, data)} />
          <Container>
            {isLoading && <CircularProgress size={20} sx={style.loading} />}
            {data && (
              <EditDetails
                permissions={{ keys: PermissionKey.VendorEditBuys }}
                alwaysOpen
                titleLabel={t(`vendor:form.name`)}
                title={currentLanguage === LanguageCode.en ? 'name_En' : 'name_Fr'}
                header={<VendorBuyOpportunityHeader />}
                disabled={
                  !vendor || vendor.isArchived || data.isArchived || !hasPermissions(PermissionKey.VendorEditBuys)
                }
                entity={data}
                schema={VendorBuyOpportunitySchema()}
                onSave={saveBuyOpportunity}
                actionMenuItems={renderActionMenuItems}
                onConfirm={() => refresh()}
                breadcrumbs={[
                  { title: t('vendor:title'), href: routes.Admin.Vendor.path },
                  {
                    title: vendor?.name,
                    href: generatePath(routes.Admin.Vendor.Detail.BuyOpportunities.path, { vendorId }),
                  },
                  { title: getTranslation(data, 'name') },
                ]}
              >
                <VendorBuyOpportunityForm />
              </EditDetails>
            )}
          </Container>
        </LayoutPage>
      </VendorBuyOpportunityDetailContext.Provider>
    </RequirePermission>
  );
};
