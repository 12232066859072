import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth, useEntityFormContext } from '../../../hooks';
import { VendorBuyingAgreementEntity } from '../../../models';
import { RebateCategoryEntity } from '../../../models/Vendor/RebateCategoryEntity';
import { FilterPeriod, PermissionKey, Styles } from '../../../types';
import { PeriodForm } from '../../PeriodForm';
import { VendorBuyingAgreementSelect } from '../BuyingAgreements/VendorBuyingAgreementSelect';
import { VendorRebateCategoryHeader } from './VendorRebateCategoryHeader';
import { VendorRebateCategoryDuplicateTierGrowth } from './VendorRebateCategoryDuplicateTierGrowth';
import { TargetedMembershipMemberForm, TargetedMembershipMemberGroupForm } from '../../TargetedMembership';
const style: Styles = {
  container: {
    pb: 4,
    py: 3,
    '&>*': {
      mb: 2,
    },
  },
};

interface VendorRebateCategoryDuplicateFormProps {
  showVendorBuyingAgreementSelect?: boolean;
  rebateCategoryId: number;
}

export const VendorRebateCategoryDuplicateForm = ({
  showVendorBuyingAgreementSelect,
  rebateCategoryId,
}: VendorRebateCategoryDuplicateFormProps) => {
  const { user, hasPermissions } = useAuth();
  const params = useParams();
  const vendorId = Number(user?.profile.vendor?.id ?? params.vendorId);
  const { watch, setValue, setIgnoreDirtySubmit } = useEntityFormContext<RebateCategoryEntity>();
  const vendorBuyingAgreementId = watch('vendorBuyingAgreementId');

  useEffect(() => {
    setIgnoreDirtySubmit(true);
  }, [setIgnoreDirtySubmit]);

  const onVendorBuyingAgreementChange = (value?: VendorBuyingAgreementEntity) => {
    if (value) {
      setValue('effectiveDate', value.effectiveDate);
      setValue('expiryDate', value.expiryDate);
    }
  };

  const filterVendorBuyingAgreement = (v: VendorBuyingAgreementEntity) =>
    v.id == vendorBuyingAgreementId || v.period == FilterPeriod.Current || v.period == FilterPeriod.Upcoming;

  return (
    <>
      <VendorRebateCategoryHeader />
      {showVendorBuyingAgreementSelect && (
        <Box sx={style.container}>
          <Grid container spacing={3}>
            <VendorBuyingAgreementSelect
              filter={filterVendorBuyingAgreement}
              onChange={onVendorBuyingAgreementChange}
              vendorId={vendorId}
              xs={12}
            />
            {vendorBuyingAgreementId && <PeriodForm readOnly xs={6} />}
          </Grid>
          {hasPermissions(PermissionKey.VendorViewRebates) && (
            <VendorRebateCategoryDuplicateTierGrowth rebateCategoryId={rebateCategoryId} vendorId={vendorId} />
          )}
          {hasPermissions(PermissionKey.MemberViewMembers) && (
            <>
              <TargetedMembershipMemberGroupForm fullWidth />
              <TargetedMembershipMemberForm fullWidth />
            </>
          )}
        </Box>
      )}
    </>
  );
};
