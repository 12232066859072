import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { RequireTemporaryAuth, RequireUnauth } from '../components';
import { ForgotPassword, Login, NotFound, ResetPassword, Root, SetPassword, TermsAndConditions } from '../pages';
import { UserType } from '../types';
import { AdminRoutes } from './admin';
import { MemberRoutes } from './member';
import { VendorRoutes } from './vendor';
import { SetVerificationPhoneNumber } from '../pages/SetVerificationPhoneNumber';
import { VerifyCode } from '../pages/VerifyCode';
import { VerifyPhoneNumber } from '../pages/VerifyPhoneNumber';
import { RequireVerificationPhoneNumberRequired } from '../components/Routes/RequireVerificationPhoneNumberRequired';

export const routes = {
  Root: {
    path: '/',
    element: <Root />,
  },
  Login: {
    path: '/login',
    element: (
      <RequireUnauth>
        <Login />
      </RequireUnauth>
    ),
  },
  TermsAndConditions: {
    path: '/terms',
    element: (
      <RequireUnauth>
        <TermsAndConditions />
      </RequireUnauth>
    ),
  },
  SetPassword: {
    path: '/set-password',
    element: (
      <RequireTemporaryAuth>
        <SetPassword />
      </RequireTemporaryAuth>
    ),
  },
  VerifyCode: {
    path: '/verify-code',
    element: (
      <RequireTemporaryAuth>
        <VerifyCode />
      </RequireTemporaryAuth>
    ),
  },
  SetVerificationPhoneNumber: {
    path: '/set-verification-phone-number',
    element: (
      <RequireVerificationPhoneNumberRequired>
        <SetVerificationPhoneNumber />
      </RequireVerificationPhoneNumberRequired>
    ),
  },
  VerifyPhoneNumber: {
    path: '/verify-phone-number',
    element: (
      <RequireVerificationPhoneNumberRequired>
        <VerifyPhoneNumber />
      </RequireVerificationPhoneNumberRequired>
    ),
  },
  ResetPassword: {
    path: '/reset-password',
    element: (
      <RequireUnauth>
        <ResetPassword />
      </RequireUnauth>
    ),
  },
  ForgotPassword: {
    path: '/forgot-password',
    element: (
      <RequireUnauth>
        <ForgotPassword />
      </RequireUnauth>
    ),
  },
  Admin: AdminRoutes,
  Member: MemberRoutes,
  Vendor: VendorRoutes,
  NotFound: {
    path: '*',
    element: <NotFound />,
  },
};

export interface RoutePath {
  path: string;
}

export const getDashboardPath = (userType: UserType) => {
  switch (userType) {
    case UserType.Internal:
      return routes.Admin.Dashboard.path;
    case UserType.Member:
      return routes.Member.Dashboard.path;
    case UserType.Vendor:
      return routes.Vendor.Dashboard.path;
  }
};

const reactRouterRoutes: RouteObject[] = [];

const addReactRouterRoute = (route: Partial<RouteObject>, parentRoute?: Partial<RouteObject>) => {
  const children: RouteObject[] = [];
  const relativePath = route.path;
  route.path = parentRoute ? `${parentRoute.path}/${route.path}` : route.path;

  Object.entries(route).forEach(([subkey, subvalue]) => {
    if (subkey !== 'path' && subkey !== 'element' && subkey !== 'errorElement') {
      const child = addReactRouterRoute(subvalue, route);
      children.push(child);
    }
  });

  return {
    path: relativePath,
    element: route.element,
    children,
    errorElement: parentRoute?.errorElement ?? route.errorElement,
  };
};

Object.values(routes).forEach((value) => {
  const route = addReactRouterRoute(value);
  reactRouterRoutes.push(route);
});

export const router = createBrowserRouter(reactRouterRoutes);
