import {
  ArchiveRounded,
  CheckCircleRounded,
  LocalActivityRounded,
  ModeCommentRounded,
  UnarchiveRounded,
  UnpublishedRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from '@mui/icons-material';
import { Link, MenuItem } from '@mui/material';
import { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { vendor as vendorApi } from '../../api';
import { VendorContext } from '../../contexts';
import { useApi, useAuth, useInternationalization } from '../../hooks';
import { VendorEntity } from '../../models';
import { routes } from '../../routes';
import { OrderBy, PermissionKey, Styles } from '../../types';
import { LimitCommaValues } from '../LimitCommaValues';
import { ArchiveMenuItem } from '../Menu';
import { Table, TableColumn } from '../Table';

const style: Styles = {
  largeMenuItem: {
    width: 250,
  },
};

export const VendorListView = () => {
  const { t, getTranslation } = useInternationalization();
  const { hasPermissions } = useAuth();
  const navigate = useNavigate();
  const { data, isLoading, filter, setFilter, fetchData } = useContext(VendorContext);
  const archiveApi = useApi(vendorApi.archive, { successKey: 'common:success.action' });
  const visibleApi = useApi(vendorApi.visible, { successKey: 'common:success.action' });
  const activeApi = useApi(vendorApi.active, { successKey: 'common:success.action' });

  const bulkActions = hasPermissions(PermissionKey.VendorEditInfo)
    ? [
        {
          icon: <CheckCircleRounded />,
          label: t('common:action.markActive'),
          onConfirm: (items: VendorEntity[]) => onActive(true, items),
        },
        {
          icon: <UnpublishedRounded />,
          label: t('common:action.markInactive'),
          onConfirm: (items: VendorEntity[]) => onActive(false, items),
        },
        {
          icon: <VisibilityRounded />,
          label: t('vendor:action.markVisible'),
          onConfirm: async (items: VendorEntity[]) => onVisible(true, items),
        },
        {
          icon: <VisibilityOffRounded />,
          label: t('vendor:action.markInvisible'),
          onConfirm: async (items: VendorEntity[]) => onVisible(false, items),
        },
        {
          icon: <ArchiveRounded />,
          label: t('common:action.archive'),
          onConfirm: async (items: VendorEntity[]) => onArchiveConfirm(true, items),
        },
        {
          icon: <UnarchiveRounded />,
          label: t('common:action.unarchive'),
          onConfirm: async (items: VendorEntity[]) => onArchiveConfirm(false, items),
        },
      ]
    : [];

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onArchiveConfirm = async (isArchived: boolean, vendors: VendorEntity[]) => {
    await archiveApi.call(
      vendors.map((i) => i.id),
      isArchived,
    );
    fetchData();
  };

  const onActive = async (isActive: boolean, vendors: VendorEntity[]) => {
    await activeApi.call(
      vendors.map((i) => i.id),
      isActive,
    );
    await fetchData();
  };

  const onVisible = async (isVisible: boolean, vendors: VendorEntity[]) => {
    await visibleApi.call(
      vendors.map((i) => i.id),
      isVisible,
    );
    await fetchData();
  };

  const renderActionsMenuItems = (vendor: VendorEntity, onClick: () => void) => [
    ...(hasPermissions(PermissionKey.VendorEditInfo)
      ? [
          <MenuItem
            id={vendor.isActive ? 'inactive' : 'active'}
            key="active"
            onClick={() => {
              onActive(!vendor.isActive, [vendor]);
              onClick();
            }}
            sx={style.largeMenuItem}
          >
            {vendor.isActive ? t('common:action.markInactive') : t('common:action.markActive')}
          </MenuItem>,
          <MenuItem
            id={vendor.isVisible ? 'invisible' : 'visible'}
            key="visible"
            onClick={() => {
              onVisible(!vendor.isVisible, [vendor]);
              onClick();
            }}
          >
            {vendor.isVisible ? t('vendor:action.markInvisible') : t('vendor:action.markVisible')}
          </MenuItem>,
        ]
      : []),
    <ArchiveMenuItem
      key="archive"
      entity={vendor}
      name={vendor.name}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('vendor:action.suffix')}
      permissions={{ keys: PermissionKey.VendorEditInfo }}
    />,
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(generatePath(routes.Admin.Vendor.Detail.path, { vendorId: vendor.id }));
      }}
    >
      {t('common:view')}
    </MenuItem>,
  ];

  const renderVendorLink = (id: number, text: string | undefined) =>
    !!text && (
      <Link underline="always" href={generatePath(routes.Admin.Vendor.Detail.path, { vendorId: id })}>
        {text}
      </Link>
    );

  const renderVendorDesignations = (item: VendorEntity) => (
    <LimitCommaValues
      value={item.vendorDesignations.map((vendorDesignation) => getTranslation(vendorDesignation, 'name')).join(', ')}
      maxLength={50}
    />
  );

  return (
    <Table
      actionMenuItems={renderActionsMenuItems}
      data={data ?? []}
      isLoading={isLoading}
      translationNamespace="vendor"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      bulkActions={bulkActions}
      bulkActionSuffix={t('vendor:action.suffix')}
      renderBulkSelection={(items) => (items.length === 1 ? items[0].name : t('navigation:sections.vendors'))}
    >
      <TableColumn
        type="custom"
        width="10%"
        sortable
        id="number"
        render={({ id, fullNumber }: VendorEntity) => renderVendorLink(id, fullNumber)}
      />
      <TableColumn
        type="custom"
        width="50%"
        sortable
        id="name"
        render={({ id, name }: VendorEntity) => renderVendorLink(id, name)}
      />
      <TableColumn type="property" width="20%" sortable id="responsibleBuyer" />
      <TableColumn type="custom" width="20%" id="vendorDesignations" render={renderVendorDesignations} />
      <TableColumn type="icon" sortable id="cciPreferred" align="center" iconComponent={LocalActivityRounded} />
      <TableColumn type="icon" sortable id="isActive" iconComponent={CheckCircleRounded} align="center" />
      <TableColumn type="icon" sortable id="isVisible" align="center" iconComponent={VisibilityRounded} />
      <TableColumn type="icon" tooltip id="notes" align="center" iconComponent={ModeCommentRounded} />
    </Table>
  );
};
