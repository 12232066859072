import { Button, Card, CardContent, MenuItem, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { VendorDetailContext } from '../../../contexts/Vendor/VendorDetailContext';
import { useApi, useAuth, useGlobalEdit, useInternationalization } from '../../../hooks';
import { Entity, PaginationEntity, PermissionKey } from '../../../types';
import { Autocomplete } from '../../Form';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';

interface VendorBlockListProps<T> {
  getCall: (vendorId: number) => Promise<T[]>;
  searchCall: (searchText: string, isArchived: boolean) => Promise<PaginationEntity<T>>;
  blockCall: (vendorId: number, id: number) => Promise<T>;
  unblockCall: (vendorId: number, id: number) => Promise<void>;
  renderOptionLabel: (item: T) => string;
  title: string;
  noOptionsText: string;
  translationNamespace: string;
}

export const VendorBlockList = <T extends Entity>({
  getCall,
  searchCall,
  blockCall,
  unblockCall,
  renderOptionLabel,
  title,
  noOptionsText,
  translationNamespace,
}: VendorBlockListProps<T>) => {
  const { hasPermissions } = useAuth();
  const { t } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { vendorId } = useContext(VendorDetailContext);
  const {
    data: blockedEntities,
    isLoading: blockedEntitiesLoading,
    refresh: refreshBlockedEntities,
  } = useApi(getCall, null, vendorId);
  const blockApi = useApi(blockCall, { successKey: 'common:success.action' });
  const unblockApi = useApi(unblockCall, { successKey: 'common:success.action' });
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<T | null>(null);

  const entitiesExistsInBlockList = (entity: T) =>
    blockedEntities?.some((blockedEntity) => blockedEntity.id === entity.id) ?? true;

  const clearSelectedItem = () => {
    setSelectedItem(null);
    setSearchText('');
  };

  const blockSelectedEntity = async () => {
    if (selectedItem) {
      await blockApi.call(vendorId, selectedItem.id);
      clearSelectedItem();
      await refreshBlockedEntities();
    }
  };

  const renderMemberActionsMenuItems = (entity: T, onClick: () => void) => [
    <MenuItem
      id="unblock"
      key="unblock"
      onClick={async () => {
        onClick();
        await unblockApi.call(vendorId, entity.id);
        clearSelectedItem();
        await refreshBlockedEntities();
      }}
    >
      {t('common:remove')}
    </MenuItem>,
  ];

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h3">{title}</Typography>
          <Table
            actionMenuItems={hasPermissions(PermissionKey.VendorEditBlocks) ? renderMemberActionsMenuItems : undefined}
            data={blockedEntities ?? []}
            translationNamespace={translationNamespace}
            isLoading={blockedEntitiesLoading}
          >
            <TableColumn type="custom" id="name" render={renderOptionLabel} />
          </Table>
          <HasPermissions keys={PermissionKey.VendorEditBlocks}>
            <Stack direction="row" spacing={2}>
              <Autocomplete
                minSearchTextLength={1}
                value={searchText}
                onValueChange={(value) => setSearchText(value)}
                noOptionsText={noOptionsText}
                apiFunction={searchCall}
                apiExtraParams={[false]}
                disableOption={entitiesExistsInBlockList}
                getOptionLabel={(option) => (typeof option === 'string' ? option : renderOptionLabel(option))}
                onApplySearch={(text) => {
                  setSearchText(text);
                  if (!text) {
                    setSelectedItem(null);
                  }
                }}
                onSelectItem={(item) => setSelectedItem(item)}
              />

              <Button
                size="small"
                disabled={!selectedItem || globalEditing}
                variant="contained"
                onClick={blockSelectedEntity}
              >
                {t('common:add')}
              </Button>
            </Stack>
          </HasPermissions>
        </Stack>
      </CardContent>
    </Card>
  );
};
