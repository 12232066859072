import { formatDistance } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import { PERCENT_MAX_DECIMALS } from '../constants';
import i18n from '../i18n';
import { ApiErrorCodes, FormattedInput, LanguageCode } from '../types';

export const phoneNumberFormatter = (value: string): FormattedInput => {
  const parsed = value.replace(/\D/g, '');
  let formatted = parsed;
  const match = parsed.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    formatted = `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return { parsed, formatted: [undefined, formatted, undefined] };
};

export const postalCodeFormatter = (value: string): FormattedInput => {
  const parsed = value.replace(/\s/g, '').toUpperCase();
  let formatted = parsed;
  const match = parsed.match(/^([A-Z]\d[A-Z])(\d[A-Z]\d)$/);
  if (match) {
    formatted = `${match[1]} ${match[2]}`;
  }
  return { parsed, formatted: [undefined, formatted, undefined] };
};

export const numberFormatter =
  (currentLanguage: LanguageCode, unit: string, maxDecimals: number, unitAtEnd = false, hideUnitWhenEmpty = false) =>
  (value: string | number): FormattedInput => {
    if (value === '' || value == null) {
      return {
        parsed: '',
        formatted: hideUnitWhenEmpty
          ? [undefined, '', undefined]
          : [!unitAtEnd ? unit : undefined, '', unitAtEnd ? unit : undefined],
      };
    }
    const isFr = currentLanguage === LanguageCode.fr;
    // Since 16 significant digits with JavaScript numbers, limit input size, but keep at least one
    const maxDigits = Math.max(16 - maxDecimals, 1);
    // Replace everything that is not digits or decimal separators (allow both dot and comma in French)
    const cleaned = isFr
      ? value
          .toString()
          .replace(/[^0-9,.]/g, '')
          .replace(',', '.')
      : value.toString().replace(/[^0-9.]/g, '');
    // Get only the first decimal point and its following numbers
    const sanitized = cleaned.match(/\d*\.?\d*/)?.[0] ?? '';
    // Split the string to get the numbers before the decimal (characteristic) and
    // after the decimal (mantissa)
    const [characteristic, mantissa] = sanitized.split('.');
    // Limit the characteristic to the maximum number of digits
    const limitedCharacteristic = characteristic.substring(0, maxDigits);
    // Parse the number before the decimal
    const characteristicParsed = Number(limitedCharacteristic);
    // Format it using the appropriate locale
    const characteristicFormatted = characteristicParsed.toLocaleString(isFr ? 'fr-CA' : 'en-CA');
    // Limit the number of decimal places
    const limitedMantissa = mantissa?.substring(0, maxDecimals);
    // Assemble a raw number that can be parsed
    const rawNumber = `${characteristic ?? 0}.${limitedMantissa ?? ''}`;
    const parsedNumber = Number(rawNumber);
    // Assemble the formatted number
    const formatted = `${characteristicFormatted}${
      maxDecimals > 0 && (mantissa || mantissa === '') ? (isFr ? ',' : '.') : ''
    }${limitedMantissa ?? ''}`;
    // Return a string if the inputed number had a decimal point or a zero after the decimal
    // at the end, therefore allowing the user to continue typing their number
    const parsed = limitedMantissa === '' || limitedMantissa?.slice(-1) === '0' ? rawNumber : parsedNumber;
    // Return the final result
    return {
      parsed: parsed,
      formatted: [!unitAtEnd ? unit : undefined, formatted, unitAtEnd ? unit : undefined],
    };
  };

export const paddedZerosFormatter =
  (numberOfZeros: number) =>
  (value: string | number): FormattedInput => {
    const stringValue = String(value);
    const numberValue = Number(stringValue);
    const parsed = numberValue === 0 ? '0' : stringValue.replace(/(\D|^0+)/g, '');
    const startAdornment = '0'.repeat(numberOfZeros - Math.min(parsed.length, numberOfZeros));
    return { parsed, formatted: [startAdornment, parsed, undefined] };
  };

export const formatDateDistance = (date: Date, currentLanguage: LanguageCode) =>
  formatDistance(date, new Date(), {
    addSuffix: true,
    locale: currentLanguage === LanguageCode.fr ? frCA : enCA,
  });

export const formatMailChimpErrors = (errors: string | null) => {
  return errors == ApiErrorCodes.UNHANDLED_MAILCHIMP_ERROR
    ? i18n.t('common:error.generic')
    : errors
        ?.split('; ')
        .map((e) => e.trim())
        .join('\n\n') ?? '';
};

export const percentageFormatter = (currentLanguage: LanguageCode) =>
  numberFormatter(currentLanguage, '%', PERCENT_MAX_DECIMALS, true);
