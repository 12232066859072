import { CloseRounded, MenuRounded } from '@mui/icons-material';
import { Box, Divider, Drawer, IconButton, Stack } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationMenuContext } from '../../contexts';
import { useBreakpoints } from '../../hooks';
import { Styles } from '../../types';
import { NavigationBranding } from './NavigationBranding';

const style: Styles = {
  container: {
    display: { xs: 'flex', md: 'none' },
    mr: 1,
  },
  drawerHeader: {
    mx: 1,
    py: 1.5,
  },
  drawer: {
    overflow: 'hidden',
    width: '100%',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: '100%',
      boxSizing: 'border-box',
    },
  },
  childrenContainer: {
    overflow: 'scroll',
  },
  icon: {
    padding: '7px',
  },
};

export const NavigationMobileSideMenu = ({ children }: PropsWithChildren) => {
  const [visible, setVisible] = useState<boolean>(false);
  const breakpoint = useBreakpoints();
  const location = useLocation();

  useEffect(() => {
    if (breakpoint != 'xs') {
      setVisible(false);
    }
  }, [breakpoint]);

  useEffect(() => {
    setVisible(false);
  }, [location]);

  return (
    <NavigationMenuContext.Provider value={{ variant: 'mobile', expanded: true }}>
      <Box sx={style.container}>
        <IconButton size="small" sx={style.icon} onClick={() => setVisible(true)}>
          {visible ? <CloseRounded /> : <MenuRounded />}
        </IconButton>
        <Drawer sx={style.drawer} anchor="left" open={visible}>
          <Stack direction="row" spacing={1} sx={style.drawerHeader}>
            <IconButton size="small" sx={style.icon} onClick={() => setVisible(false)}>
              <CloseRounded />
            </IconButton>
            <NavigationBranding showUserType={{ mobile: true, desktop: false }} />
          </Stack>
          <Divider />
          <Box className="navigation-side navigation-expanded navigation-mobile" sx={style.childrenContainer} py={0.5}>
            {children}
          </Box>
        </Drawer>
      </Box>
    </NavigationMenuContext.Provider>
  );
};
