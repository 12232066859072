import React from 'react';
import { CommitmentEventEntity } from '../../models';

interface CommitmentEventDetailContextType {
  event: CommitmentEventEntity | null;
  eventId: number;
  readonly: boolean;
}

export const CommitmentEventDetailContext = React.createContext<CommitmentEventDetailContextType>(
  {} as CommitmentEventDetailContextType,
);
