import { Box, Link, Stack, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { vendor as vendorApi, report as reportApi } from '../../../api';
import {
  DEFAULT_PAGINATION_FILTER,
  DOLLAR_MAX_DECIMALS,
  MAX_PAGINATION_FILTER,
  PERCENT_MAX_DECIMALS,
} from '../../../constants';
import { useApi, useInternationalization, usePageTitle } from '../../../hooks';
import { HoldbackReportEntity } from '../../../models';
import {
  OrderBy,
  PermissionKey,
  Styles,
  HoldbackReportFilter,
  RebateTypeAndUnitType,
  LanguageCode,
} from '../../../types';
import { downloadBlob, formatShortDate } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { Autocomplete, Switch } from '../../Form';
import { LayoutPage } from '../../Layout';
import { Table, TableColumn } from '../../Table';
import { DownloadRounded } from '@mui/icons-material';
import { DatePicker } from '../../Form';
import { numberFormatter } from '../../../utils/formatters';
import { LoadingButton } from '@mui/lab';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../routes';

const style: Styles = {
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
  textContainer: {
    mb: 0.2,
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectContainer: {
    width: {
      xs: '100%',
      sm: '250px',
    },
  },
};

const defaultFilter: HoldbackReportFilter = {
  vendorNameOrNumber: '',
  year: new Date().getFullYear(),
  ...DEFAULT_PAGINATION_FILTER,
};

export const HoldbackReportList = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const [filter, setFilter] = useState(defaultFilter);
  const { data, isLoading } = useApi(reportApi.getAllHoldbacks, null, filter);
  const { call: downloadExport, isLoading: isExportLoading } = useApi(reportApi.exportHoldbacks, {
    successKey: 'common:success.action',
  });
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);

  usePageTitle(`report:sections.holdbacks`);

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
    setIsAdvancedMode(checked);
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onYearChange = (year: number) => setFilter((prev) => ({ ...prev, year }));

  const onClear = () => {
    setFilter((prev) => ({
      ...prev,
      ...defaultFilter,
    }));
  };

  const onExport = async () => {
    const fileData = await downloadExport({ ...filter, ...MAX_PAGINATION_FILTER });
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
    }
  };

  const getYear = (date: Date | null) => date?.getFullYear() ?? null;

  const getDateFromYear = (year: number) => {
    if (!year) return null;
    const date = new Date(1900, 1, 1);
    date.setFullYear(year);
    return date;
  };

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <Box sx={style.selectContainer}>
        <DatePicker
          label={t('report:holdbackReport.filter.year')}
          onChange={(value: Date | null) => onYearChange(getYear(value) ?? new Date().getFullYear())}
          value={formatShortDate(getDateFromYear(filter.year))}
          yearOnly
        />
      </Box>
    </FilterContainer>
  );

  const renderTextSearch = () => (
    <Box sx={style.selectSmallContainer}>
      <Autocomplete
        minSearchTextLength={1}
        value={filter.vendorNameOrNumber}
        sx={style.textContainer}
        noOptionsText={t('vendor:search.notFound')}
        apiFunction={vendorApi.getSuggestions}
        label={t('vendor:search.title')}
        placeholder={t('vendor:search.placeholder')}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
        }}
        onApplySearch={(searchText) =>
          setFilter((prev) => ({ ...prev, vendorNameOrNumber: searchText ?? undefined, pageNumber: 1 }))
        }
        onSelectItem={(item) =>
          setFilter((prev) => ({ ...prev, vendorNameOrNumber: item?.name ?? undefined, pageNumber: 1 }))
        }
      />
    </Box>
  );

  const renderValue = (entity: HoldbackReportEntity) => {
    if (!entity.isRebateNote && entity.value !== null) {
      const formatter = numberFormatter(
        currentLanguage,
        entity.unit !== RebateTypeAndUnitType.Custom
          ? t(`common:rebateTypeAndUnitType.${entity.unit}`)
          : ` ${getTranslation(entity, 'customUnit') ?? entity.customUnit}`,
        entity.unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
        entity.unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
      );
      return formatter(entity.value).formatted.join('');
    }
    return getTranslation(entity, 'rebateNote') || entity.rebateNote || '';
  };

  const renderName = (entity: HoldbackReportEntity, text: string) => {
    const href = generatePath(routes.Admin.Vendor.RebateCategoryProgram.path, {
      vendorId: entity.vendorId,
      vendorRebateCategoryId: entity.rebateCategory?.id,
      rebateProgramId: entity.rebateProgram.id,
    });
    return <Link href={href}>{text}</Link>;
  };

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="report:holdbackReport"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      keySelector={(item, index) => `${index}-${item.id}`}
    >
      <TableColumn
        type="custom"
        width="5%"
        id="vendorNumber"
        sortable
        render={(i: HoldbackReportEntity) => i.vendorNumber?.toString() ?? ''}
      />
      <TableColumn type="custom" width="10%" id="vendor" sortable render={(i: HoldbackReportEntity) => i.vendorName} />
      <TableColumn
        type="custom"
        width="10%"
        id="rebateCategory"
        sortable
        render={(i: HoldbackReportEntity) => getTranslation(i.rebateCategory, 'name')}
      />
      <TableColumn
        type="custom"
        width="5%"
        id="rebateProgramNumber"
        sortable
        render={(i: HoldbackReportEntity) => renderName(i, i.rebateProgram.number.toString())}
      />
      <TableColumn
        type="custom"
        width="10%"
        id="rebateProgram"
        sortable
        render={(i: HoldbackReportEntity) => renderName(i, getTranslation(i.rebateProgram, 'name'))}
      />
      <TableColumn
        type="custom"
        sortable
        width="7.5%"
        id="effectiveDate"
        render={({ effectiveDate }: HoldbackReportEntity) => formatShortDate(effectiveDate)}
      />
      <TableColumn
        type="custom"
        sortable
        width="7.5%"
        id="expiryDate"
        render={({ expiryDate }: HoldbackReportEntity) => formatShortDate(expiryDate)}
      />
      <TableColumn type="custom" width="20%" id="value" render={renderValue} />
      <TableColumn
        type="custom"
        sortable
        width="10%"
        id="rebateType"
        render={({ rebateType }: HoldbackReportEntity) =>
          rebateType ? getTranslation(rebateType, 'abbreviation') : ''
        }
      />
      <TableColumn
        type="custom"
        sortable
        width="10%"
        id="receivableFrequency"
        render={({ receivableFrequency }: HoldbackReportEntity) =>
          receivableFrequency ? getTranslation(receivableFrequency, 'value') : ''
        }
      />
      <TableColumn
        type="custom"
        sortable
        width="10%"
        id="paymentMethod"
        render={({ paymentMethod }: HoldbackReportEntity) =>
          paymentMethod ? getTranslation(paymentMethod, 'value') : ''
        }
      />
    </Table>
  );

  const renderPageActions = () => (
    <LoadingButton variant="outlined" onClick={onExport} loading={isExportLoading} startIcon={<DownloadRounded />}>
      {t('common:export')}
    </LoadingButton>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.ReportVendorData }}
      display="Tab"
      title={t(`report:sections.holdbacks`)}
      rightTitle={renderPageActions()}
    >
      <Stack spacing={3}>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark" mr={3}>
            {t('report:holdbackReport.filter.searchByVendor')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('report:holdbackReport.filter.filterList')}
          </Typography>
        </Box>
        {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
        {renderTable()}
      </Stack>
    </LayoutPage>
  );
};
