import axios from 'axios';
import { ChangeLogEntity, PublicationCategoryEntity, PublicationEntity } from '../../models';
import {
  PublicationFilter,
  ChangeLogFilter,
  ContentTriggers,
  PaginationEntity,
  TranslationLanguage,
  LanguageCode,
  UpdateContentFileLanguageRequest,
} from '../../types';
import { TranslatedFileEntity } from '../../models/TranslatedFileEntity';

export const Publication = {
  getAllPublications: async (publicationFilter: PublicationFilter): Promise<PaginationEntity<PublicationEntity>> => {
    const { data } = await axios.post('api/publication/search', publicationFilter);
    return { ...data, data: data.data.map((d: PublicationEntity) => new PublicationEntity(d)) };
  },
  getPublication: async (publicationId: number): Promise<PublicationEntity> => {
    const { data } = await axios.get(`api/publication/${publicationId}`);
    return new PublicationEntity(data);
  },
  getPublicationSuggestions: async (
    publicationName: string,
    isArchived?: boolean,
  ): Promise<PaginationEntity<PublicationEntity>> => {
    const { data } = await axios.post('api/publication/search', {
      publicationName,
      pageSize: 10,
      pageNumber: 1,
      isArchived,
    });
    return { ...data, data: data.data.map((d: PublicationEntity) => new PublicationEntity(d)) };
  },
  createPublication: async (entity: PublicationEntity): Promise<PublicationEntity> => {
    const { data } = await axios.post('api/publication', entity);
    return new PublicationEntity(data);
  },
  updatePublication: async (entity: PublicationEntity): Promise<PublicationEntity> => {
    const { data } = await axios.put(`api/publication/${entity.id}`, entity);
    return new PublicationEntity(data);
  },
  uploadPublicationFiles: async (publicationId: number, files: TranslatedFileEntity[]): Promise<PublicationEntity> => {
    const formData = PublicationEntity.toFormData(files, 'files');
    const { data } = await axios.post(`api/publication/${publicationId}/files`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new PublicationEntity(data);
  },
  updatePublicationFilesLanguage: async (
    publicationId: number,
    files: UpdateContentFileLanguageRequest[],
  ): Promise<PublicationEntity> => {
    const { data } = await axios.put(`api/publication/${publicationId}/files/language`, files);
    return new PublicationEntity(data);
  },
  deletePublicationFiles: async (publicationId: number, ids: number[]): Promise<PublicationEntity> => {
    const { data } = await axios.delete(
      `api/publication/${publicationId}/files?${ids.map((id) => `fileIds=${id}`).join('&')}`,
    );
    return new PublicationEntity(data);
  },
  getPublicationFile: async (publicationId: number, fileId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/publication/${publicationId}/files/${fileId}`, {
      responseType: 'blob',
    });
    return data;
  },
  uploadPublicationBlockFile: async (
    publicationId: number,
    blockId: number,
    language: TranslationLanguage,
    file: File,
  ): Promise<PublicationEntity> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.put(`api/publication/${publicationId}/block/${blockId}/file/${language}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new PublicationEntity(data);
  },
  deletePublicationBlockFile: async (
    publicationId: number,
    blockId: number,
    language: LanguageCode,
  ): Promise<PublicationEntity> => {
    const { data } = await axios.delete(`api/publication/${publicationId}/block/${blockId}/file/${language}`);
    return new PublicationEntity(data);
  },
  getPublicationBlockFile: async (publicationId: number, blockId: number, language: LanguageCode): Promise<Blob> => {
    const { data } = await axios.get(`api/publication/${publicationId}/block/${blockId}/file/${language}`, {
      responseType: 'blob',
    });
    return data;
  },
  updatePublicationWorkflow: async (publicationId: number, trigger: ContentTriggers): Promise<PublicationEntity> => {
    const { data } = await axios.post(`api/publication/${publicationId}/${trigger}`);
    return new PublicationEntity(data);
  },
  getPublicationChangeLogs: async (
    publicationId: number,
    filter: ChangeLogFilter,
  ): Promise<PaginationEntity<ChangeLogEntity>> => {
    const { data } = await axios.post(`api/publication/${publicationId}/changeLog/search`, {
      ...filter,
    });
    return { ...data, data: data.data.map((v: ChangeLogEntity) => new ChangeLogEntity(v)) };
  },
  addPublicationComment: async (publicationId: number, description: string): Promise<ChangeLogEntity> => {
    const { data } = await axios.post(`api/publication/${publicationId}/comment`, {
      description,
    });
    return new ChangeLogEntity(data);
  },
  getPublicationCategories: async (): Promise<PublicationCategoryEntity[]> => {
    const { data } = await axios.get(`api/publication/categories`);
    return data.map((d: PublicationCategoryEntity) => new PublicationCategoryEntity(d));
  },
};
