import { AddRounded, ArchiveRounded, EditRounded, UnarchiveRounded } from '@mui/icons-material';
import { Box, Button, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { EditDrawer } from '../..';
import { vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { VendorDetailContext } from '../../../contexts';
import { useApi, useAuth, useEnumList, useGlobalEdit, useInternationalization } from '../../../hooks';
import { GeneralConditionEntity, TranslatedFileEntity, VendorGeneralConditionEntity } from '../../../models';
import { VendorGeneralConditionSchema } from '../../../schemas/Vendor';
import {
  FilterPeriod,
  GeneralConditionType,
  OrderBy,
  PermissionKey,
  Styles,
  VendorGeneralConditionFilter,
} from '../../../types';
import { downloadBlob } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { PeriodFilterSelect } from '../../Filter/PeriodFilterSelect';
import { Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem, MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { VendorGeneralConditionForm } from './VendorGeneralConditionForm';
import { VendorGeneralConditionsTable } from './VendorGeneralConditionsTable';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectLargeContainer: {
    width: {
      xs: '100%',
      sm: '300px',
    },
  },
};

const defaultFilter = {
  type: undefined,
  period: FilterPeriod.All,
  isArchived: false,
};

export const VendorGeneralConditions = () => {
  const { hasPermissions } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { vendor, vendorId } = useContext(VendorDetailContext);
  const [filter, setFilter] = useState<VendorGeneralConditionFilter>({
    ...defaultFilter,
    ...DEFAULT_PAGINATION_FILTER,
  });
  const { data, isLoading, refresh } = useApi(
    vendorApi.getAllGeneralConditions,
    { skipFetch: !vendor },
    vendor?.id,
    filter,
  );
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [vendorGeneralCondition, setVendorGeneralCondition] = useState(new VendorGeneralConditionEntity());
  const { call: create } = useApi(vendorApi.createGeneralCondition, { successKey: 'common:success.action' });
  const { call: update } = useApi(vendorApi.updateGeneralCondition, { successKey: 'common:success.action' });
  const { call: uploadFiles } = useApi(vendorApi.uploadGeneralConditionFiles, null);
  const { call: updateFilesLanguage } = useApi(vendorApi.updateGeneralConditionFilesLanguage, null);
  const { call: deleteFiles } = useApi(vendorApi.deleteGeneralConditionFiles, null);
  const archiveApi = useApi(vendorApi.archiveGeneralCondition, { successKey: 'common:success.action' });
  const typeOptions = useEnumList(GeneralConditionType, 'vendor:generalCondition.type', true);
  const { enqueueSnackbar } = useSnackbar();
  const { call: download } = useApi(vendorApi.getGeneralConditionFile, null);

  const downloadFile = async (entity: GeneralConditionEntity, file: TranslatedFileEntity) => {
    if (vendor && entity && file.file?.id) {
      const fileData = await download(vendor.id, entity.id, file.file.id);
      if (fileData) {
        downloadBlob(file.file.name, fileData);
      }
    }
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      type: (event.target.value as GeneralConditionType) || undefined,
      pageNumber: 1,
    }));
  };

  const onSave = async (entityToSave: VendorGeneralConditionEntity) => {
    let updatedEntity = vendorGeneralCondition.id
      ? await update(vendorId, entityToSave)
      : await create(vendorId, entityToSave);

    const filesDiff = vendorGeneralCondition?.getFilesDiff(entityToSave);
    const hasAddedFiles = filesDiff?.added.length;

    if (hasAddedFiles && updatedEntity) {
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
    }

    if (updatedEntity) {
      if (filesDiff?.removed.length) {
        updatedEntity = await deleteFiles(vendorId, updatedEntity.id, filesDiff.removed);
      }
      if (filesDiff?.languageModified.length && updatedEntity) {
        updatedEntity = await updateFilesLanguage(vendorId, updatedEntity.id, filesDiff.languageModified);
      }
      if (hasAddedFiles && updatedEntity) {
        updatedEntity.files = entityToSave.files
          .filter((f) => !f.file?.id)
          .map((f) => VendorGeneralConditionEntity.createFromFile(f));
        updatedEntity = await uploadFiles(vendorId, updatedEntity.id, filesDiff.added);
      }
    }

    enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    await refresh();
    return updatedEntity;
  };

  const renderSearch = () => (
    <Stack>
      <FilterContainer onClear={onClear}>
        <PeriodFilterSelect filter={filter} setFilter={setFilter} />
        <Box sx={style.selectLargeContainer}>
          <Select
            displayEmpty
            options={typeOptions}
            value={filter.type ?? ''}
            onChange={onTypeChange}
            label={t('vendor:generalCondition.search.type')}
          />
        </Box>
      </FilterContainer>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="female" />
    </Stack>
  );

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: VendorGeneralConditionEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: VendorGeneralConditionEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onArchiveConfirm = async (isArchived: boolean, entities: VendorGeneralConditionEntity[]) => {
    await archiveApi.call(
      vendorId,
      entities.map((i) => i.id),
      isArchived,
    );
    refresh();
  };

  const renderActionsMenuItems = (entity: VendorGeneralConditionEntity, onClick: () => void) => [
    ...(!entity.isArchived
      ? [
          <MenuItem
            id="edit"
            key="edit"
            permissions={{ keys: PermissionKey.VendorEditConditions }}
            onClick={() => {
              setVendorGeneralCondition(entity);
              setIsAddOpen(true);
              onClick();
            }}
          >
            {t('common:edit')}
          </MenuItem>,
        ]
      : []),
    <ArchiveMenuItem
      key="archive"
      permissions={{ keys: PermissionKey.VendorEditConditions }}
      entity={entity}
      name={getTranslation(entity, 'summary')}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('vendor:generalCondition.actions.suffix')}
    />,
  ];

  const renderTable = () => (
    <VendorGeneralConditionsTable
      data={data ?? []}
      translationNamespace="vendor:generalCondition"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={
        vendor && hasPermissions(PermissionKey.VendorEditConditions) && !globalEditing
          ? renderActionsMenuItems
          : undefined
      }
      bulkActions={vendor && !vendor.isArchived && !globalEditing ? bulkActions : undefined}
      bulkActionSuffix={t('vendor:generalCondition.actions.suffix')}
      bulkPermissions={{ keys: PermissionKey.VendorEditConditions }}
      renderBulkSelection={(items) =>
        items.length === 1 ? getTranslation(items[0], 'summary') : t(`vendor:generalCondition.title`)
      }
      downloadFile={downloadFile}
    />
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {vendorGeneralCondition.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(
              vendorGeneralCondition.id
                ? 'vendor:generalCondition.actions.edit'
                : 'vendor:generalCondition.actions.add',
            )}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={vendorGeneralCondition}
      schema={VendorGeneralConditionSchema()}
      onSave={onSave}
      onConfirm={() => {
        refresh();
        setIsAddOpen(false);
      }}
      onCancel={() => {
        setIsAddOpen(false);
        setVendorGeneralCondition(new VendorGeneralConditionEntity());
      }}
    >
      <VendorGeneralConditionForm />
    </EditDrawer>
  );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.VendorEditConditions}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => {
          setVendorGeneralCondition(new VendorGeneralConditionEntity());
          setIsAddOpen(true);
        }}
        disabled={!vendor || vendor.isArchived || globalEditing}
      >
        {t('vendor:generalCondition.actions.add')}
      </Button>
    </HasPermissions>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewConditions }}
      title={t('vendor:generalCondition.title')}
      display="Tab"
      rightTitle={renderAddButton()}
    >
      {renderSearch()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
