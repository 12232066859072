import { Grid } from '@mui/material';
import { PasswordComplexityStatus } from '../../components';
import { ControlledInput, StaticField } from '../../components/Form';
import { useEntityFormContext, useInternationalization } from '../../hooks';
import { UserPasswordEntity } from '../../models';

export const PasswordForm = () => {
  const { t } = useInternationalization();
  const { control, watch, readOnly } = useEntityFormContext<UserPasswordEntity>();
  const newPassword = watch('newPassword');

  return (
    <Grid container rowSpacing={4} columnSpacing={2}>
      {readOnly ? (
        <Grid item xs={3}>
          <StaticField
            label={t('user:profile.password.oldPassword')}
            value={t('user:profile.password.oldPasswordPlaceholder')}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={3}>
            <ControlledInput
              label={t('user:profile.password.oldPassword')}
              name="oldPassword"
              control={control}
              type="password"
            />
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <ControlledInput
              label={t('user:profile.password.newPassword')}
              name="newPassword"
              control={control}
              type="password"
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              label={t('user:profile.password.newPasswordConfirmation')}
              name="newPasswordConfirmation"
              control={control}
              type="password"
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={12}>
            <PasswordComplexityStatus password={newPassword} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
