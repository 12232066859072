import { date, object, string } from 'yup';
import { ForestProductPriceUpdateEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema } from '../../types';
import { MAX_LONG_TEXT_LENGTH } from '../../constants';

export const ForestProductPriceUpdateSchema = (): EntityFormSchema<ForestProductPriceUpdateEntity> => ({
  schema: object().shape({
    date: date().required(),
    notes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    notes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
  }),
  errorCodeMap: {
    [ApiErrorCodes.FOREST_PRODUCT_PRICE_UPDATE_DATE_NOT_UNIQUE]: { key: 'date', message: 'common:error.fieldUnique' },
  },
});
