import { EditableEntity, RoleEntity, UserPhoneNumberEntity, VendorEntity } from '..';
import { LanguageCode, TimeZoneCode, UserType, VerificationStatus } from '../../types';
import { MemberEntity } from '../Member';

export class UserEntity extends EditableEntity {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  language: LanguageCode;
  timeZone: TimeZoneCode;
  roleId: number;
  role: RoleEntity;
  isAllowedAccess: boolean;
  isArchived: boolean;
  notes: string;
  lastLogin: Date;
  lastVerifiedDate: Date;
  members?: MemberEntity[];
  memberIds?: number[];
  vendor?: VendorEntity;
  vendorId?: number;
  jobTitle?: string;
  department?: string;
  phoneOffice?: UserPhoneNumberEntity;
  phoneMobile?: UserPhoneNumberEntity;
  verificationPhoneNumber?: string;
  verificationStatus?: VerificationStatus;
  isVerifiable?: boolean;
  userType?: UserType;

  constructor(entity?: UserEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.roleId = this.role?.id;
      this.vendorId = this.vendor?.id;
      this.members = this.members?.map((v: MemberEntity) => new MemberEntity(v));
      this.vendor = this.vendor && new VendorEntity(this.vendor);
    } else {
      this.username = '';
      this.email = '';
      this.firstName = '';
      this.lastName = '';
      this.language = LanguageCode.en;
      this.timeZone = TimeZoneCode.MountainTime;
      this.role = new RoleEntity();
      this.isAllowedAccess = true;
      this.isArchived = false;
      this.notes = '';
      this.lastLogin = new Date();
      this.lastVerifiedDate = new Date();
      this.jobTitle = '';
      this.department = '';
      this.phoneOffice = new UserPhoneNumberEntity();
      this.phoneMobile = new UserPhoneNumberEntity();
      this.members = [];
      this.memberIds = [];
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
