import { DragHandleRounded, MoreVertRounded, SvgIconComponent, SyncProblemRounded } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu,
  MenuItemProps,
  Table as MuiTable,
  Stack,
  SvgIconProps,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { CSSProperties, MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks';
import { palette } from '../../styles/palette';
import theme from '../../styles/theme';
import {
  Archivable,
  BulkAction,
  Entity,
  OrderBy,
  OrderDirection,
  PaginationEntity,
  PaginationFilter,
  Pending,
  PermissionProps,
  Styles,
} from '../../types';
import { scrollToTop } from '../../utils/helper';
import { BulkActions } from '../BulkActions';
import { LimitCommaValues } from '../LimitCommaValues';
import { Pagination } from '../Pagination';
import { TableColumnProps } from './TableColumn';
import { PAGINATION_ROWS_PER_PAGE_OPTIONS } from '../../constants';

const style: Styles = {
  archivedRow: {
    backgroundColor: `${palette.grey[50]} !important`,
    '& .MuiTableCell-root, & .MuiSvgIcon-root': {
      color: palette.grey[400],
    },
  },
  draggingRow: {
    display: 'table',
    backgroundColor: palette.white,
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderColor: palette.secondary.dark,
  },
  rowHead: {
    backgroundColor: palette.grey[200],
    '.MuiTableCell-root': {
      color: palette.grey[500],
    },
  },
  tableContainer: {
    mt: '8px !important',
  },
  body: {
    transition: 'opacity 0ms',
    '& tr': {
      backgroundColor: palette.white,
    },
    '& .MuiLink-root': {
      textDecoration: 'none',
      fontWeight: 600,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  tableContainerLoading: {
    transition: 'opacity 1s ease-out',
    opacity: 0.5,
  },
  cellIcon: {
    fontSize: 'small',
    htmlColor: palette.grey[500],
  },
  icon: {
    mb: -0.75,
  },
  clickable: {
    cursor: 'pointer',
  },
  centeredColumnSortable: {
    marginLeft: '25px',
  },
  footer: {
    background: palette.backgrounds.container,
    '& .MuiTableCell-footer': {
      border: 0,
    },
  },
  tooltip: {
    overflowY: 'auto',
  },
  iconButtonSmallRowHeight: {
    p: 0,
  },
  tableSortLabel: {
    px: '0 !important',
  },
  bulkActionCell: {
    pl: '4px !important',
  },
  menuCell: {
    pr: '4px !important',
    '& .MuiSvgIcon-root': {
      color: `${palette.secondary.dark} !important`,
    },
  },
  menu: {
    '& li': {
      whiteSpace: 'nowrap',
    },
  },
};

export interface TableReorderEvent {
  fromIndex: number;
  toIndex: number;
}

export interface TableProps<T extends Entity & Archivable & Pending> {
  actionMenuItems?: (item: T, onClick: () => void) => React.ReactElement<MenuItemProps>[];
  children?: (ReactElement<TableColumnProps<T>> | false)[] | ReactElement<TableColumnProps<T>>;
  data: Array<T> | PaginationEntity<T>;
  isLoading?: boolean;
  reorderable?: boolean;
  onReorder?: (e: TableReorderEvent) => void;
  translationData?: Record<string, string>;
  translationNamespace: string;
  paginationFilter?: PaginationFilter;
  onPaginationChange?: (page: number, pageSize: number) => void;
  sortColumns?: OrderBy[];
  onSortChange?: (orderBy: OrderBy[]) => void;
  bulkActions?: BulkAction<T>[];
  bulkActionSuffix?: string;
  renderBulkSelection?: (items: T[]) => string;
  hideTotal?: boolean;
  hidePending?: boolean;
  rowHeight?: 'normal' | 'small';
  bulkPermissions?: PermissionProps;
  hiddenColumns?: string[];
  onDownloadTableClick?: () => void;
  handleBulkSelection?: (items: T[], bulkActionLabel: string) => boolean;
  keySelector?: (item: T, index: number) => string;
}

export const Table = <T extends Entity & Archivable & Pending>({
  actionMenuItems,
  children,
  data,
  isLoading,
  onReorder,
  reorderable,
  translationData,
  translationNamespace,
  onPaginationChange,
  paginationFilter,
  sortColumns,
  onSortChange,
  bulkActions,
  hideTotal,
  hidePending,
  bulkActionSuffix,
  renderBulkSelection,
  rowHeight = 'normal',
  bulkPermissions,
  hiddenColumns,
  onDownloadTableClick,
  handleBulkSelection,
  keySelector,
}: TableProps<T>) => {
  const { hasPermissions } = useAuth();
  const { t } = useTranslation();
  const { mixins, breakpoints } = useTheme();
  const containerRef = useRef<HTMLDivElement>();
  const isMounted = useRef<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuItem, setMenuItem] = useState<T | null>(null);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const filteredBulkActions =
    bulkActions && (!bulkPermissions?.keys || hasPermissions(bulkPermissions.keys, bulkPermissions.any))
      ? bulkActions.filter((b) => !b.permissions?.keys || hasPermissions(b.permissions.keys, b.permissions.any))
      : [];

  useEffect(() => {
    if (containerRef.current && isMounted.current) {
      const appBar = mixins?.toolbar[breakpoints.up('sm')] as CSSProperties | undefined;
      const appBarHeight = appBar?.minHeight ? parseInt(appBar.minHeight.toString()) + 10 : 0;
      scrollToTop(containerRef.current.offsetTop - appBarHeight);
    }
    isMounted.current = true;
  }, [paginationFilter?.pageNumber, paginationFilter?.pageSize, mixins, breakpoints]);

  useEffect(() => {
    setSelectedItems([]);
  }, [data]);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>, item: T) => {
    setAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!children) {
    return null;
  }

  const columns = (
    Array.isArray(children)
      ? children.filter((x) => x).map((x) => (x as ReactElement<TableColumnProps<T>>).props)
      : [children.props]
  ).filter((c) => !hiddenColumns?.includes(c.id.toString()));

  const handleOnDragEnd = (result: DropResult) =>
    result.destination && onReorder && onReorder({ fromIndex: result.source.index, toIndex: result.destination.index });

  const renderTableCell = (item: T, column: TableColumnProps<T>, index: number) => {
    switch (column.type) {
      case 'custom':
        return column.render(item, index);
      case 'property': {
        const value = item[column.id];
        const stringValue = value !== null ? new String(value) : '';
        if (column.maxLength) {
          return <LimitCommaValues value={value as string} maxLength={column.maxLength} />;
        }
        return stringValue;
      }
      case 'icon':
      case 'button': {
        const iconTooltipText = (
          column.render ? column.render(item, index) : column.type === 'button' ? undefined : item[column.id]
        ) as string;
        let icon: SvgIconComponent | null = null;
        if (typeof column.iconComponent === 'function') {
          icon = (column.iconComponent as CallableFunction)(iconTooltipText);
        } else if (iconTooltipText || column.tooltip === false) {
          icon = column.iconComponent;
        }

        const iconProps = typeof column.iconProps === 'function' ? column.iconProps(item) : column.iconProps;

        return (
          icon && (
            <Tooltip
              arrow
              placement="top"
              sx={column.sortable ? style.sortableIcon : undefined}
              title={
                column.tooltip ? (
                  <Typography sx={style.tooltip} variant="tooltip">
                    {iconTooltipText}
                  </Typography>
                ) : column.tooltip === false ? undefined : (
                  t(`${translationNamespace}:table.${column.id.toString()}`, translationData)
                )
              }
            >
              {React.createElement(icon, {
                ...style.cellIcon,
                ...iconProps,
                onClick: column.type === 'button' ? () => column.onClick?.(item, index) : undefined,
                sx: {
                  ...(iconProps?.sx ? iconProps.sx : {}),
                  ...(column.type === 'button' ? style.clickable : {}),
                  ...style.icon,
                },
              } as SvgIconProps)}
            </Tooltip>
          )
        );
      }
      default:
        return '';
    }
  };

  const renderRow = (item: T, index: number) => (
    <Draggable
      draggableId={item.id.toString()}
      index={index}
      isDragDisabled={!reorderable}
      key={keySelector ? keySelector(item, index) : item.id}
    >
      {(provided, snapshot) => (
        <>
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              ...(snapshot.isDragging ? style.draggingRow : {}),
              ...(item.isArchived ? style.archivedRow : {}),
            }}
          >
            {reorderable && (
              <TableCell {...provided.dragHandleProps} width="0%">
                <DragHandleRounded htmlColor={palette.secondary.dark} />
              </TableCell>
            )}
            {filteredBulkActions.length > 0 && (
              <TableCell width="0%" sx={style.bulkActionCell}>
                <Tooltip title={item.isPending ? t('common:changeRequest.pending') : undefined}>
                  <Box>
                    <Checkbox
                      size="small"
                      checked={selectedItems?.includes(item)}
                      onChange={onRowCheck(item)}
                      disabled={!!item.isPending}
                    />
                  </Box>
                </Tooltip>
              </TableCell>
            )}
            {columns.map((column, i) => (
              <TableCell
                width={column.type === 'icon' || column.type === 'button' ? 24 : column.width}
                key={column.id.toString() + i.toString()}
                align={column.align ?? 'left'}
                sx={column.sx}
              >
                {renderTableCell(item, column, index)}
              </TableCell>
            ))}
            {item.isPending && !hidePending && (
              <TableCell width={50} align="center">
                <Tooltip title={t('common:changeRequest.pending')}>
                  <SyncProblemRounded htmlColor={palette.primary.deep} />
                </Tooltip>
              </TableCell>
            )}
            {actionMenuItems && (!item.isPending || hidePending) && (
              <TableCell width={50} align="center" sx={style.menuCell}>
                {hasActionMenuItems(item) && (
                  <IconButton
                    id="action-menu"
                    disabled={isLoading}
                    onClick={(e) => handleMenuOpen(e, item)}
                    sx={rowHeight === 'small' ? style.iconButtonSmallRowHeight : undefined}
                  >
                    <MoreVertRounded htmlColor={palette.secondary.dark} />
                  </IconButton>
                )}
              </TableCell>
            )}
          </TableRow>
        </>
      )}
    </Draggable>
  );

  const renderActionMenuItems = () => {
    if (actionMenuItems && menuItem) {
      return actionMenuItems(menuItem, handleMenuClose);
    }
  };

  const hasActionMenuItems = (item: T) => {
    const actionsFiltered = actionMenuItems ? actionMenuItems(item, () => undefined)?.filter((a) => !!a) : null;
    return actionsFiltered && actionsFiltered.length > 0;
  };

  const getNextSortDirection = (direction?: OrderDirection): OrderDirection | undefined => {
    switch (direction) {
      case 'asc':
        return 'desc';
      case 'desc':
        return undefined;
      case undefined:
        return 'asc';
    }
  };

  const dataArray = Array.isArray(data) ? data : data.data;

  const enabledItems = dataArray.filter((d) => !d.isPending);

  const onHeaderCheck = () => {
    if (selectedItems?.length === 0) {
      return setSelectedItems(enabledItems);
    }
    return setSelectedItems([]);
  };

  const onRowCheck = (item: T) => () => {
    if (selectedItems?.includes(item)) {
      return setSelectedItems(selectedItems.filter((s) => s !== item));
    }
    return setSelectedItems([...(selectedItems ?? []), item]);
  };

  const onHeaderOrderBy = (columnName: string, direction: OrderDirection | undefined) => () => {
    let updatedColumns = sortColumns ?? [];
    updatedColumns = updatedColumns.filter((c) => c.columnName !== columnName);

    if (direction) {
      updatedColumns.push({ columnName, direction });
    }

    onSortChange && onSortChange(updatedColumns);
  };

  return (
    <>
      <Stack ref={containerRef} direction="row" alignItems="center" justifyContent="space-between">
        <Stack spacing={1} direction="row" alignItems="center">
          {isLoading ? (
            <CircularProgress size={22} />
          ) : (
            !hideTotal && (
              <Typography variant="h6">
                {t('common:table.results', { count: Array.isArray(data) ? data.length : data.totalCount })}
              </Typography>
            )
          )}
          {!hidePending && !Array.isArray(data) && data.totalPending !== undefined && data.totalPending > 0 && (
            <Stack direction="row" spacing={0.25} alignItems="center">
              <SyncProblemRounded htmlColor={palette.grey[600]} />
              <Typography variant="outstandingCount">
                {t('changeRequest:outstanding', { count: data.totalPending })}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction="row">
          {onDownloadTableClick && selectedItems.length == 0 && (
            <IconButton onClick={onDownloadTableClick}>
              <DownloadIcon />
            </IconButton>
          )}
          {filteredBulkActions.length > 0 && (
            <BulkActions
              bulkActions={filteredBulkActions}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              renderSelection={renderBulkSelection}
              bulkActionSuffix={bulkActionSuffix}
              disabled={isLoading}
              handleBulkSelection={handleBulkSelection}
            />
          )}
        </Stack>
      </Stack>
      <TableContainer
        sx={{
          ...style.tableContainer,
          ...(isLoading ? style.tableContainerLoading : {}),
        }}
      >
        <MuiTable>
          <TableHead>
            <TableRow sx={style.rowHead}>
              {reorderable && <TableCell />}
              {filteredBulkActions.length > 0 && (
                <TableCell width="0%" sx={style.bulkActionCell}>
                  <Checkbox
                    size="small"
                    onChange={onHeaderCheck}
                    indeterminate={
                      selectedItems && selectedItems.length > 0 && selectedItems.length !== enabledItems.length
                    }
                    checked={selectedItems && selectedItems.length > 0 && selectedItems?.length === enabledItems.length}
                  />
                </TableCell>
              )}
              {columns.map((column, i) => {
                const columnId = column.id.toString();
                const sortColumn = sortColumns?.find((c) => c.columnName === columnId);
                const direction = sortColumn?.direction;
                const label =
                  column.type === 'icon' || column.type === 'button'
                    ? ''
                    : t(`${translationNamespace}:table.${columnId}`, translationData) + (column.required ? ' *' : '');
                return (
                  <TableCell
                    sortDirection={direction}
                    key={columnId + i.toString()}
                    align={column.align ?? 'left'}
                    width={column.type === 'icon' ? 24 : column.width}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        sx={{
                          ...style.tableSortLabel,
                          ...(column.align === 'center' && column.type !== 'icon' && column.type !== 'button'
                            ? style.centeredColumnSortable
                            : {}),
                        }}
                        active={!!sortColumn}
                        direction={direction}
                        onClick={onHeaderOrderBy(columnId, getNextSortDirection(direction))}
                      >
                        {label}
                      </TableSortLabel>
                    ) : (
                      label
                    )}
                  </TableCell>
                );
              })}
              {actionMenuItems && <TableCell sx={style.menuCell} />}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => {
                const rows = Array.isArray(data) ? data : data.data;
                return (
                  <TableBody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      ...style.body,
                    }}
                  >
                    {rows.map((item, index) => renderRow(item, index))}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
          {!Array.isArray(data) &&
            paginationFilter &&
            paginationFilter.pageNumber &&
            paginationFilter.pageSize &&
            onPaginationChange && (
              <TableFooter sx={style.footer}>
                <TableRow>
                  <Pagination
                    count={data.totalCount}
                    page={paginationFilter.pageNumber - 1}
                    onPageChange={(_, page) =>
                      paginationFilter.pageSize && onPaginationChange(page + 1, paginationFilter.pageSize)
                    }
                    rowsPerPage={paginationFilter.pageSize}
                    rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
                    onRowsPerPageChange={(e) =>
                      paginationFilter.pageNumber &&
                      onPaginationChange(paginationFilter.pageNumber, parseInt(e.target.value))
                    }
                  />
                </TableRow>
              </TableFooter>
            )}
        </MuiTable>
        <Menu
          id="table-menu"
          MenuListProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.MenuListProps}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleMenuClose}
          PaperProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps}
          sx={style.menu}
        >
          {renderActionMenuItems()}
        </Menu>
      </TableContainer>
    </>
  );
};
