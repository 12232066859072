import { Box, Chip, CircularProgress, Link, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import { useEnumList, useInternationalization } from '../../../hooks';
import { EventEntity } from '../../../models';
import { routes } from '../../../routes';
import { EventFilter, EventListDateRange, EventType, OrderBy } from '../../../types';
import { formatShortDate } from '../../../utils/helper';
import { DatePicker } from '../../Form';
import { Table, TableColumn } from '../../Table';

interface CmsEventsListProps {
  events: EventEntity[];
  onFilterChange: (filter: Partial<EventFilter>, view?: 'calendar' | 'list') => void;
  isLoading: boolean;
  filter: EventFilter;
  emptyPlaceholder?: ReactNode;
}

export const CmsEventsList = ({ events, isLoading, onFilterChange, filter, emptyPlaceholder }: CmsEventsListProps) => {
  const { getTranslation, t } = useInternationalization();
  const dateRangeOptions = useEnumList(EventListDateRange, 'cms:events.filter.dateRange', false, 'male', false);

  const renderEventLink = (entity: EventEntity) => {
    // to update when member pages for these events will be created
    let href = '';
    switch (entity.type) {
      case EventType.PoolBuy:
      case EventType.SpecialBuy:
      case EventType.Booking:
        href = generatePath(routes.Member.BuyGuideBuyOpportunityDetail.path, {
          vendorId: entity.vendorId,
          vendorBuyOpportunityId: entity.id,
        });
        break;
      case EventType.Publication:
        href = generatePath(routes.Member.PublicationDetails.path, {
          publicationId: entity.id,
        });

        break;

      default:
        break;
    }
    return (
      <Link underline="always" href={href}>
        {(entity.vendorName ? entity.vendorName + ' - ' : '') + getTranslation(entity, 'name')}
      </Link>
    );
  };

  const onDateRangeChange = (dateRange: EventListDateRange) => () => {
    let newEffectiveDate = new Date(formatDate(filter.effectiveDate));
    let newExpiryDate = new Date(formatDate(filter.effectiveDate));
    switch (dateRange) {
      case EventListDateRange.Day:
        newEffectiveDate = new Date();
        newExpiryDate = new Date();
        break;
      case EventListDateRange.Week:
        newEffectiveDate = new Date();
        newExpiryDate.setDate(newExpiryDate.getDate() + 7);
        break;
      case EventListDateRange.Month:
        newEffectiveDate = new Date();
        newExpiryDate.setDate(newExpiryDate.getDate() + 31);
        break;
    }

    onFilterChange({
      effectiveDate: formatShortDate(newEffectiveDate),
      expiryDate: formatShortDate(newExpiryDate),
    });
  };

  const onSortChange = (orderBy: OrderBy[]) => onFilterChange({ orderBy: orderBy.length ? orderBy : undefined });

  const formatDate = (date?: string) => (date ? `${date}T00:00:00` : '');

  const renderFilter = () => {
    return (
      <Box>
        <Stack display="flex" flexDirection="row" my={1}>
          <Box mr={2}>
            <DatePicker
              value={formatDate(filter.effectiveDate)}
              label={t('cms:events.table.effectiveDate')}
              onChange={(date: Date | null) => {
                onFilterChange({ effectiveDate: formatShortDate(date) });
              }}
            />
          </Box>
          <Box>
            <DatePicker
              value={formatDate(filter.expiryDate)}
              label={t('cms:events.table.expiryDate')}
              onChange={(date: Date | null) => {
                onFilterChange({ expiryDate: formatShortDate(date) });
              }}
            />
          </Box>
        </Stack>
        <Stack spacing={0.7} direction="row">
          {dateRangeOptions.map((option) => (
            <Chip key={option.value} label={option.label} onClick={onDateRangeChange(option.value)} />
          ))}
        </Stack>
      </Box>
    );
  };

  return (
    <Stack spacing={2}>
      {renderFilter()}
      {events?.length === 0 && emptyPlaceholder ? (
        <Stack>
          {isLoading && <CircularProgress size={22} />}
          {emptyPlaceholder}
        </Stack>
      ) : (
        <Table
          data={events}
          translationNamespace="cms:events"
          rowHeight="small"
          isLoading={isLoading}
          sortColumns={filter.orderBy}
          onSortChange={onSortChange}
          keySelector={(item) => item.id + item.type}
        >
          <TableColumn type="custom" id="name" render={renderEventLink} />
          <TableColumn
            sortable
            type="custom"
            id="type"
            render={({ type }: EventEntity) => t(`cms:events.type.${type}`)}
          />
          <TableColumn
            sortable
            type="custom"
            id="effectiveDate"
            render={({ effectiveDate }: EventEntity) => formatShortDate(effectiveDate)}
          />
          <TableColumn
            sortable
            type="custom"
            id="expiryDate"
            render={({ expiryDate }: EventEntity) => formatShortDate(expiryDate)}
          />
        </Table>
      )}
    </Stack>
  );
};
