/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { t } from 'i18next';
import { generatePath } from 'react-router-dom';
import { SearchDocumentReferenceEntity } from '../models';
import { routes } from '../routes';
import { Breadcrumb, EntityType, PermissionKey, RouteData, SearchCategory, UserType } from '../types';
import { cms as cmsApi, vendor as vendorApi } from '../api';
import { getTranslation } from './translation';

export const searchCategoriesPermissions: Record<UserType, Record<SearchCategory, PermissionKey[]>> = {
  InternalUser: {
    [SearchCategory.Vendors]: [
      PermissionKey.VendorViewVendors,
      PermissionKey.VendorViewContacts,
      PermissionKey.VendorViewConditions,
      PermissionKey.VendorViewFiles,
      PermissionKey.VendorViewRebates,
      PermissionKey.VendorViewVBAs,
    ],
    [SearchCategory.Members]: [PermissionKey.MemberViewMembers, PermissionKey.MemberViewMembers],
    [SearchCategory.EventsAndPublications]: [
      PermissionKey.CmsNewsView,
      PermissionKey.CmsStaticView,
      PermissionKey.CmsHelpView,
    ],
    [SearchCategory.InternalContacts]: [],
    [SearchCategory.ForestProducts]: [PermissionKey.CommodityPriceView],
    [SearchCategory.RebatePrograms]: [PermissionKey.VendorViewRebates],
  },
  MemberUser: {
    [SearchCategory.Vendors]: [
      PermissionKey.VendorViewVendors,
      PermissionKey.VendorViewContacts,
      PermissionKey.VendorViewConditions,
      PermissionKey.VendorViewFiles,
      PermissionKey.VendorViewRebateTerms,
      PermissionKey.VendorViewRebateFOID,
      PermissionKey.VendorViewRebateMP,
      PermissionKey.VendorViewRebateQP,
      PermissionKey.VendorViewRebateSP,
      PermissionKey.VendorViewRebateAP,
      PermissionKey.VendorViewRebateTiersGrowths,
      PermissionKey.VendorViewVBAs,
    ],
    [SearchCategory.Members]: [PermissionKey.MemberViewMembers, PermissionKey.MemberViewMembers],
    [SearchCategory.EventsAndPublications]: [],
    [SearchCategory.InternalContacts]: [],
    [SearchCategory.ForestProducts]: [PermissionKey.CommodityPriceView],
    [SearchCategory.RebatePrograms]: [PermissionKey.VendorViewPrograms],
  },
  VendorUser: {
    [SearchCategory.Vendors]: [
      PermissionKey.VendorViewVendors,
      PermissionKey.VendorViewContacts,
      PermissionKey.VendorViewConditions,
      PermissionKey.VendorViewRebates,
      PermissionKey.VendorViewFiles,
      PermissionKey.VendorViewVBAs,
    ],
    [SearchCategory.Members]: [PermissionKey.MemberViewMembers, PermissionKey.MemberViewMembers],
    [SearchCategory.EventsAndPublications]: [PermissionKey.CmsNewsView],
    [SearchCategory.InternalContacts]: [],
    [SearchCategory.ForestProducts]: [PermissionKey.CommodityPriceView],
    [SearchCategory.RebatePrograms]: [PermissionKey.VendorViewRebates],
  },
};

export const breadcrumbGenerator: Record<
  UserType,
  Record<
    EntityType,
    (
      item: SearchDocumentReferenceEntity,
      routeValues: RouteData,
    ) => (Breadcrumb & { downloadApi?: () => Promise<Blob> })[]
  >
> = {
  InternalUser: {
    Vendor: (i, rv) => [
      { title: t('vendor:title'), href: routes.Admin.Vendor.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.Vendor.Detail.path, rv) },
    ],
    VendorBuyingAgreement: (i, rv) => [
      {
        title: t('vendor:buyingAgreements.title'),
        href: generatePath(routes.Admin.Vendor.Detail.BuyingAgreements.path, rv),
      },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.Vendor.BuyingAgreement.path, rv) },
    ],
    VendorBuyingAgreementFile: (i, rv) => [
      {
        href: generatePath(routes.Admin.Vendor.BuyingAgreement.Documents.path, rv),
        ...(rv.vendorId && rv.vendorBuyingAgreementId
          ? {
              downloadApi: () =>
                vendorApi.getBuyingAgreementFile(rv.vendorId!, rv.vendorBuyingAgreementId!, i.entityId),
            }
          : {}),
      },
    ],
    VendorContact: (i, rv) => [
      {
        title: t('vendor:contact.title'),
        href: generatePath(routes.Admin.Vendor.Detail.Contacts.path, rv),
      },
    ],
    VendorGeneralCondition: (i, rv) => [
      {
        title: t('vendor:generalCondition.title'),
        href: generatePath(routes.Admin.Vendor.Detail.GeneralConditions.path, rv),
      },
    ],
    VendorGeneralConditionFile: (i, rv) => [
      {
        href: generatePath(routes.Admin.Vendor.Detail.GeneralConditions.path, rv),
        ...(rv.vendorId && rv.vendorGeneralConditionId
          ? {
              downloadApi: () =>
                vendorApi.getGeneralConditionFile(rv.vendorId!, rv.vendorGeneralConditionId!, i.entityId),
            }
          : {}),
      },
    ],
    VendorLocation: (i, rv) => [
      {
        title: t('vendor:location.title'),
        href: generatePath(routes.Admin.Vendor.Detail.Locations.path, rv),
      },
    ],
    VendorTier: (i, rv) => [
      {
        title: t('vendor:sections.tierAndGrowth'),
        href: generatePath(routes.Admin.Vendor.Detail.TiersAndGrowths.path, rv),
      },
    ],
    VendorGrowth: (i, rv) => [
      {
        title: t('vendor:sections.tierAndGrowth'),
        href: generatePath(routes.Admin.Vendor.Detail.TiersAndGrowths.path, rv),
      },
    ],
    Member: (i, rv) => [
      { title: t('member:title'), href: routes.Admin.Member.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.Member.Detail.path, rv) },
    ],
    MemberContact: (i, rv) => [
      {
        title: t('member:contact.title'),
        href: generatePath(routes.Admin.Member.Detail.Contacts.path, rv),
      },
    ],
    Office: (i, rv) => [
      { title: t('contact:office.title'), href: routes.Admin.TBMContacts.Offices.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.OfficeDetails.path, rv) },
    ],
    InternalContact: (i, rv) => [
      { title: t('contact:internalContact.title'), href: routes.Admin.TBMContacts.InternalContacts.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.InternalContactDetails.path, rv) },
    ],
    VendorDocument: (i, rv) => [
      {
        title: t('vendor:document.title'),
        href: generatePath(routes.Admin.Vendor.Detail.Documents.path, rv),
        ...(rv.vendorId
          ? {
              downloadApi: () => vendorApi.getDocumentFile(rv.vendorId!, i.entityId),
            }
          : {}),
      },
    ],
    RebateCategory: (i, rv) => [
      {
        title: t('vendor:rebateCategory.title'),
        href: generatePath(routes.Admin.Vendor.Detail.RebateCategories.path, rv),
      },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.Vendor.RebateCategory.path, rv) },
    ],
    RebateProgram: (i, rv) => [
      {
        title: t('vendor:rebateCategory.programs.title'),
        href: generatePath(routes.Admin.Vendor.RebateCategory.Programs.path, rv),
      },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.Vendor.RebateCategoryProgram.path, rv) },
    ],
    VendorBuyingAgreementGeneralCondition: (_, rv) => [
      {
        title: t('vendor:generalCondition.title'),
        href: generatePath(routes.Admin.Vendor.BuyingAgreement.GeneralConditions.path, rv),
      },
    ],
    VendorBuyingAgreementGeneralConditionFile: (i, rv) => [
      {
        href: generatePath(routes.Admin.Vendor.Detail.GeneralConditions.path, rv),
        ...(rv.vendorId && rv.vendorBuyingAgreementId && rv.vendorGeneralConditionId
          ? {
              downloadApi: () =>
                vendorApi.getBuyingAgreementGeneralConditionFile(
                  rv.vendorId!,
                  rv.vendorBuyingAgreementId!,
                  rv.vendorGeneralConditionId!,
                  i.entityId,
                ),
            }
          : {}),
      },
    ],
    VendorBuyingAgreementBuyOpportunity: (i, rv) => [
      {
        title: t('vendor:buyOpportunities.title'),
        href: generatePath(routes.Admin.Vendor.BuyingAgreement.BuyOpportunities.path, rv),
      },
      {
        title: getTranslation(i, 'name'),
        href: generatePath(routes.Admin.Vendor.BuyingAgreementBuyOpportunity.path, rv),
      },
    ],
    VendorBuyingAgreementBuyOpportunityFile: (i, rv) => [
      {
        href: generatePath(routes.Admin.Vendor.BuyingAgreementBuyOpportunity.Documents.path, rv),
        ...(rv.vendorId && rv.vendorBuyingAgreementId && rv.buyOpportunityId
          ? {
              downloadApi: () =>
                vendorApi.getBuyingAgreementBuyOpportunityFile(
                  rv.vendorId!,
                  rv.vendorBuyingAgreementId!,
                  rv.buyOpportunityId!,
                  i.entityId,
                ),
            }
          : {}),
      },
    ],
    VendorBuyingAgreementRebateCategory: (i, rv) => [
      {
        title: t('vendor:rebateCategory.title'),
        href: generatePath(routes.Admin.Vendor.BuyingAgreement.RebateCategories.path, rv),
      },
      {
        title: getTranslation(i, 'name'),
        href: generatePath(routes.Admin.Vendor.BuyingAgreementRebateCategory.path, rv),
      },
    ],
    Publication: (i, rv) => [
      { title: t('navigation:sections.contentManager'), href: routes.Admin.Cms.path },
      { title: t('cms:sections.publications'), href: routes.Admin.Cms.Publications.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.PublicationDetails.path, rv) },
    ],
    PublicationFile: (i, rv) => [
      {
        href: generatePath(routes.Admin.PublicationDetails.path, rv),
        ...(rv.publicationId
          ? {
              downloadApi: () => cmsApi.getPublicationFile(rv.publicationId!, i.entityId),
            }
          : {}),
      },
    ],
    StaticPage: (i, rv) => [
      { title: t('navigation:sections.contentManager'), href: routes.Admin.Cms.path },
      { title: t('cms:sections.staticPages'), href: routes.Admin.Cms.StaticPages.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.StaticPageDetails.path, rv) },
    ],
    StaticPageFile: (i, rv) => [
      {
        href: generatePath(routes.Admin.StaticPageDetails.path, rv),
        ...(rv.vendorId
          ? {
              downloadApi: () => cmsApi.getStaticPageFile(rv.vendorId!, i.entityId),
            }
          : {}),
      },
    ],
    HelpTraining: (i, rv) => [
      { title: t('navigation:sections.contentManager'), href: routes.Admin.Cms.path },
      { title: t('cms:sections.helpTrainings'), href: routes.Admin.Cms.HelpTrainings.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Admin.HelpTrainingDetails.path, rv) },
    ],
    HelpTrainingFile: (i, rv) => [
      {
        href: generatePath(routes.Admin.HelpTrainingDetails.path, rv),
        ...(rv.helpTrainingId
          ? {
              downloadApi: () => cmsApi.getHelpTrainingFile(rv.helpTrainingId!, i.entityId),
            }
          : {}),
      },
    ],
    VendorBuyOpportunity: (i, rv) => [
      {
        title: t('vendor:buyOpportunities.title'),
        href: generatePath(routes.Admin.Vendor.Detail.BuyOpportunities.path, rv),
      },
      {
        title: getTranslation(i, 'name'),
        href: generatePath(routes.Admin.Vendor.BuyOpportunity.path, rv),
      },
    ],
    VendorBuyOpportunityFile: (i, rv) => [
      {
        href: generatePath(routes.Admin.Vendor.BuyOpportunity.path, rv),
        ...(rv.vendorId && rv.vendorBuyOpportunityId
          ? {
              downloadApi: () => vendorApi.getBuyOpportunityFile(rv.vendorId!, rv.vendorBuyOpportunityId!, i.entityId),
            }
          : {}),
      },
    ],
    ForestProductGroup: (i, rv) => [
      {
        title: t('forestProduct:sections.productGroups'),
        href: routes.Admin.ForestProducts.Groups.path,
      },
      {
        title: getTranslation(i, 'name'),
        href: generatePath(routes.Admin.ForestProductGroupDetail.path, rv),
      },
    ],
  },
  MemberUser: {
    Vendor: (i, rv) => [
      { title: t('buyGuide:title'), href: routes.Member.BuyGuide.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.BuyGuideDetail.path, rv) },
    ],
    VendorBuyingAgreement: () => [],
    VendorContact: (i, rv) => [
      {
        title: t('vendor:contact.title'),
        href: generatePath(routes.Member.BuyGuideDetail.Contacts.path, rv),
      },
    ],
    VendorGeneralCondition: (i, rv) => [
      {
        title: t('vendor:generalCondition.title'),
        href: generatePath(routes.Member.BuyGuideDetail.GeneralConditions.path, rv),
      },
    ],
    VendorGeneralConditionFile: (i, rv) => [
      {
        href: generatePath(routes.Member.BuyGuideDetail.GeneralConditions.path, rv),
        ...(rv.vendorId && rv.vendorGeneralConditionId
          ? {
              downloadApi: () =>
                vendorApi.getGeneralConditionFile(rv.vendorId!, rv.vendorGeneralConditionId!, i.entityId),
            }
          : {}),
      },
    ],
    VendorLocation: (_, rv) => [
      {
        title: t('vendor:location.title'),
        href: generatePath(routes.Member.BuyGuideDetail.Locations.path, rv),
      },
    ],
    VendorTier: () => [],
    VendorGrowth: () => [],
    Member: (i, rv) => [
      { title: t('member:title'), href: routes.Member.Member.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.Member.Detail.path, rv) },
    ],
    MemberContact: (i, rv) => [
      {
        title: t('member:contact.title'),
        href: generatePath(routes.Member.Member.Detail.Contacts.path, rv),
      },
    ],
    Office: (i, rv) => [
      { title: t('contact:office.title'), href: routes.Member.TBMContacts.Offices.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.OfficeDetails.path, rv) },
    ],
    InternalContact: (i, rv) => [
      { title: t('contact:internalContact.title'), href: routes.Member.TBMContacts.InternalContacts.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.InternalContactDetails.path, rv) },
    ],
    VendorDocument: (i, rv) => [
      {
        title: t('vendor:document.title'),
        href: generatePath(routes.Member.BuyGuideDetail.Documents.path, rv),
        ...(rv.vendorId
          ? {
              downloadApi: () => vendorApi.getDocumentFile(rv.vendorId!, i.entityId),
            }
          : {}),
      },
    ],
    RebateCategory: () => [],
    RebateProgram: (i, rv) => [
      {
        title: t('vendor:rebateCategory.programs.title'),
        href: generatePath(routes.Member.BuyGuideDetail.RebatePrograms.path, rv),
      },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.BuyGuideRebateProgramDetail.path, rv) },
    ],
    VendorBuyingAgreementGeneralCondition: () => [],
    VendorBuyingAgreementGeneralConditionFile: () => [],
    VendorBuyingAgreementBuyOpportunity: () => [],
    VendorBuyingAgreementBuyOpportunityFile: () => [],
    VendorBuyingAgreementRebateCategory: () => [],
    VendorBuyingAgreementFile: () => [],
    Publication: (i, rv) => [
      { title: t('navigation:sections.news'), href: routes.Member.Cms.Publications.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.PublicationDetails.path, rv) },
    ],
    PublicationFile: (i, rv) => [
      {
        href: generatePath(routes.Member.PublicationDetails.path, rv),
        ...(rv.publicationId
          ? {
              downloadApi: () => cmsApi.getPublicationFile(rv.publicationId!, i.entityId),
            }
          : {}),
      },
    ],
    StaticPage: (i, rv) => [
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.StaticPageDetails.path, rv) },
    ],
    StaticPageFile: (i, rv) => [
      {
        href: generatePath(routes.Member.StaticPageDetails.path, rv),
        ...(rv.staticPageId
          ? {
              downloadApi: () => cmsApi.getStaticPageFile(rv.staticPageId!, i.entityId),
            }
          : {}),
      },
    ],
    HelpTraining: (i, rv) => [
      { title: t('cms:sections.helpTrainings'), href: routes.Member.Help.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.Help.Detail.path, rv) },
    ],
    HelpTrainingFile: (i, rv) => [
      {
        href: generatePath(routes.Member.Help.Detail.path, rv),
        ...(rv.helpTrainingId
          ? {
              downloadApi: () => cmsApi.getHelpTrainingFile(rv.helpTrainingId!, i.entityId),
            }
          : {}),
      },
    ],
    VendorBuyOpportunity: (i, rv) => [
      {
        title: t('vendor:sections.buyOpportunities'),
        href: generatePath(routes.Member.BuyGuideDetail.BuyOpportunities.path, rv),
      },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Member.BuyGuideBuyOpportunityDetail.path, rv) },
    ],
    VendorBuyOpportunityFile: (i, rv) => [
      {
        href: generatePath(routes.Member.BuyGuideBuyOpportunityDetail.path, rv),
        ...(rv.vendorId && rv.vendorBuyOpportunityId
          ? {
              downloadApi: () => vendorApi.getBuyOpportunityFile(rv.vendorId!, rv.vendorBuyOpportunityId!, i.entityId),
            }
          : {}),
      },
    ],
    ForestProductGroup: () => [
      { title: t('navigation:sections.forestProducts'), href: routes.Member.ForestProducts.path },
      { title: t('forestProduct:sections.prices'), href: routes.Member.ForestProducts.Prices.path },
    ],
  },
  VendorUser: {
    Vendor: () => [],
    VendorBuyingAgreement: (i, rv) => [
      { title: t('vendor:sections.buyingAgreements'), href: routes.Vendor.BuyingAgreements.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Vendor.BuyingAgreement.path, rv) },
    ],
    VendorBuyingAgreementGeneralCondition: (i, rv) => [
      {
        title: t('vendor:generalCondition.title'),
        href: generatePath(routes.Vendor.BuyingAgreement.GeneralConditions.path, rv),
      },
    ],
    VendorBuyingAgreementGeneralConditionFile: (i, rv) => [
      {
        href: generatePath(routes.Vendor.BuyingAgreement.GeneralConditions.path, rv),
        ...(rv.vendorId && rv.vendorBuyingAgreementId && rv.vendorGeneralConditionId
          ? {
              downloadApi: () =>
                vendorApi.getBuyingAgreementGeneralConditionFile(
                  rv.vendorId!,
                  rv.vendorBuyingAgreementId!,
                  rv.vendorGeneralConditionId!,
                  i.entityId,
                ),
            }
          : {}),
      },
    ],
    VendorBuyingAgreementBuyOpportunity: (i, rv) => [
      {
        title: t('vendor:buyOpportunities.title'),
        href: generatePath(routes.Vendor.BuyingAgreement.BuyOpportunities.path, rv),
      },
      {
        title: getTranslation(i, 'name'),
        href: generatePath(routes.Vendor.BuyingAgreementBuyOpportunity.path, rv),
      },
    ],
    VendorBuyingAgreementBuyOpportunityFile: (i, rv) => [
      {
        href: generatePath(routes.Vendor.BuyingAgreementBuyOpportunity.path, rv),
        ...(rv.vendorId && rv.vendorBuyingAgreementId && rv.buyOpportunityId
          ? {
              downloadApi: () =>
                vendorApi.getBuyingAgreementBuyOpportunityFile(
                  rv.vendorId!,
                  rv.vendorBuyingAgreementId!,
                  rv.buyOpportunityId!,
                  i.entityId,
                ),
            }
          : {}),
      },
    ],
    VendorBuyingAgreementRebateCategory: (i, rv) => [
      {
        title: t('vendor:rebateCategory.title'),
        href: generatePath(routes.Vendor.BuyingAgreement.RebateCategories.path, rv),
      },
      {
        title: getTranslation(i, 'name'),
        href: generatePath(routes.Vendor.BuyingAgreementRebateCategory.path, rv),
      },
    ],
    VendorBuyingAgreementFile: (i, rv) => [
      {
        href: generatePath(routes.Vendor.BuyingAgreement.Documents.path, rv),
        ...(rv.vendorId && rv.vendorBuyingAgreementId
          ? {
              downloadApi: () =>
                vendorApi.getBuyingAgreementFile(rv.vendorId!, rv.vendorBuyingAgreementId!, i.entityId),
            }
          : {}),
      },
    ],
    VendorBuyOpportunity: () => [],
    VendorBuyOpportunityFile: () => [],
    VendorContact: () => [
      {
        title: t('vendor:contact.title'),
        href: routes.Vendor.Contacts.path,
      },
    ],
    VendorGeneralCondition: () => [],
    VendorGeneralConditionFile: () => [],
    VendorLocation: () => [
      {
        title: t('vendor:sections.locations'),
        href: routes.Vendor.Locations.path,
      },
    ],
    VendorDocument: (i, rv) => [
      {
        title: t('vendor:sections.documents'),
        href: routes.Vendor.Documents.path,
        ...(rv.vendorId
          ? {
              downloadApi: () => vendorApi.getDocumentFile(rv.vendorId!, i.entityId),
            }
          : {}),
      },
    ],
    VendorTier: () => [],
    VendorGrowth: () => [],
    Member: (i, rv) => [
      { title: t('member:title'), href: routes.Vendor.Member.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Vendor.Member.Detail.path, rv) },
    ],
    MemberContact: (i, rv) => [
      {
        title: t('member:contact.title'),
        href: generatePath(routes.Vendor.Member.Detail.Contacts.path, rv),
      },
    ],
    Office: (i, rv) => [
      { title: t('contact:office.title'), href: routes.Vendor.TBMContacts.Offices.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Vendor.OfficeDetails.path, rv) },
    ],
    InternalContact: (i, rv) => [
      { title: t('contact:internalContact.title'), href: routes.Vendor.TBMContacts.InternalContacts.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Vendor.InternalContactDetails.path, rv) },
    ],
    RebateCategory: () => [],
    RebateProgram: () => [],
    Publication: (i, rv) => [
      { title: t('navigation:sections.news'), href: routes.Vendor.Cms.Publications.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Vendor.PublicationDetails.path, rv) },
    ],
    PublicationFile: (i, rv) => [
      {
        href: generatePath(routes.Vendor.PublicationDetails.path, rv),
        ...(rv.publicationId
          ? {
              downloadApi: () => cmsApi.getPublicationFile(rv.publicationId!, i.entityId),
            }
          : {}),
      },
    ],
    StaticPage: () => [],
    StaticPageFile: () => [],
    HelpTraining: (i, rv) => [
      { title: t('navigation:sections.help'), href: routes.Vendor.Help.path },
      { title: getTranslation(i, 'name'), href: generatePath(routes.Vendor.Help.Detail.path, rv) },
    ],
    HelpTrainingFile: (i, rv) => [
      {
        href: generatePath(routes.Vendor.Help.Detail.path, rv),
        ...(rv.helpTrainingId
          ? {
              downloadApi: () => cmsApi.getHelpTrainingFile(rv.helpTrainingId!, i.entityId),
            }
          : {}),
      },
    ],
    ForestProductGroup: () => [],
  },
};

export const entityTypePermissions: Record<UserType, Record<EntityType, PermissionKey[]>> = {
  InternalUser: {
    [EntityType.Vendor]: [PermissionKey.VendorViewVendors],
    [EntityType.VendorContact]: [PermissionKey.VendorViewContacts],
    [EntityType.VendorLocation]: [PermissionKey.VendorViewContacts],
    [EntityType.VendorGeneralCondition]: [PermissionKey.VendorViewConditions],
    [EntityType.VendorGeneralConditionFile]: [PermissionKey.VendorViewConditions],
    [EntityType.RebateCategory]: [PermissionKey.VendorViewRebates],
    [EntityType.RebateProgram]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorTier]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorGrowth]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorDocument]: [PermissionKey.VendorViewFiles],
    [EntityType.VendorBuyOpportunity]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorBuyOpportunityFile]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorBuyingAgreement]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementGeneralCondition]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementGeneralConditionFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementBuyOpportunity]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementBuyOpportunityFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementRebateCategory]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.Member]: [PermissionKey.MemberViewMembers],
    [EntityType.MemberContact]: [PermissionKey.MemberViewMembers],
    [EntityType.Publication]: [PermissionKey.CmsNewsView],
    [EntityType.PublicationFile]: [PermissionKey.CmsNewsView],
    [EntityType.StaticPage]: [PermissionKey.CmsStaticView],
    [EntityType.StaticPageFile]: [PermissionKey.CmsStaticView],
    [EntityType.HelpTraining]: [PermissionKey.CmsHelpView],
    [EntityType.HelpTrainingFile]: [PermissionKey.CmsHelpView],
    [EntityType.InternalContact]: [],
    [EntityType.Office]: [],
    [EntityType.ForestProductGroup]: [PermissionKey.CommodityPriceView],
  },
  MemberUser: {
    [EntityType.Vendor]: [PermissionKey.VendorViewVendors],
    [EntityType.VendorContact]: [PermissionKey.VendorViewContacts],
    [EntityType.VendorLocation]: [PermissionKey.VendorViewContacts],
    [EntityType.VendorGeneralCondition]: [PermissionKey.VendorViewConditions],
    [EntityType.VendorGeneralConditionFile]: [PermissionKey.VendorViewConditions],
    [EntityType.RebateCategory]: [PermissionKey.VendorViewPrograms],
    [EntityType.RebateProgram]: [PermissionKey.VendorViewPrograms],
    [EntityType.VendorDocument]: [PermissionKey.VendorViewFiles],
    [EntityType.VendorBuyOpportunity]: [PermissionKey.VendorViewBuys],
    [EntityType.VendorBuyOpportunityFile]: [PermissionKey.VendorViewBuys],
    [EntityType.VendorBuyingAgreement]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementGeneralCondition]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementGeneralConditionFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementBuyOpportunity]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementBuyOpportunityFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementRebateCategory]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorTier]: [PermissionKey.VendorViewRebateTiersGrowths],
    [EntityType.VendorGrowth]: [PermissionKey.VendorViewRebateTiersGrowths],
    [EntityType.Member]: [PermissionKey.MemberViewMembers],
    [EntityType.MemberContact]: [PermissionKey.MemberViewMembers],
    [EntityType.Publication]: [PermissionKey.CmsNewsView],
    [EntityType.PublicationFile]: [PermissionKey.CmsNewsView],
    [EntityType.StaticPage]: [PermissionKey.CmsStaticView],
    [EntityType.StaticPageFile]: [PermissionKey.CmsStaticView],
    [EntityType.HelpTraining]: [PermissionKey.CmsHelpView],
    [EntityType.HelpTrainingFile]: [PermissionKey.CmsHelpView],
    [EntityType.InternalContact]: [],
    [EntityType.Office]: [],
    [EntityType.ForestProductGroup]: [PermissionKey.CommodityPriceView],
  },
  VendorUser: {
    [EntityType.Vendor]: [PermissionKey.VendorViewVendors],
    [EntityType.VendorContact]: [PermissionKey.VendorViewContacts],
    [EntityType.VendorLocation]: [PermissionKey.VendorViewContacts],
    [EntityType.VendorGeneralCondition]: [PermissionKey.VendorViewConditions],
    [EntityType.VendorGeneralConditionFile]: [PermissionKey.VendorViewConditions],
    [EntityType.RebateCategory]: [PermissionKey.VendorViewRebates],
    [EntityType.RebateProgram]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorDocument]: [PermissionKey.VendorViewFiles],
    [EntityType.VendorBuyOpportunity]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorBuyOpportunityFile]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorBuyingAgreement]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementGeneralCondition]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementGeneralConditionFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementBuyOpportunity]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementBuyOpportunityFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementRebateCategory]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorBuyingAgreementFile]: [PermissionKey.VendorViewVBAs],
    [EntityType.VendorTier]: [PermissionKey.VendorViewRebates],
    [EntityType.VendorGrowth]: [PermissionKey.VendorViewRebates],
    [EntityType.Member]: [PermissionKey.MemberViewMembers],
    [EntityType.MemberContact]: [PermissionKey.MemberViewMembers],
    [EntityType.Publication]: [PermissionKey.CmsNewsView],
    [EntityType.PublicationFile]: [PermissionKey.CmsNewsView],
    [EntityType.StaticPage]: [PermissionKey.CmsStaticView],
    [EntityType.StaticPageFile]: [PermissionKey.CmsStaticView],
    [EntityType.HelpTraining]: [PermissionKey.CmsHelpView],
    [EntityType.HelpTrainingFile]: [PermissionKey.CmsHelpView],
    [EntityType.InternalContact]: [],
    [EntityType.Office]: [],
    [EntityType.ForestProductGroup]: [PermissionKey.CommodityPriceView],
  },
};

export const entityTypeToSearchCategories: Record<EntityType, SearchCategory> = {
  [EntityType.Vendor]: SearchCategory.Vendors,
  [EntityType.VendorContact]: SearchCategory.Vendors,
  [EntityType.VendorLocation]: SearchCategory.Vendors,
  [EntityType.VendorGeneralCondition]: SearchCategory.Vendors,
  [EntityType.VendorGeneralConditionFile]: SearchCategory.Vendors,
  [EntityType.RebateCategory]: SearchCategory.Vendors,
  [EntityType.RebateProgram]: SearchCategory.Vendors,
  [EntityType.VendorTier]: SearchCategory.Vendors,
  [EntityType.VendorGrowth]: SearchCategory.Vendors,
  [EntityType.VendorDocument]: SearchCategory.Vendors,
  [EntityType.VendorBuyOpportunity]: SearchCategory.Vendors,
  [EntityType.VendorBuyOpportunityFile]: SearchCategory.Vendors,
  [EntityType.VendorBuyingAgreement]: SearchCategory.Vendors,
  [EntityType.VendorBuyingAgreementGeneralCondition]: SearchCategory.Vendors,
  [EntityType.VendorBuyingAgreementGeneralConditionFile]: SearchCategory.Vendors,
  [EntityType.VendorBuyingAgreementBuyOpportunity]: SearchCategory.Vendors,
  [EntityType.VendorBuyingAgreementBuyOpportunityFile]: SearchCategory.Vendors,
  [EntityType.VendorBuyingAgreementRebateCategory]: SearchCategory.Vendors,
  [EntityType.VendorBuyingAgreementFile]: SearchCategory.Vendors,
  [EntityType.Member]: SearchCategory.Members,
  [EntityType.MemberContact]: SearchCategory.Members,
  [EntityType.Publication]: SearchCategory.EventsAndPublications,
  [EntityType.PublicationFile]: SearchCategory.EventsAndPublications,
  [EntityType.StaticPage]: SearchCategory.EventsAndPublications,
  [EntityType.StaticPageFile]: SearchCategory.EventsAndPublications,
  [EntityType.HelpTraining]: SearchCategory.EventsAndPublications,
  [EntityType.HelpTrainingFile]: SearchCategory.EventsAndPublications,
  [EntityType.InternalContact]: SearchCategory.InternalContacts,
  [EntityType.Office]: SearchCategory.InternalContacts,
  [EntityType.ForestProductGroup]: SearchCategory.ForestProducts,
};
