import { object, string } from 'yup';
import { MAX_SHORT_TEXT_LENGTH, MAX_URL_LENGTH } from '../../constants';
import { MemberEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema } from '../../types';
import { AddressSchema, EmailAddressSchema, PhoneNumberSchema } from '../Contact';

export const MemberChangeRequestSchema = (): EntityFormSchema<MemberEntity> => ({
  schema: object({
    name: string().required().max(MAX_SHORT_TEXT_LENGTH),
    website: string().notRequired().nullable().max(MAX_URL_LENGTH),
    address: AddressSchema().schema,
  })
    .concat(PhoneNumberSchema().schema)
    .concat(EmailAddressSchema().schema),
  errorCodeMap: {
    [ApiErrorCodes.MEMBER_NAME_NOT_UNIQUE]: { key: 'name', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.MEMBER_NUMBER_LOCATION_NOT_UNIQUE]: { key: 'location', message: 'common:error.fieldUnique' },
  },
});
