import { Box, FormHelperText, Grid, Tab, Tabs, lighten } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { FileEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { LanguageCode, Styles, TranslationLanguage } from '../../../types';
import { Attachments } from '../Base/Attachments/Attachments';

const style: Styles = {
  dirty: {
    backgroundColor: lighten(palette.secondary.orange, 0.85),
    border: '1px solid ' + palette.secondary.orange,
    borderRadius: 3,
    padding: 2,
  },
  tabContainer: {
    textAlign: 'right',
    height: 0,
    mt: -0.5,
  },
  tabs: {
    display: 'inline-block',
    borderBottom: 'none',
    mt: 0,
    mr: 1,
  },
  tab: {
    minWidth: 0,
    minHeight: 0,
    px: 1,
    py: 0.5,
  },
};

interface ControlledTranslatedAttachmentProps
  extends Omit<React.ComponentProps<typeof Attachments>, 'onChange' | 'files' | 'maxCount'> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  hideLabel?: boolean;
  required?: boolean;
  isFrRequired?: boolean;
  languageDispatch?: [TranslationLanguage, Dispatch<SetStateAction<TranslationLanguage>>];
  hideLanguageTab?: boolean;
}

export const ControlledTranslatedAttachment = ({
  name,
  control,
  required,
  label,
  isFrRequired,
  languageDispatch,
  hideLanguageTab,
  ...props
}: ControlledTranslatedAttachmentProps) => {
  const { t, currentLanguage } = useInternationalization();
  const currentLanguageDispatch = useState<TranslationLanguage>(
    currentLanguage === LanguageCode.fr ? TranslationLanguage.Fr : TranslationLanguage.En,
  );

  const [language, setLanguage] = languageDispatch ?? currentLanguageDispatch;

  const readOnly = control._options.context?.readOnly || props.readOnly;

  useEffect(() => {
    setLanguage(currentLanguage === LanguageCode.fr ? TranslationLanguage.Fr : TranslationLanguage.En);
  }, [currentLanguage, setLanguage, readOnly]);

  const { trigger } = useEntityFormContext();

  const languageOptions = useEnumList(TranslationLanguage, 'common:translationLanguage');
  const isFr = language === TranslationLanguage.Fr;

  return (
    <Controller
      control={control}
      key={`${name}_${language}`}
      name={`${name}_${language}`}
      // label={`${label} (${languageOptions.find((option) => option.value === language)?.label})`}
      render={({ field: { onChange, value, onBlur }, fieldState: { error }, formState }) => {
        const onFileChange = (files: File[]) => {
          onChange(files[0] ?? null);
          trigger(`${name}_${isFr ? TranslationLanguage.En : TranslationLanguage.Fr}`);
          onBlur();
        };

        const hasFileChanged = () => {
          const original = formState.defaultValues[name] as FileEntity;
          const current = value as FileEntity;
          return original?.id !== current?.id && !control._options.context?.readOnly;
        };

        return (
          <Box sx={control._options.context?.variant === 'changeRequest' && hasFileChanged() ? style.dirty : undefined}>
            <Attachments
              {...props}
              onChange={onFileChange}
              label={
                readOnly
                  ? `${label} (${languageOptions.find((option) => option.value === language)?.label})`
                  : `${label} ${(required && !isFr) || (isFr && isFrRequired) ? '*' : ''}`
              }
              maxCount={1}
              files={value ? [value] : []}
              readOnly={readOnly}
              error={!!error}
              helperText={error?.message && t(error?.message, { name: label })}
              hideLabel={props.hideLabel}
              contentBesideTitle={
                !readOnly && !hideLanguageTab ? (
                  <Grid item xs={12} sx={[style.tabContainer, props.hideLabel ? style.noLabelTabContainer : {}]}>
                    <Tabs
                      value={language}
                      onChange={(_, value: TranslationLanguage) => setLanguage(value)}
                      sx={style.tabs}
                    >
                      {languageOptions.map((option) => (
                        <Tab key={option.value} value={option.value} label={option.label} sx={style.tab} />
                      ))}
                    </Tabs>
                  </Grid>
                ) : undefined
              }
            />
            {languageOptions.map((option) => {
              if (option.value === language) return null;
              const error = control.getFieldState(`${name}_${option.value}`).error;
              return (
                error?.message && (
                  <FormHelperText key={option.value} error>
                    {t(error.message, { name: `${label} (${option.label})` })}
                  </FormHelperText>
                )
              );
            })}
          </Box>
        );
      }}
    />
  );
};
