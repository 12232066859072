import { ProfileEntity } from '../models';
import { User } from '../types';

export function removeLocalUser() {
  localStorage.removeItem('user');
}

export function setLocalUser(user: User) {
  localStorage.setItem('user', JSON.stringify(user));
  setLocalMemberId(user.memberId);
}

export function setLocalMemberId(memberId: number | null) {
  if (!memberId) {
    localStorage.removeItem('memberId');
  } else {
    localStorage.setItem('memberId', memberId.toString());
  }
}

export function getLocalUser(): User | null {
  const user = localStorage.getItem('user') as string;
  if (user) {
    const parsedUser = JSON.parse(user);
    return { ...parsedUser, profile: new ProfileEntity(parsedUser.profile) };
  }
  return null;
}

export function getLocalMemberId(): string | null {
  return localStorage.getItem('memberId');
}
