import { useTranslation } from 'react-i18next';
import { vendor as vendorApi } from '../../../api';
import { useApi, useAuth } from '../../../hooks';
import { VendorPaymentScheduleSchema } from '../../../schemas/Vendor';
import { PermissionKey } from '../../../types';
import { EditCardSkeleton } from '../../Card';
import { PaymentScheduleForm } from './PaymentScheduleForm';

export const PaymentScheduleVendor = () => {
  const { hasPermissions } = useAuth();
  const { t } = useTranslation();
  const { data, isLoading, refresh } = useApi(vendorApi.getCurrentPaymentSchedule, { callImmediately: true });
  const { call } = useApi(vendorApi.updatePaymentScheduleChangeRequest, { successKey: 'common:success.save' });

  return (
    <EditCardSkeleton
      readOnly={!hasPermissions(PermissionKey.VendorEditFinancial)}
      entity={data}
      isLoading={isLoading}
      title={t('vendor:financials.sections.paymentSchedule')}
      schema={VendorPaymentScheduleSchema()}
      onSave={call}
      onConfirm={() => refresh()}
      showModifiedDate
      isChangeRequest
    >
      <PaymentScheduleForm />
    </EditCardSkeleton>
  );
};
