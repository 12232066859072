import { useContext, useEffect, useMemo } from 'react';
import { vendor as vendorApi } from '../../api';
import { MAX_PAGINATION_FILTER } from '../../constants';
import { EntityFormProvider, VendorChangeRequestContext } from '../../contexts';
import { useApi, useEntityForm } from '../../hooks';
import { FileEntity, VendorDocumentEntity } from '../../models';
import { VendorDocumentSchema } from '../../schemas';
import { ChangeRequestTrigger } from '../../types';
import { downloadBlob } from '../../utils/helper';
import { EditDrawerChangeRequest } from '../EditDrawerChangeRequest';
import { VendorDocumentForm } from '../Vendor/Documents';
import { ChangeRequestDrawerHeader } from './ChangeRequestDrawerHeader';

export const VendorDocumentChangeRequest = () => {
  const { changeRequest: vendorChangeRequest, isAfter } = useContext(VendorChangeRequestContext);
  const { call: getFileChangeRequest } = useApi(vendorApi.getDocumentFileChangeRequest, null);
  const { call: getFile } = useApi(vendorApi.getDocumentFile, null);
  const { data, isLoading } = useApi(vendorApi.getDocumentChangeRequest, null, vendorChangeRequest.id);
  const { call: update } = useApi(vendorApi.approvedOrRejectDocumentRequest, { successKey: 'common:success.save' });
  const entity = useMemo(() => new VendorDocumentEntity(), []);
  const { data: rebateCategories, isLoading: isRebateLoading } = useApi(
    vendorApi.getAllRebateCategories,
    null,
    vendorChangeRequest.vendor.id,
    MAX_PAGINATION_FILTER,
  );

  const { handleSubmit, reset, setValues, isSubmitDisabled, form, handleError } = useEntityForm<VendorDocumentEntity>(
    data?.entity || entity,
    VendorDocumentSchema(),
    { variant: 'changeRequest', readOnly: !isAfter || vendorChangeRequest.isDeleted },
  );

  useEffect(() => {
    if (data) {
      if (isAfter) {
        setValues(!vendorChangeRequest.isDeleted ? data?.changeRequest || entity : entity);
      } else {
        reset();
      }
    }
  }, [data, isAfter, setValues, reset, entity, vendorChangeRequest.isDeleted]);

  const saveDrawer = (e: VendorDocumentEntity, trigger: ChangeRequestTrigger) => {
    return update(vendorChangeRequest.id, trigger, e);
  };

  const downloadFile = async (file: FileEntity) => {
    if (file.id) {
      const fileData = await (isAfter || !data?.entity
        ? getFileChangeRequest(vendorChangeRequest.id)
        : getFile(vendorChangeRequest.vendor.id, data.entity.id));
      if (fileData) {
        downloadBlob(file.name, fileData);
      }
    }
  };

  return (
    <EntityFormProvider {...form} isLoading={isLoading || !data || isRebateLoading || !rebateCategories}>
      <EditDrawerChangeRequest
        open={true}
        entity={entity}
        handleSubmit={handleSubmit}
        handleError={handleError}
        isSubmitDisabled={vendorChangeRequest.isDeleted ? false : isSubmitDisabled}
        reset={reset}
        onSave={saveDrawer}
      >
        <ChangeRequestDrawerHeader />
        <VendorDocumentForm downloadFile={downloadFile} rebateCategories={rebateCategories?.data} />
      </EditDrawerChangeRequest>
    </EntityFormProvider>
  );
};
