import axios from 'axios';
import { VendorBuyOpportunityEntity } from '../../models';
import { PaginationEntity, VendorBuyOpportunityFilter } from '../../types';

export const buyOpportunity = {
  getAllBuyOpportunities: async (
    vendorId: number,
    buyOpportunityFilter: VendorBuyOpportunityFilter,
  ): Promise<PaginationEntity<VendorBuyOpportunityEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/buyopportunities/search`, buyOpportunityFilter);
    return { ...data, data: data.data.map((v: VendorBuyOpportunityEntity) => new VendorBuyOpportunityEntity(v)) };
  },
  createBuyOpportunity: async (
    vendorId: number,
    entity: VendorBuyOpportunityEntity,
  ): Promise<VendorBuyOpportunityEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/buyopportunities`, entity);
    return new VendorBuyOpportunityEntity(data);
  },
  updateBuyOpportunity: async (
    vendorId: number,
    entity: VendorBuyOpportunityEntity,
  ): Promise<VendorBuyOpportunityEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/buyopportunities/${entity.id}`, entity);
    return new VendorBuyOpportunityEntity(data);
  },
  getBuyOpportunity: async (vendorId: number, buyOpportunityId: number): Promise<VendorBuyOpportunityEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/buyopportunities/${buyOpportunityId}`);
    return new VendorBuyOpportunityEntity(data);
  },
  archiveBuyOpportunity: async (
    vendorId: number,
    ids: number[],
    isArchived: boolean,
  ): Promise<VendorBuyOpportunityEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/buyopportunities/archive`, { isArchived, ids });
    return data.map((v: VendorBuyOpportunityEntity) => new VendorBuyOpportunityEntity(v));
  },
  deleteBuyOpportunityFiles: async (
    vendorId: number,
    buyOpportunityId: number,
    ids: number[],
  ): Promise<VendorBuyOpportunityEntity> => {
    const { data } = await axios.delete(
      `api/vendor/${vendorId}/buyopportunities/${buyOpportunityId}/files?${ids.map((id) => `fileIds=${id}`).join('&')}`,
    );
    return new VendorBuyOpportunityEntity(data);
  },
  uploadBuyOpportunityFiles: async (
    vendorId: number,
    vendorBuyOpportunityId: number,
    files: File[],
  ): Promise<VendorBuyOpportunityEntity> => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyopportunities/${vendorBuyOpportunityId}/files`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    return new VendorBuyOpportunityEntity(data);
  },
  getBuyOpportunityFile: async (vendorId: number, buyOpportunityId: number, fileId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/buyopportunities/${buyOpportunityId}/files/${fileId}`, {
      responseType: 'blob',
    });
    return data;
  },
};
