import { array, object, string } from 'yup';

export const PhoneNumberSchema = () => ({
  schema: object({
    phoneNumbers: array().of(
      object({
        number: string()
          .required()
          .matches(/^\d{10}$/, 'common:error.fieldInvalid'),
      }),
    ),
  }),
});
