import { KeyboardArrowRightRounded } from '@mui/icons-material';
import { Box, Link, Stack, Typography } from '@mui/material';
import { palette } from '../styles/palette';
import { Breadcrumb, Styles } from '../types';

const style: Styles = {
  wrapper: {
    py: 2,
  },
  breadcrumbText: {
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  searchBreadcrumbText: {
    color: palette.grey[500],
    fontWeight: 500,
    ':hover': {
      color: palette.primary.main,
    },
  },
  seperator: {
    pr: 0.2,
    pl: 0.5,
  },
  linkSearchVariant: {
    ':hover': {
      textDecorationColor: palette.primary.main,
    },
  },
};

interface BreadcrumbProps {
  breadcrumbs: Breadcrumb[];
  variant?: 'page' | 'search';
}

export const Breadcrumbs = ({ breadcrumbs, variant = 'page' }: BreadcrumbProps) => (
  <Box sx={variant === 'page' ? style.wrapper : undefined}>
    <Stack
      direction="row"
      alignItems="center"
      component="span"
      spacing={variant === 'search' ? 0.5 : 0.25}
      flexWrap="wrap"
    >
      {breadcrumbs.map((b, i) =>
        b.href ? (
          <Stack
            direction="row"
            alignItems={variant === 'search' ? 'baseline' : undefined}
            spacing={variant === 'search' ? 0.5 : 0.25}
            key={i.toString()}
          >
            <Link
              href={b.href}
              underline={variant === 'page' || variant === 'search' ? 'hover' : undefined}
              sx={variant === 'search' ? style.linkSearchVariant : undefined}
            >
              <Typography
                variant="breadcrumb"
                component="span"
                sx={[style.breadcrumbText, variant === 'search' ? style.searchBreadcrumbText : {}]}
              >
                {b.title}
              </Typography>
            </Link>
            {variant === 'page' ? (
              <KeyboardArrowRightRounded fontSize="small" htmlColor={palette.grey[400]} />
            ) : (
              i !== breadcrumbs.length - 1 &&
              !!breadcrumbs[i + 1].title && (
                <Typography
                  sx={style.seperator}
                  variant="breadcrumb"
                  component="span"
                  color={palette.grey[400]}
                  fontWeight={variant == 'search' ? 500 : undefined}
                >
                  /
                </Typography>
              )
            )}
          </Stack>
        ) : (
          <Typography
            key={i.toString()}
            variant="breadcrumb"
            component="span"
            color={palette.grey[400]}
            sx={style.seperator}
          >
            {b.title}
          </Typography>
        ),
      )}
    </Stack>
  </Box>
);
