import { Box, SelectChangeEvent } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { vendor as vendorApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { useApi, useAuth, useEnumList, useInternationalization } from '../../hooks';
import { VendorChangeRequestEntity } from '../../models';
import {
  ChangeRequestState,
  OrderBy,
  PermissionKey,
  Styles,
  VendorChangeRequestEntityType,
  VendorChangeRequestFilter,
} from '../../types';
import { formatShortDate } from '../../utils/helper';
import { FilterContainer } from '../Container';
import { Autocomplete, Select } from '../Form';
import { LayoutPage } from '../Layout';
import { MenuItem } from '../Menu';
import { RequirePermission } from '../Permission';
import { Table, TableColumn } from '../Table';
import { VendorChangeRequest } from './VendorChangeRequest';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      md: '220px',
    },
  },
  selectLargeContainer: {
    width: {
      xs: '100%',
      md: '320px',
    },
  },
};

const defaultFilter: VendorChangeRequestFilter = {
  vendorId: undefined,
  vendorNameOrNumber: undefined,
  entityType: undefined,
  state: ChangeRequestState.Submitted,
};

export const VendorChangeRequests = () => {
  const { hasPermissions } = useAuth();
  const { t } = useInternationalization();
  const [entity, setEntity] = useState<VendorChangeRequestEntity>();
  const [filter, setFilter] = useState<VendorChangeRequestFilter>({
    ...defaultFilter,
    ...DEFAULT_PAGINATION_FILTER,
  });
  const { data, isLoading, refresh } = useApi(vendorApi.getAllChangeRequests, null, filter);
  const stateOptions = useEnumList(ChangeRequestState, 'changeRequest:state', true);
  const entityTypeOptions = useEnumList(VendorChangeRequestEntityType, 'changeRequest:vendorEntityType', true);

  const canApprove = useCallback(
    (value: VendorChangeRequestEntityType) => {
      return (
        ((value === VendorChangeRequestEntityType.VendorContact ||
          value === VendorChangeRequestEntityType.VendorLocation) &&
          hasPermissions(PermissionKey.VendorApproveContacts)) ||
        ((value === VendorChangeRequestEntityType.VendorInvoicingInformation ||
          value === VendorChangeRequestEntityType.VendorPaymentSchedule ||
          value === VendorChangeRequestEntityType.VendorTaxInformation) &&
          hasPermissions(PermissionKey.VendorApproveFinancial)) ||
        (value === VendorChangeRequestEntityType.VendorDocument && hasPermissions(PermissionKey.VendorApproveFiles))
      );
    },
    [hasPermissions],
  );

  const filteredEntityTypeOptions = useMemo(
    () => entityTypeOptions.filter((option) => !option.value || canApprove(option.value)),
    [entityTypeOptions, canApprove],
  );

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const onStateChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, state: (event.target.value as ChangeRequestState) || undefined, pageNumber: 1 }));
  };

  const onEntityTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      entityType: (event.target.value as VendorChangeRequestEntityType) || undefined,
      pageNumber: 1,
    }));
  };

  const renderSearch = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <Box sx={style.selectLargeContainer}>
        <Autocomplete
          minSearchTextLength={1}
          value={filter.vendorNameOrNumber}
          noOptionsText={t('vendor:search.notFound')}
          apiFunction={vendorApi.getSuggestions}
          label={t('changeRequest:search.vendorNameOrNumber')}
          placeholder={t('vendor:search.placeholder')}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
          }}
          onApplySearch={(searchText) =>
            setFilter((prev) => ({ ...prev, vendorNameOrNumber: searchText ?? undefined, pageNumber: 1 }))
          }
          onSelectItem={(item) =>
            setFilter((prev) => ({ ...prev, vendorId: item?.id, vendorNameOrNumber: item?.name, pageNumber: 1 }))
          }
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={stateOptions}
          value={filter.state ?? ''}
          onChange={onStateChange}
          label={t('changeRequest:search.state')}
        />
      </Box>
      <Box sx={style.selectLargeContainer}>
        <Select
          displayEmpty
          options={filteredEntityTypeOptions}
          value={filter.entityType ?? ''}
          onChange={onEntityTypeChange}
          label={t('changeRequest:search.entityType')}
        />
      </Box>
    </FilterContainer>
  );

  const renderActionsMenuItems = (e: VendorChangeRequestEntity, onClick: () => void) => [
    ...(e.state === ChangeRequestState.Submitted && canApprove(e.entityType)
      ? [
          <MenuItem
            id="review"
            key="review"
            onClick={() => {
              onClick();
              setEntity(e);
            }}
          >
            {t('changeRequest:actions.review')}
          </MenuItem>,
        ]
      : []),
  ];

  const renderVendor = (entity: VendorChangeRequestEntity) => entity.vendor.name;

  const renderState = (entity: VendorChangeRequestEntity) => t(`changeRequest:state.${entity.state}`);

  const renderType = (entity: VendorChangeRequestEntity) => t(`changeRequest:type.${entity.type}`);

  const onCloseDrawer = () => {
    refresh();
    setEntity(undefined);
  };

  const onCancelDrawer = () => {
    setEntity(undefined);
  };

  const renderEntityType = (entity: VendorChangeRequestEntity) =>
    t(`changeRequest:vendorEntityType.${entity.entityType}`);

  return (
    <RequirePermission
      keys={[
        PermissionKey.VendorApproveContacts,
        PermissionKey.VendorApproveFinancial,
        PermissionKey.VendorApproveFiles,
      ]}
      any
    >
      <LayoutPage display="Tab" title={t('changeRequest:sections.vendor')}>
        <>
          {renderSearch()}
          <Table
            data={data ?? []}
            translationNamespace="changeRequest"
            paginationFilter={filter}
            onPaginationChange={onPaginationChange}
            sortColumns={filter.orderBy}
            onSortChange={onSortChange}
            isLoading={isLoading}
            actionMenuItems={renderActionsMenuItems}
          >
            <TableColumn type="custom" width="25%" id="vendor" sortable render={renderVendor} />
            <TableColumn type="custom" width="20%" id="entityType" sortable render={renderEntityType} />
            <TableColumn type="custom" width="10%" id="type" sortable render={renderType} />
            <TableColumn
              type="custom"
              sortable
              width="10%"
              id="createdDate"
              render={({ createdDate }: VendorChangeRequestEntity) => formatShortDate(createdDate)}
            />
            <TableColumn type="property" sortable width="10%" id="createdBy" align="center" />
            <TableColumn
              type="custom"
              sortable
              width="10%"
              id="reviewedDate"
              render={({ reviewedDate }: VendorChangeRequestEntity) => formatShortDate(reviewedDate)}
            />
            <TableColumn type="property" sortable width="10%" id="reviewedBy" align="center" />
            <TableColumn type="custom" width="15%" id="state" sortable render={renderState} />
          </Table>
        </>
        {entity && (
          <VendorChangeRequest vendorChangeRequest={entity} onConfirm={onCloseDrawer} onCancel={onCancelDrawer} />
        )}
      </LayoutPage>
    </RequirePermission>
  );
};
