import { number, object, string } from 'yup';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { RebateCategoryEntity } from '../../models';
import { EntityFormSchema } from '../../types';

export const VendorRebateCategoryDuplicateSchema = (): EntityFormSchema<RebateCategoryEntity> => ({
  schema: object().shape(
    {
      name_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
      name_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      vendorBuyingAgreementId: number().typeError('common:error.fieldRequired').required(),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
