import { createElement, isValidElement } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { Stack, SvgIconProps, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Styles } from '../../../types';
import { palette } from '../../../styles/palette';

const style: Styles = {
  icon: {
    fontSize: 20,
    color: palette.grey[500],
    mb: -0.75,
  },
};

interface ContainerProps extends PropsWithChildren {
  label?: string | JSX.Element;
  sx?: Styles[0];
  hideLabel?: boolean;
  icon?: SvgIconComponent | JSX.Element;
  contentBesideTitle?: JSX.Element;
}

export const Container = ({ label, icon, hideLabel, children, sx, contentBesideTitle }: ContainerProps) => (
  <Stack spacing={1} flex="1" sx={sx}>
    {label && !hideLabel && (
      <Stack direction="row" spacing={0.5} flexWrap="wrap" alignItems={'center'}>
        {contentBesideTitle}
        <Typography display="block" variant="label">
          {label}
        </Typography>
        {icon &&
          (isValidElement(icon) ? icon : createElement(icon as SvgIconComponent, { sx: style.icon } as SvgIconProps))}
      </Stack>
    )}
    {children}
  </Stack>
);
