import { Box, Grid } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { roleGroup as roleGroupApi } from '../../../api';
import { MAX_LONG_TEXT_LENGTH, MAX_PAGINATION_FILTER } from '../../../constants';
import { useEntityFormContext, useEnumList } from '../../../hooks';
import { UserEntity, VendorBuyingAgreementEntity } from '../../../models';
import { BuyOpportunityEntity } from '../../../models/Vendor/BuyOpportunityEntity';
import { FilterPeriod, OpportunityType, RoleGroup, Styles } from '../../../types';
import {
  ControlledCheckbox,
  ControlledEntitySelect,
  ControlledInput,
  ControlledSelect,
  ControlledTranslatedInput,
} from '../../Form';
import { PeriodForm } from '../../PeriodForm';
import { VendorBuyingAgreementSelect } from '../BuyingAgreements/VendorBuyingAgreementSelect';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
};

export const VendorBuyOpportunityForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const vendorId = Number(params.vendorId);
  const { setValue, control, variant, watch } = useEntityFormContext<BuyOpportunityEntity>();
  const opportunityTypeWatch = watch('opportunityType');

  const vbaFilter = useCallback(
    (vba: VendorBuyingAgreementEntity) => vba.period === FilterPeriod.Current || vba.period === FilterPeriod.Upcoming,
    [],
  );

  const opportunityTypeOptions = useEnumList(OpportunityType, 'vendor:buyOpportunities.opportunity').filter(
    (o) => o.value !== OpportunityType.All,
  );

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        <Grid item xs={variant === 'drawer' ? 6 : 4}>
          <ControlledSelect
            onClear={() => setValue('opportunityType', null, { shouldDirty: true })}
            label={t('vendor:buyOpportunities.form.opportunity')}
            name="opportunityType"
            control={control}
            options={opportunityTypeOptions}
            required
          />
        </Grid>
        {variant === 'drawer' && <Grid item xs={6}></Grid>}
        <PeriodForm xs={variant === 'drawer' ? 4 : 2} />
        {(opportunityTypeWatch === OpportunityType.Booking || opportunityTypeWatch === OpportunityType.SpecialBuy) && (
          <VendorBuyingAgreementSelect vendorId={vendorId} filter={vbaFilter} xs={variant === 'drawer' ? 6 : 4} />
        )}
        <Grid item xs={variant === 'drawer' ? 6 : 4}>
          <ControlledEntitySelect
            label={t('vendor:buyOpportunities.form.responsibleBuyer')}
            name="responsibleBuyer"
            apiFunction={roleGroupApi.getUsers}
            apiParams={[RoleGroup.Buyer, MAX_PAGINATION_FILTER]}
            getOptionLabel={(entity: UserEntity) => entity.fullName}
            control={control}
            required
          />
        </Grid>
        {opportunityTypeWatch !== OpportunityType.Booking &&
          opportunityTypeWatch !== OpportunityType.SpecialBuy &&
          variant == 'drawer' && <Grid item xs={6} />}
        <Grid item xs={12}>
          <ControlledCheckbox
            label={t('vendor:buyOpportunities.form.isVisibleToMember')}
            name="isVisibleToMember"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={variant === 'drawer' ? 12 : 6}>
          <ControlledInput
            label={t('vendor:buyOpportunities.form.vendorNotes')}
            name="vendorNotes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={12} md={variant === 'drawer' ? 12 : 6}>
          <ControlledInput
            label={t('vendor:buyOpportunities.form.notes')}
            name="notes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={12} md={variant === 'drawer' ? 12 : 6}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
