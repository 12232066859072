import { Box, Grid } from '@mui/material';
import { useContext } from 'react';
import { useInternationalization } from '../../hooks';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';
import { formatShortDate } from '../../utils/helper';
import { StaticField } from '../Form';
import { BuyGuideVendorBuyOpportunityDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorBuyOpportunityDetailContext';

const style: Styles = {
  container: {
    pb: 4,
  },
  borderedBox: {
    borderTop: `solid 1px ${palette.grey[300]}`,
    '>div': {
      paddingTop: 3,
    },
  },
};

export const BuyGuideVendorBuyOpportunityForm = () => {
  const internationalization = useInternationalization();
  const { t, getTranslation } = internationalization;
  const { buyOpportunity } = useContext(BuyGuideVendorBuyOpportunityDetailContext);

  if (!buyOpportunity) {
    return null;
  }

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <StaticField
            label={t('buyGuide:buyOpportunities.form.opportunity')}
            value={t(`vendor:buyOpportunities.opportunity.${buyOpportunity.opportunityType}`)}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StaticField
            label={t('buyGuide:buyOpportunities.form.effectiveDate')}
            value={formatShortDate(buyOpportunity.effectiveDate)}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StaticField
            label={t('buyGuide:buyOpportunities.form.expiryDate')}
            value={formatShortDate(buyOpportunity.expiryDate)}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StaticField
            label={t('buyGuide:buyOpportunities.form.responsibleBuyer')}
            value={buyOpportunity.responsibleBuyer}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={style.borderedBox}>
            <Grid item xs={12}>
              <StaticField
                sx={style.staticField}
                label={t('buyGuide:buyOpportunities.form.notes')}
                value={getTranslation(buyOpportunity, 'memberNotes')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
