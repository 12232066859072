import { Box, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { categorization as categorizationApi, member as memberApi } from '../../api';
import { MemberContext } from '../../contexts';
import { useApi, useAuth, useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { MemberFilter, SelectOption, Styles, TranslationContext } from '../../types';
import { areArrayEquals } from '../../utils/helper';
import { FilterContainer } from '../Container';
import { ArchiveFilterSelect } from '../Filter/ArchiveFilterSelect';
import { MailSyncFilterSelect } from '../Filter/MailSyncFilterSelect';
import { Autocomplete, MultiSelect, Select } from '../Form';

const style: Styles = {
  memberOrVendorUserFilter: {
    mb: 2,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectContainer: {
    width: {
      xs: '100%',
      sm: '250px',
    },
  },
};

const defaultTextSearchFilter = { memberNameOrNumber: undefined };
const defaultAdvancedSearchFilter: MemberFilter = {
  membershipTypeIds: undefined,
  regionIds: undefined,
  isMarketing: undefined,
  isActive: undefined,
  isMyLocation: undefined,
  mailSyncErrorOnly: undefined,
};

export const MemberListSearch = () => {
  const { t, getTranslation } = useInternationalization();
  const { isInternalUser, isMemberUser, isVendorUser } = useAuth();
  const navigate = useNavigate();
  const { filter, setFilter } = useContext(MemberContext);
  const [isAdvancedMode, setIsAdvancedMode] = useState(
    !!filter.membershipTypeIds ||
      !!filter.regionIds ||
      !!filter.isMarketing ||
      !!filter.isMyLocation ||
      !!filter.mailSyncErrorOnly,
  );
  const membershipTypes = useApi(categorizationApi.getAll, null, 'membershipType', false);
  const regions = useApi(categorizationApi.getAll, null, 'region', false);

  useEffect(() => {
    if (
      !!filter.membershipTypeIds ||
      !!filter.regionIds ||
      !!filter.isMarketing ||
      !!filter.isMyLocation ||
      !!filter.mailSyncErrorOnly
    ) {
      setIsAdvancedMode(true);
    }
  }, [filter]);

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...(checked ? defaultTextSearchFilter : defaultAdvancedSearchFilter) }));
    setIsAdvancedMode(checked);
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const onBooleanChange = (event: SelectChangeEvent<unknown>, propertyName: keyof MemberFilter) => {
    const value = !event.target.value ? undefined : event.target.value === 'true';
    setFilter((prev) => ({ ...prev, [propertyName]: value, pageNumber: 1 }));
  };

  const onMembershipTypeBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.membershipTypeIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, membershipTypeIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const onRegionBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.regionIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, regionIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const getBooleanOptions = (context: TranslationContext = 'male'): SelectOption<string>[] => [
    { label: t('common:filter.all', { context }), value: '' },
    { label: t('common:boolean.true'), value: 'true' },
    { label: t('common:boolean.false'), value: 'false' },
  ];

  const getIsMyLocationOptions = (context: TranslationContext = 'male'): SelectOption<string>[] => [
    { label: t('common:filter.all', { context }), value: '' },
    { label: t('member:search.isMyLocation'), value: 'true' },
  ];

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear}>
      <Box sx={style.selectContainer}>
        <MultiSelect
          label={t('member:search.membershipTypeIds')}
          options={membershipTypes.data?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
          onConfirm={onMembershipTypeBlur}
          values={filter.membershipTypeIds || []}
        />
      </Box>
      <Box sx={style.selectContainer}>
        <MultiSelect
          label={t('member:search.regionIds')}
          options={regions.data?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
          onConfirm={onRegionBlur}
          values={filter.regionIds || []}
        />
      </Box>
      {isInternalUser && (
        <>
          <Box sx={style.selectSmallContainer}>
            <Select
              displayEmpty
              options={getBooleanOptions()}
              value={filter.isMarketing ?? ''}
              onChange={(e) => onBooleanChange(e, 'isMarketing')}
              label={t('member:search.isMarketing')}
            />
          </Box>
          <Box sx={style.selectSmallContainer}>
            <Select
              displayEmpty
              options={getBooleanOptions()}
              value={filter.isActive ?? ''}
              onChange={(e) => onBooleanChange(e, 'isActive')}
              label={t('member:search.isActive')}
            />
          </Box>
        </>
      )}
      {isMemberUser && (
        <Box sx={style.selectContainer}>
          <Select
            displayEmpty
            options={getIsMyLocationOptions()}
            value={filter.isMyLocation ?? ''}
            onChange={(e) => onBooleanChange(e, 'isMyLocation')}
            label={t('member:search.locations')}
          />
        </Box>
      )}
      <MailSyncFilterSelect filter={filter} setFilter={setFilter} />
    </FilterContainer>
  );

  const renderTextSearch = () => (
    <Autocomplete
      minSearchTextLength={1}
      value={filter.memberNameOrNumber}
      sx={style.textContainer}
      noOptionsText={t('member:search.notFound')}
      apiFunction={memberApi.getSuggestions}
      label={t('member:search.title')}
      placeholder={t('member:search.placeholder')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.fullNumber} - ${option.name}`)}
      onApplySearch={(searchText) =>
        setFilter((prev) => ({ ...prev, memberNameOrNumber: searchText ?? undefined, pageNumber: 1 }))
      }
      onSelectItem={(item) => {
        const basePath = isInternalUser
          ? routes.Admin.Member.Detail.path
          : isMemberUser
          ? routes.Member.Member.Detail.path
          : routes.Vendor.Member.Detail.path;
        return item && navigate(generatePath(basePath, { memberId: item.id }));
      }}
    />
  );

  return (
    <Stack sx={isMemberUser || isVendorUser ? style.memberOrVendorUserFilter : undefined}>
      <Stack spacing={3}>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark">
            {t('member:search.title')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('member:search.filterList')}
          </Typography>
        </Box>
        {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
      </Stack>
      {isInternalUser && <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />}
    </Stack>
  );
};
