import { Grid, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { role as roleApi, vendor as vendorApi } from '../../api';
import { TBM_MEMBER_SUPPORT_ROLE_ID } from '../../constants';
import { UserContext, UserDetailContext } from '../../contexts';
import { useApi, useAuth, useEntityFormContext, useEnumList, useInternationalization } from '../../hooks';
import { UserEntity, VendorEntity } from '../../models';
import { LanguageCode, SelectOption, TimeZoneCode, UserType } from '../../types';
import { phoneNumberFormatter } from '../../utils/formatters';
import { formatDateTime, formatShortDate } from '../../utils/helper';
import { ControlledAutocomplete, ControlledCheckbox, ControlledInput, ControlledSelect, StaticField } from '../Form';
import { UserMemberList } from './UserMemberList';

export const UserForm = () => {
  const { isInternalUser } = useAuth();
  const { t, getTranslation, timeZoneName } = useInternationalization();
  const { control, readOnly, variant, getValues, watch } = useEntityFormContext<UserEntity>();
  const { type: typeFromDetail } = useContext(UserDetailContext);
  const { type: typeFromList } = useContext(UserContext);
  const type = typeFromDetail ?? typeFromList;
  const { data: roles } = useApi(roleApi.getAll, null, type);

  const roleId = watch('roleId');
  const languageOptions = useEnumList(LanguageCode, 'common:language');
  const timeZoneOptions = useEnumList(TimeZoneCode, 'common:timeZone');

  const roleOptions = useMemo(() => {
    let values: SelectOption<string>[] = [];
    const roleOptions = roles?.map((role) => ({ value: role.id.toString(), label: getTranslation(role, 'name') }));
    if (roleOptions?.length) {
      values = [...roleOptions];
    }

    return values;
  }, [roles, getTranslation]);

  const isDrawer = useMemo(() => variant === 'drawer', [variant]);

  const getVendorValue = () => {
    if (getValues().vendor) {
      const vendor = new VendorEntity(getValues().vendor);
      return `${vendor.fullNumber} - ${vendor.name}`;
    }
    return undefined;
  };

  const getRoleValue = (id: unknown) => {
    const role = roles?.find((u) => u.id == id);
    if (role) {
      return getTranslation(role, 'name');
    }
    return '';
  };

  return (
    <Grid container rowSpacing={isDrawer ? 2 : 4} columnSpacing={2}>
      {(isDrawer || !readOnly) && (
        <>
          <Grid item xs={6}>
            <ControlledInput
              label={t('user:form.firstName')}
              name="firstName"
              control={control}
              variant="standard"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledInput
              label={t('user:form.lastName')}
              name="lastName"
              control={control}
              variant="standard"
              required
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={isDrawer ? 6 : 3}>
        <ControlledInput
          label={t('user:form.email')}
          name={`email`}
          control={control}
          linkType="email"
          required
          readOnly={variant === 'details'}
        />
      </Grid>
      <Grid item xs={6} md={isDrawer ? 4 : 2}>
        <ControlledSelect
          label={t('user:form.language')}
          name="language"
          options={languageOptions}
          control={control}
          defaultValue={LanguageCode.en}
          renderValue={(id) => t(`common:language.${id}`)}
        />
      </Grid>
      <Grid item xs={6} md={isDrawer ? 5 : 3} xl={isDrawer ? 4 : 3}>
        <ControlledSelect
          label={t('user:form.role')}
          placeholder={t('user:form.role')}
          name="roleId"
          renderValue={getRoleValue}
          options={roleOptions}
          control={control}
          required
        />
      </Grid>
      {isDrawer && <Grid item xs={8} />}
      {type === UserType.Vendor && (
        <Grid item xs={12} md={isDrawer ? 6 : readOnly ? 2 : 2.5}>
          <ControlledAutocomplete
            minSearchTextLength={1}
            control={control}
            name="vendorId"
            value={getVendorValue()}
            noOptionsText={t('user:form.search.vendor.notFound')}
            apiFunction={vendorApi.getSuggestions}
            apiExtraParams={[false]}
            label={t('user:form.vendor')}
            placeholder={t('user:form.search.vendor.placeholder')}
            getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.fullNumber} - ${option.name}`)}
            getOptionValue={(option) => option.id}
            required
          />
        </Grid>
      )}
      {isDrawer && type === UserType.Vendor && <Grid item xs={6} />}
      {variant !== 'drawer' && (
        <Grid item xs={6} md={1.5}>
          <ControlledCheckbox
            label={t('user:form.isAllowedAccess')}
            name="isAllowedAccess"
            control={control}
            variant="stacked"
          />
        </Grid>
      )}

      {!isDrawer && type === UserType.Internal && <Grid item xs={2} />}

      <Grid item xs={6} md={isDrawer ? 6 : 3}>
        <ControlledInput label={t('user:form.jobTitle')} name="jobTitle" control={control} />
      </Grid>
      <Grid item xs={6} md={isDrawer ? 6 : 2}>
        <ControlledInput label={t('user:form.department')} name="department" control={control} />
      </Grid>
      <Grid item xs={12} md={isDrawer ? 6 : readOnly ? 3 : 3}>
        <Grid container columnSpacing={0.5}>
          <Grid
            item
            xs={6}
            md={isDrawer ? 8 : readOnly ? 6.5 : 8}
            lg={isDrawer ? 8 : readOnly ? 6 : 8}
            xl={isDrawer ? 8 : readOnly ? 5 : 8}
          >
            <ControlledInput
              label={t('user:form.phoneOffice')}
              formatter={phoneNumberFormatter}
              name="phoneOffice.number"
              control={control}
              linkType="phone"
            />
          </Grid>
          <Grid item xs={6} md={isDrawer ? 4 : readOnly ? 4 : 4}>
            <ControlledInput
              label={t('user:form.officeExtension')}
              name="phoneOffice.extension"
              type="tel"
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={isDrawer ? 4 : readOnly ? 2 : 2}>
        <ControlledInput
          label={t('user:form.phoneMobile')}
          name="phoneMobile.number"
          control={control}
          linkType="phone"
          formatter={phoneNumberFormatter}
        />
      </Grid>
      <Grid item xs={6} md={isDrawer ? 6 : 4}>
        <ControlledSelect
          label={t('user:profile.timeZone')}
          name="timeZone"
          options={timeZoneOptions}
          control={control}
          defaultValue={TimeZoneCode.MountainTime}
          renderValue={(id) => t(`common:timeZone.${id}`)}
        />
      </Grid>
      {readOnly && (
        <Grid item xs={6} md={3}>
          <StaticField value={formatDateTime(getValues().lastLogin, timeZoneName)} label={t('user:form.lastLogin')} />
        </Grid>
      )}
      {readOnly && type === UserType.Member && (
        <Grid item xs={6} md={3}>
          <StaticField value={formatShortDate(getValues().lastVerifiedDate)} label={t('user:form.lastVerified')} />
        </Grid>
      )}

      {type === UserType.Member && roleId != TBM_MEMBER_SUPPORT_ROLE_ID && (
        <Grid item xs={12}>
          <UserMemberList />
        </Grid>
      )}
      {isInternalUser && (
        <>
          <Grid item xs={12}>
            <Typography variant="h3">{t('user:form.notes')}</Typography>
          </Grid>
          <Grid item xs={12} md={isDrawer ? 12 : 5}>
            <ControlledInput label={t('user:form.internalNotes')} name="notes" control={control} multiline rows={3} />
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Trans i18nKey="common:formHelperText.notes" />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
