import { FormHelperText, TextField, TextFieldProps } from '@mui/material';
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import { FocusEventHandler, ReactNode } from 'react';
import { useInternationalization } from '../../../hooks';
import { LanguageCode } from '../../../types';
import { Container } from './Container';

interface DatePickerProps extends Omit<MuiDatePickerProps<string, Date>, 'renderInput'> {
  label?: string;
  hideLabel?: boolean;
  error?: boolean;
  clearable?: boolean;
  helperText?: ReactNode;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  yearOnly?: boolean;
}

export const DatePicker = ({
  hideLabel,
  error,
  clearable,
  helperText,
  label,
  onBlur,
  yearOnly,
  ...props
}: DatePickerProps) => {
  const { currentLanguage } = useInternationalization();

  return (
    <Container label={label} hideLabel={hideLabel}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={currentLanguage === LanguageCode.fr ? fr : undefined}
      >
        <MuiDatePicker
          {...props}
          inputFormat={yearOnly ? 'y' : 'yyyy-MM-dd'}
          views={yearOnly ? ['year'] : undefined}
          componentsProps={{
            ...props.componentsProps,
            ...(clearable
              ? {
                  actionBar: {
                    actions: ['clear'],
                  },
                }
              : {}),
          }}
          renderInput={(params: TextFieldProps) => (
            <TextField
              {...params}
              onBlur={onBlur}
              inputProps={{
                ...params.inputProps,
                placeholder: '',
              }}
              InputProps={{
                ...params.InputProps,
                error,
              }}
            />
          )}
        />
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </LocalizationProvider>
    </Container>
  );
};
