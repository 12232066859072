import { PendingEntity } from '../PendingEntity';

export class VendorPaymentScheduleEntity extends PendingEntity {
  monthlyPaymentSchedule?: string;
  quarterlyPaymentSchedule?: string;
  semiAnnuallyPaymentSchedule?: string;
  annuallyPaymentSchedule?: string;
  paymentScheduleTerm_En?: string;
  paymentScheduleTerm_Fr?: string;
  paymentScheduleFOID?: string;
  notes?: string;
  vendorNotes?: string;
  vendorId?: number;

  constructor(entity?: VendorPaymentScheduleEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.monthlyPaymentSchedule = '';
      this.quarterlyPaymentSchedule = '';
      this.semiAnnuallyPaymentSchedule = '';
      this.annuallyPaymentSchedule = '';
      this.paymentScheduleTerm_En = '';
      this.paymentScheduleTerm_Fr = '';
      this.paymentScheduleFOID = '';
      this.notes = '';
      this.vendorNotes = '';
    }
  }
}
