import {
  AssignmentTurnedInRounded,
  Campaign,
  ExpandLessRounded,
  KeyboardDoubleArrowUpRounded,
  LocalActivityRounded,
  Newspaper,
  RemoveRounded,
  StarsRounded,
} from '@mui/icons-material';
import { palette } from '../styles/palette';
import { EventType, PublicationUrgency } from '../types';

export const getEventTypeBackgroundColor = (eventType: EventType) => {
  switch (eventType) {
    case EventType.Booking:
      return palette.secondary.green;
    case EventType.SpecialBuy:
      return palette.secondary.yellow;
    case EventType.PoolBuy:
      return palette.primary.deep;
    case EventType.CCI:
      return palette.secondary.orange;
    case EventType.Publication:
      return palette.primary.main;
  }
};

export const getEventTypeIcon = (eventType: EventType, color: string) => {
  switch (eventType) {
    case EventType.Booking:
      return <AssignmentTurnedInRounded htmlColor={color} fontSize="small" />;
    case EventType.SpecialBuy:
      return <StarsRounded htmlColor={color} fontSize="small" />;
    case EventType.PoolBuy:
      return <Campaign htmlColor={color} fontSize="small" />;
    case EventType.CCI:
      return <LocalActivityRounded htmlColor={color} fontSize="small" />;
    case EventType.Publication:
      return <Newspaper htmlColor={color} fontSize="small" />;
  }
};

export const getUrgencyIcon = (urgency: PublicationUrgency, color: string) => {
  switch (urgency) {
    case PublicationUrgency.Red:
      return <KeyboardDoubleArrowUpRounded htmlColor={color} fontSize="small" />;
    case PublicationUrgency.Yellow:
      return <ExpandLessRounded htmlColor={color} fontSize="small" />;
    case PublicationUrgency.Green:
      return <RemoveRounded htmlColor={color} fontSize="small" />;
  }
};
