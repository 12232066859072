import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { palette } from '../styles/palette';
import { Styles } from '../types';
import { Drawer } from './Drawer';

const style: Styles = {
  container: {
    minWidth: {
      xs: '100%',
      md: 700,
    },
    maxWidth: {
      xs: '100%',
      lg: '30vw',
    },
    backgroundColor: palette.white,
    borderTopLeftRadius: '12px',
    overflow: 'hidden',
    outline: 'none',
    flex: 1,
    marginTop: 1,
    spacing: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  containerLarge: {
    minWidth: {
      xs: '100%',
      lg: '90vw',
    },
    maxWidth: {
      xs: '100%',
      lg: '70vw',
    },
  },
  box: {
    px: 3,
    color: palette.white,
    alignItems: 'center',
  },
  actionBox: {
    minHeight: 78,
    backgroundColor: palette.primary.dark,
  },
  titleBox: {
    minHeight: 47,
    display: 'flex',
    px: 2,
    backgroundColor: palette.primary.main,
  },
  childrenContainer: {
    my: 3,
    px: 2.5,
    overflowY: 'auto',
    flex: 1,
  },
  paperDrawer: {
    backgroundColor: 'transparent',
    width: {
      xs: '100%',
      md: 'inherit',
    },
  },
};

export interface StaticDrawerProps extends PropsWithChildren {
  open: boolean;
  title: string | React.ReactNode;
  footer: React.ReactNode;
  onClose: () => void;
  size?: 'medium' | 'large';
}

export const StaticDrawer = ({ open, title, children, footer, onClose, size = 'medium' }: StaticDrawerProps) => {
  return (
    <Drawer PaperProps={{ sx: style.paperDrawer }} anchor="right" open={open} onClose={onClose}>
      <Box sx={{ ...style.container, ...(size === 'large' ? style.containerLarge : {}) }}>
        <Box sx={[style.box, style.titleBox]}>{title} </Box>
        <Box sx={style.childrenContainer}>{children}</Box>
        <Stack spacing={3} direction="row" sx={[style.box, style.actionBox]} justifyContent="space-between">
          {footer}
        </Stack>
      </Box>
    </Drawer>
  );
};
