import { useOutlet } from 'react-router-dom';
import { useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';
import { PermissionKey } from '../../types';

export const ForestProductMemberLayout = () => {
  const { t } = useInternationalization();
  const outlet = useOutlet();

  const tabs = [
    <LinkTab
      label={t('forestProduct:sections.tradingDashboard')}
      value={routes.Member.ForestProducts.TradingDashboard.path}
      permissions={{ keys: [PermissionKey.CommodityPriceView] }}
    />,
    <LinkTab
      label={t('forestProduct:sections.prices')}
      value={routes.Member.ForestProducts.Prices.path}
      permissions={{ keys: [PermissionKey.CommodityPriceView] }}
    />,
    <LinkTab
      label={t('forestProduct:sections.commitments')}
      value={routes.Member.ForestProducts.Commitments.path}
      permissions={{ keys: [PermissionKey.MemberCommitmentsView] }}
    />,
  ];

  return <LayoutPage display="Page" tabs={tabs} title={t('forestProduct:title')} outlet={outlet} />;
};
