import { Box, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnumList } from '../../hooks';
import { FilterPeriod, PeriodFilter, Styles } from '../../types';
import { Select } from '../Form';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
};

interface PeriodFilterSelectProps {
  filter: PeriodFilter;
  setFilter: Dispatch<SetStateAction<PeriodFilter>>;
  label?: string;
}

export const PeriodFilterSelect = ({ filter, setFilter, label }: PeriodFilterSelectProps) => {
  const { t } = useTranslation();
  const periodFilterOptions = useEnumList(FilterPeriod, 'common:filterPeriod', undefined, 'female', false);

  const onPeriodChange = (event: SelectChangeEvent<FilterPeriod>) => {
    setFilter((prev) => ({ ...prev, period: event.target.value as FilterPeriod, pageNumber: 1 }));
  };

  return (
    <Box sx={style.selectSmallContainer}>
      <Select
        displayEmpty
        options={periodFilterOptions}
        value={filter.period ?? ''}
        onChange={(e) => onPeriodChange(e)}
        label={label ?? t('common:period')}
      />
    </Box>
  );
};
