import axios from 'axios';
import { CommitmentEventCategoryEntity, CommitmentEventEntity } from '../../models';
import { CommitmentEventFilter, PaginationEntity } from '../../types';

export const CommitmentEvent = {
  getAllCommitmentEvents: async (
    commitmentEventFilter: CommitmentEventFilter,
  ): Promise<PaginationEntity<CommitmentEventEntity>> => {
    const { data } = await axios.post('api/commitmentEvent/search', commitmentEventFilter);
    return { ...data, data: data.data.map((d: CommitmentEventEntity) => new CommitmentEventEntity(d)) };
  },
  createCommitmentEvents: async (entity: CommitmentEventEntity): Promise<CommitmentEventEntity> => {
    const { data } = await axios.post('api/commitmentEvent', entity);
    return new CommitmentEventEntity(data);
  },
  archiveCommitmentEvents: async (isArchived: boolean, ids: number[]): Promise<CommitmentEventEntity[]> => {
    const { data } = await axios.put(`api/commitmentEvent/archive`, { isArchived, ids });
    return data;
  },
  getCommitmentEvent: async (id: number): Promise<CommitmentEventEntity> => {
    const { data } = await axios.get<CommitmentEventEntity>(`api/commitmentEvent/${id}`);
    return new CommitmentEventEntity(data);
  },
  getLatestCommitmentEvent: async (): Promise<CommitmentEventEntity | null> => {
    const { data } = await axios.get<CommitmentEventEntity>(`api/commitmentEvent/latest`);
    return data ? new CommitmentEventEntity(data) : null;
  },
  updateCommitmentEvent: async (entity: CommitmentEventEntity): Promise<CommitmentEventEntity> => {
    const { data } = await axios.put(`api/commitmentEvent/${entity.id}`, entity);
    return new CommitmentEventEntity(data);
  },
  getComitmentEventCategorySuggestions: async (
    name: string,
  ): Promise<PaginationEntity<CommitmentEventCategoryEntity>> => {
    const { data } = await axios.post(`api/commitmentEvent/categories/search`, {
      name,
      distinctBy: 'name',
      pageSize: 10,
      pageNumber: 1,
    });
    return { ...data, data: data.data.map((v: CommitmentEventCategoryEntity) => new CommitmentEventCategoryEntity(v)) };
  },
};
