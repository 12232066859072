import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { auth as authApi } from '../api';
import { HTTP_STATUS_2FA_CHALLENGE, HTTP_STATUS_OK, HTTP_STATUS_SET_PASSWORD } from '../constants';
import { ProfileEntity } from '../models';
import { getLocalUser, removeLocalUser, setLocalUser } from '../services/auth.service';
import {
  ApiErrorCodes,
  ApiException,
  Authentication,
  CodeVerificationRequest,
  PasswordInitialization,
  PermissionKey,
  PhoneNumberInitializationRequest,
  PhoneNumberVerificationRequest,
  User,
  UserTemporaryPassword,
  UserType,
} from '../types';
import { getTimeZoneIANAName } from '../utils/timeZone';
import { useInternationalization } from './useInternationalization';

interface AuthContextType {
  isLoaded: boolean;
  user: User | null;
  userTemporaryPassword: UserTemporaryPassword | null;
  userVerifyCode: CodeVerificationRequest | null;
  userVerifyPhoneNumber: PhoneNumberInitializationRequest | null;
  login: (auth: Authentication) => Promise<void>;
  logout: (user: User) => Promise<void>;
  setPassword: (passwordInitialization: PasswordInitialization) => Promise<void>;
  setVerificationPhoneNumber: (phoneNumberVerification: PhoneNumberInitializationRequest | null) => Promise<boolean>;
  verifyPhoneNumber: (phoneNumberVerification: PhoneNumberVerificationRequest) => Promise<boolean>;
  verifyCode: (codeVerification: CodeVerificationRequest) => Promise<boolean | ApiErrorCodes>;
  resendCode: () => Promise<void | null>;
  setProfileInformation: (profile: ProfileEntity) => void;
  hasPermissions: (permissions: PermissionKey[] | PermissionKey, any?: boolean) => boolean;
  refresh: () => Promise<void>;
  setMemberId: (id: number | null) => void; // only for MemberUser
  userType: UserType;
  isInternalUser: boolean;
  isMemberUser: boolean;
  isVendorUser: boolean;
  isMemberUserSupport: boolean;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

function useAuth() {
  const { setTimeZoneName } = useInternationalization();
  const [user, setUser] = React.useState<User | null>(null);
  const [auth, setAuth] = React.useState<Authentication | null>(null);
  const [userTemporaryPassword, setUserTemporaryPassword] = React.useState<UserTemporaryPassword | null>(null);
  const [userVerifyCode, setUserVerifyCode] = React.useState<CodeVerificationRequest | null>(null);
  const [userVerifyPhoneNumber, setUserVerifyPhoneNumber] = React.useState<PhoneNumberInitializationRequest | null>(
    null,
  );
  const [isLoaded, setLoaded] = React.useState<boolean>(getLocalUser() === null);

  const setProfileInformation = useCallback(
    (profile: ProfileEntity) => {
      setTimeZoneName(getTimeZoneIANAName(profile.timeZone));
    },
    [setTimeZoneName],
  );

  const refreshWithUser = useCallback(
    async (user: User) => {
      try {
        const updatedUser = await authApi.postWithRefreshToken(user.profile.username, user.refreshToken);
        setUser(updatedUser);
        setProfileInformation(updatedUser.profile);
        setLocalUser(updatedUser);
      } catch {
        logout(user);
      } finally {
        setLoaded(true);
      }
    },
    [setProfileInformation],
  );

  const refresh = useCallback(async () => {
    if (user) {
      refreshWithUser(user);
    }
  }, [refreshWithUser, user]);

  useEffect(() => {
    const localUser = getLocalUser();
    if (localUser) {
      refreshWithUser(localUser);
    }
  }, [refreshWithUser, setProfileInformation]);

  const logout = async (user: User) => {
    authApi.logout(user.refreshToken);
    setUser(null);
    removeLocalUser();
  };

  const hasPermissions = useCallback(
    (permissions: PermissionKey[] | PermissionKey, any?: boolean): boolean => {
      if (user && user.profile && user.profile.permissionKeys) {
        if (Array.isArray(permissions)) {
          if (any) {
            return permissions.some((p) => user.profile.permissionKeys.includes(p));
          } else {
            return permissions.every((p) => user.profile.permissionKeys.includes(p));
          }
        } else {
          return user.profile.permissionKeys.includes(permissions);
        }
      }
      return false;
    },
    [user],
  );

  const setPassword = async (passwordInitialization: PasswordInitialization) => {
    const userInfo = await authApi.setPassword(passwordInitialization);
    setLocalUser(userInfo);
    setUser(userInfo);
    setUserTemporaryPassword(null);
    setLoaded(true);
  };

  const setVerificationPhoneNumber = async (phoneNumberVerification: PhoneNumberInitializationRequest | null) => {
    try {
      if (phoneNumberVerification) {
        await authApi.setVerificationPhoneNumber(phoneNumberVerification);
      }
      setUserVerifyPhoneNumber(phoneNumberVerification);
      return true;
    } catch {
      return false;
    }
  };

  const verifyPhoneNumber = async (phoneNumberVerification: PhoneNumberVerificationRequest) => {
    try {
      await authApi.verifyPhoneNumber(phoneNumberVerification);
      setUserVerifyPhoneNumber(null);
      return true;
    } catch {
      return false;
    }
  };

  const verifyCode = async (codeVerification: CodeVerificationRequest) => {
    try {
      const userInfo = await authApi.verifyCode({ ...codeVerification, ...userVerifyCode });
      setLocalUser(userInfo);
      setUser(userInfo);
      setUserVerifyCode(null);
      setLoaded(true);
      setAuth(null);
      return true;
    } catch (ex) {
      if (axios.isAxiosError(ex) && ex.response?.data === ApiErrorCodes.USER_CREDENTIALS_CODE_MISMATCH) {
        return ApiErrorCodes.USER_CREDENTIALS_CODE_MISMATCH;
      } else if (axios.isAxiosError(ex) && ex.response?.data === ApiErrorCodes.USER_CREDENTIALS_NOT_AUTHORIZED) {
        setUserVerifyCode(null);
        return ApiErrorCodes.USER_CREDENTIALS_NOT_AUTHORIZED;
      } else {
        return false;
      }
    }
  };

  const resendCode = async () => {
    auth && (await login(auth));
  };

  const setMemberId = (id: number | null) => {
    const localUser = getLocalUser();
    if (localUser) {
      localUser.memberId = id;
      setLocalUser(localUser);
      setUser(localUser);
      refreshWithUser(localUser);
    }
  };

  const login = async (_auth: Authentication) => {
    try {
      const [status, userInfo] = await authApi.post(_auth.username, _auth.password);
      if (status === HTTP_STATUS_OK) {
        const loginUser = userInfo as User;
        setLocalUser(loginUser);
        setUser(loginUser);
      } else if (status === HTTP_STATUS_SET_PASSWORD) {
        setUserTemporaryPassword(userInfo as UserTemporaryPassword);
      } else if (status === HTTP_STATUS_2FA_CHALLENGE) {
        setUserVerifyCode(userInfo as CodeVerificationRequest);
        setAuth(_auth);
      }
      setLoaded(true);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        throw { code: err.response.data } as ApiException;
      }
      throw err;
    }
  };

  return {
    isLoaded,
    user,
    userTemporaryPassword,
    userVerifyCode,
    userVerifyPhoneNumber,
    login,
    logout,
    setPassword,
    verifyCode,
    resendCode,
    setVerificationPhoneNumber,
    verifyPhoneNumber,
    setProfileInformation,
    hasPermissions,
    refresh,
    setMemberId,
    userType: user?.profile.userType ?? UserType.Internal,
    isInternalUser: user?.profile.userType == UserType.Internal,
    isMemberUser: user?.profile.userType == UserType.Member,
    isVendorUser: user?.profile.userType == UserType.Vendor,
    isMemberUserSupport: hasPermissions(PermissionKey.AdminSupportMembers),
  };
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer(): AuthContextType {
  return React.useContext(AuthContext) as AuthContextType;
}
