export class MemberPermissionEntity {
  id: number;
  memberId: number;
  memberName: string | null;
  memberGroupId: number;
  memberGroupName: string | null;
  isIncluded: boolean;

  constructor() {
    this.id = 0;
    this.memberId = 0;
    this.memberName = null;
    this.isIncluded = true;
    this.memberGroupId = 0;
    this.memberGroupName = null;
  }
}
