import { AddRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategorizationContext } from '../../contexts';
import { Styles } from '../../types';
import { CategorizationEditDrawer } from './CategorizationEditDrawer';
import { HasPermissions } from '../Permission';
import { PermissionKey } from '../../types';

const style: Styles = {
  stack: {
    pb: 4,
  },
  title: {
    pr: 4,
  },
};

export const CategorizationHeader = () => {
  const { t } = useTranslation();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { type } = useContext(CategorizationContext);

  const toggleDrawer = (value: boolean) => () => setIsAddOpen(value);

  return (
    <>
      <Stack sx={style.stack} direction="row" justifyContent="space-between">
        <Typography sx={style.title} variant="h2">
          {t(`categorization:${type}.title`)}
        </Typography>
        <HasPermissions keys={PermissionKey.AdminEditCategorization}>
          <Button onClick={toggleDrawer(true)} variant="contained" startIcon={<AddRounded />}>
            {t(`categorization:action.add`)}
          </Button>
        </HasPermissions>
      </Stack>
      <CategorizationEditDrawer open={isAddOpen} onClose={toggleDrawer(false)} />
    </>
  );
};
