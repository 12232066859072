import { number } from 'yup';
import { VendorBuyingAgreementRebateCategoryEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { VendorRebateCategorySchema } from './VendorRebateCategorySchema';

export const VendorBuyingAgreementRebateCategorySchema =
  (): EntityFormSchema<VendorBuyingAgreementRebateCategoryEntity> => ({
    schema: VendorRebateCategorySchema().schema.shape({
      vendorBuyingAgreementId: number().nullable().notRequired(),
    }),
    errorCodeMap: {},
  });
