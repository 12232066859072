import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../types';
import { ControlledSelect } from './ControlledSelect';

interface ControlledSelectBooleanProps extends Omit<React.ComponentProps<typeof ControlledSelect>, 'options'> {
  nullable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  defaultToAll?: boolean;
}

export const ControlledSelectBoolean = ({
  nullable,
  setValue,
  defaultToAll,
  ...props
}: ControlledSelectBooleanProps) => {
  const { t } = useTranslation();

  const getBooleanOptions = (): SelectOption<boolean>[] => [
    { label: t('common:boolean.true'), value: true },
    { label: t('common:boolean.false'), value: false },
  ];

  const onClear = () => {
    setValue(props.name, null, { shouldTouch: true });
  };

  const renderValue = (value: unknown) => {
    if (typeof value === 'boolean') {
      return t(`common:boolean.${value}`);
    }

    if (defaultToAll) {
      return t('common:filter.all', { context: 'male' });
    }

    return props.control._options.context?.readOnly ? null : t('common:notSpecified');
  };

  return (
    <ControlledSelect
      displayEmpty={!!nullable}
      onClear={nullable ? onClear : undefined}
      options={getBooleanOptions()}
      renderValue={renderValue}
      defaultValue={nullable ? null : false}
      {...props}
    />
  );
};
