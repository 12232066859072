import { AddRounded, ArchiveRounded, ModeCommentRounded, UnarchiveRounded } from '@mui/icons-material';
import { Button, Link, MenuItem, Stack, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { EditDrawer, EntityPeriod, LimitCommaValues } from '../..';
import { vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { VendorDetailContext } from '../../../contexts';
import { useApi, useGlobalEdit, useInternationalization } from '../../../hooks';
import { RebateCategoryEntity, VendorRebateCategoryEntity } from '../../../models';
import { routes } from '../../../routes';
import { VendorRebateCategorySchema } from '../../../schemas/Vendor';
import { FilterPeriod, OrderBy, PermissionKey, VendorRebateCategoryFilter } from '../../../types';
import { downloadBlob, formatShortDate } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { PeriodFilterSelect } from '../../Filter/PeriodFilterSelect';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem, DuplicateMenuItem, ExportMenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { VendorRebateCategoryDuplicateDrawer } from './VendorRebateCategoryDuplicateDrawer';
import { VendorRebateCategoryForm } from './VendorRebateCategoryForm';
import { VendorRebateCategoryHeader } from './VendorRebateCategoryHeader';

const defaultAdvancedSearchFilter = {
  period: FilterPeriod.All,
  isArchived: false,
};

export const VendorRebateCategories = () => {
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const navigate = useNavigate();
  const { vendor, vendorId } = useContext(VendorDetailContext);
  const [filter, setFilter] = useState<VendorRebateCategoryFilter>({
    ...defaultAdvancedSearchFilter,
    ...DEFAULT_PAGINATION_FILTER,
  });
  const apiFilter = useMemo<VendorRebateCategoryFilter>(() => ({ ...filter }), [filter]);
  const { data, isLoading, refresh } = useApi(
    vendorApi.getAllRebateCategories,
    { skipFetch: !vendor },
    vendor?.id,
    apiFilter,
  );
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState<RebateCategoryEntity | null>(null);
  const vendorRebateCategory = useMemo(() => new VendorRebateCategoryEntity(), []);
  const { call: create } = useApi(vendorApi.createRebateCategory, {
    successKey: 'common:success.save',
  });
  const { call: getProgramInputSheet } = useApi(vendorApi.getRebateCategoryProgramInputSheet, null);
  const archiveApi = useApi(vendorApi.archiveRebateCategory, { successKey: 'common:success.action' });

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const renderAdvancedSearch = () => (
    <Stack>
      <FilterContainer onClear={onClear}>
        <PeriodFilterSelect filter={filter} setFilter={setFilter} />
      </FilterContainer>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="female" />
    </Stack>
  );

  const renderRebateCategoryLink = (entity: VendorRebateCategoryEntity) => (
    <Link
      underline="always"
      href={generatePath(routes.Admin.Vendor.RebateCategory.path, { vendorId, vendorRebateCategoryId: entity.id })}
    >
      {getTranslation(entity, 'name')}
    </Link>
  );

  const renderVendorDesignations = (entity: VendorRebateCategoryEntity) => (
    <LimitCommaValues
      value={entity.vendorDesignations
        ?.map((vendorDesignation) => getTranslation(vendorDesignation, 'name'))
        .join(', ')}
      maxLength={50}
    />
  );

  const renderFilterPeriod = (filterPeriod?: FilterPeriod) => (
    <EntityPeriod period={filterPeriod} translationContext="female" />
  );

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: VendorRebateCategoryEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: VendorRebateCategoryEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onArchiveConfirm = async (isArchived: boolean, rebates: VendorRebateCategoryEntity[]) => {
    await archiveApi.call(
      vendorId,
      rebates.map((i) => i.id),
      isArchived,
    );
    refresh();
  };

  const renderActionsMenuItems = (entity: VendorRebateCategoryEntity, onClick: () => void) => [
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(
          generatePath(routes.Admin.Vendor.RebateCategory.path, {
            vendorId: vendorId,
            vendorRebateCategoryId: entity.id,
          }),
        );
      }}
    >
      {t('common:view')}
    </MenuItem>,
    <DuplicateMenuItem
      key="duplicate"
      permissions={{ keys: PermissionKey.VendorEditRebates }}
      entity={entity}
      onClick={() => {
        onClick();
        setDuplicateOpen(entity);
      }}
    />,
    <ExportMenuItem
      key="export"
      entity={entity}
      onClick={onClick}
      onExport={async () => {
        const fileData = await getProgramInputSheet(vendorId, entity.id);
        if (fileData) {
          downloadBlob(fileData.filename ?? 'report.xlsx', fileData.data);
        }
      }}
    />,
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={getTranslation(entity, 'name')}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('vendor:rebateCategory.actions.suffix')}
    />,
  ];

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.VendorEditRebates}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => setIsAddOpen(true)}
        disabled={!vendor || vendor.isArchived || globalEditing}
      >
        {t('vendor:rebateCategory.actions.add')}
      </Button>
    </HasPermissions>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewRebates }}
      title={t('vendor:rebateCategory.title')}
      rightTitle={renderAddButton()}
      display="Tab"
    >
      {renderAdvancedSearch()}
      <Table
        data={data ?? []}
        translationNamespace="vendor:rebateCategory"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
        bulkActions={bulkActions}
        bulkActionSuffix={t('vendor:rebateCategory.actions.suffix')}
        renderBulkSelection={(items) =>
          items.length === 1 ? getTranslation(items[0], 'name') : t(`vendor:rebateCategory.title`)
        }
      >
        <TableColumn
          type="custom"
          sortable
          width="15%"
          id="period"
          render={(entity: VendorRebateCategoryEntity) => renderFilterPeriod(entity.period)}
        />
        <TableColumn type="custom" sortable width="30%" id="name" render={renderRebateCategoryLink} />
        <TableColumn
          type="custom"
          sortable
          id="programsCount"
          align="center"
          render={({ programsCount }: VendorRebateCategoryEntity) => programsCount.toString()}
        />
        <TableColumn type="custom" width="15%" id="vendorDesignations" render={renderVendorDesignations} />
        <TableColumn
          type="custom"
          sortable
          width="10%"
          id="effectiveDate"
          align="center"
          render={({ effectiveDate }: VendorRebateCategoryEntity) => formatShortDate(effectiveDate)}
        />
        <TableColumn
          type="custom"
          sortable
          width="10%"
          id="expiryDate"
          align="center"
          render={({ expiryDate }: VendorRebateCategoryEntity) => formatShortDate(expiryDate)}
        />
        <TableColumn type="icon" tooltip width={100} id="notes" align="center" iconComponent={ModeCommentRounded} />
      </Table>
      <EditDrawer
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <AddRounded />
            <Typography variant="drawerTitle">{t('vendor:rebateCategory.actions.add')}</Typography>
          </Stack>
        }
        open={isAddOpen}
        entity={vendorRebateCategory}
        schema={VendorRebateCategorySchema()}
        redirectPath={(entity) =>
          generatePath(routes.Admin.Vendor.RebateCategory.path, {
            vendorId: vendorId,
            vendorRebateCategoryId: entity.id,
          })
        }
        redirectLabel={t('vendor:rebateCategory.actions.saveOpen')}
        onSave={async (entity) => {
          const createdEntity = await create(vendorId, entity);
          await refresh();
          return createdEntity;
        }}
        onConfirm={() => {
          refresh();
          setIsAddOpen(false);
        }}
        onCancel={() => setIsAddOpen(false)}
      >
        <VendorRebateCategoryHeader />
        <VendorRebateCategoryForm />
      </EditDrawer>
      <VendorRebateCategoryDuplicateDrawer
        rebateCategory={duplicateOpen}
        onClose={() => setDuplicateOpen(null)}
        refresh={refresh}
      />
    </LayoutPage>
  );
};
