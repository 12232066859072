import { useApi, useInternationalization } from '../../hooks';
import { vendor as vendorApi } from '../../api';
import { VendorLocationEntity } from '../../models';
import { formatAddress, formatShortDate, downloadBlob } from '../../utils/helper';
import { PhoneNumberList, EmailAddressList } from '../Contact';
import { LayoutPage } from '../Layout';
import { Table, TableColumn } from '../Table';
import { OrderBy, PermissionKey, VendorLocationFilter } from '../../types';
import { useContext, useState } from 'react';
import { BuyGuideVendorDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { Stack, Typography } from '@mui/material';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';

export const BuyGuideVendorLocationList = () => {
  const { vendorId } = useContext(BuyGuideVendorDetailContext);
  const [filter, setFilter] = useState<VendorLocationFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });
  const { t, getTranslation } = useInternationalization();
  const { data, isLoading } = useApi(vendorApi.getAllLocations, { skipFetch: !vendorId }, vendorId, filter);
  const { call: downloadExport } = useApi(vendorApi.exportAllLocations, null);

  const exportLocations = async () => {
    if (vendorId !== null) {
      const fileData = await downloadExport(vendorId);
      if (fileData) {
        downloadBlob(fileData.filename ?? 'export.csv', fileData.data);
      }
    }
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderGeneralContacts = (entity: VendorLocationEntity) => (
    <Stack spacing={1}>
      <PhoneNumberList data={entity.phoneNumbers} />
      <EmailAddressList data={entity.emailAddresses} />
    </Stack>
  );

  const renderAddress = (entity: VendorLocationEntity) => (
    <Typography variant="inherit" whiteSpace="pre-wrap">
      {formatAddress(entity.address)}
    </Typography>
  );

  const renderLastUpdate = (entity: VendorLocationEntity) => formatShortDate(entity.modifiedDate);

  const renderTable = () =>
    data && (
      <Table
        data={data}
        translationNamespace="buyGuide:locations"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        onDownloadTableClick={exportLocations}
        hidePending
      >
        <TableColumn
          type="custom"
          width="20%"
          id="description"
          sortable
          render={(entity: VendorLocationEntity) => getTranslation(entity, 'description')}
        />
        <TableColumn type="custom" width="20%" id="address" render={renderAddress} />
        <TableColumn type="custom" width="20%" id="generalContacts" render={renderGeneralContacts} />
        <TableColumn type="custom" width="20%" id="modifiedDate" sortable align="center" render={renderLastUpdate} />
      </Table>
    );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewContacts }}
      title={t('buyGuide:locations.title')}
      display={'Tab'}
    >
      {renderTable()}
    </LayoutPage>
  );
};
