import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { VendorForm } from '.';
import { ArchiveBanner, EditDetails } from '..';
import { vendor as vendorApi } from '../../api';
import { VendorDetailContext } from '../../contexts';
import { useApi, usePageTitle } from '../../hooks';
import { VendorEntity } from '../../models';
import { routes } from '../../routes';
import { VendorSchema } from '../../schemas';
import { PermissionKey, Styles } from '../../types';
import { Container } from '../Container';
import { LayoutPage } from '../Layout';
import { ArchiveMenuItem } from '../Menu';
import { LinkTab } from '../Tab';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const VendorDetail = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const params = useParams();
  const vendorId = Number(params.vendorId);
  const { data, isLoading, refresh } = useApi(vendorApi.get, null, vendorId);
  const { call } = useApi(vendorApi.update, {
    successKey: 'common:success.save',
  });

  const archiveApi = useApi(vendorApi.archive, { successKey: 'common:success.action' });
  usePageTitle(data?.name ?? '');

  const onArchiveConfirm = async (isArchived: boolean, entity: VendorEntity) => {
    await archiveApi.call([entity.id], isArchived);
    refresh();
  };

  const tabs = [
    <LinkTab
      label={t('vendor:sections.buyingAgreements')}
      value={generatePath(routes.Admin.Vendor.Detail.BuyingAgreements.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewVBAs }}
    />,
    <LinkTab
      label={t('vendor:sections.rebateCategories')}
      value={generatePath(routes.Admin.Vendor.Detail.RebateCategories.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewRebates }}
    />,
    <LinkTab
      label={t('vendor:sections.documents')}
      value={generatePath(routes.Admin.Vendor.Detail.Documents.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewFiles }}
    />,
    <LinkTab
      label={t('vendor:sections.buyOpportunities')}
      value={generatePath(routes.Admin.Vendor.Detail.BuyOpportunities.path, { vendorId })}
      permissions={{ keys: [PermissionKey.VendorViewBuys] }}
    />,
    <LinkTab
      label={t('vendor:sections.generalConditions')}
      value={generatePath(routes.Admin.Vendor.Detail.GeneralConditions.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewConditions }}
    />,
    <LinkTab
      label={t('vendor:sections.tierAndGrowth')}
      value={generatePath(routes.Admin.Vendor.Detail.TiersAndGrowths.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewRebates }}
    />,
    <LinkTab
      label={t('vendor:sections.financials')}
      value={generatePath(routes.Admin.Vendor.Detail.Financials.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewFinancial }}
    />,
    <LinkTab
      label={t('vendor:sections.contacts')}
      value={generatePath(routes.Admin.Vendor.Detail.Contacts.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewContacts }}
    />,
    <LinkTab
      label={t('vendor:sections.locations')}
      value={generatePath(routes.Admin.Vendor.Detail.Locations.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewContacts }}
    />,
    <LinkTab
      label={t('vendor:sections.blocks')}
      value={generatePath(routes.Admin.Vendor.Detail.Blocks.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewBlocks }}
    />,
    <LinkTab
      label={t('vendor:sections.users')}
      value={generatePath(routes.Admin.Vendor.Detail.VendorUsers.path, { vendorId })}
      permissions={{ keys: PermissionKey.AdminManageVendorUsers }}
    />,
  ];

  const renderActionMenuItems = (onClick: () => void) =>
    data
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={data.name}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('vendor:action.suffix')}
          />,
        ]
      : [];

  return (
    <VendorDetailContext.Provider value={{ vendor: data, vendorId }}>
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
        <ArchiveBanner
          permissions={{ keys: PermissionKey.VendorEditInfo }}
          entity={data}
          onUnarchive={() => data && onArchiveConfirm(false, data)}
        />
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              permissions={{ keys: PermissionKey.VendorEditInfo }}
              titleLabel={t(`vendor:form.name`)}
              title="name"
              disabled={data.isArchived}
              entity={data}
              schema={VendorSchema()}
              onSave={call}
              actionMenuItems={renderActionMenuItems}
              onConfirm={() => refresh()}
              breadcrumbs={[{ title: t('vendor:title'), href: routes.Admin.Vendor.path }, { title: data?.name }]}
            >
              <VendorForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </VendorDetailContext.Provider>
  );
};
