import { LanguageCode } from '../types';
import { FileEntity } from './FileEntity';

export class TranslatedFileEntity {
  file: FileEntity | null;
  languages: LanguageCode[];

  constructor(entity?: TranslatedFileEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.languages = [LanguageCode.en];
      this.file = null;
    }
  }
}
