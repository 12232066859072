import { ContentBlockType, LanguageCode } from '../../types';
import { ContentBlockEntity } from '../../models/Cms/ContentBlockEntity';
import { CmsContentBlockImageView } from './CmsContentBlockImageView';
import { ContentEntity } from '../../models';
import { CmsContentBlockVideoView } from './CmsContentBlockVideoView';
import { CmsContentBlockHtmlView } from './CmsContentBlockHtmlView';

interface CmsContentBlockViewProps {
  contentEntity: ContentEntity;
  contentBlock: ContentBlockEntity;
  downloadBlockFileApi: (contentEntityId: number, blockId: number, language: LanguageCode) => Promise<Blob>;
}

export const CmsContentBlockView = ({
  contentBlock,
  contentEntity,
  downloadBlockFileApi,
}: CmsContentBlockViewProps) => {
  if (!contentBlock) {
    return null;
  }

  switch (contentBlock?.type) {
    case ContentBlockType.HTML:
      return <CmsContentBlockHtmlView contentBlock={contentBlock} />;

    case ContentBlockType.Image:
      return (
        <CmsContentBlockImageView
          contentEntity={contentEntity}
          contentBlock={contentBlock}
          downloadBlockFileApi={downloadBlockFileApi}
        />
      );
    case ContentBlockType.Video:
      return <CmsContentBlockVideoView contentBlock={contentBlock} />;
    default:
      return null;
  }
};
