import { TimeZoneCode } from '../types';

const TIME_ZONE_IANA_NAME_MAP = {
  [TimeZoneCode.ChamorroStandardTime]: 'Pacific/Guam',
  [TimeZoneCode.SamoaStandardTime]: 'Pacific/Midway',
  [TimeZoneCode.HawaiiAleutianStandardTime]: 'Pacific/Honolulu',
  [TimeZoneCode.HawaiiAleutianTime]: 'America/Adak',
  [TimeZoneCode.AlaskaTime]: 'America/Anchorage',
  [TimeZoneCode.PacificTime]: 'America/Los_Angeles',
  [TimeZoneCode.MountainStandardTime]: 'America/Phoenix',
  [TimeZoneCode.MountainTime]: 'America/Denver',
  [TimeZoneCode.CentralStandardTime]: 'America/Regina',
  [TimeZoneCode.CentralTime]: 'America/Chicago',
  [TimeZoneCode.EasternStandardTime]: 'America/Atikokan',
  [TimeZoneCode.EasternTime]: 'America/New_York',
  [TimeZoneCode.AtlanticStandardTime]: 'America/Puerto_Rico',
  [TimeZoneCode.AtlanticTime]: 'America/Halifax',
  [TimeZoneCode.NewfoundlandTime]: 'America/St_Johns',
};

export const getTimeZoneIANAName = (timeZone: TimeZoneCode): string => TIME_ZONE_IANA_NAME_MAP[timeZone];
