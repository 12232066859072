import { useState } from 'react';
import { VendorChangeRequestContext } from '../../contexts';
import { VendorChangeRequestEntity } from '../../models';
import { VendorChangeRequestEntityType } from '../../types';
import { InvoicingInformationChangeRequest } from './InvoicingInformationChangeRequest';
import { PaymentScheduleChangeRequest } from './PaymentScheduleChangeRequest';
import { TaxInformationChangeRequest } from './TaxInformationChangeRequest';
import { VendorContactChangeRequest } from './VendorContactChangeRequest';
import { VendorDocumentChangeRequest } from './VendorDocumentChangeRequest';
import { VendorLocationChangeRequest } from './VendorLocationChangeRequest';

interface VendorChangeRequestProps {
  vendorChangeRequest: VendorChangeRequestEntity;
  onConfirm: () => void;
  onCancel: () => void;
}

export const VendorChangeRequest = ({ vendorChangeRequest, onConfirm, onCancel }: VendorChangeRequestProps) => {
  const [isAfter, setIsAfter] = useState(!vendorChangeRequest.isDeleted);

  const getForm = () => {
    switch (vendorChangeRequest.entityType) {
      case VendorChangeRequestEntityType.VendorContact:
        return <VendorContactChangeRequest />;
      case VendorChangeRequestEntityType.VendorInvoicingInformation:
        return <InvoicingInformationChangeRequest />;
      case VendorChangeRequestEntityType.VendorPaymentSchedule:
        return <PaymentScheduleChangeRequest />;
      case VendorChangeRequestEntityType.VendorTaxInformation:
        return <TaxInformationChangeRequest />;
      case VendorChangeRequestEntityType.VendorLocation:
        return <VendorLocationChangeRequest />;
      case VendorChangeRequestEntityType.VendorDocument:
        return <VendorDocumentChangeRequest />;
      default:
        return null;
    }
  };

  return (
    <VendorChangeRequestContext.Provider
      value={{
        changeRequest: vendorChangeRequest,
        isAfter,
        onConfirm,
        onCancel,
        toggleViewMode: setIsAfter,
      }}
    >
      {getForm()}
    </VendorChangeRequestContext.Provider>
  );
};
