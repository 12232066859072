import { CircularProgress, Stack, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { user as userApi } from '../../api';
import { UserDetailContext } from '../../contexts';
import { useApi, useInternationalization } from '../../hooks';
import { UserPermissionsEntity } from '../../models';
import { UserPermissionsSchema } from '../../schemas/';
import { PermissionKey, PermissionState, Styles, UserType } from '../../types';
import { EditCard } from '../Card';
import { Container } from '../Container';
import { RequirePermission } from '../Permission';
import { UserPermissionsForm } from './UserPermissionsForm';
import { UserTwoFactorAuthentication } from './UserTwoFactorAuthentication';

const style: Styles = {
  container: {
    pt: 4,
    pb: 4,
  },
  loading: {
    mx: 2,
  },
};

interface UserPermissionsProps {
  type: UserType;
}

export const UserPermissions = ({ type }: UserPermissionsProps) => {
  const { t } = useInternationalization();
  const { userId, user } = useContext(UserDetailContext);

  // User Permissions
  const {
    data: userPermissions,
    isLoading: userPermissionsLoading,
    refresh: userPermissionsRefresh,
  } = useApi(userApi.getPermissions, null, userId, type);
  const updatePermissions = useApi(userApi.updatePermissions, { successKey: 'common:success.action' });
  const userPermissionsEntity = useMemo<UserPermissionsEntity | null>(
    () =>
      user ? { id: user.id, userType: user.userType ?? UserType.Internal, assignedPermissions: userPermissions } : null,
    [user, userPermissions],
  );

  const onUserSave = async (entity: UserPermissionsEntity) => {
    if (entity.assignedPermissions) {
      const result = await updatePermissions.call(
        entity.id,
        type,
        entity.assignedPermissions.filter((p) => p.state === PermissionState.Included).map((p) => p.permission.id),
        entity.assignedPermissions.filter((p) => p.state === PermissionState.Excluded).map((p) => p.permission.id),
      );
      await userPermissionsRefresh();
      return { ...entity, assignedPermissions: result };
    }
    return null;
  };

  const getRequiredPermission = (type: UserType) => {
    switch (type) {
      case UserType.Member:
        return PermissionKey.AdminManageMemberUsers;
      case UserType.Vendor:
        return PermissionKey.AdminManageVendorUsers;
      case UserType.Internal:
      default:
        return PermissionKey.AdminManageInternalUsers;
    }
  };

  return (
    <RequirePermission keys={getRequiredPermission(type)}>
      <Container isHighlighted sx={style.container}>
        <Typography variant="h2">{t('user:sections.permissions')}</Typography>
        {userPermissionsLoading && <CircularProgress size={20} sx={style.loading} />}
        {userPermissionsEntity && (
          <Stack direction="column" spacing={1}>
            <EditCard
              title={t('user:permissions.customPermissions')}
              entity={userPermissionsEntity}
              schema={UserPermissionsSchema()}
              onSave={onUserSave}
            >
              <UserPermissionsForm />
            </EditCard>
          </Stack>
        )}
        <UserTwoFactorAuthentication type={type} />
      </Container>
    </RequirePermission>
  );
};
