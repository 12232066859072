import { Box, Chip, Link, Stack, Typography } from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import { vendor as vendorApi, report as reportApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER, MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization, usePageTitle } from '../../../hooks';
import { MissingTranslationReportEntity } from '../../../models';
import {
  MissingTranslationEntityType,
  OrderBy,
  PermissionKey,
  Styles,
  MissingTranslationsReportFilter,
} from '../../../types';
import { downloadBlob, formatShortDate } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { Autocomplete, MultiSelect, Switch } from '../../Form';
import { LayoutPage } from '../../Layout';
import { Table, TableColumn } from '../../Table';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../routes';
import { DownloadRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const style: Styles = {
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
  textContainer: {
    mb: 0.2,
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectContainer: {
    width: {
      xs: '100%',
      sm: '250px',
    },
  },
};

const defaultFilter: MissingTranslationsReportFilter = {
  buyersIds: [],
  vendorNameOrNumber: '',
  entityType: MissingTranslationEntityType.RebateProgram,
  ...DEFAULT_PAGINATION_FILTER,
};

export const MissingTranslationReportList = () => {
  const { t, getTranslation } = useInternationalization();
  const [filter, setFilter] = useState(defaultFilter);
  const isRebateProgram = filter.entityType === 'RebateProgram';
  const { data, isLoading } = useApi(reportApi.getAllMissingTranslations, null, filter);
  const { call: downloadExport, isLoading: isExportLoading } = useApi(reportApi.exportMissingTranslations, {
    successKey: 'common:success.action',
  });
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);

  usePageTitle(`report:sections.MissingTranslation`);

  const entityTypeToPath: Record<MissingTranslationEntityType, string> = {
    GeneralCondition: routes.Admin.Vendor.Detail.GeneralConditions.path,
    RebateCategory: routes.Admin.Vendor.RebateCategory.path,
    RebateProgram: routes.Admin.Vendor.RebateCategoryProgram.path,
    TiersAndGrowths: routes.Admin.Vendor.Detail.TiersAndGrowths.path,
  };

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...defaultFilter, entityType: prev.entityType }));
    setIsAdvancedMode(checked);
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onClear = () => {
    setFilter((prev) => ({
      ...prev,
      ...defaultFilter,
    }));
  };

  const onExport = async () => {
    const fileData = await downloadExport({ ...filter, ...MAX_PAGINATION_FILTER });
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
    }
  };

  const buyerOptions = useMemo(
    () => data?.responsibleBuyersForFilter.map((b) => ({ value: b?.id, label: b?.displayName })) ?? [],
    [data?.responsibleBuyersForFilter],
  );

  const onBuyersChange = (values: number[]) => setFilter((prev) => ({ ...prev, buyersIds: values }));

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <Box sx={style.selectContainer}>
        <MultiSelect
          displayEmpty
          options={buyerOptions}
          values={filter.buyersIds ?? []}
          onConfirm={onBuyersChange}
          noValueText={t('common:filter.all', { context: 'female' })}
          label={t('report:missingTranslationReport.filter.buyer')}
        />
      </Box>
    </FilterContainer>
  );

  const onTypeSelect = (value: MissingTranslationEntityType) => () =>
    setFilter((prev) => ({
      ...prev,
      entityType: value,
    }));

  const renderFilterSection = () => (
    <Stack sx={style.filterContainer} direction="row" spacing={2} alignItems="center">
      <Typography align="center" variant="label" color="grey.600">
        {t('common:show')}
      </Typography>
      <Stack spacing={0.7} direction="row" sx={style.chipContainer}>
        {Object.values(MissingTranslationEntityType).map((val) => (
          <Chip
            key={val}
            label={t(`report:missingTranslationReport.entityType.${val}`)}
            onClick={onTypeSelect(val)}
            color={filter.entityType === val ? 'primary' : 'secondary'}
          />
        ))}
      </Stack>
    </Stack>
  );

  const renderTextSearch = () => (
    <Box sx={style.selectSmallContainer}>
      <Autocomplete
        minSearchTextLength={1}
        value={filter.vendorNameOrNumber}
        sx={style.textContainer}
        noOptionsText={t('vendor:search.notFound')}
        apiFunction={vendorApi.getSuggestions}
        label={t('vendor:search.title')}
        placeholder={t('vendor:search.placeholder')}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
        }}
        onApplySearch={(searchText) =>
          setFilter((prev) => ({ ...prev, vendorNameOrNumber: searchText ?? undefined, pageNumber: 1 }))
        }
        onSelectItem={(item) =>
          setFilter((prev) => ({ ...prev, vendorNameOrNumber: item?.name ?? undefined, pageNumber: 1 }))
        }
      />
    </Box>
  );

  const renderName = (entity: MissingTranslationReportEntity) => {
    const href = generatePath(entityTypeToPath[entity.entityType], {
      vendorId: entity.vendorId,
      vendorRebateCategoryId: entity.rebateCategoryId ?? entity.id,
      rebateProgramId: entity.id,
    });
    return <Link href={href}>{getTranslation(entity, 'name')}</Link>;
  };

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="report:missingTranslationReport"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      keySelector={(item, index) => `${index}-${item.entityType}-${item.field}-${item.id}`}
    >
      <TableColumn
        type="custom"
        width="10%"
        id="vendorNumber"
        sortable
        render={(i: MissingTranslationReportEntity) => i.vendorNumber}
      />
      <TableColumn
        type="custom"
        width="15%"
        id="vendor"
        sortable
        render={(i: MissingTranslationReportEntity) => i.vendorName}
      />
      <TableColumn
        type="custom"
        width="15%"
        id="entityType"
        sortable
        render={(i: MissingTranslationReportEntity) => t(`report:missingTranslationReport.entityType.${i.entityType}`)}
      />
      <TableColumn type="custom" width="20%" id="name" sortable render={renderName} />
      <TableColumn
        type="custom"
        width="15%"
        id="field"
        sortable
        render={(i: MissingTranslationReportEntity) => t(`report:missingTranslationReport.fields.${i.field}`)}
      />
      <TableColumn
        type="custom"
        width="15%"
        id="buyer"
        sortable
        render={(i: MissingTranslationReportEntity) => i.responsibleBuyer}
      />
      <TableColumn
        type="custom"
        width={isRebateProgram ? '10%' : '20%'}
        id="lastUpdateDate"
        sortable
        render={(i: MissingTranslationReportEntity) => formatShortDate(i.lastUpdateDate)}
      />
    </Table>
  );

  const renderPageActions = () => (
    <LoadingButton variant="outlined" onClick={onExport} loading={isExportLoading} startIcon={<DownloadRounded />}>
      {t('common:export')}
    </LoadingButton>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.ReportVendorData }}
      display="Tab"
      title={t(`report:sections.MissingTranslation`)}
      rightTitle={renderPageActions()}
    >
      <Stack spacing={3}>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark" mr={3}>
            {t('report:missingTranslationReport.filter.searchByVendor')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('report:missingTranslationReport.filter.filterList', {
              entityType: filter.entityType,
            })}
          </Typography>
        </Box>
        {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
        {renderFilterSection()}
        {renderTable()}
      </Stack>
    </LayoutPage>
  );
};
