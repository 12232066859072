import { AddRounded, EditRounded, SyncDisabledRounded, VisibilityRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { EditDrawer } from '../..';
import { useAuth, useGlobalEdit, useInternationalization } from '../../../hooks';
import { VendorContactEntity } from '../../../models';
import { VendorContactSchema } from '../../../schemas/Vendor';
import { BaseComponentListProps, ContactType, OrderBy, PermissionKey, VendorContactFilter } from '../../../types';
import { formatMailChimpErrors } from '../../../utils/formatters';
import { formatShortDate } from '../../../utils/helper';
import { EmailAddressList, PhoneNumberList } from '../../Contact';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { VendorContactForm } from './VendorContactForm';

export const VendorContactsList = ({
  addButtonProps,
  tableProps,
  showSearch,
  onSave,
  fetchApi,
  filter,
  setFilter,
  layout = 'Tab',
  isChangeRequest,
}: BaseComponentListProps<VendorContactEntity, VendorContactFilter>) => {
  const { hasPermissions, isInternalUser } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { data, isLoading, refresh } = fetchApi;
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [vendorContact, setVendorContact] = useState<VendorContactEntity>(new VendorContactEntity());

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderSearch = () => <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />;

  const renderBaseActionsMenuItems = (entity: VendorContactEntity, onClick: () => void) => [
    ...(!entity.isArchived
      ? [
          <MenuItem
            id="edit"
            key="edit"
            onClick={() => {
              setVendorContact(entity);
              setIsAddOpen(true);
              onClick();
            }}
          >
            {t('common:edit')}
          </MenuItem>,
        ]
      : []
    ).concat(tableProps?.actionMenuItems ? tableProps?.actionMenuItems(entity, onClick) : []),
  ];

  const renderName = (entity: VendorContactEntity) =>
    entity.type === ContactType.Department
      ? getTranslation(entity, 'department')
      : `${entity.firstName} ${entity.lastName}`;

  const renderContactRole = (entity: VendorContactEntity) => (entity.role ? getTranslation(entity.role, 'name') : '');

  const renderPhoneNumbers = (entity: VendorContactEntity) => <PhoneNumberList data={entity.phoneNumbers} />;

  const renderEmailAddresses = (entity: VendorContactEntity) => <EmailAddressList data={entity.emailAddresses} />;

  const renderLastModified = (entity: VendorContactEntity) =>
    t('common:entity.modifiedDateShort', {
      date: formatShortDate(entity.modifiedDate),
      name: entity.modifiedBy,
    });

  const renderTable = () =>
    data && (
      <Table
        {...tableProps}
        data={data}
        translationNamespace="vendor:contact"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={
          hasPermissions(PermissionKey.VendorEditContacts) && !globalEditing ? renderBaseActionsMenuItems : undefined
        }
      >
        <TableColumn type="custom" width="20%" id="name" sortable render={renderName} />
        <TableColumn type="custom" width="20%" id="role" sortable render={renderContactRole} />
        <TableColumn type="custom" width="20%" id="phoneNumbers" render={renderPhoneNumbers} />
        <TableColumn type="custom" width="20%" id="emailAddresses" render={renderEmailAddresses} />
        <TableColumn type="custom" width="20%" id="modifiedDate" sortable render={renderLastModified} />
        <TableColumn type="icon" id="isVisibleToMember" sortable align="center" iconComponent={VisibilityRounded} />
        {isInternalUser && (
          <TableColumn
            type="icon"
            id="mailSyncError"
            align="center"
            tooltip
            render={(item: VendorContactEntity) => formatMailChimpErrors(item.mailSyncError)}
            iconComponent={SyncDisabledRounded}
          />
        )}
      </Table>
    );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.VendorEditContacts}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => {
          setVendorContact(new VendorContactEntity());
          setIsAddOpen(true);
        }}
        {...addButtonProps}
        disabled={addButtonProps?.disabled || globalEditing}
      >
        {t('vendor:contact.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {vendorContact.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(vendorContact.id ? 'vendor:contact.actions.edit' : 'vendor:contact.actions.add')}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={vendorContact}
      schema={VendorContactSchema()}
      onSave={async (entity) => {
        const createdEntity = await onSave(entity);
        await refresh();
        return createdEntity;
      }}
      onConfirm={() => {
        refresh();
        setIsAddOpen(false);
      }}
      onCancel={() => setIsAddOpen(false)}
      isChangeRequest={isChangeRequest}
    >
      <VendorContactForm />
    </EditDrawer>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewContacts }}
      title={t('vendor:contact.title')}
      rightTitle={renderAddButton()}
      display={layout}
    >
      {showSearch && renderSearch()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
