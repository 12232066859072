import { CircularProgress, Stack } from '@mui/material';
import { useContext } from 'react';
import { VendorBuyOpportunityDetailContext } from '../../../contexts/Vendor/VendorBuyOpportunityDetailContext';
import { useInternationalization } from '../../../hooks';
import { VendorBuyOpportunitySchema } from '../../../schemas';
import { Styles } from '../../../types';
import { EditCard } from '../../Card';
import { Container } from '../../Container';
import { VendorBuyOpportunityDetailsForm } from './VendorBuyOpportunityDetailsForm';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const VendorBuyOpportunityDetails = () => {
  const { t } = useInternationalization();
  const { vendorBuyOpportunity, save, fetchData, isLoading, readOnly } = useContext(VendorBuyOpportunityDetailContext);

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        {isLoading && !vendorBuyOpportunity && <CircularProgress size={20} sx={style.loading} />}
        {vendorBuyOpportunity && (
          <EditCard
            readOnly={readOnly}
            title={t('vendor:buyOpportunities.sections.details')}
            entity={vendorBuyOpportunity}
            disabled={vendorBuyOpportunity.isArchived}
            schema={VendorBuyOpportunitySchema()}
            onSave={save}
            onConfirm={() => fetchData()}
            showModifiedDate
          >
            <VendorBuyOpportunityDetailsForm />
          </EditCard>
        )}
      </Stack>
    </Container>
  );
};
