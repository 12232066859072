import axios from 'axios';
import { VendorBuyingAgreementGeneralConditionEntity } from '../../../models';
import { TranslatedFileEntity } from '../../../models/TranslatedFileEntity';
import {
  PaginationEntity,
  UpdateGeneralConditionFileLanguageRequest,
  VendorGeneralConditionFilter,
} from '../../../types';

export const buyingAgreementGeneralCondition = {
  getAllBuyingAgreementGeneralConditions: async (
    vendorId: number,
    buyingAgreementId: number,
    filter: VendorGeneralConditionFilter,
  ): Promise<PaginationEntity<VendorBuyingAgreementGeneralConditionEntity>> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/generalconditions/search`,
      filter,
    );
    return {
      ...data,
      data: data.data.map(
        (v: VendorBuyingAgreementGeneralConditionEntity) => new VendorBuyingAgreementGeneralConditionEntity(v),
      ),
    };
  },
  createBuyingAgreementGeneralCondition: async (
    vendorId: number,
    buyingAgreementId: number,
    entity: VendorBuyingAgreementGeneralConditionEntity,
  ): Promise<VendorBuyingAgreementGeneralConditionEntity> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/generalconditions`,
      entity,
    );
    return new VendorBuyingAgreementGeneralConditionEntity(data);
  },
  updateBuyingAgreementGeneralCondition: async (
    vendorId: number,
    buyingAgreementId: number,
    entity: VendorBuyingAgreementGeneralConditionEntity,
  ): Promise<VendorBuyingAgreementGeneralConditionEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/generalconditions/${entity.id}`,
      entity,
    );
    return new VendorBuyingAgreementGeneralConditionEntity(data);
  },
  deleteBuyingAgreementGeneralCondition: async (
    vendorId: number,
    buyingAgreementId: number,
    id: number,
  ): Promise<void> => {
    await axios.delete(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/generalconditions/${id}`);
  },
  uploadBuyingAgreementGeneralConditionFiles: async (
    vendorId: number,
    buyingAgreementId: number,
    generalConditionId: number,
    files: TranslatedFileEntity[],
  ): Promise<VendorBuyingAgreementGeneralConditionEntity> => {
    const formData = VendorBuyingAgreementGeneralConditionEntity.toFormData(files, 'files');
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/generalConditions/${generalConditionId}/files`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    return new VendorBuyingAgreementGeneralConditionEntity(data);
  },
  updateBuyingAgreementGeneralConditionFilesLanguage: async (
    vendorId: number,
    buyingAgreementId: number,
    generalConditionId: number,
    files: UpdateGeneralConditionFileLanguageRequest[],
  ): Promise<VendorBuyingAgreementGeneralConditionEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/generalConditions/${generalConditionId}/files/language`,
      files,
    );
    return new VendorBuyingAgreementGeneralConditionEntity(data);
  },
  deleteBuyingAgreementGeneralConditionFiles: async (
    vendorId: number,
    buyingAgreementId: number,
    generalConditionId: number,
    ids: number[],
  ): Promise<VendorBuyingAgreementGeneralConditionEntity> => {
    const { data } = await axios.delete(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/generalConditions/${generalConditionId}/files?${ids
        .map((id) => `fileIds=${id}`)
        .join('&')}`,
    );
    return new VendorBuyingAgreementGeneralConditionEntity(data);
  },
  getBuyingAgreementGeneralConditionFile: async (
    vendorId: number,
    buyingAgreementId: number,
    generalConditionId: number,
    fileId: number,
  ): Promise<Blob> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/generalConditions/${generalConditionId}/files/${fileId}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  },
};
