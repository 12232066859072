import { useOutlet } from 'react-router-dom';
import { useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { PermissionKey } from '../../types';
import { LayoutPage } from '../Layout';
import { RequirePermission } from '../Permission';
import { LinkTab } from '../Tab';

export const ReportLayout = () => {
  const { t } = useInternationalization();
  const outlet = useOutlet();

  const tabs = [
    <LinkTab
      label={t('report:sections.failedAuthentication')}
      value={routes.Admin.Reports.FailedAuthentication.path}
      permissions={{ keys: PermissionKey.ReportLogin }}
    />,
    <LinkTab
      label={t('report:sections.invalidAccess')}
      value={routes.Admin.Reports.InvalidAccess.path}
      permissions={{ keys: PermissionKey.ReportLogin }}
    />,
    <LinkTab
      label={t('report:sections.VBA')}
      value={routes.Admin.Reports.UnapprovedBuyingAgreements.path}
      permissions={{ keys: PermissionKey.ReportVendorData }}
    />,
    <LinkTab
      label={t('report:sections.RebateCategory')}
      value={routes.Admin.Reports.RebateCategories.path}
      permissions={{ keys: PermissionKey.ReportVendorData }}
    />,
    <LinkTab
      label={t('report:sections.RebateProgram')}
      value={routes.Admin.Reports.RebatePrograms.path}
      permissions={{ keys: PermissionKey.ReportVendorData }}
    />,
    <LinkTab
      label={t('report:sections.MissingTranslation')}
      value={routes.Admin.Reports.MissingTranslations.path}
      permissions={{ keys: PermissionKey.ReportVendorData }}
    />,
    <LinkTab
      label={t('report:sections.ProductCategories')}
      value={routes.Admin.Reports.VendorProductCategories.path}
      permissions={{ keys: PermissionKey.ReportVendorData }}
    />,
    <LinkTab
      label={t('report:sections.activityLogs')}
      value={routes.Admin.Reports.ActivityLogs.path}
      permissions={{ keys: PermissionKey.ReportActivityLog }}
    />,
    <LinkTab
      label={t('report:sections.holdbacks')}
      value={routes.Admin.Reports.Holdbacks.path}
      permissions={{ keys: PermissionKey.ReportVendorData }}
    />,
  ];

  return (
    <RequirePermission
      keys={[PermissionKey.ReportLogin, PermissionKey.ReportVendorData, PermissionKey.ReportActivityLog]}
      any
    >
      <LayoutPage title={t('report:title')} tabs={tabs} outlet={outlet} />
    </RequirePermission>
  );
};
