import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth, useEntityFormContext } from '../../../hooks';
import { ControlledInput } from '../../Form';

export const TaxInformationForm = () => {
  const { isInternalUser } = useAuth();
  const { t } = useTranslation();
  const { control, variant } = useEntityFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <ControlledInput label={t('vendor:financials.form.gstNumber')} name="gstNumber" control={control} />
      </Grid>
      <Grid item xs={6}>
        <ControlledInput label={t('vendor:financials.form.qstNumber')} name="qstNumber" control={control} />
      </Grid>
      <Grid item xs={variant === 'changeRequest' ? 12 : 6}>
        <ControlledInput
          label={isInternalUser ? t('vendor:financials.form.vendorNotes') : t('vendor:financials.form.comments')}
          name="vendorNotes"
          control={control}
          multiline
          rows={3}
        />
      </Grid>
      <Grid item xs={6}>
        {isInternalUser && variant !== 'changeRequest' && (
          <ControlledInput
            label={t('vendor:financials.form.notes')}
            name="notes"
            control={control}
            multiline
            rows={3}
          />
        )}
      </Grid>
    </Grid>
  );
};
