import React from 'react';
import { VendorEntity, VendorRebateCategoryEntity } from '../../models';

interface VendorRebateCategoryDetailContextType {
  fetchData: () => Promise<void>;
  isLoading: boolean;
  vendorId: number;
  vendor: VendorEntity | null;
  vendorRebateCategory: VendorRebateCategoryEntity | null;
  vendorRebateCategoryId: number;
  readOnly: boolean;
}

export const VendorRebateCategoryDetailContext = React.createContext<VendorRebateCategoryDetailContextType>(
  {} as VendorRebateCategoryDetailContextType,
);
