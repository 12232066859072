import React, { Dispatch, SetStateAction } from 'react';
import { UserEntity } from '../../models';
import { PaginationEntity, UserFilter, UserType } from '../../types';

interface UserContextType {
  type: UserType;
  data: PaginationEntity<UserEntity> | null;
  setData: Dispatch<SetStateAction<PaginationEntity<UserEntity> | null>>;
  fetchData: () => Promise<void>;
  filter: UserFilter;
  setFilter: Dispatch<SetStateAction<UserFilter>>;
  isLoading: boolean;
  hasLastVerified?: boolean;
}

export const UserContext = React.createContext<UserContextType>({} as UserContextType);
