import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { DEFAULT_PAGINATION_FILTER } from '../constants';
import { SearchPeriod, SearchSort, SearchRequest } from '../types';

interface SearchContextType {
  searchRequest: SearchRequest;
  setSearchRequest: Dispatch<SetStateAction<SearchRequest>>;
  searchHasUnmount: boolean;
  setSearchHasUnmount: Dispatch<SetStateAction<boolean>>;
}

export const defaultSearchRequest = {
  query: '',
  period: SearchPeriod.PreviousYear,
  sort: SearchSort.MostRelevant,
  ...DEFAULT_PAGINATION_FILTER,
};

export const SearchContext = React.createContext<SearchContextType>({} as SearchContextType);

export const SearchProvider = ({ children }: PropsWithChildren) => {
  const [searchRequest, setSearchRequest] = useState<SearchRequest>(defaultSearchRequest);
  const [searchHasUnmount, setSearchHasUnmount] = useState<boolean>(false);

  return (
    <SearchContext.Provider value={{ searchRequest, setSearchRequest, searchHasUnmount, setSearchHasUnmount }}>
      {children}
    </SearchContext.Provider>
  );
};
