import { Box, Grid } from '@mui/material';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { FileEntity, StaticPageEntity } from '../../../models';
import { Styles } from '../../../types';
import { ControlledTranslatedInput } from '../../Form';
import { ControlledTranslatedAttachment } from '../../Form/Controlled/ControlledTranslatedAttachment';
import { MAX_FILE_SIZE_MB } from '../../../constants';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
};

interface CmsStaticPageFormProps {
  onFileClick?: (file: FileEntity) => void;
}

export const CmsStaticPageForm = ({ onFileClick }: CmsStaticPageFormProps) => {
  const { t } = useInternationalization();
  const { control, variant, readOnly } = useEntityFormContext<StaticPageEntity>();

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        {!readOnly && (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              variant="standard"
              label={t('cms:staticPages.form.name')}
              name="name"
              control={control}
              required
              isFrRequired
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTranslatedAttachment
            label={t('cms:staticPages.form.file')}
            name="iconImage"
            control={control}
            maxSizeMB={MAX_FILE_SIZE_MB}
            onFileClick={onFileClick}
            required
            onlyImg
            isFrRequired
          />
        </Grid>
      </Grid>
    </Box>
  );
};
