import { VendorEntityChangeRequestEntity } from '../models';
import { Entity, DeletableEntity } from '../types';
import { compareAsc } from './sort';

type ArrayWithKey<T> = { [P in keyof T]: T[P] extends [] ? P : string }[keyof T];

export const padChangeRequestArray = <T extends Record<K, Entity[]>, K extends ArrayWithKey<T>>(
  entity: VendorEntityChangeRequestEntity<T> | null,
  ...keys: K[]
) => {
  if (entity) {
    keys.forEach((key) => {
      if (entity.entity) {
        entity.changeRequest[key] = entity.changeRequest[key]
          .concat(
            entity.entity[key]
              .filter((x) => !entity.changeRequest[key].some((y) => x.id == y.id))
              .map((x) => ({ ...x, isDeleted: true })),
          )
          .sort(compareAsc('id')) as T[K];

        entity.entity[key] = entity.entity[key].sort(compareAsc('id'));
      }
    });
  }
  return entity;
};

export const unpadChangeRequestArray = <T extends Record<K, DeletableEntity[]>, K extends ArrayWithKey<T>>(
  entity: T,
  ...keys: K[]
) => {
  keys.forEach((key) => {
    entity[key] = entity[key].filter((e) => !e.isDeleted) as T[K];
  });
  return entity;
};
