import { useContext, useEffect, useMemo } from 'react';
import { member as memberApi } from '../../api';
import { EntityFormProvider, MemberChangeRequestContext } from '../../contexts';
import { useApi, useEntityForm } from '../../hooks';
import { MemberContactEntity } from '../../models';
import { MemberContactSchema } from '../../schemas';
import { ChangeRequestTrigger } from '../../types';
import { padChangeRequestArray, unpadChangeRequestArray } from '../../utils/changeRequests';
import { EditDrawerChangeRequest } from '../EditDrawerChangeRequest';
import { MemberContactForm } from '../Member/Contacts';
import { ChangeRequestDrawerHeader } from './ChangeRequestDrawerHeader';

export const MemberContactChangeRequest = () => {
  const { changeRequest: memberChangeRequest, isAfter } = useContext(MemberChangeRequestContext);
  const { data, isLoading } = useApi(memberApi.getContactChangeRequest, null, memberChangeRequest.id);
  const { call: update } = useApi(memberApi.approvedOrRejectContactRequest, { successKey: 'common:success.save' });
  const entity = useMemo(() => new MemberContactEntity(), []);
  const paddedData = useMemo(() => padChangeRequestArray(data, 'emailAddresses', 'phoneNumbers'), [data]);

  const { handleSubmit, reset, setValues, isSubmitDisabled, form, handleError } = useEntityForm<MemberContactEntity>(
    paddedData?.entity || entity,
    MemberContactSchema(),
    {
      variant: 'changeRequest',
      readOnly: !isAfter || memberChangeRequest.isDeleted,
    },
  );

  useEffect(() => {
    if (data) {
      if (isAfter) {
        setValues(!memberChangeRequest.isDeleted ? paddedData?.changeRequest || entity : entity);
      } else {
        reset();
      }
    }
  }, [data, isAfter, setValues, reset, entity, paddedData?.changeRequest, memberChangeRequest.isDeleted]);

  const saveDrawer = (e: MemberContactEntity, trigger: ChangeRequestTrigger) => {
    return update(memberChangeRequest.id, trigger, unpadChangeRequestArray(e, 'emailAddresses', 'phoneNumbers'));
  };

  return (
    <EntityFormProvider {...form} isLoading={isLoading || !data}>
      <EditDrawerChangeRequest
        open={true}
        entity={entity}
        handleSubmit={handleSubmit}
        handleError={handleError}
        isSubmitDisabled={memberChangeRequest.isDeleted ? false : isSubmitDisabled}
        reset={reset}
        onSave={saveDrawer}
      >
        <ChangeRequestDrawerHeader />
        <MemberContactForm />
      </EditDrawerChangeRequest>
    </EntityFormProvider>
  );
};
