import { setting as settingApi } from '../../api';
import { useApi, useInternationalization, useSettings } from '../../hooks';
import { SettingSchema } from '../../schemas';
import { PermissionKey } from '../../types';
import { EditCard } from '../Card';
import { LayoutPage } from '../Layout';
import { SettingMailSyncForm } from './SettingMailSyncForm';
import { HasPermissions } from '../Permission';
import { SettingSearch } from './SettingSearch';

export const SettingsLayout = () => {
  const { t } = useInternationalization();
  const { settings, refresh } = useSettings();
  const setSettingsApi = useApi(settingApi.set, { successKey: 'common:success.save' });

  return (
    <LayoutPage
      permissions={{ keys: [PermissionKey.AdminSettingsMailChimp, PermissionKey.AdminSettingsSearch], any: true }}
      title={t('setting:title')}
    >
      <HasPermissions keys={PermissionKey.AdminSettingsMailChimp}>
        {settings && (
          <EditCard
            title={t('setting:mailSync.title')}
            entity={settings}
            schema={SettingSchema()}
            onSave={setSettingsApi.call}
            onConfirm={refresh}
          >
            <SettingMailSyncForm />
          </EditCard>
        )}
      </HasPermissions>

      <HasPermissions keys={PermissionKey.AdminSettingsSearch}>
        <SettingSearch />
      </HasPermissions>
    </LayoutPage>
  );
};
