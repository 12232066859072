import { useContext, useState } from 'react';
import { member as memberApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { MemberDetailContext } from '../../../contexts';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { MemberContactEntity } from '../../../models';
import { PaginationFilter, PermissionKey } from '../../../types';
import { MenuItem } from '../../Menu';
import { MemberContactsList } from './MemberContactsList';

export const MemberContactsListMember = () => {
  const { t } = useInternationalization();
  const { user, isMemberUserSupport, hasPermissions } = useAuth();
  const { memberId, member } = useContext(MemberDetailContext);
  const [filter, setFilter] = useState<PaginationFilter>(DEFAULT_PAGINATION_FILTER);

  const getContactsApi = useApi(memberApi.getAllContacts, null, memberId, filter);
  const { call: createRequest } = useApi(memberApi.createContactChangeRequest, { successKey: 'common:success.save' });
  const { call: updateRequest } = useApi(memberApi.updateContactChangeRequest, { successKey: 'common:success.save' });
  const { call: removeRequest } = useApi(memberApi.removeContactChangeRequest, { successKey: 'common:success.save' });

  const onDelete = async (entity: MemberContactEntity) => {
    await removeRequest(memberId, entity.id);
    await getContactsApi.refresh();
  };

  const onSave = async (entity: MemberContactEntity) => {
    return entity.id ? await updateRequest(memberId, entity) : await createRequest(memberId, entity);
  };

  const canEdit = user?.profile.members?.some((m) => m.id === memberId) || isMemberUserSupport;

  const renderActionsMenuItems = (entity: MemberContactEntity, onClick: () => void) => [
    <MenuItem
      id="remove"
      key="remove"
      onClick={() => {
        onDelete(entity);
        onClick();
      }}
    >
      {t('common:changeRequest.remove')}
    </MenuItem>,
  ];

  return (
    <MemberContactsList
      isChangeRequest
      filter={filter}
      setFilter={setFilter}
      fetchApi={getContactsApi}
      onSave={onSave}
      readOnly={!canEdit}
      tableProps={{
        actionMenuItems: canEdit ? renderActionsMenuItems : undefined,
        hidePending: !member?.isMyLocation && !hasPermissions(PermissionKey.MemberEditContacts),
      }}
    />
  );
};
