import { Button, CircularProgress, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useConfirmationModal } from '../../hooks';
import { palette } from '../../styles/palette';
import { BulkAction, Entity, Styles } from '../../types';

const style: Styles = {
  loading: {
    mx: 2,
  },
  button: {
    fontSize: '14px',
    color: palette.primary.deep,
  },
};

interface BulkActionsProps<T extends Entity> {
  selectedItems: T[];
  setSelectedItems: Dispatch<SetStateAction<T[]>>;
  bulkActions: BulkAction<T>[];
  renderSelection?: (items: T[]) => string;
  disabled?: boolean;
  bulkActionSuffix?: string;
  handleBulkSelection?: (items: T[], bulkActionLabel: string) => boolean;
}

export const BulkActions = <T extends Entity>({
  selectedItems,
  setSelectedItems,
  bulkActions,
  renderSelection,
  disabled,
  bulkActionSuffix,
  handleBulkSelection,
}: BulkActionsProps<T>) => {
  const { t } = useTranslation();
  const [bulkActionInProgress, setBulkActionInProgress] = useState<BulkAction<T> | null>(null);
  const { openModal } = useConfirmationModal();
  const { enqueueSnackbar } = useSnackbar();

  const onBulkActionConfirmed = async (bulkAction: BulkAction<T>) => {
    try {
      await bulkAction.onConfirm(selectedItems);
      setSelectedItems([]);
    } finally {
      setBulkActionInProgress(null);
    }
  };

  const onBulkActionClick = async (bulkAction: BulkAction<T>) => {
    setBulkActionInProgress(bulkAction);
    const showHandleSelection = handleBulkSelection ? handleBulkSelection(selectedItems, bulkAction.label) : false;
    const baseTranslationValues = {
      action: bulkAction.label,
      separator: bulkAction.separator,
      count: selectedItems.length,
    };
    if (showHandleSelection) {
      enqueueSnackbar(t('common:error.bulkSelectionError', { name: bulkAction.label }), { variant: 'error' });
      setBulkActionInProgress(null);
    } else {
      openModal({
        confirmText: t('common:action.confirmButton', { action: bulkAction.label }),
        title: t('common:action.confirmTitle', {
          ...baseTranslationValues,
          name: bulkActionSuffix,
        }),
        description: (
          <Trans
            i18nKey="common:action.confirmDescription"
            values={{
              ...baseTranslationValues,
              name: renderSelection
                ? renderSelection(selectedItems)
                : t('common:table.results', { count: selectedItems.length }),
            }}
          />
        ),
        onConfirm: () => onBulkActionConfirmed(bulkAction),
        onCancel: () => setBulkActionInProgress(null),
      });
    }
  };

  const actionsDisabled = !!bulkActionInProgress || disabled;

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {bulkActionInProgress && <CircularProgress size={20} sx={style.loading} />}
      {!!selectedItems.length &&
        bulkActions.map((bulkAction, index) => (
          <Button
            key={index.toString()}
            sx={style.button}
            disabled={actionsDisabled || bulkAction.disabled}
            startIcon={bulkAction.icon}
            onClick={() => onBulkActionClick(bulkAction)}
          >
            {bulkAction.label}
          </Button>
        ))}
    </Stack>
  );
};
