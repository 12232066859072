import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { vendor as vendorApi } from '../../../api';
import { MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useAuth, useEntityFormContext, useInternationalization } from '../../../hooks';
import { RebateCategoryEntity } from '../../../models/Vendor/RebateCategoryEntity';
import {
  ControlledTranslatedAutocomplete,
  ControlledTranslatedAutocompleteInputProps,
} from '../../Form/Controlled/ControlledTranslatedAutocomplete';
import { ControlledTranslatedInput } from '../../Form';

export const VendorRebateCategoryHeader = () => {
  const { user, isInternalUser } = useAuth();
  const params = useParams();
  const vendorId = Number(user?.profile.vendor?.id ?? params.vendorId);
  const { t } = useInternationalization();
  const { control, readOnly } = useEntityFormContext<RebateCategoryEntity>();

  const inputProps: ControlledTranslatedAutocompleteInputProps<RebateCategoryEntity> = {
    maxLength: MAX_SHORT_TEXT_LENGTH,
    label: t('vendor:rebateCategory.form.name'),
    name: 'name',
    variant: 'standard',
    control: control,
    hideLabel: readOnly,
    readOnly,
    required: true,
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {isInternalUser ? (
          <ControlledTranslatedAutocomplete
            apiFunction={vendorApi.getRebateCategorySuggestions}
            apiExtraParams={[vendorId]}
            minSearchTextLength={1}
            inputProps={inputProps}
          />
        ) : (
          <ControlledTranslatedInput {...inputProps} />
        )}
      </Grid>
    </Grid>
  );
};
