import { DeleteRounded, LinkRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useRef } from 'react';
import { Styles, LanguageCode } from '../../../../types';
import { formatShortDate, getAuthorizeFileExtensions } from '../../../../utils/helper';
import { Container } from '../Container';
import { AttachmentsBanner } from './AttachmentsBanner';
import { TranslatedFileEntity } from '../../../../models/TranslatedFileEntity';

const style: Styles = {
  table: {
    'thead .MuiTableCell-root': {
      border: 0,
    },
  },
  actionCell: {
    py: 0,
  },
  downloadButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '0 !important',
    mt: '-1.5px !important',
    color: 'inherit !important',
  },
  checkbox: {
    padding: '0 !important',
  },
};

interface TranslatedAttachmentsProps {
  onFileClick?: (file: TranslatedFileEntity) => void;
  onChange?: (files: TranslatedFileEntity[]) => void;
  files: TranslatedFileEntity[];
  maxSizeMB?: number;
  maxCount?: number;
  label: string;
  readOnly?: boolean;
  error?: boolean;
  helperText?: string;
  hideLabel?: boolean;
  contentBesideTitle?: JSX.Element;
}

export const TranslatedAttachments = ({
  onChange,
  onFileClick,
  files,
  maxSizeMB,
  maxCount,
  label,
  readOnly,
  helperText,
  hideLabel,
  error,
  contentBesideTitle,
}: TranslatedAttachmentsProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const acceptedExtensions = getAuthorizeFileExtensions()
    .map((ext) => `.${ext.toLowerCase()}`)
    .join(',');

  const handleClick = () => {
    inputRef.current?.click();
  };

  const fileListToArray = (list: FileList | null) => {
    const result: File[] = [];
    if (list) {
      for (let i = 0; i < list.length; i++) {
        const file = list.item(i);
        if (file) {
          result.push(file);
        }
      }
    }

    return result;
  };

  const removeFile = (index: number) => {
    onChange && onChange(files.filter((_, i) => i !== index));
  };

  const handleFileSelection = (list: FileList | null) => {
    const updatedList = [...files];
    fileListToArray(list).forEach((file) => {
      if (!updatedList.some(({ file: f }) => f?.name === file?.name)) {
        updatedList.push({ file: file, languages: [LanguageCode.en] });
      }
    });
    onChange && onChange(updatedList);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    handleFileSelection(ev.target.files);
    ev.target.value = '';
  };

  const handleLanguageChange = (language: LanguageCode, index: number, checked: boolean) => {
    onChange &&
      onChange(
        files.map((file, i) => {
          const fileCopy = { ...file };
          if (index === i) {
            if (checked) {
              fileCopy.languages = [...file.languages, language];
            } else {
              fileCopy.languages = file.languages.filter((l) => l !== language);
            }
          }
          return fileCopy;
        }),
      );
  };

  const handleDrop = (files: FileList) => {
    handleFileSelection(files);
  };
  return (
    <Container label={label} hideLabel={hideLabel} contentBesideTitle={contentBesideTitle}>
      <Stack spacing={1}>
        {!readOnly && (!maxCount || maxCount > files.length) && (
          <Box>
            <AttachmentsBanner
              onClick={handleClick}
              onDrop={handleDrop}
              maxCount={maxCount}
              maxSizeMB={maxSizeMB ?? 0}
            />
            <input type="file" hidden ref={inputRef} multiple onChange={handleChange} accept={acceptedExtensions} />
          </Box>
        )}
        {!!files.length && (
          <TableContainer>
            <Table sx={style.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('common:translationLanguage.En')}</TableCell>
                  <TableCell>{t('common:translationLanguage.Fr')}</TableCell>
                  <TableCell>{t('common:file.name')}</TableCell>
                  <TableCell>{t('common:file.upload')}</TableCell>
                  {!readOnly && <TableCell align="right" />}
                </TableRow>
              </TableHead>
              <TableBody>
                {files?.map((file: TranslatedFileEntity, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          sx={style.checkbox}
                          disabled={readOnly}
                          onChange={(e, checked) => handleLanguageChange(LanguageCode.en, index, checked)}
                          checked={file.languages.includes(LanguageCode.en)}
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          sx={style.checkbox}
                          disabled={readOnly}
                          onChange={(e, checked) => handleLanguageChange(LanguageCode.fr, index, checked)}
                          checked={file.languages.includes(LanguageCode.fr)}
                        />
                      </TableCell>
                      <TableCell>
                        {file.file?.id && onFileClick ? (
                          <Stack direction="row" spacing={0.5}>
                            <LinkRounded color="primary" />
                            <Button
                              disableRipple
                              className="MuiTableCell-root"
                              sx={style.downloadButton}
                              onClick={() => onFileClick(file)}
                            >
                              <Typography variant="underlinedLink">{file.file.name}</Typography>
                            </Button>
                          </Stack>
                        ) : (
                          file.file?.name
                        )}
                      </TableCell>
                      <TableCell>
                        {file.file?.uploadedBy &&
                          file.file?.uploadedDate &&
                          `${formatShortDate(file.file?.uploadedDate)}, ${file.file?.uploadedBy}`}
                      </TableCell>
                      {!readOnly && (
                        <TableCell align="right" sx={style.actionCell}>
                          <IconButton onClick={() => removeFile(index)}>
                            <DeleteRounded />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!files.length && readOnly && <Typography variant="valuePlaceholder">{t('common:notSpecified')}</Typography>}
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Stack>
    </Container>
  );
};
