import { CircularProgress } from '@mui/material';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { CmsStaticPageContext } from '../../../contexts/Cms';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { FileEntity, StaticPageEntity } from '../../../models';
import { routes } from '../../../routes';
import { StaticPageSchema } from '../../../schemas';
import {
  ContentStatus,
  ContentTriggers,
  LanguageCode,
  PermissionKey,
  Styles,
  TranslationLanguage,
} from '../../../types';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { LayoutPage } from '../../Layout';
import { LinkTab } from '../../Tab';
import { CmsChangeLogs } from '../ChangeLog/CmsChangeLogs';
import { CmsWorkflow } from '../CmsWorkflow';
import { CmsStaticPageForm } from './CmsStaticPageForm';
import { useSnackbar } from 'notistack';
import { downloadBlob } from '../../../utils/helper';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const CmsStaticPageDetail = () => {
  const { t, getTranslatablePropertyKey, getTranslation, currentLanguage } = useInternationalization();
  const { hasPermissions } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const outlet = useOutlet();
  const staticPageId = Number(params.staticPageId);
  const { data, refresh, isLoading } = useApi(cmsApi.getStaticPage, null, staticPageId);
  const { call: update } = useApi(cmsApi.updateStaticPage, null);
  const { call: download } = useApi(cmsApi.getStaticPageIconImage, null);
  const { call: upload } = useApi(cmsApi.uploadStaticPageFile, { successKey: 'common:success.action' });
  const { call: trigger, isLoading: isLoadingTrigger } = useApi(cmsApi.updateStaticPageWorkflow, null);
  const userRoutes = routes.Admin;

  const canEdit =
    (data?.status === ContentStatus.InProgress && hasPermissions(PermissionKey.CmsStaticEdit)) ||
    ((data?.status === ContentStatus.Submitted || data?.status === ContentStatus.Approved) &&
      hasPermissions(PermissionKey.CmsStaticApprove));

  const tabs = [
    <LinkTab
      label={t('cms:staticPages.sections.content')}
      value={generatePath(userRoutes.StaticPageDetails.Content.path, { staticPageId })}
      permissions={{ keys: [PermissionKey.CmsStaticView, PermissionKey.CmsStaticEdit], any: true }}
    />,
  ];

  const save = async (entity: StaticPageEntity) => {
    const updatedEntity = await update(entity);
    if (updatedEntity && updatedEntity.id) {
      for (const language of [TranslationLanguage.En, TranslationLanguage.Fr]) {
        const file = entity[`iconImage_${language}`];
        if (file && !file.id) {
          updatedEntity[`iconImage_${language}`] = file;
          enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
          await upload(updatedEntity.id, language.toLocaleLowerCase() as LanguageCode, file as unknown as File);
        }
      }
    } else {
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    await refresh();
    return updatedEntity;
  };

  const downloadFile = async (file: FileEntity) => {
    if (data) {
      const fileData = await download(data.id, currentLanguage);
      if (fileData) {
        downloadBlob(file.name, fileData);
      }
    }
  };

  const onUpdateWorkflowConfirm = (newTrigger: ContentTriggers) => async () => {
    await trigger(staticPageId, newTrigger);
    refresh();
  };

  const renderWorkflow = () => {
    if (
      !data ||
      data?.status == ContentStatus.Rejected ||
      !hasPermissions([PermissionKey.CmsStaticEdit, PermissionKey.CmsStaticApprove], true)
    ) {
      return undefined;
    }

    return (
      <CmsWorkflow
        isLoading={isLoadingTrigger}
        onTrigger={onUpdateWorkflowConfirm}
        status={data.status}
        entityName={getTranslation(data, 'name')}
        approvePermission={PermissionKey.CmsStaticApprove}
        editPermission={PermissionKey.CmsStaticEdit}
        entitySuffix={t('cms:staticPages.actions.suffix')}
      />
    );
  };

  return (
    <CmsStaticPageContext.Provider value={{ staticPage: data, canEdit, isLoading: isLoading, fetchData: refresh }}>
      <LayoutPage
        display="Detail"
        permissions={{ keys: [PermissionKey.CmsStaticView, PermissionKey.CmsStaticEdit], any: true }}
        tabs={tabs}
        outlet={outlet}
      >
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              titleLabel={t(`cms:staticPages.form.name`)}
              title={getTranslatablePropertyKey(data, 'name')}
              entity={data}
              schema={StaticPageSchema()}
              onSave={save}
              onConfirm={() => refresh()}
              hideHeaderWhenEditing
              readOnly={!canEdit}
              breadcrumbs={[
                { title: t('cms:staticPages.title'), href: userRoutes.Cms.StaticPages.path },
                { title: getTranslation(data, 'name') },
              ]}
              extraActions={[
                <CmsChangeLogs
                  key="changeLogs"
                  cmsEntityId={data.id}
                  fetchApi={cmsApi.getStaticPageChangeLogs}
                  addCommentApi={cmsApi.addStaticPageComment}
                  title={t('changeLog:staticPageTitle')}
                />,
              ]}
              workflow={renderWorkflow()}
              alwaysOpen
            >
              <CmsStaticPageForm onFileClick={downloadFile} />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </CmsStaticPageContext.Provider>
  );
};
