import { CheckRounded } from '@mui/icons-material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  MemberGroupMemberPermissionsForm,
  MemberGroupNotesForm,
  MemberGroupTargetedMembershipForm,
  MemberGroupTypeForm,
} from '.';
import { ArchiveBanner, EditDetails, LimitCommaValues } from '..';
import { memberGroup as memberGroupApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { MemberGroupDetailContext } from '../../contexts';
import { useApi, useInternationalization, usePageTitle } from '../../hooks';
import { MemberEntity, MemberGroupEntity } from '../../models';
import { routes } from '../../routes';
import { MemberGroupSchema } from '../../schemas';
import { PaginationFilter, Styles } from '../../types';
import { EditCard } from '../Card';
import { Container } from '../Container';
import { ArchiveMenuItem } from '../Menu';
import { Table, TableColumn } from '../Table';

const style: Styles = {
  loading: {
    mx: 2,
  },
  highlightContainer: {
    mb: 5,
  },
};

export const MemberGroupDetail = () => {
  const { t, getTranslation } = useInternationalization();
  const params = useParams();
  const memberGroupId = Number(params.memberGroupId);
  const [pagination, setPagination] = useState<PaginationFilter>(DEFAULT_PAGINATION_FILTER);
  const {
    data: members,
    isLoading: membersLoading,
    refresh: membersRefresh,
  } = useApi(memberGroupApi.getMembers, null, memberGroupId, pagination);
  const { data, isLoading, refresh } = useApi(memberGroupApi.get, null, memberGroupId);
  const { call } = useApi(memberGroupApi.update, {
    successKey: 'common:success.save',
  });

  const archiveApi = useApi(memberGroupApi.archive, { successKey: 'common:success.action' });
  usePageTitle(data?.name ?? '');

  const onArchiveConfirm = async (isArchived: boolean, entity: MemberGroupEntity) => {
    await archiveApi.call([entity.id], isArchived);
    refresh();
  };

  const onConfirm = () => {
    refresh();
    membersRefresh();
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setPagination((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const renderActionMenuItems = (onClick: () => void) =>
    data
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={data.name}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('memberGroup:action.suffix')}
          />,
        ]
      : [];

  const renderMembershipType = (item: MemberEntity) =>
    item?.membershipType ? getTranslation(item.membershipType, 'name') : '';

  const renderRegions = (item: MemberEntity) => (
    <LimitCommaValues value={item.regions.map((region) => getTranslation(region, 'name')).join(', ')} maxLength={50} />
  );

  return (
    <MemberGroupDetailContext.Provider value={{ memberGroup: data, memberGroupId }}>
      <ArchiveBanner entity={data} onUnarchive={() => data && onArchiveConfirm(false, data)} />
      <Container>
        {isLoading && <CircularProgress size={20} sx={style.loading} />}
        {data && (
          <EditDetails
            titleLabel={t(`memberGroup:table.name`)}
            title="name"
            disabled={data.isArchived}
            entity={data}
            schema={MemberGroupSchema()}
            onSave={call}
            actionMenuItems={renderActionMenuItems}
            onConfirm={() => refresh()}
            breadcrumbs={[
              {
                title: t('memberGroup:title'),
                href: routes.Admin.MemberGroups.path,
              },
              { title: data?.name },
            ]}
          />
        )}
      </Container>
      <Container isHighlighted>
        <Stack spacing={2} sx={style.highlightContainer}>
          {data && (
            <Box>
              <EditCard
                title={t('memberGroup:form.type')}
                entity={data}
                disabled={data.isArchived}
                schema={MemberGroupSchema()}
                onSave={call}
                onConfirm={onConfirm}
              >
                <MemberGroupTypeForm />
              </EditCard>
              <EditCard
                title={t('memberGroup:form.targetedMembership')}
                entity={data}
                disabled={data.isArchived}
                schema={MemberGroupSchema()}
                onSave={call}
                onConfirm={onConfirm}
              >
                <MemberGroupTargetedMembershipForm />
              </EditCard>
              <EditCard
                title={t('memberGroup:form.customPermissions')}
                entity={data}
                disabled={data.isArchived}
                schema={MemberGroupSchema()}
                onSave={call}
                onConfirm={onConfirm}
              >
                <MemberGroupMemberPermissionsForm />
              </EditCard>
              <EditCard
                title={t('memberGroup:form.notes')}
                entity={data}
                disabled={data.isArchived}
                schema={MemberGroupSchema()}
                onSave={call}
                onConfirm={onConfirm}
              >
                <MemberGroupNotesForm />
              </EditCard>
            </Box>
          )}
          <Typography variant="h3">
            {t('memberGroup:sections.memberGroupPreview', { count: members?.totalCount })}
          </Typography>
          <Table
            data={members ?? []}
            translationNamespace="member"
            paginationFilter={pagination}
            onPaginationChange={onPaginationChange}
            isLoading={membersLoading}
            hideTotal
          >
            <TableColumn type="property" width={100} id="fullNumber" />
            <TableColumn type="property" width={100} id="name" />
            <TableColumn type="custom" width={100} id="membershipType" render={renderMembershipType} />
            <TableColumn type="custom" width={100} id="regions" render={renderRegions} />
            <TableColumn type="icon" width={100} id="isMarketing" iconComponent={CheckRounded} align="center" />
          </Table>
        </Stack>
      </Container>
    </MemberGroupDetailContext.Provider>
  );
};
