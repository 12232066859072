import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption, TranslationContext } from '../types';

export function useEnumList<T>(
  enumType: { [key: string]: T },
  translationKey: string,
  withAll?: boolean,
  context: TranslationContext = 'male',
  sortByLabel = true,
  hasDescription = false,
  colors?: { [key: string]: string },
): SelectOption<T>[] {
  const { t } = useTranslation();
  let list: SelectOption<T>[] = withAll ? [{ label: t('common:filter.all', { context }), value: '' as T }] : [];

  list = [
    ...list,
    ...useMemo(
      () =>
        Object.keys(enumType)
          .map((enumKey) => ({
            label: t(`${translationKey}.${enumType[enumKey]}`, { context }),
            value: enumType[enumKey],
            description: hasDescription
              ? t(`${translationKey}.${enumType[enumKey]}Description`, { context })
              : undefined,
            color: colors && enumKey in colors ? colors[enumKey] : undefined,
          }))
          .sort(sortByLabel ? (a, b) => a.label.localeCompare(b.label) : undefined),
      [enumType, t, translationKey, sortByLabel, hasDescription, context, colors],
    ),
  ];

  return list;
}
