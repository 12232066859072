import { VisibilityRounded } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { VendorLocationEntity } from '../../../models';
import { Styles } from '../../../types';
import { AddressForm, EmailAddressListForm, PhoneNumberListForm } from '../../Contact';
import { EntityModifiedDate } from '../../EntityModifiedDate';
import { ControlledCheckbox, ControlledTranslatedInput } from '../../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
  tableCell: {
    px: 1,
    py: 0.5,
    verticalAlign: 'top',
    '&:first-of-type': {
      pl: 0,
    },
    '&:last-child': {
      pr: 0,
    },
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  tableButton: {
    mt: 3,
  },
  buttonBox: {
    mt: 1,
  },
};

export const VendorLocationForm = () => {
  const { t } = useInternationalization();
  const { getValues, control, watch } = useEntityFormContext<VendorLocationEntity>();
  const editing = !!watch('id');

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        {editing && (
          <Grid item xs={12}>
            <EntityModifiedDate entity={getValues()} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTranslatedInput
            maxLength={MAX_SHORT_TEXT_LENGTH}
            rows={2}
            multiline
            label={t('vendor:location.form.description')}
            name="description"
            control={control}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledCheckbox
            label={t('vendor:location.form.isVisibleToMember')}
            icon={VisibilityRounded}
            name="isVisibleToMember"
            control={control}
          />
        </Grid>
        <AddressForm requiredFields={['country', 'city', 'postalCode', 'province', 'street']} />
        <Grid item xs={12}>
          <Typography variant="h3">{t('vendor:location.form.generalContacts')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <EmailAddressListForm />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberListForm />
        </Grid>
      </Grid>
    </Box>
  );
};
