import { EditRounded, LinkRounded } from '@mui/icons-material';
import { Box, Button, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { Breadcrumbs, EntityDetails } from '..';
import { useAuth, useGlobalEdit, useInternationalization } from '../../hooks';
import { ContentEntity } from '../../models';
import { palette } from '../../styles/palette';
import { Breadcrumb, ContentStatus, LanguageCode, PermissionKey, Styles } from '../../types';
import { StaticField } from '../Form';
import { LayoutPage } from '../Layout';
import { CmsContentEdit, CmsContentEditProps } from './CmsContentEdit';
import { CmsContentView } from './CmsContentView';

const style: Styles = {
  saveButton: {
    color: palette.primary.deep,
  },
  container: {
    boxSizing: 'border-box',
    minWidth: { xs: '100%', lg: 960 },
    maxWidth: { xs: 'fit-content', lg: 960 },
    m: '0 auto',
    backgroundColor: palette.white,
  },
  tabContainer: {
    py: 3,
    mt: -1,
  },
  pageContainer: {
    py: 6,
  },
  editingContainer: {
    px: 3,
  },
  staticContainer: {
    px: { xs: 3, md: 12 },
  },
  openLinkButton: {
    color: palette.primary.deep,
  },
  breadcrumbs: {
    mt: -4,
  },
};

interface CmsContentProps<T extends ContentEntity> extends Partial<CmsContentEditProps<T>> {
  contentEntity: T;
  readOnly?: boolean;
  viewPermissions?: PermissionKey | PermissionKey[];
  editPermissions?: PermissionKey | PermissionKey[];
  approvePermissions?: PermissionKey | PermissionKey[];
  isLoading?: boolean;
  onConfirm?: () => Promise<void>;
  downloadApi?: (contentEntityId: number, fileId: number) => Promise<Blob>;
  downloadBlockFileApi: (contentEntityId: number, blockId: number, language: LanguageCode) => Promise<Blob>;
  breadcrumbs?: Breadcrumb[];
  display?: 'Page' | 'Tab';
  hideModifiedDate?: boolean;
}

export const CmsContent = <T extends ContentEntity>({
  contentEntity,
  isLoading,
  viewPermissions,
  editPermissions,
  approvePermissions,
  onConfirm,
  display = 'Tab',
  breadcrumbs,
  hideModifiedDate,
  ...props
}: CmsContentProps<T>) => {
  const { t, getTranslation } = useInternationalization();
  const { hasPermissions } = useAuth();
  const { globalEditing, setGlobalEditing } = useGlobalEdit();
  const [editing, setEditing] = useState(false);

  const readOnly =
    props.readOnly ||
    !editPermissions ||
    !hasPermissions(editPermissions) ||
    (globalEditing && !editing) ||
    (contentEntity?.status === ContentStatus.InProgress && !hasPermissions(editPermissions)) ||
    ((contentEntity?.status === ContentStatus.Approved || contentEntity?.status === ContentStatus.Submitted) &&
      approvePermissions &&
      !hasPermissions(approvePermissions)) ||
    contentEntity?.status === ContentStatus.Rejected;

  const onEditClick = () => setEditing(!editing);

  const onBlockConfirm = async () => {
    if (onConfirm) {
      await onConfirm();
    }
    setEditing(false);
  };

  const onBlockCancel = async () => {
    setEditing(false);
  };

  useEffect(() => {
    setGlobalEditing(editing);
  }, [editing, setGlobalEditing]);

  const actionButtons = !readOnly ? (
    editing ? undefined : (
      <Button
        sx={style.saveButton}
        disabled={globalEditing}
        variant="text"
        startIcon={<EditRounded />}
        onClick={onEditClick}
      >
        {t('common:edit')}
      </Button>
    )
  ) : contentEntity.associatedLink ? (
    <Button
      sx={style.openLinkButton}
      component={Link}
      href={contentEntity.associatedLink}
      startIcon={<LinkRounded />}
      target="_blank"
    >
      {t('cms:content.openLink')}
    </Button>
  ) : undefined;

  const renderContent = () => (
    <Box sx={style.content}>
      {editing &&
      props.schema &&
      props.deleteContentBlockFileApi &&
      props.deleteFilesApi &&
      props.updateApi &&
      props.uploadBlockFileApi ? (
        <CmsContentEdit
          contentEntity={contentEntity}
          schema={props.schema}
          deleteContentBlockFileApi={props.deleteContentBlockFileApi}
          deleteFilesApi={props.deleteFilesApi}
          downloadApi={props.downloadApi}
          updateApi={props.updateApi}
          uploadBlockFileApi={props.uploadBlockFileApi}
          uploadFilesApi={props.uploadFilesApi}
          updateFilesLanguageApi={props.updateFilesLanguageApi}
          downloadBlockFileApi={props.downloadBlockFileApi}
          onConfirm={onBlockConfirm}
          onCancel={onBlockCancel}
        />
      ) : (
        <CmsContentView
          contentEntity={contentEntity}
          downloadApi={props.downloadApi}
          downloadBlockFileApi={props.downloadBlockFileApi}
          hideModifiedDate={display === 'Tab' || hideModifiedDate}
        />
      )}
    </Box>
  );

  return (
    <LayoutPage display={display} permissions={{ keys: viewPermissions }}>
      {display === 'Page' && breadcrumbs && (
        <Box sx={style.breadcrumbs}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Box>
      )}
      <Box
        sx={[
          style.container,
          display === 'Tab' ? style.tabContainer : style.pageContainer,
          editing ? style.editingContainer : style.staticContainer,
        ]}
      >
        <EntityDetails
          entity={contentEntity}
          header={
            editing ? (
              <></>
            ) : (
              <StaticField
                variant="h1"
                value={display === 'Page' ? getTranslation(contentEntity, 'name') : t('cms:content.title')}
              />
            )
          }
          actionButtons={actionButtons}
          isLoading={isLoading}
          alwaysOpen
          hideModifiedDate
        >
          {renderContent()}
        </EntityDetails>
      </Box>
    </LayoutPage>
  );
};
