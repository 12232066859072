import {
  BeenhereRounded,
  CampaignRounded,
  CarpenterRounded,
  CommentBankRounded,
  DashboardRounded,
  FactCheckRounded,
  LibraryBooksRounded,
  LocalShippingRounded,
  MapRounded,
  NewspaperRounded,
  PeopleRounded,
  StoreMallDirectoryRounded,
  TourRounded,
} from '@mui/icons-material';
import { Navigate, useOutlet } from 'react-router-dom';
import { TimbermartLogo } from '../../assets/logos/TimbermartLogo';
import { routes } from '../../routes';
import { LanguageCode, PermissionKey } from '../../types';
import {
  NavigationExpanderMenuItem,
  NavigationMenu,
  NavigationMenuDivider,
  NavigationMenuItem,
  NavigationScrollTop,
} from '../Navigation';
import { LayoutBaseUser } from './LayoutBaseUser';
import { useInternationalization } from '../../hooks';

export const LayoutMemberUser = () => {
  const { t, currentLanguage } = useInternationalization();
  const outlet = useOutlet();

  return (
    <LayoutBaseUser>
      <NavigationScrollTop />
      <NavigationMenu>
        <NavigationMenuItem
          title={t('navigation:sections.dashboard')}
          icon={<DashboardRounded />}
          href={routes.Member.Dashboard.path}
        />
        <NavigationMenuItem
          title={t('navigation:sections.buyGuide')}
          icon={<StoreMallDirectoryRounded />}
          href={routes.Member.BuyGuide.path}
          matchPath="/member/buy-guide/*"
          permissions={{ keys: PermissionKey.VendorViewBuyGuide }}
        />
        <NavigationMenuItem
          key="contentAndPublications"
          title={t('navigation:sections.news')}
          icon={<NewspaperRounded />}
          href={routes.Member.Cms.path}
          matchPath="/member/content-publications/*"
        />
        <NavigationMenuItem
          title={t('navigation:sections.forestProducts')}
          icon={<CarpenterRounded />}
          href={routes.Member.ForestProducts.path}
          permissions={{
            keys: PermissionKey.CommodityPriceView,
          }}
          matchPath="/member/forest-products/*"
        />
        <NavigationMenuItem
          title={t('navigation:sections.confidentialCommitmentIncentives')}
          icon={<TourRounded />}
          href={routes.Member.CommitmentEventDetails.path}
          matchPath="member/cci/*"
          permissions={{ keys: PermissionKey.CciViewCommitments }}
        />
        <NavigationMenuItem
          title={t('navigation:sections.tbmContacts')}
          icon={<TimbermartLogo />}
          href={routes.Member.TBMContacts.path}
          matchPath="member/tbm-contacts/*"
        />
        <NavigationMenuItem
          key="members"
          title={t('navigation:sections.members')}
          icon={<BeenhereRounded />}
          href={routes.Member.Member.path}
          matchPath={[`${routes.Member.Member.path}/*`]}
          permissions={{ keys: PermissionKey.MemberViewMembers }}
        />
        <NavigationMenuItem
          title={t('navigation:sections.users')}
          key="users"
          icon={<PeopleRounded />}
          href={routes.Member.User.path}
          matchPath="/member/users/*"
          permissions={{ keys: PermissionKey.AdminManageMemberUsers }}
        />
        <NavigationMenuDivider />
        <NavigationExpanderMenuItem type="vertical" expanded={false}>
          <NavigationMenuItem
            title={t('navigation:sections.flyerHome')}
            icon={<MapRounded />}
            href={t('navigation:externalSites.flyerHome')}
            target="_blank"
          />
          {currentLanguage === LanguageCode.en && (
            <NavigationMenuItem
              title={t('navigation:sections.flyerDocket')}
              icon={<FactCheckRounded />}
              href={t('navigation:externalSites.flyerDocket')}
              target="_blank"
            />
          )}
          <NavigationMenuItem
            title={t('navigation:sections.flyerOrder')}
            icon={<LibraryBooksRounded />}
            href={t('navigation:externalSites.flyerOrder')}
            target="_blank"
          />
          <NavigationMenuItem
            title={t('navigation:sections.memberServices')}
            icon={<CommentBankRounded />}
            href={t('navigation:externalSites.memberServices')}
            target="_blank"
          />
          <NavigationMenuItem
            title={t('navigation:sections.tbmSocial')}
            icon={<CampaignRounded />}
            href={t('navigation:externalSites.tbmSocial')}
            target="_blank"
          />
          <NavigationMenuItem
            title={t('navigation:sections.distribution')}
            icon={<LocalShippingRounded />}
            href={t('navigation:externalSites.distribution')}
            target="_blank"
          />
        </NavigationExpanderMenuItem>
      </NavigationMenu>
      {outlet ?? <Navigate to={routes.Member.Dashboard.path} />}
    </LayoutBaseUser>
  );
};
