import { BlockRounded, CheckRounded } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useContext } from 'react';
import { BuyGuideVendorDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { useInternationalization } from '../../hooks';
import { Styles } from '../../types';
import { formatAddress } from '../../utils/helper';
import { StaticField } from '../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 2,
  },
};

export const BuyGuideVendorForm = () => {
  const { t, getTranslation } = useInternationalization();
  const { vendor } = useContext(BuyGuideVendorDetailContext);

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={4} lg={3}>
          <StaticField
            label={t('buyGuide:form.vendorDesignations')}
            value={vendor?.vendorDesignations.map((o) => getTranslation(o, 'name')).join(', ')}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <StaticField
            label={t('buyGuide:form.isCCIPreferred')}
            value={vendor?.cciPreferred ? <CheckRounded /> : <BlockRounded />}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <StaticField
            label={t('buyGuide:form.centralBilling')}
            value={vendor?.centralBilling ? <CheckRounded /> : <BlockRounded />}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          <StaticField
            label={t('buyGuide:form.directBilling')}
            value={vendor?.directBilling ? <CheckRounded /> : <BlockRounded />}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <StaticField label={t('buyGuide:form.responsibleBuyer')} value={vendor?.responsibleBuyer} />
        </Grid>
        <Grid item xs={12}>
          <StaticField label={t('common:contact.address.address')} value={formatAddress(vendor?.address)} />
        </Grid>

        <Grid item xs={12} sx={style.fieldGroup}>
          <StaticField label={t('buyGuide:form.website')} value={vendor?.website} linkType="url" />
        </Grid>
        <Grid item xs={12}>
          <StaticField label={t('buyGuide:form.notes')} value={vendor?.memberNotes} />
        </Grid>
      </Grid>
    </Box>
  );
};
