import axios from 'axios';
import { OfficeEntity } from '../../models';
import { ExportType, OfficeFilter, PaginationEntity } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const Office = {
  getAllOffices: async (officeFilter: OfficeFilter): Promise<PaginationEntity<OfficeEntity>> => {
    const { data } = await axios.post('api/office/search', officeFilter);
    return { ...data, data: data.data.map((d: OfficeEntity) => new OfficeEntity(d)) };
  },
  getOffice: async (officeId: number): Promise<OfficeEntity> => {
    const { data } = await axios.get(`api/office/${officeId}`);
    return new OfficeEntity(data);
  },
  createOffice: async (entity: OfficeEntity): Promise<OfficeEntity> => {
    const { data } = await axios.post('api/office', entity);
    return new OfficeEntity(data);
  },
  updateOffice: async (entity: OfficeEntity): Promise<OfficeEntity> => {
    const { data } = await axios.put(`api/office/${entity.id}`, entity);
    return new OfficeEntity(data);
  },
  archiveOffice: async (ids: number[], isArchived: boolean): Promise<OfficeEntity[]> => {
    const { data } = await axios.put(`api/office/archive`, { isArchived, ids });
    return data.map((v: OfficeEntity) => new OfficeEntity(v));
  },
  exportAllOffices: async (exportType: ExportType): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/office/export?type=${exportType}`, { responseType: 'blob' });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
