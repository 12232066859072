import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { role as roleApi } from '../../api';
import { useApi, useInternationalization, usePageTitle } from '../../hooks';
import { UserType } from '../../types';
import { Table, TableColumn } from '../Table';
import { RoleEntity } from '../../models';
import { RoleEditDrawer } from '.';
import { LayoutPage } from '../Layout';

interface RoleListViewProps {
  type: UserType;
}

export const RoleListView = ({ type }: RoleListViewProps) => {
  const { t, getTranslation } = useInternationalization();
  const { data, isLoading } = useApi(roleApi.getAll, null, type);
  const [selectedRole, setSelectedRole] = useState<RoleEntity | null>(null);
  usePageTitle(`user:role.sections.${type}`);

  const renderActionsMenuItems = (role: RoleEntity, onClick: () => void) => [
    <MenuItem
      id="managePermissions"
      key="managePermissions"
      onClick={() => {
        setSelectedRole(role);
        onClick();
      }}
    >
      {t('user:role.actions.managePermissions')}
    </MenuItem>,
  ];

  return (
    <LayoutPage display="Tab" title={t(`user:role.sections.${type}`)}>
      <Table
        actionMenuItems={renderActionsMenuItems}
        data={data ?? []}
        isLoading={isLoading}
        translationNamespace="user:role"
      >
        <TableColumn type="custom" id="name" render={(role: RoleEntity) => getTranslation(role, 'name')} />
      </Table>
      {selectedRole && <RoleEditDrawer entity={selectedRole} onClose={() => setSelectedRole(null)} />}
    </LayoutPage>
  );
};
