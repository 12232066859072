import { useOutlet } from 'react-router-dom';
import { useInternationalization } from '../../hooks';
import { LayoutPage } from '../Layout';

export const CommitmentLayout = () => {
  const { t } = useInternationalization();
  const outlet = useOutlet();

  return <LayoutPage display="Page" title={t('cci:title')} outlet={outlet} />;
};
