import { Control, Controller } from 'react-hook-form';
import { AssignedPermissionsList } from './AssignedPermissionsList';
import { UserType } from '../../types';

interface ControlledAssignedPermissionsListProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  readOnly?: boolean;
  userType: UserType;
}

export const ControlledAssignedPermissionsList = ({
  name,
  control,
  readOnly,
  userType,
}: ControlledAssignedPermissionsListProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <AssignedPermissionsList
          value={value}
          onChange={(value) => onChange(value)}
          readOnly={readOnly || control._options.context?.readOnly}
          userType={userType}
        />
      )}
    />
  );
};
