import { ArrowForwardRounded, SvgIconComponent } from '@mui/icons-material';
import { Card, Link, Stack, SvgIconProps, Typography } from '@mui/material';
import { createElement, isValidElement } from 'react';
import { palette } from '../../../styles/palette';
import { Styles } from '../../../types';
import { Loading } from '../../Loading';
import { Widget, WidgetProps } from './Widget';

const style: Styles = {
  card: {
    py: 2,
    px: 3,
    mb: 2,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  },
  title: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '12px',
  },
  count: {
    mt: 1,
    mb: 1,
    fontSize: '48px',
    fontWeight: 700,
    color: palette.primary.main,
  },
  icon: {
    fontSize: 24,
    color: palette.primary.main,
  },
  linkIcon: {
    fontSize: 24,
    color: palette.primary.deep,
  },
};

export interface CounterWidgetProps extends WidgetProps {
  isLoading?: boolean;
  count?: number;
  icon?: SvgIconComponent | JSX.Element;
  title?: string;
  link?: string;
  sx?: Styles[0];
}

export const CounterWidget = ({ isLoading, count, title, link, icon, ...props }: CounterWidgetProps) => (
  <Widget {...props}>
    <Card sx={[style.card, ...(props.sx ? [props.sx] : [])]}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack component={Link} href={link}>
          <Typography sx={style.count}>{count}</Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
              {icon &&
                (isValidElement(icon)
                  ? icon
                  : createElement(icon as SvgIconComponent, { sx: style.icon } as SvgIconProps))}
              <Typography sx={style.title}>{title}</Typography>
            </Stack>
            <ArrowForwardRounded sx={style.linkIcon} />
          </Stack>
        </Stack>
      )}
    </Card>
  </Widget>
);
