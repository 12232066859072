import { ForestProductGroup } from './forestProductGroup';
import { ForestProduct } from './forestProduct';
import { ForestProductPriceUpdate } from './forestProductPriceUpdate';
import { commitmentDocument } from './commitmentDocument';

export const forestProduct = {
  ...ForestProductGroup,
  ...ForestProduct,
  ...ForestProductPriceUpdate,
  ...commitmentDocument,
};
