import { MemberGroupType } from '../../types';
import { CategorizationEntity } from '../Categorization/CategorizationEntity';
import { EditableEntity } from '../EditableEntity';
import { MemberPermissionEntity } from '../MemberPermission/MemberPermissionEntity';

export class MemberGroupEntity extends EditableEntity {
  name: string;
  membersCount: number;
  notes: string;
  isBasedOnMemberCriteria: boolean;
  isMarketing: boolean | null;
  regions: CategorizationEntity[];
  regionIds: number[];
  membershipTypes: CategorizationEntity[];
  membershipTypeIds: number[];
  permissions: MemberPermissionEntity[];
  isArchived: boolean;
  type: MemberGroupType;

  constructor(entity?: MemberGroupEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.membershipTypeIds = entity.membershipTypes?.map((m) => m.id);
      this.regionIds = entity.regions?.map((r) => r.id);
    } else {
      this.name = '';
      this.membersCount = 0;
      this.notes = '';
      this.isBasedOnMemberCriteria = false;
      this.isMarketing = null;
      this.regionIds = [];
      this.membershipTypeIds = [];
      this.permissions = [];
      this.isArchived = false;
      this.type = MemberGroupType.General;
    }
  }
}
