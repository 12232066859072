import axios from 'axios';
import { NotificationEntity } from '../models';
import { NotificationFilter, PaginationEntity } from '../types';

export const notification = {
  hasUnread: async (): Promise<boolean> => {
    const { data } = await axios.get('api/notifications/unread');
    return data;
  },
  getAll: async (filter: NotificationFilter): Promise<PaginationEntity<NotificationEntity>> => {
    const { data } = await axios.post(`api/notifications/search`, filter);
    return data;
  },
  readAll: async (): Promise<void> => {
    return await axios.put('api/notifications/read');
  },
  read: async (id: number): Promise<void> => {
    return await axios.put(`api/notifications/${id}/read`);
  },
};
