import { Grid } from '@mui/material';
import { categorization as categorizationApi } from '../../api';
import { useApi, useEntityFormContext, useInternationalization } from '../../hooks';
import { MemberGroupEntity } from '../../models';
import { ControlledCheckbox, ControlledMultiSelect, ControlledSelectBoolean } from '../Form';

export const MemberGroupTargetedMembershipForm = () => {
  const { t, getTranslation } = useInternationalization();
  const { data: membershipTypes } = useApi(categorizationApi.getAll, null, 'membershipType', false);
  const { data: regions } = useApi(categorizationApi.getAll, null, 'region', false);
  const { setValue, control, watch, variant } = useEntityFormContext<MemberGroupEntity>();

  const isBasedOnMemberCriteriaWatch = watch('isBasedOnMemberCriteria');
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mt={1.5}>
        <ControlledCheckbox
          label={t('memberGroup:form.isBasedOnMemberCriteria')}
          name="isBasedOnMemberCriteria"
          control={control}
        />
      </Grid>
      {isBasedOnMemberCriteriaWatch && (
        <>
          <Grid item xs={variant === 'drawer' ? 12 : 5}>
            <ControlledMultiSelect
              label={t('memberGroup:form.membershipTypes')}
              name="membershipTypeIds"
              options={membershipTypes?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
              control={control}
              multiple
              defaultToAll
            />
          </Grid>
          <Grid item xs={variant === 'drawer' ? 12 : 5}>
            <ControlledMultiSelect
              label={t('memberGroup:form.regions')}
              name="regionIds"
              options={regions?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
              control={control}
              multiple
              defaultToAll
            />
          </Grid>
          <Grid item xs={variant === 'drawer' ? 6 : 2}>
            <ControlledSelectBoolean
              label={t('memberGroup:form.marketing')}
              name="isMarketing"
              control={control}
              setValue={setValue}
              nullable
              defaultToAll
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
