import { useContext, useEffect, useMemo } from 'react';
import { vendor as vendorApi } from '../../api';
import { EntityFormProvider, VendorChangeRequestContext } from '../../contexts';
import { useApi, useEntityForm } from '../../hooks';
import { VendorInvoicingInformationEntity } from '../../models';
import { VendorInvoicingInformationSchema } from '../../schemas';
import { ChangeRequestTrigger } from '../../types';
import { EditDrawerChangeRequest } from '../EditDrawerChangeRequest';
import { InvoicingInformationForm } from '../Vendor/Financials/InvoicingInformationForm';
import { ChangeRequestDrawerHeader } from './ChangeRequestDrawerHeader';

export const InvoicingInformationChangeRequest = () => {
  const { changeRequest: vendorChangeRequest, isAfter } = useContext(VendorChangeRequestContext);
  const { data, isLoading } = useApi(vendorApi.getInvoicingInformationChangeRequest, null, vendorChangeRequest.id);
  const { call: update } = useApi(vendorApi.approvedOrRejectInvoicingInformationChangeRequest, {
    successKey: 'common:success.save',
  });
  const entityPlaceholder = useMemo(() => new VendorInvoicingInformationEntity(), []);

  const { handleSubmit, reset, setValues, isSubmitDisabled, form, handleError } =
    useEntityForm<VendorInvoicingInformationEntity>(
      data?.entity ?? entityPlaceholder,
      VendorInvoicingInformationSchema(),
      {
        variant: 'changeRequest',
        readOnly: !isAfter,
      },
    );

  useEffect(() => {
    if (data) {
      if (isAfter) {
        setValues(data?.changeRequest || entityPlaceholder);
      } else {
        reset();
      }
    }
  }, [data, isAfter, setValues, reset, entityPlaceholder, data?.changeRequest]);

  const saveDrawer = (e: VendorInvoicingInformationEntity, trigger: ChangeRequestTrigger) => {
    return update(vendorChangeRequest.id, trigger, e);
  };

  return (
    <EntityFormProvider {...form} isLoading={isLoading || !data}>
      <EditDrawerChangeRequest
        open={true}
        entity={entityPlaceholder}
        handleSubmit={handleSubmit}
        handleError={handleError}
        isSubmitDisabled={isSubmitDisabled}
        reset={reset}
        onSave={saveDrawer}
      >
        <ChangeRequestDrawerHeader />
        <InvoicingInformationForm />
      </EditDrawerChangeRequest>
    </EntityFormProvider>
  );
};
