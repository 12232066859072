import { CheckRounded, BlockRounded, SvgIconComponent } from '@mui/icons-material';
import { Control, Controller } from 'react-hook-form';
import { Checkbox } from '../Base/Checkbox';
import { StaticField } from '../Base/StaticField';

interface ControlledCheckboxProps extends React.ComponentProps<typeof Checkbox> {
  label: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  icon?: SvgIconComponent;
}

export const ControlledCheckbox = ({ name, control, label, icon, ...props }: ControlledCheckboxProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState: { isDirty } }) =>
      control._options.context?.readOnly ? (
        <StaticField
          label={label}
          icon={icon}
          value={typeof field.value === 'boolean' ? field.value ? <CheckRounded /> : <BlockRounded /> : field.value}
        />
      ) : (
        <Checkbox
          {...field}
          {...props}
          checked={field.value}
          className={control._options.context?.variant === 'changeRequest' && isDirty ? 'dirty' : undefined}
          label={label}
          icon={icon}
          disabled={control._options.context?.readOnly}
        />
      )
    }
  />
);
