import { mixed, object, string } from 'yup';
import { MAX_FILE_SIZE_MB, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import i18n from '../../i18n';
import { StaticPageEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { mbToBytes } from '../../utils/file';
import { isImgExtension } from '../../utils/schemas';

export const StaticPageSchema = (): EntityFormSchema<StaticPageEntity> => ({
  schema: object().shape(
    {
      name_En: string().enRequired().max(MAX_SHORT_TEXT_LENGTH),
      name_Fr: string().frRequired().max(MAX_SHORT_TEXT_LENGTH),
      iconImage_En: mixed()
        .required(`${i18n.t('common:translationLanguage.En')} ${i18n.t('common:error.fieldRequired')}`)
        .test('max-size', 'common:error.arrayMaxItemSize', (file) =>
          file ? file.size < mbToBytes(MAX_FILE_SIZE_MB) : true,
        )
        .test('file-type', 'common:error.onlyImgExtension', isImgExtension),
      iconImage_Fr: mixed()
        .required(`${i18n.t('common:translationLanguage.Fr')} ${i18n.t('common:error.fieldRequired')}`)
        .test('max-size', 'common:error.arrayMaxItemSize', (file) =>
          file ? file.size < mbToBytes(MAX_FILE_SIZE_MB) : true,
        )
        .test('file-type', 'common:error.onlyImgExtension', isImgExtension),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
