import { useFieldArray } from 'react-hook-form';
import { member as memberApi } from '../../api';
import { useEntityFormContext, useInternationalization } from '../../hooks';
import { MemberEntity, MemberGroupEntity, MemberPermissionEntity } from '../../models';
import { MemberPermissionsList } from '../MemberPermissions';

export const MemberGroupMemberPermissionsForm = () => {
  const { t } = useInternationalization();
  const { control, readOnly } = useEntityFormContext<MemberGroupEntity>();

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'permissions',
    keyName: 'customId',
  });

  const onAddPermission = ({ id, name }: MemberEntity) => {
    append({ ...new MemberPermissionEntity(), memberId: id, memberName: name });
  };

  return (
    <MemberPermissionsList
      permissions={fields}
      isIncludedProperty="isIncluded"
      apiCall={memberApi.getSuggestions}
      renderKeyValue={(p: MemberPermissionEntity) => ({ label: p.memberName ?? '', value: p.memberId })}
      renderOptionLabel={(item: MemberEntity) => `${item.fullNumber} - ${item.name}`}
      nameColumn={t('memberGroup:form.member')}
      isIncludedColumn={t('memberGroup:form.permission')}
      onUpdate={update}
      onAdd={onAddPermission}
      readOnly={readOnly}
      onRemove={remove}
    />
  );
};
