import { useContext, useEffect } from 'react';
import { VendorBuyingAgreementContext } from '../../../../contexts/Vendor';
import { useEntityFormContext } from '../../../../hooks';
import { VendorBuyingAgreementGeneralConditionEntity } from '../../../../models';
import { VendorGeneralConditionForm } from '../../GeneralConditions';

export const VendorBuyingAgreementGeneralConditionForm = () => {
  const { vendorBuyingAgreement } = useContext(VendorBuyingAgreementContext);
  const { setValue, watch } = useEntityFormContext<VendorBuyingAgreementGeneralConditionEntity>();

  const editing = !!watch('id');

  useEffect(() => {
    if (!editing) {
      setValue('effectiveDate', vendorBuyingAgreement.effectiveDate);
      setValue('expiryDate', vendorBuyingAgreement.expiryDate);
    }
  }, [vendorBuyingAgreement, setValue, editing]);

  return <VendorGeneralConditionForm />;
};
