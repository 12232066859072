import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Card, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { DOLLAR_MAX_DECIMALS, PERCENT_MAX_DECIMALS } from '../../constants';
import { BuyGuideVendorRebateProgramDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorRebateProgramDetailContext';
import { useAuth, useInternationalization } from '../../hooks';
import { VendorRebateCategoryRebateSummaryGroupDetailEntity } from '../../models';
import { palette } from '../../styles/palette';
import { LanguageCode, PermissionKey, RebateTypeAndUnitType, Styles } from '../../types';
import { numberFormatter } from '../../utils/formatters';
import { calculateTermValue } from '../../utils/rebate';
import { LayoutPage } from '../Layout';

const style: Styles = {
  container: {
    pb: 4,
  },
  divider: {
    borderBottom: '1px solid ' + palette.grey[300],
    marginLeft: 2,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
  },
  infoIcon: {
    fontSize: 15,
    marginLeft: 0.5,
  },
};

export const BuyGuideVendorRebateProgramRebates = () => {
  const internationalization = useInternationalization();
  const { t, getTranslation, currentLanguage } = internationalization;
  const { hasPermissions } = useAuth();
  const { rebateProgram } = useContext(BuyGuideVendorRebateProgramDetailContext);

  const renderGroupSummary = (detail: VendorRebateCategoryRebateSummaryGroupDetailEntity) => {
    let displayedValue: string | null = null;
    if (detail.value) {
      const formatter = numberFormatter(
        currentLanguage,
        detail.unit !== RebateTypeAndUnitType.Custom
          ? t(`common:rebateTypeAndUnitType.${detail.unit}`)
          : getTranslation(detail, 'customUnit'),
        detail.unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
        detail.unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
      );
      displayedValue = formatter(detail.value).formatted.join('');
    } else {
      const textValue = getTranslation(detail, 'textValue');
      if (textValue) {
        displayedValue = textValue;
      }
    }

    const rebateTypeAbbreviation = getTranslation(detail, 'rebateTypeName');
    if (rebateTypeAbbreviation && displayedValue) {
      displayedValue += ` ${rebateTypeAbbreviation}`;
    }

    return displayedValue;
  };

  const renderPercentage = (value: number | undefined | null) => {
    if (!value) {
      return '';
    }
    const formatter = numberFormatter(
      currentLanguage,
      t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Percent}`),
      PERCENT_MAX_DECIMALS,
      true,
    );
    return formatter(value).formatted.join('');
  };

  if (!rebateProgram) {
    return null;
  }

  return (
    <LayoutPage display="Tab" permissions={{ keys: PermissionKey.VendorViewPrograms }}>
      <Box sx={style.container}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {hasPermissions(PermissionKey.VendorViewPrograms) && (
                <>
                  <Grid item xs={6}>
                    <Typography sx={style.title}>{t('buyGuide:rebatePrograms.rebates.calculationMethod')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={style.title}>{t('buyGuide:rebatePrograms.rebates.notes')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {rebateProgram.rebateCategory.rebate?.calculationMethod
                        ? getTranslation(rebateProgram.rebateCategory.rebate.calculationMethod, 'value')
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {rebateProgram.rebateCategory.rebate
                        ? getTranslation(rebateProgram.rebateCategory.rebate, 'calculationMethodMemberNotes')
                        : ''}
                    </Typography>
                  </Grid>
                </>
              )}

              {hasPermissions(PermissionKey.VendorViewRebateTerms) && (
                <>
                  <Grid item sx={style.divider} xs={12} />

                  <Grid item xs={6}>
                    <Typography sx={style.title}>{t('buyGuide:rebatePrograms.rebates.termValue')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={style.title}>{t('buyGuide:rebatePrograms.rebates.notes')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {calculateTermValue(rebateProgram.rebateCategory.rebate, internationalization) || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography whiteSpace="pre-wrap">
                      {`${
                        getTranslation(rebateProgram.rebateCategory.rebate, 'paymentTermMemberNotes')
                          ? getTranslation(rebateProgram.rebateCategory.rebate, 'paymentTermMemberNotes') + '\n'
                          : ''
                      }`}
                      {`${t('buyGuide:rebatePrograms.rebates.payment')} ${
                        getTranslation(rebateProgram.rebateCategory.rebate, 'memberPaymentDate') ?? ''
                      }`}
                    </Typography>
                  </Grid>
                </>
              )}

              {hasPermissions(PermissionKey.VendorViewRebateFOID) && (
                <>
                  <Grid item sx={style.divider} xs={12} />
                  <Grid item xs={6} display="flex" alignItems="center">
                    <Typography sx={style.title}>{getTranslation(rebateProgram.foid, 'title')}</Typography>
                    <Tooltip
                      title={
                        <Typography variant="tooltip" whiteSpace="pre-wrap">
                          {getTranslation(rebateProgram.foid, 'description')}
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon sx={style.infoIcon} htmlColor={palette.grey[500]} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={style.title}>{t('buyGuide:rebatePrograms.rebates.notes')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {rebateProgram.foid.details.length ? (
                      rebateProgram.foid.details.map((detail, index) => (
                        <Grid container key={index} spacing={2}>
                          <Grid item xs={6}>
                            <Typography key={index}>{renderGroupSummary(detail)}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{getTranslation(detail, 'memberNotes')}</Typography>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Typography>-</Typography>
                    )}
                  </Grid>
                </>
              )}

              {!!rebateProgram.payableRebatesSummary?.groups.length &&
                hasPermissions(
                  [
                    PermissionKey.VendorViewRebateMP,
                    PermissionKey.VendorViewRebateQP,
                    PermissionKey.VendorViewRebateSP,
                    PermissionKey.VendorViewRebateAP,
                  ],
                  true,
                ) && (
                  <>
                    <Grid item sx={style.divider} xs={12} />
                    <Grid item xs={12}>
                      {rebateProgram.payableRebatesSummary?.groups?.map((group, index, groups) => (
                        <>
                          <Grid container key={index} spacing={2}>
                            <Grid item xs={6} display="flex" alignItems="center">
                              <Typography sx={style.title}>{getTranslation(group, 'title')}</Typography>
                              <Tooltip
                                title={
                                  <Typography variant="tooltip" whiteSpace="pre-wrap">
                                    {getTranslation(group, 'description')}
                                  </Typography>
                                }
                                placement="top"
                                arrow
                              >
                                <InfoOutlinedIcon sx={style.infoIcon} htmlColor={palette.grey[500]} />
                              </Tooltip>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={style.title}>{t('buyGuide:rebatePrograms.rebates.notes')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {group.details.map((detail, index) => (
                                <Grid container key={index} spacing={2}>
                                  <Grid item xs={6}>
                                    <Typography>{renderGroupSummary(detail)}</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>{getTranslation(detail, 'memberNotes')}</Typography>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                            {groups.length != index + 1 && <Grid item sx={style.divider} xs={12} mb={2} />}
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </>
                )}

              {hasPermissions(PermissionKey.VendorViewPrograms) && (
                <>
                  <Grid item sx={style.divider} xs={12} />
                  <Grid item xs={12} display="flex" alignItems="center">
                    <Typography sx={style.title}>{t('buyGuide:rebatePrograms.rebates.completeDiscount')}</Typography>
                    <Tooltip
                      title={
                        <Typography variant="tooltip" whiteSpace="pre-wrap">
                          {t('buyGuide:rebatePrograms.rebates.description.completeDiscount')}
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon sx={style.infoIcon} htmlColor={palette.grey[500]} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {renderPercentage(rebateProgram.payableRebatesSummary?.completeDiscount) || '-'}
                    </Typography>
                  </Grid>
                  <Grid item sx={style.divider} xs={12} />
                  <Grid item xs={12} display="flex" alignItems="center">
                    <Typography sx={style.title}>{t('buyGuide:rebatePrograms.rebates.totalRebate')}</Typography>
                    <Tooltip
                      title={
                        <Typography variant="tooltip" whiteSpace="pre-wrap">
                          {t('buyGuide:rebatePrograms.rebates.description.totalRebate')}
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon sx={style.infoIcon} htmlColor={palette.grey[500]} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {renderPercentage(rebateProgram.payableRebatesSummary?.totalRebates) || '-'}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </LayoutPage>
  );
};
