import { VendorTierGrowthEntity } from './VendorTierGrowthEntity';

export class VendorBuyingAgreementTierGrowthEntity extends VendorTierGrowthEntity {
  isVBAEditable: boolean;

  constructor(entity?: VendorBuyingAgreementTierGrowthEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.isVBAEditable = true;
    }
  }
}
