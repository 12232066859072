import { AddRounded, DeleteRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { DOLLAR_MAX_DECIMALS, MAX_SHORT_TEXT_LENGTH, PERCENT_MAX_DECIMALS } from '../../../constants';
import { VendorTierGrowthContext } from '../../../contexts/Vendor/VendorTierGrowthContext';
import {
  useAuth,
  useBreakpoints,
  useEntityFormContext,
  useEntityFormFieldArray,
  useEnumList,
  useInternationalization,
} from '../../../hooks';
import { VendorTierGrowthEntity, VendorTierGrowthValueEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { LanguageCode, RebateTypeAndUnitType, Styles } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { ControlledCheckbox, ControlledInput, ControlledSelect, ControlledTranslatedInput, Switch } from '../../Form';

const style: Styles = {
  notes: {
    mt: 1,
  },
  tableCell: {
    px: 1.25,
    py: 0.5,
    verticalAlign: 'top',
  },
  tableRow: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${palette.grey[300]}`,
    },
    '& .MuiTableCell-root:first-of-type': {
      pl: 0,
    },
  },
  deleteIcon: {
    verticalAlign: 'top',
    pt: '12px !important',
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  buttonBox: {
    mt: 1,
    borderBottom: `1px solid ${palette.grey[300]}`,
    pb: 3,
  },
  isRetroactiveToDollarContainer: {
    pt: '15px !important',
    pl: 3,
  },
  isTierOrGrowthContainer: {
    pt: '14px !important',
  },
};

export const VendorTierGrowthForm = () => {
  const { isInternalUser } = useAuth();
  const { t, currentLanguage } = useInternationalization();
  const { isEditingOrAddingGrowth, tierOrGrowth, setTierOrGrowth, setIsEditingOrAddingGrowth } =
    useContext(VendorTierGrowthContext);
  const { control, readOnly, watch, getValues, reset, setIgnoreDirtySubmit, formState } =
    useEntityFormContext<VendorTierGrowthEntity>();
  const values = useEntityFormFieldArray(control, 'values');
  const unitOptions = useEnumList(RebateTypeAndUnitType, 'common:rebateTypeAndUnitType');
  const rebateUnitWatch = watch('rebateUnit');
  const unitWatch = watch('unit');
  const breakpoint = useBreakpoints();
  const editing = !!watch('id');

  const isCustom = unitWatch === RebateTypeAndUnitType.Custom;
  const isRebateCustom = rebateUnitWatch === RebateTypeAndUnitType.Custom;

  useEffect(() => {
    if (!editing) {
      // needed here to trigger a dirty state when setting the name programmatically
      setIgnoreDirtySubmit(true);
    }
  }, [editing, setIgnoreDirtySubmit]);

  const getUnitFormatter = (unit: RebateTypeAndUnitType) =>
    numberFormatter(
      currentLanguage,
      unit !== RebateTypeAndUnitType.Custom ? t(`common:rebateTypeAndUnitType.${unit}`) : '',
      unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
      unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
    );

  return (
    <Box>
      <Grid container spacing={2.5}>
        {!tierOrGrowth.id && (
          <Grid item xs={12} sx={style.isTierOrGrowthContainer}>
            <Switch
              label={t('vendor:rebateCategory.tierAndGrowth.growthCardType')}
              leftLabel={t('vendor:rebateCategory.tierAndGrowth.tierCardType')}
              name="tierCardType"
              hideLabel={readOnly}
              readOnly={readOnly}
              onChange={(_, checked) => {
                setIsEditingOrAddingGrowth(checked);
                const initialValues =
                  (formState.defaultValues as VendorTierGrowthEntity) ?? new VendorTierGrowthEntity();
                setTierOrGrowth(initialValues);
                reset(initialValues);
              }}
              checked={isEditingOrAddingGrowth}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTranslatedInput
            maxLength={MAX_SHORT_TEXT_LENGTH}
            label={t(`vendor:rebateCategory.tierAndGrowth.form.${isEditingOrAddingGrowth ? 'growthName' : 'tierName'}`)}
            name="name"
            variant="standard"
            control={control}
            hideLabel={readOnly}
            readOnly={readOnly}
            required
          />
        </Grid>

        <Grid item container xs={12} spacing={2.5}>
          <Grid item xs={4} sm={3} display="flex" alignItems="flex-start">
            <ControlledSelect
              label={t('vendor:rebateCategory.tierAndGrowth.form.rebateUnit')}
              name="rebateUnit"
              options={unitOptions}
              control={control}
            />
          </Grid>
          {isRebateCustom && (
            <Grid item xs={8} sm={6} display="flex" alignItems="flex-start">
              <ControlledTranslatedInput
                maxLength={MAX_SHORT_TEXT_LENGTH}
                label={t(`vendor:rebateCategory.tierAndGrowth.form.customRebateUnit`)}
                name="rebateCustomUnit"
                control={control}
                hideLabel={readOnly}
                readOnly={readOnly}
                required
              />
            </Grid>
          )}
        </Grid>

        <Grid item container xs={12} spacing={2.5}>
          <Grid item xs={4} sm={3} display="flex" alignItems="flex-start">
            <ControlledSelect
              label={
                isEditingOrAddingGrowth
                  ? t('vendor:rebateCategory.tierAndGrowth.form.growthUnit')
                  : t('vendor:rebateCategory.tierAndGrowth.form.fromToUnit')
              }
              name="unit"
              options={unitOptions}
              control={control}
            />
          </Grid>
          {isCustom && (
            <Grid item xs={8} sm={6} display="flex" alignItems="flex-start">
              <ControlledTranslatedInput
                maxLength={MAX_SHORT_TEXT_LENGTH}
                label={t(
                  `vendor:rebateCategory.tierAndGrowth.form.${
                    isEditingOrAddingGrowth ? 'customGrowthUnit' : 'customFromToUnit'
                  }`,
                )}
                name="customUnit"
                control={control}
                hideLabel={readOnly}
                readOnly={readOnly}
                required
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table sx={style.table}>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">{t('vendor:rebateCategory.tierAndGrowth.rebateValue')} *</TableCell>
                  {isEditingOrAddingGrowth ? (
                    <>
                      <TableCell width="25%">{t('vendor:rebateCategory.tierAndGrowth.baselineYear')} *</TableCell>
                      <TableCell width="30%">{t('vendor:rebateCategory.tierAndGrowth.baselineVolume')} *</TableCell>
                      <TableCell width="30%">{t('vendor:rebateCategory.tierAndGrowth.growth')} *</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell width="30%">{t('vendor:rebateCategory.tierAndGrowth.from')}</TableCell>
                      <TableCell width="30%">{t('vendor:rebateCategory.tierAndGrowth.to')}</TableCell>
                    </>
                  )}
                  {!readOnly && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.fields.map((item, index) => (
                  <TableRow key={item.id} sx={style.tableRow}>
                    <TableCell sx={style.tableCell} width={breakpoint === 'lg' ? '24.25%' : '20%'}>
                      <ControlledInput
                        required
                        hideLabel
                        label={t('vendor:rebateCategory.tierAndGrowth.rebateValue')}
                        name={`values.${index}.rebateValue`}
                        control={control}
                        formatter={getUnitFormatter(rebateUnitWatch)}
                      />
                    </TableCell>
                    {isEditingOrAddingGrowth ? (
                      <>
                        <TableCell sx={style.tableCell} width="25%">
                          <ControlledInput
                            hideLabel
                            required
                            label={t('vendor:rebateCategory.tierAndGrowth.baselineYear')}
                            name={`values.${index}.baselineYear`}
                            control={control}
                          />
                        </TableCell>
                        <TableCell sx={style.tableCell} width="30%">
                          <ControlledInput
                            hideLabel
                            required
                            label={t('vendor:rebateCategory.tierAndGrowth.baselineVolume')}
                            name={`values.${index}.baselineVolume`}
                            control={control}
                          />
                        </TableCell>
                        <TableCell sx={style.tableCell} width="30%">
                          <ControlledInput
                            hideLabel
                            required
                            label={t('vendor:rebateCategory.tierAndGrowth.growth')}
                            name={`values.${index}.growth`}
                            control={control}
                            formatter={getUnitFormatter(unitWatch)}
                          />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell sx={style.tableCell} width="30%">
                          <ControlledInput
                            hideLabel
                            label={t('vendor:rebateCategory.tierAndGrowth.from')}
                            name={`values.${index}.from`}
                            control={control}
                            formatter={getUnitFormatter(unitWatch)}
                          />
                        </TableCell>
                        <TableCell sx={style.tableCell} width="30%">
                          <ControlledInput
                            hideLabel
                            label={t('vendor:rebateCategory.tierAndGrowth.to')}
                            name={`values.${index}.to`}
                            control={control}
                            formatter={getUnitFormatter(unitWatch)}
                          />
                        </TableCell>
                      </>
                    )}
                    {!readOnly && values.fields.length > 1 && (
                      <TableCell sx={{ ...style.tableCell, ...style.deleteIcon }} align="right">
                        <IconButton
                          onClick={() => {
                            values.remove(index);
                          }}
                        >
                          <DeleteRounded />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!readOnly && (
            <Box sx={style.buttonBox}>
              <Button
                variant="contained"
                size="small"
                startIcon={<AddRounded />}
                onClick={() => values.append(new VendorTierGrowthValueEntity())}
              >
                {t('common:add')}
              </Button>
            </Box>
          )}
        </Grid>

        {(getValues().isRetroactiveToDollar && readOnly) ||
          (!isEditingOrAddingGrowth && (
            <Grid item xs={12} sx={style.isRetroactiveToDollarContainer}>
              <ControlledCheckbox
                label={t('vendor:rebateCategory.tierAndGrowth.isRetroactiveToDollar')}
                name="isRetroactiveToDollar"
                control={control}
              />
            </Grid>
          ))}
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('vendor:rebateCategory.tierAndGrowth.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
            readOnly={readOnly}
          />
        </Grid>
        {isInternalUser && (
          <Grid item xs={12}>
            <ControlledInput
              label={t('vendor:rebateCategory.tierAndGrowth.notes')}
              name="notes"
              control={control}
              multiline
              rows={3}
            />
            <Box sx={style.notes}>{!readOnly && <Trans i18nKey="common:formHelperText.notes" />}</Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
