import { useContext } from 'react';
import { cms as cmsApi } from '../../../api';
import { CmsStaticPageContext } from '../../../contexts/Cms';
import { StaticPageEntity } from '../../../models';
import { StaticPageSchema } from '../../../schemas';
import { PermissionKey } from '../../../types';
import { CmsContent } from '../CmsContent';
import { useInternationalization } from '../../../hooks';

export const CmsStaticPageContent = () => {
  const { currentLanguage } = useInternationalization();
  const { staticPage, isLoading, fetchData } = useContext(CmsStaticPageContext);

  return (
    <CmsContent
      contentEntity={staticPage ?? new StaticPageEntity()}
      isLoading={isLoading}
      viewPermissions={PermissionKey.CmsStaticView}
      editPermissions={PermissionKey.CmsStaticEdit}
      approvePermissions={PermissionKey.CmsStaticApprove}
      updateApi={cmsApi.updateStaticPage}
      uploadBlockFileApi={cmsApi.uploadStaticPageBlockFile}
      downloadApi={(contentEntityId) => cmsApi.getStaticPageIconImage(contentEntityId, currentLanguage)}
      deleteFilesApi={cmsApi.deleteStaticPageFiles}
      deleteContentBlockFileApi={cmsApi.deleteStaticPageBlockFile}
      downloadBlockFileApi={cmsApi.getStaticPageBlockFile}
      schema={StaticPageSchema()}
      onConfirm={fetchData}
    />
  );
};
