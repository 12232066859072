import { useTranslation } from 'react-i18next';
import { PermissionKey } from '../../../types';
import { LayoutPage } from '../../Layout';
import { InvoicingInformationVendor } from './InvoicingInformationVendor';
import { PaymentScheduleVendor } from './PaymentScheduleVendor';
import { TaxInformationVendor } from './TaxInformationVendor';

export const VendorFinancialsVendor = () => {
  const { t } = useTranslation();

  return (
    <LayoutPage permissions={{ keys: PermissionKey.VendorViewFinancial }} title={t('vendor:sections.financials')}>
      <TaxInformationVendor />
      <InvoicingInformationVendor />
      <PaymentScheduleVendor />
    </LayoutPage>
  );
};
