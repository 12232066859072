import { Divider } from '@mui/material';
import { useContext } from 'react';
import { NavigationMenuContext } from '../../contexts';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';

const style: Styles = {
  divider: {
    borderColor: palette.grey[300],
    mx: 2,
    my: 2,
  },
  dividerSideCollapsed: {
    pr: 0,
    width: '24px',
  },
};

export const NavigationMenuDivider = () => {
  const { variant: menuVariant, expanded } = useContext(NavigationMenuContext);

  return (
    <Divider
      sx={{
        ...style.divider,
        ...(menuVariant === 'side' && !expanded ? style.dividerSideCollapsed : {}),
      }}
    />
  );
};
