import { Box, Stack, Typography } from '@mui/material';
import { Timbermart } from '../../../assets/logos';
import { useAuth, useInternationalization } from '../../../hooks';
import { Styles } from '../../../types';

const style: Styles = {
  logo: {
    mt: 12,
    svg: {
      width: { xs: '80vw', md: '554px' },
      height: 'auto',
    },
  },
  greeting: {
    fontSize: '24px',
    fontWeight: 700,
    mt: 4,
  },
};

export const WelcomeMessage = () => {
  const { t } = useInternationalization();
  const { user } = useAuth();

  return (
    <Stack alignItems="center">
      <Box sx={style.logo}>
        <Timbermart />
      </Box>
      <Typography sx={style.greeting}>
        {t('dashboard:welcomeMessage.greeting', { name: user?.profile?.fullName })}
      </Typography>
    </Stack>
  );
};
