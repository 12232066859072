import { array, number, object, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH, TBM_MEMBER_SUPPORT_ROLE_ID } from '../../constants';
import { UserEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema, LanguageCode, UserType } from '../../types';

export const UserSchema = (type: UserType): EntityFormSchema<UserEntity> => ({
  schema: object({
    firstName: string().required().max(MAX_SHORT_TEXT_LENGTH),
    lastName: string().required().max(MAX_SHORT_TEXT_LENGTH),
    language: string().notRequired().oneOf(Object.values(LanguageCode)),
    roleId: number().required(),
    email: string().required().email().max(MAX_SHORT_TEXT_LENGTH),
    notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    ...(type === UserType.Member && {
      memberIds: array().when('roleId', {
        is: (roleId: number) => roleId !== TBM_MEMBER_SUPPORT_ROLE_ID,
        then: (schema) => schema.required().min(1),
      }),
    }),
    ...(type === UserType.Vendor && {
      vendorId: number().typeError('common:error.fieldRequired').required(),
    }),
    jobTitle: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    department: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    phoneOffice: object({
      number: string()
        .nullable()
        .notRequired()
        .matches(/^\d{10}$/, { message: 'common:error.fieldInvalid', excludeEmptyString: true }),
    }),
    phoneMobile: object({
      number: string()
        .nullable()
        .notRequired()
        .matches(/^\d{10}$/, { message: 'common:error.fieldInvalid', excludeEmptyString: true }),
    }),
  }),
  errorCodeMap: {
    [ApiErrorCodes.USER_EMAIL_NOT_UNIQUE]: { key: 'email', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.USER_CREATION_ERROR__USERNAME_EXISTS]: { key: 'email', message: 'common:error.fieldUnique' },
  },
});
