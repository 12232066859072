import { EditableEntity } from '../EditableEntity';

export class CategorizationEntity extends EditableEntity {
  name_Fr: string;
  name_En: string;
  abbreviation_Fr: string;
  abbreviation_En: string;
  description_Fr: string;
  description_En: string;
  searchKeywords_Fr?: string;
  searchKeywords_En?: string;
  note: string;
  modifiedDate: Date;
  modifiedBy: string;
  displayOrder: number;
  isArchived: boolean;

  constructor() {
    super();
    this.name_Fr = '';
    this.description_Fr = '';
    this.searchKeywords_Fr = '';
    this.abbreviation_Fr = '';
    this.name_En = '';
    this.description_En = '';
    this.searchKeywords_En = '';
    this.abbreviation_En = '';
    this.note = '';
    this.modifiedBy = '';
    this.modifiedDate = new Date();
    this.displayOrder = 0;
    this.isArchived = false;
  }
}
