import { Box, Grid, Typography } from '@mui/material';
import { AddressForm, EmailAddressListForm, PhoneNumberListForm } from '..';
import { MAX_LONG_TEXT_LENGTH } from '../../../constants';
import { useAuth, useEntityFormContext, useInternationalization } from '../../../hooks';
import { OfficeEntity } from '../../../models';
import { Styles } from '../../../types';
import { ControlledInput, ControlledTranslatedInput } from '../../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
};

export const OfficeForm = () => {
  const { isInternalUser, isVendorUser, isMemberUser } = useAuth();
  const { t } = useInternationalization();
  const { control, variant } = useEntityFormContext<OfficeEntity>();

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        <AddressForm excludeFields={['country']} requiredFields={['city', 'postalCode', 'province', 'street']} />
        <Grid item xs={12}>
          <Typography variant="h3">{t('contact:office.form.generalContacts')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <EmailAddressListForm />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberListForm />
        </Grid>
        {isInternalUser && (
          <>
            <Grid item xs={12}>
              <Typography variant="drawerContentSubtitle">{t('contact:office.form.notes')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ControlledInput
                label={t('contact:office.form.internalNotes')}
                name="notes"
                control={control}
                multiline
                rows={3}
                maxLength={MAX_LONG_TEXT_LENGTH}
              />
            </Grid>
          </>
        )}
        {(isInternalUser || isMemberUser) && (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              label={
                isInternalUser ? t('contact:office.form.memberNotes') : t('contact:office.form.notes')
              }
              name="memberNotes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        )}
        {(isInternalUser || isVendorUser) && (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              label={
                isInternalUser ? t('contact:office.form.vendorNotes') : t('contact:office.form.notes')
              }
              name="vendorNotes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
