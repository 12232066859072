import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { Loading } from '../..';
import { vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryProgramDetailContext } from '../../../contexts/Vendor';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryProgramRebateEntity } from '../../../models';
import { VendorRebateCategoryProgramRebateSchema } from '../../../schemas/Vendor';
import { palette } from '../../../styles/palette';
import { RebateValueType, Styles } from '../../../types';
import { EditCard } from '../../Card';
import { Container } from '../../Container';
import {
  VendorRebateCategoryRebateCalculationMethodForm,
  VendorRebateCategoryRebatePaymentTermsForm,
  VendorRebateCategoryRebateValueList,
} from '../RebateCategoryRebates';

const style: Styles = {
  container: {
    py: 4,
  },
  header: {
    pb: 3,
  },
  card: {
    borderTop: 'solid 8px ' + palette.warning.main,
  },
  cardHeader: {
    borderBottom: 'solid 1px ' + palette.grey[300],
    pt: 2,
    pb: 2,
    mb: 2,
  },
  buttons: {
    mt: 3,
  },
};

export const VendorRebateCategoryProgramRebates = () => {
  const { t } = useInternationalization();
  const { vendorRebateCategoryId, vendorId, vendorRebateCategoryProgramId, readOnly } = useContext(
    VendorRebateCategoryProgramDetailContext,
  );
  const {
    data: vendorRebateCategoryRebate,
    isLoading,
    refresh,
  } = useApi(
    vendorApi.getRebateCategoryProgramRebate,
    null,
    vendorId,
    vendorRebateCategoryId,
    vendorRebateCategoryProgramId,
  );
  const { call: update } = useApi(vendorApi.updateRebateCategoryProgramRebate, { successKey: 'common:success.save' });

  const updateRebate = async (entity: VendorRebateCategoryProgramRebateEntity) => {
    return await update(vendorId, vendorRebateCategoryId, vendorRebateCategoryProgramId, entity);
  };

  const onConfirm = () => {
    refresh();
  };

  return (
    <Container isHighlighted sx={style.container}>
      {isLoading && !vendorRebateCategoryRebate ? (
        <Loading />
      ) : (
        !!vendorRebateCategoryRebate && (
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={style.header}>
              <Typography variant="h2">{t('vendor:rebateCategory.rebates.title')}</Typography>
            </Stack>
            <EditCard
              title={t('vendor:rebateCategory.rebates.sections.calculationMethod')}
              entity={vendorRebateCategoryRebate}
              schema={VendorRebateCategoryProgramRebateSchema('calculationMethod')}
              onSave={updateRebate}
              onConfirm={onConfirm}
              readOnly
            >
              <VendorRebateCategoryRebateCalculationMethodForm />
            </EditCard>
            <EditCard
              title={t('vendor:rebateCategory.rebates.sections.paymentTerms')}
              entity={vendorRebateCategoryRebate}
              schema={VendorRebateCategoryProgramRebateSchema('paymentTerms')}
              onSave={updateRebate}
              onConfirm={onConfirm}
              readOnly
            >
              <VendorRebateCategoryRebatePaymentTermsForm />
            </EditCard>
            <VendorRebateCategoryRebateValueList
              title={t('vendor:rebateCategory.rebates.sections.foidRebates')}
              entity={vendorRebateCategoryRebate}
              onSave={updateRebate}
              onConfirm={onConfirm}
              rebateValueType={RebateValueType.FOID}
              listProperty="foidRebates"
              borderColor={palette.secondary.blue}
              readOnly
            />
            <VendorRebateCategoryRebateValueList
              title={t('vendor:rebateCategory.rebates.sections.receivableRebates')}
              entity={vendorRebateCategoryRebate}
              onSave={updateRebate}
              onConfirm={onConfirm}
              rebateValueType={RebateValueType.Receivable}
              listProperty="receivableRebates"
              summaryProperty="receivableRebatesSummary"
              borderColor={palette.secondary.yellow}
              readOnly
            />
            <VendorRebateCategoryRebateValueList
              title={t('vendor:rebateCategory.rebates.sections.payableRebates')}
              entity={vendorRebateCategoryRebate}
              onSave={updateRebate}
              onConfirm={onConfirm}
              rebateValueType={RebateValueType.Payable}
              listProperty="payableRebates"
              summaryProperty="payableRebatesSummary"
              borderColor={palette.secondary.green}
              readOnly={readOnly}
            />
            <VendorRebateCategoryRebateValueList
              title={t('vendor:rebateCategory.rebates.sections.holdbackRebates')}
              entity={vendorRebateCategoryRebate}
              onSave={updateRebate}
              onConfirm={onConfirm}
              rebateValueType={RebateValueType.Holdback}
              listProperty="holdbackRebates"
              summaryProperty="holdbackRebatesSummary"
              borderColor={palette.secondary.orange}
              readOnly={readOnly}
            />
          </>
        )
      )}
    </Container>
  );
};
