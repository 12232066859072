import axios from 'axios';
import { ChangeLogEntity, StaticPageEntity } from '../../models';
import {
  StaticPageFilter,
  ChangeLogFilter,
  ContentTriggers,
  PaginationEntity,
  TranslationLanguage,
  LanguageCode,
} from '../../types';

export const StaticPage = {
  getAllStaticPages: async (staticPageFilter: StaticPageFilter): Promise<PaginationEntity<StaticPageEntity>> => {
    const { data } = await axios.post('api/staticpage/search', staticPageFilter);
    return { ...data, data: data.data.map((d: StaticPageEntity) => new StaticPageEntity(d)) };
  },
  getStaticPage: async (staticPageId: number): Promise<StaticPageEntity> => {
    const { data } = await axios.get(`api/staticpage/${staticPageId}`);
    return new StaticPageEntity(data);
  },
  getStaticPageSuggestions: async (
    staticPageName: string,
    isArchived?: boolean,
  ): Promise<PaginationEntity<StaticPageEntity>> => {
    const { data } = await axios.post('api/staticpage/search', {
      staticPageName,
      pageSize: 10,
      pageNumber: 1,
      isArchived,
    });
    return { ...data, data: data.data.map((d: StaticPageEntity) => new StaticPageEntity(d)) };
  },
  createStaticPage: async (entity: StaticPageEntity): Promise<StaticPageEntity> => {
    const { data } = await axios.post('api/staticPage', entity);
    return new StaticPageEntity(data);
  },
  updateStaticPage: async (entity: StaticPageEntity): Promise<StaticPageEntity> => {
    const { data } = await axios.put(`api/staticpage/${entity.id}`, entity);
    return new StaticPageEntity(data);
  },
  uploadStaticPageFile: async (staticPageId: number, language: LanguageCode, file: File): Promise<StaticPageEntity> => {
    const formData = new FormData();
    formData.append('iconImage', file);
    const { data } = await axios.put(`api/staticpage/${staticPageId}/iconimage/${language}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new StaticPageEntity(data);
  },
  deleteStaticPageFiles: async (staticPageId: number, ids: number[]): Promise<StaticPageEntity> => {
    const { data } = await axios.delete(
      `api/staticpage/${staticPageId}/files?${ids.map((id) => `fileIds=${id}`).join('&')}`,
    );
    return new StaticPageEntity(data);
  },
  getStaticPageIconImage: async (staticPageId: number, language: LanguageCode): Promise<Blob> => {
    const { data } = await axios.get(`api/staticpage/${staticPageId}/iconimage/${language}`, {
      responseType: 'blob',
    });
    return data;
  },
  getStaticPageFile: async (staticPageId: number, fileId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/staticpage/${staticPageId}/files/${fileId}`, {
      responseType: 'blob',
    });
    return data;
  },
  uploadStaticPageBlockFile: async (
    staticPageId: number,
    blockId: number,
    language: TranslationLanguage,
    file: File,
  ): Promise<StaticPageEntity> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.put(`api/staticpage/${staticPageId}/block/${blockId}/file/${language}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new StaticPageEntity(data);
  },
  deleteStaticPageBlockFile: async (
    staticPageId: number,
    blockId: number,
    language: LanguageCode,
  ): Promise<StaticPageEntity> => {
    const { data } = await axios.delete(`api/staticpage/${staticPageId}/block/${blockId}/file/${language}`);
    return new StaticPageEntity(data);
  },
  getStaticPageBlockFile: async (staticPageId: number, blockId: number, language: LanguageCode): Promise<Blob> => {
    const { data } = await axios.get(`api/staticpage/${staticPageId}/block/${blockId}/file/${language}`, {
      responseType: 'blob',
    });
    return data;
  },
  updateStaticPageWorkflow: async (staticPageId: number, trigger: ContentTriggers): Promise<StaticPageEntity> => {
    const { data } = await axios.post(`api/staticpage/${staticPageId}/${trigger}`);
    return new StaticPageEntity(data);
  },
  getStaticPageChangeLogs: async (
    staticPageId: number,
    filter: ChangeLogFilter,
  ): Promise<PaginationEntity<ChangeLogEntity>> => {
    const { data } = await axios.post(`api/staticpage/${staticPageId}/changeLog/search`, {
      ...filter,
    });
    return { ...data, data: data.data.map((v: ChangeLogEntity) => new ChangeLogEntity(v)) };
  },
  addStaticPageComment: async (staticPageId: number, description: string): Promise<ChangeLogEntity> => {
    const { data } = await axios.post(`api/staticpage/${staticPageId}/comment`, {
      description,
    });
    return new ChangeLogEntity(data);
  },
};
