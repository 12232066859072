import { EditableEntity } from '../EditableEntity';

export class VendorEntityChangeRequestEntity<T> extends EditableEntity {
  entity: T | null;
  changeRequest: T;

  constructor(entity?: VendorEntityChangeRequestEntity<T>) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
    }
  }
}
