import { Stack } from '@mui/material';
import { useContext } from 'react';
import { VendorBuyingAgreementRebateCategoryDetailContext } from '../../../../contexts/Vendor';
import { useAuth, useInternationalization } from '../../../../hooks';
import { VendorBuyingAgreementRebateCategorySchema } from '../../../../schemas';
import { Styles } from '../../../../types';
import { EditCard } from '../../../Card';
import { Container } from '../../../Container';
import {
  TargetedMembershipCategorizationForm,
  TargetedMembershipMemberForm,
  TargetedMembershipMemberGroupForm,
} from '../../../TargetedMembership';
import { VendorRebateCategoryPermissionsNoteForm } from '../../RebateCategories';

const style: Styles = {
  highlightContainer: {
    mb: 5,
  },
};

export const VendorBuyingAgreementRebateCategoryPermissions = () => {
  const { t } = useInternationalization();
  const { isInternalUser } = useAuth();
  const { rebateCategory, save, fetchData, readOnly } = useContext(VendorBuyingAgreementRebateCategoryDetailContext);

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        <EditCard
          title={t('vendor:targetedMembership.title')}
          entity={rebateCategory}
          readOnly={readOnly || (!rebateCategory.targetedMembership?.isBasedOnMemberCriteria && !isInternalUser)}
          schema={VendorBuyingAgreementRebateCategorySchema()}
          onSave={save}
          onConfirm={fetchData}
        >
          <TargetedMembershipCategorizationForm />
        </EditCard>
        {isInternalUser && (
          <>
            <EditCard
              title={t('vendor:targetedMembership.form.memberGroupTitle')}
              entity={rebateCategory}
              readOnly={readOnly}
              schema={VendorBuyingAgreementRebateCategorySchema()}
              onSave={save}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberGroupForm hideTitle />
            </EditCard>
            <EditCard
              title={t('vendor:targetedMembership.form.memberTitle')}
              entity={rebateCategory}
              readOnly={readOnly}
              schema={VendorBuyingAgreementRebateCategorySchema()}
              onSave={save}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberForm hideTitle />
            </EditCard>
            <EditCard
              title={t('vendor:rebateCategory:form.notes')}
              entity={rebateCategory}
              readOnly={readOnly}
              schema={VendorBuyingAgreementRebateCategorySchema()}
              onSave={save}
              onConfirm={fetchData}
            >
              <VendorRebateCategoryPermissionsNoteForm />
            </EditCard>
          </>
        )}
      </Stack>
    </Container>
  );
};
