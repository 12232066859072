import { CheckRounded, RemoveRounded } from '@mui/icons-material';
import { Control, Controller } from 'react-hook-form';
import { StaticField } from '../Base/StaticField';
import { Switch } from '../Base/Switch';

interface ControlledSwitchProps extends React.ComponentProps<typeof Switch> {
  label: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
}

export const ControlledSwitch = ({ name, control, label, ...props }: ControlledSwitchProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { name, onBlur, onChange, value } }) =>
      control._options.context?.readOnly ? (
        <StaticField
          label={label}
          value={typeof value === 'boolean' ? value ? <CheckRounded /> : <RemoveRounded /> : value}
        />
      ) : (
        <Switch
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          {...props}
          checked={value}
          label={label}
          disabled={control._options.context?.readOnly}
        />
      )
    }
  />
);
