import React from 'react';
import { VendorBuyingAgreementRebateCategoryEntity } from '../../models';

interface VendorBuyingAgreementRebateCategoryDetailContextType {
  fetchData: () => Promise<void>;
  vendorId: number;
  readOnly: boolean;
  vendorBuyingAgreementId: number;
  rebateCategory: VendorBuyingAgreementRebateCategoryEntity;
  save: (
    entity: VendorBuyingAgreementRebateCategoryEntity,
  ) => Promise<VendorBuyingAgreementRebateCategoryEntity | null>;
}

export const VendorBuyingAgreementRebateCategoryDetailContext =
  React.createContext<VendorBuyingAgreementRebateCategoryDetailContextType>(
    {} as VendorBuyingAgreementRebateCategoryDetailContextType,
  );
