import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { HelpTrainingType, Styles } from '../../../types';
import { ControlledCheckbox, ControlledSelect, ControlledTranslatedInput } from '../../Form';
import { PeriodForm } from '../../PeriodForm';
import { HelpTrainingEntity } from '../../../models';
import { useEnumList } from '../../../hooks';
import { MAX_LONG_TEXT_LENGTH } from '../../../constants';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
};

export const CmsHelpTrainingForm = () => {
  const { t } = useInternationalization();
  const { control, variant, readOnly } = useEntityFormContext<HelpTrainingEntity>();
  const typeOptions = useEnumList(HelpTrainingType, 'cms:helpTrainings.form.type');

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        {!readOnly && (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              variant="standard"
              label={t('cms:helpTrainings.form.name')}
              name="name"
              control={control}
              required
            />
          </Grid>
        )}
        <Grid item xs={12} md={variant === 'drawer' ? 12 : 6}>
          <Stack spacing={1}>
            <ControlledTranslatedInput
              maxLength={MAX_LONG_TEXT_LENGTH}
              label={t('cms:helpTrainings.form.searchKeywords')}
              name="searchKeywords"
              control={control}
            />
            {!readOnly && <Typography variant="h4">{t('common:formHelperText.keywords')}</Typography>}
          </Stack>
        </Grid>
        <Grid item xs={6} md={variant === 'drawer' ? 12 : 3}>
          <ControlledSelect
            label={t('cms:helpTrainings.form.type.title')}
            name="type"
            control={control}
            options={typeOptions}
            required
          />
        </Grid>
        <Grid item xs={6} md={variant === 'drawer' ? 12 : 3}>
          <PeriodForm optionalExpiryDate={true} showExpiryDate={false} />
        </Grid>

        <Grid item xs={12} md={variant === 'drawer' ? 4 : 2}>
          <ControlledCheckbox
            label={t('cms:helpTrainings.form.isVisibleToAdmins')}
            name="isVisibleToAdmins"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={variant === 'drawer' ? 4 : 2}>
          <ControlledCheckbox
            label={t('cms:helpTrainings.form.isVisibleToVendors')}
            name="isVisibleToVendors"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={variant === 'drawer' ? 4 : 2}>
          <ControlledCheckbox
            label={t('cms:helpTrainings.form.isVisibleToMembers')}
            name="isVisibleToMembers"
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
