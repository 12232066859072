import axios from 'axios';
import { VendorBuyingAgreementRebateCategoryEntity, VendorRebateCategoryRebateEntity } from '../../../models';
import { PaginationEntity, RebateCategoryDuplicate, VendorRebateCategoryFilter } from '../../../types';

export const buyingAgreementRebateCategory = {
  getAllBuyingAgreementRebateCategories: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryFilter: VendorRebateCategoryFilter,
  ): Promise<PaginationEntity<VendorBuyingAgreementRebateCategoryEntity>> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/search`,
      rebateCategoryFilter,
    );
    return {
      ...data,
      data: data.data.map(
        (v: VendorBuyingAgreementRebateCategoryEntity) => new VendorBuyingAgreementRebateCategoryEntity(v),
      ),
    };
  },
  createBuyingAgreementRebateCategory: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategory: VendorBuyingAgreementRebateCategoryEntity,
  ): Promise<VendorBuyingAgreementRebateCategoryEntity> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories`,
      rebateCategory,
    );
    return new VendorBuyingAgreementRebateCategoryEntity(data);
  },
  updateBuyingAgreementRebateCategory: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategory: VendorBuyingAgreementRebateCategoryEntity,
  ): Promise<VendorBuyingAgreementRebateCategoryEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategory.id}`,
      rebateCategory,
    );
    return new VendorBuyingAgreementRebateCategoryEntity(data);
  },
  duplicateBuyingAgreementRebateCategory: async (
    duplicate: RebateCategoryDuplicate,
  ): Promise<VendorBuyingAgreementRebateCategoryEntity> => {
    const { data } = await axios.put(
      `api/vendor/${duplicate.vendorId}/buyingagreements/${duplicate.buyingAgreementId}/rebatecategories/${duplicate.rebateCategoryId}/duplicate`,
      duplicate,
    );
    return new VendorBuyingAgreementRebateCategoryEntity(data);
  },
  deleteBuyingAgreementRebateCategory: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
  ): Promise<void> => {
    await axios.delete(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}`,
    );
  },
  getBuyingAgreementRebateCategory: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
  ): Promise<VendorBuyingAgreementRebateCategoryEntity> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}`,
    );
    return new VendorBuyingAgreementRebateCategoryEntity(data);
  },
  getBuyingAgreementRebateCategoryRebate: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
  ): Promise<VendorRebateCategoryRebateEntity> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/rebates`,
    );
    return new VendorRebateCategoryRebateEntity(data);
  },
  updateBuyingAgreementRebateCategoryRebate: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    entity: VendorRebateCategoryRebateEntity,
  ): Promise<VendorRebateCategoryRebateEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/rebates`,
      entity,
    );
    return new VendorRebateCategoryRebateEntity(data);
  },
};
