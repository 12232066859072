import { CategorizationLayout } from '../../../components/Categorization';

export function VendorDesignations() {
  return (
    <CategorizationLayout
      type="vendorDesignation"
      translationContext="male"
      omitFields={['abbreviation', 'searchKeywords']}
    />
  );
}
