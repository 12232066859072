import { PriorityHighRounded } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { MAX_FILE_SIZE_MB } from '../../../constants';
import { useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { AdEntity, FileEntity } from '../../../models';
import { palette } from '../../../styles/palette';
import { AdType, Styles } from '../../../types';
import { ControlledCheckbox, ControlledInput, ControlledSelect, ControlledTranslatedInput } from '../../Form';
import { ControlledTranslatedAttachment } from '../../Form/Controlled/ControlledTranslatedAttachment';
import { PeriodForm } from '../../PeriodForm';
import { TargetedMembershipForm } from '../../TargetedMembership';
import { CmsAdView } from './CmsAdView';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
  notesHelperText: {
    mt: 1,
  },
  checkbox: {
    mt: 3,
  },
  sectionHeader: {
    borderTop: 'solid 1px ' + palette.grey[300],
    pt: 3,
  },
};

interface CmsAdFormProps {
  setAdType: (adType: AdType) => void;
  onFileClick?: (file: FileEntity) => void;
}

export const CmsAdForm = ({ setAdType, onFileClick }: CmsAdFormProps) => {
  const internationalization = useInternationalization();
  const { t } = internationalization;
  const { control, variant, watch, readOnly, getValues } = useEntityFormContext<AdEntity>();
  const typeOptions = useEnumList(AdType, 'cms:ads.form.type');
  const typeWatch = watch('type');
  const isImageType = typeWatch === AdType.Image;

  useEffect(() => setAdType(typeWatch), [setAdType, typeWatch]);

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        {variant === 'drawer' && (
          <Grid item xs={12}>
            <ControlledInput label={t('cms:ads.form.name')} name="name" control={control} required />
          </Grid>
        )}
        <Grid item xs={variant === 'drawer' ? 12 : 3}>
          <ControlledSelect
            label={t('cms:ads.form.type.title')}
            name="type"
            control={control}
            options={typeOptions}
            required
          />
        </Grid>
        <PeriodForm xs={variant === 'drawer' ? 6 : 2} />
        <Grid
          item
          xs={variant === 'drawer' ? 12 : 5}
          sx={readOnly || variant === 'drawer' ? undefined : style.checkbox}
        >
          <ControlledCheckbox
            label={t('cms:ads.form.hasPriority')}
            name="hasPriority"
            control={control}
            icon={PriorityHighRounded}
          />
        </Grid>
        {readOnly && variant !== 'drawer' && (
          <Grid item xs={12} lg={9}>
            <Typography variant="h3" sx={style.header} mb={2}>
              {t('cms:ads.form.preview')}
            </Typography>
            <CmsAdView
              ads={[getValues()]}
              emptyPlaceholder={<Typography variant="valuePlaceholder">{t('common:notSpecified')}</Typography>}
            />
          </Grid>
        )}
        {!readOnly && isImageType && (
          <>
            <Grid item xs={12}>
              <ControlledTranslatedAttachment
                label={t('cms:ads.form.file')}
                name="file"
                control={control}
                maxSizeMB={MAX_FILE_SIZE_MB}
                onFileClick={onFileClick}
                required
                onlyImg
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTranslatedInput
                label={t('cms:ads.form.destinationUrl')}
                name="destinationUrl"
                control={control}
              />
            </Grid>
          </>
        )}
        {!readOnly && !isImageType && (
          <Grid item xs={12}>
            <ControlledTranslatedInput label={t('cms:ads.form.videoUrl')} name="videoUrl" control={control} required />
          </Grid>
        )}
      </Grid>
      <TargetedMembershipForm />
    </Box>
  );
};
