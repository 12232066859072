import { date, object, ref, string } from 'yup';
import { PublicationEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { MAX_SHORT_TEXT_LENGTH, MAX_URL_LENGTH } from '../../constants';
import { isDateValid } from '../../utils/helper';

export const PublicationSchema = (): EntityFormSchema<PublicationEntity> => ({
  schema: object().shape(
    {
      name_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
      name_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      publicationCategoryId: string().required(),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .required()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      urgency: string().required(),
      associatedLink: string().notRequired().max(MAX_URL_LENGTH),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
