import { AddRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet } from 'react-router-dom';
import { UserForm, UserListSearch, UserListView } from '.';
import { EditDrawer, ExportButton } from '..';
import { user as userApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER, MAX_PAGINATION_FILTER } from '../../constants';
import { UserContext } from '../../contexts';
import { useApi, useAuth } from '../../hooks';
import { UserEntity } from '../../models';
import { routes } from '../../routes';
import { UserSchema } from '../../schemas';
import { ExportType, PermissionKey, UserFilter, UserType } from '../../types';
import { downloadBlob } from '../../utils/helper';
import { LayoutPage } from '../Layout';
import { RequirePermission } from '../Permission';
import { LinkTab } from '../Tab';

interface UserListProps {
  type: UserType;
  hasLastVerified?: boolean;
}

export const UserList = ({ type, hasLastVerified }: UserListProps) => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const { isInternalUser, hasPermissions } = useAuth();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [filter, setFilter] = useState<UserFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });
  const { refresh, isLoading, data, setData } = useApi(userApi.getAll, { skipFetch: !!outlet }, type, filter);
  const { call: create } = useApi(userApi.create, { successKey: 'common:success.save' });
  const { call: downloadExport } = useApi(userApi.export, {
    successKey: 'common:success.action',
  });
  const [user, setUser] = useState(new UserEntity());

  useEffect(() => {
    setUser(new UserEntity());
  }, [isAddOpen]);

  const onExport = async (exportType: ExportType = ExportType.PDF) => {
    const fileData = await downloadExport(type, { ...filter, ...MAX_PAGINATION_FILTER }, exportType);
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
    }
  };

  const renderAddButton = () => (
    <Button startIcon={<AddRounded />} onClick={() => setIsAddOpen(true)} variant="contained">
      {t('user:add')}
    </Button>
  );

  if (outlet) {
    return outlet;
  }

  const tabs = [
    <LinkTab
      label={t('user:sections.InternalUser')}
      value={routes.Admin.User.InternalUser.path}
      permissions={{ keys: PermissionKey.AdminManageInternalUsers }}
    />,
    <LinkTab
      label={t('user:sections.MemberUser')}
      value={routes.Admin.User.MemberUser.path}
      permissions={{ keys: PermissionKey.AdminManageMemberUsers }}
    />,
    <LinkTab
      label={t('user:sections.VendorUser')}
      value={routes.Admin.User.VendorUser.path}
      permissions={{ keys: PermissionKey.AdminManageVendorUsers }}
    />,
  ];

  const renderExportButton = () => (
    <ExportButton displayMenu defaultExportType={ExportType.PDF} onExportClick={onExport} />
  );

  return (
    <RequirePermission
      keys={
        type === UserType.Internal
          ? PermissionKey.AdminManageInternalUsers
          : type === UserType.Member
          ? PermissionKey.AdminManageMemberUsers
          : PermissionKey.AdminManageVendorUsers
      }
    >
      <UserContext.Provider
        value={{ data, setData, isLoading, fetchData: refresh, filter, setFilter, type, hasLastVerified }}
      >
        <LayoutPage
          title={t('user:title')}
          rightTitle={!isInternalUser ? renderAddButton() : undefined}
          tabs={isInternalUser ? tabs : undefined}
        >
          {isInternalUser && (
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 1, md: 2 }}
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', md: 'center' }}
              pb={2}
            >
              <Typography display="inline" variant="h2">
                {t(`user:sections.${type}`)}
              </Typography>
              <Stack direction="row" spacing={1}>
                {renderAddButton()}
                {hasPermissions(PermissionKey.ReportUsers) && renderExportButton()}
              </Stack>
            </Stack>
          )}
          <UserListSearch />
          <UserListView />
          <EditDrawer
            title={
              <Stack direction="row" spacing={1} alignItems="center">
                <AddRounded />
                <Typography variant="drawerTitle">{t(`user:add`)}</Typography>
              </Stack>
            }
            open={isAddOpen}
            entity={user}
            schema={UserSchema(type)}
            redirectPath={({ id }) =>
              generatePath(
                isInternalUser ? get(routes.Admin.User, `${type}.Detail.path`, '') : routes.Member.User.Detail.path,
                { userId: id },
              )
            }
            redirectLabel={t('user:saveOpenUser')}
            onSave={(entity) => {
              return create(entity, type);
            }}
            onConfirm={() => {
              refresh();
              setIsAddOpen(false);
            }}
            onCancel={() => setIsAddOpen(false)}
          >
            <UserForm />
          </EditDrawer>
        </LayoutPage>
      </UserContext.Provider>
    </RequirePermission>
  );
};
