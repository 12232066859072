import { paddedZerosFormatter } from '../../utils/formatters';
import { CategorizationEntity } from '../Categorization';
import { AddressEntity } from '../Contact';
import { EditableEntity } from '../EditableEntity';

export class VendorEntity extends EditableEntity {
  number: number | null;
  name: string;
  responsibleBuyer: string | null;
  responsibleBuyerId: number | null;
  vendorDesignationsIds: number[];
  vendorDesignations: CategorizationEntity[];
  isVisible: boolean;
  isActive: boolean;
  notes: string;
  memberNotes: string;
  address: AddressEntity;
  website: string;
  cciPreferred: boolean;
  centralBilling: boolean;
  directBilling: boolean;
  isArchived: boolean;
  latestChange?: Date;
  isFavourite?: boolean;

  static readonly NUMBER_PADDING_DIGITS = 6;

  constructor(entity?: VendorEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.vendorDesignationsIds = entity.vendorDesignations?.map((r) => r.id);
    } else {
      this.number = null;
      this.name = '';
      this.responsibleBuyerId = null;
      this.responsibleBuyer = null;
      this.vendorDesignationsIds = [];
      this.vendorDesignations = [];
      this.isVisible = true;
      this.notes = '';
      this.memberNotes = '';
      this.address = new AddressEntity();
      this.website = '';
      this.cciPreferred = false;
      this.centralBilling = false;
      this.isArchived = false;
      this.isActive = true;
    }
  }

  get fullNumber() {
    if (!this.number) {
      return '';
    }
    return paddedZerosFormatter(VendorEntity.NUMBER_PADDING_DIGITS)(this.number).formatted.join('');
  }
}
