import { cms as cmsApi } from '../../../api';
import { useApi, useInternationalization } from '../../../hooks';
import { AdFilter, ContentStatus } from '../../../types';
import { MIN_PAGINATION_FILTER } from '../../../constants';
import { NewspaperRounded } from '@mui/icons-material';
import { routes } from '../../../routes';
import { CounterWidget } from './CounterWidget';
import { WidgetProps } from './Widget';

const filter: AdFilter = {
  status: ContentStatus.Submitted,
  ...MIN_PAGINATION_FILTER,
};

export const SubmittedAds = (props: WidgetProps) => {
  const { t } = useInternationalization();
  const { data, isLoading } = useApi(cmsApi.getAllAds, null, filter);

  return (
    <CounterWidget
      {...props}
      isLoading={isLoading}
      count={data?.totalCount}
      title={t('dashboard:submittedAds:title')}
      icon={NewspaperRounded}
      link={routes.Admin.Cms.Ads.path}
    />
  );
};
