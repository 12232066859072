import { formatInTimeZone } from 'date-fns-tz';
import format from 'date-fns/format';
import { enCA, frCA } from 'date-fns/locale';
import { IMG_EXTENSIONS, PDF_CONTENT_TYPE } from '../constants';
import { env } from '../env';
import i18n from '../i18n';
import { AddressEntity } from '../models';
import { postalCodeFormatter } from './formatters';
import { t } from 'i18next';
import { palette } from '../styles/palette';

export const uncapitalize = (str: string) => str.charAt(0).toLowerCase() + str.slice(1);

export const formatShortDate = (date: Date | string | null) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy-MM-dd');
};

export const formatMonth = (date: Date | string | null) => {
  if (!date) return '';
  const isFr = i18n.languages[0].startsWith('fr');
  return format(new Date(date), 'MMM', { locale: isFr ? frCA : enCA })
    .toUpperCase()
    .replace('.', '');
};

export const formatLongDate = (date: Date | string | null) => {
  if (!date) return '';
  const isFr = i18n.languages[0].startsWith('fr');
  return format(new Date(date), isFr ? 'd MMMM yyyy' : 'MMMM d, yyyy', {
    locale: isFr ? frCA : enCA,
  });
};

export const formatDateTime = (date: Date | string | null, timeZone?: string) => {
  if (!date) return '';
  const isFr = i18n.languages?.[0].startsWith('fr');
  return timeZone
    ? formatInTimeZone(new Date(date), timeZone, isFr ? 'd MMMM, yyyy, HH:mm zzz' : 'MMMM d, yyyy, hh:mm a zzz', {
        locale: isFr ? frCA : enCA,
      })
    : format(new Date(date), isFr ? 'd MMMM, yyyy, HH:mm zzz' : 'MMMM d, yyyy, hh:mm a zzz', {
        locale: isFr ? frCA : enCA,
      });
};

export const formatAddress = (address: AddressEntity | null | undefined) => {
  if (!address || (!address.street && !address.city && !address.province && !address.postalCode && !address.country)) {
    return null;
  }

  return i18n.languages[0].startsWith('fr')
    ? `${address.street ? `${address.street}\n` : ''}${address.city || ''}${
        address.province ? ` (${i18n.t(`common:province.${address.province}`)})` : ''
      } ${address.postalCode ? postalCodeFormatter(address.postalCode).formatted[1] : ''}${
        address.country ? '\n' + i18n.t(`common:country.${address.country}`) : ''
      }`
    : `${address.street ? `${address.street}\n` : ''}${address.city || ''}${
        address.province ? `, ${i18n.t(`common:province.${address.province}`)}` : ''
      } ${address.postalCode ? postalCodeFormatter(address.postalCode).formatted[1] : ''}${
        address.country ? '\n' + i18n.t(`common:country.${address.country}`) : ''
      }`;
};

export const areArrayEquals = (array1: string[] | number[], array2: string[] | number[]) => {
  return array1.sort().toString() == array2.sort().toString();
};

export const scrollToTop = (offset = 0) => window.scrollTo(0, offset);

const previewPdf = (filename: string, url: string) => {
  const template = `
    <div>
      <div style="display: flex; width: calc(100% - 16px); margin: 8px;">
        <a href="${url}" download="${filename}" style="
          padding: 8px;
          background: ${palette.primary.main};
          text-decoration: none;
          color: white;
          border-radius: 4px;
          text-align: center;
          font-family: Montserrat;
          font-weight: 600;"
        >
          ${t('common:download')}
        </a>
      </div>
      <iframe
        src="${url + '#toolbar=0'}"
        frameborder="0"
        width="100%"
        style="border: none; height: calc(100% - 35px)"
      ></iframe>
    </div>`;

  const newWindow = window.open('', '_blank');
  if (newWindow) {
    newWindow.document.head.insertAdjacentHTML(
      'afterbegin',
      "<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet'>",
    );
    newWindow.document.body.setAttribute(
      'style',
      'height:calc(100% - 16px); margin: 0;overflow-y:hidden; background:#323639;',
    );
    newWindow.document.body.insertAdjacentHTML('afterbegin', template);
    newWindow.document.title = filename;
  }
};

export const downloadBlob = (filename: string, blob: Blob) => {
  const url = URL.createObjectURL(blob);
  const filenameParsed = filename.replace(/['"]+/g, '');

  if (blob.type === PDF_CONTENT_TYPE) {
    previewPdf(filenameParsed, url);
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filenameParsed);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 250);
  }
};

export const formatMoney = (value: number | string | null | undefined) => {
  const isEn = i18n.languages[0].startsWith('en');
  if (value === null || value === undefined) return '';
  const castedValue = Number(value);
  if (isNaN(castedValue)) return '';

  const formatter = isEn
    ? new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
      })
    : new Intl.NumberFormat('fr-CA', {
        style: 'currency',
        currency: 'CAD',
      });
  return formatter.format(castedValue);
};

export const isDateValid = (date: Date) => date instanceof Date && !isNaN(date.valueOf());

export const getAuthorizeFileExtensions = (onlyImg = false) => {
  if (onlyImg) {
    return IMG_EXTENSIONS;
  }
  return (env.REACT_APP_AUTHORIZE_FILE_EXTENSIONS || '')?.split(',') ?? [];
};

export const toExternalLink = (link: string) =>
  link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`;
