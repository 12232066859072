import { paddedZerosFormatter } from '../../utils/formatters';
import { CategorizationEntity } from '../Categorization/CategorizationEntity';
import { AddressEntity } from '../Contact';
import { PendingEntity } from '../PendingEntity';
import { MemberEmailAddressEntity } from './MemberEmailAddressEntity';
import { MemberPhoneNumberEntity } from './MemberPhoneNumberEntity';

export class MemberEntity extends PendingEntity {
  number: number;
  location: number;
  name: string;
  membershipTypeId: number | null;
  membershipType: CategorizationEntity | null;
  regionIds: number[];
  regions: CategorizationEntity[];
  isMarketing: boolean;
  taskCount: number;
  notes: string;
  website: string;
  isArchived: boolean;
  isActive: boolean;
  activeSince: Date | null;
  inactiveSince: Date | null;
  legalName: string;
  businessNumber: string;
  shareIssueDate: Date | null;
  address: AddressEntity;
  emailAddresses: MemberEmailAddressEntity[];
  phoneNumbers: MemberPhoneNumberEntity[];
  isMyLocation: boolean;
  isEditable: boolean;
  hasFlyer: boolean;
  hasAirMiles: boolean;
  hasCreditCard: boolean;
  hasGiftCard: boolean;
  readonly canConfirm: boolean;

  static readonly NUMBER_PADDING_DIGITS = 4;
  static readonly LOCATION_PADDING_DIGITS = 2;

  constructor(entity?: MemberEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.regionIds = entity.regions?.map((r) => r.id);
      this.isEditable = entity['isEditable'] ?? true;
    } else {
      this.number = 0;
      this.location = 0;
      this.name = '';
      this.regionIds = [];
      this.regions = [];
      this.isMarketing = false;
      this.taskCount = 0;
      this.notes = '';
      this.website = '';
      this.isArchived = false;
      this.isActive = true;
      this.activeSince = new Date();
      this.inactiveSince = null;
      this.legalName = '';
      this.businessNumber = '';
      this.shareIssueDate = null;
      this.address = new AddressEntity();
      this.emailAddresses = [];
      this.phoneNumbers = [];
      this.isMyLocation = false;
      this.isEditable = true;
      this.hasFlyer = false;
      this.hasAirMiles = false;
      this.hasCreditCard = false;
      this.hasGiftCard = false;
    }
  }

  get fullNumber() {
    return `${paddedZerosFormatter(MemberEntity.NUMBER_PADDING_DIGITS)(this.number).formatted.join(
      '',
    )}.${paddedZerosFormatter(MemberEntity.LOCATION_PADDING_DIGITS)(this.location).formatted.join('')}`;
  }
}
