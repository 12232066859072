import { AutocompleteRenderGroupParams, Box, Button, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { useApi, useGlobalEdit, useInternationalization } from '../../../hooks';
import { VendorTierGrowthEntity } from '../../../models';
import { GrowthOrTierOption, PaginationEntity, SearchVendorTiersGrowthsFilter, Styles } from '../../../types';
import { Autocomplete } from '../../Form';

const style: Styles = {
  addButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
    justifyContent: 'flex-start',
  },
  inputContainer: {
    maxWidth: { xs: '100%', lg: '60%', xl: '50%' },
    pb: 2,
  },
  fullWidth: {
    minWidth: '100% !important',
  },
};

const defaultFilter: SearchVendorTiersGrowthsFilter = {
  pageSize: 5,
  pageNumber: 1,
  isArchived: false,
};

interface VendorTiersGrowthsSelectProps {
  vendorId: number;
  currentTiers: (VendorTierGrowthEntity | GrowthOrTierOption)[] | null;
  currentGrowths: (VendorTierGrowthEntity | GrowthOrTierOption)[] | null;
  onAssociateTierOrGrowth: (item: GrowthOrTierOption) => Promise<void>;
  onCreateTierOrGrowth?: (name: string) => void;
  fullWidth?: boolean;
}

export const VendorTiersGrowthsSelect = ({
  vendorId,
  currentTiers,
  currentGrowths,
  onAssociateTierOrGrowth,
  onCreateTierOrGrowth,
  fullWidth,
}: VendorTiersGrowthsSelectProps) => {
  const { t } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<GrowthOrTierOption | null>(null);
  const { call: fetchVendorTiers } = useApi(vendorApi.getAllTier, { callImmediately: false });
  const { call: fetchVendorGrowths } = useApi(vendorApi.getAllGrowth, { callImmediately: false });

  const onSearchTierOrGrowth = useCallback(
    async (name: string) => {
      const tiers = await fetchVendorTiers(vendorId, { ...defaultFilter, name });
      const growths = await fetchVendorGrowths(vendorId, { ...defaultFilter, name });

      const data = [
        ...(tiers?.data ?? []).map((tier) => ({
          name_En: tier.name_En,
          name_Fr: tier.name_Fr,
          type: 'tier',
          id: tier.id,
        })),
        ...(growths?.data ?? []).map((growth) => ({
          name_En: growth.name_En,
          name_Fr: growth.name_Fr,
          type: 'growth',
          id: growth.id,
        })),
      ];

      return { data } as PaginationEntity<GrowthOrTierOption>;
    },
    [vendorId, fetchVendorTiers, fetchVendorGrowths],
  );

  const onAdd = async () => {
    if (selectedItem && canAddTierOrGrowth(selectedItem)) {
      await onAssociateTierOrGrowth(selectedItem);
      setSelectedItem(null);
      setSearchText('');
    }
  };

  const canAddTierOrGrowth = (item: GrowthOrTierOption) => {
    const collection = (item.type === 'growth' ? currentGrowths : currentTiers) ?? [];
    return collection.every((tierOrGrowth) => tierOrGrowth.id !== item.id);
  };

  const createTierOrGrowth = () => {
    onCreateTierOrGrowth && onCreateTierOrGrowth(searchText);
    setSelectedItem(null);
    setSearchText('');
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => (
    <Box key={params.key}>
      <Typography p={1}>{t(`vendor:rebateCategory.tierAndGrowth.${params.group}CardType`)}</Typography>
      {params.children}
    </Box>
  );

  const renderExtraOption = () =>
    onCreateTierOrGrowth && !selectedItem && searchText ? (
      <li key="extra">
        <Button fullWidth sx={style.addButton} variant="text" onClick={createTierOrGrowth}>
          {t('vendor:rebateCategory.tierAndGrowth.actions.createAndAdd', { name: searchText })}
        </Button>
      </li>
    ) : undefined;

  if (globalEditing) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="flex-end"
      sx={{ ...style.inputContainer, ...(fullWidth ? style.fullWidth : {}) }}
    >
      <Autocomplete
        minSearchTextLength={1}
        value={searchText}
        onValueChange={(value) => setSearchText(value)}
        noOptionsText={t('vendor:rebateCategory.tierAndGrowth.notFound')}
        apiFunction={onSearchTierOrGrowth}
        disableOption={(option) => !canAddTierOrGrowth(option)}
        languageOptionKey="name"
        extraOption={renderExtraOption()}
        onApplySearch={(text) => {
          setSearchText(text);
          if (!text) {
            setSelectedItem(null);
          }
        }}
        groupBy={(item) => item.type}
        renderGroup={renderGroup}
        onSelectItem={setSelectedItem}
      />
      <Button disabled={!selectedItem} variant="contained" onClick={onAdd}>
        {t('common:add')}
      </Button>
    </Stack>
  );
};
