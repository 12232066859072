import { getAuthorizeFileExtensions } from './helper';

export const isImgExtension = (file: File) => {
  return isAllowedExtension(file, true);
};

export const isAllowedExtension = (file: File, onlyImg = false) => {
  if (!file) {
    return true;
  }
  const fileNameSplitted = file?.name?.split?.('.');
  if (!fileNameSplitted?.length) {
    return false;
  }
  const extension = fileNameSplitted[fileNameSplitted.length - 1];
  const supportedExtensions = getAuthorizeFileExtensions(onlyImg);
  return supportedExtensions.map((e) => e.toUpperCase()).includes(extension.toUpperCase());
};
