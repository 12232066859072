import axios from 'axios';
import { ForestProductGroupEntity } from '../../models';
import { PaginationEntity, ForestProductGroupFilter } from '../../types';

export const ForestProductGroup = {
  getAllForestProductGroups: async (
    forestProductGroupFilter: ForestProductGroupFilter,
  ): Promise<PaginationEntity<ForestProductGroupEntity>> => {
    const { data } = await axios.post(`api/forestproductgroup/search`, forestProductGroupFilter);
    return { ...data, data: data.data.map((v: ForestProductGroupEntity) => new ForestProductGroupEntity(v)) };
  },
  getForestProductGroup: async (productGroupId: number): Promise<ForestProductGroupEntity> => {
    const { data } = await axios.get(`api/forestproductgroup/${productGroupId}`);
    return new ForestProductGroupEntity(data);
  },
  createForestProductGroup: async (entity: ForestProductGroupEntity): Promise<ForestProductGroupEntity> => {
    const { data } = await axios.post(`api/forestproductgroup`, entity);
    return new ForestProductGroupEntity(data);
  },
  updateForestProductGroup: async (entity: ForestProductGroupEntity): Promise<ForestProductGroupEntity> => {
    const { data } = await axios.put(`api/forestproductgroup/${entity.id}`, entity);
    return new ForestProductGroupEntity(data);
  },
  archiveForestProductGroup: async (ids: number[], isArchived: boolean): Promise<ForestProductGroupEntity[]> => {
    const { data } = await axios.put(`api/forestproductgroup/archive`, { isArchived, ids });
    return data.map((v: ForestProductGroupEntity) => new ForestProductGroupEntity(v));
  },
};
