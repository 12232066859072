import { i18n } from 'i18next';
import { Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InternationalizationContext } from '../contexts';
import { LanguageCode, TranslatedStrings } from '../types';

export interface InternationalizationProps {
  t: (key: string, options?: object | undefined) => string;
  i18n: i18n;
  currentLanguage: LanguageCode;
  otherLanguage: LanguageCode;
  getTranslation: <T extends string>(object: TranslatedStrings<T>, key: T) => TranslatedStrings<T>[`${T}_Fr`];
  getTranslatablePropertyKey: <T extends string>(
    object: TranslatedStrings<T>,
    key: T,
    fallbackValue?: boolean,
  ) => `${T}_Fr` | `${T}_En`;
  timeZoneName: string | undefined;
  setTimeZoneName: Dispatch<SetStateAction<string | undefined>>;
  setProperty: <T extends string>(
    object: TranslatedStrings<T>,
    key: T,
    value: TranslatedStrings<T>[keyof TranslatedStrings<T>],
  ) => void;
}

export function useInternationalization(): InternationalizationProps {
  const { t, i18n } = useTranslation();
  const { timeZoneName, setTimeZoneName } = useContext(InternationalizationContext);

  const currentLanguage = useMemo(
    () => (i18n.languages[0].startsWith('fr') ? LanguageCode.fr : LanguageCode.en),
    [i18n.languages],
  );

  const otherLanguage = useMemo(
    () => (currentLanguage === LanguageCode.fr ? LanguageCode.en : LanguageCode.fr),
    [currentLanguage],
  );

  const getTranslation = useCallback(
    <T extends string>(object: TranslatedStrings<T>, key: T) =>
      currentLanguage === LanguageCode.fr && object[`${key}_Fr`] ? object[`${key}_Fr`] : object[`${key}_En`],
    [currentLanguage],
  );

  const getTranslatablePropertyKey = useCallback(
    <T extends string>(object: TranslatedStrings<T>, key: T, fallbackValue = true) =>
      currentLanguage === LanguageCode.fr && (!fallbackValue || object[`${key}_Fr`])
        ? (`${key}_Fr` as keyof TranslatedStrings<T>)
        : (`${key}_En` as keyof TranslatedStrings<T>),
    [currentLanguage],
  );

  const setProperty = useCallback(
    <T extends string>(
      object: TranslatedStrings<T>,
      key: T,
      value: TranslatedStrings<T>[keyof TranslatedStrings<T>],
    ) => {
      object[getTranslatablePropertyKey(object, key, false)] = value;
    },
    [getTranslatablePropertyKey],
  );

  return {
    t,
    i18n,
    currentLanguage,
    otherLanguage,
    getTranslation,
    getTranslatablePropertyKey,
    timeZoneName,
    setTimeZoneName,
    setProperty,
  };
}
