import { Box, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { ContentBlockType, LanguageCode, Styles, TranslationLanguage } from '../../types';
import { ArrowDownward, ArrowUpward, Delete, Image, TextFieldsOutlined, Videocam } from '@mui/icons-material';
import { useApi, useEnumList, useInternationalization } from '../../hooks';
import { useState, useEffect } from 'react';
import { palette } from '../../styles/palette';
import { RichText } from '../Form/Base/RichText';
import { ContentEntity, FileEntity } from '../../models';
import { MAX_FILE_SIZE_MB } from '../../constants';
import { ContentBlockEntity } from '../../models/Cms/ContentBlockEntity';
import { Input } from '../Form';
import { UseFieldArrayReturn } from 'react-hook-form';
import { Attachments } from '../Form/Base/Attachments/Attachments';
import { downloadBlob } from '../../utils/helper';

const style: Styles = {
  container: {
    background: palette.white,
    borderRadius: 4,
    my: 1,
    boxShadow: `0px 2px 4px ${palette.shadow}`,
    padding: '4px 16px 16px 16px',
  },
  tabs: {
    display: 'inline-block',
    borderBottom: 'none',
    mt: 0,
    minHeight: 'fit-content',
  },
  tab: {
    minWidth: 0,
    minHeight: 0,
    px: 1,
    py: 0.5,
  },
  iconButton: {
    padding: 1,
  },
  verticalSeparator: {
    borderRight: `2px solid ${palette.grey[200]}`,
    paddingRight: 1.5,
    height: '27px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
  },
};

interface CmsContentBlockEditProps {
  contentEntity: ContentEntity;
  contentBlock: ContentBlockEntity;
  fieldArray: UseFieldArrayReturn<ContentEntity, 'pages.0.blocks', 'fieldArrayId'>;
  index: number;
  downloadBlockFileApi: (contentEntityId: number, blockId: number, language: LanguageCode) => Promise<Blob>;
}

export const CmsContentBlockEdit = ({
  contentBlock,
  contentEntity,
  fieldArray,
  index,
  downloadBlockFileApi,
}: CmsContentBlockEditProps) => {
  const { t, currentLanguage } = useInternationalization();
  const [language, setLanguage] = useState<TranslationLanguage>(
    currentLanguage === LanguageCode.fr ? TranslationLanguage.Fr : TranslationLanguage.En,
  );
  const { call: downloadBlockFile } = useApi(downloadBlockFileApi, null);

  useEffect(() => {
    setLanguage(currentLanguage === LanguageCode.fr ? TranslationLanguage.Fr : TranslationLanguage.En);
  }, [currentLanguage, setLanguage]);
  const languageOptions = useEnumList(TranslationLanguage, 'common:translationLanguage');

  if (!contentEntity) {
    return null;
  }

  const getTitle = () => {
    switch (contentBlock?.type) {
      case ContentBlockType.HTML:
        return (
          <Typography sx={style.title}>
            <TextFieldsOutlined htmlColor={palette.grey[500]} />
            {t('cms:content.text')}
          </Typography>
        );
      case ContentBlockType.Image:
        return (
          <Typography sx={style.title}>
            <Image htmlColor={palette.grey[500]} />
            {t('cms:content.image')}
          </Typography>
        );
      case ContentBlockType.Video:
        return (
          <Typography sx={style.title}>
            <Videocam htmlColor={palette.grey[500]} />
            {t('cms:content.video')}
          </Typography>
        );

      default:
        return undefined;
    }
  };

  const getBlock = () => {
    switch (contentBlock?.type) {
      case ContentBlockType.HTML:
        return (
          <RichText
            key={language}
            value={contentBlock?.[`content_${language}`]}
            onChange={(value) => fieldArray.update(index, { ...contentBlock, [`content_${language}`]: value })}
          />
        );
      case ContentBlockType.Image:
        return (
          <Attachments
            maxSizeMB={MAX_FILE_SIZE_MB}
            maxCount={1}
            hideLabel
            onlyImg
            onFileClick={async () => {
              const fileData = await downloadBlockFile(
                contentEntity.id,
                contentEntity.pages[0].blocks[index].id,
                language == TranslationLanguage.En ? LanguageCode.en : LanguageCode.fr,
              );
              if (fileData) {
                downloadBlob(
                  (language == TranslationLanguage.En
                    ? contentEntity.pages[0].blocks[index]?.file_En?.name
                    : contentEntity.pages[0].blocks[index]?.file_Fr?.name) ?? '',
                  fileData,
                );
              }
            }}
            files={contentBlock[`file_${language}`] != null ? [contentBlock[`file_${language}`] as FileEntity] : []}
            onChange={(file: FileEntity[]) =>
              fieldArray.update(index, { ...contentBlock, [`file_${language}`]: file[0] })
            }
          />
        );
      case ContentBlockType.Video:
        return (
          <Input
            hideLabel
            placeholder={t('cms:content.urlPlaceholder')}
            value={contentBlock[`content_${language}`]}
            onChange={(e) => fieldArray.update(index, { ...contentBlock, [`content_${language}`]: e.target.value })}
          />
        );

      default:
        return undefined;
    }
  };

  const onMove = (to: number) => {
    fieldArray.move(index, to);
  };

  return (
    <Box sx={style.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6} container spacing={0.5} alignItems="center">
          <Grid item>{getTitle()}</Grid>
          <Grid item sx={style.verticalSeparator} />
          <Grid item>
            <IconButton sx={style.iconButton} onClick={() => onMove(index - 1)} disabled={index === 0}>
              <ArrowUpward htmlColor={palette.primary.deep} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              sx={style.iconButton}
              onClick={() => onMove(index + 1)}
              disabled={index === fieldArray.fields.length - 1}
            >
              <ArrowDownward htmlColor={palette.primary.deep} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={6} container spacing={0.5} alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Tabs value={language} onChange={(_, value: TranslationLanguage) => setLanguage(value)} sx={style.tabs}>
              {languageOptions.map((option) => (
                <Tab key={option.value} value={option.value} label={option.label} sx={style.tab} />
              ))}
            </Tabs>
          </Grid>
          <Grid item sx={style.verticalSeparator} />
          <Grid item>
            <IconButton sx={style.iconButton} onClick={() => fieldArray.remove(index)}>
              <Delete htmlColor={palette.primary.deep} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {getBlock()}
    </Box>
  );
};
