import { ChangeLogActionType, ChangeLogEntityType, ChangeLogType } from '../../types';

export class ChangeLogEntity {
  id: number;
  description?: string;
  loggedById: number;
  loggedBy?: string;
  logDate: Date;
  entity?: object;
  entityName_En: string;
  entityName_Fr: string;
  entityType?: ChangeLogEntityType;
  changeLogActionType?: ChangeLogActionType;
  changeLogType?: ChangeLogType;

  constructor(entity?: ChangeLogEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    }
  }
}
