import { SxProps, Theme } from '@mui/material';
import { ReactElement } from 'react';
import { Property } from 'csstype';
import { CardListElementProps } from './CardListElement';

export interface CardListRowProps<T> {
  children?: (ReactElement<CardListElementProps<T>> | false)[] | ReactElement<CardListElementProps<T>>;
  sx?: SxProps<Theme>;
  justifyContent?: Property.JustifyContent;
  alignItems?: Property.AlignItems;
  noUnderline?: boolean;
}

// eslint-disable-next-line no-empty-pattern
export const CardListRow = <T,>({}: CardListRowProps<T>) => <></>;
