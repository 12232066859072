import { CheckRounded } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { vendor as vendorApi } from '../../../api';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { routes } from '../../../routes';
import { PermissionKey, VendorBuyingAgreementStatus } from '../../../types';
import { VendorBuyingAgreementDetail } from './VendorBuyingAgreementDetail';

export const VendorBuyingAgreementDetailVendor = () => {
  const { hasPermissions, user } = useAuth();
  const { t } = useInternationalization();
  const params = useParams();
  const vendorBuyingAgreementId = Number(params.vendorBuyingAgreementId);
  const getBuyingAgreementApi = useApi(
    vendorApi.getBuyingAgreement,
    null,
    user?.profile.vendor?.id,
    vendorBuyingAgreementId,
  );
  const { call: submit, isLoading } = useApi(vendorApi.submitBuyingAgreement, null);

  const status = getBuyingAgreementApi.data?.status;

  const onSubmit = async () => {
    await submit(vendorBuyingAgreementId);
    getBuyingAgreementApi.refresh();
  };

  const renderWorkflow = () => {
    if (status != VendorBuyingAgreementStatus.InProgressVendor || !hasPermissions(PermissionKey.VendorEditVBAs)) {
      return undefined;
    }

    return (
      <Box>
        <Button size="small" variant="contained" disabled={isLoading} onClick={onSubmit} startIcon={<CheckRounded />}>
          {t('vendor:buyingAgreements.workflow.submit')}
        </Button>
      </Box>
    );
  };

  return (
    <VendorBuyingAgreementDetail
      vendorId={user?.profile.vendor?.id}
      vendorBuyingAgreementId={vendorBuyingAgreementId}
      readOnly
      fetchApi={getBuyingAgreementApi}
      workflow={renderWorkflow()}
      breadcrumbs={[
        { title: t('vendor:buyingAgreements.title'), href: routes.Vendor.BuyingAgreements.path },
        { title: getBuyingAgreementApi.data?.name },
      ]}
    />
  );
};
