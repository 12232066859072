import { Box, Grid } from '@mui/material';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { ForestProductGroupEntity } from '../../../models';
import { Styles } from '../../../types';
import { ControlledInput, ControlledTranslatedInput } from '../../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
};

export const ForestProductForm = () => {
  const { t } = useInternationalization();
  const { control } = useEntityFormContext<ForestProductGroupEntity>();
  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ControlledTranslatedInput
            maxLength={MAX_SHORT_TEXT_LENGTH}
            label={t('forestProduct:products.form.name')}
            name="name"
            control={control}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTranslatedInput
            maxLength={MAX_LONG_TEXT_LENGTH}
            label={t('forestProduct:products.form.description')}
            name="description"
            multiline
            rows={3}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            maxLength={MAX_LONG_TEXT_LENGTH}
            label={t('forestProduct:products.form.notes')}
            name="notes"
            multiline
            rows={3}
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
