import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Navigate, generatePath, useOutlet, useParams } from 'react-router-dom';
import { EditDetails } from '../../..';
import { vendor as vendorApi } from '../../../../api';
import { VendorBuyingAgreementBuyOpportunityDetailContext } from '../../../../contexts/Vendor';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../../hooks';
import { VendorBuyingAgreementBuyOpportunityEntity } from '../../../../models';
import { routes } from '../../../../routes';
import { VendorBuyingAgreementBuyOpportunitySchema } from '../../../../schemas';
import { LanguageCode, PermissionKey, Styles } from '../../../../types';
import { Container } from '../../../Container';
import { RequirePermission } from '../../../Permission';
import { LinkTab } from '../../../Tab';
import { VendorBuyOpportunityHeader } from '../../BuyOpportunities/VendorBuyOpportunityHeader';
import { VendorBuyingAgreementBuyOpportunityForm } from './VendorBuyingAgreementBuyOpportunityForm';
import { LayoutPage } from '../../../Layout';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const VendorBuyingAgreementBuyOpportunityDetail = () => {
  const { t, currentLanguage, getTranslation } = useInternationalization();
  const { hasPermissions, user, isInternalUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const outlet = useOutlet();
  const params = useParams();
  const vendorId = Number(user?.profile.vendor?.id ?? params.vendorId);
  const { data: vendor } = useApi(vendorApi.get, { skipFetch: !isInternalUser }, vendorId);
  const vendorBuyingAgreementId = Number(params.vendorBuyingAgreementId);
  const buyOpportunityId = Number(params.buyOpportunityId);
  const { data: buyingAgreement } = useApi(vendorApi.getBuyingAgreement, null, vendorId, vendorBuyingAgreementId);
  const { data, isLoading, refresh } = useApi(
    vendorApi.getBuyingAgreementBuyOpportunity,
    null,
    vendorId,
    vendorBuyingAgreementId,
    buyOpportunityId,
  );
  const { call: update } = useApi(vendorApi.updateBuyingAgreementBuyOpportunity, null);
  const { call: uploadFiles } = useApi(vendorApi.uploadBuyingAgreementBuyOpportunityFiles, null);
  const { call: deleteFiles } = useApi(vendorApi.deleteBuyingAgreementBuyOpportunityFiles, null);
  usePageTitle(data ? getTranslation(data, 'name') : '');

  const saveBuyOpportunity = async (entity: VendorBuyingAgreementBuyOpportunityEntity) => {
    let savedBuyOpportunity = await update(vendorId, vendorBuyingAgreementId, entity);
    if (savedBuyOpportunity && data) {
      const filesDiff = data.getFilesDiff(entity);
      if (filesDiff.removed.length) {
        savedBuyOpportunity = await deleteFiles(vendorId, vendorBuyingAgreementId, buyOpportunityId, filesDiff.removed);
      }
      if (filesDiff.added.length) {
        enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
        savedBuyOpportunity = await uploadFiles(vendorId, vendorBuyingAgreementId, buyOpportunityId, filesDiff.added);
      }
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    return savedBuyOpportunity;
  };

  const currentPath = isInternalUser
    ? routes.Admin.Vendor.BuyingAgreementBuyOpportunity
    : routes.Vendor.BuyingAgreementBuyOpportunity;

  const getGeneratedPath = (path: string) =>
    generatePath(path, { vendorId, vendorBuyingAgreementId, buyOpportunityId });

  const readOnly =
    !buyingAgreement?.isChildEditable(user?.profile.userType) || !hasPermissions(PermissionKey.VendorEditVBAs);

  const tabs = [
    <LinkTab
      label={t('vendor:buyOpportunities.sections.permissions')}
      value={generatePath(currentPath.Permissions.path, {
        vendorId,
        vendorBuyingAgreementId,
        buyOpportunityId,
      })}
    />,
    <LinkTab
      label={t('vendor:buyOpportunities.sections.documents')}
      value={getGeneratedPath(currentPath.Documents.path)}
    />,
    <LinkTab
      label={t('vendor:buyOpportunities.sections.details')}
      value={getGeneratedPath(currentPath.Details.path)}
    />,
  ];

  if (isLoading || !data) {
    return (
      <Container>
        <CircularProgress size={20} sx={style.loading} />
      </Container>
    );
  }

  if (!outlet) {
    return <Navigate replace to={getGeneratedPath(currentPath.Permissions.path)} />;
  }

  return (
    <RequirePermission keys={PermissionKey.VendorViewVBAs}>
      <VendorBuyingAgreementBuyOpportunityDetailContext.Provider
        value={{
          vendorId,
          readOnly,
          buyOpportunity: data,
          save: saveBuyOpportunity,
          fetchData: refresh,
        }}
      >
        <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
          <Container>
            <EditDetails
              titleLabel={t(`vendor:form.name`)}
              title={currentLanguage === LanguageCode.en ? 'name_En' : 'name_Fr'}
              readOnly={readOnly}
              header={<VendorBuyOpportunityHeader />}
              entity={data}
              schema={VendorBuyingAgreementBuyOpportunitySchema()}
              onSave={saveBuyOpportunity}
              onConfirm={refresh}
              breadcrumbs={
                isInternalUser
                  ? [
                      { title: t('vendor:title'), href: routes.Admin.Vendor.path },
                      { title: vendor?.name, href: generatePath(routes.Admin.Vendor.Detail.path, { vendorId }) },
                      {
                        title: buyingAgreement?.name,
                        href: generatePath(routes.Admin.Vendor.BuyingAgreement.BuyOpportunities.path, {
                          vendorId,
                          vendorBuyingAgreementId,
                        }),
                      },
                      { title: data ? getTranslation(data, 'name') : undefined },
                    ]
                  : [
                      { title: t('vendor:buyingAgreements.title'), href: routes.Vendor.BuyingAgreements.path },
                      {
                        title: buyingAgreement?.name,
                        href: generatePath(routes.Vendor.BuyingAgreement.BuyOpportunities.path, {
                          vendorBuyingAgreementId,
                        }),
                      },
                      { title: data ? getTranslation(data, 'name') : undefined },
                    ]
              }
            >
              <VendorBuyingAgreementBuyOpportunityForm />
            </EditDetails>
          </Container>
        </LayoutPage>
      </VendorBuyingAgreementBuyOpportunityDetailContext.Provider>
    </RequirePermission>
  );
};
