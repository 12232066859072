import { useEntityFormContext } from '../../hooks';
import { RoleEntity } from '../../models';
import { UserType } from '../../types';
import { ControlledAssignedPermissionsList } from '../Permission';

export const RoleForm = () => {
  const { control, getValues } = useEntityFormContext<RoleEntity>();

  return (
    <ControlledAssignedPermissionsList
      control={control}
      name="assignedPermissions"
      userType={getValues().userType ?? UserType.Internal}
    />
  );
};
