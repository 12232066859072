import { CheckCircleRounded, LocalActivityRounded, VisibilityRounded } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { Trans } from 'react-i18next';
import { roleGroup as roleGroupApi } from '../../api';
import { MAX_LONG_TEXT_LENGTH, MAX_PAGINATION_FILTER, MAX_SHORT_TEXT_LENGTH, MAX_URL_LENGTH } from '../../constants';
import { useEntityFormContext, useInternationalization } from '../../hooks';
import { UserEntity, VendorEntity } from '../../models';
import { RoleGroup, Styles } from '../../types';
import { paddedZerosFormatter } from '../../utils/formatters';
import { AddressForm } from '../Contact';
import { ControlledCheckbox, ControlledEntitySelect, ControlledInput, ControlledMultiSelect } from '../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 2,
  },
  numberFieldDrawer: {
    maxWidth: '30%',
  },
  notes: {
    mt: 1,
  },
};

export const VendorForm = () => {
  const { t, getTranslation } = useInternationalization();
  const { control, readOnly, variant, getValues } = useEntityFormContext<VendorEntity>();

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        {variant === 'drawer' && (
          <Grid item xs={12}>
            <ControlledInput
              maxLength={MAX_SHORT_TEXT_LENGTH}
              label={t('vendor:table.name')}
              name="name"
              variant="standard"
              control={control}
              required
            />
          </Grid>
        )}
        <Grid item xs={variant === 'drawer' ? 12 : 3}>
          <ControlledInput
            label={t('vendor:form.number')}
            name="number"
            formatter={paddedZerosFormatter(VendorEntity.NUMBER_PADDING_DIGITS)}
            type="number"
            control={control}
            sx={variant === 'drawer' ? style.numberFieldDrawer : undefined}
          />
        </Grid>
        {variant === 'details' && (
          <Grid item xs={3}>
            <ControlledMultiSelect
              label={t('vendor:form.vendorDesignations')}
              name="vendorDesignationsIds"
              options={
                getValues().vendorDesignations.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []
              }
              control={control}
              readOnly
            />
          </Grid>
        )}
        <Grid item xs={variant === 'drawer' ? 12 : 3}>
          <ControlledEntitySelect
            label={t('vendor:form.responsibleBuyer')}
            name="responsibleBuyer"
            apiFunction={roleGroupApi.getUsers}
            apiParams={[RoleGroup.Buyer, MAX_PAGINATION_FILTER]}
            getOptionLabel={(entity: UserEntity) => entity.fullName}
            control={control}
          />
        </Grid>
        {variant === 'details' && (
          <Grid item xs={3}>
            <ControlledCheckbox
              label={t('vendor:form.isCCIPreferred')}
              name="cciPreferred"
              control={control}
              icon={LocalActivityRounded}
              disabled
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <ControlledCheckbox
            label={t('vendor:form.isActive')}
            name="isActive"
            control={control}
            icon={CheckCircleRounded}
          />
        </Grid>
        <Grid item xs={variant === 'drawer' ? 3 : 9}>
          <ControlledCheckbox
            label={`${t('vendor:form.visible')}`}
            icon={VisibilityRounded}
            name="isVisible"
            control={control}
          />
        </Grid>
        <AddressForm excludeFields={['country']} requiredFields={['city', 'postalCode', 'province', 'street']} />
        <Grid item xs={12} sx={style.fieldGroup}>
          <ControlledInput
            label={t('vendor:form.website')}
            name="website"
            linkType="url"
            control={control}
            maxLength={MAX_URL_LENGTH}
          />
        </Grid>
        <Grid item xs={variant === 'drawer' ? 12 : 6}>
          <ControlledInput
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
            multiline
            label={t('vendor:form.memberNotes')}
            name="memberNotes"
            control={control}
          />
        </Grid>
        <Grid item xs={variant === 'drawer' ? 12 : 6}>
          <ControlledInput
            label={t('vendor:form.notes')}
            name="notes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
          <Box sx={style.notes}>{!readOnly && <Trans i18nKey="common:formHelperText.notes" />}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};
