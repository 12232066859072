import { LanguageCode, Styles } from '../../types';
import { useApi, useInternationalization } from '../../hooks';
import { ContentBlockEntity } from '../../models/Cms/ContentBlockEntity';
import { ContentEntity } from '../../models';
import { Loading } from '../Loading';
import { Box } from '@mui/material';

const style: Styles = {
  container: {
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
    },
  },
};

interface CmsContentBlockImageViewProps {
  contentEntity: ContentEntity;
  contentBlock: ContentBlockEntity;
  downloadBlockFileApi: (contentEntityId: number, blockId: number, language: LanguageCode) => Promise<Blob>;
}

export const CmsContentBlockImageView = ({
  contentBlock,
  contentEntity,
  downloadBlockFileApi,
}: CmsContentBlockImageViewProps) => {
  const { currentLanguage } = useInternationalization();

  const language =
    (currentLanguage == LanguageCode.fr && contentBlock.file_Fr) || !contentBlock.file_En
      ? LanguageCode.fr
      : LanguageCode.en;

  const { data, isLoading } = useApi(
    downloadBlockFileApi,
    { skipFetch: !contentBlock.file_En && !contentBlock.file_Fr },
    contentEntity.id,
    contentBlock.id,
    language ?? LanguageCode.en,
  );

  if (isLoading) {
    return <Loading />;
  }

  return data ? (
    <Box sx={style.container}>
      <img src={URL.createObjectURL(data)} />
    </Box>
  ) : null;
};
