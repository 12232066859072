import { Trans, useTranslation } from 'react-i18next';
import { useConfirmationModal } from '../../hooks';
import { Archivable, Entity } from '../../types';
import { MenuItem, MenuItemProps } from './MenuItem';

interface DeleteMenuItemProps<T extends Entity & Archivable> extends MenuItemProps {
  entity: T;
  name: string;
  onDeleteConfirm: (entity: T) => void;
  onClick: () => void;
  actionSuffix: string;
}

export const DeleteMenuItem = <T extends Entity & Archivable>({
  entity,
  name,
  onDeleteConfirm,
  onClick,
  actionSuffix,
  ...props
}: DeleteMenuItemProps<T>) => {
  const { openModal } = useConfirmationModal();
  const { t } = useTranslation();

  const onDeleteClick = () => {
    openModal({
      confirmText: t('common:action.confirmButton', { action: t('common:remove') }),
      title: t('common:action.confirmTitle', {
        name: actionSuffix,
        action: t('common:remove'),
        count: 1,
      }),
      description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name }} />,
      onConfirm: () => onDeleteConfirm(entity),
    });
    onClick && onClick();
  };

  return (
    <MenuItem {...props} id="delete" key="delete" onClick={onDeleteClick}>
      {t('common:remove')}
    </MenuItem>
  );
};
