import { AddRounded, ModeCommentRounded, VisibilityRounded } from '@mui/icons-material';
import { Button, Link, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { cci as cciApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization, usePageTitle } from '../../../hooks';
import { CommitmentEventEntity } from '../../../models';
import { routes } from '../../../routes';
import { CommitmentEventSchema } from '../../../schemas';
import { CommitmentEventFilter, FilterPeriod, OrderBy, PermissionKey } from '../../../types';
import { formatShortDate } from '../../../utils/helper';
import { EditDrawer } from '../../EditDrawer';
import { EntityPeriod } from '../../EntityPeriod';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { PeriodFilterSelect } from '../../Filter/PeriodFilterSelect';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem, MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { CommitmentEventForm } from './CommitmentEventForm';

const defaultFilter: CommitmentEventFilter = {
  period: FilterPeriod.All,
  isArchived: false,
  ...DEFAULT_PAGINATION_FILTER,
};

export const CommitmentEventList = () => {
  const { t, getTranslation } = useInternationalization();
  const [filter, setFilter] = useState(defaultFilter);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const newEvent = useMemo(() => new CommitmentEventEntity(), []);
  const navigate = useNavigate();
  const { data, isLoading, refresh } = useApi(cciApi.getAllCommitmentEvents, null, filter);
  const archiveApi = useApi(cciApi.archiveCommitmentEvents, { successKey: 'common:success.action' });
  const { call: create } = useApi(cciApi.createCommitmentEvents, { successKey: 'common:success.action' });
  usePageTitle('cci:events.title');

  const saveDrawer = async (entity: CommitmentEventEntity) => {
    const createdEntity = await create(entity);
    await refresh();
    return createdEntity;
  };

  const getDetailsPath = (id: number) => generatePath(routes.Admin.CommitmentEventDetails.path, { eventId: id });

  const cancelDrawer = () => setIsAddOpen(false);

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onArchiveConfirm = async (isArchived: boolean, item: CommitmentEventEntity) => {
    await archiveApi.call(isArchived, [item.id]);
    refresh();
  };

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.CciManageEvents}>
      <Button variant="contained" startIcon={<AddRounded />} onClick={() => setIsAddOpen(true)}>
        {t('cci:events.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderSearch = () => (
    <Stack spacing={1}>
      <PeriodFilterSelect label={t('cci:events.table.period')} filter={filter} setFilter={setFilter} />
      <ArchiveFilterSelect translationContext="male" filter={filter} setFilter={setFilter} />
    </Stack>
  );

  const renderActionsMenuItems = (item: CommitmentEventEntity, onClick: () => void) => [
    <ArchiveMenuItem
      key="archive"
      entity={item}
      name={item.year.toString()}
      onClick={onClick}
      onArchiveConfirm={onArchiveConfirm}
      actionSuffix={t(`cci:events.actions.suffix`)}
      permissions={{ keys: PermissionKey.CciManageEvents }}
    />,
    <MenuItem id="view" key="view" onClick={() => navigate(getDetailsPath(item.id))}>
      {t('common:view')}
    </MenuItem>,
  ];

  const renderPeriod = (entity: CommitmentEventEntity) => (
    <EntityPeriod period={entity.period} translationContext="male" />
  );

  const renderNotes = (entity: CommitmentEventEntity) => {
    let notes = '';
    if (entity.notes) {
      notes = `${t('cci:events.form.notes')}: ${entity.notes}\n`;
    }
    if (getTranslation(entity, 'memberNotes')) {
      notes += `${t('cci:events.form.memberNotes')}: ${getTranslation(entity, 'memberNotes')}`;
    }
    return notes;
  };

  const renderLastModified = (entity: CommitmentEventEntity) =>
    t('common:entity.modifiedDateShort', {
      date: formatShortDate(entity.modifiedDate),
      name: entity.modifiedBy,
    });

  const renderYearLink = (entity: CommitmentEventEntity) => (
    <Link underline="always" href={getDetailsPath(entity.id)}>
      {entity.year}
    </Link>
  );

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="cci:events"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={renderActionsMenuItems}
    >
      <TableColumn type="custom" width="15%" id="period" sortable render={renderPeriod} />
      <TableColumn type="custom" width="20%" id="year" sortable render={renderYearLink} />
      <TableColumn type="property" width="10%" id="categoriesCount" sortable />
      <TableColumn
        type="custom"
        width="15%"
        id="effectiveDate"
        sortable
        render={({ effectiveDate }: CommitmentEventEntity) => formatShortDate(effectiveDate)}
      />
      <TableColumn
        type="custom"
        width="15%"
        id="expiryDate"
        sortable
        render={({ expiryDate }: CommitmentEventEntity) => formatShortDate(expiryDate)}
      />
      <TableColumn type="custom" width="20%" id="modifiedDate" sortable render={renderLastModified} />
      <TableColumn type="icon" id="isVisibleToVendor" sortable align="center" iconComponent={VisibilityRounded} />
      <TableColumn
        type="icon"
        tooltip
        id="notes"
        align="center"
        render={renderNotes}
        iconComponent={ModeCommentRounded}
      />
    </Table>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.CciViewCommitments }}
      display="Tab"
      title={t('cci:events.title')}
      rightTitle={renderAddButton()}
    >
      <EditDrawer
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <AddRounded />
            <Typography variant="drawerTitle">{t('cci:events.actions.add')}</Typography>
          </Stack>
        }
        open={isAddOpen}
        redirectPath={(item) => getDetailsPath(item.id)}
        entity={newEvent}
        schema={CommitmentEventSchema()}
        redirectLabel={t('cci:events.actions.saveOpen')}
        onSave={saveDrawer}
        onConfirm={confirmDrawer}
        onCancel={cancelDrawer}
      >
        <CommitmentEventForm />
      </EditDrawer>
      {renderSearch()}
      {renderTable()}
    </LayoutPage>
  );
};
