import { NotificationsRounded, SettingsRounded } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { notification as notificationApi } from '../../../api';
import { FETCH_COUNT_NOTIFICATION_INTERVAL } from '../../../constants';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { routes } from '../../../routes';
import { palette } from '../../../styles/palette';
import { NotificationFilter, Styles } from '../../../types';
import { Pagination } from '../../Pagination';
import { NotificationListItem } from './NotificationListItem';

const style: Styles = {
  iconButton: {
    padding: '7px',
  },
  menuContainer: {
    width: {
      xs: '100%',
      sm: '600px',
    },
  },
  list: {
    height: {
      xs: '100%',
      sm: '400px',
    },
    overflowY: 'auto',
  },
  userMenu: {
    mt: 1.5,
  },
  userMenuPaper: {
    minWidth: {
      xs: '95%',
      sm: '600px',
    },
    height: {
      xs: '100%',
      sm: 'auto',
    },
    borderRadius: '20px',
    borderTopRightRadius: 0,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
  },
  readButton: {
    py: '5px',
  },
  headerContainer: {
    pb: 1,
  },
  paginationContainer: {
    mt: 1,
    px: 1,
    '& .MuiToolbar-root': {
      justifyContent: 'space-between',
    },
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
  },
  emptyContainer: {
    textAlign: 'center',
    mt: 2,
    overflowY: 'unset',
  },
};

const defaultFilter: NotificationFilter = {
  isRead: undefined,
  pageNumber: 1,
  pageSize: 20,
};

export const NotificationMenu = () => {
  const { isInternalUser, isMemberUser } = useAuth();
  const { t } = useInternationalization();
  const [filter, setFilter] = useState<NotificationFilter>(defaultFilter);
  const {
    data: notifications,
    isLoading,
    refresh: refreshNotifications,
  } = useApi(notificationApi.getAll, { callImmediately: false }, filter);
  const { data: hasUnread, refresh: refreshUnread } = useApi(notificationApi.hasUnread, { callImmediately: true });
  const { call: readAll, isLoading: readAllLoading } = useApi(notificationApi.readAll, {
    successKey: 'common:success.save',
  });
  const buttonRef = useRef(null);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const fetchHasUnread = () => refreshUnread();

    const fetchHasUnreadInterval = setInterval(fetchHasUnread, FETCH_COUNT_NOTIFICATION_INTERVAL);

    return () => {
      clearInterval(fetchHasUnreadInterval);
    };
  }, [refreshUnread]);

  useEffect(() => {
    if (visible) {
      refreshUnread();
      setFilter((prev) => ({ ...prev, pageNumber: defaultFilter.pageNumber }));
    }
  }, [visible, refreshUnread]);

  const onShowChange = (isRead?: boolean) => () =>
    setFilter((prev) => ({ ...prev, isRead, pageNumber: defaultFilter.pageNumber }));

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onMarkAllAsRead = async () => {
    await readAll();
    refreshNotifications();
    refreshUnread();
  };

  const closeMenu = () => {
    setVisible(false);
    refreshUnread();
  };

  const renderIcon = () => (
    <Tooltip title={t('notification:title')}>
      <IconButton sx={style.iconButton} ref={buttonRef} size="small" onClick={() => setVisible(true)}>
        <Badge overlap="circular" variant="dot" invisible={!hasUnread} color="error">
          <NotificationsRounded htmlColor={palette.primary.deep} />
        </Badge>
      </IconButton>
    </Tooltip>
  );

  const renderFilter = () => (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography align="center" variant="label" color="grey.600">
        {t('common:show')}
      </Typography>
      <Stack spacing={0.7} direction="row">
        <Chip
          key="all"
          label={t('common:filter.all', { context: 'female' })}
          onClick={onShowChange(undefined)}
          color={filter.isRead == undefined ? 'primary' : 'secondary'}
        />
        <Chip
          key="unreadOnly"
          label={t('notification:filter.unreadOnly')}
          onClick={onShowChange(false)}
          color={filter.isRead == false ? 'primary' : 'secondary'}
        />
      </Stack>
    </Stack>
  );

  const renderHeader = () => (
    <Container sx={style.headerContainer}>
      <Grid container display="flex" alignItems="center">
        <Grid item xs={12} sm={6}>
          {renderFilter()}
        </Grid>
        <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
          <Stack direction="row" spacing={0.5}>
            <Button
              sx={style.readButton}
              size="small"
              variant="text"
              onClick={onMarkAllAsRead}
              disabled={readAllLoading || !hasUnread}
            >
              {t('notification:actions.markAllAsRead')}
            </Button>
            <IconButton
              sx={style.iconButton}
              size="small"
              component={Link}
              onClick={closeMenu}
              href={
                isInternalUser
                  ? routes.Admin.Profile.path
                  : isMemberUser
                  ? routes.Member.Profile.path
                  : routes.Vendor.Profile.path
              }
            >
              <SettingsRounded htmlColor={palette.grey[400]} />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );

  const renderPagination = () =>
    notifications && (
      <Box sx={style.paginationContainer}>
        <Pagination
          hideFirstAndLast
          component="div"
          rowsPerPageOptions={[]}
          count={notifications.totalCount}
          page={notifications.currentPage - 1}
          onPageChange={(_, page) => filter.pageSize && onPaginationChange(page + 1, filter.pageSize)}
          rowsPerPage={notifications.pageSize}
        />
      </Box>
    );

  const renderContent = () => {
    if (isLoading || !notifications || notifications.data.length === 0) {
      return (
        <Container sx={[style.list, style.emptyContainer]}>
          {isLoading ? (
            <CircularProgress size={25} />
          ) : (
            <Typography variant="h3">{t('notification:emptyTitle')}</Typography>
          )}
        </Container>
      );
    }
    return (
      <>
        <List component="nav" disablePadding sx={style.list}>
          {notifications.data.map((n) => (
            <NotificationListItem key={n.id} notification={n} onClick={closeMenu} />
          ))}
        </List>
        <Divider />
        {renderPagination()}
      </>
    );
  };

  return (
    <>
      {renderIcon()}
      <Menu
        anchorEl={buttonRef.current}
        open={visible}
        onClose={() => setVisible(false)}
        PaperProps={{
          sx: style.userMenuPaper,
        }}
        sx={style.userMenu}
      >
        <Box sx={style.menuContainer}>
          {renderHeader()}
          <Divider />
          {renderContent()}
        </Box>
      </Menu>
    </>
  );
};
