import { ArchiveRounded, UnarchiveRounded } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { VendorDetailContext } from '../../../contexts';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorLocationEntity } from '../../../models';
import { PermissionKey, VendorLocationFilter } from '../../../types';
import { ArchiveMenuItem } from '../../Menu';
import { VendorLocationsList } from './VendorLocationsList';

export const VendorLocationsListAdmin = () => {
  const { t, getTranslation } = useInternationalization();
  const { vendor, vendorId } = useContext(VendorDetailContext);
  const [filter, setFilter] = useState<VendorLocationFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });

  const getAllLocationsApi = useApi(vendorApi.getAllLocations, { skipFetch: !vendor }, vendor?.id, filter);
  const { call: create } = useApi(vendorApi.createLocation, { successKey: 'common:success.save' });
  const { call: update } = useApi(vendorApi.updateLocation, { successKey: 'common:success.save' });
  const archiveApi = useApi(vendorApi.archiveLocation, { successKey: 'common:success.action' });

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: VendorLocationEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: VendorLocationEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onSave = async (entity: VendorLocationEntity) => {
    return entity.id ? await update(vendorId, entity) : await create(vendorId, entity);
  };

  const onArchiveConfirm = async (isArchived: boolean, entities: VendorLocationEntity[]) => {
    await archiveApi.call(
      vendorId,
      entities.map((e) => e.id),
      isArchived,
    );
    getAllLocationsApi.refresh();
  };

  const renderActionsMenuItems = (entity: VendorLocationEntity, onClick: () => void) => [
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={getTranslation(entity, 'description')}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('vendor:location.actions.suffix')}
    />,
  ];

  return (
    <VendorLocationsList
      layout="Tab"
      showSearch
      addButtonProps={{
        disabled: !vendor || vendor.isArchived,
      }}
      filter={filter}
      setFilter={setFilter}
      fetchApi={getAllLocationsApi}
      onSave={onSave}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
        bulkActions: vendor && !vendor.isArchived ? bulkActions : undefined,
        bulkActionSuffix: t('vendor:location.actions.suffix'),
        bulkPermissions: { keys: PermissionKey.VendorEditContacts },
        renderBulkSelection: (items) =>
          items.length === 1 ? getTranslation(items[0], 'description') : t(`vendor:location.title`),
      }}
    />
  );
};
