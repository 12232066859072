export class PhoneNumberEntity {
  id: number;
  number: string;
  extension: string;
  description_En: string;
  description_Fr: string;

  constructor() {
    this.id = 0;
    this.number = '';
    this.extension = '';
    this.description_En = '';
    this.description_Fr = '';
  }
}
