import { ArrowForwardRounded } from '@mui/icons-material';
import { Card, Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { palette } from '../../../styles/palette';
import { ContentStatus, LanguageCode, StaticPageFilter, Styles } from '../../../types';
import { Loading } from '../../Loading';
import { Widget, WidgetProps } from './Widget';
import { useApi, useInternationalization } from '../../../hooks';
import { cms as cmsApi } from '../../../api';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../routes';
import { StaticPageEntity } from '../../../models';
import { MAX_PAGINATION_FILTER } from '../../../constants';

const style: Styles = {
  cardsContainer: {
    mt: 3,
    px: 2,
    backgroundColor: palette.white,
    borderRadius: 2,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    '>div': {
      boxShadow: 'unset',
      borderRadius: 0,
    },
    '>div:not(:first-of-type)': {
      borderTop: `1px solid ${palette.grey[300]}`,
    },
  },
  title: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  content: {
    '>img': {
      width: '87px',
      height: '44px',
      objectFit: 'contain',
      mr: 2,
    },
    mb: 1.5,
    mt: 2,
  },
  linkIcon: {
    fontSize: 24,
    color: palette.primary.deep,
  },
};

const defaultFilter: StaticPageFilter = {
  status: ContentStatus.Approved,
  ...MAX_PAGINATION_FILTER,
};

export const StaticPageWidget = (props: WidgetProps) => {
  const { getTranslation, currentLanguage } = useInternationalization();
  const { data, isLoading } = useApi(cmsApi.getAllStaticPages, { callImmediately: true }, defaultFilter);
  const { call: getImage } = useApi(cmsApi.getStaticPageIconImage, null);
  const [elements, setElements] = useState<string[] | null>(null);

  const getLink = (staticPage: StaticPageEntity) =>
    generatePath(routes.Member.StaticPageDetails.path, { staticPageId: staticPage.id });

  useEffect(() => {
    const getElement = async () => {
      const result: string[] = [];
      if (data?.data) {
        for await (const item of data.data) {
          const language = currentLanguage === LanguageCode.fr && item.iconImage_Fr ? LanguageCode.fr : LanguageCode.en;
          const image = await getImage(item.id, language);
          if (image) {
            result.push(URL.createObjectURL(image));
          }
        }
        setElements(result);
      }
    };
    getElement();
  }, [currentLanguage, data?.data, getImage]);

  if (isLoading || !elements) {
    return <Loading />;
  }

  return (
    <Widget {...props}>
      <Stack sx={style.cardsContainer}>
        {data?.data.map((page, index) => (
          <Card key={page.id}>
            <Stack component={Link} href={getLink(page)}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" justifyContent="start" sx={style.content}>
                  <img src={elements[index]} />
                  <Typography sx={style.title}>{getTranslation(page, 'name')}</Typography>
                </Stack>
                <ArrowForwardRounded sx={style.linkIcon} />
              </Stack>
            </Stack>
          </Card>
        ))}
      </Stack>
    </Widget>
  );
};
