import { BaseUserType, EditableEntity, NotificationSettingEntity, UserPhoneNumberEntity } from '..';
import { LanguageCode, NotificationEmailFrequency, PermissionKey, TimeZoneCode, UserType } from '../../types';

export class ProfileEntity extends EditableEntity {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  language: LanguageCode;
  timeZone: TimeZoneCode;
  jobTitle?: string;
  department?: string;
  members?: BaseUserType[] | null;
  vendor: BaseUserType | null;
  phoneOffice?: UserPhoneNumberEntity;
  phoneMobile?: UserPhoneNumberEntity;
  verificationPhoneNumber?: string;
  permissionKeys: PermissionKey[];
  userType: UserType;
  mustAcceptConfidentialityAgreement: boolean | null;
  notificationSettings: NotificationSettingEntity[];
  notificationEmailFrequency: NotificationEmailFrequency;

  constructor(entity?: ProfileEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.username = '';
      this.email = '';
      this.firstName = '';
      this.lastName = '';
      this.language = LanguageCode.en;
      this.timeZone = TimeZoneCode.MountainTime;
      this.jobTitle = '';
      this.department = '';
      this.phoneOffice = new UserPhoneNumberEntity();
      this.phoneMobile = new UserPhoneNumberEntity();
      this.permissionKeys = [];
      this.userType = UserType.Internal;
      this.mustAcceptConfidentialityAgreement = null;
      this.notificationSettings = [];
      this.notificationEmailFrequency = NotificationEmailFrequency.Weekly;
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getDisplayName(memberId: number | null) {
    if (this.userType == UserType.Vendor && this.vendor) {
      return this.vendor.displayName;
    }
    if (this.userType == UserType.Member && this.members) {
      return this.members.find((m) => m.id == memberId)?.displayName;
    }

    return null;
  }
}
