import { useInternationalization } from '../../../../hooks';
import {
  ChangeLogEntity,
  FileEntity,
  VendorGeneralConditionEntity,
  VendorRebateCategoryEntity,
  VendorTierGrowthEntity,
} from '../../../../models';
import { BuyOpportunityEntity } from '../../../../models/Vendor/BuyOpportunityEntity';
import { ChangeLogEntityType } from '../../../../types';
import { ChangeLogItem } from '../../../ChangeLog';

interface VendorBuyingAgreementChangeLogItemProps {
  item: ChangeLogEntity;
}

export const VendorBuyingAgreementChangeLogItem = ({ item }: VendorBuyingAgreementChangeLogItemProps) => {
  const { t, getTranslation } = useInternationalization();

  const renderValue = () => {
    if (item.entity) {
      switch (item.entityType) {
        case ChangeLogEntityType.Tiers:
        case ChangeLogEntityType.Growth:
          return getTranslation(item.entity as VendorTierGrowthEntity, 'name');
        case ChangeLogEntityType.Document:
          return (item.entity as FileEntity).name;
        case ChangeLogEntityType.GeneralConditions:
          return t(`vendor:generalCondition.type.${(item.entity as VendorGeneralConditionEntity).type}`);
        case ChangeLogEntityType.BuyOpportunity: {
          const buyOpportunity = item.entity as BuyOpportunityEntity;
          const type = t(`vendor:buyOpportunities.opportunity.${buyOpportunity.opportunityType}`);
          return `${type} - ${getTranslation(buyOpportunity, 'name')}`;
        }
        case ChangeLogEntityType.RebateCategory:
          return getTranslation(item.entity as VendorRebateCategoryEntity, 'name');
        default:
          break;
      }
    }
    return '';
  };

  return (
    <ChangeLogItem
      item={item}
      renderValue={(item) =>
        t(`changeLog:entityType.${item.entityType}`, {
          action: item.changeLogActionType,
          fullName: item.loggedBy,
          value: renderValue(),
        })
      }
    />
  );
};
