import { useOutlet } from 'react-router-dom';
import { useInternationalization, usePageTitle } from '../../hooks';
import { routes } from '../../routes';
import { PermissionKey } from '../../types';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';

export const ChangeRequests = () => {
  const { t } = useInternationalization();
  const outlet = useOutlet();
  usePageTitle('changeRequest:title');

  const tabs = [
    <LinkTab
      label={t('changeRequest:sections.vendor')}
      value={routes.Admin.ChangeRequests.Vendor.path}
      permissions={{
        keys: [
          PermissionKey.VendorApproveContacts,
          PermissionKey.VendorApproveFinancial,
          PermissionKey.VendorApproveFiles,
        ],
        any: true,
      }}
    />,
    <LinkTab
      label={t('changeRequest:sections.member')}
      value={routes.Admin.ChangeRequests.Member.path}
      permissions={{
        keys: [PermissionKey.MemberApproveMembers, PermissionKey.MemberApproveContacts],
        any: true,
      }}
    />,
  ];

  return <LayoutPage outlet={outlet} title={t('changeRequest:title')} tabs={tabs} />;
};
