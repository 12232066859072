import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ControlledInput } from '../components/Form';
import { LayoutPublic } from '../components/Layout';
import { useAuth, useInternationalization, usePageTitle } from '../hooks';
import { routes } from '../routes';
import { palette } from '../styles/palette';
import { Styles, PhoneNumberVerificationRequest } from '../types';

const style: Styles = {
  button: {
    width: '100%',
  },
};

export function VerifyPhoneNumber() {
  const { t } = useInternationalization();
  usePageTitle('auth:verifyPhoneNumber.title');
  const [errorKey, setErrorKey] = useState('');
  const navigate = useNavigate();
  const { refresh, setVerificationPhoneNumber, verifyPhoneNumber, userVerifyPhoneNumber } = useAuth();

  const validationLogin = Yup.object({
    code: Yup.string().required(t('common:error.fieldRequired', { name: t('auth:verifyPhoneNumber.code') })),
  });

  const { handleSubmit, control, formState } = useForm<PhoneNumberVerificationRequest>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(validationLogin),
  });

  const onSubmit = async (request: PhoneNumberVerificationRequest) => {
    setErrorKey('');
    const result = await verifyPhoneNumber(request);
    if (result) {
      refresh();
    } else {
      setErrorKey(t('common:error.generic'));
    }
  };

  const onResendCode = async () => {
    setErrorKey('');
    if (userVerifyPhoneNumber) {
      const result = await setVerificationPhoneNumber(userVerifyPhoneNumber);
      if (result) {
        refresh();
      } else {
        setErrorKey(t('common:error.generic'));
      }
    }
  };

  const onBack = async () => {
    await setVerificationPhoneNumber(null);
    navigate(routes.SetVerificationPhoneNumber.path);
  };

  return (
    <LayoutPublic title={t('auth:verifyPhoneNumber.title')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Typography>
            {t('auth:verifyPhoneNumber.info', {
              phoneNumber: userVerifyPhoneNumber?.phoneNumber?.substring(userVerifyPhoneNumber.phoneNumber.length - 4),
            })}
          </Typography>
          <ControlledInput label={t('auth:verifyPhoneNumber.code')} name="code" control={control} autoFocus />
          <Button onClick={onResendCode}>{t('auth:verifyCode.resendCode')}</Button>
          <LoadingButton sx={style.button} loading={formState.isSubmitting} type="submit" variant="contained">
            {t('auth:verifyPhoneNumber.action')}
          </LoadingButton>
          {!!errorKey && <Typography variant="inputError">{t(errorKey)}</Typography>}
          <Link onClick={onBack} underline="hover">
            <Stack direction="row" alignItems="center" component="span" spacing={0.25}>
              <KeyboardArrowLeftRounded fontSize="small" htmlColor={palette.grey[400]} />
              <Typography variant="breadcrumb" component="span" sx={style.breadcrumbText}>
                {t('auth:verifyPhoneNumber.back')}
              </Typography>
            </Stack>
          </Link>
        </Stack>
      </form>
    </LayoutPublic>
  );
}
