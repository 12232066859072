import { CampaignRounded, CheckCircleRounded } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { categorization as categorizationApi, member as memberApi } from '../../api';
import { MAX_INTEGER, MAX_URL_LENGTH } from '../../constants';
import { useApi, useAuth, useDebounce, useEntityFormContext, useInternationalization } from '../../hooks';
import { MemberEntity } from '../../models';
import { LanguageCode } from '../../types';
import { paddedZerosFormatter } from '../../utils/formatters';
import { AddressForm, EmailAddressListForm, PhoneNumberListForm } from '../Contact';
import { ControlledCheckbox, ControlledDatePicker, ControlledInput, ControlledSelect } from '../Form';
import { ControlledMultiSelect } from '../Form/Controlled/ControlledMultiSelect';

export const MemberForm = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { isInternalUser } = useAuth();
  const { control, readOnly, setValue, resetField, variant, watch, formState } = useEntityFormContext<MemberEntity>();
  const membershipTypes = useApi(categorizationApi.getAll, null, 'membershipType', false);
  const regions = useApi(categorizationApi.getAll, null, 'region', false);

  // Update Location automatically with next available when Number changes
  const watchNumber = watch('number');
  const debouncedWatchNumber = useDebounce(watchNumber, 500);
  const { call: callNextLocation } = useApi(memberApi.getNextLocationAvailable, null);
  useEffect(() => {
    if (
      formState.dirtyFields.number &&
      debouncedWatchNumber &&
      debouncedWatchNumber > 0 &&
      debouncedWatchNumber < MAX_INTEGER
    ) {
      callNextLocation(debouncedWatchNumber).then(
        (nextLocation) => (nextLocation || nextLocation === 0) && setValue('location', nextLocation),
      );
    }
  }, [formState.dirtyFields.number, debouncedWatchNumber, callNextLocation, setValue]);

  // Update InactiveSince when isActive is toggled to false
  const watchIsActive = watch('isActive');
  useEffect(() => {
    if (formState.dirtyFields.isActive && !watchIsActive) {
      setValue('inactiveSince', new Date(), { shouldDirty: true });
    } else if (formState.dirtyFields.inactiveSince && watchIsActive) {
      resetField('inactiveSince');
    }
  }, [formState, watchIsActive, resetField, setValue]);

  const getMembershipTypeValue = (id: unknown) => {
    const membershipType = membershipTypes.data?.values.find((mt) => mt.id == id);
    if (membershipType) {
      return getTranslation(membershipType, 'name');
    }
    return '';
  };

  return (
    <Grid container rowSpacing={variant === 'drawer' ? 2 : 4} columnSpacing={2}>
      {(variant === 'drawer' || variant === 'changeRequest') && (
        <Grid item xs={12}>
          <ControlledInput label={t('member:form.name')} name="name" control={control} variant="standard" required />
        </Grid>
      )}
      {variant !== 'changeRequest' && (
        <>
          <Grid item xs={3} md={variant === 'drawer' ? 3 : 1}>
            <ControlledInput
              label={t('member:form.number')}
              name="number"
              type="number"
              control={control}
              formatter={paddedZerosFormatter(MemberEntity.NUMBER_PADDING_DIGITS)}
              required
              readOnly={!isInternalUser}
            />
          </Grid>
          <Grid item xs={4} md={variant === 'drawer' ? (currentLanguage == LanguageCode.fr ? 4 : 3) : 2}>
            <ControlledInput
              label={t('member:form.location')}
              type="number"
              name="location"
              control={control}
              formatter={paddedZerosFormatter(MemberEntity.LOCATION_PADDING_DIGITS)}
              required
              readOnly={!isInternalUser}
            />
          </Grid>
          {isInternalUser && (
            <Grid item xs={4} md={variant === 'drawer' ? 12 : 2}>
              <ControlledCheckbox
                label={t('member:form.isMarketing')}
                icon={CampaignRounded}
                name="isMarketing"
                control={control}
                variant={variant === 'drawer' ? 'inline' : 'stacked'}
              />
            </Grid>
          )}
          <Grid item xs={variant === 'drawer' ? 6 : 3}>
            <ControlledSelect
              label={t('member:form.membershipTypeId')}
              name="membershipTypeId"
              renderValue={getMembershipTypeValue}
              options={
                membershipTypes.data?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []
              }
              control={control}
              required
              readOnly={!isInternalUser}
            />
          </Grid>
          <Grid item xs={variant === 'drawer' ? 6 : 3}>
            <ControlledMultiSelect
              label={t('member:table.regionIds')}
              name="regionIds"
              options={regions.data?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
              control={control}
              mode="select"
              required
              readOnly={!isInternalUser}
            />
          </Grid>
          {variant !== 'drawer' && <Grid item xs={0} md={1} />}
        </>
      )}

      {isInternalUser && variant !== 'changeRequest' && (
        <>
          <Grid item xs={3} md={variant === 'drawer' ? 12 : 1}>
            <ControlledCheckbox
              label={t('member:form.isActive')}
              name="isActive"
              control={control}
              variant={variant === 'drawer' ? 'inline' : 'stacked'}
              icon={CheckCircleRounded}
            />
          </Grid>
          <Grid item xs={3} md={variant === 'drawer' ? 6 : 2}>
            <ControlledDatePicker label={t('member:form.activeSince')} name="activeSince" control={control} />
          </Grid>
          <Grid item xs={3} md={variant === 'drawer' ? 6 : 2}>
            <ControlledDatePicker
              label={t('member:form.inactiveSince')}
              name="inactiveSince"
              control={control}
              disabled={watchIsActive}
            />
          </Grid>
          {variant !== 'drawer' && <Grid item xs={0} md={5} />}
        </>
      )}
      <Grid item xs={4} md={variant === 'drawer' ? 12 : 3}>
        <ControlledInput label={t('member:form.legalName')} name="legalName" control={control} />
      </Grid>
      {isInternalUser && variant !== 'changeRequest' && (
        <>
          <Grid item xs={3} md={variant === 'drawer' ? 12 : 2}>
            <ControlledInput label={t('member:form.businessNumber')} name="businessNumber" control={control} />
          </Grid>
          <Grid item xs={variant === 'drawer' ? 12 : 4}>
            <ControlledDatePicker label={t('member:form.shareIssueDate')} name="shareIssueDate" control={control} />
          </Grid>
        </>
      )}
      <AddressForm excludeFields={['country']} />
      <Grid item xs={12}>
        <Typography variant="h3">{t('member:form.contactInformation')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <EmailAddressListForm disableTranslation />
      </Grid>
      <Grid item xs={12}>
        <PhoneNumberListForm disableTranslation />
      </Grid>
      <Grid item xs={12} md={variant === 'drawer' ? 12 : 6}>
        <ControlledInput
          label={t('member:form.website')}
          name="website"
          linkType="url"
          control={control}
          maxLength={MAX_URL_LENGTH}
        />
      </Grid>
      {isInternalUser && variant !== 'changeRequest' && (
        <>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <ControlledCheckbox label={t('member:form.flyer')} name="hasFlyer" control={control} />
              <ControlledCheckbox label={t('member:form.airMiles')} name="hasAirMiles" control={control} />
              <ControlledCheckbox label={t('member:form.creditCard')} name="hasCreditCard" control={control} />
              <ControlledCheckbox label={t('member:form.giftCard')} name="hasGiftCard" control={control} />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">{t('member:form.notes')}</Typography>
          </Grid>
          <Grid item xs={variant === 'drawer' ? 12 : 5}>
            <ControlledInput label={t('member:form.internalNotes')} name="notes" control={control} multiline rows={3} />
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Trans i18nKey="common:formHelperText.notes" />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
