import React from 'react';
import { VendorBuyingAgreementEntity } from '../../models';

interface VendorBuyingAgreementContextType {
  vendorBuyingAgreement: VendorBuyingAgreementEntity;
  readOnly: boolean;
  refresh: () => Promise<void>;
}

export const VendorBuyingAgreementContext = React.createContext<VendorBuyingAgreementContextType>(
  {} as VendorBuyingAgreementContextType,
);
