export const palette = {
  primary: {
    dark: '#0A1E3E',
    main: '#005EB8',
    lightMain: '#EAF7FF',
    light: '#999999',
    contrastText: '#e5e5e5',
    blue: '#005EB8',
    deep: '#06386F',
  },
  secondary: {
    lightest: '#CAECFF',
    light: '#5580b6',
    main: '#005EB8',
    dark: '#214F99',
    darker: '#0A1E3E',
    darkest: '#010F25',
    blue: '#2CB3FF',
    yellow: '#FFC805',
    green: '#09977D',
    orange: '#D9730C',
    red: '#AB1230',
    transparentMain: '#3066A811',
    contrastText: '#ffffff',
  },
  backgrounds: {
    container: '#F0F2F5',
  },
  shadow: '#0A1E3E1A',
  white: '#FFFFFF',
  black: '#000000',
  grey: {
    50: '#F7F8FA',
    100: '#F0F2F5',
    200: '#E4E9EC',
    300: '#CAD3DA',
    400: '#979FA8',
    500: '#6D737B',
    600: '#22272D',
    700: '#6D6E71',
  },
  info: {
    main: '#2CB3FF',
  },
  warning: {
    main: '#FFC805',
  },
  success: {
    main: '#09977D',
  },
  error: {
    main: '#AB1230',
    light: '#FD304E',
  },
  PublicationUrgency: {
    Green: '#09977D',
    Yellow: '#D9730C',
    Red: '#AB1230',
  },
};
