import { AddRounded, ArchiveRounded, ModeCommentRounded, UnarchiveRounded } from '@mui/icons-material';
import { Button, Link, MenuItem, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { forestProduct as forestProductApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { ForestProductPriceUpdateEntity } from '../../../models';
import { ForestProductPriceUpdateFilter, OrderBy, PermissionKey } from '../../../types';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { routes } from '../../../routes';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { ForestProductPriceUpdateSchema } from '../../../schemas';
import { EditDrawer } from '../../EditDrawer';
import { ForestProductPriceUpdateForm } from './ForestProductPriceUpdateForm';
import { formatShortDate } from '../../../utils/helper';

const defaultFilter: ForestProductPriceUpdateFilter = {
  isArchived: false,
  ...MAX_PAGINATION_FILTER,
};

export const ForestProductPriceUpdateList = () => {
  const navigate = useNavigate();
  const { t } = useInternationalization();
  const priceUpdate = useMemo(() => new ForestProductPriceUpdateEntity(), []);
  const [filter, setFilter] = useState(defaultFilter);
  const [isAddOpen, setIsAddOpen] = useState(false);

  const { data, isLoading, refresh } = useApi(forestProductApi.getAllForestProductPriceUpdates, null, filter);

  const { call: create } = useApi(forestProductApi.createForestProductPriceUpdate, {
    successKey: 'common:success.save',
  });
  const archiveApi = useApi(forestProductApi.archiveForestProductPriceUpdate, { successKey: 'common:success.action' });

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: ForestProductPriceUpdateEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: ForestProductPriceUpdateEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onSave = async (entity: ForestProductPriceUpdateEntity) => {
    return await create(entity);
  };

  const onArchiveConfirm = async (isArchived: boolean, priceUpdates: ForestProductPriceUpdateEntity[]) => {
    await archiveApi.call(
      priceUpdates.map((i) => i.id),
      isArchived,
    );
    refresh();
  };

  const renderFilter = () => <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext={'male'} />;

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const getProductPriceUpdateDetailPath = (priceUpdateId: number) =>
    generatePath(routes.Admin.ForestProductPriceUpdateDetail.path, { priceUpdateId });

  const renderDate = (entity: ForestProductPriceUpdateEntity) => (
    <Link underline="always" href={getProductPriceUpdateDetailPath(entity.id)}>
      {formatShortDate(entity.date)}
    </Link>
  );

  const renderActionsMenuItems = (entity: ForestProductPriceUpdateEntity, onClick: () => void) => [
    <MenuItem id="view" key="view" onClick={() => navigate(getProductPriceUpdateDetailPath(entity.id))}>
      {t('common:view')}
    </MenuItem>,
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={formatShortDate(entity.date)}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('forestProduct:priceUpdates.actions.suffix')}
      permissions={{ keys: PermissionKey.CommodityPriceEdit }}
    />,
  ];

  const renderTable = () =>
    data && (
      <Table
        data={data}
        translationNamespace="forestProduct:priceUpdates"
        isLoading={isLoading}
        actionMenuItems={renderActionsMenuItems}
        bulkActions={bulkActions}
        onSortChange={onSortChange}
        sortColumns={filter.orderBy}
        renderBulkSelection={(items) =>
          items.length === 1 ? formatShortDate(items[0].date) : t('forestProduct:priceUpdates.title')
        }
      >
        <TableColumn type="custom" width="100%" id="date" sortable render={renderDate} />
        <TableColumn type="icon" tooltip id="notes" align="center" iconComponent={ModeCommentRounded} />
      </Table>
    );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.CommodityPriceEdit}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => {
          setIsAddOpen(true);
        }}
      >
        {t('forestProduct:priceUpdates.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('forestProduct:priceUpdates.actions.add')}</Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={priceUpdate}
      schema={ForestProductPriceUpdateSchema()}
      onSave={async (entity) => {
        const createdEntity = await onSave(entity);
        await refresh();
        return createdEntity;
      }}
      onConfirm={async () => {
        await refresh();
        setIsAddOpen(false);
      }}
      onCancel={() => setIsAddOpen(false)}
    >
      <ForestProductPriceUpdateForm />
    </EditDrawer>
  );

  return (
    <LayoutPage title={t('forestProduct:priceUpdates.title')} rightTitle={renderAddButton()} display="Tab">
      {renderFilter()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
