import { Box, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Timbermart } from '../../assets/logos';
import { useInternationalization } from '../../hooks';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';
import { LayoutBaseUser } from './LayoutBaseUser';

const style: Styles = {
  container: {
    pt: 0,
    alignItems: 'center',
    backgroundColor: palette.grey[100],
    overflowX: 'hidden',
  },
  header: {
    width: '100vw',
    backgroundColor: palette.primary.deep,
  },
  logo: {
    svg: {
      width: '270px',
      height: 'auto',
    },
  },
  wrapper: {
    mt: '-128px',
    p: 4,
    borderRadius: '16px',
    backgroundColor: palette.white,
    width: { xs: '100vw', md: '400px' },
    boxShadow: `0px 4px 8px ${palette.shadow}`,
    boxSizing: 'border-box',
  },
  wrapperLarge: {
    width: { xs: '100vw', md: '90vw' },
  },
  footer: {
    mt: 2,
    width: '100vw',
    backgroundColor: palette.grey[100],
  },
  copyright: {
    color: palette.grey[500],
    fontSize: 14,
    px: 3,
    pb: 3,
    textAlign: 'center',
  },
  language: {
    mr: 3,
    mt: 2,
    backgroundColor: 'transparent',
    color: palette.white,
    fontSize: 16,
    fontWeight: 600,
    mb: { xs: '150px', md: '237px' },
  },
  title: {
    fontSize: 24,
    mt: 1.5,
    mb: 4,
    lineHeight: '28px',
  },
};

interface LayoutPublicProps {
  children?: React.ReactNode;
  title?: string;
  variant?: 'regular' | 'large';
  footer?: React.ReactElement;
}

export const LayoutPublic = ({ children, title, footer, variant = 'regular' }: LayoutPublicProps) => {
  const { t, i18n, otherLanguage } = useInternationalization();

  const changeLanguage = () => {
    i18n.changeLanguage(otherLanguage);
  };

  return (
    <LayoutBaseUser sx={style.container}>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" sx={style.header}>
        <Link onClick={changeLanguage} sx={style.language}>
          {t(`common:otherLanguage.${otherLanguage}`)}
        </Link>
      </Stack>
      <Stack sx={{ ...style.wrapper, ...(variant === 'large' ? style.wrapperLarge : {}) }} spacing={2}>
        <Box sx={style.logo}>
          <Timbermart />
        </Box>
        <Box sx={style.content}>
          {title && (
            <Typography variant="h1" sx={style.title}>
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Stack>
      <Stack justifyContent="flex-end" alignItems="center" sx={style.footer}>
        {footer}
        <Typography sx={style.copyright}>{t('common:copyright', { year: new Date().getFullYear() })}</Typography>
      </Stack>
    </LayoutBaseUser>
  );
};
