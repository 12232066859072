export class VendorTierGrowthValueEntity {
  rebateValue?: number;
  from?: number;
  to?: number;
  baselineYear?: string;
  baselineVolume?: string;
  growth?: number;

  constructor(entity?: VendorTierGrowthValueEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.rebateValue = undefined;
      this.from = undefined;
      this.to = undefined;
      this.baselineYear = '';
      this.baselineVolume = '';
      this.growth = undefined;
    }
  }
}
