import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { CommitmentEventForm, CommitmentEventHeader } from '..';
import { ArchiveBanner, EditDetails } from '../..';
import { cci as cciApi } from '../../../api';
import { CommitmentEventDetailContext } from '../../../contexts';
import { useApi, useAuth, usePageTitle } from '../../../hooks';
import { CommitmentEventEntity } from '../../../models';
import { routes } from '../../../routes';
import { CommitmentEventSchema } from '../../../schemas';
import { PermissionKey } from '../../../types';
import { Container } from '../../Container';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { LinkTab } from '../../Tab';

export const CommitmentEventDetail = () => {
  const { t } = useTranslation();
  const { isInternalUser, hasPermissions } = useAuth();
  const outlet = useOutlet();
  const params = useParams();
  const eventId = Number(params.eventId);
  const { data, isLoading, refresh } = useApi(cciApi.getCommitmentEvent, null, eventId);
  const { call } = useApi(cciApi.updateCommitmentEvent, { successKey: 'common:success.save' });
  const archiveApi = useApi(cciApi.archiveCommitmentEvents, { successKey: 'common:success.action' });

  usePageTitle('cci:sections.commitmentEvent', { year: data?.year ?? '' });

  const onArchiveConfirm = async (isArchived: boolean, entity: CommitmentEventEntity) => {
    await archiveApi.call(isArchived, [entity.id]);
    refresh();
  };

  const renderActionMenuItems = (onClick: () => void) =>
    data && isInternalUser && hasPermissions(PermissionKey.CciManageEvents)
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={data.year.toString()}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('cci:events.actions.suffix')}
          />,
        ]
      : [];

  const tabs = [
    <LinkTab
      label={t('cci:sections.categories')}
      value={generatePath(routes.Admin.CommitmentEventDetails.Category.path, { eventId })}
    />,

    <LinkTab
      label={t('cci:sections.commitments')}
      value={generatePath(routes.Admin.CommitmentEventDetails.Commitments.path, { eventId })}
    />,
  ];

  return (
    <CommitmentEventDetailContext.Provider
      value={{
        event: data,
        eventId,
        readonly: data?.isArchived ?? true,
      }}
    >
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs} permissions={{ keys: PermissionKey.CciViewCommitments }}>
        <ArchiveBanner
          permissions={{ keys: PermissionKey.MemberEditMembers }}
          entity={data}
          onUnarchive={() => data && onArchiveConfirm(false, data)}
        />
        <Container>
          {isLoading && <CircularProgress size={20} />}
          {data && (
            <EditDetails
              header={<CommitmentEventHeader />}
              permissions={{ keys: PermissionKey.CciManageEvents }}
              titleLabel={t(`cci:events.form.year`)}
              title="year"
              disabled={data.isArchived}
              entity={data}
              schema={CommitmentEventSchema()}
              onSave={call}
              actionMenuItems={renderActionMenuItems}
              onConfirm={refresh}
              breadcrumbs={[
                {
                  title: t('cci:events.title'),
                  href: routes.Admin.Cci.CommitmentEvents.path,
                },
                { title: data?.year.toString() },
              ]}
            >
              <CommitmentEventForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </CommitmentEventDetailContext.Provider>
  );
};
