import { ActivityLogEntityAction, ActivityLogEntityType, ActivityLogEntityTypeGroup } from '../../types';
import { ActivityLogFieldEntity } from './ActivityLogFieldEntity';

export class ActivityLogEntity {
  id: number;
  entityTypeGroup: ActivityLogEntityTypeGroup;
  entityName_En: string;
  entityName_Fr: string;
  entityType: ActivityLogEntityType;
  fields: ActivityLogFieldEntity[];
  entityAction: ActivityLogEntityAction;
  user: string;
  actionDate: Date;

  constructor(entity?: ActivityLogEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    }
  }
}
