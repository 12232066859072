import React from 'react';
import { VendorRebateCategoryEntity } from '../../models';

interface VendorRebateCategoryProgramContextType {
  vendorId: number;
  vendorRebateCategory: VendorRebateCategoryEntity | null;
}

export const VendorRebateCategoryProgramContext = React.createContext<VendorRebateCategoryProgramContextType>(
  {} as VendorRebateCategoryProgramContextType,
);
