import { Box } from '@mui/material';
import { useContext } from 'react';
import { VendorRebateCategoryRebateValueList } from '.';
import { vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryDetailContext } from '../../../contexts/Vendor/VendorRebateCategoryDetailContext';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryRebateEntity } from '../../../models';
import { VendorRebateCategoryRebateSchema } from '../../../schemas/Vendor';
import { palette } from '../../../styles/palette';
import { PermissionKey, RebateValueType } from '../../../types';
import { EditCard } from '../../Card';
import { LayoutPage } from '../../Layout';
import { Loading } from '../../Loading';
import { VendorRebateCategoryRebateCalculationMethodForm } from './VendorRebateCategoryRebateCalculationMethodForm';
import { VendorRebateCategoryRebatePaymentTermsForm } from './VendorRebateCategoryRebatePaymentTermsForm';

export const VendorRebateCategoryRebates = () => {
  const { t } = useInternationalization();
  const { vendorRebateCategoryId, vendorId, fetchData, readOnly } = useContext(VendorRebateCategoryDetailContext);
  const {
    data: vendorRebateCategoryRebate,
    isLoading,
    refresh,
  } = useApi(vendorApi.getRebateCategoryRebate, null, vendorId, vendorRebateCategoryId);
  const { call: update } = useApi(vendorApi.updateRebateCategoryRebate, { successKey: 'common:success.save' });

  const updateRebate = async (entity: VendorRebateCategoryRebateEntity) => {
    return await update(vendorId, vendorRebateCategoryId, entity);
  };

  const onConfirm = () => {
    refresh();
    fetchData();
  };

  if (isLoading || !vendorRebateCategoryRebate) {
    return <Loading />;
  }

  return (
    <LayoutPage
      title={t('vendor:rebateCategory.rebates.title')}
      display="Tab"
      permissions={{ keys: PermissionKey.VendorViewRebates }}
    >
      <Box>
        <EditCard
          readOnly={readOnly}
          title={t('vendor:rebateCategory.rebates.sections.calculationMethod')}
          entity={vendorRebateCategoryRebate}
          schema={VendorRebateCategoryRebateSchema('calculationMethod')}
          onSave={updateRebate}
          onConfirm={onConfirm}
        >
          <VendorRebateCategoryRebateCalculationMethodForm />
        </EditCard>
        <EditCard
          readOnly={readOnly}
          title={t('vendor:rebateCategory.rebates.sections.paymentTerms')}
          entity={vendorRebateCategoryRebate}
          schema={VendorRebateCategoryRebateSchema('paymentTerms')}
          onSave={updateRebate}
          onConfirm={onConfirm}
        >
          <VendorRebateCategoryRebatePaymentTermsForm />
        </EditCard>
        <VendorRebateCategoryRebateValueList
          readOnly={readOnly}
          title={t('vendor:rebateCategory.rebates.sections.foidRebates')}
          entity={vendorRebateCategoryRebate}
          onSave={updateRebate}
          onConfirm={onConfirm}
          rebateValueType={RebateValueType.FOID}
          listProperty="foidRebates"
          borderColor={palette.secondary.blue}
        />
        <VendorRebateCategoryRebateValueList
          readOnly={readOnly}
          title={t('vendor:rebateCategory.rebates.sections.receivableRebates')}
          entity={vendorRebateCategoryRebate}
          onSave={updateRebate}
          onConfirm={onConfirm}
          rebateValueType={RebateValueType.Receivable}
          listProperty="receivableRebates"
          borderColor={palette.secondary.yellow}
        />
      </Box>
    </LayoutPage>
  );
};
