import { CheckRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PropsWithChildren, useContext, useEffect } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MemberChangeRequestContext, VendorChangeRequestContext } from '../contexts';
import { palette } from '../styles/palette';
import { ChangeRequestTrigger, Entity, Styles } from '../types';
import { Drawer } from './Drawer';

const style: Styles = {
  container: {
    minWidth: {
      xs: '100%',
      md: 700,
    },
    maxWidth: {
      xs: '100%',
      lg: '30vw',
    },
    backgroundColor: palette.white,
    borderTopLeftRadius: '12px',
    overflow: 'hidden',
    outline: 'none',
    flex: 1,
    marginTop: 1,
    spacing: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  containerLarge: {
    minWidth: {
      xs: '100%',
      lg: '90vw',
    },
    maxWidth: {
      xs: '100%',
      lg: '70vw',
    },
  },
  box: {
    px: 3,
    color: palette.white,
    alignItems: 'center',
  },
  actionBox: {
    minHeight: 78,
    backgroundColor: palette.primary.dark,
  },
  titleBox: {
    minHeight: 47,
    display: 'flex',
    px: 2,
    backgroundColor: palette.secondary.orange,
  },
  childrenContainer: {
    my: 3,
    px: 2.5,
    overflowY: 'auto',
    flex: 1,
  },
  paperDrawer: {
    backgroundColor: 'transparent',
    width: {
      xs: '100%',
      md: 'inherit',
    },
  },
};

interface EditDrawerChangeRequestProps<T extends Entity> extends PropsWithChildren {
  entity: T;
  open: boolean;
  handleSubmit: UseFormHandleSubmit<T>;
  reset: (entity?: T | undefined) => void;
  handleError: (error: unknown) => void;
  isSubmitDisabled: boolean;
  onSave: (entity: T, trigger: ChangeRequestTrigger) => Promise<T | null>;
  size?: 'medium' | 'large';
}

export const EditDrawerChangeRequest = <T extends Entity>({
  entity,
  handleSubmit,
  reset,
  handleError,
  isSubmitDisabled,
  open,
  onSave,
  children,
  size = 'medium',
}: EditDrawerChangeRequestProps<T>) => {
  const { t } = useTranslation();
  const vendorContext = useContext(VendorChangeRequestContext);
  const memberContext = useContext(MemberChangeRequestContext);
  const { changeRequest, onCancel, onConfirm } = vendorContext.changeRequest ? vendorContext : memberContext;

  useEffect(() => {
    if (!open) {
      reset(entity);
    }
  }, [open, reset, entity]);

  const onSubmitSuccess = (trigger: ChangeRequestTrigger) => async (data: T) => {
    try {
      const saveResult = await onSave(data, trigger);
      if (saveResult) {
        reset(entity);
        return saveResult;
      }
    } catch (error) {
      handleError(error);
    } finally {
      onConfirm();
    }
  };

  const onDrawerClose = () => {
    reset(entity);
    onCancel();
  };

  const renderEntityType = () =>
    t(`changeRequest:${vendorContext.changeRequest ? 'vendor' : 'member'}EntityType.${changeRequest.entityType}`);

  return (
    <Drawer PaperProps={{ sx: style.paperDrawer }} anchor="right" open={open} onClose={onDrawerClose}>
      <Box sx={{ ...style.container, ...(size === 'large' ? style.containerLarge : {}) }} component="form">
        <Stack
          sx={[style.box, style.titleBox]}
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <CheckRounded />
          <Typography variant="drawerTitle">
            {t('changeRequest:drawer.title', {
              type: t(`changeRequest:type.${changeRequest.type}`),
              entityType: renderEntityType(),
            })}
          </Typography>
        </Stack>
        <Box sx={style.childrenContainer}>{children}</Box>
        <Stack spacing={3} direction="row" sx={[style.box, style.actionBox]} justifyContent="space-between">
          <Button onClick={onCancel} variant="outlined" color="secondary" size="small">
            {t('common:cancel')}
          </Button>
          <Stack spacing={3} direction="row">
            <Button
              variant="contained"
              size="small"
              onClick={() => onSubmitSuccess(ChangeRequestTrigger.Reject)(entity)}
            >
              {t('common:changeRequest.decline')}
            </Button>
            <Button
              disabled={isSubmitDisabled}
              variant="contained"
              size="small"
              onClick={handleSubmit(onSubmitSuccess(ChangeRequestTrigger.Approve))}
            >
              {t('common:changeRequest.approve')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};
