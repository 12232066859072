import { CircularProgress } from '@mui/material';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { EntityDetails } from '..';
import { vendor as vendorApi } from '../../api';
import { BuyGuideVendorRebateProgramDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorRebateProgramDetailContext';
import { useApi, useInternationalization, usePageTitle } from '../../hooks';
import { routes } from '../../routes';
import { PermissionKey, Styles } from '../../types';
import { Container } from '../Container';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';
import { BuyGuideVendorRebateProgramForm } from './BuyGuideVendorRebateProgramForm';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const BuyGuideVendorRebateProgramDetail = () => {
  const { t, getTranslation, getTranslatablePropertyKey } = useInternationalization();
  const outlet = useOutlet();
  const params = useParams();
  const vendorId = Number(params.vendorId);
  const { data: vendor } = useApi(vendorApi.get, null, vendorId);
  const rebateProgramId = Number(params.rebateProgramId);
  const { data, isLoading } = useApi(vendorApi.getRebateProgram, null, vendorId, rebateProgramId);

  usePageTitle(data ? getTranslation(data, 'name') : '');

  const tabs = [
    <LinkTab
      label={t('buyGuide:rebatePrograms.sections.rebates')}
      value={generatePath(routes.Member.BuyGuideRebateProgramDetail.Rebates.path, { vendorId, rebateProgramId })}
      permissions={{ keys: PermissionKey.VendorViewPrograms }}
    />,
    <LinkTab
      label={t('buyGuide:rebatePrograms.sections.tiersAndGrowths')}
      value={generatePath(routes.Member.BuyGuideRebateProgramDetail.TiersAndGrowths.path, {
        vendorId,
        rebateProgramId,
      })}
      permissions={{ keys: PermissionKey.VendorViewRebateTiersGrowths }}
    />,
    <LinkTab
      label={t('buyGuide:rebatePrograms.sections.documents')}
      value={generatePath(routes.Member.BuyGuideRebateProgramDetail.Documents.path, { vendorId, rebateProgramId })}
      permissions={{ keys: PermissionKey.VendorViewFiles }}
    />,
  ];

  return (
    <BuyGuideVendorRebateProgramDetailContext.Provider value={{ rebateProgramId, vendorId, rebateProgram: data }}>
      <LayoutPage outlet={outlet} display="Detail" tabs={tabs} permissions={{ keys: PermissionKey.VendorViewPrograms }}>
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EntityDetails
              titleLabel={t(`buyGuide:rebatePrograms.form.rebateProgramName`)}
              title={getTranslatablePropertyKey(data, 'name')}
              entity={data}
              breadcrumbs={[
                {
                  title: t('buyGuide:title'),
                  href: routes.Member.BuyGuide.path,
                },
                {
                  title: vendor?.name,
                  href: generatePath(routes.Member.BuyGuideDetail.RebatePrograms.path, { vendorId }),
                },
                { title: data ? getTranslation(data, 'name') : undefined },
              ]}
            >
              <BuyGuideVendorRebateProgramForm />
            </EntityDetails>
          )}
        </Container>
      </LayoutPage>
    </BuyGuideVendorRebateProgramDetailContext.Provider>
  );
};
