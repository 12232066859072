import axios from 'axios';
import { MemberCommitmentReportDetailEntity } from '../../models';
import { MemberCommitmentReportFilter, PaginationEntity } from '../../types';

export const MemberCommitmentReport = {
  getAllMemberCommitmentReportDetails: async (
    memberCommitmentsFilter: MemberCommitmentReportFilter,
  ): Promise<PaginationEntity<MemberCommitmentReportDetailEntity>> => {
    const { data } = await axios.post(`api/memberCommitmentReport/search`, memberCommitmentsFilter);
    return {
      ...data,
      data: data.data.map((d: MemberCommitmentReportDetailEntity) => new MemberCommitmentReportDetailEntity(d)),
    };
  },
};
