import axios from 'axios';
import { MemberCommitmentDocumentEntity } from '../../models';
import { PaginationEntity, MemberCommitmentDocumentFilter } from '../../types';

export const commitmentDocument = {
  getAllDocuments: async (
    documentFilter: MemberCommitmentDocumentFilter,
  ): Promise<PaginationEntity<MemberCommitmentDocumentEntity>> => {
    const { data } = await axios.post(`api/member/commitmentdocuments/search`, documentFilter);
    return {
      ...data,
      data: data.data.map((v: MemberCommitmentDocumentEntity) => new MemberCommitmentDocumentEntity(v)),
    };
  },
  getDocumentFile: async (memberCommitmentDocumentId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/member/commitmentdocuments/${memberCommitmentDocumentId}/file`, {
      responseType: 'blob',
    });
    return data;
  },
  getCurrentDocuments: async (
    documentFilter: MemberCommitmentDocumentFilter,
  ): Promise<PaginationEntity<MemberCommitmentDocumentEntity>> => {
    const { data } = await axios.post(`api/member/commitmentdocuments/search`, documentFilter);
    return {
      ...data,
      data: data.data.map((v: MemberCommitmentDocumentEntity) => new MemberCommitmentDocumentEntity(v)),
    };
  },
};
