import { Box, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { cci as cciApi } from '../../../api';
import { MAX_LONG_TEXT_LENGTH, MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useAuth, useEntityFormContext, useInternationalization } from '../../../hooks';
import { CommitmentEventEntity } from '../../../models';
import { CommitmentEventFilter, SelectOption, Styles } from '../../../types';
import { EntityPeriod } from '../../EntityPeriod';
import {
  Checkbox,
  ControlledCheckbox,
  ControlledInput,
  ControlledSelect,
  ControlledTranslatedInput,
  StaticField,
} from '../../Form';
import { Loading } from '../../Loading';
import { PeriodForm } from '../../PeriodForm';
import { CommitmentEventHeader } from './CommitmentEventHeader';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
};

const defaultFilter: CommitmentEventFilter = {
  isArchived: false,
  ...MAX_PAGINATION_FILTER,
};

export const CommitmentEventForm = () => {
  const { t } = useInternationalization();
  const { isInternalUser, isMemberUser } = useAuth();
  const { control, variant, setValue, readOnly, watch } = useEntityFormContext<CommitmentEventEntity>();
  const [startFromPreviousYear, setStartFromPreviousYear] = useState(false);
  const { data, isLoading } = useApi(cciApi.getAllCommitmentEvents, { skipFetch: !isInternalUser }, defaultFilter);
  const period = watch('period');

  const previousYearOptions: SelectOption<number>[] | undefined = useMemo(() => {
    return data?.data.map((e) => ({ label: e.year.toString(), value: e.id })) ?? [];
  }, [data]);

  useEffect(() => {
    setStartFromPreviousYear(previousYearOptions.length > 0);
  }, [previousYearOptions, setValue]);

  useEffect(() => {
    setValue(
      'previousCommitmentEventId',
      startFromPreviousYear && previousYearOptions.length > 0 ? previousYearOptions[0].value : null,
    );
  }, [startFromPreviousYear, previousYearOptions, setValue]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={style.container}>
      {variant == 'drawer' && <CommitmentEventHeader />}
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        {variant == 'drawer' && (
          <>
            <Grid item xs={12}>
              <Checkbox
                label={t('cci:events.form.startFromPreviousYear')}
                checked={startFromPreviousYear}
                onChange={(_, checked) => setStartFromPreviousYear(checked)}
                disabled={!data || previousYearOptions.length === 0}
              />
            </Grid>
            {startFromPreviousYear && previousYearOptions.length > 0 && (
              <>
                <Grid item xs={12} md={3}>
                  <ControlledSelect
                    label={t('cci:events.form.previousYear')}
                    name="previousCommitmentEventId"
                    control={control}
                    options={previousYearOptions}
                    required
                  />
                </Grid>
                <Grid item xs={0} md={9} />
              </>
            )}
          </>
        )}
        {readOnly && (
          <Grid item xs={4} lg={3} xl={2}>
            <StaticField
              label={t('cci:events.table.period')}
              rawRender
              value={<EntityPeriod period={period} translationContext="male" />}
            />
          </Grid>
        )}
        <PeriodForm
          xs={4}
          effectiveDateLabel={t('cci:events.form.effectiveDate')}
          expiryDateLabel={t('cci:events.form.expiryDate')}
          lg={variant == 'drawer' ? 6 : 3}
          xl={variant == 'drawer' ? 6 : 2}
        />
        {isInternalUser && (
          <Grid item xs={12}>
            <ControlledCheckbox
              label={t('cci:events.form.isVisibleToVendor')}
              name="isVisibleToVendor"
              control={control}
            />
          </Grid>
        )}
        <Grid item xs={variant === 'drawer' || isMemberUser ? 12 : 6}>
          <ControlledTranslatedInput
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
            multiline
            label={isMemberUser ? t('cci:events.form.notes') : t('cci:events.form.memberNotes')}
            name="memberNotes"
            control={control}
          />
        </Grid>
        {isInternalUser && (
          <Grid item xs={variant === 'drawer' ? 12 : 6}>
            <ControlledInput
              label={t('cci:events.form.internalNotes')}
              name="notes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
