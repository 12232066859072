import axios from 'axios';
import { MemberCommitmentEntity } from '../../models';
import { ExportType, MemberCommitmentReportGrouping, MemberCommitmentsFilter, PaginationEntity } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const MemberCommitment = {
  getAllMemberCommitments: async (
    eventId: number,
    memberCommitmentsFilter: MemberCommitmentsFilter,
  ): Promise<PaginationEntity<MemberCommitmentEntity>> => {
    const { data } = await axios.post(
      `api/commitmentEvent/${eventId}/memberCommitments/search`,
      memberCommitmentsFilter,
    );
    return { ...data, data: data.data.map((d: MemberCommitmentEntity) => new MemberCommitmentEntity(d)) };
  },
  getMemberCommitment: async (eventId: number, memberCommitmentId: number): Promise<MemberCommitmentEntity> => {
    const { data } = await axios.get(`api/commitmentEvent/${eventId}/memberCommitments/${memberCommitmentId}`);
    return new MemberCommitmentEntity(data);
  },
  createMemberCommitment: async (
    commitmentEventId: number,
    entity: MemberCommitmentEntity,
  ): Promise<MemberCommitmentEntity> => {
    const { data } = await axios.post(`api/commitmentEvent/${commitmentEventId}/memberCommitments`, entity);
    return new MemberCommitmentEntity(data);
  },
  updateMemberCommitment: async (
    commitmentEventId: number,
    entity: MemberCommitmentEntity,
  ): Promise<MemberCommitmentEntity> => {
    const { data } = await axios.put(`api/commitmentEvent/${commitmentEventId}/memberCommitments/${entity.id}`, entity);
    return new MemberCommitmentEntity(data);
  },
  exportMemberCommitment: async (
    commitmentEventId: number,
    groupBy: MemberCommitmentReportGrouping,
    exportType: ExportType,
  ): Promise<{ data: Blob; filename?: string }> => {
    let viewBy = '';
    switch (groupBy) {
      case MemberCommitmentReportGrouping.Category: {
        viewBy = '/categories';
        break;
      }
      case MemberCommitmentReportGrouping.Member: {
        viewBy = '/members';
        break;
      }
      case MemberCommitmentReportGrouping.Vendor: {
        viewBy = '/vendors';
        break;
      }
    }
    const result = await axios.get(
      `api/commitmentEvent/${commitmentEventId}/memberCommitments${viewBy}/export?type=${exportType}`,
      {
        responseType: 'blob',
      },
    );
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  exportMemberCommitments: async (exportType: ExportType): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/commitmentEvent/memberCommitments/export?type=${exportType}`, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
