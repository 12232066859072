import {
  AccountBalanceRounded,
  BadgeRounded,
  BeenhereRounded,
  FactCheckRounded,
  HomeRounded,
  LocationOnRounded,
  NewspaperRounded,
  RequestQuoteRounded,
  TourRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Navigate, useOutlet } from 'react-router-dom';
import { TimbermartLogo } from '../../assets/logos/TimbermartLogo';
import { routes } from '../../routes';
import { PermissionKey } from '../../types';
import { NavigationMenu, NavigationMenuItem, NavigationScrollTop } from '../Navigation';
import { LayoutBaseUser } from './LayoutBaseUser';

export const LayoutVendorUser = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();

  return (
    <LayoutBaseUser>
      <NavigationScrollTop />
      <NavigationMenu>
        <NavigationMenuItem
          title={t('navigation:sections.home')}
          icon={<HomeRounded />}
          href={routes.Vendor.Dashboard.path}
        />
        <NavigationMenuItem
          title={t('navigation:sections.vbas')}
          icon={<FactCheckRounded />}
          href={routes.Vendor.BuyingAgreements.path}
          permissions={{ keys: PermissionKey.VendorViewVBAs }}
        />
        <NavigationMenuItem
          title={t('navigation:sections.confidentialCommitmentIncentives')}
          icon={<TourRounded />}
          href={routes.Vendor.Cci.path}
          matchPath="vendor/cci/*"
          permissions={{ keys: PermissionKey.CciViewCommitments }}          
        />        
        <NavigationMenuItem
          title={t('navigation:sections.priceListDocuments')}
          icon={<RequestQuoteRounded />}
          href={routes.Vendor.Documents.path}
          permissions={{ keys: PermissionKey.VendorViewFiles }}
        />
        <NavigationMenuItem
          title={t('navigation:sections.financial')}
          icon={<AccountBalanceRounded />}
          href={routes.Vendor.Financials.path}
          permissions={{ keys: PermissionKey.VendorViewFinancial }}
        />
        <NavigationMenuItem
          title={t('navigation:sections.contacts')}
          icon={<BadgeRounded />}
          href={routes.Vendor.Contacts.path}
          permissions={{ keys: PermissionKey.VendorViewContacts }}
        />
        <NavigationMenuItem
          title={t('navigation:sections.locations')}
          icon={<LocationOnRounded />}
          href={routes.Vendor.Locations.path}
          permissions={{ keys: PermissionKey.VendorViewContacts }}
        />
        <NavigationMenuItem
          title={t('navigation:sections.tbmContacts')}
          icon={<TimbermartLogo />}
          href={routes.Vendor.TBMContacts.path}
          matchPath="vendor/tbm-contacts/*"
        />
        <NavigationMenuItem
          title={t('navigation:sections.members')}
          icon={<BeenhereRounded />}
          href={routes.Vendor.Member.path}
          matchPath={[`${routes.Vendor.Member.path}/*`]}
          permissions={{ keys: PermissionKey.MemberViewMembers }}
        />
        <NavigationMenuItem
          key="contentAndPublications"
          title={t('navigation:sections.contentManager')}
          icon={<NewspaperRounded />}
          href={routes.Vendor.Cms.path}
          matchPath={[`${routes.Vendor.Cms.path}/*`]}
          permissions={{ keys: PermissionKey.CmsNewsView }}
        />
      </NavigationMenu>
      {outlet ?? <Navigate to={routes.Vendor.Dashboard.path} />}
    </LayoutBaseUser>
  );
};
