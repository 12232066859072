import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ForestProductPriceUpdateHeader = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h1">{t('forestProduct:priceUpdates.detailTitle')}</Typography>
      </Grid>
    </Grid>
  );
};
