import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { ControlledInput } from '../components/Form';
import { LayoutPublic } from '../components/Layout';
import { useAuth, useInternationalization, usePageTitle } from '../hooks';
import { Styles, PhoneNumberInitializationRequest } from '../types';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { phoneNumberFormatter } from '../utils/formatters';

const style: Styles = {
  button: {
    width: '100%',
  },
};

export function SetVerificationPhoneNumber() {
  const { t } = useInternationalization();
  usePageTitle('auth:setVerificationPhoneNumber.title');
  const [errorKey, setErrorKey] = useState('');
  const navigate = useNavigate();
  const { refresh, setVerificationPhoneNumber, logout, user } = useAuth();

  const validationLogin = Yup.object({
    phoneNumber: Yup.string()
      .required(t('common:error.fieldRequired', { name: t('auth:setVerificationPhoneNumber.code') }))
      .matches(/^\d{10}$/, 'common:error.fieldInvalid'),
  });

  const { handleSubmit, control, formState } = useForm<PhoneNumberInitializationRequest>({
    defaultValues: {
      phoneNumber: '',
    },
    resolver: yupResolver(validationLogin),
  });

  const onSubmit = async (verificationPhoneNumber: PhoneNumberInitializationRequest) => {
    setErrorKey('');
    const result = await setVerificationPhoneNumber(verificationPhoneNumber);
    if (result) {
      navigate(routes.VerifyPhoneNumber.path);
      refresh();
    } else {
      setErrorKey(t('common:error.generic'));
    }
  };

  const onCancel = async () => {
    if (user) {
      await logout(user);
    }
  };

  return (
    <LayoutPublic title={t('auth:setVerificationPhoneNumber.title')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Typography>{t('auth:setVerificationPhoneNumber.info')}</Typography>
          <ControlledInput
            label={t('auth:setVerificationPhoneNumber.phoneNumber')}
            name="phoneNumber"
            formatter={phoneNumberFormatter}
            control={control}
          />
          <LoadingButton
            disabled={!formState.isValid}
            sx={style.button}
            loading={formState.isSubmitting}
            type="submit"
            variant="contained"
          >
            {t('auth:setVerificationPhoneNumber.action')}
          </LoadingButton>
          <Button onClick={onCancel}>{t('auth:setVerificationPhoneNumber.cancel')}</Button>
          {!!errorKey && <Typography variant="inputError">{t(errorKey)}</Typography>}
        </Stack>
      </form>
    </LayoutPublic>
  );
}
