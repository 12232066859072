import axios from 'axios';
import {
  VendorEntityChangeRequestEntity,
  VendorInvoicingInformationEntity,
  VendorPaymentScheduleEntity,
  VendorTaxInformationEntity,
} from '../../models';
import { ChangeRequestTrigger } from '../../types';

export const financial = {
  getTaxInformation: async (vendorId: number): Promise<VendorTaxInformationEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/financials/taxInformation`);
    return new VendorTaxInformationEntity({ ...data, vendorId });
  },
  updateTaxInformation: async (entity: VendorTaxInformationEntity): Promise<VendorTaxInformationEntity> => {
    const { data } = await axios.put(`api/vendor/${entity.vendorId}/financials/taxInformation`, entity);
    return new VendorTaxInformationEntity({ ...data, vendorId: entity.vendorId });
  },
  getInvoicingInformation: async (vendorId: number): Promise<VendorInvoicingInformationEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/financials/invoicingInformation`);
    return new VendorInvoicingInformationEntity({ ...data, vendorId });
  },
  updateInvoicingInformation: async (
    entity: VendorInvoicingInformationEntity,
  ): Promise<VendorInvoicingInformationEntity> => {
    const { data } = await axios.put(`api/vendor/${entity.vendorId}/financials/invoicingInformation`, entity);
    return new VendorInvoicingInformationEntity({ ...data, vendorId: entity.vendorId });
  },
  getPaymentSchedule: async (vendorId: number): Promise<VendorPaymentScheduleEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/financials/paymentSchedule`);
    return new VendorPaymentScheduleEntity({ ...data, vendorId });
  },
  updatePaymentSchedule: async (entity: VendorPaymentScheduleEntity): Promise<VendorPaymentScheduleEntity> => {
    const { data } = await axios.put(`api/vendor/${entity.vendorId}/financials/paymentSchedule`, entity);
    return new VendorPaymentScheduleEntity({ ...data, vendorId: entity.vendorId });
  },
  getCurrentTaxInformation: async (): Promise<VendorTaxInformationEntity> => {
    const { data } = await axios.get(`api/vendor/financials/taxInformation`);
    return new VendorTaxInformationEntity(data);
  },
  updateTaxInformationChangeRequest: async (
    entity: VendorTaxInformationEntity,
  ): Promise<VendorTaxInformationEntity> => {
    const { data } = await axios.put(`api/vendor/financials/taxInformation`, entity);
    return new VendorTaxInformationEntity(data);
  },
  getCurrentInvoicingInformation: async (): Promise<VendorInvoicingInformationEntity> => {
    const { data } = await axios.get(`api/vendor/financials/invoicingInformation`);
    return new VendorInvoicingInformationEntity(data);
  },
  updateInvoicingInformationChangeRequest: async (
    entity: VendorInvoicingInformationEntity,
  ): Promise<VendorInvoicingInformationEntity> => {
    const { data } = await axios.put(`api/vendor/financials/invoicingInformation`, entity);
    return new VendorInvoicingInformationEntity(data);
  },
  getCurrentPaymentSchedule: async (): Promise<VendorPaymentScheduleEntity> => {
    const { data } = await axios.get(`api/vendor/financials/paymentSchedule`);
    return new VendorPaymentScheduleEntity(data);
  },
  updatePaymentScheduleChangeRequest: async (
    entity: VendorPaymentScheduleEntity,
  ): Promise<VendorPaymentScheduleEntity> => {
    const { data } = await axios.put(`api/vendor/financials/paymentSchedule`, entity);
    return new VendorPaymentScheduleEntity(data);
  },
  approvedOrRejectTaxInformationChangeRequest: async (
    id: number,
    trigger: ChangeRequestTrigger,
    entity: VendorTaxInformationEntity,
  ): Promise<VendorTaxInformationEntity> => {
    const { data } = await axios.post(`api/vendorChangeRequests/financials/taxInformation/${id}/${trigger}`, entity);
    return new VendorTaxInformationEntity(data);
  },
  getTaxInformationChangeRequest: async (
    id: number,
  ): Promise<VendorEntityChangeRequestEntity<VendorTaxInformationEntity>> => {
    const { data } = await axios.get(`api/vendorChangeRequests/financials/taxInformation/${id}`);
    return new VendorEntityChangeRequestEntity(data);
  },
  approvedOrRejectPaymentScheduleChangeRequest: async (
    id: number,
    trigger: ChangeRequestTrigger,
    entity: VendorPaymentScheduleEntity,
  ): Promise<VendorPaymentScheduleEntity> => {
    const { data } = await axios.post(`api/vendorChangeRequests/financials/paymentSchedule/${id}/${trigger}`, entity);
    return new VendorPaymentScheduleEntity(data);
  },
  getPaymentScheduleChangeRequest: async (
    id: number,
  ): Promise<VendorEntityChangeRequestEntity<VendorPaymentScheduleEntity>> => {
    const { data } = await axios.get(`api/vendorChangeRequests/financials/paymentSchedule/${id}`);
    return new VendorEntityChangeRequestEntity(data);
  },
  approvedOrRejectInvoicingInformationChangeRequest: async (
    id: number,
    trigger: ChangeRequestTrigger,
    entity: VendorInvoicingInformationEntity,
  ): Promise<VendorInvoicingInformationEntity> => {
    const { data } = await axios.post(
      `api/vendorChangeRequests/financials/invoicingInformation/${id}/${trigger}`,
      entity,
    );
    return new VendorInvoicingInformationEntity(data);
  },
  getInvoicingInformationChangeRequest: async (
    id: number,
  ): Promise<VendorEntityChangeRequestEntity<VendorInvoicingInformationEntity>> => {
    const { data } = await axios.get(`api/vendorChangeRequests/financials/invoicingInformation/${id}`);
    return new VendorEntityChangeRequestEntity(data);
  },
};
