import { VisibilityRounded } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { AddressForm, EmailAddressListForm, PhoneNumberListForm } from '..';
import { categorization as categorizationApi, contact as contactApi } from '../../../api';
import { MAX_LONG_TEXT_LENGTH, MAX_PAGINATION_FILTER, MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useApi, useAuth, useEntityFormContext, useInternationalization } from '../../../hooks';
import { InternalContactEntity, OfficeEntity } from '../../../models';
import { OfficeFilter, Styles } from '../../../types';
import {
  ControlledCheckbox,
  ControlledEntitySelect,
  ControlledInput,
  ControlledMultiSelect,
  ControlledTranslatedInput,
} from '../../Form';
import { Loading } from '../../Loading';

const style: Styles = {
  container: {
    pb: 4,
  },
};

const officeFilter: OfficeFilter = {
  isArchived: false,
  ...MAX_PAGINATION_FILTER,
};

export const InternalContactForm = () => {
  const { t, getTranslation } = useInternationalization();
  const { isInternalUser, isMemberUser, isVendorUser } = useAuth();
  const { data: boards, isLoading: isLoadingBoards } = useApi(categorizationApi.getAll, null, 'board', false);
  const { data: departments, isLoading: isLoadingDepartments } = useApi(
    categorizationApi.getAll,
    null,
    'department',
    false,
  );
  const { control, watch } = useEntityFormContext<InternalContactEntity>();
  const editing = !!watch('id');

  return isLoadingBoards || isLoadingDepartments ? (
    <Loading />
  ) : (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        {!editing && (
          <>
            <Grid item xs={6}>
              <ControlledInput
                label={t('contact:internalContact.form.firstName')}
                name="firstName"
                control={control}
                maxLength={MAX_SHORT_TEXT_LENGTH}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledInput
                label={t('contact:internalContact.form.lastName')}
                name="lastName"
                control={control}
                maxLength={MAX_SHORT_TEXT_LENGTH}
                required
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('contact:internalContact.form.role')}
            name="role"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        {isInternalUser && (
          <>
            <Grid item xs={6}>
              <ControlledCheckbox
                label={t('contact:internalContact.form.isVisibleToMember')}
                icon={VisibilityRounded}
                name="isVisibleToMember"
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledCheckbox
                label={t('contact:internalContact.form.isVisibleToVendor')}
                icon={VisibilityRounded}
                name="isVisibleToVendor"
                control={control}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ControlledEntitySelect
            label={t('contact:internalContact.form.officeId')}
            name="office"
            apiFunction={contactApi.getAllOffices}
            apiParams={[officeFilter]}
            getOptionLabel={(entity: OfficeEntity) => getTranslation(entity, 'description')}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledMultiSelect
            label={t('contact:internalContact.form.boardIds')}
            name="boardIds"
            options={boards?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
            control={control}
            mode="autocomplete"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledMultiSelect
            label={t('contact:internalContact.form.departmentIds')}
            name="departmentIds"
            options={departments?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
            control={control}
            mode="autocomplete"
          />
        </Grid>
        <AddressForm excludeFields={['country']} />
        <Grid item xs={12}>
          <Typography variant="h3">{t('contact:internalContact.form.generalContacts')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <EmailAddressListForm />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberListForm />
        </Grid>
        {isInternalUser && (
          <>
            <Grid item xs={12}>
              <Typography variant="drawerContentSubtitle">{t('contact:internalContact.form.notes')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ControlledInput
                label={t('contact:internalContact.form.internalNotes')}
                name="notes"
                control={control}
                multiline
                rows={3}
                maxLength={MAX_LONG_TEXT_LENGTH}
              />
            </Grid>
          </>
        )}
        {(isInternalUser || isMemberUser) && (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              label={
                isInternalUser ? t('contact:internalContact.form.memberNotes') : t('contact:internalContact.form.notes')
              }
              name="memberNotes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        )}
        {(isInternalUser || isVendorUser) && (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              label={
                isInternalUser ? t('contact:internalContact.form.vendorNotes') : t('contact:internalContact.form.notes')
              }
              name="vendorNotes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
