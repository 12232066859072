import Typography from '@mui/material/Typography';
import { useFieldArray } from 'react-hook-form';
import { memberGroup as memberGroupApi } from '../../api';
import { useEntityFormContext, useInternationalization } from '../../hooks';
import { MemberGroupEntity, TargetedMembershipMemberGroupEntity } from '../../models';
import { Styles, TargetedEntity } from '../../types';
import { MemberPermissionsList } from '../MemberPermissions';

const style: Styles = {
  header: {
    py: 3,
  },
};

interface TargetedMembershipMemberGroupFormProps {
  hideTitle?: boolean;
  fullWidth?: boolean;
}

export const TargetedMembershipMemberGroupForm = ({ hideTitle, fullWidth }: TargetedMembershipMemberGroupFormProps) => {
  const { t } = useInternationalization();
  const { control, readOnly } = useEntityFormContext<TargetedEntity>();

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'targetedMembership.memberGroups',
  });

  const onAddPermission = ({ id, name }: MemberGroupEntity) => {
    append({ ...new TargetedMembershipMemberGroupEntity(), memberGroupId: id, memberGroupName: name });
  };

  return (
    <>
      {!hideTitle && (
        <Typography variant="h3" sx={style.header}>
          {t('vendor:targetedMembership.form.memberGroupTitle')}
        </Typography>
      )}
      <MemberPermissionsList
        permissions={fields}
        isIncludedProperty="isAllowed"
        apiCall={memberGroupApi.getSuggestions}
        renderKeyValue={(p: TargetedMembershipMemberGroupEntity) => ({
          label: p.memberGroupName ?? '',
          value: p.memberGroupId,
        })}
        renderOptionLabel={(item: MemberGroupEntity) => item.name}
        nameColumn={t('vendor:targetedMembership.form.memberGroup')}
        isIncludedColumn={t('vendor:targetedMembership.form.permission')}
        onUpdate={update}
        onAdd={onAddPermission}
        readOnly={readOnly}
        onRemove={remove}
        fullWidth={fullWidth}
      />
    </>
  );
};
