import {
  CheckRounded,
  DeleteRounded,
  EditOffRounded,
  ExpandMore,
  InfoOutlined,
  MoreVertRounded,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { MouseEvent, useContext, useMemo, useState } from 'react';
import { cci as cciApi } from '../../../api';
import { CommitmentEventDetailContext, EntityFormProvider } from '../../../contexts';
import { useApi, useEntityForm, useInternationalization } from '../../../hooks';
import { CommitmentEventCategoryEntity, MemberCommitmentEntity } from '../../../models';
import { MemberCommitmentSchema } from '../../../schemas';
import { palette } from '../../../styles/palette';
import theme from '../../../styles/theme';
import { FilterPeriod, LanguageCode, RebateTypeAndUnitType, Styles } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { formatShortDate } from '../../../utils/helper';
import { Checkbox, StaticField } from '../../Form';
import { MenuItem } from '../../Menu';
import { MemberCommitmentForm } from './MemberCommitmentForm';
import { DOLLAR_NO_DECIMAL } from '../../../constants';

const styles: Styles = {
  accordion: {
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '16px !important',
    border: 0,
    ':before': {
      display: 'none',
    },
    minHeight: 72,
    boxSizing: 'border-box',
  },
  archived: {
    background: palette.grey[50],
    '*': {
      color: palette.grey[400],
    },
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
  },
  commitmentRequiredTag: {
    color: palette.secondary.red,
    background: 'rgba(171, 18, 48, 0.15)',
    px: 1,
    borderRadius: 1,
    fontWeight: 600,
  },
  menu: {
    '& li': {
      whiteSpace: 'nowrap',
    },
  },
  summaryContent: {
    flex: 1,
  },
  submitFormBox: {
    '.MuiFormControlLabel-root': {
      flex: 1,
    },
    '.MuiTypography-label': {
      fontWeight: 600,
      color: palette.grey[600],
    },
  },
};

interface MemberCommitmentDetailProps {
  memberCommitment: MemberCommitmentEntity;
  onDelete?: (memberCommitment: MemberCommitmentEntity) => void;
  onConfirm?: () => void;
  onEditClick?: (category: CommitmentEventCategoryEntity) => void;
}

export const MemberCommitmentDetail = ({ memberCommitment, onConfirm, onDelete }: MemberCommitmentDetailProps) => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { eventId, event } = useContext(CommitmentEventDetailContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [editing, setEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const originalMemberCommitment = useMemo(
    () =>
      ({
        ...memberCommitment,
        vendors: memberCommitment.vendors.map((x) => ({ ...x })),
      } as MemberCommitmentEntity),
    [memberCommitment],
  );
  const [acceptedAgreement, setAcceptedAgreement] = useState(false);
  const alreadyCommitted = !!memberCommitment.id;
  const readOnly = !editing && alreadyCommitted;
  const canEdit = event?.period == FilterPeriod.Current;
  const [expanded, setExpanded] = useState(!readOnly);
  const { form, handleSubmit, isSubmitDisabled, reset } = useEntityForm(
    originalMemberCommitment,
    MemberCommitmentSchema(),
    {
      readOnly,
    },
  );
  const { call: create } = useApi(cciApi.createMemberCommitment, { successKey: 'common:success.action' });
  const { call: update } = useApi(cciApi.updateMemberCommitment, { successKey: 'common:success.action' });

  const moneyFormatter = useMemo(
    () =>
      numberFormatter(
        currentLanguage,
        t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Dollar}`),
        DOLLAR_NO_DECIMAL,
        currentLanguage !== LanguageCode.en,
      ),
    [currentLanguage, t],
  );

  const onSubmitSuccess = async (data: MemberCommitmentEntity) => {
    if (!isSaving) {
      try {
        setIsSaving(true);
        const saveResult = alreadyCommitted ? await update(eventId, data) : await create(eventId, data);
        if (saveResult) {
          setEditing(false);
          onConfirm && onConfirm();
        }
      } catch (error) {
        //handleError(error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setEditing(false);
    reset(originalMemberCommitment);
  };

  const handleDelete = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete(memberCommitment);
    }
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onAccordionChange = () => {
    setExpanded((prev) => !prev);
  };

  const onCheckboxChange = () => {
    setAcceptedAgreement((prev) => !prev);
  };

  const renderActionMenuItems = () => [
    <MenuItem
      id="edit"
      key="edit"
      onClick={() => {
        handleMenuClose();
        setExpanded(true);
        setEditing(true);
      }}
    >
      {t('common:edit')}
    </MenuItem>,
  ];

  return (
    <>
      <Accordion sx={styles.accordion} expanded={expanded} onChange={onAccordionChange}>
        <AccordionSummary expandIcon={<ExpandMore htmlColor={palette.primary.deep} />} sx={styles.accordionSummary}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={styles.summaryContent}>
            <Stack alignItems="center" direction="row" spacing={1} flex={1}>
              <Typography variant="h3" display="flex">
                {getTranslation(memberCommitment.commitmentEventCategory, 'name')}
              </Typography>
              {alreadyCommitted ? (
                <CheckRounded htmlColor={palette.grey[500]} />
              ) : (
                <Typography ml={1} sx={styles.commitmentRequiredTag}>
                  {t('cci:eventCategories.card.commitmentRequiredTag')}
                </Typography>
              )}
            </Stack>
            {!expanded && alreadyCommitted && (
              <StaticField
                label={t('cci:memberCommitments.form.willSupport')}
                value={t('cci:memberCommitments.vendorCount', {
                  count: memberCommitment.vendors.filter((v) => v.willSupport).length,
                })}
              />
            )}
            {!expanded && alreadyCommitted && (
              <StaticField
                label={t('cci:memberCommitments.form.volume')}
                value={moneyFormatter(memberCommitment.volume ?? '').formatted.join('')}
              />
            )}
            <Stack alignItems="center" direction="row" spacing={1}>
              {expanded && alreadyCommitted && (
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography variant="modifiedDate">
                    {t('cci:memberCommitments.modifiedDate', { date: formatShortDate(memberCommitment.modifiedDate) })}
                  </Typography>
                  <Tooltip
                    title={
                      <>
                        <Typography variant="tooltip">
                          {t('cci:memberCommitments.modifiedDateTooltip', {
                            date: formatShortDate(memberCommitment.createdDate),
                            name: memberCommitment.createdBy,
                          })}
                        </Typography>
                        <Typography variant="tooltip">
                          {t('cci:memberCommitments.lastModifiedTooltip', {
                            date: formatShortDate(memberCommitment.modifiedDate),
                            name: memberCommitment.modifiedBy,
                          })}
                        </Typography>
                      </>
                    }
                    placement="top"
                    arrow
                  >
                    <InfoOutlined fontSize="small" htmlColor={palette.grey[500]} />
                  </Tooltip>
                </Stack>
              )}
              {canEdit && !readOnly && !alreadyCommitted && (
                <IconButton onClick={(e) => handleDelete(e)}>
                  <DeleteRounded htmlColor={palette.secondary.dark} />
                </IconButton>
              )}
              {canEdit && alreadyCommitted && editing && (
                <IconButton onClick={(e) => handleCancel(e)}>
                  <EditOffRounded htmlColor={palette.secondary.dark} />
                </IconButton>
              )}
              {canEdit && readOnly && (
                <IconButton id="action-menu" onClick={(e) => handleMenuOpen(e)}>
                  <MoreVertRounded htmlColor={palette.secondary.dark} />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <EntityFormProvider {...form}>
            <form id="edit-vendor-form" onSubmit={handleSubmit(onSubmitSuccess)}>
              <Stack spacing={1}>
                <MemberCommitmentForm />
                {!readOnly && (
                  <Stack
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={styles.submitFormBox}
                  >
                    <Checkbox
                      checked={acceptedAgreement}
                      onChange={onCheckboxChange}
                      label={t('cci:memberCommitments.form.conditions')}
                    />
                    <LoadingButton
                      loading={isSaving}
                      disabled={isSubmitDisabled || !acceptedAgreement}
                      variant="contained"
                      type="submit"
                    >
                      {t('cci:memberCommitments.actions.commit')}
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            </form>
          </EntityFormProvider>
        </AccordionDetails>
      </Accordion>
      <Menu
        id="table-menu"
        MenuListProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.MenuListProps}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        PaperProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps}
        sx={styles.menu}
      >
        {renderActionMenuItems()}
      </Menu>
    </>
  );
};
