import { Stack } from '@mui/material';
import { ControlledRadioSelect } from '../../components/Form';
import {
  useAuth,
  useEntityFormContext,
  useEntityFormFieldArray,
  useEnumList,
  useInternationalization,
} from '../../hooks';
import { NotificationSettingEntity, ProfileEntity } from '../../models';
import { NotificationType, NotificationMode } from '../../types';

export const NotificationSettingForm = () => {
  const { isInternalUser, isVendorUser } = useAuth();
  const { t } = useInternationalization();
  const { control } = useEntityFormContext<ProfileEntity>();
  const { fields: notificationSettings } = useEntityFormFieldArray(control, 'notificationSettings');
  const modeOptions = useEnumList(NotificationMode, 'notification:mode', false, undefined, false).map((mode) => ({
    ...mode,
  }));

  const renderCategoryName = (entityType: NotificationType) => {
    switch (entityType) {
      case NotificationType.Member:
        return isInternalUser ? t('notification:type.member') : t('notification:type.changeRequest');
      case NotificationType.Vendor:
        return isVendorUser ? t('notification:type.changeRequest') : t('notification:type.vendor');
      case NotificationType.VendorBuyingAgreement:
        return t('notification:type.vba');
      case NotificationType.MemberCommitmentDocument:
        return t('notification:type.commitments');
      default:
        return '';
    }
  };

  const renderCategoryMode = (setting: NotificationSettingEntity & Record<'id', string>, index: number) => (
    <ControlledRadioSelect
      key={setting.id}
      control={control}
      name={`notificationSettings.${index}.mode`}
      row
      label={renderCategoryName(setting.type)}
      options={modeOptions}
    />
  );

  return (
    <Stack spacing={3}>
      {notificationSettings
        .map((category, index) => ({ category, index }))
        .sort((a, b) =>
          renderCategoryName(a.category.type).localeCompare(renderCategoryName(b.category.type)),
        )
        .map((item) => renderCategoryMode(item.category, item.index))}
    </Stack>
  );
};
