import { Tooltip, Typography } from '@mui/material';
import { Styles, TranslationContext } from '../types';
import { useInternationalization } from '../hooks';

const style: Styles = {
  text: {
    textDecoration: 'underline',
    display: 'inline',
  },
  tooltip: {
    overflowY: 'auto',
  },
};

interface LimitCommaValuesProps {
  value: string;
  maxLength: number;
  translationContext?: TranslationContext;
  defaultToAll?: boolean;
}

export function LimitCommaValues({ value, maxLength, translationContext, defaultToAll }: LimitCommaValuesProps) {
  const { t } = useInternationalization();
  const isLarger = value.length > maxLength;
  return isLarger ? (
    <Typography variant="body1">
      {value.substring(0, maxLength)}...
      <Tooltip
        title={
          <Typography sx={style.tooltip} variant="tooltip">
            {value.split(',').join('\n')}
          </Typography>
        }
        placement="top"
      >
        <Typography sx={style.text} variant="body1" component="span">{`(${value.split(',').length})`}</Typography>
      </Tooltip>
    </Typography>
  ) : (
    <Typography variant="body1">
      {defaultToAll && !value.length ? t('common:filter.all', { context: translationContext }) : value}
    </Typography>
  );
}
