import { useContext } from 'react';
import { cms as cmsApi } from '../../../api';
import { CmsHelpTrainingContext } from '../../../contexts/Cms';
import { HelpTrainingEntity } from '../../../models';
import { HelpTrainingSchema } from '../../../schemas';
import { PermissionKey } from '../../../types';
import { CmsContent } from '../CmsContent';

export const CmsHelpTrainingContent = () => {
  const { helpTraining, isLoading, fetchData } = useContext(CmsHelpTrainingContext);

  return (
    <CmsContent
      contentEntity={helpTraining ?? new HelpTrainingEntity()}
      isLoading={isLoading}
      viewPermissions={PermissionKey.CmsHelpView}
      editPermissions={PermissionKey.CmsHelpEdit}
      approvePermissions={PermissionKey.CmsHelpApprove}
      updateApi={cmsApi.updateHelpTraining}
      uploadFilesApi={cmsApi.uploadHelpTrainingFiles}
      uploadBlockFileApi={cmsApi.uploadHelpTrainingBlockFile}
      downloadApi={cmsApi.getHelpTrainingFile}
      deleteFilesApi={cmsApi.deleteHelpTrainingFiles}
      updateFilesLanguageApi={cmsApi.updateHelpTrainingFilesLanguage}
      deleteContentBlockFileApi={cmsApi.deleteHelpTrainingBlockFile}
      downloadBlockFileApi={cmsApi.getHelpTrainingBlockFile}
      schema={HelpTrainingSchema()}
      onConfirm={fetchData}
    />
  );
};
