import { Box, Grid } from '@mui/material';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { ForestProductGroupEntity } from '../../../models';
import { Styles } from '../../../types';
import { ControlledInput, ControlledTranslatedInput } from '../../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
};

export const ForestProductGroupForm = () => {
  const { t } = useInternationalization();
  const { control, variant } = useEntityFormContext<ForestProductGroupEntity>();
  const isDrawer = variant === 'drawer';

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        {isDrawer && (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              maxLength={MAX_SHORT_TEXT_LENGTH}
              label={t('forestProduct:groups.form.name')}
              name="name"
              control={control}
              required
              isFrRequired
              variant={isDrawer ? 'outlined' : 'standard'}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTranslatedInput
            maxLength={MAX_LONG_TEXT_LENGTH}
            label={t('forestProduct:groups.form.description')}
            name="description"
            multiline
            rows={3}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            maxLength={MAX_LONG_TEXT_LENGTH}
            label={t('forestProduct:groups.form.notes')}
            name="notes"
            multiline
            rows={3}
            control={control}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
