import { Link, Tab, TabProps } from '@mui/material';
import { useMemo } from 'react';
import useAuth from '../../hooks/useAuth';
import { PermissionProps, Styles } from '../../types';

const style: Styles = {
  hiddenTab: {
    display: 'none',
  },
};

interface LinkTabProps {
  label?: string;
  value?: string;
  disabled?: TabProps['disabled'];
  permissions?: PermissionProps;
  hidden?: boolean;
}

export const LinkTab = ({ permissions, hidden, ...props }: LinkTabProps) => {
  const { hasPermissions } = useAuth();

  const isTabVisible = useMemo(
    () => !hidden && (!permissions?.keys || hasPermissions(permissions.keys, permissions.any)),
    [hasPermissions, permissions, hidden],
  );

  return <Tab component={Link} href={props.value} {...props} sx={!isTabVisible ? style.hiddenTab : undefined} />;
};
