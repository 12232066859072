import { Typography } from '@mui/material';
import { Styles } from '../../types';
import { useInternationalization } from '../../hooks';
import { ContentBlockEntity } from '../../models/Cms/ContentBlockEntity';
import { palette } from '../../styles/palette';

const style: Styles = {
  typography: {
    fontSize: '16px',
    h1: {
      fontSize: '20px',
    },
    h2: {
      fontSize: '18px',
    },
    blockquote: {
      borderLeft: 'solid 4px ' + palette.primary.main,
      fontSize: '16px',
      fontStyle: 'italic',
      lineHeight: '24px',
      margin: 0,
      overflow: 'hidden',
      padding: '32px 0 16px 16px',
      position: 'relative',
      ':before': {
        color: palette.primary.main,
        content: 'open-quote',
        fontSize: '104px',
        fontStyle: 'normal',
        lineHeight: '0',
        position: 'absolute',
        top: '34px',
      },
    },
  },
};

interface CmsContentBlockHtmlViewProps {
  contentBlock: ContentBlockEntity;
}

export const CmsContentBlockHtmlView = ({ contentBlock }: CmsContentBlockHtmlViewProps) => {
  const { getTranslation } = useInternationalization();
  return (
    <Typography
      sx={style.typography}
      dangerouslySetInnerHTML={{ __html: getTranslation(contentBlock, 'content') }}
    ></Typography>
  );
};
