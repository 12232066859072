import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../hooks';
import { AdEntity, FileEntity } from '../../../models';
import { routes } from '../../../routes';
import { AdSchema } from '../../../schemas';
import {
  AdType,
  ContentStatus,
  ContentTriggers,
  LanguageCode,
  PermissionKey,
  Styles,
  TranslationLanguage,
} from '../../../types';
import { downloadBlob } from '../../../utils/helper';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { LayoutPage } from '../../Layout';
import { CmsChangeLogs } from '../ChangeLog/CmsChangeLogs';
import { CmsWorkflow } from './../CmsWorkflow';
import { CmsAdForm } from './CmsAdForm';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const CmsAdDetail = () => {
  const { t } = useInternationalization();
  const { enqueueSnackbar } = useSnackbar();
  const { hasPermissions } = useAuth();
  const params = useParams();
  const adId = Number(params.adId);
  const { data, refresh, isLoading } = useApi(cmsApi.getAd, null, adId);
  const { call: update } = useApi(cmsApi.updateAd, null);
  const { call: upload } = useApi(cmsApi.uploadAdFile, { successKey: 'common:success.action' });
  const { call: download } = useApi(cmsApi.getAdFile, null);
  const { call: trigger, isLoading: isLoadingTrigger } = useApi(cmsApi.updateAdWorkflow, null);
  const [adType, setAdType] = useState(data?.type || AdType.Image);
  usePageTitle(data?.name ?? '');

  const canEdit =
    (data?.status === ContentStatus.InProgress && hasPermissions(PermissionKey.CmsAdEdit)) ||
    ((data?.status === ContentStatus.Submitted || data?.status === ContentStatus.Approved) &&
      hasPermissions(PermissionKey.CmsAdApprove));

  const save = async (entity: AdEntity) => {
    const updatedEntity = await update(entity);
    if (updatedEntity && updatedEntity.id) {
      for (const language of [TranslationLanguage.En, TranslationLanguage.Fr]) {
        const file = entity[`file_${language}`];
        if (file && !file.id) {
          updatedEntity[`file_${language}`] = file;
          enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
          await upload(updatedEntity.id, language.toLocaleLowerCase() as LanguageCode, file as unknown as File);
        }
      }
    } else {
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    return updatedEntity;
  };

  const downloadFile = async (file: FileEntity) => {
    if (data) {
      const fileData = await download(data.id, file.id === data.file_Fr?.id ? LanguageCode.fr : LanguageCode.en);
      if (fileData) {
        downloadBlob(file.name, fileData);
      }
    }
  };

  const onUpdateWorkflowConfirm = (newTrigger: ContentTriggers) => async () => {
    await trigger(adId, newTrigger);
    refresh();
  };
  const renderWorkflow = () => {
    if (
      !data ||
      data?.status == ContentStatus.Rejected ||
      !hasPermissions([PermissionKey.CmsAdEdit, PermissionKey.CmsAdApprove], true)
    ) {
      return undefined;
    }

    return (
      <CmsWorkflow
        isLoading={isLoadingTrigger}
        onTrigger={onUpdateWorkflowConfirm}
        status={data.status}
        entityName={data.name}
        approvePermission={PermissionKey.CmsAdApprove}
        editPermission={PermissionKey.CmsAdEdit}
        entitySuffix={t('cms:ads.actions.suffix')}
      />
    );
  };

  return (
    <LayoutPage display="Detail" permissions={{ keys: PermissionKey.CmsAdView }}>
      <Container>
        {isLoading && <CircularProgress size={20} sx={style.loading} />}
        {data && (
          <EditDetails
            titleLabel={t(`cms:ads.form.name`)}
            title="name"
            entity={data}
            schema={AdSchema(adType)}
            onSave={save}
            onConfirm={() => refresh()}
            alwaysOpen
            readOnly={!canEdit}
            breadcrumbs={[{ title: t('cms:ads.title'), href: routes.Admin.Cms.Ads.path }, { title: data?.name }]}
            extraActions={[
              <CmsChangeLogs
                key="changeLogs"
                cmsEntityId={data.id}
                fetchApi={cmsApi.getAdChangeLogs}
                addCommentApi={cmsApi.addAdComment}
                title={t('changeLog:adTitle')}
              />,
            ]}
            workflow={renderWorkflow()}
          >
            <CmsAdForm onFileClick={downloadFile} setAdType={setAdType} />
          </EditDetails>
        )}
      </Container>
    </LayoutPage>
  );
};
