export class TargetedMembershipMemberGroupEntity {
  isAllowed: boolean;
  memberGroupId: number;
  memberGroupName: string;

  constructor(entity?: TargetedMembershipMemberGroupEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.isAllowed = true;
      this.memberGroupName = '';
    }
  }
}
