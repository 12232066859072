import { number, object, string } from 'yup';
import { VendorRebateCategoryRebateValueEntity } from '../../models';
import { EntityFormSchema, RebateTypeAndUnitType, RebateValueType } from '../../types';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';

export const VendorRebateCategoryRebateValueSchema = (
  rebateValueType: RebateValueType,
): EntityFormSchema<VendorRebateCategoryRebateValueEntity> => ({
  schema: object({
    value: number().when('isRebateNote', {
      is: false,
      then: (schema) => schema.typeError('common:error.fieldRequired').required(),
      otherwise: (schema) =>
        schema
          .transform(() => {
            return undefined;
          })
          .nullable()
          .notRequired(),
    }),
    unit: string().when('isRebateNote', {
      is: false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    ...(rebateValueType !== RebateValueType.FOID
      ? {
          receivableFrequencyId: number().typeError('common:error.fieldRequired').required(),
        }
      : {}),
    ...(rebateValueType !== RebateValueType.Holdback
      ? {
          rebateNote_En: string().when('isRebateNote', {
            is: true,
            then: (schema) => schema.required().max(MAX_LONG_TEXT_LENGTH),
            otherwise: (schema) => schema.nullable().notRequired(),
          }),
          rebateNote_Fr: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
          customUnit_En: string().when('unit', {
            is: RebateTypeAndUnitType.Custom,
            then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
            otherwise: (schema) => schema.nullable().notRequired(),
          }),
          customUnit_Fr: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
          holdbackGL: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
        }
      : {
          rebateNote: string().when('isRebateNote', {
            is: true,
            then: (schema) => schema.required().max(MAX_LONG_TEXT_LENGTH),
            otherwise: (schema) => schema.nullable().notRequired(),
          }),
          customUnit: string().when('unit', {
            is: RebateTypeAndUnitType.Custom,
            then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
            otherwise: (schema) => schema.nullable().notRequired(),
          }),
        }),
    memberNotes_En: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
    memberNotes_Fr: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
    notes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
  }),
  errorCodeMap: {},
});
