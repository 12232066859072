import {
  AdminPanelSettingsRounded,
  BadgeRounded,
  BeenhereRounded,
  CarpenterRounded,
  CategoryRounded,
  DashboardRounded,
  LeaderboardRounded,
  NewspaperRounded,
  PeopleRounded,
  StoreMallDirectoryRounded,
  SyncProblemRounded,
  TourRounded,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Navigate, useOutlet } from 'react-router-dom';
import { routes } from '../../routes';
import { PermissionKey } from '../../types';
import {
  NavigationExpanderMenuItem,
  NavigationMenu,
  NavigationMenuDivider,
  NavigationMenuItem,
  NavigationScrollTop,
} from '../Navigation';
import { LayoutBaseUser } from './LayoutBaseUser';

export const LayoutAdminUser = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();

  return (
    <LayoutBaseUser>
      <NavigationScrollTop />
      <NavigationMenu>
        <NavigationMenuItem
          title={t('navigation:sections.dashboard')}
          icon={<DashboardRounded />}
          href={routes.Admin.Dashboard.path}
        />
        <NavigationMenuItem
          title={t('navigation:sections.vendors')}
          icon={<StoreMallDirectoryRounded />}
          href={routes.Admin.Vendor.path}
          matchPath="/admin/vendors/*"
          permissions={{ keys: PermissionKey.VendorViewVendors }}
        />
        <NavigationMenuItem
          title={t('navigation:sections.confidentialCommitmentIncentives')}
          icon={<TourRounded />}
          href={routes.Admin.Cci.CommitmentEvents.path}
          permissions={{ keys: PermissionKey.CciViewCommitments }}
          matchPath="/admin/cci/*"
        />
        <NavigationMenuItem
          title={t('navigation:sections.forestProducts')}
          icon={<CarpenterRounded />}
          href={routes.Admin.ForestProducts.path}
          permissions={{
            keys: [
              PermissionKey.CommodityGroupManage,
              PermissionKey.CommodityPriceEdit,
              PermissionKey.CommodityPriceView,
            ],
            any: true,
          }}
          matchPath="/admin/forest-products/*"
        />
        <NavigationMenuItem
          key="contacts"
          title={t('navigation:sections.contacts')}
          icon={<BadgeRounded />}
          href={routes.Admin.TBMContacts.path}
          matchPath="/admin/tbm-contacts/*"
        />
        <NavigationMenuItem
          key="members"
          title={t('navigation:sections.members')}
          icon={<BeenhereRounded />}
          href={routes.Admin.Member.path}
          matchPath={[`${routes.Admin.Member.path}/*`, `${routes.Admin.MemberGroups.path}/*`]}
          permissions={{ keys: PermissionKey.MemberViewMembers }}
        />
        <NavigationMenuDivider />
        <NavigationExpanderMenuItem type="vertical">
          <NavigationMenuItem
            title={t('navigation:sections.changeRequests')}
            icon={<SyncProblemRounded />}
            href={routes.Admin.ChangeRequests.path}
            matchPath="/admin/change-requests/*"
            permissions={{
              keys: [
                PermissionKey.VendorApproveContacts,
                PermissionKey.VendorApproveFinancial,
                PermissionKey.VendorApproveFiles,
                PermissionKey.MemberApproveContacts,
                PermissionKey.MemberApproveMembers,
              ],
              any: true,
            }}
          />
          <NavigationMenuItem
            key="contentAndPublications"
            title={t('navigation:sections.contentManager')}
            icon={<NewspaperRounded />}
            href={routes.Admin.Cms.path}
            matchPath="/admin/content-publications/*"
            permissions={{
              keys: [
                PermissionKey.CmsAdView,
                PermissionKey.CmsNewsView,
                PermissionKey.CmsStaticView,
                PermissionKey.CmsHelpView,
              ],
              any: true,
            }}
          />
          <NavigationMenuItem
            title={t('navigation:sections.users')}
            key="users"
            icon={<PeopleRounded />}
            href={routes.Admin.User.path}
            matchPath="/admin/users/*"
            permissions={{
              keys: [
                PermissionKey.AdminManageInternalUsers,
                PermissionKey.AdminManageMemberUsers,
                PermissionKey.AdminManageVendorUsers,
              ],
              any: true,
            }}
          />
          <NavigationMenuItem
            title={t('navigation:sections.permissions')}
            key="rolesAndPermissions"
            icon={<AdminPanelSettingsRounded />}
            href={routes.Admin.Role.path}
            matchPath="/admin/roles/*"
            permissions={{ keys: PermissionKey.AdminEditPermissions }}
          />
          <NavigationMenuItem
            key="categorization"
            title={t('navigation:sections.categorization')}
            icon={<CategoryRounded />}
            href={routes.Admin.Region.path}
            matchPath="/admin/categorization/*"
            permissions={{ keys: PermissionKey.AdminViewCategorization }}
          />
          <NavigationMenuItem
            key="reports"
            title={t('navigation:sections.reports')}
            icon={<LeaderboardRounded />}
            href={routes.Admin.Reports.path}
            matchPath="/admin/reports/*"
            permissions={{
              keys: [PermissionKey.ReportLogin, PermissionKey.ReportVendorData, PermissionKey.ReportActivityLog],
              any: true,
            }}
          />
        </NavigationExpanderMenuItem>
      </NavigationMenu>
      {outlet ?? <Navigate to={routes.Admin.Dashboard.path} />}
    </LayoutBaseUser>
  );
};
