import { AddRounded, EditRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { categorization as categorizationApi } from '../../api';
import { CategorizationContext } from '../../contexts';
import { useApi } from '../../hooks';
import { CategorizationEntity } from '../../models';
import { CategorizationSchema } from '../../schemas';
import { EditDrawer } from '../EditDrawer';
import { CategorizationForm } from './CategorizationForm';

interface CategorizationEditDrawerProps {
  entity?: CategorizationEntity | null;
  open: boolean;
  onClose: () => void;
}

export const CategorizationEditDrawer = ({ onClose, entity, open }: CategorizationEditDrawerProps) => {
  const { type, data, isFieldVisible, fetchData } = useContext(CategorizationContext);
  const { t } = useTranslation();
  const { call } = useApi(entity ? categorizationApi.update : categorizationApi.create, {
    successKey: 'common:success.save',
  });

  const isNameUnique = (propertyName: 'name_Fr' | 'name_En' | 'abbreviation_En' | 'abbreviation_Fr', value: string) =>
    data?.values
      .filter((d) => d.id !== entity?.id)
      .every((d) => value.trim().toLowerCase() !== d[propertyName]?.trim().toLowerCase()) || false;

  const category = useMemo(() => entity ?? new CategorizationEntity(), [entity]);

  const handleSave = (entity: CategorizationEntity) => call(type, entity);

  const handleConfirm = () => {
    onClose();
    fetchData();
  };

  const renderTitle = () => (
    <Stack direction="row" spacing={1} alignItems="center">
      {!entity ? <AddRounded /> : <EditRounded />}
      <Typography variant="drawerTitle">
        {!entity ? t(`categorization:${type}.add`) : t(`categorization:${type}.edit`)}
      </Typography>
    </Stack>
  );

  return (
    <EditDrawer
      open={open}
      entity={category}
      schema={CategorizationSchema(isFieldVisible('abbreviation'), isNameUnique)}
      title={renderTitle()}
      onConfirm={handleConfirm}
      onCancel={onClose}
      onSave={handleSave}
    >
      <CategorizationForm />
    </EditDrawer>
  );
};
