import { PeriodEntity } from '../PeriodEntity';

export class CommitmentEventEntity extends PeriodEntity {
  year: number;
  categoriesCount: number;
  isVisibleToVendor: boolean;
  notes: string;
  memberNotes_En: string;
  memberNotes_Fr: string;
  previousCommitmentEventId: number | null;
  isArchived: boolean;

  constructor(entity?: CommitmentEventEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.year = new Date().getFullYear() + 1;
      this.isVisibleToVendor = false;
      this.notes = '';
      this.memberNotes_En = '';
      this.memberNotes_Fr = '';
      this.previousCommitmentEventId = null;
      this.isArchived = false;
    }
  }
}
