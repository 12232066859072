import { Box, Grid } from '@mui/material';
import { MAX_LONG_TEXT_LENGTH } from '../../../constants';
import { useInternationalization } from '../../../hooks';
import { ForestProductPriceUpdateEntity, ForestProductPriceUpdateProductEntity } from '../../../models';
import { EntityFormOptions, Styles } from '../../../types';
import { ControlledTranslatedInput, StaticField } from '../../Form';
import { Control } from 'react-hook-form';

const style: Styles = {
  container: {
    pb: 4,
  },
};

interface ForestProductPriceUpdatePricesCommentFormProps {
  control: Control<ForestProductPriceUpdateEntity, EntityFormOptions>;
  productIndex: number;
  product: ForestProductPriceUpdateProductEntity;
}

export const ForestProductPriceUpdatePricesCommentForm = ({
  control,
  productIndex,
  product,
}: ForestProductPriceUpdatePricesCommentFormProps) => {
  const { t, getTranslation } = useInternationalization();

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StaticField
            label={t('forestProduct:priceUpdates.form.name')}
            value={getTranslation(product.forestProduct, 'name')}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('forestProduct:priceUpdates.form.comment')}
            name={`products.${productIndex}.notes`}
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
