import { ArchiveRounded, UnarchiveRounded } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER, MAX_PAGINATION_FILTER } from '../../../constants';
import { VendorDetailContext } from '../../../contexts';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorDocumentEntity } from '../../../models';
import { FilterPeriod, PermissionKey, VendorDocumentFilter } from '../../../types';
import { ArchiveMenuItem } from '../../Menu';
import { VendorDocumentsList } from './VendorDocumentsList';

const defaultFilter: VendorDocumentFilter = {
  period: FilterPeriod.All,
  isArchived: false,
  type: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

export const VendorDocumentsListAdmin = () => {
  const { t, getTranslation } = useInternationalization();
  const { enqueueSnackbar } = useSnackbar();
  const { vendor, vendorId } = useContext(VendorDetailContext);
  const [filter, setFilter] = useState(defaultFilter);

  const getAllDocumentsApi = useApi(vendorApi.getAllDocuments, { skipFetch: !vendor }, vendor?.id, filter);
  const { call: create } = useApi(vendorApi.createDocument, null);
  const { call: update } = useApi(vendorApi.updateDocument, null);
  const { call: upload } = useApi(vendorApi.uploadDocumentFile, { successKey: 'common:success.action' });
  const { call: download } = useApi(vendorApi.getDocumentFile, null);
  const { call: archive } = useApi(vendorApi.archiveDocument, { successKey: 'common:success.action' });
  const { data: rebateCategories } = useApi(vendorApi.getAllRebateCategories, null, vendorId, MAX_PAGINATION_FILTER);

  const downloadFile = async (entity: VendorDocumentEntity) => {
    return await download(vendorId, entity.id);
  };

  const onSave = async (entity: VendorDocumentEntity) => {
    const savedDocument = entity.id ? await update(vendorId, entity) : await create(vendorId, entity);
    if (savedDocument && savedDocument.id && entity.file && !entity.file.id) {
      savedDocument.file = entity.file;
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
      await upload(vendorId, savedDocument.id, entity.file as unknown as File);
    } else {
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    return savedDocument;
  };

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: VendorDocumentEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: VendorDocumentEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onArchiveConfirm = async (isArchived: boolean, entities: VendorDocumentEntity[]) => {
    await archive(
      vendorId,
      entities.map((i) => i.id),
      isArchived,
    );
    getAllDocumentsApi.refresh();
  };

  const renderActionsMenuItems = (entity: VendorDocumentEntity, onClick: () => void) => [
    <ArchiveMenuItem
      key="archive"
      permissions={{ keys: PermissionKey.VendorEditFiles }}
      entity={entity}
      name={getTranslation(entity, 'description')}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('vendor:document.actions.suffix')}
    />,
  ];

  return (
    <VendorDocumentsList
      rebateCategories={rebateCategories?.data}
      layout="Tab"
      showSearch
      addButtonProps={{
        disabled: !vendor || vendor.isArchived,
      }}
      filter={filter}
      setFilter={setFilter}
      defaultFilter={defaultFilter}
      fetchApi={getAllDocumentsApi}
      onSave={onSave}
      onFileDownload={downloadFile}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
        bulkActions: vendor && !vendor.isArchived ? bulkActions : undefined,
        bulkActionSuffix: t('vendor:document.actions.suffix'),
        bulkPermissions: { keys: PermissionKey.VendorEditFiles },
        renderBulkSelection: (items) =>
          items.length === 1 ? getTranslation(items[0], 'description') : t(`vendor:document.title`),
      }}
    />
  );
};
