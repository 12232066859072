import { object, string } from 'yup';
import { ForestProductGroupEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';

export const ForestProductGroupSchema = (): EntityFormSchema<ForestProductGroupEntity> => ({
  schema: object().shape({
    name_En: string().enRequired().max(MAX_SHORT_TEXT_LENGTH),
    name_Fr: string().frRequired().max(MAX_SHORT_TEXT_LENGTH),
    description_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    description_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
  }),
  errorCodeMap: {},
});
