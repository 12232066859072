import { MissingTranslationEntityType, MissingTranslationField } from '../../types';

export class MissingTranslationReportEntity {
  id: number;
  rebateCategoryId?: number;
  field: MissingTranslationField;
  entityType: MissingTranslationEntityType;
  name_En: string;
  name_Fr: string;
  vendorId: number;
  vendorName: string;
  vendorNumber: string;
  responsibleBuyerId: number;
  responsibleBuyer: string;
  lastUpdateDate: Date;

  constructor(entity?: MissingTranslationReportEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.vendorName = '';
      this.responsibleBuyer = '';
    }
  }
}
