import { Box, CircularProgress, Stack } from '@mui/material';
import { useContext } from 'react';
import { cms as cmsApi } from '../../../api';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { PublicationEntity } from '../../../models';
import { PublicationSchema } from '../../../schemas';
import { Styles } from '../../../types';
import { EditCard } from '../../Card';
import { Container } from '../../Container';
import {
  TargetedMembershipCategorizationForm,
  TargetedMembershipMemberForm,
  TargetedMembershipMemberGroupForm,
} from '../../TargetedMembership';
import { CmsPublicationContext } from '../../../contexts/Cms';

const style: Styles = {
  loading: {
    mx: 2,
    mt: 3,
  },
  highlightContainer: {
    mb: 5,
  },
};

export const CmsPublicationTargeting = () => {
  const { t } = useInternationalization();
  const { isInternalUser } = useAuth();
  const { publication, canEdit, isLoading, fetchData } = useContext(CmsPublicationContext);
  const { call: update } = useApi(cmsApi.updatePublication, {
    successKey: 'common:success.save',
  });

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        {isLoading && !publication && <CircularProgress size={20} sx={style.loading} />}
        {publication && (
          <Box>
            <EditCard
              title={t('vendor:targetedMembership.title')}
              entity={publication}
              disabled={!canEdit}
              schema={PublicationSchema()}
              onSave={(entity: PublicationEntity) => update(entity)}
              onConfirm={fetchData}
            >
              <TargetedMembershipCategorizationForm />
            </EditCard>
            {isInternalUser && (
              <EditCard
                title={t('vendor:targetedMembership.form.memberGroupTitle')}
                entity={publication}
                disabled={!canEdit}
                schema={PublicationSchema()}
                onSave={(entity: PublicationEntity) => update(entity)}
                onConfirm={fetchData}
              >
                <TargetedMembershipMemberGroupForm hideTitle />
              </EditCard>
            )}
            {isInternalUser && (
              <EditCard
                title={t('vendor:targetedMembership.form.memberTitle')}
                entity={publication}
                disabled={!canEdit}
                schema={PublicationSchema()}
                onSave={(entity: PublicationEntity) => update(entity)}
                onConfirm={fetchData}
              >
                <TargetedMembershipMemberForm hideTitle />
              </EditCard>
            )}
          </Box>
        )}
      </Stack>
    </Container>
  );
};
