import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet } from 'react-router-dom';
import { CommitmentEventForm, CommitmentEventHeader } from '..';
import { EditDetails, Loading } from '../..';
import { cci as cciApi } from '../../../api';
import { CommitmentEventDetailContext } from '../../../contexts';
import { useApi, usePageTitle } from '../../../hooks';
import { routes } from '../../../routes';
import { CommitmentEventSchema } from '../../../schemas';
import { PermissionKey } from '../../../types';
import { Container } from '../../Container';
import { LayoutPage } from '../../Layout';
import { LinkTab } from '../../Tab';

export const MemberCommitmentEventDetail = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const { data, isLoading, refresh } = useApi(cciApi.getLatestCommitmentEvent, { callImmediately: true });
  const { call } = useApi(cciApi.updateCommitmentEvent, { successKey: 'common:success.save' });
  usePageTitle('cci:sections.commitmentEvent', { year: data?.year ?? '' });

  const tabs = [
    ...(data
      ? [
          <LinkTab
            label={t('cci:sections.commitments')}
            value={generatePath(routes.Member.CommitmentEventDetails.Commitments.path)}
          />,
        ]
      : []),
    <LinkTab
      label={t('cci:sections.history')}
      value={generatePath(routes.Member.CommitmentEventDetails.History.path)}
    />,
  ];

  if (isLoading) {
    return (
      <LayoutPage display="Detail">
        <Container>
          <Loading />
        </Container>
      </LayoutPage>
    );
  }

  if (!data) {
    return (
      <LayoutPage
        display="Page"
        title={t('cci:title')}
        outlet={outlet}
        tabs={tabs}
        permissions={{ keys: PermissionKey.CciViewCommitments }}
      />
    );
  }

  return (
    <CommitmentEventDetailContext.Provider
      value={{ event: data, eventId: data?.id, readonly: data?.isArchived ?? true }}
    >
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs} permissions={{ keys: PermissionKey.CciViewCommitments }}>
        <Container>
          {data && (
            <EditDetails
              header={<CommitmentEventHeader />}
              permissions={{ keys: PermissionKey.CciManageEvents }}
              titleLabel={t(`cci:events.form.year`)}
              title="year"
              disabled={data.isArchived}
              entity={data}
              schema={CommitmentEventSchema()}
              onSave={call}
              onConfirm={refresh}
              readOnly
              breadcrumbs={[]}
            >
              <CommitmentEventForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </CommitmentEventDetailContext.Provider>
  );
};
