import { BuyGuideList } from '../../../components/BuyGuide';
import { ConfidentialAgreement } from '../../../components/ConfidentialAgreement';
import { usePageTitle } from '../../../hooks';

export function BuyGuide() {
  usePageTitle('buyGuide:title');
  return (
    <ConfidentialAgreement>
      <BuyGuideList />
    </ConfidentialAgreement>
  );
}
