import React from 'react';
import { PublicationEntity } from '../../models';

export interface CmsPublicationContextType {
  fetchData: () => Promise<void>;
  publication: PublicationEntity | null;
  canEdit: boolean;
  isLoading: boolean;
}

export const CmsPublicationContext = React.createContext<CmsPublicationContextType>({} as CmsPublicationContextType);
