import { VendorRebateCategoryProgramStatus } from '../../types';
import { CategorizationEntity } from '../Categorization';
import { PeriodEntity } from '../PeriodEntity';
import { RebateCategoryReportEntity } from './RebateCategoryReportEntity';

export class VendorDataReportEntity extends PeriodEntity {
  number: number;
  name: string;
  name_En: string;
  name_Fr: string;
  vendorId: number;
  vendorName: string;
  vendorNumber: string;
  responsibleBuyerId: number;
  responsibleBuyer: string;
  rebateCategory?: RebateCategoryReportEntity;
  productCategories?: CategorizationEntity[];
  status?: VendorRebateCategoryProgramStatus;
  custom?: boolean;
  targetsAll?: boolean;
  approvedBy?: string;
  verifiedBy?: string;

  constructor(entity?: VendorDataReportEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.vendorName = '';
      this.name = '';
      this.responsibleBuyer = '';
    }
  }
}
