import { Chip, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnumList } from '../../hooks';
import { ContentFilter, ContentFilterFilter, Styles, TranslationContext } from '../../types';

const style: Styles = {
  filterContainer: {
    py: 2,
  },
};

interface ContentFilterSelectProps {
  filter: ContentFilterFilter;
  setFilter: Dispatch<SetStateAction<ContentFilterFilter>>;
  translationContext: TranslationContext;
  ignoreFilters?: ContentFilter[];
}

export const ContentFilterSelect = ({
  filter,
  setFilter,
  translationContext,
  ignoreFilters,
}: ContentFilterSelectProps) => {
  const { t } = useTranslation();
  const contentFilterOptions = useEnumList(ContentFilter, 'cms:showFilter', true, translationContext, false).filter(
    (c) => !ignoreFilters || !ignoreFilters.includes(c.value),
  );

  const onChange = (value: ContentFilter | '') => () => {
    const contentFilterValue = value === '' ? undefined : value;
    setFilter((prev) => ({ ...prev, filter: contentFilterValue, pageNumber: 1 }));
  };

  const value = filter.filter === undefined ? '' : filter.filter;

  return (
    <Stack sx={style.filterContainer} direction="row" spacing={2} alignItems="center">
      <Typography align="center" variant="label" color="grey.600">
        {t('common:show')}
      </Typography>
      <Stack spacing={0.7} direction="row">
        {contentFilterOptions.map((option) => (
          <Chip
            key={option.value}
            label={option.label}
            onClick={onChange(option.value)}
            color={option.value === value ? 'primary' : 'secondary'}
          />
        ))}
      </Stack>
    </Stack>
  );
};
