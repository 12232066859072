import { Stack, Typography } from '@mui/material';
import { Loading } from '../..';
import { PERCENT_MAX_DECIMALS } from '../../../constants';
import { useInternationalization } from '../../../hooks';
import { VendorRebateCategoryRebateSummaryEntity } from '../../../models';
import { RebateTypeAndUnitType, Styles } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';

const style: Styles = {
  stack: {
    mt: 2,
  },
  typography: {
    fontSize: 16,
    fontWeight: 600,
  },
};

interface VendorRebateCategoryRebateFactorSummaryProps {
  summary: VendorRebateCategoryRebateSummaryEntity | null;
}

export const VendorRebateCategoryRebateFactorSummary = ({ summary }: VendorRebateCategoryRebateFactorSummaryProps) => {
  const { t, currentLanguage } = useInternationalization();

  if (!summary) {
    return <Loading />;
  }

  const renderValue = (value?: number | null) => {
    if (value == null) {
      return <>&mdash;</>;
    }
    const formatter = numberFormatter(
      currentLanguage,
      t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Percent}`),
      PERCENT_MAX_DECIMALS,
      true,
    );
    return formatter(value).formatted.join('');
  };

  return (
    <Stack direction="row" spacing={1} sx={style.stack}>
      <Typography variant="body1" sx={style.typography}>
        {t('vendor:rebateCategory.form.completeDiscount')}: {renderValue(summary.completeDiscount)}
      </Typography>
      <Typography variant="body1" sx={style.typography}>
        {t('vendor:rebateCategory.form.totalRebates')}: {renderValue(summary.totalRebates)}
      </Typography>
    </Stack>
  );
};
