import { AddRounded } from '@mui/icons-material';
import { Box, Button, Link, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useOutlet } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { StaticPageEntity } from '../../../models';
import { routes } from '../../../routes';
import { StaticPageSchema } from '../../../schemas';
import {
  ContentFilter,
  ContentStatus,
  LanguageCode,
  OrderBy,
  PermissionKey,
  StaticPageFilter,
  Styles,
  TranslationLanguage,
} from '../../../types';
import { EditDrawer } from '../../EditDrawer';
import { Autocomplete, Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { CmsContentStatus } from '../CmsContentStatus';
import { CmsStaticPageForm } from './CmsStaticPageForm';
import { useSnackbar } from 'notistack';
import { FilterContainer } from '../../Container';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
    my: 3,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
};

const defaultFilter: StaticPageFilter = {
  staticPageName: '',
  filter: undefined,
  status: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

export const CmsStaticPageList = () => {
  const outlet = useOutlet();
  const { enqueueSnackbar } = useSnackbar();
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const navigate = useNavigate();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const staticPage = useMemo(() => new StaticPageEntity(), []);
  const [filter, setFilter] = useState(defaultFilter);
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const statusOptions = useEnumList(ContentStatus, 'cms:status', true);
  const filterOptions = useEnumList(ContentFilter, 'cms:showFilter', false, undefined, false);
  const { call: create } = useApi(cmsApi.createStaticPage, null);
  const { call: upload } = useApi(cmsApi.uploadStaticPageFile, null);
  const { data, isLoading, refresh } = useApi(cmsApi.getAllStaticPages, null, filter);
  usePageTitle('cms:staticPages.title');

  useEffect(() => {
    refresh();
  }, [currentLanguage, refresh]);

  if (outlet) {
    return outlet;
  }

  const saveDrawer = async (entity: StaticPageEntity) => {
    const createdEntity = await create(entity);
    if (createdEntity && createdEntity.id) {
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
      for (const language of [TranslationLanguage.En, TranslationLanguage.Fr]) {
        const file = entity[`iconImage_${language}`];
        if (file && !file.id) {
          createdEntity[`iconImage_${language}`] = file;
          await upload(createdEntity.id, language.toLocaleLowerCase() as LanguageCode, file as unknown as File);
        }
      }
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    await refresh();
    return createdEntity;
  };

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({
      ...prev,
      ...(checked ? { ...defaultFilter, filter: ContentFilter.Upcoming } : defaultFilter),
    }));
    setIsAdvancedMode(checked);
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onStatusChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, status: (event.target.value as ContentStatus) || undefined, pageNumber: 1 }));
  };

  const onFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, filter: event.target.value as ContentFilter, pageNumber: 1 }));
  };

  const getDetailPath = (id: number) => generatePath(routes.Admin.StaticPageDetails.Content.path, { staticPageId: id });

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.CmsStaticEdit}>
      <Button variant="contained" startIcon={<AddRounded />} onClick={() => setIsAddOpen(true)}>
        {t('cms:staticPages.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderActionsMenuItems = (item: StaticPageEntity) => {
    return [
      <MenuItem id="view" key="view" onClick={() => navigate(getDetailPath(item.id))}>
        {t('common:view')}
      </MenuItem>,
    ];
  };

  const renderStaticPageLink = (id: number, text: string | undefined) =>
    !!text && (
      <Link underline="always" href={getDetailPath(id)}>
        {text}
      </Link>
    );

  const renderTextSearch = () => (
    <Autocomplete
      value={filter.staticPageName}
      sx={style.textContainer}
      noOptionsText={t('cms:staticPages.search.notFound')}
      apiFunction={cmsApi.getStaticPageSuggestions}
      label={t('cms:staticPages.search.title')}
      placeholder={t('cms:staticPages.search.placeholder')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : getTranslation(option, 'name'))}
      onApplySearch={(searchText) =>
        setFilter((prev) => ({ ...prev, staticPageName: searchText ?? undefined, pageNumber: 1 }))
      }
      onSelectItem={(item) => item && navigate(getDetailPath(item.id))}
    />
  );

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...{ ...defaultFilter, filter: ContentFilter.Upcoming } }));
  };

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear} my={3}>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={filterOptions}
          value={filter.filter ?? ''}
          onChange={onFilterChange}
          label={t('cms:staticPages.search.show')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={statusOptions}
          value={filter.status ?? ''}
          onChange={onStatusChange}
          label={t('cms:staticPages.search.status')}
        />
      </Box>
    </FilterContainer>
  );

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="cms:staticPages"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={renderActionsMenuItems}
    >
      <TableColumn
        type="custom"
        sortable
        width="15%"
        id="status"
        render={(item: StaticPageEntity) => <CmsContentStatus status={item.status} />}
      />
      <TableColumn
        type="custom"
        sortable
        width="50%"
        id="name"
        render={(item: StaticPageEntity) => renderStaticPageLink(item.id, getTranslation(item, 'name'))}
      />
      <TableColumn
        type="custom"
        sortable
        width="35%"
        id="submittedBy"
        render={(item: StaticPageEntity) => item.modifiedBy}
      />
    </Table>
  );

  return (
    <LayoutPage
      display="Tab"
      title={t('cms:staticPages.title')}
      rightTitle={renderAddButton()}
      permissions={{
        keys: PermissionKey.CmsStaticView,
      }}
    >
      <EditDrawer
        title={
          <HasPermissions keys={[PermissionKey.CmsStaticEdit]}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AddRounded />
              <Typography variant="drawerTitle">{t('cms:staticPages.actions.add')}</Typography>
            </Stack>
          </HasPermissions>
        }
        open={isAddOpen}
        redirectPath={(item) => getDetailPath(item.id)}
        entity={staticPage}
        schema={StaticPageSchema()}
        redirectLabel={t('cms:staticPages.actions.saveOpen')}
        onSave={saveDrawer}
        onConfirm={confirmDrawer}
        onCancel={cancelDrawer}
      >
        <CmsStaticPageForm />
      </EditDrawer>
      <Box sx={style.filterBox}>
        <Typography variant="searchTitle" color="primary.dark">
          {t('cms:staticPages.search.title')}
        </Typography>
        <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
        <Typography variant="searchTitle" color="grey.600">
          {t('cms:staticPages.search.filterList')}
        </Typography>
      </Box>
      {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
      {renderTable()}
    </LayoutPage>
  );
};
