import { TypographyOptions } from '@mui/material/styles/createTypography';
import { palette } from './palette';

export const typography = {
  fontFamily: 'Montserrat',
  inputError: {
    fontWeight: 400,
    fontSize: 14,
    color: palette.error.main,
  },
  h1: {
    fontSize: 32,
    lineHeight: '40px',
    fontWeight: 700,
    color: palette.primary.blue,
  },
  h2: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
  },
  h3: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
  },
  h4: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 400,
  },
  h5: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
  },
  h6: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 700,
    color: palette.grey[700],
  },
  body1: {
    fontSize: 14,
    fontWeight: 500,
    color: palette.grey[600],
  },
  tableRow: {
    fontSize: 14,
    fontWeight: 500,
  },
  tabLabel: {
    color: palette.grey[500],
    fontSize: '14px !important',
    fontWeight: 600,
    textTransform: 'none',
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    color: palette.grey[500],
  },
  selectOutlineValue: {
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey[600],
  },
  selectStandardValue: {
    fontWeight: 600,
    fontSize: 14,
    color: palette.primary.dark,
  },
  toast: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: '0.15px',
    color: palette.white,
  },
  drawerTitle: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: '0.15px',
    color: palette.white,
  },
  drawerContentTitle: {
    fontSize: 20,
    fontWeight: 800,
    color: palette.black,
  },
  drawerContentSubtitle: {
    fontSize: 16,
    fontWeight: 700,
    color: palette.black,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: palette.grey[600],
  },
  modalDescription: {
    fontSize: 16,
    fontWeight: 500,
    color: palette.grey[600],
  },
  searchTitle: {
    fontWeight: 600,
    fontSize: 16,
  },
  tooltip: {
    display: 'block',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    maxHeight: '200px',
    maxWidth: '500px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-line',
  },
  button: {
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'none',
    letterSpacing: '0.15px',
  },
  buttonSmall: {
    fontSize: 14,
  },
  valuePlaceholder: {
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: 16,
    color: palette.grey[400],
  },
  breadcrumb: {
    fontWeight: 600,
    fontSize: 12,
    color: palette.primary.deep,
  },
  modifiedDate: {
    fontSize: 12,
    color: palette.grey[500],
  },
  menuUsername: {
    fontWeight: 600,
    fontSize: 16,
  },
  chip: {
    fontWeight: 600,
    fontSize: 12,
  },
  fileHelper: {
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey[600],
  },
  underlinedLink: {
    textDecoration: 'underline',
  },
  userDisplayName: {
    fontWeight: 600,
    fontSize: 12,
    color: palette.grey[500],
  },
  outstandingCount: {
    fontWeight: 700,
    fontSize: 14,
    color: palette.grey[600],
  },
  tag: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: '0.15px',
    color: palette.grey[500],
  },
  status: {
    display: 'flex',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '0.15px',
    color: palette.grey[500],
    padding: '2px 4px',
    borderRadius: '4px',
    alignItems: 'center',
    width: 'fit-content',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      marginLeft: 4,
    },
  },
  notificationTime: {
    fontWeight: 500,
    fontSize: 12,
    color: palette.grey[500],
  },
  notificationMessage: {
    fontWeight: 500,
    fontSize: 14,
    color: palette.grey[600],
  },
} as ExtendedTypographyOptions;

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    inputError: true;
    tabLabel: true;
    label: true;
    tableRow: true;
    selectOutlineValue: true;
    selectStandardValue: true;
    toast: true;
    drawerTitle: true;
    drawerContentTitle: true;
    drawerContentSubtitle: true;
    modalTitle: true;
    modalDescription: true;
    searchTitle: true;
    tooltip: true;
    valuePlaceholder: true;
    breadcrumb: true;
    modifiedDate: true;
    buttonSmall: true;
    menuUsername: true;
    chip: true;
    fileHelper: true;
    underlinedLink: true;
    userDisplayName: true;
    outstandingCount: true;
    tag: true;
    status: true;
    notificationTime: true;
    notificationMessage: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  inputError: React.CSSProperties;
  tabLabel: React.CSSProperties;
  label: React.CSSProperties;
  tableRow: React.CSSProperties;
  selectOutlineValue: React.CSSProperties;
  selectStandardValue: React.CSSProperties;
  toast: React.CSSProperties;
  drawerTitle: React.CSSProperties;
  drawerContentTitle: React.CSSProperties;
  drawerContentSubtitle: React.CSSProperties;
  modalTitle: React.CSSProperties;
  modalDescription: React.CSSProperties;
  searchTitle: React.CSSProperties;
  tooltip: React.CSSProperties;
  valuePlaceholder: React.CSSProperties;
  breadcrumb: React.CSSProperties;
  modifiedDate: React.CSSProperties;
  buttonSmall: React.CSSProperties;
  menuUsername: React.CSSProperties;
  chip: React.CSSProperties;
  fileHelper: React.CSSProperties;
  underlinedLink: React.CSSProperties;
  userDisplayName: React.CSSProperties;
  outstandingCount: React.CSSProperties;
  tag: React.CSSProperties;
  status: React.CSSProperties;
  notificationTime: React.CSSProperties;
  notificationMessage: React.CSSProperties;
}
