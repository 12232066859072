import { MenuItem, MenuItemProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Archivable, Entity } from '../../types';

interface ExportMenuItemProps<T extends Entity & Archivable> extends MenuItemProps {
  entity: T;
  onExport: (entity: T) => Promise<void>;
  onClick: () => void;
}

export const ExportMenuItem = <T extends Entity & Archivable>({
  entity,
  onExport,
  onClick,
  ...props
}: ExportMenuItemProps<T>) => {
  const { t } = useTranslation();

  const onExportClick = async () => {
    onClick && onClick();
    await onExport(entity);
  };

  return (
    <MenuItem {...props} id="export" key="export" onClick={onExportClick} disabled={entity.isArchived}>
      {t('common:export')}
    </MenuItem>
  );
};
