import React from 'react';
import { VendorEntity } from '../../models';

interface BuyGuideVendorDetailContextType {
  vendor: VendorEntity | null;
  vendorId: number;
}

export const BuyGuideVendorDetailContext = React.createContext<BuyGuideVendorDetailContextType>(
  {} as BuyGuideVendorDetailContextType,
);
