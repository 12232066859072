import axios from 'axios';
import { MemberCommitmentDocumentEntity } from '../../models';
import { PaginationEntity, MemberCommitmentDocumentFilter } from '../../types';

export const commitmentDocument = {
  getAllDocuments: async (
    memberId: number,
    documentFilter: MemberCommitmentDocumentFilter,
  ): Promise<PaginationEntity<MemberCommitmentDocumentEntity>> => {
    const { data } = await axios.post(`api/member/${memberId}/commitmentDocuments/search`, documentFilter);
    return {
      ...data,
      data: data.data.map((v: MemberCommitmentDocumentEntity) => new MemberCommitmentDocumentEntity(v)),
    };
  },
  createDocument: async (
    memberId: number,
    entity: MemberCommitmentDocumentEntity,
  ): Promise<MemberCommitmentDocumentEntity> => {
    const { data } = await axios.post(`api/member/${memberId}/commitmentDocuments`, entity);
    return new MemberCommitmentDocumentEntity(data);
  },
  updateDocument: async (
    memberId: number,
    entity: MemberCommitmentDocumentEntity,
  ): Promise<MemberCommitmentDocumentEntity> => {
    const { data } = await axios.put(`api/member/${memberId}/commitmentDocuments/${entity.id}`, entity);
    return new MemberCommitmentDocumentEntity(data);
  },
  getDocumentFile: async (memberId: number, vendorDocumentId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/member/${memberId}/commitmentDocuments/${vendorDocumentId}/file`, {
      responseType: 'blob',
    });
    return data;
  },
  uploadDocumentFile: async (
    memberId: number,
    memberCommitmentDocumentId: number,
    file: File,
  ): Promise<MemberCommitmentDocumentEntity> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.put(
      `api/member/${memberId}/commitmentDocuments/${memberCommitmentDocumentId}/file`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    return new MemberCommitmentDocumentEntity(data);
  },
  archiveDocument: async (
    memberId: number,
    ids: number[],
    isArchived: boolean,
  ): Promise<MemberCommitmentDocumentEntity[]> => {
    const { data } = await axios.put(`api/member/${memberId}/commitmentDocuments/archive`, {
      isArchived,
      ids,
    });
    return data.map((v: MemberCommitmentDocumentEntity) => new MemberCommitmentDocumentEntity(v));
  },
};
