import { AddRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { EditDrawer } from '../../..';
import { vendor as vendorApi } from '../../../../api';
import { MAX_PAGINATION_FILTER } from '../../../../constants';
import { VendorBuyingAgreementContext } from '../../../../contexts/Vendor';
import { useAuth, useEnumList, useGlobalEdit, useInternationalization } from '../../../../hooks';
import { useApi } from '../../../../hooks/useApi';
import { VendorBuyingAgreementBuyOpportunityEntity } from '../../../../models';
import { routes } from '../../../../routes';
import { VendorBuyingAgreementBuyOpportunitySchema } from '../../../../schemas';
import { OpportunityType, PermissionKey, Styles, VendorBuyOpportunityFilter } from '../../../../types';
import { Select } from '../../../Form';
import { LayoutPage } from '../../../Layout';
import { DeleteMenuItem, MenuItem } from '../../../Menu';
import { HasPermissions } from '../../../Permission';
import { VendorBuyOpportunitiesCardList } from '../../BuyOpportunities/VendorBuyOpportunitiesCardList';
import { VendorBuyOpportunityHeader } from '../../BuyOpportunities/VendorBuyOpportunityHeader';
import { VendorBuyingAgreementBuyOpportunityForm } from './VendorBuyingAgreementBuyOpportunityForm';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '220px',
    },
    pb: 2,
  },
};

export const VendorBuyingAgreementBuyOpportunitiesList = () => {
  const { isInternalUser } = useAuth();
  const navigate = useNavigate();
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { vendorBuyingAgreement, readOnly } = useContext(VendorBuyingAgreementContext);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const vendorBuyOpportunity = useMemo(() => new VendorBuyingAgreementBuyOpportunityEntity(), []);
  const [filter, setFilter] = useState<VendorBuyOpportunityFilter>({
    ...MAX_PAGINATION_FILTER,
    opportunity: OpportunityType.All,
  });

  const opportunityOptions = useEnumList(OpportunityType, 'vendor:buyOpportunities.opportunity').filter(
    (o) => o.value !== OpportunityType.PoolBuy,
  );

  const { refresh, data, isLoading } = useApi(
    vendorApi.getAllBuyingAgreementBuyOpportunities,
    null,
    vendorBuyingAgreement.vendorId,
    vendorBuyingAgreement.id,
    filter,
  );

  const { call: remove } = useApi(vendorApi.deleteBuyingAgreementBuyOpportunity, {
    successKey: 'common:success.save',
  });
  const { call: create } = useApi(vendorApi.createBuyingAgreementBuyOpportunity, { successKey: 'common:success.save' });

  const getDetailPath = (entity: VendorBuyingAgreementBuyOpportunityEntity) =>
    generatePath(
      isInternalUser
        ? routes.Admin.Vendor.BuyingAgreementBuyOpportunity.path
        : routes.Vendor.BuyingAgreementBuyOpportunity.path,
      {
        vendorId: vendorBuyingAgreement.vendorId,
        vendorBuyingAgreementId: vendorBuyingAgreement.id,
        buyOpportunityId: entity.id,
      },
    );

  const saveDrawer = async (entity: VendorBuyingAgreementBuyOpportunityEntity) => {
    const result = await create(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, entity);
    await refresh();
    return result;
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const onDeleteConfirm = async (entity: VendorBuyingAgreementBuyOpportunityEntity) => {
    await remove(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, entity.id);
    refresh();
  };

  const renderActionsMenuItems = (entity: VendorBuyingAgreementBuyOpportunityEntity, onClick: () => void) => {
    const menuItems = [
      <MenuItem id="view" key="view" onClick={() => navigate(getDetailPath(entity))}>
        {t('common:view')}
      </MenuItem>,
    ];
    if (!readOnly) {
      menuItems.push(
        <DeleteMenuItem
          key="delete"
          permissions={{ keys: PermissionKey.VendorEditVBAs }}
          actionSuffix={t('vendor:buyOpportunities.actions.suffix')}
          name={getTranslation(entity, 'name')}
          entity={entity}
          onDeleteConfirm={onDeleteConfirm}
          onClick={onClick}
        />,
      );
    }
    return menuItems;
  };

  const renderAddButton = () =>
    !readOnly ? (
      <HasPermissions keys={PermissionKey.VendorEditVBAs}>
        <Button
          variant="contained"
          startIcon={<AddRounded />}
          onClick={() => setIsAddOpen(true)}
          disabled={globalEditing}
        >
          {t('vendor:buyOpportunities.actions.add')}
        </Button>
      </HasPermissions>
    ) : undefined;

  const renderList = () => (
    <VendorBuyOpportunitiesCardList
      data={data ?? []}
      isLoading={isLoading}
      translationNamespace="vendor"
      hideResultCount
      actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
      href={getDetailPath}
    />
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('vendor:buyOpportunities.actions.add')}</Typography>
        </Stack>
      }
      redirectPath={(entity) => getDetailPath(entity)}
      open={isAddOpen}
      entity={vendorBuyOpportunity}
      schema={VendorBuyingAgreementBuyOpportunitySchema()}
      redirectLabel={t('vendor:buyOpportunities.actions.saveOpen')}
      onSave={saveDrawer}
      onConfirm={confirmDrawer}
      onCancel={cancelDrawer}
    >
      <VendorBuyOpportunityHeader />
      <VendorBuyingAgreementBuyOpportunityForm />
    </EditDrawer>
  );

  const renderSearch = () => (
    <Box sx={style.selectSmallContainer}>
      <Select
        displayEmpty
        value={filter.opportunity}
        options={opportunityOptions}
        onChange={(event) => {
          setFilter((prev) => ({ ...prev, opportunity: event.target.value as OpportunityType }));
        }}
        label={t('vendor:buyOpportunities.filter.opportunity')}
      />
    </Box>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewVBAs }}
      title={t('vendor:buyOpportunities.title')}
      display="Tab"
      rightTitle={renderAddButton()}
    >
      {renderSearch()}
      {renderList()}
      {renderDrawer()}
    </LayoutPage>
  );
};
