import { MenuItem, MenuItemProps } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useConfirmationModal } from '../../hooks';
import { Entity } from '../../types';

interface VerifyMenuItemProps<T extends Entity> extends MenuItemProps {
  entity: T;
  name: string;
  onVerifyConfirm: (entity: T) => void;
  onClick: () => void;
  actionSuffix: string;
}

export const VerifyMenuItem = <T extends Entity>({
  entity,
  name,
  onVerifyConfirm,
  onClick,
  actionSuffix,
  ...props
}: VerifyMenuItemProps<T>) => {
  const { openModal } = useConfirmationModal();
  const { t } = useTranslation();

  const onVerifyClick = () => {
    openModal({
      confirmText: t('common:action.confirmButton', { action: t('common:action.verify') }),
      title: t('common:action.confirmTitle', {
        name: actionSuffix,
        action: t('common:action.verify'),
        count: 1,
      }),
      description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name }} />,
      onConfirm: () => onVerifyConfirm(entity),
    });
    onClick && onClick();
  };

  return (
    <MenuItem {...props} id="verify" key="verify" onClick={onVerifyClick}>
      {t('common:action.verify')}
    </MenuItem>
  );
};
