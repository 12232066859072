import { ModeCommentRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import { useApi, useInternationalization } from '../../hooks';
import { MemberCommitmentDocumentEntity } from '../../models';
import { FilterPeriod, MemberCommitmentDocumentFilter, OrderBy, PermissionKey } from '../../types';
import { LayoutPage } from '../Layout';
import { Table, TableColumn } from '../Table';
import { forestProduct as forestProductApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { downloadBlob, formatLongDate, formatShortDate } from '../../utils/helper';
import { PeriodFilterSelect } from '../Filter/PeriodFilterSelect';
import { EntityPeriod } from '../EntityPeriod';

const defaultFilter: MemberCommitmentDocumentFilter = {
  isArchived: false,
  period: FilterPeriod.All,
  ...DEFAULT_PAGINATION_FILTER,
};

export const ForestProductCommitmentDocumentList = () => {
  const { t } = useInternationalization();

  const [filter, setFilter] = useState(defaultFilter);
  const { data, isLoading } = useApi(forestProductApi.getAllDocuments, null, filter);

  const { call: download } = useApi(forestProductApi.getDocumentFile, null);

  const downloadFile = async (entity: MemberCommitmentDocumentEntity) => {
    if (entity.id && entity.file) {
      const fileData = await download(entity.id);
      if (fileData) {
        downloadBlob(entity.file.name, fileData);
      }
    }
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderFilter = () => (
    <Stack mb={2}>
      <PeriodFilterSelect filter={filter} setFilter={setFilter} />
    </Stack>
  );

  const renderPeriod = (entity: MemberCommitmentDocumentEntity) => (
    <EntityPeriod period={entity.period} translationContext="male" />
  );

  const renderType = (entity: MemberCommitmentDocumentEntity) => t(`member:commitmentDocuments.type.${entity.type}`);

  const renderFileName = (entity: MemberCommitmentDocumentEntity) => entity.file?.name ?? '';

  const renderLastModified = (entity: MemberCommitmentDocumentEntity) =>
    t('common:entity.modifiedDateShort', {
      date: formatShortDate(entity.modifiedDate),
      name: entity.modifiedBy,
    });

  const renderNotes = (entity: MemberCommitmentDocumentEntity) => entity.memberNotes;

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="member:commitmentDocuments"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
    >
      <TableColumn type="custom" width="10%" id="period" sortable render={renderPeriod} />
      <TableColumn type="custom" width="15%" id="type" sortable render={renderType} />
      <TableColumn type="property" width="20%" id="description" sortable />
      <TableColumn type="custom" width="25%" id="filename" sortable render={renderFileName} />
      <TableColumn
        type="custom"
        id="effectiveDate"
        width="10%"
        sortable
        render={(entity: MemberCommitmentDocumentEntity) => formatLongDate(entity.effectiveDate)}
      />
      <TableColumn
        type="custom"
        id="expiryDate"
        width="10%"
        sortable
        render={(entity: MemberCommitmentDocumentEntity) => formatLongDate(entity.expiryDate)}
      />
      <TableColumn type="custom" width="20%" id="modifiedDate" sortable render={renderLastModified} />
      <TableColumn
        type="icon"
        tooltip
        id="notes"
        align="center"
        sortable
        render={renderNotes}
        iconComponent={ModeCommentRounded}
      />
      <TableColumn
        type="button"
        tooltip
        id="download"
        align="center"
        render={() => t('common:download')}
        iconComponent={DownloadIcon}
        onClick={(entity: MemberCommitmentDocumentEntity) => downloadFile(entity)}
      />
    </Table>
  );

  return (
    <>
      <LayoutPage
        title={t('member:commitmentDocuments.title')}
        display="Tab"
        permissions={{ keys: PermissionKey.MemberCommitmentsView }}
      >
        {renderFilter()}
        {renderTable()}
      </LayoutPage>
    </>
  );
};
