import { object, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorPaymentScheduleEntity } from '../../models';
import { EntityFormSchema } from '../../types';

export const VendorPaymentScheduleSchema = (): EntityFormSchema<VendorPaymentScheduleEntity> => ({
  schema: object({
    monthlyPaymentSchedule: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
    quarterlyPaymentSchedule: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
    semiAnnuallyPaymentSchedule: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
    annuallyPaymentSchedule: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
    paymentScheduleTerm: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
    paymentScheduleFOID: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
    notes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
    vendorNotes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
  }),
});
