import {
  VendorRebateCategoryRebateSummaryEntity,
  VendorRebateCategoryRebateValueEntity,
  VendorRebateCategoryValueEntity,
} from '.';
import { RebateTypeAndUnitType } from '../../types';

export class VendorRebateCategoryRebateEntity {
  id: number;
  calculationMethodId: number | null;
  calculationMethod: VendorRebateCategoryValueEntity | null;
  customMethod_En: string;
  customMethod_Fr: string;
  calculationMethodMemberNotes_Fr: string;
  calculationMethodMemberNotes_En: string;
  calculationMethodNotes: string;
  isTermNote: boolean | null;
  termValue: number | null;
  unit: RebateTypeAndUnitType | null;
  customUnit_En: string;
  customUnit_Fr: string;
  termNote_En: string;
  termNote_Fr: string;
  tbmPaymentDate_En: string;
  tbmPaymentDate_Fr: string;
  memberPaymentDate_En: string;
  memberPaymentDate_Fr: string;
  paymentTermMemberNotes_Fr: string;
  paymentTermMemberNotes_En: string;
  paymentTermNotes: string;
  foidRebates?: VendorRebateCategoryRebateValueEntity[];
  receivableRebates?: VendorRebateCategoryRebateValueEntity[];
  receivableRebatesSummary: VendorRebateCategoryRebateSummaryEntity | null;

  constructor(entity?: VendorRebateCategoryRebateEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.customMethod_En = '';
      this.customMethod_Fr = '';
      this.calculationMethodMemberNotes_Fr = '';
      this.calculationMethodMemberNotes_En = '';
      this.calculationMethodNotes = '';
      this.isTermNote = false;
      this.customUnit_En = '';
      this.customUnit_Fr = '';
      this.termNote_En = '';
      this.termNote_Fr = '';
      this.tbmPaymentDate_En = '';
      this.tbmPaymentDate_Fr = '';
      this.memberPaymentDate_En = '';
      this.memberPaymentDate_Fr = '';
      this.paymentTermMemberNotes_Fr = '';
      this.paymentTermMemberNotes_En = '';
      this.paymentTermNotes = '';
      this.foidRebates = [];
      this.receivableRebates = [];
      this.unit = RebateTypeAndUnitType.Percent;
    }
  }
}
