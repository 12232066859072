import { Box, Grid } from '@mui/material';
import { memberGroup as memberGroupApi } from '../../../api';
import { MAX_LONG_TEXT_LENGTH, MAX_PAGINATION_FILTER, MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useApi, useEntityFormContext, useInternationalization } from '../../../hooks';
import { VendorBuyOpportunityEntity } from '../../../models';
import { MemberGroupType, OpportunityType, Styles } from '../../../types';
import { ControlledSelect, ControlledTranslatedInput } from '../../Form';

const style: Styles = {
  fieldGroup: {
    py: 3,
  },
};

const memberGroupsFilter = {
  memberGroupName: undefined,
  type: MemberGroupType.DCAssociation,
  ...MAX_PAGINATION_FILTER,
  isArchived: false,
};

export const VendorBuyOpportunityDetailsForm = () => {
  const { t } = useInternationalization();
  const { data: memberGroups } = useApi(memberGroupApi.getAll, null, memberGroupsFilter);
  const { setValue, control, watch } = useEntityFormContext<VendorBuyOpportunityEntity>();
  const opportunityWatch = watch('opportunityType');

  return (
    <Box>
      <Grid container spacing={3} sx={style.fieldGroup}>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.shippingDate')}
            name="shippingDate"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.foid')}
            name="foid"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.termValue')}
            name="terms"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.paymentTermDiscount')}
            name="paymentTermDiscount"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.minOrder')}
            name="minimumOrder"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.minOrderQuantity')}
            name="minimumOrderQuantity"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.minFreightPrepaid')}
            name="minimumFreightPrepaid"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        {opportunityWatch === OpportunityType.PoolBuy && (
          <Grid item xs={4}>
            <ControlledSelect
              multiple
              onClear={() => setValue('participatingDCIds', [], { shouldDirty: true })}
              label={t('vendor:buyOpportunities.form.participatingDCs')}
              name="participatingDCIds"
              control={control}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              renderValue={(ids: any) => {
                const groups = memberGroups?.data.filter((m) => ids.includes(m.id));
                return groups ? groups.map((m) => m.name).join(', ') : '';
              }}
              options={memberGroups?.data?.map((u) => ({ label: u.name, value: u.id })) ?? []}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.description')}
            name="description"
            control={control}
            multiline
            rows={5}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
