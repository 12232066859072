import { useParams } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { useApi, useInternationalization, usePageTitle } from '../../../hooks';
import { StaticPageEntity } from '../../../models';
import { routes } from '../../../routes';
import { CmsContent } from '../CmsContent';

export const CmsStaticPageView = () => {
  const { t, getTranslation } = useInternationalization();
  const params = useParams();
  const staticPageId = Number(params.staticPageId);
  const { data, isLoading } = useApi(cmsApi.getStaticPage, null, staticPageId);
  usePageTitle(data ? getTranslation(data, 'name') : '');

  return (
    <CmsContent
      display="Page"
      isLoading={isLoading}
      contentEntity={data ?? new StaticPageEntity()}
      downloadApi={cmsApi.getStaticPageFile}
      downloadBlockFileApi={cmsApi.getStaticPageBlockFile}
      hideModifiedDate
      breadcrumbs={[
        {
          title: t('dashboard:title'),
          href: routes.Member.Dashboard.path,
        },
        { title: data ? getTranslation(data, 'name') : '' },
      ]}
    />
  );
};
