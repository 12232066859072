import axios from 'axios';
import { CategorizationEntity } from '../models';
import {
  CategorizationDisplayOrder,
  CategorizationList,
  CategorizationSorting,
  CategorizationType,
  PaginationEntity,
} from '../types';

export const categorization = {
  getAll: async (type: CategorizationType, isArchived?: boolean): Promise<CategorizationList> => {
    const { data } = await axios.get(`api/${type}`, { params: { isArchived } });
    return data;
  },
  create: async (type: CategorizationType, entity: CategorizationEntity): Promise<CategorizationEntity> => {
    const { data } = await axios.post(`api/${type}`, entity);
    return data;
  },
  update: async (type: CategorizationType, entity: CategorizationEntity): Promise<CategorizationEntity> => {
    const { data } = await axios.put(`api/${type}/${entity.id}`, entity);
    return data;
  },
  archive: async (type: CategorizationType, isArchived: boolean, ids: number[]): Promise<CategorizationEntity[]> => {
    const { data } = await axios.put(`api/${type}/archive`, { isArchived, ids });
    return data;
  },
  updateSorting: async (type: CategorizationType, sorting: CategorizationSorting): Promise<CategorizationList> => {
    const { data } = await axios.put(`api/${type}/sorting`, sorting);
    return data;
  },
  updateDisplayOrder: async (
    type: CategorizationType,
    displayOrders: CategorizationDisplayOrder[],
  ): Promise<CategorizationList> => {
    const { data } = await axios.put(`api/${type}/displayOrder`, displayOrders);
    return data;
  },
  getProductCategoriesSuggestions: async (nameOrKeyword?: string): Promise<PaginationEntity<CategorizationEntity>> => {
    const { data } = await axios.post(`api/productCategory/suggestions`, {
      nameOrKeyword,
      pageNumber: 1,
      pageSize: 10,
    });
    return data;
  },
};
