import { array, number, object, ref, string } from 'yup';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { CommitmentEventCategoryEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema } from '../../types';

export const CommitmentEventCategorySchema = (): EntityFormSchema<CommitmentEventCategoryEntity> => ({
  schema: object().shape(
    {
      name_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
      name_Fr: string().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      productCategoryId: number().required(),
      effectiveYear: number()
        .notRequired()
        .nullable()
        .when('expiryYear', {
          is: (year: number) => year?.toString().length === 4,
          then: (s) => s.max(ref('expiryYear')),
        }),
      expiryYear: number()
        .notRequired()
        .nullable()
        .when('effectiveYear', {
          is: (year: number) => year?.toString().length === 4,
          then: (s) => s.min(ref('effectiveYear')),
        }),
      vendors: array()
        .of(object().shape({ vendorId: number().required() }))
        .min(1),
    },
    [['expiryYear', 'effectiveYear']],
  ),
  errorCodeMap: {
    [ApiErrorCodes.COMMITMENT_EVENT_CATEGORY_NAME_EN_NOT_UNIQUE]: {
      key: 'name_En',
      message: 'common:error.fieldUnique',
    },
    [ApiErrorCodes.COMMITMENT_EVENT_CATEGORY_NAME_FR_NOT_UNIQUE]: {
      key: 'name_Fr',
      message: 'common:error.fieldUnique',
    },
  },
});
