import { useApi, useInternationalization } from '../../hooks';
import { vendor as vendorApi } from '../../api';
import { VendorContactEntity } from '../../models';
import { downloadBlob, formatShortDate } from '../../utils/helper';
import { PhoneNumberList, EmailAddressList } from '../Contact';
import { LayoutPage } from '../Layout';
import { Table, TableColumn } from '../Table';
import { VendorContactFilter, OrderBy, ContactType, PermissionKey } from '../../types';
import { useContext, useState } from 'react';
import { BuyGuideVendorDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';

export const BuyGuideVendorContactList = () => {
  const { vendorId } = useContext(BuyGuideVendorDetailContext);
  const [filter, setFilter] = useState<VendorContactFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });
  const { t, getTranslation } = useInternationalization();
  const { data, isLoading } = useApi(vendorApi.getAllContacts, { skipFetch: !vendorId }, vendorId, filter);
  const { call: downloadExport } = useApi(vendorApi.exportAllContacts, null);

  const exportContacts = async () => {
    if (vendorId !== null) {
      const fileData = await downloadExport(vendorId);
      if (fileData) {
        downloadBlob(fileData.filename ?? 'export.csv', fileData.data);
      }
    }
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderName = (entity: VendorContactEntity) =>
    entity.type === ContactType.Department
      ? getTranslation(entity, 'department')
      : `${entity.firstName} ${entity.lastName}`;

  const renderContactRole = (entity: VendorContactEntity) =>
    entity.role && entity.type !== ContactType.Department ? getTranslation(entity.role, 'name') : '';

  const renderPhoneNumbers = (entity: VendorContactEntity) => <PhoneNumberList data={entity.phoneNumbers} />;

  const renderEmailAddresses = (entity: VendorContactEntity) => <EmailAddressList data={entity.emailAddresses} />;

  const renderLastModified = (entity: VendorContactEntity) => formatShortDate(entity.modifiedDate);

  const renderTable = () =>
    data && (
      <Table
        data={data}
        translationNamespace="buyGuide:contacts"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        onDownloadTableClick={exportContacts}
        hidePending
      >
        <TableColumn type="custom" width="20%" id="name" sortable render={renderName} />
        <TableColumn type="custom" width="20%" id="role" sortable render={renderContactRole} />
        <TableColumn type="custom" width="20%" id="phoneNumbers" render={renderPhoneNumbers} />
        <TableColumn type="custom" width="20%" id="emailAddresses" render={renderEmailAddresses} />
        <TableColumn type="custom" width="20%" id="modifiedDate" sortable align="center" render={renderLastModified} />
      </Table>
    );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewContacts }}
      title={t('buyGuide:contacts.title')}
      display={'Tab'}
    >
      {renderTable()}
    </LayoutPage>
  );
};
