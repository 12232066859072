import { useParams } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { useApi, useInternationalization } from '../../../hooks';
import { PublicationEntity } from '../../../models';
import { routes } from '../../../routes';
import { CmsContent } from '../CmsContent';

export const CmsPublicationView = () => {
  const { t, getTranslation } = useInternationalization();
  const params = useParams();
  const publicationId = Number(params.publicationId);
  const { data, isLoading } = useApi(cmsApi.getPublication, null, publicationId);

  return (
    <CmsContent
      display="Page"
      isLoading={isLoading}
      contentEntity={data ?? new PublicationEntity()}
      downloadApi={cmsApi.getPublicationFile}
      downloadBlockFileApi={cmsApi.getPublicationBlockFile}
      breadcrumbs={[
        {
          title: t('cms:sections.publications'),
          href: routes.Member.Cms.Publications.path,
        },
        { title: data ? getTranslation(data, 'name') : '' },
      ]}
    />
  );
};
