import { DrawerProps, ModalProps, Drawer as MuiDrawer } from '@mui/material';

export const Drawer = (props: DrawerProps) => {
  const onClose: ModalProps['onClose'] = (event, reason) => {
    if (reason !== 'backdropClick' && props.onClose) {
      return props.onClose(event, reason);
    }
  };

  return <MuiDrawer {...props} onClose={onClose} />;
};
