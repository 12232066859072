import { AddRounded } from '@mui/icons-material';
import { Box, Button, Link, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useOutlet } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useAuth, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { HelpTrainingEntity } from '../../../models';
import { routes } from '../../../routes';
import { HelpTrainingSchema } from '../../../schemas';
import {
  ContentFilter,
  ContentStatus,
  HelpTrainingFilter,
  HelpTrainingType,
  OrderBy,
  PermissionKey,
  Styles,
} from '../../../types';
import { FilterContainer } from '../../Container';
import { EditDrawer } from '../../EditDrawer';
import { Autocomplete, Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { CmsContentStatus } from '../CmsContentStatus';
import { CmsHelpTrainingForm } from './CmsHelpTrainingForm';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
    my: 3,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
};

const defaultFilter: HelpTrainingFilter = {
  helpTrainingNameOrKeyword: '',
  filter: undefined,
  status: undefined,
  type: undefined,
  isReadOnly: true,
  ...DEFAULT_PAGINATION_FILTER,
};

interface CmsHelpTrainingListProps {
  isReadOnly?: boolean;
}

export const CmsHelpTrainingList = ({ isReadOnly = true }: CmsHelpTrainingListProps) => {
  const { isMemberUser, isVendorUser } = useAuth();
  const outlet = useOutlet();
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const navigate = useNavigate();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const helpTraining = useMemo(() => new HelpTrainingEntity(), []);
  const [filter, setFilter] = useState({ ...defaultFilter, isReadOnly });
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const statusOptions = useEnumList(ContentStatus, 'cms:status', true);
  const filterOptions = useEnumList(ContentFilter, 'cms:showFilter', false, undefined, false);
  const typeOptions = useEnumList(HelpTrainingType, 'cms:helpTrainings.form.type', true);
  const { call: create } = useApi(cmsApi.createHelpTraining, { successKey: 'common:success.action' });
  const { data, isLoading, refresh } = useApi(cmsApi.getAllHelpTrainings, { skipFetch: true }, filter);
  usePageTitle('cms:helpTrainings.title');

  useEffect(() => {
    refresh();
  }, [currentLanguage, refresh]);

  if (outlet) {
    return outlet;
  }

  const saveDrawer = async (entity: HelpTrainingEntity) => {
    const createdEntity = await create(entity);
    await refresh();
    return createdEntity;
  };

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({
      ...prev,
      ...(checked
        ? { ...defaultFilter, filter: ContentFilter.Upcoming, isReadOnly: isReadOnly }
        : { ...defaultFilter, isReadOnly: isReadOnly }),
    }));
    setIsAdvancedMode(checked);
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const clearFilter = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter, filter: ContentFilter.Upcoming, isReadOnly: isReadOnly }));
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onStatusChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, status: (event.target.value as ContentStatus) || undefined, pageNumber: 1 }));
  };

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, type: (event.target.value as HelpTrainingType) || undefined, pageNumber: 1 }));
  };

  const onFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, filter: event.target.value as ContentFilter, pageNumber: 1 }));
  };

  const getDetailPath = (id: number) =>
    isReadOnly
      ? isMemberUser
        ? generatePath(routes.Member.Help.Detail.path, { helpTrainingId: id })
        : isVendorUser
        ? generatePath(routes.Vendor.Help.Detail.path, { helpTrainingId: id })
        : generatePath(routes.Admin.Help.Detail.path, { helpTrainingId: id })
      : generatePath(routes.Admin.HelpTrainingDetails.path, { helpTrainingId: id });

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.CmsHelpEdit}>
      <Button variant="contained" startIcon={<AddRounded />} onClick={() => setIsAddOpen(true)}>
        {t('cms:helpTrainings.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderActionsMenuItems = (item: HelpTrainingEntity) => {
    return [
      <MenuItem id="view" key="view" onClick={() => navigate(getDetailPath(item.id))}>
        {t('common:view')}
      </MenuItem>,
    ];
  };

  const renderHelpTrainingLink = (id: number, text: string | undefined) =>
    !!text && (
      <Link underline="always" href={getDetailPath(id)}>
        {text}
      </Link>
    );

  const renderTextSearch = () => (
    <Autocomplete
      value={filter.helpTrainingNameOrKeyword}
      sx={style.textContainer}
      noOptionsText={t('cms:helpTrainings.search.notFound')}
      apiFunction={cmsApi.getHelpTrainingSuggestions}
      apiExtraParams={[isReadOnly]}
      label={t('cms:helpTrainings.search.title')}
      placeholder={t('cms:helpTrainings.search.placeholder')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : getTranslation(option, 'name'))}
      onApplySearch={(searchText) =>
        setFilter((prev) => ({ ...prev, helpTrainingNameOrKeyword: searchText ?? undefined, pageNumber: 1 }))
      }
      onSelectItem={(item) => item && navigate(getDetailPath(item.id))}
    />
  );
  const renderAdvancedSearch = () => (
    <FilterContainer onClear={!isReadOnly ? clearFilter : undefined} my={3}>
      {!isReadOnly && (
        <Box sx={style.selectSmallContainer}>
          <Select
            displayEmpty
            options={filterOptions}
            value={filter.filter ?? ''}
            onChange={onFilterChange}
            label={t('cms:helpTrainings.search.show')}
          />
        </Box>
      )}
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={typeOptions}
          value={filter.type ?? ''}
          onChange={onTypeChange}
          label={t('cms:helpTrainings.search.type')}
        />
      </Box>
      {!isReadOnly && (
        <Box sx={style.selectSmallContainer}>
          <Select
            displayEmpty
            options={statusOptions}
            value={filter.status ?? ''}
            onChange={onStatusChange}
            label={t('cms:helpTrainings.search.status')}
          />
        </Box>
      )}
    </FilterContainer>
  );

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="cms:helpTrainings"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={renderActionsMenuItems}
    >
      {!isReadOnly && (
        <TableColumn
          type="custom"
          sortable
          width="15%"
          id="status"
          render={(item: HelpTrainingEntity) => <CmsContentStatus status={item.status} />}
        />
      )}
      <TableColumn
        type="custom"
        sortable
        width={!isReadOnly ? '30%' : '40%'}
        id="name"
        render={(item: HelpTrainingEntity) => renderHelpTrainingLink(item.id, getTranslation(item, 'name'))}
      />
      <TableColumn
        type="custom"
        sortable
        width={!isReadOnly ? '55%' : '60%'}
        id="type"
        render={({ type }: HelpTrainingEntity) => (type ? t(`cms:helpTrainings.table.typeValue.${type}`) : '')}
      />
    </Table>
  );

  return (
    <LayoutPage
      display={isReadOnly ? 'Page' : 'Tab'}
      title={t('cms:helpTrainings.title')}
      rightTitle={!isReadOnly ? renderAddButton() : undefined}
    >
      <EditDrawer
        title={
          <HasPermissions keys={PermissionKey.CmsHelpEdit}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AddRounded />
              <Typography variant="drawerTitle">{t('cms:helpTrainings.actions.add')}</Typography>
            </Stack>
          </HasPermissions>
        }
        open={isAddOpen}
        redirectPath={(item) => getDetailPath(item.id)}
        entity={helpTraining}
        schema={HelpTrainingSchema()}
        redirectLabel={t('cms:helpTrainings.actions.saveOpen')}
        onSave={saveDrawer}
        onConfirm={confirmDrawer}
        onCancel={cancelDrawer}
      >
        <CmsHelpTrainingForm />
      </EditDrawer>
      <Box sx={style.filterBox}>
        <Typography variant="searchTitle" color="primary.dark">
          {t('cms:helpTrainings.search.title')}
        </Typography>
        <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
        <Typography variant="searchTitle" color="grey.600">
          {t('cms:helpTrainings.search.filterList')}
        </Typography>
      </Box>
      {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
      {renderTable()}
    </LayoutPage>
  );
};
