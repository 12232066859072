import { useContext, useState } from 'react';
import { member as memberApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { MemberDetailContext } from '../../../contexts';
import { useApi } from '../../../hooks';
import { MemberContactFilter } from '../../../types';
import { downloadBlob } from '../../../utils/helper';
import { MemberContactsList } from './MemberContactsList';

export const MemberContactsListVendor = () => {
  const { member, memberId } = useContext(MemberDetailContext);
  const [filter, setFilter] = useState<MemberContactFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });

  const getAllContactsApi = useApi(memberApi.getAllContacts, { skipFetch: !member }, member?.id, filter);
  const { call: downloadExport } = useApi(memberApi.exportAllContacts, null);

  const exportContacts = async () => {
    if (memberId !== null) {
      const fileData = await downloadExport(memberId);
      if (fileData) {
        downloadBlob(fileData.filename ?? 'export.csv', fileData.data);
      }
    }
  };

  return (
    <MemberContactsList
      fetchApi={getAllContactsApi}
      onSave={async () => null}
      filter={filter}
      setFilter={setFilter}
      tableProps={{ hidePending: true, onDownloadTableClick: exportContacts }}
    />
  );
};
