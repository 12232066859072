import { Box, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { categorization as categorizationApi, roleGroup as roleGroupApi, vendor as vendorApi } from '../../api';
import { MAX_PAGINATION_FILTER } from '../../constants';
import { VendorContext } from '../../contexts';
import { useApi, useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { RoleGroup, SelectOption, Styles, TranslationContext, VendorFilter } from '../../types';
import { areArrayEquals } from '../../utils/helper';
import { FilterContainer } from '../Container';
import { ArchiveFilterSelect } from '../Filter/ArchiveFilterSelect';
import { MailSyncFilterSelect } from '../Filter/MailSyncFilterSelect';
import { Autocomplete, MultiSelect, Select } from '../Form';

const style: Styles = {
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectContainer: {
    width: {
      xs: '100%',
      sm: '250px',
    },
  },
};

const defaultTextSearchFilter = { vendorNameOrNumber: undefined };
const defaultAdvancedSearchFilter: VendorFilter = {
  designations: undefined,
  buyersIds: undefined,
  isVisible: undefined,
  isActive: undefined,
  mailSyncErrorOnly: undefined,
};

export const VendorListSearch = () => {
  const { t, getTranslation } = useInternationalization();
  const navigate = useNavigate();
  const { filter, setFilter } = useContext(VendorContext);
  const [isAdvancedMode, setIsAdvancedMode] = useState(
    !!filter.designations || !!filter.buyersIds || !!filter.isVisible || !!filter.mailSyncErrorOnly,
  );
  const buyers = useApi(roleGroupApi.getUsers, null, RoleGroup.Buyer, MAX_PAGINATION_FILTER);
  const designations = useApi(categorizationApi.getAll, null, 'vendorDesignation', false);

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...(checked ? defaultTextSearchFilter : defaultAdvancedSearchFilter) }));
    setIsAdvancedMode(checked);
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const onBooleanChange = (event: SelectChangeEvent<unknown>, propertyName: keyof VendorFilter) => {
    const value = !event.target.value ? undefined : event.target.value === 'true';
    setFilter((prev) => ({ ...prev, [propertyName]: value, pageNumber: 1 }));
  };

  const onDesignationBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.designations ?? [], selected)) {
      setFilter((prev) => ({ ...prev, designations: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const onBuyerBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.buyersIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, buyersIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const getBooleanOptions = (context: TranslationContext = 'male'): SelectOption<string>[] => [
    { label: t('common:filter.all', { context }), value: '' },
    { label: t('common:boolean.true'), value: 'true' },
    { label: t('common:boolean.false'), value: 'false' },
  ];

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear}>
      <Box sx={style.selectContainer}>
        <MultiSelect
          label={t('vendor:search.buyerIds')}
          options={buyers.data?.data.map((b) => ({ label: b.fullName, value: b.id })) ?? []}
          onConfirm={onBuyerBlur}
          values={filter.buyersIds || []}
        />
      </Box>
      <Box sx={style.selectContainer}>
        <MultiSelect
          label={t('vendor:search.designations')}
          options={designations.data?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
          onConfirm={onDesignationBlur}
          values={filter.designations || []}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={getBooleanOptions()}
          value={filter.isVisible ?? ''}
          onChange={(e) => onBooleanChange(e, 'isVisible')}
          label={t('vendor:search.isVisible')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={getBooleanOptions()}
          value={filter.isActive ?? ''}
          onChange={(e) => onBooleanChange(e, 'isActive')}
          label={t('vendor:search.isActive')}
        />
      </Box>
      <MailSyncFilterSelect filter={filter} setFilter={setFilter} />
    </FilterContainer>
  );

  const renderTextSearch = () => (
    <Autocomplete
      minSearchTextLength={1}
      value={filter.vendorNameOrNumber}
      sx={style.textContainer}
      noOptionsText={t('vendor:search.notFound')}
      apiFunction={vendorApi.getSuggestions}
      label={t('vendor:search.title')}
      placeholder={t('vendor:search.placeholder')}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
      }}
      onApplySearch={(searchText) =>
        setFilter((prev) => ({ ...prev, vendorNameOrNumber: searchText ?? undefined, pageNumber: 1 }))
      }
      onSelectItem={(item) => item && navigate(generatePath(routes.Admin.Vendor.Detail.path, { vendorId: item.id }))}
    />
  );

  return (
    <Stack>
      <Stack spacing={3}>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark">
            {t('vendor:search.title')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('vendor:search.filterList')}
          </Typography>
        </Box>
        {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
      </Stack>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />
    </Stack>
  );
};
