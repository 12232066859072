import { useContext } from 'react';
import { cms as cmsApi } from '../../../api';
import { CmsPublicationContext } from '../../../contexts/Cms';
import { PublicationEntity } from '../../../models';
import { PublicationSchema } from '../../../schemas';
import { PermissionKey } from '../../../types';
import { CmsContent } from '../CmsContent';

export const CmsPublicationContent = () => {
  const { publication, isLoading, fetchData } = useContext(CmsPublicationContext);

  return (
    <CmsContent
      contentEntity={publication ?? new PublicationEntity()}
      isLoading={isLoading}
      viewPermissions={PermissionKey.CmsNewsView}
      editPermissions={PermissionKey.CmsNewsEdit}
      approvePermissions={PermissionKey.CmsNewsApprove}
      updateApi={cmsApi.updatePublication}
      uploadFilesApi={cmsApi.uploadPublicationFiles}
      uploadBlockFileApi={cmsApi.uploadPublicationBlockFile}
      downloadApi={cmsApi.getPublicationFile}
      deleteFilesApi={cmsApi.deletePublicationFiles}
      updateFilesLanguageApi={cmsApi.updatePublicationFilesLanguage}
      deleteContentBlockFileApi={cmsApi.deletePublicationBlockFile}
      downloadBlockFileApi={cmsApi.getPublicationBlockFile}
      schema={PublicationSchema()}
      onConfirm={fetchData}
    />
  );
};
