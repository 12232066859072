import * as Yup from 'yup';
import i18n from '../i18n';

Yup.setLocale({
  mixed: {
    default: 'common:error.fieldInvalid',
    required: 'common:error.fieldRequired',
    notType: function notType(params) {
      switch (params.type) {
        case 'number':
          return 'common:error.fieldNumber';
        default:
          return 'common:error.fieldInvalid';
      }
    },
  },
  array: {
    min: ({ min }) => i18n.t('common:error.arrayMin', { min }),
    max: ({ max }) => i18n.t('common:error.arrayMax', { max }),
  },
  string: {
    email: 'common:error.fieldEmail',
    max: ({ max }) => i18n.t('common:error.maxLength', { length: max }),
  },
  number: {
    min: ({ min }) => i18n.t('common:error.fieldMin', { min }),
  },
  date: {
    min: ({ min }) => i18n.t('common:error.dateMin', { min }),
    max: ({ max }) => i18n.t('common:error.dateMax', { max }),
  },
});

declare module 'yup' {
  interface StringSchema {
    frRequired(): this;
    enRequired(): this;
  }
}

Yup.addMethod<Yup.StringSchema>(Yup.string, 'frRequired', function () {
  return this.required(() => `${i18n.t('common:translationLanguage.Fr')} ${i18n.t('common:error.fieldRequired')}`);
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'enRequired', function () {
  return this.required(() => `${i18n.t('common:translationLanguage.En')} ${i18n.t('common:error.fieldRequired')}`);
});
