import { Typography } from '@mui/material';
import { useContext } from 'react';
import { vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryProgramDetailContext } from '../../../contexts/Vendor';
import { useApi, useInternationalization } from '../../../hooks';
import { Styles } from '../../../types';
import { Container } from '../../Container';
import { VendorTierGrowthList } from '../TiersGrowths';

const style: Styles = {
  container: {
    py: 4,
  },
  header: {
    pb: 3,
  },
};

export const VendorRebateCategoryProgramTiersGrowths = () => {
  const { t } = useInternationalization();
  const { vendorId, vendorRebateCategoryId, rebateProgramId } = useContext(VendorRebateCategoryProgramDetailContext);
  const { data: tiers, isLoading: isTierLoading } = useApi(
    vendorApi.getAllRebateCategoryProgramTier,
    null,
    vendorId,
    vendorRebateCategoryId,
    rebateProgramId,
  );
  const { data: growths, isLoading: isGrowthsLoading } = useApi(
    vendorApi.getAllRebateCategoryProgramGrowth,
    null,
    vendorId,
    vendorRebateCategoryId,
    rebateProgramId,
  );

  return (
    <Container isHighlighted sx={style.container}>
      <Typography variant="h2" sx={style.header}>
        {t('vendor:rebateCategory.tierAndGrowth.title')}
      </Typography>
      <VendorTierGrowthList isLoading={isTierLoading || isGrowthsLoading} tiers={tiers} growths={growths} />
    </Container>
  );
};
