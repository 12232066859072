import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MemberGroupMemberPermissionsForm, MemberGroupNotesForm, MemberGroupTargetedMembershipForm } from '.';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { useEntityFormContext, useEnumList } from '../../hooks';
import { MemberGroupEntity } from '../../models';
import { MemberGroupType, Styles } from '../../types';
import { ControlledInput, ControlledRadioSelect } from '../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
  radioSelect: {
    pt: 1,
  },
};

export const MemberGroupForm = () => {
  const { t } = useTranslation();
  const { control } = useEntityFormContext<MemberGroupEntity>();
  const memberGroupTypeOptions = useEnumList(MemberGroupType, 'memberGroup:type', false, 'male', false, true);

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ControlledInput
            maxLength={MAX_SHORT_TEXT_LENGTH}
            label={t('memberGroup:table.name')}
            name="name"
            variant="standard"
            control={control}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="drawerContentSubtitle">{t('memberGroup:form.type')}</Typography>
          <ControlledRadioSelect
            options={memberGroupTypeOptions}
            name="type"
            control={control}
            hideLabel
            row
            sx={style.radioSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="drawerContentSubtitle">{t('memberGroup:form.targetedMembership')}</Typography>
          <MemberGroupTargetedMembershipForm />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="drawerContentSubtitle">{t('memberGroup:form.customPermissions')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <MemberGroupMemberPermissionsForm />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="drawerContentSubtitle">{t('memberGroup:form.notes')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <MemberGroupNotesForm />
        </Grid>
      </Grid>
    </Box>
  );
};
