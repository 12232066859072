import axios from 'axios';
import { AdEntity, ChangeLogEntity } from '../../models';
import { AdFilter, ChangeLogFilter, ContentTriggers, LanguageCode, PaginationEntity } from '../../types';

export const Ad = {
  getAllAds: async (adFilter: AdFilter): Promise<PaginationEntity<AdEntity>> => {
    const { data } = await axios.post('api/ad/search', adFilter);
    return { ...data, data: data.data.map((d: AdEntity) => new AdEntity(d)) };
  },
  getAd: async (adId: number): Promise<AdEntity> => {
    const { data } = await axios.get(`api/ad/${adId}`);
    return new AdEntity(data);
  },
  getAdSuggestions: async (memberNameOrNumber: string, isArchived?: boolean): Promise<PaginationEntity<AdEntity>> => {
    const { data } = await axios.post('api/ad/search', {
      memberNameOrNumber,
      pageSize: 10,
      pageNumber: 1,
      isArchived,
    });
    return { ...data, data: data.data.map((d: AdEntity) => new AdEntity(d)) };
  },
  createAd: async (entity: AdEntity): Promise<AdEntity> => {
    const { data } = await axios.post('api/ad', entity);
    return new AdEntity(data);
  },
  updateAd: async (entity: AdEntity): Promise<AdEntity> => {
    const { data } = await axios.put(`api/ad/${entity.id}`, entity);
    return new AdEntity(data);
  },
  uploadAdFile: async (adId: number, language: LanguageCode, file: File): Promise<AdEntity> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.put(`api/ad/${adId}/file/${language}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new AdEntity(data);
  },
  getAdFile: async (adId: number, language: LanguageCode): Promise<Blob> => {
    const { data } = await axios.get(`api/ad/${adId}/file/${language}`, {
      responseType: 'blob',
    });
    return data;
  },
  updateAdWorkflow: async (adId: number, trigger: ContentTriggers): Promise<AdEntity> => {
    const { data } = await axios.post(`api/ad/${adId}/${trigger}`);
    return new AdEntity(data);
  },
  getAdChangeLogs: async (adId: number, filter: ChangeLogFilter): Promise<PaginationEntity<ChangeLogEntity>> => {
    const { data } = await axios.post(`api/ad/${adId}/changeLog/search`, {
      ...filter,
    });
    return { ...data, data: data.data.map((v: ChangeLogEntity) => new ChangeLogEntity(v)) };
  },
  addAdComment: async (adId: number, description: string): Promise<ChangeLogEntity> => {
    const { data } = await axios.post(`api/ad/${adId}/comment`, {
      description,
    });
    return new ChangeLogEntity(data);
  },
};
