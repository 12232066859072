import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { auth as authApi } from '../api';
import { Breadcrumbs } from '../components';
import { ControlledInput } from '../components/Form';
import { LayoutPublic } from '../components/Layout';
import { useInternationalization, usePageTitle } from '../hooks';
import { routes } from '../routes';
import { palette } from '../styles/palette';
import { PasswordReinitializationRequest, Styles } from '../types';

const style: Styles = {
  button: {
    width: '100%',
  },
  message: {
    color: palette.success.main,
    fontWeight: 'bold',
  },
};

export function ForgotPassword() {
  const { t } = useInternationalization();
  usePageTitle('auth:forgotPassword.title');
  const [errorKey, setErrorKey] = useState('');
  const [messageKey, setMessageKey] = useState('');

  const validation = Yup.object({
    email: Yup.string().required(t('common:error.fieldRequired', { name: t('auth:forgotPassword.username') })),
  });

  const { handleSubmit, control, formState, reset } = useForm<PasswordReinitializationRequest>({
    defaultValues: { email: '' },
    resolver: yupResolver(validation),
  });

  const onSubmit = async (formData: PasswordReinitializationRequest) => {
    try {
      await authApi.forgotPassword(formData);
      reset();
      setMessageKey('auth:forgotPassword.success');
    } catch {
      setErrorKey('auth:forgotPassword.invalid');
    }
  };

  return (
    <LayoutPublic title={t('auth:forgotPassword.title')}>
      <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <ControlledInput label={t('auth:forgotPassword.username')} name="email" control={control} />
          <LoadingButton sx={style.button} loading={formState.isSubmitting} type="submit" variant="contained">
            {t('auth:forgotPassword.action')}
          </LoadingButton>
          {errorKey && <Typography variant="inputError">{t(errorKey)}</Typography>}
          {messageKey && (
            <Typography variant="body1" sx={style.message}>
              {t(messageKey)}
            </Typography>
          )}
        </Stack>
        <Breadcrumbs breadcrumbs={[{ href: routes.Login.path, title: t('auth:backToLogin') }]} />
      </form>
    </LayoutPublic>
  );
}
