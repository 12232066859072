import { DownloadRounded } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { EntityDetails } from '..';
import { vendor as vendorApi } from '../../api';
import { BuyGuideVendorDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { useApi, usePageTitle } from '../../hooks';
import { routes } from '../../routes';
import { palette } from '../../styles/palette';
import { PermissionKey, Styles } from '../../types';
import { downloadBlob } from '../../utils/helper';
import { Container } from '../Container';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';
import { BuyGuideVendorForm } from './BuyGuideVendorForm';
import { BuyGuideVendorChangeLogs } from './ChangeLog/BuyGuideVendorChangeLogs';

const style: Styles = {
  loading: {
    mx: 2,
  },
  favouriteIcon: {
    '&:hover': {
      color: palette.primary.deep,
    },
  },
};

export const BuyGuideVendorDetail = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const params = useParams();
  const vendorId = Number(params.vendorId);
  const { data, isLoading, refresh } = useApi(vendorApi.get, null, vendorId);

  const { call: favouriteCall, isLoading: isFavouriteLoading } = useApi(vendorApi.updateFavourites, null);
  const { call: downloadExport, isLoading: isExportLoading } = useApi(vendorApi.export, null);
  usePageTitle(data?.name ?? '');

  const onFavoriteClick = async () => {
    !isFavouriteLoading && data?.id && (await favouriteCall([data.id], !data.isFavourite));
    refresh();
  };

  const onExportClick = async () => {
    if (data?.id) {
      const fileData = await downloadExport(data.id);
      if (fileData) {
        downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
      }
    }
  };

  const tabs = [
    <LinkTab
      label={t('buyGuide:sections.rebatePrograms')}
      value={generatePath(routes.Member.BuyGuideDetail.RebatePrograms.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewPrograms }}
    />,
    <LinkTab
      label={t('buyGuide:sections.documents')}
      value={generatePath(routes.Member.BuyGuideDetail.Documents.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewFiles }}
    />,
    <LinkTab
      label={t('buyGuide:sections.buyOpportunities')}
      value={generatePath(routes.Member.BuyGuideDetail.BuyOpportunities.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewBuys }}
    />,
    <LinkTab
      label={t('buyGuide:sections.generalConditions')}
      value={generatePath(routes.Member.BuyGuideDetail.GeneralConditions.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewConditions }}
    />,
    <LinkTab
      label={t('buyGuide:sections.contacts')}
      value={generatePath(routes.Member.BuyGuideDetail.Contacts.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewContacts }}
    />,
    <LinkTab
      label={t('buyGuide:sections.locations')}
      value={generatePath(routes.Member.BuyGuideDetail.Locations.path, { vendorId })}
      permissions={{ keys: PermissionKey.VendorViewContacts }}
    />,
  ];

  const favouriteButton = (
    <IconButton onClick={onFavoriteClick} key="favourite">
      <Tooltip arrow placement="top" title={t(`buyGuide:form.isFavourite`)}>
        {data?.isFavourite ? (
          <StarIcon htmlColor={palette.primary.deep} sx={style.favouriteIcon} />
        ) : (
          <StarOutlineIcon sx={style.favouriteIcon} />
        )}
      </Tooltip>
    </IconButton>
  );

  const exportButton = (
    <IconButton onClick={onExportClick} disabled={isExportLoading} key="export">
      <Tooltip arrow placement="top" title={t(`common:export`)}>
        <DownloadRounded htmlColor={palette.primary.deep} />
      </Tooltip>
    </IconButton>
  );

  return (
    <BuyGuideVendorDetailContext.Provider value={{ vendor: data, vendorId }}>
      <LayoutPage
        outlet={outlet}
        tabs={tabs}
        display="Detail"
        permissions={{ keys: [PermissionKey.VendorViewBuyGuide] }}
      >
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EntityDetails
              titleLabel={t(`buyGuide:form.vendor`)}
              title="name"
              entity={data}
              actionButtons={[favouriteButton, <BuyGuideVendorChangeLogs key="changeLogs" />, exportButton]}
              breadcrumbs={[
                {
                  title: t('buyGuide:title'),
                  href: routes.Member.BuyGuide.path,
                },
                { title: data?.name },
              ]}
            >
              <BuyGuideVendorForm />
            </EntityDetails>
          )}
        </Container>
      </LayoutPage>
    </BuyGuideVendorDetailContext.Provider>
  );
};
