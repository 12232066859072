import { Grid } from '@mui/material';
import { categorization as categorizationApi } from '../../api';
import { useApi, useAuth, useEntityFormContext, useInternationalization } from '../../hooks';
import { TargetedEntity } from '../../types';
import { ControlledCheckbox, ControlledMultiSelect } from '../Form';

export const TargetedMembershipCategorizationForm = () => {
  const { isInternalUser, isVendorUser } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { control, watch } = useEntityFormContext<TargetedEntity>();
  const { data: regions } = useApi(categorizationApi.getAll, null, 'region', false);
  const { data: membershipTypes } = useApi(categorizationApi.getAll, null, 'membershipType', false);

  const isBasedOnMemberCriteriaWatch = watch('targetedMembership.isBasedOnMemberCriteria');

  return (
    <Grid container spacing={3}>
      {isInternalUser && (
        <Grid item xs={12} mt={1.5}>
          <ControlledCheckbox
            label={t('vendor:targetedMembership.form.isBasedOnMemberCriteria')}
            name="targetedMembership.isBasedOnMemberCriteria"
            control={control}
          />
        </Grid>
      )}
      {isBasedOnMemberCriteriaWatch && (
        <>
          <Grid item xs={12} md={6}>
            <ControlledMultiSelect
              label={t('vendor:targetedMembership.form.regions')}
              name="targetedMembership.regionIds"
              options={regions?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
              control={control}
              multiple
              defaultToAll
            />
          </Grid>
          {(isInternalUser || isVendorUser) && (
            <Grid item xs={12} md={6}>
              <ControlledMultiSelect
                label={t('vendor:targetedMembership.form.membershipTypes')}
                name="targetedMembership.membershipTypeIds"
                options={membershipTypes?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
                control={control}
                multiple
                defaultToAll
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
