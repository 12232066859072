import { useEntityFormContext, useEnumList, useInternationalization } from '../../hooks';
import { ProfileEntity } from '../../models';
import { NotificationEmailFrequency } from '../../types';
import { ControlledRadioSelect } from '../Form';

export const NotificationEmailFrequencyForm = () => {
  const { t } = useInternationalization();
  const { control } = useEntityFormContext<ProfileEntity>();
  const emailFrequencyOptions = useEnumList(
    NotificationEmailFrequency,
    'notification:emailFrequency.type',
    false,
    undefined,
    false,
  );

  return (
    <ControlledRadioSelect
      control={control}
      name="notificationEmailFrequency"
      label={t('notification:emailFrequency.label')}
      options={emailFrequencyOptions}
    />
  );
};
