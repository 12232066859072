import axios from 'axios';
import { InternalContactEntity } from '../../models';
import { ExportType, InternalContactFilter, PaginationEntity } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const InternalContact = {
  getAllContacts: async (contactFilter: InternalContactFilter): Promise<PaginationEntity<InternalContactEntity>> => {
    const { data } = await axios.post(`api/internalContact/search`, contactFilter);
    return { ...data, data: data.data.map((v: InternalContactEntity) => new InternalContactEntity(v)) };
  },
  getContact: async (contactId: number): Promise<InternalContactEntity> => {
    const { data } = await axios.get(`api/internalContact/${contactId}`);
    return new InternalContactEntity(data);
  },
  createContact: async (entity: InternalContactEntity): Promise<InternalContactEntity> => {
    const { data } = await axios.post(`api/internalContact`, entity);
    return new InternalContactEntity(data);
  },
  updateContact: async (entity: InternalContactEntity): Promise<InternalContactEntity> => {
    const { data } = await axios.put(`api/internalContact/${entity.id}`, entity);
    return new InternalContactEntity(data);
  },
  archiveContact: async (ids: number[], isArchived: boolean): Promise<InternalContactEntity[]> => {
    const { data } = await axios.put(`api/internalContact/archive`, { isArchived, ids });
    return data.map((v: InternalContactEntity) => new InternalContactEntity(v));
  },
  exportEmployees: async (
    contactFilter: InternalContactFilter,
    exportType: ExportType,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post(`api/internalContact/export?type=${exportType}`, contactFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
