import { AddRounded, EditRounded } from '@mui/icons-material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { cci as cciApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { CommitmentEventDetailContext } from '../../../contexts';
import { useApi, useAuth, useGlobalEdit, useInternationalization } from '../../../hooks';
import { CommitmentEventCategoryEntity } from '../../../models';
import { CommitmentEventCategorySchema } from '../../../schemas/Commitment';
import { CommitmentEventCategoryFilter, PermissionKey } from '../../../types';
import { Container } from '../../Container';
import { EditDrawer } from '../../EditDrawer';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { LayoutPage } from '../../Layout';
import { CommitmentEventCategoriesAccordion } from './CommitmentEventCategoriesAccordion';
import { CommitmentEventCategoryForm } from './CommitmentEventCategoryForm';
import { CommitmentEventCategorySelect } from './CommitmentEventCategorySelect';

const defaultFilter: CommitmentEventCategoryFilter = {
  isArchived: false,
  ...MAX_PAGINATION_FILTER,
};

export const CommitmentEventCategoryList = () => {
  const { t } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { hasPermissions } = useAuth();
  const [filter, setFilter] = useState(defaultFilter);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { eventId, event, readonly } = useContext(CommitmentEventDetailContext);
  const newCategory = useMemo(() => new CommitmentEventCategoryEntity(), []);

  const [selectedCategory, setSelectedCategory] = useState(newCategory);
  const { data, isLoading, refresh } = useApi(cciApi.getAllCommitmentEventCategories, null, eventId, filter);
  const { call: create } = useApi(cciApi.createCommitmentEventCategory, { successKey: 'common:success.action' });
  const { call: update } = useApi(cciApi.updateCommitmentEventCategory, { successKey: 'common:success.action' });

  const onCreateCategory = (name: string) => {
    setSelectedCategory({ ...selectedCategory, name_En: name, effectiveYear: event?.year });
    setIsAddOpen(true);
  };

  const onAddExistingCategory = (category: CommitmentEventCategoryEntity) => {
    setSelectedCategory({
      ...selectedCategory,
      name_En: category.name_En,
      name_Fr: category.name_Fr,
      effectiveYear: event?.year,
    });
    setIsAddOpen(true);
  };

  const onEditClick = (category: CommitmentEventCategoryEntity) => {
    setSelectedCategory(category);
    setIsAddOpen(true);
  };

  const renderSearch = () => (
    <Stack spacing={1}>
      {!readonly && !globalEditing && hasPermissions(PermissionKey.CciManageEvents) ? (
        <Box alignItems="center" display="flex">
          <CommitmentEventCategorySelect
            eventId={eventId}
            currentCategories={data?.data ?? []}
            onCreateCategory={onCreateCategory}
            onAddExistingCategory={onAddExistingCategory}
          />
        </Box>
      ) : undefined}
      <ArchiveFilterSelect translationContext="male" filter={filter} setFilter={setFilter} />
    </Stack>
  );

  const saveDrawer = async (entity: CommitmentEventCategoryEntity) => {
    const createdOrUpdatedEntity = !entity.id ? await create(eventId, entity) : await update(eventId, entity);
    await refresh();
    return createdOrUpdatedEntity;
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
    setSelectedCategory(newCategory);
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
    setSelectedCategory(newCategory);
  };

  return (
    <LayoutPage
      display="Tab"
      permissions={{ keys: PermissionKey.CciViewCommitments }}
      title={t('cci:eventCategories.title')}
    >
      <Container>{isLoading && <CircularProgress size={20} />}</Container>
      <EditDrawer
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            {selectedCategory.id ? <EditRounded /> : <AddRounded />}
            <Typography variant="drawerTitle">
              {t(selectedCategory.id ? 'cci:eventCategories.actions.edit' : 'cci:eventCategories.actions.add')}
            </Typography>
          </Stack>
        }
        entity={selectedCategory}
        schema={CommitmentEventCategorySchema()}
        onSave={saveDrawer}
        open={isAddOpen}
        onCancel={cancelDrawer}
        onConfirm={confirmDrawer}
      >
        <CommitmentEventCategoryForm />
      </EditDrawer>
      {renderSearch()}
      <CommitmentEventCategoriesAccordion
        categories={data?.data ?? []}
        isCommitted={false}
        refresh={refresh}
        onEditClick={onEditClick}
      />
      <CommitmentEventCategoriesAccordion
        categories={data?.data ?? []}
        isCommitted={true}
        refresh={refresh}
        onEditClick={onEditClick}
      />
    </LayoutPage>
  );
};
