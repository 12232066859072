import { Box, Grid } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_FILE_SIZE_MB, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../../../constants';
import { VendorBuyingAgreementContext } from '../../../../contexts/Vendor';
import { useAuth, useEntityFormContext, useEnumList } from '../../../../hooks';
import { VendorBuyingAgreementDocumentEntity } from '../../../../models';
import { Styles, VendorBuyingAgreementDocumentType } from '../../../../types';
import { ControlledAttachment, ControlledInput, ControlledSelect } from '../../../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
};

export const VendorBuyingAgreementDocumentForm = () => {
  const { t } = useTranslation();
  const { isInternalUser } = useAuth();
  const { vendorBuyingAgreement } = useContext(VendorBuyingAgreementContext);
  const { control, watch } = useEntityFormContext<VendorBuyingAgreementDocumentEntity>();
  const typeOptions = useEnumList(VendorBuyingAgreementDocumentType, 'vendor:buyingAgreements.documents.type');
  const isEditing = !!watch('id');

  const filteredTypeOptions = useMemo(
    () =>
      typeOptions.filter((o) =>
        vendorBuyingAgreement.isApproveOrReject && !isEditing
          ? o.value !== VendorBuyingAgreementDocumentType.Legal && o.value !== VendorBuyingAgreementDocumentType.Other
          : o,
      ),
    [typeOptions, vendorBuyingAgreement, isEditing],
  );

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ControlledAttachment
            label={t('vendor:document.form.file')}
            name="file"
            control={control}
            maxSizeMB={MAX_FILE_SIZE_MB}
            required
            readOnly={isEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledSelect
            label={t('vendor:buyingAgreements.documents.form.type')}
            name="type"
            options={filteredTypeOptions}
            control={control}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            label={t('vendor:buyingAgreements.documents.form.description')}
            name="description"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            label={
              isInternalUser
                ? t('vendor:buyingAgreements.documents.form.vendorNotes')
                : t('vendor:buyingAgreements.documents.form.comments')
            }
            name="vendorNotes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
        {isInternalUser && (
          <Grid item xs={12}>
            <ControlledInput
              label={t('vendor:buyingAgreements.documents.form.notes')}
              name="notes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
