import { FileEntity } from '../FileEntity';
import { ContentEntity } from './ContentEntity';

export class StaticPageEntity extends ContentEntity {
  iconImage_En: FileEntity;
  iconImage_Fr: FileEntity;

  constructor(entity?: StaticPageEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.effectiveDate = null;
      this.expiryDate = null;
    }
  }
}
