import { array, boolean, number, object, string } from 'yup';
import { MAX_INTEGER, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorTierGrowthEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema, RebateTypeAndUnitType } from '../../types';

export const VendorTierGrowthSchema = (isEditingOrAddingGrowth: boolean): EntityFormSchema<VendorTierGrowthEntity> => ({
  schema: object({
    name_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
    name_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    rebateUnit: string().required().oneOf(Object.values(RebateTypeAndUnitType)),
    rebateCustomUnit_En: string().when('rebateUnit', {
      is: (value: RebateTypeAndUnitType) => value == RebateTypeAndUnitType.Custom,
      then: () => string().required().max(MAX_SHORT_TEXT_LENGTH),
      otherwise: () => string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    }),
    rebateCustomUnit_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    unit: string().required().oneOf(Object.values(RebateTypeAndUnitType)),
    customUnit_En: string().when('unit', {
      is: (value: RebateTypeAndUnitType) => value == RebateTypeAndUnitType.Custom,
      then: () => string().required().max(MAX_SHORT_TEXT_LENGTH),
      otherwise: () => string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    }),
    customUnit_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    memberNotes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    memberNotes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    isRetroactiveToDollar: boolean().notRequired(),
    values: array(
      object({
        rebateValue: number().required().max(MAX_INTEGER),
        ...(!isEditingOrAddingGrowth && {
          from: number()
            .transform((value) => {
              return !value && value !== 0 ? undefined : value;
            })
            .nullable()
            .notRequired(),
          to: number()
            .transform((value) => {
              return !value && value !== 0 ? undefined : value;
            })
            .nullable()
            .notRequired(),
        }),
        ...(isEditingOrAddingGrowth && {
          baselineYear: string().required().max(MAX_SHORT_TEXT_LENGTH),
          baselineVolume: string().required().max(MAX_SHORT_TEXT_LENGTH),
          growth: number().required().max(MAX_INTEGER),
        }),
      }),
    ).min(1),
  }),
  errorCodeMap: {
    [ApiErrorCodes.TIER_NAME_EN_NOT_UNIQUE]: { key: 'name_En', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.TIER_NAME_FR_NOT_UNIQUE]: { key: 'name_Fr', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.GROWTH_NAME_EN_NOT_UNIQUE]: { key: 'name_En', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.GROWTH_NAME_FR_NOT_UNIQUE]: { key: 'name_Fr', message: 'common:error.fieldUnique' },
  },
});
