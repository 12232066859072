import { array, boolean, number, object } from 'yup';
import { MemberCommitmentEntity } from '../../models';
import { EntityFormSchema } from '../../types';

export const MemberCommitmentSchema = (): EntityFormSchema<MemberCommitmentEntity> => ({
  schema: object().shape({
    commitmentEventCategoryId: number().required(),
    vendors: array()
      .of(object().shape({ isCurrent: boolean().required(), willSupport: boolean().required() }))
      .min(1)
      .required(),
    volume: number().required(),
  }),
  errorCodeMap: {},
});
