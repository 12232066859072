import {
  KeyboardArrowDownRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  KeyboardArrowUpRounded,
  MoreHorizRounded,
} from '@mui/icons-material';
import { Box, MenuItem, Typography } from '@mui/material';
import { PropsWithChildren, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationMenuContext } from '../../contexts';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';

const style: Styles = {
  menuItem: {
    color: 'secondary.contrastText',
    py: 0,
    my: 0,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },
  },
  menuItemSideCollapsed: {
    color: 'secondary.contrastText',
    py: 0,
    my: 0,
    px: 0,
    mx: 0,
    pl: 1,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },
  },
  menuItemSideExpanded: {
    color: 'secondary.contrastText',
    py: 0,
    my: 0,
    px: 2.5,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },
  },
  horizontalBox: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: '0.5em',
    justifyContent: 'flex-end',
    width: '100%',
  },
  horizontalBoxCollapsed: {
    alignItems: 'center',
    flex: 1,
    gap: '0.5em',
    display: 'block',
    pl: 1.5,
    justifyContent: 'center',
    width: '18px',
    '.navigation-collapsed &': {
      justifyContent: 'center',
    },
  },
  verticalBoxSideExpanded: {
    pb: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    '.navigation-collapsed & path': {
      color: palette.white,
    },
  },
  verticalBoxSideCollapsed: {
    alignItems: 'center',
    flex: 1,
    display: 'block',
    justifyContent: 'center',
    py: 0,
    pl: 0.25,
    '.navigation-collapsed & path': {
      color: palette.white,
    },
  },
  more: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: palette.primary.deep,
    fontWeight: 600,
    '& svg': {
      ml: 1,
    },
    '& span': {
      ml: 2,
    },
  },
  moreMenuCollapsed: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    mb: 1,
    '& svg': {
      ml: 1,
    },
    '& span': {
      display: 'none',
    },
  },
  moreArrow: {
    '.navigation-collapsed &': {
      display: 'none',
    },
  },
  collapse: {
    color: palette.primary.deep,
    fontSize: '12px',
    fontWeight: 600,
    '.navigation-collapsed &': {
      display: 'none',
    },
  },
  collapseIcon: {
    '.navigation-collapsed & path': {
      fill: palette.white,
    },
  },
};

interface NavigationExpanderMenuItemProps extends PropsWithChildren {
  type: 'vertical' | 'horizontal';
  onClick?: React.MouseEventHandler;
  expanded?: boolean;
}

export const NavigationExpanderMenuItem = ({ type, onClick, expanded, children }: NavigationExpanderMenuItemProps) => {
  const { t } = useTranslation();
  const { variant: menuVariant, expanded: menuExpanded } = useContext(NavigationMenuContext);
  const [innerExpanded, setInnerExpanded] = useState<boolean>(
    type == 'vertical' && (expanded == null ? true : !!expanded),
  );

  const onExpanderClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setInnerExpanded(!innerExpanded);
  };

  const isExpanded = () => innerExpanded || expanded;

  return (
    <>
      <MenuItem
        onClick={onClick ?? onExpanderClick}
        disableRipple
        sx={
          menuVariant === 'side'
            ? menuExpanded
              ? style.menuItemSideExpanded
              : style.menuItemSideCollapsed
            : style.menuItem
        }
      >
        {type === 'vertical' ? (
          <Box sx={menuExpanded ? style.verticalBoxSideExpanded : style.verticalBoxSideCollapsed}>
            <Box sx={menuVariant === 'side' ? (menuExpanded ? style.more : style.moreMenuCollapsed) : style.more}>
              <MoreHorizRounded fontSize="small" htmlColor={palette.primary.deep} />
              <span>{t(isExpanded() ? 'navigation:expander.less' : 'navigation:expander.more')}</span>
            </Box>
            {isExpanded() ? (
              <KeyboardArrowUpRounded sx={style.moreArrow} htmlColor={palette.primary.deep} />
            ) : (
              <KeyboardArrowDownRounded sx={style.moreArrow} htmlColor={palette.primary.deep} />
            )}
          </Box>
        ) : (
          <Box
            sx={
              menuVariant === 'side'
                ? menuExpanded
                  ? style.horizontalBox
                  : style.horizontalBoxCollapsed
                : style.horizontalBox
            }
          >
            {isExpanded() ? (
              <>
                <KeyboardArrowLeftRounded sx={style.collapseIcon} htmlColor={palette.primary.deep} fontSize="small" />
                <Typography sx={style.collapse}>{t('navigation:expander.collapse')}</Typography>
              </>
            ) : (
              <>
                <KeyboardArrowRightRounded sx={style.collapseIcon} htmlColor={palette.primary.deep} fontSize="small" />
              </>
            )}
          </Box>
        )}
      </MenuItem>
      {isExpanded() && children}
    </>
  );
};
