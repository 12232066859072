import { InfoOutlined, PersonRounded, WarningAmberRounded } from '@mui/icons-material';
import { Box, Button, Card, Chip, Grid, List, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDebounce, useInternationalization } from '../../hooks';
import { AssignedPermissionEntity, PermissionEntity } from '../../models';
import { palette } from '../../styles/palette';
import { PermissionCategory, PermissionState, Styles, UserType } from '../../types';
import { Input } from '../Form';
import { Loading } from '../Loading';
import { dependentPermissions } from '../../utils/permissions';

const style: Styles = {
  card: {
    border: 'solid 1px ' + palette.grey[400],
    mr: 1.5,
    borderRadius: 2,
  },
  list: {
    height: 300,
    overflow: 'auto',
  },
  listItemStack: {
    width: '100%',
  },
  tooltip: {
    ml: 1,
    mb: -0.5,
    fontSize: 18,
    color: palette.grey[500],
  },
  dependencyWarningTooltip: {
    ml: 1,
    mb: -0.5,
    fontSize: 18,
    color: palette.error.main,
  },
  dependencyWarningTooltipText: {
    textAlign: 'center',
  },
  dependencyWarningLink: {
    cursor: 'pointer',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  inheritedIcon: {
    mb: -0.5,
    mr: 0.5,
    fontSize: 18,
    color: palette.grey[500],
  },
  header: {
    mt: 2,
    pb: 2,
  },
  resultsCount: {
    m: 2,
  },
  button: {
    px: 2,
  },
  listItemText: {
    flex: 1,
    '.MuiTypography-root': {
      lineHeight: 1,
    },
  },
};

interface AssignedPermissionsListProps {
  value: AssignedPermissionEntity[] | null;
  onChange?: (permissions: AssignedPermissionEntity[]) => void | Promise<void>;
  readOnly?: boolean;
  userType: UserType;
}

export const AssignedPermissionsList = ({ value, onChange, readOnly, userType }: AssignedPermissionsListProps) => {
  const { t, getTranslation } = useInternationalization();
  const [textFilter, setTextFilter] = useState('');
  const [categoryFilters, setCategoryFilters] = useState<PermissionCategory[]>([]);

  const categories = useMemo(
    () =>
      value
        ?.map((p) => p.permission.category)
        .filter((value, index, array) => array.indexOf(value) === index)
        .sort(),
    [value],
  );

  const debouncedTextFilter = useDebounce(textFilter, 500);
  const splitTextFilter = useMemo(
    () => debouncedTextFilter.split(' ').map((t) => t.toLowerCase()),
    [debouncedTextFilter],
  );

  const includedAndInheritedPermissions = useMemo(
    () => value?.filter((p) => p.state === PermissionState.Included || p.state === PermissionState.Inherited),
    [value],
  );

  const filteredPermissions = useMemo(
    () =>
      value?.filter(
        (p) =>
          (!splitTextFilter.length ||
            splitTextFilter.every((t) => getTranslation(p.permission, 'name').toLowerCase().includes(t))) &&
          (!categoryFilters.length || categoryFilters.includes(p.permission.category)),
      ),
    [value, splitTextFilter, categoryFilters, getTranslation],
  );

  const currentPermissions = useMemo(
    () =>
      filteredPermissions
        ?.filter((p) => p.state === PermissionState.Included || p.state === PermissionState.Inherited)
        .sort((a, b) => getTranslation(a.permission, 'name').localeCompare(getTranslation(b.permission, 'name'))),
    [filteredPermissions, getTranslation],
  );

  const availablePermissions = useMemo(
    () =>
      filteredPermissions
        ?.filter((p) => p.state === PermissionState.Excluded || p.state === PermissionState.Available)
        .sort((a, b) => getTranslation(a.permission, 'name').localeCompare(getTranslation(b.permission, 'name'))),
    [filteredPermissions, getTranslation],
  );

  const onPermissionInclude = (permissionsToInclude: PermissionEntity[]) => () => {
    if (value && onChange) {
      onChange(
        value.map((p) => ({
          ...p,
          state: permissionsToInclude.some((toInclude) => p.permission.id === toInclude.id)
            ? p.defaultState === PermissionState.Inherited
              ? PermissionState.Inherited
              : PermissionState.Included
            : p.state,
        })),
      );
    }
  };

  const onPermissionRemove = (permissionsToExclude: PermissionEntity[]) => () => {
    if (value && onChange) {
      onChange(
        value.map((p) => ({
          ...p,
          state: permissionsToExclude.some((toExclude) => p.permission.id === toExclude.id)
            ? p.defaultState === PermissionState.Inherited
              ? PermissionState.Excluded
              : PermissionState.Available
            : p.state,
        })),
      );
    }
  };

  const renderPermissionDependencyWarning = (permission: AssignedPermissionEntity) => {
    if (permission.state !== PermissionState.Included && permission.state !== PermissionState.Inherited) {
      return null;
    }

    const dependencyPermissions = dependentPermissions[userType][permission.permission.id]?.filter(
      (dependent) => !includedAndInheritedPermissions?.some((current) => current.permission.id === dependent),
    );

    if (!dependencyPermissions || dependencyPermissions.length === 0) {
      return null;
    }

    const dependencyPermissionsToDisplay = dependencyPermissions?.reduce((accumulator, dependent) => {
      const matchingPermission = value?.find((p) => p.permission.id === dependent);
      if (matchingPermission) {
        accumulator.push(matchingPermission.permission);
      }
      return accumulator;
    }, [] as PermissionEntity[]);

    return (
      <Tooltip
        sx={style.dependencyWarningTooltip}
        arrow
        placement="top"
        title={
          <Typography variant="tooltip" sx={style.dependencyWarningTooltipText}>
            {t('user:permissions.requiredDependencies', {
              count: dependencyPermissionsToDisplay.length,
              permissions: dependencyPermissionsToDisplay.map((p) => getTranslation(p, 'name')),
            })}
            <Box onClick={onPermissionInclude(dependencyPermissionsToDisplay)} sx={style.dependencyWarningLink}>
              {t('user:permissions.addRequiredDependencies', {
                count: dependencyPermissionsToDisplay.length,
              })}
            </Box>
          </Typography>
        }
      >
        <WarningAmberRounded />
      </Tooltip>
    );
  };

  const renderPermission = (permission: AssignedPermissionEntity) => (
    <ListItem key={permission.permission.id}>
      <Stack sx={style.listItemStack} direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <ListItemText sx={style.listItemText}>
          {permission.defaultState === PermissionState.Inherited && (
            <Tooltip
              sx={style.inheritedIcon}
              arrow
              placement="top"
              title={<Typography variant="tooltip">{t('user:permissions.inherited')}</Typography>}
            >
              <PersonRounded />
            </Tooltip>
          )}
          {getTranslation(permission.permission, 'name')}
          <Tooltip
            sx={style.tooltip}
            arrow
            placement="top"
            title={<Typography variant="tooltip">{getTranslation(permission.permission, 'description')}</Typography>}
          >
            <InfoOutlined />
          </Tooltip>
          {renderPermissionDependencyWarning(permission)}
        </ListItemText>
        {!readOnly &&
          (permission.state === PermissionState.Available || permission.state === PermissionState.Excluded) && (
            <Button
              variant="outlined"
              size="small"
              onClick={onPermissionInclude([permission.permission])}
              sx={style.button}
            >
              {t('common:add')}
            </Button>
          )}
        {!readOnly &&
          (permission.state === PermissionState.Inherited || permission.state === PermissionState.Included) && (
            <Button
              variant="outlined"
              size="small"
              onClick={onPermissionRemove([permission.permission])}
              sx={style.button}
            >
              {t('common:remove')}
            </Button>
          )}
      </Stack>
    </ListItem>
  );

  const renderPermissions = (permissions: AssignedPermissionEntity[] | undefined, title: string) => (
    <Stack>
      <Typography variant="h3" sx={style.header}>
        {title}
      </Typography>
      <Card sx={style.card}>
        <List sx={style.list}>
          {permissions?.length ? (
            permissions && permissions.map((p) => renderPermission(p))
          ) : (
            <Typography variant="h6" sx={style.resultsCount}>
              {t('common:table.results', { count: 0 })}
            </Typography>
          )}
        </List>
      </Card>
    </Stack>
  );

  if (!value) {
    return <Loading />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2} alignItems={{ xs: 'flex-start', lg: 'center' }}>
        <Typography variant="h4">{t('user:permissions.filter')}</Typography>
        <Stack direction="row" flexWrap="wrap" gap={0.5} alignItems="center">
          <Chip
            label={t('user:permissions.all')}
            color={categoryFilters.length ? 'secondary' : 'primary'}
            onClick={categoryFilters.length ? () => setCategoryFilters([]) : undefined}
          />
          {categories &&
            categories.map((category) => (
              <Chip
                key={category}
                label={t(`user:permissionCategory.${category}`)}
                color={categoryFilters.includes(category) ? 'primary' : 'secondary'}
                onClick={
                  categoryFilters.includes(category)
                    ? () => setCategoryFilters((prev) => prev.filter((c) => c !== category))
                    : () => setCategoryFilters((prev) => [...prev, category])
                }
              />
            ))}
        </Stack>
        <Input
          value={textFilter}
          onChange={(event) => setTextFilter(event.target.value)}
          placeholder={t('user:permissions.searchPlaceholder')}
          size="small"
          hideLabel
        />
      </Stack>
      <Grid container spacing={1}>
        {!readOnly && (
          <Grid item xs={6}>
            {renderPermissions(availablePermissions, t('user:permissions.availablePermissions'))}
          </Grid>
        )}
        <Grid item xs={readOnly ? 12 : 6}>
          {renderPermissions(currentPermissions, t('user:permissions.currentPermissions'))}
        </Grid>
      </Grid>
    </Stack>
  );
};
