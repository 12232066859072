import { UserType, VendorBuyingAgreementDocumentStatus, VendorBuyingAgreementDocumentType } from '../../types';
import { EditableEntity } from '../EditableEntity';
import { FileEntity } from '../FileEntity';

export class VendorBuyingAgreementDocumentEntity extends EditableEntity {
  type: VendorBuyingAgreementDocumentType | null;
  description: string;
  vendorNotes: string;
  notes: string;
  status: VendorBuyingAgreementDocumentStatus;
  reviewed: boolean;
  file: FileEntity | null;
  createdByUserType: UserType;

  constructor(entity?: VendorBuyingAgreementDocumentEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
      this.id = this.file?.id ?? 0;
    } else {
      this.id = 0;
      this.description = '';
      this.vendorNotes = '';
      this.notes = '';
      this.type = null;
      this.file = null;
    }
  }

  static createFromFile(file: FileEntity) {
    const newDocument = new VendorBuyingAgreementDocumentEntity();
    newDocument.file = file;
    newDocument.type = VendorBuyingAgreementDocumentType.SignedAgreement;
    return newDocument;
  }

  static toFormData(entities: VendorBuyingAgreementDocumentEntity[], key: string) {
    const formData = new FormData();
    entities.forEach((entity, index) => {
      formData.append(`${key}[${index}].File`, entity.file ?? '');
      formData.append(`${key}[${index}].Description`, entity.description);
      formData.append(`${key}[${index}].Type`, entity.type ?? '');
      formData.append(`${key}[${index}].Notes`, entity.notes);
      formData.append(`${key}[${index}].VendorNotes`, entity.vendorNotes);
    });
    return formData;
  }
}
