import { MoreVertRounded } from '@mui/icons-material';
import { IconButton, IconButtonProps, Menu, MenuItemProps } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { palette } from '../../styles/palette';
import theme from '../../styles/theme';
import { Styles } from '../../types';

const style: Styles = {
  menu: {
    '& li': {
      whiteSpace: 'nowrap',
    },
  },
};

interface ActionMenuProps {
  disabled?: boolean;
  menuItems: (onClick: () => void) => React.ReactElement<MenuItemProps>[];
}

export const ActionMenu = ({ disabled, menuItems }: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (onClick?: IconButtonProps['onClick']) => (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onClick && onClick(event);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderIconButton = (onClick?: IconButtonProps['onClick']) => (
    <IconButton disabled={disabled} onClick={handleMenuOpen(onClick)}>
      <MoreVertRounded htmlColor={palette.secondary.dark} />
    </IconButton>
  );

  const renderActionMenuItems = () => {
    return menuItems(handleMenuClose);
  };

  const renderMenu = () => (
    <Menu
      id="table-menu"
      MenuListProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.MenuListProps}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleMenuClose}
      PaperProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps}
      sx={style.menu}
    >
      {renderActionMenuItems()}
    </Menu>
  );

  return (
    <>
      {renderIconButton()}
      {renderMenu()}
    </>
  );
};
