import { boolean, object } from 'yup';
import { SettingEntity } from '../../models';
import { EntityFormSchema } from '../../types';

export const SettingSchema = (): EntityFormSchema<SettingEntity> => ({
  schema: object({
    isMailSyncEnabled: boolean().required(),
  }),
  errorCodeMap: {},
});
