import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { DOLLAR_MAX_DECIMALS, MAX_SHORT_TEXT_LENGTH, PERCENT_MAX_DECIMALS } from '../../../constants';
import { useAuth, useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryRebateEntity } from '../../../models';
import { LanguageCode, RebateTypeAndUnitType } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { calculateTermValue } from '../../../utils/rebate';
import {
  ControlledInput,
  ControlledSelect,
  ControlledSwitch,
  ControlledTranslatedInput,
  StaticField,
} from '../../Form';

export const VendorRebateCategoryRebatePaymentTermsForm = () => {
  const { isInternalUser } = useAuth();
  const internationalization = useInternationalization();
  const { t, currentLanguage } = internationalization;
  const { control, watch, readOnly, setValue, resetField } = useEntityFormContext<VendorRebateCategoryRebateEntity>();
  const unitOptions = useEnumList(RebateTypeAndUnitType, 'common:rebateTypeAndUnitType');

  const rebateEntity = watch();
  const isTermNoteWatch = watch('isTermNote');
  const unit = watch('unit');
  const isCustomUnitWatch = unit === RebateTypeAndUnitType.Custom;

  useEffect(() => {
    if (!readOnly) {
      if (isTermNoteWatch === null) {
        setValue('isTermNote', false, { shouldDirty: true, shouldValidate: true });
      } else if (isTermNoteWatch === true) {
        resetField('termValue');
        resetField('unit');
        resetField('customUnit_En');
        resetField('customUnit_Fr');
      } else if (isTermNoteWatch === false) {
        resetField('termNote_En');
        resetField('termNote_Fr');
      }
    }
  }, [isTermNoteWatch, readOnly, setValue, resetField]);

  return (
    <Grid container spacing={3} alignItems={!readOnly ? 'baseline' : undefined}>
      {readOnly ? (
        <Grid item xs={12}>
          <StaticField
            label={
              isTermNoteWatch
                ? t('vendor:rebateCategory.rebates.form.termNote')
                : t('vendor:rebateCategory.rebates.form.termValue')
            }
            value={calculateTermValue(rebateEntity, internationalization)}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <ControlledSwitch
              label={t('vendor:rebateCategory.rebates.form.isTermNote')}
              leftLabel={t('vendor:rebateCategory.rebates.form.isTermValue')}
              name="isTermNote"
              control={control}
            />
          </Grid>
          {isTermNoteWatch ? (
            <Grid item xs={12}>
              <ControlledTranslatedInput
                label={t('vendor:rebateCategory.rebates.form.termNote')}
                name="termNote"
                control={control}
                required
                maxLength={MAX_SHORT_TEXT_LENGTH}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={4}>
                <ControlledInput
                  label={t('vendor:rebateCategory.rebates.form.termValue')}
                  name="termValue"
                  control={control}
                  formatter={numberFormatter(
                    currentLanguage,
                    !isCustomUnitWatch && unit ? t(`common:rebateTypeAndUnitType.${unit}`) : '',
                    unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
                    unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
                  )}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <ControlledSelect
                  label={t('vendor:rebateCategory.rebates.form.unit')}
                  name="unit"
                  options={unitOptions}
                  control={control}
                  required
                />
              </Grid>
              {isCustomUnitWatch && (
                <Grid item xs={4}>
                  <ControlledTranslatedInput
                    label={t('vendor:rebateCategory.rebates.form.customUnit')}
                    name="customUnit"
                    control={control}
                    required
                    maxLength={MAX_SHORT_TEXT_LENGTH}
                  />
                </Grid>
              )}
            </>
          )}
        </>
      )}

      <Grid item xs={6}>
        <ControlledTranslatedInput
          label={t('vendor:rebateCategory.rebates.form.tbmPaymentDate')}
          name="tbmPaymentDate"
          control={control}
          maxLength={MAX_SHORT_TEXT_LENGTH}
        />
      </Grid>
      {isInternalUser && (
        <Grid item xs={6}>
          <ControlledTranslatedInput
            label={t('vendor:rebateCategory.rebates.form.memberPaymentDate')}
            name="memberPaymentDate"
            control={control}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <ControlledTranslatedInput
          label={t('vendor:rebateCategory.rebates.form.paymentTermMemberNotes')}
          name="paymentTermMemberNotes"
          control={control}
        />
      </Grid>
      {isInternalUser && (
        <Grid item xs={6}>
          <ControlledInput
            label={t('vendor:rebateCategory.rebates.form.paymentTermNotes')}
            name="paymentTermNotes"
            control={control}
          />
        </Grid>
      )}
    </Grid>
  );
};
