import { DeleteRounded, LinkRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useRef } from 'react';
import { Trans } from 'react-i18next';
import { useConfirmationModal, useInternationalization } from '../../../../hooks';
import { FileEntity } from '../../../../models';
import { Styles } from '../../../../types';
import { formatBytes } from '../../../../utils/file';
import { formatShortDate, getAuthorizeFileExtensions } from '../../../../utils/helper';
import { isAllowedExtension } from '../../../../utils/schemas';
import { Container } from '../Container';
import { AttachmentsBanner } from './AttachmentsBanner';

const style: Styles = {
  table: {
    'thead .MuiTableCell-root': {
      border: 0,
    },
  },
  actionCell: {
    py: 0,
  },
  downloadButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '0 !important',
    mt: '-1.5px !important',
    color: 'inherit !important',
  },
};

interface AttachmentsProps {
  onFileClick?: (file: FileEntity) => void;
  onChange: (files: FileEntity[]) => void;
  files: FileEntity[];
  maxSizeMB: number;
  maxCount?: number;
  label?: string;
  readOnly?: boolean;
  error?: boolean;
  helperText?: string;
  hideLabel?: boolean;
  contentBesideTitle?: JSX.Element;
  onlyImg?: boolean;
}

export const Attachments = ({
  onChange,
  onFileClick,
  files,
  maxSizeMB,
  maxCount,
  label,
  readOnly,
  helperText,
  hideLabel,
  error,
  contentBesideTitle,
  onlyImg,
}: AttachmentsProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { openModal, modalRef } = useConfirmationModal();
  const { t } = useInternationalization();

  const acceptedExtensions = getAuthorizeFileExtensions(onlyImg)
    .map((ext) => `.${ext.toLowerCase()}`)
    .join(',');

  const handleClick = () => {
    inputRef.current?.click();
  };

  const fileListToArray = (list: FileList | null) => {
    const result: File[] = [];
    if (list) {
      for (let i = 0; i < list.length; i++) {
        const file = list.item(i);
        if (file) {
          result.push(file);
        }
      }
    }

    return result;
  };

  const removeFile = (index: number) => {
    onChange(files.filter((_, i) => i !== index));
  };

  const handleFileSelection = async (list: FileList | null) => {
    const updatedList = [...files];
    const selectedFiles = fileListToArray(list);

    const existingFiles = updatedList.filter(({ name: existingName }) =>
      selectedFiles.some(({ name }) => existingName === name),
    );

    if (!selectedFiles.every((f) => isAllowedExtension(f, onlyImg))) {
      return;
    }

    let overrideFiles = false;
    if (existingFiles.length) {
      openModal({
        confirmText: t('common:action.confirmButton', { action: t('common:action.replace') }),
        title: t('common:action.confirmTitle', {
          name: t('common:file.title'),
          action: t('common:action.replace'),
          count: existingFiles.length,
        }),
        description: (
          <Trans
            i18nKey="common:action.confirmReplaceDescription"
            values={{
              count: existingFiles.length,
              names: existingFiles.map((f) => f.name),
              name: existingFiles[0].name,
            }}
            components={{ ul: <ul />, li: <li /> }}
          />
        ),
      });

      overrideFiles = (await modalRef.current?.waitForResponse()) ?? false;
    }

    mergeSelectedFiles(selectedFiles, updatedList, overrideFiles);
  };

  const mergeSelectedFiles = (selectedFiles: File[], currentFiles: File[], overrideFiles: boolean) => {
    selectedFiles.forEach((file) => {
      const existingIndex = currentFiles.findIndex(({ name }) => name === file.name);
      if (existingIndex >= 0 && overrideFiles) {
        currentFiles.splice(existingIndex, 1);
      }
      if (existingIndex < 0 || overrideFiles) {
        currentFiles.push(file);
      }
    });
    onChange(currentFiles);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    handleFileSelection(ev.target.files);
    ev.target.value = '';
  };

  const handleDrop = (files: FileList) => {
    handleFileSelection(files);
  };

  return (
    <Container label={label} hideLabel={hideLabel} contentBesideTitle={contentBesideTitle}>
      <Stack spacing={1}>
        {!readOnly && (!maxCount || maxCount > files.length) && (
          <Box>
            <AttachmentsBanner
              onlyImg={onlyImg}
              onClick={handleClick}
              onDrop={handleDrop}
              maxCount={maxCount}
              maxSizeMB={maxSizeMB}
            />
            <input type="file" hidden ref={inputRef} multiple onChange={handleChange} accept={acceptedExtensions} />
          </Box>
        )}
        {!!files.length && (
          <TableContainer>
            <Table sx={style.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('common:file.name')}</TableCell>
                  <TableCell>{t('common:file.size')}</TableCell>
                  <TableCell>{t('common:file.upload')}</TableCell>
                  {!readOnly && <TableCell align="right" />}
                </TableRow>
              </TableHead>
              <TableBody>
                {files?.map((file: FileEntity, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      {file.id && onFileClick ? (
                        <Stack direction="row" spacing={0.5}>
                          <LinkRounded color="primary" />
                          <Button
                            disableRipple
                            className="MuiTableCell-root"
                            sx={style.downloadButton}
                            onClick={() => onFileClick(file)}
                          >
                            <Typography variant="underlinedLink">{file.name}</Typography>
                          </Button>
                        </Stack>
                      ) : (
                        file.name
                      )}
                    </TableCell>
                    <TableCell>{formatBytes(file.size)}</TableCell>
                    <TableCell>
                      {file.uploadedDate &&
                        `${formatShortDate(file.uploadedDate)}${file.uploadedBy ? ', ' + file.uploadedBy : ''}`}
                    </TableCell>
                    {!readOnly && (
                      <TableCell align="right" sx={style.actionCell}>
                        <IconButton onClick={() => removeFile(index)}>
                          <DeleteRounded />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!files.length && readOnly && <Typography variant="valuePlaceholder">{t('common:notSpecified')}</Typography>}
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Stack>
    </Container>
  );
};
