import { AddRounded, ArchiveRounded, EditRounded, ModeCommentRounded, UnarchiveRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { EditDrawer } from '../..';
import { member as memberApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { MemberDetailContext } from '../../../contexts';
import { useApi, useGlobalEdit, useInternationalization } from '../../../hooks';
import { MemberCommitmentDocumentEntity } from '../../../models';
import { MemberCommitmentDocumentSchema } from '../../../schemas';
import { MemberCommitmentDocumentFilter, OrderBy, PermissionKey } from '../../../types';
import { downloadBlob, formatLongDate, formatShortDate } from '../../../utils/helper';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem, MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { MemberCommitmentDocumentForm } from './MemberCommitmentDocumentForm';

const defaultFilter: MemberCommitmentDocumentFilter = {
  isArchived: false,
  ...DEFAULT_PAGINATION_FILTER,
};

export const MemberCommitmentDocumentList = () => {
  const { t } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { member, memberId } = useContext(MemberDetailContext);

  const [filter, setFilter] = useState(defaultFilter);
  const { data, isLoading, refresh } = useApi(memberApi.getAllDocuments, { skipFetch: !member }, member?.id, filter);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [commitmentDocument, setCommitmentDocument] = useState<MemberCommitmentDocumentEntity>(
    new MemberCommitmentDocumentEntity(),
  );

  const { enqueueSnackbar } = useSnackbar();

  const { call: create } = useApi(memberApi.createDocument, null);
  const { call: update } = useApi(memberApi.updateDocument, null);
  const { call: upload } = useApi(memberApi.uploadDocumentFile, { successKey: 'common:success.action' });
  const { call: download } = useApi(memberApi.getDocumentFile, null);
  const { call: archive } = useApi(memberApi.archiveDocument, { successKey: 'common:success.action' });

  const onSave = async (entity: MemberCommitmentDocumentEntity) => {
    const savedDocument = entity.id ? await update(memberId, entity) : await create(memberId, entity);
    if (savedDocument && savedDocument.id && entity.file && !entity.file.id) {
      savedDocument.file = entity.file;
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
      await upload(memberId, savedDocument.id, entity.file as unknown as File);
    } else {
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    return savedDocument;
  };

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: MemberCommitmentDocumentEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: MemberCommitmentDocumentEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onArchiveConfirm = async (isArchived: boolean, entities: MemberCommitmentDocumentEntity[]) => {
    await archive(
      memberId,
      entities.map((i) => i.id),
      isArchived,
    );
    refresh();
  };

  const downloadFile = async (entity: MemberCommitmentDocumentEntity) => {
    if (entity.id && entity.file) {
      const fileData = await download(memberId, entity.id);
      if (fileData) {
        downloadBlob(entity.file.name, fileData);
      }
    }
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderFilter = () => (
    <Stack>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />
    </Stack>
  );

  const renderActionsMenuItems = (entity: MemberCommitmentDocumentEntity, onClick: () => void) => [
    <MenuItem
      id="download"
      key="download"
      onClick={() => {
        downloadFile(entity);
        onClick();
      }}
    >
      {t('common:download')}
    </MenuItem>,
    ...(!entity.isArchived
      ? [
          <MenuItem
            id="edit"
            key="edit"
            permissions={{ keys: PermissionKey.MemberCommitmentsEdit }}
            onClick={() => {
              setCommitmentDocument(entity);
              setIsAddOpen(true);
              onClick();
            }}
            disabled={member?.isArchived}
          >
            {t('common:edit')}
          </MenuItem>,
        ]
      : []),
    <ArchiveMenuItem
      key="archive"
      permissions={{ keys: PermissionKey.MemberCommitmentsEdit }}
      entity={entity}
      name={entity.description}
      onClick={onClick}
      disabled={member?.isArchived}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('member:commitmentDocuments.actions.suffix')}
    />,
  ];

  const renderType = (entity: MemberCommitmentDocumentEntity) => t(`member:commitmentDocuments.type.${entity.type}`);

  const renderFileName = (entity: MemberCommitmentDocumentEntity) => entity.file?.name ?? '';

  const renderLastModified = (entity: MemberCommitmentDocumentEntity) =>
    t('common:entity.modifiedDateShort', {
      date: formatShortDate(entity.modifiedDate),
      name: entity.modifiedBy,
    });

  const renderNotes = (entity: MemberCommitmentDocumentEntity) => {
    let notes = entity.notes;
    if (entity.memberNotes) {
      notes += `\n${t('member:commitmentDocuments.form.memberNotes')}: ${entity.memberNotes}`;
    }
    return notes;
  };

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="member:commitmentDocuments"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
      bulkActions={!member?.isArchived && !globalEditing ? bulkActions : undefined}
      bulkActionSuffix={t('member:commitmentDocuments.actions.suffix')}
      renderBulkSelection={(items) =>
        items.length === 1 ? items[0].description : t(`member:commitmentDocuments.title`)
      }
    >
      <TableColumn type="custom" width="15%" id="type" sortable render={renderType} />
      <TableColumn type="property" width="20%" id="description" sortable />
      <TableColumn type="custom" width="25%" id="filename" sortable render={renderFileName} />
      <TableColumn
        type="custom"
        id="effectiveDate"
        width="10%"
        sortable
        render={(entity: MemberCommitmentDocumentEntity) => formatLongDate(entity.effectiveDate)}
      />
      <TableColumn
        type="custom"
        id="expiryDate"
        width="10%"
        sortable
        render={(entity: MemberCommitmentDocumentEntity) => formatLongDate(entity.expiryDate)}
      />
      <TableColumn type="custom" width="20%" id="modifiedDate" sortable render={renderLastModified} />
      <TableColumn
        type="icon"
        tooltip
        id="notes"
        align="center"
        sortable
        render={renderNotes}
        iconComponent={ModeCommentRounded}
      />
    </Table>
  );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.MemberCommitmentsEdit}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => {
          setCommitmentDocument(new MemberCommitmentDocumentEntity());
          setIsAddOpen(true);
        }}
        disabled={member?.isArchived || globalEditing}
      >
        {t('member:commitmentDocuments.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {commitmentDocument.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(
              commitmentDocument.id
                ? 'member:commitmentDocuments.actions.edit'
                : 'member:commitmentDocuments.actions.add',
            )}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={commitmentDocument}
      schema={MemberCommitmentDocumentSchema()}
      onSave={onSave}
      onConfirm={() => {
        refresh();
        setIsAddOpen(false);
      }}
      onCancel={() => setIsAddOpen(false)}
    >
      <MemberCommitmentDocumentForm />
    </EditDrawer>
  );

  return (
    <>
      <LayoutPage
        title={t('member:commitmentDocuments.title')}
        rightTitle={renderAddButton()}
        display={'Tab'}
        permissions={{ keys: PermissionKey.MemberCommitmentsView }}
      >
        {renderFilter()}
        {renderTable()}
        {renderDrawer()}
      </LayoutPage>
    </>
  );
};
