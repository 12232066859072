import { CircularProgress, Stack } from '@mui/material';
import { useContext } from 'react';
import { vendor as vendorApi } from '../../../api';
import { VendorBuyOpportunityDetailContext } from '../../../contexts/Vendor/VendorBuyOpportunityDetailContext';
import { useApi, useInternationalization } from '../../../hooks';
import { FileEntity } from '../../../models';
import { VendorBuyOpportunitySchema } from '../../../schemas';
import { Styles } from '../../../types';
import { downloadBlob } from '../../../utils/helper';
import { EditCard } from '../../Card';
import { Container } from '../../Container';
import { VendorBuyOpportunityDocumentForm } from './VendorBuyOpportunityDocumentForm';

const style: Styles = {
  loading: {
    mx: 2,
    mt: 3,
  },
  highlightContainer: {
    mb: 5,
  },
};

export const VendorBuyOpportunityDocuments = () => {
  const { t } = useInternationalization();
  const { vendorId, vendorBuyOpportunity, vendorBuyOpportunityId, save, fetchData, isLoading, readOnly } = useContext(
    VendorBuyOpportunityDetailContext,
  );
  const { call: getFile } = useApi(vendorApi.getBuyOpportunityFile, null);

  const downloadFile = async (file: FileEntity) => {
    if (file.id) {
      const fileData = await getFile(vendorId, vendorBuyOpportunityId, file.id);
      if (fileData) {
        downloadBlob(file.name, fileData);
      }
    }
  };

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        {isLoading && !vendorBuyOpportunity && <CircularProgress size={20} sx={style.loading} />}
        {vendorBuyOpportunity && (
          <EditCard
            readOnly={readOnly}
            title={`${t('vendor:buyOpportunities.sections.files')} (${vendorBuyOpportunity.filesCount || 0})`}
            entity={vendorBuyOpportunity}
            disabled={vendorBuyOpportunity.isArchived}
            schema={VendorBuyOpportunitySchema()}
            onSave={save}
            onConfirm={() => fetchData()}
            showModifiedDate
          >
            <VendorBuyOpportunityDocumentForm downloadFile={downloadFile} />
          </EditCard>
        )}
      </Stack>
    </Container>
  );
};
