import { Add, Delete } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { isAfter } from 'date-fns';
import { useContext } from 'react';
import { useFieldArray } from 'react-hook-form';
import { categorization as categorizationApi, vendor as vendorApi } from '../../../api';
import { CommitmentEventDetailContext } from '../../../contexts';
import { useApi, useEntityFormContext, useInternationalization } from '../../../hooks';
import { CommitmentEventCategoryEntity } from '../../../models';
import { CommitmentEventCategoryVendorEntity } from '../../../models/Cci/CommitmentEventCategoryVendorEntity';
import { palette } from '../../../styles/palette';
import { Styles } from '../../../types';
import {
  Autocomplete,
  Checkbox,
  ControlledDatePicker,
  ControlledSelect,
  ControlledTranslatedInput,
  MultiSelect,
} from '../../Form';
import { Loading } from '../../Loading';
import { Table, TableColumn } from '../../Table';

const styles: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
  tableHeader: {
    minHeight: '56px',
    background: palette.grey[200],
    alignItems: 'center',
    marginTop: '5px',
    paddingLeft: 1,
    boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
  },
  tableRow: {
    py: '5px',
    boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
    marginLeft: 0.5,
    maxWidth: '100%',
    '>div:first-of-type': {
      paddingLeft: 0,
    },
  },
  autocomplete: {
    '.MuiFormControl-root': {
      marginTop: 0,
    },
  },
  checkboxContainer: {
    '>label': {
      margin: 0,
    },
  },
  deleteIcon: {
    cursor: 'pointer',
  },
};

export const CommitmentEventCategoryForm = () => {
  const { t, getTranslation } = useInternationalization();
  const { event } = useContext(CommitmentEventDetailContext);
  const { control, variant } = useEntityFormContext<CommitmentEventCategoryEntity>();
  const { data: regions, isLoading: isRegionsLoading } = useApi(categorizationApi.getAll, null, 'region', false);
  const { data: productCategories, isLoading: isProductCategoryLoading } = useApi(
    categorizationApi.getAll,
    null,
    'productCategory',
    false,
  );
  const { fields, append, remove, update } = useFieldArray({ name: 'vendors', control });

  const statusOptions = [
    { label: t('cci:eventCategories.filter.requiresCommitment'), value: false },
    { label: t('cci:eventCategories.filter.committed'), value: true },
  ];

  const regionOptions =
    regions?.values.map((region) => ({
      label: getTranslation(region, 'abbreviation'),
      value: region.id,
    })) ?? [];

  const productCategoryOptions =
    productCategories?.values.map((pc) => ({
      label: getTranslation(pc, 'name'),
      value: pc.id,
    })) ?? [];

  if (isRegionsLoading || isProductCategoryLoading) {
    return <Loading />;
  }

  return (
    <Box sx={styles.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? styles.fieldGroup : undefined}>
        <Grid item xs={12}>
          <ControlledTranslatedInput
            name="name"
            label={t('cci:eventCategories.form.name')}
            control={control}
            required
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledSelect
            label={t('cci:eventCategories.form.status')}
            name="isCommitted"
            control={control}
            options={statusOptions}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledSelect
            label={t('cci:eventCategories.form.productCategory')}
            name="productCategoryId"
            control={control}
            options={productCategoryOptions}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledDatePicker
            label={t('cci:eventCategories.form.effectiveYear')}
            name="effectiveYear"
            clearable
            yearOnly
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledDatePicker
            label={t('cci:eventCategories.form.expiryYear')}
            name="expiryYear"
            clearable
            yearOnly
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Table data={fields ?? []} translationNamespace="cci:eventCategories" hideTotal>
            <TableColumn
              type="custom"
              width="45%"
              id="vendor"
              render={(field: CommitmentEventCategoryVendorEntity, index) => (
                <Autocomplete
                  minSearchTextLength={1}
                  value={field.vendor?.name}
                  noOptionsText={t('vendor:search.notFound')}
                  apiFunction={vendorApi.getSuggestions}
                  apiExtraParams={[false]}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
                  }}
                  sx={styles.autocomplete}
                  onSelectItem={(item) =>
                    update(index, {
                      ...field,
                      vendorId: item?.id ?? null,
                      vendor: item,
                    })
                  }
                />
              )}
              required
            />
            <TableColumn
              type="custom"
              width="35%"
              id="regions"
              render={(field: CommitmentEventCategoryVendorEntity, index) => (
                <MultiSelect
                  onConfirm={(values) => update(index, { ...field, regionIds: values })}
                  options={regionOptions}
                  values={field.regionIds}
                />
              )}
              required
            />
            <TableColumn
              type="custom"
              width="20%"
              id="cciPreferred"
              align="center"
              render={(field: CommitmentEventCategoryVendorEntity, index) => (
                <Checkbox
                  onChange={(event) => update(index, { ...field, isPreferred: event.target.checked })}
                  checked={field.isPreferred}
                />
              )}
            />
            <TableColumn
              type="button"
              id=""
              tooltip={false}
              iconProps={{ htmlColor: palette.primary.deep, sx: styles.deleteIcon }}
              onClick={(field, index) => remove(index)}
              iconComponent={Delete}
              render={() => !!event?.effectiveDate && isAfter(new Date(event.effectiveDate), new Date())}
            />
          </Table>
          <Grid item mt={2}>
            <Button
              startIcon={<Add />}
              variant="contained"
              onClick={() => append(new CommitmentEventCategoryVendorEntity())}
            >
              {t('common:add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
