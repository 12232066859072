import {
  FirstPageRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  LastPageRounded,
} from '@mui/icons-material';
import { IconButton, TablePagination, TablePaginationProps, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { useTranslation } from 'react-i18next';
import { Styles } from '../../types';

const style: Styles = {
  paginationMobile: {
    '.MuiTableCell-root': {
      px: '0 !important',
    },
    '.MuiTablePagination-toolbar': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      ml: 0,
      pl: 0,
      flexWrap: 'wrap',
    },
    '.MuiInputBase-root': {
      mr: 0,
    },
    '.MuiTablePagination-spacer, .MuiTablePagination-selectLabel': {
      display: 'none',
    },
    '.MuiTablePagination-actions': {
      ml: '0 !important',
    },
  },
};

type PaginationProps = {
  hideFirstAndLast?: boolean;
  component?: string;
} & TablePaginationProps;

export const Pagination = ({ hideFirstAndLast, ...props }: PaginationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const renderPageActions = (actionProps: TablePaginationActionsProps) => {
    const {
      className,
      count,
      page,
      onPageChange,
      getItemAriaLabel,
      rowsPerPage,
      backIconButtonProps,
      nextIconButtonProps,
    } = actionProps;
    const lastPage = Math.ceil(count / rowsPerPage) - 1;
    const disableNext = nextIconButtonProps?.disabled || page >= lastPage;
    const disablePrev = backIconButtonProps?.disabled || page === 0;

    return (
      <div className={className}>
        {!hideFirstAndLast && (
          <Tooltip title={getItemAriaLabel('first')}>
            <span>
              <IconButton onClick={(e) => onPageChange(e, 0)} disabled={disablePrev}>
                <FirstPageRounded />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={getItemAriaLabel('previous')}>
          <span>
            <IconButton onClick={(e) => onPageChange(e, page - 1)} disabled={disablePrev}>
              <KeyboardArrowLeftRounded />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={getItemAriaLabel('next')}>
          <span>
            <IconButton onClick={(e) => onPageChange(e, page + 1)} disabled={disableNext}>
              <KeyboardArrowRightRounded />
            </IconButton>
          </span>
        </Tooltip>
        {!hideFirstAndLast && (
          <Tooltip title={getItemAriaLabel('last')}>
            <span>
              <IconButton onClick={(e) => onPageChange(e, lastPage)} disabled={disableNext}>
                <LastPageRounded />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <TablePagination
      sx={mobile ? style.paginationMobile : undefined}
      ActionsComponent={renderPageActions}
      getItemAriaLabel={(type: string) => t(`common:pagination.${type}`)}
      labelRowsPerPage={t('common:pagination.rowsPerPage')}
      labelDisplayedRows={(pagination) => t('common:pagination.count', { pagination })}
      {...props}
    />
  );
};
