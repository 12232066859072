import { ExpandMore, ModeCommentRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { forestProduct as forestProductApi } from '../../../api';
import { DOLLAR_MAX_DECIMALS } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { LanguageCode, RebateTypeAndUnitType, Styles } from '../../../types';
import { LayoutPage } from '../../Layout';
import { formatShortDate } from '../../../utils/helper';
import { palette } from '../../../styles/palette';
import { numberFormatter } from '../../../utils/formatters';
import { ForestProductPriceHistoryChart } from './ForestProductPriceHistoryChart';
import { Select } from '../../Form';

const styles: Styles = {
  accordion: {
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '16px !important',
    border: 0,
    ':before': {
      display: 'none',
    },
    boxSizing: 'border-box',
    mt: 3,
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    minHeight: { xs: 70, md: undefined },
  },
  city: {
    alignItems: 'flex-end',
    color: 'black',
  },
  selectedCity: {
    '>*': {
      color: 'white !important',
    },
  },
  selectCity: {
    mb: 1,
  },
};

export const ForestProductPriceHistory = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const [groupId, setGroupId] = useState<number | null>();
  const [cityId, setCityId] = useState<number | null>();
  const [productId, setProductId] = useState<number | null>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { data } = useApi(forestProductApi.getForestProductLatestPriceUpdate, {
    callImmediately: true,
  });

  const onAccordionChange = (selectedId: number) => {
    const isSelected = selectedId === productId;
    setProductId(isSelected ? null : selectedId);
    productId != null && selectedId !== productId && setCityId(null);
  };

  const groups = useMemo(
    () =>
      data?.products
        ?.map((product) => product.forestProduct.forestProductGroup)
        .filter((group, index, groups) => groups.findIndex((g) => g.id === group.id) === index),
    [data?.products],
  );

  const moneyFormatter = useMemo(
    () =>
      numberFormatter(
        currentLanguage,
        t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Dollar}`),
        DOLLAR_MAX_DECIMALS,
        currentLanguage !== LanguageCode.en,
        true,
      ),
    [currentLanguage, t],
  );

  useEffect(() => groups && setGroupId(groups[0]?.id), [groups]);

  useEffect(() => {
    setProductId(null);
  }, [groupId]);

  useEffect(() => {
    setCityId((prev) => (productId ? prev ?? data?.products?.[0]?.tradingCities[0]?.tradingCity.id : null));
  }, [data?.products, productId]);

  const renderAccordions = () =>
    data?.products
      ?.filter((p) => p.forestProduct.forestProductGroup.id === groupId)
      .map((product) => (
        <Accordion
          key={product.forestProductId}
          sx={styles.accordion}
          expanded={productId === product.forestProductId}
          onChange={() => onAccordionChange(product.forestProductId)}
        >
          <AccordionSummary expandIcon={<ExpandMore htmlColor={palette.primary.deep} />} sx={styles.accordionSummary}>
            <Stack alignItems="center" direction="row" spacing={1} flex={1} justifyContent="space-between">
              <Typography variant="h3">{getTranslation(product.forestProduct, 'name')}</Typography>
              <Stack direction="row" alignItems="center" flexWrap="wrap">
                {isDesktop &&
                  product.tradingCities.map((tradingCity) => {
                    const selected = productId === product.forestProductId && cityId === tradingCity.tradingCity.id;
                    return (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setProductId(product.forestProductId);
                          setCityId(tradingCity.tradingCity.id);
                        }}
                        variant={selected ? 'contained' : undefined}
                        key={tradingCity.tradingCity.id}
                      >
                        <Stack sx={[styles.city, selected ? styles.selectedCity : {}]}>
                          <Typography variant="label">{getTranslation(tradingCity.tradingCity, 'name')}</Typography>
                          <Typography variant="h3">
                            {tradingCity.price ? moneyFormatter(tradingCity.price).formatted.join('') : '–'}
                          </Typography>
                        </Stack>
                      </Button>
                    );
                  })}
                {getTranslation(product, 'notes') && (
                  <Stack ml={2}>
                    <Tooltip arrow title={getTranslation(product, 'notes')} placement="top">
                      <ModeCommentRounded fontSize="small" htmlColor={palette.grey[500]} />
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {!isDesktop && (
              <Select
                options={product.tradingCities.map((tradingCity) => ({
                  label: getTranslation(tradingCity.tradingCity, 'name'),
                  value: tradingCity.tradingCity.id,
                }))}
                value={cityId}
                onChange={(e) => setCityId(e.target.value as number)}
                sx={styles.selectCity}
              />
            )}
            {!!productId && !!cityId && (
              <ForestProductPriceHistoryChart
                priceUpdate={data}
                forestProductId={productId}
                forestProductTradingCityId={cityId}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ));

  return (
    <LayoutPage
      title={t('forestProduct:priceHistory.title')}
      rightTitle={
        <Typography variant="label">
          {!!data?.date && t('forestProduct:priceHistory.updateDate', { date: formatShortDate(data?.date) })}
        </Typography>
      }
      display={'Tab'}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="label">{t('common:show')}</Typography>
        {groups?.map((group) => (
          <Chip
            label={getTranslation(group, 'name')}
            onClick={() => setGroupId(group.id)}
            color={groupId === group.id ? 'primary' : 'secondary'}
            key={group.id}
          />
        ))}
      </Stack>
      {renderAccordions()}
    </LayoutPage>
  );
};
