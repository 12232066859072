export class UserPasswordEntity {
  id: number;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;

  constructor() {
    this.id = 0;
    this.oldPassword = '';
    this.newPassword = '';
    this.newPasswordConfirmation = '';
  }
}
