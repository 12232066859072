import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { useEntityFormContext, useEnumList, useInternationalization } from '../../hooks';
import { AddressEntity } from '../../models';
import { CanadaProvinceOrTerritory, Country, Province, UnitedStatesStateOrTerritory } from '../../types';
import { postalCodeFormatter } from '../../utils/formatters';
import { formatAddress } from '../../utils/helper';
import { ControlledInput, ControlledSelect, StaticField } from '../Form';

interface AddressFormProps {
  excludeFields?: (keyof AddressEntity)[];
  requiredFields?: (keyof AddressEntity)[];
}

export const AddressForm = ({ excludeFields, requiredFields }: AddressFormProps) => {
  const { t } = useInternationalization();
  const { control, readOnly, getValues, setValue, variant, watch, trigger } = useEntityFormContext();
  const provinceOptions = useEnumList(Province, 'common:province');
  const countryOptions = useEnumList(Country, 'common:country');
  const province = watch('address.province');

  const isDrawer = variant === 'drawer' || variant === 'changeRequest';

  useEffect(() => {
    if (Object.values(CanadaProvinceOrTerritory).includes(province)) {
      setValue('address.country', Country.Canada);
      getValues('address.postalCode') && trigger('address.postalCode');
    } else if (Object.values(UnitedStatesStateOrTerritory).includes(province)) {
      setValue('address.country', Country.UnitedStates);
      getValues('address.postalCode') && trigger('address.postalCode');
    } else if (!province) {
      setValue('address.country', null);
    }
  }, [province, setValue, trigger, getValues]);

  if (readOnly) {
    return (
      <Grid item xs={12}>
        <StaticField label={t('common:contact.address.address')} value={formatAddress(getValues().address)} />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h3">{t('common:contact.address.address')}</Typography>
      </Grid>
      {!excludeFields?.includes('street') && (
        <Grid item xs={6} md={isDrawer ? 12 : 4}>
          <ControlledInput
            required={requiredFields?.includes('street')}
            label={t('common:contact.address.street')}
            name={`address.street`}
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
      )}
      {!excludeFields?.includes('city') && (
        <Grid item xs={6} md={isDrawer ? 12 : 4}>
          <ControlledInput
            required={requiredFields?.includes('city')}
            label={t('common:contact.address.city')}
            name={`address.city`}
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
      )}
      {!excludeFields?.includes('province') && (
        <Grid item xs={6} md={isDrawer ? 8 : 2}>
          <ControlledSelect
            onClear={() => setValue('address.province', null, { shouldDirty: true })}
            label={t('common:contact.address.province')}
            name="address.province"
            control={control}
            options={provinceOptions}
            required={requiredFields?.includes('province')}
          />
        </Grid>
      )}
      {!excludeFields?.includes('postalCode') && (
        <Grid item xs={6} md={isDrawer ? 4 : 2}>
          <ControlledInput
            label={t('common:contact.address.postalCode')}
            name="address.postalCode"
            control={control}
            formatter={postalCodeFormatter}
            required={requiredFields?.includes('postalCode')}
          />
        </Grid>
      )}
      {!excludeFields?.includes('country') && (
        <Grid item xs={12}>
          <ControlledSelect
            readOnly
            label={t('common:contact.address.country')}
            name="address.country"
            control={control}
            options={countryOptions}
            required={requiredFields?.includes('country')}
          />
        </Grid>
      )}
    </>
  );
};
