import { cms as cmsApi } from '../../../api';
import { useApi } from '../../../hooks';
import { AdFilter, ContentFilter, Styles } from '../../../types';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { WidgetProps } from './Widget';
import { CmsAdView } from '../../Cms';
import { palette } from '../../../styles/palette';
import { Box } from '@mui/material';

const styles: Styles = {
  container: {
    borderBottom: `1px solid ${palette.grey[300]}`,
    pb: 2,
  },
};

const defaultFilter: AdFilter = {
  adName: '',
  type: undefined,
  status: undefined,
  filter: ContentFilter.Active,
  ...DEFAULT_PAGINATION_FILTER,
  pageSize: 20,
};

export const Ads: (props: WidgetProps) => JSX.Element | null = () => {
  const { data, isLoading } = useApi(cmsApi.getAllAds, null, defaultFilter);

  if (!data?.totalCount) {
    return null;
  }

  return (
    <Box sx={styles.container}>
      <CmsAdView ads={data?.data} isLoading={isLoading} />
    </Box>
  );
};
