export const TimbermartLogo = () => (
  <svg width="23" height="23" viewBox="0 -0.127 20.335 13.935" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.95179 13.6181H0.667022C0.298324 13.6181 0 13.3213 0 12.9526V5.56033C0 5.38439 0.0703734 5.21611 0.194293 5.09066L4.8864 0.363369C5.01032 0.237919 5.1786 0.166016 5.3576 0.166016C5.53506 0.166016 5.70335 0.23639 5.82879 0.360309L10.4215 4.94226C10.5469 5.06771 10.6158 5.23753 10.6158 5.41499V12.9526C10.6158 13.3213 10.3174 13.6181 9.95026 13.6181"
      fill="#AB1230"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -4.440892098500626e-16)"
    />
    <path
      d="M15.2606 4.4305L11.0136 0.194293C10.8882 0.068844 10.7184 0 10.5424 0H10.5409C10.365 0 10.1952 0.0703739 10.0697 0.197353L8.61328 1.62319L9.57098 2.59924L9.58016 2.59007L11.5889 4.60184C11.7143 4.72729 11.7847 4.89711 11.7847 5.12506V12.2879V12.5832V12.6413V12.652L11.7878 13.2043V13.6189H14.7894C15.1565 13.6189 15.4548 13.3221 15.4548 12.9534V4.90323C15.4548 4.72576 15.3845 4.55595 15.2606 4.43203"
      fill="#AB1230"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -4.440892098500626e-16)"
    />
    <path
      d="M20.0854 4.4305L15.8385 0.194293C15.713 0.068844 15.5448 0 15.3688 0H15.3658C15.1898 0 15.02 0.0703739 14.8946 0.197353L13.6875 1.37994L14.509 2.19536L14.7018 2.38812L16.4137 4.10769C16.5392 4.23314 16.6095 4.40296 16.6095 4.63091V12.2879V12.5832V12.6413V12.652V13.2043L16.6111 13.6189H19.6127C19.9798 13.6189 20.2782 13.3221 20.2782 12.9534V4.90323C20.2782 4.72576 20.2078 4.55595 20.0823 4.43203"
      fill="#AB1230"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -4.440892098500626e-16)"
    />
  </svg>
);
