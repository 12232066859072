import { Box, Card, CardContent, Grid } from '@mui/material';
import { useContext } from 'react';
import { BuyGuideVendorBuyOpportunityDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorBuyOpportunityDetailContext';
import { useInternationalization } from '../../hooks';
import { OpportunityType, Styles } from '../../types';
import { StaticField } from '../Form';
import { Container } from '../Container';

const style: Styles = {
  container: {
    pt: 3,
    pb: 4,
  },
};

export const BuyGuideVendorBuyOpportunityDetails = () => {
  const internationalization = useInternationalization();
  const { t, getTranslation } = internationalization;
  const { buyOpportunity } = useContext(BuyGuideVendorBuyOpportunityDetailContext);

  if (!buyOpportunity) {
    return null;
  }

  return (
    <Container isHighlighted>
      <Box sx={style.container}>
        <Card>
          <CardContent>
            <Grid container spacing={3} sx={style.fieldGroup}>
              <Grid item xs={4}>
                <StaticField
                  label={t('buyGuide:buyOpportunities.form.shippingDate')}
                  value={getTranslation(buyOpportunity, 'shippingDate')}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticField
                  label={t('buyGuide:buyOpportunities.form.foid')}
                  value={getTranslation(buyOpportunity, 'foid')}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticField
                  label={t('buyGuide:buyOpportunities.form.termValue')}
                  value={getTranslation(buyOpportunity, 'terms')}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticField
                  label={t('buyGuide:buyOpportunities.form.paymentTermDiscount')}
                  value={getTranslation(buyOpportunity, 'paymentTermDiscount')}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticField
                  label={t('buyGuide:buyOpportunities.form.minOrder')}
                  value={getTranslation(buyOpportunity, 'minimumOrder')}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticField
                  label={t('buyGuide:buyOpportunities.form.minOrderQuantity')}
                  value={getTranslation(buyOpportunity, 'minimumOrderQuantity')}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticField
                  label={t('buyGuide:buyOpportunities.form.minFreightPrepaid')}
                  value={getTranslation(buyOpportunity, 'minimumFreightPrepaid')}
                />
              </Grid>
              {buyOpportunity.opportunityType === OpportunityType.PoolBuy && (
                <Grid item xs={4}>
                  <StaticField
                    label={t('buyGuide:buyOpportunities.form.participatingDCs')}
                    value={buyOpportunity.participatingDCs.map((m) => m.name).join(', ') ?? ''}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <StaticField
                  label={t('buyGuide:buyOpportunities.form.description')}
                  value={getTranslation(buyOpportunity, 'description')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};
