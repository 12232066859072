import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext } from '../../../hooks';
import { VendorRebateCategoryProgramEntity } from '../../../models';
import { ControlledTranslatedInput } from '../../Form/Controlled/ControlledTranslatedInput';
import { VendorRebateCategoryProgramStatusDisplay } from './VendorRebateCategoryProgramStatusDisplay';

export const VendorRebateCategoryProgramHeader = () => {
  const { t } = useTranslation();
  const { control, readOnly, variant, watch } = useEntityFormContext<VendorRebateCategoryProgramEntity>();
  const watchStatus = watch('status');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ControlledTranslatedInput
          maxLength={MAX_SHORT_TEXT_LENGTH}
          label={t('vendor:rebateCategory.programs.form.name')}
          name="name"
          variant="standard"
          control={control}
          hideLabel={readOnly}
          readOnly={readOnly}
          required
        />
      </Grid>
      {variant !== 'drawer' && (
        <Grid item xs={2}>
          <VendorRebateCategoryProgramStatusDisplay status={watchStatus} />
        </Grid>
      )}
    </Grid>
  );
};
