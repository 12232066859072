import { SearchRounded } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Menu, TextField, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchContext } from '../../contexts';
import { defaultSearchRequest } from '../../contexts/SearchContext';
import { useAuth } from '../../hooks';
import { routes } from '../../routes';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';

const style: Styles = {
  textField: {
    mx: { xs: 0, sm: 1, md: 3 },
    minWidth: { xs: '100%', sm: 200, md: 400, lg: 600 },
    '& .MuiInputBase-root': {
      backgroundColor: palette.grey[100],
      border: 'none',
      pr: 0,
      fontWeight: 600,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  menuContainer: {
    width: {
      xs: '100%',
      sm: '600px',
    },
  },
  searchMenu: {
    mt: 0.75,
  },
  searchMenuPaper: {
    minWidth: '80%',
    px: 1,
    borderRadius: '12px',
    borderTopRightRadius: 0,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
  },
};

export const NavigationSearchBar = () => {
  const [query, setQuery] = useState('');
  const { searchRequest, setSearchRequest, searchHasUnmount, setSearchHasUnmount } = useContext(SearchContext);
  const { isMemberUser, isVendorUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const buttonRef = useRef(null);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setQuery(searchRequest.query);
    setVisible(false);
  }, [searchRequest, setQuery]);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setQuery(event.target.value);
    setSearchHasUnmount(false);
  };

  const onIconClick = () => {
    setVisible((prev) => !prev);
  };

  const search = () => {
    const trimmedQuery = query.trim();
    if (trimmedQuery) {
      setSearchRequest(() => ({ ...defaultSearchRequest, query: trimmedQuery }));
      navigate(
        isMemberUser ? routes.Member.Search.path : isVendorUser ? routes.Vendor.Search.path : routes.Admin.Search.path,
      );
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && search();

  const renderSearchField = () => (
    <TextField
      variant="outlined"
      value={!searchHasUnmount ? query : ''}
      onChange={onChange}
      onKeyPress={onKeyPress}
      autoFocus={mobile}
      InputProps={{
        name: 'query',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={search}>
              <SearchRounded />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={style.textField}
    />
  );

  if (mobile) {
    return (
      <>
        <IconButton sx={style.iconButton} ref={buttonRef} size="small" onClick={onIconClick}>
          <SearchRounded htmlColor={palette.primary.deep} />
        </IconButton>
        <Menu
          anchorEl={buttonRef.current}
          open={visible}
          onClose={() => setVisible(false)}
          PaperProps={{
            sx: style.searchMenuPaper,
          }}
          sx={style.searchMenu}
        >
          <Box sx={style.menuContainer}>{renderSearchField()}</Box>
        </Menu>
      </>
    );
  }

  return <>{renderSearchField()}</>;
};
