import axios from 'axios';
import { VendorBuyingAgreementBuyOpportunityEntity } from '../../../models';
import { PaginationEntity, VendorBuyOpportunityFilter } from '../../../types';

export const buyingAgreementBuyOpportunity = {
  getAllBuyingAgreementBuyOpportunities: async (
    vendorId: number,
    buyingAgreementId: number,
    filter: VendorBuyOpportunityFilter,
  ): Promise<PaginationEntity<VendorBuyingAgreementBuyOpportunityEntity>> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/buyopportunities/search`,
      filter,
    );
    return {
      ...data,
      data: data.data.map(
        (v: VendorBuyingAgreementBuyOpportunityEntity) => new VendorBuyingAgreementBuyOpportunityEntity(v),
      ),
    };
  },
  getBuyingAgreementBuyOpportunity: async (
    vendorId: number,
    buyingAgreementId: number,
    buyingOpportunityId: number,
  ): Promise<VendorBuyingAgreementBuyOpportunityEntity> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/buyopportunities/${buyingOpportunityId}`,
    );
    return new VendorBuyingAgreementBuyOpportunityEntity(data);
  },
  createBuyingAgreementBuyOpportunity: async (
    vendorId: number,
    buyingAgreementId: number,
    entity: VendorBuyingAgreementBuyOpportunityEntity,
  ): Promise<VendorBuyingAgreementBuyOpportunityEntity> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/buyopportunities`,
      entity,
    );
    return new VendorBuyingAgreementBuyOpportunityEntity(data);
  },
  updateBuyingAgreementBuyOpportunity: async (
    vendorId: number,
    buyingAgreementId: number,
    entity: VendorBuyingAgreementBuyOpportunityEntity,
  ): Promise<VendorBuyingAgreementBuyOpportunityEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/buyopportunities/${entity.id}`,
      entity,
    );
    return new VendorBuyingAgreementBuyOpportunityEntity(data);
  },
  deleteBuyingAgreementBuyOpportunity: async (
    vendorId: number,
    buyingAgreementId: number,
    id: number,
  ): Promise<void> => {
    await axios.delete(`api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/buyopportunities/${id}`);
  },
  getBuyingAgreementBuyOpportunityFile: async (
    vendorId: number,
    buyingAgreementId: number,
    buyOpportunityId: number,
    fileId: number,
  ): Promise<Blob> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/buyopportunities/${buyOpportunityId}/files/${fileId}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  },
  deleteBuyingAgreementBuyOpportunityFiles: async (
    vendorId: number,
    buyingAgreementId: number,
    buyOpportunityId: number,
    ids: number[],
  ): Promise<VendorBuyingAgreementBuyOpportunityEntity> => {
    const { data } = await axios.delete(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/buyopportunities/${buyOpportunityId}/files?${ids
        .map((id) => `fileIds=${id}`)
        .join('&')}`,
    );
    return new VendorBuyingAgreementBuyOpportunityEntity(data);
  },
  uploadBuyingAgreementBuyOpportunityFiles: async (
    vendorId: number,
    buyingAgreementId: number,
    vendorBuyOpportunityId: number,
    files: File[],
  ): Promise<VendorBuyingAgreementBuyOpportunityEntity> => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/buyopportunities/${vendorBuyOpportunityId}/files`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    return new VendorBuyingAgreementBuyOpportunityEntity(data);
  },
};
