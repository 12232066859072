export class EditableEntity {
  id: number;
  createdDate: Date;
  createdBy: string;
  createdById: number;
  modifiedDate: Date;
  modifiedBy: string;
  latestChange?: Date;

  constructor(entity?: EditableEntity) {
    if (entity) {
      this.id = entity.id;
      this.createdBy = entity.createdBy;
      this.createdDate = entity.createdDate;
      this.modifiedBy = entity.modifiedBy;
      this.modifiedDate = entity.modifiedDate;
      this.latestChange = entity.latestChange;
    } else {
      this.id = 0;
      this.modifiedBy = '';
      this.modifiedDate = new Date();
      this.createdBy = '';
      this.createdDate = new Date();
    }
  }
}
