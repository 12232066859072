import { BuyGuideVendorDetail } from '../../../components/BuyGuide/BuyGuideVendorDetail';
import { ConfidentialAgreement } from '../../../components/ConfidentialAgreement';

export function BuyGuideDetail() {
  return (
    <ConfidentialAgreement>
      <BuyGuideVendorDetail />
    </ConfidentialAgreement>
  );
}
