import { report as reportsApi } from '../../../api';
import { VendorDataReportList } from '../../../components/Report';

export function RebateProgramsReports() {
  return (
    <VendorDataReportList
      dataApi={reportsApi.getAllRebatePrograms}
      exportApi={reportsApi.exportRebatePrograms}
      reportType="RebateProgram"
    />
  );
}
