import { AddressEntity, InternalContactEmailAddressEntity, InternalContactPhoneNumberEntity, OfficeEntity } from '..';
import { CategorizationEntity } from '../../Categorization';
import { EditableEntity } from '../../EditableEntity';

export class InternalContactEntity extends EditableEntity {
  firstName: string;
  lastName: string;
  department_En: string;
  department_Fr: string;
  isVisibleToMember: boolean;
  isVisibleToVendor: boolean;
  role_En: string;
  role_Fr: string;
  address: AddressEntity;
  officeId: number | null;
  office: OfficeEntity | null;
  boardIds: number[] | null;
  boards: CategorizationEntity[];
  departmentIds: number[] | null;
  departments: CategorizationEntity[];
  emailAddresses: InternalContactEmailAddressEntity[];
  phoneNumbers: InternalContactPhoneNumberEntity[];
  notes: string;
  memberNotes_En: string;
  memberNotes_Fr: string;
  vendorNotes_En: string;
  vendorNotes_Fr: string;
  isArchived: boolean;

  constructor(entity?: InternalContactEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.firstName = '';
      this.lastName = '';
      this.address = new AddressEntity();
      this.isVisibleToMember = true;
      this.isVisibleToVendor = true;
      this.role_En = '';
      this.role_Fr = '';
      this.boards = [];
      this.departments = [];
      this.emailAddresses = [];
      this.phoneNumbers = [];
      this.notes = '';
      this.memberNotes_En = '';
      this.memberNotes_Fr = '';
      this.vendorNotes_En = '';
      this.vendorNotes_Fr = '';
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
