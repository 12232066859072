import { UserType, VendorBuyingAgreementStatus, VendorBuyingAgreementType } from '../../types';
import { FileEntity } from '../FileEntity';
import { PeriodEntity } from '../PeriodEntity';
import { VendorBuyingAgreementDocumentEntity } from './VendorBuyingAgreementDocumentEntity';
import { VendorEntity } from './VendorEntity';

export class VendorBuyingAgreementEntity extends PeriodEntity {
  name: string;
  responsibleBuyerId: number | null;
  responsibleBuyer: string | null;
  vendorRepresentative: string;
  status: VendorBuyingAgreementStatus;
  type: VendorBuyingAgreementType;
  approvalDate: Date | null;
  notes: string;
  files?: VendorBuyingAgreementDocumentEntity[];
  filesCount: number;
  isArchived: boolean;
  vendorId: number;
  vendor: VendorEntity;
  rawFiles?: FileEntity[];
  isTransferred: boolean;
  // local assignment only
  isDuplicate: boolean;
  isPendingCreation: boolean;

  static readonly MAX_FILES_COUNT = 20;
  static readonly MAX_FILE_SIZE_MB = 50;

  constructor(entity?: VendorBuyingAgreementEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.name = '';
      this.files = [];
      this.rawFiles = [];
      this.type = VendorBuyingAgreementType.FileBased;
      this.status = VendorBuyingAgreementStatus.InProgressTBM;
      this.isArchived = false;
      this.filesCount = 0;
      this.effectiveDate = new Date(new Date().getFullYear(), 0, 1);
      this.expiryDate = new Date(new Date().getFullYear(), 11, 31);
      this.isPendingCreation = false;
    }
  }

  get isApproveOrReject() {
    return this.status == VendorBuyingAgreementStatus.Approved || this.status == VendorBuyingAgreementStatus.Rejected;
  }

  isChildEditable(userType?: UserType | null) {
    if (this.isArchived) {
      return false;
    }

    switch (userType) {
      case UserType.Internal:
        return this.status == VendorBuyingAgreementStatus.InProgressTBM;
      case UserType.Vendor:
        return this.status == VendorBuyingAgreementStatus.InProgressVendor;
      default:
        return false;
    }
  }

  duplicateInApp() {
    const duplicateItem = new VendorBuyingAgreementEntity();
    duplicateItem.id = this.id;
    duplicateItem.name = this.name;
    duplicateItem.type = VendorBuyingAgreementType.InApp;
    duplicateItem.responsibleBuyerId = this.responsibleBuyerId;
    duplicateItem.vendorRepresentative = this.vendorRepresentative;
    duplicateItem.isDuplicate = true;
    return duplicateItem;
  }
}
