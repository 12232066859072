import { CircularProgress } from '@mui/material';
import { generatePath, Navigate, useOutlet, useParams } from 'react-router-dom';
import { ArchiveBanner, EditDetails } from '../..';
import { vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryDetailContext } from '../../../contexts/Vendor/VendorRebateCategoryDetailContext';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../hooks';
import { RebateCategoryEntity, VendorRebateCategoryEntity } from '../../../models';
import { routes } from '../../../routes';
import { VendorRebateCategorySchema } from '../../../schemas';
import { PermissionKey, Styles } from '../../../types';
import { Container } from '../../Container';
import { ArchiveMenuItem, DuplicateMenuItem } from '../../Menu';
import { LinkTab } from '../../Tab';
import { VendorRebateCategoryForm } from './VendorRebateCategoryForm';
import { VendorRebateCategoryHeader } from './VendorRebateCategoryHeader';
import { VendorRebateCategoryDuplicateDrawer } from './VendorRebateCategoryDuplicateDrawer';
import { LayoutPage } from '../../Layout';
import { useState } from 'react';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const VendorRebateCategoryDetail = () => {
  const { hasPermissions } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const outlet = useOutlet();
  const params = useParams();
  const vendorId = Number(params.vendorId);
  const vendorRebateCategoryId = Number(params.vendorRebateCategoryId);
  const { data: vendor } = useApi(vendorApi.get, null, vendorId);
  const {
    data: vendorRebateCategory,
    isLoading,
    refresh,
  } = useApi(vendorApi.getRebateCategory, null, vendorId, vendorRebateCategoryId);
  const [duplicateOpen, setDuplicateOpen] = useState<RebateCategoryEntity | null>(null);

  const { call: update } = useApi(vendorApi.updateRebateCategory, {
    successKey: 'common:success.save',
  });

  usePageTitle(vendorRebateCategory ? getTranslation(vendorRebateCategory, 'name') : '');

  const saveRebateCategory = async (entity: VendorRebateCategoryEntity) => {
    const savedRebateCategory = await update(vendorId, entity);
    return savedRebateCategory;
  };

  const archiveApi = useApi(vendorApi.archiveRebateCategory, { successKey: 'common:success.action' });

  const onArchiveConfirm = async (isArchived: boolean, entity: VendorRebateCategoryEntity) => {
    await archiveApi.call(vendorId, [entity.id], isArchived);
    refresh();
  };

  const renderActionMenuItems = (onClick: () => void) =>
    vendorRebateCategory
      ? [
          <ArchiveMenuItem
            key="archive"
            permissions={{ keys: PermissionKey.VendorEditRebates }}
            entity={vendorRebateCategory}
            name={getTranslation(vendorRebateCategory, 'name')}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('vendor:action.suffix')}
            disabled={!vendor || vendor.isArchived}
          />,
          <DuplicateMenuItem
            key="duplicate"
            permissions={{ keys: PermissionKey.VendorEditRebates }}
            entity={vendorRebateCategory}
            onClick={() => {
              onClick();
              setDuplicateOpen(vendorRebateCategory);
            }}
          />,
        ]
      : [];

  const tabs = [
    <LinkTab
      label={t('vendor:rebateCategory.sections.permissions')}
      value={generatePath(routes.Admin.Vendor.RebateCategory.Permissions.path, {
        vendorId,
        vendorRebateCategoryId,
      })}
    />,
    <LinkTab
      label={t('vendor:rebateCategory.sections.rebates')}
      value={generatePath(routes.Admin.Vendor.RebateCategory.Rebates.path, { vendorId, vendorRebateCategoryId })}
      permissions={{ keys: PermissionKey.VendorViewRebates }}
    />,
    <LinkTab
      label={t('vendor:rebateCategory.sections.tierAndGrowth')}
      value={generatePath(routes.Admin.Vendor.RebateCategory.TiersAndGrowths.path, {
        vendorId,
        vendorRebateCategoryId,
      })}
      permissions={{ keys: PermissionKey.VendorViewRebates }}
    />,
    <LinkTab
      label={t('vendor:rebateCategory.sections.programs')}
      value={generatePath(routes.Admin.Vendor.RebateCategory.Programs.path, { vendorId, vendorRebateCategoryId })}
      permissions={{ keys: PermissionKey.VendorViewRebates }}
    />,
  ];

  if (!outlet) {
    return (
      <Navigate
        replace
        to={generatePath(routes.Admin.Vendor.RebateCategory.Permissions.path, { vendorId, vendorRebateCategoryId })}
      />
    );
  }

  return (
    <VendorRebateCategoryDetailContext.Provider
      value={{
        vendorId,
        vendorRebateCategoryId,
        vendor,
        vendorRebateCategory,
        fetchData: refresh,
        isLoading,
        readOnly: !hasPermissions(PermissionKey.VendorEditRebates),
      }}
    >
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
        <ArchiveBanner
          permissions={{ keys: PermissionKey.VendorEditRebates }}
          entity={vendorRebateCategory}
          onUnarchive={() => vendorRebateCategory && onArchiveConfirm(false, vendorRebateCategory)}
        />
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {vendorRebateCategory && (
            <EditDetails
              permissions={{ keys: PermissionKey.VendorEditRebates }}
              titleLabel={t(`vendor:form.name`)}
              title={'name_En'}
              header={<VendorRebateCategoryHeader />}
              disabled={!vendor || vendor.isArchived || vendorRebateCategory.isArchived}
              entity={vendorRebateCategory}
              schema={VendorRebateCategorySchema()}
              onSave={saveRebateCategory}
              actionMenuItems={renderActionMenuItems}
              onConfirm={() => refresh()}
              breadcrumbs={[
                { title: t('vendor:title'), href: routes.Admin.Vendor.path },
                {
                  title: vendor?.name,
                  href: generatePath(routes.Admin.Vendor.Detail.RebateCategories.path, { vendorId }),
                },
                { title: getTranslation(vendorRebateCategory, 'name') },
              ]}
            >
              <VendorRebateCategoryForm />
            </EditDetails>
          )}
        </Container>
        <VendorRebateCategoryDuplicateDrawer rebateCategory={duplicateOpen} onClose={() => setDuplicateOpen(null)} />
      </LayoutPage>
    </VendorRebateCategoryDetailContext.Provider>
  );
};
