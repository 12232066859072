import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useIsMounted from '../../hooks/useIsMounted';
import { getDashboardPath, routes } from '../../routes';
import { UserType } from '../../types';

interface RequireAuthProps extends PropsWithChildren {
  userType: UserType;
}

export function RequireAuth({ userType, children }: RequireAuthProps) {
  const { user, userVerifyPhoneNumber } = useAuth();
  const location = useLocation();
  const isMounted = useIsMounted();

  if (user) {
    if (user.profile.userType != userType) {
      return <Navigate to={getDashboardPath(user.profile.userType)} replace />;
    }
    if (userVerifyPhoneNumber) {
      return <Navigate to={routes.VerifyPhoneNumber.path} replace />;
    }
    if (user?.requiresVerificationPhoneNumber) {
      return <Navigate to={routes.SetVerificationPhoneNumber.path} replace />;
    }
    return <>{children}</>;
  }

  return <Navigate to={routes.Login.path} replace state={{ path: !isMounted() ? location.pathname : null }} />;
}
