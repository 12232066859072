import { CategorizationLayout } from '../../../components/Categorization';
import { PermissionKey } from '../../../types';

export function Departments() {
  return (
    <CategorizationLayout
      type="department"
      translationContext="male"
      omitFields={['description', 'abbreviation', 'searchKeywords']}
      permissions={{ keys: PermissionKey.AdminManageContacts }}
    />
  );
}
