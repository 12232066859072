import { VisibilityRounded } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { MAX_FILE_SIZE_MB, MAX_LONG_TEXT_LENGTH } from '../../../constants';
import { useApi, useAuth, useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { GeneralConditionEntity, TranslatedFileEntity } from '../../../models';
import { vendor as vendorApi } from '../../../api';
import { GeneralConditionType, Styles } from '../../../types';
import { downloadBlob } from '../../../utils/helper';
import { EntityModifiedDate } from '../../EntityModifiedDate';
import {
  ControlledCheckbox,
  ControlledInput,
  ControlledSelect,
  ControlledTranslatedInput,
  TranslatedAttachments,
} from '../../Form';
import { PeriodForm } from '../../PeriodForm';
import { TargetedMembershipForm } from '../../TargetedMembership';
import { VendorBuyingAgreementSelect } from '../BuyingAgreements/VendorBuyingAgreementSelect';

const style: Styles = {
  container: {
    pb: 4,
  },
};

export const VendorGeneralConditionForm = () => {
  const { t } = useInternationalization();
  const { isInternalUser, user } = useAuth();
  const params = useParams();
  const isBuyingAgreementChild = !!params.vendorBuyingAgreementId;
  const buyingAgreementId = Number(params.vendorBuyingAgreementId ?? 0);
  const vendorId = Number(user?.profile.vendor?.id ?? params.vendorId);
  const { getValues, setValue, control, watch } = useEntityFormContext<GeneralConditionEntity>();
  const id = watch('id');
  const editing = !!id;
  const generalConditionTypeOptions = useEnumList(
    GeneralConditionType,
    'vendor:generalCondition.type',
    false,
    'male',
    false,
  );
  const type = watch('type');
  const files = watch('files');
  const { call: downloadForBuyingAgreement } = useApi(vendorApi.getBuyingAgreementGeneralConditionFile, null);
  const { call: download } = useApi(vendorApi.getGeneralConditionFile, null);

  const downloadFile = async (file: TranslatedFileEntity) => {
    if (id && file.file?.id) {
      const fileData = await (isBuyingAgreementChild
        ? downloadForBuyingAgreement(vendorId, buyingAgreementId, id, file.file.id)
        : download(vendorId, id, file.file.id));
      if (fileData) {
        downloadBlob(file.file.name, fileData);
      }
    }
  };

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        {editing && (
          <Grid item xs={12}>
            <EntityModifiedDate entity={getValues()} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledSelect
            label={t('vendor:generalCondition.form.type')}
            name="type"
            control={control}
            options={generalConditionTypeOptions}
            required
          />
        </Grid>
        <PeriodForm xs={6} />
        {!isBuyingAgreementChild && <VendorBuyingAgreementSelect vendorId={vendorId} xs={12} />}
        {isInternalUser && (
          <Grid item xs={12}>
            <ControlledCheckbox
              label={t('vendor:generalCondition.form.isVisibleToMember')}
              icon={VisibilityRounded}
              name="isVisibleToMember"
              control={control}
            />
          </Grid>
        )}
        {type !== GeneralConditionType.Freight ? (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              label={t('vendor:generalCondition.form.summary')}
              name="summary"
              control={control}
              multiline
              rows={5}
              required
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <ControlledTranslatedInput
                label={t('vendor:generalCondition.form.freightPolicy')}
                name="freightPolicy"
                control={control}
                maxLength={195}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTranslatedInput
                label={t('vendor:generalCondition.form.pickupAllowance')}
                name="pickupAllowance"
                control={control}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTranslatedInput
                label={t('vendor:generalCondition.form.prepaidAmount')}
                name="prepaidAmount"
                control={control}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTranslatedInput
                label={t('vendor:generalCondition.form.fobPoint')}
                name="fobPoint"
                control={control}
                maxLength={20}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TranslatedAttachments
            onChange={(changedFiles) =>
              setValue('files', changedFiles, { shouldDirty: true, shouldTouch: true, shouldValidate: true })
            }
            files={files ?? []}
            maxSizeMB={MAX_FILE_SIZE_MB}
            label={''}
            onFileClick={downloadFile}
          />
        </Grid>
        <Grid item xs={12}>
          <TargetedMembershipForm />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            label={
              isInternalUser
                ? t('vendor:generalCondition.form.vendorNotes')
                : t('vendor:generalCondition.form.comments')
            }
            name="vendorNotes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('vendor:generalCondition.form.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
        {isInternalUser && (
          <Grid item xs={12}>
            <ControlledInput
              label={t('vendor:generalCondition.form.notes')}
              name="notes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
