import { Box, Container as MuiContainer } from '@mui/material';
import { PropsWithChildren } from 'react';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';

const style: Styles = {
  highlightedContainer: { backgroundColor: palette.backgrounds.container },
  borderedContainer: { borderBottom: '1px solid ' + palette.grey[300] },
  wrapper: {
    pl: { xs: 0, md: '75px' },
    transition: 'padding-left 500ms ease',
  },
};

interface ContainerProps extends PropsWithChildren {
  isHighlighted?: boolean;
  isBordered?: boolean;
  sx?: Styles[0];
  wrapperSx?: Styles[0];
}

export const Container = ({ children, isBordered, isHighlighted, sx, wrapperSx }: ContainerProps) => {
  return (
    <MuiContainer
      maxWidth={false}
      sx={{
        ...(isHighlighted ? style.highlightedContainer : {}),
        ...(isBordered ? style.borderedContainer : {}),
        ...sx,
      }}
    >
      <Box className="navigation-spacing" sx={{ ...style.wrapper, ...wrapperSx }}>
        {children}
      </Box>
    </MuiContainer>
  );
};
