import axios from 'axios';
import { SettingEntity } from '../models';

export const setting = {
  get: async (): Promise<SettingEntity> => {
    const { data } = await axios.get('api/settings');
    return new SettingEntity(data);
  },
  set: async (setting: SettingEntity): Promise<SettingEntity> => {
    const { data } = await axios.post('api/settings', setting);
    return new SettingEntity(data);
  },
};
