import { mixed, object, string } from 'yup';
import { MAX_FILE_SIZE_MB, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { FileEntity } from '../../models';
import { VendorBuyingAgreementDocumentEntity } from '../../models/Vendor/VendorBuyingAgreementDocumentEntity';
import { EntityFormSchema, VendorBuyingAgreementDocumentType } from '../../types';
import { mbToBytes } from '../../utils/file';

export const VendorBuyingAgreementDocumentSchema = (): EntityFormSchema<VendorBuyingAgreementDocumentEntity> => ({
  schema: object().shape({
    type: string().required().oneOf(Object.values(VendorBuyingAgreementDocumentType)),
    description: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    vendorNotes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    file: mixed<FileEntity>()
      .required()
      .test('max-size', 'common:error.arrayMaxItemSize', (file) =>
        file ? file.size < mbToBytes(MAX_FILE_SIZE_MB) : true,
      ),
  }),
  errorCodeMap: {},
});
