import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { roleGroup as roleGroupApi } from '../../../../api';
import { MAX_LONG_TEXT_LENGTH, MAX_PAGINATION_FILTER } from '../../../../constants';
import { useAuth, useEntityFormContext, useEnumList } from '../../../../hooks';
import { UserEntity } from '../../../../models';
import { VendorBuyingAgreementBuyOpportunityEntity } from '../../../../models/Vendor/VendorBuyingAgreementBuyOpportunityEntity';
import { OpportunityType, RoleGroup, Styles } from '../../../../types';
import {
  ControlledCheckbox,
  ControlledEntitySelect,
  ControlledInput,
  ControlledSelect,
  ControlledTranslatedInput,
} from '../../../Form';
import { PeriodForm } from '../../../PeriodForm';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
};

export const VendorBuyingAgreementBuyOpportunityForm = () => {
  const { isInternalUser } = useAuth();
  const { t } = useTranslation();
  const { setValue, control, variant } = useEntityFormContext<VendorBuyingAgreementBuyOpportunityEntity>();

  const opportunityTypeOptions = useEnumList(OpportunityType, 'vendor:buyOpportunities.opportunity').filter(
    (o) => o.value !== OpportunityType.All && o.value !== OpportunityType.PoolBuy,
  );

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        <Grid item xs={6} md={variant === 'drawer' ? 6 : 4}>
          <ControlledSelect
            onClear={() => setValue('opportunityType', null, { shouldDirty: true })}
            label={t('vendor:buyOpportunities.form.opportunity')}
            name="opportunityType"
            control={control}
            options={opportunityTypeOptions}
            required
          />
        </Grid>
        {variant === 'drawer' && <Grid item xs={6}></Grid>}
        <PeriodForm md={variant === 'drawer' ? 4 : 2} />
        <Grid item xs={6} md={variant === 'drawer' ? 6 : 4}>
          <ControlledEntitySelect
            label={t('vendor:buyOpportunities.form.responsibleBuyer')}
            name="responsibleBuyer"
            apiFunction={roleGroupApi.getUsers}
            apiParams={[RoleGroup.Buyer, MAX_PAGINATION_FILTER]}
            getOptionLabel={(entity: UserEntity) => entity.fullName}
            control={control}
            required
          />
        </Grid>
        {variant == 'drawer' && <Grid item xs={6} />}
        {isInternalUser && (
          <Grid item xs={12}>
            <ControlledCheckbox
              label={t('vendor:buyOpportunities.form.isVisibleToMember')}
              name="isVisibleToMember"
              control={control}
            />
          </Grid>
        )}
        <Grid item xs={12} md={variant === 'drawer' ? 12 : 6}>
          <ControlledInput
            label={
              isInternalUser
                ? t('vendor:buyOpportunities.form.vendorNotes')
                : t('vendor:buyOpportunities.form.comments')
            }
            name="vendorNotes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
        {isInternalUser && (
          <Grid item xs={12} md={variant === 'drawer' ? 12 : 6}>
            <ControlledInput
              label={t('vendor:buyOpportunities.form.notes')}
              name="notes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
        )}
        <Grid item xs={12} md={variant === 'drawer' ? 12 : 6}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
