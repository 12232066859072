import { Box, Link, SelectChangeEvent, Switch, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { generatePath, useNavigate, useOutlet } from 'react-router-dom';
import { cms as cmsApi, vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useAuth, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { PublicationEntity } from '../../../models';
import { routes } from '../../../routes';
import { palette } from '../../../styles/palette';
import { ContentFilter, OrderBy, PublicationFilter, PublicationUrgency, Styles } from '../../../types';
import { FilterContainer } from '../../Container';
import { ContentFilterSelect } from '../../Filter/ContentFilterSelect';
import { Autocomplete, Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { Table, TableColumn } from '../../Table';
import { formatShortDate } from '../../../utils/helper';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectLargeContainer: {
    width: {
      xs: '100%',
      md: '320px',
    },
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
    mt: 3,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
};

const defaultFilter: PublicationFilter = {
  publicationName: '',
  filter: ContentFilter.Active,
  publicationCategoryId: undefined,
  status: undefined,
  isMandatory: undefined,
  urgency: undefined,
  vendorNameOrNumber: undefined,
  vendorId: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

export const CmsPublicationListMember = () => {
  const { isMemberUser } = useAuth();
  const outlet = useOutlet();
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(defaultFilter);
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const urgencyOptions = useEnumList(
    PublicationUrgency,
    'cms:publications.form.urgency',
    true,
    undefined,
    false,
    undefined,
    palette.PublicationUrgency,
  );

  const { data: publicationCategories } = useApi(cmsApi.getPublicationCategories, { callImmediately: true });
  const { data, isLoading, refresh } = useApi(cmsApi.getAllPublications, { skipFetch: true }, filter);
  usePageTitle('cms:publications.title');

  useEffect(() => {
    refresh();
  }, [currentLanguage, refresh]);

  if (outlet) {
    return outlet;
  }

  const categoriesOptions = [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { label: t('common:filter.all', { context: 'male' }), value: '' as any },
    ...(publicationCategories?.map((c) => ({
      value: c.id,
      label: getTranslation(c, 'name'),
      groupName: getTranslation(c, 'groupName'),
    })) ?? []),
  ];

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...defaultFilter, filter: prev.filter }));
    setIsAdvancedMode(checked);
  };

  const clearFilter = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onCategoryChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      publicationCategoryId: event.target.value !== '' ? Number(event.target.value) : undefined,
      pageNumber: 1,
    }));
  };

  const onUrgencyChange = (event: SelectChangeEvent<unknown>) => {
    setFilter((prev) => ({ ...prev, urgency: (event.target.value as string) || undefined, pageNumber: 1 }));
  };

  const getDetailPath = (id: number) =>
    isMemberUser
      ? generatePath(routes.Member.PublicationDetails.path, { publicationId: id })
      : generatePath(routes.Admin.PublicationDetails.Content.path, { publicationId: id });

  const renderActionsMenuItems = (item: PublicationEntity) => {
    return [
      <MenuItem id="view" key="view" onClick={() => navigate(getDetailPath(item.id))}>
        {t('common:view')}
      </MenuItem>,
    ];
  };

  const renderPublicationLink = (id: number, text: string | undefined) =>
    !!text && (
      <Link underline="always" href={getDetailPath(id)}>
        {text}
      </Link>
    );

  const renderUrgency = ({ urgency }: PublicationEntity) =>
    urgency ? (
      <Typography variant="inherit" color={urgencyOptions.find((u) => u.value == urgency)?.color}>
        {t(`cms:publications.table.urgencyValue.${urgency}`)}
      </Typography>
    ) : (
      ''
    );

  const renderVendor = ({ vendor }: PublicationEntity) => vendor?.name;

  const renderTextSearch = () => (
    <Autocomplete
      value={filter.publicationName}
      sx={style.textContainer}
      noOptionsText={t('cms:publications.search.notFound')}
      apiFunction={cmsApi.getPublicationSuggestions}
      label={t('cms:publications.search.title')}
      placeholder={t('cms:publications.search.placeholder')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : getTranslation(option, 'name'))}
      onApplySearch={(searchText) =>
        setFilter((prev) => ({ ...prev, publicationName: searchText ?? undefined, pageNumber: 1 }))
      }
      onSelectItem={(item) => item && navigate(getDetailPath(item.id))}
    />
  );
  const renderAdvancedSearch = () => (
    <FilterContainer onClear={clearFilter} mt={3}>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={categoriesOptions}
          value={filter.publicationCategoryId?.toString() ?? ''}
          onChange={onCategoryChange}
          label={t('cms:publications.search.category')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={urgencyOptions}
          value={filter.urgency ?? ''}
          onChange={onUrgencyChange}
          label={t('cms:publications.search.urgency')}
        />
      </Box>
      <Box sx={style.selectLargeContainer}>
        <Autocomplete
          minSearchTextLength={1}
          value={filter.vendorNameOrNumber}
          noOptionsText={t('vendor:search.notFound')}
          apiFunction={vendorApi.getSuggestions}
          label={t('changeRequest:search.vendorNameOrNumber')}
          placeholder={t('vendor:search.placeholder')}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
          }}
          onApplySearch={(searchText) =>
            setFilter((prev) => ({ ...prev, vendorNameOrNumber: searchText ?? undefined, pageNumber: 1 }))
          }
          onSelectItem={(item) =>
            setFilter((prev) => ({ ...prev, vendorId: item?.id, vendorNameOrNumber: item?.name, pageNumber: 1 }))
          }
        />
      </Box>
    </FilterContainer>
  );

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="cms:publications"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={renderActionsMenuItems}
    >
      <TableColumn type="custom" sortable width="15%" id="urgency" render={renderUrgency} />
      <TableColumn
        type="custom"
        sortable
        width="25%"
        id="name"
        render={(item: PublicationEntity) => renderPublicationLink(item.id, getTranslation(item, 'name'))}
      />
      <TableColumn
        type="custom"
        sortable
        width="20%"
        id="category"
        render={(item: PublicationEntity) =>
          item.category
            ? `${getTranslation(item.category, 'groupName')} - ${getTranslation(item.category, 'name')}`
            : ''
        }
      />
      <TableColumn
        type="custom"
        sortable
        width="12.5%"
        id="effectiveDate"
        render={({ effectiveDate }: PublicationEntity) => formatShortDate(effectiveDate)}
      />
      <TableColumn
        type="custom"
        sortable
        width="12.5%"
        id="expiryDate"
        render={({ expiryDate }: PublicationEntity) => formatShortDate(expiryDate)}
      />
      <TableColumn type="custom" sortable width="15%" id="vendor" render={renderVendor} />
    </Table>
  );

  return (
    <LayoutPage display="Tab" title={t('cms:publications.title')}>
      <Box sx={style.filterBox}>
        <Typography variant="searchTitle" color="primary.dark">
          {t('cms:publications.search.title')}
        </Typography>
        <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
        <Typography variant="searchTitle" color="grey.600">
          {t('cms:publications.search.filterList')}
        </Typography>
      </Box>
      {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
      <ContentFilterSelect
        filter={filter}
        setFilter={setFilter}
        ignoreFilters={[ContentFilter.Drafts, ContentFilter.Published, ContentFilter.Rejected, ContentFilter.Upcoming]}
        translationContext="female"
      />
      {renderTable()}
    </LayoutPage>
  );
};
