import { Typography, alpha } from '@mui/material';
import { useInternationalization } from '../hooks';
import { palette } from '../styles/palette';
import { FilterPeriod, Styles, TranslationContext } from '../types';

interface EntityPeriodProps {
  period?: FilterPeriod;
  translationContext: TranslationContext;
  isArchived?: boolean;
}

const styles: Styles = {
  isArchived: {
    background: palette.grey[600],
    color: 'white',
  },
  current: {
    background: alpha(palette.secondary.green, 0.15),
    color: palette.secondary.green,
  },
  upcoming: {
    background: alpha(palette.secondary.orange, 0.15),
    color: palette.secondary.orange,
  },
  expired: {
    background: palette.grey[200],
    color: palette.grey[500],
  },
};

export function EntityPeriod({ period, translationContext, isArchived }: EntityPeriodProps) {
  const { t } = useInternationalization();

  if (isArchived) {
    return (
      <Typography variant="status" sx={styles.isArchived}>
        {t('common:isArchived.true', { context: translationContext })}
      </Typography>
    );
  }

  switch (period) {
    case FilterPeriod.Upcoming:
      return (
        <Typography variant="status" sx={styles.upcoming}>
          {t('common:filterPeriod.Upcoming', { context: translationContext })}
        </Typography>
      );
    case FilterPeriod.Current:
      return (
        <Typography variant="status" sx={styles.current}>
          {t('common:filterPeriod.Current', { context: translationContext })}
        </Typography>
      );
    case FilterPeriod.Expired:
      return (
        <Typography variant="status" sx={styles.expired}>
          {t('common:filterPeriod.Expired', { context: translationContext })}
        </Typography>
      );
  }

  return <></>;
}
