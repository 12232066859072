import React from 'react';
import { VendorRebateCategoryProgramEntity } from '../../models';

interface BuyGuideVendorRebateProgramDetailContextType {
  vendorId: number;
  rebateProgramId: number;
  rebateProgram: VendorRebateCategoryProgramEntity | null;
}

export const BuyGuideVendorRebateProgramDetailContext =
  React.createContext<BuyGuideVendorRebateProgramDetailContextType>({} as BuyGuideVendorRebateProgramDetailContextType);
