import { Box } from '@mui/material';
import { Styles } from '../../types';
import { useInternationalization } from '../../hooks';
import { ContentBlockEntity } from '../../models/Cms/ContentBlockEntity';
import { YOUTUBE_EMBED_URL, YOUTUBE_URL_REGEX } from '../../constants';

const style: Styles = {
  video: {
    display: 'block',
    height: 0,
    margin: 'auto',
    overflow: 'hidden',
    padding: '0% 0% 56.25%',
    position: 'relative',
    width: '100%',
    '& iframe': {
      border: 0,
      bottom: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
};

interface CmsContentBlockVideoViewProps {
  contentBlock: ContentBlockEntity;
}

export const CmsContentBlockVideoView = ({ contentBlock }: CmsContentBlockVideoViewProps) => {
  const { getTranslation } = useInternationalization();
  const videoUrl = getTranslation(contentBlock, 'content');

  if (!videoUrl) {
    return null;
  }

  const youTubeVideoId = videoUrl.match(YOUTUBE_URL_REGEX)?.[1];

  if (!youTubeVideoId) {
    return null;
  }

  return (
    <Box sx={style.video}>
      <iframe id="player" src={`${YOUTUBE_EMBED_URL}${youTubeVideoId}`} />
    </Box>
  );
};
