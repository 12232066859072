import React from 'react';
import { ForestProductGroupEntity } from '../../models';

interface ForestProductGroupContextType {
  forestProductGroup: ForestProductGroupEntity | null;
  forestProductGroupId: number;
}

export const ForestProductGroupContext = React.createContext<ForestProductGroupContextType>(
  {} as ForestProductGroupContextType,
);
