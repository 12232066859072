import axios from 'axios';
import { EventEntity } from '../../models';
import { EventFilter, EventPaginationEntity } from '../../types';

export const Event = {
  getAllEvents: async (eventFilter: EventFilter): Promise<EventPaginationEntity> => {
    const { data } = await axios.post('api/event/search', eventFilter);
    return { ...data, data: data.data.map((d: EventEntity) => new EventEntity(d)) };
  },
};
