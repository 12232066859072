import { EditableEntity } from '../../EditableEntity';
import { AddressEntity } from '../AddressEntity';
import { OfficeEmailAddressEntity } from './OfficeEmailAddressEntity';
import { OfficePhoneNumberEntity } from './OfficePhoneNumberEntity';

export class OfficeEntity extends EditableEntity {
  description_En: string;
  description_Fr: string;
  address: AddressEntity;
  notes: string | null;
  memberNotes_En: string | null;
  memberNotes_Fr: string | null;
  vendorNotes_En: string | null;
  vendorNotes_Fr: string | null;
  emailAddresses: OfficeEmailAddressEntity[];
  phoneNumbers: OfficePhoneNumberEntity[];
  isArchived: boolean;

  constructor(entity?: OfficeEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.description_En = '';
      this.description_Fr = '';
      this.notes = '';
      this.memberNotes_En = '';
      this.memberNotes_Fr = '';
      this.vendorNotes_En = '';
      this.vendorNotes_Fr = '';
      this.address = new AddressEntity();
      this.emailAddresses = [];
      this.phoneNumbers = [];
      this.isArchived = false;
    }
  }
}
