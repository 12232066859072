import { Stack } from '@mui/material';
import { useContext } from 'react';
import { BuyGuideVendorBuyOpportunityDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorBuyOpportunityDetailContext';
import { useApi, useInternationalization } from '../../hooks';
import { Styles } from '../../types';
import { FileEntity } from '../../models';
import { downloadBlob } from '../../utils/helper';
import { VendorBuyOpportunityDocumentForm } from '../Vendor/BuyOpportunities/VendorBuyOpportunityDocumentForm';
import { EditCard } from '../Card';
import { VendorBuyOpportunitySchema } from '../../schemas';
import { vendor as vendorApi } from '../../api';
import { Container } from '../Container';

const style: Styles = {
  highlightContainer: {
    mb: 5,
  },
};

export const BuyGuideVendorBuyOpportunityDocuments = () => {
  const internationalization = useInternationalization();
  const { t } = internationalization;
  const { vendorId, buyOpportunity, buyOpportunityId } = useContext(BuyGuideVendorBuyOpportunityDetailContext);
  const { call: getFile } = useApi(vendorApi.getBuyOpportunityFile, null);

  const downloadFile = async (file: FileEntity) => {
    if (file.id) {
      const fileData = await getFile(vendorId, buyOpportunityId, file.id);
      if (fileData) {
        downloadBlob(file.name, fileData);
      }
    }
  };

  if (!buyOpportunity) {
    return null;
  }

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        {buyOpportunity && (
          <EditCard
            title={`${t('buyGuide:buyOpportunities.sections.files')} (${buyOpportunity.filesCount || 0})`}
            entity={buyOpportunity}
            readOnly={true}
            schema={VendorBuyOpportunitySchema()}
          >
            <VendorBuyOpportunityDocumentForm downloadFile={downloadFile} />
          </EditCard>
        )}
      </Stack>
    </Container>
  );
};
