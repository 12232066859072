import { ListItem, Stack, Typography } from '@mui/material';
import { useInternationalization } from '../../hooks';
import { ChangeLogEntity } from '../../models';
import { ChangeLogType, Styles } from '../../types';
import { formatDateTime } from '../../utils/helper';

const style: Styles = {
  itemContainer: {
    py: 2,
  },
  description: {
    whiteSpace: 'break-spaces',
    overflowWrap: 'anywhere',
  },
};

interface ChangeLogItemProps<T extends ChangeLogEntity> {
  item: T;
  renderValue: (item: T) => string;
}

export const ChangeLogItem = <T extends ChangeLogEntity>({ item, renderValue }: ChangeLogItemProps<T>) => {
  const { timeZoneName } = useInternationalization();

  return (
    <ListItem key={item.id} disablePadding>
      <Stack spacing={1} sx={style.itemContainer}>
        <Stack direction="row" spacing={1.2}>
          {item.changeLogType == ChangeLogType.Comment && item.loggedBy && (
            <Typography variant="userDisplayName" color="grey.600">
              {item.loggedBy}
            </Typography>
          )}
          <Typography variant="chip" color="grey.500">
            {formatDateTime(item.logDate, timeZoneName)}
          </Typography>
        </Stack>

        {item.changeLogType == ChangeLogType.Comment ? (
          <Typography variant="h4" sx={style.description}>
            {item.description}
          </Typography>
        ) : (
          <Typography variant="label" color="grey.600">
            {renderValue(item)}
          </Typography>
        )}
      </Stack>
    </ListItem>
  );
};
