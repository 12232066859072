import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { InternalContactFilter } from '../../../types';
import { InternalContactsList } from '../InternalContact/InternalContactsList';

export const OfficeInternalContactList = () => {
  const params = useParams();
  const officeId = Number(params.officeId);
  const [filter, setFilter] = useState<InternalContactFilter>({
    isArchived: false,
    officeIds: [officeId],
    ...DEFAULT_PAGINATION_FILTER,
  });

  return (
    <InternalContactsList
      onSave={async () => null}
      filter={filter}
      setFilter={setFilter}
      readOnly
    />
  );
};
