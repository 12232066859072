import { ArrowForwardRounded, DownloadRounded } from '@mui/icons-material';
import { IconButton, Link, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Breadcrumbs } from '..';
import { useApi, useAuth, useInternationalization } from '../../hooks';
import { SearchDocumentEntity } from '../../models';
import { palette } from '../../styles/palette';
import { EntityType, RouteData, Styles } from '../../types';
import { useContext, useEffect, useState } from 'react';
import { downloadBlob } from '../../utils/helper';
import { breadcrumbGenerator } from '../../utils/searchUtils';
import { SearchContext } from '../../contexts';

const style: Styles = {
  listItem: {
    borderBottomColor: palette.grey[300],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    '&:last-child': {
      borderBottomStyle: 'none',
    },
  },
  entityType: {
    background: palette.grey[100],
    color: palette.grey[500],
  },
  entityName: {
    fontWeight: 500,
    ':hover': {
      textDecoration: 'underline',
    },
  },
};

interface SearchResultProps {
  item: SearchDocumentEntity;
}

const defaultDownloadApi: () => Promise<Blob> = () => Promise.resolve(new Blob());

export const SearchResult = ({ item }: SearchResultProps) => {
  const { t, getTranslation } = useInternationalization();
  const [downloadApiFn, setDownloadApiFn] = useState<(() => Promise<Blob>) | undefined>();
  const { call } = useApi(downloadApiFn ?? defaultDownloadApi, null);
  const { searchRequest } = useContext(SearchContext);

  const { userType } = useAuth();

  const entityTypeRouteKeyMap: Record<EntityType, keyof RouteData | undefined> = {
    Vendor: 'vendorId',
    VendorBuyingAgreement: 'vendorBuyingAgreementId',
    VendorBuyingAgreementFile: undefined,
    VendorContact: undefined,
    VendorGeneralCondition: 'vendorGeneralConditionId',
    VendorGeneralConditionFile: undefined,
    VendorLocation: undefined,
    Member: 'memberId',
    MemberContact: undefined,
    Office: 'officeId',
    InternalContact: 'contactId',
    VendorDocument: undefined,
    RebateCategory: 'vendorRebateCategoryId',
    RebateProgram: 'rebateProgramId',
    VendorBuyingAgreementGeneralCondition: 'vendorGeneralConditionId',
    VendorBuyingAgreementGeneralConditionFile: undefined,
    VendorBuyingAgreementBuyOpportunity: 'buyOpportunityId',
    VendorBuyingAgreementBuyOpportunityFile: undefined,
    VendorBuyingAgreementRebateCategory: 'vendorRebateCategoryId',
    VendorTier: undefined,
    VendorGrowth: undefined,
    Publication: 'publicationId',
    PublicationFile: undefined,
    StaticPage: 'staticPageId',
    StaticPageFile: undefined,
    VendorBuyOpportunity: 'vendorBuyOpportunityId',
    VendorBuyOpportunityFile: undefined,
    HelpTraining: 'helpTrainingId',
    HelpTrainingFile: undefined,
    ForestProductGroup: 'productGroupId',
  };

  useEffect(() => {
    const download = async () => {
      if (downloadApiFn instanceof Function) {
        const fileData = await call();
        if (fileData) {
          downloadBlob(item.fileName ?? getTranslation(item, 'name'), fileData as Blob);
          setDownloadApiFn(undefined);
        }
      }
    };
    download();
  }, [call, downloadApiFn, getTranslation, item]);

  const hierarchy = item.breadcrumbs ? [...item.breadcrumbs, item] : [item];

  const routeValues = hierarchy.reduce((acc, value) => {
    const key = entityTypeRouteKeyMap[value.entityType];
    if (key) {
      acc[key] = value.entityId;
    }
    return acc;
  }, {} as RouteData);

  const breadcrumbs = hierarchy.map((item) => breadcrumbGenerator[userType][item.entityType](item, routeValues)).flat();

  const url = breadcrumbs.length ? breadcrumbs[Math.max(0, breadcrumbs.length - 1)].href : undefined;

  const downloadApi = breadcrumbs[breadcrumbs.length - 1]?.downloadApi;

  const getName = (text: string, shouldBeBold: string) => {
    const regex = RegExp(shouldBeBold.replace(/[^\w\s]/gi, ''), 'ig');
    const textArray = text.split(regex);
    const match = text.match(regex);

    return (
      <span>
        {textArray.map((text, index) => (
          <>
            {text}
            {index !== textArray.length - 1 && match && <b>{match[index]}</b>}
          </>
        ))}
      </span>
    );
  };

  return (
    <ListItem
      sx={style.listItem}
      component={Typography}
      secondaryAction={
        downloadApi ? (
          <IconButton edge="end" aria-label="download" onClick={() => setDownloadApiFn(() => downloadApi)}>
            <DownloadRounded />
          </IconButton>
        ) : (
          <IconButton edge="end" aria-label="comments" component={Link} href={url}>
            <ArrowForwardRounded />
          </IconButton>
        )
      }
    >
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              variant="h3"
              sx={style.entityName}
              component={Link}
              href={url}
              onClick={(e) => {
                if (downloadApi) {
                  e.preventDefault();
                  setDownloadApiFn(() => downloadApi);
                }
              }}
            >
              {getName(getTranslation(item, 'name'), searchRequest.query)}
            </Typography>
            <Typography variant="status" sx={style.entityType}>
              {t(`common:entityType.${item.entityType}`)}
            </Typography>
          </Stack>
        }
        secondary={<Breadcrumbs variant="search" breadcrumbs={breadcrumbs} />}
      />
    </ListItem>
  );
};
