import { Autocomplete, Checkbox, Chip, FormControlLabel, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption, Styles, TranslationContext } from '../../../types';
import { Input } from './Input';
import { Select } from './Select';
import { palette } from '../../../styles/palette';

const styles: Styles = {
  chipsContainer: {
    flexWrap: 'wrap',
    gap: 1,
  },
  inputContainer: {
    paddingRight: '7px !important',
    svg: {
      background: palette.white,
    },
  },
};

interface SelectProps<T extends string | number> extends Omit<React.ComponentProps<typeof Select<T, T[]>>, 'value'> {
  onConfirm: (values: T[]) => void;
  onValuesChange?: (values: T[]) => void;
  options: SelectOption<T>[];
  values?: T[];
  mode?: 'select' | 'autocomplete';
  noOptionsText?: string;
  noValueText?: string;
  defaultToAll?: boolean;
  translationContext?: TranslationContext;
}

export const MultiSelect = <T extends string | number>({
  error,
  helperText,
  onConfirm,
  options,
  values,
  onValuesChange,
  mode = 'select',
  noOptionsText,
  noValueText,
  defaultToAll,
  translationContext = 'male',
  ...props
}: SelectProps<T>) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<T[]>(values ?? []);

  useEffect(() => {
    setSelectedValues(values ?? []);
  }, [values]);

  const onSelectChange = (e: SelectChangeEvent<T[]>) => {
    const values = e.target.value as T[];
    setSelectedValues(values);
    onValuesChange && onValuesChange(values);
  };

  const onAutocompleteChange = (values: SelectOption<T>[]) => {
    const selected = values.map((v) => v.value);
    setSelectedValues(selected);
    onValuesChange && onValuesChange(selected);
  };

  const onRemoveChip = (value: T) => () => {
    const newValues = selectedValues.filter((v) => v !== value);
    setSelectedValues(newValues);
    onValuesChange && onValuesChange(newValues);
  };

  const getAutocompleteSelectedOptions = () =>
    selectedValues.map((v) => {
      const option = options.find((o) => o.value === v);
      return { label: option?.label ?? '', value: v };
    });

  const renderOption = (option: SelectOption<T>, index: number, props?: React.HTMLAttributes<HTMLLIElement>) => {
    const isSelected = selectedValues.includes(option.value);
    return (
      <MenuItem key={index.toString()} value={option.value} {...props}>
        <FormControlLabel
          onClick={(e) => e.preventDefault()}
          label={option.label}
          control={<Checkbox variant={isSelected ? 'light' : 'dark'} checked={isSelected} />}
        />
      </MenuItem>
    );
  };

  const renderSelect = () => (
    <Select
      autoWidth={false}
      displayEmpty
      error={!!error}
      helperText={helperText}
      onClose={() => onConfirm(selectedValues)}
      multiple
      sx={styles.inputContainer}
      renderValue={(selected: T[]) =>
        selected.length === 0
          ? noValueText ?? t('common:filter.all', { context: translationContext })
          : options
              .filter((o) => selected.includes(o.value))
              .map((o) => o.label)
              .join(', ')
      }
      value={selectedValues}
      onChange={onSelectChange}
      {...props}
    >
      {options.map((v, i) => renderOption(v, i))}
    </Select>
  );

  const renderAutocomplete = () => (
    <Stack spacing={1} flex={1}>
      <Autocomplete
        isOptionEqualToValue={(option, value) => option.value == value.value}
        multiple
        disableCloseOnSelect
        disableClearable
        noOptionsText={noOptionsText}
        renderTags={() => null}
        value={getAutocompleteSelectedOptions()}
        onChange={(_, values) => onAutocompleteChange(values)}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => renderOption(option, options.indexOf(option), props)}
        options={options}
        renderInput={(params) => (
          <Input
            {...params}
            className={props.className}
            label={props.label}
            placeholder={props.placeholder}
            error={!!error}
            helperText={helperText}
          />
        )}
      />
      <Stack direction="row" sx={styles.chipsContainer}>
        {selectedValues.map((v) => {
          const option = options.find((o) => o.value === v);
          return (
            option && (
              <Chip
                variant="filled"
                key={v}
                onClick={onRemoveChip(v)}
                onDelete={onRemoveChip(v)}
                label={option.label}
              />
            )
          );
        })}
        {defaultToAll && !selectedValues.length && (
          <Chip variant="filled" label={t('common:filter.all', { context: translationContext })} />
        )}
      </Stack>
    </Stack>
  );

  return mode === 'select' ? renderSelect() : renderAutocomplete();
};
