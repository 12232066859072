import { VendorRebateCategoryRebateEntity } from './VendorRebateCategoryRebateEntity';
import { VendorRebateCategoryRebateSummaryEntity } from './VendorRebateCategoryRebateSummaryEntity';
import { VendorRebateCategoryRebateValueEntity } from './VendorRebateCategoryRebateValueEntity';

export class VendorRebateCategoryProgramRebateEntity extends VendorRebateCategoryRebateEntity {
  payableRebates: VendorRebateCategoryRebateValueEntity[];
  payableRebatesSummary: VendorRebateCategoryRebateSummaryEntity | null;
  holdbackRebates: VendorRebateCategoryRebateValueEntity[];
  holdbackRebatesSummary: VendorRebateCategoryRebateSummaryEntity | null;

  constructor(entity?: VendorRebateCategoryProgramRebateEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.payableRebates = [];
      this.holdbackRebates = [];
    }
  }
}
