import {
  ArchiveRounded,
  CheckRounded,
  HowToRegRounded,
  ModeCommentRounded,
  PersonOffRounded,
  UnarchiveRounded,
  VerifiedUserRounded,
} from '@mui/icons-material';
import { Button, Link, MenuItem, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { user as userApi } from '../../api';
import { TBM_MEMBER_SUPPORT_ROLE_ID } from '../../constants';
import { UserContext } from '../../contexts';
import { useApi, useAuth, useInternationalization } from '../../hooks';
import { UserEntity } from '../../models';
import { routes } from '../../routes';
import { OrderBy, UserType, VerificationStatus } from '../../types';
import { formatDateTime, formatShortDate } from '../../utils/helper';
import { LimitCommaValues } from '../LimitCommaValues';
import { AllowAccessMenuItem, ArchiveMenuItem, ResetPasswordMenuItem } from '../Menu';
import { VerifyMenuItem } from '../Menu/VerifyMenuItem';
import { Table, TableColumn } from '../Table';

export const UserListView = () => {
  const { t, getTranslation, timeZoneName } = useInternationalization();
  const navigate = useNavigate();
  const { isInternalUser, user } = useAuth();
  const { data, isLoading, filter, setFilter, fetchData, type, hasLastVerified } = useContext(UserContext);
  const archiveApi = useApi(userApi.archive, { successKey: 'common:success.action' });
  const allowAccessApi = useApi(userApi.allowAccess, { successKey: 'common:success.action' });
  const resetPasswordApi = useApi(userApi.resetPassword, { successKey: 'common:success.action' });
  const verifyApi = useApi(userApi.verify, { successKey: 'common:success.action' });

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onArchiveConfirm = async (isArchived: boolean, users: UserEntity[]) => {
    await archiveApi.call(
      users.map((i) => i.id),
      isArchived,
      type,
    );
    fetchData();
  };

  const onAllowAccessConfirm = async (isAllowedAccess: boolean, users: UserEntity[]) => {
    await allowAccessApi.call(
      users.map((i) => i.id),
      isAllowedAccess,
      type,
    );
    fetchData();
  };

  const onResetPasswordConfirm = async (user: UserEntity | null) => {
    if (user) {
      await resetPasswordApi.call(user.id, type);
    }
  };

  const onVerifyConfirm = async (users: UserEntity[]) => {
    await verifyApi.call(
      users.map((i) => i.id),
      type,
    );
    fetchData();
  };

  const bulkActions = [
    ...(type === UserType.Member
      ? [
          {
            icon: <VerifiedUserRounded />,
            label: t('common:action.verify'),
            onConfirm: onVerifyConfirm,
          },
        ]
      : []),
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: UserEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: UserEntity[]) => onArchiveConfirm(false, items),
    },
    {
      icon: <HowToRegRounded />,
      label: t('common:action.allowAccess'),
      separator: t('common:action.accessSeparator'),
      onConfirm: async (items: UserEntity[]) => onAllowAccessConfirm(true, items),
    },
    {
      icon: <PersonOffRounded />,
      label: t('common:action.blockAccess'),
      separator: t('common:action.accessSeparator'),
      onConfirm: async (items: UserEntity[]) => onAllowAccessConfirm(false, items),
    },
  ];

  const renderActionsMenuItems = (entity: UserEntity, onClick: () => void) => [
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(
          generatePath(
            isInternalUser ? get(routes.Admin.User, `${type}.Detail.path`, '') : routes.Member.User.Detail.path,
            { userId: entity.id },
          ),
        );
      }}
    >
      {t('common:view')}
    </MenuItem>,
    ...(type === UserType.Member && entity?.isVerifiable
      ? [
          <VerifyMenuItem
            key="verify"
            entity={entity}
            name={entity.fullName}
            onClick={onClick}
            onVerifyConfirm={(entity) => onVerifyConfirm([entity])}
            actionSuffix={t('user:action.suffix')}
          />,
        ]
      : []),
    ...(entity.id !== user?.profile.id
      ? [
          <AllowAccessMenuItem
            key="allowAccess"
            entity={entity}
            name={entity.fullName}
            onClick={onClick}
            onAllowAccessConfirm={(value, entity) => onAllowAccessConfirm(value, [entity])}
            actionSuffix={t('user:action.suffix')}
          />,
        ]
      : []),
    <ResetPasswordMenuItem
      key="resetPassword"
      entity={entity}
      name={entity.fullName}
      onClick={onClick}
      onResetPasswordConfirm={onResetPasswordConfirm}
      actionSuffix={t('user:action.suffix')}
    />,
    ...(entity.id !== user?.profile.id
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={entity}
            name={entity.fullName}
            onClick={onClick}
            onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
            actionSuffix={t('user:action.suffix')}
          />,
        ]
      : []),
  ];

  const renderUserLink = (id: number, text: string) => (
    <Link
      underline="always"
      href={generatePath(
        isInternalUser ? get(routes.Admin.User, `${type}.Detail.path`, '') : routes.Member.User.Detail.path,
        { userId: id },
      )}
    >
      {text}
    </Link>
  );

  const renderMembers = (item: UserEntity) =>
    item.roleId == TBM_MEMBER_SUPPORT_ROLE_ID ? (
      <Typography variant="body1">{t('common:filter.all', { context: 'male' })}</Typography>
    ) : (
      <LimitCommaValues
        value={item.members?.map((member) => `${member.fullNumber} - ${member.name}`).join(', ') ?? ''}
        maxLength={50}
      />
    );

  const renderLastVerifiedDate = (item: UserEntity) =>
    item.isArchived ? (
      ''
    ) : item.verificationStatus === VerificationStatus.Verified ? (
      formatShortDate(item.lastVerifiedDate)
    ) : (
      <Button disabled={!item?.isVerifiable} onClick={() => onVerifyConfirm([item])}>
        {t('common:action.verify')}
      </Button>
    );

  const handleBulkSelection = (items: UserEntity[], bulkActionLabel: string): boolean => {
    return bulkActionLabel === t('common:action.verify') ? items.filter((x) => !x?.isVerifiable).length > 0 : false;
  };

  return (
    <Stack spacing={3}>
      <Table
        actionMenuItems={renderActionsMenuItems}
        data={data ?? []}
        translationNamespace="user"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        bulkActions={bulkActions}
        bulkActionSuffix={t('user:action.suffix')}
        renderBulkSelection={(items) =>
          items.length === 1 ? items[0].fullName : t(isInternalUser ? `user:sections.${type}` : 'user:sections.users')
        }
        isLoading={isLoading}
        handleBulkSelection={type === UserType.Member ? handleBulkSelection : undefined}
      >
        <TableColumn
          type="custom"
          sortable
          width={type === UserType.Member ? '15%' : type === UserType.Vendor ? '20%' : '25%'}
          id="lastName"
          render={({ id, lastName }: UserEntity) => renderUserLink(id, lastName)}
        />
        <TableColumn
          type="custom"
          sortable
          width={type === UserType.Member ? '15%' : type === UserType.Vendor ? '20%' : '25%'}
          id="firstName"
          render={({ id, firstName }: UserEntity) => renderUserLink(id, firstName)}
        />
        <TableColumn
          type="custom"
          width={type === UserType.Member || type === UserType.Vendor ? '15%' : '25%'}
          sortable
          id="role"
          render={({ role }: UserEntity) => getTranslation(role, 'name')}
        />
        {type === UserType.Member && (
          <TableColumn type="custom" sortable id="members" width="20%" render={renderMembers} />
        )}
        {type === UserType.Vendor && (
          <TableColumn
            type="custom"
            sortable
            id="vendor"
            width="20%"
            render={({ vendor }: UserEntity) => (vendor ? `${vendor.fullNumber} - ${vendor.name}` : '')}
          />
        )}
        <TableColumn
          type="custom"
          sortable
          width="25%"
          id="lastLogin"
          render={({ lastLogin }: UserEntity) => formatDateTime(lastLogin, timeZoneName)}
        />
        {!!hasLastVerified && (
          <TableColumn
            type="custom"
            sortable
            width="10%"
            id="lastVerifiedDate"
            render={(item: UserEntity) => renderLastVerifiedDate(item)}
          />
        )}
        <TableColumn type="icon" sortable id="isAllowedAccess" iconComponent={CheckRounded} align="center" />
        {isInternalUser && (
          <TableColumn type="icon" tooltip id="notes" align="center" iconComponent={ModeCommentRounded} />
        )}
      </Table>
    </Stack>
  );
};
