import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { Snackbar } from './components/Snackbar';
import useAuth from './hooks/useAuth';
import { router } from './routes';
import theme from './styles/theme';

function App() {
  const { isLoaded } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={1}
        Components={{ error: Snackbar, success: Snackbar, info: Snackbar, warning: Snackbar }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className="App">{isLoaded && <RouterProvider router={router} />}</div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
