import axios from 'axios';
import { VendorBuyingAgreementTierGrowthEntity, VendorTierGrowthEntity } from '../../../models';

export const buyingAgreementTier = {
  getAllBuyingAgreementRebateCategoryTier: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
  ): Promise<VendorBuyingAgreementTierGrowthEntity[]> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/tiers`,
    );
    return data.map((v: VendorBuyingAgreementTierGrowthEntity) => new VendorBuyingAgreementTierGrowthEntity(v));
  },
  associateBuyingAgreementTier: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    tierId: number,
  ): Promise<VendorBuyingAgreementTierGrowthEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/tiers/${tierId}/associate`,
    );
    return new VendorBuyingAgreementTierGrowthEntity(data);
  },
  disassociateBuyingAgreementTier: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    tierId: number,
  ): Promise<void> => {
    await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/tiers/${tierId}/disassociate`,
    );
  },
  updateBuyingAgreementTier: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    entity: VendorTierGrowthEntity,
  ): Promise<VendorBuyingAgreementTierGrowthEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/tiers/${entity.id}`,
      entity,
    );
    return new VendorBuyingAgreementTierGrowthEntity(data);
  },
  createBuyingAgreementTier: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    entity: VendorTierGrowthEntity,
  ): Promise<VendorBuyingAgreementTierGrowthEntity> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/tiers`,
      entity,
    );
    return new VendorBuyingAgreementTierGrowthEntity(data);
  },
  deleteBuyingAgreementTier: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    tierId: number,
  ): Promise<void> => {
    await axios.delete(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/tiers/${tierId}`,
    );
  },
};
