import axios from 'axios';
import { ActivityLogEntity } from '../../models';
import { ActivityLogFilter, ExportType, PaginationEntity } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const activityLog = {
  getAllActivityLogs: async (activityLogFilter: ActivityLogFilter): Promise<PaginationEntity<ActivityLogEntity>> => {
    const { data } = await axios.post('api/activityLog/search', activityLogFilter);
    return { ...data, data: data.data.map((d: ActivityLogEntity) => new ActivityLogEntity(d)) };
  },
  exportActivityLogs: async (
    activityLogFilter: ActivityLogFilter,
    exportType: ExportType,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post(`api/activityLog/export?type=${exportType}`, activityLogFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
