import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { roleGroup as roleGroupApi } from '../../../api';
import {
  MAX_FILE_SIZE_MB,
  MAX_LONG_TEXT_LENGTH,
  MAX_PAGINATION_FILTER,
  MAX_SHORT_TEXT_LENGTH,
} from '../../../constants';
import { useAuth, useEntityFormContext, useEnumList } from '../../../hooks';
import { UserEntity, VendorBuyingAgreementEntity } from '../../../models';
import { RoleGroup, Styles, VendorBuyingAgreementType } from '../../../types';
import {
  ControlledAttachments,
  ControlledDatePicker,
  ControlledEntitySelect,
  ControlledInput,
  ControlledRadioSelect,
} from '../../Form';
import { PeriodForm } from '../../PeriodForm';
import { VendorBuyingAgreementContext } from '../../../contexts/Vendor';
const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
  notes: {
    mt: 1,
  },
};

export const VendorBuyingAgreementForm = () => {
  const { t } = useTranslation();
  const { isInternalUser } = useAuth();
  const { vendorBuyingAgreement } = useContext(VendorBuyingAgreementContext);
  const { getValues, control, readOnly, variant, watch, setIgnoreDirtySubmit } =
    useEntityFormContext<VendorBuyingAgreementEntity>();
  const rawFiles = watch('rawFiles');
  const isDuplicate = watch('isDuplicate');
  const typeOptions = useEnumList(VendorBuyingAgreementType, 'vendor:buyingAgreements.type').map((o) => ({
    ...o,
    disabled: isDuplicate,
  }));

  useEffect(() => {
    setIgnoreDirtySubmit(isDuplicate);
  }, [isDuplicate, setIgnoreDirtySubmit]);

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        <Grid item lg={variant === 'drawer' ? 6 : 4} xs={6}>
          <ControlledEntitySelect
            label={t('vendor:buyingAgreements.form.responsibleBuyer')}
            name="responsibleBuyer"
            apiFunction={roleGroupApi.getUsers}
            apiParams={[RoleGroup.Buyer, MAX_PAGINATION_FILTER]}
            getOptionLabel={(entity: UserEntity) => entity.fullName}
            control={control}
            required
          />
        </Grid>
        <Grid item lg={variant === 'drawer' ? 6 : 4} xs={6}>
          <ControlledInput
            label={t('vendor:buyingAgreements.form.vendorRepresentative')}
            name="vendorRepresentative"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        {variant !== 'drawer' && <Grid item display={{ xs: 'none', lg: 'inherit' }} xs={0} lg={4} />}
        <PeriodForm lg={variant === 'drawer' ? 4 : 2} xs={variant === 'drawer' ? 4 : 6} />
        {readOnly && getValues().approvalDate && isInternalUser && (
          <Grid item xs={4}>
            <ControlledDatePicker
              label={t('vendor:buyingAgreements.form.approvalDate')}
              name="approvalDate"
              control={control}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h3">{t('vendor:buyingAgreements.sections.type')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <ControlledRadioSelect
            label={t('vendor:buyingAgreements.form.type')}
            options={typeOptions}
            name="type"
            control={control}
            row
            hideLabel
            readOnly={!!vendorBuyingAgreement?.id}
          />
        </Grid>
      </Grid>
      {isInternalUser && (
        <>
          {variant === 'drawer' && (
            <>
              <Grid item xs={12}>
                <Typography variant="h3">{`${t('vendor:buyingAgreements.sections.files')} (${
                  rawFiles?.length || 0
                })`}</Typography>
              </Grid>
              <Box sx={style.fieldGroup}>
                <ControlledAttachments
                  label={t('vendor:buyingAgreements.form.files')}
                  name="rawFiles"
                  control={control}
                  maxSizeMB={MAX_FILE_SIZE_MB}
                  hideLabel
                />
              </Box>
            </>
          )}
          <Grid container spacing={3} sx={style.fieldGroup}>
            <Grid item xs={12}>
              <Typography variant="h3">{t('vendor:buyingAgreements.sections.notes')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ControlledInput
                label={t('vendor:buyingAgreements.form.notes')}
                name="notes"
                control={control}
                multiline
                rows={3}
                maxLength={MAX_LONG_TEXT_LENGTH}
              />
              <Box sx={style.notes}>{!readOnly && <Trans i18nKey="common:formHelperText.notes" />}</Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
