import { Box, GlobalStyles, MenuList, Paper, Popper, useMediaQuery, useTheme } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { NavigationExpanderMenuItem } from '.';
import { NavigationMenuContext } from '../../contexts';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';

const style: Styles = {
  popper: {
    zIndex: 1150,
  },
  paper: {
    backgroundColor: 'secondary.main',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: '1.5rem',
    borderTopLeftRadius: 0,
    borderTopRightRadius: '1.5rem',
    border: 'solid 1px ' + palette.secondary.main,
    borderLeft: 'none',
    boxShadow: `0 4px 8px ${palette.shadow}`,
    display: { xs: 'none', md: 'block' },
    mt: 1,
    pb: 2,
    pt: 2,
    transition: 'background-color 500ms ease, border-color 500ms ease',
  },
  menuList: {
    maxHeight: 'calc(100vh - 104px)',
    my: 0,
    overflowX: 'hidden',
    overflowY: 'overlay',
    py: 0,
    transition: 'width 500ms ease',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&:hover': {
      scrollbarWidth: 'thin',
      scrollbarColor: `${palette.primary.deep} ${palette.primary.main}`,
      '&::-webkit-scrollbar': {
        width: '8px',
      },
    },
  },
  menuListCollapsed: {
    width: '56px',
    '&::-webkit-scrollbar-thumb': {
      background: palette.primary.deep,
    },
  },
  paperExpanded: {
    backgroundColor: palette.white,
    borderColor: palette.grey[200],
  },
  menuListExpanded: {
    width: '240px',
  },
};

const globalOpenStyles = {
  '.navigation-spacing': { paddingLeft: '240px !important' },
};

export interface NavigationSideMenuProps extends PropsWithChildren {
  anchorElRef: React.MutableRefObject<Element | null>;
}

export const NavigationSideMenu = ({ anchorElRef, children }: NavigationSideMenuProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [expanded, setExpanded] = useState<boolean>(false);

  // Force a re-render when anchorElRef has the toolbar DOM element
  useEffect(() => {
    setExpanded(true);
  }, [anchorElRef, setExpanded]);

  if (!anchorElRef.current) {
    return null;
  }

  const handleExpanderClick = () => {
    setExpanded(!expanded);
  };

  return (
    <NavigationMenuContext.Provider value={{ variant: 'side', expanded }}>
      <Popper disablePortal sx={style.popper} open={true} anchorEl={anchorElRef.current} placement="bottom-start">
        <Paper sx={{ ...style.paper, ...(expanded ? style.paperExpanded : {}) }}>
          <MenuList
            className={expanded ? 'navigation-side navigation-expanded' : 'navigation-side navigation-collapsed'}
            sx={{ ...style.menuList, ...(expanded ? style.menuListExpanded : style.menuListCollapsed) }}
          >
            <NavigationExpanderMenuItem type="horizontal" onClick={handleExpanderClick} expanded={!!expanded} />
            <Box py={1.5}>{children}</Box>
          </MenuList>
          {expanded && matches && <GlobalStyles styles={globalOpenStyles} />}
        </Paper>
      </Popper>
    </NavigationMenuContext.Provider>
  );
};
