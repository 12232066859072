import { AddRounded, CheckRounded, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { cci as cciApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { CommitmentEventDetailContext } from '../../../contexts';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { CommitmentEventCategoryEntity } from '../../../models';
import { MemberCommitmentEntity, MemberCommitmentVendorEntity } from '../../../models/Cci';
import { palette } from '../../../styles/palette';
import { FilterPeriod, MemberCommitmentsFilter, PermissionKey, Styles } from '../../../types';
import { Container } from '../../Container';
import { LayoutPage } from '../../Layout';
import { MemberCommitmentDetail } from './MemberCommitmentDetail';

const styles: Styles = {
  categories: {
    backgroundColor: 'transparent',
  },
  category: {
    flexDirection: 'row-reverse',
    borderBottom: 'solid 1px ' + palette.grey[300],
  },
  categoryDisabled: {
    opacity: '1 !important',
    '.MuiListItemText-primary': {
      color: palette.grey[500],
    },
    cursor: 'default',
  },
  categoryAddIcon: {
    minWidth: 0,
  },
  archived: {
    background: palette.grey[50],
    '*': {
      color: palette.grey[400],
    },
  },
  commitmentRequiredTag: {
    color: palette.secondary.red,
    background: 'rgba(171, 18, 48, 0.15)',
    px: 1,
    borderRadius: 1,
    fontWeight: 600,
  },
  menu: {
    '& li': {
      whiteSpace: 'nowrap',
    },
  },
  accordionsContainer: {
    '>div:not(div:last-of-type)': {
      mb: 1,
    },
  },
  accordionSummary: {
    pl: 0,
  },
  accordionDetails: {
    pl: 0,
  },
  list: {
    pl: 0,
    mx: -2,
  },
  listItemButton: {
    px: 0,
  },
};

const defaultFilter = {
  isArchived: false,
  ...MAX_PAGINATION_FILTER,
};

export const MemberCommitmentList = () => {
  const { t, getTranslation } = useInternationalization();
  const { user } = useAuth();
  const [expanded, setExpanded] = useState(true);
  const { eventId, event } = useContext(CommitmentEventDetailContext);
  const filter = useMemo(
    () =>
      ({
        ...defaultFilter,
        memberId: user?.memberId ?? '',
      } as MemberCommitmentsFilter),
    [user?.memberId],
  );
  const [newMemberCommitments, setNewMemberCommitments] = useState<MemberCommitmentEntity[]>([]);
  const {
    data: memberCommitments,
    isLoading: memberCommitmentsLoading,
    refresh: refreshMemberCommitments,
  } = useApi(cciApi.getAllMemberCommitments, null, eventId, filter);
  const {
    data: categories,
    isLoading: isCategoriesLoading,
    refresh: refreshCategories,
  } = useApi(cciApi.getAllCommitmentEventCategories, null, eventId, defaultFilter);
  const filteredCategories = useMemo(
    () =>
      categories?.data.filter(
        (category) =>
          memberCommitments?.data.every((mc) => mc.commitmentEventCategory.id !== category.id) &&
          newMemberCommitments.every((mc) => mc.commitmentEventCategory.id !== category.id),
      ),
    [categories, memberCommitments, newMemberCommitments],
  );
  const canEdit = event?.period == FilterPeriod.Current;

  useEffect(() => {
    if (memberCommitments?.data) {
      setNewMemberCommitments((prev) =>
        prev.filter((c) =>
          memberCommitments?.data.every((mc) => mc.commitmentEventCategory.id !== c.commitmentEventCategory.id),
        ),
      );
    }
  }, [memberCommitments]);

  useEffect(() => {
    if (memberCommitments?.data) {
      setNewMemberCommitments((prev) =>
        prev.filter((c) =>
          memberCommitments?.data.every((mc) => mc.commitmentEventCategory.id !== c.commitmentEventCategory.id),
        ),
      );
    }
  }, [memberCommitments]);

  // Refresh data when user changes selected member
  useEffect(() => {
    refreshMemberCommitments();
    refreshCategories();
  }, [user?.memberId, refreshMemberCommitments, refreshCategories]);

  const onAccordionChange = () => {
    setExpanded((prev) => !prev);
  };

  const addCategory = (commitmentEventCategory: CommitmentEventCategoryEntity) => {
    setNewMemberCommitments((prev) => [
      {
        ...new MemberCommitmentEntity(),
        commitmentEventCategory,
        commitmentEventCategoryId: commitmentEventCategory.id,
        vendors: commitmentEventCategory.vendors.map((v) => ({
          ...new MemberCommitmentVendorEntity(),
          vendor: v.vendor,
          vendorId: v.vendor?.id ?? null,
        })),
      },
      ...prev,
    ]);
  };

  const onDelete = (entity: MemberCommitmentEntity) => {
    setNewMemberCommitments((prev) => prev.filter((mc) => mc !== entity));
  };

  const onConfirm = () => {
    refreshMemberCommitments();
    refreshCategories();
  };

  return (
    <LayoutPage
      display="Tab"
      permissions={{ keys: PermissionKey.CciViewCommitments }}
      title={t('cci:memberCommitments.title')}
    >
      <Container>{(memberCommitmentsLoading || isCategoriesLoading) && <CircularProgress size={20} />}</Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={expanded ? 4 : 12}>
          <Accordion
            expanded={expanded}
            onChange={onAccordionChange}
            TransitionProps={{ timeout: 0 }}
            sx={[styles.categories /*...(category.isArchived ? [styles.archived] : [])*/]}
          >
            <AccordionSummary sx={styles.accordionSummary} expandIcon={<ExpandMore htmlColor={palette.primary.deep} />}>
              <Typography variant="h3">{t('cci:eventCategories.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={styles.accordionDetails}>
              <List sx={styles.list}>
                {filteredCategories?.map((category) => (
                  <Tooltip
                    title={category.isCommitted ? t('cci:memberCommitments.committedCategoryWarning') : undefined}
                    placement="top"
                  >
                    <ListItemButton
                      onClick={category.isCommitted || !canEdit ? undefined : () => addCategory(category)}
                      sx={[styles.category, category.isCommitted ? styles.categoryDisabled : {}]}
                    >
                      {canEdit && (
                        <ListItemIcon sx={styles.categoryAddIcon}>
                          {category.isCommitted ? (
                            <CheckRounded htmlColor={palette.grey[500]} />
                          ) : (
                            <AddRounded htmlColor={palette.primary.deep} />
                          )}
                        </ListItemIcon>
                      )}
                      <ListItemText>{getTranslation(category, 'name')}</ListItemText>
                    </ListItemButton>
                  </Tooltip>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={expanded ? 8 : 12}>
          <Stack spacing={2}>
            <Typography variant="h2">
              {t('cci:memberCommitments.sections.commitmentRequired', { count: newMemberCommitments.length })}
            </Typography>
            <Stack spacing={1}>
              {newMemberCommitments.map((memberCommitment) => (
                <MemberCommitmentDetail
                  key={memberCommitment.commitmentEventCategoryId}
                  memberCommitment={memberCommitment}
                  onConfirm={onConfirm}
                  onDelete={onDelete}
                />
              ))}
            </Stack>
            <Typography variant="h2">
              {t('cci:memberCommitments.sections.committed', { count: memberCommitments?.data.length ?? 0 })}
            </Typography>
            <Stack spacing={1}>
              {memberCommitments?.data.map((memberCommitment) => (
                <MemberCommitmentDetail
                  key={memberCommitment.id}
                  memberCommitment={memberCommitment}
                  onConfirm={onConfirm}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </LayoutPage>
  );
};
