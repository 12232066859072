import { Box, Grid } from '@mui/material';
import { useContext } from 'react';
import { BuyGuideVendorRebateProgramDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorRebateProgramDetailContext';
import { useInternationalization } from '../../hooks';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';
import { formatShortDate } from '../../utils/helper';
import { StaticField } from '../Form';

const style: Styles = {
  container: {
    pb: 4,
  },
  borderedBox: {
    borderTop: `solid 1px ${palette.grey[300]}`,
    '>div': {
      paddingTop: 3,
    },
  },
};

export const BuyGuideVendorRebateProgramForm = () => {
  const internationalization = useInternationalization();
  const { t, getTranslation } = internationalization;
  const { rebateProgram } = useContext(BuyGuideVendorRebateProgramDetailContext);

  if (!rebateProgram) {
    return null;
  }

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={2}>
          <StaticField label={t('buyGuide:rebatePrograms.form.number')} value={rebateProgram.id} />
        </Grid>
        <Grid item xs={6} sm={2}>
          <StaticField
            label={t('buyGuide:rebatePrograms.form.vendorDesignations')}
            value={rebateProgram.vendorDesignations.map((vd) => getTranslation(vd, 'name')).join(', ')}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <StaticField
            label={t('buyGuide:rebatePrograms.form.responsibleBuyer')}
            value={rebateProgram.rebateCategory.responsibleBuyer}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <StaticField
            label={t('buyGuide:rebatePrograms.form.effectiveDate')}
            value={formatShortDate(rebateProgram.effectiveDate)}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <StaticField
            label={t('buyGuide:rebatePrograms.form.expiryDate')}
            value={formatShortDate(rebateProgram.expiryDate)}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={style.borderedBox}>
            <Grid item xs={12}>
              <StaticField
                sx={style.staticField}
                label={t('buyGuide:rebatePrograms.form.notes')}
                value={getTranslation(rebateProgram, 'memberNotes')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
