import { AddRounded } from '@mui/icons-material';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  categorization as categorizationApi,
  cci as cciApi,
  member as memberApi,
  vendor as vendorApi,
} from '../../../api';
import { DEFAULT_PAGINATION_FILTER, DOLLAR_NO_DECIMAL, MAX_PAGINATION_FILTER } from '../../../constants';
import { CommitmentEventDetailContext } from '../../../contexts';
import { useApi, useAuth, useEnumList, useGlobalEdit, useInternationalization } from '../../../hooks';
import { MemberCommitmentEntity, MemberCommitmentReportDetailEntity } from '../../../models/Cci';
import { MemberCommitmentSchema } from '../../../schemas';
import {
  CommitmentEventCategoryFilter,
  ExportType,
  LanguageCode,
  MemberCommitmentReportFilter,
  MemberCommitmentReportGrouping,
  OrderBy,
  PermissionKey,
  RebateTypeAndUnitType,
  Styles,
} from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { downloadBlob, formatShortDate } from '../../../utils/helper';
import { EditDrawer } from '../../EditDrawer';
import { ExportButton } from '../../ExportButton';
import { Autocomplete, MultiSelect } from '../../Form';
import { LayoutPage } from '../../Layout';
import { Loading } from '../../Loading';
import { MenuItem } from '../../Menu';
import { Table, TableColumn } from '../../Table';
import { MemberCommitmentForm } from '../Categories/MemberCommitmentForm';

const styles: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      md: '220px',
    },
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '344px',
    },
  },
};

const defaultFilter: MemberCommitmentReportFilter = {
  grouping: MemberCommitmentReportGrouping.None,
  ...DEFAULT_PAGINATION_FILTER,
};

const categoryFilter: CommitmentEventCategoryFilter = {
  isArchived: false,
  ...MAX_PAGINATION_FILTER,
};

export const CommitmentEventCommitments = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const params = useParams();
  const { hasPermissions } = useAuth();
  const eventId = Number(params.eventId);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filter, setFilter] = useState({ ...defaultFilter, commitmentEventIds: [eventId] });
  const { readonly } = useContext(CommitmentEventDetailContext);
  const { data, isLoading, refresh } = useApi(cciApi.getAllMemberCommitmentReportDetails, null, filter);
  const { call: getMemberCommitment, isLoading: isMemberCommitmentLoading } = useApi(cciApi.getMemberCommitment, null);
  const { data: regions, isLoading: isRegionsLoading } = useApi(categorizationApi.getAll, null, 'region');
  const { data: categories, isLoading: isCategoriesLoading } = useApi(
    cciApi.getAllCommitmentEventCategories,
    null,
    eventId,
    categoryFilter,
  );
  const { call: create } = useApi(cciApi.createMemberCommitment, { successKey: 'common:success.action' });
  const { call: update } = useApi(cciApi.updateMemberCommitment, { successKey: 'common:success.action' });
  const { call: downloadExport } = useApi(cciApi.exportMemberCommitment, { successKey: 'common:success.action' });
  const newCommitment = useMemo(() => new MemberCommitmentEntity(), []);
  const booleanCounts = filter.grouping === MemberCommitmentReportGrouping.None;

  const [selectedCommitment, setSelectedCommitment] = useState(newCommitment);

  const viewByFilterOptions = useEnumList(MemberCommitmentReportGrouping, 'cci:reportGrouping', false, 'male', false);

  const regionOptions = useMemo(
    () => regions?.values?.map((region) => ({ value: region.id, label: getTranslation(region, 'abbreviation') })) ?? [],
    [getTranslation, regions?.values],
  );

  const categoryOptions = useMemo(
    () => categories?.data.map((category) => ({ value: category.id, label: getTranslation(category, 'name') })) ?? [],
    [getTranslation, categories],
  );

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onGroupingChange = (value: MemberCommitmentReportGrouping) => () => {
    setFilter(() => ({ ...defaultFilter, commitmentEventIds: [eventId], grouping: value }));
  };

  const onRegionsChange = (values: number[]) => setFilter((prev) => ({ ...prev, regionIds: values }));

  const onCategoriesChange = (values: number[]) =>
    setFilter((prev) => ({ ...prev, commitmentEventCategoryIds: values }));

  const moneyFormatter = useMemo(
    () =>
      numberFormatter(
        currentLanguage,
        t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Dollar}`),
        DOLLAR_NO_DECIMAL,
        currentLanguage !== LanguageCode.en,
      ),
    [currentLanguage, t],
  );

  const saveDrawer = async (entity: MemberCommitmentEntity) => {
    const createdOrUpdatedEntity = !entity.id ? await create(eventId, entity) : await update(eventId, entity);
    await refresh();
    return createdOrUpdatedEntity;
  };

  const cancelDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedCommitment(newCommitment);
  };

  const confirmDrawer = () => {
    refresh();
    cancelDrawer();
  };

  const onExportClick = async (exportType: ExportType = ExportType.CSV) => {
    if (eventId !== null) {
      const fileData = await downloadExport(eventId, filter.grouping, exportType);
      if (fileData) {
        downloadBlob(fileData.filename ?? 'export.csv', fileData.data);
      }
    }
  };

  const toggleEditDrawer = (value: MemberCommitmentReportDetailEntity) => async () => {
    if (value.memberCommitmentId) {
      setIsDrawerOpen(true);
      const memberCommitment = await getMemberCommitment(eventId, value.memberCommitmentId);
      memberCommitment && setSelectedCommitment(memberCommitment);
    }
  };

  const renderSearch = () => (
    <Stack spacing={{ xs: 1, md: 2 }} mb={2}>
      <Stack spacing={{ xs: 1, md: 2 }} direction="row" alignItems="center">
        <Typography align="center" variant="label" color="grey.600">
          {t('cci:eventCommitments.filter.viewBy')}
        </Typography>
        <Stack spacing={0.7} direction="row">
          {viewByFilterOptions.map((option) => (
            <Chip
              key={option.value}
              label={option.label}
              onClick={onGroupingChange(option.value)}
              color={option.value === filter.grouping ? 'primary' : 'secondary'}
            />
          ))}
        </Stack>
      </Stack>
      <Stack spacing={{ xs: 1, md: 2 }} mb={2} direction={{ xs: 'column', md: 'row' }}>
        <Box sx={styles.selectSmallContainer}>
          <MultiSelect
            onConfirm={onCategoriesChange}
            options={categoryOptions}
            values={filter.commitmentEventCategoryIds}
            label={t('cci:eventCommitments.filter.categories')}
          />
        </Box>
        <Box sx={styles.selectSmallContainer}>
          <MultiSelect
            onConfirm={onRegionsChange}
            options={regionOptions}
            values={filter.regionIds}
            label={t('cci:eventCommitments.filter.regions')}
          />
        </Box>
        {(filter.grouping === MemberCommitmentReportGrouping.None ||
          filter.grouping === MemberCommitmentReportGrouping.Member) && (
          <Autocomplete
            minSearchTextLength={1}
            value={filter.memberNameOrNumber}
            sx={styles.textContainer}
            noOptionsText={t('member:search.notFound')}
            apiFunction={memberApi.getSuggestions}
            label={t('cci:eventCommitments.filter.member')}
            placeholder={t('member:search.placeholder')}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
            }}
            onApplySearch={(searchText) =>
              setFilter((prev) => ({
                ...prev,
                memberNameOrNumber: searchText ?? undefined,
                memberId: undefined,
                pageNumber: 1,
              }))
            }
            onSelectItem={(item) =>
              setFilter((prev) => ({
                ...prev,
                memberId: item?.id,
                memberNameOrNumber: item ? `${item.fullNumber ? item.fullNumber + ' - ' : ''}${item.name}` : undefined,
                pageNumber: 1,
              }))
            }
          />
        )}
        {filter.grouping === MemberCommitmentReportGrouping.Vendor && (
          <Autocomplete
            minSearchTextLength={1}
            value={filter.vendorNameOrNumber}
            sx={styles.textContainer}
            noOptionsText={t('vendor:search.notFound')}
            apiFunction={vendorApi.getSuggestions}
            label={t('cci:eventCommitments.filter.vendor')}
            placeholder={t('vendor:search.placeholder')}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
            }}
            onApplySearch={(searchText) =>
              setFilter((prev) => ({
                ...prev,
                vendorNameOrNumber: searchText ?? undefined,
                vendorId: undefined,
                pageNumber: 1,
              }))
            }
            onSelectItem={(item) =>
              setFilter((prev) => ({
                ...prev,
                vendorId: item?.id,
                vendorNameOrNumber: item ? `${item.fullNumber ? item.fullNumber + ' - ' : ''}${item.name}` : undefined,
                pageNumber: 1,
              }))
            }
          />
        )}
      </Stack>
    </Stack>
  );

  const renderActionsMenuItems = (entity: MemberCommitmentReportDetailEntity, onClick: () => void) => [
    <MenuItem
      id="edit"
      key="edit"
      onClick={() => {
        onClick();
        toggleEditDrawer(entity)();
      }}
    >
      {t('common:edit')}
    </MenuItem>,
  ];

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="cci:eventCommitments"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading || isRegionsLoading || isCategoriesLoading}
      actionMenuItems={
        filter.grouping === MemberCommitmentReportGrouping.None &&
        !readonly &&
        hasPermissions(PermissionKey.CciEditCommitments) &&
        !globalEditing
          ? renderActionsMenuItems
          : undefined
      }
    >
      {(filter.grouping === MemberCommitmentReportGrouping.None ||
        filter.grouping === MemberCommitmentReportGrouping.Member) && (
        <TableColumn
          type="custom"
          id="memberNumber"
          render={(item: MemberCommitmentReportDetailEntity) => item?.member?.fullNumber ?? ''}
          sortable
        />
      )}
      {(filter.grouping === MemberCommitmentReportGrouping.None ||
        filter.grouping === MemberCommitmentReportGrouping.Member) && (
        <TableColumn
          type="custom"
          id="member"
          render={(item: MemberCommitmentReportDetailEntity) => item?.member?.name ?? ''}
          sortable
        />
      )}
      {filter.grouping === MemberCommitmentReportGrouping.Vendor && (
        <TableColumn
          type="custom"
          id="vendorNumber"
          render={(item: MemberCommitmentReportDetailEntity) => item?.vendor?.fullNumber ?? ''}
          sortable
        />
      )}
      {filter.grouping === MemberCommitmentReportGrouping.Vendor && (
        <TableColumn
          type="custom"
          id="vendor"
          render={(item: MemberCommitmentReportDetailEntity) => item?.vendor?.name ?? ''}
          sortable
        />
      )}
      <TableColumn
        type="custom"
        id="category"
        render={(item: MemberCommitmentReportDetailEntity) =>
          item?.category ? getTranslation(item.category, 'name') : ''
        }
        sortable
      />
      {filter.grouping === MemberCommitmentReportGrouping.None && (
        <TableColumn
          type="custom"
          id="vendor"
          render={(item: MemberCommitmentReportDetailEntity) => item?.vendor?.name ?? ''}
          sortable
        />
      )}
      <TableColumn
        type="custom"
        id="regions"
        render={(item: MemberCommitmentReportDetailEntity) =>
          item?.regions?.length
            ? item?.regions?.map((r) => getTranslation(r, 'abbreviation')).join(', ') ?? ''
            : t('common:filter.all', { context: 'female' })
        }
      />
      <TableColumn
        type="custom"
        id="isCurrent"
        render={(item: MemberCommitmentReportDetailEntity) =>
          booleanCounts
            ? t(`common:boolean.${(!!(item?.isCurrent || false)).toString()}`)
            : item?.isCurrent?.toString() ?? ''
        }
        sortable
      />
      <TableColumn
        type="custom"
        id="willSupport"
        render={(item: MemberCommitmentReportDetailEntity) =>
          booleanCounts
            ? t(`common:boolean.${(!!(item?.willSupport || false)).toString()}`)
            : item?.willSupport?.toString() ?? ''
        }
        sortable
      />
      <TableColumn
        type="custom"
        id="volume"
        render={(item: MemberCommitmentReportDetailEntity) =>
          item?.volume != null ? moneyFormatter(item?.volume).formatted.join('') : '-'
        }
        sortable
      />
      {filter.grouping === MemberCommitmentReportGrouping.None && (
        <TableColumn
          type="custom"
          id="modifiedDate"
          render={(item: MemberCommitmentReportDetailEntity) =>
            t('cci:eventCommitments.table.committedValue', {
              name: item.modifiedBy,
              date: formatShortDate(item.modifiedDate),
            })
          }
          sortable
        />
      )}
    </Table>
  );

  const renderPageActions = () => (
    <Stack direction="row" spacing={1}>
      {filter.grouping == MemberCommitmentReportGrouping.None &&
        !readonly &&
        hasPermissions(PermissionKey.CciEditCommitments) && (
          <Button
            variant="contained"
            startIcon={<AddRounded />}
            onClick={() => setIsDrawerOpen(true)}
            disabled={globalEditing}
          >
            {t('cci:eventCommitments.actions.add')}
          </Button>
        )}
      <ExportButton
        displayMenu={filter.grouping != MemberCommitmentReportGrouping.None}
        defaultExportType={ExportType.CSV}
        onExportClick={onExportClick}
      />
    </Stack>
  );

  return (
    <LayoutPage
      display="Tab"
      permissions={{ keys: PermissionKey.CciViewCommitments }}
      title={t('cci:eventCommitments.title')}
      rightTitle={renderPageActions()}
    >
      {renderSearch()}
      {renderTable()}
      <EditDrawer
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <AddRounded />
            <Typography variant="drawerTitle">{t('cci:eventCommitments.actions.add')}</Typography>
          </Stack>
        }
        entity={selectedCommitment}
        schema={MemberCommitmentSchema()}
        onSave={saveDrawer}
        open={isDrawerOpen}
        onCancel={cancelDrawer}
        onConfirm={confirmDrawer}
      >
        {isMemberCommitmentLoading ? <Loading /> : <MemberCommitmentForm />}
      </EditDrawer>
    </LayoutPage>
  );
};
