export class TargetedMembershipMemberEntity {
  isAllowed: boolean;
  memberId: number;
  memberName: string;

  constructor(entity?: TargetedMembershipMemberEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.isAllowed = true;
      this.memberName = '';
    }
  }
}
