import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, Collapse, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MAX_MEMBER_MENU_SELECTION } from '../../constants';
import { useAuth, useInternationalization } from '../../hooks';
import { BaseUserType } from '../../models';
import { routes } from '../../routes';
import { palette } from '../../styles/palette';
import { typography } from '../../styles/typography';
import { Styles } from '../../types';
import { NavigationMenuItem } from './NavigationMenuItem';

const style: Styles = {
  menuItem: {
    '& .MuiListItemText-root': {
      px: 0,
    },
    width: '100%',
    px: 3,
    py: 1,
    mt: 1,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  menuUsername: {
    '& .MuiListItemText-root': {
      ...typography.menuUsername,
    },
    padding: '0 !important',
  },
  memberNameMenuItem: {
    '& .MuiListItemText-root': {
      py: 1,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
  },
  displayName: {
    marginTop: '4px !important',
    lineHeight: '16px',
    color: palette.grey[500],
  },
  linkContainer: {
    px: 3,
  },
};

export function NavigationUserTypeMenu() {
  const { t } = useInternationalization();
  const { user, setMemberId, isInternalUser, isMemberUser, isMemberUserSupport } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const onUserTypeChange = (userType: BaseUserType) => {
    setMemberId(userType.id);
    setIsOpen(false);
  };

  const onCollapseClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const renderIconOpen = () => (isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />);

  const showMembersList = () =>
    !isMemberUserSupport &&
    user?.profile.members &&
    user.profile.members.length > 1 &&
    user.profile.members.length <= MAX_MEMBER_MENU_SELECTION + 1;

  const showAllMemberLink = () =>
    isMemberUserSupport || (user?.profile.members && user.profile.members.length > MAX_MEMBER_MENU_SELECTION + 1);

  if (!user?.profile) {
    return null;
  }

  return (
    <Box>
      <Button sx={style.menuItem} onClick={onCollapseClick} disabled={!isMemberUser || !showMembersList()}>
        <Stack direction="row" alignItems="center" flex={1}>
          <Stack spacing={1} flex={1} alignItems="flex-start" textAlign="left">
            <NavigationMenuItem sx={style.menuUsername} title={user.profile.fullName} />
            {!isInternalUser && (
              <Typography sx={style.displayName} variant="label">
                {user.profile.getDisplayName(user.memberId)}
              </Typography>
            )}
          </Stack>
          {showMembersList() && renderIconOpen()}
        </Stack>
      </Button>
      {showAllMemberLink() && (
        <Box sx={style.linkContainer}>
          <Link
            href={`${routes.Member.Member.path}?myLocations`}
            variant="chip"
            color={palette.primary.deep}
            underline="hover"
          >
            {t('user:showAllLocations')}
          </Link>
        </Box>
      )}
      {showMembersList() && (
        <Collapse in={isOpen} timeout="auto">
          {Object.values(user?.profile.members ?? [])
            .filter((c) => c.id !== user.memberId)
            .map((c) => (
              <NavigationMenuItem
                sx={style.memberNameMenuItem}
                key={c.id}
                title={c.displayName}
                onClick={() => onUserTypeChange(c)}
              />
            ))}
        </Collapse>
      )}
    </Box>
  );
}
