import axios from 'axios';
import { VendorEntityChangeRequestEntity, VendorLocationEntity } from '../../models';
import { ChangeRequestTrigger, PaginationEntity, PaginationFilter, VendorLocationFilter } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const location = {
  getAllLocations: async (
    vendorId: number,
    locationFilter: VendorLocationFilter,
  ): Promise<PaginationEntity<VendorLocationEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/locations/search`, locationFilter);
    return { ...data, data: data.data.map((v: VendorLocationEntity) => new VendorLocationEntity(v)) };
  },
  createLocation: async (vendorId: number, entity: VendorLocationEntity): Promise<VendorLocationEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/locations`, entity);
    return new VendorLocationEntity(data);
  },
  updateLocation: async (vendorId: number, entity: VendorLocationEntity): Promise<VendorLocationEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/locations/${entity.id}`, entity);
    return new VendorLocationEntity(data);
  },
  archiveLocation: async (vendorId: number, ids: number[], isArchived: boolean): Promise<VendorLocationEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/locations/archive`, { isArchived, ids });
    return data.map((v: VendorLocationEntity) => new VendorLocationEntity(v));
  },
  getCurrentLocations: async (locationFilter: PaginationFilter): Promise<PaginationEntity<VendorLocationEntity>> => {
    const { data } = await axios.post(`api/vendor/locations/search`, locationFilter);
    return { ...data, data: data.data.map((v: VendorLocationEntity) => new VendorLocationEntity(v)) };
  },
  createLocationChangeRequest: async (entity: VendorLocationEntity): Promise<VendorLocationEntity> => {
    const { data } = await axios.post(`api/vendor/locations`, entity);
    return new VendorLocationEntity(data);
  },
  updateLocationChangeRequest: async (entity: VendorLocationEntity): Promise<VendorLocationEntity> => {
    const { data } = await axios.put(`api/vendor/locations/${entity.id}`, entity);
    return new VendorLocationEntity(data);
  },
  removeLocationChangeRequest: async (id: number): Promise<VendorLocationEntity> => {
    const { data } = await axios.delete(`api/vendor/locations/${id}`);
    return new VendorLocationEntity(data);
  },
  approvedOrRejectLocationRequest: async (
    id: number,
    trigger: ChangeRequestTrigger,
    entity: VendorLocationEntity,
  ): Promise<VendorLocationEntity> => {
    const { data } = await axios.post(`api/vendorChangeRequests/locations/${id}/${trigger}`, entity);
    return new VendorLocationEntity(data);
  },
  getLocationChangeRequest: async (id: number): Promise<VendorEntityChangeRequestEntity<VendorLocationEntity>> => {
    const { data } = await axios.get(`api/vendorChangeRequests/locations/${id}`);
    return new VendorEntityChangeRequestEntity(data);
  },
  exportAllLocations: async (vendorId: number): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/vendor/${vendorId}/locations/export`, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
