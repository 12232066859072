import { Typography } from '@mui/material';
import { useContext } from 'react';
import { vendor as vendorApi } from '../../api';
import { BuyGuideVendorRebateProgramDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorRebateProgramDetailContext';
import { useInternationalization, useApi } from '../../hooks';
import { Styles } from '../../types';
import { VendorTierGrowthList } from '../Vendor/TiersGrowths';
import { Container } from '../Container';

const style: Styles = {
  container: {
    py: 4,
  },
  header: {
    pb: 3,
  },
};

export const BuyGuideVendorRebateProgramTiersGrowths = () => {
  const { t } = useInternationalization();
  const { vendorId, rebateProgramId } = useContext(BuyGuideVendorRebateProgramDetailContext);
  const { data: tiers, isLoading: isTierLoading } = useApi(
    vendorApi.getAllRebateProgramTier,
    null,
    vendorId,
    rebateProgramId,
  );
  const { data: growths, isLoading: isGrowthsLoading } = useApi(
    vendorApi.getAllRebateProgramGrowth,
    null,
    vendorId,
    rebateProgramId,
  );

  return (
    <Container isHighlighted sx={style.container}>
      <Typography variant="h2" sx={style.header}>
        {t('vendor:rebateCategory.tierAndGrowth.title')}
      </Typography>
      <VendorTierGrowthList isLoading={isTierLoading || isGrowthsLoading} tiers={tiers} growths={growths} />
    </Container>
  );
};
