import { Box, CircularProgress, Stack } from '@mui/material';
import { useContext } from 'react';
import { VendorRebateCategoryPermissionsNoteForm } from '.';
import { vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryProgramDetailContext } from '../../../contexts/Vendor';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryProgramEntity } from '../../../models';
import { VendorRebateCategoryProgramSchema } from '../../../schemas';
import { Styles } from '../../../types';
import { EditCard } from '../../Card';
import { Container } from '../../Container';
import {
  TargetedMembershipCategorizationForm,
  TargetedMembershipMemberForm,
  TargetedMembershipMemberGroupForm,
} from '../../TargetedMembership';

const style: Styles = {
  loading: {
    mx: 2,
    mt: 3,
  },
  highlightContainer: {
    mb: 5,
  },
};

export const VendorRebateCategoryProgramPermissions = () => {
  const { t } = useInternationalization();
  const { vendorId, vendorRebateCategory, vendorRebateCategoryProgram, fetchData, isLoading, readOnly } = useContext(
    VendorRebateCategoryProgramDetailContext,
  );
  const { call: update } = useApi(vendorApi.updateRebateCategoryProgram, {
    successKey: 'common:success.save',
  });

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        {isLoading && !vendorRebateCategoryProgram && <CircularProgress size={20} sx={style.loading} />}
        {vendorRebateCategoryProgram && vendorRebateCategory && (
          <Box>
            <EditCard
              title={t('vendor:targetedMembership.title')}
              entity={vendorRebateCategoryProgram}
              readOnly={readOnly}
              disabled={vendorRebateCategoryProgram.isArchived}
              schema={VendorRebateCategoryProgramSchema()}
              onSave={(entity: VendorRebateCategoryProgramEntity) => update(vendorId, vendorRebateCategory?.id, entity)}
              onConfirm={fetchData}
            >
              <TargetedMembershipCategorizationForm />
            </EditCard>
            <EditCard
              title={t('vendor:targetedMembership.form.memberGroupTitle')}
              entity={vendorRebateCategoryProgram}
              readOnly={readOnly}
              disabled={vendorRebateCategoryProgram.isArchived}
              schema={VendorRebateCategoryProgramSchema()}
              onSave={(entity: VendorRebateCategoryProgramEntity) => update(vendorId, vendorRebateCategory?.id, entity)}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberGroupForm hideTitle />
            </EditCard>
            <EditCard
              title={t('vendor:targetedMembership.form.memberTitle')}
              entity={vendorRebateCategoryProgram}
              readOnly={readOnly}
              disabled={vendorRebateCategoryProgram.isArchived}
              schema={VendorRebateCategoryProgramSchema()}
              onSave={(entity: VendorRebateCategoryProgramEntity) => update(vendorId, vendorRebateCategory?.id, entity)}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberForm hideTitle />
            </EditCard>
            <EditCard
              title={t('vendor:rebateCategory:form.notes')}
              entity={vendorRebateCategoryProgram}
              readOnly={readOnly}
              disabled={vendorRebateCategoryProgram.isArchived}
              schema={VendorRebateCategoryProgramSchema()}
              onSave={(entity: VendorRebateCategoryProgramEntity) => update(vendorId, vendorRebateCategory?.id, entity)}
              onConfirm={fetchData}
            >
              <VendorRebateCategoryPermissionsNoteForm />
            </EditCard>
          </Box>
        )}
      </Stack>
    </Container>
  );
};
