import { PermissionKey } from '../../types';
import { Dashboard } from './Dashboard';
import {
  FailedMailSyncMembers,
  FailedMailSyncVendors,
  InProgressVBAs,
  MemberChangeRequests,
  SubmittedAds,
  SubmittedHelpTrainings,
  SubmittedPublications,
  VendorChangeRequests,
} from './Widgets';

export const DashboardAdmin = () => (
  <Dashboard>
    <VendorChangeRequests
      permissions={{
        keys: [
          PermissionKey.VendorApproveContacts,
          PermissionKey.VendorApproveFinancial,
          PermissionKey.VendorApproveFiles,
        ],
        any: true,
      }}
      placement="top"
    />
    <MemberChangeRequests
      permissions={{
        keys: [PermissionKey.MemberApproveContacts, PermissionKey.MemberApproveMembers],
        any: true,
      }}
      placement="top"
    />
    <SubmittedAds permissions={{ keys: [PermissionKey.CmsAdView, PermissionKey.CmsAdApprove] }} placement="top" />
    <SubmittedPublications
      permissions={{ keys: [PermissionKey.CmsNewsView, PermissionKey.CmsNewsApprove] }}
      placement="top"
    />
    <SubmittedHelpTrainings
      permissions={{ keys: [PermissionKey.CmsHelpView, PermissionKey.CmsHelpApprove] }}
      placement="top"
    />
    <InProgressVBAs
      placement={'center'}
      permissions={{
        keys: PermissionKey.VendorViewVBAs,
      }}
    />
    <FailedMailSyncMembers permissions={{ keys: PermissionKey.MemberApproveContacts }} placement="top" />
    <FailedMailSyncVendors permissions={{ keys: PermissionKey.VendorApproveContacts }} placement="top" />
  </Dashboard>
);
