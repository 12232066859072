import { AddRounded, EditRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { EditDrawer } from '../..';
import { VendorTierGrowthContext, VendorTierGrowthContextType } from '../../../contexts/Vendor/VendorTierGrowthContext';
import { useInternationalization } from '../../../hooks';
import { VendorTierGrowthEntity } from '../../../models';
import { EditDrawerProps } from '../../EditDrawer';
import { VendorTierGrowthForm } from './VendorTierGrowthForm';

interface VendorTiersGrowthsDrawerProps<T extends VendorTierGrowthEntity>
  extends Omit<EditDrawerProps<T>, 'title' | 'redirectLabel'> {
  context: VendorTierGrowthContextType;
}

export const VendorTiersGrowthsDrawer = <T extends VendorTierGrowthEntity>({
  entity,
  context,
  ...props
}: VendorTiersGrowthsDrawerProps<T>) => {
  const { t, getTranslation } = useInternationalization();

  return (
    <VendorTierGrowthContext.Provider value={context}>
      <EditDrawer
        {...props}
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            {entity?.id ? <EditRounded /> : <AddRounded />}
            <Typography variant="drawerTitle">
              {entity?.id
                ? t('vendor:rebateCategory.tierAndGrowth.actions.edit', {
                    name: getTranslation(entity, 'name'),
                  })
                : t('vendor:rebateCategory.tierAndGrowth.actions.add')}
            </Typography>
          </Stack>
        }
        entity={entity}
      >
        <VendorTierGrowthForm />
      </EditDrawer>
    </VendorTierGrowthContext.Provider>
  );
};
