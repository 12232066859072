import { Box, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Timbermart } from '../../assets/logos';
import { useAuth } from '../../hooks';
import { routes } from '../../routes';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';

const style: Styles = {
  branding: {
    alignItems: 'center',
    display: 'flex',
  },
  applicationName: {
    fontSize: 12,
    fontWeight: 700,
    ml: 4,
    mr: 1,
    textTransform: 'uppercase',
    color: palette.grey[500],
  },
};

interface NavigationBrandingProps {
  showUserType?: { mobile?: boolean; desktop?: boolean };
}

export const NavigationBranding = ({ showUserType }: NavigationBrandingProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Box sx={style.branding}>
      <Link href={routes.Root.path}>
        <Stack direction="row" alignItems="center">
          <Timbermart />
          <Typography
            component="span"
            sx={{
              ...style.applicationName,
              display: { xs: showUserType?.mobile ? 'unset' : 'none', md: showUserType?.desktop ? 'unset' : 'none' },
            }}
          >
            {t(`navigation:applicationName.${user?.profile.userType}`)}
          </Typography>
        </Stack>
      </Link>
    </Box>
  );
};
