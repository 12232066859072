import { mixed, object, string } from 'yup';
import { AddressSchema, EmailAddressSchema, PhoneNumberSchema } from '.';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { OfficeEntity } from '../../models';
import { EntityFormSchema, Province } from '../../types';

export const OfficeSchema = (): EntityFormSchema<OfficeEntity> => ({
  schema: object({
    description_En: string().enRequired().max(MAX_SHORT_TEXT_LENGTH),
    description_Fr: string().frRequired().max(MAX_SHORT_TEXT_LENGTH),
    address: AddressSchema(true).schema.concat(
      object({
        street: string().required().max(MAX_SHORT_TEXT_LENGTH),
        city: string().required().max(MAX_SHORT_TEXT_LENGTH),
        province: mixed<Province>().oneOf(Object.values(Province)).required(),
      }),
    ),
    notes: string().notRequired().max(MAX_LONG_TEXT_LENGTH),
    memberNotes: string().notRequired().max(MAX_LONG_TEXT_LENGTH),
    vendorNotes: string().notRequired().max(MAX_LONG_TEXT_LENGTH),
  })
    .concat(PhoneNumberSchema().schema)
    .concat(EmailAddressSchema().schema),
  errorCodeMap: {},
});
