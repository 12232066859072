import { CircularProgress, Typography } from '@mui/material';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { forestProduct as forestProductApi } from '../../../api';
import { useApi, useInternationalization, usePageTitle } from '../../../hooks';
import { ForestProductGroupEntity } from '../../../models';
import { routes } from '../../../routes';
import { ForestProductGroupSchema } from '../../../schemas';
import { PermissionKey, Styles } from '../../../types';
import { ArchiveBanner } from '../../ArchiveBanner';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { ForestProductGroupForm } from './ForestProductGroupForm';
import { ForestProductGroupHeader } from './ForestProductGroupHeader';
import { formatShortDate } from '../../../utils/helper';
import { LinkTab } from '../../Tab';
import { ForestProductGroupContext } from '../../../contexts/ForestProduct';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const ForestProductGroupDetail = () => {
  const { t, getTranslation, getTranslatablePropertyKey } = useInternationalization();
  const params = useParams();
  const productGroupId = Number(params.productGroupId);
  const outlet = useOutlet();
  const { data, isLoading, refresh } = useApi(forestProductApi.getForestProductGroup, null, productGroupId);
  const { call } = useApi(forestProductApi.updateForestProductGroup, { successKey: 'common:success.save' });

  const archiveApi = useApi(forestProductApi.archiveForestProductGroup, { successKey: 'common:success.action' });
  usePageTitle(data ? getTranslation(data, 'name') ?? '' : '');

  const onArchiveConfirm = async (isArchived: boolean, entity: ForestProductGroupEntity) => {
    await archiveApi.call([entity.id], isArchived);
    refresh();
  };

  const renderActionMenuItems = (onClick: () => void) =>
    data
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={getTranslation(data, 'name')}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('forestProduct:groups.actions.suffix')}
          />,
        ]
      : [];

  const tabs = [
    <LinkTab
      label={t('forestProduct:groups.sections.products')}
      value={generatePath(routes.Admin.ForestProductGroupDetail.Products.path, { productGroupId })}
      permissions={{ keys: PermissionKey.CommodityGroupManage }}
    />,
  ];

  return (
    <ForestProductGroupContext.Provider value={{ forestProductGroup: data, forestProductGroupId: productGroupId }}>
      <LayoutPage display="Detail" tabs={tabs} outlet={outlet}>
        <ArchiveBanner
          permissions={{ keys: PermissionKey.CommodityGroupManage }}
          entity={data}
          onUnarchive={() => data && onArchiveConfirm(false, data)}
        />
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              permissions={{ keys: PermissionKey.CommodityGroupManage }}
              titleLabel={t(`forestProduct:groups.form.name`)}
              title={getTranslatablePropertyKey(data, 'name') ?? 'id'}
              header={<ForestProductGroupHeader />}
              disabled={data.isArchived}
              entity={data}
              schema={ForestProductGroupSchema()}
              onSave={call}
              actionMenuItems={renderActionMenuItems}
              onConfirm={() => refresh()}
              breadcrumbs={[
                {
                  title: t('forestProduct:groups.title'),
                  href: routes.Admin.ForestProducts.Groups.path,
                },
                { title: getTranslation(data, 'name') },
              ]}
              renderModifiedDate={(e) => (
                <Typography variant="modifiedDate">
                  {e.modifiedBy &&
                    e.modifiedDate &&
                    t('common:entity.modifiedDate', {
                      date: formatShortDate(e.modifiedDate),
                      name: e.modifiedBy,
                    })}
                </Typography>
              )}
            >
              <ForestProductGroupForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </ForestProductGroupContext.Provider>
  );
};
