import { Box, Container, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { NotFound } from '../pages';

const style = {
  container: {
    display: 'flex',
    height: '100%',
  },
};

interface ErrorBoundaryProps {
  message?: string;
}

export function ErrorBoundary({ message }: ErrorBoundaryProps) {
  const err = useRouteError();

  const { t } = useTranslation();

  if (axios.isAxiosError(err) && err.response && err.response.status === 404) return <NotFound />;

  return (
    <Container sx={style.container}>
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <Typography fontSize={40} variant="h1">
          {message ?? t('common:error.generic')}
        </Typography>
      </Box>
    </Container>
  );
}
