import { Grid } from '@mui/material';
import { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { categorization as categorizationApi, vendor as vendorApi } from '../../../api';
import {
  DOLLAR_MAX_DECIMALS,
  MAX_LONG_TEXT_LENGTH,
  MAX_SHORT_TEXT_LENGTH,
  PERCENT_MAX_DECIMALS,
} from '../../../constants';
import { VendorBuyingAgreementRebateCategoryDetailContext } from '../../../contexts/Vendor';
import { useApi, useAuth, useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryRebateValueEntity } from '../../../models';
import { LanguageCode, RebateTypeAndUnitType, RebateValueType, VendorRebateCategoryValue } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { ControlledInput, ControlledSelect, ControlledSwitch, ControlledTranslatedInput } from '../../Form';

interface VendorRebateCategoryRebateValueFormProps {
  rebateValueType: RebateValueType;
}

export const VendorRebateCategoryRebateValueForm = ({ rebateValueType }: VendorRebateCategoryRebateValueFormProps) => {
  const { vendorBuyingAgreementId: hasVBAContext } = useContext(VendorBuyingAgreementRebateCategoryDetailContext);

  const { isInternalUser } = useAuth();
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { data: paymentMethods } = useApi(
    vendorApi.getRebateCategoryValues,
    null,
    VendorRebateCategoryValue.PaymentMethods,
  );
  const { data: receivableFrequencies } = useApi(
    vendorApi.getRebateCategoryValues,
    null,
    VendorRebateCategoryValue.ReceivableFrequencies,
  );
  const filteredReceivableFrequencies = useMemo(
    () => receivableFrequencies?.filter((x) => x.rebateValueTypes?.includes(rebateValueType)),
    [receivableFrequencies, rebateValueType],
  );
  const { data: rebateTypes } = useApi(categorizationApi.getAll, null, 'rebateType', false);
  const unitOptions = useEnumList(RebateTypeAndUnitType, 'common:rebateTypeAndUnitType');

  const { control, watch, setValue, readOnly } = useEntityFormContext<VendorRebateCategoryRebateValueEntity>();

  const isRebateNote = watch('isRebateNote');
  const unit = watch('unit');
  const isCustomUnit = unit === RebateTypeAndUnitType.Custom;

  return (
    <Grid container spacing={3} alignItems={!readOnly ? 'baseline' : undefined}>
      <Grid item xs={12}>
        <ControlledSwitch
          label={t(
            rebateValueType !== RebateValueType.Holdback
              ? 'vendor:rebateCategory.rebates.form.isRebateNote'
              : 'vendor:rebateCategory.rebates.form.isHoldbackNote',
          )}
          leftLabel={t(
            rebateValueType !== RebateValueType.Holdback
              ? 'vendor:rebateCategory.rebates.form.isRebateValue'
              : 'vendor:rebateCategory.rebates.form.isHoldbackValue',
          )}
          name="isRebateNote"
          control={control}
        />
      </Grid>
      {isRebateNote ? (
        <Grid item xs={12}>
          {rebateValueType !== RebateValueType.Holdback ? (
            <ControlledTranslatedInput
              label={t('vendor:rebateCategory.rebates.form.rebateNote')}
              name="rebateNote"
              control={control}
              required
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          ) : (
            <ControlledInput
              label={t('vendor:rebateCategory.rebates.form.holdbackNote')}
              name="rebateNote"
              control={control}
              required
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          )}
        </Grid>
      ) : (
        <>
          <Grid item xs={4}>
            <ControlledInput
              label={t(
                rebateValueType !== RebateValueType.Holdback
                  ? 'vendor:rebateCategory.rebates.form.value'
                  : 'vendor:rebateCategory.rebates.form.holdbackValue',
              )}
              name="value"
              control={control}
              formatter={numberFormatter(
                currentLanguage,
                !isCustomUnit ? t(`common:rebateTypeAndUnitType.${unit}`) : '',
                unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
                unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
              )}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <ControlledSelect
              label={t('vendor:rebateCategory.rebates.form.unit')}
              name="unit"
              options={unitOptions}
              control={control}
              required
            />
          </Grid>
          {isCustomUnit && (
            <Grid item xs={4}>
              {rebateValueType !== RebateValueType.Holdback ? (
                <ControlledTranslatedInput
                  label={t('vendor:rebateCategory.rebates.form.customUnit')}
                  name="customUnit"
                  control={control}
                  required
                  maxLength={MAX_SHORT_TEXT_LENGTH}
                />
              ) : (
                <ControlledInput
                  label={t('vendor:rebateCategory.rebates.form.customUnit')}
                  name="customUnit"
                  control={control}
                  required
                  maxLength={MAX_SHORT_TEXT_LENGTH}
                />
              )}
            </Grid>
          )}
        </>
      )}
      {rebateValueType === RebateValueType.Receivable && (
        <Grid item xs={12}>
          <ControlledInput
            label={t('vendor:rebateCategory.rebates.form.vendorRebateCode')}
            name="vendorRebateCode"
            control={control}
          />
        </Grid>
      )}
      {rebateValueType !== RebateValueType.FOID && (
        <Grid item xs={12}>
          <ControlledSelect
            label={t(
              `vendor:rebateCategory.rebates.form.${
                hasVBAContext
                  ? 'paymentTerm'
                  : rebateValueType == RebateValueType.Payable
                  ? 'payableFrequency'
                  : 'receivableFrequency'
              }`,
            )}
            name="receivableFrequencyId"
            options={
              filteredReceivableFrequencies?.map((d) => ({ label: getTranslation(d, 'value'), value: d.id })) ?? []
            }
            control={control}
            required
            onClear={() => setValue('receivableFrequencyId', null, { shouldDirty: true })}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ControlledSelect
          label={t(
            rebateValueType !== RebateValueType.Holdback
              ? 'vendor:rebateCategory.rebates.form.rebateType'
              : 'vendor:rebateCategory.rebates.form.holdbackType',
          )}
          name="rebateTypeId"
          options={rebateTypes?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
          control={control}
          onClear={() => setValue('rebateTypeId', null, { shouldDirty: true })}
        />
      </Grid>
      {rebateValueType !== RebateValueType.Payable && (
        <Grid item xs={12}>
          <ControlledSelect
            label={t('vendor:rebateCategory.rebates.form.paymentMethod')}
            name="paymentMethodId"
            options={paymentMethods?.map((d) => ({ label: getTranslation(d, 'value'), value: d.id })) ?? []}
            control={control}
            onClear={() => setValue('paymentMethodId', null, { shouldDirty: true })}
          />
        </Grid>
      )}
      {rebateValueType === RebateValueType.Holdback && (
        <Grid item xs={12}>
          <ControlledInput
            label={t('vendor:rebateCategory.rebates.form.holdbackGL')}
            name="holdbackGL"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
      )}
      {(rebateValueType === RebateValueType.FOID || rebateValueType === RebateValueType.Payable) && (
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('vendor:rebateCategory.rebates.form.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
      )}
      {isInternalUser && (
        <>
          <Grid item xs={12}>
            <ControlledInput
              label={t('vendor:rebateCategory.rebates.form.notes')}
              name="notes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
          </Grid>
          <Grid item xs={12}>
            <Trans i18nKey="common:formHelperText.notes" />
          </Grid>
        </>
      )}
    </Grid>
  );
};
