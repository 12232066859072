import axios from 'axios';
import { VendorTierGrowthEntity } from '../../models';
import { PaginationEntity, SearchVendorTiersGrowthsFilter } from '../../types';

export const tier = {
  getAllTier: async (
    vendorId: number,
    filter: SearchVendorTiersGrowthsFilter,
  ): Promise<PaginationEntity<VendorTierGrowthEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/tiers/search`, filter);
    return { ...data, data: data.data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v)) };
  },
  getAllRebateCategoryTier: async (vendorId: number, rebateCategoryId: number): Promise<VendorTierGrowthEntity[]> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/tiers`);
    return data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v));
  },
  addRebateCategoryTier: async (
    vendorId: number,
    rebateCategoryId: number,
    tierId: number,
  ): Promise<VendorTierGrowthEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/tiers/${tierId}`);
    return new VendorTierGrowthEntity(data);
  },
  removeRebateCategoryTier: async (
    vendorId: number,
    rebateCategoryId: number,
    tierId: number,
  ): Promise<VendorTierGrowthEntity> => {
    const { data } = await axios.delete(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/tiers/${tierId}`);
    return new VendorTierGrowthEntity(data);
  },
  updateTier: async (vendorId: number, entity: VendorTierGrowthEntity): Promise<VendorTierGrowthEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/tiers/${entity.id}`, entity);
    return new VendorTierGrowthEntity(data);
  },
  createTier: async (vendorId: number, entity: VendorTierGrowthEntity): Promise<VendorTierGrowthEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/tiers`, entity);
    return new VendorTierGrowthEntity(data);
  },
  archiveTier: async (vendorId: number, ids: number[], isArchived: boolean): Promise<VendorTierGrowthEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/tiers/archive`, { isArchived, ids });
    return data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v));
  },
  getAllRebateCategoryProgramTier: async (
    vendorId: number,
    rebateCategoryId: number,
    rebateProgramId: number,
  ): Promise<VendorTierGrowthEntity[]> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/${rebateProgramId}/tiers`,
    );
    return data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v));
  },
  getAllRebateProgramTier: async (vendorId: number, rebateProgramId: number): Promise<VendorTierGrowthEntity[]> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebateprograms/${rebateProgramId}/tiers`);
    return data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v));
  },
};
