import { PendingEntity } from '../PendingEntity';

export class VendorInvoicingInformationEntity extends PendingEntity {
  centralBilling: boolean;
  directBilling: boolean;
  ediCapability: boolean;
  ediSystem?: string;
  notes?: string;
  vendorNotes?: string;
  vendorId?: number;

  constructor(entity?: VendorInvoicingInformationEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.centralBilling = false;
      this.directBilling = false;
      this.ediCapability = false;
      this.ediSystem = '';
      this.notes = '';
      this.vendorNotes = '';
    }
  }
}
