import {
  Button,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useInternationalization } from '../../hooks';
import { palette } from '../../styles/palette';
import { Entity, PaginationEntity, SelectOption, Styles } from '../../types';
import { Autocomplete } from '../Form';
import { ActionMenu } from '../Menu';

const style: Styles = {
  table: {
    'thead .MuiTableCell-root': {
      border: 0,
      color: palette.grey[700],
    },
    'tbody .MuiTableCell-root': {
      py: 1,
      height: '50px',
    },
  },
  footerAction: {
    maxWidth: { md: '45%' },
  },
  fullWidth: {
    maxWidth: '100%',
  },
};

interface MemberPermissionsListProps<T, P> {
  permissions: P[];
  apiCall: (searchText: string, isArchived: boolean) => Promise<PaginationEntity<T>>;
  nameColumn: string;
  isIncludedColumn: string;
  renderKeyValue: (permission: P) => SelectOption<number>;
  renderOptionLabel: (item: T) => string;
  onAdd?: (item: T) => void;
  onUpdate: (index: number, permission: P) => void;
  onRemove?: (index: number) => void;
  readOnly?: boolean;
  isIncludedProperty: keyof P;
  fullWidth?: boolean;
}

export const MemberPermissionsList = <T extends Entity, P>({
  permissions,
  nameColumn,
  apiCall,
  renderKeyValue,
  renderOptionLabel,
  onAdd,
  onUpdate,
  onRemove,
  readOnly,
  isIncludedProperty,
  isIncludedColumn,
  fullWidth,
}: MemberPermissionsListProps<T, P>) => {
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<T | null>(null);
  const { t } = useInternationalization();

  const onAddPermission = () => {
    if (selectedItem && canAddPermission(selectedItem.id)) {
      onAdd && onAdd(selectedItem);
      setSelectedItem(null);
      setSearchText('');
    }
  };

  const canAddPermission = (itemId: number) => {
    return !permissions.map((p) => renderKeyValue(p).value).includes(itemId);
  };

  const renderIncludeLabel = (isIncluded: boolean) => {
    return isIncluded ? t('common:permission.include') : t('common:permission.exclude');
  };

  const renderIncludeAction = (isIncluded: boolean) => {
    return isIncluded ? t('common:action.include') : t('common:action.exclude');
  };

  const renderMenuItems = (permission: P, index: number) => (onClick: () => void) =>
    [
      <MenuItem
        id="isIncluded"
        key="isIncluded"
        onClick={() => {
          onClick();
          onUpdate(index, { ...permission, [isIncludedProperty]: !permission[isIncludedProperty] } as P);
        }}
      >
        {renderIncludeAction(!permission[isIncludedProperty])}
      </MenuItem>,
    ].concat(
      onRemove
        ? [
            <MenuItem
              id="remove"
              key="remove"
              onClick={() => {
                onClick();
                onRemove && onRemove(index);
              }}
            >
              {t('common:remove')}
            </MenuItem>,
          ]
        : [],
    );

  const renderPermissionTable = () => (
    <TableContainer>
      <Table sx={style.table}>
        <TableHead>
          <TableRow>
            <TableCell width="50%">{nameColumn}</TableCell>
            <TableCell width="50%">{isIncludedColumn}</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions.map((permission, index) => (
            <TableRow key={renderKeyValue(permission).value}>
              <TableCell>{renderKeyValue(permission).label}</TableCell>
              <TableCell>{renderIncludeLabel(!!permission[isIncludedProperty])}</TableCell>
              <TableCell align="right">
                {!readOnly && <ActionMenu menuItems={renderMenuItems(permission, index)} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderEmptyPlaceholder = () => <Typography variant="valuePlaceholder">{t('common:notSpecified')}</Typography>;

  const renderFooterAction = () => (
    <Stack direction="row" spacing={2} sx={fullWidth ? style.fullWidth : style.footerAction}>
      <Autocomplete
        minSearchTextLength={1}
        value={searchText}
        onValueChange={(value) => setSearchText(value)}
        noOptionsText={t('member:search.notFound')}
        apiFunction={apiCall}
        apiExtraParams={[false]}
        disableOption={(i) => !canAddPermission(i.id)}
        getOptionLabel={(option) => (typeof option === 'string' ? option : renderOptionLabel(option))}
        onApplySearch={(text) => {
          setSearchText(text);
          if (!text) {
            setSelectedItem(null);
          }
        }}
        onSelectItem={(item) => setSelectedItem(item)}
        hideLabel
      />

      <Button size="small" disabled={!selectedItem} variant="contained" onClick={onAddPermission}>
        {t('common:add')}
      </Button>
    </Stack>
  );

  return (
    <Stack spacing={2}>
      {readOnly && !permissions.length ? renderEmptyPlaceholder() : renderPermissionTable()}
      {!readOnly && onAdd && renderFooterAction()}
    </Stack>
  );
};
