import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth, useEntityFormContext } from '../../../hooks';
import { ControlledInput, ControlledTranslatedInput } from '../../Form';

export const PaymentScheduleForm = () => {
  const { isInternalUser } = useAuth();
  const { t } = useTranslation();
  const { control, variant } = useEntityFormContext();

  return (
    <Grid container spacing={3} alignItems="end">
      {isInternalUser && variant !== 'changeRequest' && (
        <>
          <Grid item xs={3}>
            <ControlledInput
              label={t('vendor:financials.form.monthlyPaymentSchedule')}
              name="monthlyPaymentSchedule"
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              label={t('vendor:financials.form.quarterlyPaymentSchedule')}
              name="quarterlyPaymentSchedule"
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              label={t('vendor:financials.form.semiAnnuallyPaymentSchedule')}
              name="semiAnnuallyPaymentSchedule"
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              label={t('vendor:financials.form.annuallyPaymentSchedule')}
              name="annuallyPaymentSchedule"
              control={control}
            />
          </Grid>
        </>
      )}
      <Grid item xs={!isInternalUser || variant === 'changeRequest' ? 12 : 3}>
        <ControlledTranslatedInput
          label={t('vendor:financials.form.paymentScheduleTerm')}
          name="paymentScheduleTerm"
          control={control}
        />
      </Grid>
      {isInternalUser && variant !== 'changeRequest' && (
        <Grid item xs={3}>
          <ControlledInput
            label={t('vendor:financials.form.paymentScheduleFOID')}
            name="paymentScheduleFOID"
            control={control}
          />
        </Grid>
      )}
      {isInternalUser && <Grid item xs={6} />}
      <Grid item xs={!isInternalUser || variant === 'changeRequest' ? 12 : 6}>
        <ControlledInput
          label={isInternalUser ? t('vendor:financials.form.vendorNotes') : t('vendor:financials.form.comments')}
          name="vendorNotes"
          control={control}
          multiline
          rows={3}
        />
      </Grid>
      <Grid item xs={6}>
        {isInternalUser && variant !== 'changeRequest' && (
          <ControlledInput
            label={t('vendor:financials.form.notes')}
            name="notes"
            control={control}
            multiline
            rows={3}
          />
        )}
      </Grid>
    </Grid>
  );
};
