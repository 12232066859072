import { Box, Link, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import { categorization as categorizationApi, vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER, MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { VendorDataReportEntity } from '../../../models';
import {
  FilterPeriod,
  OrderBy,
  PermissionKey,
  ReportPaginationEntity,
  SelectOption,
  Styles,
  TranslationContext,
  VendorDataReportFilter,
  VendorRebateCategoryProgramStatus,
} from '../../../types';
import { downloadBlob, formatShortDate } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { Autocomplete, MultiSelect, Select, Switch } from '../../Form';
import { LayoutPage } from '../../Layout';
import { Table, TableColumn } from '../../Table';
import { PeriodFilterSelect } from '../../Filter/PeriodFilterSelect';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../routes';
import { DownloadRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { LimitCommaValues } from '../../LimitCommaValues';

const style: Styles = {
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
  textContainer: {
    mb: 0.2,
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectContainer: {
    width: {
      xs: '100%',
      sm: '250px',
    },
  },
};

const defaultFilter: VendorDataReportFilter = {
  buyersIds: [],
  vendorNameOrNumber: '',
  period: FilterPeriod.Upcoming,
  status: undefined,
  targetsAll: undefined,
  custom: undefined,
  productCategories: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

const defaultSearch: VendorDataReportFilter = {
  buyersIds: [],
  vendorNameOrNumber: '',
  period: FilterPeriod.All,
  status: undefined,
  targetsAll: undefined,
  custom: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

interface VendorDataReportListProps {
  reportType: 'VBA' | 'RebateCategory' | 'RebateProgram' | 'ProductCategories';
  dataApi: (vendorDataReportFilter: VendorDataReportFilter) => Promise<ReportPaginationEntity>;
  exportApi: (vendorDataReportFilter: VendorDataReportFilter) => Promise<{
    data: Blob;
    filename?: string | undefined;
  }>;
}

export const VendorDataReportList = ({ dataApi, exportApi, reportType }: VendorDataReportListProps) => {
  const { t, getTranslation } = useInternationalization();
  const isRebateProgram = reportType === 'RebateProgram';
  const isProductCategories = reportType === 'ProductCategories';
  const [filter, setFilter] = useState(defaultSearch);
  const { data, isLoading } = useApi(dataApi, null, filter);
  const { call: downloadExport, isLoading: isExportLoading } = useApi(exportApi, {
    successKey: 'common:success.action',
  });
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const productCategories = useApi(
    categorizationApi.getAll,
    { skipFetch: !isProductCategories },
    'productCategory',
    false,
  );
  const statusOptions = useEnumList(VendorRebateCategoryProgramStatus, 'vendor:rebateCategory.programs.status');

  usePageTitle(`report:sections.${reportType}`);

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...(checked ? defaultFilter : defaultSearch) }));
    setIsAdvancedMode(checked);
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onClear = () => {
    setFilter((prev) => ({
      ...prev,
      ...defaultFilter,
    }));
  };

  const getBooleanOptions = (context: TranslationContext = 'male'): SelectOption<string>[] => [
    { label: t('common:filter.all', { context }), value: '' },
    { label: t('common:boolean.true'), value: 'true' },
    { label: t('common:boolean.false'), value: 'false' },
  ];

  const onExport = async () => {
    const fileData = await downloadExport({ ...filter, ...MAX_PAGINATION_FILTER });
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
    }
  };

  const buyerOptions = useMemo(
    () => data?.responsibleBuyersForFilter.map((b) => ({ value: b.id, label: b.displayName })) ?? [],
    [data?.responsibleBuyersForFilter],
  );

  const onStatusChange = (values: VendorRebateCategoryProgramStatus[]) =>
    setFilter((prev) => ({ ...prev, status: values }));

  const onBuyersChange = (values: number[]) => setFilter((prev) => ({ ...prev, buyersIds: values }));

  const onProductCategoriesChange = (values: number[]) => setFilter((prev) => ({ ...prev, productCategories: values }));

  const onBooleanChange = (event: SelectChangeEvent<unknown>, propertyName: keyof VendorDataReportFilter) => {
    const value = !event.target.value ? undefined : event.target.value === 'true';
    setFilter((prev) => ({ ...prev, [propertyName]: value, pageNumber: 1 }));
  };

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear} mb={3}>
      {!isProductCategories && (
        <Box sx={style.selectSmallContainer}>
          <PeriodFilterSelect filter={filter} setFilter={setFilter} />
        </Box>
      )}
      {isProductCategories && (
        <Box sx={style.selectContainer}>
          <MultiSelect
            label={t('vendor:rebateCategory.form.productCategories')}
            name="productCategoryIds"
            options={
              productCategories.data?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []
            }
            values={filter.productCategories ?? []}
            onConfirm={onProductCategoriesChange}
          />
        </Box>
      )}
      {!isProductCategories && !isRebateProgram && (
        <Box sx={style.selectContainer}>
          <MultiSelect
            displayEmpty
            options={buyerOptions}
            values={filter.buyersIds ?? []}
            onConfirm={onBuyersChange}
            noValueText={t('common:filter.all', { context: 'female' })}
            label={t('report:vendorDataReport.filter.buyer')}
          />
        </Box>
      )}
      {isRebateProgram && (
        <>
          <Box sx={style.selectSmallContainer}>
            <MultiSelect
              displayEmpty
              options={statusOptions}
              values={filter.status ?? []}
              onConfirm={onStatusChange}
              noValueText={t('common:filter.all', { context: 'female' })}
              label={t('report:vendorDataReport.filter.status')}
            />
          </Box>
          <Box sx={style.selectSmallContainer}>
            <Select
              displayEmpty
              options={getBooleanOptions()}
              value={filter.targetsAll ?? ''}
              onChange={(e) => onBooleanChange(e, 'targetsAll')}
              label={t('report:vendorDataReport.filter.targetsAll')}
            />
          </Box>
          <Box sx={style.selectSmallContainer}>
            <Select
              displayEmpty
              options={getBooleanOptions()}
              value={filter.custom ?? ''}
              onChange={(e) => onBooleanChange(e, 'custom')}
              label={t('report:vendorDataReport.filter.custom')}
            />
          </Box>
        </>
      )}
    </FilterContainer>
  );

  const renderTextSearch = () => (
    <Box sx={style.selectSmallContainer}>
      <Autocomplete
        minSearchTextLength={1}
        value={filter.vendorNameOrNumber}
        sx={style.textContainer}
        noOptionsText={t('vendor:search.notFound')}
        apiFunction={vendorApi.getSuggestions}
        label={t('vendor:search.title')}
        placeholder={t('vendor:search.placeholder')}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return `${option.fullNumber ? option.fullNumber + ' - ' : ''}${option.name}`;
        }}
        onApplySearch={(searchText) =>
          setFilter((prev) => ({ ...prev, vendorNameOrNumber: searchText ?? undefined, pageNumber: 1 }))
        }
        onSelectItem={(item) =>
          setFilter((prev) => ({ ...prev, vendorNameOrNumber: item?.name ?? undefined, pageNumber: 1 }))
        }
      />
    </Box>
  );

  const renderName = (entity: VendorDataReportEntity, text: string) => {
    const href = generatePath(
      routes.Admin.Vendor[
        reportType === 'VBA'
          ? 'BuyingAgreement'
          : reportType === 'RebateCategory'
          ? 'RebateCategory'
          : 'RebateCategoryProgram'
      ].path,
      {
        vendorId: entity.vendorId ?? entity.rebateCategory?.vendorId,
        vendorBuyingAgreementId: entity.id,
        vendorRebateCategoryId: entity.rebateCategory?.id ?? entity.id,
        rebateProgramId: entity.id,
      },
    );
    return <Link href={href}>{text}</Link>;
  };

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="report:vendorDataReport"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
    >
      <TableColumn
        type="custom"
        width={isRebateProgram ? '10%' : '20%'}
        id="vendorNumber"
        sortable
        render={(i: VendorDataReportEntity) => i.vendorNumber ?? i.rebateCategory?.vendorNumber}
      />
      <TableColumn
        type="custom"
        width={isRebateProgram ? '15%' : '20%'}
        id="vendor"
        sortable
        render={(i: VendorDataReportEntity) => i.vendorName ?? i.rebateCategory?.vendorName}
      />
      {isProductCategories && (
        <TableColumn
          type="custom"
          width="50%"
          id="productCategories"
          render={(i: VendorDataReportEntity) =>
            !!i.productCategories && (
              <LimitCommaValues
                value={i.productCategories
                  .map((productCategory) => getTranslation(productCategory, 'name'))
                  .sort()
                  .join(', ')}
                maxLength={250}
              />
            )
          }
        />
      )}
      {isRebateProgram && (
        <TableColumn
          type="custom"
          width="15%"
          id="rebateCategory"
          sortable
          render={(i: VendorDataReportEntity) => (i.rebateCategory ? getTranslation(i.rebateCategory, 'name') : '')}
        />
      )}
      {isRebateProgram && (
        <TableColumn
          type="custom"
          width="5%"
          id="id"
          sortable
          render={(i: VendorDataReportEntity) => renderName(i, i.number.toString() ?? '')}
        />
      )}
      {!isProductCategories && (
        <TableColumn
          type="custom"
          width="15%"
          id="name"
          sortable
          render={(i: VendorDataReportEntity) => renderName(i, getTranslation(i, 'name') || i.name)}
        />
      )}
      {!isProductCategories && !isRebateProgram && (
        <TableColumn
          type="custom"
          width="15%"
          id="buyer"
          sortable
          render={(i: VendorDataReportEntity) => i.responsibleBuyer ?? i.rebateCategory?.responsibleBuyer}
        />
      )}
      {isRebateProgram && <TableColumn type="property" width="12%" id="approvedBy" sortable />}
      {isRebateProgram && <TableColumn type="property" width="12%" id="verifiedBy" sortable />}
      {isRebateProgram && (
        <TableColumn
          type="custom"
          width="7%"
          id="status"
          sortable
          render={(i: VendorDataReportEntity) => t(`vendor:rebateCategory.programs.status.${i.status}`)}
        />
      )}
      {isRebateProgram && (
        <TableColumn
          type="custom"
          width="5%"
          id="targetsAll"
          sortable
          render={(i: VendorDataReportEntity) => t(`common:boolean.${i.targetsAll}`)}
        />
      )}
      {isRebateProgram && (
        <TableColumn
          type="custom"
          width="5%"
          id="custom"
          sortable
          render={(i: VendorDataReportEntity) => t(`common:boolean.${i.custom}`)}
        />
      )}
      {!isProductCategories && (
        <TableColumn
          type="custom"
          width={isRebateProgram ? '10%' : '20%'}
          id="effectiveDate"
          sortable
          render={(i: VendorDataReportEntity) => formatShortDate(i.effectiveDate)}
        />
      )}
      {!isProductCategories && (
        <TableColumn
          type="custom"
          width={isRebateProgram ? '10%' : '20%'}
          id="expiryDate"
          sortable
          render={(i: VendorDataReportEntity) => formatShortDate(i.expiryDate)}
        />
      )}
    </Table>
  );

  const renderPageActions = () => (
    <LoadingButton variant="outlined" onClick={onExport} loading={isExportLoading} startIcon={<DownloadRounded />}>
      {t('common:export')}
    </LoadingButton>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.ReportVendorData }}
      display="Tab"
      title={t(`report:sections.${reportType}`)}
      rightTitle={renderPageActions()}
    >
      <Stack spacing={3}>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark" mr={3}>
            {t('report:vendorDataReport.filter.searchByVendor')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('report:vendorDataReport.filter.filterList', {
              reportType,
              context: reportType === 'VBA' ? 'vowels' : 'consonant',
            })}
          </Typography>
        </Box>
        {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
        {renderTable()}
      </Stack>
    </LayoutPage>
  );
};
