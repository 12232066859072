import { AddRounded, CheckRounded } from '@mui/icons-material';
import { Box, Button, Link, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useOutlet } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useAuth, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { PublicationEntity } from '../../../models';
import { routes } from '../../../routes';
import { PublicationSchema } from '../../../schemas';
import {
  ContentFilter,
  ContentStatus,
  OrderBy,
  PermissionKey,
  PublicationFilter,
  PublicationUrgency,
  Styles,
} from '../../../types';
import { formatShortDate } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { EditDrawer } from '../../EditDrawer';
import { Autocomplete, Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { CmsPublicationForm } from './CmsPublicationForm';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
    my: 3,
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
};

const defaultFilter: PublicationFilter = {
  publicationName: '',
  filter: undefined,
  publicationCategoryId: undefined,
  status: undefined,
  isMandatory: undefined,
  urgency: undefined,
  vendorNameOrNumber: undefined,
  vendorId: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

export const CmsPublicationListVendor = () => {
  const { isMemberUser, isVendorUser } = useAuth();
  const outlet = useOutlet();
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const navigate = useNavigate();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const publication = useMemo(() => {
    const publication = new PublicationEntity();
    publication.urgency = PublicationUrgency.Green;
    return publication;
  }, []);
  const [filter, setFilter] = useState(defaultFilter);
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const statusOptions = useEnumList(ContentStatus, 'cms:status', true);
  const filterOptions = useEnumList(ContentFilter, 'cms:showFilter', false, undefined, false);
  const { call: create } = useApi(cmsApi.createPublication, { successKey: 'common:success.action' });
  const { data: publicationCategories } = useApi(cmsApi.getPublicationCategories, { callImmediately: true });
  const { data, isLoading, refresh } = useApi(cmsApi.getAllPublications, { skipFetch: true }, filter);
  usePageTitle('cms:publications.title');

  useEffect(() => {
    refresh();
  }, [currentLanguage, refresh]);

  if (outlet) {
    return outlet;
  }

  const categoriesOptions = [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { label: t('common:filter.all', { context: 'male' }), value: '' as any },
    ...(publicationCategories?.map((c) => ({
      value: c.id,
      label: getTranslation(c, 'name'),
      groupName: getTranslation(c, 'groupName'),
    })) ?? []),
  ];

  const saveDrawer = async (entity: PublicationEntity) => {
    const createdEntity = await create(entity);
    await refresh();
    return createdEntity;
  };

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({
      ...prev,
      ...(checked ? { ...defaultFilter, filter: ContentFilter.Upcoming } : defaultFilter),
    }));
    setIsAdvancedMode(checked);
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const clearFilter = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter, filter: ContentFilter.Upcoming }));
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onCategoryChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      publicationCategoryId: event.target.value !== '' ? Number(event.target.value) : undefined,
      pageNumber: 1,
    }));
  };

  const onStatusChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, status: (event.target.value as ContentStatus) || undefined, pageNumber: 1 }));
  };

  const onFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({ ...prev, filter: event.target.value as ContentFilter, pageNumber: 1 }));
  };

  const getDetailPath = (id: number) =>
    isMemberUser
      ? generatePath(routes.Member.PublicationDetails.path, { publicationId: id })
      : isVendorUser
      ? generatePath(routes.Vendor.PublicationDetails.Content.path, { publicationId: id })
      : generatePath(routes.Admin.PublicationDetails.Content.path, { publicationId: id });

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.CmsNewsEdit}>
      <Button variant="contained" startIcon={<AddRounded />} onClick={() => setIsAddOpen(true)}>
        {t('cms:publications.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderActionsMenuItems = (item: PublicationEntity) => {
    return [
      <MenuItem id="view" key="view" onClick={() => navigate(getDetailPath(item.id))}>
        {t('common:view')}
      </MenuItem>,
    ];
  };

  const renderPublicationLink = (id: number, text: string | undefined) =>
    !!text && (
      <Link underline="always" href={getDetailPath(id)}>
        {text}
      </Link>
    );

  const renderTextSearch = () => (
    <Autocomplete
      value={filter.publicationName}
      sx={style.textContainer}
      noOptionsText={t('cms:publications.search.notFound')}
      apiFunction={cmsApi.getPublicationSuggestions}
      label={t('cms:publications.search.title')}
      placeholder={t('cms:publications.search.placeholder')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : getTranslation(option, 'name'))}
      onApplySearch={(searchText) =>
        setFilter((prev) => ({ ...prev, publicationName: searchText ?? undefined, pageNumber: 1 }))
      }
      onSelectItem={(item) => item && navigate(getDetailPath(item.id))}
    />
  );
  const renderAdvancedSearch = () => (
    <FilterContainer onClear={clearFilter} my={3}>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={filterOptions}
          value={filter.filter ?? ''}
          onChange={onFilterChange}
          label={t('cms:publications.search.show')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={categoriesOptions}
          value={filter.publicationCategoryId?.toString() ?? ''}
          onChange={onCategoryChange}
          label={t('cms:publications.search.category')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={statusOptions}
          value={filter.status ?? ''}
          onChange={onStatusChange}
          label={t('cms:publications.search.status')}
        />
      </Box>
    </FilterContainer>
  );

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="cms:publications"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={renderActionsMenuItems}
    >
      <TableColumn
        type="custom"
        sortable
        width="25%"
        id="name"
        render={(item: PublicationEntity) => renderPublicationLink(item.id, getTranslation(item, 'name'))}
      />
      <TableColumn
        type="custom"
        sortable
        width="15%"
        id="category"
        render={(item: PublicationEntity) =>
          item.category
            ? `${getTranslation(item.category, 'groupName')} - ${getTranslation(item.category, 'name')}`
            : ''
        }
      />
      <TableColumn
        type="custom"
        sortable
        width="12.5%"
        id="effectiveDate"
        render={({ effectiveDate }: PublicationEntity) => formatShortDate(effectiveDate)}
      />
      <TableColumn
        type="custom"
        sortable
        width="12.5%"
        id="expiryDate"
        render={({ expiryDate }: PublicationEntity) => formatShortDate(expiryDate)}
      />
      <TableColumn
        type="custom"
        sortable
        width="15%"
        id="submittedBy"
        render={(item: PublicationEntity) => item.modifiedBy}
      />
      <TableColumn
        type="custom"
        sortable
        width="10%"
        id="status"
        render={(item: PublicationEntity) => (item.status ? t(`cms:status.${item.status}`) : '')}
      />
      <TableColumn
        type="custom"
        sortable
        align="center"
        id="isActive"
        render={({ isActive }: PublicationEntity) => !!isActive && <CheckRounded fontSize="small" />}
      />
    </Table>
  );

  return (
    <LayoutPage
      display="Tab"
      title={t('cms:publications.title')}
      rightTitle={renderAddButton()}
      permissions={{
        keys: PermissionKey.CmsNewsView,
      }}
    >
      <EditDrawer
        title={
          <HasPermissions keys={[PermissionKey.CmsNewsEdit]}>
            <Stack direction="row" spacing={1} alignItems="center">
              <AddRounded />
              <Typography variant="drawerTitle">{t('cms:publications.actions.add')}</Typography>
            </Stack>
          </HasPermissions>
        }
        open={isAddOpen}
        redirectPath={(item) => getDetailPath(item.id)}
        entity={publication}
        schema={PublicationSchema()}
        redirectLabel={t('cms:publications.actions.saveOpen')}
        onSave={saveDrawer}
        onConfirm={confirmDrawer}
        onCancel={cancelDrawer}
      >
        <CmsPublicationForm />
      </EditDrawer>
      <Box sx={style.filterBox}>
        <Typography variant="searchTitle" color="primary.dark">
          {t('cms:publications.search.title')}
        </Typography>
        <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
        <Typography variant="searchTitle" color="grey.600">
          {t('cms:publications.search.filterList')}
        </Typography>
      </Box>
      {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
      {renderTable()}
    </LayoutPage>
  );
};
