import { vendor as vendorApi } from '../../../api';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorBuyingAgreementEntity } from '../../../models';
import { PermissionKey } from '../../../types';
import { downloadBlob } from '../../../utils/helper';
import { MenuItem } from '../../Menu';
import { MenuItemProps } from '../../Menu/MenuItem';

interface VendorBuyingAgreementExportMenuItemProps extends MenuItemProps {
  entity: VendorBuyingAgreementEntity;
  onClick: () => void;
}

export const VendorBuyingAgreementExportMenuItem = ({
  entity,
  onClick,
  ...props
}: VendorBuyingAgreementExportMenuItemProps) => {
  const { t } = useInternationalization();
  const { call: downloadExport } = useApi(vendorApi.exportBuyingAgreement, null);

  const exportBuyingAgreement = async () => {
    if (entity !== null) {
      const fileData = await downloadExport(entity.vendorId, entity.id);
      if (fileData) {
        downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
      }
    }
  };

  return (
    <MenuItem
      {...props}
      id="export"
      key="export"
      permissions={{ keys: PermissionKey.VendorViewVBAs }}
      onClick={() => {
        exportBuyingAgreement();
        onClick();
      }}
    >
      {t('vendor:buyingAgreements.actions.export')}
    </MenuItem>
  );
};
