import { Box, lighten } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../styles/palette';
import { Styles } from '../../../types';
import { RadioSelect } from '../Base/RadioSelect';
import { StaticField } from '../Base/StaticField';

const style: Styles = {
  dirty: {
    backgroundColor: lighten(palette.secondary.orange, 0.85),
    border: '1px solid ' + palette.secondary.orange,
    borderRadius: 3,
    padding: 2,
  },
};

interface ControlledRadioSelectProps extends React.ComponentProps<typeof RadioSelect> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
}

export const ControlledRadioSelect = ({
  name,
  control,
  label,
  required,
  readOnly,
  ...props
}: ControlledRadioSelectProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, fieldState: { error, isDirty } }) =>
        control._options.context?.readOnly || readOnly ? (
          <StaticField
            label={label}
            value={props.options?.find((o) => o.value === value)?.label}
            hideLabel={props.hideLabel}
          />
        ) : (
          <Box sx={control._options.context?.variant === 'changeRequest' && isDirty ? style.dirty : undefined}>
            <RadioSelect
              {...props}
              onBlur={onBlur}
              name={name}
              label={required ? `${label} *` : label}
              id={name}
              onChange={onChange}
              value={value ?? ''}
              error={!!error}
              helperText={error?.message && t(error?.message, { name: label })}
            />
          </Box>
        )
      }
    />
  );
};
