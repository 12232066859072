import axios from 'axios';
import { AssignedPermissionEntity, RoleEntity, UserEntity } from '../models';
import { PermissionKey, UserType } from '../types';

export const role = {
  getUsers: async (role: number): Promise<UserEntity[]> => {
    const { data } = await axios.get<UserEntity[]>(`api/role/${role}/users`);
    return data.map((d) => Object.assign(new UserEntity(), d));
  },
  getAll: async (type: UserType): Promise<RoleEntity[]> => {
    const { data } = await axios.get<RoleEntity[]>(`api/role?type=${type}`);
    return data.map((d) => Object.assign(new RoleEntity(), d));
  },
  getPermissions: async (role: number): Promise<AssignedPermissionEntity[]> => {
    const { data } = await axios.get<AssignedPermissionEntity[]>(`api/role/${role}/permissions`);
    return data.map((d) => new AssignedPermissionEntity(d));
  },
  updatePermissions: async (roleId: number, permissions: PermissionKey[]): Promise<AssignedPermissionEntity[]> => {
    const { data } = await axios.put<AssignedPermissionEntity[]>(`api/role/${roleId}/permissions`, {
      permissionKeys: permissions,
    });
    return data.map((d) => new AssignedPermissionEntity(d));
  },
};
