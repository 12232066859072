import { AccountCircleRounded, EventRounded } from '@mui/icons-material';
import { Box, Grid, IconButton, Link, Menu, Stack, Tooltip, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { NavigationUserMenuItems, NotificationMenu } from '.';
import { NavigationMenuContext } from '../../contexts';
import { useAuth, useBreakpoints, useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { palette } from '../../styles/palette';
import { Styles } from '../../types';

const style: Styles = {
  profileButton: {
    padding: '7px',
    marginLeft: '5px',
  },
  profileUserInfo: {
    maxWidth: '108px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  userMenu: {
    mt: 1.5,
  },
  userMenuList: {
    minWidth: '330px',
  },
  userMenuPaper: {
    borderRadius: '20px',
    borderTopRightRadius: 0,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
  },
};

export const NavigationUserMenu = () => {
  const { t } = useInternationalization();
  const { user, isInternalUser, isMemberUser } = useAuth();
  const buttonRef = useRef(null);
  const [visible, setVisible] = useState<boolean>(false);
  const displayName = user?.profile.getDisplayName(user.memberId);
  const breakpoint = useBreakpoints();

  const getAccountTooltip = () =>
    displayName ? (
      <Typography variant="tooltip">{user ? `${user.profile.fullName}\n${displayName}` : ''}</Typography>
    ) : null;

  return (
    <Grid display="flex" alignItems="center">
      {isMemberUser && (
        <Tooltip title={t('cms:events.title')}>
          <IconButton component={Link} href={generatePath(routes.Member.Events.path)} size="small">
            <EventRounded htmlColor={palette.primary.deep} />
          </IconButton>
        </Tooltip>
      )}
      <NotificationMenu />
      <Tooltip title={getAccountTooltip()}>
        <IconButton ref={buttonRef} sx={style.profileButton} onClick={() => setVisible(true)} size="small">
          <Stack direction="row" spacing={1} alignItems="center">
            <AccountCircleRounded />
            {user?.profile && !isInternalUser && breakpoint != 'xs' && (
              <Stack alignItems="flex-start" spacing={0.3}>
                <Typography sx={style.profileUserInfo} variant="breadcrumb">
                  {user?.profile.fullName}
                </Typography>
                <Typography sx={style.profileUserInfo} variant="userDisplayName">
                  {displayName}
                </Typography>
              </Stack>
            )}
          </Stack>
        </IconButton>
      </Tooltip>
      <NavigationMenuContext.Provider value={{ variant: 'side', expanded: true }}>
        <Menu
          id="menu-user"
          className="navigation-user"
          anchorEl={buttonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={visible}
          onClose={() => setVisible(false)}
          onClick={() => setVisible(false)}
          MenuListProps={{
            sx: style.userMenuList,
          }}
          PaperProps={{
            sx: style.userMenuPaper,
          }}
          sx={style.userMenu}
        >
          <Box>
            <NavigationUserMenuItems />
          </Box>
        </Menu>
      </NavigationMenuContext.Provider>
    </Grid>
  );
};
