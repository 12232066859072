import { Skeleton } from '@mui/material';
import { Entity, Pending, Styles } from '../../types';
import { EditCard } from './EditCard';

const style: Styles = {
  skeleton: {
    my: 2,
    height: 230,
  },
};

interface EditCardSkeletonProps<T extends Entity & Pending>
  extends Omit<React.ComponentProps<typeof EditCard<T>>, 'entity'> {
  entity: T | null;
  isLoading?: boolean;
}

export const EditCardSkeleton = <T extends Entity>({
  isLoading = false,
  entity,
  ...props
}: EditCardSkeletonProps<T>) => {
  if (isLoading || !entity) {
    return <Skeleton variant="rounded" sx={style.skeleton} />;
  }

  return <EditCard entity={entity} {...props} />;
};
