import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { EventObject } from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import Calendar from '@toast-ui/react-calendar';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAuth, useInternationalization } from '../../../hooks';
import { EventEntity } from '../../../models';
import { routes } from '../../../routes';
import { palette } from '../../../styles/palette';
import { typography } from '../../../styles/typography';
import { EventFilter, EventType, PermissionKey, PublicationUrgency, Styles } from '../../../types';
import { getEventTypeBackgroundColor, getEventTypeIcon, getUrgencyIcon } from '../../../utils/eventCalendar';
import { formatShortDate } from '../../../utils/helper';

const style: Styles = {
  calendarContainer: {
    py: 1,
    '& .toastui-calendar-daygrid-cell': {
      borderLeft: `1px solid ${palette.grey[300]}`,
      '& .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator': {
        backgroundColor: palette.primary.main,
        height: '30px',
        width: '30px',
        lineHeight: '30px',
      },
    },
    '& .toastui-calendar-weekday-event-block': {
      marginTop: '5px !important',
    },
    '& .toastui-calendar-template-time svg': {
      width: '20px',
      height: '20px',
      '& path': {
        fill: 'currentColor',
      },
    },
  },
  calendarHeader: {
    pb: 2,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    height: '25px',
    cursor: 'pointer',
  },
  title: {
    ...typography.h5,
    fontWeight: 500,
    marginLeft: '5px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  monthDayName: {
    ...typography.tabLabel,
    color: palette.black,
  },
  showMoreButton: {
    position: 'absolute',
    bottom: '10px',
    left: 0,
    right: 0,
    ...typography.label,
    fontWeight: 600,
    color: palette.primary.deep,
    fontFamily: typography.fontFamily,
  },
};

interface CmsEventsCalendarProps {
  events: EventEntity[];
  onFilterChange: (filter: Partial<EventFilter>, view?: 'calendar' | 'list') => void;
  isLoading: boolean;
  emptyPlaceholder?: ReactNode;
}

export const CmsEventsCalendar = ({ events, onFilterChange, isLoading, emptyPlaceholder }: CmsEventsCalendarProps) => {
  const { t, getTranslation } = useInternationalization();
  const navigate = useNavigate();
  const { hasPermissions } = useAuth();
  const calendarRef = useRef<Calendar>(null);

  const triggerDateChange = useCallback(() => {
    if (calendarRef.current?.calendarInstance) {
      onFilterChange({
        effectiveDate: formatShortDate(calendarRef.current.calendarInstance.getDateRangeStart().toDate()),
        expiryDate: formatShortDate(calendarRef.current.calendarInstance.getDateRangeEnd().toDate()),
      });
    }
  }, [onFilterChange]);

  useEffect(() => {
    triggerDateChange();
  }, [triggerDateChange]);

  useEffect(() => {
    if (calendarRef.current?.calendarInstance) {
      calendarRef.current.calendarInstance.on('clickMoreEventsBtn', ({ date }) => {
        onFilterChange({ effectiveDate: formatShortDate(date), expiryDate: formatShortDate(date) }, 'list');
      });
      calendarRef.current.calendarInstance.on('clickEvent', (item) => {
        switch (item.event.raw.type) {
          case EventType.PoolBuy:
          case EventType.SpecialBuy:
          case EventType.Booking:
            hasPermissions(PermissionKey.VendorViewBuyGuide) &&
              navigate(
                generatePath(routes.Member.BuyGuideBuyOpportunityDetail.path, {
                  vendorId: item.event.raw.vendorId,
                  vendorBuyOpportunityId: item.event.id,
                }),
              );
            break;
          case EventType.Publication:
            navigate(
              generatePath(routes.Member.PublicationDetails.path, {
                publicationId: item.event.id,
              }),
            );
            break;

          default:
            break;
        }
      });
    }
  }, [hasPermissions, navigate, onFilterChange]);

  const getEventTypeColor = (eventType: EventType) => {
    switch (eventType) {
      case EventType.SpecialBuy:
        return palette.grey[600];
      case EventType.Booking:
      case EventType.PoolBuy:
      case EventType.CCI:
      case EventType.Publication:
        return palette.white;
    }
  };

  const calendarEvents = events.map((e) => ({
    id: e.id,
    title: (e.vendorName ? e.vendorName + ' - ' : '') + getTranslation(e, 'name'),
    start: e.effectiveDate,
    end: e.expiryDate,
    backgroundColor: getEventTypeBackgroundColor(e.type),
    borderColor: getEventTypeBackgroundColor(e.type),
    color:
      e.expiryDate && formatShortDate(e.effectiveDate) != formatShortDate(e.expiryDate)
        ? getEventTypeColor(e.type)
        : palette.grey[600],
    raw: {
      type: e.type,
      vendorId: e.vendorId,
      urgency: e.urgency,
    },
  }));

  const renderTime = (title: string, raw: { type: EventType; urgency: PublicationUrgency }, color: string) => {
    const text = (
      <Stack direction="row" alignItems="center" style={style.titleContainer as React.CSSProperties}>
        {getEventTypeIcon(raw.type, color)}
        {raw.type === EventType.Publication && getUrgencyIcon(raw.urgency, color)}
        <span title={title} style={{ ...style.title, color } as React.CSSProperties}>
          {title}
        </span>
      </Stack>
    );

    return ReactDOMServer.renderToString(text);
  };

  const renderMonthDayName = (label: string) => {
    const text = <span style={style.monthDayName as React.CSSProperties}>{label}</span>;
    return ReactDOMServer.renderToString(text);
  };

  const renderShowMoreButton = (moreEventCount: number) => {
    const text = (
      <span style={style.showMoreButton as React.CSSProperties}>
        {t('cms:events.showMore', { count: moreEventCount })}
      </span>
    );
    return ReactDOMServer.renderToString(text);
  };

  const template = {
    monthDayName({ label }: EventObject) {
      return renderMonthDayName(label);
    },
    time({ title, raw, color }: EventObject) {
      return renderTime(title, raw, color);
    },
    monthGridHeaderExceed(hiddenEvents: number) {
      return renderShowMoreButton(hiddenEvents);
    },
  };

  const theme = {
    common: {
      backgroundColor: 'transparent',
      dayName: { color: palette.black },
      today: { color: palette.white },
      holiday: { color: palette.black },
      saturday: { color: palette.black },
      border: '1px solid ' + palette.grey[300],
    },
    month: {
      dayExceptThisMonth: { color: palette.black },
      holidayExceptThisMonth: { color: palette.black },
    },
  };

  const onTodayClick = () => {
    calendarRef.current?.calendarInstance?.today();
    triggerDateChange();
  };

  const onNextClick = () => {
    calendarRef.current?.calendarInstance?.next();
    triggerDateChange();
  };

  const onPrevClick = () => {
    calendarRef.current?.calendarInstance?.prev();
    triggerDateChange();
  };

  const renderDateRange = () => {
    if (calendarRef.current?.calendarInstance) {
      const effectiveDate = calendarRef.current.calendarInstance.getDateRangeStart().toDate();
      const expiryDate = calendarRef.current.calendarInstance.getDateRangeEnd().toDate();
      const hasSameYear = effectiveDate.getFullYear() === expiryDate.getFullYear();
      let expiryDateStr = formatShortDate(expiryDate);
      expiryDateStr = hasSameYear ? expiryDateStr.substring(5, expiryDateStr.length) : expiryDateStr;
      return (
        <>
          {formatShortDate(effectiveDate)} &mdash; {expiryDateStr}
        </>
      );
    }
  };

  const renderCalendarHeader = () => (
    <Box sx={style.calendarHeader}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h2">{renderDateRange()}</Typography>
        {isLoading && <CircularProgress size={20} />}
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Button disabled={isLoading} onClick={onTodayClick} variant="outlined" size="small">
          {t('cms:events.calendar.today')}
        </Button>
        <Stack direction="row">
          <IconButton disabled={isLoading} onClick={onPrevClick}>
            <KeyboardArrowLeftRounded />
          </IconButton>
          <IconButton disabled={isLoading} onClick={onNextClick}>
            <KeyboardArrowRightRounded />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <Box sx={style.calendarContainer}>
      {renderCalendarHeader()}
      {calendarEvents?.length === 0 && emptyPlaceholder}
      <Box display={calendarEvents?.length === 0 && emptyPlaceholder ? 'none' : undefined}>
        <Calendar
          ref={calendarRef}
          gridSelection={false}
          isReadOnly
          usageStatistics={false}
          view="month"
          events={!isLoading ? calendarEvents : []}
          theme={theme}
          template={template}
          month={{
            dayNames: [
              t('cms:events.calendar.sunday'),
              t('cms:events.calendar.monday'),
              t('cms:events.calendar.tuesday'),
              t('cms:events.calendar.wednesday'),
              t('cms:events.calendar.thursday'),
              t('cms:events.calendar.friday'),
              t('cms:events.calendar.saturday'),
            ],
            visibleWeeksCount: 2,
            visibleEventCount: 12,
          }}
        />
      </Box>
    </Box>
  );
};
