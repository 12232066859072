import { useContext, useEffect, useMemo } from 'react';
import { vendor as vendorApi } from '../../api';
import { EntityFormProvider, VendorChangeRequestContext } from '../../contexts';
import { useApi, useEntityForm } from '../../hooks';
import { VendorLocationEntity } from '../../models';
import { VendorLocationSchema } from '../../schemas';
import { ChangeRequestTrigger } from '../../types';
import { padChangeRequestArray, unpadChangeRequestArray } from '../../utils/changeRequests';
import { EditDrawerChangeRequest } from '../EditDrawerChangeRequest';
import { VendorLocationForm } from '../Vendor/Locations';
import { ChangeRequestDrawerHeader } from './ChangeRequestDrawerHeader';

export const VendorLocationChangeRequest = () => {
  const { changeRequest: vendorChangeRequest, isAfter } = useContext(VendorChangeRequestContext);
  const { data, isLoading } = useApi(vendorApi.getLocationChangeRequest, null, vendorChangeRequest.id);
  const { call: update } = useApi(vendorApi.approvedOrRejectLocationRequest, { successKey: 'common:success.save' });
  const entity = useMemo(() => new VendorLocationEntity(), []);
  const paddedData = useMemo(() => padChangeRequestArray(data, 'emailAddresses', 'phoneNumbers'), [data]);

  const { handleSubmit, reset, setValues, isSubmitDisabled, form, handleError } = useEntityForm<VendorLocationEntity>(
    paddedData?.entity || entity,
    VendorLocationSchema(),
    {
      variant: 'changeRequest',
      readOnly: !isAfter || vendorChangeRequest.isDeleted,
    },
  );

  useEffect(() => {
    if (data) {
      if (isAfter) {
        setValues(!vendorChangeRequest.isDeleted ? paddedData?.changeRequest || entity : entity);
      } else {
        reset();
      }
    }
  }, [data, isAfter, setValues, reset, paddedData?.changeRequest, vendorChangeRequest.isDeleted, entity]);

  const saveDrawer = (e: VendorLocationEntity, trigger: ChangeRequestTrigger) => {
    return update(vendorChangeRequest.id, trigger, unpadChangeRequestArray(e, 'emailAddresses', 'phoneNumbers'));
  };

  return (
    <EntityFormProvider {...form} isLoading={isLoading || !data}>
      <EditDrawerChangeRequest
        open={true}
        entity={entity}
        handleSubmit={handleSubmit}
        handleError={handleError}
        isSubmitDisabled={vendorChangeRequest.isDeleted ? false : isSubmitDisabled}
        reset={reset}
        onSave={saveDrawer}
      >
        <ChangeRequestDrawerHeader />
        <VendorLocationForm />
      </EditDrawerChangeRequest>
    </EntityFormProvider>
  );
};
