import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { getDashboardPath, routes } from '../../routes';

export function RequireUnauth({ children }: PropsWithChildren) {
  const { user, userTemporaryPassword, userVerifyCode, userVerifyPhoneNumber } = useAuth();
  const location = useLocation();
  const { state } = useLocation();

  if (userTemporaryPassword) {
    return <Navigate to={routes.SetPassword.path} replace />;
  }
  if (userVerifyCode) {
    return <Navigate to={routes.VerifyCode.path} replace />;
  }
  if (userVerifyPhoneNumber) {
    return <Navigate to={routes.VerifyPhoneNumber.path} replace />;
  }
  if (user?.requiresVerificationPhoneNumber) {
    return <Navigate to={routes.SetVerificationPhoneNumber.path} replace />;
  }

  return user ? (
    <Navigate to={state?.path || getDashboardPath(user.profile.userType)} replace state={{ path: location.pathname }} />
  ) : (
    <>{children}</>
  );
}
