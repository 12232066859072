import Box from '@mui/material/Box';
import { PropsWithChildren } from 'react';
import { ConfirmationModalProvider, GlobalEditProvider, SearchProvider } from '../../contexts';
import { Styles } from '../../types';

const style: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    pt: 8,
    alignItems: 'flex-start',
    '& > :last-child': {
      flex: 1,
    },
  },
};

interface LayoutBaseUserProps extends PropsWithChildren {
  children?: React.ReactNode;
  sx?: Styles[0];
}

export const LayoutBaseUser = ({ children, sx }: LayoutBaseUserProps) => (
  <ConfirmationModalProvider>
    <GlobalEditProvider>
      <SearchProvider>
        <Box sx={{ ...style.container, ...sx }}>{children}</Box>
      </SearchProvider>
    </GlobalEditProvider>
  </ConfirmationModalProvider>
);
