import axios from 'axios';
import { MemberEntity, MemberGroupEntity } from '../../models';

export const blocked = {
  getBlockedMembers: async (vendorId: number): Promise<MemberEntity[]> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/blocked/members`);
    return data.map((m: MemberEntity) => new MemberEntity(m));
  },
  blockMember: async (vendorId: number, memberId: number): Promise<MemberEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/blocked/members/${memberId}`);
    return new MemberEntity(data);
  },
  unblockMember: async (vendorId: number, memberId: number): Promise<void> => {
    await axios.delete(`api/vendor/${vendorId}/blocked/members/${memberId}`);
  },
  getBlockedMemberGroups: async (vendorId: number): Promise<MemberGroupEntity[]> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/blocked/memberGroups`);
    return data.map((mg: MemberGroupEntity) => new MemberGroupEntity(mg));
  },
  blockMemberGroup: async (vendorId: number, memberGroupId: number): Promise<MemberGroupEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/blocked/memberGroups/${memberGroupId}`);
    return new MemberGroupEntity(data);
  },
  unblockMemberGroup: async (vendorId: number, memberGroupId: number): Promise<void> => {
    await axios.delete(`api/vendor/${vendorId}/blocked/memberGroups/${memberGroupId}`);
  },
};
