import { CheckCircleRounded, CloseRounded, InfoRounded, ReportProblemRounded } from '@mui/icons-material';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import { SnackbarContent, SnackbarKey, SnackbarMessage, VariantType, useSnackbar } from 'notistack';
import { forwardRef } from 'react';
import { palette } from '../styles/palette';
import { Styles } from '../types';

const style: Styles = {
  container: {
    backgroundColor: palette.primary.dark,
    pr: '3px',
    pl: '15px',
    py: '3px',
    borderRadius: '8px',
    maxWidth: '100vw',
  },
  text: {
    mt: '-3px',
    marginLeft: 1.5,
    color: palette.white,
  },
  closeIcon: {
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: palette.white,
      },
      backgroundColor: palette.primary.deep,
    },
  },
};

interface SnackbarProps {
  id: SnackbarKey;
  message: SnackbarMessage;
  variant: VariantType;
}

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>((props, ref) => {
  const { message, id, variant } = props;
  const { closeSnackbar } = useSnackbar();

  const getColorFromVariant = () => {
    switch (variant) {
      case 'info':
        return palette.info.main;
      case 'warning':
        return palette.warning.main;
      case 'error':
        return palette.error.light;
      case 'success':
        return palette.success.main;
    }
  };

  const getIconFromVariant = () => {
    switch (variant) {
      case 'info':
        return <InfoRounded fontSize="small" htmlColor={getColorFromVariant()} />;
      case 'warning':
      case 'error':
        return <ReportProblemRounded fontSize="small" htmlColor={getColorFromVariant()} />;
      case 'success':
        return <CheckCircleRounded fontSize="small" htmlColor={getColorFromVariant()} />;
    }
  };

  const onCloseSnackbar = () => closeSnackbar(id);

  return (
    <SnackbarContent ref={ref}>
      <Card onClick={onCloseSnackbar} sx={style.container}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
          <Stack spacing={1} direction="row" alignItems="center">
            {getIconFromVariant()}
            <Typography sx={style.text} variant="toast">
              {message}
            </Typography>
          </Stack>
          <IconButton sx={style.closeIcon} color="secondary">
            <CloseRounded htmlColor={palette.white} />
          </IconButton>
        </Stack>
      </Card>
    </SnackbarContent>
  );
});
