import { CategorizationEntity } from '../Categorization';
import { VendorEntity } from '../Vendor';

export class CommitmentEventCategoryVendorEntity {
  id: number;
  vendorId: number | null;
  vendor: VendorEntity | null;
  regionIds: number[];
  regions: CategorizationEntity[];
  isPreferred: boolean;
  volume?: number;
  isCurrent?: number;
  willSupport?: number;

  constructor(entity?: CommitmentEventCategoryVendorEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.vendorId = null;
      this.vendor = new VendorEntity();
      this.regionIds = [];
      this.regions = [];
      this.isPreferred = false;
    }
  }
}
