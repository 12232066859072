import { AttachmentRounded, ModeCommentRounded, VisibilityRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { EntityPeriod, StaticDrawer } from '../..';
import { useAuth, useInternationalization } from '../../../hooks';
import { GeneralConditionEntity, TranslatedFileEntity, VendorGeneralConditionEntity } from '../../../models';
import { GeneralConditionType, Styles } from '../../../types';
import { formatLongDate, formatShortDate } from '../../../utils/helper';
import { TranslatedAttachments } from '../../Form';
import { Table, TableColumn } from '../../Table';
import { TableProps } from '../../Table/Table';

const style: Styles = {
  attachmentButton: {
    py: '0 !important',
  },
};

interface VendorGeneralConditionsTableProps<T extends VendorGeneralConditionEntity> extends TableProps<T> {
  downloadFile: (entity: GeneralConditionEntity, file: TranslatedFileEntity) => Promise<void>;
}

export const VendorGeneralConditionsTable = <T extends VendorGeneralConditionEntity>({
  downloadFile,
  ...tableProps
}: VendorGeneralConditionsTableProps<T>) => {
  const { isInternalUser } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const [selectedEntity, setSelectedEntity] = useState<GeneralConditionEntity | null>(null);

  const renderPeriod = (entity: VendorGeneralConditionEntity) => (
    <EntityPeriod period={entity.period} translationContext="female" />
  );

  const renderType = (entity: VendorGeneralConditionEntity) => t(`vendor:generalCondition.type.${entity.type}`);

  const renderSummary = (entity: VendorGeneralConditionEntity) => (
    <Stack alignItems="flex-start">
      <Typography variant="body1">
        {entity.type === GeneralConditionType.Freight
          ? [
              getTranslation(entity, 'freightPolicy'),
              getTranslation(entity, 'pickupAllowance')
                ? `${t('vendor:generalCondition.form.pickupAllowance')}: ${getTranslation(entity, 'pickupAllowance')}`
                : null,
              getTranslation(entity, 'prepaidAmount')
                ? `${t('vendor:generalCondition.form.prepaidAmount')}: ${getTranslation(entity, 'prepaidAmount')}`
                : null,
              getTranslation(entity, 'fobPoint')
                ? `${t('vendor:generalCondition.form.fobPoint')}: ${getTranslation(entity, 'fobPoint')}`
                : null,
            ]
              .filter((x) => x)
              .join(', ')
          : getTranslation(entity, 'summary')}
      </Typography>
      {entity.files?.length > 0 && (
        <Button
          startIcon={<AttachmentRounded />}
          size="small"
          variant="text"
          sx={style.attachmentButton}
          onClick={() => setSelectedEntity(entity)}
        >
          {t('vendor:generalCondition.attachmentsWithNumber', { count: entity.files?.length ?? 0 })}
        </Button>
      )}
    </Stack>
  );

  const renderTargetedMembership = (entity: VendorGeneralConditionEntity) =>
    [
      entity.targetedMembership?.regions?.map((r) => getTranslation(r, 'name')).join(', '),
      isInternalUser && entity.targetedMembership?.membershipTypes?.length
        ? `(${entity.targetedMembership?.membershipTypes.map((r) => getTranslation(r, 'name')).join(', ')})`
        : null,
      isInternalUser && (entity.targetedMembership?.members?.length || entity.targetedMembership?.memberGroups?.length)
        ? '*'
        : null,
    ]
      .filter((x) => x)
      .join(' ');

  const renderLastModified = (entity: VendorGeneralConditionEntity) =>
    t('common:entity.modifiedDateShort', {
      date: formatShortDate(entity.modifiedDate),
      name: entity.modifiedBy,
    });

  const renderNotes = (entity: VendorGeneralConditionEntity) => {
    let notes = '';
    if (isInternalUser && entity.notes) {
      notes = `${t('vendor:buyOpportunities.form.notes')}: ${entity.notes}\n`;
    }
    if (entity.vendorNotes) {
      notes += `${
        isInternalUser ? t('vendor:buyOpportunities.form.vendorNotes') : t('vendor:buyOpportunities.form.comments')
      }: ${entity.vendorNotes}\n`;
    }
    if (getTranslation(entity, 'memberNotes')) {
      notes += `${t('vendor:buyOpportunities.form.memberNotes')}: ${getTranslation(entity, 'memberNotes')}`;
    }
    return notes;
  };

  return (
    <>
      <Table {...tableProps}>
        <TableColumn type="custom" width="10%" id="period" sortable render={renderPeriod} />
        <TableColumn type="custom" width="10%" id="type" sortable render={renderType} />
        <TableColumn type="custom" width="40%" id="summary" sortable render={renderSummary} />
        <TableColumn
          type="custom"
          id="effectiveDate"
          width="10%"
          sortable
          render={(entity: VendorGeneralConditionEntity) => formatLongDate(entity.effectiveDate)}
        />
        <TableColumn
          type="custom"
          id="expiryDate"
          width="10%"
          sortable
          render={(entity: VendorGeneralConditionEntity) => formatLongDate(entity.expiryDate)}
        />
        <TableColumn type="custom" width="10%" id="targetedMembership" sortable render={renderTargetedMembership} />
        <TableColumn type="custom" width="10%" id="modifiedDate" sortable render={renderLastModified} />
        {isInternalUser && (
          <TableColumn type="icon" id="isVisibleToMember" sortable align="center" iconComponent={VisibilityRounded} />
        )}
        <TableColumn
          type="icon"
          tooltip
          id="notes"
          align="center"
          render={renderNotes}
          iconComponent={ModeCommentRounded}
        />
      </Table>
      <StaticDrawer
        title={t('vendor:generalCondition.attachments')}
        open={!!selectedEntity}
        footer={
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button variant="contained" size="small" onClick={() => setSelectedEntity(null)}>
              {t('common:close')}
            </Button>
          </Box>
        }
        onClose={() => setSelectedEntity(null)}
      >
        <TranslatedAttachments
          files={selectedEntity?.files ?? []}
          label={''}
          onFileClick={(file) => selectedEntity && downloadFile(selectedEntity, file)}
          readOnly
        />
      </StaticDrawer>
    </>
  );
};
