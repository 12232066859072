import axios from 'axios';
import { VendorTierGrowthEntity } from '../../models';
import { PaginationEntity, SearchVendorTiersGrowthsFilter } from '../../types';

export const growth = {
  getAllGrowth: async (
    vendorId: number,
    filter: SearchVendorTiersGrowthsFilter,
  ): Promise<PaginationEntity<VendorTierGrowthEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/growths/search`, filter);
    return { ...data, data: data.data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v)) };
  },
  getAllRebateCategoryGrowth: async (vendorId: number, rebateCategoryId: number): Promise<VendorTierGrowthEntity[]> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/growths`);
    return data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v));
  },
  addRebateCategoryGrowth: async (
    vendorId: number,
    rebateCategoryId: number,
    growthId: number,
  ): Promise<VendorTierGrowthEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/growths/${growthId}`);
    return new VendorTierGrowthEntity(data);
  },
  removeRebateCategoryGrowth: async (
    vendorId: number,
    rebateCategoryId: number,
    growthId: number,
  ): Promise<VendorTierGrowthEntity> => {
    const { data } = await axios.delete(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/growths/${growthId}`,
    );
    return new VendorTierGrowthEntity(data);
  },
  updateGrowth: async (vendorId: number, entity: VendorTierGrowthEntity): Promise<VendorTierGrowthEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/growths/${entity.id}`, entity);
    return new VendorTierGrowthEntity(data);
  },
  createGrowth: async (vendorId: number, entity: VendorTierGrowthEntity): Promise<VendorTierGrowthEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/growths`, entity);
    return new VendorTierGrowthEntity(data);
  },
  archiveGrowth: async (vendorId: number, ids: number[], isArchived: boolean): Promise<VendorTierGrowthEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/growths/archive`, { isArchived, ids });
    return data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v));
  },
  getAllRebateCategoryProgramGrowth: async (
    vendorId: number,
    rebateCategoryId: number,
    rebateProgramId: number,
  ): Promise<VendorTierGrowthEntity[]> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/${rebateProgramId}/growths`,
    );
    return data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v));
  },
  getAllRebateProgramGrowth: async (vendorId: number, rebateProgramId: number): Promise<VendorTierGrowthEntity[]> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebateprograms/${rebateProgramId}/growths`);
    return data.map((v: VendorTierGrowthEntity) => new VendorTierGrowthEntity(v));
  },
};
