import axios, { AxiosRequestHeaders, AxiosRequestTransformer } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import format from 'date-fns/format';
import { env } from '../env';
import { routes } from '../routes';
import { getLocalMemberId, getLocalUser, removeLocalUser, setLocalUser } from '../services/auth.service';
import { auth } from './auth';
import { categorization } from './categorization';
import { cci } from './cci';
import { cms } from './cms';
import { contact } from './contact';
import { logging } from './logging';
import { member } from './member';
import { memberGroup } from './memberGroup';
import { notification } from './notification';
import { profile } from './profile';
import { role } from './role';
import { roleGroup } from './roleGroup';
import { setting } from './setting';
import { user } from './user';
import { vendor } from './vendor';
import { forestProduct } from './forestProduct';
import { search } from './search';
import { report } from './report';

// Headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.baseURL = env.REACT_APP_API_URL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dateTransformer = (data: any, headers: AxiosRequestHeaders): any => {
  if (headers['Content-Type'] !== 'multipart/form-data') {
    if (data instanceof Date) {
      // Format date (without time zone) in manner that can be parsed by .NET Core API
      return format(new Date(data), "yyyy-MM-dd'T'HH:mm:ss");
    }
    if (Array.isArray(data)) {
      return data.map((val) => dateTransformer(val, headers));
    }
    if (typeof data === 'object' && data !== null) {
      return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val, headers)]));
    }
  }
  return data;
};

axios.defaults.transformRequest = [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])];

createAuthRefreshInterceptor(axios, (failedRequest) => {
  const currentUser = getLocalUser();
  if (!currentUser) {
    removeLocalUser();
    window.location.href = routes.Login.path;
    return Promise.reject();
  }
  return auth
    .postWithRefreshToken(currentUser.profile.username, currentUser.refreshToken)
    .then((user) => {
      failedRequest.response.config.headers['Authorization'] = `Bearer ${user.accessToken}`;
      setLocalUser(user);
      return Promise.resolve();
    })
    .catch(() => {
      removeLocalUser();
      window.location.href = routes.Login.path;
    });
});

axios.interceptors.request.use((request) => {
  const currentUser = getLocalUser();
  const headers = request.headers;
  if (headers) {
    if (currentUser) {
      headers['Authorization'] = `Bearer ${currentUser.accessToken}`;
    }
    headers['X-MemberId'] = getLocalMemberId();
  }
  return request;
});

export {
  auth,
  categorization,
  cci,
  cms,
  contact,
  logging,
  member,
  memberGroup,
  notification,
  profile,
  role,
  roleGroup,
  setting,
  user,
  vendor,
  forestProduct,
  search,
  report,
};
