import { useTranslation } from 'react-i18next';
import { MAX_FILE_SIZE_MB } from '../../../constants';
import { useEntityFormContext } from '../../../hooks';
import { FileEntity } from '../../../models';
import { BuyOpportunityEntity } from '../../../models/Vendor/BuyOpportunityEntity';
import { ControlledAttachments } from '../../Form';

interface VendorBuyOpportunityDocumentFormProps {
  downloadFile?: (file: FileEntity) => void;
}

export const VendorBuyOpportunityDocumentForm = ({ downloadFile }: VendorBuyOpportunityDocumentFormProps) => {
  const { t } = useTranslation();
  const { control } = useEntityFormContext<BuyOpportunityEntity>();

  return (
    <ControlledAttachments
      label={t('vendor:buyOpportunities.documentForm.files')}
      name="files"
      control={control}
      maxCount={BuyOpportunityEntity.MAX_FILES_COUNT}
      maxSizeMB={MAX_FILE_SIZE_MB}
      onFileClick={downloadFile}
      hideLabel
    />
  );
};
