import { AddRounded, ModeCommentRounded } from '@mui/icons-material';
import { Button, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { VendorRebateCategoryProgramForm, VendorRebateCategoryRebateSummary } from '.';
import { EditDrawer, EntityPeriod, LimitCommaValues } from '../..';
import { categorization as categorizationApi, vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { VendorRebateCategoryProgramContext } from '../../../contexts';
import { VendorRebateCategoryDetailContext } from '../../../contexts/Vendor/VendorRebateCategoryDetailContext';
import { useApi, useGlobalEdit, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryProgramEntity } from '../../../models/Vendor/VendorRebateCategoryProgramEntity';
import { routes } from '../../../routes';
import { VendorRebateCategoryProgramSchema } from '../../../schemas';
import { palette } from '../../../styles/palette';
import { FilterPeriod, PermissionKey, Styles, VendorRebateCategoryProgramFilter } from '../../../types';
import { formatLongDate } from '../../../utils/helper';
import { CardList, CardListElement, CardListRow } from '../../Card';
import { Container, FilterContainer } from '../../Container';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { PeriodFilterSelect } from '../../Filter/PeriodFilterSelect';
import { ArchiveMenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { VendorRebateCategoryProgramStatusDisplay } from './VendorRebateCategoryProgramStatusDisplay';

const style: Styles = {
  container: {
    py: 4,
  },
  header: {
    pb: 3,
  },
  cardTitle: {
    borderBottom: 0,
    marginBottom: 0,
    paddingBottom: 0,
  },
  dataRow: {
    justifyContent: 'left',
  },
};

const defaultAdvancedSearchFilter = {
  period: FilterPeriod.All,
  isArchived: false,
};

export const VendorRebateCategoryPrograms = () => {
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { vendorRebateCategoryId, vendorId, vendor, vendorRebateCategory } = useContext(
    VendorRebateCategoryDetailContext,
  );
  const [filter, setFilter] = useState<VendorRebateCategoryProgramFilter>({
    ...defaultAdvancedSearchFilter,
    ...DEFAULT_PAGINATION_FILTER,
  });
  const {
    data: programs,
    isLoading,
    refresh,
  } = useApi(vendorApi.getAllRebateCategoryPrograms, null, vendorId, vendorRebateCategoryId, filter);
  const { data: regions } = useApi(categorizationApi.getAll, null, 'region');
  const { call: create } = useApi(vendorApi.createRebateCategoryProgram, { successKey: 'common:success.save' });
  const vendorRebateCategoryProgram = useMemo(() => {
    const program = new VendorRebateCategoryProgramEntity();
    if (vendorRebateCategory) {
      program.vendorDesignationIds = vendorRebateCategory.vendorDesignationIds;
      program.effectiveDate = vendorRebateCategory.effectiveDate;
      program.expiryDate = vendorRebateCategory.expiryDate;
      program.memberNotes_En = vendorRebateCategory.memberNotes_En;
      program.memberNotes_Fr = vendorRebateCategory.memberNotes_Fr;
    }
    return program;
  }, [vendorRebateCategory]);
  const archiveApi = useApi(vendorApi.archiveRebateCategoryProgram, { successKey: 'common:success.action' });

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const saveDrawer = async (entity: VendorRebateCategoryProgramEntity) => {
    const savedTierOrGrowth = await create(vendorId, vendorRebateCategoryId, entity);
    return savedTierOrGrowth;
  };

  const cancelDrawer = () => {
    setIsDrawerOpen(false);
  };

  const confirmDrawer = () => {
    refresh();
    setIsDrawerOpen(false);
  };

  const onArchiveConfirm = async (isArchived: boolean, entity: VendorRebateCategoryProgramEntity) => {
    await archiveApi.call(vendorId, vendorRebateCategoryId, [entity.id], isArchived);
    refresh();
  };

  const renderTiersActionsMenuItems = (entity: VendorRebateCategoryProgramEntity, onClick: () => void) => [
    <MenuItem
      id="vendor:rebateCategory.programs.view"
      key="view"
      onClick={() => {
        navigate(
          generatePath(routes.Admin.Vendor.RebateCategoryProgram.path, {
            vendorId,
            vendorRebateCategoryId,
            rebateProgramId: entity.id,
          }),
        );
      }}
    >
      {t('common:view')}
    </MenuItem>,
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={getTranslation(entity, 'name')}
      onClick={onClick}
      onArchiveConfirm={onArchiveConfirm}
      permissions={{ keys: PermissionKey.VendorEditRebates }}
      actionSuffix={t('vendor:rebateCategory.programs.actions.suffix')}
    />,
  ];

  const renderRegions = (item: VendorRebateCategoryProgramEntity) => (
    <LimitCommaValues
      value={
        regions?.values.length
          ? regions.values
              .filter((region) => item.targetedMembership?.regionIds?.includes(region.id))
              .map((region) => getTranslation(region, 'abbreviation'))
              .join(', ')
          : ''
      }
      maxLength={50}
    />
  );
  const renderDesignations = (item: VendorRebateCategoryProgramEntity) => (
    <LimitCommaValues
      value={item.vendorDesignations.map((designation) => getTranslation(designation, 'name')).join(', ')}
      maxLength={50}
    />
  );

  const renderFilterPeriod = (item: VendorRebateCategoryProgramEntity) => (
    <EntityPeriod period={item.period} isArchived={item.isArchived} translationContext="female" />
  );

  const renderAdvancedSearch = () => (
    <Stack spacing={1}>
      <FilterContainer onClear={onClear}>
        <PeriodFilterSelect filter={filter} setFilter={setFilter} />
      </FilterContainer>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />
    </Stack>
  );

  return (
    <VendorRebateCategoryProgramContext.Provider value={{ vendorId, vendorRebateCategory }}>
      <Container isHighlighted sx={style.container}>
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={style.header}>
            <Typography variant="h2">{t('vendor:rebateCategory.programs.title')}</Typography>
            <HasPermissions keys={PermissionKey.VendorEditRebates}>
              <Button
                variant="contained"
                startIcon={<AddRounded />}
                onClick={() => setIsDrawerOpen(true)}
                disabled={
                  !vendor ||
                  vendor.isArchived ||
                  !vendorRebateCategory ||
                  vendorRebateCategory.isArchived ||
                  globalEditing
                }
              >
                {t('vendor:rebateCategory.programs.actions.add')}
              </Button>
            </HasPermissions>
          </Stack>
          {renderAdvancedSearch()}
          <CardList
            data={programs ?? []}
            translationNamespace="vendor"
            hideResultCount
            isLoading={isLoading}
            actionMenuItems={!globalEditing ? renderTiersActionsMenuItems : undefined}
            href={(item) =>
              generatePath(routes.Admin.Vendor.RebateCategoryProgram.path, {
                vendorId,
                vendorRebateCategoryId,
                rebateProgramId: item.id,
              })
            }
          >
            <CardListRow sx={style.cardTitle}>
              <CardListElement type="title" id="name" translated hideLabel />
              <CardListElement
                type="custom"
                id="status"
                hideLabel
                render={(item: VendorRebateCategoryProgramEntity) => (
                  <VendorRebateCategoryProgramStatusDisplay status={item.status} />
                )}
              />
              <CardListElement
                type="custom"
                render={(item: VendorRebateCategoryProgramEntity) => renderFilterPeriod(item)}
                id="period"
                hideLabel
              />
              <CardListElement
                type="custom"
                render={(item: VendorRebateCategoryProgramEntity) =>
                  item.notes && (
                    <Tooltip title={item.notes} placement="top">
                      <ModeCommentRounded htmlColor={palette.primary.deep} />
                    </Tooltip>
                  )
                }
                rawRender
                id="notes"
                decorator
                hideLabel
              />
            </CardListRow>
            <CardListRow justifyContent="start">
              <CardListElement
                type="custom"
                render={(item: VendorRebateCategoryProgramEntity) => <Typography>{item.number}</Typography>}
                id="number"
                hideLabel
              />
              <CardListElement
                type="custom"
                render={(item: VendorRebateCategoryProgramEntity) => (
                  <Typography fontWeight={600}>{renderDesignations(item)}</Typography>
                )}
                id="vendorDesignations.name"
                hideLabel
              />
              <CardListElement
                type="custom"
                render={(item: VendorRebateCategoryProgramEntity) => (
                  <Typography variant="body1">{renderRegions(item)}</Typography>
                )}
                id="regionsIds"
                hideLabel
              />
              <CardListElement
                type="custom"
                render={(item: VendorRebateCategoryProgramEntity) => (
                  <Typography variant="body1">{`${formatLongDate(item.effectiveDate)} - ${formatLongDate(
                    item.expiryDate,
                  )}`}</Typography>
                )}
                id="effectiveDate"
                hideLabel
              />
            </CardListRow>
            <CardListRow>
              <CardListElement
                type="custom"
                render={(item: VendorRebateCategoryProgramEntity) => (
                  <>
                    <Typography fontWeight={600}>{t('vendor:rebateCategory.programs.payableRebates')}</Typography>
                    <VendorRebateCategoryRebateSummary summary={item.payableRebatesSummary} />
                  </>
                )}
                id="payableRebatesSummary"
                hideLabel
              />
            </CardListRow>
          </CardList>
        </>
        <EditDrawer
          title={
            <Stack direction="row" spacing={1} alignItems="center">
              <AddRounded />
              <Typography variant="drawerTitle">{t('vendor:rebateCategory.programs.actions.add')}</Typography>
            </Stack>
          }
          open={isDrawerOpen}
          entity={vendorRebateCategoryProgram}
          schema={VendorRebateCategoryProgramSchema()}
          redirectLabel={t('vendor:rebateCategory.programs.actions.saveOpen')}
          onSave={saveDrawer}
          onConfirm={confirmDrawer}
          onCancel={cancelDrawer}
          redirectPath={(entity) =>
            generatePath(routes.Admin.Vendor.RebateCategoryProgram.path, {
              vendorId,
              vendorRebateCategoryId,
              rebateProgramId: entity.id,
            })
          }
        >
          <VendorRebateCategoryProgramForm />
        </EditDrawer>
      </Container>
    </VendorRebateCategoryProgramContext.Provider>
  );
};
