import { BlockRounded, CheckRounded, EditRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../styles/palette';
import { Styles, VendorBuyingAgreementStatus } from '../../../types';

const style: Styles = {
  approved: {
    color: palette.success.main,
  },
  other: {
    color: palette.grey[500],
  },
};

interface VendorBuyingAgreementStatusDisplayProps {
  status: VendorBuyingAgreementStatus;
}

export const VendorBuyingAgreementStatusDisplay = ({ status }: VendorBuyingAgreementStatusDisplayProps) => {
  const { t } = useTranslation();

  const renderIcon = () => {
    switch (status) {
      case VendorBuyingAgreementStatus.InProgressTBM:
      case VendorBuyingAgreementStatus.InProgressVendor:
        return <EditRounded htmlColor={palette.grey[500]} />;
      case VendorBuyingAgreementStatus.Approved:
        return <CheckRounded htmlColor={palette.success.main} />;
      case VendorBuyingAgreementStatus.Rejected:
        return <BlockRounded htmlColor={palette.grey[500]} />;
      default:
        return null;
    }
  };

  return (
    <Typography variant="status" sx={status === VendorBuyingAgreementStatus.Approved ? style.approved : style.other}>
      {t(`vendor:buyingAgreements.status.${status}`)}
      {renderIcon()}
    </Typography>
  );
};
