import { SvgIconComponent } from '@mui/icons-material';
import { SvgIconProps, TableCellProps } from '@mui/material';
import { ReactElement } from 'react';
import { Styles } from '../../types';

interface PropertyTableColumnProps<T> {
  type: 'property';
  id: keyof T;
  maxLength?: number;
}

interface IconTableColumnProps<T> {
  type: 'icon';
  id: keyof T;
  tooltip?: boolean;
  iconProps?: ((item: T) => SvgIconProps) | SvgIconProps;
  iconComponent: ((value: T[keyof T]) => SvgIconComponent | null) | SvgIconComponent;
  render?: (item: T, index: number) => string;
}

interface ButtonTableColumnProps<T> {
  type: 'button';
  id: string;
  tooltip?: boolean;
  iconProps?: ((item: T) => SvgIconProps) | SvgIconProps;
  onClick: (item: T, index: number) => void;
  iconComponent: ((value: T[keyof T], index: number) => SvgIconComponent | null) | SvgIconComponent;
  render?: (item: T, index: number) => string | boolean;
}

interface CustomTableColumnProps<T> {
  type: 'custom';
  id: string;
  render: (item: T, index: number) => ReactElement | string | false;
}

export type TableColumnProps<T> = (
  | PropertyTableColumnProps<T>
  | CustomTableColumnProps<T>
  | IconTableColumnProps<T>
  | ButtonTableColumnProps<T>
) & {
  align?: TableCellProps['align'];
  width?: TableCellProps['width'];
  sortable?: boolean;
  required?: boolean;
  sx?: Styles[0];
};

// eslint-disable-next-line no-empty-pattern
export const TableColumn = <T,>({}: TableColumnProps<T>) => <></>;
