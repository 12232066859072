import { AddRounded, EditRounded, ModeCommentRounded } from '@mui/icons-material';
import { Button, Card, CardContent, MenuItem, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { VendorRebateCategoryRebateFactorSummary, VendorRebateCategoryRebateValueForm } from '.';
import { EditDrawer } from '../..';
import { DOLLAR_MAX_DECIMALS, PERCENT_MAX_DECIMALS } from '../../../constants';
import { VendorBuyingAgreementRebateCategoryDetailContext } from '../../../contexts/Vendor';
import { useAuth, useConfirmationModal, useGlobalEdit, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryRebateSummaryEntity, VendorRebateCategoryRebateValueEntity } from '../../../models';
import { VendorRebateCategoryRebateValueSchema } from '../../../schemas/Vendor';
import { palette } from '../../../styles/palette';
import { LanguageCode, RebateTypeAndUnitType, RebateValueType, Styles } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { Table, TableColumn } from '../../Table';

const style: Styles = {
  card: {
    my: 3,
    borderTop: 'solid 8px ' + palette.grey[300],
  },
  cardHeader: {
    borderBottom: 'solid 1px ' + palette.grey[300],
    pt: 1,
    pb: 2,
    mb: 2,
  },
  buttons: {
    mt: 3,
  },
};

interface VendorRebateCategoryRebateValueListProps<T> {
  entity: T;
  listProperty: keyof T;
  summaryProperty?: keyof T;
  onSave: (entity: T) => Promise<T | null>;
  onConfirm: () => void;
  title: string;
  borderColor: string;
  readOnly?: boolean;
  rebateValueType: RebateValueType;
}

export const VendorRebateCategoryRebateValueList = <T,>({
  entity,
  listProperty,
  onSave,
  onConfirm,
  title,
  borderColor,
  readOnly,
  rebateValueType,
  summaryProperty,
}: VendorRebateCategoryRebateValueListProps<T>) => {
  const { vendorBuyingAgreementId: hasVBAContext } = useContext(VendorBuyingAgreementRebateCategoryDetailContext);
  const { isInternalUser } = useAuth();
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { openModal } = useConfirmationModal();
  const { globalEditing } = useGlobalEdit();
  const [vendorRebateCategoryRebateValue, setVendorRebateCategoryRebateValue] = useState(
    new VendorRebateCategoryRebateValueEntity(),
  );
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const rebateValueList = entity[listProperty] as VendorRebateCategoryRebateValueEntity[];

  const updateOrAddRebateValue = async (rebateValue: VendorRebateCategoryRebateValueEntity) => {
    if (!rebateValue.id) {
      rebateValueList.push(rebateValue);
    } else {
      const index = rebateValueList.findIndex((r) => r.id === rebateValue.id);
      rebateValueList[index] = rebateValue;
    }
    await onSave(entity);
    return rebateValue;
  };

  const deleteRebateValue = async (rebateValue: VendorRebateCategoryRebateValueEntity) => {
    const index = rebateValueList.findIndex((r) => r.id === rebateValue.id);
    rebateValueList.splice(index, 1);
    await onSave(entity);
    onConfirm();
    return entity;
  };

  const addRebateValue = () => {
    setVendorRebateCategoryRebateValue(new VendorRebateCategoryRebateValueEntity());
    setDrawerIsOpen(true);
  };

  const renderActionsMenuItems = (rebateValue: VendorRebateCategoryRebateValueEntity, onClick: () => void) => [
    <MenuItem
      id="edit"
      key="edit"
      onClick={() => {
        setVendorRebateCategoryRebateValue(rebateValue);
        setDrawerIsOpen(true);
        onClick();
      }}
    >
      {t('common:edit')}
    </MenuItem>,
    <MenuItem
      id="delete"
      key="delete"
      onClick={() => {
        openModal({
          confirmText: t('common:action.confirmButton', { action: t('common:remove') }),
          title: t('common:action.confirmTitle', {
            name: t('vendor:rebateCategory.rebates.actions.suffix'),
            action: t('common:remove'),
            count: 1,
          }),
          description: (
            <Trans
              i18nKey="common:action.confirmDescription"
              values={{
                count: 1,
                name: `${renderValue(rebateValue)} ${
                  rebateValue.receivableFrequency ? getTranslation(rebateValue.receivableFrequency, 'value') : ''
                }`,
              }}
            />
          ),
          onConfirm: () => deleteRebateValue(rebateValue),
        });
        onClick();
      }}
    >
      {t('common:remove')}
    </MenuItem>,
  ];

  const drawerConfirm = () => {
    setDrawerIsOpen(false);
    onConfirm();
  };

  const drawerCancel = () => {
    setDrawerIsOpen(false);
  };

  const renderValue = (entity: VendorRebateCategoryRebateValueEntity) => {
    if (!entity.isRebateNote && entity.value !== null) {
      const formatter = numberFormatter(
        currentLanguage,
        entity.unit !== RebateTypeAndUnitType.Custom
          ? t(`common:rebateTypeAndUnitType.${entity.unit}`)
          : ` ${getTranslation(entity, 'customUnit') ?? entity.customUnit}`,
        entity.unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
        entity.unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
      );
      return formatter(entity.value).formatted.join('');
    }
    return getTranslation(entity, 'rebateNote') || entity.rebateNote || '';
  };

  const renderNotes = (entity: VendorRebateCategoryRebateValueEntity) => {
    let notes = '';
    if (isInternalUser && entity.notes) {
      notes = `${t('vendor:rebateCategory.rebates.form.notes')}: ${entity.notes}\n`;
    }
    if (getTranslation(entity, 'memberNotes')) {
      notes += `${t('vendor:rebateCategory.rebates.form.memberNotes')}: ${getTranslation(entity, 'memberNotes')}`;
    }
    return notes;
  };

  return (
    <>
      <Card sx={{ ...style.card, borderColor }}>
        <CardContent>
          <Stack sx={style.cardHeader} spacing={3} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h3">{title}</Typography>
          </Stack>
          <Table
            data={rebateValueList}
            translationNamespace="vendor:rebateCategory.rebates"
            actionMenuItems={!readOnly && !globalEditing ? renderActionsMenuItems : undefined}
            hideTotal
            rowHeight="small"
          >
            <TableColumn type="custom" id="value" render={renderValue} />
            {rebateValueType !== RebateValueType.FOID && (
              <TableColumn
                type="custom"
                id={
                  hasVBAContext
                    ? 'paymentTerm'
                    : rebateValueType == RebateValueType.Payable
                    ? 'payableFrequency'
                    : 'receivableFrequency'
                }
                render={(entity: VendorRebateCategoryRebateValueEntity) =>
                  entity.receivableFrequency ? getTranslation(entity.receivableFrequency, 'value') ?? '' : ''
                }
              />
            )}
            <TableColumn
              type="custom"
              id="rebateType"
              render={(entity: VendorRebateCategoryRebateValueEntity) =>
                entity.rebateType ? getTranslation(entity.rebateType, 'abbreviation') ?? '' : ''
              }
            />
            {rebateValueType !== RebateValueType.Payable && (
              <TableColumn
                type="custom"
                id="paymentMethod"
                render={(entity: VendorRebateCategoryRebateValueEntity) =>
                  entity.paymentMethod ? getTranslation(entity.paymentMethod, 'value') ?? '' : ''
                }
              />
            )}
            {rebateValueType === RebateValueType.Receivable && <TableColumn type="property" id="vendorRebateCode" />}
            {rebateValueType === RebateValueType.Holdback && <TableColumn type="property" id="holdbackGL" />}
            <TableColumn
              type="icon"
              tooltip
              width="5%"
              id="notes"
              align="right"
              iconComponent={ModeCommentRounded}
              render={renderNotes}
            />
          </Table>
          {summaryProperty && (
            <VendorRebateCategoryRebateFactorSummary
              summary={entity[summaryProperty] as VendorRebateCategoryRebateSummaryEntity}
            />
          )}
          {!readOnly && (
            <Box sx={style.buttons}>
              <Button
                startIcon={<AddRounded />}
                onClick={addRebateValue}
                variant="contained"
                size="small"
                disabled={globalEditing}
              >
                {t('vendor:rebateCategory.rebates.actions.add')}
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
      <EditDrawer
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            {vendorRebateCategoryRebateValue.id ? <EditRounded /> : <AddRounded />}
            <Typography variant="drawerTitle">
              {t(
                vendorRebateCategoryRebateValue.id
                  ? 'vendor:rebateCategory.rebates.actions.edit'
                  : 'vendor:rebateCategory.rebates.actions.add',
              )}
            </Typography>
          </Stack>
        }
        open={drawerIsOpen}
        entity={vendorRebateCategoryRebateValue}
        schema={VendorRebateCategoryRebateValueSchema(rebateValueType)}
        redirectLabel={t('vendor:rebateCategory.rebates.actions.saveOpen')}
        onSave={updateOrAddRebateValue}
        onConfirm={drawerConfirm}
        onCancel={drawerCancel}
      >
        <VendorRebateCategoryRebateValueForm rebateValueType={rebateValueType} />
      </EditDrawer>
    </>
  );
};
