import axios from 'axios';
import { VendorGeneralConditionEntity } from '../../models';
import { TranslatedFileEntity } from '../../models/TranslatedFileEntity';
import { PaginationEntity, UpdateGeneralConditionFileLanguageRequest, VendorGeneralConditionFilter } from '../../types';

export const generalCondition = {
  getAllGeneralConditions: async (
    vendorId: number,
    generalConditionFilter: VendorGeneralConditionFilter,
  ): Promise<PaginationEntity<VendorGeneralConditionEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/generalConditions/search`, generalConditionFilter);
    return { ...data, data: data.data.map((v: VendorGeneralConditionEntity) => new VendorGeneralConditionEntity(v)) };
  },
  createGeneralCondition: async (
    vendorId: number,
    entity: VendorGeneralConditionEntity,
  ): Promise<VendorGeneralConditionEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/generalConditions`, entity);
    return new VendorGeneralConditionEntity(data);
  },
  updateGeneralCondition: async (
    vendorId: number,
    entity: VendorGeneralConditionEntity,
  ): Promise<VendorGeneralConditionEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/generalConditions/${entity.id}`, entity);
    return new VendorGeneralConditionEntity(data);
  },
  archiveGeneralCondition: async (
    vendorId: number,
    ids: number[],
    isArchived: boolean,
  ): Promise<VendorGeneralConditionEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/generalConditions/archive`, { isArchived, ids });
    return data.map((v: VendorGeneralConditionEntity) => new VendorGeneralConditionEntity(v));
  },
  uploadGeneralConditionFiles: async (
    vendorId: number,
    generalConditionId: number,
    files: TranslatedFileEntity[],
  ): Promise<VendorGeneralConditionEntity> => {
    const formData = VendorGeneralConditionEntity.toFormData(files, 'files');
    const { data } = await axios.post(
      `api/vendor/${vendorId}/generalConditions/${generalConditionId}/files`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    return new VendorGeneralConditionEntity(data);
  },
  updateGeneralConditionFilesLanguage: async (
    vendorId: number,
    generalConditionId: number,
    files: UpdateGeneralConditionFileLanguageRequest[],
  ): Promise<VendorGeneralConditionEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/generalConditions/${generalConditionId}/files/language`,
      files,
    );
    return new VendorGeneralConditionEntity(data);
  },
  deleteGeneralConditionFiles: async (
    vendorId: number,
    generalConditionId: number,
    ids: number[],
  ): Promise<VendorGeneralConditionEntity> => {
    const { data } = await axios.delete(
      `api/vendor/${vendorId}/generalConditions/${generalConditionId}/files?${ids
        .map((id) => `fileIds=${id}`)
        .join('&')}`,
    );
    return new VendorGeneralConditionEntity(data);
  },
  getGeneralConditionFile: async (vendorId: number, generalConditionId: number, fileId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/generalConditions/${generalConditionId}/files/${fileId}`, {
      responseType: 'blob',
    });
    return data;
  },
};
