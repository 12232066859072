import { useApi, useEntityForm, useInternationalization } from '../../hooks';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import {
  ContentBlockType,
  UpdateContentFileLanguageRequest,
  EntityFormSchema,
  LanguageCode,
  Styles,
  TranslationLanguage,
} from '../../types';
import { ContentEntity } from '../../models';
import { Add, Attachment, EditRounded, Image, TextFieldsOutlined, Videocam } from '@mui/icons-material';
import { useFieldArray } from 'react-hook-form';
import { ContentBlockEntity } from '../../models/Cms/ContentBlockEntity';
import { CmsContentBlockEdit } from './CmsContentBlockEdit';
import { useSnackbar } from 'notistack';
import { TranslatedAttachments } from '../Form/Base/Attachments/TranslatedAttachments';
import { useState } from 'react';
import { MAX_FILE_SIZE_MB } from '../../constants';
import { StaticDrawer } from '..';
import { TranslatedFileEntity } from '../../models/TranslatedFileEntity';
import { downloadBlob } from '../../utils/helper';
import { palette } from '../../styles/palette';

const style: Styles = {
  loading: {
    mx: 2,
  },
  contentButton: {
    minWidth: '90%',
    justifyContent: 'space-between',
    '&>div>svg': {
      mr: 1,
    },
  },
  contentButtonLayout: {
    pb: { xs: 1, md: undefined },
  },
  saveButton: {
    ml: 2,
  },
  editHeader: {
    mt: -2,
    px: 2,
    py: 1,
    color: palette.white,
    backgroundColor: palette.primary.dark,
    borderTopLeftRadius: '12px',
  },
};

export interface CmsContentEditProps<T extends ContentEntity> {
  contentEntity: T;
  updateApi: (entity: T) => Promise<T>;
  uploadFilesApi?: (contentEntityId: number, files: TranslatedFileEntity[]) => Promise<T>;
  updateFilesLanguageApi?: (contentEntityId: number, files: UpdateContentFileLanguageRequest[]) => Promise<T>;
  uploadBlockFileApi: (
    helpTrainingId: number,
    blockId: number,
    language: TranslationLanguage,
    file: File,
  ) => Promise<T>;
  downloadApi?: (contentEntityId: number, fileId: number) => Promise<Blob>;
  deleteFilesApi: (helpTrainingId: number, ids: number[]) => Promise<T>;
  deleteContentBlockFileApi: (helpTrainingId: number, blockId: number, language: LanguageCode) => Promise<T>;
  downloadBlockFileApi: (contentEntityId: number, blockId: number, language: LanguageCode) => Promise<Blob>;
  schema: EntityFormSchema<T>;
  onConfirm: () => Promise<void>;
  onCancel: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const defaultUploadFilesApi = (helpTrainingId: number, files: TranslatedFileEntity[]) => undefined as any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const defaultDownloadApi = (contentEntityId: number, fileId: number) => undefined as any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const defaultUpdateFilesLanguage = (helpTrainingId: number, files: UpdateContentFileLanguageRequest[]) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undefined as any;

export const CmsContentEdit = <T extends ContentEntity>({
  contentEntity,
  updateApi,
  uploadFilesApi,
  updateFilesLanguageApi,
  uploadBlockFileApi,
  downloadApi,
  deleteFilesApi,
  deleteContentBlockFileApi,
  downloadBlockFileApi,
  schema,
  onConfirm,
  onCancel,
}: CmsContentEditProps<T>) => {
  const { t, getTranslation } = useInternationalization();
  const [attachmentsOpened, setAttachmentsOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { call: update } = useApi(updateApi, null);
  const { call: uploadFiles } = useApi(uploadFilesApi ?? defaultUploadFilesApi, null);
  const { call: uploadBlockFile } = useApi(uploadBlockFileApi, null);
  const { call: download } = useApi(downloadApi ?? defaultDownloadApi, null);
  const { call: deleteFiles } = useApi(deleteFilesApi, null);
  const { call: deleteContentBlockFile } = useApi(deleteContentBlockFileApi, null);
  const { call: updateFilesLanguage } = useApi(updateFilesLanguageApi ?? defaultUpdateFilesLanguage, null);

  const form = useEntityForm<ContentEntity>(contentEntity, schema as unknown as EntityFormSchema<ContentEntity>);

  const fieldArray = useFieldArray({
    name: `pages[${0}].blocks` as 'pages.0.blocks',
    control: form.control,
    keyName: 'fieldArrayId',
  });

  const filesWatch = form.watch('files');

  const save = async (entityToSave: T) => {
    let updatedEntity = await update({
      ...entityToSave,
      pages: entityToSave.pages.map((page) => ({
        ...page,
        blocks: page.blocks.map((block, index) => ({
          ...block,
          order: index,
        })),
      })),
    } as T);

    const filesDiff = contentEntity?.getFilesDiff(entityToSave);
    const hasAddedFiles = filesDiff?.added.length;
    const hasAddedBlockImages = entityToSave.pages?.some((p) =>
      p.blocks.some(
        (b) => b.type === ContentBlockType.Image && ((b.file_En && !b.file_En?.id) || (b.file_Fr && !b.file_Fr?.id)),
      ),
    );

    if ((hasAddedBlockImages || hasAddedFiles) && updatedEntity) {
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
    }

    if (updatedEntity) {
      if (filesDiff?.removed.length) {
        updatedEntity = await deleteFiles(updatedEntity.id, filesDiff.removed);
      }
      if (filesDiff?.languageModified.length && updatedEntity) {
        await updateFilesLanguage(updatedEntity.id, filesDiff.languageModified);
      }
      if (hasAddedFiles && updatedEntity && uploadFiles) {
        updatedEntity.files = entityToSave.files.filter((f) => !f.file?.id).map((f) => ContentEntity.createFromFile(f));
        await uploadFiles(updatedEntity.id, filesDiff.added);
      }
    }

    for await (const [pageIndex, page] of entityToSave.pages.entries()) {
      for await (const [blockIndex, block] of page.blocks.entries()) {
        if (block.type === ContentBlockType.Image && updatedEntity) {
          const blockId = updatedEntity.pages[pageIndex].blocks[blockIndex].id;
          const blockBeforeChanges = contentEntity?.pages[pageIndex]?.blocks.find((b) => b.id > 0 && b.id === block.id);

          if (blockBeforeChanges?.file_En?.id && !block.file_En?.id) {
            await deleteContentBlockFile(updatedEntity.id, block.id, LanguageCode.en);
          }
          if (blockBeforeChanges?.file_Fr?.id && !block.file_Fr?.id) {
            await deleteContentBlockFile(updatedEntity.id, block.id, LanguageCode.fr);
          }

          if (block.file_En && !block.file_En?.id) {
            await uploadBlockFile(updatedEntity.id, blockId, TranslationLanguage.En, block.file_En);
          }
          if (block.file_Fr && !block.file_Fr?.id) {
            await uploadBlockFile(updatedEntity.id, blockId, TranslationLanguage.Fr, block.file_Fr);
          }
        }
      }
    }

    enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    await onConfirm();
    return updatedEntity;
  };

  const cancel = () => {
    form.reset();
    onCancel();
  };

  const addContentBlock = (blockType: ContentBlockType) => () => {
    fieldArray.append({ ...new ContentBlockEntity(), type: blockType, order: fieldArray.fields?.length });
  };

  const downloadFile = async (file: TranslatedFileEntity) => {
    if (contentEntity && file.file?.id && downloadApi) {
      const fileData = await download(contentEntity.id, file.file.id);
      if (fileData) {
        downloadBlob(file.file.name, fileData as Blob);
      }
    }
  };

  return (
    <Stack spacing={3}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={style.editHeader}>
        <Stack spacing={1} direction="row" alignItems="center">
          <EditRounded />
          <Typography variant="drawerTitle">
            {t('common:editing', { name: getTranslation(contentEntity, 'name') })}
          </Typography>
        </Stack>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          {!!uploadFilesApi && (
            <Button
              startIcon={<Attachment />}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setAttachmentsOpened(true)}
            >
              {t('cms:content.attachmentsWithNumber', { count: filesWatch?.length ?? 0 })}
            </Button>
          )}
          <Button onClick={cancel} variant="outlined" color="secondary" size="small">
            {t('common:cancel')}
          </Button>
          <Button
            sx={style.saveButton}
            onClick={form.handleSubmit(() => save(form.getValues() as T))}
            variant="contained"
            size="small"
            disabled={form.isSubmitDisabled}
          >
            {t('common:save')}
          </Button>
        </Stack>
      </Box>
      <StaticDrawer
        title={t('cms:content.attachments')}
        open={attachmentsOpened}
        footer={
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button variant="contained" size="small" onClick={() => setAttachmentsOpened(false)}>
              {t('common:close')}
            </Button>
          </Box>
        }
        onClose={() => setAttachmentsOpened(false)}
      >
        <TranslatedAttachments
          onChange={(files) => form.setValue('files', files, { shouldDirty: true, shouldTouch: true })}
          files={filesWatch ?? []}
          maxSizeMB={MAX_FILE_SIZE_MB}
          label={''}
          onFileClick={downloadFile}
        />
      </StaticDrawer>
      {fieldArray.fields.map((block, index) => (
        <CmsContentBlockEdit
          key={index}
          contentEntity={contentEntity}
          contentBlock={block}
          fieldArray={fieldArray}
          index={index}
          downloadBlockFileApi={downloadBlockFileApi}
        />
      ))}
      {!fieldArray.fields.length && <Typography textAlign="center">{t('cms:content.addContentMessage')}</Typography>}
      <Grid container justifyContent="center" mt={3}>
        <Grid item xs={12} md={4} lg={2.5} sx={style.contentButtonLayout}>
          <Button
            sx={style.contentButton}
            variant="outlined"
            endIcon={<Add />}
            onClick={addContentBlock(ContentBlockType.HTML)}
          >
            <Box alignItems="center" display="flex">
              <TextFieldsOutlined />
              {t('cms:content.text')}
            </Box>
          </Button>
        </Grid>
        <Grid item xs={12} md={4} lg={2.5} sx={style.contentButtonLayout}>
          <Button
            sx={style.contentButton}
            variant="outlined"
            endIcon={<Add />}
            onClick={addContentBlock(ContentBlockType.Image)}
          >
            <Box alignItems="center" display="flex">
              <Image />
              {t('cms:content.image')}
            </Box>
          </Button>
        </Grid>
        <Grid item xs={12} md={4} lg={2.5} sx={style.contentButtonLayout}>
          <Button
            sx={style.contentButton}
            variant="outlined"
            endIcon={<Add />}
            onClick={addContentBlock(ContentBlockType.Video)}
          >
            <Box alignItems="center" display="flex">
              <Videocam />
              {t('cms:content.video')}
            </Box>
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};
