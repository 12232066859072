import { CmsHelpTrainingList } from '../../../../components/Cms';
import { HasPermissions } from '../../../../components/Permission/HasPermission';
import { PermissionKey } from '../../../../types';

export function CmsHelpTrainings() {
  return (
    <HasPermissions keys={PermissionKey.CmsHelpView}>
      <CmsHelpTrainingList isReadOnly={false} />
    </HasPermissions>
  );
}
