import { Chip, Stack, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { BuyGuideVendorDetailContext } from '../../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { useApi, useAuth, useEnumList, useInternationalization } from '../../../hooks';
import { BuyGuideChangeLogEntityType, BuyGuideChangeLogFilter, PermissionKey, Styles } from '../../../types';
import { ChangeLogs } from '../../ChangeLog';
import { BuyGuideVendorChangeLogItem } from './BuyGuideVendorChangeLogItem';

const style: Styles = {
  filterContainer: {
    px: 2.5,
    py: 0.5,
    overflowX: 'auto',
  },
  chipContainer: {
    '>div': {
      margin: `4px 5.6px 4px 0 !important`,
    },
    flexWrap: 'wrap',
  },
};

const defaultFilter: BuyGuideChangeLogFilter = {
  changeLogEntityTypes: [],
  ...MAX_PAGINATION_FILTER,
};

export const BuyGuideVendorChangeLogs = () => {
  const { t } = useInternationalization();
  const { hasPermissions } = useAuth();
  const { vendorId } = useContext(BuyGuideVendorDetailContext);
  const [filter, setFilter] = useState(defaultFilter);
  const changeLogEntityTypeOptions = useEnumList(
    BuyGuideChangeLogEntityType,
    'changeLog:type',
    false,
    undefined,
    false,
  );
  const { data, isLoading, refresh } = useApi(
    vendorApi.getAllChangeLogs,
    { callImmediately: false, skipFetch: true },
    vendorId,
    filter,
  );

  const filteredChangeLogEntityTypeOptions = useMemo(
    () =>
      changeLogEntityTypeOptions.filter((o) => {
        switch (o.value) {
          case BuyGuideChangeLogEntityType.Contact:
          case BuyGuideChangeLogEntityType.Location:
            return hasPermissions(PermissionKey.VendorViewContacts);
          case BuyGuideChangeLogEntityType.Document:
            return hasPermissions(PermissionKey.VendorViewFiles);
          case BuyGuideChangeLogEntityType.GeneralConditions:
            return hasPermissions(PermissionKey.VendorViewConditions);
          case BuyGuideChangeLogEntityType.RebateProgram:
            return hasPermissions(PermissionKey.VendorViewPrograms);
          case BuyGuideChangeLogEntityType.BuyOpportunity:
            return hasPermissions(PermissionKey.VendorViewBuys);

          default:
            return false;
        }
      }),
    [changeLogEntityTypeOptions, hasPermissions],
  );

  const onTypeSelect = (value: BuyGuideChangeLogEntityType) => () =>
    filter.changeLogEntityTypes.includes(value)
      ? setFilter((prev) => ({ ...prev, changeLogEntityTypes: prev.changeLogEntityTypes.filter((e) => e !== value) }))
      : setFilter((prev) => ({ ...prev, changeLogEntityTypes: [...prev.changeLogEntityTypes, value] }));

  const renderFilterSection = () => (
    <Stack sx={style.filterContainer} direction="row" spacing={2} alignItems="center">
      <Typography align="center" variant="label" color="grey.600">
        {t('common:show')}
      </Typography>
      <Stack spacing={0.7} direction="row" sx={style.chipContainer}>
        {[
          <Chip
            key="All"
            label={t('changeLog:type.All')}
            onClick={() => setFilter((prev) => ({ ...prev, changeLogEntityTypes: [] }))}
            color={!filter.changeLogEntityTypes.length ? 'primary' : 'secondary'}
          />,
          ...filteredChangeLogEntityTypeOptions.map((option) => (
            <Chip
              key={option.value}
              label={option.label}
              onClick={onTypeSelect(option.value)}
              color={filter.changeLogEntityTypes.includes(option.value) ? 'primary' : 'secondary'}
            />
          )),
        ]}
      </Stack>
    </Stack>
  );

  return (
    <ChangeLogs
      title={t('changeLog:buyGuideTitle')}
      data={data}
      isLoading={isLoading}
      refresh={refresh}
      renderFilter={renderFilterSection}
      renderItem={(item) => <BuyGuideVendorChangeLogItem key={item.id} item={item} />}
    />
  );
};
