import { boolean, object, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorInvoicingInformationEntity } from '../../models';
import { EntityFormSchema } from '../../types';

export const VendorInvoicingInformationSchema = (): EntityFormSchema<VendorInvoicingInformationEntity> => ({
  schema: object({
    centralBilling: boolean().notRequired().nullable(),
    directBilling: boolean().notRequired().nullable(),
    ediCapability: boolean().notRequired().nullable(),
    ediSystem: string().max(MAX_SHORT_TEXT_LENGTH).notRequired().nullable(),
    notes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
    vendorNotes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
  }),
});
