import {
  VendorRebateCategoryEntity,
  VendorRebateCategoryRebateSummaryEntity,
  VendorRebateCategoryRebateSummaryGroupEntity,
} from '.';
import { CategorizationEntity, PeriodEntity, TargetedMembershipEntity } from '..';
import { VendorRebateCategoryProgramStatus } from '../../types';

export class VendorRebateCategoryProgramEntity extends PeriodEntity {
  status: VendorRebateCategoryProgramStatus;
  number: number;
  name_Fr: string;
  name_En: string;
  notes: string;
  isArchived: boolean;
  vendorDesignations: CategorizationEntity[];
  vendorDesignationIds: number[];
  memberNotes_Fr: string;
  memberNotes_En: string;
  filesCount: number;
  foid: VendorRebateCategoryRebateSummaryGroupEntity;
  targetedMembership: TargetedMembershipEntity | null;
  payableRebatesSummary: VendorRebateCategoryRebateSummaryEntity;
  permissionNotes: string;
  rebateCategory: VendorRebateCategoryEntity;

  constructor(entity?: VendorRebateCategoryProgramEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.vendorDesignationIds = entity.vendorDesignations.map((v) => v.id);
    } else {
      this.status = VendorRebateCategoryProgramStatus.InProgress;
      this.number = 0;
      this.name_Fr = '';
      this.name_En = '';
      this.notes = '';
      this.isArchived = false;
      this.vendorDesignations = [];
      this.vendorDesignationIds = [];
      this.memberNotes_Fr = '';
      this.memberNotes_En = '';
      this.filesCount = 0;
      this.targetedMembership = new TargetedMembershipEntity();
      this.payableRebatesSummary = new VendorRebateCategoryRebateSummaryEntity();
      this.rebateCategory = new VendorRebateCategoryEntity();
      this.permissionNotes = '';
    }
  }
}
