import { VisibilityRounded } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { member as memberApi } from '../../../api';
import { useApi, useEntityFormContext, useInternationalization } from '../../../hooks';
import { MemberContactEntity } from '../../../models';
import { ContactType, Styles } from '../../../types';
import { EmailAddressListForm, PhoneNumberListForm } from '../../Contact';
import { EntityModifiedDate } from '../../EntityModifiedDate';
import { ControlledCheckbox, ControlledInput, ControlledSelect, ControlledTranslatedInput, Switch } from '../../Form';
import { Loading } from '../../Loading';

const style: Styles = {
  container: {
    pb: 4,
  },
  tableCell: {
    px: 1,
    py: 0.5,
    verticalAlign: 'top',
    '&:first-of-type': {
      pl: 0,
    },
    '&:last-child': {
      pr: 0,
    },
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  tableButton: {
    mt: 3,
  },
  buttonBox: {
    mt: 1,
  },
};

export const MemberContactForm = () => {
  const { t, getTranslation } = useInternationalization();
  const { data: contactRoles, isLoading } = useApi(memberApi.getContactRoles, { callImmediately: true });
  const {
    setValue,
    getValues,
    control,
    readOnly,
    watch,
    formState: { dirtyFields },
  } = useEntityFormContext<MemberContactEntity>();
  const editing = !!watch('id');
  const contactType = watch('type');

  return isLoading ? (
    <Loading />
  ) : (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        {editing && (
          <Grid item xs={12}>
            <EntityModifiedDate entity={getValues()} />
          </Grid>
        )}
        {!editing && (
          <Grid item xs={12}>
            <Switch
              label={t('member:contact.type.department')}
              leftLabel={t('member:contact.type.person')}
              name="type"
              hideLabel={readOnly}
              readOnly={readOnly}
              showIcon={dirtyFields.type}
              onChange={(_, checked) => {
                setValue('type', checked ? ContactType.Department : ContactType.Person, { shouldDirty: true });
              }}
              checked={contactType === ContactType.Department}
            />
          </Grid>
        )}
        {contactType === ContactType.Person ? (
          <>
            <Grid item xs={6}>
              <ControlledInput label={t('user:form.firstName')} name="firstName" control={control} required />
            </Grid>
            <Grid item xs={6}>
              <ControlledInput label={t('user:form.lastName')} name="lastName" control={control} required />
            </Grid>
            <Grid item xs={12}>
              <ControlledSelect
                label={t('member:contact.form.role')}
                name="roleId"
                options={contactRoles?.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []}
                control={control}
                required
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <ControlledTranslatedInput
              label={t('member:contact.form.department')}
              name="department"
              control={control}
              required
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledCheckbox
            label={t('member:contact.form.isVisibleToMember')}
            icon={VisibilityRounded}
            name="isVisibleToMember"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailAddressListForm />
        </Grid>
        <Grid item xs={12}>
          <PhoneNumberListForm />
        </Grid>
      </Grid>
    </Box>
  );
};
