import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EntityFormProvider } from '../contexts';
import { useEntityForm } from '../hooks';
import { palette } from '../styles/palette';
import { Entity, EntityFormSchema, Styles } from '../types';
import { Drawer } from './Drawer';

const style: Styles = {
  container: {
    minWidth: {
      xs: '100%',
      md: 700,
    },
    maxWidth: {
      xs: '100%',
      lg: '30vw',
    },
    backgroundColor: palette.white,
    borderTopLeftRadius: '12px',
    overflow: 'hidden',
    outline: 'none',
    flex: 1,
    marginTop: 1,
    spacing: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  containerLarge: {
    minWidth: {
      xs: '100%',
      lg: '90vw',
    },
    maxWidth: {
      xs: '100%',
      lg: '70vw',
    },
  },
  box: {
    px: 3,
    color: palette.white,
    alignItems: 'center',
  },
  actionBox: {
    minHeight: 78,
    backgroundColor: palette.primary.dark,
  },
  titleBox: {
    minHeight: 47,
    display: 'flex',
    px: 2,
    backgroundColor: palette.primary.main,
  },
  childrenContainer: {
    my: 3,
    px: 2.5,
    overflowY: 'auto',
    flex: 1,
  },
  paperDrawer: {
    backgroundColor: 'transparent',
    width: {
      xs: '100%',
      md: 'inherit',
    },
  },
};

export interface EditDrawerProps<T extends Entity> extends PropsWithChildren {
  entity: T;
  open: boolean;
  schema: EntityFormSchema<T>;
  onSave: (entity: T) => Promise<T | null>;
  onConfirm: () => void;
  onCancel: () => void;
  title: string | React.ReactNode;
  redirectPath?: (entity: T) => string;
  redirectLabel?: string;
  size?: 'medium' | 'large';
  isChangeRequest?: boolean;
}

export const EditDrawer = <T extends Entity>({
  entity,
  schema,
  open,
  onCancel,
  onConfirm,
  onSave,
  title,
  children,
  redirectPath,
  redirectLabel,
  size = 'medium',
  isChangeRequest,
}: EditDrawerProps<T>) => {
  const { t } = useTranslation();
  const { handleSubmit, reset, isSubmitDisabled, form, handleError } = useEntityForm(entity, schema, {
    variant: 'drawer',
  });
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!open) {
      reset(entity);
    }
  }, [open, reset, entity]);

  const onSubmitSuccess = async (data: T) => {
    if (!isSaving) {
      try {
        setIsSaving(true);
        const saveResult = await onSave(data);
        if (saveResult) {
          onConfirm();
          reset(entity);
          return saveResult;
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const onSubmitAndRedirect = async (data: T) => {
    const result = await onSubmitSuccess(data);
    if (result && redirectPath) {
      navigate(redirectPath(result), { state: { ignoreBlocker: true } });
    }
    onConfirm();
  };

  const onClose = () => {
    reset(entity);
    onCancel();
  };

  return (
    <Drawer PaperProps={{ sx: style.paperDrawer }} anchor="right" open={open} onClose={onClose}>
      <EntityFormProvider {...form}>
        <Box
          sx={{ ...style.container, ...(size === 'large' ? style.containerLarge : {}) }}
          component="form"
          onSubmit={handleSubmit(onSubmitSuccess)}
        >
          <Box sx={[style.box, style.titleBox]}>{title} </Box>
          <Box sx={style.childrenContainer}>{children}</Box>
          <Stack spacing={3} direction="row" sx={[style.box, style.actionBox]} justifyContent="space-between">
            <Button onClick={onCancel} variant="outlined" color="secondary" size="small">
              {t('common:cancel')}
            </Button>
            <Stack spacing={3} direction="row">
              <LoadingButton
                loading={isSaving}
                disabled={isSubmitDisabled}
                variant="contained"
                size="small"
                type="submit"
              >
                {isChangeRequest ? t('common:changeRequest.submit') : t('common:saveAndClose')}
              </LoadingButton>
              {redirectPath && (
                <LoadingButton
                  size="small"
                  loading={isSaving}
                  disabled={isSubmitDisabled}
                  variant="contained"
                  onClick={handleSubmit(onSubmitAndRedirect)}
                >
                  {redirectLabel ?? t('common:saveAndManage')}
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </Box>
      </EntityFormProvider>
    </Drawer>
  );
};
