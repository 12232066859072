import { EditRounded, ModeCommentRounded, MoreVertRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState, MouseEvent, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { DOLLAR_MAX_DECIMALS } from '../../../constants';
import { ForestProductPriceUpdateContext } from '../../../contexts/ForestProduct';
import { forestProduct as forestProductApi } from '../../../api';
import { useApi, useEntityForm, useGlobalEdit, useInternationalization, usePageTitle } from '../../../hooks';
import { ForestProductPriceUpdateEntity } from '../../../models';
import { ForestProductPriceUpdateSchema } from '../../../schemas';
import { LanguageCode, PermissionKey, RebateTypeAndUnitType, Styles } from '../../../types';
import { numberFormatter } from '../../../utils/formatters';
import { ControlledInput } from '../../Form';
import { LayoutPage } from '../../Layout';
import { Loading } from '../../Loading';
import { LoadingButton } from '@mui/lab';
import { palette } from '../../../styles/palette';
import theme from '../../../styles/theme';
import { StaticDrawer } from '../../StaticDrawer';
import { ForestProductPriceUpdatePricesCommentForm } from './ForestProductPriceUpdatePricesCommentForm';
import { HasPermissions } from '../../Permission';
import { EntityFormProvider } from '../../../contexts';

const styles: Styles = {
  menu: {
    '& li': {
      whiteSpace: 'nowrap',
    },
  },
};

export const ForestProductPriceUpdatePrices = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const params = useParams();
  const productGroupId = Number(params.productGroupId);
  const { priceUpdate, refresh } = useContext(ForestProductPriceUpdateContext);
  const [editing, setEditing] = useState(false);
  const { globalEditing, setGlobalEditing } = useGlobalEdit();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuItemIndex, setMenuItemIndex] = useState<number | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { control, reset, isSubmitDisabled, handleSubmit, handleError, form } = useEntityForm(
    priceUpdate || new ForestProductPriceUpdateEntity(),
    ForestProductPriceUpdateSchema(),
    {
      readOnly: !editing,
    },
  );
  const { call: update, isLoading: updateLoading } = useApi(forestProductApi.updateForestProductPriceUpdate, {
    successKey: 'common:success.save',
  });
  const productGroup = priceUpdate?.products?.find(
    (product) => product.forestProduct.forestProductGroup.id === productGroupId,
  )?.forestProduct.forestProductGroup;

  const tradingCities = useMemo(
    () => priceUpdate?.products?.[0].tradingCities.map((c) => c.tradingCity),
    [priceUpdate?.products],
  );

  usePageTitle(productGroup ? getTranslation(productGroup, 'name') : '');

  const moneyFormatter = useMemo(
    () =>
      numberFormatter(
        currentLanguage,
        t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Dollar}`),
        DOLLAR_MAX_DECIMALS,
        currentLanguage !== LanguageCode.en,
        true,
      ),
    [currentLanguage, t],
  );

  const onEditClick = () => setEditing(true);
  const onCancelClick = () => {
    setEditing(false);
    reset();
  };

  const onSubmitSuccess = async (data: ForestProductPriceUpdateEntity) => {
    try {
      await update({
        ...data,
        products: data.products?.map((p) => ({
          ...p,
          tradingCities: [...p.tradingCities.map((c) => ({ ...c, price: c.price ?? null }))],
        })),
      });
      setEditing(false);
      refresh();
    } catch (error) {
      handleError(error);
    }
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>, productIndex: number) => {
    setMenuItemIndex(productIndex);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setGlobalEditing(editing);
  }, [editing, setGlobalEditing]);

  const renderActionMenuItems = () => {
    return priceUpdate && menuItemIndex != null
      ? [
          !priceUpdate.products?.[menuItemIndex].notes_En && !priceUpdate.products?.[menuItemIndex].notes_Fr ? (
            <MenuItem
              id="addComment"
              key="addComment"
              onClick={() => {
                setIsDrawerOpen(true);
                handleMenuClose();
              }}
            >
              {t('forestProduct:priceUpdates.actions.addComment')}
            </MenuItem>
          ) : (
            <MenuItem
              id="editComment"
              key="editComment"
              onClick={() => {
                setIsDrawerOpen(true);
                handleMenuClose();
              }}
            >
              {t('forestProduct:priceUpdates.actions.editComment')}
            </MenuItem>
          ),
        ]
      : [];
  };

  if (!priceUpdate || !productGroup) {
    return <Loading />;
  }

  return (
    <LayoutPage
      display="Tab"
      title={getTranslation(productGroup, 'name')}
      rightTitle={
        editing ? (
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={onCancelClick}>
              {t('common:cancel')}
            </Button>
            <LoadingButton
              loading={updateLoading}
              disabled={isSubmitDisabled}
              variant="contained"
              type="submit"
              onClick={handleSubmit(onSubmitSuccess)}
            >
              {t('common:save')}
            </LoadingButton>
          </Stack>
        ) : (
          <HasPermissions keys={PermissionKey.CommodityPriceEdit}>
            <Button
              variant="outlined"
              disabled={globalEditing || priceUpdate.isArchived}
              startIcon={<EditRounded />}
              onClick={onEditClick}
            >
              {t('common:edit')}
            </Button>
          </HasPermissions>
        )
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="20%" />
              {tradingCities?.map((tradingCity) => (
                <TableCell width={`${80 / tradingCities.length}%`} key={tradingCity.id}>
                  {getTranslation(tradingCity, 'name')}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {priceUpdate.products?.map((product, productIndex) =>
              product.forestProduct.forestProductGroup.id === productGroupId ? (
                <TableRow key={product.forestProduct.id}>
                  <TableCell>{getTranslation(product.forestProduct, 'name')}</TableCell>
                  {product.tradingCities.map((tradingCity, tradingCityIndex) => (
                    <TableCell key={tradingCity.tradingCity.id}>
                      {tradingCity.tradingCity.id === tradingCities?.[tradingCityIndex]?.id && (
                        <ControlledInput
                          name={`products.${productIndex}.tradingCities.${tradingCityIndex}.price`}
                          control={control}
                          formatter={moneyFormatter}
                        />
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    {editing ? (
                      <IconButton id="action-menu" onClick={(e) => handleMenuOpen(e, productIndex)}>
                        <MoreVertRounded htmlColor={palette.secondary.dark} />
                      </IconButton>
                    ) : (
                      getTranslation(product, 'notes') && (
                        <Tooltip arrow title={getTranslation(product, 'notes')} placement="top">
                          <ModeCommentRounded fontSize="small" htmlColor={palette.grey[500]} />
                        </Tooltip>
                      )
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <Fragment key={product.forestProduct.id} />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="table-menu"
        MenuListProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.MenuListProps}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        PaperProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps}
        sx={styles.menu}
      >
        {renderActionMenuItems()}
      </Menu>

      <EntityFormProvider {...form}>
        <StaticDrawer
          title={
            menuItemIndex != null
              ? priceUpdate.products?.[menuItemIndex].notes_En || priceUpdate.products?.[menuItemIndex].notes_Fr
                ? t('forestProduct:priceUpdates.actions.editComment')
                : t('forestProduct:priceUpdates.actions.addComment')
              : ''
          }
          open={isDrawerOpen}
          footer={
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button variant="contained" size="small" onClick={() => setIsDrawerOpen(false)}>
                {t('common:close')}
              </Button>
            </Box>
          }
          onClose={() => setIsDrawerOpen(false)}
        >
          {menuItemIndex != null && priceUpdate.products?.[menuItemIndex] && (
            <ForestProductPriceUpdatePricesCommentForm
              control={control}
              productIndex={menuItemIndex}
              product={priceUpdate.products?.[menuItemIndex]}
            />
          )}
        </StaticDrawer>
      </EntityFormProvider>
    </LayoutPage>
  );
};
