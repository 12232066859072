import { Grid } from '@mui/material';
import { ControlledInput, ControlledSelect, StaticField } from '../../components/Form';
import { useEntityFormContext, useEnumList, useInternationalization } from '../../hooks';
import { ProfileEntity } from '../../models';
import { LanguageCode, TimeZoneCode } from '../../types';
import { phoneNumberFormatter } from '../../utils/formatters';

export const ProfileForm = () => {
  const { t } = useInternationalization();
  const { control, readOnly, variant, getValues } = useEntityFormContext<ProfileEntity>();
  const languageOptions = useEnumList(LanguageCode, 'common:language');
  const timeZoneOptions = useEnumList(TimeZoneCode, 'common:timeZone');

  const profile = getValues() as ProfileEntity;

  return (
    <Grid container rowSpacing={4} columnSpacing={2}>
      {!readOnly && (
        <>
          <Grid item xs={6}>
            <ControlledInput
              label={t('user:profile.firstName')}
              name="firstName"
              control={control}
              variant="standard"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledInput
              label={t('user:profile.lastName')}
              name="lastName"
              control={control}
              variant="standard"
              required
            />
          </Grid>
        </>
      )}
      <Grid item xs={3}>
        <ControlledInput label={t('user:profile.jobTitle')} name="jobTitle" control={control} />
      </Grid>
      <Grid item xs={3}>
        <ControlledInput label={t('user:profile.department')} name="department" control={control} />
      </Grid>
      <Grid item xs={6}>
        <ControlledSelect
          label={t('user:profile.timeZone')}
          name="timeZone"
          options={timeZoneOptions}
          control={control}
          defaultValue={TimeZoneCode.MountainTime}
          renderValue={(id) => t(`common:timeZone.${id}`)}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledInput
          label={t('user:profile.email')}
          name={`email`}
          control={control}
          required
          readOnly={variant === 'details'}
        />
      </Grid>
      <Grid item xs={3}>
        <Grid container columnSpacing={0.5}>
          <Grid item xs={readOnly ? 6.5 : 8} lg={readOnly ? 6 : 8} xl={readOnly ? 5 : 8}>
            <ControlledInput
              label={t('user:profile.phoneOffice')}
              formatter={phoneNumberFormatter}
              name="phoneOffice.number"
              control={control}
            />
          </Grid>
          <Grid item xs={readOnly ? 4 : 4}>
            <ControlledInput
              label={t('user:profile.officeExtension')}
              name="phoneOffice.extension"
              type={'tel'}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={readOnly ? 2 : 2}>
        <ControlledInput
          label={t('user:profile.phoneMobile')}
          name="phoneMobile.number"
          control={control}
          formatter={phoneNumberFormatter}
        />
      </Grid>
      {profile.members && readOnly && (
        <Grid item xs={2}>
          <StaticField value={profile.members.map((m) => m.displayName).join(', ')} label={t('user:profile.members')} />
        </Grid>
      )}
      {profile.vendor && readOnly && (
        <Grid item xs={2}>
          <StaticField value={profile.vendor.displayName} label={t('user:profile.vendor')} />
        </Grid>
      )}
      <Grid item xs={readOnly ? 2 : 4}>
        <ControlledSelect
          label={t('user:profile.language')}
          name="language"
          options={languageOptions}
          control={control}
          defaultValue={LanguageCode.en}
          renderValue={(id) => t(`common:language.${id}`)}
        />
      </Grid>
    </Grid>
  );
};
