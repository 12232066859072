import axios from 'axios';
import { MemberContactEntity, MemberContactRoleEntity, MemberEntityChangeRequestEntity } from '../../models';
import { ChangeRequestTrigger, MemberContactFilter, PaginationEntity, PaginationFilter } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const contact = {
  getContactRoles: async (): Promise<MemberContactRoleEntity[]> => {
    const { data } = await axios.get(`api/member/contactRoles`);
    return data.map((v: MemberContactRoleEntity) => new MemberContactRoleEntity(v));
  },
  getAllContacts: async (
    memberId: number,
    contactFilter: MemberContactFilter,
  ): Promise<PaginationEntity<MemberContactEntity>> => {
    const { data } = await axios.post(`api/member/${memberId}/contacts/search`, contactFilter);
    return { ...data, data: data.data.map((v: MemberContactEntity) => new MemberContactEntity(v)) };
  },
  createContact: async (memberId: number, entity: MemberContactEntity): Promise<MemberContactEntity> => {
    const { data } = await axios.post(`api/member/${memberId}/contacts`, entity);
    return new MemberContactEntity(data);
  },
  updateContact: async (memberId: number, entity: MemberContactEntity): Promise<MemberContactEntity> => {
    const { data } = await axios.put(`api/member/${memberId}/contacts/${entity.id}`, entity);
    return new MemberContactEntity(data);
  },
  archiveContact: async (memberId: number, ids: number[], isArchived: boolean): Promise<MemberContactEntity[]> => {
    const { data } = await axios.put(`api/member/${memberId}/contacts/archive`, { isArchived, ids });
    return data.map((v: MemberContactEntity) => new MemberContactEntity(v));
  },
  getCurrentContacts: async (contactFilter: PaginationFilter): Promise<PaginationEntity<MemberContactEntity>> => {
    const { data } = await axios.post(`api/member/contacts/search`, contactFilter);
    return { ...data, data: data.data.map((v: MemberContactEntity) => new MemberContactEntity(v)) };
  },
  createContactChangeRequest: async (memberId: number, entity: MemberContactEntity): Promise<MemberContactEntity> => {
    const { data } = await axios.post(`api/member/${memberId}/contacts/changeRequest`, entity);
    return new MemberContactEntity(data);
  },
  updateContactChangeRequest: async (memberId: number, entity: MemberContactEntity): Promise<MemberContactEntity> => {
    const { data } = await axios.put(`api/member/${memberId}/contacts/${entity.id}/changeRequest`, entity);
    return new MemberContactEntity(data);
  },
  removeContactChangeRequest: async (memberId: number, id: number): Promise<MemberContactEntity> => {
    const { data } = await axios.delete(`api/member/${memberId}/contacts/${id}/changeRequest`);
    return new MemberContactEntity(data);
  },
  approvedOrRejectContactRequest: async (
    id: number,
    trigger: ChangeRequestTrigger,
    entity: MemberContactEntity,
  ): Promise<MemberContactEntity> => {
    const { data } = await axios.post(`api/memberChangeRequests/contacts/${id}/${trigger}`, entity);
    return new MemberContactEntity(data);
  },
  getContactChangeRequest: async (id: number): Promise<MemberEntityChangeRequestEntity<MemberContactEntity>> => {
    const { data } = await axios.get(`api/memberChangeRequests/contacts/${id}`);
    return new MemberEntityChangeRequestEntity(data);
  },
  exportAllContacts: async (memberId: number): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/member/${memberId}/contacts/export`, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  exportAllMembersContacts: async (): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/member/contacts/export`, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  mailSyncContacts: async (memberId: number, ids: number[]): Promise<void> => {
    await axios.put(`api/member/${memberId}/contacts/mailsync`, { ids });
  },
};
