import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth, useEntityFormContext } from '../../../hooks';
import { CommitmentEventEntity } from '../../../models';
import { ControlledDatePicker } from '../../Form';

export const CommitmentEventHeader = () => {
  const { t } = useTranslation();
  const { user, isMemberUser } = useAuth();
  const displayName = user?.profile.getDisplayName(user.memberId);
  const { control, readOnly, variant, watch } = useEntityFormContext<CommitmentEventEntity>();
  const year = watch('year');

  return (
    <>
      {!isMemberUser ? (
        <Grid container>
          <Grid item xs={12} sm={3} lg={variant == 'drawer' ? 3 : 2}>
            <ControlledDatePicker
              label={t('cci:events.form.year')}
              name="year"
              yearOnly
              control={control}
              required
              readOnlyVariant="h1"
              hideLabel={variant == 'drawer' ? false : readOnly}
            />
          </Grid>
        </Grid>
      ) : (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h1">{year}</Typography>
          <Typography variant="label">{displayName}</Typography>
        </Stack>
      )}
    </>
  );
};
