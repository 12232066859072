import { useOutlet } from 'react-router-dom';
import { useAuth, useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';

export const TBMContactLayout = () => {
  const { isMemberUser, isInternalUser } = useAuth();
  const { t } = useInternationalization();
  const outlet = useOutlet();

  const tabs = [
    <LinkTab
      label={t('contact:sections.internalContacts')}
      value={
        isInternalUser
          ? routes.Admin.TBMContacts.InternalContacts.path
          : isMemberUser
          ? routes.Member.TBMContacts.InternalContacts.path
          : routes.Vendor.TBMContacts.InternalContacts.path
      }
    />,
    <LinkTab
      label={t('contact:sections.offices')}
      value={
        isInternalUser
          ? routes.Admin.TBMContacts.Offices.path
          : isMemberUser
          ? routes.Member.TBMContacts.Offices.path
          : routes.Vendor.TBMContacts.Offices.path
      }
    />,
  ];
  return <LayoutPage display="Page" tabs={tabs} title={t('contact:title')} outlet={outlet} />;
};
