import { RebateTypeAndUnitType } from '../../types';
import { CategorizationEntity } from '../Categorization';
import { VendorRebateCategoryValueEntity } from './VendorRebateCategoryValueEntity';

export class VendorRebateCategoryRebateValueEntity {
  id: number;
  isRebateNote: boolean;
  value: number | null;
  unit: RebateTypeAndUnitType | null;
  customUnit_En: string;
  customUnit_Fr: string;
  customUnit: string;
  rebateNote_En: string;
  rebateNote_Fr: string;
  rebateNote: string;
  vendorRebateCode: string;
  receivableFrequencyId: number | null;
  rebateTypeId: number | null;
  paymentMethodId: number | null;
  notes: string;
  memberNotes_En: string;
  memberNotes_Fr: string;
  paymentMethod: VendorRebateCategoryValueEntity | null;
  receivableFrequency: VendorRebateCategoryValueEntity | null;
  rebateType: CategorizationEntity | null;
  holdbackGL: string | null;

  constructor(entity?: VendorRebateCategoryRebateValueEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.isRebateNote = false;
      this.customUnit_En = '';
      this.customUnit_Fr = '';
      this.customUnit = '';
      this.rebateNote_En = '';
      this.rebateNote_Fr = '';
      this.rebateNote = '';
      this.vendorRebateCode = '';
      this.holdbackGL = '';
      this.notes = '';
      this.memberNotes_En = '';
      this.memberNotes_Fr = '';
      this.unit = RebateTypeAndUnitType.Percent;
    }
  }
}
