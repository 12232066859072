import Typography from '@mui/material/Typography';
import { useFieldArray } from 'react-hook-form';
import { member as memberApi } from '../../api';
import { useEntityFormContext, useInternationalization } from '../../hooks';
import { MemberEntity, TargetedMembershipMemberEntity } from '../../models';
import { Styles, TargetedEntity } from '../../types';
import { MemberPermissionsList } from '../MemberPermissions';

const style: Styles = {
  header: {
    py: 3,
  },
};

interface TargetedMembershipMemberFormProps {
  hideTitle?: boolean;
  fullWidth?: boolean;
}

export const TargetedMembershipMemberForm = ({ hideTitle, fullWidth }: TargetedMembershipMemberFormProps) => {
  const { t } = useInternationalization();
  const { control, readOnly } = useEntityFormContext<TargetedEntity>();

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'targetedMembership.members',
  });

  const onAddPermission = ({ id, name }: MemberEntity) => {
    append({ ...new TargetedMembershipMemberEntity(), memberId: id, memberName: name });
  };

  return (
    <>
      {!hideTitle && (
        <Typography variant="h3" sx={style.header}>
          {t('vendor:targetedMembership.form.memberTitle')}
        </Typography>
      )}
      <MemberPermissionsList
        permissions={fields}
        isIncludedProperty="isAllowed"
        apiCall={memberApi.getSuggestions}
        renderKeyValue={(p: TargetedMembershipMemberEntity) => ({
          label: p.memberName ?? '',
          value: p.memberId,
        })}
        renderOptionLabel={(item: MemberEntity) => `${item.fullNumber} - ${item.name}`}
        nameColumn={t('vendor:targetedMembership.form.member')}
        isIncludedColumn={t('vendor:targetedMembership.form.permission')}
        onUpdate={update}
        onAdd={onAddPermission}
        readOnly={readOnly}
        onRemove={remove}
        fullWidth={fullWidth}
      />
    </>
  );
};
