import { PendingEntity } from '../PendingEntity';

export class VendorTaxInformationEntity extends PendingEntity {
  gstNumber?: string;
  qstNumber?: string;
  notes?: string;
  vendorNotes?: string;
  vendorId?: number;

  constructor(entity?: VendorTaxInformationEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.gstNumber = '';
      this.qstNumber = '';
      this.notes = '';
      this.vendorNotes = '';
    }
  }
}
