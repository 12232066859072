import { blocked } from './blocked';
import { buyOpportunity } from './buyOpportunity';
import { buyingAgreement } from './buyingAgreement';
import { changeRequest } from './changeRequest';
import { contact } from './contact';
import { document } from './document';
import { financial } from './financial';
import { generalCondition } from './generalCondition';
import { growth } from './growth';
import { location } from './location';
import { program } from './program';
import { rebate } from './rebate';
import { tier } from './tier';
import { vendor as base } from './vendor';

export const vendor = {
  ...base,
  ...financial,
  ...buyingAgreement,
  ...rebate,
  ...location,
  ...tier,
  ...growth,
  ...generalCondition,
  ...document,
  ...blocked,
  ...contact,
  ...program,
  ...buyOpportunity,
  ...changeRequest,
};
