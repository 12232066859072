import { CategorizationLayout } from '../../../components/Categorization';

export function ProductCategories() {
  return (
    <CategorizationLayout
      type="productCategory"
      translationContext="female"
      omitFields={['abbreviation', 'description']}
    />
  );
}
