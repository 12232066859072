import { useInternationalization } from '../../../hooks';
import { ChangeLogEntity } from '../../../models';
import { ChangeLogItem } from '../../ChangeLog';

interface CmsChangeLogItemProps {
  item: ChangeLogEntity;
}

export const CmsChangeLogItem = ({ item }: CmsChangeLogItemProps) => {
  const { t } = useInternationalization();

  return (
    <ChangeLogItem
      item={item}
      renderValue={(item) =>
        t(`changeLog:entityType.${item.entityType}`, {
          action: item.changeLogActionType,
          fullName: item.loggedBy,
        })
      }
    />
  );
};
