import { useState } from 'react';
import { MemberChangeRequestContext } from '../../contexts';
import { MemberChangeRequestEntity } from '../../models';
import { MemberChangeRequestEntityType } from '../../types';
import { MemberContactChangeRequest } from './MemberContactChangeRequest';
import { MemberGeneralInformationChangeRequest } from './MemberGeneralInformationChangeRequest';

interface MemberChangeRequestProps {
  memberChangeRequest: MemberChangeRequestEntity;
  onConfirm: () => void;
  onCancel: () => void;
}

export const MemberChangeRequest = ({ memberChangeRequest, onConfirm, onCancel }: MemberChangeRequestProps) => {
  const [isAfter, setIsAfter] = useState(!memberChangeRequest.isDeleted);

  const getForm = () => {
    switch (memberChangeRequest.entityType) {
      case MemberChangeRequestEntityType.MemberContact:
        return <MemberContactChangeRequest />;
      case MemberChangeRequestEntityType.MemberGeneralInformation:
        return <MemberGeneralInformationChangeRequest />;
      default:
        return null;
    }
  };

  return (
    <MemberChangeRequestContext.Provider
      value={{
        changeRequest: memberChangeRequest,
        isAfter,
        onConfirm,
        onCancel,
        toggleViewMode: setIsAfter,
      }}
    >
      {getForm()}
    </MemberChangeRequestContext.Provider>
  );
};
