import { WarningRounded } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CalculateIcon from '@mui/icons-material/Calculate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { vendor as vendorApi } from '../../api/vendor';
import { DOLLAR_MAX_DECIMALS, PERCENT_MAX_DECIMALS } from '../../constants';
import { useApi, useDebounce, useInternationalization } from '../../hooks';
import {
  CategorizationEntity,
  VendorRebateCategoryProgramEntity,
  VendorRebateCategoryRebateValueEntity,
  VendorRebateCategoryValueEntity,
} from '../../models';
import { palette } from '../../styles/palette';
import { LanguageCode, RebateTypeAndUnitType, Styles, VendorRebateCategoryValue } from '../../types';
import { numberFormatter, percentageFormatter } from '../../utils/formatters';
import { Drawer } from '../Drawer';
import { Checkbox, Input, Select, StaticField } from '../Form';
import { Loading } from '../Loading';

const style: Styles = {
  container: {
    minWidth: {
      xs: '100%',
      md: 700,
    },
    maxWidth: {
      xs: '100%',
      lg: '30vw',
    },
    backgroundColor: palette.white,
    borderTopLeftRadius: '12px',
    overflow: 'hidden',
    outline: 'none',
    flex: 1,
    marginTop: 1,
    spacing: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    px: 3,
    color: palette.white,
    alignItems: 'center',
  },
  actionBox: {
    minHeight: 78,
    backgroundColor: palette.primary.dark,
  },
  titleBox: {
    minHeight: 47,
    display: 'flex',
    px: 2,
    backgroundColor: palette.primary.dark,
    '>svg': {
      marginRight: 1,
    },
  },
  contentContainer: {
    my: 3,
    px: 2.5,
    overflowY: 'auto',
    flex: 1,
  },
  paperDrawer: {
    backgroundColor: 'transparent',
    width: {
      xs: '100%',
      md: 'inherit',
    },
  },
  borderedTopContainer: {
    marginTop: 2,
    marginLeft: 2,
    padding: '16px 0 0 0 !important',
    borderTop: `2px solid ${palette.grey[200]}`,
  },
  input: {
    marginTop: '0px !important',
  },
  excludedRow: {
    backgroundColor: palette.grey[50],
    '& .MuiTableCell-root': {
      color: palette.grey[400],
    },
    '& .MuiFormControl-root': {
      opacity: 0.5,
    },
  },
  commentIcon: {
    fontSize: 20,
  },
  circularProcess: {
    mr: 2,
  },
};

interface BuyGuideVendorRebateProgramCalculatorProps {
  selectedProgram: VendorRebateCategoryProgramEntity | undefined;
  programs: VendorRebateCategoryProgramEntity[];
  vendorId: number;
  onClose: () => void;
}

type BuyGuideCalculatorRebate = VendorRebateCategoryRebateValueEntity & {
  selected?: boolean;
  added?: boolean;
  included?: boolean;
  actualValue?: number | null;
};

const PAYMENT_FREQUENCY_TYPES = ['MP', 'QP', 'SP', 'AP'];
const TERM_VALUE_RECEIVABLE_FREQUENCY_ID = 0;
const FOID_RECEIVABLE_FREQUENCY_ID = 1;

// Sort rebates by their receivableFrequencyId, and then by whether it is manually added or not
const compareRebates = (a: BuyGuideCalculatorRebate, b: BuyGuideCalculatorRebate) => {
  if (a.receivableFrequencyId === b.receivableFrequencyId) {
    if (!a.added && b.added) {
      return -1;
    }
    if (a.added && !b.added) {
      return 1;
    }
    if (a.added && b.added) {
      return a.id > b.id ? -1 : 1;
    }
    return 0;
  }
  if (a.receivableFrequencyId === null) {
    return 1;
  }
  if (b.receivableFrequencyId === null) {
    return -1;
  }
  if (a.receivableFrequencyId < b.receivableFrequencyId) {
    return -1;
  }
  return 1;
};

export const BuyGuideVendorRebateProgramCalculator = ({
  selectedProgram: program,
  programs,
  vendorId,
  onClose,
}: BuyGuideVendorRebateProgramCalculatorProps) => {
  const internationalization = useInternationalization();
  const { t, getTranslation, currentLanguage } = internationalization;
  const [listPrice, setListPrice] = useState(0);
  const [selectedProgram, setSelectedProgram] = useState<VendorRebateCategoryProgramEntity | undefined>(program);
  const [calculatorRebates, setCalculatorRebates] = useState<BuyGuideCalculatorRebate[]>([]);
  const [lastRebateId, setLastRebateId] = useState<number>(0);
  const [calculationResults, setCalculationResults] = useState({
    netPrice: 0,
    completeDiscount: 0,
  });

  const debouncedListPrice = useDebounce(listPrice, 1000);
  const debouncedSelectedProgram = useDebounce(selectedProgram, 1000);
  const debouncedCalculatorRebates = useDebounce(calculatorRebates, 1000);

  const { data: receivableFrequencies } = useApi(
    vendorApi.getRebateCategoryValues,
    null,
    VendorRebateCategoryValue.ReceivableFrequencies,
  );
  const { data: rebate } = useApi(
    vendorApi.getRebateProgramRebate,
    { skipFetch: !vendorId || !selectedProgram?.id },
    vendorId,
    selectedProgram?.id,
  );
  const { call: calculate, isLoading: calculating } = useApi(vendorApi.calculateRebateProgramRebate, null);

  const programOptions = useMemo(
    () => programs.map((p) => ({ value: p.id, label: getTranslation(p, 'name') })),
    [getTranslation, programs],
  );
  const receivableFrequenciesOptions = useMemo(
    () => [
      // Add FOID manually, as it is not an option for Payable Rebates
      { value: 1, label: t('buyGuide:calculator.table.foid') },
      ...(receivableFrequencies
        // Remove all non-payable Frequency Types
        ?.filter((rf) => PAYMENT_FREQUENCY_TYPES.includes(rf.value_En))
        .map((rf) => ({
          value: rf.id,
          label: getTranslation(rf, 'value'),
        })) ?? []),
    ],
    [getTranslation, receivableFrequencies, t],
  );

  const defaultCalculatorRebates = useMemo(
    () =>
      rebate
        ? [
            // Create Term rebate from Term Value in Rebate,
            {
              ...new VendorRebateCategoryRebateValueEntity(),
              actualValue: rebate.termValue,
              unit: rebate.unit,
              value: rebate.unit === RebateTypeAndUnitType.Percent ? rebate.termValue : 0,
              included: true,
              receivableFrequencyId: TERM_VALUE_RECEIVABLE_FREQUENCY_ID,
              receivableFrequency: {
                ...new VendorRebateCategoryValueEntity(),
                value_En: t('buyGuide:calculator.table.termValue'),
                value_Fr: t('buyGuide:calculator.table.termValue'),
              },
              // Copy Term note from Rebate
              isRebateNote: !!rebate.isTermNote,
              rebateNote_En: rebate.termNote_En,
              rebateNote_Fr: rebate.termNote_Fr,
            },
            // combining with FOID rebates,
            ...(rebate.foidRebates?.map((f) => ({
              ...f,
              actualValue: f.value,
              value: f.unit === RebateTypeAndUnitType.Percent ? f.value : 0,
              included: true,
              receivableFrequencyId: FOID_RECEIVABLE_FREQUENCY_ID,
              receivableFrequency: {
                ...new VendorRebateCategoryValueEntity(),
                value_En: t('buyGuide:calculator.table.foid'),
                value_Fr: t('buyGuide:calculator.table.foid'),
              },
            })) ?? []),
            // and payable rebates.
            ...rebate.payableRebates.map((p) => ({
              ...p,
              actualValue: p.value,
              value: p.unit === RebateTypeAndUnitType.Percent ? p.value : 0,
              included: true,
            })),
          ]
        : [],
    [rebate, t],
  );

  // Combine user-added rebates with rebates from rebate program
  useEffect(
    () =>
      setCalculatorRebates((prev) =>
        [...defaultCalculatorRebates, ...prev.filter((d) => d.added)].sort(compareRebates),
      ),
    [defaultCalculatorRebates],
  );

  // Calculate rebate percentage, spliting calculator rebates into respective types
  useEffect(() => {
    const calculateRebates = async () => {
      if (debouncedSelectedProgram?.id) {
        const foids = debouncedCalculatorRebates.filter(
          (d) => d.included && d.receivableFrequencyId === FOID_RECEIVABLE_FREQUENCY_ID,
        );
        const payableRebates = debouncedCalculatorRebates.filter(
          (d) =>
            d.included &&
            d.receivableFrequencyId != null &&
            d.receivableFrequencyId !== TERM_VALUE_RECEIVABLE_FREQUENCY_ID &&
            d.receivableFrequencyId !== FOID_RECEIVABLE_FREQUENCY_ID,
        );
        const term = debouncedCalculatorRebates.find(
          (d) => d.receivableFrequencyId === TERM_VALUE_RECEIVABLE_FREQUENCY_ID,
        );
        const result = await calculate(vendorId, debouncedSelectedProgram?.id, {
          foidRebates: foids,
          payableRebates,
          termValue: term?.value ?? undefined,
        });
        setCalculationResults({
          completeDiscount: result?.completeDiscount ?? 0,
          netPrice: Math.max(debouncedListPrice * (1 - (result?.completeDiscount ?? 0) / 100), 0),
        });
      }
    };
    calculateRebates();
    // SUP-14954 - for performance reasons rebate?.foidRebates has been removed from the list of dependencies.
    // Changing `rebate` triggers updating of `defaultCalculatorRebates`, which then subsequently updates `debouncedCalculatorRebates`
    // which is already present in the list of dependencies
  }, [calculate, debouncedListPrice, debouncedSelectedProgram?.id, debouncedCalculatorRebates, vendorId]);

  const onProgramChange = (event: SelectChangeEvent<number>) => {
    setSelectedProgram(programs.find((p) => p.id === event.target.value));
  };

  const onValueChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setCalculatorRebates((prev) => {
      return prev.map((p) => (p.id === id ? { ...p, value: (e.target.value as unknown as number) || 0 } : p));
    });
  };

  const onReceivableFrequencyChange = (e: SelectChangeEvent<number | null>, id: number) => {
    setCalculatorRebates((prev) => {
      return prev
        .map((p) => (p.id === id ? { ...p, receivableFrequencyId: Number(e.target.value) } : p))
        .sort(compareRebates);
    });
  };

  const onRebateTypeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setCalculatorRebates((prev) => {
      return prev.map((p) =>
        p.id === id
          ? {
              ...p,
              rebateType: {
                ...(p.rebateType ? p.rebateType : new CategorizationEntity()),
                name_En: e.target.value,
              },
            }
          : p,
      );
    });
  };

  const onIncludedChange = (included: boolean, id?: number) => {
    setCalculatorRebates((prev) => {
      return prev.map((p) =>
        p.receivableFrequencyId !== 0 && (id == null || p.id === id)
          ? {
              ...p,
              included,
            }
          : p,
      );
    });
  };

  const priceFormatter = useMemo(
    () =>
      numberFormatter(
        currentLanguage,
        t(`common:rebateTypeAndUnitType.${RebateTypeAndUnitType.Dollar}`),
        DOLLAR_MAX_DECIMALS,
        currentLanguage !== LanguageCode.en,
      ),
    [currentLanguage, t],
  );

  const renderValueColumn = (item: BuyGuideCalculatorRebate) => {
    return (
      <Box display="flex" alignContent="center">
        <Input
          value={item.value}
          formatter={percentageFormatter(currentLanguage)}
          onChange={(e) => onValueChange(e, item.id)}
          sx={style.input}
        />
      </Box>
    );
  };

  const renderReceivableFrequencyColumn = (item: BuyGuideCalculatorRebate) => {
    if (item.added) {
      return (
        <Select
          value={item.receivableFrequencyId}
          options={receivableFrequenciesOptions}
          onChange={(e) => onReceivableFrequencyChange(e, item.id)}
        />
      );
    }
    return (item.receivableFrequency && getTranslation(item.receivableFrequency, 'value')) || '';
  };

  const renderSelectAll = () => {
    const includableItems = calculatorRebates.filter((r) => r.receivableFrequencyId !== 0);
    const allIncluded = includableItems.every((r) => r.included === true);
    const allExcluded = includableItems.every((r) => r.included === false);
    return (
      <Checkbox
        checked={allIncluded}
        indeterminate={!allIncluded && !allExcluded}
        onChange={() => onIncludedChange(!allIncluded)}
        sx={style.input}
      />
    );
  };

  const renderSelectedColumn = (item: BuyGuideCalculatorRebate) =>
    item.receivableFrequencyId !== 0 && (
      <Checkbox
        checked={item.included}
        onChange={(_, checked) => onIncludedChange(checked, item.id)}
        sx={style.input}
      />
    );

  const renderRebateTooltip = (item: BuyGuideCalculatorRebate) => {
    if (!item.isRebateNote && item.actualValue !== null) {
      const formatter = numberFormatter(
        currentLanguage,
        item.unit !== RebateTypeAndUnitType.Custom
          ? t(`common:rebateTypeAndUnitType.${item.unit}`)
          : ` ${getTranslation(item, 'customUnit') ?? item.customUnit}`,
        item.unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
        item.unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
      );
      return formatter(item.actualValue || '').formatted.join('');
    }
    return getTranslation(item, 'rebateNote') || item.rebateNote || '';
  };

  const renderRebateTypeColumn = (item: BuyGuideCalculatorRebate) => {
    if (item.added) {
      return (
        <Input value={item.rebateType?.name_En} onChange={(e) => onRebateTypeChange(e, item.id)} sx={style.input} />
      );
    }
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>{item.rebateType != null && getTranslation(item.rebateType, 'name')}</Box>
        {item.unit && (item.unit !== RebateTypeAndUnitType.Percent || item.isRebateNote) && (
          <Tooltip
            title={
              <Typography variant="tooltip" whiteSpace="pre-wrap">
                {renderRebateTooltip(item)}
              </Typography>
            }
            placement="top"
            arrow
          >
            <WarningRounded htmlColor={palette.grey[500]} />
          </Tooltip>
        )}
      </Stack>
    );
  };

  const onAddClick = () => {
    const id = lastRebateId - 1;
    setLastRebateId(id);
    setCalculatorRebates((prev) =>
      [
        ...prev,
        {
          ...new VendorRebateCategoryRebateValueEntity(),
          receivableFrequencyId: receivableFrequenciesOptions?.[0].value ?? 2,
          value: 0,
          added: true,
          included: true,
          id,
        },
      ].sort(compareRebates),
    );
  };

  const renderCalculationMethod = () => {
    if (!selectedProgram?.rebateCategory.rebate.calculationMethod) {
      return undefined;
    }

    if (selectedProgram.rebateCategory.rebate.calculationMethod?.isCustom) {
      return `${getTranslation(selectedProgram.rebateCategory.rebate.calculationMethod, 'value')} (${getTranslation(
        selectedProgram.rebateCategory.rebate,
        'customMethod',
      )})`;
    }

    return getTranslation(selectedProgram.rebateCategory.rebate.calculationMethod, 'value');
  };

  const renderContent = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          label={t('buyGuide:calculator.rebateProgram')}
          options={programOptions}
          name="name"
          value={selectedProgram?.id}
          onChange={onProgramChange}
        />
      </Grid>
      <Grid item xs={12}>
        <StaticField
          label={t('buyGuide:card.calculationMethod')}
          value={renderCalculationMethod()}
          icon={
            <Tooltip
              title={
                !!selectedProgram && (
                  <Typography variant="tooltip" whiteSpace="pre-wrap">
                    {getTranslation(selectedProgram.rebateCategory.rebate, 'calculationMethodMemberNotes') ||
                      t('buyGuide:card.calculationMethodDefaultMemberNotes')}
                  </Typography>
                )
              }
              placement="top"
              arrow
            >
              <InfoOutlinedIcon sx={style.commentIcon} htmlColor={palette.grey[500]} />
            </Tooltip>
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow="0"
        sx={style.borderedTopContainer}
      >
        <Button variant="outlined" size="small" onClick={() => setCalculatorRebates(defaultCalculatorRebates)}>
          {t('buyGuide:calculator.reset')}
        </Button>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" mr={1}>
            {t('buyGuide:calculator.listPrice')}
          </Typography>
          <Input
            value={listPrice}
            onChange={(e) => {
              setListPrice((e.target.value as unknown as number) || 0);
            }}
            formatter={priceFormatter}
            hideLabel
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head">{renderSelectAll()}</TableCell>
                <TableCell variant="head" width="33%">
                  {t('buyGuide:calculator.table.receivableFrequency')}
                </TableCell>
                <TableCell variant="head" width="33%">
                  {t('buyGuide:calculator.table.type')}
                </TableCell>
                <TableCell variant="head" width="33%">
                  {t('buyGuide:calculator.table.value')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calculatorRebates?.map((item) => (
                <TableRow sx={!item.included ? style.excludedRow : undefined}>
                  <TableCell>{renderSelectedColumn(item)}</TableCell>
                  <TableCell>{renderReceivableFrequencyColumn(item)}</TableCell>
                  <TableCell>{renderRebateTypeColumn(item)}</TableCell>
                  <TableCell>{renderValueColumn(item)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={onAddClick}>
          {t('common:add')}
        </Button>
      </Grid>
      <Grid item xs={12} sx={style.borderedTopContainer} display="flex" justifyContent="space-between">
        <Typography mt={2}>{t('buyGuide:calculator.programCalculation')}</Typography>
        <Typography mt={2}>
          {calculating && <CircularProgress size={12} sx={style.circularProcess} />}
          {percentageFormatter(currentLanguage)(calculationResults.completeDiscount).formatted}
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Typography fontWeight={700}>{t('buyGuide:calculator.calculatedNetPrice')}</Typography>
        <Typography fontWeight={700}>
          {calculating && <CircularProgress size={12} sx={style.circularProcess} />}
          {priceFormatter(calculationResults.netPrice.toFixed(DOLLAR_MAX_DECIMALS)).formatted}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Drawer PaperProps={{ sx: style.paperDrawer }} anchor="right" open={!!selectedProgram} onClose={onClose}>
      <Box sx={[style.container]} component="form">
        <Box sx={[style.box, style.titleBox]} display="flex" alignItems="center">
          <CalculateIcon />
          <Typography variant="drawerTitle">{t('buyGuide:calculator.title')}</Typography>
        </Box>
        <Box sx={style.contentContainer}>{selectedProgram && rebate ? renderContent() : <Loading />}</Box>
        <Stack spacing={3} direction="row" sx={[style.box, style.actionBox]} justifyContent="space-between">
          <Button onClick={onClose} variant="outlined" color="secondary" size="small">
            {t('common:close')}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};
