import { PropsWithChildren, useMemo } from 'react';
import useAuth from '../../hooks/useAuth';
import { PermissionProps } from '../../types';

interface HasPermissionProps extends PropsWithChildren, PermissionProps {}

export function HasPermissions({ children, keys, any }: HasPermissionProps) {
  const { hasPermissions } = useAuth();

  const userHasPermissions = useMemo(() => !keys || hasPermissions(keys, any), [hasPermissions, keys, any]);

  if (!userHasPermissions) {
    return null;
  }

  return children as React.ReactElement;
}
