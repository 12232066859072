import React from 'react';
import { StaticPageEntity } from '../../models';

export interface CmsStaticPageContextType {
  fetchData: () => Promise<void>;
  staticPage: StaticPageEntity | null;
  canEdit: boolean;
  isLoading: boolean;
}

export const CmsStaticPageContext = React.createContext<CmsStaticPageContextType>({} as CmsStaticPageContextType);
