import React, { useEffect } from 'react';
import { setting as settingApi } from '../api';
import { SettingEntity } from '../models';
import { useApi } from './useApi';

interface SettingsContextType {
  settings: SettingEntity | null;
  refresh: () => Promise<void>;
}

const SettingsContext = React.createContext<SettingsContextType | null>(null);

function useSettings() {
  const [settings, setSettings] = React.useState<SettingEntity | null>(null);
  const { data, refresh } = useApi(settingApi.get, { callImmediately: true });

  useEffect(() => {
    setSettings(data);
  }, [data]);

  return {
    settings,
    refresh,
  };
}

export function SettingsProvider({ children }: { children: React.ReactNode }) {
  const settings = useSettings();

  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
}

export default function SettingsConsumer(): SettingsContextType {
  return React.useContext(SettingsContext) as SettingsContextType;
}
