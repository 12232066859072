import { useContext, useState } from 'react';
import { vendor as vendorApi } from '../../../../api';
import { VendorBuyingAgreementRebateCategoryDetailContext } from '../../../../contexts/Vendor';
import { useApi, useInternationalization } from '../../../../hooks';
import { VendorBuyingAgreementTierGrowthEntity, VendorTierGrowthEntity } from '../../../../models';
import { VendorTierGrowthSchema } from '../../../../schemas';
import { GrowthOrTierOption } from '../../../../types';
import { LayoutPage } from '../../../Layout';
import { MenuItem } from '../../../Menu';
import { DeleteMenuItem } from '../../../Menu/DeleteMenuItem';
import { VendorTierGrowthList, VendorTiersGrowthsDrawer, VendorTiersGrowthsSelect } from '../../TiersGrowths';

const saveOption = { successKey: 'common:success.save' };

export const VendorBuyingAgreementRebateCategoryTiersGrowths = () => {
  const { t, getTranslation, setProperty } = useInternationalization();
  const { vendorId, rebateCategory, vendorBuyingAgreementId, readOnly } = useContext(
    VendorBuyingAgreementRebateCategoryDetailContext,
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditingOrAddingGrowth, setIsEditingOrAddingGrowth] = useState(false);
  const [tierOrGrowth, setTierOrGrowth] = useState<VendorTierGrowthEntity>(new VendorTierGrowthEntity());
  const {
    data: tiers,
    isLoading: isTierLoading,
    refresh: refreshTiers,
  } = useApi(
    vendorApi.getAllBuyingAgreementRebateCategoryTier,
    null,
    vendorId,
    vendorBuyingAgreementId,
    rebateCategory.id,
  );
  const {
    data: growths,
    isLoading: isGrowthsLoading,
    refresh: refreshGrowths,
  } = useApi(
    vendorApi.getAllBuyingAgreementRebateCategoryGrowth,
    null,
    vendorId,
    vendorBuyingAgreementId,
    rebateCategory.id,
  );

  const { call: associateTier } = useApi(vendorApi.associateBuyingAgreementTier, saveOption);
  const { call: associateGrowth } = useApi(vendorApi.associateBuyingAgreementGrowth, saveOption);
  const { call: disassociateGrowth } = useApi(vendorApi.disassociateBuyingAgreementGrowth, saveOption);
  const { call: disassociateTier } = useApi(vendorApi.disassociateBuyingAgreementTier, saveOption);
  const { call: deleteGrowth } = useApi(vendorApi.deleteBuyingAgreementGrowth, saveOption);
  const { call: deleteTier } = useApi(vendorApi.deleteBuyingAgreementTier, saveOption);
  const { call: createOrUpdateTier } = useApi(
    tierOrGrowth.id ? vendorApi.updateBuyingAgreementTier : vendorApi.createBuyingAgreementTier,
    saveOption,
  );
  const { call: createOrUpdateGrowth } = useApi(
    tierOrGrowth.id ? vendorApi.updateBuyingAgreementGrowth : vendorApi.createBuyingAgreementGrowth,
    saveOption,
  );

  const onDeleteConfirm = async (entity: VendorBuyingAgreementTierGrowthEntity, isGrowthSelected: boolean) => {
    if (entity) {
      let removeGrowthOrTier = isGrowthSelected ? disassociateGrowth : disassociateTier;
      if (entity.isVBAEditable) {
        removeGrowthOrTier = isGrowthSelected ? deleteGrowth : deleteTier;
      }
      await removeGrowthOrTier(vendorId, vendorBuyingAgreementId, rebateCategory.id, entity.id);
      await (isGrowthSelected ? refreshGrowths() : refreshTiers());
    }
  };

  const renderActionsMenuItems = (
    entity: VendorBuyingAgreementTierGrowthEntity,
    onClick: () => void,
    isGrowthSelected: boolean,
  ) => [
    ...(entity.isVBAEditable
      ? [
          <MenuItem
            id="edit"
            key="edit"
            onClick={() => {
              setIsEditingOrAddingGrowth(isGrowthSelected);
              setTierOrGrowth(entity);
              setIsDrawerOpen(true);
              onClick();
            }}
          >
            {t('common:edit')}
          </MenuItem>,
        ]
      : []),
    <DeleteMenuItem
      key="delete"
      entity={entity}
      name={getTranslation(entity, 'name')}
      onClick={onClick}
      onDeleteConfirm={(entity) => onDeleteConfirm(entity, isGrowthSelected)}
      actionSuffix={t(`vendor:rebateCategory.tierAndGrowth.actions.suffix.${isGrowthSelected ? 'growth' : 'tier'}`)}
    />,
  ];

  const onAssociateTierOrGrowth = async (item: GrowthOrTierOption) => {
    const addGrowthOrTier = item.type === 'growth' ? associateGrowth : associateTier;
    await addGrowthOrTier(vendorId, vendorBuyingAgreementId, rebateCategory.id, item.id);
    await (item.type === 'growth' ? refreshGrowths() : refreshTiers());
  };

  const saveDrawer = async (entity: VendorTierGrowthEntity) => {
    const createOrUpdateGrowthOrTier = isEditingOrAddingGrowth ? createOrUpdateGrowth : createOrUpdateTier;
    const savedTierOrGrowth = await createOrUpdateGrowthOrTier(
      vendorId,
      vendorBuyingAgreementId,
      rebateCategory.id,
      entity,
    );
    if (savedTierOrGrowth) {
      setIsEditingOrAddingGrowth(false);
      setTierOrGrowth(new VendorTierGrowthEntity());
    }
    return savedTierOrGrowth;
  };

  const cancelDrawer = () => {
    setIsDrawerOpen(false);
    setIsEditingOrAddingGrowth(false);
    setTierOrGrowth(new VendorTierGrowthEntity());
  };

  const confirmDrawer = () => {
    isEditingOrAddingGrowth ? refreshGrowths() : refreshTiers();
    setIsEditingOrAddingGrowth(false);
    setIsDrawerOpen(false);
  };

  const onCreateTierOrGrowth = (text: string) => {
    const newVendorTierGrowthEntity = new VendorTierGrowthEntity();
    setProperty(newVendorTierGrowthEntity, 'name', text);
    setTierOrGrowth(newVendorTierGrowthEntity);
    setIsEditingOrAddingGrowth(false);
    setIsDrawerOpen(true);
  };

  return (
    <LayoutPage title={t('vendor:rebateCategory.tierAndGrowth.title')} display="Tab">
      {!readOnly && (
        <VendorTiersGrowthsSelect
          currentGrowths={growths}
          currentTiers={tiers}
          onAssociateTierOrGrowth={onAssociateTierOrGrowth}
          vendorId={vendorId}
          onCreateTierOrGrowth={onCreateTierOrGrowth}
        />
      )}
      <VendorTierGrowthList
        isLoading={isTierLoading || isGrowthsLoading}
        tiers={tiers}
        growths={growths}
        actionMenuItems={!readOnly ? renderActionsMenuItems : undefined}
      />
      <VendorTiersGrowthsDrawer
        open={isDrawerOpen}
        entity={tierOrGrowth}
        schema={VendorTierGrowthSchema(isEditingOrAddingGrowth)}
        onSave={saveDrawer}
        onConfirm={confirmDrawer}
        onCancel={cancelDrawer}
        context={{ setIsEditingOrAddingGrowth, isEditingOrAddingGrowth, tierOrGrowth, setTierOrGrowth }}
      />
    </LayoutPage>
  );
};
