import { date, mixed, object, ref, string } from 'yup';
import { MAX_FILE_SIZE_MB, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { MemberCommitmentDocumentEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { mbToBytes } from '../../utils/file';
import { isDateValid } from '../../utils/helper';

export const MemberCommitmentDocumentSchema = (): EntityFormSchema<MemberCommitmentDocumentEntity> => ({
  schema: object().shape(
    {
      description: string().required().max(MAX_SHORT_TEXT_LENGTH),
      notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      type: string().required(),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .nullable()
        .notRequired()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      file: mixed()
        .required()
        .test('max-size', 'common:error.arrayMaxItemSize', (file) =>
          file ? file.size < mbToBytes(MAX_FILE_SIZE_MB) : true,
        ),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
