import { Box, Grid } from '@mui/material';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../../../constants';
import { useEntityFormContext, useInternationalization } from '../../../../hooks';
import { VendorBuyingAgreementBuyOpportunityEntity } from '../../../../models';
import { Styles } from '../../../../types';
import { ControlledTranslatedInput } from '../../../Form';

const style: Styles = {
  container: {
    py: 1,
  },
};

export const VendorBuyingAgreementBuyOpportunityDetailsForm = () => {
  const { t } = useInternationalization();
  const { control } = useEntityFormContext<VendorBuyingAgreementBuyOpportunityEntity>();

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.shippingDate')}
            name="shippingDate"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.foid')}
            name="foid"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.termValue')}
            name="terms"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.paymentTermDiscount')}
            name="paymentTermDiscount"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.minOrder')}
            name="minimumOrder"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.minOrderQuantity')}
            name="minimumOrderQuantity"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.minFreightPrepaid')}
            name="minimumFreightPrepaid"
            control={control}
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('vendor:buyOpportunities.form.description')}
            name="description"
            control={control}
            multiline
            rows={5}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
