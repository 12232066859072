import { Link, ListItemIcon, ListItemText, MenuItem, Tooltip, TypographyProps } from '@mui/material';
import { HTMLAttributeAnchorTarget, PropsWithChildren, useContext, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { NavigationMenuContext } from '../../contexts';
import useAuth from '../../hooks/useAuth';
import { palette } from '../../styles/palette';
import { PermissionProps, Styles } from '../../types';

const baseIconStyle: Styles = {
  '.navigation-side.navigation-collapsed & svg, .navigation-mobile & svg': {
    '& path': {
      fill: palette.white,
    },
    color: palette.white,
  },
  '.navigation-user & svg, .navigation-side.navigation-expanded & svg': {
    '& path': {
      fill: palette.secondary.main,
      transition: 'fill 500ms ease',
    },
    color: palette.secondary.main,
  },
  '.navigation-user &.Mui-selected svg, .navigation-side.navigation-expanded &.Mui-selected svg': {
    '& path': {
      fill: palette.white,
    },
    color: palette.white,
  },
  '.navigation-side.navigation-expanded &.Mui-selected svg': {
    '& path': {
      fill: palette.white,
    },
    color: palette.white,
  },
};

const style: Styles = {
  listItemIcon: {
    minWidth: '0 !important',
    p: 1,
    borderRadius: 2,
  },
  listItemIconSideExpanded: {
    minWidth: '0 !important',
    p: 1,
    borderRadius: 2,
    '& svg': {
      maxHeight: '24px',
    },
  },
  listItemIconSideCollapsed: {
    minWidth: '0 !important',
    p: 1,
    borderRadius: 2,
    '.MuiMenuItem-root:hover &, .Mui-selected &': {
      backgroundColor: palette.primary.dark,
    },
  },
  listItemTextSideCollapsed: {
    color: palette.white,
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: 0,
    letterSpacing: 0,
    pr: 0,
    width: 0,
  },
  listItemTextSideExpanded: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    color: palette.grey[600],
    pl: 1,
    pr: 3,
    width: 'auto',
    '.Mui-selected &': {
      color: palette.white,
    },
  },
  listItemTextSmall: {
    fontSize: '12px',
  },
  listItemTextStatic: {
    whiteSpace: 'nowrap',
    '.navigation-side.navigation-expanded &': {
      color: palette.grey[400],
      opacity: 1,
      pl: 0,
      width: 'auto',
    },
  },
  menuItem: {
    backgroundColor: 'transparent',
    color: 'primary.contrastText',
    pt: 1.5,
    px: 3,
    transition: '250ms all ease',
    ...baseIconStyle,
    '&.Mui-selected': {
      backgroundColor: 'primary.main',
      borderColor: 'primary.main',
      color: palette.white,
    },
    '.navigation-side &': {
      flexDirection: 'row',
      mx: 0,
      overflow: 'hidden',
      px: 1,
      py: 0,
      whiteSpace: 'normal',
      borderRadius: 2,
    },
    '.navigation-side.navigation-expanded &': {
      mx: 1,
      '& .MuiListItemIcon-root': {
        pl: 0,
      },
    },
    '.navigation-mobile &': {
      minHeight: 0,
      mx: 0,
      my: 0,
      px: 2,
      py: 0.5,
    },
    '.navigation-user &': {
      minHeight: 0,
      mx: 0,
      my: 0,
      px: 2,
      py: 0.5,
    },
    '&.Mui-selected:hover, &.Mui-selected:focus': {
      backgroundColor: 'secondary.dark',
    },
  },
  menuItemStatic: {
    backgroundColor: 'transparent',
    color: 'primary.contrastText',
    mx: 2.5,
    pt: 1.5,
    px: 3,
    pointerEvents: 'none',
    transition: '250ms all ease',
    ...baseIconStyle,
    '&.Mui-selected': {
      backgroundColor: 'secondary.dark',
      borderColor: 'secondary.main',
      color: palette.white,
    },
    '.navigation-side &': {
      flexDirection: 'row',
      mx: 0,
      overflow: 'hidden',
      px: 1,
      py: 0.5,
      whiteSpace: 'normal',
    },
    '.navigation-side.navigation-expanded &': {
      px: 2.5,
    },
    '.navigation-mobile &': {
      minHeight: 0,
      mx: 0,
      my: 0,
      px: 2,
      py: 0.5,
    },
    '.navigation-user &': {
      minHeight: 0,
      mx: 0,
      my: 0,
      px: 2,
      py: 2,
    },
    '&.Mui-selected:hover, &.Mui-selected:focus': {
      backgroundColor: 'secondary.dark',
    },
    '.navigation-side.navigation-collapsed &': {
      height: 0,
      opacity: 0,
      py: 0,
      transition: '250ms all ease',
    },
  },
  menuItemSideCollapsed: {
    backgroundColor: 'transparent',
    color: 'primary.contrastText',
    mx: 1,
    pt: 1.5,
    px: 3,
    transition: '250ms all ease',
    ...baseIconStyle,
    '.navigation-side &': {
      flexDirection: 'row',
      mx: 0,
      overflow: 'hidden',
      px: 1,
      py: 0,
      whiteSpace: 'normal',
    },
    '.navigation-side.navigation-expanded &': {
      px: 2.5,
    },
    '.navigation-mobile &': {
      minHeight: 0,
      mx: 0,
      my: 0,
      px: 2,
      py: 0.75,
    },
    '.navigation-user &': {
      minHeight: 0,
      mx: 0,
      my: 0,
      px: 2,
      py: 2,
    },
    '& path': {
      fill: palette.white,
    },
  },
};

export interface NavigationMenuItemProps extends PropsWithChildren {
  disabled?: boolean;
  href?: string;
  icon?: React.ReactNode;
  matchPath?: string | string[];
  onClick?: React.MouseEventHandler;
  sx?: Styles[0];
  textAlign?: TypographyProps['textAlign'];
  title?: string;
  variant?: 'small' | 'normal';
  permissions?: PermissionProps;
  target?: HTMLAttributeAnchorTarget;
}

export const NavigationMenuItem = ({
  children,
  disabled,
  href,
  icon,
  matchPath: matchPathValue,
  onClick,
  sx,
  textAlign,
  title,
  variant,
  permissions,
  target,
}: NavigationMenuItemProps) => {
  const location = useLocation();
  const { hasPermissions } = useAuth();
  const { variant: menuVariant, expanded } = useContext(NavigationMenuContext);

  const isMatch = useMemo(() => {
    if (Array.isArray(matchPathValue)) {
      return matchPathValue.some((path) => !!matchPath({ path, end: true }, location.pathname));
    } else if (typeof matchPathValue === 'string') {
      return !!matchPath({ path: matchPathValue, end: true }, location.pathname);
    }

    return !!matchPath({ path: href || '', end: true }, location.pathname);
  }, [matchPathValue, href, location]);

  const isMenuItemVisible = useMemo(
    () => !permissions?.keys || hasPermissions(permissions.keys, permissions.any),
    [hasPermissions, permissions],
  );

  if (!isMenuItemVisible) {
    return null;
  }

  const isClickable = href || onClick || icon;

  return (
    <Tooltip arrow title={menuVariant === 'side' && !expanded ? title : ''}>
      <MenuItem
        component={href ? Link : 'a'}
        disabled={disabled}
        href={href}
        onClick={onClick}
        selected={isMatch}
        target={target}
        sx={{
          ...(!isClickable
            ? style.menuItemStatic
            : menuVariant === 'side' && !expanded
            ? style.menuItemSideCollapsed
            : style.menuItem),
          ...sx,
        }}
      >
        {!!icon && (
          <ListItemIcon
            color={isMatch ? 'secondary.main' : 'primary.contrastText'}
            sx={
              menuVariant === 'side'
                ? expanded
                  ? style.listItemIconSideExpanded
                  : style.listItemIconSideCollapsed
                : style.listItemIconSideExpanded
            }
          >
            {icon}
          </ListItemIcon>
        )}
        {!children && (
          <ListItemText
            disableTypography
            sx={{
              ...(expanded ? style.listItemTextSideExpanded : style.listItemTextSideCollapsed),
              textAlign,
              ...(variant === 'small' ? style.listItemTextSmall : {}),
              ...(!isClickable ? style.listItemTextStatic : {}),
            }}
          >
            {title}
          </ListItemText>
        )}
        {children}
      </MenuItem>
    </Tooltip>
  );
};
