import { CircularProgress } from '@mui/material';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { EntityDetails } from '..';
import { vendor as vendorApi } from '../../api';
import { BuyGuideVendorBuyOpportunityDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorBuyOpportunityDetailContext';
import { useApi, useInternationalization, usePageTitle } from '../../hooks';
import { routes } from '../../routes';
import { PermissionKey, Styles } from '../../types';
import { Container } from '../Container';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';
import { BuyGuideVendorBuyOpportunityForm } from './BuyGuideVendorBuyOpportunityForm';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const BuyGuideVendorBuyOpportunityDetail = () => {
  const { t, getTranslation, getTranslatablePropertyKey } = useInternationalization();
  const outlet = useOutlet();
  const params = useParams();
  const vendorId = Number(params.vendorId);
  const { data: vendor } = useApi(vendorApi.get, null, vendorId);
  const buyOpportunityId = Number(params.vendorBuyOpportunityId);
  const { data, isLoading } = useApi(vendorApi.getBuyOpportunity, null, vendorId, buyOpportunityId);

  usePageTitle(data ? getTranslation(data, 'name') : '');

  const tabs = [
    <LinkTab
      label={t('buyGuide:buyOpportunities.sections.details')}
      value={generatePath(routes.Member.BuyGuideBuyOpportunityDetail.Details.path, {
        vendorId,
        vendorBuyOpportunityId: buyOpportunityId,
      })}
      permissions={{ keys: PermissionKey.VendorViewBuys }}
    />,
    <LinkTab
      label={t('buyGuide:buyOpportunities.sections.documents')}
      value={generatePath(routes.Member.BuyGuideBuyOpportunityDetail.Documents.path, {
        vendorId,
        vendorBuyOpportunityId: buyOpportunityId,
      })}
      permissions={{ keys: PermissionKey.VendorViewBuys }}
    />,
  ];

  return (
    <BuyGuideVendorBuyOpportunityDetailContext.Provider value={{ buyOpportunityId, vendorId, buyOpportunity: data }}>
      <LayoutPage outlet={outlet} display="Detail" tabs={tabs} permissions={{ keys: PermissionKey.VendorViewBuys }}>
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EntityDetails
              titleLabel={t(`buyGuide:buyOpportunities.form.name`)}
              title={getTranslatablePropertyKey(data, 'name')}
              entity={data}
              breadcrumbs={[
                {
                  title: t('buyGuide:title'),
                  href: routes.Member.BuyGuide.path,
                },
                {
                  title: vendor?.name,
                  href: generatePath(routes.Member.BuyGuideDetail.BuyOpportunities.path, { vendorId }),
                },
                { title: data ? getTranslation(data, 'name') : undefined },
              ]}
            >
              <BuyGuideVendorBuyOpportunityForm />
            </EntityDetails>
          )}
        </Container>
      </LayoutPage>
    </BuyGuideVendorBuyOpportunityDetailContext.Provider>
  );
};
