import { OpportunityType } from '../../types';
import { FileEntity } from '../FileEntity';
import { PeriodEntity } from '../PeriodEntity';
import { TargetedMembershipEntity } from '../TargetedMembership';
import { VendorBuyingAgreementEntity } from './VendorBuyingAgreementEntity';

export class BuyOpportunityEntity extends PeriodEntity {
  vendorBuyingAgreementId: number | null;
  vendorBuyingAgreement: VendorBuyingAgreementEntity;
  name_En: string;
  name_Fr: string;
  opportunityType: OpportunityType | null;
  responsibleBuyerId: number | null;
  responsibleBuyer: string;
  shippingDate_En: string;
  shippingDate_Fr: string;
  foid_En: string;
  foid_Fr: string;
  terms_En: string;
  terms_Fr: string;
  paymentTermDiscount_En: string;
  paymentTermDiscount_Fr: string;
  minimumOrder_En: string;
  minimumOrder_Fr: string;
  minimumOrderQuantity_En: string;
  minimumOrderQuantity_Fr: string;
  minimumFreightPrepaid_En: string;
  minimumFreightPrepaid_Fr: string;
  description_En: string;
  description_Fr: string;
  isVisibleToMember: boolean;
  memberNotes_Fr: string;
  memberNotes_En: string;
  notes: string;
  vendorNotes: string;
  targetedMembership: TargetedMembershipEntity;
  isArchived: boolean;
  files: FileEntity[];
  filesCount: number;

  static readonly MAX_FILES_COUNT = 10;

  constructor(entity?: BuyOpportunityEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.vendorBuyingAgreementId = null;
      this.name_En = '';
      this.name_Fr = '';
      this.opportunityType = null;
      this.responsibleBuyerId = null;
      this.responsibleBuyer = '';
      this.shippingDate_En = '';
      this.shippingDate_Fr = '';
      this.foid_En = '';
      this.foid_Fr = '';
      this.terms_En = '';
      this.terms_Fr = '';
      this.paymentTermDiscount_En = '';
      this.paymentTermDiscount_Fr = '';
      this.minimumOrder_En = '';
      this.minimumOrder_Fr = '';
      this.minimumOrderQuantity_En = '';
      this.minimumOrderQuantity_Fr = '';
      this.minimumFreightPrepaid_En = '';
      this.minimumFreightPrepaid_Fr = '';
      this.description_En = '';
      this.description_Fr = '';
      this.isVisibleToMember = false;
      this.memberNotes_Fr = '';
      this.memberNotes_En = '';
      this.notes = '';
      this.vendorNotes = '';
      this.isArchived = false;
      this.files = [];
      this.filesCount = 0;
    }
  }

  getFilesDiff(entity: BuyOpportunityEntity) {
    const filesToDelete = this.files?.filter((existingFile) => {
      const replacedFile = entity.files?.find((newFile) => newFile.name === existingFile.name);
      return !replacedFile || replacedFile.id !== existingFile.id;
    });

    return {
      added: entity.files?.filter((f) => !f.id) ?? [],
      removed: filesToDelete.map((f) => f.id ?? 0) ?? [],
    };
  }
}
