import { array, object, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';

export const EmailAddressSchema = () => ({
  schema: object({
    emailAddresses: array().of(
      object({
        email: string().required().max(MAX_SHORT_TEXT_LENGTH).email(),
        description: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
        description_En: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
        description_Fr: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
      }),
    ),
  }),
});
