import { array, date, number, object, ref, string } from 'yup';
import { MAX_FILE_SIZE_MB, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorBuyingAgreementEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema } from '../../types';
import { mbToBytes } from '../../utils/file';
import { isDateValid } from '../../utils/helper';

export const VendorBuyingAgreementSchema = (): EntityFormSchema<VendorBuyingAgreementEntity> => ({
  schema: object().shape(
    {
      name: string().required().max(MAX_SHORT_TEXT_LENGTH),
      responsibleBuyerId: number().typeError('common:error.fieldRequired').required(),
      vendorRepresentative: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .required()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      files: array()
        .max(20)
        .test('max-size', 'common:error.arrayMaxItemSize', (files) =>
          files ? files.every((f) => f.size < mbToBytes(MAX_FILE_SIZE_MB)) : true,
        ),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {
    [ApiErrorCodes.VENDOR_BUYING_AGREEMENT_NAME_NOT_UNIQUE]: { key: 'name', message: 'common:error.fieldUnique' },
  },
});
