import React from 'react';
import { MemberGroupEntity } from '../../models';

interface MemberGroupDetailContextType {
  memberGroup: MemberGroupEntity | null;
  memberGroupId: number;
}

export const MemberGroupDetailContext = React.createContext<MemberGroupDetailContextType>(
  {} as MemberGroupDetailContextType,
);
