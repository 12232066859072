import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { ControlledInput } from '../components/Form';
import { LayoutPublic } from '../components/Layout';
import { useAuth, useInternationalization, usePageTitle } from '../hooks';
import { ApiErrorCodes, CodeVerificationRequest, Styles } from '../types';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';

const style: Styles = {
  button: {
    width: '100%',
  },
};

export function VerifyCode() {
  const { t } = useInternationalization();
  usePageTitle('auth:verifyCode.title');
  const [errorKey, setErrorKey] = useState('');
  const navigate = useNavigate();
  const { verifyCode, userVerifyCode, resendCode } = useAuth();

  const validationLogin = Yup.object({
    code: Yup.string().required(t('common:error.fieldRequired', { name: t('auth:verifyCode.code') })),
  });

  const { handleSubmit, control, formState, reset } = useForm<CodeVerificationRequest>({
    defaultValues: {
      username: userVerifyCode?.username,
      code: '',
      sessionToken: userVerifyCode?.sessionToken,
    },
    resolver: yupResolver(validationLogin),
  });

  const onSubmit = async (codeVerification: CodeVerificationRequest) => {
    setErrorKey('');
    const response = await verifyCode(codeVerification);
    if (response === ApiErrorCodes.USER_CREDENTIALS_CODE_MISMATCH) {
      setErrorKey(t('auth:verifyCode.invalidCode'));
    } else if (response === ApiErrorCodes.USER_CREDENTIALS_NOT_AUTHORIZED) {
      navigate(routes.Login.path);
    } else if (!response) {
      setErrorKey(t('common:error.generic'));
    }
    reset();
  };

  const onResendCodeClick = async () => await resendCode();

  return (
    <LayoutPublic title={t('auth:verifyCode.title')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <ControlledInput label={t('auth:verifyCode.code')} name="code" control={control} autoFocus />
          <Button onClick={onResendCodeClick}>{t('auth:verifyCode.resendCode')}</Button>
          <LoadingButton sx={style.button} loading={formState.isSubmitting} type="submit" variant="contained">
            {t('auth:verifyCode.action')}
          </LoadingButton>
          {!!errorKey && <Typography variant="inputError">{t(errorKey)}</Typography>}
        </Stack>
      </form>
    </LayoutPublic>
  );
}
