export class ForestProductPriceUpdateHistoryEntity {
  id: number;
  price: number;
  date: Date;

  constructor(entity?: ForestProductPriceUpdateHistoryEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
      this.date = new Date(entity.date);
      this.id = this.date.getTime();
    } else {
      this.id = 0;
      this.price = 0;
      this.date = new Date();
    }
  }
}
