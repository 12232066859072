import { ErrorBoundary, RequireAuth } from '../components';
import { LayoutVendorUser } from '../components/Layout';
import {
  BuyingAgreement,
  BuyingAgreementBuyOpportunities,
  BuyingAgreementBuyOpportunity,
  BuyingAgreementBuyOpportunityDetails,
  BuyingAgreementBuyOpportunityDocuments,
  BuyingAgreementBuyOpportunityPermissions,
  BuyingAgreementDocuments,
  BuyingAgreementGeneralConditions,
  BuyingAgreementRebateCategories,
  BuyingAgreementRebateCategory,
  BuyingAgreementRebateCategoryPermissions,
  BuyingAgreementRebateCategoryRebates,
  BuyingAgreementRebateCategoryTiersGrowths,
  BuyingAgreements,
  Cms,
  CmsPublicationDetailContent,
  CmsPublicationDetails,
  CmsPublications,
  CmsPublicationTarget,
  Contacts,
  Dashboard,
  Documents,
  Financials,
  HelpDetail,
  HelpLayout,
  Locations,
  Member,
  MemberContacts,
  Members,
  ProfileLayout,
  Search,
  TBMContactInternalContactDetail,
  TBMContactInternalContacts,
  TBMContactOfficeDetail,
  TBMContactOfficeInternalContacts,
  TBMContactOffices,
  TBMContacts,
  VendorCommitmentLayout,
  VendorCommitments,
} from '../pages/Vendor';
import { UserType } from '../types';

export const VendorRoutes = {
  path: '/vendor',
  errorElement: <ErrorBoundary />,
  element: (
    <RequireAuth userType={UserType.Vendor}>
      <LayoutVendorUser />
    </RequireAuth>
  ),
  Dashboard: {
    path: 'dashboard',
    element: <Dashboard />,
  },
  BuyingAgreements: {
    path: 'buying-agreements',
    element: <BuyingAgreements />,
  },
  BuyingAgreement: {
    path: 'buying-agreements/:vendorBuyingAgreementId',
    element: <BuyingAgreement />,
    Documents: {
      path: 'documents',
      element: <BuyingAgreementDocuments />,
    },
    BuyOpportunities: {
      path: 'buy-opportunities',
      element: <BuyingAgreementBuyOpportunities />,
    },
    GeneralConditions: {
      path: 'general-conditions',
      element: <BuyingAgreementGeneralConditions />,
    },
    RebateCategories: {
      path: 'rebate-categories',
      element: <BuyingAgreementRebateCategories />,
    },
  },
  BuyingAgreementBuyOpportunity: {
    path: 'buying-agreements/:vendorBuyingAgreementId/buy-opportunities/:buyOpportunityId',
    element: <BuyingAgreementBuyOpportunity />,
    Permissions: {
      path: 'permissions',
      element: <BuyingAgreementBuyOpportunityPermissions />,
    },
    Documents: {
      path: 'documents',
      element: <BuyingAgreementBuyOpportunityDocuments />,
    },
    Details: {
      path: 'details',
      element: <BuyingAgreementBuyOpportunityDetails />,
    },
  },
  BuyingAgreementRebateCategory: {
    path: 'buying-agreements/:vendorBuyingAgreementId/rebate-categories/:vendorRebateCategoryId',
    element: <BuyingAgreementRebateCategory />,
    Permissions: {
      path: 'permissions',
      element: <BuyingAgreementRebateCategoryPermissions />,
    },
    TiersAndGrowths: {
      path: 'tiers-growths',
      element: <BuyingAgreementRebateCategoryTiersGrowths />,
    },
    Rebates: {
      path: 'rebates',
      element: <BuyingAgreementRebateCategoryRebates />,
    },
  },
  Contacts: {
    path: 'contacts',
    element: <Contacts />,
  },
  Locations: {
    path: 'locations',
    element: <Locations />,
  },
  Financials: {
    path: 'financials',
    element: <Financials />,
  },
  Documents: {
    path: 'documents',
    element: <Documents />,
  },
  Member: {
    path: 'members',
    element: <Members />,
    Detail: {
      path: ':memberId',
      element: <Member />,
      Contacts: {
        path: 'contacts',
        element: <MemberContacts />,
      },
    },
  },
  TBMContacts: {
    path: 'tbm-contacts',
    element: <TBMContacts />,
    InternalContacts: {
      path: 'contacts',
      element: <TBMContactInternalContacts />,
    },
    Offices: {
      path: 'offices',
      element: <TBMContactOffices />,
    },
  },
  OfficeDetails: {
    path: 'tbm-contacts/offices/:officeId',
    element: <TBMContactOfficeDetail />,
    InternalContacts: {
      path: 'contacts',
      element: <TBMContactOfficeInternalContacts />,
    },
  },
  InternalContactDetails: {
    path: 'tbm-contacts/contacts/:contactId',
    element: <TBMContactInternalContactDetail />,
  },
  Profile: {
    path: 'profile',
    element: <ProfileLayout />,
  },
  Help: {
    path: 'help',
    element: <HelpLayout />,
    Detail: {
      path: ':helpTrainingId',
      element: <HelpDetail />,
    },
  },
  Cms: {
    path: 'content-publications',
    element: <Cms />,
    Publications: {
      path: 'publications',
      element: <CmsPublications />,
    },
  },
  PublicationDetails: {
    path: 'content-publications/publications/:publicationId',
    element: <CmsPublicationDetails />,
    Content: {
      path: 'content',
      element: <CmsPublicationDetailContent />,
    },
    Targeting: {
      path: 'targeting',
      element: <CmsPublicationTarget />,
    },
  },
  Cci: {
    path: 'cci',
    element: <VendorCommitmentLayout />,
    Commitments: {
      path: 'commitments',
      element: <VendorCommitments />,
    },
  },
  Search: {
    path: 'search',
    element: <Search />,
  },
};
