import { SubdirectoryArrowRightRounded } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, generatePath, useOutlet } from 'react-router-dom';
import { EditDetails } from '../..';
import { vendor as vendorApi } from '../../../api';
import { VendorBuyingAgreementContext } from '../../../contexts/Vendor';
import { useApi, useAuth, useConfirmationModal, usePageTitle } from '../../../hooks';
import { VendorBuyingAgreementEntity } from '../../../models';
import { routes } from '../../../routes';
import { VendorBuyingAgreementSchema } from '../../../schemas';
import {
  BaseComponentDetailProps,
  PermissionKey,
  Styles,
  VendorBuyingAgreementStatus,
  VendorBuyingAgreementType,
} from '../../../types';
import { Container } from '../../Container';
import { HasPermissions } from '../../Permission';
import { LinkTab } from '../../Tab';
import { VendorBuyingAgreementChangeLogs } from './ChangeLog/VendorBuyingAgreementChangeLogs';
import { VendorBuyingAgreementExportMenuItem } from './VendorBuyingAgreementExportMenuItem';
import { VendorBuyingAgreementForm } from './VendorBuyingAgreementForm';
import { VendorBuyingAgreementHeader } from './VendorBuyingAgreementHeader';
import { LayoutPage } from '../../Layout';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

interface VendorBuyingAgreementDetailProps extends BaseComponentDetailProps<VendorBuyingAgreementEntity> {
  workflow?: React.ReactElement;
  vendorId?: number;
  vendorBuyingAgreementId: number;
}

export const VendorBuyingAgreementDetail = ({
  fetchApi,
  onSave,
  actionMenuItems,
  workflow,
  breadcrumbs,
  readOnly,
  vendorId,
  vendorBuyingAgreementId,
}: VendorBuyingAgreementDetailProps) => {
  const { isInternalUser, user, hasPermissions } = useAuth();
  const outlet = useOutlet();
  const { t } = useTranslation();
  const { isLoading, data, refresh } = fetchApi;
  const { openModal } = useConfirmationModal();
  const { call: transfer } = useApi(vendorApi.transferBuyingAgreement, { successKey: 'common:success.save' });
  usePageTitle(data?.name ?? '');

  const canEdit = hasPermissions(PermissionKey.VendorEditVBAs);

  const baseRoute = isInternalUser ? routes.Admin.Vendor.BuyingAgreement : routes.Vendor.BuyingAgreement;

  const getGeneratedPath = (path: string) => generatePath(path, { vendorId, vendorBuyingAgreementId });

  const renderActionMenuItems = (onClick: () => void) => [
    ...(actionMenuItems ? actionMenuItems(onClick) : []),
    ...(data?.type == VendorBuyingAgreementType.InApp
      ? [<VendorBuyingAgreementExportMenuItem key="export" onClick={onClick} entity={data} />]
      : []),
  ];

  const openTransferModal = () => {
    if (data) {
      openModal({
        confirmText: t('common:action.confirmButton', { action: t('vendor:buyingAgreements.actions.transfer') }),
        title: t('vendor:buyingAgreements.transfer.title'),
        description: (
          <Trans i18nKey="vendor:buyingAgreements.transfer.description" components={{ ul: <ul />, li: <li /> }} />
        ),
        onConfirm: async () => {
          await transfer(data.vendorId, data.id);
          refresh();
        },
      });
    }
  };

  const renderExtraButtons = () => [
    <VendorBuyingAgreementChangeLogs key="changelog" />,
    ...(isInternalUser &&
    data?.status == VendorBuyingAgreementStatus.Approved &&
    data.type == VendorBuyingAgreementType.InApp &&
    !data?.isArchived
      ? [
          <HasPermissions keys={PermissionKey.VendorApproveVBAs} key="transfer">
            <Button
              disabled={data.isTransferred}
              id="transfer"
              startIcon={<SubdirectoryArrowRightRounded />}
              onClick={openTransferModal}
            >
              {t('vendor:buyingAgreements.actions.transfer')}
            </Button>
          </HasPermissions>,
        ]
      : []),
  ];

  const tabs = [
    <LinkTab label={t('vendor:sections.vendorDocuments')} value={getGeneratedPath(baseRoute.Documents.path)} />,
    ...(data?.type == VendorBuyingAgreementType.InApp
      ? [
          <LinkTab
            key="general"
            label={t('vendor:sections.generalConditions')}
            value={getGeneratedPath(baseRoute.GeneralConditions.path)}
          />,
          <LinkTab
            key="opportunity"
            label={t('vendor:sections.buyOpportunities')}
            value={getGeneratedPath(baseRoute.BuyOpportunities.path)}
          />,
          <LinkTab
            key="category"
            label={t('vendor:sections.rebateCategories')}
            value={getGeneratedPath(baseRoute.RebateCategories.path)}
          />,
        ]
      : []),
  ];

  if (isLoading || !data) {
    return (
      <Container>
        <CircularProgress size={20} sx={style.loading} />
      </Container>
    );
  }

  if (!outlet) {
    return <Navigate replace to={getGeneratedPath(baseRoute.Documents.path)} />;
  }

  return (
    <VendorBuyingAgreementContext.Provider
      value={{
        vendorBuyingAgreement: data,
        readOnly: !data?.isChildEditable(user?.profile.userType) || !canEdit,
        refresh: refresh,
      }}
    >
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
        <Container>
          <EditDetails
            titleLabel={t(`vendor:form.name`)}
            title="name"
            header={<VendorBuyingAgreementHeader />}
            disabled={data.isArchived}
            readOnly={readOnly || !canEdit}
            entity={data}
            schema={VendorBuyingAgreementSchema()}
            onSave={onSave}
            actionMenuItems={renderActionMenuItems}
            onConfirm={() => refresh()}
            breadcrumbs={breadcrumbs}
            extraActions={renderExtraButtons()}
            workflow={workflow}
          >
            <VendorBuyingAgreementForm />
          </EditDetails>
        </Container>
      </LayoutPage>
    </VendorBuyingAgreementContext.Provider>
  );
};
