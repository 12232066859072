import { Dispatch, SetStateAction } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArchiveFilter, Styles, TranslationContext } from '../../types';
import { useEnumList } from '../../hooks';

const style: Styles = {
  filterContainer: {
    py: 2,
  },
};

interface ArchiveFilterSelectProps {
  filter: ArchiveFilter;
  setFilter: Dispatch<SetStateAction<ArchiveFilter>>;
  translationContext: TranslationContext;
}

enum ArchiveFilterOptions {
  ALL = 'All',
  AVAILABLE = 'Available',
  ARCHIVED = 'Archived',
}

export const ArchiveFilterSelect = ({ filter, setFilter, translationContext }: ArchiveFilterSelectProps) => {
  const { t } = useTranslation();
  const archiveFilterOptions = useEnumList(
    ArchiveFilterOptions,
    'common:archiveFilter',
    false,
    translationContext,
    false,
  );

  const onChange = (value: ArchiveFilterOptions) => () => {
    const isArchived = value === ArchiveFilterOptions.ALL ? undefined : value === ArchiveFilterOptions.ARCHIVED;
    setFilter((prev) => ({ ...prev, isArchived, pageNumber: 1 }));
  };

  const value =
    filter.isArchived === undefined
      ? ArchiveFilterOptions.ALL
      : filter.isArchived
      ? ArchiveFilterOptions.ARCHIVED
      : ArchiveFilterOptions.AVAILABLE;

  return (
    <Stack sx={style.filterContainer} direction="row" spacing={2} alignItems="center">
      <Typography align="center" variant="label" color="grey.600">
        {t('common:show')}
      </Typography>
      <Stack spacing={0.7} direction="row">
        {archiveFilterOptions.map((option) => (
          <Chip
            key={option.value}
            label={option.label}
            onClick={onChange(option.value)}
            color={option.value === value ? 'primary' : 'secondary'}
          />
        ))}
      </Stack>
    </Stack>
  );
};
