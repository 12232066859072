import { object, ref, string } from 'yup';
import { UserPasswordEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { testPasswordComplexity } from '../../utils/password';

export const PasswordSchema = (): EntityFormSchema<UserPasswordEntity> => ({
  schema: object({
    oldPassword: string().required(),
    newPassword: string()
      .required()
      .test('passwordComplexity', 'auth:error.passwordNotComplex', (value) => {
        if (!value) {
          return false;
        }
        const passwordComplexity = testPasswordComplexity(value);
        return (
          passwordComplexity.length &&
          passwordComplexity.numbers &&
          passwordComplexity.lowercaseLetters &&
          passwordComplexity.uppercaseLetters &&
          passwordComplexity.specialCharacters &&
          passwordComplexity.trailingSpace
        );
      }),
    newPasswordConfirmation: string()
      .required()
      .oneOf([ref('newPassword'), null], 'auth:error.passwordsDoNotMatch'),
  }),
});
