import axios from 'axios';
import { ChangeLogEntity, HelpTrainingEntity } from '../../models';
import {
  HelpTrainingFilter,
  ChangeLogFilter,
  ContentTriggers,
  PaginationEntity,
  TranslationLanguage,
  LanguageCode,
  UpdateContentFileLanguageRequest,
} from '../../types';
import { TranslatedFileEntity } from '../../models/TranslatedFileEntity';

export const HelpTraining = {
  getAllHelpTrainings: async (
    helpTrainingFilter: HelpTrainingFilter,
  ): Promise<PaginationEntity<HelpTrainingEntity>> => {
    const { data } = await axios.post('api/helpTraining/search', helpTrainingFilter);
    return { ...data, data: data.data.map((d: HelpTrainingEntity) => new HelpTrainingEntity(d)) };
  },
  getHelpTraining: async (helpTrainingId: number): Promise<HelpTrainingEntity> => {
    const { data } = await axios.get(`api/helpTraining/${helpTrainingId}`);
    return new HelpTrainingEntity(data);
  },
  getHelpTrainingSuggestions: async (
    helpTrainingNameOrKeyword: string,
    isReadOnly?: boolean,
  ): Promise<PaginationEntity<HelpTrainingEntity>> => {
    const { data } = await axios.post('api/helpTraining/search', {
      helpTrainingNameOrKeyword,
      pageSize: 10,
      pageNumber: 1,
      isReadOnly,
    });
    return { ...data, data: data.data.map((d: HelpTrainingEntity) => new HelpTrainingEntity(d)) };
  },
  createHelpTraining: async (entity: HelpTrainingEntity): Promise<HelpTrainingEntity> => {
    const { data } = await axios.post('api/helpTraining', entity);
    return new HelpTrainingEntity(data);
  },
  updateHelpTraining: async (entity: HelpTrainingEntity): Promise<HelpTrainingEntity> => {
    const { data } = await axios.put(`api/helpTraining/${entity.id}`, entity);
    return new HelpTrainingEntity(data);
  },
  uploadHelpTrainingFiles: async (
    helpTrainingId: number,
    files: TranslatedFileEntity[],
  ): Promise<HelpTrainingEntity> => {
    const formData = HelpTrainingEntity.toFormData(files, 'files');
    const { data } = await axios.post(`api/helpTraining/${helpTrainingId}/files`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new HelpTrainingEntity(data);
  },
  updateHelpTrainingFilesLanguage: async (
    helpTrainingId: number,
    files: UpdateContentFileLanguageRequest[],
  ): Promise<HelpTrainingEntity> => {
    const { data } = await axios.put(`api/helpTraining/${helpTrainingId}/files/language`, files);
    return new HelpTrainingEntity(data);
  },
  deleteHelpTrainingFiles: async (helpTrainingId: number, ids: number[]): Promise<HelpTrainingEntity> => {
    const { data } = await axios.delete(
      `api/helpTraining/${helpTrainingId}/files?${ids.map((id) => `fileIds=${id}`).join('&')}`,
    );
    return new HelpTrainingEntity(data);
  },
  getHelpTrainingFile: async (helpTrainingId: number, fileId: number): Promise<Blob> => {
    const { data } = await axios.get(`api/helpTraining/${helpTrainingId}/files/${fileId}`, {
      responseType: 'blob',
    });
    return data;
  },
  uploadHelpTrainingBlockFile: async (
    helpTrainingId: number,
    blockId: number,
    language: TranslationLanguage,
    file: File,
  ): Promise<HelpTrainingEntity> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.put(`api/helpTraining/${helpTrainingId}/block/${blockId}/file/${language}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return new HelpTrainingEntity(data);
  },
  deleteHelpTrainingBlockFile: async (
    helpTrainingId: number,
    blockId: number,
    language: LanguageCode,
  ): Promise<HelpTrainingEntity> => {
    const { data } = await axios.delete(`api/helpTraining/${helpTrainingId}/block/${blockId}/file/${language}`);
    return new HelpTrainingEntity(data);
  },
  getHelpTrainingBlockFile: async (helpTrainingId: number, blockId: number, language: LanguageCode): Promise<Blob> => {
    const { data } = await axios.get(`api/helpTraining/${helpTrainingId}/block/${blockId}/file/${language}`, {
      responseType: 'blob',
    });
    return data;
  },
  updateHelpTrainingWorkflow: async (helpTrainingId: number, trigger: ContentTriggers): Promise<HelpTrainingEntity> => {
    const { data } = await axios.post(`api/helpTraining/${helpTrainingId}/${trigger}`);
    return new HelpTrainingEntity(data);
  },
  getHelpTrainingChangeLogs: async (
    helpTrainingId: number,
    filter: ChangeLogFilter,
  ): Promise<PaginationEntity<ChangeLogEntity>> => {
    const { data } = await axios.post(`api/helpTraining/${helpTrainingId}/changeLog/search`, filter);
    return { ...data, data: data.data.map((v: ChangeLogEntity) => new ChangeLogEntity(v)) };
  },
  addHelpTrainingComment: async (helpTrainingId: number, description: string): Promise<ChangeLogEntity> => {
    const { data } = await axios.post(`api/helpTraining/${helpTrainingId}/comment`, {
      description,
    });
    return new ChangeLogEntity(data);
  },
};
