import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { useContext, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { vendor as vendorApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { BuyGuideVendorDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { useApi, useEnumList, useInternationalization } from '../../hooks';
import { VendorBuyOpportunityEntity } from '../../models';
import { routes } from '../../routes';
import { FilterPeriod, OpportunityType, PermissionKey, Styles, VendorBuyOpportunityFilter } from '../../types';
import { FilterContainer } from '../Container';
import { PeriodFilterSelect } from '../Filter/PeriodFilterSelect';
import { Select } from '../Form';
import { LayoutPage } from '../Layout';
import { VendorBuyOpportunitiesCardList } from '../Vendor/BuyOpportunities/VendorBuyOpportunitiesCardList';

const style: Styles = {
  selectLargeContainer: {
    width: {
      xs: '100%',
      sm: '300px',
    },
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
};

const defaultFilter: VendorBuyOpportunityFilter = {
  opportunity: OpportunityType.All,
  period: FilterPeriod.Current,
  isArchived: false,
  ...DEFAULT_PAGINATION_FILTER,
};

export const BuyGuideVendorBuyOpportunityList = () => {
  const { t } = useInternationalization();
  const navigate = useNavigate();
  const { vendor, vendorId } = useContext(BuyGuideVendorDetailContext);
  const [filter, setFilter] = useState<VendorBuyOpportunityFilter>(defaultFilter);
  const { data, isLoading } = useApi(vendorApi.getAllBuyOpportunities, { skipFetch: !vendor }, vendor?.id, filter);
  const opportunityOptions = useEnumList(OpportunityType, 'buyGuide:buyOpportunities.opportunity', false);

  const onOpportunityTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      opportunity: (event.target.value as OpportunityType) || undefined,
      pageNumber: 1,
    }));
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const renderFilter = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <PeriodFilterSelect filter={filter} setFilter={setFilter} />
      <Box sx={style.selectLargeContainer}>
        <Select
          options={opportunityOptions}
          value={filter.opportunity ?? ''}
          onChange={(e) => onOpportunityTypeChange(e)}
          label={t('buyGuide:buyOpportunities.filter.opportunity')}
        />
      </Box>
    </FilterContainer>
  );

  const renderActionsMenuItems = (entity: VendorBuyOpportunityEntity) => [
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(
          generatePath(routes.Member.BuyGuideBuyOpportunityDetail.Details.path, {
            vendorId,
            vendorBuyOpportunityId: entity.id,
          }),
        );
      }}
    >
      {t('common:view')}
    </MenuItem>,
  ];
  const renderList = () => (
    <VendorBuyOpportunitiesCardList
      data={data ?? []}
      isLoading={isLoading}
      translationNamespace="vendor"
      actionMenuItems={renderActionsMenuItems}
      hideResultCount
      href={(entity) =>
        generatePath(routes.Member.BuyGuideBuyOpportunityDetail.Details.path, {
          vendorId: vendorId,
          vendorBuyOpportunityId: entity.id,
        })
      }
    />
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewBuys }}
      title={t('buyGuide:buyOpportunities.title')}
      display="Tab"
    >
      {renderFilter()}
      {renderList()}
    </LayoutPage>
  );
};
