import { Grid } from '@mui/material';
import { vendor as vendorApi } from '../../../api';
import { useApi, useAuth, useEntityFormContext, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryRebateEntity } from '../../../models';
import { VendorRebateCategoryValue } from '../../../types';
import { ControlledInput, ControlledSelect, ControlledTranslatedInput } from '../../Form';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../../constants';

export const VendorRebateCategoryRebateCalculationMethodForm = () => {
  const { isInternalUser } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { data: calculationMethods } = useApi(
    vendorApi.getRebateCategoryValues,
    null,
    VendorRebateCategoryValue.CalculationMethods,
  );
  const { control, watch, readOnly } = useEntityFormContext<VendorRebateCategoryRebateEntity>();

  const calculationMethodId = watch('calculationMethodId');
  const customCalculationMethod = calculationMethods?.find((x) => x.id === calculationMethodId)?.isCustom;

  return (
    <Grid container spacing={3} alignItems={!readOnly ? 'baseline' : undefined}>
      <Grid item xs={customCalculationMethod ? 6 : 12}>
        <ControlledSelect
          label={t('vendor:rebateCategory.rebates.form.calculationMethod')}
          name="calculationMethodId"
          options={calculationMethods?.map((d) => ({ label: getTranslation(d, 'value'), value: d.id })) ?? []}
          renderValue={(id) => {
            const calculationMethod = calculationMethods?.find((cm) => cm.id === id);
            return calculationMethod ? getTranslation(calculationMethod, 'value') : '';
          }}
          control={control}
          required
        />
      </Grid>
      {customCalculationMethod && (
        <Grid item xs={6}>
          <ControlledTranslatedInput
            label={t('vendor:rebateCategory.rebates.form.customMethod')}
            name="customMethod"
            control={control}
            required
            maxLength={MAX_SHORT_TEXT_LENGTH}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <ControlledTranslatedInput
          label={t('vendor:rebateCategory.rebates.form.calculationMethodMemberNotes')}
          name="calculationMethodMemberNotes"
          control={control}
          maxLength={MAX_LONG_TEXT_LENGTH}
        />
      </Grid>
      {isInternalUser && (
        <Grid item xs={6}>
          <ControlledInput
            label={t('vendor:rebateCategory.rebates.form.calculationMethodNotes')}
            name="calculationMethodNotes"
            control={control}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
      )}
    </Grid>
  );
};
