import { date, number, object, ref, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorRebateCategoryEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { isDateValid } from '../../utils/helper';

export const VendorRebateCategorySchema = (): EntityFormSchema<VendorRebateCategoryEntity> => ({
  schema: object().shape(
    {
      name_En: string().required().max(MAX_SHORT_TEXT_LENGTH),
      name_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      vendorBuyingAgreementId: number().typeError('common:error.fieldRequired').required(),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .required()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      responsibleBuyerId: number().typeError('common:error.fieldRequired').required(),
      memberNotes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
