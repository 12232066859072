import { useContext } from 'react';
import { vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryDetailContext } from '../../../contexts/Vendor/VendorRebateCategoryDetailContext';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorTierGrowthEntity } from '../../../models';
import { GrowthOrTierOption } from '../../../types';
import { LayoutPage } from '../../Layout';
import { DeleteMenuItem } from '../../Menu/DeleteMenuItem';
import { VendorTierGrowthList, VendorTiersGrowthsSelect } from '../TiersGrowths';

export const VendorRebateCategoryTiersGrowths = () => {
  const { t, getTranslation } = useInternationalization();
  const { vendorRebateCategoryId, vendorId, readOnly } = useContext(VendorRebateCategoryDetailContext);
  const {
    data: tiers,
    isLoading: isTierLoading,
    refresh: refreshTiers,
  } = useApi(vendorApi.getAllRebateCategoryTier, null, vendorId, vendorRebateCategoryId);
  const {
    data: growths,
    isLoading: isGrowthsLoading,
    refresh: refreshGrowths,
  } = useApi(vendorApi.getAllRebateCategoryGrowth, null, vendorId, vendorRebateCategoryId);
  const { call: addTier } = useApi(vendorApi.addRebateCategoryTier, { successKey: 'common:success.save' });
  const { call: addGrowth } = useApi(vendorApi.addRebateCategoryGrowth, { successKey: 'common:success.save' });
  const { call: removeGrowth } = useApi(vendorApi.removeRebateCategoryGrowth, { successKey: 'common:success.action' });
  const { call: removeTier } = useApi(vendorApi.removeRebateCategoryTier, { successKey: 'common:success.action' });

  const onDeleteConfirm = async (entity: VendorTierGrowthEntity, isGrowthSelected: boolean) => {
    if (entity) {
      isGrowthSelected
        ? await removeGrowth(vendorId, vendorRebateCategoryId, entity.id)
        : await removeTier(vendorId, vendorRebateCategoryId, entity.id);
      isGrowthSelected ? refreshGrowths() : refreshTiers();
    }
  };

  const renderActionsMenuItems = (entity: VendorTierGrowthEntity, onClick: () => void, isGrowthSelected: boolean) => [
    <DeleteMenuItem
      key="delete"
      entity={entity}
      name={getTranslation(entity, 'name')}
      onClick={onClick}
      onDeleteConfirm={(entity) => onDeleteConfirm(entity, isGrowthSelected)}
      actionSuffix={t(`vendor:rebateCategory.tierAndGrowth.actions.suffix.${isGrowthSelected ? 'growth' : 'tier'}`)}
    />,
  ];

  const onAssociateTierOrGrowth = async (item: GrowthOrTierOption) => {
    const addGrowthOrTier = item.type === 'growth' ? addGrowth : addTier;
    const refreshGrowthsOrTiers = item.type === 'growth' ? refreshGrowths : refreshTiers;
    await addGrowthOrTier(vendorId, vendorRebateCategoryId, item.id);
    await refreshGrowthsOrTiers();
  };

  return (
    <LayoutPage title={t('vendor:rebateCategory.tierAndGrowth.title')} display="Tab">
      {!readOnly && (
        <VendorTiersGrowthsSelect
          currentGrowths={growths}
          currentTiers={tiers}
          onAssociateTierOrGrowth={onAssociateTierOrGrowth}
          vendorId={vendorId}
        />
      )}
      <VendorTierGrowthList
        isLoading={isTierLoading || isGrowthsLoading}
        tiers={tiers}
        growths={growths}
        actionMenuItems={!readOnly ? renderActionsMenuItems : undefined}
      />
    </LayoutPage>
  );
};
