import { TypographyProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatShortDate } from '../../../utils/helper';
import { DatePicker } from '../Base/DatePicker';
import { StaticField } from '../Base/StaticField';

interface ControlledDatePickerProps extends Omit<React.ComponentProps<typeof DatePicker>, 'value' | 'onChange'> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  label?: string;
  required?: boolean;
  readOnlyVariant?: TypographyProps['variant'];
}

export const ControlledDatePicker = ({
  name,
  control,
  hideLabel,
  label,
  required,
  yearOnly,
  readOnlyVariant,
  readOnly,
  ...props
}: ControlledDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, fieldState: { error, isDirty } }) => {
        const getYear = (date: Date | null) => date?.getFullYear() ?? null;

        const getDateFromYear = (year: number) => {
          if (!year) return null;
          const date = new Date(1900, 1, 1);
          date.setFullYear(year);
          return date;
        };

        const onValueChange = (value: Date | null, keyboardInputValue?: string | undefined) => {
          return onChange(yearOnly ? getYear(value) : value, keyboardInputValue);
        };

        return control._options.context?.readOnly || readOnly ? (
          <StaticField
            label={label}
            hideLabel={hideLabel}
            variant={readOnlyVariant}
            value={yearOnly ? value : formatShortDate(value)}
          />
        ) : (
          <DatePicker
            {...props}
            className={control._options.context?.variant === 'changeRequest' && isDirty ? 'dirty' : undefined}
            onBlur={onBlur}
            label={required ? `${label} *` : label}
            hideLabel={hideLabel}
            onChange={onValueChange}
            value={yearOnly ? getDateFromYear(value) : value}
            error={!!error}
            yearOnly={yearOnly}
            helperText={error?.message && t(error?.message, { name: label })}
          />
        );
      }}
    />
  );
};
