import { useOutlet } from 'react-router-dom';
import { useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';
import { PermissionKey } from '../../types';

export const ForestProductLayout = () => {
  const { t } = useInternationalization();
  const outlet = useOutlet();

  const tabs = [
    <LinkTab
      label={t('forestProduct:sections.productGroups')}
      value={routes.Admin.ForestProducts.Groups.path}
      permissions={{ keys: [PermissionKey.CommodityGroupManage] }}
    />,
    <LinkTab
      label={t('forestProduct:sections.priceUpdates')}
      value={routes.Admin.ForestProducts.PriceUpdates.path}
      permissions={{ keys: [PermissionKey.CommodityPriceEdit, PermissionKey.CommodityPriceView], any: true }}
    />,
  ];
  return <LayoutPage display="Page" tabs={tabs} title={t('forestProduct:title')} outlet={outlet} />;
};
