import { object, string } from 'yup';
import { CategorizationEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema } from '../../types';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';

export const CategorizationSchema = (
  hasAbbreviation: boolean,
  isPropertyUniqueCallback: (
    propertyName: 'name_Fr' | 'name_En' | 'abbreviation_En' | 'abbreviation_Fr',
    value: string,
  ) => boolean,
): EntityFormSchema<CategorizationEntity> => ({
  schema: object({
    name_En: string()
      .required()
      .max(MAX_SHORT_TEXT_LENGTH)
      .test('unique', 'common:error.fieldUnique', (value) => !!value && isPropertyUniqueCallback('name_En', value)),
    name_Fr: string()
      .required()
      .max(MAX_SHORT_TEXT_LENGTH)
      .test('unique', 'common:error.fieldUnique', (value) => !!value && isPropertyUniqueCallback('name_Fr', value)),
    ...(hasAbbreviation && {
      abbreviation_En: string()
        .required()
        .max(MAX_SHORT_TEXT_LENGTH)
        .test(
          'unique',
          'common:error.fieldUnique',
          (value) => !!value && isPropertyUniqueCallback('abbreviation_En', value),
        ),
      abbreviation_Fr: string()
        .required()
        .max(MAX_SHORT_TEXT_LENGTH)
        .test(
          'unique',
          'common:error.fieldUnique',
          (value) => !!value && isPropertyUniqueCallback('abbreviation_Fr', value),
        ),
    }),
  }),
  errorCodeMap: {
    [ApiErrorCodes.CATEGORY_NAME_EN_NOT_UNIQUE]: { key: 'name_En', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.CATEGORY_NAME_FR_NOT_UNIQUE]: { key: 'name_Fr', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.CATEGORY_ABBREVIATION_EN_NOT_UNIQUE]: {
      key: 'abbreviation_En',
      message: 'common:error.fieldUnique',
    },
    [ApiErrorCodes.CATEGORY_ABBREVIATION_FR_NOT_UNIQUE]: {
      key: 'abbreviation_Fr',
      message: 'common:error.fieldUnique',
    },
  },
});
