import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { PasswordComplexityStatus } from '../components';
import { ControlledInput } from '../components/Form';
import { LayoutPublic } from '../components/Layout';
import { useInternationalization, usePageTitle } from '../hooks';
import useAuth from '../hooks/useAuth';
import { PasswordInitialization, Styles } from '../types';
import { testPasswordComplexity } from '../utils/password';

const style: Styles = {
  button: {
    width: '100%',
  },
};

export function SetPassword() {
  const { t } = useInternationalization();
  usePageTitle('auth:setPassword.title');
  const [errorKey, setErrorKey] = useState('');
  const { setPassword, userTemporaryPassword } = useAuth();

  const validationLogin = Yup.object({
    newPassword: Yup.string()
      .required(t('common:error.fieldRequired', { name: t('auth:setPassword.newPassword') }))
      .test('passwordComplexity', t('auth:error.passwordNotComplex'), (value) => {
        if (!value) {
          return false;
        }
        const passwordComplexity = testPasswordComplexity(value);
        return (
          passwordComplexity.length &&
          passwordComplexity.numbers &&
          passwordComplexity.lowercaseLetters &&
          passwordComplexity.uppercaseLetters &&
          passwordComplexity.specialCharacters &&
          passwordComplexity.trailingSpace
        );
      }),
    newPasswordConfirmation: Yup.string()
      .required(t('common:error.fieldRequired', { name: t('auth:setPassword.newPasswordConfirmation') }))
      .oneOf([Yup.ref('newPassword'), null], t('auth:error.passwordsDoNotMatch')),
  });

  const { handleSubmit, control, formState, watch } = useForm<PasswordInitialization>({
    defaultValues: {
      username: userTemporaryPassword?.username,
      newPassword: '',
      newPasswordConfirmation: '',
      sessionToken: userTemporaryPassword?.sessionToken,
    },
    resolver: yupResolver(validationLogin),
  });

  const onSubmit = async (passwordInitialization: PasswordInitialization) => {
    try {
      setErrorKey('');
      await setPassword(passwordInitialization);
    } catch {
      setErrorKey(t('common:error.generic'));
    }
  };

  const password = watch('newPassword');

  return (
    <LayoutPublic title={t('auth:setPassword.title')}>
      <form id="set-password-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <PasswordComplexityStatus password={password} />
          <ControlledInput
            label={t('auth:setPassword.newPassword')}
            type="password"
            name="newPassword"
            control={control}
          />
          <ControlledInput
            label={t('auth:setPassword.newPasswordConfirmation')}
            type="password"
            name="newPasswordConfirmation"
            control={control}
          />
          <LoadingButton sx={style.button} loading={formState.isSubmitting} type="submit" variant="contained">
            {t('auth:setPassword.action')}
          </LoadingButton>
          {!!errorKey && <Typography variant="inputError">{t(errorKey)}</Typography>}
        </Stack>
      </form>
    </LayoutPublic>
  );
}
