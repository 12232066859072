import { vendor as vendorApi } from '../../../api';
import { useApi, useInternationalization } from '../../../hooks';
import { ChangeRequestState, VendorChangeRequestFilter } from '../../../types';
import { MIN_PAGINATION_FILTER } from '../../../constants';
import { SyncProblemRounded } from '@mui/icons-material';
import { routes } from '../../../routes';
import { CounterWidget } from './CounterWidget';
import { WidgetProps } from './Widget';

const filter: VendorChangeRequestFilter = {
  state: ChangeRequestState.Submitted,
  ...MIN_PAGINATION_FILTER,
};

export const VendorChangeRequests = (props: WidgetProps) => {
  const { t } = useInternationalization();
  const { data, isLoading } = useApi(vendorApi.getAllChangeRequests, null, filter);

  return (
    <CounterWidget
      {...props}
      isLoading={isLoading}
      count={data?.totalCount}
      title={t('dashboard:vendorChangeRequests:title')}
      icon={SyncProblemRounded}
      link={routes.Admin.ChangeRequests.Vendor.path}
    />
  );
};
