import axios from 'axios';
import { UserEntity } from '../models';
import { PaginationEntity, RoleGroup, UserFilter } from '../types';

export const roleGroup = {
  getUsers: async (roleGroup: RoleGroup, userFilter: UserFilter): Promise<PaginationEntity<UserEntity>> => {
    const { data } = await axios.post(`api/roleGroup/${roleGroup}/users/search`, userFilter);
    return { ...data, data: data.data.map((d: UserEntity) => new UserEntity(d)) };
  },
};
