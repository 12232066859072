import { RebateCategoryEntity } from './RebateCategoryEntity';
import { VendorEntity } from './VendorEntity';

export class VendorRebateCategoryEntity extends RebateCategoryEntity {
  programsCount: number;
  vendorId: number;
  vendor: VendorEntity;

  constructor(entity?: VendorRebateCategoryEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.programsCount = 0;
    }
  }
}
