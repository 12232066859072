import { Box, InputAdornment, OutlinedTextFieldProps, Stack, TextField, Typography } from '@mui/material';
import { FormattedInput, Styles } from '../../../types';
import { ChangeEvent } from 'react';

const style: Styles = {
  startAdornment: {
    mr: 0,
  },
  endAdornment: {
    ml: 0,
  },
  horizontalLabel: {
    mr: 2,
  },
};

interface InputProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  label?: string;
  variant?: 'outlined' | 'standard';
  hideLabel?: boolean;
  contentBesideTitle?: JSX.Element;
  titleStacked?: boolean;
  maxLength?: number;
  formatter?: (value: string) => FormattedInput;
  orientation?: 'horizontal' | 'vertical';
}

export const Input = ({
  hideLabel,
  label,
  variant,
  contentBesideTitle,
  titleStacked,
  maxLength,
  formatter,
  onChange,
  value,
  orientation = 'vertical',
  ...props
}: InputProps) => {
  const onChangeWithFormatter = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (formatter) {
      event.target.value = formatter(event.target.value).parsed.toString();
    }
    onChange && onChange(event);
  };

  return (
    <Stack
      flexDirection={orientation === 'horizontal' ? 'row' : undefined}
      alignItems={orientation === 'horizontal' ? 'center' : undefined}
      spacing={hideLabel || orientation === 'horizontal' ? 0 : 1}
      flex="1"
    >
      <Box
        flexDirection={titleStacked ? 'column-reverse' : undefined}
        display="flex"
        sx={orientation === 'horizontal' ? style.horizontalLabel : undefined}
      >
        {!hideLabel && (
          <Typography variant="label" minWidth="fit-content" alignItems="end" display="flex">
            {label}
          </Typography>
        )}
        {contentBesideTitle}
      </Box>
      <TextField
        variant={variant ? variant : 'outlined'}
        {...props}
        inputProps={{ ...props.inputProps, maxLength }}
        value={formatter ? formatter((value ?? '') as string).formatted[1] : value ?? ''}
        onChange={onChangeWithFormatter}
        InputProps={{
          startAdornment: formatter ? (
            <InputAdornment position="start" sx={style.startAdornment}>
              <Typography variant="inherit"> {formatter((value ?? '') as string).formatted[0]}</Typography>
            </InputAdornment>
          ) : undefined,
          endAdornment: formatter ? (
            <InputAdornment position="end" sx={style.endAdornment}>
              <Typography variant="inherit"> {formatter((value ?? '') as string).formatted[2]}</Typography>
            </InputAdornment>
          ) : undefined,
          ...props.InputProps,
        }}
      />
    </Stack>
  );
};
