import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { auth as authApi } from '../api';
import { PasswordComplexityStatus } from '../components';
import { ControlledInput } from '../components/Form';
import { LayoutPublic } from '../components/Layout';
import { usePageTitle } from '../hooks';
import { routes } from '../routes';
import { PasswordReinitialization, Styles } from '../types';
import { testPasswordComplexity } from '../utils/password';

const style: Styles = {
  button: {
    width: '100%',
  },
};

export function ResetPassword() {
  const { t } = useTranslation();
  usePageTitle('auth:resetPassword.title');
  const [errorKey, setErrorKey] = useState('');
  const navigate = useNavigate();

  const validationLogin = Yup.object({
    username: Yup.string().required(t('common:error.fieldRequired', { name: t('auth:resetPassword.username') })),
    confirmationCode: Yup.string().required(
      t('common:error.fieldRequired', { name: t('auth:resetPassword.confirmationCode') }),
    ),
    newPassword: Yup.string()
      .required(t('common:error.fieldRequired', { name: t('auth:resetPassword.newPassword') }))
      .test('passwordComplexity', t('auth:error.passwordNotComplex'), (value) => {
        if (!value) {
          return false;
        }
        const passwordComplexity = testPasswordComplexity(value);
        return (
          passwordComplexity.length &&
          passwordComplexity.numbers &&
          passwordComplexity.lowercaseLetters &&
          passwordComplexity.uppercaseLetters &&
          passwordComplexity.specialCharacters &&
          passwordComplexity.trailingSpace
        );
      }),
    newPasswordConfirmation: Yup.string()
      .required(t('common:error.fieldRequired', { name: t('auth:resetPassword.newPasswordConfirmation') }))
      .oneOf([Yup.ref('newPassword'), null], t('auth:error.passwordsDoNotMatch')),
  });

  const { handleSubmit, control, formState, watch } = useForm<PasswordReinitialization>({
    defaultValues: { username: '', confirmationCode: '', newPassword: '', newPasswordConfirmation: '' },
    resolver: yupResolver(validationLogin),
  });

  const onSubmit = async (formData: PasswordReinitialization) => {
    try {
      setErrorKey('');
      await authApi.resetPassword(formData);
      navigate(routes.Login.path);
    } catch {
      setErrorKey('auth:resetPassword.invalid');
    }
  };

  const password = watch('newPassword');

  return (
    <LayoutPublic title={t('auth:resetPassword.title')}>
      <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <ControlledInput label={t('auth:resetPassword.username')} name="username" control={control} />
          <ControlledInput label={t('auth:resetPassword.confirmationCode')} name="confirmationCode" control={control} />
          <PasswordComplexityStatus password={password} />
          <ControlledInput
            label={t('auth:resetPassword.newPassword')}
            type="password"
            name="newPassword"
            control={control}
          />
          <ControlledInput
            label={t('auth:resetPassword.newPasswordConfirmation')}
            type="password"
            name="newPasswordConfirmation"
            control={control}
          />
          <LoadingButton sx={style.button} loading={formState.isSubmitting} type="submit" variant="contained">
            {t('auth:resetPassword.action')}
          </LoadingButton>
          {!!errorKey && <Typography variant="inputError">{t(errorKey)}</Typography>}
        </Stack>
      </form>
    </LayoutPublic>
  );
}
