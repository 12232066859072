import { array, mixed, object } from 'yup';
import { ProfileEntity } from '../../models';
import { EntityFormSchema, NotificationType, NotificationMode } from '../../types';

export const ProfileNotificationSettingSchema = (): EntityFormSchema<ProfileEntity> => ({
  schema: object({
    notificationSettings: array().of(
      object({
        mode: mixed<NotificationMode>().oneOf(Object.values(NotificationMode)).required(),
        type: mixed<NotificationType>().oneOf(Object.values(NotificationType)).required(),
      }),
    ),
  }),
});
