import { useEntityFormContext } from '../../hooks';
import { UserPermissionsEntity } from '../../models';
import { UserType } from '../../types';
import { ControlledAssignedPermissionsList } from '../Permission';

export const UserPermissionsForm = () => {
  const { control, getValues } = useEntityFormContext<UserPermissionsEntity>();

  return (
    <ControlledAssignedPermissionsList
      control={control}
      name="assignedPermissions"
      userType={getValues().userType ?? UserType.Internal}
    />
  );
};
