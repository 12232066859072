import axios from 'axios';
import {
  MemberChangeRequestEntity,
  MemberEntity,
  MemberEntityChangeRequestEntity,
  MemberPermissionEntity,
} from '../../models';
import { ChangeRequestTrigger, MemberChangeRequestFilter, MemberFilter, PaginationEntity } from '../../types';

export const member = {
  getAll: async (memberFiler: MemberFilter): Promise<PaginationEntity<MemberEntity>> => {
    const { data } = await axios.post('api/member/search', memberFiler);
    return { ...data, data: data.data.map((d: MemberEntity) => new MemberEntity(d)) };
  },
  getSuggestions: async (
    memberNameOrNumber: string,
    isArchived?: boolean,
    isMyLocation?: boolean,
  ): Promise<PaginationEntity<MemberEntity>> => {
    const { data } = await axios.post('api/member/search', {
      memberNameOrNumber,
      pageSize: 20,
      pageNumber: 1,
      isArchived,
      isMyLocation,
    });
    return { ...data, data: data.data.map((d: MemberEntity) => new MemberEntity(d)) };
  },
  create: async (entity: MemberEntity): Promise<MemberEntity> => {
    const { data } = await axios.post('api/member', entity);
    return new MemberEntity(data);
  },
  get: async (memberId: number): Promise<MemberEntity> => {
    const { data } = await axios.get<MemberEntity>(`api/member/${memberId}`);
    return new MemberEntity(data);
  },
  update: async (entity: MemberEntity): Promise<MemberEntity> => {
    const { data } = await axios.put(`api/member/${entity.id}`, entity);
    return new MemberEntity(data);
  },
  confirm: async (memberId: number): Promise<MemberEntity> => {
    const { data } = await axios.put(`api/member/${memberId}/confirm`);
    return new MemberEntity(data);
  },
  getNextNumberAvailable: async (): Promise<number> => {
    const { data } = await axios.get('api/member/nextNumber');
    return data;
  },
  getNextLocationAvailable: async (memberNumber: number): Promise<number> => {
    const { data } = await axios.get(`api/member/nextLocation/${memberNumber}`);
    return data;
  },
  archive: async (ids: number[], isArchived: boolean): Promise<MemberEntity[]> => {
    const { data } = await axios.put(`api/member/archive`, { isArchived, ids });
    return data.map((v: MemberEntity) => new MemberEntity(v));
  },
  active: async (ids: number[], isActive: boolean): Promise<MemberEntity[]> => {
    const { data } = await axios.put(`api/member/active`, { isActive, ids });
    return data.map((v: MemberEntity) => new MemberEntity(v));
  },
  getMemberGroups: async (memberId: number): Promise<MemberPermissionEntity[]> => {
    const { data } = await axios.get(`api/member/${memberId}/memberGroups`);
    return data;
  },
  updateMemberGroup: async (memberId: number, memberGroupId: number, isIncluded: boolean): Promise<MemberEntity> => {
    const { data } = await axios.put(`api/member/${memberId}/memberGroups`, { memberGroupId, isIncluded });
    return new MemberEntity(data);
  },
  updateMemberChangeRequest: async (entity: MemberEntity): Promise<MemberEntity> => {
    const { data } = await axios.put(`api/member/${entity.id}/generalInformation`, entity);
    return new MemberEntity(data);
  },
  getAllChangeRequests: async (
    changeRequestFilter: MemberChangeRequestFilter,
  ): Promise<PaginationEntity<MemberChangeRequestEntity>> => {
    const { data } = await axios.post(`api/memberChangeRequests/search`, changeRequestFilter);
    return { ...data, data: data.data.map((v: MemberChangeRequestEntity) => new MemberChangeRequestEntity(v)) };
  },
  approvedOrRejectGeneralInformationRequest: async (
    id: number,
    trigger: ChangeRequestTrigger,
    entity: MemberEntity,
  ): Promise<MemberEntity> => {
    const { data } = await axios.post(`api/memberChangeRequests/generalInformation/${id}/${trigger}`, entity);
    return new MemberEntity(data);
  },
  getGeneralInformationChangeRequest: async (id: number): Promise<MemberEntityChangeRequestEntity<MemberEntity>> => {
    const { data } = await axios.get(`api/memberChangeRequests/generalInformation/${id}`);
    return new MemberEntityChangeRequestEntity(data);
  },
};
