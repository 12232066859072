import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutlet, useSearchParams } from 'react-router-dom';
import { MemberListSearch, MemberListView } from '.';
import { member as memberApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { MemberContext } from '../../contexts';
import { useApi } from '../../hooks';
import { MemberFilter } from '../../types';
import { LayoutPage } from '../Layout';

export const MemberListMember = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setInternalFilter] = useState<MemberFilter>({
    isArchived: false,
    isMyLocation: searchParams.has('myLocations') ? true : undefined,
    ...DEFAULT_PAGINATION_FILTER,
  });
  const { refresh, isLoading, data, setData } = useApi(memberApi.getAll, { skipFetch: !!outlet }, filter);

  // Erase search params when filter change, in order to allow returning My Locations page
  const setFilter = useCallback(
    (value: SetStateAction<MemberFilter>) => {
      setInternalFilter(value);
      setSearchParams({});
    },
    [setInternalFilter, setSearchParams],
  );

  // Set Filtering to My Locations when Search Params change
  useEffect(() => {
    if (searchParams.has('myLocations')) {
      setInternalFilter((prev) => ({ ...prev, isMyLocation: true }));
    }
  }, [searchParams, setInternalFilter]);

  if (outlet) {
    return outlet;
  }

  return (
    <MemberContext.Provider value={{ data, setData, isLoading, fetchData: refresh, filter, setFilter }}>
      <LayoutPage title={t('member:title')}>
        <MemberListSearch />
        <MemberListView />
      </LayoutPage>
    </MemberContext.Provider>
  );
};
