import { VendorRebateCategoryRebateSummaryGroupEntity } from ".";

export class VendorRebateCategoryRebateSummaryEntity {
    completeDiscount: number;
    totalRebates: number;
    termFactor: number;
    rebateFactor: number;
    foidFactor: number;
    hasNonPercentageValues: boolean;
    foid: VendorRebateCategoryRebateSummaryGroupEntity;
    groups: VendorRebateCategoryRebateSummaryGroupEntity[];

    constructor(entity?: VendorRebateCategoryRebateSummaryEntity) {
      if (entity) {
        Object.assign(this, { ...entity });
      }
    }
  }
  