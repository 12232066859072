import axios from 'axios';
import { ChangeLogEntity, VendorEntity } from '../../models';
import { BuyGuideChangeLogFilter, BuyGuideFilter, EntityType, FilterPeriod, PaginationEntity, VendorFilter } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const vendor = {
  getAll: async (vendorFiler: VendorFilter | BuyGuideFilter): Promise<PaginationEntity<VendorEntity>> => {
    const { data } = await axios.post('api/vendor/search', vendorFiler);
    return { ...data, data: data.data.map((v: VendorEntity) => new VendorEntity(v)) };
  },
  getSuggestions: async (vendorNameOrNumber: string, isArchived?: boolean): Promise<PaginationEntity<VendorEntity>> => {
    const { data } = await axios.post('api/vendor/search', {
      vendorNameOrNumber,
      pageSize: 20,
      pageNumber: 1,
      isArchived,
    });
    return { ...data, data: data.data.map((v: VendorEntity) => new VendorEntity(v)) };
  },
  getMemberSuggestions: async (vendorName: string): Promise<PaginationEntity<VendorEntity>> => {
    const { data } = await axios.post('api/vendor/search', {
      vendorName,
      pageSize: 10,
      pageNumber: 1,
    });
    return { ...data, data: data.data.map((v: VendorEntity) => new VendorEntity(v)) };
  },
  create: async (entity: VendorEntity): Promise<VendorEntity> => {
    const { data } = await axios.post('api/vendor', entity);
    return new VendorEntity(data);
  },
  get: async (vendorId: number): Promise<VendorEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}`);
    return new VendorEntity(data);
  },
  update: async (entity: VendorEntity): Promise<VendorEntity> => {
    const { data } = await axios.put(`api/vendor/${entity.id}`, entity);
    return new VendorEntity(data);
  },
  archive: async (ids: number[], isArchived: boolean): Promise<VendorEntity[]> => {
    const { data } = await axios.put(`api/vendor/archive`, { isArchived, ids });
    return data.map((v: VendorEntity) => new VendorEntity(v));
  },
  visible: async (ids: number[], isVisible: boolean): Promise<VendorEntity[]> => {
    const { data } = await axios.put(`api/vendor/visible`, { ids, isVisible });
    return data.map((v: VendorEntity) => new VendorEntity(v));
  },
  active: async (ids: number[], isActive: boolean): Promise<VendorEntity[]> => {
    const { data } = await axios.put(`api/vendor/active`, { ids, isActive });
    return data.map((v: VendorEntity) => new VendorEntity(v));
  },
  updateFavourites: async (ids: number[], isFavourite: boolean): Promise<VendorEntity[]> => {
    const { data } = await axios.put(`api/vendor/favourite`, { isFavourite, ids });
    return data.map((v: VendorEntity) => new VendorEntity(v));
  },
  getAllChangeLogs: async (
    vendorId: number,
    filter: BuyGuideChangeLogFilter,
  ): Promise<PaginationEntity<ChangeLogEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/changeLog/search`, {
      ...filter,
    });
    return { ...data, data: data.data.map((v: ChangeLogEntity) => new ChangeLogEntity(v)) };
  },
  export: async (
    vendorId: number,
    period: FilterPeriod | null = null,
    entityTypes: EntityType[] | null = null,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/vendor/${vendorId}/report`, { params: { period, entityTypes }, responseType: 'blob' });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
