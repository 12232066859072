import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Entity } from '../types';
import { formatShortDate, uncapitalize } from '../utils/helper';

interface EntityModifiedDateProps {
  entity: Entity;
}

export const EntityModifiedDate = ({ entity }: EntityModifiedDateProps) => {
  const { t } = useTranslation();

  if (!entity.createdDate || !entity.createdBy || !entity.modifiedDate || !entity.modifiedBy) {
    return null;
  }

  return (
    <Typography variant="modifiedDate">
      {t('common:entity.createdDate', {
        date: formatShortDate(entity.createdDate),
        name: entity.createdBy,
      })}
      {uncapitalize(
        t('common:entity.modifiedDate', {
          date: formatShortDate(entity.modifiedDate),
          name: entity.modifiedBy,
        }),
      )}
    </Typography>
  );
};
