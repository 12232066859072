import { Grid, RegularBreakpoints } from '@mui/material';
import { useAuth, useEntityFormContext, useInternationalization } from '../../hooks';
import { TargetedMembershipEntity } from '../../models';
import { ControlledMultiSelect, StaticField } from '../Form';

interface TargetedMembershipHeaderProps {
  regionsBreakpoints: RegularBreakpoints;
  membershipTypesBreakpoints: RegularBreakpoints;
  membersBreakpoints: RegularBreakpoints;
}

export const TargetedMembershipHeader = ({
  regionsBreakpoints,
  membersBreakpoints,
  membershipTypesBreakpoints,
}: TargetedMembershipHeaderProps) => {
  const { t, getTranslation } = useInternationalization();
  const { isInternalUser } = useAuth();
  const { control, watch } = useEntityFormContext<{ targetedMembership: TargetedMembershipEntity | null }>();
  const targetedMembership = watch('targetedMembership');
  const isBasedOnMemberCriteria = targetedMembership?.isBasedOnMemberCriteria;

  return (
    <>
      {isInternalUser && (
        <Grid item {...membershipTypesBreakpoints}>
          <ControlledMultiSelect
            label={t('vendor:targetedMembership.form.membershipTypes')}
            name="targetedMembership.membershipTypeIds"
            options={
              isBasedOnMemberCriteria
                ? targetedMembership?.membershipTypes?.map((d) => ({
                    label: getTranslation(d, 'name'),
                    value: d.id,
                  })) ?? []
                : []
            }
            control={control}
            multiple
            readOnly
            defaultToAll={isBasedOnMemberCriteria}
          />
        </Grid>
      )}
      <Grid item {...regionsBreakpoints}>
        <ControlledMultiSelect
          label={t('vendor:targetedMembership.form.regions')}
          name="targetedMembership.regionIds"
          options={
            isBasedOnMemberCriteria
              ? targetedMembership?.regions?.map((d) => ({
                  label: getTranslation(d, 'name'),
                  value: d.id,
                })) ?? []
              : []
          }
          control={control}
          multiple
          readOnly
          defaultToAll={isBasedOnMemberCriteria}
        />
      </Grid>
      {isInternalUser && (
        <Grid item {...membersBreakpoints}>
          <StaticField
            label={t('vendor:targetedMembership.form.customPermissions')}
            value={t(
              `common:boolean.${!!targetedMembership?.memberGroups?.length || !!targetedMembership?.members?.length}`,
            )}
          />
        </Grid>
      )}
    </>
  );
};
