import { BlockRounded, CheckRounded, ReplayRounded, SendRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { ArchiveBanner } from '../..';
import { vendor as vendorApi } from '../../../api';
import { useApi, useAuth, useConfirmationModal } from '../../../hooks';
import { VendorBuyingAgreementEntity } from '../../../models';
import { routes } from '../../../routes';
import { palette } from '../../../styles/palette';
import {
  PermissionKey,
  VendorBuyingAgreementStatus,
  VendorBuyingAgreementTriggers,
  VendorBuyingAgreementType,
} from '../../../types';
import { ArchiveMenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { VendorBuyingAgreementDetail } from './VendorBuyingAgreementDetail';

export const VendorBuyingAgreementDetailAdmin = () => {
  const { hasPermissions } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal } = useConfirmationModal();
  const params = useParams();
  const vendorId = Number(params.vendorId);
  const getVendorApi = useApi(vendorApi.get, null, vendorId);
  const vendorBuyingAgreementId = Number(params.vendorBuyingAgreementId);
  const getBuyingAgreementApi = useApi(vendorApi.getBuyingAgreement, null, vendorId, vendorBuyingAgreementId);
  const { call: update } = useApi(vendorApi.updateBuyingAgreement, null);
  const { call: trigger, isLoading: isLoadingTrigger } = useApi(vendorApi.updateWorkflowBuyingAgreement, null);
  const archiveApi = useApi(vendorApi.archiveBuyingAgreement, { successKey: 'common:success.action' });

  const status = getBuyingAgreementApi.data?.status;

  const saveBuyingAgreement = async (entity: VendorBuyingAgreementEntity) => {
    return await update(vendorId, entity);
  };

  const onArchiveConfirm = async (isArchived: boolean, entity: VendorBuyingAgreementEntity) => {
    await archiveApi.call(vendorId, [entity.id], isArchived);
    getBuyingAgreementApi.refresh();
  };

  const onUnarchive = () => {
    if (getBuyingAgreementApi.data) {
      onArchiveConfirm(false, getBuyingAgreementApi.data);
    }
  };

  const onUpdateWorkflowConfirm = (newTrigger: VendorBuyingAgreementTriggers) => async () => {
    await trigger(vendorId, vendorBuyingAgreementId, newTrigger);
    getBuyingAgreementApi.refresh();
  };

  const onApproveRejectWorkflow =
    (newTrigger: VendorBuyingAgreementTriggers.Approve | VendorBuyingAgreementTriggers.Reject) => () => {
      const { name, filesCount, type } = getBuyingAgreementApi.data ?? {};

      if (
        type == VendorBuyingAgreementType.FileBased &&
        newTrigger !== VendorBuyingAgreementTriggers.Reject &&
        !filesCount
      ) {
        enqueueSnackbar(t('vendor:buyingAgreements.errors.approveFileBasedWithoutFiles'), { variant: 'error' });
        return;
      }

      const action = t(`vendor:buyingAgreements.workflow.${newTrigger.toLowerCase()}`);
      openModal({
        confirmText: t('common:action.confirmButton', { action }),
        title: t('common:action.confirmTitle', { name: t('vendor:buyingAgreements.actions.suffix'), action, count: 1 }),
        description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name }} />,
        onConfirm: onUpdateWorkflowConfirm(newTrigger),
      });
    };

  const renderActionMenuItems = (onClick: () => void) =>
    getBuyingAgreementApi.data
      ? [
          <ArchiveMenuItem
            permissions={{ keys: PermissionKey.VendorEditVBAs }}
            key="archive"
            entity={getBuyingAgreementApi.data}
            name={getBuyingAgreementApi.data.name}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('vendor:buyingAgreements.actions.suffix')}
            disabled={getBuyingAgreementApi.data.isArchived}
          />,
        ]
      : [];

  const renderWorkflow = () => {
    if (
      (status != VendorBuyingAgreementStatus.InProgressTBM && status != VendorBuyingAgreementStatus.InProgressVendor) ||
      !hasPermissions([PermissionKey.VendorApproveVBAs, PermissionKey.VendorEditVBAs], true)
    ) {
      return undefined;
    }

    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Stack spacing={1} direction="row">
          {status == VendorBuyingAgreementStatus.InProgressTBM && (
            <HasPermissions keys={PermissionKey.VendorApproveVBAs}>
              <Button
                size="small"
                variant="contained"
                disabled={isLoadingTrigger}
                onClick={onApproveRejectWorkflow(VendorBuyingAgreementTriggers.Approve)}
                startIcon={<CheckRounded />}
              >
                {t('vendor:buyingAgreements.workflow.approve')}
              </Button>
            </HasPermissions>
          )}
          {status == VendorBuyingAgreementStatus.InProgressTBM && (
            <HasPermissions keys={PermissionKey.VendorApproveVBAs}>
              <Button
                size="small"
                disabled={isLoadingTrigger}
                variant="outlined"
                onClick={onApproveRejectWorkflow(VendorBuyingAgreementTriggers.Reject)}
                startIcon={<BlockRounded />}
              >
                {t('vendor:buyingAgreements.workflow.reject')}
              </Button>
            </HasPermissions>
          )}
          {status == VendorBuyingAgreementStatus.InProgressVendor && (
            <HasPermissions keys={[PermissionKey.VendorEditVBAs, PermissionKey.VendorApproveVBAs]} any>
              <Button
                size="small"
                disabled={isLoadingTrigger}
                variant="contained"
                onClick={onUpdateWorkflowConfirm(VendorBuyingAgreementTriggers.Recall)}
                startIcon={<ReplayRounded />}
              >
                {t('vendor:buyingAgreements.workflow.recall')}
              </Button>
            </HasPermissions>
          )}
        </Stack>
        {status == VendorBuyingAgreementStatus.InProgressTBM &&
          getBuyingAgreementApi.data?.type === VendorBuyingAgreementType.InApp && (
            <HasPermissions keys={[PermissionKey.VendorEditVBAs, PermissionKey.VendorApproveVBAs]} any>
              <Button
                size="small"
                variant="text"
                disabled={isLoadingTrigger}
                onClick={onUpdateWorkflowConfirm(VendorBuyingAgreementTriggers.SendToVendor)}
                startIcon={<SendRounded htmlColor={palette.primary.deep} />}
              >
                <Typography variant="inherit" color={palette.primary.deep}>
                  {t('vendor:buyingAgreements.workflow.sendToVendor')}
                </Typography>
              </Button>
            </HasPermissions>
          )}
      </Box>
    );
  };

  return (
    <>
      <ArchiveBanner entity={getBuyingAgreementApi.data} onUnarchive={onUnarchive} />
      <VendorBuyingAgreementDetail
        vendorId={vendorId}
        vendorBuyingAgreementId={vendorBuyingAgreementId}
        readOnly={status !== VendorBuyingAgreementStatus.InProgressTBM}
        onSave={saveBuyingAgreement}
        actionMenuItems={status == VendorBuyingAgreementStatus.InProgressTBM ? renderActionMenuItems : undefined}
        fetchApi={getBuyingAgreementApi}
        workflow={renderWorkflow()}
        breadcrumbs={[
          { title: t('vendor:title'), href: routes.Admin.Vendor.path },
          { title: getVendorApi.data?.name, href: generatePath(routes.Admin.Vendor.Detail.path, { vendorId }) },
          { title: getBuyingAgreementApi.data?.name },
        ]}
      />
    </>
  );
};
