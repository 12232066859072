import { Navigate, useOutlet } from 'react-router-dom';
import { useAuth, usePageTitle } from '../../hooks';
import { routes } from '../../routes';
import { PermissionKey } from '../../types';

export const UserLayout = () => {
  const { hasPermissions } = useAuth();
  const outlet = useOutlet();
  usePageTitle('user:title');

  if (!outlet) {
    if (hasPermissions(PermissionKey.AdminManageInternalUsers)) {
      return <Navigate replace to={routes.Admin.User.InternalUser.path} />;
    }
    if (hasPermissions(PermissionKey.AdminManageMemberUsers)) {
      return <Navigate replace to={routes.Admin.User.MemberUser.path} />;
    }
    if (hasPermissions(PermissionKey.AdminManageVendorUsers)) {
      return <Navigate replace to={routes.Admin.User.VendorUser.path} />;
    }
  }

  return outlet;
};
