import { number, object, string } from 'yup';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorContactEntity } from '../../models';
import { ContactType, EntityFormSchema } from '../../types';
import { EmailAddressSchema, PhoneNumberSchema } from '../Contact';

export const VendorContactSchema = (): EntityFormSchema<VendorContactEntity> => ({
  schema: object({
    firstName: string().when('type', {
      is: ContactType.Person,
      then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    lastName: string().when('type', {
      is: ContactType.Person,
      then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    roleId: number().when('type', {
      is: ContactType.Person,
      then: (schema) => schema.typeError('common:error.fieldRequired').required(),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    department_En: string().when('type', {
      is: ContactType.Department,
      then: (schema) => schema.required().max(MAX_SHORT_TEXT_LENGTH),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    department_Fr: string().when('type', {
      is: ContactType.Department,
      then: (schema) => schema.notRequired().max(MAX_SHORT_TEXT_LENGTH),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  })
    .concat(PhoneNumberSchema().schema)
    .concat(EmailAddressSchema().schema),
  errorCodeMap: {},
});
