import { EditableEntity } from '../EditableEntity';
import { ForestProductPriceUpdateProductEntity } from './ForestProductPriceUpdateProductEntity';

export class ForestProductPriceUpdateEntity extends EditableEntity {
  id: number;
  date: Date;
  notes?: string;
  products?: ForestProductPriceUpdateProductEntity[];
  isArchived: boolean;

  constructor(entity?: ForestProductPriceUpdateEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
      this.date = new Date(entity.date);
    } else {
      this.id = 0;
      this.date = new Date();
    }
  }
}
