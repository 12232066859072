import { FormHelperText, Grid, Tab, Tabs } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ControlledInput } from '..';
import { useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { LanguageCode, Styles, TranslationLanguage } from '../../../types';

const style: Styles = {
  tabContainer: {
    textAlign: 'right',
    height: 0,
    mt: -0.5,
  },
  noLabelTabContainer: {
    height: 30,
  },
  tabs: {
    display: 'inline-block',
    borderBottom: 'none',
    mt: 0,
    mr: 1,
  },
  tab: {
    minWidth: 0,
    minHeight: 0,
    px: 1,
    py: 0.5,
  },
};

interface ControlledTranslatedInputProps {
  isFrRequired?: boolean;
  languageDispatch?: [TranslationLanguage, Dispatch<SetStateAction<TranslationLanguage>>];
  hideLanguageTab?: boolean;
}

export const ControlledTranslatedInput = ({
  control,
  name,
  label,
  isFrRequired,
  languageDispatch,
  hideLanguageTab,
  ...props
}: React.ComponentProps<typeof ControlledInput> & ControlledTranslatedInputProps) => {
  const { t, currentLanguage } = useInternationalization();
  const currentLanguageDispatch = useState<TranslationLanguage>(
    currentLanguage === LanguageCode.fr ? TranslationLanguage.Fr : TranslationLanguage.En,
  );

  const readOnly = control._options.context?.readOnly || props.readOnly;

  const [language, setLanguage] = languageDispatch ?? currentLanguageDispatch;

  useEffect(() => {
    setLanguage(currentLanguage === LanguageCode.fr ? TranslationLanguage.Fr : TranslationLanguage.En);
  }, [currentLanguage, setLanguage, readOnly]);

  const { trigger, getValues } = useEntityFormContext();

  const languageOptions = useEnumList(TranslationLanguage, 'common:translationLanguage');

  return (
    <Grid container>
      <Grid item xs={12}>
        <ControlledInput
          control={control}
          key={`${name}_${language}`}
          name={`${name}_${language}`}
          label={readOnly ? `${label} (${languageOptions.find((option) => option.value === language)?.label})` : label}
          contentBesideTitle={
            !readOnly && !hideLanguageTab ? (
              <Grid item xs={12} sx={[style.tabContainer, props.hideLabel ? style.noLabelTabContainer : {}]}>
                <Tabs value={language} onChange={(_, value: TranslationLanguage) => setLanguage(value)} sx={style.tabs}>
                  {languageOptions.map((option) => (
                    <Tab key={option.value} value={option.value} label={option.label} sx={style.tab} />
                  ))}
                </Tabs>
              </Grid>
            ) : undefined
          }
          {...props}
          staticValue={
            getValues()[`${name}_${language}`] ||
            getValues()[
              `${name}_${language === TranslationLanguage.Fr ? TranslationLanguage.En : TranslationLanguage.Fr}`
            ]
          }
          required={language === TranslationLanguage.Fr ? isFrRequired : props.required}
          onBlur={(e) => {
            trigger(`${name}_${language === TranslationLanguage.Fr ? TranslationLanguage.En : TranslationLanguage.Fr}`);
            props.onBlur && props.onBlur(e);
          }}
        />
        {languageOptions.map((option) => {
          if (option.value === language) return null;
          const error = control.getFieldState(`${name}_${option.value}`).error;
          return (
            error?.message && (
              <FormHelperText key={option.value} error>
                {t(error.message, { name: `${label} (${option.label})` })}
              </FormHelperText>
            )
          );
        })}
      </Grid>
    </Grid>
  );
};
