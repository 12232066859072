import { Control, Controller } from 'react-hook-form';
import { useInternationalization } from '../../../hooks';
import { Select } from '../Base/Select';
import { StaticField } from '../Base/StaticField';

interface ControlledSelectProps extends React.ComponentProps<typeof Select> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  label?: string;
  required?: boolean;
}

export const ControlledSelect = ({ name, control, label, required, readOnly, ...props }: ControlledSelectProps) => {
  const { t } = useInternationalization();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, fieldState: { error, isDirty } }) => {
        const selectedOption = props.options?.find((o) => o.value == value);

        return control._options.context?.readOnly || readOnly ? (
          <StaticField
            label={label}
            sx={{ '& p': { color: selectedOption?.color } }}
            value={props.renderValue ? props.renderValue(value) : selectedOption?.label ?? value}
            hideLabel={props.hideLabel}
          />
        ) : (
          <Select
            {...props}
            onBlur={onBlur}
            className={control._options.context?.variant === 'changeRequest' && isDirty ? 'dirty' : undefined}
            name={name}
            label={required ? `${label} *` : label}
            id={name}
            onChange={onChange}
            value={value ?? ''}
            error={!!error}
            helperText={error?.message && t(error?.message, { name: label })}
          />
        );
      }}
    />
  );
};
