import { EditableEntity } from '../EditableEntity';

export class ForestProductGroupEntity extends EditableEntity {
  id: number;
  name_En: string;
  name_Fr: string;
  description_En: string;
  description_Fr: string;
  productsCount: number;
  isArchived: boolean;
  notes: string;

  constructor(entity?: ForestProductGroupEntity) {
    super();
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.name_En = '';
      this.name_Fr = '';
      this.description_En = '';
      this.description_Fr = '';
      this.productsCount = 0;
      this.notes = '';
    }
  }
}
