import { Box, Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { MAX_FILE_SIZE_MB } from '../../../constants';
import { useAuth, useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { FileEntity, VendorDocumentEntity, VendorRebateCategoryEntity } from '../../../models';
import { DocumentType, LanguageCode, Styles } from '../../../types';
import { EntityModifiedDate } from '../../EntityModifiedDate';
import {
  ControlledAttachment,
  ControlledInput,
  ControlledMultiSelect,
  ControlledRadioSelect,
  ControlledTranslatedInput,
} from '../../Form';
import { PeriodForm } from '../../PeriodForm';

const style: Styles = {
  container: {
    pb: 4,
  },
};

interface VendorDocumentFormProps {
  rebateCategories?: VendorRebateCategoryEntity[];
  downloadFile?: (file: FileEntity) => void;
}

export const VendorDocumentForm = ({ rebateCategories, downloadFile }: VendorDocumentFormProps) => {
  const { isInternalUser } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { getValues, control, trigger, watch, variant } = useEntityFormContext<VendorDocumentEntity>();
  const editing = !!watch('id');
  const documentTypeOptions = useEnumList(DocumentType, 'vendor:document.type', false, 'male', false);
  const languageCodeOptions = useEnumList(LanguageCode, 'common:language', false);
  const languagesWatch = watch('languages');

  const rebateCategoriesOptions = useMemo(
    () =>
      rebateCategories
      ?.filter((rc) => !rc.isArchived)
      .map((rc) => ({
        value: rc.id,
        label: getTranslation(rc, 'name'),
      })) ?? [],
    [getTranslation, rebateCategories],
  );

  useEffect(() => {
    trigger('description_En');
    trigger('description_Fr');
  }, [trigger, languagesWatch]);

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        {editing && (
          <Grid item xs={12}>
            <EntityModifiedDate entity={getValues()} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('vendor:document.form.description')}
            name="description"
            control={control}
            required={languagesWatch?.includes(LanguageCode.en)}
            isFrRequired={languagesWatch?.includes(LanguageCode.fr)}
          />
        </Grid>
        <PeriodForm xs={6} optionalExpiryDate />
        <Grid item xs={12}>
          <ControlledRadioSelect
            label={t('vendor:document.form.type')}
            options={documentTypeOptions}
            name="type"
            control={control}
            row
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledMultiSelect
            label={t('vendor:document.form.languages')}
            options={languageCodeOptions}
            name="languages"
            control={control}
            noValueText=""
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledMultiSelect
            label={t('vendor:document.form.rebateCategoryIds')}
            name="rebateCategoryIds"
            options={rebateCategoriesOptions}
            control={control}
            mode="autocomplete"
            translationContext="female"
            defaultToAll
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledAttachment
            onFileClick={downloadFile}
            label={t('vendor:document.form.file')}
            name="file"
            control={control}
            maxSizeMB={MAX_FILE_SIZE_MB}
            required
            readOnly={variant === 'changeRequest'}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            label={isInternalUser ? t('vendor:document.form.vendorNotes') : t('vendor:document.form.comments')}
            name="vendorNotes"
            control={control}
            multiline
            rows={3}
          />
        </Grid>
        {isInternalUser && variant !== 'changeRequest' && (
          <Grid item xs={12}>
            <ControlledInput
              label={t('vendor:document.form.notes')}
              name="notes"
              control={control}
              multiline
              rows={3}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTranslatedInput
            label={t('vendor:document.form.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
