import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER, MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { VendorDocumentEntity } from '../../../models';
import { FilterPeriod, PermissionKey, VendorDocumentFilter } from '../../../types';
import { MenuItem } from '../../Menu';
import { VendorDocumentsList } from './VendorDocumentsList';

const defaultFilter: VendorDocumentFilter = {
  period: FilterPeriod.All,
  type: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

export const VendorDocumentsListVendor = () => {
  const { user, hasPermissions } = useAuth();
  const { t } = useInternationalization();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState(defaultFilter);

  const getCurrentDocumentsApi = useApi(vendorApi.getCurrentDocuments, null, filter);
  const { call: createRequest } = useApi(vendorApi.createDocumentChangeRequest, null);
  const { call: updateRequest } = useApi(vendorApi.updateDocumentChangeRequest, null);
  const { call: uploadRequest } = useApi(vendorApi.uploadDocumentFileChangeRequest, {
    successKey: 'common:success.action',
  });
  const { call: download } = useApi(vendorApi.getDocumentFile, null);
  const { call: removeRequest } = useApi(vendorApi.removeDocumentChangeRequest, {
    successKey: 'common:success.action',
  });
  const { data: rebateCategories } = useApi(vendorApi.getCurrentRebateCategories, null, MAX_PAGINATION_FILTER);

  const downloadFile = async (entity: VendorDocumentEntity) => {
    if (user?.profile.vendor?.id) {
      return await download(user.profile.vendor.id, entity.id);
    }
    return null;
  };

  const onDelete = async (entity: VendorDocumentEntity) => {
    await removeRequest(entity.id);
    await getCurrentDocumentsApi.refresh();
  };

  const onSave = async (entity: VendorDocumentEntity) => {
    const savedDocument = entity.id ? await updateRequest(entity) : await createRequest(entity);
    if (savedDocument && savedDocument.changeRequestId && entity.file && !entity.file.id) {
      savedDocument.file = entity.file;
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
      await uploadRequest(savedDocument.changeRequestId, entity.file as unknown as File);
    } else {
      enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    }
    return savedDocument;
  };

  const renderActionsMenuItems = (entity: VendorDocumentEntity, onClick: () => void) => [
    <MenuItem
      id="remove"
      key="remove"
      onClick={() => {
        onDelete(entity);
        onClick();
      }}
    >
      {t('common:changeRequest.remove')}
    </MenuItem>,
  ];

  return (
    <VendorDocumentsList
      rebateCategories={rebateCategories?.data}
      showSearch
      onFileDownload={downloadFile}
      isChangeRequest
      layout="Page"
      filter={filter}
      setFilter={setFilter}
      defaultFilter={defaultFilter}
      fetchApi={getCurrentDocumentsApi}
      onSave={onSave}
      tableProps={
        hasPermissions(PermissionKey.VendorEditFiles) ? { actionMenuItems: renderActionsMenuItems } : undefined
      }
    />
  );
};
