/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiException } from '../types';
import useAuth from './useAuth';

interface Options {
  /** Translation key to use for success message that will be queued. By default, no success message is displayed. */
  successKey?: string;
  /** Translation key to use for error message that will be queued. By default, a generic error messag is used. */
  errorKey?: string;
  /** Skip the automatic fetching funtionality when `args` is provided. */
  skipFetch?: boolean;
  /** Call the API function immediately */
  callImmediately?: boolean;
}

export interface Result<Args extends any[], Return> {
  /**
   * Invoke the provided API function when the provided arguments.
   *
   * @param args - Arguments for function
   * @returns Promise for function
   */
  call: (...args: Args) => Promise<Return | null>;
  /** Result of API call */
  data: Return | null;
  /** Flag if API is loading */
  isLoading: boolean;
  /** Refresh the data by calling the API function with the originally-provided `args`. */
  refresh: () => Promise<void>;
  /**
   * Manually override the persisted API result, until the next time the API is called.
   * Useful to maintain state following a user action that mutates the data immediately
   * preceeding a `refresh` call.
   *
   * @remarks Should be used only for exceptional circumstances.
   */
  setData: Dispatch<SetStateAction<Return | null>>;
}

/**
 * Encapsulates an API call, adding persistance of data and error handling
 * *
 * @param apiFunction - Function to be called.
 * @param options - Options for the operation of the hook, of type {@link Options}.
 * @param args - Arguments to be called. If provided, the API function will be called immediately, unless `options.skipFetch` is true.
 * @returns Object containing function to call API operation, persisted result and other metadata, of type {@link Result}
 *
 * @beta
 */
export function useApi<Args extends any[], Return>(
  apiFunction: (...args: Args) => Promise<Return>,
  options: Options | null,
  ...args: Partial<Args>
): Result<Args, Return> {
  const { refresh: refreshUser } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(
    (args.length !== 0 && args[0] !== undefined && !options?.skipFetch) || !!options?.callImmediately,
  );
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<Return | null>(null);

  if (error) {
    throw error;
  }

  const call = useCallback(
    async (...args: Args) => {
      try {
        setLoading(true);
        const data = apiFunction instanceof Function ? await apiFunction(...args) : null;
        setData(data);
        if (options?.successKey) {
          enqueueSnackbar(t(options.successKey), { variant: 'success' });
        }
        return data;
      } catch (err) {
        console.error(err);
        if (!options?.errorKey) {
          if (axios.isAxiosError(err) && err.response && err.response.status === 403) {
            enqueueSnackbar(t('common:error.noAccess'), { variant: 'error' });
          } else if (!(axios.isAxiosError(err) && err.response && err.response.status === 404)) {
            enqueueSnackbar(t('common:error.generic'), { variant: 'error' });
          }
        } else {
          enqueueSnackbar(t(options.errorKey), { variant: 'error' });
        }
        setData(null);
        if (axios.isAxiosError(err) && err.response) {
          if (err.response.status === 403) {
            refreshUser();
          } else if (err.response.status === 404) {
            // Allow error to be handled by error boundary, in order to display 404 message
            setError(err);
          }
          throw { code: err.response.data } as ApiException;
        }
        return null;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiFunction, enqueueSnackbar, options?.errorKey, options?.successKey],
  );

  const refresh = useCallback(async () => {
    if ((args.length !== 0 && args[0] !== undefined) || options?.callImmediately) {
      await call(...(args as Args));
    } else {
      setData(null);
    }
    // Exhaustive Deps rule does not work with spreads, therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call, ...args]);

  useEffect(() => {
    if (options?.callImmediately || !options?.skipFetch) {
      refresh();
    }
  }, [refresh, options?.skipFetch, options?.callImmediately]);

  return { isLoading, data, setData, call, refresh };
}
