export const bytesToMB = (bytes: number) => bytes / 1024 ** 2;

export const mbToBytes = (bytes: number) => bytes * 1024 ** 2;

export const formatBytes = (bytes: number | undefined, decimals = 2) => {
  if (!bytes || bytes <= 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getContentDispositionFileName = (headers?: Record<string, string | undefined>) =>
  headers?.['content-disposition']?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1].replace(/['"]/g, '') ?? '';
