import { Stack, Typography } from '@mui/material';
import { JSXElementConstructor, PropsWithChildren, ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth, useGlobalEdit } from '../../hooks';
import { PermissionProps, Styles } from '../../types';
import { Container } from '../Container';
import { RequirePermission } from '../Permission';
import { LinkTab, LinkTabs } from '../Tab';

const style: Styles = {
  pageTitleContainer: {
    pb: { xs: 2, md: 4 },
    pt: { xs: 2, md: 6 },
  },
  pageTitleWrapperContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    alignItems: { xs: 'flex-start', md: 'center' },
  },
  pageChildrenContainer: {
    pb: { xs: 2, md: 6 },
    pt: { xs: 2, md: 4 },
  },
  tabContainer: {
    pt: 4,
    pb: 4,
    flex: 1,
  },
};

interface LayoutPageProps extends PropsWithChildren {
  display?: 'Page' | 'Tab' | 'Detail';
  title?: string;
  rightTitle?: React.ReactElement;
  tabs?: React.ReactElement<React.ComponentProps<typeof LinkTab>>[];
  permissions?: PermissionProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  outlet?: ReactElement<any, string | JSXElementConstructor<any>> | null;
}

export const LayoutPage = ({
  title,
  children,
  tabs,
  rightTitle,
  permissions,
  outlet,
  display = 'Page',
}: LayoutPageProps) => {
  const { hasPermissions } = useAuth();
  const { globalEditing, setGlobalEditing } = useGlobalEdit();
  useEffect(() => {
    return () => {
      setGlobalEditing(false);
    };
  }, [setGlobalEditing]);

  // Redirect to the first available tab, if no outlet
  if ((display === 'Page' || display === 'Detail') && outlet === null && tabs) {
    for (const tab of tabs) {
      if (
        tab.props.value &&
        (!tab.props.permissions ||
          !tab.props.permissions.keys ||
          hasPermissions(tab.props.permissions.keys, tab.props.permissions.any))
      ) {
        return <Navigate replace to={tab.props.value} />;
      }
    }
  }

  const renderPage = () => (
    <>
      {title && (
        <Container sx={style.pageTitleContainer} wrapperSx={style.pageTitleWrapperContainer}>
          <Typography variant="h1">{title}</Typography>
          {rightTitle}
        </Container>
      )}
      {display === 'Detail' && children}
      <Container isBordered>
        {tabs && tabs.length > 0 && (
          <LinkTabs>
            {tabs.map((t) => (
              <LinkTab key={t.props.label} disabled={globalEditing} {...t.props} />
            ))}
          </LinkTabs>
        )}
      </Container>
      {outlet ||
        (display === 'Page' ? (
          <Container isHighlighted sx={style.pageChildrenContainer}>
            {children}
          </Container>
        ) : null)}
    </>
  );

  const renderTab = () => (
    <Container isHighlighted sx={style.tabContainer}>
      <Stack spacing={4}>
        {(title || rightTitle) && (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Typography variant="h2">{title}</Typography>
            {rightTitle}
          </Stack>
        )}
        <Stack>{children}</Stack>
      </Stack>
    </Container>
  );

  return (
    <RequirePermission {...permissions}>
      {display === 'Page' || display === 'Detail' ? renderPage() : renderTab()}
    </RequirePermission>
  );
};
