import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { VOWEL_REGEX } from './constants';
import { env } from './env';
import { LanguageCode } from './types';
import { formatShortDate } from './utils/helper';
import setLanguageHeader from './utils/setLanguageHeader';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      ns: [
        'auth',
        'common',
        'navigation',
        'categorization',
        'dashboard',
        'vendor',
        'member',
        'memberGroup',
        'user',
        'changeRequest',
        'buyGuide',
        'changeLog',
        'confidentialAgreement',
        'cms',
        'contact',
        'notification',
        'cci',
        'setting',
        'forestProduct',
        'report',
      ],
      load: 'languageOnly',
      supportedLngs: Object.values(LanguageCode),
      fallbackLng: (code) => {
        const languageCode = code.split('-')[0] as LanguageCode;
        if (Object.values(LanguageCode).includes(languageCode)) {
          return [languageCode];
        }

        return [LanguageCode.en];
      },
      debug: env.REACT_APP_ENVIRONMENT === 'dev',
      react: {
        useSuspense: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'u', 'p', 'ol', 'li'],
      },
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      i18n.services.formatter?.add('lowercase', (value) => value.toLowerCase());
      i18n.services.formatter?.add('shortDate', (value) => formatShortDate(value));
      i18n.services.formatter?.add(
        'wrapListItem',
        (value: Array<string>) => `<ul>${value.map((v) => `<li>${v}</li>`).join('')}</ul>`,
      );
      i18n.services.formatter?.add('vowelsPrefix', (value, _, options) =>
        value.match(VOWEL_REGEX) ? `${options.vowel}'${value}` : `${options.else} ${value}`,
      );
    },
  );

i18n.on('languageChanged', (lng) => {
  setLanguageHeader(lng);
});

export default i18n;
