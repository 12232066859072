import React from 'react';
import { VendorTierGrowthEntity } from '../../models';

export interface VendorTierGrowthContextType {
  isEditingOrAddingGrowth: boolean;
  tierOrGrowth: VendorTierGrowthEntity;
  setTierOrGrowth: (value: VendorTierGrowthEntity) => void;
  setIsEditingOrAddingGrowth: (value: boolean) => void;
}

export const VendorTierGrowthContext = React.createContext<VendorTierGrowthContextType>(
  {} as VendorTierGrowthContextType,
);
