import React, { PropsWithChildren, useRef, useState } from 'react';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { ConfirmationModalRef } from '../types';

export interface ConfirmationModalProps {
  open: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string | React.ReactElement;
  description?: string | React.ReactElement;
  confirmText?: string;
}

interface ConfirmationModalContextType {
  modalProps: ConfirmationModalProps;
  setModalProps: React.Dispatch<React.SetStateAction<ConfirmationModalProps>>;
  modalRef: React.MutableRefObject<ConfirmationModalRef | null>;
}

export const ConfirmationModalContext = React.createContext<ConfirmationModalContextType>(
  {} as ConfirmationModalContextType,
);

export const ConfirmationModalProvider = ({ children }: PropsWithChildren) => {
  const [modalProps, setModalProps] = useState<ConfirmationModalProps>({ open: false });
  const modalRef = useRef<ConfirmationModalRef | null>(null);

  return (
    <ConfirmationModalContext.Provider value={{ modalProps, setModalProps, modalRef }}>
      {children}
      <ConfirmationModal ref={modalRef} />
    </ConfirmationModalContext.Provider>
  );
};
