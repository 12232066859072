import { object, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorTaxInformationEntity } from '../../models';
import { EntityFormSchema } from '../../types';

export const VendorTaxInformationSchema = (): EntityFormSchema<VendorTaxInformationEntity> => ({
  schema: object({
    gstNumber: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
    qstNumber: string().notRequired().nullable().max(MAX_SHORT_TEXT_LENGTH),
    notes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
    vendorNotes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
  }),
});
