import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks';
import { palette } from '../styles/palette';
import { Archivable, PermissionProps, Styles } from '../types';
import { Container } from './Container';
import { HasPermissions } from './Permission';

const style: Styles = {
  container: {
    py: 1,
    mb: 2,
  },
  wrapper: {
    py: 0.5,
    px: 2,
    textAlign: 'center',
    backgroundColor: palette.grey[200],
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 48,
  },
  button: {
    px: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

interface ArchiveBannerProps<T extends Archivable> {
  entity: T | null;
  onUnarchive: () => void;
  permissions?: PermissionProps;
}

export const ArchiveBanner = <T extends Archivable>({ entity, onUnarchive, permissions }: ArchiveBannerProps<T>) => {
  const { t } = useTranslation();
  const { isInternalUser } = useAuth();

  return entity?.isArchived ? (
    <Container sx={style.container}>
      <Box sx={style.wrapper}>
        <Typography variant="inherit">{t('common:archiveBanner')}</Typography>
        {isInternalUser && (
          <HasPermissions {...permissions}>
            <Button sx={style.button} variant="text" onClick={onUnarchive}>
              {t('common:action.unarchive')}
            </Button>
          </HasPermissions>
        )}
      </Box>
    </Container>
  ) : (
    <></>
  );
};
