import { useTranslation } from 'react-i18next';
import { MenuItem, MenuItemProps } from './MenuItem';
import { Archivable, Entity } from '../../types';

interface DuplicateMenuItemProps<T extends Entity & Archivable> extends MenuItemProps {
  entity: T;
  onClick: () => void;
}

export const DuplicateMenuItem = <T extends Entity & Archivable>({
  entity,
  onClick,
  ...props
}: DuplicateMenuItemProps<T>) => {
  const { t } = useTranslation();

  return (
    <MenuItem {...props} id="duplicate" key="duplicate" onClick={onClick} disabled={entity.isArchived}>
      {t('common:action.duplicate')}
    </MenuItem>
  );
};
