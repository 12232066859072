import { AddRounded, ArchiveRounded, EditRounded, ModeCommentRounded, UnarchiveRounded } from '@mui/icons-material';
import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { forestProduct as forestProductApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { ForestProductGroupContext } from '../../../contexts/ForestProduct';
import { useApi, useGlobalEdit, useInternationalization } from '../../../hooks';
import { ForestProductEntity } from '../../../models';
import { ForestProductSchema } from '../../../schemas';
import { ForestProductFilter, OrderBy, PermissionKey } from '../../../types';
import { EditDrawer } from '../../EditDrawer';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { ForestProductForm } from './ForestProductForm';

const defaultFilter: ForestProductFilter = {
  isArchived: false,
  ...DEFAULT_PAGINATION_FILTER,
};

export const ForestProductList = () => {
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const [product, setProduct] = useState(new ForestProductEntity());
  const [filter, setFilter] = useState(defaultFilter);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { forestProductGroup, forestProductGroupId } = useContext(ForestProductGroupContext);

  const { data, isLoading, refresh } = useApi(
    forestProductApi.getAllForestProducts,
    null,
    forestProductGroupId,
    filter,
  );

  const { call: create } = useApi(forestProductApi.createForestProduct, { successKey: 'common:success.save' });
  const { call: update } = useApi(forestProductApi.updateForestProduct, { successKey: 'common:success.save' });
  const archiveApi = useApi(forestProductApi.archiveForestProduct, { successKey: 'common:success.action' });

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: ForestProductEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: ForestProductEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onSave = async (entity: ForestProductEntity) => {
    return await (entity.id ? update(forestProductGroupId, entity) : create(forestProductGroupId, entity));
  };

  const onArchiveConfirm = async (isArchived: boolean, products: ForestProductEntity[]) => {
    await archiveApi.call(
      forestProductGroupId,
      products.map((i) => i.id),
      isArchived,
    );
    refresh();
  };

  const renderFilter = () => <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext={'male'} />;

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderActionsMenuItems = (entity: ForestProductEntity, onClick: () => void) => [
    <MenuItem
      id="edit"
      key="edit"
      disabled={forestProductGroup?.isArchived}
      onClick={() => {
        setProduct(entity);
        setIsDrawerOpen(true);
      }}
    >
      {t('common:edit')}
    </MenuItem>,
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={getTranslation(entity, 'name')}
      onClick={onClick}
      disabled={forestProductGroup?.isArchived}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('forestProduct:products.actions.suffix')}
      permissions={{ keys: PermissionKey.CommodityGroupManage }}
    />,
  ];

  const renderTable = () =>
    data && (
      <Table
        data={data}
        translationNamespace="forestProduct:products"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
        bulkActions={!forestProductGroup?.isArchived ? bulkActions : undefined}
        renderBulkSelection={(items) =>
          items.length === 1 ? getTranslation(items[0], 'name') : t('forestProduct:products.title')
        }
      >
        <TableColumn type="property" width="50%" id="name_En" sortable />
        <TableColumn type="property" width="50%" id="name_Fr" sortable />
        <TableColumn type="icon" tooltip id="notes" align="center" iconComponent={ModeCommentRounded} />
      </Table>
    );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.CommodityGroupManage}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        disabled={forestProductGroup?.isArchived || globalEditing}
        onClick={() => {
          setProduct(new ForestProductEntity());
          setIsDrawerOpen(true);
        }}
      >
        {t('forestProduct:products.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {product.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(product.id ? 'forestProduct:products.actions.edit' : 'forestProduct:products.actions.add')}
          </Typography>
        </Stack>
      }
      open={isDrawerOpen}
      entity={product}
      schema={ForestProductSchema()}
      onSave={async (entity) => {
        const createdEntity = await onSave(entity);
        await refresh();
        return createdEntity;
      }}
      onConfirm={async () => {
        await refresh();
        setIsDrawerOpen(false);
      }}
      onCancel={() => setIsDrawerOpen(false)}
    >
      <ForestProductForm />
    </EditDrawer>
  );

  return (
    <LayoutPage title={t('forestProduct:products.title')} rightTitle={renderAddButton()} display="Tab">
      {renderFilter()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
