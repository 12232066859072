import { CircularProgress } from '@mui/material';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { forestProduct as forestProductApi } from '../../../api';
import { ForestProductPriceUpdateContext } from '../../../contexts/ForestProduct';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../hooks';
import { ForestProductPriceUpdateEntity } from '../../../models';
import { routes } from '../../../routes';
import { ForestProductPriceUpdateSchema } from '../../../schemas';
import { PermissionKey, Styles } from '../../../types';
import { formatShortDate } from '../../../utils/helper';
import { ArchiveBanner } from '../../ArchiveBanner';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { LinkTab } from '../../Tab';
import { ForestProductPriceUpdateForm } from './ForestProductPriceUpdateForm';
import { ForestProductPriceUpdateHeader } from './ForestProductPriceUpdateHeader';

const style: Styles = {
  title: {
    pb: 4,
  },
  loading: {
    mx: 2,
  },
};

export const ForestProductPriceUpdateDetail = () => {
  const { t, getTranslation } = useInternationalization();
  const { isInternalUser } = useAuth();
  const outlet = useOutlet();
  const params = useParams();
  const priceUpdateId = Number(params.priceUpdateId);
  const { data, isLoading, refresh } = useApi(forestProductApi.getForestProductPriceUpdate, null, priceUpdateId);
  const { call } = useApi(forestProductApi.updateForestProductPriceUpdate, { successKey: 'common:success.save' });
  usePageTitle(data ? formatShortDate(data.date) : '');

  const archiveApi = useApi(forestProductApi.archiveForestProductPriceUpdate, { successKey: 'common:success.action' });

  const onArchiveConfirm = async (isArchived: boolean, entity: ForestProductPriceUpdateEntity) => {
    await archiveApi.call([entity.id], isArchived);
    refresh();
  };

  const renderActionMenuItems = (onClick: () => void) =>
    data && isInternalUser
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={formatShortDate(data.date)}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('forestProduct:priceUpdates.actions.suffix')}
          />,
        ]
      : [];

  const tabs = data?.products
    ?.map((product) => product.forestProduct.forestProductGroup)
    .filter((group, index, groups) => groups.findIndex((g) => g.id === group.id) === index)
    .map((group) => (
      <LinkTab
        label={getTranslation(group, 'name')}
        value={generatePath(routes.Admin.ForestProductPriceUpdateDetail.Groups.path, {
          priceUpdateId: priceUpdateId,
          productGroupId: group.id,
        })}
      />
    ));

  return (
    <ForestProductPriceUpdateContext.Provider value={{ priceUpdate: data, refresh }}>
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
        <ArchiveBanner
          permissions={{ keys: PermissionKey.CommodityPriceEdit }}
          entity={data}
          onUnarchive={() => data && onArchiveConfirm(false, data)}
        />
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              permissions={{ keys: PermissionKey.CommodityPriceEdit }}
              titleLabel=""
              title={formatShortDate(data?.date)}
              header={<ForestProductPriceUpdateHeader />}
              disabled={data.isArchived}
              readOnly={!isInternalUser}
              entity={data}
              schema={ForestProductPriceUpdateSchema()}
              onSave={call}
              actionMenuItems={renderActionMenuItems}
              onConfirm={() => refresh()}
              breadcrumbs={[
                {
                  title: t('forestProduct:priceUpdates.title'),
                  href: routes.Admin.ForestProducts.PriceUpdates.path,
                },
                { title: formatShortDate(data?.date) },
              ]}
            >
              <ForestProductPriceUpdateForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </ForestProductPriceUpdateContext.Provider>
  );
};
