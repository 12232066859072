import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography, alpha } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { profile as profileApi } from '../api';
import { ConfidentialAgreementContext } from '../contexts/ConfidentialAgreementContext';
import { useApi, useAuth, useInternationalization } from '../hooks';
import { routes } from '../routes';
import { palette } from '../styles/palette';
import { Styles } from '../types';
import { Drawer } from './Drawer';

const style: Styles = {
  paperDrawer: {
    backgroundColor: 'transparent',
    width: {
      xs: '100%',
      md: 'inherit',
    },
  },
  drawer: {
    '.MuiBackdrop-root': {
      backgroundColor: alpha(palette.primary.dark, 0.95),
    },
  },
  container: {
    minWidth: {
      xs: '100%',
      md: 700,
    },
    maxWidth: {
      xs: '100%',
      lg: '30vw',
    },
    overflow: 'hidden',
    backgroundColor: palette.white,
    borderTopLeftRadius: '12px',
    outline: 'none',
    flex: 1,
    marginTop: 1,
    spacing: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    px: 3,
    color: palette.white,
    alignItems: 'center',
  },
  titleBox: {
    minHeight: 47,
    display: 'flex',
    px: 2,
    backgroundColor: palette.primary.dark,
    '>svg': {
      marginRight: 1,
    },
  },
  body: {
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 4,
    overflowY: 'auto',
  },
  actionBox: {
    minHeight: 78,
    backgroundColor: palette.primary.dark,
  },
  button: {
    color: palette.primary.deep,
  },
};

interface Props {
  children: JSX.Element;
}

export const ConfidentialAgreement = (props: Props) => {
  const { t } = useInternationalization();
  const navigate = useNavigate();
  const { user, refresh: refreshProfile } = useAuth();
  const [isOpen, setIsOpen] = useState(!!user?.profile.mustAcceptConfidentialityAgreement);
  const { call, isLoading } = useApi(profileApi.acceptConfidentialAgreement, null);

  const confidentialAgreementButton = (
    <Button onClick={() => setIsOpen(true)} sx={style.button} startIcon={<InsertDriveFileIcon />}>
      {t('confidentialAgreement:button')}
    </Button>
  );

  const onDrawerClose = (_: object, reason: 'backdropClick') => reason !== 'backdropClick' && onClose;
  const onClose = () => navigate(routes.Member.Dashboard.path, { state: { ignoreBlocker: true } });

  const onAccept = async (e: MouseEvent<HTMLButtonElement>) => {
    //to fix NS_BINDING_ABORTED error on Firefox
    e.preventDefault();
    const result = await call();
    await refreshProfile();
    !!result && setIsOpen(false);
  };

  return (
    <ConfidentialAgreementContext.Provider value={{ confidentialAgreementButton }}>
      <Drawer
        PaperProps={{ sx: style.paperDrawer }}
        sx={style.drawer}
        anchor="right"
        open={isOpen}
        onClose={onDrawerClose}
        disableEscapeKeyDown
      >
        <Box sx={[style.container, style.containerLarge]} component="form">
          <Box sx={[style.box, style.titleBox]} display="flex" alignItems="center">
            <InsertDriveFileIcon />
            <Typography variant="drawerTitle">{t('confidentialAgreement:button')}</Typography>
          </Box>
          <Box sx={style.body}>
            <h2>{t('confidentialAgreement:title')}</h2>
            <Typography whiteSpace="pre-wrap">{t('confidentialAgreement:body')}</Typography>
          </Box>
          <Stack spacing={3} direction="row" sx={[style.box, style.actionBox]} justifyContent="flex-end">
            {user?.profile.mustAcceptConfidentialityAgreement ? (
              <>
                <Button onClick={onClose} variant="outlined" color="secondary" size="small">
                  {t('common:decline')}
                </Button>
                <LoadingButton loading={isLoading} variant="contained" size="small" type="submit" onClick={onAccept}>
                  {t('common:accept')}
                </LoadingButton>
              </>
            ) : (
              <Button onClick={() => setIsOpen(false)} variant="contained" size="small">
                {t('common:close')}
              </Button>
            )}
          </Stack>
        </Box>
      </Drawer>
      {props.children}
    </ConfidentialAgreementContext.Provider>
  );
};
