import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '..';
import { StaticField } from '../Base/StaticField';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ControlledAutocompleteProps<T, Args extends any[]>
  extends React.ComponentProps<typeof Autocomplete<T, Args>> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  label?: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptionValue?: (item: T) => any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ControlledAutocomplete = <T, Args extends any[]>({
  name,
  control,
  label,
  required,
  getOptionValue,
  ...props
}: ControlledAutocompleteProps<T, Args>) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur }, fieldState: { error } }) =>
        control._options.context?.readOnly ? (
          <StaticField label={label} value={props.value} />
        ) : (
          <Autocomplete
            {...props}
            name={name}
            onBlur={onBlur}
            label={required ? `${label}*` : label}
            onSelectItem={(item: T | null) => {
              onChange(getOptionValue && item ? getOptionValue(item) : item);
            }}
            error={!!error}
            helperText={error?.message && t(error?.message, { name: label })}
          />
        )
      }
    />
  );
};
