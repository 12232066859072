import { Breakpoint, useMediaQuery } from '@mui/material';
import theme from '../styles/theme';

export function useBreakpoints(): Breakpoint {
  const isXs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

  if (isXs) {
    return 'xs';
  }
  if (isSm) {
    return 'sm';
  }
  if (isMd) {
    return 'md';
  }

  if (isLg) {
    return 'lg';
  }

  return 'xl';
}
