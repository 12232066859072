import { FiberManualRecord, FilterAltRounded, ViewColumnRounded, ViewListRounded } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Menu, Stack, Typography } from '@mui/material';
import { add } from 'date-fns';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { routes } from '../../../routes';
import { palette } from '../../../styles/palette';
import theme from '../../../styles/theme';
import { EventFilter, EventType, PermissionKey, Styles } from '../../../types';
import { getEventTypeBackgroundColor, getEventTypeIcon } from '../../../utils/eventCalendar';
import { areArrayEquals, formatShortDate } from '../../../utils/helper';
import { Checkbox } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { CmsEventsCalendar } from './CmsEventsCalendar';
import { CmsEventsList } from './CmsEventsList';

const style: Styles = {
  filterContainer: {
    flexDirection: { xs: 'column', md: 'row' },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: { xs: 'flex-start', md: 'center' },
    gap: { xs: 1, md: undefined },
  },
  viewButton: {
    color: palette.primary.deep,
  },
  filterCircleIcon: {
    fontSize: 14,
  },
  filterMenuPaper: {
    ...(theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps?.sx as React.CSSProperties),
    minWidth: 300,
  },
};

const defaultFilter: EventFilter = {
  favouriteVendorsOnly: true,
  types: Object.values(EventType),
  effectiveDate: undefined,
  expiryDate: undefined,
  ...MAX_PAGINATION_FILTER,
};

export const CmsEvents = () => {
  const { t } = useInternationalization();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(defaultFilter);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data, isLoading, call } = useApi(cmsApi.getAllEvents, { callImmediately: false });
  const [currentView, setCurrentView] = useState<'calendar' | 'list'>('calendar');
  const favouriteVendorsOptions = [
    { label: t('cms:events.filter.allVendors'), value: false },
    { label: t('cms:events.filter.favouritesOnly'), value: true },
  ];
  const eventTypeOptions = useEnumList(EventType, 'cms:events.type');
  const [selectedFilterValues, setSelectedFilterValues] = useState<EventType[]>([]);
  const [shouldRenderNoFavourites, setShouldRenderNoFavourites] = useState(false);
  usePageTitle('cms:events.title');

  useEffect(() => {
    (async () => {
      if (filter.expiryDate && filter.effectiveDate) {
        const result = await call(filter);
        setShouldRenderNoFavourites(!!result && !!filter.favouriteVendorsOnly && !result.hasFavouriteVendors);
      }
    })();
  }, [filter, call]);

  const onFavouriteVendorsOnlyChange = (favouriteVendorsOnly: boolean) => () => {
    setFilter((prev) => ({ ...prev, favouriteVendorsOnly }));
  };

  const onSelectedTypeChange = (type: EventType) => (e: MouseEvent<HTMLElement>) => {
    if (selectedFilterValues.includes(type)) {
      setSelectedFilterValues((prev) => prev.filter((v) => v !== type));
    } else {
      setSelectedFilterValues((prev) => [...prev, type]);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const onViewToggle = () => {
    const newView = currentView === 'calendar' ? 'list' : 'calendar';
    if (newView === 'list') {
      setFilter((prev) => ({
        ...prev,
        effectiveDate: formatShortDate(new Date()),
        expiryDate: formatShortDate(add(new Date(), { days: 7 })),
      }));
    }
    setCurrentView(newView);
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSelectedFilterValues([...(filter.types ?? [])]);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    if (!areArrayEquals(selectedFilterValues, filter.types ?? [])) {
      setFilter((prev) => ({ ...prev, types: selectedFilterValues }));
    }
  };

  const onFilterChange = useCallback((filter: Partial<EventFilter>, view?: 'calendar' | 'list') => {
    setFilter((prev) => ({ ...prev, ...filter }));
    if (view) {
      setCurrentView(view);
    }
  }, []);

  const renderEventTypeMenu = () => (
    <Menu
      id="table-menu"
      MenuListProps={theme.components?.MuiSelect?.defaultProps?.MenuProps?.MenuListProps}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleMenuClose}
      PaperProps={{ ...theme.components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps, sx: style.filterMenuPaper }}
    >
      {eventTypeOptions.map((type) => {
        const permissionKeys: PermissionKey[] = [];
        if ([EventType.PoolBuy, EventType.SpecialBuy, EventType.Booking].includes(type.value)) {
          permissionKeys.push(PermissionKey.VendorViewBuyGuide);
        }

        const label = (
          <Box display="flex" alignItems="center">
            <FiberManualRecord sx={style.filterCircleIcon} htmlColor={getEventTypeBackgroundColor(type.value)} />
            {getEventTypeIcon(type.value, 'black')}
            <Typography display="block" variant="label" ml={1}>
              {type.label}
            </Typography>
          </Box>
        );
        return (
          <MenuItem
            onClick={onSelectedTypeChange(type.value)}
            permissions={{ keys: permissionKeys.length ? permissionKeys : undefined }}
            key={type.value}
          >
            <Checkbox
              onClick={onSelectedTypeChange(type.value)}
              value={selectedFilterValues.includes(type.value)}
              checked={selectedFilterValues.includes(type.value)}
              label={label}
            />
          </MenuItem>
        );
      })}
    </Menu>
  );

  const renderSearch = () => (
    <Box sx={style.filterContainer}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography align="center" variant="label" color="grey.600">
          {t('common:show')}
        </Typography>
        <Stack spacing={0.7} direction="row">
          {favouriteVendorsOptions.map((option) => (
            <Chip
              key={option.value.toString()}
              label={option.label}
              onClick={onFavouriteVendorsOnlyChange(option.value)}
              color={option.value === filter.favouriteVendorsOnly ? 'primary' : 'secondary'}
            />
          ))}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <IconButton id="filter-menu" disabled={isLoading} onClick={(e) => handleMenuOpen(e)}>
          <FilterAltRounded htmlColor={palette.secondary.dark} />
        </IconButton>
        <Button
          sx={style.viewButton}
          onClick={onViewToggle}
          startIcon={currentView == 'calendar' ? <ViewListRounded /> : <ViewColumnRounded />}
        >
          {currentView == 'calendar' ? t('cms:events.filter.listView') : t('cms:events.filter.calendarView')}
        </Button>
      </Stack>
    </Box>
  );

  const renderNoFavourites = () => (
    <Stack alignItems="center" spacing={4}>
      <Stack alignItems="center" spacing={1}>
        <Typography variant="h3">{t('cms:events.noFavouriteVendorsTitle')}</Typography>
        <Typography variant="h4"> {t('cms:events.noFavouriteVendorsDescription')}</Typography>
      </Stack>
      <Button variant="contained" size="small" onClick={() => navigate(generatePath(routes.Member.BuyGuide.path))}>
        {t('cms:events.viewVendors')}
      </Button>
    </Stack>
  );

  const renderView = () =>
    currentView == 'calendar' ? (
      <CmsEventsCalendar
        isLoading={isLoading || !data}
        onFilterChange={onFilterChange}
        events={data?.data ?? []}
        emptyPlaceholder={shouldRenderNoFavourites ? renderNoFavourites() : undefined}
      />
    ) : (
      <CmsEventsList
        filter={filter}
        isLoading={isLoading || !data}
        onFilterChange={onFilterChange}
        events={data?.data ?? []}
        emptyPlaceholder={shouldRenderNoFavourites ? renderNoFavourites() : undefined}
      />
    );

  return (
    <LayoutPage title={t('cms:events.title')}>
      {renderSearch()}
      {renderView()}
      {renderEventTypeMenu()}
    </LayoutPage>
  );
};
