import { DownloadRounded, ModeCommentRounded } from '@mui/icons-material';
import CalculateIcon from '@mui/icons-material/Calculate';
import CommentIcon from '@mui/icons-material/Comment';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import StairsIcon from '@mui/icons-material/Stairs';
import { IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { EntityPeriod, LimitCommaValues } from '..';
import { vendor as vendorApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { BuyGuideVendorDetailContext } from '../../contexts/BuyGuide/BuyGuideVendorDetailContext';
import { useApi, useAuth, useInternationalization } from '../../hooks';
import { VendorRebateCategoryProgramEntity } from '../../models/Vendor/VendorRebateCategoryProgramEntity';
import { routes } from '../../routes';
import { palette } from '../../styles/palette';
import { EntityType, FilterPeriod, PermissionKey, Styles, VendorRebateCategoryProgramFilter } from '../../types';
import { downloadBlob, formatShortDate } from '../../utils/helper';
import { calculateTermValue } from '../../utils/rebate';
import { CardList, CardListElement, CardListRow } from '../Card';
import { Container, FilterContainer } from '../Container';
import { PeriodFilterSelect } from '../Filter/PeriodFilterSelect';
import { StaticField } from '../Form';
import { VendorRebateCategoryRebateSummary } from '../Vendor/RebateCategories';
import { BuyGuideVendorRebateProgramCalculator } from './BuyGuideVendorRebateProgramCalculator';

const style: Styles = {
  container: {
    py: 4,
  },
  header: {
    pb: 3,
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      md: '180px',
    },
  },
  selectLargeContainer: {
    width: {
      xs: '100%',
      md: '380px',
    },
  },
  commentIcon: {
    fontSize: 15,
  },
  modifiedDateCardRow: {
    mt: -2,
    mb: 1,
    mr: 0.5,
  },
};

const defaultAdvancedSearchFilter: VendorRebateCategoryProgramFilter = {
  period: FilterPeriod.Current,
  isArchived: false,
};

export const BuyGuideVendorRebateProgramList = () => {
  const internationalization = useInternationalization();
  const { t, getTranslation } = internationalization;
  const { hasPermissions } = useAuth();
  const navigate = useNavigate();
  const { vendorId } = useContext(BuyGuideVendorDetailContext);
  const [filter, setFilter] = useState<VendorRebateCategoryProgramFilter>({
    ...DEFAULT_PAGINATION_FILTER,
    ...defaultAdvancedSearchFilter,
  });
  const [calculatedProgram, setCalculatedProgram] = useState<VendorRebateCategoryProgramEntity | undefined>();
  const { data: programs, isLoading } = useApi(vendorApi.getAllRebatePrograms, null, vendorId, filter);
  const { call: downloadExport, isLoading: isExportLoading } = useApi(vendorApi.export, null);

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const onCalculatorClose = () => {
    setCalculatedProgram(undefined);
  };

  const onExportClick = async () => {
    const fileData = await downloadExport(vendorId, filter.period, [EntityType.RebateProgram]);
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
    }
  };

  const getLinkDestination = (item: VendorRebateCategoryProgramEntity) =>
    generatePath(routes.Member.BuyGuideRebateProgramDetail.path, {
      vendorId,
      rebateProgramId: item.id,
    });

  const renderActionsMenuItems = (entity: VendorRebateCategoryProgramEntity) => [
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(
          generatePath(routes.Member.BuyGuideRebateProgramDetail.path, {
            vendorId,
            rebateProgramId: entity.id,
          }),
        );
      }}
    >
      {t('common:view')}
    </MenuItem>,
  ];

  const renderDesignations = (item: VendorRebateCategoryProgramEntity) => (
    <LimitCommaValues
      value={item.vendorDesignations.map((designation) => getTranslation(designation, 'name')).join(', ')}
      maxLength={50}
    />
  );

  const renderFilterPeriod = (filterPeriod?: FilterPeriod) => (
    <EntityPeriod period={filterPeriod} translationContext="female" />
  );

  const renderModifiedDate = (item: VendorRebateCategoryProgramEntity) => (
    <Typography variant="caption">
      {item.modifiedDate ? t(`buyGuide:card.lastModified`, { date: formatShortDate(item.modifiedDate) }) : ''}
    </Typography>
  );

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <PeriodFilterSelect filter={filter} setFilter={setFilter} />
    </FilterContainer>
  );

  const renderExportButton = () => (
    <IconButton onClick={onExportClick} disabled={isExportLoading} key="export">
      <Tooltip arrow placement="top" title={t(`common:export`)}>
        <DownloadRounded htmlColor={palette.primary.deep} />
      </Tooltip>
    </IconButton>
  );

  return (
    <Container isHighlighted sx={style.container}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={style.header}>
        <Typography variant="h2">{t('buyGuide:rebatePrograms.title')}</Typography>
        {renderExportButton()}
      </Stack>
      {renderAdvancedSearch()}
      <CardList
        data={programs ?? []}
        translationNamespace="buyGuide"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        hideResultCount
        isLoading={isLoading}
        actionMenuItems={renderActionsMenuItems}
        href={getLinkDestination}
        linkOnlyInTitle
      >
        <CardListRow>
          <CardListElement type="title" id="name" translated hideLabel />
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) => renderFilterPeriod(item.period)}
            id="period"
            rawRender
            hideLabel
          />
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) => (
              <Tooltip title={t('buyGuide:card.tierGrowth')} placement="top" arrow>
                <IconButton
                  disabled={isLoading}
                  onClick={() =>
                    navigate(
                      generatePath(routes.Member.BuyGuideRebateProgramDetail.TiersAndGrowths.path, {
                        vendorId,
                        rebateProgramId: item.id,
                      }),
                    )
                  }
                >
                  <Stack direction="row" alignItems="center">
                    <StairsIcon htmlColor={palette.primary.deep} />
                    <Typography>{item.rebateCategory.tiersCount + item.rebateCategory.growthsCount}</Typography>
                  </Stack>
                </IconButton>
              </Tooltip>
            )}
            rawRender
            id="tierGrowth"
            align="right"
            hideLabel
            decorator
            hideIf={(item) =>
              !hasPermissions(PermissionKey.VendorViewRebateTiersGrowths) ||
              !(item.rebateCategory.tiersCount || item.rebateCategory.growthsCount)
            }
          />
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) =>
              item && (
                <IconButton
                  disabled={isLoading}
                  onClick={() =>
                    navigate(
                      generatePath(routes.Member.BuyGuideRebateProgramDetail.Documents.path, {
                        vendorId,
                        rebateProgramId: item.id,
                      }),
                    )
                  }
                >
                  <Tooltip title={t('buyGuide:card.files')} placement="top" arrow>
                    <Stack direction="row" alignItems="center">
                      <FilePresentRoundedIcon htmlColor={palette.primary.deep} />
                      <Typography>{item.filesCount}</Typography>
                    </Stack>
                  </Tooltip>
                </IconButton>
              )
            }
            rawRender
            id="files"
            align="right"
            hideLabel
            decorator
            hideIf={(item) => !hasPermissions(PermissionKey.VendorViewFiles) || !item.filesCount}
          />
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) =>
              item.notes && (
                <Tooltip title={item.notes} placement="top" arrow>
                  <ModeCommentRounded htmlColor={palette.primary.deep} />
                </Tooltip>
              )
            }
            rawRender
            id="notes"
            hideLabel
            decorator
            hideIf={(item) => !item.notes}
          />
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) => (
              <IconButton disabled={isLoading} onClick={() => setCalculatedProgram(item)}>
                <Tooltip title={t('buyGuide:card.calculator')} placement="top" arrow>
                  <CalculateIcon htmlColor={palette.primary.deep} />
                </Tooltip>
              </IconButton>
            )}
            rawRender
            id="calculator"
            align="right"
            hideLabel
            decorator
            hideIf={() => !hasPermissions(PermissionKey.VendorViewPrograms)}
          />
        </CardListRow>

        <CardListRow noUnderline justifyContent="flex-end" sx={style.modifiedDateCardRow}>
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) => renderModifiedDate(item)}
            id={'modifiedDate'}
            rawRender
            hideLabel
          />
        </CardListRow>

        <CardListRow justifyContent="start" alignItems="start">
          <CardListElement type="property" id="number" />
          <CardListElement
            type="custom"
            rawRender
            render={(item: VendorRebateCategoryProgramEntity) => renderDesignations(item)}
            id="vendorDesignations"
          />
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) => item.rebateCategory.responsibleBuyer}
            id="responsibleBuyer"
          />
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) => formatShortDate(item.effectiveDate)}
            id="effectiveDate"
          />
          <CardListElement
            type="custom"
            render={(item: VendorRebateCategoryProgramEntity) => formatShortDate(item.expiryDate)}
            id="expiryDate"
          />
        </CardListRow>
        {hasPermissions([PermissionKey.VendorViewRebateTerms, PermissionKey.VendorViewPrograms], true) && (
          <CardListRow justifyContent="start" noUnderline alignItems="start">
            <CardListElement
              type="custom"
              hideLabel
              rawRender
              hideIf={() => !hasPermissions(PermissionKey.VendorViewRebateTerms)}
              render={(item: VendorRebateCategoryProgramEntity) => (
                <StaticField
                  label={t('buyGuide:card.termValue')}
                  value={calculateTermValue(item.rebateCategory.rebate, internationalization)}
                  icon={
                    getTranslation(item.rebateCategory.rebate, 'termNote') ? (
                      <Tooltip
                        title={
                          <Typography variant="tooltip" whiteSpace="pre-wrap">{`${getTranslation(
                            item.rebateCategory.rebate,
                            'termNote',
                          )}`}</Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <CommentIcon sx={style.commentIcon} htmlColor={palette.grey[500]} />
                      </Tooltip>
                    ) : undefined
                  }
                />
              )}
              id="termValue"
            />
            <CardListElement
              type="custom"
              render={(item: VendorRebateCategoryProgramEntity) =>
                getTranslation(item.rebateCategory.rebate, 'memberPaymentDate')
              }
              id="memberPaymentDate"
              hideIf={() => !hasPermissions(PermissionKey.VendorViewRebateTerms)}
            />
            <CardListElement
              type="custom"
              hideLabel
              rawRender
              render={(item: VendorRebateCategoryProgramEntity) => (
                <StaticField
                  label={t('buyGuide:card.calculationMethod')}
                  value={
                    item.rebateCategory.rebate.calculationMethod
                      ? getTranslation(item.rebateCategory.rebate.calculationMethod, 'value')
                      : undefined
                  }
                  icon={
                    getTranslation(item.rebateCategory.rebate, 'calculationMethodMemberNotes') ? (
                      <Tooltip
                        title={
                          <Typography variant="tooltip" whiteSpace="pre-wrap">
                            {getTranslation(item.rebateCategory.rebate, 'calculationMethodMemberNotes')}
                          </Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <CommentIcon sx={style.commentIcon} htmlColor={palette.grey[500]} />
                      </Tooltip>
                    ) : undefined
                  }
                />
              )}
              id="calculationMethod"
              hideIf={() => !hasPermissions(PermissionKey.VendorViewPrograms)}
            />
          </CardListRow>
        )}

        {hasPermissions(
          [
            PermissionKey.VendorViewRebateFOID,
            PermissionKey.VendorViewPrograms,
            PermissionKey.VendorViewRebateMP,
            PermissionKey.VendorViewRebateQP,
            PermissionKey.VendorViewRebateSP,
            PermissionKey.VendorViewRebateAP,
          ],
          true,
        ) && (
          <CardListRow>
            <CardListElement
              type="custom"
              rawRender
              render={(item: VendorRebateCategoryProgramEntity) => (
                <VendorRebateCategoryRebateSummary summary={item.payableRebatesSummary} foid={item.foid} />
              )}
              id="payableRebatesSummary"
              hideLabel
            />
          </CardListRow>
        )}
      </CardList>
      {calculatedProgram && (
        <BuyGuideVendorRebateProgramCalculator
          selectedProgram={calculatedProgram}
          programs={programs?.data ?? []}
          vendorId={vendorId}
          onClose={onCalculatorClose}
        />
      )}
    </Container>
  );
};
