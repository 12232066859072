import { AddRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { generatePath, useParams } from 'react-router-dom';
import { vendor as vendorApi } from '../../../api';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { RebateCategoryEntity, VendorBuyingAgreementRebateCategoryEntity } from '../../../models';
import { routes } from '../../../routes';
import { VendorRebateCategoryDuplicateSchema } from '../../../schemas';
import { RebateCategoryDuplicate } from '../../../types';
import { EditDrawer } from '../../EditDrawer';
import { VendorRebateCategoryDuplicateForm } from './VendorRebateCategoryDuplicateForm';

interface VendorRebateCategoryDuplicateDrawerProps {
  rebateCategory: RebateCategoryEntity | null;
  onClose: () => void;
  refresh?: () => Promise<void>;
}

export const VendorRebateCategoryDuplicateDrawer = ({
  rebateCategory,
  onClose,
  refresh,
}: VendorRebateCategoryDuplicateDrawerProps) => {
  const { t } = useInternationalization();
  const { user } = useAuth();
  const params = useParams();
  const vendorId = Number(user?.profile.vendor?.id ?? params.vendorId);
  const isUnderVBA = rebateCategory instanceof VendorBuyingAgreementRebateCategoryEntity;
  const duplicateApi = useApi(
    isUnderVBA ? vendorApi.duplicateBuyingAgreementRebateCategory : vendorApi.duplicateRebateCategory,
    { successKey: 'common:success.save' },
  );

  const buildRebateCategoryDuplicateForm = (entity: RebateCategoryEntity): RebateCategoryDuplicate => ({
    name_En: entity.name_En,
    name_Fr: entity.name_Fr,
    buyingAgreementId: entity.vendorBuyingAgreementId ?? -1,
    rebateCategoryId: entity.id,
    vendorId,
    ...(!isUnderVBA
      ? {
          tierIds: entity.tierIds,
          growthIds: entity.growthIds,
          members: entity.targetedMembership?.members,
          memberGroups: entity.targetedMembership?.memberGroups,
        }
      : {}),
  });

  return (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('vendor:rebateCategory.actions.duplicate')}</Typography>
        </Stack>
      }
      open={!!rebateCategory}
      entity={rebateCategory as RebateCategoryEntity}
      schema={VendorRebateCategoryDuplicateSchema()}
      redirectPath={(entity) =>
        generatePath(
          isUnderVBA ? routes.Admin.Vendor.BuyingAgreementRebateCategory.path : routes.Admin.Vendor.RebateCategory.path,
          {
            vendorId,
            vendorBuyingAgreementId: entity.vendorBuyingAgreementId ?? -1,
            vendorRebateCategoryId: entity.id,
          },
        )
      }
      redirectLabel={t('vendor:rebateCategory.actions.saveOpen')}
      onSave={async (entity) => {
        if (entity) {
          const createdEntity = await duplicateApi.call(buildRebateCategoryDuplicateForm(entity));
          await refresh?.();
          return createdEntity;
        }
        return null;
      }}
      onConfirm={() => {
        refresh?.();
        onClose();
      }}
      onCancel={onClose}
    >
      {rebateCategory?.id && (
        <VendorRebateCategoryDuplicateForm
          showVendorBuyingAgreementSelect={!isUnderVBA}
          rebateCategoryId={rebateCategory.id}
        />
      )}
    </EditDrawer>
  );
};
