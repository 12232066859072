import { alpha, createTheme, lighten, LinkProps, Shadows } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { LinkBehaviour } from '../components/Routes/LinkBehaviour';
import { palette } from './palette';
import { typography } from './typography';

const selectPaperSx = {
  ...typography.selectOutlineValue,
  boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.14)',
  '& li': {
    minHeight: '48px !important',
    whiteSpace: 'normal',
    height: 'auto !important',
  },
  '& .MuiFormControlLabel-label': {
    fontWeight: typography.selectOutlineValue.fontWeight + ' !important',
    fontSize: typography.selectOutlineValue.fontSize + ' !important',
  },
  '& li:hover': {
    backgroundColor: palette.grey[100] + '!important',
  },
  '& li.Mui-focused, li:focus': {
    backgroundColor: palette.grey[200] + '!important',
  },
  "&& .Mui-selected, && li[aria-selected='true']": {
    backgroundColor: palette.primary.main + '!important',
    color: palette.white + ' !important',
    '& .MuiFormControlLabel-label': {
      color: palette.white + ' !important',
    },
  },
};

export default createTheme({
  palette,
  shadows: Array(25).fill('none') as Shadows,
  typography,
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          marginTop: '8px',
          borderBottom: 'none',
          overflow: 'visible',
        },
      },
      defaultProps: {
        TabIndicatorProps: {
          style: {
            background: palette.primary.blue,
            height: '3px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography.tabLabel,
          borderBottom: 'solid 3px transparent',
          '&.Mui-disabled': {
            color: palette.grey[400],
          },
          '&.Mui-selected': {
            color: palette.primary.blue,
          },
          '&:hover': {
            color: palette.grey[600],
            borderBottomColor: palette.grey[300],
          },
          '&.Mui-selected:hover': {
            borderBottomColor: 'transparent',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        component: LinkBehaviour,
        color: 'inherit',
      } as LinkProps,
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '.MuiTableCell-root': {
            ...typography.tableRow,
            color: palette.grey[700],
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          background: palette.white,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          '.MuiTableCell-root': {
            backgroundColor: palette.grey[200],
            color: palette.grey[500],
            padding: 8,
            paddingLeft: 4,
            paddingRight: 4,
            '&:first-of-type': {
              paddingLeft: 12,
            },
            '&:last-child': {
              paddingRight: 12,
            },
          },
        },
        root: {
          '.MuiTableCell-root': {
            ...typography.tableRow,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 4,
            paddingRight: 4,
            '&:first-of-type': {
              paddingLeft: 12,
            },
            '&:last-child': {
              paddingRight: 12,
            },
          },
          '.MuiButtonBase-root': {
            padding: 8,
          },
          height: 56,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState: { variant, color, size } }) => ({
          ...((variant === 'outlined' || variant === 'contained') && {
            height: 'auto',
            borderRadius: '8px',
            minHeight: '36px',
            color: palette.white,
            border: '2px solid ' + palette.primary.blue,
            ...typography.button,
          }),
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            borderColor: palette.primary.deep,
            color: palette.primary.deep,
            '&:hover': {
              borderWidth: '2px',
              backgroundColor: palette.primary.deep,
              borderColor: palette.primary.deep,
              color: palette.white,
            },
            '&.Mui-disabled': {
              borderWidth: '2px',
              borderColor: palette.grey[400],
              backgroundColor: 'transparent',
              color: palette.grey[400],
            },
          }),
          ...(variant === 'outlined' &&
            color === 'secondary' && {
              borderColor: palette.white,
              color: palette.white,
              '&:hover': {
                borderWidth: '2px',
                backgroundColor: palette.white,
                borderColor: palette.white,
                color: palette.primary.dark,
              },
              '&.Mui-disabled': {
                borderWidth: '2px',
                borderColor: palette.grey[400],
                backgroundColor: 'transparent',
                color: palette.grey[400],
              },
            }),
          ...(variant === 'contained' && {
            backgroundColor: palette.primary.main,
            '&:hover': {
              borderColor: palette.primary.deep,
              backgroundColor: palette.primary.deep,
            },
            '&.Mui-disabled': {
              borderColor: palette.grey[500],
              backgroundColor: palette.grey[500],
              color: palette.grey[400],
            },
          }),
          paddingTop: 8,
          paddingBottom: 8,
          ...(variant === 'text' && {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: 8,
            '&:hover': {
              backgroundColor: palette.grey[200],
            },
          }),
          ...(size === 'small' && {
            ...typography.buttonSmall,
            paddingTop: 5,
            paddingBottom: 5,
            minHeight: '0',
          }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState: { disabled, color } }) => ({
          padding: '12px',
          borderRadius: '8px',
          fontFamily: 'inherit',
          '& .MuiSvgIcon-root': {
            color: disabled ? palette.grey[400] : color === 'secondary' ? palette.white : palette.primary.deep,
          },
          '&:hover': {
            '& .MuiSvgIcon-root': {
              color: color === 'secondary' ? palette.primary.dark : undefined,
            },
            backgroundColor: color === 'secondary' ? palette.white : palette.grey[200],
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white,
          borderRadius: '8px',
          '& fieldset': {
            borderColor: palette.grey[400],
          },
          '&.dirty fieldset': {
            borderColor: palette.secondary.orange,
          },
          '&.dirty': {
            backgroundColor: lighten(palette.secondary.orange, 0.85),
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'standard' && {
            '.MuiInputBase-root': {
              fontSize: '24px',
              fontWeight: 700,
            },
          }),
          '& > div': {
            height: ownerState.multiline ? 'auto' : '48px',
          },
          '& svg': {
            marginRight: '5px',
            marginLeft: '3px',
          },
          '&.dirty .MuiInputBase-root': {
            backgroundColor: lighten(palette.secondary.orange, 0.85),
          },
          '&.dirty .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary.orange,
          },
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: selectPaperSx,
          },
        },
        sx: {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.grey[400],
          },
          '&.MuiOutlinedInput-root': {
            height: '48px',
            backgroundColor: palette.white,
            borderRadius: '8px',
            paddingRight: '0 !important',
          },
        },
      },
      styleOverrides: {
        outlined: {
          ...typography.selectOutlineValue,
          display: 'flex',
          alignItems: 'center',
        },
        standard: {
          paddingTop: '6px',
          paddingLeft: '5px',
          paddingRight: '30px !important',
          ...typography.selectStandardValue,
        },
        icon: ({ ownerState }) => ({
          fill: palette.grey[600],
          opacity: ownerState && ownerState.disabled ? 0.4 : 1,
        }),
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: { ...typography.label },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        variant: 'dark',
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: ownerState.variant === 'dark' ? palette.grey[500] : palette.white,
          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
            color: ownerState.variant === 'dark' ? palette.primary.deep : palette.white,
          },
          '&.Mui-disabled': {
            color: palette.grey[400],
          },
          '&.dirty .MuiSvgIcon-root': {
            fill: palette.secondary.orange,
          },
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        }),
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        thumb: {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
          color: palette.white,
          '.Mui-checked &': {
            color: palette.primary.deep,
          },
        },
        track: {
          backgroundColor: palette.grey[400],
          '.Mui-checked &': {
            color: '#B3CFEA',
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        componentsProps: {
          paper: {
            sx: selectPaperSx,
          },
        },
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'right',
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.primary.dark,
          padding: '8px 12px',
        },
        arrow: {
          '&::before': {
            backgroundColor: palette.primary.dark,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(palette.primary.dark, 0.8),
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
          [theme.breakpoints.up('md')]: {
            maxWidth: 400,
          },
          maxHeight: '80%',
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: `0px 2px 4px ${palette.shadow}`,
          borderRadius: '16px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: '32px',
          ...typography.chip,
          ...(ownerState.variant === 'filled' && {
            background: ownerState.color === 'primary' ? palette.primary.main : palette.grey[200],
            color: ownerState.color === 'primary' ? palette.white : palette.primary.deep,
            '& .MuiChip-deleteIcon': {
              color: palette.grey[400],
            },
            '&:hover, & .MuiChip-deleteIcon:hover': {
              '.MuiChip-deleteIcon': {
                color: palette.primary.deep,
              },
              color: ownerState.color === 'primary' ? palette.white : palette.primary.deep,
              background: ownerState.color === 'primary' ? palette.primary.deep : palette.grey[300],
            },
          }),
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'secondary' && {
              background: palette.grey[200],
              color: palette.primary.deep,
            }),
        }),
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          boxShadow: `0px 2px 4px ${palette.shadow}`,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: 13,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        transitionDuration: { enter: 200, exit: 100 },
      },
    },
  },
});

declare module '@mui/material/Checkbox' {
  interface CheckboxProps {
    variant?: 'light' | 'dark';
  }
}
