import { PermissionKey } from '../../types';
import { Dashboard } from './Dashboard';
import { FavouriteVendors, UnverifiedMemberUser, News, Ads } from './Widgets';
import { StaticPageWidget } from './Widgets/StaticPageWidget';

export const DashboardMember = () => (
  <Dashboard>
    <Ads placement="center" />
    <FavouriteVendors
      permissions={{
        keys: PermissionKey.VendorViewBuyGuide,
      }}
      placement="center"
    />
    <UnverifiedMemberUser
      permissions={{
        keys: PermissionKey.AdminManageMemberUsers,
      }}
      placement="right"
    />
    <News placement="right" isMandatoryOrFavouriteVendorOrNoVendor />
    <StaticPageWidget placement="right" />
  </Dashboard>
);
