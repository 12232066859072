import { Control, Controller, Path, useController } from 'react-hook-form';
import { useApi, useInternationalization } from '../../../hooks';
import { Entity, EntityRelationship, PaginationEntity } from '../../../types';
import { Loading } from '../../Loading';
import { Select } from '../Base/Select';
import { StaticField } from '../Base/StaticField';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ControlledEntityProps<K extends Record<string, any>, T extends Entity, Args extends any[]>
  extends Omit<React.ComponentProps<typeof Select>, 'name'> {
  name: EntityRelationship<Path<K>>;
  apiFunction: (...args: Args) => Promise<PaginationEntity<T>>;
  apiParams: Partial<Args>;
  getOptionLabel: (option: T) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<K>;
  label?: string;
  required?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ControlledEntitySelect = <K extends Record<string, any>, T extends Entity, Args extends any[]>({
  name,
  apiFunction,
  apiParams,
  getOptionLabel,
  control,
  label,
  required,
  readOnly,
  ...props
}: ControlledEntityProps<K, T, Args>) => {
  const { t } = useInternationalization();

  const relationshipPath = name as unknown as Path<K>;
  const idPath = `${name}Id` as Path<K>;

  const { field: relationshipField } = useController({ name: relationshipPath, control });
  const { data, isLoading } = useApi(apiFunction, null, ...(apiParams as Args));

  const options = data?.data.map((d) => ({ label: getOptionLabel(d), value: d.id })) ?? [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderStaticValue = (value: any) => {
    // If the relationship field has a value, show that one instead
    if (relationshipField && relationshipField.value) {
      return typeof relationshipField.value === 'string'
        ? relationshipField.value
        : getOptionLabel(relationshipField.value);
    }
    // Otherwise, show the value from the retrieved list
    const matchingValue = data?.data.find((d) => d.id == value);
    if (matchingValue) {
      return getOptionLabel(matchingValue);
    }
    return null;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderValue = (idValue: any) => {
    // If the relationship field has the matching ID, show that as the selected value,
    // which will allow the current value to appear, even if it does not exists in
    // the current list (for instance, it has been archived)
    if (relationshipField && relationshipField.value && relationshipField.value.id === idValue) {
      return getOptionLabel(relationshipField.value);
    }
    // Otherwise, show the value from the retrieved list
    const matchingValue = data?.data.find((d) => d.id == idValue);
    if (matchingValue) {
      return getOptionLabel(matchingValue);
    }
    return null;
  };

  return (
    <Controller
      control={control}
      name={idPath}
      render={({ field: { onChange, value, onBlur }, fieldState: { error, isDirty } }) =>
        control._options.context?.readOnly || readOnly ? (
          <StaticField label={label} value={renderStaticValue(value)} hideLabel={props.hideLabel} />
        ) : isLoading ? (
          <Loading />
        ) : (
          <Select
            {...props}
            onBlur={onBlur}
            className={control._options.context?.variant === 'changeRequest' && isDirty ? 'dirty' : undefined}
            name={name}
            label={required ? `${label} *` : label}
            id={name}
            onChange={onChange}
            renderValue={renderValue}
            onClear={() => onChange(null)}
            options={options}
            value={value ?? ''}
            error={!!error}
            helperText={error?.message && t(error?.message, { name: label })}
          />
        )
      }
    />
  );
};
