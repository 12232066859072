import React from 'react';
import { UserEntity } from '../../models';
import { UserType } from '../../types';

interface UserDetailContextType {
  user: UserEntity | null;
  userId: number;
  type: UserType;
  fetchData: () => Promise<void>;
}

export const UserDetailContext = React.createContext<UserDetailContextType>({} as UserDetailContextType);
