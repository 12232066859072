import axios from 'axios';
import { ProfileEntity, UserPasswordEntity } from '../models';

export const profile = {
  get: async (): Promise<ProfileEntity> => {
    const { data } = await axios.get(`api/User/profile`);
    return new ProfileEntity(data);
  },
  update: async (entity: ProfileEntity): Promise<ProfileEntity> => {
    const { data } = await axios.put(`api/User/profile`, entity);
    return new ProfileEntity(data);
  },
  updatePassword: async (entity: UserPasswordEntity): Promise<UserPasswordEntity> => {
    await axios.post(`api/user/${entity.id}/updatepassword`, entity);
    return new UserPasswordEntity();
  },
  acceptConfidentialAgreement: async (): Promise<ProfileEntity> => {
    const { data } = await axios.post('/api/user/acceptConfidentialityAgreement');
    return new ProfileEntity(data);
  },
};
