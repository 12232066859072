import { BuyGuideVendorBuyOpportunityDetail } from '../../../components/BuyGuide';
import { ConfidentialAgreement } from '../../../components/ConfidentialAgreement';

export function BuyGuideBuyOpportunityDetail() {
  return (
    <ConfidentialAgreement>
      <BuyGuideVendorBuyOpportunityDetail />
    </ConfidentialAgreement>
  );
}
