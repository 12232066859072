import { CircularProgress, Typography } from '@mui/material';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { ArchiveBanner, EditDetails } from '..';
import { user as userApi } from '../../api';
import { UserDetailContext } from '../../contexts';
import { useApi, useAuth, usePageTitle } from '../../hooks';
import { UserEntity } from '../../models';
import { routes } from '../../routes';
import { UserSchema } from '../../schemas';
import { PermissionKey, Styles, UserType } from '../../types';
import { formatShortDate } from '../../utils/helper';
import { Container } from '../Container';
import { StaticField } from '../Form';
import { AllowAccessMenuItem, ArchiveMenuItem, ResetPasswordMenuItem } from '../Menu';
import { VerifyMenuItem } from '../Menu/VerifyMenuItem';
import { LinkTab } from '../Tab';
import { UserForm } from './UserForm';
import { LayoutPage } from '../Layout';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

interface UserDetailsProps {
  type: UserType;
}

export const UserDetail = ({ type }: UserDetailsProps) => {
  const { isInternalUser, user, isMemberUser } = useAuth();
  const { t } = useTranslation();
  const outlet = useOutlet();
  const params = useParams();
  const userId = Number(params.userId);
  const { data, isLoading, refresh } = useApi(userApi.get, null, userId, type);
  const { call: update } = useApi(userApi.update, {
    successKey: 'common:success.save',
  });
  const archiveApi = useApi(userApi.archive, { successKey: 'common:success.action' });
  const allowAccessApi = useApi(userApi.allowAccess, { successKey: 'common:success.action' });
  const resetPasswordApi = useApi(userApi.resetPassword, { successKey: 'common:success.action' });
  const verifyApi = useApi(userApi.verify, { successKey: 'common:success.action' });
  usePageTitle(data?.fullName ?? '');

  const onArchiveConfirm = async (isArchived: boolean, entity: UserEntity) => {
    await archiveApi.call([entity.id], isArchived, type);
    refresh();
  };

  const onAllowAccessConfirm = async (isAllowedAccess: boolean, user: UserEntity | null) => {
    if (user) {
      await allowAccessApi.call([user.id], isAllowedAccess, type);
      refresh();
    }
  };

  const onResetPasswordConfirm = async (user: UserEntity | null) => {
    if (user) {
      await resetPasswordApi.call(user.id, type);
    }
  };

  const onVerifyConfirm = async (users: UserEntity[]) => {
    await verifyApi.call(
      users.map((i) => i.id),
      type,
    );
    refresh();
  };

  const renderActionMenuItems = (onClick: () => void) =>
    data
      ? [
          ...(type === UserType.Member && data?.isVerifiable
            ? [
                <VerifyMenuItem
                  key="verify"
                  entity={data}
                  name={data.fullName}
                  onClick={onClick}
                  onVerifyConfirm={(entity) => onVerifyConfirm([entity])}
                  actionSuffix={t('user:action.suffix')}
                />,
              ]
            : []),
          ...(data.id !== user?.profile.id
            ? [
                <AllowAccessMenuItem
                  key="allowAccess"
                  entity={data}
                  name={data.fullName}
                  onClick={onClick}
                  onAllowAccessConfirm={onAllowAccessConfirm}
                  actionSuffix={t('user:action.suffix')}
                />,
              ]
            : []),
          <ResetPasswordMenuItem
            key="resetPassword"
            entity={data}
            name={data.fullName}
            onClick={onClick}
            onResetPasswordConfirm={onResetPasswordConfirm}
            actionSuffix={t('user:action.suffix')}
          />,
          ...(data.id !== user?.profile.id
            ? [
                <ArchiveMenuItem
                  key="archive"
                  entity={data}
                  name={data.fullName}
                  onClick={onClick}
                  onArchiveConfirm={onArchiveConfirm}
                  actionSuffix={t('user:action.suffix')}
                />,
              ]
            : []),
        ]
      : [];

  const getRequiredPermission = (type: UserType) => {
    switch (type) {
      case UserType.Member:
        return PermissionKey.AdminManageMemberUsers;
      case UserType.Vendor:
        return PermissionKey.AdminManageVendorUsers;
      case UserType.Internal:
      default:
        return PermissionKey.AdminManageInternalUsers;
    }
  };

  const tabs = [
    <LinkTab
      label={t('user:sections.permissions')}
      value={generatePath(
        isMemberUser
          ? routes.Member.User.Detail.Permissions.path
          : get(routes.Admin.User, `${type}.Detail.Permissions.path`, ''),
        { userId },
      )}
      permissions={{ keys: getRequiredPermission(type) }}
    />,
  ];

  return (
    <UserDetailContext.Provider value={{ user: data, userId, type, fetchData: refresh }}>
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
        <ArchiveBanner entity={data} onUnarchive={() => data && onArchiveConfirm(false, data)} />
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              titleLabel={t(`user:form.fullName`)}
              title={'fullName'}
              header={<StaticField hideLabel={true} variant={'h1'} value={data.fullName} />}
              hideHeaderWhenEditing
              disabled={data.isArchived}
              entity={data}
              schema={UserSchema(type)}
              onSave={(user) => update(user, type)}
              actionMenuItems={renderActionMenuItems}
              onConfirm={() => refresh()}
              renderModifiedDate={
                isMemberUser
                  ? (entity) => (
                      <Typography variant="modifiedDate">
                        {t('common:entity.lastUpdateWithName', {
                          date: formatShortDate(entity.modifiedDate),
                          name: entity.modifiedBy,
                        })}
                      </Typography>
                    )
                  : undefined
              }
              breadcrumbs={
                isInternalUser
                  ? [
                      {
                        title: t('user:title'),
                        href: routes.Admin.User.path,
                      },
                      {
                        title: t(`user:sections.${type}`),
                        href: generatePath(get(routes.Admin.User, `${type}.path`, '')),
                      },
                      {
                        title: data?.fullName,
                      },
                    ]
                  : [
                      {
                        title: t('user:title'),
                        href: routes.Member.User.path,
                      },
                      {
                        title: data?.fullName,
                      },
                    ]
              }
            >
              <UserForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </UserDetailContext.Provider>
  );
};
