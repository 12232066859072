import { Tabs } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

export const LinkTabs = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();
  return (
    <Tabs variant="scrollable" scrollButtons="auto" value={pathname}>
      {children}
    </Tabs>
  );
};
