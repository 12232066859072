declare global {
  interface Window {
    env: ProcessEnv;
  }
}

type ProcessEnv = {
  REACT_APP_API_URL: string;
  REACT_APP_ENVIRONMENT: 'dev' | 'qa' | 'prod';
  REACT_APP_AUTHORIZE_FILE_EXTENSIONS: string;
};

export const env: ProcessEnv = { ...process.env, ...window.env };
