import { AddRounded } from '@mui/icons-material';
import { Box, Button, Link, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { contact as contactApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useAuth, useDebounce, useEnumList, useInternationalization } from '../../../hooks';
import { OfficeEntity } from '../../../models';
import { routes } from '../../../routes';
import { OfficeSchema } from '../../../schemas';
import {
  BaseComponentListProps,
  ExportType,
  OfficeFilter,
  OrderBy,
  PermissionKey,
  Province,
  Styles,
} from '../../../types';
import { downloadBlob } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { EditDrawer } from '../../EditDrawer';
import { ExportButton } from '../../ExportButton';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { Input, Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { OfficeForm } from './OfficeForm';
import { OfficeHeader } from './OfficeHeader';

const style: Styles = {
  inputLargeContainer: {
    width: {
      xs: '100%',
      sm: '360px',
    },
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
  switch: {
    marginBottom: '-4px',
  },
};

const defaultTextSearchFilter: OfficeFilter = {
  officeDescription: '',
  ...DEFAULT_PAGINATION_FILTER,
};
const defaultAdvancedSearchFilter: OfficeFilter = {
  province: undefined,
  city: '',
  ...DEFAULT_PAGINATION_FILTER,
};

export const OfficeList = ({
  addButtonProps,
  tableProps,
  onSave,
  layout = 'Tab',
  isChangeRequest,
  hidePending,
  readOnly,
  ...props
}: Partial<BaseComponentListProps<OfficeEntity, OfficeFilter>>) => {
  const navigate = useNavigate();
  const { isInternalUser, isMemberUser } = useAuth();
  const { t, getTranslation } = useInternationalization();

  const stateFilter = useState<OfficeFilter>({ isArchived: false, ...defaultTextSearchFilter });
  const [filter, setFilter] = props.filter && props.setFilter ? [props.filter, props.setFilter] : stateFilter;
  const debounceTextFilter = useDebounce(filter, 750);

  const fetchApi = useApi(contactApi.getAllOffices, { callImmediately: false, skipFetch: true }, filter);
  const { data, isLoading, call } = props.fetchApi ?? fetchApi;

  const { call: downloadExport } = useApi(contactApi.exportAllOffices, null);

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [office, setOffice] = useState<OfficeEntity>(new OfficeEntity());
  const [isAdvancedMode, setIsAdvancedMode] = useState(!!filter.city || !!filter.province);
  const provinceOptions = useEnumList(Province, 'common:province', true);

  useEffect(() => {
    call({
      city: debounceTextFilter.city,
      officeDescription: debounceTextFilter.officeDescription,
      province: filter.province,
      orderBy: filter.orderBy,
      pageNumber: filter.pageNumber,
      pageSize: filter.pageSize,
      isArchived: filter.isArchived,
    });
  }, [
    call,
    debounceTextFilter.officeDescription,
    debounceTextFilter.city,
    filter.isArchived,
    filter.orderBy,
    filter.pageNumber,
    filter.pageSize,
    filter.province,
  ]);

  const getOfficeDetailPath = (officeId: number) =>
    generatePath(
      isInternalUser
        ? routes.Admin.OfficeDetails.path
        : isMemberUser
        ? routes.Member.OfficeDetails.path
        : routes.Vendor.OfficeDetails.path,
      { officeId },
    );

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...(checked ? defaultTextSearchFilter : defaultAdvancedSearchFilter) }));
    setIsAdvancedMode(checked);
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onProvinceChange = (event: SelectChangeEvent<Province> | null) => {
    setFilter((prev) => ({ ...prev, province: (event?.target?.value as Province) || undefined }));
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const exportOffices = async (exportType: ExportType = ExportType.CSV) => {
    const fileData = await downloadExport(exportType);
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.csv', fileData.data);
    }
  };

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear}>
      <Box sx={style.inputLargeContainer}>
        <Input
          value={filter.city ?? ''}
          label={t('common:contact.address.city')}
          onChange={(event) => setFilter((prev) => ({ ...prev, city: event.target.value ?? undefined, pageNumber: 1 }))}
        />
      </Box>
      <Box sx={style.inputLargeContainer}>
        <Select
          displayEmpty
          options={provinceOptions}
          value={filter.province ?? ''}
          onChange={onProvinceChange}
          label={t('common:contact.address.province')}
          onClear={() => onProvinceChange(null)}
        />
      </Box>
    </FilterContainer>
  );

  const renderTextSearch = () => (
    <Input
      sx={style.textContainer}
      value={filter.officeDescription ?? ''}
      label={t('contact:office.search.name')}
      placeholder={t('contact:office.search.placeholder')}
      onChange={(event) =>
        setFilter((prev) => ({ ...prev, officeDescription: event.target.value ?? undefined, pageNumber: 1 }))
      }
    />
  );

  const renderBaseActionsMenuItems = (entity: OfficeEntity, onClick: () => void) =>
    [
      <MenuItem id="view" key="view" onClick={() => navigate(getOfficeDetailPath(entity.id))}>
        {t('common:view')}
      </MenuItem>,
    ].concat(tableProps?.actionMenuItems ? tableProps?.actionMenuItems(entity, onClick) : []);

  const renderName = (entity: OfficeEntity) => (
    <Link underline="always" href={getOfficeDetailPath(entity.id)}>
      {getTranslation(entity, 'description')}
    </Link>
  );

  const renderCity = (entity: OfficeEntity) => entity.address.city ?? '';

  const renderProvince = (entity: OfficeEntity) => entity.address.province ?? '';

  const renderTable = () =>
    data && (
      <Table
        {...tableProps}
        data={data}
        translationNamespace="contact:office"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={renderBaseActionsMenuItems}
        hidePending={hidePending}
      >
        <TableColumn type="custom" width="50%" id="description" sortable render={renderName} />
        <TableColumn type="custom" width="30%" id="city" sortable render={renderCity} />
        <TableColumn type="custom" width="20%" id="province" sortable render={renderProvince} />
      </Table>
    );

  const renderPageActions = () => (
    <Stack direction="row" spacing={1}>
      {!readOnly && (
        <HasPermissions keys={PermissionKey.AdminManageContacts}>
          <Button
            variant="contained"
            startIcon={<AddRounded />}
            onClick={() => {
              setOffice(new OfficeEntity());
              setIsAddOpen(true);
            }}
            {...addButtonProps}
          >
            {t('contact:office.actions.add')}
          </Button>
        </HasPermissions>
      )}
      <ExportButton displayMenu defaultExportType={ExportType.CSV} onExportClick={exportOffices} />
    </Stack>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('contact:office.actions.add')}</Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={office}
      schema={OfficeSchema()}
      onSave={async (entity) => {
        if (onSave) {
          const createdEntity = await onSave(entity);
          call(filter);
          return createdEntity;
        }
        return null;
      }}
      onConfirm={() => setIsAddOpen(false)}
      redirectPath={(e) => getOfficeDetailPath(e.id)}
      onCancel={() => setIsAddOpen(false)}
      isChangeRequest={isChangeRequest}
    >
      <OfficeHeader />
      <OfficeForm />
    </EditDrawer>
  );

  return (
    <LayoutPage title={t('contact:office.title')} rightTitle={renderPageActions()} display={layout}>
      <Stack spacing={3} mb={!isInternalUser ? 2 : 0}>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark">
            {t('contact:office.search.title')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('contact:office.search.filterList')}
          </Typography>
        </Box>
        <Stack>
          {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
          {isInternalUser && <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />}
        </Stack>
      </Stack>
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
