import axios from 'axios';
import { ForestProductEntity } from '../../models';
import { PaginationEntity, ForestProductFilter } from '../../types';

export const ForestProduct = {
  getAllForestProducts: async (
    forestProductGroupId: number,
    forestProductFilter: ForestProductFilter,
  ): Promise<PaginationEntity<ForestProductEntity>> => {
    const { data } = await axios.post(
      `api/forestProductGroup/${forestProductGroupId}/products/search`,
      forestProductFilter,
    );
    return { ...data, data: data.data.map((v: ForestProductEntity) => new ForestProductEntity(v)) };
  },
  getForestProduct: async (forestProductGroupId: number, productId: number): Promise<ForestProductEntity> => {
    const { data } = await axios.get(`api/forestproductgroup/${forestProductGroupId}/products/${productId}`);
    return new ForestProductEntity(data);
  },
  createForestProduct: async (
    forestProductGroupId: number,
    entity: ForestProductEntity,
  ): Promise<ForestProductEntity> => {
    const { data } = await axios.post(`api/forestproductgroup/${forestProductGroupId}/products`, entity);
    return new ForestProductEntity(data);
  },
  updateForestProduct: async (
    forestProductGroupId: number,
    entity: ForestProductEntity,
  ): Promise<ForestProductEntity> => {
    const { data } = await axios.put(`api/forestproductgroup/${forestProductGroupId}/products/${entity.id}`, entity);
    return new ForestProductEntity(data);
  },
  archiveForestProduct: async (
    forestProductGroupId: number,
    ids: number[],
    isArchived: boolean,
  ): Promise<ForestProductEntity[]> => {
    const { data } = await axios.put(`api/forestproductgroup/${forestProductGroupId}/products/archive`, {
      isArchived,
      ids,
    });
    return data.map((v: ForestProductEntity) => new ForestProductEntity(v));
  },
};
