import { VendorContactEmailAddressEntity, VendorContactPhoneNumberEntity } from '.';
import { AddressEntity } from '../Contact';
import { EditableEntity } from '../EditableEntity';

export class VendorLocationEntity extends EditableEntity {
  description_En: string;
  description_Fr: string;
  emailAddresses: VendorContactEmailAddressEntity[];
  phoneNumbers: VendorContactPhoneNumberEntity[];
  isArchived: boolean;
  isVisibleToMember: boolean;
  address: AddressEntity;

  constructor(entity?: VendorLocationEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.description_En = '';
      this.description_Fr = '';
      this.emailAddresses = [];
      this.phoneNumbers = [];
      this.isVisibleToMember = true;
      this.address = new AddressEntity();
    }
  }
}
