import { CircularProgress } from '@mui/material';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { CmsPublicationContext } from '../../../contexts/Cms';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../hooks';
import { PublicationEntity } from '../../../models';
import { routes } from '../../../routes';
import { PublicationSchema } from '../../../schemas';
import { ContentStatus, ContentTriggers, PermissionKey, Styles } from '../../../types';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { LayoutPage } from '../../Layout';
import { LinkTab } from '../../Tab';
import { CmsChangeLogs } from '../ChangeLog/CmsChangeLogs';
import { CmsWorkflow } from '../CmsWorkflow';
import { CmsPublicationForm } from './CmsPublicationForm';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const CmsPublicationDetail = () => {
  const { t, getTranslatablePropertyKey, getTranslation } = useInternationalization();
  const { hasPermissions, isVendorUser } = useAuth();
  const params = useParams();
  const outlet = useOutlet();
  const publicationId = Number(params.publicationId);
  const { data, refresh, isLoading } = useApi(cmsApi.getPublication, null, publicationId);
  const { call: update } = useApi(cmsApi.updatePublication, null);
  const { call: trigger, isLoading: isLoadingTrigger } = useApi(cmsApi.updatePublicationWorkflow, null);
  const userRoutes = isVendorUser ? routes.Vendor : routes.Admin;
  usePageTitle(data ? getTranslation(data, 'name') : '');

  const canEdit =
    (data?.status === ContentStatus.InProgress && hasPermissions(PermissionKey.CmsNewsEdit)) ||
    ((data?.status === ContentStatus.Submitted || data?.status === ContentStatus.Approved) &&
      hasPermissions(PermissionKey.CmsNewsApprove));

  const tabs = [
    <LinkTab
      label={t('cms:publications.sections.content')}
      value={generatePath(userRoutes.PublicationDetails.Content.path, { publicationId })}
      permissions={{ keys: [PermissionKey.CmsNewsView, PermissionKey.CmsNewsEdit], any: true }}
    />,
    <LinkTab
      label={t('cms:publications.sections.targeting')}
      value={generatePath(userRoutes.PublicationDetails.Targeting.path, { publicationId })}
      permissions={{ keys: [PermissionKey.CmsNewsView, PermissionKey.CmsNewsEdit], any: true }}
    />,
  ];

  const save = async (entity: PublicationEntity) => {
    const updatedEntity = await update(entity);
    await refresh();
    return updatedEntity;
  };

  const onUpdateWorkflowConfirm = (newTrigger: ContentTriggers) => async () => {
    await trigger(publicationId, newTrigger);
    refresh();
  };

  const renderWorkflow = () => {
    if (
      !data ||
      data?.status == ContentStatus.Rejected ||
      !hasPermissions([PermissionKey.CmsNewsEdit, PermissionKey.CmsNewsApprove], true)
    ) {
      return undefined;
    }

    return (
      <CmsWorkflow
        isLoading={isLoadingTrigger}
        onTrigger={onUpdateWorkflowConfirm}
        status={data.status}
        entityName={getTranslation(data, 'name')}
        approvePermission={PermissionKey.CmsNewsApprove}
        editPermission={PermissionKey.CmsNewsEdit}
        entitySuffix={t('cms:publications.actions.suffix')}
      />
    );
  };

  return (
    <CmsPublicationContext.Provider value={{ publication: data, canEdit, isLoading: isLoading, fetchData: refresh }}>
      <LayoutPage
        display="Detail"
        permissions={{ keys: [PermissionKey.CmsNewsView, PermissionKey.CmsNewsEdit], any: true }}
        tabs={tabs}
        outlet={outlet}
      >
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              titleLabel={t(`cms:publications.form.name`)}
              title={getTranslatablePropertyKey(data, 'name')}
              entity={data}
              schema={PublicationSchema()}
              onSave={save}
              onConfirm={() => refresh()}
              hideHeaderWhenEditing
              readOnly={!canEdit}
              breadcrumbs={[
                { title: t('cms:publications.title'), href: userRoutes.Cms.Publications.path },
                { title: getTranslation(data, 'name') },
              ]}
              extraActions={[
                <CmsChangeLogs
                  key="changeLogs"
                  cmsEntityId={data.id}
                  fetchApi={cmsApi.getPublicationChangeLogs}
                  addCommentApi={cmsApi.addPublicationComment}
                  title={t('changeLog:publicationTitle')}
                />,
              ]}
              workflow={renderWorkflow()}
              alwaysOpen={isVendorUser}
            >
              <CmsPublicationForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </CmsPublicationContext.Provider>
  );
};
