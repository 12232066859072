import { Control, FieldArrayPath, FieldValues, useFieldArray, UseFieldArrayReturn } from 'react-hook-form';

export const useEntityFormFieldArray = <
  TFieldValues extends FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues>,
>(
  control: Control<TFieldValues>,
  name: TFieldArrayName,
): UseFieldArrayReturn<TFieldValues, TFieldArrayName, 'id'> => {
  return useFieldArray({
    control: control,
    name: name,
  });
};
