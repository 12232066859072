import { mixed, number, object, string } from 'yup';
import { VendorEntity } from '../../models';
import { ApiErrorCodes, EntityFormSchema, Province } from '../../types';
import { AddressSchema } from '../Contact';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH, MAX_URL_LENGTH } from '../../constants';

export const VendorSchema = (): EntityFormSchema<VendorEntity> => ({
  schema: object({
    name: string().required().max(MAX_SHORT_TEXT_LENGTH),
    number: number()
      .integer()
      .min(1)
      .notRequired()
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null)),
    address: AddressSchema(true).schema.concat(
      object({
        street: string().required().max(MAX_SHORT_TEXT_LENGTH),
        city: string().required().max(MAX_SHORT_TEXT_LENGTH),
        province: mixed<Province>().oneOf(Object.values(Province)).required(),
      }),
    ),
    memberNotes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
    notes: string().notRequired().nullable().max(MAX_LONG_TEXT_LENGTH),
    website: string().notRequired().nullable().max(MAX_URL_LENGTH),
  }),
  errorCodeMap: {
    [ApiErrorCodes.VENDOR_NAME_NOT_UNIQUE]: { key: 'name', message: 'common:error.fieldUnique' },
    [ApiErrorCodes.VENDOR_NUMBER_NOT_UNIQUE]: { key: 'number', message: 'common:error.fieldUnique' },
  },
});
