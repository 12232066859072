import { Box, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { categorization as categorizationApi, roleGroup as roleGroupApi } from '../../../api';
import { MAX_LONG_TEXT_LENGTH, MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useAuth, useEntityFormContext, useInternationalization } from '../../../hooks';
import { UserEntity } from '../../../models';
import { RebateCategoryEntity } from '../../../models/Vendor/RebateCategoryEntity';
import { palette } from '../../../styles/palette';
import { RoleGroup, Styles } from '../../../types';
import { calculateTermValue } from '../../../utils/rebate';
import {
  ControlledEntitySelect,
  ControlledInput,
  ControlledMultiSelect,
  ControlledTranslatedInput,
  StaticField,
} from '../../Form';
import { PeriodForm } from '../../PeriodForm';
import { TargetedMembershipHeader } from '../../TargetedMembership';
import { VendorBuyingAgreementSelect } from '../BuyingAgreements/VendorBuyingAgreementSelect';
import { VendorRebateCategoryRebateSummary } from './VendorRebateCategoryRebateSummary';
import { VendorBuyingAgreementContext } from '../../../contexts/Vendor';
import { useContext, useEffect } from 'react';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
  notesHelperText: {
    mt: 1,
  },
  sectionHeader: {
    borderTop: 'solid 1px ' + palette.grey[300],
    pt: 3,
  },
};

export const VendorRebateCategoryForm = () => {
  const internationalization = useInternationalization();
  const { t, getTranslation } = internationalization;
  const { user, isInternalUser } = useAuth();
  const params = useParams();
  const { vendorBuyingAgreement } = useContext(VendorBuyingAgreementContext);
  const isBuyingAgreementChild = !!params.vendorBuyingAgreementId;
  const vendorId = Number(user?.profile.vendor?.id ?? params.vendorId);
  const vendorDesignations = useApi(categorizationApi.getAll, null, 'vendorDesignation', false);
  const productCategories = useApi(categorizationApi.getAll, null, 'productCategory', false);
  const { getValues, control, readOnly, variant, watch, setValue } = useEntityFormContext<RebateCategoryEntity>();

  const rebateCategory = getValues();
  const summary = rebateCategory.receivableRebatesSummary;

  const editing = !!watch('id');

  useEffect(() => {
    if (!editing && isBuyingAgreementChild) {
      setValue('effectiveDate', vendorBuyingAgreement?.effectiveDate);
      setValue('expiryDate', vendorBuyingAgreement?.expiryDate);
    }
  }, [
    setValue,
    editing,
    isBuyingAgreementChild,
    vendorBuyingAgreement?.effectiveDate,
    vendorBuyingAgreement?.expiryDate,
  ]);

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        <Grid item xs={variant === 'drawer' ? 12 : 4}>
          <ControlledMultiSelect
            label={t('vendor:rebateCategory.form.vendorDesignation')}
            name="vendorDesignationIds"
            options={
              vendorDesignations.data?.values?.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []
            }
            control={control}
          />
        </Grid>
        <PeriodForm xs={variant === 'drawer' ? 6 : 2} />
        <Grid item xs={variant === 'drawer' ? 12 : 2}>
          <ControlledEntitySelect
            label={t('vendor:buyOpportunities.form.responsibleBuyer')}
            name="responsibleBuyer"
            apiFunction={roleGroupApi.getUsers}
            apiParams={[RoleGroup.Buyer, MAX_PAGINATION_FILTER]}
            getOptionLabel={(entity: UserEntity) => entity.fullName}
            control={control}
            required
          />
        </Grid>
        {!isBuyingAgreementChild && (
          <VendorBuyingAgreementSelect vendorId={vendorId} xs={variant === 'drawer' ? 12 : 2} />
        )}
        <Grid item xs={variant === 'drawer' ? 12 : 4}>
          <ControlledMultiSelect
            label={t('vendor:rebateCategory.form.productCategories')}
            name="productCategoryIds"
            options={
              productCategories.data?.values.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []
            }
            control={control}
          />
        </Grid>
        {variant !== 'drawer' && (
          <TargetedMembershipHeader
            regionsBreakpoints={{ xs: 4 }}
            membershipTypesBreakpoints={{ xs: 4 }}
            membersBreakpoints={{ xs: 8 }}
          />
        )}
        {summary != null && (
          <Grid item xs={12}>
            <Typography variant="h3" sx={style.sectionHeader}>
              {t('vendor:rebateCategory.sections.receivableRebates')}
            </Typography>
            <VendorRebateCategoryRebateSummary summary={summary} />
          </Grid>
        )}
        {variant !== 'drawer' && (
          <>
            <Grid item xs={1}>
              <StaticField
                label={t('vendor:rebateCategory.form.tiers')}
                value={t(`common:boolean.${rebateCategory.tiersCount > 0}`)}
              />
            </Grid>
            <Grid item xs={1}>
              <StaticField
                label={t('vendor:rebateCategory.form.growths')}
                value={t(`common:boolean.${rebateCategory.growthsCount > 0}`)}
              />
            </Grid>
            <Grid item xs={2}>
              <StaticField
                label={t('vendor:rebateCategory.form.terms')}
                value={calculateTermValue(rebateCategory.rebate, internationalization)}
              />
            </Grid>
            <Grid item xs={8}>
              <StaticField
                label={t('vendor:rebateCategory.form.calculationMethod')}
                value={
                  rebateCategory.rebate.calculationMethod != null
                    ? rebateCategory.rebate.calculationMethod.isCustom
                      ? getTranslation(rebateCategory.rebate, 'customMethod')
                      : getTranslation(rebateCategory.rebate.calculationMethod, 'value')
                    : ''
                }
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant="h3" sx={style.sectionHeader}>
            {t('vendor:rebateCategory.sections.notes')}
          </Typography>
        </Grid>
        <Grid item xs={variant === 'drawer' ? 12 : 6}>
          <ControlledTranslatedInput
            label={t('vendor:rebateCategory.form.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
            readOnly={readOnly}
            maxLength={MAX_LONG_TEXT_LENGTH}
          />
        </Grid>
        {isInternalUser && (
          <Grid item xs={variant === 'drawer' ? 12 : 6}>
            <ControlledInput
              label={t('vendor:rebateCategory.form.notes')}
              name="notes"
              control={control}
              multiline
              rows={3}
              maxLength={MAX_LONG_TEXT_LENGTH}
            />
            <Box sx={style.notesHelperText}>{!readOnly && <Trans i18nKey="common:formHelperText.notes" />}</Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
