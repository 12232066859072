import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps, FormControlLabel, Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Styles } from '../../../types';
import { Container } from './Container';
import { palette } from '../../../styles/palette';

const style: Styles = {
  stackedContainer: {
    alignItems: 'flex-start',
  },
  stackedCheckbox: {
    pl: 0,
  },
  leftLabel: {
    ml: 1.25,
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    '>svg': {
      fontSize: 10,
      marginLeft: 1,
    },
  },
};

interface SwitchProps extends Omit<MuiSwitchProps, 'variant'> {
  label?: string;
  leftLabel?: string;
  variant?: 'inline' | 'stacked';
  hideLabel?: boolean;
  showIcon?: boolean;
}

export const Switch = ({ hideLabel, label, leftLabel, variant = 'inline', showIcon, ...props }: SwitchProps) =>
  variant === 'inline' ? (
    <Box display="flex" alignItems="center">
      <FormControlLabel
        sx={style.switchContainer}
        control={
          <>
            {showIcon && <CircleIcon fontSize="small" htmlColor={palette.secondary.orange} />}
            {!!leftLabel && (
              <Typography variant="label" sx={style.leftLabel}>
                {leftLabel}
              </Typography>
            )}
            <MuiSwitch {...props} disabled={props.readOnly} />
          </>
        }
        label={label}
      />
    </Box>
  ) : (
    <Container
      label={label}
      hideLabel={hideLabel}
      sx={{ ...style.stackedContainer, ...(showIcon ? style.switchContainer : {}) }}
    >
      <CircleIcon fontSize="small" htmlColor={palette.secondary.orange} />
      <MuiSwitch {...props} sx={style.stackedCheckbox} disabled={props.readOnly} />
    </Container>
  );
