import { CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { contact as contactApi } from '../../../api';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../hooks';
import { InternalContactEntity } from '../../../models';
import { routes } from '../../../routes';
import { InternalContactSchema } from '../../../schemas';
import { PermissionKey, Styles } from '../../../types';
import { ArchiveBanner } from '../../ArchiveBanner';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { InternalContactForm } from './InternalContactForm';
import { InternalContactHeader } from './InternalContactHeader';
import { formatShortDate } from '../../../utils/helper';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const InternalContactDetail = () => {
  const { t } = useInternationalization();
  const { isInternalUser, isMemberUser } = useAuth();
  const params = useParams();
  const contactId = Number(params.contactId);
  const { data, isLoading, refresh } = useApi(contactApi.getContact, null, contactId);
  const { call } = useApi(contactApi.updateContact, { successKey: 'common:success.save' });

  const archiveApi = useApi(contactApi.archiveContact, { successKey: 'common:success.action' });
  usePageTitle(data?.fullName ?? '');

  const onArchiveConfirm = async (isArchived: boolean, entity: InternalContactEntity) => {
    await archiveApi.call([entity.id], isArchived);
    refresh();
  };

  const renderActionMenuItems = (onClick: () => void) =>
    data && isInternalUser
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={data.fullName}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('contact:internalContact.actions.suffix')}
          />,
        ]
      : [];

  return (
    <LayoutPage display="Detail">
      <ArchiveBanner
        permissions={{ keys: PermissionKey.AdminManageContacts }}
        entity={data}
        onUnarchive={() => data && onArchiveConfirm(false, data)}
      />
      <Container>
        {isLoading && <CircularProgress size={20} sx={style.loading} />}
        {data && (
          <EditDetails
            permissions={{ keys: PermissionKey.AdminManageContacts }}
            titleLabel={t(`contact:internalContact.form.name`)}
            title="fullName"
            header={<InternalContactHeader />}
            disabled={data.isArchived}
            readOnly={!isInternalUser}
            entity={data}
            schema={InternalContactSchema()}
            onSave={call}
            alwaysOpen
            actionMenuItems={renderActionMenuItems}
            onConfirm={() => refresh()}
            breadcrumbs={[
              {
                title: t('contact:internalContact.title'),
                href: isInternalUser
                  ? routes.Admin.TBMContacts.InternalContacts.path
                  : isMemberUser
                  ? routes.Member.TBMContacts.InternalContacts.path
                  : routes.Vendor.TBMContacts.InternalContacts.path,
              },
              { title: data.fullName },
            ]}
            renderModifiedDate={(e) => (
              <Typography variant="modifiedDate">
                {e.modifiedBy &&
                  e.modifiedDate &&
                  t('common:entity.modifiedDate', {
                    date: formatShortDate(e.modifiedDate),
                    name: e.modifiedBy,
                  })}
              </Typography>
            )}
          >
            <InternalContactForm />
          </EditDetails>
        )}
      </Container>
    </LayoutPage>
  );
};
