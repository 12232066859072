import { Box, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useEnumList } from '../../hooks';
import { MailSyncFilter, Styles, TranslationContext } from '../../types';
import { Select } from '../Form';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
};

interface MailSyncFilterSelectProps {
  filter: MailSyncFilter;
  setFilter: Dispatch<SetStateAction<MailSyncFilter>>;
  translationContext?: TranslationContext;
}

enum MailSyncFilterOptions {
  FAILED = 'Failed',
}

export const MailSyncFilterSelect = ({ filter, setFilter, translationContext = 'male' }: MailSyncFilterSelectProps) => {
  const { isInternalUser } = useAuth();
  const { t } = useTranslation();
  const mailSyncFilterOptions = useEnumList(
    MailSyncFilterOptions,
    'common:mailSyncFilter',
    true,
    translationContext,
    false,
  );

  const onMailSyncChange = (event: SelectChangeEvent<unknown>) => {
    const value = !event.target.value ? undefined : event.target.value === MailSyncFilterOptions.FAILED;
    setFilter((prev) => ({ ...prev, mailSyncErrorOnly: value, pageNumber: 1 }));
  };

  if (!isInternalUser) {
    return null;
  }

  return (
    <Box sx={style.selectSmallContainer}>
      <Select
        displayEmpty
        options={mailSyncFilterOptions}
        value={filter.mailSyncErrorOnly ? MailSyncFilterOptions.FAILED : ''}
        onChange={onMailSyncChange}
        label={t('common:filter.mailSync')}
      />
    </Box>
  );
};
