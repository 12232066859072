import { CheckCircleRounded } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import {
  PASSWORD_COMPLEXITY_MIN_LENGTH,
  PASSWORD_COMPLEXITY_MIN_LOWERCASE_LETTERS,
  PASSWORD_COMPLEXITY_MIN_NUMBERS,
  PASSWORD_COMPLEXITY_MIN_SPECIAL_CHARACTERS,
  PASSWORD_COMPLEXITY_MIN_UPPERCASE_LETTERS,
  PASSWORD_COMPLEXITY_SPECIAL_CHARACTERS,
} from '../constants';
import { useInternationalization } from '../hooks';
import { palette } from '../styles/palette';
import { Styles } from '../types';
import { testPasswordComplexity } from '../utils/password';

const style: Styles = {
  title: { fontSize: '14px' },
  list: {
    my: 0,
    pl: '1em',
    listStyleType: '"\\2013\\00A0"',
  },
  requirement: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  validRequirement: {
    color: palette.success.main,
    fontWeight: 600,
  },
  validIcon: {
    width: '14px',
    height: 'auto',
    ml: 0.5,
    lineHeight: '0px',
    display: 'inline-block',
  },
};

interface PasswordComplexityStatusProps {
  password: string;
}

export const PasswordComplexityStatus = ({ password }: PasswordComplexityStatusProps) => {
  const { t } = useInternationalization();

  const complexityStatus = testPasswordComplexity(password);

  const renderRequirement = (
    translationKey: string,
    translationOptions: object,
    conformsToComplexityRequirement: boolean,
  ) => (
    <Typography
      component="li"
      sx={{ ...style.requirement, ...(conformsToComplexityRequirement ? style.validRequirement : {}) }}
    >
      {t(translationKey, translationOptions)}
      {conformsToComplexityRequirement && (
        <CheckCircleRounded fontSize="small" htmlColor={palette.success.main} sx={style.validIcon} />
      )}
    </Typography>
  );

  return (
    <Stack>
      <Typography variant="body1" sx={style.title}>
        {t('auth:passwordComplexity.title')}
      </Typography>
      <Box component="ul" sx={style.list}>
        {renderRequirement(
          'auth:passwordComplexity.length',
          { count: PASSWORD_COMPLEXITY_MIN_LENGTH },
          complexityStatus.length,
        )}
        {renderRequirement(
          'auth:passwordComplexity.numbers',
          { count: PASSWORD_COMPLEXITY_MIN_NUMBERS },
          complexityStatus.numbers,
        )}
        {renderRequirement(
          'auth:passwordComplexity.specialCharacters',
          {
            count: PASSWORD_COMPLEXITY_MIN_SPECIAL_CHARACTERS,
            characters: PASSWORD_COMPLEXITY_SPECIAL_CHARACTERS.split('').join(' '),
          },
          complexityStatus.specialCharacters,
        )}
        {renderRequirement(
          'auth:passwordComplexity.uppercaseLetters',
          { count: PASSWORD_COMPLEXITY_MIN_UPPERCASE_LETTERS },
          complexityStatus.uppercaseLetters,
        )}
        {renderRequirement(
          'auth:passwordComplexity.lowercaseLetters',
          { count: PASSWORD_COMPLEXITY_MIN_LOWERCASE_LETTERS },
          complexityStatus.lowercaseLetters,
        )}
        {renderRequirement('auth:passwordComplexity.trailingSpace', {}, complexityStatus.trailingSpace)}
      </Box>
    </Stack>
  );
};
