import { Card, Typography, CardContent, Stack, CircularProgress } from '@mui/material';
import { search as searchApi } from '../../api';
import { useApi, useConfirmationModal, useInternationalization } from '../../hooks';
import { palette } from '../../styles/palette';
import { Styles, TaskResult } from '../../types';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { formatDateTime } from '../../utils/helper';
import { TaskLogEntity } from '../../models';
import { CheckRounded, ErrorRounded } from '@mui/icons-material';

const styles: Styles = {
  card: {
    my: 3,
  },
  cardContent: {
    pt: 1,
  },
  headerTitle: {
    py: 1.5,
    borderBottom: 'solid 1px ' + palette.grey[300],
    mb: 2,
    pb: 2,
  },
  completedStatus: {
    color: palette.success.main,
  },
  failedStatus: {
    color: palette.error.main,
  },
  inProgressStatus: {
    color: palette.grey[500],
  },
  circularProgress: {
    ml: 2,
  },
};

export const SettingSearch = () => {
  const { t, timeZoneName } = useInternationalization();
  const { openModal } = useConfirmationModal();
  const { call: reindex, isLoading: isReindexLoading } = useApi(searchApi.reindex, null);
  const {
    data: reindexStatus,
    isLoading: isReindexStatusLoading,
    refresh: refreshReindexStatus,
  } = useApi(searchApi.reindexStatus, { callImmediately: true });
  const [refreshInterval, setRefreshInteval] = useState<NodeJS.Timer>();
  const [isReindexing, setIsReindexing] = useState(false);

  const isReindexInProgress =
    isReindexing || isReindexStatusLoading || isReindexLoading || reindexStatus?.canReindex === false;

  const onReindexClick = () => {
    openModal({
      confirmText: t('navigation:search.reindex'),
      title: t('navigation:search.reindex'),
      description: t('navigation:search.reindexConfirmation'),
      onConfirm: async () => {
        setIsReindexing(true);
        await reindex();
        setTimeout(async () => {
          await refreshReindexStatus();
        }, 1000);
      },
    });
  };

  useEffect(() => {
    if (reindexStatus?.canReindex === false) {
      !refreshInterval && setRefreshInteval(setInterval(() => refreshReindexStatus(), 1000));
    } else {
      if (refreshInterval) {
        clearInterval(refreshInterval);
        setRefreshInteval(undefined);
      }
      setIsReindexing(false);
    }
    return () => {
      refreshInterval && clearInterval(refreshInterval);
      refreshInterval && setRefreshInteval(undefined);
    };
  }, [refreshInterval, refreshReindexStatus, reindexStatus]);

  const renderStatus = (taskLog: TaskLogEntity) => (
    <Typography
      variant="status"
      sx={
        taskLog.taskResult === TaskResult.Completed
          ? styles.completedStatus
          : taskLog.taskResult === TaskResult.Failed
          ? styles.failedStatus
          : styles.inProgressStatus
      }
    >
      {t(`common:taskResult.${taskLog.taskResult}`, { context: 'female' })}
      {taskLog.taskResult === TaskResult.Completed && <CheckRounded htmlColor={palette.success.main} />}
      {taskLog.taskResult === TaskResult.Failed && <ErrorRounded htmlColor={palette.error.main} />}
      {taskLog.taskResult === TaskResult.InProgress && <CircularProgress size={12} sx={styles.circularProgress} />}
    </Typography>
  );

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Typography variant="h3" sx={styles.headerTitle}>
          {t('navigation:search.settingTitle')}
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <LoadingButton variant="outlined" onClick={onReindexClick} loading={isReindexInProgress}>
            {t('navigation:search.reindex')}
          </LoadingButton>
          {reindexStatus?.lastReindexation && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>
                {t('navigation:search.lastReindexation', {
                  date: formatDateTime(reindexStatus.lastReindexation.startDate, timeZoneName),
                  user: reindexStatus.lastReindexation.user,
                })}
              </Typography>
              {renderStatus(reindexStatus.lastReindexation)}
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
