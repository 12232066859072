import { Box, Button, Stack, StackProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useInternationalization } from '../../hooks';
import { Styles } from '../../types';

const style: Styles = {
  clearAllContainer: {
    pt: {
      xs: 0,
      md: 3.5,
    },
  },
};

interface FilterContainerProps extends PropsWithChildren, StackProps {
  onClear?: () => void;
}

export const FilterContainer = ({ children, onClear, ...props }: FilterContainerProps) => {
  const { t } = useInternationalization();
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, md: 3 }} alignItems="flex-end" {...props}>
      {children}
      {onClear && (
        <Box sx={style.clearAllContainer}>
          <Button onClick={onClear}>{t('common:filter.clearAll')}</Button>
        </Box>
      )}
    </Stack>
  );
};
