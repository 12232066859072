import { ArchiveRounded, SyncRounded, UnarchiveRounded } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { VendorDetailContext } from '../../../contexts';
import { useApi, useInternationalization, useSettings } from '../../../hooks';
import { VendorContactEntity } from '../../../models';
import { ContactType, PermissionKey, VendorContactFilter } from '../../../types';
import { ArchiveMenuItem, MailSyncMenuItem } from '../../Menu';
import { VendorContactsList } from './VendorContactsList';

export const VendorContactsListAdmin = () => {
  const { settings } = useSettings();
  const { t, getTranslation } = useInternationalization();
  const { vendor, vendorId } = useContext(VendorDetailContext);
  const [filter, setFilter] = useState<VendorContactFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });

  const getAllContactsApi = useApi(vendorApi.getAllContacts, { skipFetch: !vendor }, vendor?.id, filter);
  const { call: create } = useApi(vendorApi.createContact, { successKey: 'common:success.save' });
  const { call: update } = useApi(vendorApi.updateContact, { successKey: 'common:success.save' });
  const archiveApi = useApi(vendorApi.archiveContact, { successKey: 'common:success.action' });
  const mailSyncApi = useApi(vendorApi.mailSyncContacts, {
    successKey: 'common:success.action',
    errorKey: 'common:error.mailSync',
  });

  const bulkActions = [
    {
      icon: <SyncRounded />,
      label: t('common:action.mailSync'),
      onConfirm: async (items: VendorContactEntity[]) => onMailSyncConfirm(items),
      permissions: { keys: PermissionKey.VendorApproveContacts },
      disabled: !settings?.isMailSyncEnabled,
    },
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: VendorContactEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: VendorContactEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onSave = async (entity: VendorContactEntity) => {
    return entity.id ? await update(vendorId, entity) : await create(vendorId, entity);
  };

  const onArchiveConfirm = async (isArchived: boolean, contacts: VendorContactEntity[]) => {
    await archiveApi.call(
      vendorId,
      contacts.map((i) => i.id),
      isArchived,
    );
    getAllContactsApi.refresh();
  };

  const onMailSyncConfirm = async (contacts: VendorContactEntity[]) => {
    await mailSyncApi.call(
      vendorId,
      contacts.map((i) => i.id),
    );
    getAllContactsApi.refresh();
  };

  const renderActionsMenuItems = (entity: VendorContactEntity, onClick: () => void) => [
    <MailSyncMenuItem
      key="mailSync"
      permissions={{ keys: PermissionKey.VendorApproveContacts }}
      entity={entity}
      onClick={onClick}
      onMailSync={(entity) => onMailSyncConfirm([entity])}
      isVisible={
        (!!entity.mailSyncError || (!entity.mailSyncDate && !entity.isArchived)) && entity.type == ContactType.Person
      }
      shouldDelete={entity.isArchived || !!vendor?.isArchived}
    />,
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={
        entity.type === ContactType.Department
          ? getTranslation(entity, 'department')
          : `${entity.firstName} ${entity.lastName}`
      }
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('vendor:contact.actions.suffix')}
    />,
  ];

  return (
    <VendorContactsList
      layout="Tab"
      showSearch
      addButtonProps={{
        disabled: !vendor || vendor.isArchived,
      }}
      filter={filter}
      setFilter={setFilter}
      fetchApi={getAllContactsApi}
      onSave={onSave}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
        bulkActions: vendor && !vendor.isArchived ? bulkActions : undefined,
        bulkActionSuffix: t('vendor:contact.actions.suffix'),
        bulkPermissions: { keys: PermissionKey.VendorEditContacts },
        renderBulkSelection: (items) =>
          items.length === 1
            ? items[0].type === ContactType.Department
              ? getTranslation(items[0], 'department')
              : `${items[0].firstName} ${items[0].lastName}`
            : t(`vendor:contact.title`),
      }}
    />
  );
};
