import { Chip, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { vendor as vendorApi } from '../../../../api';
import { MAX_PAGINATION_FILTER } from '../../../../constants';
import { VendorBuyingAgreementContext } from '../../../../contexts/Vendor';
import { useApi, useEnumList, useInternationalization } from '../../../../hooks';
import { ChangeLogFilter, ChangeLogType, Styles } from '../../../../types';
import { ChangeLogs } from '../../../ChangeLog';
import { VendorBuyingAgreementChangeLogItem } from './VendorBuyingAgreementChangeLogItem';

const style: Styles = {
  filterContainer: {
    px: 2.5,
    py: 1,
    overflowX: 'auto',
  },
};

const defaultFilter: ChangeLogFilter = {
  changeLogType: ChangeLogType.All,
  ...MAX_PAGINATION_FILTER,
};

export const VendorBuyingAgreementChangeLogs = () => {
  const { t } = useInternationalization();
  const { vendorBuyingAgreement } = useContext(VendorBuyingAgreementContext);
  const [filter, setFilter] = useState(defaultFilter);
  const changeLogTypeOptions = useEnumList(ChangeLogType, 'changeLog:type', false, undefined, false);
  const { data, isLoading, refresh } = useApi(
    vendorApi.getAllBuyingAgreementChangeLogs,
    { callImmediately: false, skipFetch: true },
    vendorBuyingAgreement.vendorId,
    vendorBuyingAgreement.id,
    filter,
  );

  const { call: addComment } = useApi(vendorApi.addBuyingAgreementComment, { successKey: 'common:success.action' });

  const onTypeSelect = (value: ChangeLogType) => () => setFilter((prev) => ({ ...prev, changeLogType: value }));

  const onCommentAdded = async (text: string) => {
    await addComment(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, text);
    refresh();
  };

  const renderFilterSection = () => (
    <Stack sx={style.filterContainer} direction="row" spacing={2} alignItems="center">
      <Typography align="center" variant="label" color="grey.600">
        {t('common:show')}
      </Typography>
      <Stack spacing={0.7} direction="row">
        {changeLogTypeOptions.map((option) => (
          <Chip
            key={option.value}
            label={option.label}
            onClick={onTypeSelect(option.value)}
            color={option.value === filter.changeLogType ? 'primary' : 'secondary'}
          />
        ))}
      </Stack>
    </Stack>
  );

  return (
    <ChangeLogs
      title={t('changeLog:vbaTitle')}
      data={data}
      isLoading={isLoading}
      refresh={refresh}
      renderFilter={renderFilterSection}
      renderItem={(item) => <VendorBuyingAgreementChangeLogItem key={item.id} item={item} />}
      onCommentAdded={onCommentAdded}
    />
  );
};
