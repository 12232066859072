import { CloseRounded } from '@mui/icons-material';
import { Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModalContext } from '../contexts';
import { palette } from '../styles/palette';
import { Styles } from '../types';

const style: Styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: palette.white,
    p: 3,
    width: {
      xs: '90%',
      md: 'auto',
    },
    minWidth: '400px',
    minHeight: '150px',
    borderRadius: '15px',
  },
  close: {
    mt: '-18px !important',
    mr: '-18px !important',
  },
};

type PromiseInfo = {
  resolve: (value: boolean) => void;
  reject: () => void;
};

export const ConfirmationModal = React.forwardRef((_, ref) => {
  const {
    modalProps: { open, onCancel, onConfirm, title, description, confirmText },
    setModalProps,
  } = useContext(ConfirmationModalContext);
  const { t } = useTranslation();
  const promiseInfo = useRef<PromiseInfo | null>(null);

  useImperativeHandle(ref, () => ({
    waitForResponse: () => {
      return new Promise<boolean>((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };
      });
    },
  }));

  const onCloseModal = () => {
    promiseInfo.current?.resolve(false);
    setModalProps({ open: false });
    onCancel && onCancel();
  };

  const onConfirmModal = () => {
    promiseInfo.current?.resolve(true);
    setModalProps({ open: false });
    onConfirm && onConfirm();
  };

  return (
    <Modal open={open} onClose={onCloseModal}>
      <Stack sx={style.container} spacing={5} direction="column" justifyContent="space-between">
        <Stack spacing={1}>
          <Stack spacing={5} direction="row" alignItems="flex-start" justifyContent="space-between">
            <Typography variant="modalTitle">{title}</Typography>
            <IconButton id="close" onClick={onCloseModal} sx={style.close}>
              <CloseRounded htmlColor={palette.primary.deep} fontSize="large" />
            </IconButton>
          </Stack>
          <Typography variant="modalDescription">{description}</Typography>
        </Stack>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <Button id="cancel" onClick={onCloseModal} variant="outlined">
            {t('common:cancel')}
          </Button>
          <Button id="confirm" onClick={onConfirmModal} variant="contained">
            {confirmText || t('common:confirm')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
});
