import { report as reportsApi } from '../../../api';
import { VendorDataReportList } from '../../../components/Report';

export function VendorProductCategoriesReports() {
  return (
    <VendorDataReportList
      dataApi={reportsApi.getAllVendorProductCategories}
      exportApi={reportsApi.exportVendorProductCategories}
      reportType="ProductCategories"
    />
  );
}
