import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HIDE_FILE_EXTENSIONS } from '../../../../constants';
import { palette } from '../../../../styles/palette';
import { Styles } from '../../../../types';
import { getAuthorizeFileExtensions } from '../../../../utils/helper';

const style: Styles = {
  attachmentsActionContainer: {
    border: '2px dashed' + palette.grey[300],
    height: '100px',
    borderRadius: '16px',
  },
  addAttachments: {
    '&:hover': {
      background: 'transparent',
    },
    marginLeft: '-3px',
    py: 0,
  },
  dragOver: {
    border: '2px dashed' + palette.primary.main,
  },
};

type Props = {
  onDrop: (files: FileList) => void;
  onClick: () => void;
  maxSizeMB: number;
  maxCount?: number;
  onlyImg?: boolean;
};

export const AttachmentsBanner = ({ onDrop, onClick, maxSizeMB, maxCount, onlyImg }: Props) => {
  const { t } = useTranslation();
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragOver(true);
    ev.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragOver(false);
    onDrop(ev.dataTransfer.files);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const renderFileExtensions = () => (
    <Typography variant="fileHelper" textTransform="uppercase">
      {getAuthorizeFileExtensions(onlyImg)
        .filter((x) => !HIDE_FILE_EXTENSIONS.map((f) => f.toUpperCase()).includes(x.toUpperCase()))
        .join(', ')}
    </Typography>
  );

  return (
    <Box
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      sx={[style.attachmentsActionContainer, isDragOver && style.dragOver]}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Trans i18nKey="common:file.help">
          <Typography variant="button">0</Typography>
          <Button onClick={onClick} sx={style.addAttachments}>
            1
          </Button>
        </Trans>
      </Box>
      <Stack spacing={0.5} display="flex" alignItems="center">
        <Typography variant="fileHelper">{t('common:file.maxFiles', { count: maxCount ?? 0, maxSizeMB })}</Typography>
        {renderFileExtensions()}
      </Stack>
    </Box>
  );
};
