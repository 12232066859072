import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Breadcrumbs } from '../components';
import { LayoutPublic } from '../components/Layout';
import { useInternationalization, usePageTitle } from '../hooks';
import { routes } from '../routes';
import { Styles } from '../types';

const style: Styles = {
  content: {
    '& ol': {
      counterReset: 'item',
    },
    '& li': {
      display: 'block',
      mt: 1.25,
    },
    '& li:before': {
      content: 'counters(item, ".") ". "',
      counterIncrement: 'item',
      pr: 1.25,
      ml: -5,
    },
    '& ol ol ol li:before': {
      content: 'counter(item, lower-alpha) ") "',
    },
  },
};

export function TermsAndConditions() {
  const { t } = useInternationalization();
  usePageTitle('auth:termsAndConditions.title');

  return (
    <LayoutPublic title={t('auth:termsAndConditions.title')} variant="large">
      <Typography variant="body1" component="div" sx={style.content}>
        <Trans i18nKey="auth:termsAndConditions.content" />
      </Typography>
      <Breadcrumbs breadcrumbs={[{ href: routes.Login.path, title: t('auth:backToLogin') }]} />
    </LayoutPublic>
  );
}
