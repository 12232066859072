import { CategorizationEntity } from '../Categorization';
import { EditableEntity } from '../EditableEntity';
import { CommitmentEventCategoryVendorEntity } from './CommitmentEventCategoryVendorEntity';

export class CommitmentEventCategoryEntity extends EditableEntity {
  name_En: string;
  name_Fr: string;
  isCommitted: boolean;
  effectiveYear?: number;
  expiryYear?: number;
  productCategoryId: number | null;
  productCategory: CategorizationEntity;
  isArchived: boolean;
  vendors: CommitmentEventCategoryVendorEntity[];
  commitmentEventId: number;
  yearOverYear?: number;

  constructor(entity?: CommitmentEventCategoryEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
      this.productCategoryId = entity.productCategory?.id;
      this.vendors =
        entity.vendors?.map((v) => ({
          ...v,
          vendorId: v.vendor?.id ?? null,
          id: Number(`${entity.commitmentEventId}${v.vendorId}`),
        })) ?? [];
    } else {
      this.name_En = '';
      this.name_Fr = '';
      this.isCommitted = false;
      this.effectiveYear = undefined;
      this.expiryYear = undefined;
      this.productCategoryId = null;
      this.isArchived = false;
      this.vendors = [];
    }
  }
}
