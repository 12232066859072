import React, { Dispatch, SetStateAction } from 'react';
import { CategorizationEntity } from '../../models';
import { CategorizationList, CategorizationType, TranslationContext, WithoutLanguageSuffix } from '../../types';

interface CategorizationContextType {
  type: CategorizationType;
  data: CategorizationList | null;
  setData: Dispatch<SetStateAction<CategorizationList | null>>;
  fetchData: () => Promise<void>;
  isLoading: boolean;
  translationContext: TranslationContext;
  omitFields?: WithoutLanguageSuffix<keyof CategorizationEntity>[];
  isFieldVisible: (fieldName: WithoutLanguageSuffix<keyof CategorizationEntity>) => boolean;
}

export const CategorizationContext = React.createContext<CategorizationContextType>({} as CategorizationContextType);
