import { MenuItem, MenuItemProps } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useConfirmationModal } from '../../hooks';
import { Entity } from '../../types';

interface ResetPasswordMenuItemProps<T extends Entity> extends MenuItemProps {
  entity: T;
  name: string;
  onResetPasswordConfirm: (entity: T) => void;
  onClick: () => void;
  actionSuffix: string;
}

export const ResetPasswordMenuItem = <T extends Entity>({
  entity,
  name,
  onResetPasswordConfirm,
  onClick,
  actionSuffix,
  ...props
}: ResetPasswordMenuItemProps<T>) => {
  const { openModal } = useConfirmationModal();
  const { t } = useTranslation();

  const onResetPasswordClick = () => {
    openModal({
      confirmText: t('common:action.confirmButton', { action: t('common:action.resetPassword') }),
      title: t('common:action.confirmTitle', {
        name: actionSuffix,
        action: t('common:action.resetPassword'),
        count: 1,
      }),
      description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name }} />,
      onConfirm: () => onResetPasswordConfirm(entity),
    });
    onClick && onClick();
  };

  return (
    <MenuItem {...props} id="resetPassword" key="resetPassword" onClick={onResetPasswordClick}>
      {t('common:action.resetPassword')}
    </MenuItem>
  );
};
