import axios from 'axios';
import {
  VendorRebateCategoryEntity,
  VendorRebateCategoryProgramEntity,
  VendorRebateCategoryProgramRebateEntity,
  VendorRebateCategoryRebateSummaryEntity,
} from '../../models';
import {
  PaginationEntity,
  PaginationFilter,
  VendorRebateCategoryProgramFilter,
  VendorRebateCategoryProgramTriggers,
} from '../../types';
import { getContentDispositionFileName } from '../../utils/file';
import { VendorRebateProgramCalculateEntity } from '../../models/Vendor/VendorRebateProgramCalculateEntity';

export const program = {
  getAllRebateCategoryPrograms: async (
    vendorId: number,
    rebateCategoryId: number,
    programFilter: VendorRebateCategoryProgramFilter,
  ): Promise<VendorRebateCategoryProgramEntity[]> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/search`,
      programFilter,
    );
    return {
      ...data,
      data: data.data.map((v: VendorRebateCategoryProgramEntity) => new VendorRebateCategoryProgramEntity(v)),
    };
  },
  getRebateCategoryProgramNextNumber: async (vendorId: number, rebateCategoryId: number): Promise<number> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/nextNumber`);
    return data;
  },
  createRebateCategoryProgram: async (
    vendorId: number,
    rebateCategoryId: number,
    program: VendorRebateCategoryProgramEntity,
  ): Promise<VendorRebateCategoryProgramEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs`, program);
    return new VendorRebateCategoryProgramEntity(data);
  },
  updateRebateCategoryProgram: async (
    vendorId: number,
    rebateCategoryId: number,
    program: VendorRebateCategoryProgramEntity,
  ): Promise<VendorRebateCategoryProgramEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/${program.id}`,
      program,
    );
    return new VendorRebateCategoryProgramEntity(data);
  },
  getRebateCategoryProgram: async (
    vendorId: number,
    rebateCategoryId: number,
    rebateProgramId: number,
  ): Promise<VendorRebateCategoryProgramEntity> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/${rebateProgramId}`,
    );
    return new VendorRebateCategoryProgramEntity(data);
  },
  archiveRebateCategoryProgram: async (
    vendorId: number,
    rebateCategoryId: number,
    ids: number[],
    isArchived: boolean,
  ): Promise<VendorRebateCategoryEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/archive`, {
      isArchived,
      ids,
    });
    return new VendorRebateCategoryEntity(data);
  },
  getRebateCategoryProgramRebate: async (
    vendorId: number,
    rebateCategoryId: number,
    vendorRebateCategoryProgramId: number,
  ): Promise<VendorRebateCategoryProgramRebateEntity> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/${vendorRebateCategoryProgramId}/rebates`,
    );
    return new VendorRebateCategoryProgramRebateEntity(data);
  },
  updateRebateCategoryProgramRebate: async (
    vendorId: number,
    rebateCategoryId: number,
    vendorRebateCategoryProgramId: number,
    entity: VendorRebateCategoryProgramRebateEntity,
  ): Promise<VendorRebateCategoryProgramRebateEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/${vendorRebateCategoryProgramId}/rebates`,
      entity,
    );
    return new VendorRebateCategoryProgramRebateEntity(data);
  },
  getRebateCategoryProgramInputSheet: async (
    vendorId: number,
    rebateCategoryId: number,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programInputSheet`, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  getAllRebatePrograms: async (
    vendorId: number,
    programFilter: VendorRebateCategoryProgramFilter,
  ): Promise<PaginationEntity<VendorRebateCategoryProgramEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/rebateprograms/search`, programFilter);
    return {
      ...data,
      data: data.data.map((v: VendorRebateCategoryProgramEntity) => new VendorRebateCategoryProgramEntity(v)),
    };
  },
  getProgramRebateCategories: async (
    vendorId: number,
    filter: PaginationFilter,
  ): Promise<PaginationEntity<VendorRebateCategoryEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/rebateprograms/categories/search`, filter);
    return { ...data, data: data.data.map((v: VendorRebateCategoryEntity) => new VendorRebateCategoryEntity(v)) };
  },
  getRebateProgram: async (vendorId: number, rebateProgramId: number): Promise<VendorRebateCategoryProgramEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebateprograms/${rebateProgramId}`);
    return new VendorRebateCategoryProgramEntity(data);
  },
  getRebateProgramRebate: async (
    vendorId: number,
    rebateProgramId: number,
  ): Promise<VendorRebateCategoryProgramRebateEntity> => {
    const { data } = await axios.get(`api/vendor/${vendorId}/rebateprograms/${rebateProgramId}/rebates`);
    return new VendorRebateCategoryProgramRebateEntity(data);
  },
  calculateRebateProgramRebate: async (
    vendorId: number,
    rebateProgramId: number,
    rebateProgramCalculate: VendorRebateProgramCalculateEntity,
  ): Promise<VendorRebateCategoryRebateSummaryEntity> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/rebateprograms/${rebateProgramId}/calculate`,
      rebateProgramCalculate,
    );
    return new VendorRebateCategoryRebateSummaryEntity(data);
  },
  updateWorkflowRebateProgram: async (
    vendorId: number,
    rebateCategoryId: number,
    rebateProgramId: number,
    trigger: VendorRebateCategoryProgramTriggers,
  ): Promise<VendorRebateCategoryProgramEntity> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/rebatecategories/${rebateCategoryId}/programs/${rebateProgramId}/${trigger}`,
    );
    return new VendorRebateCategoryProgramEntity(data);
  },
};
