import React from 'react';
import { ForestProductPriceUpdateEntity } from '../../models';

interface ForestProductPriceUpdateContextType {
  priceUpdate: ForestProductPriceUpdateEntity | null;
  refresh: () => void;
}

export const ForestProductPriceUpdateContext = React.createContext<ForestProductPriceUpdateContextType>(
  {} as ForestProductPriceUpdateContextType,
);
