import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@mui/material';
import { PermissionProps } from '../../types';
import { HasPermissions } from '../Permission';

export interface MenuItemProps extends MuiMenuItemProps {
  permissions?: PermissionProps;
  isVisible?: boolean;
}

export const MenuItem = ({ permissions, isVisible = true, ...props }: MenuItemProps) =>
  isVisible ? (
    <HasPermissions {...permissions}>
      <MuiMenuItem {...props}></MuiMenuItem>
    </HasPermissions>
  ) : null;
