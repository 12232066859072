import { Grid } from '@mui/material';
import { useEntityFormContext, useEnumList, useInternationalization } from '../../hooks';
import { MemberGroupEntity } from '../../models';
import { MemberGroupType } from '../../types';
import { ControlledRadioSelect } from '../Form';

export const MemberGroupTypeForm = () => {
  const { t } = useInternationalization();
  const { control } = useEntityFormContext<MemberGroupEntity>();
  const memberGroupTypeOptions = useEnumList(MemberGroupType, 'memberGroup:type', false, 'male', false, true);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ControlledRadioSelect
          label={t('memberGroup:table.type')}
          options={memberGroupTypeOptions}
          name="type"
          control={control}
          row
        />
      </Grid>
    </Grid>
  );
};
