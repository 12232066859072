import { PropsWithChildren, useMemo } from 'react';
import { ErrorBoundary } from '..';
import { useInternationalization } from '../../hooks';
import useAuth from '../../hooks/useAuth';
import { PermissionProps } from '../../types';

interface RequirePermissionProps extends PropsWithChildren, PermissionProps {}

export function RequirePermission({ children, keys, any }: RequirePermissionProps) {
  const { hasPermissions } = useAuth();
  const { t } = useInternationalization();

  const userHasPermissions = useMemo(() => !keys || hasPermissions(keys, any), [hasPermissions, keys, any]);

  if (!userHasPermissions) {
    return <ErrorBoundary message={t('common:error.noAccess')} />;
  }

  return children as React.ReactElement;
}
