import { Box, Grid } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { categorization as categorizationApi, vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryProgramContext } from '../../../contexts';
import { VendorRebateCategoryProgramDetailContext } from '../../../contexts/Vendor';
import { useApi, useEntityFormContext, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryProgramEntity } from '../../../models';
import { Styles } from '../../../types';
import { ControlledInput, ControlledMultiSelect, ControlledTranslatedInput, StaticField } from '../../Form';
import { PeriodForm } from '../../PeriodForm';

const style: Styles = {
  container: {
    pb: 4,
  },
  fieldGroup: {
    py: 3,
  },
  notesHelperText: {
    mt: 1,
  },
};

export const VendorRebateCategoryProgramForm = () => {
  const { t, getTranslation } = useInternationalization();
  const vendorRebateCategoryProgramContext = useContext(VendorRebateCategoryProgramContext);
  const vendorRebateCategoryProgramDetailContext = useContext(VendorRebateCategoryProgramDetailContext);
  const vendorId = vendorRebateCategoryProgramContext.vendorId ?? vendorRebateCategoryProgramDetailContext.vendorId;
  const vendorRebateCategory =
    vendorRebateCategoryProgramContext.vendorRebateCategory ??
    vendorRebateCategoryProgramDetailContext.vendorRebateCategory;
  const vendorRebateCategoryId =
    vendorRebateCategoryProgramContext.vendorRebateCategory?.id ??
    vendorRebateCategoryProgramDetailContext.vendorRebateCategoryId;

  const { vendorRebateCategoryProgram } = vendorRebateCategoryProgramDetailContext;

  const vendorDesignations = useApi(categorizationApi.getAll, null, 'vendorDesignation', false);
  const { data: nextNumber } = useApi(
    vendorApi.getRebateCategoryProgramNextNumber,
    { skipFetch: !!vendorRebateCategoryProgram?.id },
    vendorId,
    vendorRebateCategoryId,
  );

  const { setValue, getValues, control, readOnly, variant, trigger } =
    useEntityFormContext<VendorRebateCategoryProgramEntity>();

  const rebateProgram = getValues();

  const isBasedOnMemberCriteria = rebateProgram.targetedMembership?.isBasedOnMemberCriteria;

  useEffect(() => {
    if (!vendorRebateCategoryProgram?.id) {
      setValue('number', nextNumber ?? 1);
    }
  }, [nextNumber, setValue, vendorRebateCategoryProgram?.id]);

  useEffect(() => {
    if (!vendorRebateCategoryProgram?.id) {
      setValue('name_En', `${vendorRebateCategory?.name_En}`, { shouldDirty: true });
      vendorRebateCategory?.name_Fr && setValue('name_Fr', `${vendorRebateCategory?.name_Fr}`, { shouldDirty: true });
      trigger();
    }
  }, [vendorRebateCategoryProgram, vendorRebateCategory, nextNumber, setValue, trigger]);

  return (
    <Box sx={style.container}>
      <Grid container spacing={3} sx={variant === 'drawer' ? style.fieldGroup : undefined}>
        <Grid
          item
          xs={variant === 'drawer' ? 6 : 2}
          alignItems={variant === 'drawer' ? 'center' : 'start'}
          display="flex"
        >
          <ControlledInput
            label={t('vendor:rebateCategory.programs.form.programNumber')}
            name="number"
            type="number"
            control={control}
            readOnly
          />
        </Grid>
        {variant === 'drawer' && (
          <>
            <Grid item xs={6}>
              <ControlledTranslatedInput
                label={t('vendor:rebateCategory.programs.form.name')}
                name="name"
                control={control}
                required
              />
            </Grid>
          </>
        )}
        <Grid item xs={variant === 'drawer' ? 12 : 2}>
          <ControlledMultiSelect
            label={t('vendor:rebateCategory.programs.form.vendorDesignation')}
            name="vendorDesignationIds"
            options={
              vendorDesignations.data?.values?.map((d) => ({ label: getTranslation(d, 'name'), value: d.id })) ?? []
            }
            control={control}
            mode="autocomplete"
          />
        </Grid>
        <PeriodForm xs={variant === 'drawer' ? 6 : 2} />
        {variant !== 'drawer' && (
          <>
            <Grid item xs={4}>
              <ControlledMultiSelect
                label={t('vendor:targetedMembership.form.membershipTypes')}
                name="targetedMembership.membershipTypeIds"
                options={
                  isBasedOnMemberCriteria
                    ? rebateProgram.targetedMembership?.membershipTypes?.map((d) => ({
                        label: getTranslation(d, 'name'),
                        value: d.id,
                      })) ?? []
                    : []
                }
                control={control}
                mode="autocomplete"
                readOnly
                defaultToAll={isBasedOnMemberCriteria}
              />
            </Grid>
            <Grid item xs={2}>
              <ControlledMultiSelect
                label={t('vendor:targetedMembership.form.regions')}
                name="targetedMembership.regionIds"
                options={
                  isBasedOnMemberCriteria
                    ? rebateProgram.targetedMembership?.regions?.map((d) => ({
                        label: getTranslation(d, 'name'),
                        value: d.id,
                      })) ?? []
                    : []
                }
                control={control}
                mode="autocomplete"
                readOnly
                defaultToAll={isBasedOnMemberCriteria}
              />
            </Grid>
            <Grid item xs={6}>
              <StaticField
                label={t('vendor:rebateCategory.programs.form.hasCustomPermissions')}
                value={t(
                  `common:boolean.${
                    !!rebateProgram.targetedMembership?.memberGroups?.length ||
                    !!rebateProgram.targetedMembership?.members?.length
                  }`,
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={variant === 'drawer' ? 12 : 6}>
          <ControlledTranslatedInput
            label={t('vendor:rebateCategory.programs.form.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={variant === 'drawer' ? 12 : 6}>
          <ControlledInput
            label={t('vendor:rebateCategory.programs.form.notes')}
            name="notes"
            control={control}
            multiline
            rows={3}
          />
          <Box sx={style.notesHelperText}>{!readOnly && <Trans i18nKey="common:formHelperText.notes" />}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};
