import { object, string } from 'yup';
import { MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { InternalContactEntity } from '../../models';
import { EntityFormSchema } from '../../types';
import { AddressSchema, EmailAddressSchema, PhoneNumberSchema } from '.';

export const InternalContactSchema = (): EntityFormSchema<InternalContactEntity> => ({
  schema: object({
    firstName: string().required().max(MAX_SHORT_TEXT_LENGTH),
    lastName: string().required().max(MAX_SHORT_TEXT_LENGTH),
    role_En: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    role_Fr: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    memberNotes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    memberNotes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    vendorNotes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    vendorNotes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
    address: AddressSchema(false).schema,
  })
    .concat(PhoneNumberSchema().schema)
    .concat(EmailAddressSchema().schema),
  errorCodeMap: {},
});
