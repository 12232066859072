import { SyncDisabledRounded } from '@mui/icons-material';
import { createSearchParams } from 'react-router-dom';
import { vendor as vendorApi } from '../../../api';
import { MIN_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { routes } from '../../../routes';
import { VendorFilter } from '../../../types';
import { CounterWidget } from './CounterWidget';
import { WidgetProps } from './Widget';

const filter: VendorFilter = {
  ...MIN_PAGINATION_FILTER,
  mailSyncErrorOnly: true,
};

export const FailedMailSyncVendors = (props: WidgetProps) => {
  const { t } = useInternationalization();
  const { data, isLoading } = useApi(vendorApi.getAll, null, filter);

  return (
    <CounterWidget
      {...props}
      isLoading={isLoading}
      count={data?.totalCount}
      title={t('dashboard:failedMailSyncVendors:title')}
      icon={SyncDisabledRounded}
      link={`${routes.Admin.Vendor.path}?${createSearchParams({ mailSyncErrorOnly: 'true' }).toString()}`}
    />
  );
};
