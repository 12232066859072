import { AddRounded, InsertDriveFileRounded, ModeCommentRounded } from '@mui/icons-material';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditDrawer, EntityPeriod } from '../..';
import { useAuth, useGlobalEdit } from '../../../hooks';
import { VendorBuyingAgreementEntity } from '../../../models/Vendor/VendorBuyingAgreementEntity';
import { VendorBuyingAgreementSchema } from '../../../schemas';
import { palette } from '../../../styles/palette';
import {
  BaseComponentListProps,
  FilterPeriod,
  PermissionKey,
  VendorBuyingAgreementFilter,
  VendorBuyingAgreementStatus,
  VendorBuyingAgreementType,
} from '../../../types';
import { formatLongDate } from '../../../utils/helper';
import { CardList, CardListElement, CardListRow } from '../../Card';
import { FilterContainer } from '../../Container';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { PeriodFilterSelect } from '../../Filter/PeriodFilterSelect';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { VendorBuyingAgreementExportMenuItem } from './VendorBuyingAgreementExportMenuItem';
import { VendorBuyingAgreementForm } from './VendorBuyingAgreementForm';
import { VendorBuyingAgreementHeader } from './VendorBuyingAgreementHeader';
import { VendorBuyingAgreementStatusDisplay } from './VendorBuyingAgreementStatusDisplay';

const defaultFilter = {
  period: FilterPeriod.All,
  isArchived: false,
};

interface VendorBuyingAgreementsListProps
  extends BaseComponentListProps<VendorBuyingAgreementEntity, VendorBuyingAgreementFilter> {
  showAddButton: boolean;
  redirectPath: (entity: VendorBuyingAgreementEntity) => string;
}
export const VendorBuyingAgreementsList = ({
  addButtonProps,
  tableProps,
  showSearch,
  onSave,
  fetchApi,
  filter,
  setFilter,
  layout = 'Tab',
  showAddButton,
  redirectPath,
}: VendorBuyingAgreementsListProps) => {
  const { t } = useTranslation();
  const { globalEditing } = useGlobalEdit();
  const { isInternalUser } = useAuth();
  const { data, isLoading, refresh } = fetchApi;
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [vendorBuyingAgreement, setVendorBuyingAgreement] = useState(new VendorBuyingAgreementEntity());

  const clearFilter = () => {
    setFilter((prev) => ({ ...prev, ...defaultFilter }));
  };

  const onDuplicate = (item: VendorBuyingAgreementEntity) => {
    setVendorBuyingAgreement(item.duplicateInApp());
    setIsAddOpen(true);
  };

  const renderActionMenuItems = (item: VendorBuyingAgreementEntity, onClick: () => void) => [
    ...(tableProps?.actionMenuItems ? tableProps.actionMenuItems(item, onClick) : []),
    ...(item?.type == VendorBuyingAgreementType.InApp
      ? [<VendorBuyingAgreementExportMenuItem key="export" onClick={onClick} entity={item} />]
      : []),
    ...(isInternalUser &&
    item.status == VendorBuyingAgreementStatus.Approved &&
    item.type == VendorBuyingAgreementType.InApp &&
    !item.isArchived
      ? [
          <MenuItem
            id="duplicate"
            key="duplicate"
            onClick={() => {
              onDuplicate(item);
              onClick();
            }}
            permissions={{ keys: [PermissionKey.VendorViewVBAs, PermissionKey.VendorEditVBAs] }}
          >
            {t('vendor:buyingAgreements.actions.createFrom')}
          </MenuItem>,
        ]
      : []),
  ];

  const renderSearch = () => (
    <Stack mb={!isInternalUser ? 2 : 0}>
      <FilterContainer onClear={clearFilter}>
        <PeriodFilterSelect filter={filter} setFilter={setFilter} />
      </FilterContainer>
      {isInternalUser && <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="female" />}
    </Stack>
  );

  const cancelDrawer = () => {
    setIsAddOpen(false);
    setVendorBuyingAgreement(new VendorBuyingAgreementEntity());
  };

  const confirmDrawer = () => {
    refresh();
    cancelDrawer();
  };

  const renderCardList = () => (
    <CardList
      data={data ?? []}
      translationNamespace="vendor"
      isLoading={isLoading}
      hideResultCount
      actionMenuItems={!globalEditing ? renderActionMenuItems : undefined}
      href={redirectPath}
    >
      <CardListRow>
        <CardListElement type="title" id="name" />
        <CardListElement
          type="custom"
          id="type"
          hideLabel
          render={(vba: VendorBuyingAgreementEntity) => (
            <Typography variant="tag">{t(`vendor:buyingAgreements.type.${vba.type}`)}</Typography>
          )}
        />
        <CardListElement
          type="custom"
          id="period"
          hideLabel
          render={(vba: VendorBuyingAgreementEntity) => (
            <EntityPeriod period={vba.period} isArchived={vba.isArchived} translationContext="male" />
          )}
        />
        <CardListElement
          type="custom"
          id="status"
          hideLabel
          align="right"
          render={(vba: VendorBuyingAgreementEntity) => <VendorBuyingAgreementStatusDisplay status={vba.status} />}
        />
        {isInternalUser && (
          <CardListElement
            type="custom"
            id="notes"
            hideLabel
            decorator
            render={(vba: VendorBuyingAgreementEntity) =>
              vba.notes && (
                <Stack direction="row" spacing={0.5}>
                  <Tooltip arrow placement="top" title={<Typography variant="tooltip">{vba.notes}</Typography>}>
                    <ModeCommentRounded htmlColor={palette.primary.deep} />
                  </Tooltip>
                </Stack>
              )
            }
          />
        )}
        <CardListElement
          type="custom"
          id="files"
          hideLabel
          decorator
          render={(vba: VendorBuyingAgreementEntity) => (
            <Stack direction="row" spacing={0.5}>
              <InsertDriveFileRounded htmlColor={palette.primary.deep} />
              <Typography>{vba.filesCount}</Typography>
            </Stack>
          )}
        />
      </CardListRow>
      <CardListRow>
        <CardListElement
          type="custom"
          id="effectiveDate"
          render={(vba: VendorBuyingAgreementEntity) => formatLongDate(vba.effectiveDate)}
        />
        <CardListElement
          type="custom"
          id="expiryDate"
          render={(vba: VendorBuyingAgreementEntity) => formatLongDate(vba.expiryDate)}
        />
      </CardListRow>
      <CardListRow>
        <CardListElement type="property" id="responsibleBuyer" />
      </CardListRow>
    </CardList>
  );

  const renderAddButton = () =>
    showAddButton ? (
      <HasPermissions keys={PermissionKey.VendorEditVBAs}>
        <Button
          variant="contained"
          startIcon={<AddRounded />}
          onClick={() => setIsAddOpen(true)}
          {...addButtonProps}
          disabled={addButtonProps?.disabled || globalEditing}
        >
          {t('vendor:buyingAgreements.actions.add')}
        </Button>
      </HasPermissions>
    ) : (
      <></>
    );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <AddRounded />
          <Typography variant="drawerTitle">{t('vendor:buyingAgreements.actions.add')}</Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={vendorBuyingAgreement}
      schema={VendorBuyingAgreementSchema()}
      redirectPath={redirectPath}
      redirectLabel={t('vendor:buyingAgreements.actions.saveOpen')}
      onSave={async (entity) => {
        const createdEntity = await onSave(entity);
        await refresh();
        return createdEntity;
      }}
      onConfirm={confirmDrawer}
      onCancel={cancelDrawer}
    >
      <VendorBuyingAgreementHeader />
      <VendorBuyingAgreementForm />
    </EditDrawer>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewVBAs }}
      title={t('vendor:buyingAgreements.title')}
      rightTitle={renderAddButton()}
      display={layout}
    >
      {showSearch && renderSearch()}
      {renderCardList()}
      {renderDrawer()}
    </LayoutPage>
  );
};
