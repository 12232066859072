import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { useEntityFormContext } from '../../../hooks';
import { InternalContactEntity } from '../../../models';
import { ControlledInput } from '../../Form';

export const InternalContactHeader = () => {
  const { t } = useTranslation();
  const { control, readOnly, getValues } = useEntityFormContext<InternalContactEntity>();

  if (readOnly) {
    const values = getValues();
    return <Typography variant="h1">{`${values.firstName} ${values.lastName}`}</Typography>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <ControlledInput
          label={t('contact:internalContact.form.firstName')}
          name="firstName"
          control={control}
          variant="standard"
          maxLength={MAX_SHORT_TEXT_LENGTH}
          hideLabel={readOnly}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledInput
          label={t('contact:internalContact.form.lastName')}
          name="lastName"
          control={control}
          variant="standard"
          maxLength={MAX_SHORT_TEXT_LENGTH}
          hideLabel={readOnly}
          required
        />
      </Grid>
    </Grid>
  );
};
