import { AddRounded, EditRounded, SyncDisabledRounded, VisibilityRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { EditDrawer } from '../..';
import { useAuth, useGlobalEdit, useInternationalization } from '../../../hooks';
import { MemberContactEntity } from '../../../models';
import { MemberContactSchema } from '../../../schemas/Member';
import { BaseComponentListProps, ContactType, MemberContactFilter, OrderBy, PermissionKey } from '../../../types';
import { formatMailChimpErrors } from '../../../utils/formatters';
import { formatShortDate } from '../../../utils/helper';
import { EmailAddressList, PhoneNumberList } from '../../Contact';
import { ArchiveFilterSelect } from '../../Filter/ArchiveFilterSelect';
import { LayoutPage } from '../../Layout';
import { MenuItem } from '../../Menu';
import { HasPermissions } from '../../Permission';
import { Table, TableColumn } from '../../Table';
import { MemberContactForm } from './MemberContactForm';

export const MemberContactsList = ({
  addButtonProps,
  tableProps,
  showSearch,
  onSave,
  fetchApi,
  filter,
  setFilter,
  layout = 'Tab',
  isChangeRequest,
  readOnly,
}: BaseComponentListProps<MemberContactEntity, MemberContactFilter>) => {
  const { hasPermissions, isInternalUser } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { data, isLoading, refresh } = fetchApi;
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [memberContact, setMemberContact] = useState<MemberContactEntity>(new MemberContactEntity());

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const renderSearch = () => <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />;

  const renderBaseActionsMenuItems = (entity: MemberContactEntity, onClick: () => void) => [
    ...(!entity.isArchived
      ? [
          <MenuItem
            id="edit"
            key="edit"
            onClick={() => {
              setMemberContact(entity);
              setIsAddOpen(true);
              onClick();
            }}
          >
            {t('common:edit')}
          </MenuItem>,
        ]
      : []
    ).concat(tableProps?.actionMenuItems ? tableProps?.actionMenuItems(entity, onClick) : []),
  ];

  const renderName = (entity: MemberContactEntity) =>
    entity.type === ContactType.Department
      ? getTranslation(entity, 'department')
      : `${entity.firstName} ${entity.lastName}`;

  const renderContactRole = (entity: MemberContactEntity) => (entity.role ? getTranslation(entity.role, 'name') : '');

  const renderPhoneNumbers = (entity: MemberContactEntity) => <PhoneNumberList data={entity.phoneNumbers} />;

  const renderEmailAddresses = (entity: MemberContactEntity) => <EmailAddressList data={entity.emailAddresses} />;

  const renderLastModified = (entity: MemberContactEntity) =>
    isInternalUser
      ? t('common:entity.modifiedDateShort', {
          date: formatShortDate(entity.modifiedDate),
          name: entity.modifiedBy,
        })
      : formatShortDate(entity.modifiedDate);

  const renderTable = () =>
    data && (
      <Table
        {...tableProps}
        data={data}
        translationNamespace="member:contact"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        sortColumns={filter.orderBy}
        onSortChange={onSortChange}
        isLoading={isLoading}
        actionMenuItems={
          hasPermissions(PermissionKey.MemberEditContacts) && !readOnly && !globalEditing
            ? renderBaseActionsMenuItems
            : undefined
        }
      >
        <TableColumn type="custom" width="20%" id="name" sortable render={renderName} />
        <TableColumn type="custom" width="20%" id="role" sortable render={renderContactRole} />
        <TableColumn type="custom" width="20%" id="phoneNumbers" render={renderPhoneNumbers} />
        <TableColumn type="custom" width="20%" id="emailAddresses" render={renderEmailAddresses} />
        <TableColumn type="custom" width="20%" id="modifiedDate" sortable render={renderLastModified} />
        <TableColumn type="icon" id="isVisibleToMember" sortable align="center" iconComponent={VisibilityRounded} />
        {isInternalUser && (
          <TableColumn
            type="icon"
            id="mailSyncError"
            align="center"
            tooltip
            render={(item: MemberContactEntity) => formatMailChimpErrors(item.mailSyncError)}
            iconComponent={SyncDisabledRounded}
          />
        )}
      </Table>
    );

  const renderAddButton = () => (
    <HasPermissions keys={PermissionKey.MemberEditContacts}>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => {
          setMemberContact(new MemberContactEntity());
          setIsAddOpen(true);
        }}
        {...addButtonProps}
        disabled={addButtonProps?.disabled || globalEditing}
      >
        {t('member:contact.actions.add')}
      </Button>
    </HasPermissions>
  );

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {memberContact.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(memberContact.id ? 'member:contact.actions.edit' : 'member:contact.actions.add')}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={memberContact}
      schema={MemberContactSchema()}
      onSave={async (entity) => {
        const createdEntity = await onSave(entity);
        await refresh();
        return createdEntity;
      }}
      onConfirm={() => {
        refresh();
        setIsAddOpen(false);
      }}
      onCancel={() => setIsAddOpen(false)}
      isChangeRequest={isChangeRequest}
    >
      <MemberContactForm />
    </EditDrawer>
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.MemberViewContacts }}
      title={t('member:contact.title')}
      rightTitle={!readOnly ? renderAddButton() : undefined}
      display={layout}
    >
      {showSearch && renderSearch()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
