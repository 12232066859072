import {
  BlockRounded,
  CheckRounded,
  LockOpenRounded,
  RemoveModeratorRounded,
  VerifiedUserRounded,
} from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import { Navigate, generatePath, useOutlet, useParams } from 'react-router-dom';
import { VendorRebateCategoryProgramForm } from '.';
import { ArchiveBanner, EditDetails } from '../..';
import { vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryProgramDetailContext } from '../../../contexts/Vendor';
import { useApi, useAuth, useConfirmationModal, useInternationalization, usePageTitle } from '../../../hooks';
import { VendorRebateCategoryProgramEntity } from '../../../models/Vendor/VendorRebateCategoryProgramEntity';
import { routes } from '../../../routes';
import { VendorRebateCategoryProgramSchema } from '../../../schemas';
import {
  PermissionKey,
  Styles,
  VendorRebateCategoryProgramStatus,
  VendorRebateCategoryProgramTriggers,
} from '../../../types';
import { Container } from '../../Container';
import { ArchiveMenuItem } from '../../Menu';
import { LinkTab } from '../../Tab';
import { VendorRebateCategoryProgramHeader } from './VendorRebateCategoryProgramHeader';
import { LayoutPage } from '../../Layout';

const style: Styles = {
  loading: {
    mx: 2,
  },
};

export const VendorRebateCategoryProgramDetail = () => {
  const { hasPermissions } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const { openModal } = useConfirmationModal();
  const outlet = useOutlet();
  const params = useParams();
  const vendorId = Number(params.vendorId);
  const vendorRebateCategoryId = Number(params.vendorRebateCategoryId);
  const rebateProgramId = Number(params.rebateProgramId);
  const { data: vendor, isLoading: isVendorLoading } = useApi(vendorApi.get, null, vendorId);
  const {
    data: rebateCategoryProgram,
    refresh,
    isLoading,
  } = useApi(vendorApi.getRebateCategoryProgram, null, vendorId, vendorRebateCategoryId, rebateProgramId);
  const { data: vendorRebateCategory, isLoading: isCategoryLoading } = useApi(
    vendorApi.getRebateCategory,
    null,
    vendorId,
    vendorRebateCategoryId,
  );
  const { call: update } = useApi(vendorApi.updateRebateCategoryProgram, { successKey: 'common:success.save' });
  const { call: trigger, isLoading: isLoadingTrigger } = useApi(vendorApi.updateWorkflowRebateProgram, {
    successKey: 'common:success.action',
  });
  usePageTitle(rebateCategoryProgram ? getTranslation(rebateCategoryProgram, 'name') : '');

  const status = rebateCategoryProgram?.status;

  const saveRebateCategoryProgram = async (entity: VendorRebateCategoryProgramEntity) => {
    const savedRebateCategoryProgram = await update(vendorId, vendorRebateCategoryId, entity);
    return savedRebateCategoryProgram;
  };

  const archiveApi = useApi(vendorApi.archiveRebateCategoryProgram, { successKey: 'common:success.action' });

  const onArchiveConfirm = async (isArchived: boolean, entity: VendorRebateCategoryProgramEntity) => {
    await archiveApi.call(vendorId, vendorRebateCategoryId, [entity.id], isArchived);
    refresh();
  };

  const onUpdateWorkflowConfirm = (newTrigger: VendorRebateCategoryProgramTriggers) => async () => {
    await trigger(vendorId, vendorRebateCategoryId, rebateProgramId, newTrigger);
    refresh();
  };

  const onApproveRejectWorkflow =
    (newTrigger: VendorRebateCategoryProgramTriggers.Approve | VendorRebateCategoryProgramTriggers.Reject) => () => {
      const name = rebateCategoryProgram ? getTranslation(rebateCategoryProgram, 'name') : '';
      const action = t(`vendor:rebateCategory.programs.workflow.${newTrigger.toLowerCase()}`);
      openModal({
        confirmText: t('common:action.confirmButton', { action }),
        title: t('common:action.confirmTitle', {
          name: t('vendor:rebateCategory.programs.actions.suffix'),
          action,
          count: 1,
        }),
        description: <Trans i18nKey="common:action.confirmDescription" values={{ count: 1, name }} />,
        onConfirm: onUpdateWorkflowConfirm(newTrigger),
      });
    };

  const renderActionMenuItems = (onClick: () => void) =>
    rebateCategoryProgram
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={rebateCategoryProgram}
            name={getTranslation(rebateCategoryProgram, 'name')}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('vendor:rebateCategory.programs.actions.suffix')}
            disabled={!vendor || vendor.isArchived || !vendorRebateCategory || vendorRebateCategory.isArchived}
          />,
        ]
      : [];

  const renderWorkflow = () => {
    const canApprove = hasPermissions(PermissionKey.VendorApproveRebates);
    const canVerify = hasPermissions(PermissionKey.VendorVerifyRebates);

    if (!canApprove && !canVerify) {
      return undefined;
    }

    const workflowDisabled =
      isLoadingTrigger || vendor?.isArchived || rebateCategoryProgram?.isArchived || vendorRebateCategory?.isArchived;

    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Stack spacing={1} direction="row">
          {canApprove && status == VendorRebateCategoryProgramStatus.InProgress && (
            <Button
              size="small"
              variant="contained"
              disabled={workflowDisabled}
              onClick={onApproveRejectWorkflow(VendorRebateCategoryProgramTriggers.Approve)}
              startIcon={<CheckRounded />}
            >
              {t('vendor:rebateCategory.programs.workflow.approve')}
            </Button>
          )}
          {canApprove && status == VendorRebateCategoryProgramStatus.InProgress && (
            <Button
              size="small"
              disabled={workflowDisabled}
              variant="outlined"
              onClick={onApproveRejectWorkflow(VendorRebateCategoryProgramTriggers.Reject)}
              startIcon={<BlockRounded />}
            >
              {t('vendor:rebateCategory.programs.workflow.reject')}
            </Button>
          )}
          {canVerify && status === VendorRebateCategoryProgramStatus.Approved && (
            <Button
              size="small"
              disabled={workflowDisabled}
              variant="contained"
              onClick={onUpdateWorkflowConfirm(VendorRebateCategoryProgramTriggers.Verify)}
              startIcon={<VerifiedUserRounded />}
            >
              {t('vendor:rebateCategory.programs.workflow.verify')}
            </Button>
          )}
          {canVerify && status === VendorRebateCategoryProgramStatus.Verified && (
            <Button
              size="small"
              disabled={workflowDisabled}
              variant="contained"
              onClick={onUpdateWorkflowConfirm(VendorRebateCategoryProgramTriggers.Unverify)}
              startIcon={<RemoveModeratorRounded />}
            >
              {t('vendor:rebateCategory.programs.workflow.unverify')}
            </Button>
          )}
          {canApprove &&
            status != VendorRebateCategoryProgramStatus.InProgress &&
            status != VendorRebateCategoryProgramStatus.Verified && (
              <Button
                size="small"
                disabled={workflowDisabled}
                variant="outlined"
                onClick={onUpdateWorkflowConfirm(VendorRebateCategoryProgramTriggers.Reopen)}
                startIcon={<LockOpenRounded />}
              >
                {t('vendor:rebateCategory.programs.workflow.reopen')}
              </Button>
            )}
        </Stack>
      </Box>
    );
  };

  const tabs = [
    <LinkTab
      label={t('vendor:rebateCategory.programs.sections.permissions')}
      value={generatePath(routes.Admin.Vendor.RebateCategoryProgram.Permissions.path, {
        vendorId,
        vendorRebateCategoryId,
        rebateProgramId: rebateProgramId,
      })}
    />,
    <LinkTab
      label={t('vendor:rebateCategory.sections.rebates')}
      value={generatePath(routes.Admin.Vendor.RebateCategoryProgram.Rebates.path, {
        vendorId,
        vendorRebateCategoryId,
        rebateProgramId,
      })}
      permissions={{ keys: PermissionKey.VendorViewRebates }}
    />,
    <LinkTab
      label={t('vendor:rebateCategory.programs.sections.tierAndGrowth')}
      permissions={{ keys: PermissionKey.VendorViewRebates }}
      value={generatePath(routes.Admin.Vendor.RebateCategoryProgram.TiersAndGrowths.path, {
        vendorId,
        vendorRebateCategoryId,
        rebateProgramId,
      })}
    />,
  ];

  if (!outlet) {
    return (
      <Navigate
        replace
        to={generatePath(routes.Admin.Vendor.RebateCategoryProgram.Permissions.path, {
          vendorId,
          vendorRebateCategoryId,
          rebateProgramId,
        })}
      />
    );
  }

  return (
    <VendorRebateCategoryProgramDetailContext.Provider
      value={{
        vendorId,
        vendor,
        vendorRebateCategory,
        vendorRebateCategoryId,
        vendorRebateCategoryProgram: rebateCategoryProgram,
        rebateProgramId: rebateProgramId,
        isLoading: isLoading || isCategoryLoading || isVendorLoading,
        vendorRebateCategoryProgramId: rebateProgramId,
        fetchData: refresh,
        readOnly:
          rebateCategoryProgram?.status !== VendorRebateCategoryProgramStatus.InProgress ||
          !hasPermissions(PermissionKey.VendorEditRebates),
      }}
    >
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs} permissions={{ keys: PermissionKey.VendorViewRebates }}>
        <ArchiveBanner
          entity={rebateCategoryProgram}
          onUnarchive={() => rebateCategoryProgram && onArchiveConfirm(false, rebateCategoryProgram)}
        />
        <Container>
          {(isLoading || isCategoryLoading || isVendorLoading) && <CircularProgress size={20} sx={style.loading} />}
          {rebateCategoryProgram && (
            <EditDetails
              titleLabel={t(`vendor:rebateCategory.programs.form.name`)}
              title={'name_En'}
              permissions={{ keys: PermissionKey.VendorEditRebates }}
              disabled={!vendor || vendor.isArchived || rebateCategoryProgram.isArchived}
              readOnly={rebateCategoryProgram?.status !== VendorRebateCategoryProgramStatus.InProgress}
              header={<VendorRebateCategoryProgramHeader />}
              entity={rebateCategoryProgram}
              schema={VendorRebateCategoryProgramSchema()}
              onSave={saveRebateCategoryProgram}
              actionMenuItems={renderActionMenuItems}
              onConfirm={refresh}
              breadcrumbs={[
                { title: t('vendor:title'), href: routes.Admin.Vendor.path },
                { title: vendor?.name, href: generatePath(routes.Admin.Vendor.Detail.path, { vendorId }) },
                {
                  title: vendorRebateCategory ? getTranslation(vendorRebateCategory, 'name') : undefined,
                  href: generatePath(routes.Admin.Vendor.RebateCategory.Programs.path, {
                    vendorId,
                    vendorRebateCategoryId,
                  }),
                },
                {
                  title: rebateCategoryProgram ? getTranslation(rebateCategoryProgram, 'name') : undefined,
                },
              ]}
              workflow={renderWorkflow()}
            >
              <VendorRebateCategoryProgramForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </VendorRebateCategoryProgramDetailContext.Provider>
  );
};
