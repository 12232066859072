import { AddRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet } from 'react-router-dom';
import { memberGroup as memberGroupApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { MemberGroupContext } from '../../contexts';
import { useApi } from '../../hooks';
import { MemberGroupEntity } from '../../models';
import { routes } from '../../routes';
import { MemberGroupSchema } from '../../schemas';
import { MemberGroupFilter, Styles } from '../../types';
import { EditDrawer } from '../EditDrawer';
import { LayoutPage } from '../Layout';
import { LinkTab } from '../Tab';
import { MemberGroupForm } from './MemberGroupForm';
import { MemberGroupListSearch } from './MemberGroupListSearch';
import { MemberGroupListView } from './MemberGroupListView';

const style: Styles = {
  subTitleWrapperContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 4,
  },
};

export const MemberGroupLayout = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [filter, setFilter] = useState<MemberGroupFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });
  const { refresh, isLoading, data, setData } = useApi(memberGroupApi.getAll, { skipFetch: !!outlet }, filter);
  const { call } = useApi(memberGroupApi.create, { successKey: 'common:success.save' });
  const memberGroup = useMemo(() => new MemberGroupEntity(), []);

  const toggleDrawer = (value: boolean) => () => setIsAddOpen(value);

  const tabs = [
    <LinkTab label={t('navigation:sections.members')} value={routes.Admin.Member.path} />,
    <LinkTab label={t('navigation:sections.memberGroups')} value={routes.Admin.MemberGroups.path} />,
  ];

  if (outlet) {
    return outlet;
  }

  return (
    <MemberGroupContext.Provider value={{ data, setData, isLoading, fetchData: refresh, filter, setFilter }}>
      <LayoutPage title={t('member:title')} tabs={tabs}>
        <Box sx={style.subTitleWrapperContainer}>
          <Typography display="inline" variant="h2">
            {t('navigation:sections.memberGroups')}
          </Typography>
          <Button startIcon={<AddRounded />} onClick={toggleDrawer(true)} variant="contained">
            {t('memberGroup:add')}
          </Button>
        </Box>
        <MemberGroupListSearch />
        <MemberGroupListView />
        <EditDrawer
          title={
            <Stack direction="row" spacing={1} alignItems="center">
              <AddRounded />
              <Typography variant="drawerTitle">{t(`memberGroup:add`)}</Typography>
            </Stack>
          }
          open={isAddOpen}
          entity={memberGroup}
          schema={MemberGroupSchema()}
          redirectPath={(entity) => generatePath(routes.Admin.MemberGroups.Detail.path, { memberGroupId: entity.id })}
          onSave={call}
          onConfirm={() => {
            refresh();
            setIsAddOpen(false);
          }}
          onCancel={() => setIsAddOpen(false)}
          redirectLabel={t('memberGroup:saveOpenMemberGroup')}
        >
          <MemberGroupForm />
        </EditDrawer>
      </LayoutPage>
    </MemberGroupContext.Provider>
  );
};
