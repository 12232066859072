import { ArchiveRounded, UnarchiveRounded } from '@mui/icons-material';
import { useState } from 'react';
import { contact as contactApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { InternalContactEntity } from '../../../models';
import { PermissionKey, InternalContactFilter } from '../../../types';
import { ArchiveMenuItem } from '../../Menu';
import { InternalContactsList } from './InternalContactsList';

export const InternalContactsListAdmin = () => {
  const { t } = useInternationalization();
  const [filter, setFilter] = useState<InternalContactFilter>({ isArchived: false, ...DEFAULT_PAGINATION_FILTER });

  const getAllContactsApi = useApi(contactApi.getAllContacts, { callImmediately: false, skipFetch: true }, filter);
  const { call: create } = useApi(contactApi.createContact, { successKey: 'common:success.save' });
  const archiveApi = useApi(contactApi.archiveContact, { successKey: 'common:success.action' });

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: InternalContactEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: InternalContactEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onSave = async (entity: InternalContactEntity) => {
    return await create(entity);
  };

  const onArchiveConfirm = async (isArchived: boolean, contacts: InternalContactEntity[]) => {
    await archiveApi.call(
      contacts.map((i) => i.id),
      isArchived,
    );
    getAllContactsApi.call(filter);
  };

  const renderActionsMenuItems = (entity: InternalContactEntity, onClick: () => void) => [
    <ArchiveMenuItem
      key="archive"
      entity={entity}
      name={`${entity.firstName} ${entity.lastName}`}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('member:contact.actions.suffix')}
      permissions={{ keys: PermissionKey.AdminManageContacts }}
    />,
  ];

  return (
    <InternalContactsList
      showSearch
      filter={filter}
      setFilter={setFilter}
      fetchApi={getAllContactsApi}
      onSave={onSave}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
        bulkActions: bulkActions,
        bulkActionSuffix: t('member:contact.actions.suffix'),
        bulkPermissions: { keys: PermissionKey.AdminManageContacts },
        renderBulkSelection: (items) =>
          items.length === 1 ? `${items[0].firstName} ${items[0].lastName}` : t(`member:contact.title`),
      }}
    />
  );
};
