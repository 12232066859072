import { AuthenticationLogType, UserType } from '../../types';

export class AuthenticationLogEntity {
  id: number;
  type: AuthenticationLogType;
  username?: string;
  userType?: UserType;
  logDate: Date;

  constructor(entity?: AuthenticationLogEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
    }
  }
}
