import { object, string } from 'yup';
import { MemberEntity } from '../../models';
import { EntityFormSchema, Province, UnitedStatesStateOrTerritory } from '../../types';

export const AddressSchema = (isPostalCodeRequired?: boolean): EntityFormSchema<MemberEntity> => ({
  schema: object({
    postalCode: isPostalCodeRequired
      ? string()
          .required()
          .when('province', {
            is: (value: Province) => value && Object.values<Province>(UnitedStatesStateOrTerritory).includes(value),
            then: (schema) =>
              schema.matches(/^\d{5}(-\d{4})?$/, { message: 'common:error.fieldInvalid', excludeEmptyString: true }),
            otherwise: (schema) =>
              schema.matches(/^[A-Z]\d[A-Z]\d[A-Z]\d$/, {
                message: 'common:error.fieldInvalid',
                excludeEmptyString: true,
              }),
          })
      : string()
          .notRequired()
          .nullable()
          .when('province', {
            is: (value: Province) => value && Object.values<Province>(UnitedStatesStateOrTerritory).includes(value),
            then: (schema) =>
              schema.matches(/^\d{5}(-\d{4})?$/, { message: 'common:error.fieldInvalid', excludeEmptyString: true }),
            otherwise: (schema) =>
              schema.matches(/^[A-Z]\d[A-Z]\d[A-Z]\d$/, {
                message: 'common:error.fieldInvalid',
                excludeEmptyString: true,
              }),
          }),
  }),
  errorCodeMap: {},
});
