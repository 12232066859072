import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

interface GlobalEditContextType {
  globalEditing: boolean;
  setGlobalEditing: Dispatch<SetStateAction<boolean>>;
}

export const GlobalEditContext = React.createContext<GlobalEditContextType>({} as GlobalEditContextType);

export const GlobalEditProvider = ({ children }: PropsWithChildren) => {
  const [globalEditing, setGlobalEditing] = useState(false);

  return (
    <GlobalEditContext.Provider value={{ globalEditing, setGlobalEditing }}>{children}</GlobalEditContext.Provider>
  );
};
