import axios from 'axios';
import { VendorBuyingAgreementTierGrowthEntity, VendorTierGrowthEntity } from '../../../models';

export const buyingAgreementGrowth = {
  getAllBuyingAgreementRebateCategoryGrowth: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
  ): Promise<VendorBuyingAgreementTierGrowthEntity[]> => {
    const { data } = await axios.get(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/growths`,
    );
    return data.map((v: VendorBuyingAgreementTierGrowthEntity) => new VendorBuyingAgreementTierGrowthEntity(v));
  },
  associateBuyingAgreementGrowth: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    growthId: number,
  ): Promise<VendorBuyingAgreementTierGrowthEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/growths/${growthId}/associate`,
    );
    return new VendorBuyingAgreementTierGrowthEntity(data);
  },
  disassociateBuyingAgreementGrowth: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    growthId: number,
  ): Promise<void> => {
    await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/growths/${growthId}/disassociate`,
    );
  },
  updateBuyingAgreementGrowth: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    entity: VendorTierGrowthEntity,
  ): Promise<VendorBuyingAgreementTierGrowthEntity> => {
    const { data } = await axios.put(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/growths/${entity.id}`,
      entity,
    );
    return new VendorBuyingAgreementTierGrowthEntity(data);
  },
  createBuyingAgreementGrowth: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    entity: VendorTierGrowthEntity,
  ): Promise<VendorBuyingAgreementTierGrowthEntity> => {
    const { data } = await axios.post(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/growths`,
      entity,
    );
    return new VendorBuyingAgreementTierGrowthEntity(data);
  },
  deleteBuyingAgreementGrowth: async (
    vendorId: number,
    buyingAgreementId: number,
    rebateCategoryId: number,
    growthId: number,
  ): Promise<void> => {
    await axios.delete(
      `api/vendor/${vendorId}/buyingagreements/${buyingAgreementId}/rebatecategories/${rebateCategoryId}/growths/${growthId}`,
    );
  },
};
