import { Box, Button, Collapse, MenuItemProps, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableEntity } from '../models';
import { Breadcrumb, Styles } from '../types';
import { Breadcrumbs } from './Breadcrumbs';
import { EntityModifiedDate } from './EntityModifiedDate';
import { StaticField } from './Form';
import { ActionMenu } from './Menu';
import { formatShortDate } from '../utils/helper';
import { Loading } from './Loading';

const style: Styles = {
  box: {
    mb: 0,
  },
  header: {
    mb: 2,
    pb: 1,
  },
  collapse: {
    position: 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '30px',
      backgroundImage: 'linear-gradient(to bottom, #00000000, #FFFFFFFF)',
    },
  },
  expanded: {
    mb: 2,
  },
  actions: {
    width: { xs: '100%', md: 'auto' },
  },
};

interface EntityDetailsProps<T extends EditableEntity> extends PropsWithChildren {
  entity: T;
  actionMenuItems?: (onClick: () => void) => React.ReactElement<MenuItemProps>[];
  actionButtons?: JSX.Element | JSX.Element[];
  title?: keyof T & string;
  titleLabel?: string;
  header?: ReactNode;
  breadcrumbs?: Breadcrumb[];
  alwaysOpen?: boolean;
  isLoading?: boolean;
  hideModifiedDate?: boolean;
}

export const EntityDetails = <T extends EditableEntity>({
  entity,
  title,
  children,
  actionMenuItems,
  actionButtons,
  titleLabel,
  breadcrumbs,
  alwaysOpen,
  header,
  isLoading,
  hideModifiedDate,
}: EntityDetailsProps<T>) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(alwaysOpen || false);

  const onCollapseClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Stack
        direction={{
          xs: (entity.modifiedDate && entity.modifiedBy) || entity.latestChange ? 'column-reverse' : 'column',
          md: (entity.modifiedDate && entity.modifiedBy) || entity.latestChange ? 'row-reverse' : 'row',
        }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        {!hideModifiedDate &&
          (entity.latestChange ? (
            <Typography variant="modifiedDate">
              {t('common:entity.lastUpdate', {
                date: formatShortDate(entity.latestChange),
              })}
            </Typography>
          ) : (
            <EntityModifiedDate entity={entity} />
          ))}
        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      </Stack>
      <Box sx={style.box}>
        <Box>
          <Stack
            sx={style.header}
            spacing={{ xs: 0, md: 3 }}
            direction={{ xs: 'column-reverse', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="space-between"
          >
            {isLoading ? (
              <Loading />
            ) : (
              header || <StaticField variant="h1" label={titleLabel} value={title ? `${entity[title]}` : ''} />
            )}
            <Stack direction="row" spacing={0.5} justifyContent="flex-end" sx={style.actions}>
              {actionButtons}
              {actionMenuItems && <ActionMenu disabled={isLoading} menuItems={actionMenuItems} />}
            </Stack>
          </Stack>
          {!isLoading && children && (
            <>
              <Collapse sx={!isOpen ? style.collapse : style.expanded} collapsedSize="70px" in={isOpen}>
                <Box>{children}</Box>
              </Collapse>
              {!alwaysOpen && (
                <Box>
                  <Button size="small" variant="text" sx={style.actionButton} onClick={onCollapseClick}>
                    {isOpen ? t('common:showLess') : t('common:showMore')}
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
