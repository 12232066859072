import React, { Dispatch, SetStateAction } from 'react';
import { VendorEntity } from '../../models';
import { PaginationEntity, VendorFilter } from '../../types';

interface VendorContextType {
  data: PaginationEntity<VendorEntity> | null;
  setData: Dispatch<SetStateAction<PaginationEntity<VendorEntity> | null>>;
  fetchData: () => Promise<void>;
  filter: VendorFilter;
  setFilter: Dispatch<SetStateAction<VendorFilter>>;
  isLoading: boolean;
}

export const VendorContext = React.createContext<VendorContextType>({} as VendorContextType);
