import { object, string } from 'yup';
import { MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { ProfileEntity } from '../../models';
import { EntityFormSchema, LanguageCode } from '../../types';

export const ProfileSchema = (): EntityFormSchema<ProfileEntity> => ({
  schema: object({
    firstName: string().required().max(MAX_SHORT_TEXT_LENGTH),
    lastName: string().required().max(MAX_SHORT_TEXT_LENGTH),
    language: string().notRequired().oneOf(Object.values(LanguageCode)),
    email: string().required().email().max(MAX_SHORT_TEXT_LENGTH),
    jobTitle: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    department: string().nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
    phoneOffice: object({
      number: string()
        .nullable()
        .notRequired()
        .matches(/^\d{10}$/, { message: 'common:error.fieldInvalid', excludeEmptyString: true }),
    }),
    phoneMobile: object({
      number: string()
        .nullable()
        .notRequired()
        .matches(/^\d{10}$/, { message: 'common:error.fieldInvalid', excludeEmptyString: true }),
    }),
  }),
});
