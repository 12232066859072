import axios from 'axios';
import { ForestProductPriceUpdateEntity, ForestProductPriceUpdateHistoryEntity } from '../../models';
import { PaginationEntity, ForestProductPriceUpdateFilter } from '../../types';

export const ForestProductPriceUpdate = {
  getAllForestProductPriceUpdates: async (
    forestProductPriceUpdateFilter: ForestProductPriceUpdateFilter,
  ): Promise<PaginationEntity<ForestProductPriceUpdateEntity>> => {
    const { data } = await axios.post(`api/forestproductpriceupdate/search`, forestProductPriceUpdateFilter);
    return {
      ...data,
      data: data.data.map((v: ForestProductPriceUpdateEntity) => new ForestProductPriceUpdateEntity(v)),
    };
  },
  getForestProductPriceUpdate: async (priceUpdateId: number): Promise<ForestProductPriceUpdateEntity> => {
    const { data } = await axios.get(`api/forestproductpriceupdate/${priceUpdateId}`);
    return new ForestProductPriceUpdateEntity(data);
  },
  createForestProductPriceUpdate: async (
    entity: ForestProductPriceUpdateEntity,
  ): Promise<ForestProductPriceUpdateEntity> => {
    const { data } = await axios.post(`api/forestproductpriceupdate`, entity);
    return new ForestProductPriceUpdateEntity(data);
  },
  updateForestProductPriceUpdate: async (
    entity: ForestProductPriceUpdateEntity,
  ): Promise<ForestProductPriceUpdateEntity> => {
    const { data } = await axios.put(`api/forestproductpriceupdate/${entity.id}`, entity);
    return new ForestProductPriceUpdateEntity(data);
  },
  archiveForestProductPriceUpdate: async (
    ids: number[],
    isArchived: boolean,
  ): Promise<ForestProductPriceUpdateEntity[]> => {
    const { data } = await axios.put(`api/forestproductpriceupdate/archive`, { isArchived, ids });
    return data.map((v: ForestProductPriceUpdateEntity) => new ForestProductPriceUpdateEntity(v));
  },
  getForestProductLatestPriceUpdate: async (): Promise<ForestProductPriceUpdateEntity> => {
    const { data } = await axios.get(`api/forestproductpriceupdate/latest`);
    return new ForestProductPriceUpdateEntity(data);
  },
  getForestProductPriceUpdateHistory: async (
    forestProductId: number,
    forestProductTradingCityId: number,
  ): Promise<ForestProductPriceUpdateHistoryEntity[]> => {
    const { data } = await axios.post(`api/forestproductpriceupdate/history`, {
      forestProductId,
      forestProductTradingCityId,
    });
    return data.map((v: ForestProductPriceUpdateHistoryEntity) => new ForestProductPriceUpdateHistoryEntity(v));
  },
};
