import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { getDashboardPath, routes } from '../../routes';

export function RequireVerificationPhoneNumberRequired({ children }: PropsWithChildren) {
  const { user } = useAuth();

  if (user?.requiresVerificationPhoneNumber) {
    return <>{children}</>;
  } else if (!user) {
    return <Navigate to={routes.Login.path} replace />;
  }
  return <Navigate to={getDashboardPath(user.profile.userType)} replace />;
}
