import { ArchiveRounded, ModeCommentRounded, UnarchiveRounded } from '@mui/icons-material';
import { Link, MenuItem, Stack } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { memberGroup as memberGroupApi } from '../../api';
import { MemberGroupContext } from '../../contexts';
import { useApi } from '../../hooks';
import { MemberGroupEntity } from '../../models';
import { routes } from '../../routes';
import { OrderBy } from '../../types';
import { ArchiveMenuItem } from '../Menu';
import { Table, TableColumn } from '../Table';

export const MemberGroupListView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading, filter, setFilter, fetchData } = useContext(MemberGroupContext);
  const archiveApi = useApi(memberGroupApi.archive, { successKey: 'common:success.action' });

  const bulkActions = [
    {
      icon: <ArchiveRounded />,
      label: t('common:action.archive'),
      onConfirm: async (items: MemberGroupEntity[]) => onArchiveConfirm(true, items),
    },
    {
      icon: <UnarchiveRounded />,
      label: t('common:action.unarchive'),
      onConfirm: async (items: MemberGroupEntity[]) => onArchiveConfirm(false, items),
    },
  ];

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onArchiveConfirm = async (isArchived: boolean, memberGroups: MemberGroupEntity[]) => {
    await archiveApi.call(
      memberGroups.map((i) => i.id),
      isArchived,
    );
    fetchData();
  };

  const renderActionsMenuItems = (memberGroup: MemberGroupEntity, onClick: () => void) => [
    <ArchiveMenuItem
      key="archive"
      entity={memberGroup}
      name={memberGroup.name}
      onClick={onClick}
      onArchiveConfirm={(value, entity) => onArchiveConfirm(value, [entity])}
      actionSuffix={t('memberGroup:action.suffix')}
    />,
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(generatePath(routes.Admin.MemberGroups.Detail.path, { memberGroupId: memberGroup.id }));
      }}
    >
      {t('common:view')}
    </MenuItem>,
  ];

  const renderMemberGroupLink = (id: number, text: string | undefined) =>
    !!text && (
      <Link underline="always" href={generatePath(routes.Admin.MemberGroups.Detail.path, { memberGroupId: id })}>
        {text}
      </Link>
    );
  return (
    <Stack spacing={3}>
      <Table
        actionMenuItems={renderActionsMenuItems}
        data={data ?? []}
        translationNamespace="memberGroup"
        paginationFilter={filter}
        onPaginationChange={onPaginationChange}
        bulkActions={bulkActions}
        bulkActionSuffix={t('memberGroup:action.suffix')}
        renderBulkSelection={(items) => (items.length === 1 ? items[0].name : t('navigation:sections.memberGroups'))}
        isLoading={isLoading}
        onSortChange={onSortChange}
        sortColumns={filter.orderBy}
      >
        <TableColumn
          type="custom"
          sortable
          id="name"
          render={({ id, name }: MemberGroupEntity) => renderMemberGroupLink(id, name)}
          width="40%"
        />
        <TableColumn
          type="custom"
          sortable
          id="type"
          render={({ type }: MemberGroupEntity) => t(`memberGroup:type.${type}`)}
          width="20%"
        />
        <TableColumn type="property" id="membersCount" width="40%" />
        <TableColumn type="icon" tooltip id="notes" align="center" iconComponent={ModeCommentRounded} />
      </Table>
    </Stack>
  );
};
