import { Box } from '@mui/material';
import { useInternationalization } from '../../hooks';
import { PhoneNumberEntity } from '../../models';
import { phoneNumberFormatter } from '../../utils/formatters';
import { StaticField } from '../Form';

interface PhoneNumberListProps {
  data: PhoneNumberEntity[];
}

export const PhoneNumberList = ({ data }: PhoneNumberListProps) => {
  const { getTranslation } = useInternationalization();

  return (
    <>
      {data.map((phoneNumber) => (
        <Box component="div" key={phoneNumber.id}>
          {getTranslation(phoneNumber, 'description') ? getTranslation(phoneNumber, 'description') + ': ' : ''}
          <StaticField
            value={`${phoneNumberFormatter(phoneNumber.number).formatted.join('')}${
              phoneNumber.extension ? ' ' + phoneNumber.extension : ''
            }`}
            linkType="phone"
            variant="body2"
          />
        </Box>
      ))}
    </>
  );
};
