import { array, date, mixed, object, ref, string } from 'yup';
import { MAX_FILE_SIZE_MB, MAX_LONG_TEXT_LENGTH, MAX_SHORT_TEXT_LENGTH } from '../../constants';
import { VendorDocumentEntity } from '../../models';
import { EntityFormSchema, LanguageCode } from '../../types';
import { mbToBytes } from '../../utils/file';
import { isDateValid } from '../../utils/helper';

export const VendorDocumentSchema = (): EntityFormSchema<VendorDocumentEntity> => ({
  schema: object().shape(
    {
      description_En: string().when('languages', {
        is: (val: LanguageCode[] | undefined) => val?.includes(LanguageCode.en),
        then: (schema) => schema.enRequired().max(MAX_SHORT_TEXT_LENGTH),
        otherwise: (schema) => schema.nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      }),
      description_Fr: string().when('languages', {
        is: (val: LanguageCode[] | undefined) => val?.includes(LanguageCode.fr),
        then: (schema) => schema.frRequired().max(MAX_SHORT_TEXT_LENGTH),
        otherwise: (schema) => schema.nullable().notRequired().max(MAX_SHORT_TEXT_LENGTH),
      }),
      languages: array().min(1).required(),
      vendorNotes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      notes: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_En: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      memberNotes_Fr: string().nullable().notRequired().max(MAX_LONG_TEXT_LENGTH),
      effectiveDate: date()
        .required()
        .when('expiryDate', { is: isDateValid, then: (s) => s.max(ref('expiryDate')) }),
      expiryDate: date()
        .nullable()
        .notRequired()
        .when('effectiveDate', { is: isDateValid, then: (s) => s.min(ref('effectiveDate')) }),
      file: mixed()
        .required()
        .test('max-size', 'common:error.arrayMaxItemSize', (file) =>
          file ? file.size < mbToBytes(MAX_FILE_SIZE_MB) : true,
        ),
    },
    [['expiryDate', 'effectiveDate']],
  ),
  errorCodeMap: {},
});
