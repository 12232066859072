import { CategorizationLayout } from '../../../components/Categorization';

export function MembershipTypes() {
  return (
    <CategorizationLayout
      type="membershipType"
      translationContext="male"
      omitFields={['abbreviation', 'searchKeywords']}
    />
  );
}
