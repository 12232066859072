import { Box, SelectChangeEvent, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { role as roleApi, user as userApi } from '../../api';
import { UserContext } from '../../contexts';
import { useApi, useAuth, useDebounce, useInternationalization } from '../../hooks';
import { routes } from '../../routes';
import { SelectOption, Styles, TranslationContext, UserFilter, UserType } from '../../types';
import { areArrayEquals } from '../../utils/helper';
import { FilterContainer } from '../Container';
import { ArchiveFilterSelect } from '../Filter/ArchiveFilterSelect';
import { Autocomplete, Input, MultiSelect, Select } from '../Form';

const style: Styles = {
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginBottom: '-4px',
  },
  textContainer: {
    width: {
      xs: '100%',
      sm: '450px',
    },
  },
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectLargeContainer: {
    width: {
      xs: '100%',
      sm: '360px',
    },
  },
};

const defaultTextSearchFilter = { userFirstNameOrLastName: undefined };
const defaultAdvancedSearchFilter = {
  roleIds: undefined,
  memberNameOrNumber: undefined,
  vendorNameOrNumber: undefined,
  isAllowedAccess: undefined,
  isVerified: undefined,
};

export const UserListSearch = () => {
  const { t, getTranslation } = useInternationalization();
  const navigate = useNavigate();
  const { isInternalUser } = useAuth();
  const { filter: appliedFilter, setFilter: setAppliedFilter, type } = useContext(UserContext);
  const [filter, setFilter] = useState(appliedFilter);
  const debouncedFilter = useDebounce(filter, 500);
  const { data: roles } = useApi(roleApi.getAll, null, type);
  const [isAdvancedMode, setIsAdvancedMode] = useState(
    !!filter.roleIds || !!filter.isAllowedAccess || !!filter.isVerified,
  );

  useEffect(() => {
    setAppliedFilter(debouncedFilter);
  }, [setAppliedFilter, debouncedFilter]);

  const toggleAdvancedMode = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilter((prev) => ({ ...prev, ...(checked ? defaultTextSearchFilter : defaultAdvancedSearchFilter) }));
    setIsAdvancedMode(checked);
  };

  const onClear = () => {
    setFilter((prev) => ({ ...prev, ...defaultAdvancedSearchFilter }));
  };

  const onBooleanChange = (event: SelectChangeEvent<unknown>, propertyName: keyof UserFilter) => {
    const value = !event.target.value ? undefined : event.target.value === 'true';
    setFilter((prev) => ({ ...prev, [propertyName]: value, pageNumber: 1 }));
  };

  const onRoleBlur = (selected: number[]) => {
    if (!areArrayEquals(filter.roleIds ?? [], selected)) {
      setFilter((prev) => ({ ...prev, roleIds: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const getBooleanOptions = (context: TranslationContext = 'male'): SelectOption<string>[] => [
    { label: t('common:filter.all', { context }), value: '' },
    { label: t('common:boolean.true'), value: 'true' },
    { label: t('common:boolean.false'), value: 'false' },
  ];

  const renderAdvancedSearch = () => (
    <FilterContainer onClear={onClear}>
      {type === UserType.Member && (
        <Box sx={style.selectLargeContainer}>
          <Input
            value={filter.memberNameOrNumber ?? ''}
            label={t('user:search.memberNameOrNumber')}
            onChange={(event) =>
              setFilter((prev) => ({
                ...prev,
                memberNameOrNumber: event.target.value ?? undefined,
                pageNumber: 1,
              }))
            }
          />
        </Box>
      )}
      {type === UserType.Vendor && (
        <Box sx={style.selectLargeContainer}>
          <Input
            value={filter.vendorNameOrNumber ?? ''}
            label={t('user:search.vendorNameOrNumber')}
            onChange={(event) =>
              setFilter((prev) => ({
                ...prev,
                vendorNameOrNumber: event.target.value ?? undefined,
                pageNumber: 1,
              }))
            }
          />
        </Box>
      )}
      <Box sx={style.selectSmallContainer}>
        <MultiSelect
          values={filter.roleIds ?? []}
          onConfirm={onRoleBlur}
          options={roles?.map((role) => ({ value: role.id, label: getTranslation(role, 'name') })) ?? []}
          label={t('user:search.role')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={getBooleanOptions()}
          value={filter.isAllowedAccess ?? ''}
          onChange={(e) => onBooleanChange(e, 'isAllowedAccess')}
          label={t('user:search.isAllowed')}
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <Select
          displayEmpty
          options={getBooleanOptions()}
          value={filter.isVerified ?? ''}
          onChange={(e) => onBooleanChange(e, 'isVerified')}
          label={t('user:search.isVerified')}
        />
      </Box>
    </FilterContainer>
  );

  const renderTextSearch = () => (
    <Autocomplete
      value={filter.userFirstNameOrLastName}
      sx={style.textContainer}
      noOptionsText={t('user:search.notFound')}
      apiFunction={(searchText: string) => userApi.getSuggestions(searchText, type)}
      label={t('user:search.title')}
      placeholder={t('user:search.placeholder')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.firstName} ${option.lastName}`)}
      onApplySearch={(searchText) =>
        setFilter((prev) => ({ ...prev, userFirstNameOrLastName: searchText ?? undefined, pageNumber: 1 }))
      }
      onSelectItem={(item) =>
        item &&
        navigate(
          generatePath(
            isInternalUser ? get(routes.Admin.User, `${type}.Detail.path`, '') : routes.Member.User.Detail.path,
            {
              userId: item.id,
            },
          ),
        )
      }
    />
  );

  return (
    <Stack>
      <Stack spacing={3}>
        <Box sx={style.filterBox}>
          <Typography variant="searchTitle" color="primary.dark">
            {t('user:search.title')}
          </Typography>
          <Switch onChange={toggleAdvancedMode} sx={style.switch} checked={isAdvancedMode} />
          <Typography variant="searchTitle" color="grey.600">
            {t('user:search.filterList')}
          </Typography>
        </Box>
        {!isAdvancedMode ? renderTextSearch() : renderAdvancedSearch()}
      </Stack>
      <ArchiveFilterSelect filter={filter} setFilter={setFilter} translationContext="male" />
    </Stack>
  );
};
