import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { DeepPartial, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApiErrorCodes, ApiException, Entity, EntityFormOptions, EntityFormSchema } from '../types';
import { useConfirmationModal } from './useConfirmationModal';
import { usePrompt } from './usePrompt';

export function useEntityForm<T extends Entity>(
  entity: T,
  schema: EntityFormSchema<T>,
  options?: Partial<EntityFormOptions>,
) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [ignoreDirtySubmit, setIgnoreDirtySubmit] = useState(false);
  const { openModal, modalRef } = useConfirmationModal();
  const { t } = useTranslation();

  const context = {
    readOnly: options?.readOnly || false,
    variant: options?.variant,
    setIgnoreDirtySubmit,
  };

  const form = useForm<T, EntityFormOptions>({
    defaultValues: { ...entity } as DeepPartial<T>,
    context: context,
    resolver: yupResolver(schema.schema),
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const {
    handleSubmit,
    control,
    reset: resetForm,
    setValue,
    setError,
    formState: { isDirty, isValid, isSubmitting },
    resetField,
    getValues,
    watch,
  } = form;

  usePrompt(async () => {
    openModal({
      title: t('common:navigation.confirmationTitle'),
      description: t('common:navigation.confirmationDescription'),
      confirmText: t('common:navigation.confirmationText'),
    });
    return (await modalRef.current?.waitForResponse()) ?? false;
  }, isDirty);

  useEffect(() => {
    if (isSubmitting) {
      (document.activeElement as HTMLInputElement)?.blur();
    }
  }, [isSubmitting]);

  useEffect(() => {
    setIsSubmitDisabled((!isDirty && !ignoreDirtySubmit) || !isValid || isSubmitting);
  }, [isDirty, isValid, ignoreDirtySubmit, isSubmitting]);

  const reset = useCallback(
    (entity?: T) => {
      if (entity) {
        resetForm({ ...entity });
      } else {
        resetForm();
      }
    },
    [resetForm],
  );

  const setValues = useCallback(
    (entity: T) => {
      if (entity) {
        //Ensure reset is called on next render cycle, to fix apparent bug with React Hook Form's useFieldArray
        setTimeout(() => resetForm({ ...entity }, { keepDefaultValues: true }), 0);
      }
    },
    [resetForm],
  );

  useEffect(() => {
    entity && reset(entity);
  }, [entity, reset]);

  function handleError(error: unknown) {
    const codes = (error as ApiException).code;
    if (codes && schema.errorCodeMap) {
      codes
        .split(',')
        .map((code) => code.trim() as ApiErrorCodes)
        .forEach((code) => {
          const errorCodeInfo = schema.errorCodeMap && schema.errorCodeMap[code];
          if (errorCodeInfo) {
            setError(errorCodeInfo.key, { message: errorCodeInfo.message });
          }
        });
    }
    throw error;
  }

  return {
    handleSubmit,
    control,
    reset,
    setValue,
    setValues,
    setError,
    resetField,
    handleError,
    getValues,
    watch,
    isSubmitDisabled,
    form: {
      ...form,
      ...context,
    },
  };
}
