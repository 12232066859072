import axios from 'axios';
import { HoldbackReportEntity, MissingTranslationReportEntity, VendorDataReportEntity } from '../../models';
import {
  MissingTranslationPaginationEntity,
  PaginationEntity,
  ReportPaginationEntity,
  VendorDataReportFilter,
} from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const vendorDataReport = {
  getAllUnapprovedBuyingAgreement: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<ReportPaginationEntity> => {
    const { data } = await axios.post('api/reports/buyingagreements/search', vendorDataReportFilter);
    return {
      ...data,
      data: data.data.map((d: VendorDataReportEntity) => new VendorDataReportEntity(d)),
    };
  },
  exportUnapprovedBuyingAgreement: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post('api/reports/buyingagreements/export', vendorDataReportFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  getAllRebateCategoriesWithoutPrograms: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<ReportPaginationEntity> => {
    const { data } = await axios.post('api/reports/rebatecategories/search', vendorDataReportFilter);
    return {
      ...data,
      data: data.data.map((d: VendorDataReportEntity) => new VendorDataReportEntity(d)),
    };
  },
  exportRebateCategoryWithoutPrograms: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post('api/reports/rebatecategories/export', vendorDataReportFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  getAllRebatePrograms: async (vendorDataReportFilter: VendorDataReportFilter): Promise<ReportPaginationEntity> => {
    const { data } = await axios.post('api/reports/rebateprograms/search', vendorDataReportFilter);
    return {
      ...data,
      data: data.data.map((d: VendorDataReportEntity) => new VendorDataReportEntity(d)),
    };
  },
  exportRebatePrograms: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post('api/reports/rebateprograms/export', vendorDataReportFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  getAllMissingTranslations: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<MissingTranslationPaginationEntity> => {
    const { data } = await axios.post('api/reports/missingtranslations/search', vendorDataReportFilter);
    return {
      ...data,
      data: data.data.map((d: MissingTranslationReportEntity) => new MissingTranslationReportEntity(d)),
    };
  },
  exportMissingTranslations: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post('api/reports/missingtranslations/export', vendorDataReportFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  getAllVendorProductCategories: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<ReportPaginationEntity> => {
    const { data } = await axios.post('api/reports/vendors/productcategories/search', vendorDataReportFilter);
    return {
      ...data,
      data: data.data.map((d: VendorDataReportEntity) => new VendorDataReportEntity(d)),
    };
  },
  exportVendorProductCategories: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post('api/reports/vendors/productcategories/export', vendorDataReportFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  getAllHoldbacks: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<PaginationEntity<HoldbackReportEntity>> => {
    const { data } = await axios.post('api/reports/holdbacks/search', vendorDataReportFilter);
    return {
      ...data,
      data: data.data.map((d: MissingTranslationReportEntity) => new MissingTranslationReportEntity(d)),
    };
  },
  exportHoldbacks: async (
    vendorDataReportFilter: VendorDataReportFilter,
  ): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.post('api/reports/holdbacks/export', vendorDataReportFilter, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
};
