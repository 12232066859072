import { PermissionKey, UserType } from '../types';

export const dependentPermissions: Record<UserType, Record<PermissionKey, PermissionKey[] | null>> = {
  InternalUser: {
    [PermissionKey.AdminEditPermissions]: [],
    [PermissionKey.AdminManageInternalUsers]: [],
    [PermissionKey.AdminManageMemberUsers]: [],
    [PermissionKey.AdminManageVendorUsers]: [],
    [PermissionKey.AdminManageGeneralGroups]: [],
    [PermissionKey.AdminManageOwnerGroups]: [],
    [PermissionKey.AdminManageDCGroups]: [],
    [PermissionKey.MemberViewMembers]: [],
    [PermissionKey.MemberEditMembers]: [PermissionKey.MemberViewMembers],
    [PermissionKey.MemberApproveMembers]: [PermissionKey.MemberViewMembers],
    [PermissionKey.VendorViewVendors]: [],
    [PermissionKey.VendorEditInfo]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorViewContacts]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorEditContacts]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewContacts],
    [PermissionKey.VendorApproveContacts]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewContacts],
    [PermissionKey.VendorViewFinancial]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorEditFinancial]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewFinancial],
    [PermissionKey.VendorApproveFinancial]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewFinancial],
    [PermissionKey.VendorViewRebates]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorEditRebates]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewRebates],
    [PermissionKey.VendorApproveRebates]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewRebates],
    [PermissionKey.VendorViewConditions]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorEditConditions]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewConditions],
    [PermissionKey.VendorApproveConditions]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewConditions],
    [PermissionKey.VendorViewBuys]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorEditBuys]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewBuys],
    [PermissionKey.VendorApproveBuys]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewBuys],
    [PermissionKey.VendorViewVBAs]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorEditVBAs]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewVBAs],
    [PermissionKey.VendorApproveVBAs]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewVBAs],
    [PermissionKey.VendorViewFiles]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorEditFiles]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewFiles],
    [PermissionKey.VendorApproveFiles]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewFiles],
    [PermissionKey.VendorViewBuyGuide]: null,
    [PermissionKey.VendorViewBlocks]: [PermissionKey.VendorViewVendors],
    [PermissionKey.VendorEditBlocks]: [PermissionKey.VendorViewVendors, PermissionKey.VendorViewBlocks],
    [PermissionKey.VendorViewPrograms]: null,
    [PermissionKey.CmsAdView]: [],
    [PermissionKey.CmsAdEdit]: [PermissionKey.CmsAdView],
    [PermissionKey.CmsAdApprove]: [PermissionKey.CmsAdView],
    [PermissionKey.AdminSupportMembers]: null,
    [PermissionKey.CmsNewsView]: [],
    [PermissionKey.CmsNewsEdit]: [PermissionKey.CmsNewsView],
    [PermissionKey.CmsNewsApprove]: [PermissionKey.CmsNewsView],
    [PermissionKey.MemberViewContacts]: [],
    [PermissionKey.MemberEditContacts]: [PermissionKey.MemberViewContacts],
    [PermissionKey.MemberApproveContacts]: [PermissionKey.MemberViewContacts],
    [PermissionKey.MemberViewMemberUsers]: null,
    [PermissionKey.AdminManageContacts]: [],
    [PermissionKey.CmsHelpView]: [],
    [PermissionKey.CmsHelpEdit]: [PermissionKey.CmsHelpView],
    [PermissionKey.CmsHelpApprove]: [PermissionKey.CmsHelpView],
    [PermissionKey.CciViewCommitments]: [],
    [PermissionKey.CciEditCommitments]: [PermissionKey.CciViewCommitments],
    [PermissionKey.CciManageEvents]: [],
    [PermissionKey.CmsStaticView]: [],
    [PermissionKey.CmsStaticEdit]: [PermissionKey.CmsStaticView],
    [PermissionKey.CmsStaticApprove]: [PermissionKey.CmsStaticView],
    [PermissionKey.AdminSettingsMailChimp]: [],
    [PermissionKey.ReportLogin]: [],
    [PermissionKey.ReportUsers]: [],
    [PermissionKey.CommodityGroupManage]: [],
    [PermissionKey.CommodityPriceView]: [],
    [PermissionKey.CommodityPriceEdit]: [PermissionKey.CommodityPriceView],
    [PermissionKey.MemberCommitmentsView]: [],
    [PermissionKey.MemberCommitmentsEdit]: [PermissionKey.MemberCommitmentsView],
    [PermissionKey.ReportVendorData]: [],
    [PermissionKey.ReportActivityLog]: [],
    [PermissionKey.AdminSettingsSearch]: [],
    [PermissionKey.VendorViewRebateTerms]: null,
    [PermissionKey.VendorViewRebateFOID]: null,
    [PermissionKey.VendorViewRebateMP]: null,
    [PermissionKey.VendorViewRebateQP]: null,
    [PermissionKey.VendorViewRebateSP]: null,
    [PermissionKey.VendorViewRebateAP]: null,
    [PermissionKey.VendorViewRebateTiersGrowths]: null,
    [PermissionKey.AdminViewCategorization]: [],
    [PermissionKey.AdminEditCategorization]: [PermissionKey.AdminViewCategorization],
    [PermissionKey.VendorVerifyRebates]: null
  },
  MemberUser: {
    [PermissionKey.AdminEditPermissions]: null,
    [PermissionKey.AdminManageInternalUsers]: null,
    [PermissionKey.AdminManageMemberUsers]: null,
    [PermissionKey.AdminManageVendorUsers]: null,
    [PermissionKey.AdminManageGeneralGroups]: null,
    [PermissionKey.AdminManageOwnerGroups]: null,
    [PermissionKey.AdminManageDCGroups]: null,
    [PermissionKey.MemberViewMembers]: [],
    [PermissionKey.MemberEditMembers]: [PermissionKey.MemberViewMembers],
    [PermissionKey.MemberApproveMembers]: null,
    [PermissionKey.VendorViewVendors]: null,
    [PermissionKey.VendorEditInfo]: null,
    [PermissionKey.VendorViewContacts]: [PermissionKey.VendorViewBuyGuide],
    [PermissionKey.VendorEditContacts]: null,
    [PermissionKey.VendorApproveContacts]: null,
    [PermissionKey.VendorViewFinancial]: null,
    [PermissionKey.VendorEditFinancial]: null,
    [PermissionKey.VendorApproveFinancial]: null,
    [PermissionKey.VendorViewRebates]: null,
    [PermissionKey.VendorEditRebates]: null,
    [PermissionKey.VendorApproveRebates]: null,
    [PermissionKey.VendorViewConditions]: [PermissionKey.VendorViewBuyGuide],
    [PermissionKey.VendorEditConditions]: null,
    [PermissionKey.VendorApproveConditions]: null,
    [PermissionKey.VendorViewBuys]: [PermissionKey.VendorViewBuyGuide],
    [PermissionKey.VendorEditBuys]: null,
    [PermissionKey.VendorApproveBuys]: null,
    [PermissionKey.VendorViewVBAs]: null,
    [PermissionKey.VendorEditVBAs]: null,
    [PermissionKey.VendorApproveVBAs]: null,
    [PermissionKey.VendorViewFiles]: [PermissionKey.VendorViewBuyGuide],
    [PermissionKey.VendorEditFiles]: null,
    [PermissionKey.VendorApproveFiles]: null,
    [PermissionKey.VendorViewBuyGuide]: [],
    [PermissionKey.VendorViewBlocks]: null,
    [PermissionKey.VendorEditBlocks]: null,
    [PermissionKey.VendorViewPrograms]: [PermissionKey.VendorViewBuyGuide],
    [PermissionKey.CmsAdView]: null,
    [PermissionKey.CmsAdEdit]: null,
    [PermissionKey.CmsAdApprove]: null,
    [PermissionKey.AdminSupportMembers]: [],
    [PermissionKey.CmsNewsView]: null,
    [PermissionKey.CmsNewsEdit]: null,
    [PermissionKey.CmsNewsApprove]: null,
    [PermissionKey.MemberViewContacts]: [],
    [PermissionKey.MemberEditContacts]: [],
    [PermissionKey.MemberApproveContacts]: null,
    [PermissionKey.MemberViewMemberUsers]: [],
    [PermissionKey.AdminManageContacts]: null,
    [PermissionKey.CmsHelpView]: null,
    [PermissionKey.CmsHelpEdit]: null,
    [PermissionKey.CmsHelpApprove]: null,
    [PermissionKey.CciViewCommitments]: [],
    [PermissionKey.CciEditCommitments]: [PermissionKey.CciViewCommitments],
    [PermissionKey.CciManageEvents]: null,
    [PermissionKey.CmsStaticView]: null,
    [PermissionKey.CmsStaticEdit]: null,
    [PermissionKey.CmsStaticApprove]: null,
    [PermissionKey.AdminSettingsMailChimp]: null,
    [PermissionKey.ReportLogin]: null,
    [PermissionKey.ReportUsers]: null,
    [PermissionKey.CommodityGroupManage]: null,
    [PermissionKey.CommodityPriceView]: [],
    [PermissionKey.CommodityPriceEdit]: null,
    [PermissionKey.MemberCommitmentsView]: [],
    [PermissionKey.MemberCommitmentsEdit]: null,
    [PermissionKey.ReportVendorData]: null,
    [PermissionKey.ReportActivityLog]: null,
    [PermissionKey.AdminSettingsSearch]: null,
    [PermissionKey.VendorViewRebateTerms]: [PermissionKey.VendorViewBuyGuide, PermissionKey.VendorViewPrograms],
    [PermissionKey.VendorViewRebateFOID]: [PermissionKey.VendorViewBuyGuide, PermissionKey.VendorViewPrograms],
    [PermissionKey.VendorViewRebateMP]: [PermissionKey.VendorViewBuyGuide, PermissionKey.VendorViewPrograms],
    [PermissionKey.VendorViewRebateQP]: [PermissionKey.VendorViewBuyGuide, PermissionKey.VendorViewPrograms],
    [PermissionKey.VendorViewRebateSP]: [PermissionKey.VendorViewBuyGuide, PermissionKey.VendorViewPrograms],
    [PermissionKey.VendorViewRebateAP]: [PermissionKey.VendorViewBuyGuide, PermissionKey.VendorViewPrograms],
    [PermissionKey.VendorViewRebateTiersGrowths]: [PermissionKey.VendorViewBuyGuide, PermissionKey.VendorViewPrograms],
    [PermissionKey.AdminViewCategorization]: null,
    [PermissionKey.AdminEditCategorization]: null,
    [PermissionKey.VendorVerifyRebates]: null
  },
  VendorUser: {
    [PermissionKey.AdminEditPermissions]: null,
    [PermissionKey.AdminManageInternalUsers]: null,
    [PermissionKey.AdminManageMemberUsers]: null,
    [PermissionKey.AdminManageVendorUsers]: null,
    [PermissionKey.AdminManageGeneralGroups]: null,
    [PermissionKey.AdminManageOwnerGroups]: null,
    [PermissionKey.AdminManageDCGroups]: null,
    [PermissionKey.MemberViewMembers]: [],
    [PermissionKey.MemberEditMembers]: null,
    [PermissionKey.MemberApproveMembers]: null,
    [PermissionKey.VendorViewVendors]: null,
    [PermissionKey.VendorEditInfo]: null,
    [PermissionKey.VendorViewContacts]: [],
    [PermissionKey.VendorEditContacts]: [PermissionKey.VendorViewContacts],
    [PermissionKey.VendorApproveContacts]: null,
    [PermissionKey.VendorViewFinancial]: [],
    [PermissionKey.VendorEditFinancial]: [PermissionKey.VendorViewFinancial],
    [PermissionKey.VendorApproveFinancial]: null,
    [PermissionKey.VendorViewRebates]: null,
    [PermissionKey.VendorEditRebates]: null,
    [PermissionKey.VendorApproveRebates]: null,
    [PermissionKey.VendorViewConditions]: null,
    [PermissionKey.VendorEditConditions]: null,
    [PermissionKey.VendorApproveConditions]: null,
    [PermissionKey.VendorViewBuys]: null,
    [PermissionKey.VendorEditBuys]: null,
    [PermissionKey.VendorApproveBuys]: null,
    [PermissionKey.VendorViewVBAs]: [],
    [PermissionKey.VendorEditVBAs]: [PermissionKey.VendorViewVBAs],
    [PermissionKey.VendorApproveVBAs]: null,
    [PermissionKey.VendorViewFiles]: [],
    [PermissionKey.VendorEditFiles]: [PermissionKey.VendorViewFiles],
    [PermissionKey.VendorApproveFiles]: null,
    [PermissionKey.VendorViewBuyGuide]: null,
    [PermissionKey.VendorViewBlocks]: null,
    [PermissionKey.VendorEditBlocks]: null,
    [PermissionKey.VendorViewPrograms]: null,
    [PermissionKey.CmsAdView]: null,
    [PermissionKey.CmsAdEdit]: null,
    [PermissionKey.CmsAdApprove]: null,
    [PermissionKey.AdminSupportMembers]: null,
    [PermissionKey.CmsNewsView]: [],
    [PermissionKey.CmsNewsEdit]: [PermissionKey.CmsNewsView],
    [PermissionKey.CmsNewsApprove]: null,
    [PermissionKey.MemberViewContacts]: [],
    [PermissionKey.MemberEditContacts]: null,
    [PermissionKey.MemberApproveContacts]: null,
    [PermissionKey.MemberViewMemberUsers]: null,
    [PermissionKey.AdminManageContacts]: null,
    [PermissionKey.CmsHelpView]: null,
    [PermissionKey.CmsHelpEdit]: null,
    [PermissionKey.CmsHelpApprove]: null,
    [PermissionKey.CciViewCommitments]: [],
    [PermissionKey.CciEditCommitments]: null,
    [PermissionKey.CciManageEvents]: null,
    [PermissionKey.CmsStaticView]: null,
    [PermissionKey.CmsStaticEdit]: null,
    [PermissionKey.CmsStaticApprove]: null,
    [PermissionKey.AdminSettingsMailChimp]: null,
    [PermissionKey.ReportLogin]: null,
    [PermissionKey.ReportUsers]: null,
    [PermissionKey.CommodityGroupManage]: null,
    [PermissionKey.CommodityPriceView]: null,
    [PermissionKey.CommodityPriceEdit]: null,
    [PermissionKey.MemberCommitmentsView]: null,
    [PermissionKey.MemberCommitmentsEdit]: null,
    [PermissionKey.ReportVendorData]: null,
    [PermissionKey.ReportActivityLog]: null,
    [PermissionKey.AdminSettingsSearch]: null,
    [PermissionKey.VendorViewRebateTerms]: null,
    [PermissionKey.VendorViewRebateFOID]: null,
    [PermissionKey.VendorViewRebateMP]: null,
    [PermissionKey.VendorViewRebateQP]: null,
    [PermissionKey.VendorViewRebateSP]: null,
    [PermissionKey.VendorViewRebateAP]: null,
    [PermissionKey.VendorViewRebateTiersGrowths]: null,
    [PermissionKey.AdminViewCategorization]: null,
    [PermissionKey.AdminEditCategorization]: null,
    [PermissionKey.VendorVerifyRebates]: null
  },
};
