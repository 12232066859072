import { Grid, Typography } from '@mui/material';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { ForestProductGroupEntity } from '../../../models';
import { MAX_SHORT_TEXT_LENGTH } from '../../../constants';
import { ControlledTranslatedInput } from '../../Form';

export const ForestProductGroupHeader = () => {
  const { t } = useInternationalization();
  const { control, readOnly, getValues } = useEntityFormContext<ForestProductGroupEntity>();
  const values = getValues();

  if (readOnly) {
    return <Typography variant="h1">{values.name_En}</Typography>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ControlledTranslatedInput
          maxLength={MAX_SHORT_TEXT_LENGTH}
          label={t('forestProduct:groups.form.name')}
          name="name"
          control={control}
          required
          isFrRequired
          variant="standard"
        />
      </Grid>
    </Grid>
  );
};
