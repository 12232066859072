import { DOLLAR_MAX_DECIMALS, PERCENT_MAX_DECIMALS } from '../constants';
import { InternationalizationProps } from '../hooks/useInternationalization';
import { VendorRebateCategoryRebateEntity } from '../models';
import { LanguageCode, RebateTypeAndUnitType } from '../types';
import { numberFormatter } from './formatters';

export const calculateTermValue = (
  entity: VendorRebateCategoryRebateEntity,
  internationalization: Pick<InternationalizationProps, 'currentLanguage' | 't' | 'getTranslation'>,
) => {
  const { getTranslation, t, currentLanguage } = internationalization;
  const isCustomUnitWatch = entity.unit === RebateTypeAndUnitType.Custom;

  if (entity.isTermNote) {
    return getTranslation(entity, 'termNote');
  } else if (entity.termValue !== null) {
    const formatter = numberFormatter(
      currentLanguage,
      entity.unit !== RebateTypeAndUnitType.Custom
        ? t(`common:rebateTypeAndUnitType.${entity.unit}`)
        : getTranslation(entity, 'customUnit'),
      entity.unit === RebateTypeAndUnitType.Dollar ? DOLLAR_MAX_DECIMALS : PERCENT_MAX_DECIMALS,
      entity.unit === RebateTypeAndUnitType.Dollar && currentLanguage === LanguageCode.en ? false : true,
    );
    return formatter(entity.termValue).formatted.join(isCustomUnitWatch ? ' ' : '');
  }
  return null;
};
