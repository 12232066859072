/* eslint-disable @typescript-eslint/no-explicit-any */
import { AutocompleteRenderInputParams } from '@mui/material';
import { useEffect, useState } from 'react';
import { ControlledTranslatedInput } from '..';
import { useEntityFormContext, useInternationalization } from '../../../hooks';
import { LanguageCode, TranslationLanguage, WithoutLanguageSuffix } from '../../../types';
import { Autocomplete, AutocompleteProps } from '../Base/Autocomplete';

export interface ControlledTranslatedAutocompleteInputProps<T> extends React.ComponentProps<typeof ControlledTranslatedInput> {
  name: WithoutLanguageSuffix<keyof T>;
}

interface ControlledTranslatedAutocompleteProps<T, Args extends any[]> extends AutocompleteProps<T, Args> {
  inputProps: ControlledTranslatedAutocompleteInputProps<T>;
}

export const ControlledTranslatedAutocomplete = <T, Args extends any[]>({
  inputProps,
  ...props
}: ControlledTranslatedAutocompleteProps<T, Args>) => {
  const { setValue, watch } = useEntityFormContext();
  const { currentLanguage } = useInternationalization();
  const languageDispatch = useState<TranslationLanguage>(
    currentLanguage === LanguageCode.fr ? TranslationLanguage.Fr : TranslationLanguage.En,
  );
  const languageValue = languageDispatch[0];
  const [searchText, setSearchText] = useState('');
  const watchEn = watch(`${inputProps.name}_${TranslationLanguage.En}`);
  const watchFr = watch(`${inputProps.name}_${TranslationLanguage.Fr}`);

  useEffect(() => {
    setSearchText(languageValue == TranslationLanguage.En ? watchEn : watchFr);
  }, [languageValue, setSearchText, watchEn, watchFr]);

  const getNameKey = (lang: TranslationLanguage) => `${inputProps.name}_${lang}`;

  const onSelectItem = (item: T | null) => {
    if (item) {
      Object.values(TranslationLanguage).forEach((v) => {
        setValue(getNameKey(v), (item as any)[getNameKey(v)]);
      });
    }
  };

  const renderTranslatedInput = (params?: AutocompleteRenderInputParams) => (
    <ControlledTranslatedInput
      {...params}
      {...inputProps}
      inputProps={{
        ...(params ? params.inputProps : {}),
        ...inputProps.inputProps,
      }}
      InputProps={{
        ref: params?.InputProps.ref,
      }}
      languageDispatch={languageDispatch}
    />
  );

  return inputProps.readOnly ? (
    renderTranslatedInput()
  ) : (
    <Autocomplete
      {...props}
      onSelectItem={onSelectItem}
      languageOptionKey={inputProps.name}
      value={searchText}
      renderInput={renderTranslatedInput}
    />
  );
};
