import axios from 'axios';
import { SearchDocumentEntity, SearchReindexationStatusEntity } from '../models';
import { PaginationEntity, SearchRequest } from '../types';

export const search = {
  search: async (request: SearchRequest): Promise<PaginationEntity<SearchDocumentEntity>> => {
    const { data } = await axios.post(`api/search`, request);
    return data;
  },
  reindex: async (): Promise<void> => {
    return (await axios.post('api/search/reindex')).data;
  },
  reindexStatus: async (): Promise<SearchReindexationStatusEntity | null> => {
    return (await axios.get('api/search/reindex/status')).data;
  },
};
