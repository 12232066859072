import { RebateTypeAndUnitType } from '../../types';
import { EditableEntity } from '../EditableEntity';
import { VendorTierGrowthValueEntity } from './VendorTierGrowthValueEntity';

export class VendorTierGrowthEntity extends EditableEntity {
  isArchived: boolean;
  name_Fr: string;
  name_En: string;
  rebateUnit: RebateTypeAndUnitType;
  rebateCustomUnit_En: string;
  rebateCustomUnit_Fr: string;
  unit: RebateTypeAndUnitType;
  customUnit_En: string;
  customUnit_Fr: string;
  memberNotes_En: string;
  memberNotes_Fr: string;
  notes: string;
  isRetroactiveToDollar?: boolean;
  values: VendorTierGrowthValueEntity[];

  constructor(entity?: VendorTierGrowthEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.name_Fr = '';
      this.name_En = '';
      this.rebateUnit = RebateTypeAndUnitType.Percent;
      this.rebateCustomUnit_En = '';
      this.rebateCustomUnit_Fr = '';
      this.unit = RebateTypeAndUnitType.Dollar;
      this.customUnit_En = '';
      this.customUnit_Fr = '';
      this.memberNotes_Fr = '';
      this.memberNotes_En = '';
      this.notes = '';
      this.isRetroactiveToDollar = true;
      this.values = [new VendorTierGrowthValueEntity()];
    }
  }
}
