import { BuyGuideVendorRebateProgramDetail } from '../../../components/BuyGuide';
import { ConfidentialAgreement } from '../../../components/ConfidentialAgreement';

export function BuyGuideRebateProgramDetail() {
  return (
    <ConfidentialAgreement>
      <BuyGuideVendorRebateProgramDetail />
    </ConfidentialAgreement>
  );
}
