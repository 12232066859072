import { Box } from '@mui/material';
import { ChangeEvent, useContext } from 'react';
import { MemberChangeRequestContext, VendorChangeRequestContext } from '../../contexts';
import { useInternationalization } from '../../hooks';
import { ChangeRequestType, Styles } from '../../types';
import { Switch } from '../Form';

const style: Styles = {
  container: {
    mb: 2,
  },
};

export const ChangeRequestDrawerHeader = () => {
  const { t } = useInternationalization();
  const vendorContext = useContext(VendorChangeRequestContext);
  const memberContext = useContext(MemberChangeRequestContext);
  const { toggleViewMode, isAfter, changeRequest } = vendorContext.changeRequest ? vendorContext : memberContext;

  const onChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    toggleViewMode(checked);
  };

  if ([ChangeRequestType.Archive, ChangeRequestType.Create].includes(changeRequest.type)) {
    return null;
  }

  return (
    <Box sx={style.container}>
      <Switch
        label={t('changeRequest:entityState.after')}
        leftLabel={t('changeRequest:entityState.before')}
        onChange={onChange}
        checked={isAfter}
      />
    </Box>
  );
};
