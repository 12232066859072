import { CircularProgress } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationEmailFrequencyForm, NotificationSettingForm, PasswordForm, ProfileForm } from '.';
import { profile as profileApi, auth as authApi } from '../../api';
import { EditDetails } from '../../components';
import { EditCard } from '../../components/Card';
import { Container } from '../../components/Container';
import { StaticField } from '../../components/Form';
import { useApi, usePageTitle } from '../../hooks';
import useAuth from '../../hooks/useAuth';
import { ProfileEntity, UserPasswordEntity } from '../../models';
import { ProfileSchema } from '../../schemas';
import {
  PasswordSchema,
  ProfileNotificationEmailFrequencySchema,
  ProfileNotificationSettingSchema,
} from '../../schemas/Profile';
import { Styles } from '../../types';
import { MenuItem } from '../Menu';
import { TwoFactorAuthenticationForm } from './TwoFactorAuthenticationForm';

const style: Styles = {
  container: {
    marginTop: '60px',
  },
  loading: {
    mx: 2,
  },
};

export const ProfileDetail = () => {
  const { t } = useTranslation();
  const { user, setProfileInformation, isMemberUserSupport, isInternalUser } = useAuth();
  const { data: profile, isLoading, refresh } = useApi(profileApi.get, { callImmediately: true });
  const { call: update } = useApi(profileApi.update, {
    successKey: 'common:success.save',
  });
  const { call: resetPhoneNumber } = useApi(authApi.resetPhoneNumber, {
    successKey: 'common:success.save',
  });
  const { call: updatePassword } = useApi(profileApi.updatePassword, {
    errorKey: 'user:profile.password.error',
    successKey: 'common:success.save',
  });
  const passwordEntity = useMemo(() => {
    const entity = new UserPasswordEntity();
    if (user?.profile) {
      entity.id = user?.profile.id;
    }
    return entity;
  }, [user]);

  useEffect(() => {
    if (profile) {
      setProfileInformation(profile);
    }
  }, [profile, setProfileInformation]);

  usePageTitle(profile?.fullName ?? '');

  const twoFactorAuthenticationMenuItems = (onClick: () => void) =>
    profile && isInternalUser
      ? [
          <MenuItem
            key="resend2fa"
            onClick={async () => {
              onClick();
              await resetPhoneNumber();
              refresh();
            }}
          >
            {t('user:profile.twoFactorAuthentication.reset')}
          </MenuItem>,
        ]
      : [];

  return (
    <>
      <Container wrapperSx={style.container}>
        {isLoading && <CircularProgress sx={style.loading} />}
        {profile && (
          <EditDetails
            alwaysOpen
            titleLabel={t(`user:form.fullName`)}
            title={'fullName'}
            header={<StaticField hideLabel={true} variant={'h1'} value={profile.fullName} />}
            hideHeaderWhenEditing
            entity={profile}
            schema={ProfileSchema()}
            onConfirm={refresh}
            onSave={(profile: ProfileEntity) => update(profile)}
          >
            <ProfileForm />
          </EditDetails>
        )}
      </Container>
      <Container isHighlighted>
        <EditCard
          title={t('user:profile.password.title')}
          entity={passwordEntity}
          schema={PasswordSchema()}
          onSave={updatePassword}
        >
          <PasswordForm />
        </EditCard>
        {isInternalUser && profile && (
          <EditCard
            title={t('user:profile.twoFactorAuthentication.title')}
            entity={profile}
            schema={ProfileSchema()}
            actionMenuItems={profile.verificationPhoneNumber ? twoFactorAuthenticationMenuItems : undefined}
            readOnly
          >
            <TwoFactorAuthenticationForm />
          </EditCard>
        )}
        {!isMemberUserSupport && profile && profile.notificationSettings?.length > 0 && (
          <EditCard
            title={t('notification:title')}
            entity={profile}
            schema={ProfileNotificationSettingSchema()}
            onSave={update}
            onConfirm={refresh}
          >
            <NotificationSettingForm />
          </EditCard>
        )}
        {!isMemberUserSupport && profile && (
          <EditCard
            title={t('notification:emailFrequency.title')}
            entity={profile}
            schema={ProfileNotificationEmailFrequencySchema()}
            onSave={update}
            onConfirm={refresh}
          >
            <NotificationEmailFrequencyForm />
          </EditCard>
        )}
      </Container>
    </>
  );
};
