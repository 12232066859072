import { EditRounded, SyncProblemRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, IconButton, MenuItemProps, Stack, Tooltip, Typography } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityFormProvider } from '../../contexts';
import { useEntityForm, useGlobalEdit } from '../../hooks';
import { palette } from '../../styles/palette';
import { Entity, EntityFormSchema, Pending, Styles } from '../../types';
import { EntityModifiedDate } from '../EntityModifiedDate';
import { ActionMenu } from '../Menu';

const style: Styles = {
  card: {
    my: 3,
  },
  cardContent: {
    pt: 1,
  },
  editingCard: {
    border: `2px solid ${palette.primary.dark}`,
  },
  header: {
    borderBottom: 'solid 1px ' + palette.grey[300],
    mb: 2,
    pb: 0.5,
  },
  editHeader: {
    px: 2,
    py: 1,
    color: palette.white,
    backgroundColor: palette.primary.dark,
  },
  headerTitle: {
    py: 1.5,
  },
};

interface EditCardProps<T extends Entity> extends PropsWithChildren {
  entity: T;
  schema: EntityFormSchema<T>;
  onSave?: (entity: T) => Promise<T | null>;
  onConfirm?: () => void;
  title: string | React.ReactNode;
  actionButtons?: React.ReactElement | React.ReactElement[];
  disabled?: boolean;
  readOnly?: boolean;
  showModifiedDate?: boolean;
  isChangeRequest?: boolean;
  actionMenuItems?: (onClick: () => void) => React.ReactElement<MenuItemProps>[];
}

export const EditCard = <T extends Entity & Pending>({
  disabled,
  entity,
  schema,
  onSave,
  onConfirm,
  title,
  children,
  actionButtons,
  readOnly,
  showModifiedDate,
  isChangeRequest,
  actionMenuItems,
}: EditCardProps<T>) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const { globalEditing, setGlobalEditing } = useGlobalEdit();
  const { handleSubmit, reset, isSubmitDisabled, form, handleError } = useEntityForm<T>(entity, schema, {
    readOnly: readOnly || !editing,
    variant: 'card',
  });
  const [isSaving, setIsSaving] = useState(false);

  const onEditClick = () => setEditing(!editing);

  const onCancelClick = () => {
    entity && reset(entity);
    setEditing(false);
  };

  const onSubmitSuccess = async (data: T) => {
    if (onSave && !isSaving) {
      try {
        setIsSaving(true);
        const saveResult = await onSave(data);
        if (saveResult) {
          setEditing(false);
          onConfirm && onConfirm();
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  useEffect(() => {
    setGlobalEditing(editing);
    if (disabled && editing) {
      setEditing(false);
    }
  }, [disabled, editing, setGlobalEditing]);

  return (
    <Card sx={[style.card, editing ? style.editingCard : {}]}>
      <EntityFormProvider {...form}>
        <form id="edit-vendor-form" onSubmit={handleSubmit(onSubmitSuccess)}>
          {editing && (
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={style.editHeader}>
              <Stack spacing={1} direction="row" alignItems="center">
                <EditRounded />
                <Typography variant="drawerTitle">{t('common:editing', { name: title })}</Typography>
              </Stack>
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <Button onClick={onCancelClick} variant="outlined" color="secondary" size="small">
                  {t('common:cancel')}
                </Button>
                <Stack spacing={3} direction="row">
                  <LoadingButton
                    loading={isSaving}
                    disabled={isSubmitDisabled}
                    variant="contained"
                    size="small"
                    type="submit"
                  >
                    {isChangeRequest ? t('common:changeRequest.submit') : t('common:saveAndClose')}
                  </LoadingButton>
                  {actionButtons}
                </Stack>
              </Stack>
            </Box>
          )}
          <CardContent sx={style.cardContent}>
            {!editing && (
              <Stack sx={style.header} spacing={3} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" sx={style.headerTitle}>
                  {title}
                </Typography>
                <Stack spacing={3} direction="row" alignItems="center">
                  {showModifiedDate && <EntityModifiedDate entity={entity} />}
                  {entity.isPending && (
                    <Tooltip title={t('common:changeRequest.pending')}>
                      <SyncProblemRounded htmlColor={palette.primary.deep} />
                    </Tooltip>
                  )}
                  {!readOnly && !entity.isPending && (
                    <IconButton disabled={globalEditing || disabled} onClick={onEditClick}>
                      <EditRounded />
                    </IconButton>
                  )}
                  {actionMenuItems && <ActionMenu menuItems={actionMenuItems} />}
                </Stack>
              </Stack>
            )}
            <Box sx={editing ? style.cardContent : {}}>{children}</Box>
          </CardContent>
        </form>
      </EntityFormProvider>
    </Card>
  );
};
