import { Box, CircularProgress, Stack } from '@mui/material';
import { useContext } from 'react';
import { VendorRebateCategoryPermissionsNoteForm } from '.';
import { vendor as vendorApi } from '../../../api';
import { VendorRebateCategoryDetailContext } from '../../../contexts/Vendor/VendorRebateCategoryDetailContext';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorRebateCategoryEntity } from '../../../models';
import { VendorRebateCategorySchema } from '../../../schemas';
import { Styles } from '../../../types';
import { EditCard } from '../../Card';
import { Container } from '../../Container';
import {
  TargetedMembershipCategorizationForm,
  TargetedMembershipMemberForm,
  TargetedMembershipMemberGroupForm,
} from '../../TargetedMembership';

const style: Styles = {
  loading: {
    mx: 2,
    mt: 3,
  },
  highlightContainer: {
    mb: 5,
  },
};

export const VendorRebateCategoryPermissions = () => {
  const { t } = useInternationalization();
  const { vendorId, vendorRebateCategory, fetchData, isLoading, readOnly } = useContext(
    VendorRebateCategoryDetailContext,
  );
  const { call: update } = useApi(vendorApi.updateRebateCategory, {
    successKey: 'common:success.save',
  });

  return (
    <Container isHighlighted>
      <Stack spacing={2} sx={style.highlightContainer}>
        {isLoading && !vendorRebateCategory && <CircularProgress size={20} sx={style.loading} />}
        {vendorRebateCategory && (
          <Box>
            <EditCard
              readOnly={readOnly}
              title={t('vendor:targetedMembership.title')}
              entity={vendorRebateCategory}
              disabled={vendorRebateCategory.isArchived}
              schema={VendorRebateCategorySchema()}
              onSave={(entity: VendorRebateCategoryEntity) => update(vendorId, entity)}
              onConfirm={fetchData}
            >
              <TargetedMembershipCategorizationForm />
            </EditCard>
            <EditCard
              readOnly={readOnly}
              title={t('vendor:targetedMembership.form.memberGroupTitle')}
              entity={vendorRebateCategory}
              disabled={vendorRebateCategory.isArchived}
              schema={VendorRebateCategorySchema()}
              onSave={(entity: VendorRebateCategoryEntity) => update(vendorId, entity)}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberGroupForm hideTitle />
            </EditCard>
            <EditCard
              readOnly={readOnly}
              title={t('vendor:targetedMembership.form.memberTitle')}
              entity={vendorRebateCategory}
              disabled={vendorRebateCategory.isArchived}
              schema={VendorRebateCategorySchema()}
              onSave={(entity: VendorRebateCategoryEntity) => update(vendorId, entity)}
              onConfirm={fetchData}
            >
              <TargetedMembershipMemberForm hideTitle />
            </EditCard>
            <EditCard
              readOnly={readOnly}
              title={t('vendor:rebateCategory:form.notes')}
              entity={vendorRebateCategory}
              disabled={vendorRebateCategory.isArchived}
              schema={VendorRebateCategorySchema()}
              onSave={(entity: VendorRebateCategoryEntity) => update(vendorId, entity)}
              onConfirm={fetchData}
            >
              <VendorRebateCategoryPermissionsNoteForm />
            </EditCard>
          </Box>
        )}
      </Stack>
    </Container>
  );
};
