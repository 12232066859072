import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { ExportButton } from '../..';
import { logging as loggingApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER, MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useEnumList, useInternationalization, usePageTitle } from '../../../hooks';
import { AuthenticationLogEntity } from '../../../models';
import {
  AuthenticationLogFilter,
  AuthenticationLogType,
  ExportType,
  OrderBy,
  PermissionKey,
  Styles,
  UserType,
} from '../../../types';
import { areArrayEquals, downloadBlob, formatDateTime, formatShortDate, isDateValid } from '../../../utils/helper';
import { FilterContainer } from '../../Container';
import { DatePicker, MultiSelect, Select } from '../../Form';
import { LayoutPage } from '../../Layout';
import { Table, TableColumn } from '../../Table';

const style: Styles = {
  selectSmallContainer: {
    width: {
      xs: '100%',
      sm: '180px',
    },
  },
  selectLargeContainer: {
    width: {
      xs: '100%',
      sm: '300px',
    },
  },
};

const baseAuthenticationFilter: AuthenticationLogFilter = {
  effectiveDate: undefined,
  expiryDate: undefined,
  types: undefined,
  userType: undefined,
  ...DEFAULT_PAGINATION_FILTER,
};

const failedAuthenticationFilter: AuthenticationLogFilter = {
  hasUser: true,
  ...baseAuthenticationFilter,
};

const invalidAccessFilter: AuthenticationLogFilter = {
  hasUser: false,
  ...baseAuthenticationFilter,
};

interface AuthenticationLogListProps {
  mode?: 'failedAuthentication' | 'invalidAccess';
}

export const AuthenticationLogList = ({ mode }: AuthenticationLogListProps) => {
  const { t, timeZoneName } = useInternationalization();
  const [filter, setFilter] = useState(mode === 'invalidAccess' ? invalidAccessFilter : failedAuthenticationFilter);
  const { data, isLoading } = useApi(loggingApi.getAllAuthenticationLogs, null, filter);
  const { call: downloadExport } = useApi(loggingApi.exportAuthenticationLogs, {
    successKey: 'common:success.action',
  });
  usePageTitle(`report:authenticationLogs.modes.${mode}`);

  const userTypeOptions = useEnumList(UserType, 'user:UserType', true);
  const authenticationLogTypeOptions = useEnumList(AuthenticationLogType, 'report:AuthenticationLogType');

  const filteredAuthenticationLogTypeOptions = useMemo(
    () =>
      authenticationLogTypeOptions.filter(
        (authenticationType) => authenticationType.value !== AuthenticationLogType.UnknownUser,
      ),
    [authenticationLogTypeOptions],
  );

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onUserTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      userType: (event.target.value as UserType) || undefined,
      pageNumber: 1,
    }));
  };

  const onEffectiveDateChange = (date: Date | null) => {
    if (date && isDateValid(date)) {
      setFilter((prev) => ({ ...prev, effectiveDate: formatShortDate(date), pageNumber: 1 }));
    }
    if (!date) {
      setFilter((prev) => ({ ...prev, effectiveDate: undefined, pageNumber: 1 }));
    }
  };

  const onExpiryDateChange = (date: Date | null) => {
    if (date && isDateValid(date)) {
      setFilter((prev) => ({ ...prev, expiryDate: formatShortDate(date), pageNumber: 1 }));
    }
    if (!date) {
      setFilter((prev) => ({ ...prev, expiryDate: undefined, pageNumber: 1 }));
    }
  };

  const onTypesChange = (selected: AuthenticationLogType[]) => {
    if (!areArrayEquals(filter.types ?? [], selected)) {
      setFilter((prev) => ({ ...prev, types: selected.length ? selected : undefined, pageNumber: 1 }));
    }
  };

  const onClear = () => {
    setFilter((prev) => ({
      ...prev,
      ...(mode === 'invalidAccess' ? invalidAccessFilter : failedAuthenticationFilter),
    }));
  };

  const onExport = async (exportType: ExportType = ExportType.PDF) => {
    const fileData = await downloadExport({ ...filter, ...MAX_PAGINATION_FILTER }, exportType);
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.pdf', fileData.data);
    }
  };

  const formatDate = (date?: string) => (date ? `${date}T00:00:00` : null);

  const renderSearch = () => (
    <FilterContainer onClear={onClear} mb={3}>
      <Box sx={style.selectSmallContainer}>
        <DatePicker
          value={formatDate(filter.effectiveDate)}
          label={t('report:authenticationLogs.filter.effectiveDate')}
          onChange={onEffectiveDateChange}
          clearable
        />
      </Box>
      <Box sx={style.selectSmallContainer}>
        <DatePicker
          value={formatDate(filter.expiryDate)}
          label={t('report:authenticationLogs.filter.expiryDate')}
          onChange={onExpiryDateChange}
          clearable
        />
      </Box>
      {mode === 'failedAuthentication' && (
        <Box sx={style.selectSmallContainer}>
          <Select
            displayEmpty
            options={userTypeOptions}
            value={filter.userType ?? ''}
            onChange={onUserTypeChange}
            label={t('report:authenticationLogs.table.userType')}
          />
        </Box>
      )}
      {mode === 'failedAuthentication' && (
        <Box sx={style.selectLargeContainer}>
          <MultiSelect
            displayEmpty
            options={filteredAuthenticationLogTypeOptions}
            values={filter.types ?? []}
            onConfirm={onTypesChange}
            noValueText={t('common:filter.all', { context: 'female' })}
            label={t('report:authenticationLogs.table.type')}
          />
        </Box>
      )}
    </FilterContainer>
  );

  const renderUsername = (entity: AuthenticationLogEntity) => <Typography>{entity.username}</Typography>;

  const renderUserType = (entity: AuthenticationLogEntity) => (
    <Typography>{entity.userType ? t(`user:UserType.${entity.userType}`) : ''}</Typography>
  );

  const renderType = (entity: AuthenticationLogEntity) => (
    <Typography>{t(`report:AuthenticationLogType.${entity.type}`)}</Typography>
  );

  const renderLogDate = (entity: AuthenticationLogEntity) => formatDateTime(entity.logDate, timeZoneName);

  const renderTable = () => (
    <Table
      data={data ?? []}
      translationNamespace="report:authenticationLogs"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
    >
      <TableColumn type="custom" width="20%" id="username" sortable render={renderUsername} />
      {mode === 'failedAuthentication' && (
        <TableColumn type="custom" width="20%" id="userType" sortable render={renderUserType} />
      )}
      {mode === 'failedAuthentication' && (
        <TableColumn type="custom" width="15%" id="type" sortable render={renderType} />
      )}
      <TableColumn type="custom" width="20%" id="logDate" sortable render={renderLogDate} />
    </Table>
  );

  const renderPageActions = () => (
    <ExportButton displayMenu defaultExportType={ExportType.PDF} onExportClick={onExport} />
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.ReportLogin }}
      display="Tab"
      title={t(`report:authenticationLogs.modes.${mode}`)}
      rightTitle={renderPageActions()}
    >
      {renderSearch()}
      {renderTable()}
    </LayoutPage>
  );
};
