import { VendorEntity } from '../Vendor';

export class MemberCommitmentVendorEntity {
  vendor: VendorEntity | null;
  vendorId: number | null;
  isPreferred: boolean;
  isCurrent: boolean;
  willSupport?: boolean;

  constructor(entity?: MemberCommitmentVendorEntity) {
    if (entity) {
      Object.assign(this, { ...entity });
      this.vendorId = this.vendor?.id ?? 0;
    } else {
      this.isPreferred = false;
      this.isCurrent = false;
      this.willSupport = undefined;
    }
  }
}
