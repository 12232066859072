import { FileEntity, PeriodEntity, VendorRebateCategoryEntity } from '..';
import { DocumentType, LanguageCode } from '../../types';

export class VendorDocumentEntity extends PeriodEntity {
  firstName: string;
  lastName: string;
  description_En: string;
  description_Fr: string;
  isVisibleToMember: boolean;
  type: DocumentType;
  languages: LanguageCode[];
  file?: FileEntity | null;
  isArchived: boolean;
  vendorNotes: string;
  notes: string;
  memberNotes_En: string;
  memberNotes_Fr: string;
  rebateCategoryIds: number[];
  rebateCategories: VendorRebateCategoryEntity[];
  changeRequestId?: number;

  constructor(entity?: VendorDocumentEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.firstName = '';
      this.lastName = '';
      this.description_En = '';
      this.description_Fr = '';
      this.isVisibleToMember = false;
      this.type = DocumentType.PriceList;
      this.languages = [];
      this.rebateCategoryIds = [];
      this.file = null;
      this.memberNotes_En = '';
      this.memberNotes_Fr = '';
      this.notes = '';
      this.vendorNotes = '';
    }
  }
}
