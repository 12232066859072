import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { MAX_COMMENT_LENGTH } from '../../constants';
import { useInternationalization } from '../../hooks';
import { Styles } from '../../types';
import { Input } from '../Form';

const style: Styles = {
  container: {
    px: 2.5,
    py: 2,
  },
};

interface ChangeLogCommentProps {
  isLoading: boolean;
  onCommentAdded: (text: string) => Promise<void>;
}

export const ChangeLogComment = ({ isLoading, onCommentAdded }: ChangeLogCommentProps) => {
  const { t } = useInternationalization();
  const [commentText, setCommentText] = useState('');
  const [isCommentLoading, setCommentLoading] = useState(false);

  const onAddComment = async () => {
    try {
      setCommentLoading(true);
      await onCommentAdded(commentText);
    } finally {
      setCommentLoading(false);
      setCommentText('');
    }
  };

  return (
    <Stack spacing={1} sx={style.container}>
      <Stack>
        <Typography variant="h4" alignSelf="flex-end">
          {t('changeLog:comment.max', { maxLength: MAX_COMMENT_LENGTH })}
        </Typography>
        <Input
          disabled={isLoading || isCommentLoading}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          multiline
          rows={4}
          maxLength={MAX_COMMENT_LENGTH}
          placeholder={t('changeLog:comment.placeholder')}
        ></Input>
      </Stack>
      <LoadingButton
        onClick={onAddComment}
        loading={isCommentLoading}
        disabled={isLoading || isCommentLoading || commentText.length == 0}
        size="large"
        variant="contained"
      >
        {t('changeLog:comment.add')}
      </LoadingButton>
    </Stack>
  );
};
