import { AddRounded, EditRounded } from '@mui/icons-material';
import { Box, Button, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { vendor as vendorApi } from '../../../../api';
import { MAX_PAGINATION_FILTER } from '../../../../constants';
import { VendorBuyingAgreementContext } from '../../../../contexts/Vendor';
import { useApi, useEnumList, useGlobalEdit, useInternationalization } from '../../../../hooks';
import {
  GeneralConditionEntity,
  TranslatedFileEntity,
  VendorBuyingAgreementGeneralConditionEntity,
} from '../../../../models';
import { VendorBuyingAgreementGeneralConditionSchema } from '../../../../schemas';
import { GeneralConditionType, OrderBy, PermissionKey, Styles, VendorGeneralConditionFilter } from '../../../../types';
import { downloadBlob } from '../../../../utils/helper';
import { EditDrawer } from '../../../EditDrawer';
import { Select } from '../../../Form';
import { LayoutPage } from '../../../Layout';
import { DeleteMenuItem, MenuItem } from '../../../Menu';
import { HasPermissions } from '../../../Permission';
import { VendorGeneralConditionsTable } from '../../GeneralConditions/VendorGeneralConditionsTable';
import { VendorBuyingAgreementGeneralConditionForm } from './VendorBuyingAgreementGeneralConditionForm';

const style: Styles = {
  selectLargeContainer: {
    width: {
      xs: '100%',
      sm: '300px',
    },
    pb: 2,
  },
};

export const VendorBuyingAgreementGeneralConditionsList = () => {
  const { t } = useInternationalization();
  const { globalEditing } = useGlobalEdit();
  const { vendorBuyingAgreement, readOnly } = useContext(VendorBuyingAgreementContext);
  const [filter, setFilter] = useState<VendorGeneralConditionFilter>(MAX_PAGINATION_FILTER);

  const { data, refresh, isLoading } = useApi(
    vendorApi.getAllBuyingAgreementGeneralConditions,
    null,
    vendorBuyingAgreement.vendorId,
    vendorBuyingAgreement.id,
    filter,
  );

  const { call: remove } = useApi(vendorApi.deleteBuyingAgreementGeneralCondition, {
    successKey: 'common:success.save',
  });
  const { call: create } = useApi(vendorApi.createBuyingAgreementGeneralCondition, {
    successKey: 'common:success.save',
  });
  const { call: update } = useApi(vendorApi.updateBuyingAgreementGeneralCondition, {
    successKey: 'common:success.save',
  });
  const { call: uploadFiles } = useApi(vendorApi.uploadBuyingAgreementGeneralConditionFiles, null);
  const { call: updateFilesLanguage } = useApi(vendorApi.updateBuyingAgreementGeneralConditionFilesLanguage, null);
  const { call: deleteFiles } = useApi(vendorApi.deleteBuyingAgreementGeneralConditionFiles, null);

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [vendorGeneralCondition, setVendorGeneralCondition] = useState(
    new VendorBuyingAgreementGeneralConditionEntity(),
  );
  const typeOptions = useEnumList(GeneralConditionType, 'vendor:generalCondition.type', true);
  const { enqueueSnackbar } = useSnackbar();
  const { call: download } = useApi(vendorApi.getBuyingAgreementGeneralConditionFile, null);

  const downloadFile = async (entity: GeneralConditionEntity, file: TranslatedFileEntity) => {
    if (vendorBuyingAgreement && entity && file.file?.id) {
      const fileData = await download(
        vendorBuyingAgreement.vendorId,
        vendorBuyingAgreement.id,
        entity.id,
        file.file.id,
      );
      if (fileData) {
        downloadBlob(file.file.name, fileData);
      }
    }
  };

  const onPaginationChange = (pageNumber: number, pageSize: number) =>
    setFilter((prev) => ({ ...prev, pageNumber: prev.pageSize !== pageSize ? 1 : pageNumber, pageSize }));

  const onSortChange = (orderBy: OrderBy[]) =>
    setFilter((prev) => ({ ...prev, orderBy: orderBy.length ? orderBy : undefined }));

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    setFilter((prev) => ({
      ...prev,
      type: (event.target.value as GeneralConditionType) || undefined,
      pageNumber: 1,
    }));
  };

  const saveDrawer = async (entityToSave: VendorBuyingAgreementGeneralConditionEntity) => {
    let updatedEntity = vendorGeneralCondition.id
      ? await update(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, entityToSave)
      : await create(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, entityToSave);

    const filesDiff = vendorGeneralCondition?.getFilesDiff(entityToSave);
    const hasAddedFiles = filesDiff?.added.length;

    if (hasAddedFiles && updatedEntity) {
      enqueueSnackbar(t('common:info.uploading'), { variant: 'info', persist: true });
    }

    if (updatedEntity) {
      if (filesDiff?.removed.length) {
        updatedEntity = await deleteFiles(
          vendorBuyingAgreement.vendorId,
          vendorBuyingAgreement.id,
          updatedEntity.id,
          filesDiff.removed,
        );
      }
      if (filesDiff?.languageModified.length && updatedEntity) {
        updatedEntity = await updateFilesLanguage(
          vendorBuyingAgreement.vendorId,
          vendorBuyingAgreement.id,
          updatedEntity.id,
          filesDiff.languageModified,
        );
      }
      if (hasAddedFiles && updatedEntity) {
        updatedEntity.files = entityToSave.files
          .filter((f) => !f.file?.id)
          .map((f) => VendorBuyingAgreementGeneralConditionEntity.createFromFile(f));
        updatedEntity = await uploadFiles(
          vendorBuyingAgreement.vendorId,
          vendorBuyingAgreement.id,
          updatedEntity.id,
          filesDiff.added,
        );
      }
    }

    enqueueSnackbar(t('common:success.save'), { variant: 'success' });
    await refresh();
    return updatedEntity;
  };

  const cancelDrawer = () => {
    setIsAddOpen(false);
    setVendorGeneralCondition(new VendorBuyingAgreementGeneralConditionEntity());
  };

  const confirmDrawer = () => {
    refresh();
    setIsAddOpen(false);
  };

  const onDeleteConfirm = async (entity: VendorBuyingAgreementGeneralConditionEntity) => {
    await remove(vendorBuyingAgreement.vendorId, vendorBuyingAgreement.id, entity.id);
    refresh();
  };

  const renderSearch = () => (
    <Box sx={style.selectLargeContainer}>
      <Select
        displayEmpty
        options={typeOptions}
        value={filter.type ?? ''}
        onChange={onTypeChange}
        label={t('vendor:generalCondition.search.type')}
      />
    </Box>
  );

  const renderActionsMenuItems = (entity: VendorBuyingAgreementGeneralConditionEntity, onClick: () => void) =>
    !readOnly
      ? [
          <MenuItem
            id="edit"
            key="edit"
            permissions={{ keys: PermissionKey.VendorEditVBAs }}
            onClick={() => {
              setVendorGeneralCondition(entity);
              setIsAddOpen(true);
              onClick();
            }}
          >
            {t('common:edit')}
          </MenuItem>,
          <DeleteMenuItem
            key="delete"
            permissions={{ keys: PermissionKey.VendorEditVBAs }}
            actionSuffix={t('vendor:buyOpportunities.actions.suffix')}
            name={t(`vendor:generalCondition.type.${entity.type}`)}
            entity={entity}
            onDeleteConfirm={onDeleteConfirm}
            onClick={onClick}
          />,
        ]
      : [];

  const renderAddButton = () =>
    !readOnly ? (
      <HasPermissions keys={PermissionKey.VendorEditVBAs}>
        <Button
          variant="contained"
          startIcon={<AddRounded />}
          onClick={() => {
            setVendorGeneralCondition(new VendorBuyingAgreementGeneralConditionEntity());
            setIsAddOpen(true);
          }}
          disabled={globalEditing}
        >
          {t('vendor:generalCondition.actions.add')}
        </Button>
      </HasPermissions>
    ) : undefined;

  const renderDrawer = () => (
    <EditDrawer
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          {vendorGeneralCondition.id ? <EditRounded /> : <AddRounded />}
          <Typography variant="drawerTitle">
            {t(
              vendorGeneralCondition.id
                ? 'vendor:generalCondition.actions.edit'
                : 'vendor:generalCondition.actions.add',
            )}
          </Typography>
        </Stack>
      }
      open={isAddOpen}
      entity={vendorGeneralCondition}
      schema={VendorBuyingAgreementGeneralConditionSchema()}
      onSave={saveDrawer}
      onConfirm={confirmDrawer}
      onCancel={cancelDrawer}
    >
      <VendorBuyingAgreementGeneralConditionForm />
    </EditDrawer>
  );

  const renderTable = () => (
    <VendorGeneralConditionsTable
      hiddenColumns={['period']}
      data={data ?? []}
      translationNamespace="vendor:generalCondition"
      paginationFilter={filter}
      onPaginationChange={onPaginationChange}
      sortColumns={filter.orderBy}
      onSortChange={onSortChange}
      isLoading={isLoading}
      actionMenuItems={!globalEditing ? renderActionsMenuItems : undefined}
      downloadFile={downloadFile}
    />
  );

  return (
    <LayoutPage
      permissions={{ keys: PermissionKey.VendorViewVBAs }}
      title={t('vendor:generalCondition.title')}
      display="Tab"
      rightTitle={renderAddButton()}
    >
      {renderSearch()}
      {renderTable()}
      {renderDrawer()}
    </LayoutPage>
  );
};
