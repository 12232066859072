import { cms as cmsApi } from '../../../api';
import { useApi, useInternationalization } from '../../../hooks';
import { HelpTrainingFilter, ContentStatus } from '../../../types';
import { MIN_PAGINATION_FILTER } from '../../../constants';
import { NewspaperRounded } from '@mui/icons-material';
import { routes } from '../../../routes';
import { CounterWidget } from './CounterWidget';
import { WidgetProps } from './Widget';

const filter: HelpTrainingFilter = {
  status: ContentStatus.Submitted,
  isReadOnly: false,
  ...MIN_PAGINATION_FILTER,
};

export const SubmittedHelpTrainings = (props: WidgetProps) => {
  const { t } = useInternationalization();
  const { data, isLoading } = useApi(cmsApi.getAllHelpTrainings, null, filter);

  return (
    <CounterWidget
      {...props}
      isLoading={isLoading}
      count={data?.totalCount}
      title={t('dashboard:submittedHelpTrainings:title')}
      icon={NewspaperRounded}
      link={routes.Admin.Cms.HelpTrainings.path}
    />
  );
};
