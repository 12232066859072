import React, { Dispatch, SetStateAction } from 'react';
import { MemberEntity } from '../../models';
import { PaginationEntity, MemberFilter } from '../../types';

interface MemberContextType {
  data: PaginationEntity<MemberEntity> | null;
  setData: Dispatch<SetStateAction<PaginationEntity<MemberEntity> | null>>;
  fetchData: () => Promise<void>;
  filter: MemberFilter;
  setFilter: Dispatch<SetStateAction<MemberFilter>>;
  isLoading: boolean;
}

export const MemberContext = React.createContext<MemberContextType>({} as MemberContextType);
