import { PeriodEntity } from '..';
import { GeneralConditionType } from '../../types';
import { TargetedMembershipEntity } from '../TargetedMembership';
import { TranslatedFileEntity } from '../TranslatedFileEntity';

export class GeneralConditionEntity extends PeriodEntity {
  type: GeneralConditionType | null;
  vendorBuyingAgreementId: number | null;
  summary_En: string;
  summary_Fr: string;
  freightPolicy_En: string;
  freightPolicy_Fr: string;
  pickupAllowance_En: string;
  pickupAllowance_Fr: string;
  prepaidAmount_En: string;
  prepaidAmount_Fr: string;
  fobPoint_En: string;
  fobPoint_Fr: string;
  isVisibleToMember: boolean;
  isArchived: boolean;
  vendorNotes: string;
  notes: string;
  memberNotes_En: string;
  memberNotes_Fr: string;
  targetedMembership: TargetedMembershipEntity | null;
  files: TranslatedFileEntity[];

  constructor(entity?: GeneralConditionEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.summary_En = '';
      this.summary_Fr = '';
      this.isVisibleToMember = true;
      this.summary_En = '';
      this.summary_Fr = '';
      this.freightPolicy_En = '';
      this.freightPolicy_Fr = '';
      this.pickupAllowance_En = '';
      this.pickupAllowance_Fr = '';
      this.prepaidAmount_En = '';
      this.prepaidAmount_Fr = '';
      this.fobPoint_En = '';
      this.fobPoint_Fr = '';
      this.vendorNotes = '';
      this.notes = '';
      this.memberNotes_En = '';
      this.memberNotes_Fr = '';
      this.type = null;
      this.targetedMembership = new TargetedMembershipEntity();
      this.files = [];
    }
  }

  getFilesDiff(entity: GeneralConditionEntity) {
    const filesToDelete = this.files?.filter((existingFile) => {
      const replacedFile = entity.files?.find((newFile) => newFile.file?.name === existingFile.file?.name);
      return !replacedFile || replacedFile.file?.id !== existingFile.file?.id;
    });

    const languageModified = entity.files?.filter((newFile) => {
      return this.files?.find(
        (existingFile) =>
          existingFile.file?.id === newFile.file?.id &&
          (!existingFile.languages.every((lang) => newFile.languages.includes(lang)) ||
            existingFile.languages.length !== newFile.languages.length),
      );
    });
    return {
      added: entity.files?.filter((f) => !f.file?.id) ?? [],
      removed: filesToDelete.map((f) => f.file?.id ?? 0) ?? [],
      languageModified: languageModified.length
        ? entity.files.map((f) => ({ id: f.file?.id, languages: f.languages })).filter((f) => !!f.id)
        : [],
    };
  }

  static toFormData(entities: TranslatedFileEntity[], key: string) {
    const formData = new FormData();
    entities.forEach((entity, index) => {
      formData.append(`${key}[${index}].File`, entity.file ?? '');
      entity.languages.forEach((language, i) => {
        formData.append(`${key}[${index}].Languages[${i}]`, language);
      });
    });
    return formData;
  }

  static createFromFile(file: TranslatedFileEntity) {
    const newDocument = new TranslatedFileEntity();
    newDocument.file = file.file;
    newDocument.languages = file.languages;
    return newDocument;
  }
}
