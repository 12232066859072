import { Box, Grid } from '@mui/material';
import { MAX_FILE_SIZE_MB } from '../../../constants';
import { useEntityFormContext, useEnumList, useInternationalization } from '../../../hooks';
import { FileEntity, MemberCommitmentDocumentEntity } from '../../../models';
import { MemberCommitmentDocumentType, Styles } from '../../../types';
import { EntityModifiedDate } from '../../EntityModifiedDate';
import { ControlledAttachment, ControlledInput, ControlledRadioSelect } from '../../Form';
import { PeriodForm } from '../../PeriodForm';

const style: Styles = {
  container: {
    pb: 4,
  },
};

interface MemberCommitmentFormProps {
  downloadFile?: (file: FileEntity) => void;
}

export const MemberCommitmentDocumentForm = ({ downloadFile }: MemberCommitmentFormProps) => {
  const { t } = useInternationalization();
  const { getValues, control, watch } = useEntityFormContext<MemberCommitmentDocumentEntity>();
  const editing = !!watch('id');
  const documentTypeOptions = useEnumList(
    MemberCommitmentDocumentType,
    'member:commitmentDocuments.type',
    false,
    'male',
    false,
  );

  return (
    <Box sx={style.container}>
      <Grid container spacing={3}>
        {editing && (
          <Grid item xs={12}>
            <EntityModifiedDate entity={getValues()} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledInput
            label={t('member:commitmentDocuments.form.description')}
            name="description"
            control={control}
            required
          />
        </Grid>
        <PeriodForm xs={6} optionalExpiryDate />
        <Grid item xs={12}>
          <ControlledRadioSelect
            label={t('member:commitmentDocuments.form.type')}
            options={documentTypeOptions}
            name="type"
            control={control}
            row
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledAttachment
            onFileClick={downloadFile}
            label={t('member:commitmentDocuments.form.file')}
            name="file"
            control={control}
            maxSizeMB={MAX_FILE_SIZE_MB}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            label={t('member:commitmentDocuments.form.notes')}
            name="notes"
            control={control}
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledInput
            label={t('member:commitmentDocuments.form.memberNotes')}
            name="memberNotes"
            control={control}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
