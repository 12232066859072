import {
  PASSWORD_COMPLEXITY_MIN_LENGTH,
  PASSWORD_COMPLEXITY_MIN_LOWERCASE_LETTERS,
  PASSWORD_COMPLEXITY_MIN_NUMBERS,
  PASSWORD_COMPLEXITY_MIN_SPECIAL_CHARACTERS,
  PASSWORD_COMPLEXITY_MIN_UPPERCASE_LETTERS,
  PASSWORD_COMPLEXITY_SPECIAL_CHARACTERS,
} from '../constants';

export const testPasswordComplexity = (password: string) => {
  const testPassword = password.trim();
  return {
    length: testPassword.length >= PASSWORD_COMPLEXITY_MIN_LENGTH,
    specialCharacters:
      testPassword
        .split('')
        .reduce(
          (accumulator, value) =>
            PASSWORD_COMPLEXITY_SPECIAL_CHARACTERS.indexOf(value) == -1 ? accumulator : accumulator + value,
          '',
        ).length >= PASSWORD_COMPLEXITY_MIN_SPECIAL_CHARACTERS,
    numbers: testPassword.replace(/[^0-9]/g, '').length >= PASSWORD_COMPLEXITY_MIN_NUMBERS,
    lowercaseLetters: testPassword.replace(/[^a-z]/g, '').length >= PASSWORD_COMPLEXITY_MIN_LOWERCASE_LETTERS,
    uppercaseLetters: testPassword.replace(/[^A-Z]/g, '').length >= PASSWORD_COMPLEXITY_MIN_UPPERCASE_LETTERS,
    trailingSpace: password.length > 0 && password === password.trim(),
  };
};
