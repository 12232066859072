import React from 'react';
import { VendorEntity, VendorRebateCategoryEntity, VendorRebateCategoryProgramEntity } from '../../models';

interface VendorRebateCategoryProgramDetailContextType {
  vendorId: number;
  vendor: VendorEntity | null;
  vendorRebateCategory: VendorRebateCategoryEntity | null;
  vendorRebateCategoryId: number;
  vendorRebateCategoryProgramId: number;
  vendorRebateCategoryProgram: VendorRebateCategoryProgramEntity | null;
  rebateProgramId: number;
  fetchData: () => Promise<void>;
  isLoading: boolean;
  readOnly: boolean;
}

export const VendorRebateCategoryProgramDetailContext =
  React.createContext<VendorRebateCategoryProgramDetailContextType>({} as VendorRebateCategoryProgramDetailContextType);
