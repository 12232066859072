import { useContext, useEffect, useMemo } from 'react';
import { vendor as vendorApi } from '../../api';
import { EntityFormProvider, VendorChangeRequestContext } from '../../contexts';
import { useApi, useEntityForm } from '../../hooks';
import { VendorTaxInformationEntity } from '../../models';
import { VendorTaxInformationSchema } from '../../schemas';
import { ChangeRequestTrigger } from '../../types';
import { EditDrawerChangeRequest } from '../EditDrawerChangeRequest';
import { TaxInformationForm } from '../Vendor/Financials/TaxInformationForm';
import { ChangeRequestDrawerHeader } from './ChangeRequestDrawerHeader';

export const TaxInformationChangeRequest = () => {
  const { changeRequest: vendorChangeRequest, isAfter } = useContext(VendorChangeRequestContext);
  const { data, isLoading } = useApi(vendorApi.getTaxInformationChangeRequest, null, vendorChangeRequest.id);
  const { call: update } = useApi(vendorApi.approvedOrRejectTaxInformationChangeRequest, {
    successKey: 'common:success.save',
  });
  const entityPlaceholder = useMemo(() => new VendorTaxInformationEntity(), []);

  const { handleSubmit, reset, setValues, isSubmitDisabled, form, handleError } =
    useEntityForm<VendorTaxInformationEntity>(data?.entity ?? entityPlaceholder, VendorTaxInformationSchema(), {
      variant: 'changeRequest',
      readOnly: !isAfter,
    });

  useEffect(() => {
    if (data) {
      if (isAfter) {
        setValues(data?.changeRequest || entityPlaceholder);
      } else {
        reset();
      }
    }
  }, [data, isAfter, setValues, reset, entityPlaceholder, data?.changeRequest]);

  const saveDrawer = (e: VendorTaxInformationEntity, trigger: ChangeRequestTrigger) => {
    return update(vendorChangeRequest.id, trigger, e);
  };

  return (
    <EntityFormProvider {...form} isLoading={isLoading || !data}>
      <EditDrawerChangeRequest
        open={true}
        entity={entityPlaceholder}
        handleSubmit={handleSubmit}
        handleError={handleError}
        isSubmitDisabled={isSubmitDisabled}
        reset={reset}
        onSave={saveDrawer}
      >
        <ChangeRequestDrawerHeader />
        <TaxInformationForm />
      </EditDrawerChangeRequest>
    </EntityFormProvider>
  );
};
