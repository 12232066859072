import { ErrorBoundary, RequireAuth } from '../components';
import { LayoutMemberUser } from '../components/Layout';
import {
  BuyGuide,
  BuyGuideBuyOpportunityDetail,
  BuyGuideBuyOpportunityDetails,
  BuyGuideBuyOpportunityDocuments,
  BuyGuideDetail,
  BuyGuideRebateProgramDetail,
  BuyGuideRebateProgramDocuments,
  BuyGuideRebateProgramRebates,
  BuyGuideRebateProgramTiersGrowths,
  BuyGuideVendorBuyOpportunities,
  BuyGuideVendorContacts,
  BuyGuideVendorDocuments,
  BuyGuideVendorGeneralConditions,
  BuyGuideVendorLocations,
  BuyGuideVendorRebatePrograms,
  Cms,
  CmsEvents,
  Dashboard,
  ForestProduct,
  ForestProductCommitmentDocuments,
  ForestProductPrices,
  ForestProductTradingDashboard,
  HelpDetail,
  HelpLayout,
  Member,
  MemberContacts,
  MemberMemberUsers,
  Members,
  MemberUser,
  MemberUserPermissions,
  ProfileLayout,
  PublicationDetail,
  PublicationLayout,
  Search,
  StaticPageDetail,
  TBMContactInternalContactDetail,
  TBMContactInternalContacts,
  TBMContactOfficeDetail,
  TBMContactOfficeInternalContacts,
  TBMContactOffices,
  TBMContacts,
  Users,
} from '../pages/Member';
import { MemberCommitmentEvent, MemberCommitmentHistory, MemberCommitments } from '../pages/Member/Commitment';
import { UserType } from '../types';

export const MemberRoutes = {
  path: '/member',
  errorElement: <ErrorBoundary />,
  element: (
    <RequireAuth userType={UserType.Member}>
      <LayoutMemberUser />
    </RequireAuth>
  ),
  Dashboard: {
    path: 'dashboard',
    element: <Dashboard />,
  },
  BuyGuide: {
    path: 'buy-guide',
    element: <BuyGuide />,
  },
  BuyGuideDetail: {
    path: 'buy-guide/:vendorId',
    element: <BuyGuideDetail />,
    Contacts: {
      path: 'contacts',
      element: <BuyGuideVendorContacts />,
    },
    Locations: {
      path: 'locations',
      element: <BuyGuideVendorLocations />,
    },
    GeneralConditions: {
      path: 'general-conditions',
      element: <BuyGuideVendorGeneralConditions />,
    },
    Documents: {
      path: 'documents',
      element: <BuyGuideVendorDocuments />,
    },
    RebatePrograms: {
      path: 'rebate-programs',
      element: <BuyGuideVendorRebatePrograms />,
    },
    BuyOpportunities: {
      path: 'buy-opportunities',
      element: <BuyGuideVendorBuyOpportunities />,
    },
  },
  BuyGuideRebateProgramDetail: {
    path: 'buy-guide/:vendorId/rebate-programs/:rebateProgramId',
    element: <BuyGuideRebateProgramDetail />,
    Rebates: {
      path: 'rebates',
      element: <BuyGuideRebateProgramRebates />,
    },
    TiersAndGrowths: {
      path: 'tiers-growths',
      element: <BuyGuideRebateProgramTiersGrowths />,
    },
    Documents: {
      path: 'documents',
      element: <BuyGuideRebateProgramDocuments />,
    },
  },
  Events: {
    path: 'events',
    element: <CmsEvents />,
  },
  BuyGuideBuyOpportunityDetail: {
    path: 'buy-guide/:vendorId/buy-opportunity/:vendorBuyOpportunityId',
    element: <BuyGuideBuyOpportunityDetail />,
    Documents: {
      path: 'documents',
      element: <BuyGuideBuyOpportunityDocuments />,
    },
    Details: {
      path: 'details',
      element: <BuyGuideBuyOpportunityDetails />,
    },
  },
  Member: {
    path: 'members',
    element: <Members />,
    Detail: {
      path: ':memberId',
      element: <Member />,
      MemberUsers: {
        path: 'users',
        element: <MemberMemberUsers />,
      },
      Contacts: {
        path: 'contacts',
        element: <MemberContacts />,
      },
    },
  },
  User: {
    path: 'users',
    element: <Users />,
    Detail: {
      path: ':userId',
      element: <MemberUser />,
      Permissions: {
        path: 'permissions',
        element: <MemberUserPermissions />,
      },
    },
  },
  TBMContacts: {
    path: 'tbm-contacts',
    element: <TBMContacts />,
    InternalContacts: {
      path: 'contacts',
      element: <TBMContactInternalContacts />,
    },
    Offices: {
      path: 'offices',
      element: <TBMContactOffices />,
    },
  },
  OfficeDetails: {
    path: 'tbm-contacts/offices/:officeId',
    element: <TBMContactOfficeDetail />,
    InternalContacts: {
      path: 'contacts',
      element: <TBMContactOfficeInternalContacts />,
    },
  },
  InternalContactDetails: {
    path: 'tbm-contacts/contacts/:contactId',
    element: <TBMContactInternalContactDetail />,
  },
  Profile: {
    path: 'profile',
    element: <ProfileLayout />,
  },
  Help: {
    path: 'help',
    element: <HelpLayout />,
    Detail: {
      path: ':helpTrainingId',
      element: <HelpDetail />,
    },
  },
  Cms: {
    path: 'content-publications',
    element: <Cms />,
    Publications: {
      path: 'publications',
      element: <PublicationLayout />,
    },
  },
  CommitmentEventDetails: {
    path: 'cci',
    element: <MemberCommitmentEvent />,
    Commitments: {
      path: 'commitments',
      element: <MemberCommitments />,
    },
    History: {
      path: 'history',
      element: <MemberCommitmentHistory />,
    },
  },
  PublicationDetails: {
    path: 'publications/:publicationId',
    element: <PublicationDetail />,
  },
  StaticPageDetails: {
    path: 'static-pages/:staticPageId',
    element: <StaticPageDetail />,
  },
  ForestProducts: {
    path: 'forest-products',
    element: <ForestProduct />,
    TradingDashboard: {
      path: 'trading-dashboard',
      element: <ForestProductTradingDashboard />,
    },
    Prices: {
      path: 'prices',
      element: <ForestProductPrices />,
    },
    Commitments: {
      path: 'commitments',
      element: <ForestProductCommitmentDocuments />,
    },
  },
  Search: {
    path: 'search',
    element: <Search />,
  },
};
