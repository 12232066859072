import { Grid, RegularBreakpoints } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi, useEntityFormContext, useInternationalization } from '../../../hooks';
import { VendorBuyingAgreementEntity } from '../../../models';
import { FilterPeriod } from '../../../types';
import { ControlledSelect } from '../../Form';

interface VendorBuyingAgreementSelectProps extends RegularBreakpoints {
  vendorId: number;
  filter?: (value: VendorBuyingAgreementEntity) => boolean;
  onChange?: (value?: VendorBuyingAgreementEntity) => void;
}

const buyingAgreementFilter = {
  ...MAX_PAGINATION_FILTER,
  period: FilterPeriod.All,
};

export const VendorBuyingAgreementSelect = ({
  vendorId,
  filter,
  onChange,
  ...props
}: VendorBuyingAgreementSelectProps) => {
  const { t } = useInternationalization();
  const { data: buyingAgreements } = useApi(
    vendorApi.getAllVendorBuyingAgreements,
    null,
    vendorId,
    buyingAgreementFilter,
  );
  const { setValue, control, watch } = useEntityFormContext<{ vendorBuyingAgreementId: number | null }>();
  const [isCurrentValuesSetted, setIsCurrentValuesSetted] = useState(false);

  const currentVendorBuyingAgreementId = watch('vendorBuyingAgreementId');

  const vbasOptions = useMemo(
    () =>
      buyingAgreements?.data
        .filter(filter ?? (() => true))
        .filter((vba) => !vba.isArchived || vba.id == currentVendorBuyingAgreementId)
        .map((vba) => ({ label: vba.name, value: vba.id })),
    [buyingAgreements, filter, currentVendorBuyingAgreementId],
  );

  useEffect(() => {
    if (onChange && !isCurrentValuesSetted) {
      const current = buyingAgreements?.data.find((vba) => vba.id == currentVendorBuyingAgreementId);
      setIsCurrentValuesSetted(true);
      onChange(current);
    }
  }, [onChange, currentVendorBuyingAgreementId, buyingAgreements, isCurrentValuesSetted]);

  return (
    <Grid item {...props}>
      <ControlledSelect
        onClear={() => setValue('vendorBuyingAgreementId', null, { shouldDirty: true, shouldValidate: true })}
        label={t('vendor:buyingAgreements.form.associated')}
        name="vendorBuyingAgreementId"
        control={control}
        renderValue={(id) => vbasOptions?.find((u) => u.value === id)?.label}
        options={vbasOptions}
        required
      />
    </Grid>
  );
};
