import { ChangeRequestState, ChangeRequestType, MemberChangeRequestEntityType } from '../../types';
import { EditableEntity } from '../EditableEntity';
import { MemberEntity } from './MemberEntity';

export class MemberChangeRequestEntity extends EditableEntity {
  member: MemberEntity;
  state: ChangeRequestState;
  type: ChangeRequestType;
  entityType: MemberChangeRequestEntityType;
  reviewedDate: Date | null;
  reviewedBy: string | null;

  constructor(entity?: MemberChangeRequestEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    }
  }

  get isDeleted() {
    return this.type === ChangeRequestType.Archive;
  }
}
