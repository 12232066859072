import React from 'react';
import { FieldValues, FormProviderProps } from 'react-hook-form';
import { Loading } from '../components';
import { EntityFormContextType, EntityFormOptions } from '../types';

export const EntityFormContext = React.createContext<EntityFormContextType | null>(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EntityFormProvider = <TFieldValues extends FieldValues, TContext = any>(
  props: FormProviderProps<TFieldValues, TContext> & EntityFormOptions,
) => {
  const { children, isLoading, ...data } = props;

  return isLoading ? (
    <Loading />
  ) : (
    <EntityFormContext.Provider value={data as unknown as EntityFormContextType}>{children}</EntityFormContext.Provider>
  );
};
