import { useCallback, useEffect, useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { useApi, useEntityFormContext, useInternationalization } from '../../../hooks';
import { RebateCategoryEntity, VendorTierGrowthEntity } from '../../../models';
import { GrowthOrTierOption, Styles } from '../../../types';
import { VendorTiersGrowthsSelect } from '../TiersGrowths';
import { Table, TableColumn } from '../../Table';
import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';

const style: Styles = {
  header: {
    py: 3,
  },
};

interface VendorRebateCategoryDuplicateTierGrowthProps {
  vendorId: number;
  rebateCategoryId: number;
}

const tiersAndGrowthToGrowthOrTierOption = (
  tiersOrGrowths: VendorTierGrowthEntity[] | null,
  type: GrowthOrTierOption['type'],
): GrowthOrTierOption[] => {
  return (
    tiersOrGrowths?.map((i) => ({
      name_En: i.name_En,
      name_Fr: i.name_Fr,
      type: type,
      id: i.id,
    })) ?? []
  );
};

export const VendorRebateCategoryDuplicateTierGrowth = ({
  vendorId,
  rebateCategoryId,
}: VendorRebateCategoryDuplicateTierGrowthProps) => {
  const { t, getTranslation } = useInternationalization();
  const { setValue } = useEntityFormContext<RebateCategoryEntity>();
  const { data: growths, isLoading: isGrowthLoading } = useApi(
    vendorApi.getAllRebateCategoryGrowth,
    null,
    vendorId,
    rebateCategoryId,
  );
  const { data: tiers, isLoading: isTierLoading } = useApi(
    vendorApi.getAllRebateCategoryTier,
    null,
    vendorId,
    rebateCategoryId,
  );

  const [selectedTiersGrowths, setSelectedTiersGrowths] = useState<GrowthOrTierOption[]>([]);

  const setTierOrGrowthInForm = useCallback(() => {
    setValue('tierIds', selectedTiersGrowths.filter((x) => x.type === 'tier').map((t) => t.id) ?? []);
    setValue('growthIds', selectedTiersGrowths.filter((x) => x.type === 'growth').map((g) => g.id) ?? []);
  }, [selectedTiersGrowths, setValue]);

  useEffect(() => {
    if (!isTierLoading && !isGrowthLoading) {
      setSelectedTiersGrowths([
        ...tiersAndGrowthToGrowthOrTierOption(tiers, 'tier'),
        ...tiersAndGrowthToGrowthOrTierOption(growths, 'growth'),
      ]);
    }
  }, [growths, isGrowthLoading, isTierLoading, tiers]);

  const onAssociateTierOrGrowth = async (item: GrowthOrTierOption) => {
    setSelectedTiersGrowths((prev) => [...prev, { ...item, id: item.id }]);
    setTierOrGrowthInForm();
  };

  const onTierGrowthDelete = (item: GrowthOrTierOption) => {
    setSelectedTiersGrowths((prev) => prev.filter((x) => x.id !== item.id));
    setTierOrGrowthInForm();
  };

  useEffect(() => {
    setTierOrGrowthInForm();
  }, [selectedTiersGrowths, setTierOrGrowthInForm]);

  return (
    <>
      <Typography variant="h3" sx={style.header}>
        {t('vendor:rebateCategory.sections.tierAndGrowth')}
      </Typography>

      <VendorTiersGrowthsSelect
        currentGrowths={selectedTiersGrowths.filter((x) => x.type === 'growth')}
        currentTiers={selectedTiersGrowths.filter((x) => x.type === 'tier')}
        onAssociateTierOrGrowth={onAssociateTierOrGrowth}
        vendorId={vendorId}
        fullWidth
      />

      <Table data={selectedTiersGrowths} translationNamespace={'vendor:rebateCategory.tierAndGrowth.duplicate'}>
        <TableColumn type="custom" id="name" render={(item: GrowthOrTierOption) => getTranslation(item, 'name')} />
        <TableColumn
          type="custom"
          id="type"
          render={(item: GrowthOrTierOption) => t(`vendor:rebateCategory.tierAndGrowth.actions.suffix.${item.type}`)}
        />
        <TableColumn
          type="button"
          id="delete"
          align="center"
          tooltip
          render={() => t('common:remove')}
          iconComponent={Delete}
          onClick={onTierGrowthDelete}
        />
      </Table>
    </>
  );
};
