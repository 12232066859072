import { Typography, alpha } from '@mui/material';
import { useInternationalization } from '../../hooks';
import { palette } from '../../styles/palette';
import { ContentStatus, Styles, TranslationContext } from '../../types';

interface EntityPeriodProps {
  status: ContentStatus;
  translationContext?: TranslationContext;
}

const styles: Styles = {
  [ContentStatus.InProgress]: {
    background: alpha(palette.secondary.blue, 0.15),
    color: palette.secondary.blue,
  },
  [ContentStatus.Submitted]: {
    background: alpha(palette.secondary.orange, 0.15),
    color: palette.secondary.orange,
  },
  [ContentStatus.Approved]: {
    background: alpha(palette.secondary.green, 0.15),
    color: palette.secondary.green,
  },
  [ContentStatus.Rejected]: {
    background: alpha(palette.secondary.red, 0.15),
    color: palette.secondary.red,
  },
};

export function CmsContentStatus({ status, translationContext }: EntityPeriodProps) {
  const { t } = useInternationalization();

  return (
    <Typography variant="status" sx={styles[status]}>
      {t(`cms:status.${status}`, { context: translationContext })}
    </Typography>
  );
}
