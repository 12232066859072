export class FileEntity extends File {
  id?: number;
  uploadedDate?: Date;
  uploadedBy?: string;
  size: number;

  constructor(entity?: FileEntity) {
    super([], '');
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.id = 0;
      this.uploadedBy = '';
      this.uploadedDate = new Date();
    }
  }
}
