import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { vendor as vendorApi } from '../../../api';
import { MAX_PAGINATION_FILTER } from '../../../constants';
import { useApi } from '../../../hooks';
import { VendorBuyingAgreementEntity } from '../../../models/Vendor/VendorBuyingAgreementEntity';
import { routes } from '../../../routes';
import { FilterPeriod, VendorBuyingAgreementFilter } from '../../../types';
import { VendorBuyingAgreementsList } from './VendorBuyingAgreementsList';

const defaultFilter = {
  period: FilterPeriod.All,
  isArchived: false,
};

export const VendorBuyingAgreementsListVendor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<VendorBuyingAgreementFilter>({ ...MAX_PAGINATION_FILTER, ...defaultFilter });
  const getCurrentBuyingAgreements = useApi(vendorApi.getCurrentBuyingAgreements, null, filter);
  const onSave = async (entity: VendorBuyingAgreementEntity) => entity;

  const renderActionsMenuItems = (entity: VendorBuyingAgreementEntity) => [
    <MenuItem
      id="view"
      key="view"
      onClick={() => {
        navigate(
          generatePath(routes.Vendor.BuyingAgreement.path, {
            vendorId: entity.vendorId,
            vendorBuyingAgreementId: entity.id,
          }),
        );
      }}
    >
      {t('common:view')}
    </MenuItem>,
  ];

  return (
    <VendorBuyingAgreementsList
      layout="Page"
      showSearch
      addButtonProps={{
        disabled: true,
      }}
      filter={filter}
      setFilter={setFilter}
      fetchApi={getCurrentBuyingAgreements}
      onSave={onSave}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
      }}
      showAddButton={false}
      redirectPath={(entity) =>
        generatePath(routes.Vendor.BuyingAgreement.path, {
          vendorId: entity.vendorId,
          vendorBuyingAgreementId: entity.id,
        })
      }
    />
  );
};
