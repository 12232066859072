import { useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useInternationalization } from '../../../hooks';
import { VendorLocationEntity } from '../../../models';
import { PaginationFilter } from '../../../types';
import { MenuItem } from '../../Menu';
import { VendorLocationsList } from './VendorLocationsList';

export const VendorLocationsListVendor = () => {
  const { t } = useInternationalization();
  const [filter, setFilter] = useState<PaginationFilter>(DEFAULT_PAGINATION_FILTER);

  const getCurrentLocationsApi = useApi(vendorApi.getCurrentLocations, null, filter);
  const { call: createRequest } = useApi(vendorApi.createLocationChangeRequest, { successKey: 'common:success.save' });
  const { call: updateRequest } = useApi(vendorApi.updateLocationChangeRequest, { successKey: 'common:success.save' });
  const { call: removeRequest } = useApi(vendorApi.removeLocationChangeRequest, { successKey: 'common:success.save' });

  const onDelete = async (entity: VendorLocationEntity) => {
    await removeRequest(entity.id);
    await getCurrentLocationsApi.refresh();
  };

  const onSave = async (entity: VendorLocationEntity) => {
    return entity.id ? await updateRequest(entity) : await createRequest(entity);
  };

  const renderActionsMenuItems = (entity: VendorLocationEntity, onClick: () => void) => [
    <MenuItem
      id="remove"
      key="remove"
      onClick={() => {
        onDelete(entity);
        onClick();
      }}
    >
      {t('common:changeRequest.remove')}
    </MenuItem>,
  ];

  return (
    <VendorLocationsList
      isChangeRequest
      layout="Page"
      filter={filter}
      setFilter={setFilter}
      fetchApi={getCurrentLocationsApi}
      onSave={onSave}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
      }}
    />
  );
};
