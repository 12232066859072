import { AddRounded, DownloadRounded } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet, useSearchParams } from 'react-router-dom';
import { vendor as vendorApi } from '../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../constants';
import { VendorContext } from '../../contexts';
import { useApi, usePageTitle } from '../../hooks';
import { VendorEntity } from '../../models';
import { routes } from '../../routes';
import { VendorSchema } from '../../schemas';
import { PermissionKey, VendorFilter } from '../../types';
import { EditDrawer } from '../EditDrawer';
import { LayoutPage } from '../Layout';
import { HasPermissions } from '../Permission';
import { VendorForm } from './VendorForm';
import { VendorListSearch } from './VendorListSearch';
import { VendorListView } from './VendorListView';
import { downloadBlob } from '../../utils/helper';

export const VendorLayout = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState<VendorFilter>({
    isArchived: false,
    mailSyncErrorOnly: !!searchParams.get('mailSyncErrorOnly'),
    ...DEFAULT_PAGINATION_FILTER,
  });
  const { refresh, isLoading, data, setData } = useApi(vendorApi.getAll, { skipFetch: !!outlet }, filter);
  const { call } = useApi(vendorApi.create, { successKey: 'common:success.save' });
  const { call: download } = useApi(vendorApi.exportAllVendorsContacts, null);
  const [vendor, setVendor] = useState<VendorEntity>(new VendorEntity());

  usePageTitle('vendor:title');

  useEffect(() => {
    if (!filter.mailSyncErrorOnly) {
      setSearchParams(undefined);
    }
  }, [filter.mailSyncErrorOnly, setSearchParams]);

  const onDownload = async () => {
    const fileData = await download();
    if (fileData) {
      downloadBlob(fileData.filename ?? 'export.csv', fileData.data);
    }
  };

  const renderAddButton = () => (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
      <HasPermissions keys={PermissionKey.VendorEditInfo}>
        <Button startIcon={<AddRounded />} onClick={() => setIsAddOpen(true)} variant="contained">
          {t('vendor:add')}
        </Button>
      </HasPermissions>
      <HasPermissions keys={PermissionKey.VendorViewContacts}>
        <Button startIcon={<DownloadRounded />} onClick={onDownload} variant="outlined">
          {t('vendor:export')}
        </Button>
      </HasPermissions>
    </Stack>
  );

  if (outlet) {
    return outlet;
  }

  return (
    <VendorContext.Provider value={{ data, setData, isLoading, fetchData: refresh, filter, setFilter }}>
      <LayoutPage title={t('vendor:title')} rightTitle={renderAddButton()}>
        <VendorListSearch />
        <VendorListView />
        <EditDrawer
          title={
            <Stack direction="row" spacing={1} alignItems="center">
              <AddRounded />
              <Typography variant="drawerTitle">{t(`vendor:add`)}</Typography>
            </Stack>
          }
          open={isAddOpen}
          entity={vendor}
          schema={VendorSchema()}
          redirectPath={(entity) => generatePath(routes.Admin.Vendor.Detail.path, { vendorId: entity.id })}
          onSave={call}
          onConfirm={() => {
            refresh();
            setIsAddOpen(false);
            setVendor(new VendorEntity());
          }}
          onCancel={() => setIsAddOpen(false)}
          redirectLabel={t('vendor:saveOpenVendor')}
        >
          <VendorForm />
        </EditDrawer>
      </LayoutPage>
    </VendorContext.Provider>
  );
};
