import { InsertDriveFileRounded, ModeCommentRounded, VisibilityRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { EntityPeriod } from '../..';
import { useAuth, useInternationalization } from '../../../hooks';
import { BuyOpportunityEntity } from '../../../models/Vendor/BuyOpportunityEntity';
import { VendorBuyOpportunityEntity } from '../../../models/Vendor/VendorBuyOpportunityEntity';
import { palette } from '../../../styles/palette';
import { OpportunityType } from '../../../types';
import { formatLongDate } from '../../../utils/helper';
import { CardList, CardListElement, CardListRow } from '../../Card';
import { CardListProps } from '../../Card/CardList';

export const VendorBuyOpportunitiesCardList = <T extends BuyOpportunityEntity>(props: CardListProps<T>) => {
  const { isInternalUser, isMemberUser } = useAuth();
  const { t, getTranslation } = useInternationalization();

  const renderNotes = (entity: BuyOpportunityEntity) => {
    let notes = '';
    if (isInternalUser && entity.notes) {
      notes = `${t('vendor:buyOpportunities.form.notes')}: ${entity.notes}\n`;
    }
    if (entity.vendorNotes) {
      notes += `${
        isInternalUser ? t('vendor:buyOpportunities.form.vendorNotes') : t('vendor:buyOpportunities.form.comments')
      }: ${entity.vendorNotes}\n`;
    }
    if (getTranslation(entity, 'memberNotes')) {
      notes += !isMemberUser ? `${t('vendor:buyOpportunities.form.memberNotes')}: ` : '';
      notes += getTranslation(entity, 'memberNotes');
    }
    return notes;
  };

  return (
    <CardList {...props}>
      <CardListRow noUnderline>
        <CardListElement type="title" id="name" translated hideLabel />
        <CardListElement
          type="custom"
          id="opportunity"
          hideLabel
          render={(vbo: VendorBuyOpportunityEntity) => (
            <Typography variant="tag">{t(`vendor:buyOpportunities.opportunity.${vbo.opportunityType}`)}</Typography>
          )}
        />
        <CardListElement
          type="custom"
          id="isVisibleToMember"
          hideLabel
          render={(vbo: VendorBuyOpportunityEntity) =>
            vbo.isVisibleToMember &&
            isInternalUser && <VisibilityRounded fontSize="small" htmlColor={palette.grey[500]} />
          }
        />
        <CardListElement
          type="custom"
          id="period"
          hideLabel
          render={(vbo: VendorBuyOpportunityEntity) => (
            <EntityPeriod period={vbo.period} isArchived={vbo.isArchived} translationContext="female" />
          )}
        />
        <CardListElement
          type="custom"
          id="files"
          hideLabel
          decorator
          render={(vbo: VendorBuyOpportunityEntity) =>
            vbo.filesCount > 0 && (
              <Stack direction="row" spacing={0.5}>
                <InsertDriveFileRounded fontSize="small" htmlColor={palette.primary.deep} />
                <Typography variant="label" color={palette.primary.deep}>
                  {vbo.filesCount}
                </Typography>
              </Stack>
            )
          }
        />
        <CardListElement
          type="icon"
          id="notes"
          tooltip
          hideLabel
          decorator
          iconComponent={ModeCommentRounded}
          render={renderNotes}
        />
      </CardListRow>
      <CardListRow>
        <CardListElement
          type="custom"
          id="responsibleBuyer"
          render={(vbo: BuyOpportunityEntity) => vbo.responsibleBuyer}
        />
        <CardListElement
          type="custom"
          id="participatingDCs"
          hideIf={(vbo: BuyOpportunityEntity) => vbo.opportunityType !== OpportunityType.PoolBuy}
          render={(vbo: VendorBuyOpportunityEntity) => vbo.participatingDCs.map((p) => p.name).join(', ')}
        />
        <CardListElement
          type="custom"
          id="effective"
          render={(vbo: BuyOpportunityEntity) => formatLongDate(vbo.effectiveDate)}
        />
        <CardListElement
          type="custom"
          id="expiry"
          render={(vbo: BuyOpportunityEntity) => formatLongDate(vbo.expiryDate)}
        />
        <CardListElement
          type="custom"
          id="shippingDate"
          render={(vbo: BuyOpportunityEntity) => getTranslation(vbo, 'shippingDate')}
        />
        <CardListElement
          type="custom"
          id="minOrder"
          render={(vbo: BuyOpportunityEntity) => getTranslation(vbo, 'minimumOrder')}
        />
        <CardListElement
          type="custom"
          id="minOrderQuantity"
          render={(vbo: BuyOpportunityEntity) => getTranslation(vbo, 'minimumOrderQuantity')}
        />
      </CardListRow>
      <CardListRow>
        <CardListElement
          type="custom"
          id="termValue"
          render={(vbo: BuyOpportunityEntity) => getTranslation(vbo, 'terms')}
        />
        <CardListElement
          type="custom"
          id="minFreightPrepaid"
          render={(vbo: BuyOpportunityEntity) => getTranslation(vbo, 'minimumFreightPrepaid')}
        />
        <CardListElement type="custom" id="foid" render={(vbo: BuyOpportunityEntity) => getTranslation(vbo, 'foid')} />
        <CardListElement
          type="custom"
          id="paymentTermDiscount"
          render={(vbo: BuyOpportunityEntity) => getTranslation(vbo, 'paymentTermDiscount')}
        />
      </CardListRow>
    </CardList>
  );
};
