import { useTranslation } from 'react-i18next';
import { generatePath, useOutlet } from 'react-router-dom';
import { routes } from '../../../routes';
import { LayoutPage } from '../../Layout';
import { LinkTab } from '../../Tab';

export const VendorCommitmentsLayout = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();

  const tabs = [
    <LinkTab label={t('cci:sections.commitments')} value={generatePath(routes.Vendor.Cci.Commitments.path)} />,
  ];

  return <LayoutPage display="Page" title={t('cci:title')} outlet={outlet} tabs={tabs} />;
};
