import { useParams } from 'react-router-dom';
import { cms as cmsApi } from '../../../api';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import { HelpTrainingEntity } from '../../../models';
import { routes } from '../../../routes';
import { CmsContent } from '../CmsContent';

export const CmsHelpTrainingView = () => {
  const { isMemberUser, isVendorUser } = useAuth();
  const { t, getTranslation } = useInternationalization();
  const params = useParams();
  const helpTrainingId = Number(params.helpTrainingId);
  const { data, isLoading } = useApi(cmsApi.getHelpTraining, null, helpTrainingId);

  return (
    <CmsContent
      display="Page"
      isLoading={isLoading}
      contentEntity={data ?? new HelpTrainingEntity()}
      downloadApi={cmsApi.getHelpTrainingFile}
      downloadBlockFileApi={cmsApi.getHelpTrainingBlockFile}
      breadcrumbs={[
        {
          title: t('cms:helpTrainings.title'),
          href: isMemberUser
            ? routes.Member.Help.path
            : isVendorUser
            ? routes.Vendor.Help.path
            : routes.Admin.Help.path,
        },
        { title: data ? getTranslation(data, 'name') : '' },
      ]}
    />
  );
};
