import { DeleteRounded } from '@mui/icons-material';
import {
  Button,
  FormHelperText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { member as memberApi } from '../../api';
import { useAuth, useEntityFormContext, useInternationalization } from '../../hooks';
import { MemberEntity, UserEntity } from '../../models';
import { Styles } from '../../types';
import { Autocomplete } from '../Form';

const style: Styles = {
  tableCell: {
    px: 1,
    py: 2,
    verticalAlign: 'center',
    '&:first-of-type': {
      pl: 0,
    },
    '&:last-child': {
      pr: 0,
    },
  },
  actionTableCell: {
    py: 0,
  },
};

export const UserMemberList = () => {
  const { t } = useInternationalization();
  const { isMemberUser } = useAuth();
  const [searchText, setSearchText] = useState('');
  const [selectedMember, setSelectedMember] = useState<MemberEntity | null>(null);
  const { getFieldState, readOnly, setValue, watch } = useEntityFormContext<UserEntity>();
  const members = watch('members');
  const memberIds = watch('memberIds');
  const memberIdsFieldState = getFieldState('memberIds');

  const addMember = () => {
    if (selectedMember && canAddMember(selectedMember.id)) {
      setValue('members', members?.concat([selectedMember]), { shouldTouch: true });
      setValue('memberIds', memberIds?.concat([selectedMember.id]), { shouldTouch: true, shouldValidate: true });
      setSelectedMember(null);
      setSearchText('');
    }
  };

  const removeMember = (id: number) => {
    setValue(
      'members',
      members?.filter((x) => x.id !== id),
      { shouldTouch: true },
    );
    setValue(
      'memberIds',
      memberIds?.filter((x) => x !== id),
      { shouldTouch: true, shouldValidate: true },
    );
  };

  const canAddMember = (id: number) => {
    return !memberIds?.includes(id);
  };

  const renderPermissionTable = () => (
    <TableContainer>
      <Table sx={style.table}>
        <TableHead>
          <TableRow>
            <TableCell width="25%">{t('member:table.number')}</TableCell>
            <TableCell width="75%">{t('member:table.name')}</TableCell>
            {!readOnly && <TableCell sx={{ ...style.tableCell, ...style.actionTableCell }} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {members?.map((item) => (
            <TableRow key={item.id}>
              <TableCell sx={style.tableCell}>{item.fullNumber}</TableCell>
              <TableCell sx={style.tableCell}>{item.name}</TableCell>
              {!readOnly && (
                <TableCell sx={{ ...style.tableCell, ...style.actionTableCell }}>
                  <IconButton onClick={() => removeMember(item.id)} disabled={!item.isEditable}>
                    <DeleteRounded />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderEmptyPlaceholder = () => <Typography variant="valuePlaceholder">{t('common:notSpecified')}</Typography>;

  const renderFooterAction = () => (
    <Stack direction="row" spacing={2}>
      <Autocomplete
        minSearchTextLength={1}
        value={searchText}
        onValueChange={(value) => setSearchText(value)}
        noOptionsText={t('user:form.search.members.notFound')}
        apiFunction={memberApi.getSuggestions}
        apiExtraParams={[false, isMemberUser || undefined]}
        placeholder={t('user:form.search.members.placeholder')}
        disableOption={(i) => !canAddMember(i.id)}
        getOptionLabel={(item) => (typeof item === 'string' ? item : `${item.fullNumber} - ${item.name}`)}
        onApplySearch={(text) => {
          setSearchText(text);
          if (!text) {
            setSelectedMember(null);
          }
        }}
        onSelectItem={(item) => setSelectedMember(item)}
        hideLabel
      />

      <Button size="small" disabled={!selectedMember} variant="contained" onClick={addMember}>
        {t('common:add')}
      </Button>
    </Stack>
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h3">{t('user:form.members')} *</Typography>
      {readOnly && (!members || !members.length) ? renderEmptyPlaceholder() : renderPermissionTable()}
      {!readOnly && renderFooterAction()}
      <FormHelperText error={!!memberIdsFieldState.error}>
        {memberIdsFieldState.error?.message && t(memberIdsFieldState.error?.message, { name: t('user:form.members') })}
      </FormHelperText>
    </Stack>
  );
};
