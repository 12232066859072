import { useTranslation } from 'react-i18next';
import { vendor as vendorApi } from '../../../api';
import { useApi, useAuth } from '../../../hooks';
import { VendorInvoicingInformationSchema } from '../../../schemas/Vendor';
import { PermissionKey } from '../../../types';
import { EditCardSkeleton } from '../../Card';
import { InvoicingInformationForm } from './InvoicingInformationForm';

export const InvoicingInformationVendor = () => {
  const { hasPermissions } = useAuth();
  const { t } = useTranslation();
  const { data, isLoading, refresh } = useApi(vendorApi.getCurrentInvoicingInformation, { callImmediately: true });
  const { call } = useApi(vendorApi.updateInvoicingInformationChangeRequest, { successKey: 'common:success.save' });

  return (
    <EditCardSkeleton
      readOnly={!hasPermissions(PermissionKey.VendorEditFinancial)}
      entity={data}
      isLoading={isLoading}
      title={t('vendor:financials.sections.invoicingInformation')}
      schema={VendorInvoicingInformationSchema()}
      onSave={call}
      onConfirm={() => refresh()}
      showModifiedDate
      isChangeRequest
    >
      <InvoicingInformationForm />
    </EditCardSkeleton>
  );
};
