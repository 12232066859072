import { CircularProgress } from '@mui/material';
import { generatePath, useOutlet, useParams } from 'react-router-dom';
import { contact as contactApi } from '../../../api';
import { OfficeDetailContext } from '../../../contexts';
import { useApi, useAuth, useInternationalization, usePageTitle } from '../../../hooks';
import { OfficeEntity } from '../../../models';
import { routes } from '../../../routes';
import { OfficeSchema } from '../../../schemas';
import { LanguageCode, PermissionKey, Styles } from '../../../types';
import { ArchiveBanner } from '../../ArchiveBanner';
import { Container } from '../../Container';
import { EditDetails } from '../../EditDetails';
import { LayoutPage } from '../../Layout';
import { ArchiveMenuItem } from '../../Menu';
import { LinkTab } from '../../Tab';
import { OfficeForm } from './OfficeForm';
import { OfficeHeader } from './OfficeHeader';

const style: Styles = {
  title: {
    pb: 4,
  },
  loading: {
    mx: 2,
  },
};

export const OfficeDetail = () => {
  const { t, getTranslation, currentLanguage } = useInternationalization();
  const { isInternalUser, isMemberUser } = useAuth();
  const outlet = useOutlet();
  const params = useParams();
  const officeId = Number(params.officeId);
  const { data, isLoading, refresh } = useApi(contactApi.getOffice, null, officeId);
  const { call } = useApi(contactApi.updateOffice, { successKey: 'common:success.save' });
  usePageTitle(data ? getTranslation(data, 'description') : '');

  const archiveApi = useApi(contactApi.archiveOffice, { successKey: 'common:success.action' });

  const onArchiveConfirm = async (isArchived: boolean, entity: OfficeEntity) => {
    await archiveApi.call([entity.id], isArchived);
    refresh();
  };

  const renderActionMenuItems = (onClick: () => void) =>
    data && isInternalUser
      ? [
          <ArchiveMenuItem
            key="archive"
            entity={data}
            name={getTranslation(data, 'description')}
            onClick={onClick}
            onArchiveConfirm={onArchiveConfirm}
            actionSuffix={t('contact:office.actions.suffix')}
          />,
        ]
      : [];

  const tabs = [
    <LinkTab
      label={t('contact:sections.internalContacts')}
      value={generatePath(
        isInternalUser
          ? routes.Admin.OfficeDetails.InternalContacts.path
          : isMemberUser
          ? routes.Member.OfficeDetails.InternalContacts.path
          : routes.Vendor.OfficeDetails.InternalContacts.path,
        { officeId },
      )}
    />,
  ];

  return (
    <OfficeDetailContext.Provider value={{ office: data, officeId }}>
      <LayoutPage display="Detail" outlet={outlet} tabs={tabs}>
        <ArchiveBanner
          permissions={{ keys: PermissionKey.AdminManageContacts }}
          entity={data}
          onUnarchive={() => data && onArchiveConfirm(false, data)}
        />
        <Container>
          {isLoading && <CircularProgress size={20} sx={style.loading} />}
          {data && (
            <EditDetails
              permissions={{ keys: PermissionKey.AdminManageContacts }}
              titleLabel={t(`contact:office.form.name`)}
              title={currentLanguage === LanguageCode.en ? 'description_En' : 'description_Fr'}
              header={<OfficeHeader />}
              disabled={data.isArchived}
              readOnly={!isInternalUser}
              entity={data}
              schema={OfficeSchema()}
              onSave={call}
              actionMenuItems={renderActionMenuItems}
              onConfirm={() => refresh()}
              breadcrumbs={[
                {
                  title: t('contact:office.title'),
                  href: isInternalUser
                    ? routes.Admin.TBMContacts.Offices.path
                    : isMemberUser
                    ? routes.Member.TBMContacts.Offices.path
                    : routes.Vendor.TBMContacts.Offices.path,
                },
                { title: getTranslation(data, 'description') },
              ]}
            >
              <OfficeForm />
            </EditDetails>
          )}
        </Container>
      </LayoutPage>
    </OfficeDetailContext.Provider>
  );
};
