import axios from 'axios';
import { MemberEntity, MemberGroupEntity } from '../../models';
import { MemberGroupFilter, PaginationEntity, PaginationFilter } from '../../types';

export const memberGroup = {
  getAll: async (memberGroupFilter: MemberGroupFilter): Promise<PaginationEntity<MemberGroupEntity>> => {
    const { data } = await axios.post('api/memberGroup/search', { ...memberGroupFilter });
    return { ...data, data: data.data.map((d: MemberGroupEntity) => new MemberGroupEntity(d)) };
  },
  archive: async (ids: number[], isArchived: boolean): Promise<MemberGroupEntity[]> => {
    const { data } = await axios.put(`api/memberGroup/archive`, { isArchived, ids });
    return data.map((v: MemberGroupEntity) => new MemberGroupEntity(v));
  },
  create: async (entity: MemberGroupEntity): Promise<MemberGroupEntity> => {
    const { data } = await axios.post('api/memberGroup', entity);
    return new MemberGroupEntity(data);
  },
  getSuggestions: async (
    memberGroupName: string,
    isArchived?: boolean,
  ): Promise<PaginationEntity<MemberGroupEntity>> => {
    const { data } = await axios.post('api/memberGroup/search', {
      memberGroupName,
      pageSize: 20,
      pageNumber: 1,
      isArchived,
    });
    return { ...data, data: data.data.map((d: MemberGroupEntity) => new MemberGroupEntity(d)) };
  },
  get: async (memberGroupId: number): Promise<MemberGroupEntity> => {
    const { data } = await axios.get(`api/memberGroup/${memberGroupId}`);
    return new MemberGroupEntity(data);
  },
  getMembers: async (memberGroupId: number, pagination: PaginationFilter): Promise<PaginationEntity<MemberEntity>> => {
    const { data } = await axios.post(`api/memberGroup/${memberGroupId}/members`, pagination);
    return { ...data, data: data.data.map((d: MemberEntity) => new MemberEntity(d)) };
  },
  update: async (entity: MemberGroupEntity): Promise<MemberGroupEntity> => {
    const { data } = await axios.put(`api/memberGroup/${entity.id}`, entity);
    return new MemberGroupEntity(data);
  },
};
