import { Box, Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { MAX_LONG_TEXT_LENGTH } from '../../constants';
import { useEntityFormContext } from '../../hooks';
import { MemberGroupEntity } from '../../models';
import { ControlledInput } from '../Form';

export const MemberGroupNotesForm = () => {
  const { t } = useTranslation();
  const { control, readOnly } = useEntityFormContext<MemberGroupEntity>();

  return (
    <Stack spacing={1}>
      <ControlledInput
        rows={6}
        maxLength={MAX_LONG_TEXT_LENGTH}
        multiline
        label={t('memberGroup:table.notes')}
        name="notes"
        control={control}
      />
      <Box>{!readOnly && <Trans i18nKey="common:formHelperText.notes" />}</Box>
    </Stack>
  );
};
