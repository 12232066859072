import { Stack, Typography } from '@mui/material';
import { useInternationalization } from '../../hooks';
import { ContentEntity } from '../../models';
import { LanguageCode } from '../../types';
import { formatShortDate } from '../../utils/helper';
import { CmsContentAttachmentsView } from './CmsContentAttachmentsView';
import { CmsContentBlockView } from './CmsContentBlockView';

export interface CmsContentViewProps<T extends ContentEntity> {
  contentEntity: T;
  downloadApi?: (contentEntityId: number, fileId: number) => Promise<Blob>;
  downloadBlockFileApi: (contentEntityId: number, blockId: number, language: LanguageCode) => Promise<Blob>;
  hideModifiedDate?: boolean;
}

export const CmsContentView = <T extends ContentEntity>({
  contentEntity,
  downloadApi,
  downloadBlockFileApi,
  hideModifiedDate,
}: CmsContentViewProps<T>) => {
  const { t } = useInternationalization();
  return (
    <Stack spacing={2}>
      {!hideModifiedDate && (
        <Typography variant="modifiedDate">
          {t('cms:content.postedOn', { date: formatShortDate(contentEntity.effectiveDate) })}
        </Typography>
      )}
      <CmsContentAttachmentsView contentEntity={contentEntity} downloadApi={downloadApi} />
      {contentEntity.pages[0]?.blocks.map((block) => (
        <CmsContentBlockView
          key={block.id}
          contentEntity={contentEntity}
          contentBlock={block}
          downloadBlockFileApi={downloadBlockFileApi}
        />
      ))}
    </Stack>
  );
};
