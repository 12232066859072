import { Box } from '@mui/system';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { Styles } from '../../../types';
import { palette } from '../../../styles/palette';

const style: Styles = {
  container: {
    '.ql-toolbar, .ql-container': {
      borderColor: palette.grey[200],
      borderWidth: '2px',
    },
    '.ql-container, .ql-editor, .ql-blank': {
      minHeight: '200px',
    },
  },
  readOnly: {
    '.ql-toolbar': {
      display: 'none',
    },
    '.ql-container': {
      borderTop: `solid 2px ${palette.grey[200]} !important`,
    },
  },
};

interface RichTextProps extends ReactQuillProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const RichText = (props: RichTextProps) => {
  return (
    <Box sx={[style.container, props.readOnly ? style.readOnly : {}]}>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        {...props}
        onChange={(value, delta, source) => source === 'user' && props.onChange?.(value)}
      />
    </Box>
  );
};

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet'];
