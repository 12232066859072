import { CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  FormHelperText,
  IconButton,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { useInternationalization } from '../../../hooks';
import { SelectOption, Styles } from '../../../types';
import { Container } from './Container';

const style: Styles = {
  withClearContainer: {
    '& .MuiSelect-select': {
      paddingRight: '70px !important',
    },
  },
  removeIcon: {
    position: 'absolute',
    right: '35px',
  },
  noOptions: {
    fontStyle: 'italic',
  },
  select: {
    '& .MuiSelect-select': {
      py: 1.55,
    },
  },
};

interface SelectProps<T, A> extends MuiSelectProps<A> {
  label?: string;
  options?: SelectOption<T>[];
  helperText?: ReactNode;
  onClear?: () => void;
  hideLabel?: boolean;
}

export const Select = <T extends string | number | boolean, A>({
  label,
  options,
  children,
  helperText,
  onClear,
  hideLabel,
  ...props
}: SelectProps<T, A>) => {
  const hasClearIcon = onClear && props.value !== null && props.value !== '';
  const { t } = useInternationalization();

  const selectedOption = options?.find((option) => option.value === props.value);

  const colorStyle = {
    '& .MuiInputBase-input': {
      color: selectedOption?.color,
    },
  };

  const renderClearSelection = () =>
    hasClearIcon && (
      <IconButton size="small" onClick={onClear} sx={style.removeIcon}>
        <CloseRounded fontSize="small" />
      </IconButton>
    );

  const hasOptions = !!(options?.length || children);

  const renderOptions = () => {
    const array: JSX.Element[] = [];
    options?.forEach((o, i) => {
      if (options?.[i - 1]?.groupName != o.groupName) {
        array.push(<ListSubheader key={`group- ${i.toString()}`}>{o.groupName}</ListSubheader>);
      }
      array.push(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <MenuItem key={i.toString()} value={o.value as any} sx={{ color: o.color }}>
          {o.label}
        </MenuItem>,
      );
    });
    return array;
  };

  return (
    <Container label={label} sx={hasClearIcon ? style.withClearContainer : undefined} hideLabel={hideLabel}>
      <MuiSelect
        IconComponent={KeyboardArrowDownRounded}
        displayEmpty
        endAdornment={hasOptions ? renderClearSelection() : undefined}
        variant="outlined"
        disabled={!hasOptions}
        {...props}
        sx={{ ...(!hasOptions ? style.noOptions : {}), ...style.select, ...colorStyle, ...props.sx }}
        renderValue={
          hasOptions || props.renderValue
            ? props.renderValue
            : (value) => (!value && !hasOptions ? t('common:noOptions') : props.renderValue && props.renderValue(value))
        }
      >
        {options ? renderOptions() : children}
      </MuiSelect>
      {helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </Container>
  );
};
